import { OrderDetails } from 'api/tinybirdCalls/types'
import {
  Individual,
  Individual_PrivacySettings,
  Individual_Role,
} from 'gen/perkup/v1/individual_pb'
import { NotificationSetting_RuleGroup } from 'gen/perkup/v1/notification_pb'
import { ProductVariant_Option } from 'gen/perkup/v1/product_variant_pb'
import { RootUser } from 'gen/perkup/v1/root_user_pb'
import { arrayToObject, notNullKeyValuePairs } from './arrays'
import { objectToMessage } from './firestore'
import { buildFullName } from './people'

export function shortName(user: RootUser): boolean {
  const { firstName, lastName } = user.profile || {}

  if (!firstName || !lastName) return true

  return firstName?.length <= 1 || lastName?.length <= 1
}

export function getIndividualDisplayName(individual: Individual) {
  const { firstName, lastName } = individual
  const fullName = buildFullName({ firstName, lastName })

  return fullName || individual.email
}

export function algoliaResultsToIndividuals(results: {
  hits: any[]
}): Individual[] {
  return results.hits.map(hit => {
    const parseHit = {
      ...hit,
      dob: hit.dob && new Date(hit.dob),
      startDate: hit.start_date && new Date(hit.start_date),
      created: hit.created && new Date(hit.created),
      id: hit.id || hit.objectID,
      removedAt: hit.removed_at && new Date(hit.removed_at),
    }
    return objectToMessage(Individual, parseHit)
  })
}

export function algoliaHitToIndividual(item: any) {
  const parseHit = {
    ...item,
    id: item.id || item.objectID,
    created: item.created && new Date(item.created),
    endDate: item.end_date && new Date(item.end_date),
    startDate: item.start_date && new Date(item.start_date),
  }
  return objectToMessage(Individual, parseHit)
}

export function getIndividualPrivacySettings({
  individual,
  ruleGroup,
}: {
  individual: Individual
  ruleGroup: NotificationSetting_RuleGroup
}): Individual_PrivacySettings {
  const privacySettingType = NotificationSetting_RuleGroup[ruleGroup]

  const defaultSettings = new Individual_PrivacySettings({
    allowAdmins: true,
    allowManagers: true,
    allowMembers: true,
  })

  return individual.privacySettings[privacySettingType] || defaultSettings
}

export function getIndividualPrivacySettingsAllowRole({
  individual,
  ruleGroup,
  role,
}: {
  individual: Individual
  ruleGroup: NotificationSetting_RuleGroup
  role: Individual_Role
}): boolean {
  const privacySettings = getIndividualPrivacySettings({
    individual,
    ruleGroup,
  })

  switch (role) {
    case Individual_Role.admin:
      return privacySettings.allowAdmins
    case Individual_Role.manager:
      return privacySettings.allowManagers
    case Individual_Role.member:
      return privacySettings.allowMembers
    default:
      return false
  }
}

export function parseBigQueryIndividual(raw: string | null): Individual | null {
  if (typeof raw !== 'string') return null

  const individual = JSON.parse(raw)
  if (!individual) return null

  if (individual.emails) {
    individual.emails = arrayToObject<string>(individual.emails, {
      parseFunction: value => JSON.parse(value),
    })
  }

  if (individual.labels) {
    individual.labels = arrayToObject<string>(individual.labels, {
      parseFunction: value => JSON.parse(value),
    })
  }

  if (individual.privacySettings) {
    individual.privacySettings = JSON.parse(individual.privacySettings)
  }
  if (typeof individual.isActive === 'string') {
    individual.isActive = JSON.parse(individual.isActive)
  }

  return Individual.fromJson(individual, {
    ignoreUnknownFields: true,
  })
}

export function parseBigQueryItemOptions(
  options: OrderDetails['items'][number]['options'] | undefined | null
): Record<string, ProductVariant_Option> {
  if (!options) return {}

  return arrayToObject(notNullKeyValuePairs(options, true), {
    parseFunction: value =>
      ProductVariant_Option.fromJson({
        value,
        values: [value],
      }),
  })
}
