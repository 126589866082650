import { LockFilled } from '@ant-design/icons'
import { captureException, captureMessage } from '@sentry/react'
import { Elements, PaymentElement, useElements } from '@stripe/react-stripe-js'
import { PaymentIntent, Stripe } from '@stripe/stripe-js'
import { Button, message } from 'antd'
import { PerkEmpty, PerkLoader } from 'components'
import { OrgContext } from 'context'
import { Text } from 'evergreen-ui'
import { useStripe } from 'hooks/Stripe/useStripe'
import useIds from 'hooks/useIds'
import { useContext, useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { CreateTopUpInvoice } from 'services'
import { numToDollars } from 'utils'

function CheckoutForm({
  clientSecret,
  stripe,
  amount,
  onAfterSubmit,
  disabled = false,
}: {
  clientSecret: string
  stripe: Stripe
  amount: number
  onAfterSubmit: (paymentIntent: PaymentIntent) => void
  disabled?: boolean
}) {
  const { trigger } = useFormContext() // This might break if not wrapped in form context - not sure so be careful if trying to spread this components use
  const { individualId, orgId } = useIds()
  const [messageApi, contextHolder] = message.useMessage()
  const [isLoadingPayment, setIsLoadingPayment] = useState(false)
  const elements = useElements()

  const sentryContext = {
    contexts: {
      CompanyAccountPayment: { clientSecret, individualId, orgId },
    },
  }

  const handleSubmitStripePayment = async () => {
    if (!elements) {
      messageApi.warning('Error initialziing stripe elements.')
      captureMessage('Stripe elements not found.', sentryContext)
      return
    }

    const canProceed = await trigger()
    if (!canProceed) return

    setIsLoadingPayment(true)
    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: { return_url: undefined },
      redirect: 'if_required',
    })
    if (!error) {
      const { paymentIntent } = await stripe.retrievePaymentIntent(clientSecret)
      if (paymentIntent?.status === 'succeeded') {
        onAfterSubmit(paymentIntent)
      } else {
        setIsLoadingPayment(false)
      }
    } else {
      if (error && error.message) {
        captureException(error, sentryContext)
        if (error.type === 'card_error' || error.type === 'validation_error') {
          messageApi.warning(error.message)
        } else {
          messageApi.warning('An unexpected error occurred.')
        }
      }
      setIsLoadingPayment(false)
    }
  }

  return (
    <>
      {contextHolder}
      <div className="max-w-xl flex flex-col gap-4">
        <PaymentElement id="customStripeElement" />
        <div className="flex flex-col gap-2 mt-8">
          <Button
            size="large"
            type="primary"
            disabled={isLoadingPayment || disabled}
            loading={isLoadingPayment}
            icon={<LockFilled />}
            onClick={handleSubmitStripePayment}
          >
            Pay {numToDollars(amount, 2)}
          </Button>
          <Text color="muted">
            By confirming your payment, you allow PerkUp Inc. to charge your
            card for this payment in accordance with their terms.
          </Text>
        </div>
      </div>
    </>
  )
}

export function CompanyAccountPayment({
  accountId,
  amount,
  onAfterSubmit,
  disabled = false,
}: {
  accountId: string
  amount: number
  onAfterSubmit: (paymentIntent?: PaymentIntent) => void
  disabled?: boolean
}) {
  const { customerId, id: orgId } = useContext(OrgContext)

  const [clientSecret, setClientSecret] = useState<string>()
  const [isLoadingClientSecret, setIsLoadingClientSecret] = useState(false)
  const { stripe, isLoading: isLoadingStripe } = useStripe()

  useEffect(() => {
    setIsLoadingClientSecret(true)
    CreateTopUpInvoice({
      orgId,
      accountId,
      amount,
      paymentMethod: 'card',
      customer: customerId,
      isEmbeddedCheckout: true,
    })
      .then(res => {
        if (res) setClientSecret(res.paymentIntentClientSecret)
      })
      .finally(() => setIsLoadingClientSecret(false))
  }, [amount, accountId, customerId, orgId])

  if (isLoadingClientSecret || isLoadingStripe) return <PerkLoader />

  if (!clientSecret || !stripe)
    return (
      <PerkEmpty header="We're having trouble getting checkout started - please try again or reach out to support." />
    )

  return (
    <Elements
      stripe={stripe}
      options={{
        appearance: { theme: 'stripe' },
        clientSecret,
      }}
    >
      <CheckoutForm
        stripe={stripe}
        clientSecret={clientSecret}
        amount={amount}
        onAfterSubmit={onAfterSubmit}
        disabled={disabled}
      />
    </Elements>
  )
}
