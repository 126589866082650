import { ListBalanceTransactionsByProgramId } from 'api/databaseCalls'
import { GetOrdersByOrderIds } from 'api/databaseCalls/reads/orders'
import { Order } from 'gen/perkup/v1/vendor_pb'
import { isEmpty, uniqBy } from 'lodash-es'
import { useEffect, useState } from 'react'
import useIds from '../useIds'

export function useProgramIdToOrders({
  programId,
  ownerId,
}: {
  programId?: string
  ownerId?: string
}) {
  const { orgId } = useIds()

  const [balanceTransactionAuthIds, setBalanceTransactionAuthIds] = useState<
    string[]
  >([])
  const [orders, setOrders] = useState<Order[]>([])

  const MANUAL_TYPE_CHECK = 'manual'

  useEffect(() => {
    if (!programId || !ownerId) return
    ListBalanceTransactionsByProgramId({
      userId: ownerId,
      orgId,
      programId,
    }).then(balanceTransactions => {
      if (!balanceTransactions) return
      const uniqBts = uniqBy(balanceTransactions, 'authId')
      const filteredBts = uniqBts.filter(bt =>
        bt.type.includes(MANUAL_TYPE_CHECK)
      )
      const authIds = filteredBts.map(bt => bt.authId)
      setBalanceTransactionAuthIds(authIds)
    })
  }, [programId, orgId, ownerId])

  useEffect(() => {
    if (isEmpty(balanceTransactionAuthIds)) return
    GetOrdersByOrderIds({ orderIds: balanceTransactionAuthIds }).then(
      orders => {
        if (!orders) return
        setOrders(orders)
      }
    )
  }, [balanceTransactionAuthIds])

  return { orders }
}
