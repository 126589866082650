import { captureException } from '@sentry/react'
import { addDoc, collection, getFirestore } from 'firebase/firestore'
import { PurchaseOrder } from 'gen/perkup/v1/purchase_order_pb'
import { WithoutId } from 'types'
import { toSentry } from 'utils'
import { converter } from 'utils/firestore'

export async function createPurchaseOrder(
  purchaseOrder: WithoutId<PurchaseOrder>
) {
  try {
    const db = getFirestore()
    const colRef = collection(db, 'purchaseOrders').withConverter(
      converter(PurchaseOrder)
    )

    const newDoc = await addDoc(colRef, purchaseOrder)

    return newDoc.id
  } catch (error) {
    console.error(error)
    captureException(toSentry(error), {
      contexts: {
        createPurchaseOrder: {
          purchaseOrder,
        },
      },
    })
    return undefined
  }
}
