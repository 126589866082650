import { ListCanvasDesignsByIds } from 'api/databaseCalls/reads/canvas-designs'
import { OrgContext } from 'context'
import { CanvasDesign } from 'gen/canvas/designs_pb'
import { isEmpty } from 'lodash-es'
import { useContext, useEffect, useState } from 'react'

export function useListCanvasDesigns(ids: string[]) {
  const { id: orgId } = useContext(OrgContext)
  const [canvasDesigns, setCanvasDesigns] = useState<CanvasDesign[]>([])
  const [hasLoaded, setHasLoaded] = useState(false)

  useEffect(() => {
    if (isEmpty(ids)) {
      setCanvasDesigns([])
      setHasLoaded(true)
      return
    }
    ListCanvasDesignsByIds({ ids, orgId })
      .then(setCanvasDesigns)
      .finally(() => {
        setHasLoaded(true)
      })
  }, [ids, orgId])

  return { canvasDesigns, hasLoaded }
}
