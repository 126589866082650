import { PlusOutlined } from '@ant-design/icons'
import { Timestamp } from '@bufbuild/protobuf'
import { Button, Flex } from 'antd'
import Table, { ColumnsType } from 'antd/es/table'
import { ListenToOrgDomains } from 'api/databaseCalls'
import { tablePaginationSettings } from 'constants/antdesign'
import { OrgContext } from 'context'
import { Heading, Link, Text } from 'evergreen-ui'
import { Domain } from 'gen/perkup/v1/organization_pb'
import { isEmpty } from 'lodash-es'
import { useContext, useEffect, useState } from 'react'

interface DataType {
  domain: string
  created?: Timestamp
}

const columns: ColumnsType<DataType> = [
  {
    title: 'Domain',
    dataIndex: 'domain',
    key: 'domain',
    render: domain => <Text>{domain}</Text>,
  },
  {
    title: 'Created',
    dataIndex: 'created',
    key: 'created',
    render: created => (
      <Text>
        {new Date(Number(created?.seconds) * 1000)?.toLocaleDateString(
          'en-us',
          {
            month: 'short',
            day: 'numeric',
            year: 'numeric',
          }
        )}
      </Text>
    ),
  },
]

export function AllowedDomainsTable() {
  const org = useContext(OrgContext)

  const [domains, setDomains] = useState<Domain[]>([])

  useEffect(() => {
    return ListenToOrgDomains({
      orgId: org.id,
      cb: setDomains,
    })
  }, [org.id])

  const data = domains.map(domain => ({
    key: domain.domain,
    domain: domain.domain,
    created: domain.created,
  }))

  return (
    <Flex vertical gap={16}>
      <Flex gap={64}>
        <Flex vertical>
          <Heading size={600} marginBottom={8}>
            Allowed email domains
          </Heading>
          <Text>
            Anyone with these domains can automatically sign up for this
            organization.
          </Text>
        </Flex>
        <Link
          href="https://docs.google.com/forms/d/e/1FAIpQLSdauqw6j61CkCtgDFxivOB1bFCM8qBBqqfrOmNSnmg-3sNLGg/viewform?usp=sf_link"
          target="_blank"
          rel="noopener noreferrer"
          marginLeft="auto"
        >
          <Button type="text" icon={<PlusOutlined />}>
            Add domain
          </Button>
        </Link>
      </Flex>

      {!isEmpty(data) && (
        <Table
          dataSource={data}
          columns={columns}
          pagination={tablePaginationSettings}
        />
      )}
    </Flex>
  )
}
