import { Flex, Table, TableColumnsType } from 'antd'
import { ColumnsType } from 'antd/es/table'
import {
  FetchTinyBirdBalanceDetailedReportsByOrgId,
  FetchTinyBirdBalanceReportsByOrgId,
} from 'api/tinybirdCalls/reads'
import {
  DownloadBalanceDetailedReportButton,
  DownloadBalanceReportButton,
} from 'components'
import { tablePaginationSettings } from 'constants/antdesign'
import { isProduction } from 'constants/keys'
import { Text } from 'evergreen-ui'
import { Account } from 'gen/perkup/v1/account_pb'
import { useDevSafeOrgId } from 'hooks/useDevSafeOrgId'
import { useEffect, useState } from 'react'
import { BalanceDetailedReport, BalanceReport } from 'types/TinyBird'
import { getDateTimeString, numToDollars } from 'utils'

interface DataType {
  key: React.Key
  date: string
  openingBalance: string
  deposits: string
  refunds: string
  adjustments: string
  spent: string
  endingBalance: string
  year: number
  month: number
}

interface ExpandedDataType {
  key: React.Key
  created: string
  amount: number
  type: string
  description: string
}

const columns: ColumnsType<DataType> = [
  {
    title: 'Date',
    dataIndex: 'date',
    key: 'date',
    render: date => <Text>{date}</Text>,
  },
  {
    title: 'Opening Balance',
    dataIndex: 'openingBalance',
    key: 'openingBalance',
    render: openingBalance => <Text>{openingBalance}</Text>,
  },
  {
    title: 'Deposits',
    dataIndex: 'deposits',
    key: 'deposits',
    render: deposits => <Text>{deposits}</Text>,
  },
  {
    title: 'Refunds',
    dataIndex: 'refunds',
    key: 'refunds',
    render: refunds => <Text>{refunds}</Text>,
  },
  {
    title: 'Adjustments',
    dataIndex: 'adjustments',
    key: 'adjustments',
    render: adjustments => <Text>{adjustments}</Text>,
  },
  {
    title: 'Spent',
    dataIndex: 'spent',
    key: 'spent',
    render: spent => <Text>{spent}</Text>,
  },
  {
    title: 'Ending Balance',
    dataIndex: 'endingBalance',
    key: 'endingBalance',
    render: endingBalance => <Text>{endingBalance}</Text>,
  },
]

export function BalanceActivityTable({ account }: { account: Account }) {
  const accountId = isProduction ? account.id : 'bHK1E2Y50YMS8nwg2xOh'
  const orgId = useDevSafeOrgId()

  const [balanceReports, setBalanceReports] = useState<BalanceReport[]>([])
  const [detailedBalanceReport, setDetailedBalanceReport] = useState<
    BalanceDetailedReport[]
  >([])

  useEffect(() => {
    if (orgId && accountId) {
      FetchTinyBirdBalanceReportsByOrgId({
        orgId,
        cb: setBalanceReports,
        accountId,
      })
    }
  }, [orgId, accountId])

  useEffect(() => {
    if (orgId && accountId) {
      FetchTinyBirdBalanceDetailedReportsByOrgId({
        orgId,
        cb: setDetailedBalanceReport,
        accountId,
      })
    }
  }, [orgId, accountId])

  const formattedData = balanceReports.map(br => ({
    key: br.date,
    date: new Date(br.year, br.month - 1)?.toLocaleDateString('en-us', {
      month: 'long',
      year: 'numeric',
    }),
    openingBalance: numToDollars(br.openingBalance) || '',
    endingBalance: numToDollars(br.endingBalance) || '',
    deposits: numToDollars(br.deposited) || '',
    refunds: numToDollars(br.refunded) || '',
    spent: numToDollars(br.spent) || '',
    adjustments: numToDollars(br.adjustment) || '',
    year: br.year,
    month: br.month,
  }))

  const expandedRowRender = (record: DataType) => {
    const columns: TableColumnsType<ExpandedDataType> = [
      {
        title: 'Date',
        dataIndex: 'created',
        key: 'created',
      },
      {
        title: 'Type',
        dataIndex: 'type',
        key: 'type',
      },
      {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
      },
      {
        title: 'Amount',
        dataIndex: 'amount',
        key: 'amount',
        render: amount => numToDollars(amount),
      },
    ]

    const filteredDetailedData = detailedBalanceReport.filter(dbr => {
      const createdDate = new Date(dbr.created)
      return (
        record.year === createdDate.getFullYear() &&
        record.month - 1 === createdDate.getUTCMonth()
      )
    })

    const formattedDetailedData = filteredDetailedData.map(dbr => ({
      key: dbr.id,
      created: getDateTimeString(new Date(dbr.created)),
      amount: dbr.amount,
      type: dbr.type,
      description: dbr.description,
    }))

    return (
      <Table
        columns={columns}
        dataSource={formattedDetailedData}
        pagination={tablePaginationSettings}
      />
    )
  }

  return (
    <Flex vertical gap={16}>
      <Flex justify="space-between" align="flex-end">
        <Text>Last updated: 2 hours ago</Text>
        <Flex gap={16}>
          {accountId && (
            <DownloadBalanceReportButton reports={balanceReports} />
          )}
          {accountId && (
            <DownloadBalanceDetailedReportButton
              reports={detailedBalanceReport}
            />
          )}
        </Flex>
      </Flex>
      <Table
        dataSource={formattedData}
        columns={columns}
        expandable={{
          expandedRowRender,
          defaultExpandedRowKeys: ['0'],
        }}
        pagination={tablePaginationSettings}
      />
    </Flex>
  )
}
