import { RewardUseCase } from 'constants/newReward/rewardUseCases'
import { OrgContext } from 'context'
import { intersectionWith } from 'lodash-es'
import { getBrandedImagesByUseCases } from 'pages/NewReward/utils/uiUtils'
import { useContext, useEffect, useState } from 'react'
import { GetFirebaseStorageImage } from 'services/images'

export function useBrandedImagesByUseCase(useCases: RewardUseCase[]) {
  const { id: orgId } = useContext(OrgContext)
  const [brandedImages, setBrandedImages] = useState<
    Record<RewardUseCase, string[]>
  >({} as Record<RewardUseCase, string[]>)

  const [hasLoaded, setHasLoaded] = useState(false)

  useEffect(() => {
    const useCaseToTemplateIds = useCases.reduce(
      (acc, useCase) => ({
        ...acc,
        [useCase]: getBrandedImagesByUseCases({ useCases: [useCase] }),
      }),
      {} as Record<RewardUseCase, string[]>
    )
    Promise.all(
      Object.values(useCaseToTemplateIds)
        .flat()
        .map(async id => {
          const res = await GetFirebaseStorageImage({
            orgId,
            imageName: `${id}.jpg`,
            suffix: 'placidImages',
          })
          return res?.url
        })
    )
      .then(urls => {
        setBrandedImages(
          Object.entries(useCaseToTemplateIds).reduce(
            (acc, [useCase, templateIds]) => ({
              ...acc,
              [useCase]: intersectionWith(
                urls,
                templateIds,
                (url, templateId) => !!url && url.includes(templateId)
              ),
            }),
            {} as Record<RewardUseCase, string[]>
          )
        )
      })
      .finally(() => setHasLoaded(true))
  }, [orgId, useCases])

  return { brandedImages, hasLoaded }
}
