import { callFunction } from 'api/functionCalls'
import * as ROUTES from 'constants/routes'
import { OrgContext } from 'context'
import { useContext, useEffect, useState } from 'react'

export default function useBillingUrl() {
  const { customerId } = useContext(OrgContext)

  const [isLoading, setIsLoading] = useState(false)
  const [billingUrl, setBillingUrl] = useState<string>()

  useEffect(() => {
    const returnUrl =
      window.document.location.origin +
      ROUTES.DEFAULT_ROUTES.ORGANIZATION.BILLING

    if (customerId && returnUrl) {
      setIsLoading(true)
      callFunction('stripe-CreateCustomerPortalSessions', {
        customerId,
        returnUrl,
      })
        .then(setBillingUrl)
        .finally(() => setIsLoading(false))
    }
  }, [customerId])

  return { billingUrl, isLoading }
}
