import { SearchOutlined } from '@ant-design/icons'
import { Input } from 'antd'
import { isFunction } from 'lodash-es'
import { useSearchBox } from 'react-instantsearch'

export function AlgoliaSearchBox({
  placeholder = 'Search by occasion, brands, products, categories, etc.',
  onChange,
}: {
  placeholder?: string
  onChange?: (value: string) => void
}) {
  const { query, refine } = useSearchBox()

  return (
    <Input
      placeholder={placeholder}
      value={query}
      onChange={e => {
        refine(e.target.value)
        if (isFunction(onChange)) onChange(e.target.value)
      }}
      allowClear
      prefix={<SearchOutlined />}
      style={{ maxWidth: 520 }}
    />
  )
}
