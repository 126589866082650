import { DatePicker, Flex } from 'antd'
import { NewProgramModal, PageHeader, withOrgSidebar } from 'components'
import { DownloadRewardReportButton } from 'components/DownloadButtons/DownloadRewardReportButton'
import { BrowsePrograms, BrowseTemplates } from 'features'
import { ProgramType } from 'gen/perkup/v1/program_pb'
import { useIndividualRole } from 'hooks'
import { useState } from 'react'
import { RangeValue } from 'types/RewardsDate'

function SpotRewards() {
  const { isAdmin } = useIndividualRole()

  const [dates, setDates] = useState<RangeValue>(null)

  return (
    <Flex vertical gap={16}>
      <PageHeader
        title="Rewards"
        vlogLink="https://www.loom.com/embed/70b549360c0e453bba7153a616ef3633?sid=70b80453-1e38-4d78-83ca-bb51d1300dcb"
        buttonsToRenderOnRight={
          isAdmin && (
            <Flex gap={8}>
              <DatePicker.RangePicker format="YYYY/MM/DD" onChange={setDates} />

              <DownloadRewardReportButton
                dates={dates}
                programTypes={[ProgramType.rewards]}
              />
            </Flex>
          )
        }
      />
      <BrowseTemplates />
      <BrowsePrograms datesToFilter={dates} />
      <NewProgramModal />
    </Flex>
  )
}

export default withOrgSidebar(SpotRewards)
