import { Alert } from 'antd'
import { Pane, Text } from 'evergreen-ui'
import { ProductVariant_DeliveryType } from 'gen/perkup/v1/product_variant_pb'

export function VariantDeliveryLabel({
  deliveryType,
  useAlert = true,
}: {
  deliveryType?: ProductVariant_DeliveryType
  useAlert?: boolean
}) {
  if (!deliveryType) return null

  const isRealTimeDelivery =
    deliveryType === ProductVariant_DeliveryType.realtime

  const realTimeDeliveryString =
    'This eGift Card will be delivered instantly via email.'

  const delayedDeliveryString =
    'This eGift Card will be delivered via email in 5-7 business days.'

  return (
    <Pane>
      {isRealTimeDelivery && (
        <Text color="muted">{realTimeDeliveryString}</Text>
      )}
      {!isRealTimeDelivery && useAlert && (
        <Alert message={delayedDeliveryString} type="warning" showIcon />
      )}
      {!isRealTimeDelivery && !useAlert && (
        <Text color="muted">{delayedDeliveryString}</Text>
      )}
    </Pane>
  )
}
