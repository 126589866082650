import { Tooltip } from 'antd'
import React, { useLayoutEffect, useState } from 'react'

const useTruncatedElement = ({
  ref,
}: {
  ref: React.MutableRefObject<HTMLDivElement | null>
}) => {
  const [isTruncated, setIsTruncated] = useState(false)

  useLayoutEffect(() => {
    const { offsetHeight, scrollHeight } = ref.current || {}

    if (offsetHeight && scrollHeight && offsetHeight < scrollHeight) {
      setIsTruncated(true)
    } else {
      setIsTruncated(false)
    }
  }, [ref])

  return {
    isTruncated,
  }
}

export function PerkTruncate({ children }: { children: React.ReactNode }) {
  const ref = React.useRef<HTMLDivElement>(null)
  const [openTooltip, setOpenTooltip] = useState(false)

  const { isTruncated } = useTruncatedElement({
    ref,
  })

  return (
    <Tooltip open={openTooltip} title={ref.current?.innerText}>
      <div
        ref={ref}
        className="one-line-truncate"
        onMouseEnter={() => {
          if (isTruncated) {
            setOpenTooltip(true)
          }
        }}
        onMouseLeave={() => setOpenTooltip(false)}
      >
        {children}
      </div>
    </Tooltip>
  )
}
