import { GetIndividualById } from 'api/databaseCalls'
import { Individual } from 'gen/perkup/v1/individual_pb'
import useIds from 'hooks/useIds'
import { useEffect, useState } from 'react'

export function useIndividualById({ individualId }: { individualId?: string }) {
  const { orgId } = useIds()
  const [individual, setIndividual] = useState<Individual>()
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    if (!individualId || !orgId) {
      setIndividual(undefined)
      setIsLoading(false)
      return
    }
    setIsLoading(true)
    GetIndividualById({ orgId, individualId })
      .then(res => setIndividual(res))
      .finally(() => setIsLoading(false))
  }, [individualId, orgId])

  return { individual, isLoading }
}
