import { callFunction } from 'api/functionCalls'
import { useEffect, useState } from 'react'
import { RewardDetails } from 'types'

export function useDecryptRewardDetails({ previewId }: { previewId?: string }) {
  const [isLoading, setIsLoading] = useState(false)
  const [rewardDetails, setRewardDetails] = useState<
    RewardDetails | undefined
  >()

  // Decrypt preview ID
  useEffect(() => {
    setIsLoading(true)
    callFunction('services-DecryptString', { text: previewId })
      .then(data => setRewardDetails(JSON.parse(data)))
      .finally(() => {
        setIsLoading(false)
      })
  }, [previewId])

  return { isLoading, rewardDetails }
}
