import { PartialMessage } from '@bufbuild/protobuf'
import { captureException } from '@sentry/react'
import { callFunction } from 'api/functionCalls'
import axios from 'axios'
import {
  AURIGMA_BASE_URL,
  AURIGMA_STOREFRONT_ID,
  AURIGMA_TENANT_ID,
} from 'constants/customer-canvas'
import { BuildCanvasDesignRequest, CanvasDesign } from 'gen/canvas/designs_pb'
import {
  CustomerCanvasProduct,
  CustomerCanvasVariant,
  DesignVariable,
  Entity,
  ProductDesign,
  ProductLink,
  ProductMockup,
} from 'types'
import { toSentry } from 'utils'

interface ProductLinkDtoResponse {
  id?: number // Product link identifier.
  productVersionId?: number // Product version identifier.
  productId?: number // Product identifier
  productFilterId?: number // Product filter identifier.
  storefrontProductId?: string
}

interface PagedOfProductLinkDtoResponse {
  total?: number
  items?: ProductLinkDtoResponse[] | null
}

export async function getCustomerCanvasProductLinkByReference(id: string) {
  try {
    const response: ProductLinkDtoResponse | undefined = await callFunction(
      'aurigma-GetProductLinkByReference',
      {
        reference: id,
        storefrontId: AURIGMA_STOREFRONT_ID,
      }
    )

    if (
      !response?.id ||
      !response?.productFilterId ||
      !response?.productId ||
      !response?.productVersionId ||
      !response?.storefrontProductId
    ) {
      throw new Error('Failed to find product link')
    }

    return {
      id: response.id,
      productFilterId: response.productFilterId,
      productId: response.productId,
      productVersionId: response.productVersionId,
      storefrontProductId: response.storefrontProductId,
    } as unknown as ProductLink
  } catch (error) {
    console.error(error)
    captureException(toSentry(error), {
      contexts: {
        getCustomerCanvasProductLinkByReference: {
          reference: id,
          error,
        },
      },
    })
    return undefined
  }
}

export async function getCustomerCanvasAllProductLinks() {
  const productLinks: ProductLink[] = []

  try {
    const response: PagedOfProductLinkDtoResponse | undefined =
      await callFunction('aurigma-GetAllProductLinks', {
        storefrontId: AURIGMA_STOREFRONT_ID,
      })

    response?.items?.forEach(item => {
      if (
        item.id &&
        item.productFilterId &&
        item.productId &&
        item.productVersionId &&
        item.storefrontProductId
      ) {
        const productLink: ProductLink = {
          id: item.id,
          productFilterId: item.productFilterId,
          productId: item.productId,
          productVersionId: item.productVersionId,
          storefrontProductId: item.storefrontProductId,
        }
        productLinks.push(productLink)
      }
    })

    return productLinks
  } catch (error) {
    console.error(error)
    captureException(toSentry(error), {
      contexts: {
        getCustomerCanvasAllProductLinks: {
          error,
        },
      },
    })
    return []
  }
}

export async function getFolderByFullPath(fullPath: string) {
  try {
    const token = await callFunction('aurigma-GetBackOfficeToken', {})

    const response = await axios({
      method: 'get',
      url: `${AURIGMA_BASE_URL}/api/storage/v1/images/folders/content-by-path?fullPath=${fullPath}&tenantId=${AURIGMA_TENANT_ID}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })

    if (response?.data?.entities?.length === 0) {
      throw new Error('Requested folder was completely empty')
    }

    return response.data.entities as Entity[]
  } catch (error) {
    console.error(error)
    captureException(toSentry(error), {
      contexts: {
        getFolderByOrgId: {
          fullPath,
          error,
        },
      },
    })
    return []
  }
}

export async function getDesignVariablesByDesignFileId(id: string) {
  try {
    const token = await callFunction('aurigma-GetBackOfficeToken', {})

    const variablesResponse = await axios.get(
      `/api/atoms/v1/designs/${id}/variables?tenantId=${AURIGMA_TENANT_ID}`,
      {
        baseURL: AURIGMA_BASE_URL,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )

    if (!variablesResponse?.data?.items) {
      throw new Error('Failed to find design variables')
    }

    return variablesResponse.data.items as unknown as DesignVariable[]
  } catch (error) {
    console.error(error)
    captureException(toSentry(error), {
      contexts: {
        fetchDesignVariablesByDesignFileId: {
          id,
        },
      },
    })
    return []
  }
}

export async function generatePreviewImages({
  designId,
  mockupIds,
  variables,
}: {
  designId: string
  mockupIds: string[]
  variables: DesignVariable[]
}) {
  try {
    const token = await callFunction('aurigma-GetBackOfficeToken', {})

    const previewUrls: string[] = []

    // For each mockup, generate a preview image:
    const previewImagePromises = await Promise.all(
      mockupIds.map((mockupId, index) =>
        axios({
          method: 'post',
          url: `${AURIGMA_BASE_URL}/api/atoms/v1/designs/render-preview?tenantId=${AURIGMA_TENANT_ID}`,
          headers: {
            Authorization: `Bearer ${token}`,
          },
          responseType: 'blob',
          data: {
            designId,
            mockupId,
            renderingConfig: {
              width: 1000,
              height: 1000,
              surfaceIndex: index,
              fileFormat: 'Jpeg',
            },
            variableData: variables,
          },
        })
      )
    )

    // Convert the blobs to a URLs
    previewImagePromises.forEach(previewImageResponse => {
      if (previewImageResponse.data) {
        previewUrls.push(URL.createObjectURL(previewImageResponse.data))
      }
    })

    return previewUrls
  } catch (error) {
    console.error(error)
    captureException(toSentry(error), {
      contexts: {
        generatePreviewImage: {
          error,
        },
      },
    })
    return undefined
  }
}

export async function getFullCustomerCanvasProduct(referenceId: string) {
  try {
    const token = await callFunction('aurigma-GetBackOfficeToken', {})

    const productResponse = await axios({
      method: 'get',
      url: `${AURIGMA_BASE_URL}/api/storefront/v1/product-references/ext/${referenceId}/product?storefrontId=${AURIGMA_STOREFRONT_ID}&tenantId=${AURIGMA_TENANT_ID}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })

    if (!productResponse?.data?.id) {
      throw new Error('Failed to find product')
    }

    const { id } = productResponse.data

    const variantsResponse = await axios({
      method: 'get',
      url: `${AURIGMA_BASE_URL}/api/storefront/v1/products/${id}/variants?tenantId=${AURIGMA_TENANT_ID}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })

    if (!variantsResponse?.data?.items) {
      throw new Error('Failed to find product variants')
    }

    const variants = variantsResponse.data.items as CustomerCanvasVariant[]

    const designResponse = await axios({
      method: 'get',
      url: `${AURIGMA_BASE_URL}/api/storefront/v1/products/${id}/variant-designs?tenantId=${AURIGMA_TENANT_ID}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })

    const designs = designResponse.data.items as ProductDesign[]

    if (!designResponse?.data?.items) {
      throw new Error('Failed to find product designs')
    }

    const mockupsResponse = await axios({
      method: 'get',
      url: `${AURIGMA_BASE_URL}/api/storefront/v1/products/${id}/variant-mockups?tenantId=${AURIGMA_TENANT_ID}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })

    if (!mockupsResponse?.data?.items) {
      throw new Error('Failed to find product mockups')
    }

    const mockups = mockupsResponse.data.items as ProductMockup[]

    const fullProduct: CustomerCanvasProduct = {
      id,
      variants,
      designs,
      mockups: mockups.filter(mockup => mockup.mockupType === 'Preview'), // For now we only care about preview mockups
    }

    return fullProduct
  } catch (error) {
    console.error(error)
    captureException(toSentry(error), {
      contexts: {
        getProductMockups: {
          referenceId,
          error,
        },
      },
    })
    return undefined
  }
}

export async function createCanvasDesign(
  data: PartialMessage<BuildCanvasDesignRequest>
) {
  try {
    const buildRequest = new BuildCanvasDesignRequest(data)

    const response = await callFunction(
      'aurigma-BuildCustomizedDesign',
      buildRequest.toJson()
    )

    return CanvasDesign.fromJson(response)
  } catch (error) {
    console.error(error)
    captureException(toSentry(error), {
      contexts: {
        createDesign: {
          error,
        },
      },
    })
    return undefined
  }
}
