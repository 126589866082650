import { Flex, Tooltip } from 'antd'
import Table, { ColumnsType } from 'antd/es/table'
import { GetOrderFulfillmentsByOrderId } from 'api/databaseCalls/reads/orderFullfilments'
import { PerkLoader, TransactionDrawer, TransactionStatus } from 'components'
import { PerkLink } from 'components/perk/perk-link'
import { tablePaginationSettings } from 'constants/antdesign'
import {
  CrossIcon,
  DollarIcon,
  Pane,
  Strong,
  Text,
  TickIcon,
  TimeIcon,
} from 'evergreen-ui'
import {
  OrgTransaction,
  OrgTransaction_Shipping,
} from 'gen/perkup/v1/organization_pb'
import { OrderFulfillment } from 'gen/perkup/v1/vendor_pb'
import { isEmpty, truncate } from 'lodash-es'
import { useEffect, useMemo, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { numToDollars } from 'utils'
import { isManualTransaction } from 'utils/transactions'
import { ShipmentActionTracking } from './ShipmentActionTracking'

interface DataType {
  key: string
  date?: string
  description: string
  status: string
  amount: string | null
  currency: string
  type: string
  approved: boolean
  orderFulfillments?: OrderFulfillment[]
  handleSelectTransaction: () => void
  shipping?: OrgTransaction_Shipping
}

const columns: ColumnsType<DataType> = [
  {
    title: 'Date',
    dataIndex: 'date',
    key: 'date',
    render: date => (
      <Pane>
        <Text whiteSpace="nowrap">{date}</Text>
      </Pane>
    ),
  },
  {
    title: 'Description',
    dataIndex: 'description',
    key: 'description',
    render: description => {
      return (
        <Pane>
          {description.length <= 60 && <Text>{description}</Text>}
          {description.length > 60 && (
            <Tooltip title={description}>
              <Text>
                {truncate(description, {
                  length: 60,
                })}
              </Text>
            </Tooltip>
          )}
        </Pane>
      )
    },
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    render: (status, record) => {
      if (!isMobile) {
        return (
          <Flex gap={4} wrap>
            <TransactionStatus
              type={record.type}
              approved={record.approved}
              status={status}
            />
          </Flex>
        )
      }

      if (record.type === 'refund')
        return <DollarIcon color="yellow" marginRight={8} />
      return (
        <Pane>
          {record.approved ? (
            <TickIcon color="green" marginRight={8} />
          ) : (
            <CrossIcon color="red" marginRight={8} />
          )}
          {status === 'pending' && <TimeIcon color="blue" marginRight={8} />}
        </Pane>
      )
    },
  },
  {
    title: 'Amount',
    dataIndex: 'amount',
    key: 'amount',
    render: amount => (
      <Pane>
        <Strong size={400}>{amount}</Strong>
      </Pane>
    ),
  },
  {
    title: 'Tracking',
    dataIndex: 'orderFulfillments',
    key: 'tracking',
    render: (orderFulfillments, record) => {
      const hasOrderFulfillments =
        orderFulfillments && !isEmpty(orderFulfillments)

      const shipping = record?.shipping

      const hasTracking = !!shipping?.trackingUrl && !!shipping?.trackingNumber

      return (
        <Pane>
          {hasOrderFulfillments ? (
            <ShipmentActionTracking orderFulfillments={orderFulfillments} />
          ) : (
            <Pane>
              {hasTracking && shipping?.trackingUrl && (
                <PerkLink
                  rel="noopener noreferrer"
                  target="_blank"
                  to={shipping.trackingUrl}
                >
                  {shipping.trackingNumber}
                </PerkLink>
              )}
              {!hasTracking && <Text>—</Text>}
            </Pane>
          )}
        </Pane>
      )
    },
  },
]

export function UserTransactionsTable({
  transactions,
}: {
  transactions?: OrgTransaction[]
}) {
  const [selectedTransaction, setSelectedTransaction] =
    useState<OrgTransaction>()
  const [formattedData, setFormattedData] = useState<DataType[]>([])
  const [isLoading, setIsLoading] = useState(false)

  const dateStringOptions: Intl.DateTimeFormatOptions = useMemo(() => {
    return isMobile
      ? {
          month: 'short',
          day: 'numeric',
        }
      : {
          month: 'short',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
          timeZoneName: 'shortGeneric',
        }
  }, [])

  useEffect(() => {
    if (!transactions) return
    setIsLoading(true)
    const getData = transactions.map(async transaction => {
      const { isGift } = transaction
      const amount = isGift ? '—' : numToDollars(transaction.amount, 2)
      const handleSelectTransaction = () => {
        setSelectedTransaction(transaction)
      }
      const hasOrderFulfillments = isManualTransaction({ transaction })
      const orderFulfillments = hasOrderFulfillments
        ? await GetOrderFulfillmentsByOrderId({
            orderId: transaction.id,
          })
        : []

      return {
        key: transaction.id,
        date: transaction.created
          ?.toDate()
          .toLocaleDateString('en-us', dateStringOptions),
        description: transaction.description,
        status: transaction.status,
        amount,
        currency: transaction.currency,
        type: transaction.type,
        approved: transaction.approved,
        handleSelectTransaction,
        orderFulfillments,
        shipping: transaction.shipping,
      }
    })
    Promise.all(getData)
      .then(data => {
        setFormattedData(data)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [dateStringOptions, transactions])

  if (!transactions) return null

  if (isLoading) return <PerkLoader />

  return (
    <>
      <Table
        size={isMobile ? 'small' : 'large'}
        scroll={{ x: true }}
        dataSource={formattedData}
        columns={columns}
        onRow={({ handleSelectTransaction }) => ({
          style: { cursor: 'pointer' },
          onClick: handleSelectTransaction,
        })}
        pagination={tablePaginationSettings}
      />
      {selectedTransaction && (
        <TransactionDrawer
          key={selectedTransaction.id}
          transaction={selectedTransaction}
          onCloseComplete={() => setSelectedTransaction(undefined)}
        />
      )}
    </>
  )
}
