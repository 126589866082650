import { ManageMemberSidesheet } from 'components'
import { Avatar, Heading, Link, Pane, Table, Text } from 'evergreen-ui'
import { Individual } from 'gen/perkup/v1/individual_pb'
import { useState } from 'react'
import { buildFullName } from 'utils'

export function ProgramMemberRow(props: { individual: Individual }) {
  const { individual } = props
  const { id, firstName, lastName, email } = individual

  const [showMemberSidesheet, setShowMemberSidesheet] = useState(false)

  // Default to email if individual doesn't have a full name
  const displayName =
    buildFullName({
      firstName,
      lastName,
    }) || individual.email

  return (
    <>
      <Table.Row
        key={id}
        height={64}
        onSelect={() => setShowMemberSidesheet(true)}
        isSelectable
      >
        <Table.Cell>
          <Avatar name={displayName} size={32} marginRight={16} />
          <Pane display="flex" flexDirection="column">
            <Heading
              size={500}
              marginBottom={4}
              style={{ fontWeight: 500 }}
              is={Link}
            >
              {displayName}
            </Heading>

            <Text color="muted">{email}</Text>
          </Pane>
        </Table.Cell>
      </Table.Row>
      <ManageMemberSidesheet
        isShown={showMemberSidesheet}
        onCloseComplete={() => {
          setShowMemberSidesheet(false)
        }}
        id={id}
      />
    </>
  )
}
