import { Flex } from 'antd'
import DatePicker from 'antd/es/date-picker'
import { FORM_INPUT_MAX_WIDTH } from 'constants/newReward/layout'
import dayjs, { Dayjs } from 'dayjs'
import { Pane, Strong } from 'evergreen-ui'

export default function ExpiredForm({
  expiryDate,
  setExpiryDate,
}: {
  expiryDate: Dayjs | undefined
  setExpiryDate: (newDate: Dayjs | undefined) => void
}) {
  // Can not select days before today
  const disabledDate = (current: Dayjs) => {
    return current < dayjs().subtract(1, 'days')
  }

  const handleDateChange = (newDate: Dayjs | null) => {
    // Ant design sets value to null if the date field
    if (!newDate) {
      setExpiryDate(undefined)
    } else {
      setExpiryDate(newDate)
    }
  }

  return (
    <Flex vertical gap={8}>
      <Strong>Expiry</Strong>

      <Pane width="100%" maxWidth={FORM_INPUT_MAX_WIDTH}>
        <DatePicker
          format="MMM D, YYYY"
          placeholder="Optional"
          placement="topRight"
          value={expiryDate}
          disabledDate={disabledDate}
          onChange={handleDateChange}
        />
      </Pane>
    </Flex>
  )
}
