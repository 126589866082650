import { OrgContext } from 'context'
import { useIndividualRole } from 'hooks/individuals/useIndividualRole'
import { useContext } from 'react'

export const useCanCreateAccountInvoices = () => {
  const { settings } = useContext(OrgContext)
  const { isManager, isAdmin } = useIndividualRole()
  const { hideManagerInvoicing } = settings || {}

  return isAdmin || (isManager && !hideManagerInvoicing)
}
