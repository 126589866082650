import { Segmented } from 'antd'
import { CUSTOM_INPUT_KEY } from 'constants/keys'
import { Text } from 'evergreen-ui'
import { useState } from 'react'
import { QuantityPicker } from './QuantityPicker'

enum ExpiryOptions {
  NO_EXPIRY = 'NO-EXPIRY',
  CUSTOME_EXPIRY = 'CUSTOME-EXPIRY',
}

const EXPIRY_OPTIONS = [
  {
    key: 30,
    label: '30 days',
  },
  {
    key: 60,
    label: '60 days',
  },
  {
    key: 90,
    label: '90 days',
  },
  {
    key: 180,
    label: '180 days',
  },
  {
    key: 365,
    label: '365 days',
  },
  {
    key: CUSTOM_INPUT_KEY,
    label: 'Custom',
  },
]

export function ExpiryDaysSegment({
  currentExpiryDays,
  onConfirmDays,
}: {
  currentExpiryDays: number | undefined
  onConfirmDays: (days: number) => void
}) {
  const [currentSegment, setCurrentSegment] = useState<ExpiryOptions>(
    !currentExpiryDays ? ExpiryOptions.NO_EXPIRY : ExpiryOptions.CUSTOME_EXPIRY
  )
  const [customExpiryDays, setCustomExpiredDays] = useState<number>(
    currentExpiryDays || 30
  )
  const noExpiryIsSelected = currentSegment === ExpiryOptions.NO_EXPIRY

  const handleChangeSegment = (value: ExpiryOptions) => {
    if (value === ExpiryOptions.NO_EXPIRY) {
      onConfirmDays(0)
      setCurrentSegment(ExpiryOptions.NO_EXPIRY)
    } else {
      onConfirmDays(customExpiryDays)
      setCurrentSegment(ExpiryOptions.CUSTOME_EXPIRY)
    }
  }

  const handleUpdateExpiryDays = (days: number) => {
    setCustomExpiredDays(days)
    onConfirmDays(days)
  }

  return (
    <Segmented
      className="small-custom-segment"
      size="large"
      value={currentSegment}
      onChange={handleChangeSegment}
      options={[
        {
          label: <Text height="100%">No expiry</Text>,
          value: ExpiryOptions.NO_EXPIRY,
        },
        {
          label: (
            <QuantityPicker
              currentQuantity={customExpiryDays}
              quanitityOptions={EXPIRY_OPTIONS}
              onQuantityChange={handleUpdateExpiryDays}
              customQuantityMax={365}
              suffix="days"
              isStatic={noExpiryIsSelected}
            />
          ),
          value: ExpiryOptions.CUSTOME_EXPIRY,
        },
      ]}
    />
  )
}
