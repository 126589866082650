import { captureException } from '@sentry/react'
import { Select } from 'antd'
import { currencies } from 'constants/currencies'
import { OrgContext } from 'context'
import { Text, toaster } from 'evergreen-ui'
import { doc, getFirestore, setDoc } from 'firebase/firestore'
import { Organization } from 'gen/perkup/v1/organization_pb'
import toUpper from 'lodash-es/toUpper'
import { useContext } from 'react'
import { toSentry } from 'utils'
import { converter } from 'utils/firestore'

export function ChangeCompanyCurrency() {
  const { displayCurrency, id } = useContext(OrgContext)

  const handleCurrencyChange = (value: string) => {
    const db = getFirestore()
    const docRef = doc(db, `organizations/${id}`).withConverter(
      converter(Organization)
    )
    setDoc(docRef, { displayCurrency: value }, { merge: true }).catch(error => {
      console.error(error)
      captureException(toSentry(error), {
        contexts: {
          updateCompanyCurrency: { currency: value },
        },
      })
    })
    toaster.success(`Changed currency to ${toUpper(value)}`)
  }

  const selectedurrency = displayCurrency || 'usd'
  return (
    <Select onChange={handleCurrencyChange} value={selectedurrency}>
      {currencies.map(value => {
        return (
          <Select.Option key={value.value} value={value.value}>
            <Text>{value.label.toUpperCase()}</Text>
          </Select.Option>
        )
      })}
    </Select>
  )
}
