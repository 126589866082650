import { ApiOutlined, MonitorOutlined } from '@ant-design/icons'
import { useCubeQuery } from '@cubejs-client/react'
import { ErrorBoundary } from '@sentry/react'
import { Select, Skeleton } from 'antd'
import {
  PerkEmpty,
  PerkLoader,
  PerkScrollbars,
  ProductThumbnail,
} from 'components'
import { Heading, Text } from 'evergreen-ui'
import { useHasMounted, useInfiniteScroll } from 'hooks'
import { useState } from 'react'
import { useInsightsContext } from './insights-context'

type ProductType = 'swag' | 'publicGift' | null

function Error() {
  return (
    <div className="px-4 lg:px-6">
      <PerkEmpty
        iconNode={
          <ApiOutlined
            className="text-muted-foreground"
            style={{ fontSize: 32 }}
          />
        }
        header="Oops! Something went wrong"
        description="We couldn't load your data. Please try again later."
      />
    </div>
  )
}

function TopVariantsComponent({ productType }: { productType: ProductType }) {
  const hasMounted = useHasMounted()
  const { dateRange, insightsGlobalFilters } = useInsightsContext()
  const { currentLimit, sentinelRef, isFirstPage } = useInfiniteScroll()

  const filters = productType
    ? [
        {
          member: 'topVariants.productType',
          operator: 'equals',
          values: [productType],
        } as const,
      ]
    : []

  const { resultSet, isLoading, error } = useCubeQuery(
    {
      limit: currentLimit,
      measures: ['topVariants.count'],
      dimensions: [
        'topVariants.productName',
        'topVariants.imageUrl',
        'topVariants.price',
      ],
      filters: [...filters, ...insightsGlobalFilters],
      timeDimensions: [
        {
          dimension: 'topVariants.orders_created',
          dateRange,
        },
      ],
      order: {
        'topVariants.count': 'desc',
      },
    },
    {
      resetResultSetOnChange: false,
    }
  )

  if ((isLoading && isFirstPage) || !hasMounted) {
    return (
      <PerkScrollbars style={{ maxHeight: '100%' }}>
        <div className="px-4 lg:px-6">
          <Skeleton active paragraph={{ rows: 12 }} />
        </div>
      </PerkScrollbars>
    )
  }

  if (error || !resultSet) {
    return <Error />
  }

  if (resultSet.rawData().length === 0) {
    return (
      <div className="px-4 lg:px-6">
        <PerkEmpty
          iconNode={
            <MonitorOutlined
              className="text-muted-foreground"
              style={{ fontSize: 32 }}
            />
          }
          header="Nothing found"
          description="Try a different date range or filter."
        />
      </div>
    )
  }

  return (
    <PerkScrollbars style={{ maxHeight: '100%' }}>
      <div className="flex flex-col gap-6 px-4 lg:px-6">
        {resultSet.rawData().map((row, idx) => {
          const {
            'topVariants.productName': productName,
            'topVariants.imageUrl': imageUrl,
            'topVariants.count': count,
            'topVariants.price': price,
          } = row

          return (
            <article
              key={`${productName}-${count}`}
              className="flex gap-2 items-center"
            >
              <Text className="min-w-4">{idx + 1}</Text>
              <ProductThumbnail>
                <ProductThumbnail.Image imageUrl={imageUrl as string} />

                <div className="flex flex-col">
                  <ProductThumbnail.Name>{productName}</ProductThumbnail.Name>
                  <ProductThumbnail.Price price={Number(price || 0)} />
                </div>
              </ProductThumbnail>
              <Text className="flex-1 text-end">
                {Number(count || 0).toLocaleString()}
              </Text>
            </article>
          )
        })}
        <div
          className="pb-6"
          style={
            !isLoading && currentLimit > resultSet.rawData().length
              ? {
                  display: 'none',
                }
              : {}
          }
          ref={sentinelRef}
        >
          <PerkLoader marginY={0} />
        </div>
      </div>
    </PerkScrollbars>
  )
}

export function TopVariants() {
  const [productType, setProductType] = useState<ProductType>(null)
  const { dateRange, insightsGlobalFilters } = useInsightsContext()

  const heading = (
    <div className="flex items-center justify-between gap-2 px-4 lg:px-6">
      <Heading>Most popular items</Heading>
      <div className="flex gap-2 items-center">
        <Text>Show</Text>
        <Select
          className="min-w-20"
          size="small"
          variant="borderless"
          placement="bottomRight"
          options={[
            { label: 'All', value: null },
            { label: 'Swag', value: 'swag' },
            { label: 'Gifts', value: 'publicGift' },
          ]}
          value={productType}
          dropdownStyle={{
            minWidth: 'max-content',
          }}
          onChange={value => {
            setProductType(value)
          }}
        />
      </div>
    </div>
  )

  return (
    <section className="flex flex-col gap-6 w-full h-full rounded-lg border border-muted py-4">
      {heading}
      <ErrorBoundary fallback={<Error />}>
        <TopVariantsComponent
          productType={productType}
          key={JSON.stringify({
            dateRange,
            insightsGlobalFilters,
            productType,
          })}
        />
      </ErrorBoundary>
    </section>
  )
}
