import { EditOutlined, GlobalOutlined } from '@ant-design/icons'
import { Button, Flex, Popover } from 'antd'
import { Text } from 'evergreen-ui'
import { useState } from 'react'
import { Mode } from 'types/edit'
import { buildCountries } from 'utils'
import { CountryMultiSelect } from './CountryMultiSelect'
import { CountryOverflowMenu } from './country-overflow-menu'

function StaticCountriesWidget({
  title,
  withCountriesSuffix,
  countryIso3s,
}: {
  title: string
  withCountriesSuffix?: boolean
  countryIso3s: string[]
}) {
  if (!countryIso3s.length)
    return (
      <Flex gap={4} align="center">
        <GlobalOutlined style={{ fontSize: 18, opacity: '0.45' }} />
        <Text cursor="default">All {withCountriesSuffix && 'countries'}</Text>
      </Flex>
    )

  return (
    <Popover
      placement="bottom"
      title={
        <Text size={300} color="muted">
          {title}
        </Text>
      }
      content={<CountryOverflowMenu countries={buildCountries(countryIso3s)} />}
    >
      <Flex gap={4} align="center">
        <GlobalOutlined style={{ fontSize: 18, opacity: '0.45' }} />
        <Text cursor="default">
          {countryIso3s.length}{' '}
          {withCountriesSuffix &&
            (countryIso3s.length === 1 ? 'country' : 'countries')}
        </Text>
      </Flex>
    </Popover>
  )
}

export function CountriesWidget({
  title,
  countryIso3s,
  onCountriesChange,
  withCountriesSuffix = false,
  mode = Mode.view,
}: {
  title: string
  countryIso3s: string[]
  onCountriesChange?: (countries: string[]) => void
  withCountriesSuffix?: boolean
  mode?: Mode
}) {
  const [isMultiSelectFocused, setIsMultiSelectFocused] = useState(false)

  const countriesDisplayString =
    countryIso3s.length === 0
      ? 'All countries'
      : `${countryIso3s.length}  ${countryIso3s.length === 1 ? 'country' : 'countries'}`

  if (mode === Mode.view) {
    return (
      <StaticCountriesWidget
        title={title}
        withCountriesSuffix={withCountriesSuffix}
        countryIso3s={countryIso3s}
      />
    )
  }

  if (isMultiSelectFocused && onCountriesChange) {
    return (
      <div onBlur={() => setIsMultiSelectFocused(false)}>
        <CountryMultiSelect
          maxTagCount={1}
          onSubmitCountries={onCountriesChange}
          selectedCountries={countryIso3s}
        />
      </div>
    )
  }

  return (
    <Button type="text" onClick={() => setIsMultiSelectFocused(true)}>
      <GlobalOutlined style={{ opacity: '0.45' }} />
      {countriesDisplayString}
      <EditOutlined />
    </Button>
  )
}
