import {
  ActivatedPerkCard,
  IsAdmin,
  IsManager,
  withOrgSidebar,
  withOrgUserSidebar,
  withShortNav,
} from 'components'
import CardDetails from 'components/PerkCard/CardDetails'
import { CREATE_ORG } from 'constants/params'
import * as ROUTES from 'constants/routes'
import { DEFAULT_ROUTES } from 'constants/routes'
import { IndividualContext, UserContext } from 'context'
import { Individual_Status } from 'gen/perkup/v1/individual_pb'
import { useIndividualRole } from 'hooks'
import * as P from 'pages'
import { useContext, useMemo } from 'react'
import { isMobile } from 'react-device-detect'
import { Helmet } from 'react-helmet-async'
import {
  Navigate,
  Outlet,
  Route,
  createRoutesFromElements,
  useLocation,
  useOutletContext,
  useSearchParams,
} from 'react-router-dom'
import { getHeadTitle } from 'utils'

const Dashboard = withOrgUserSidebar(P.Dashboard)
const Transactions = withOrgUserSidebar(P.UserTransactions)
const Account = withOrgUserSidebar(P.Account)
const CreateFirstReward = withShortNav(P.FirstReward)
const CardDetailsComponent = withOrgUserSidebar(CardDetails)

// Admin routes
const PerkProgram = IsAdmin(P.PerkProgram)
const OrgTransactions = IsAdmin(P.OrgTransactions)
const Billing = IsAdmin(P.Billing)
const CompanyBranding = IsAdmin(P.CompanyBranding)
const OrgSettings = IsAdmin(P.OrgSettingsPage)
const Integrations = IsAdmin(P.Integrations)
const FirstReward = IsAdmin(CreateFirstReward)
const PerkProgramDetails = IsAdmin(P.PerkProgramDetails)
const Templates = IsAdmin(P.Templates)

// Admin or Manager routes
const OrgAccounts = IsManager(P.OrgAccounts)
const SpotRewards = IsManager(P.SpotRewards)
const Rewards = IsManager(P.Rewards)
const NewReward = IsManager(P.NewReward)
const ProgramDetails = IsManager(P.ProgramDetails)
const Directory = IsManager(P.Directory)
const BrowseGifts = IsManager(P.BrowseGifts)
const OrgSwag = IsManager(P.OrgSwag)

const AdminLayout = IsAdmin(withOrgSidebar(() => <Outlet />))
const ManagerLayout = IsManager(withOrgSidebar(() => <Outlet />))

function Home() {
  const { isManager, isAdmin } = useIndividualRole()
  const isAdminOrManager = isManager || isAdmin

  const defaultToOrgView = !isMobile && isAdminOrManager

  if (defaultToOrgView) {
    return <SpotRewards />
  }

  return <Dashboard />
}

function ProtectedRoot() {
  const { isAuthenticated } = useOutletContext() as {
    isAuthenticated: boolean
  }

  const user = useContext(UserContext)
  const individual = useContext(IndividualContext)
  const { isManager, isAdmin } = useIndividualRole()
  const isAdminOrManager = isManager || isAdmin
  const { pathname } = useLocation()

  const [searchParams] = useSearchParams()
  const createOrg = searchParams.get(CREATE_ORG) === true.toString()

  const isAuthPage = useMemo(() => {
    return [
      ROUTES.SIGN_IN,
      ROUTES.SIGN_UP,
      ROUTES.VERIFY_EMAIL,
      ROUTES.EMAIL_CONFIRMATION,
    ].includes(pathname)
  }, [pathname])

  if (!isAuthenticated) {
    return null
  }

  const notRewardsPreviewPage = !pathname.includes(ROUTES.PREVIEW_REWARDS)
  const incompleteUserOnboarding = !user.onboarding?.complete

  const isRemovedIndividual = individual?.status === Individual_Status.removed

  const defaultToOrgView = !isMobile && isAdminOrManager

  if (incompleteUserOnboarding && notRewardsPreviewPage) {
    return <P.UserOnboarding />
  }

  if (createOrg) {
    return <P.CreateOrg />
  }

  if (!user.currentOrganization || isRemovedIndividual) {
    return <P.NoOrg />
  }

  if (isAuthPage) {
    const startingRoute = defaultToOrgView
      ? DEFAULT_ROUTES.ORGANIZATION.REWARDS.SPOT
      : ROUTES.HOME

    return <Navigate to={startingRoute} />
  }

  return <Outlet />
}

function PageTitle() {
  const { pathname } = useLocation()
  return (
    <Helmet>
      <title>{getHeadTitle(pathname)}</title>
    </Helmet>
  )
}

export const appProtectedRoutes = createRoutesFromElements(
  <Route
    path="/"
    element={
      <>
        <PageTitle />
        <ProtectedRoot />
      </>
    }
  >
    {/* USER ROUTES */}
    <Route path={ROUTES.HOME} Component={Home} />
    <Route path={ROUTES.CARD} element={<Dashboard />}>
      <Route path={ROUTES.CARD_SETUP} element={<P.CardSetupDialog />} />
      <Route path={ROUTES.CARD_ACTIVATED} element={<ActivatedPerkCard />} />
    </Route>
    <Route path={`${ROUTES.AMAZON}/*`} element={<P.Amazon />} />

    <Route path={`${ROUTES.SWAG}/*`} element={<P.Swag />} />
    <Route path={`${ROUTES.PREVIEW_REWARD}/*`} element={<P.RewardPreview />} />
    <Route
      path={`${ROUTES.REWARD_ACCEPTANCE}/*`}
      element={<P.RewardAcceptance />}
    />
    <Route path={ROUTES.TRANSACTIONS} element={<Transactions />}>
      <Route path={ROUTES.TRANSACTIONS_DETAILS} element={<P.Transaction />} />
    </Route>
    <Route path={`${ROUTES.ACCOUNT}/*`} element={<Account />} />
    <Route path={ROUTES.CARD_DETAILS} element={<CardDetailsComponent />} />
    <Route path={`${ROUTES.SHOP}/*`} element={<P.Marketplace />} />

    {/* ADMIN ROUTES */}
    <Route Component={AdminLayout}>
      <Route path={`${DEFAULT_ROUTES.ORGANIZATION.INSIGHTS.ROOT}/`}>
        {P.Insights}
      </Route>
    </Route>
    {/* MANAGER ROUTES */}
    <Route Component={ManagerLayout}>
      <Route
        path={DEFAULT_ROUTES.ORGANIZATION.CUSTOMER_SUCCESS.ROOT}
        element={<P.CustomerSuccessPage />}
      />
    </Route>
    <Route path={ROUTES.FIRST_REWARD} element={<FirstReward />} />
    <Route path={ROUTES.ORGANIZATION} element={<SpotRewards />} />
    <Route
      path={DEFAULT_ROUTES.ORGANIZATION.PERK_PROGRAMS.ROOT}
      element={<PerkProgram />}
    />
    <Route
      path={DEFAULT_ROUTES.ORGANIZATION.PERK_PROGRAMS.PROGRAM_DETAILS}
      element={<PerkProgramDetails />}
    />
    <Route
      path={`${DEFAULT_ROUTES.ORGANIZATION.GIFTS.ROOT}/*`}
      element={<BrowseGifts />}
    />
    <Route
      path={DEFAULT_ROUTES.ORGANIZATION.TRANSACTIONS}
      element={<OrgTransactions />}
    />
    <Route
      path={`${DEFAULT_ROUTES.ORGANIZATION.ACCOUNTS.ROOT}/*`}
      element={<OrgAccounts />}
    />
    <Route
      path={DEFAULT_ROUTES.ORGANIZATION.INTEGRATIONS.ROOT}
      element={<Integrations />}
    />
    <Route
      path={DEFAULT_ROUTES.ORGANIZATION.BRANDING}
      element={<CompanyBranding />}
    />
    <Route
      path={`${DEFAULT_ROUTES.ORGANIZATION.SETTINGS}`}
      element={<OrgSettings />}
    />
    <Route path={DEFAULT_ROUTES.ORGANIZATION.BILLING} element={<Billing />} />

    <Route
      path={`${DEFAULT_ROUTES.ORGANIZATION.SWAG.ROOT}/*`}
      element={<OrgSwag />}
    />

    <Route
      path={`${DEFAULT_ROUTES.ORGANIZATION.ORDER.ROOT}/*`}
      element={<P.OrderProducts />}
    />

    <Route
      path={`${DEFAULT_ROUTES.ORGANIZATION.DIRECTORY.ROOT}/*`}
      element={<Directory />}
    />
    {/* ADMIN REWARD ROUTES */}
    <Route path={DEFAULT_ROUTES.ORGANIZATION.REWARDS.ROOT}>
      <Route
        path={DEFAULT_ROUTES.ORGANIZATION.REWARDS.SPOT}
        element={<SpotRewards />}
      />
      <Route
        path={DEFAULT_ROUTES.ORGANIZATION.REWARDS.REWARD}
        element={<ProgramDetails />}
      />
      <Route
        path={`${DEFAULT_ROUTES.ORGANIZATION.REWARDS.TEMPLATES.ROOT}/*`}
        element={<Templates />}
      />
      <Route
        path={`${DEFAULT_ROUTES.ORGANIZATION.REWARDS.NEW_REWARD}/*`}
        element={<NewReward />}
      />
      <Route
        path={`${DEFAULT_ROUTES.ORGANIZATION.REWARDS.BIRTHDAYS.ROOT}/*`}
        element={<Rewards />}
      />
      <Route
        path={`${DEFAULT_ROUTES.ORGANIZATION.REWARDS.ANNIVERSARIES.ROOT}/*`}
        element={<Rewards />}
      />
      <Route
        path={`${DEFAULT_ROUTES.ORGANIZATION.REWARDS.NEW_HIRE.ROOT}/*`}
        element={<Rewards />}
      />
    </Route>

    <Route path="*" element={<P.NoMatch404 />} />
  </Route>,
  [1]
)
