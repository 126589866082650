import { PriceRange } from './BrowseGifts'

export interface PriceOption {
  name: string
  value: PriceRange
  option: string
}

export enum FulfilledBy {
  AMAZON = 'amazon',
  SHOPIFY = 'shopify',
  MANUAL = 'manual',
}

export enum ConvertableTo {
  CONVERTABLE_TO_UNSPECIFIED = 'CONVERTABLE_TO_UNSPECIFIED',
  gift = 'gift',
  cash = 'cash',
}

export const ALL_CONVERTABLE_TO_OPTIONS = [
  ConvertableTo.gift,
  ConvertableTo.cash,
]
