import { IndividualsSelection } from 'components'
import {
  ALGOLIA_INDIVIDUALS_INDEX,
  STATUS_NONE_OR_INVITED_OR_ACTIVE,
} from 'constants/algolia'
import useIds from 'hooks/useIds'
import {
  InstantSearch,
  useClearRefinements,
  useInstantSearch,
  useRefinementList,
} from 'react-instantsearch'
import { individualsSearchClient } from 'services/algolia'

export function FilterByManager() {
  const { orgId } = useIds()
  const attribute = 'managerId'
  const { refine } = useRefinementList({
    attribute,
    operator: 'and',
    limit: 100,
  })
  const { refine: clearRefine } = useClearRefinements({
    includedAttributes: [attribute],
  })

  const { indexUiState } = useInstantSearch()

  const searchClient = individualsSearchClient(orgId)

  const searchFilter = `(${STATUS_NONE_OR_INVITED_OR_ACTIVE}) AND (role:admin OR role:manager)`

  const toggledManagerId = indexUiState?.refinementList?.managerId
    ? indexUiState?.refinementList?.managerId
    : []

  return (
    <InstantSearch
      indexName={ALGOLIA_INDIVIDUALS_INDEX}
      searchClient={searchClient}
    >
      <IndividualsSelection
        width={208}
        mode="single"
        onIndividualClick={selectedIndividualIds => {
          clearRefine()
          if (selectedIndividualIds?.[0]) refine(selectedIndividualIds[0])
        }}
        defaultLabel="Select Manager"
        filters={searchFilter}
        selectedIndividualIds={toggledManagerId}
      />
    </InstantSearch>
  )
}
