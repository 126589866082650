import { toaster } from 'evergreen-ui'
import { Program, Program_RecipientFilters } from 'gen/perkup/v1/program_pb'

import { GetOrgAccountById } from 'api/databaseCalls'
import { OrgContext } from 'context'
import { PartialWithFieldValue } from 'firebase/firestore'
import { Account } from 'gen/perkup/v1/account_pb'
import useIds from 'hooks/useIds'
import { useCallback, useContext, useEffect, useState } from 'react'
import { parseDraftData, saveProgram } from '../utils/programs'

export function useFormatDraftProgram({ program }: { program: Program }) {
  const org = useContext(OrgContext)

  const { orgId } = useIds()
  const programId = program.id

  const [selectedAccount, setSelectedAccount] = useState<Account | undefined>()

  const [isLoadingSave, setIsLoadingSave] = useState(false)

  const [hasLoadedDraftProgram, setHasLoadedDraftProgram] = useState(false)

  const [recipientEmails, setRecipientEmails] = useState<string[]>([])

  const [recipientIndividualIds, setRecipientIndividualIds] = useState<
    string[]
  >([])

  const [recipientFilters, setRecipientFilters] =
    useState<Program_RecipientFilters>()

  // Load existing draft program
  useEffect(() => {
    if (hasLoadedDraftProgram) return

    if (program) {
      const promises = []

      const draftData = parseDraftData({
        program,
      })

      if (draftData?.emails) setRecipientEmails(draftData.emails)
      if (draftData?.individuals) {
        setRecipientIndividualIds(draftData.individuals)
      }

      if (draftData?.recipientFilters) {
        setRecipientFilters(draftData.recipientFilters)
      }

      if (program.accountId) {
        promises.push(
          GetOrgAccountById({
            orgId,
            accountId: program.accountId,
          }).then(account => {
            setSelectedAccount(account)
          })
        )
      }

      Promise.all(promises).finally(() => {
        setHasLoadedDraftProgram(true)
      })
    } else {
      toaster.warning('Error loading draft program')
    }
  }, [hasLoadedDraftProgram, org.name, orgId, program, programId])

  const handleProgramSave = useCallback(
    async (updatedProgram?: PartialWithFieldValue<Program>) => {
      // Need this debounce - our gift saves via a callback - so we need to wait for the draft data to load
      if (!hasLoadedDraftProgram) return
      setIsLoadingSave(true)

      // Update existing draft program
      await saveProgram({
        updatedProgram,
        orgId,
        programId,
      })

      setIsLoadingSave(false)
    },
    [hasLoadedDraftProgram, orgId, programId]
  )

  return {
    selectedAccount,
    setSelectedAccount,
    recipientEmails,
    setRecipientEmails,
    recipientIndividualIds,
    setRecipientIndividualIds,
    handleProgramSave,
    isLoadingSave,
    hasLoadedDraftProgram,
    recipientFilters,
    setRecipientFilters,
  }
}
