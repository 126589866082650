import { Image, Tooltip } from 'antd'
import { ReactComponent as ChipIcon } from 'assets/noun_SIM Card_190199.svg'
// Assets
import visaLogo from 'assets/vbm_wht01.png'
import { CompanyDisplay } from 'components/Navs/CompanyDisplay'
import { UserContext } from 'context'
import { Pane, Text, useTheme } from 'evergreen-ui'
import { useContext } from 'react'
import { isMobile } from 'react-device-detect'
import { fontFamilies } from 'themes'
import { copyToClipboard } from 'utils'

function VirtualCard({
  last4,
  number,
  showCardNumber = false,
}: {
  last4?: string
  number?: string
  showCardNumber?: boolean
}) {
  const user = useContext(UserContext)
  const theme = useTheme()

  // Styling
  const cardWidth = isMobile ? '100%' : 350
  const cardHeight = isMobile ? 192 : 204
  const dotStyles = {
    size: 300,
    letterSpacing: 4,
    marginLeft: 4,
    fontSize: 10,
  }

  // Card details
  const { firstName, lastName } = user?.profile || {}
  const name = `${firstName} ${lastName}`

  // Styles
  const blackCard = '#0a0b0d'

  return (
    <Pane display="flex" justifyContent="center">
      <Pane
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        className="text-light"
        background={blackCard}
        borderRadius={8}
        boxShadow="3px 5px 20px 20px rgb(9 30 48 / 0.05)"
        height={cardHeight}
        maxWidth={350}
        width={cardWidth}
        padding={20}
      >
        {/* Card Header */}
        <Pane>
          <CompanyDisplay whiteLogo />
        </Pane>
        <Pane display="flex" flexDirection="column" justifyContent="center">
          <Pane display="flex" alignItems="center" marginBottom={8}>
            <ChipIcon
              width={isMobile ? 40 : 48}
              height={isMobile ? 40 : 48}
              fill={theme?.colors?.gray300}
              style={{ marginRight: 4 }}
            />
          </Pane>
          {/* Card Number */}
          <Pane
            display="flex"
            alignItems="center"
            className={showCardNumber ? 'card-details' : ''}
          >
            {showCardNumber && number ? (
              <Tooltip title="Copy Card Number">
                <Text
                  fontFamily={fontFamilies.digits}
                  fontSize={24}
                  fontWeight={700}
                  color="white"
                  onClick={() => copyToClipboard(number, 'card number')}
                >
                  {number.match(/.{1,4}/g)?.join(' ')}
                </Text>
              </Tooltip>
            ) : (
              <>
                <Text {...dotStyles}>
                  &#9679;&#9679;&#9679;&#9679;&nbsp;&#9679;&#9679;&#9679;&#9679;&nbsp;&#9679;&#9679;&#9679;&#9679;&nbsp;
                </Text>
                {last4 ? (
                  <Text
                    fontFamily={fontFamilies.digits}
                    fontSize={24}
                    fontWeight={700}
                    color="white"
                  >
                    {last4}
                  </Text>
                ) : (
                  <Text {...dotStyles}>&#9679;&#9679;&#9679;&#9679;</Text>
                )}
              </>
            )}
          </Pane>
        </Pane>
        {/* Bottom Section */}
        <Pane
          display="flex"
          justifyContent="space-between"
          alignItems="flex-end"
        >
          <Text color="white" className="text-uppercase" fontWeight={600}>
            {name}
          </Text>
          <Image src={visaLogo} width={80} preview={false} />
        </Pane>
      </Pane>
    </Pane>
  )
}

export default VirtualCard
