import { sampleBanners, sampleMessages } from 'constants/newReward/emails'
import { defaultOccasions } from 'constants/newReward/occasions'
import {
  RewardUseCase,
  RewardUseCases,
} from 'constants/newReward/rewardUseCases'
import placidData from 'constants/placid/placid-data.json'
import { COLOR_OPTION, SIZE_OPTION } from 'constants/productVariants'
import { toaster } from 'evergreen-ui'
import { ProductVariant } from 'gen/perkup/v1/product_variant_pb'
import { ProgramOccasion } from 'gen/perkup/v1/program_pb'
import { find, groupBy, intersectionWith, keys, sample } from 'lodash-es'
import { SelectedItems } from 'types/Items'
import { afterLastSlash, buildFullName } from 'utils'
import { flattenItems } from 'utils/items'
import { EmailFormData } from '../types/forms'
import { getPlacidTemplateIdsFromUseCases } from './program-templates'

export function generateRandomEmail(orgName: string): EmailFormData {
  return {
    title: 'A reward for you 🎉',
    banner: sample(sampleBanners)!, // Sample banners will never be empty
    note: sample(sampleMessages)!, // Sample messages will never be empty
    fromName: orgName,
  }
}

export function validateFullName({
  firstName,
  lastName,
  throwWarnings = true,
}: {
  firstName?: string
  lastName?: string
  throwWarnings?: boolean
}) {
  if (firstName && firstName.length <= 1) {
    if (throwWarnings) {
      toaster.warning('First name must be at least 2 characters', {
        id: firstName,
      })
    }
    return false
  }

  if (lastName && lastName.length <= 1) {
    if (throwWarnings) {
      toaster.warning('Last name must be at least 2 characters', {
        id: lastName,
      })
    }
    return false
  }

  const userFullName = buildFullName({
    firstName,
    lastName,
  })

  if (!userFullName) {
    if (throwWarnings) {
      toaster.warning('Please enter a valid first and last name')
    }
    return false
  }
  const maxNameLength = 24
  if (userFullName && userFullName?.length > maxNameLength) {
    if (throwWarnings) {
      toaster.warning(
        `${userFullName} must be less than 23 characters due to shipping carrier restrictions`,
        {
          id: userFullName,
        }
      )
    }
    return false
  }

  return true
}

export const getUseCasesFromOccasion = (
  occasion?: ProgramOccasion
): (keyof typeof RewardUseCases)[] => {
  if (!occasion) return []
  const currentOccasion = defaultOccasions.find(
    defaultOccasion => defaultOccasion.occasion === occasion
  )

  return currentOccasion?.useCases || []
}

export const getDefaultSearchQueryFromOccasion = ({
  occasion,
}: {
  occasion?: ProgramOccasion
}) => {
  if (!occasion) return ''
  const useCases = getUseCasesFromOccasion(occasion)
  const query = RewardUseCases[useCases[0]]?.details.searchQuery || ''
  return query
}

export const getDirectMailOrderInfo = ({
  items,
  selectedProductVariants,
  itemKey,
}: {
  selectedProductVariants: ProductVariant[]
  items: SelectedItems
  itemKey: string
}) => {
  const flattenedItems = flattenItems({ items })

  const lineItems = items.get(itemKey) || []
  const variants = intersectionWith(
    selectedProductVariants,
    lineItems || [],
    (pv, item) => afterLastSlash(item.productVariantId) === pv.id
  )
  const defaultVariant = variants[0]
  const allSizedVariants = groupBy(
    variants,
    variant => variant.options[SIZE_OPTION]?.value
  )

  const sizesSelected = keys(allSizedVariants)

  const hasQuantities = intersectionWith(
    lineItems,
    variants,
    (item, variant) =>
      afterLastSlash(variant.id) === afterLastSlash(item.productVariantId)
  ).some(item => item.quantity > 0)

  const lineItemTotal = sizesSelected.reduce((acc, size) => {
    const sizeVariant = allSizedVariants[size][0]

    const sizeQuantity = flattenedItems.find(
      item => afterLastSlash(item.productVariantId) === sizeVariant.id
    )?.quantity
    return acc + Number(sizeVariant.amount) * Number(sizeQuantity)
  }, 0)

  const colorValue = defaultVariant?.options[COLOR_OPTION]?.value

  const itemDisplayName = colorValue
    ? `${defaultVariant?.productName} - ${defaultVariant?.options[COLOR_OPTION]?.value}`
    : defaultVariant?.productName

  return {
    hasQuantities,
    sizesSelected,
    allSizedVariants,
    defaultVariant,
    itemDisplayName,
    lineItemTotal,
    lineItems,
  }
}

export const getBrandedImagesByUseCases = ({
  useCases,
}: {
  useCases: RewardUseCase[]
}) => {
  return getPlacidTemplateIdsFromUseCases({ useCases })
}

export const getPlacidDataForBrandedImage = ({ image }: { image: string }) => {
  return find(placidData, plcd => image.includes(plcd.uuid))
}

export const getDefaultUseCaseForPlacidData = (
  placidData:
    | {
        uuid: string
        tags: string[]
        title: string
      }
    | undefined
) => {
  const placidTags = placidData?.tags
  const defaultUseCase = find(RewardUseCases, rewardUseCase => {
    const categories = rewardUseCase.details.placidTags

    return categories.some(category => placidTags?.includes(category))
  })

  return defaultUseCase
}

export const getDefaultUseCaseForBrandedImage = (props: { image: string }) => {
  const placidData = getPlacidDataForBrandedImage(props)

  return getDefaultUseCaseForPlacidData(placidData)
}

export const getRewardSendDisabledAlert = ({
  isZeroDollarBudget,
  checkoutWithStripe,
  noRecipients,
  invoiceCreationDisabled,
}: {
  isZeroDollarBudget: boolean
  checkoutWithStripe: boolean
  noRecipients: boolean
  invoiceCreationDisabled: boolean
}) => {
  if (noRecipients && checkoutWithStripe) {
    return 'Please return to step 4 and add recipients.'
  }
  if (checkoutWithStripe && invoiceCreationDisabled) {
    return 'You do not have permission to create invoices. Please contact your organization administrator.'
  }
  if (isZeroDollarBudget) {
    return 'Reward budget cannot be $0, please return to step 3 and update the reward.'
  }
  return undefined
}
