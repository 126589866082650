export enum ProductTypes {
  COMPANY = 'company',
  DIRECTORY = 'directory',
  INDIVIDUAL = 'individual',
  EMPLOYMENT = 'employment',
}

export const products: ProductTypes[] = [
  ProductTypes.COMPANY,
  ProductTypes.DIRECTORY,
  ProductTypes.INDIVIDUAL,
  ProductTypes.EMPLOYMENT,
]

export const finchErrors = [
  'reauthenticate_user',
  'authentication_error',
  'invalid_token_error',
]
