import { InfiniteProgramsList } from 'features'
import {
  ProgramOccasion,
  ProgramStatus,
  ProgramType,
} from 'gen/perkup/v1/program_pb'
import { RangeValue } from 'types/RewardsDate'
import { RuleType, RuleTypes } from 'types/rules'

export default function SentRewards({
  ruleType,
  dates,
}: {
  ruleType: RuleType
  dates: RangeValue
}) {
  const determineOccasion = () => {
    if (ruleType === RuleTypes.birthdays) {
      return ProgramOccasion.birthday
    }
    if (ruleType === RuleTypes.anniversaries) {
      return ProgramOccasion.workAnniversary
    }
    return ProgramOccasion.newEmployee
  }

  const filtersToApply = {
    occasion: determineOccasion(),
    types: [
      ruleType === RuleTypes.birthdays
        ? ProgramType.birthdays
        : ProgramType.anniversaries,
    ],
    status: ProgramStatus.active,
    dates,
  }

  return (
    <InfiniteProgramsList
      numProgramsOnMount={10}
      numProgramsOnPagination={5}
      filters={filtersToApply}
    />
  )
}
