import { Pane } from 'evergreen-ui'
import { BrandedTag } from './BrandedTag'

export function BrandedTags({ coreValues }: { coreValues: string[] }) {
  return (
    <Pane display="flex" flexWrap="wrap" rowGap={8}>
      {coreValues.map(value => (
        <BrandedTag key={value}>{value}</BrandedTag>
      ))}
    </Pane>
  )
}
