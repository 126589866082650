import {
  MailOutlined,
  ReadOutlined,
  VideoCameraOutlined,
} from '@ant-design/icons'
import { Avatar, Button, Flex, Tooltip } from 'antd'
import { ATLAS_LOGO } from 'assets/contentful'
import { PerkImage, PerkLink } from 'components'
import { CSM_DETAILS_MAP } from 'constants/organizations'
import { OrgContext } from 'context'
import { Heading, Strong, Text } from 'evergreen-ui'
import { useIndividualRole } from 'hooks'
import { useContext } from 'react'
import { copyToClipboard, getInitials } from 'utils'

function CustomerSuccessPage() {
  const { csmEmail } = useContext(OrgContext)
  const { isManager } = useIndividualRole()

  const csm = csmEmail && !isManager ? CSM_DETAILS_MAP[csmEmail] : undefined

  return (
    <Flex vertical gap={48}>
      {csm && (
        <Flex vertical gap={32}>
          <Flex vertical gap={8}>
            <Heading size={700}>Dedicated customer success manager</Heading>
            <Text>
              Contact your CSM for swag inquiries, integrations, automations,
              gift ideas and feedback.
            </Text>
          </Flex>
          <Flex gap={16} align="center">
            <Avatar size={52} src={csm.pfp}>
              {getInitials(csm.name)}
            </Avatar>
            <Flex vertical>
              <Strong size={500}>{csm.name}</Strong>
              <PerkLink to={`mailto:${csmEmail}`}>{csmEmail}</PerkLink>
            </Flex>
          </Flex>
          <Flex gap={8}>
            <PerkLink to={`mailto:${csmEmail}`}>
              <Button icon={<MailOutlined />} type="default">
                Email
              </Button>
            </PerkLink>

            <PerkLink to={csm.cal} target="_blank" rel="noopener noreferrer">
              <Button icon={<VideoCameraOutlined />} type="primary">
                Book a meeting
              </Button>
            </PerkLink>
          </Flex>
        </Flex>
      )}
      <Flex vertical gap={32}>
        <Heading size={600}>Support for your team</Heading>

        <Flex vertical gap={40} style={{ paddingLeft: 16 }}>
          <Flex gap={16} align="center">
            <PerkImage src={ATLAS_LOGO} sizes="32px" width={32} />
            <Text size={500}>Live chat</Text>
          </Flex>
          <Flex gap={16} align="center">
            <MailOutlined style={{ fontSize: 32 }} />
            <Tooltip title="Copy email">
              <Text
                cursor="pointer"
                onClick={() => copyToClipboard('help@perkupapp.com', 'email')}
                size={500}
              >
                Email help@perkupapp.com
              </Text>
            </Tooltip>
          </Flex>
          <Flex gap={16} align="center">
            <ReadOutlined style={{ fontSize: 32 }} />
            <PerkLink
              to="https://help.perkupapp.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Text cursor="pointer" size={500}>
                Help center
              </Text>
            </PerkLink>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default CustomerSuccessPage
