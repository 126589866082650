import { captureException } from '@sentry/react'
import { MANUAL_STORE, ORDER_COMPLETED } from 'constants/events'
import { getAuth } from 'firebase/auth'
import { Orders } from 'gen/manual/v1/manual_connect'
import { CreateOrderResponse } from 'gen/manual/v1/manual_pb'
import { Item } from 'gen/perkup/v1/program_pb'
import { ShippingAddress } from 'gen/perkup/v1/root_user_pb'
import { VenueEvent_Info } from 'gen/perkup/v1/venue_event_pb'
import { createClient } from 'hooks/useClient'
import { logEvent } from 'utils'
import { toSentry } from 'utils/sentry'

export async function placeManualOrder(data: {
  firstName: string
  lastName: string
  shippingAddress: ShippingAddress
  items: Item[]
  programIds?: string[]
  shippingRateHandle?: string
  noPerkupFee?: boolean
  orgId: string
  userId: string
  venueEventInfo?: VenueEvent_Info
}): Promise<CreateOrderResponse | undefined> {
  const {
    firstName,
    lastName,
    shippingAddress,
    programIds,
    shippingRateHandle,
    noPerkupFee,
    orgId,
    userId,
    items,
    venueEventInfo,
  } = data

  try {
    const accessToken = await getAuth().currentUser?.getIdToken()
    const client = createClient(Orders)
    return await client
      .createOrder(
        {
          items,
          address: {
            firstName,
            lastName,
            ...shippingAddress,
          },
          programIds: programIds || [],
          shippingMethod: shippingRateHandle,
          noPerkupFee,
          venueEventInfo,
        },
        { headers: { Authorization: `Bearer ${accessToken}` } }
      )
      .then(res => {
        logEvent(ORDER_COMPLETED, {
          affiliation: MANUAL_STORE,
          orgId,
          userId,
          items,
        })
        return res
      })
  } catch (error) {
    console.error(error)

    captureException(toSentry(error), {
      contexts: {
        createManualOrder: {
          items,
          error,
          orgId,
          userId,
          firstName,
          lastName,
          shippingAddress,
        },
      },
    })
    throw error
  }
}
