// @generated by protoc-gen-es v1.6.0 with parameter "target=ts"
// @generated from file perkup/v1/transaction.proto (package perkup.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, protoInt64, Timestamp } from "@bufbuild/protobuf";
import { ProductVariant_SourceType } from "./product_variant_pb.js";

/**
 * Firestore /organizations/:id/users/:id/balanceTransactions/:id
 *
 * @generated from message perkup.v1.BalanceTransaction
 */
export class BalanceTransaction extends Message<BalanceTransaction> {
  /**
   * stored in firestore
   *
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string auth_id = 2;
   */
  authId = "";

  /**
   * @generated from field: google.protobuf.Timestamp created = 3;
   */
  created?: Timestamp;

  /**
   * $1 == 100 -spend +refund
   *
   * @generated from field: int64 amount = 4;
   */
  amount = protoInt64.zero;

  /**
   * @generated from field: string currency = 5;
   */
  currency = "";

  /**
   * ProviderAmount (Amazon Canada transacts in CAD)
   * 0 if "usd", $1 = 100
   *
   * @generated from field: int64 provider_amount = 6;
   */
  providerAmount = protoInt64.zero;

  /**
   * "" | "cad"
   *
   * @generated from field: string provider_currency = 7;
   */
  providerCurrency = "";

  /**
   * Name
   *   Hold for authorization
   *   (Title from order item)
   *
   * @generated from field: string name = 8;
   */
  name = "";

  /**
   * Eligible Category reference ProgramID
   *
   * @generated from field: string program_id = 9;
   */
  programId = "";

  /**
   * Amazon: (PO / OC)
   * Stripe: txn_...
   *
   * @generated from field: string source_id = 10;
   */
  sourceId = "";

  /**
   * Type
   *   issuing_authorization_hold, new stripe values
   * Amazon: amazon_transaction, amazon_refund
   *
   * @generated from field: string type = 11;
   */
  type = "";

  /**
   * amazon, stripe, manual ...
   *
   * @generated from field: perkup.v1.BalanceTransaction.Provider provider = 12;
   */
  provider = BalanceTransaction_Provider.PROVIDER_UNSPECIFIED;

  /**
   * adjustment, transaction, cancel, capture, hold, refund, release ...
   *
   * @generated from field: perkup.v1.BalanceTransaction.Action action = 13;
   */
  action = BalanceTransaction_Action.ACTION_UNSPECIFIED;

  /**
   * The accountId from Program.defaultAccountId if not personal funds
   *
   * @generated from field: optional string account_id = 14;
   */
  accountId?: string;

  /**
   * @generated from field: optional perkup.v1.ProductVariant.SourceType item_source_type = 15;
   */
  itemSourceType?: ProductVariant_SourceType;

  constructor(data?: PartialMessage<BalanceTransaction>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "perkup.v1.BalanceTransaction";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "auth_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "created", kind: "message", T: Timestamp },
    { no: 4, name: "amount", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 5, name: "currency", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "provider_amount", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 7, name: "provider_currency", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "program_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "source_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 12, name: "provider", kind: "enum", T: proto3.getEnumType(BalanceTransaction_Provider) },
    { no: 13, name: "action", kind: "enum", T: proto3.getEnumType(BalanceTransaction_Action) },
    { no: 14, name: "account_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 15, name: "item_source_type", kind: "enum", T: proto3.getEnumType(ProductVariant_SourceType), opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): BalanceTransaction {
    return new BalanceTransaction().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): BalanceTransaction {
    return new BalanceTransaction().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): BalanceTransaction {
    return new BalanceTransaction().fromJsonString(jsonString, options);
  }

  static equals(a: BalanceTransaction | PlainMessage<BalanceTransaction> | undefined, b: BalanceTransaction | PlainMessage<BalanceTransaction> | undefined): boolean {
    return proto3.util.equals(BalanceTransaction, a, b);
  }
}

/**
 * @generated from enum perkup.v1.BalanceTransaction.Provider
 */
export enum BalanceTransaction_Provider {
  /**
   * @generated from enum value: PROVIDER_UNSPECIFIED = 0;
   */
  PROVIDER_UNSPECIFIED = 0,

  /**
   * @generated from enum value: perkup = 1;
   */
  perkup = 1,

  /**
   * @generated from enum value: amazon = 2;
   */
  amazon = 2,

  /**
   * @generated from enum value: dreamship = 3 [deprecated = true];
   * @deprecated
   */
  dreamship = 3,

  /**
   * @generated from enum value: manual = 4;
   */
  manual = 4,

  /**
   * @generated from enum value: plum = 5;
   */
  plum = 5,

  /**
   * @generated from enum value: stripe = 6;
   */
  stripe = 6,
}
// Retrieve enum metadata with: proto3.getEnumType(BalanceTransaction_Provider)
proto3.util.setEnumType(BalanceTransaction_Provider, "perkup.v1.BalanceTransaction.Provider", [
  { no: 0, name: "PROVIDER_UNSPECIFIED" },
  { no: 1, name: "perkup" },
  { no: 2, name: "amazon" },
  { no: 3, name: "dreamship" },
  { no: 4, name: "manual" },
  { no: 5, name: "plum" },
  { no: 6, name: "stripe" },
]);

/**
 * @generated from enum perkup.v1.BalanceTransaction.Action
 */
export enum BalanceTransaction_Action {
  /**
   * @generated from enum value: ACTION_UNSPECIFIED = 0;
   */
  ACTION_UNSPECIFIED = 0,

  /**
   * @generated from enum value: adjustment = 1;
   */
  adjustment = 1,

  /**
   * @generated from enum value: transaction = 2;
   */
  transaction = 2,

  /**
   * @generated from enum value: cancel = 3;
   */
  cancel = 3,

  /**
   * @generated from enum value: capture = 4;
   */
  capture = 4,

  /**
   * @generated from enum value: hold = 5;
   */
  hold = 5,

  /**
   * @generated from enum value: refund = 6;
   */
  refund = 6,

  /**
   * @generated from enum value: release = 7;
   */
  release = 7,
}
// Retrieve enum metadata with: proto3.getEnumType(BalanceTransaction_Action)
proto3.util.setEnumType(BalanceTransaction_Action, "perkup.v1.BalanceTransaction.Action", [
  { no: 0, name: "ACTION_UNSPECIFIED" },
  { no: 1, name: "adjustment" },
  { no: 2, name: "transaction" },
  { no: 3, name: "cancel" },
  { no: 4, name: "capture" },
  { no: 5, name: "hold" },
  { no: 6, name: "refund" },
  { no: 7, name: "release" },
]);

