import { FirebaseOptions } from 'firebase/app'
import { PROD_HOSTNAME } from './hosts'
import * as ROUTES from './routes'

const firebaseConfig: FirebaseOptions = {
  apiKey: 'AIzaSyAPVLWU5kP79bZsqFIw2TBqcBcl6pnZ3mo',
  authDomain: PROD_HOSTNAME,
  projectId: 'perkup-app',
  storageBucket: 'gs://perkup-app.appspot.com',
  messagingSenderId: '295940089036',
  appId: '1:295940089036:web:8f978c22d62c356c9c0e69',
  measurementId: 'G-2VDXKT1RXJ',
  databaseURL: 'https://perkup-app.firebaseio.com',
}

export const firebaseConfigTest: FirebaseOptions = {
  apiKey: 'AIzaSyDBQsHM3DAGV65CTlOU4DjlDyGd-BMgoOA',
  authDomain: 'perkup-app-test.firebaseapp.com',
  projectId: 'perkup-app-test',
  storageBucket: 'perkup-app-test.appspot.com',
  messagingSenderId: '1073117313363',
  appId: '1:1073117313363:web:1d91fa63370629a6d209a6',
  databaseURL: 'https://perkup-app-test.firebaseio.com',
}

const actionCodeSettings = {
  url: window.location.origin + ROUTES.EMAIL_CONFIRMATION,
  handleCodeInApp: true,
}

export { firebaseConfig, actionCodeSettings }

export const localStorageEmailForSignIn = 'emailForSignIn'
