// @generated by protoc-gen-es v1.6.0 with parameter "target=ts"
// @generated from file shopify/fulfillment.proto (package shopify, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, protoInt64, Timestamp } from "@bufbuild/protobuf";

/**
 * Shopify fulfillment related Webhook event payload
 * https://shopify.dev/docs/api/admin-rest/2023-01/resources/fulfillment
 * Firestore /shopifyFulfillmentEvents/:id/
 *
 * @generated from message shopify.Fulfillment
 */
export class Fulfillment extends Message<Fulfillment> {
  /**
   * set json_name to "-" to ignore the field when protojson marshalling to JSON
   *
   * @generated from field: string id = 1 [json_name = "-"];
   */
  id = "";

  /**
   * @generated from field: int64 order_id = 2;
   */
  orderId = protoInt64.zero;

  /**
   * @generated from field: string status = 3;
   */
  status = "";

  /**
   * @generated from field: google.protobuf.Timestamp created_at = 4;
   */
  createdAt?: Timestamp;

  /**
   * @generated from field: string service = 5;
   */
  service = "";

  /**
   * @generated from field: google.protobuf.Timestamp updated_at = 6;
   */
  updatedAt?: Timestamp;

  /**
   * @generated from field: string tracking_company = 7;
   */
  trackingCompany = "";

  /**
   * @generated from field: string shipment_status = 8;
   */
  shipmentStatus = "";

  /**
   * @generated from field: int64 location_id = 9;
   */
  locationId = protoInt64.zero;

  /**
   * @generated from field: string origin_address = 10;
   */
  originAddress = "";

  /**
   * @generated from field: string email = 11;
   */
  email = "";

  /**
   * @generated from field: shopify.Fulfillment.Destination destination = 12;
   */
  destination?: Fulfillment_Destination;

  /**
   * @generated from field: repeated shopify.Fulfillment.LineItem line_items = 13;
   */
  lineItems: Fulfillment_LineItem[] = [];

  /**
   * @generated from field: string tracking_number = 14;
   */
  trackingNumber = "";

  /**
   * @generated from field: repeated string tracking_numbers = 15;
   */
  trackingNumbers: string[] = [];

  /**
   * @generated from field: string tracking_url = 16;
   */
  trackingUrl = "";

  /**
   * @generated from field: repeated string tracking_urls = 17;
   */
  trackingUrls: string[] = [];

  /**
   * @generated from field: string name = 19;
   */
  name = "";

  /**
   * @generated from field: string admin_graphql_api_id = 20;
   */
  adminGraphqlApiId = "";

  /**
   * set json_name to "id" to map fulfillment_id to id when protojson marshalling to JSON
   *
   * @generated from field: int64 fulfillment_id = 21 [json_name = "id"];
   */
  fulfillmentId = protoInt64.zero;

  constructor(data?: PartialMessage<Fulfillment>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "shopify.Fulfillment";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", jsonName: "-", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "order_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "status", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "created_at", kind: "message", T: Timestamp },
    { no: 5, name: "service", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "updated_at", kind: "message", T: Timestamp },
    { no: 7, name: "tracking_company", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "shipment_status", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "location_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 10, name: "origin_address", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 12, name: "destination", kind: "message", T: Fulfillment_Destination },
    { no: 13, name: "line_items", kind: "message", T: Fulfillment_LineItem, repeated: true },
    { no: 14, name: "tracking_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 15, name: "tracking_numbers", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 16, name: "tracking_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 17, name: "tracking_urls", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 19, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 20, name: "admin_graphql_api_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 21, name: "fulfillment_id", jsonName: "id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Fulfillment {
    return new Fulfillment().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Fulfillment {
    return new Fulfillment().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Fulfillment {
    return new Fulfillment().fromJsonString(jsonString, options);
  }

  static equals(a: Fulfillment | PlainMessage<Fulfillment> | undefined, b: Fulfillment | PlainMessage<Fulfillment> | undefined): boolean {
    return proto3.util.equals(Fulfillment, a, b);
  }
}

/**
 * @generated from enum shopify.Fulfillment.Status
 */
export enum Fulfillment_Status {
  /**
   * @generated from enum value: STATUS_UNSPECIFIED = 0;
   */
  STATUS_UNSPECIFIED = 0,

  /**
   * PerkUp has created the fulfillment and is waiting for the third-party fulfillment service to transition it to 'open' or 'success'.
   *
   * @generated from enum value: pending = 1;
   */
  pending = 1,

  /**
   * The fulfillment has been acknowledged by the service and is in processing.
   *
   * @generated from enum value: open = 2;
   */
  open = 2,

  /**
   * The fulfillment was successful.
   *
   * @generated from enum value: success = 3;
   */
  success = 3,

  /**
   * The fulfillment was cancelled.
   *
   * @generated from enum value: cancelled = 4;
   */
  cancelled = 4,

  /**
   * There was an error with the fulfillment request.
   *
   * @generated from enum value: error = 5;
   */
  error = 5,

  /**
   * The fulfillment request failed.
   *
   * @generated from enum value: failure = 6;
   */
  failure = 6,
}
// Retrieve enum metadata with: proto3.getEnumType(Fulfillment_Status)
proto3.util.setEnumType(Fulfillment_Status, "shopify.Fulfillment.Status", [
  { no: 0, name: "STATUS_UNSPECIFIED" },
  { no: 1, name: "pending" },
  { no: 2, name: "open" },
  { no: 3, name: "success" },
  { no: 4, name: "cancelled" },
  { no: 5, name: "error" },
  { no: 6, name: "failure" },
]);

/**
 * @generated from message shopify.Fulfillment.Destination
 */
export class Fulfillment_Destination extends Message<Fulfillment_Destination> {
  /**
   * @generated from field: string first_name = 1;
   */
  firstName = "";

  /**
   * @generated from field: string address1 = 2;
   */
  address1 = "";

  /**
   * @generated from field: string phone = 3;
   */
  phone = "";

  /**
   * @generated from field: string city = 4;
   */
  city = "";

  /**
   * @generated from field: string zip = 5;
   */
  zip = "";

  /**
   * @generated from field: string province = 6;
   */
  province = "";

  /**
   * @generated from field: string country = 7;
   */
  country = "";

  /**
   * @generated from field: string last_name = 8;
   */
  lastName = "";

  /**
   * @generated from field: string address2 = 9;
   */
  address2 = "";

  /**
   * @generated from field: string company = 10;
   */
  company = "";

  /**
   * @generated from field: optional float latitude = 11;
   */
  latitude?: number;

  /**
   * @generated from field: optional float longitude = 12;
   */
  longitude?: number;

  /**
   * @generated from field: string name = 13;
   */
  name = "";

  /**
   * @generated from field: string country_code = 14;
   */
  countryCode = "";

  /**
   * @generated from field: string province_code = 15;
   */
  provinceCode = "";

  constructor(data?: PartialMessage<Fulfillment_Destination>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "shopify.Fulfillment.Destination";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "first_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "address1", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "phone", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "city", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "zip", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "province", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "country", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "last_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "address2", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "company", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "latitude", kind: "scalar", T: 2 /* ScalarType.FLOAT */, opt: true },
    { no: 12, name: "longitude", kind: "scalar", T: 2 /* ScalarType.FLOAT */, opt: true },
    { no: 13, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 14, name: "country_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 15, name: "province_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Fulfillment_Destination {
    return new Fulfillment_Destination().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Fulfillment_Destination {
    return new Fulfillment_Destination().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Fulfillment_Destination {
    return new Fulfillment_Destination().fromJsonString(jsonString, options);
  }

  static equals(a: Fulfillment_Destination | PlainMessage<Fulfillment_Destination> | undefined, b: Fulfillment_Destination | PlainMessage<Fulfillment_Destination> | undefined): boolean {
    return proto3.util.equals(Fulfillment_Destination, a, b);
  }
}

/**
 * @generated from message shopify.Fulfillment.LineItem
 */
export class Fulfillment_LineItem extends Message<Fulfillment_LineItem> {
  /**
   * @generated from field: int64 id = 1;
   */
  id = protoInt64.zero;

  /**
   * @generated from field: int64 variant_id = 2;
   */
  variantId = protoInt64.zero;

  /**
   * @generated from field: string title = 3;
   */
  title = "";

  /**
   * @generated from field: int32 quantity = 4;
   */
  quantity = 0;

  /**
   * @generated from field: string sku = 5;
   */
  sku = "";

  /**
   * @generated from field: string variant_title = 6;
   */
  variantTitle = "";

  /**
   * @generated from field: string vendor = 7;
   */
  vendor = "";

  /**
   * @generated from field: string fulfillment_service = 8;
   */
  fulfillmentService = "";

  /**
   * @generated from field: int64 product_id = 9;
   */
  productId = protoInt64.zero;

  /**
   * @generated from field: bool requires_shipping = 10;
   */
  requiresShipping = false;

  /**
   * @generated from field: bool taxable = 11;
   */
  taxable = false;

  /**
   * @generated from field: bool gift_card = 12;
   */
  giftCard = false;

  /**
   * name is variant title - option1/option2 e.g. "POLO TSHIRT - Black/XL"
   *
   * @generated from field: string name = 13;
   */
  name = "";

  /**
   * @generated from field: string variant_inventory_management = 14;
   */
  variantInventoryManagement = "";

  /**
   * @generated from field: bool product_exists = 16;
   */
  productExists = false;

  /**
   * @generated from field: int32 fulfillable_quantity = 17;
   */
  fulfillableQuantity = 0;

  /**
   * @generated from field: int32 grams = 18;
   */
  grams = 0;

  /**
   * @generated from field: string price = 19;
   */
  price = "";

  /**
   * @generated from field: string total_discount = 20;
   */
  totalDiscount = "";

  /**
   * @generated from field: shopify.Fulfillment.LineItem.LineItemFulfillmentStatus fulfillment_status = 21 [deprecated = true];
   * @deprecated
   */
  fulfillmentStatus = Fulfillment_LineItem_LineItemFulfillmentStatus.FULFILLMENT_STATUS_UNSPECIFIED;

  /**
   * @generated from field: string admin_graphql_api_id = 22;
   */
  adminGraphqlApiId = "";

  constructor(data?: PartialMessage<Fulfillment_LineItem>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "shopify.Fulfillment.LineItem";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "variant_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "quantity", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "sku", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "variant_title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "vendor", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "fulfillment_service", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "product_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 10, name: "requires_shipping", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 11, name: "taxable", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 12, name: "gift_card", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 13, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 14, name: "variant_inventory_management", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 16, name: "product_exists", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 17, name: "fulfillable_quantity", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 18, name: "grams", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 19, name: "price", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 20, name: "total_discount", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 21, name: "fulfillment_status", kind: "enum", T: proto3.getEnumType(Fulfillment_LineItem_LineItemFulfillmentStatus) },
    { no: 22, name: "admin_graphql_api_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Fulfillment_LineItem {
    return new Fulfillment_LineItem().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Fulfillment_LineItem {
    return new Fulfillment_LineItem().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Fulfillment_LineItem {
    return new Fulfillment_LineItem().fromJsonString(jsonString, options);
  }

  static equals(a: Fulfillment_LineItem | PlainMessage<Fulfillment_LineItem> | undefined, b: Fulfillment_LineItem | PlainMessage<Fulfillment_LineItem> | undefined): boolean {
    return proto3.util.equals(Fulfillment_LineItem, a, b);
  }
}

/**
 * @generated from enum shopify.Fulfillment.LineItem.LineItemFulfillmentStatus
 */
export enum Fulfillment_LineItem_LineItemFulfillmentStatus {
  /**
   * @generated from enum value: FULFILLMENT_STATUS_UNSPECIFIED = 0;
   */
  FULFILLMENT_STATUS_UNSPECIFIED = 0,

  /**
   * @generated from enum value: fulfilled = 1;
   */
  fulfilled = 1,

  /**
   * @generated from enum value: null = 2;
   */
  null = 2,

  /**
   * @generated from enum value: partial = 3;
   */
  partial = 3,
}
// Retrieve enum metadata with: proto3.getEnumType(Fulfillment_LineItem_LineItemFulfillmentStatus)
proto3.util.setEnumType(Fulfillment_LineItem_LineItemFulfillmentStatus, "shopify.Fulfillment.LineItem.LineItemFulfillmentStatus", [
  { no: 0, name: "FULFILLMENT_STATUS_UNSPECIFIED" },
  { no: 1, name: "fulfilled" },
  { no: 2, name: "null" },
  { no: 3, name: "partial" },
]);

/**
 * @generated from message shopify.Fulfillment.ShipmentStatus
 */
export class Fulfillment_ShipmentStatus extends Message<Fulfillment_ShipmentStatus> {
  constructor(data?: PartialMessage<Fulfillment_ShipmentStatus>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "shopify.Fulfillment.ShipmentStatus";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Fulfillment_ShipmentStatus {
    return new Fulfillment_ShipmentStatus().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Fulfillment_ShipmentStatus {
    return new Fulfillment_ShipmentStatus().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Fulfillment_ShipmentStatus {
    return new Fulfillment_ShipmentStatus().fromJsonString(jsonString, options);
  }

  static equals(a: Fulfillment_ShipmentStatus | PlainMessage<Fulfillment_ShipmentStatus> | undefined, b: Fulfillment_ShipmentStatus | PlainMessage<Fulfillment_ShipmentStatus> | undefined): boolean {
    return proto3.util.equals(Fulfillment_ShipmentStatus, a, b);
  }
}

/**
 * @generated from enum shopify.Fulfillment.ShipmentStatus.Enum
 */
export enum Fulfillment_ShipmentStatus_Enum {
  /**
   * @generated from enum value: SHIPMENT_STATUS_UNSPECIFIED = 0;
   */
  SHIPMENT_STATUS_UNSPECIFIED = 0,

  /**
   * : A label for the shipment was purchased and printed.
   *
   * @generated from enum value: label_printed = 1;
   */
  label_printed = 1,

  /**
   * A label for the shipment was purchased, but not printed.
   *
   * @generated from enum value: label_purchased = 2;
   */
  label_purchased = 2,

  /**
   * Delivery of the shipment was attempted, but unable to be completed.
   *
   * @generated from enum value: attempted_delivery = 3;
   */
  attempted_delivery = 3,

  /**
   * The shipment is ready for pickup at a shipping depot.
   *
   * @generated from enum value: ready_for_pickup = 4;
   */
  ready_for_pickup = 4,

  /**
   * The carrier is aware of the shipment, but hasn't received it yet.
   *
   * @generated from enum value: confirmed = 5;
   */
  confirmed = 5,

  /**
   * The shipment is being transported between shipping facilities on the way to its destination.
   *
   * @generated from enum value: in_transit = 6;
   */
  in_transit = 6,

  /**
   * The shipment is being delivered to its final destination.
   *
   * @generated from enum value: out_for_delivery = 7;
   */
  out_for_delivery = 7,

  /**
   * The shipment was succesfully delivered.
   *
   * @generated from enum value: delivered = 8;
   */
  delivered = 8,

  /**
   * Something went wrong when pulling tracking information for the shipment, such as the tracking number was invalid or the shipment was canceled.
   *
   * @generated from enum value: failure = 9;
   */
  failure = 9,
}
// Retrieve enum metadata with: proto3.getEnumType(Fulfillment_ShipmentStatus_Enum)
proto3.util.setEnumType(Fulfillment_ShipmentStatus_Enum, "shopify.Fulfillment.ShipmentStatus.Enum", [
  { no: 0, name: "SHIPMENT_STATUS_UNSPECIFIED" },
  { no: 1, name: "label_printed" },
  { no: 2, name: "label_purchased" },
  { no: 3, name: "attempted_delivery" },
  { no: 4, name: "ready_for_pickup" },
  { no: 5, name: "confirmed" },
  { no: 6, name: "in_transit" },
  { no: 7, name: "out_for_delivery" },
  { no: 8, name: "delivered" },
  { no: 9, name: "failure" },
]);

