import { Empty, Flex } from 'antd'
import Skeleton from 'antd/es/skeleton'
import { Heading, Text } from 'evergreen-ui'
import { useListInvoicesByCustomerId } from 'hooks'
import { BillingHistoryTable } from './BillingHistoryTable'

export function AcccountBillingHistory({ accountId }: { accountId: string }) {
  const { invoices, isLoading } = useListInvoicesByCustomerId()

  if (isLoading) return <Skeleton active paragraph={{ rows: 8 }} />

  const invoicesForThisAccount = invoices.filter(
    invoice => invoice?.metadata?.accountId === accountId
  )

  if (invoicesForThisAccount.length === 0) {
    return (
      <Empty
        image={Empty.PRESENTED_IMAGE_SIMPLE}
        imageStyle={{ height: 60 }}
        description={
          <Flex vertical gap={8}>
            <Heading>No invoices found</Heading>
            <Text>
              We couldn&apos;t find any invoices associated with this account
            </Text>
          </Flex>
        }
      />
    )
  }

  return <BillingHistoryTable invoices={invoicesForThisAccount} />
}
