export const CSM_DETAILS_MAP: Record<
  string,
  { cal: string; pfp: string; name: string }
> = {
  'thomas@perkupapp.com': {
    cal: 'https://cal.com/thomas-mirmotahari',
    pfp: 'https://images.ctfassets.net/knc1roo454gw/4F05knUJK85o23NBRTMQjs/718d859b69955c22d11494132e08703f/thomas_profile_picture.jpeg',
    name: 'Thomas Mirmotahari',
  },
  'adriana@perkupapp.com': {
    cal: 'https://cal.com/adrianapaola',
    pfp: 'https://images.ctfassets.net/knc1roo454gw/7n2sUKYhB1B56SgJHnPgIn/28bc53098064b22b4b58d0de2857fbf8/adriana_profile_picture.png?h=250',
    name: 'Adriana Paola Castaño',
  },
  'nick@perkupapp.com': {
    cal: 'https://cal.com/nick372',
    pfp: 'https://images.ctfassets.net/knc1roo454gw/2tCPNYw2rkLLNSF2dKY4op/c3c3d433ccae4e49210cd3e8b1c6f7b8/nick_profile_picture.png?h=250',
    name: 'Nick Jackson',
  },
}
