import { Tag, TagProps } from 'antd'
import Color from 'color'
import { OrgContext } from 'context'
import { useContext } from 'react'

export function BrandedTag(props: TagProps) {
  const org = useContext(OrgContext)
  const color = Color(org.primaryColor)
  const { children, style, ...other } = props
  return (
    <Tag
      {...other}
      style={{
        color: org.primaryColor,
        borderColor: color.lightness(80).toString(),
        backgroundColor: color.lightness(95).toString(),
        ...style,
      }}
    >
      {children}
    </Tag>
  )
}
