import { Tag } from 'antd'
import { Avatar, Heading, Pane, Text } from 'evergreen-ui'
import { useMemo } from 'react'
import { buildFullName, ordinalSuffixOf } from 'utils'
import { IEventInfo } from './EventDrawer'

export function EventProfileCard({
  eventInfo,
}: {
  eventInfo: IEventInfo | undefined
}) {
  const currentIndividual = eventInfo?.eventIndividual
  const personProfileImage = currentIndividual?.profilePicture
  const firstName = currentIndividual?.firstName
  const lastName = currentIndividual?.lastName
  const email = currentIndividual?.email || '@email.com'

  const displayName =
    buildFullName({ firstName, lastName }) || email.slice(0, email.indexOf('@')) // Emails can get pretty long

  // Create event type string from event
  const isBirthdayEvent = eventInfo?.isBirthday
  const tenure = eventInfo?.yearsDiff || 0
  const eventTypeString = useMemo(() => {
    if (isBirthdayEvent) return 'Birthday 🎂'

    if (tenure === 0) return 'New hire'
    return `${ordinalSuffixOf(tenure)} Work Anniversary` // Default
  }, [isBirthdayEvent, tenure])

  return (
    <Pane display="flex" alignItems="center" gap={24}>
      <Avatar
        src={personProfileImage}
        size={72}
        name={displayName}
        color="orange"
      />
      <Pane display="flex" flexDirection="column" gap={4}>
        <Heading size={800}>{displayName}</Heading>
        <Text color="muted">{email}</Text>
        <Pane>
          <Tag color="gold">{eventTypeString}</Tag>
        </Pane>
      </Pane>
    </Pane>
  )
}
