import type { ChartPivotRow } from '@cubejs-client/core'

export function prepareChartResults(
  data: ChartPivotRow[],
  {
    limit = 9,
    truncatedLabel = 'Other',
    colors = [
      'hsl(var(--chart-1))',
      'hsl(var(--chart-2))',
      'hsl(var(--chart-3))',
      'hsl(var(--chart-4))',
      'hsl(var(--chart-5))',
      'hsl(var(--chart-6))',
      'hsl(var(--chart-7))',
      'hsl(var(--chart-8))',
      'hsl(var(--chart-9))',
      'hsl(var(--chart-10))',
    ],
  } = {}
) {
  const chartData: ChartPivotRow[] = data.slice(0, limit).map((row, index) => {
    return {
      ...row,
      fill: colors[index],
    }
  })

  if (data.length > limit) {
    const truncatedData = data.slice(limit).reduce<ChartPivotRow>(
      (acc, row) => {
        // iterate over all keys in the row other than x and xValues
        // sum up the values

        const xValues = (acc.xValues || []).concat(row.xValues || [])

        const otherValues = Object.keys(row)
          .filter(key => key !== 'x' && key !== 'xValues')
          .reduce<Record<string, number>>((accKeys, aggKey) => {
            return {
              ...accKeys,
              [aggKey]:
                (accKeys[aggKey] || 0) + row[aggKey] + (acc[aggKey] || 0),
            }
          }, {})

        return {
          ...acc,
          xValues,
          fill: colors[limit],
          ...otherValues,
        }
      },
      {
        x: truncatedLabel,
        xValues: [],
      }
    )

    chartData.push(truncatedData)
  }

  return chartData
}
