import { ShopOutlined, SyncOutlined } from '@ant-design/icons'
import { Tag, Tooltip } from 'antd'
import { DeleteProgram } from 'components'
import { DEFAULT_ROUTES } from 'constants/routes'
import { Heading, Pane, Text } from 'evergreen-ui'
import { Program, ProgramFrequency } from 'gen/perkup/v1/program_pb'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { numToDollars } from 'utils'
import ProgramCategories from './ProgramCategories'

export default function ProgramCard({ program }: { program: Program }) {
  const navigate = useNavigate()
  const [stopPropagation, setStopPropagation] = useState(false)

  if (!program) return null

  const { name, budget, frequency } = program

  const perPersonBudget$ = numToDollars(budget, 0)

  return (
    <Pane
      position="relative"
      padding={32}
      border
      borderRadius={4}
      hoverElevation={1}
      cursor="pointer"
      onClick={() => {
        if (!stopPropagation) {
          navigate(
            `${DEFAULT_ROUTES.ORGANIZATION.PERK_PROGRAMS.ROOT}/${program.id}`
          )
        }
      }}
    >
      <Pane position="absolute" top={32} right={32}>
        <DeleteProgram
          program={program}
          setStopPropagation={setStopPropagation}
        />
      </Pane>

      <Heading size={700} marginBottom={8}>
        {name}
      </Heading>

      <Text size={600} color="muted">
        {perPersonBudget$} {ProgramFrequency[frequency]}
      </Text>

      <Pane
        display="flex"
        alignItems="flex-end"
        justifyContent="space-between"
        marginBottom={16}
        marginTop={4}
        height={24}
      >
        {program?.totalMembers > 0 && (
          <Tag color="green">
            {program?.totalMembers} member
            {program?.totalMembers !== 1 ? 's' : ''}
          </Tag>
        )}

        <Pane marginRight={8} display="flex" gap={24}>
          {program.rollover && (
            <Tooltip title="Budgets rollover">
              <SyncOutlined />
            </Tooltip>
          )}
          {program.allMerchants && (
            <Tooltip title="All merchants are approved">
              <ShopOutlined />
            </Tooltip>
          )}
        </Pane>
      </Pane>

      {/* PROGRAM APPROVED CATEGORIES */}
      <ProgramCategories
        approvedCategories={program.approvedCategories}
        withName
      />
    </Pane>
  )
}
