// @generated by protoc-gen-connect-es v1.1.4 with parameter "target=ts"
// @generated from file shopify/admin.proto (package shopify, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { CollectionRequest, CollectionResponse, CollectionsRequest, CollectionsResponse, CreateCollectionRequest, DeleteCollectionImageRequest, ModifyCollectionProductsRequest, ProductRequest, ProductResponse, ProductsRequest, ProductsResponse, ReorderCollectionProductsRequest, ReorderProductImagesRequest, UpdateCollectionImageRequest, UpdateCollectionRequest, UpdateProductRequest } from "./admin_pb.js";
import { MethodKind } from "@bufbuild/protobuf";
import { Empty } from "../google/protobuf/empty_pb.js";
import { DraftOrderCalculatePayload, DraftOrderInput } from "../shopifyapi/admingql_pb.js";

/**
 * @generated from service shopify.Admin
 */
export const Admin = {
  typeName: "shopify.Admin",
  methods: {
    /**
     * TODO: add pagination?
     *
     * @generated from rpc shopify.Admin.GetProduct
     */
    getProduct: {
      name: "GetProduct",
      I: ProductRequest,
      O: ProductResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc shopify.Admin.GetProducts
     */
    getProducts: {
      name: "GetProducts",
      I: ProductsRequest,
      O: ProductsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc shopify.Admin.ListProducts
     */
    listProducts: {
      name: "ListProducts",
      I: Empty,
      O: ProductsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc shopify.Admin.ListProductsByCollection
     */
    listProductsByCollection: {
      name: "ListProductsByCollection",
      I: CollectionRequest,
      O: ProductsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc shopify.Admin.UpdateProduct
     */
    updateProduct: {
      name: "UpdateProduct",
      I: UpdateProductRequest,
      O: ProductResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc shopify.Admin.ReorderProductImages
     */
    reorderProductImages: {
      name: "ReorderProductImages",
      I: ReorderProductImagesRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc shopify.Admin.AddProductsToCollection
     */
    addProductsToCollection: {
      name: "AddProductsToCollection",
      I: ModifyCollectionProductsRequest,
      O: CollectionResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc shopify.Admin.RemoveProductsFromCollection
     */
    removeProductsFromCollection: {
      name: "RemoveProductsFromCollection",
      I: ModifyCollectionProductsRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc shopify.Admin.ReorderCollectionProducts
     */
    reorderCollectionProducts: {
      name: "ReorderCollectionProducts",
      I: ReorderCollectionProductsRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc shopify.Admin.GetCollection
     */
    getCollection: {
      name: "GetCollection",
      I: CollectionRequest,
      O: CollectionResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc shopify.Admin.ListCollections
     */
    listCollections: {
      name: "ListCollections",
      I: CollectionsRequest,
      O: CollectionsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc shopify.Admin.CreateCollection
     */
    createCollection: {
      name: "CreateCollection",
      I: CreateCollectionRequest,
      O: CollectionResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc shopify.Admin.UpdateCollection
     */
    updateCollection: {
      name: "UpdateCollection",
      I: UpdateCollectionRequest,
      O: CollectionResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc shopify.Admin.UpdateCollectionImage
     */
    updateCollectionImage: {
      name: "UpdateCollectionImage",
      I: UpdateCollectionImageRequest,
      O: CollectionResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc shopify.Admin.DeleteCollectionImage
     */
    deleteCollectionImage: {
      name: "DeleteCollectionImage",
      I: DeleteCollectionImageRequest,
      O: CollectionResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc shopify.Admin.CalculateDraftOrder
     */
    calculateDraftOrder: {
      name: "CalculateDraftOrder",
      I: DraftOrderInput,
      O: DraftOrderCalculatePayload,
      kind: MethodKind.Unary,
    },
  }
} as const;

