import {
  GetIndividualById,
  GetIndividualByUserId,
  ListEnrolledMembersByProgramId,
} from 'api/databaseCalls'
import { OrgContext } from 'context'
import { Individual } from 'gen/perkup/v1/individual_pb'
import { compact } from 'lodash-es'
import { useContext, useEffect, useState } from 'react'

/**
 * Helper function to retrieve the enrolled Member on a Program, and get their associated Individual object.
 *
 * @returns the list of enrolled Individuals on a Program.
 */
async function GetIndividualsByProgramId({
  orgId,
  programId,
  limit,
}: {
  orgId: string
  programId: string
  limit?: number
}) {
  const programMembers = await ListEnrolledMembersByProgramId({
    orgId,
    programId,
    limit,
  })

  const programIndividuals: Individual[] = compact(
    await Promise.all(
      programMembers.map(async member => {
        const userOrIndividualId = member.id
        let individual = await GetIndividualById({
          orgId,
          individualId: userOrIndividualId,
        })

        // The input id is a userId
        if (!individual) {
          individual = await GetIndividualByUserId({
            orgId,
            userId: userOrIndividualId,
          })
        }

        return individual
      })
    )
  )

  return programIndividuals
}

export function useProgramIndividuals({
  programId,
  limit,
}: {
  programId: string
  limit?: number
}) {
  const { id: orgId } = useContext(OrgContext)
  const [individuals, setIndividuals] = useState<Individual[]>([])
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (orgId && programId) {
      setIsLoading(true)
      GetIndividualsByProgramId({ orgId, programId, limit })
        .then(setIndividuals)
        .finally(() => setIsLoading(false))
    }
  }, [orgId, programId, limit])

  return { individuals, isLoading }
}
