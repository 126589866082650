import { PlacidTags } from 'constants/placid/placid-tags'
import { compact } from 'lodash-es'
import { getPlacidDataByTag } from 'utils/placid'
import { GetFirebaseStorageImage } from './images'

export async function listPlacidImagesByTag({
  tag,
  orgId,
}: {
  tag: PlacidTags
  orgId: string
}) {
  const desiredImageIds = getPlacidDataByTag(tag).map(data => data.uuid)
  const images = await Promise.all(
    desiredImageIds.map(async id => {
      const res = await GetFirebaseStorageImage({
        orgId,
        imageName: `${id}.jpg`,
        suffix: 'placidImages',
      })
      return res?.url
    })
  )

  return compact(images)
}
