import { Item } from 'gen/perkup/v1/program_pb'
import { flatten, groupBy, keys } from 'lodash-es'

export function flattenItems({ items }: { items: Map<string, Item[]> }) {
  const flattenedItems: Item[] = flatten(
    Array.from(items, ([_, items]) => items.flat())
  )
  return flattenedItems
}

export function groupItemsByQuantity({ items }: { items: Item[] }): Item[] {
  const itemsGroupedByQuantity: Item[] = []

  const itemsGroupedByProductVariantId = groupBy(
    items,
    item => item.productVariantId
  )
  const groupedItemsKeys = keys(itemsGroupedByProductVariantId)
  // We need to sum quantities of items with the same productVariantId
  groupedItemsKeys.forEach(key => {
    const groupedItems = itemsGroupedByProductVariantId[key]
    const quantity = groupedItems.reduce((acc, item) => acc + item.quantity, 0)
    const lineItem = groupedItems[0]
    lineItem.quantity = quantity
    itemsGroupedByQuantity.push(lineItem)
  })

  return itemsGroupedByQuantity
}
