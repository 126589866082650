import { captureException } from '@sentry/react'
import {
  QueryDocumentSnapshot,
  Timestamp,
  collection,
  doc,
  getDocs,
  getFirestore,
  limit,
  onSnapshot,
  orderBy,
  query,
  startAfter,
  where,
} from 'firebase/firestore'
import {
  Notification,
  NotificationSetting,
  Notification_Status,
  Notification_Type,
} from 'gen/perkup/v1/notification_pb'
import { Action, ActionType_Enum } from 'gen/perkup/v1/rules_pb'
import { NotificationSendTypes, RuleGroups } from 'types/Notifications'
import { RangeValue } from 'types/RewardsDate'
import { converter, storedId } from 'utils/firestore'
import {
  getNotificationActionType,
  getNotificationSettingId,
} from 'utils/notifications'
import { toSentry } from 'utils/sentry'

/**
 * NOTIFICATIONS
 */

export async function PaginateNotificationSnapshotsByOrgId({
  orgId,
  individualId,
  dateRange,
  index,
  queryLimit,
  notificationType,
  notificationStatus,
}: {
  orgId: string
  individualId?: string
  dateRange: RangeValue
  index?: QueryDocumentSnapshot<Notification>
  queryLimit: number
  notificationType?: Notification_Type
  notificationStatus?: Notification_Status
}) {
  try {
    const db = getFirestore()
    const colRef = collection(db, 'notifications').withConverter(
      storedId(Notification)
    )

    const start = dateRange?.[0]
      ? Timestamp.fromDate(dateRange[0].toDate())
      : undefined

    const end = dateRange?.[1]
      ? Timestamp.fromDate(dateRange[1].toDate())
      : undefined

    let q = query(
      colRef,
      where('orgId', '==', orgId),
      orderBy('createdAt', 'desc'),
      limit(queryLimit)
    )

    if (individualId) {
      q = query(q, where('individualId', '==', individualId))
    }

    if (start) {
      q = query(q, where('createdAt', '>=', start))
    }
    if (end) {
      q = query(q, where('createdAt', '<=', end))
    }

    if (notificationType) {
      q = query(q, where('type', '==', Notification_Type[notificationType]))
    }

    if (notificationStatus) {
      q = query(
        q,
        where('status', '==', Notification_Status[notificationStatus])
      )
    }

    if (index) {
      q = query(q, startAfter(index))
    }

    const qSnapshot = await getDocs(q)
    const notifications = qSnapshot.docs

    return notifications
  } catch (error) {
    console.error(error)
    captureException(toSentry(error), {
      contexts: {
        ListNotificationsByOrgId: {
          orgId,
          individualId,
        },
      },
    })
    return []
  }
}

export function listenToNotificationSetting({
  orgId,
  sendType,
  ruleGroup,
  cb,
}: {
  orgId: string
  sendType: NotificationSendTypes
  ruleGroup: RuleGroups
  cb: (notificationSetting: NotificationSetting | undefined) => void
}) {
  const notificationDocId = getNotificationSettingId({ sendType, ruleGroup })
  const db = getFirestore()
  const docRef = doc(
    db,
    `organizations/${orgId}/notificationSettings/${notificationDocId}`
  ).withConverter(converter(NotificationSetting))
  return onSnapshot(
    docRef,
    doc => cb(doc.data()),
    error => {
      console.error(error)
      captureException(toSentry(error), {
        contexts: {
          listenToNotificationSetting: {
            orgId,
            sendType,
            ruleGroup,
          },
        },
        tags: {
          orgId,
        },
      })
    }
  )
}

export function listenToNotificationAction({
  orgId,
  sendType,
  ruleId,
  cb,
}: {
  orgId: string
  sendType: NotificationSendTypes
  ruleId: string
  cb: (action: Action | undefined) => void
}) {
  const actionType = getNotificationActionType(sendType)
  const db = getFirestore()
  const docRef = doc(
    db,
    `organizations/${orgId}/rules/${ruleId}/actions/${ActionType_Enum[actionType]}`
  ).withConverter(storedId(Action))
  return onSnapshot(
    docRef,
    doc => cb(doc.data()),
    error => {
      console.error(error)
      captureException(toSentry(error), {
        contexts: {
          listenToNotificationAction: {
            orgId,
            sendType,
            ruleId,
            actionId: actionType,
          },
        },
        tags: {
          orgId,
        },
      })
    }
  )
}
