import { SyncOutlined, UploadOutlined } from '@ant-design/icons'
import { captureException } from '@sentry/react'
import { Button, Switch, Upload } from 'antd'
import { UploadChangeParam, UploadFile } from 'antd/es/upload'
import { uploadOrgAsset } from 'api/databaseCalls'
import { OrgContext } from 'context'
import { Dialog, Heading, Pane, Text, toaster } from 'evergreen-ui'
import { deleteField, doc, getFirestore, setDoc } from 'firebase/firestore'
import { Organization } from 'gen/perkup/v1/organization_pb'
import { useContext, useState } from 'react'
import { converter } from 'utils/firestore'
import { toSentry } from 'utils/sentry'

export function ChangeCompanyLogo() {
  const { logoUrl, name, showNameLogo, id } = useContext(
    OrgContext
  ) as Organization

  const [showDialog, setShowDialog] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const handleLogoUpload = (upload: UploadChangeParam<UploadFile<any>>) => {
    if (!upload.file.originFileObj) return
    if (upload.file.percent === 100) {
      setIsLoading(true)
      uploadOrgAsset({ orgId: id, upload, field: 'logoUrl' })
        .then(() =>
          toaster.success('Company logo added', { id: upload.file.fileName })
        )
        .catch(() =>
          toaster.danger('Failed to upload image. Please contact support', {
            id: upload.file.fileName,
          })
        )
        .finally(() => setIsLoading(false))
    }
  }

  const handleRemoveLogo = () => {
    const db = getFirestore()
    const docRef = doc(db, `organizations/${id}`).withConverter(
      converter(Organization)
    )
    setDoc(
      docRef,
      {
        logoUrl: deleteField(),
      },
      { merge: true }
    )
      .then(() => {
        toaster.warning('Company logo removed')
        setShowDialog(false)
      })
      .catch(error => {
        console.error(error)
        captureException(toSentry(error), {
          contexts: {
            removeLogo: { id },
          },
        })
      })
  }

  const handleUpdateNavBranding = (checked: boolean) => {
    const db = getFirestore()
    const docRef = doc(db, `organizations/${id}`).withConverter(
      converter(Organization)
    )
    setDoc(docRef, { showNameLogo: checked }, { merge: true }).catch(error => {
      console.error(error)
      captureException(toSentry(error), {
        contexts: {
          updateNavBranding: { id, checked },
        },
      })
    })
  }

  return (
    <>
      <Pane>
        {logoUrl && (
          <Pane display="flex" alignItems="center" marginTop={8} gap={16}>
            <Switch
              size="small"
              checked={showNameLogo}
              onChange={handleUpdateNavBranding}
            />
            <Text size={300}>Display logo and company name in navigation</Text>
          </Pane>
        )}

        <Pane marginTop={32} marginBottom={4}>
          <Heading>Logo</Heading>
        </Pane>

        {logoUrl && (
          <Pane display="flex" alignItems="center">
            <img
              alt={name}
              src={logoUrl}
              style={{
                maxWidth: 256,
                maxHeight: 160,
                marginRight: 16,
              }}
            />
          </Pane>
        )}

        <Pane display="flex" gap={8} marginTop={16}>
          <Upload
            onChange={handleLogoUpload}
            maxCount={1}
            showUploadList={false}
            accept="image/*"
          >
            <Button
              style={{ display: 'flex', alignItems: 'center' }}
              loading={isLoading}
              icon={logoUrl ? <SyncOutlined /> : <UploadOutlined />}
            >
              {logoUrl ? 'Change' : 'Upload'} logo
            </Button>
          </Upload>
          {logoUrl && (
            <Button danger onClick={() => setShowDialog(true)}>
              Remove
            </Button>
          )}
        </Pane>
      </Pane>

      <Dialog
        title="Remove company logo"
        isShown={showDialog}
        onCloseComplete={() => setShowDialog(false)}
        onConfirm={handleRemoveLogo}
        confirmLabel="Remove"
        intent="danger"
      >
        <img
          alt={name}
          src={logoUrl}
          style={{
            maxWidth: 256,
            maxHeight: 160,
          }}
        />
      </Dialog>
    </>
  )
}
