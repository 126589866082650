import { DollarOutlined, PlusCircleOutlined } from '@ant-design/icons'
import { captureException } from '@sentry/react'
import {
  Button,
  ButtonProps,
  Flex,
  Input,
  InputNumber,
  Modal,
  Radio,
  RadioChangeEvent,
} from 'antd'
import { callFunction } from 'api/functionCalls'
import { PerkIconTooltipButton } from 'components'
import * as KEYS from 'constants/keys'
import {
  DEFAULT_TOPUP_AMOUNT,
  MIN_ACCOUNT_TOPUP_AMOUNT,
  MIN_TOPUP_PROCESS_FEE,
  STRIPE_FEE_MULTIPLIER,
  TOPUP_MARKUP_PERCENT,
} from 'constants/money'
import { OrgContext, UserContext } from 'context'
import {
  Alert,
  Link,
  Pane,
  Paragraph,
  Strong,
  Text,
  toaster,
} from 'evergreen-ui'
import { doc, getFirestore, setDoc } from 'firebase/firestore'
import {
  Organization,
  Organization_SubscriptionStatus,
} from 'gen/perkup/v1/organization_pb'
import React, { useContext, useMemo, useState } from 'react'
import { CreateTopUpInvoice } from 'services'
import Stripe from 'stripe'
import { numToDollars } from 'utils'
import { converter } from 'utils/firestore'
import { toSentry } from 'utils/sentry'

const paymentOptions: {
  label: string
  value: Stripe.Invoice.PaymentSettings.PaymentMethodType
}[] = [
  { label: 'Credit Card', value: 'card' },
  {
    label: 'Bank Transfer',
    value: 'customer_balance',
  },
]

const orgIdsWhoPayTakeRate = [
  'K32yEQkiilBBIr6vIqIW', // Muse
  '8n4BlxHeI4B4wNy9mEqd', // MFCP
  'URUzZhaCE6ejdqUDGpGr', // AEG
  'eCYCdSvIcuiYQtkDkoFT', // Crestline
  'iwuhvinovhk3A1LlmpPX', // Vertus
  'u6u3ZtMXJnYPWobwBVLO', // Tally
  'uM7DuaohCZ3OIVMMzKGa', // Whitmor
  KEYS.PERKUP_ORG_ID, // PerkUp main account
  'Iuur9RpfMMHhGVq12H8Z', // Micro Precision Calibration
  'TklgLm6VaUL3Rkc878uR', // PUBG
  'SXQAy4rKjuftreuXCBov', // TigerConnect
  'K2HPsMdFFd1QcmCEO06q', // Fishman Flooring
  'gh08fQFa0knNVkYlLfgN', // Turing
  'aLjbkgXYDcR4s3Srjpt9', // Passumpsic Bank
  '67lFuvCqMmeM7iK9rugh', // Wise
  'qeoTyNjMCtYszHovUKmY', // GenII
  '1MQiwZsds1xdeukAioVP', // Aquestive Therapeutics Inc.
  'plFRAL6tOrAKlWOrlw3y', // Orium
  '8Et8mAFcyWINTLfCeYJx', // Barrios
  'ddYtPv9CjuMSp84E0zzP', // Glowforge
  '1ySOohoifH33Oa4uo4H4', // Pathfinder Hospitality
]

export function AccountTopUpButton({
  accountId,
  useIconButton = false,
  defaultAmount = DEFAULT_TOPUP_AMOUNT,
  ...other
}: ButtonProps & {
  accountId: string
  useIconButton?: boolean
  defaultAmount?: number
}) {
  const org = useContext(OrgContext)
  const user = useContext(UserContext)

  const [isLoading, setIsloading] = useState(false)

  const [showDialog, setShowDialog] = useState(false)
  const [topupAmount, setTopupAmount] = useState(defaultAmount)
  const [memo, setMemo] = useState('')
  const [invoiceURL, setInvoiceURL] = useState('')
  const [selectedMode, setSelectedMode] =
    useState<Stripe.Invoice.PaymentSettings.PaymentMethodType>('card')

  const processingFees = useMemo(() => {
    if (selectedMode === 'card') {
      if (topupAmount < 10000) {
        return MIN_TOPUP_PROCESS_FEE
      }
      return topupAmount * STRIPE_FEE_MULTIPLIER
    }

    return 0
  }, [selectedMode, topupAmount])

  const underMinimumAmount = MIN_ACCOUNT_TOPUP_AMOUNT > topupAmount

  const orgId = org.id

  const includeTakeRate = orgIdsWhoPayTakeRate.includes(orgId)
  const perkupFee = includeTakeRate ? topupAmount * TOPUP_MARKUP_PERCENT : 0

  const handleCheckout = async () => {
    if (underMinimumAmount) {
      console.error('Under minimum amount')
      return
    }
    try {
      setIsloading(true)

      let { customerId } = org

      // Create Stripe Customer if one doesn't already exist
      if (!org.customerId) {
        const customer = await callFunction('stripe-CreateOrgCustomer', {
          name: org.name,
          orgId: org.id,
          userId: user.id,
          email: user.profile?.email,
        })
        customerId = customer.id
        // Add customer account to Organization
        const db = getFirestore()
        const docRef = doc(db, `organizations/${org.id}`).withConverter(
          converter(Organization)
        )
        await setDoc(docRef, { customerId }, { merge: true }).catch(error => {
          console.error(error)
          captureException(toSentry(error), {
            contexts: {
              updateOrgCustomer: {
                orgId: org.id,
                customerId,
              },
            },
          })
        })
      }

      const invoice = await CreateTopUpInvoice({
        customer: customerId,
        paymentMethod: selectedMode,
        orgId,
        amount: topupAmount,
        accountId,
        includeTakeRate,
        memo: memo || undefined,
      })
      const hostedInvoiceUrl = invoice?.hostedInvoiceUrl

      if (hostedInvoiceUrl) {
        setInvoiceURL(hostedInvoiceUrl)
      } else {
        toaster.warning('Something went wrong, please contact support')
      }
    } catch (error) {
      console.error(error)
      if (error instanceof Error) {
        toaster.danger(error.message)
      }
      captureException(toSentry(error), {
        contexts: {
          checkoutButton: {
            orgId: org.id,
            accountId,
          },
        },
      })
    } finally {
      setIsloading(false)
    }
  }

  const handleCTAClick = async (
    e:
      | React.MouseEvent<HTMLAnchorElement, MouseEvent>
      | React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.stopPropagation()
    setShowDialog(true)
  }

  const handleModalClose = () => {
    setShowDialog(false)
    setInvoiceURL('')
    setMemo('')
    setSelectedMode('card')
    setTopupAmount(defaultAmount)
  }

  const paymentMethodIsCard = selectedMode === 'card'

  return (
    <>
      {!useIconButton && (
        <Button
          type="default"
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          onClick={handleCTAClick}
          loading={isLoading}
          {...other}
        >
          Add funds
        </Button>
      )}
      {useIconButton && (
        <PerkIconTooltipButton
          title="Add funds"
          icon={<PlusCircleOutlined />}
          type="text"
          onClick={handleCTAClick}
          loading={isLoading}
        />
      )}
      <Modal
        title="Add funds"
        open={showDialog}
        onCancel={handleModalClose}
        onClose={handleModalClose}
        okText="Create invoice"
        onOk={handleCheckout}
        confirmLoading={isLoading}
        footer={!invoiceURL ? undefined : null}
        okButtonProps={underMinimumAmount ? { disabled: true } : undefined}
        destroyOnClose
      >
        {invoiceURL ? (
          <Pane>
            <Link href={invoiceURL} target="_blank" size={600}>
              View invoice
            </Link>
            {selectedMode === 'customer_balance' && (
              <Paragraph marginY={16} size={300}>
                Make sure to include the invoice number in the memo field of the
                transfer. For more information, refer to{' '}
                <Link
                  href="https://help.perkupapp.com/articles/848861-paying-invoices-by-bank-transfer"
                  target="_blank"
                >
                  paying invoices by bank transfer.
                </Link>
              </Paragraph>
            )}
          </Pane>
        ) : (
          <Flex vertical gap={16}>
            {org.subscriptionStatus ===
              Organization_SubscriptionStatus.active && (
              <Pane>
                <Strong>Payment options</Strong>
                <Pane marginY={16}>
                  <Radio.Group
                    onChange={(e: RadioChangeEvent) => {
                      setSelectedMode(e.target.value)
                    }}
                    value={selectedMode}
                  >
                    {paymentOptions.map(tab => (
                      <Radio.Button key={tab.label} value={tab.value}>
                        {tab.label}
                      </Radio.Button>
                    ))}
                  </Radio.Group>
                </Pane>
              </Pane>
            )}

            <Text>
              {paymentMethodIsCard
                ? 'Your funds will be available after paying the invoice.'
                : 'Your funds will be availabe once the bank transfer is complete.'}
            </Text>

            <Flex justify="space-between" align="center">
              <Strong>Top-up amount</Strong>

              <InputNumber
                prefix={<DollarOutlined />}
                value={topupAmount / 100}
                onChange={e => (e ? setTopupAmount(e * 100) : undefined)}
                autoFocus
                required
                width={188}
                min={MIN_ACCOUNT_TOPUP_AMOUNT}
              />
            </Flex>

            {underMinimumAmount && (
              <Alert
                title={`$${MIN_ACCOUNT_TOPUP_AMOUNT} minimum when topping up funds.`}
                intent="warning"
              />
            )}

            <Flex vertical gap={8}>
              {paymentMethodIsCard && (
                <Flex justify="space-between">
                  <Text>{`${
                    STRIPE_FEE_MULTIPLIER * 100
                  }% credit card fee ($3 minimum)`}</Text>

                  <Text>{numToDollars(processingFees)}</Text>
                </Flex>
              )}

              {includeTakeRate && (
                <Flex justify="space-between">
                  <Text>{`${TOPUP_MARKUP_PERCENT * 100}% PerkUp fee`}</Text>

                  <Text>{numToDollars(perkupFee)}</Text>
                </Flex>
              )}
              <Flex justify="space-between" align="center">
                <Text>Memo</Text>
                <Input
                  value={memo}
                  onChange={e => setMemo(e.target.value)}
                  placeholder="Optional"
                  style={{ maxWidth: 240 }}
                />
              </Flex>
            </Flex>

            <Pane
              display="flex"
              justifyContent="space-between"
              borderTop
              paddingTop={16}
            >
              <Strong>Total</Strong>
              <Strong>
                {numToDollars(
                  Math.round(processingFees + perkupFee + topupAmount)
                )}{' '}
                USD
              </Strong>
            </Pane>
          </Flex>
        )}
      </Modal>
    </>
  )
}
