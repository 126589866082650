import { ListOrgTemplatesByAccess } from 'api/databaseCalls'
import { Template } from 'gen/perkup/v1/org_template_pb'
import { useIndividualRole } from 'hooks'
import useIds from 'hooks/useIds'
import { useEffect, useState } from 'react'

export function useOrgTemplatesByAccess() {
  const { role } = useIndividualRole()
  const { orgId } = useIds()
  const [templates, setTemplates] = useState<Template[]>([])
  const [hasLoaded, setHasLoaded] = useState(false)

  useEffect(() => {
    if (!orgId || !role) {
      setTemplates([])
      setHasLoaded(true)
      return
    }
    ListOrgTemplatesByAccess({ orgId, role })
      .then(setTemplates)
      .finally(() => setHasLoaded(true))
  }, [orgId, role])

  return { templates, hasLoaded }
}
