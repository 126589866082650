// @generated by protoc-gen-es v1.6.0 with parameter "target=ts"
// @generated from file perkup/v1/root_user.proto (package perkup.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, Timestamp } from "@bufbuild/protobuf";
import { Individual_Role } from "./individual_pb.js";

/**
 * Firestore /users
 *
 * @generated from message perkup.v1.RootUser
 */
export class RootUser extends Message<RootUser> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: optional perkup.v1.RootUser.Address address = 2;
   */
  address?: RootUser_Address;

  /**
   * @generated from field: optional string current_organization = 3;
   */
  currentOrganization?: string;

  /**
   * @generated from field: perkup.v1.RootUser.Onboarding onboarding = 4;
   */
  onboarding?: RootUser_Onboarding;

  /**
   * @generated from field: repeated string organizations = 5;
   */
  organizations: string[] = [];

  /**
   * @generated from field: perkup.v1.RootUser.Profile profile = 6;
   */
  profile?: RootUser_Profile;

  /**
   * @generated from field: optional string cardholder_id = 7;
   */
  cardholderId?: string;

  /**
   * @generated from field: optional string customer_id = 8;
   */
  customerId?: string;

  /**
   * @generated from field: optional string location = 9;
   */
  location?: string;

  /**
   * @generated from field: optional string default_shipping_address_id = 10;
   */
  defaultShippingAddressId?: string;

  /**
   * @generated from field: optional string display_currency = 11;
   */
  displayCurrency?: string;

  /**
   * @generated from field: optional string country_id = 12;
   */
  countryId?: string;

  /**
   * @generated from field: optional google.protobuf.Timestamp created = 13;
   */
  created?: Timestamp;

  /**
   * @generated from field: optional string created_by = 14;
   */
  createdBy?: string;

  constructor(data?: PartialMessage<RootUser>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "perkup.v1.RootUser";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "address", kind: "message", T: RootUser_Address, opt: true },
    { no: 3, name: "current_organization", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 4, name: "onboarding", kind: "message", T: RootUser_Onboarding },
    { no: 5, name: "organizations", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 6, name: "profile", kind: "message", T: RootUser_Profile },
    { no: 7, name: "cardholder_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 8, name: "customer_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 9, name: "location", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 10, name: "default_shipping_address_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 11, name: "display_currency", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 12, name: "country_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 13, name: "created", kind: "message", T: Timestamp, opt: true },
    { no: 14, name: "created_by", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RootUser {
    return new RootUser().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RootUser {
    return new RootUser().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RootUser {
    return new RootUser().fromJsonString(jsonString, options);
  }

  static equals(a: RootUser | PlainMessage<RootUser> | undefined, b: RootUser | PlainMessage<RootUser> | undefined): boolean {
    return proto3.util.equals(RootUser, a, b);
  }
}

/**
 * @generated from message perkup.v1.RootUser.Address
 */
export class RootUser_Address extends Message<RootUser_Address> {
  /**
   * @generated from field: optional string city = 1;
   */
  city?: string;

  /**
   * @generated from field: optional string country = 2;
   */
  country?: string;

  /**
   * @generated from field: optional string line1 = 3;
   */
  line1?: string;

  /**
   * @generated from field: optional string line2 = 4;
   */
  line2?: string;

  /**
   * @generated from field: optional string postal_code = 5;
   */
  postalCode?: string;

  /**
   * @generated from field: optional string state = 6;
   */
  state?: string;

  constructor(data?: PartialMessage<RootUser_Address>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "perkup.v1.RootUser.Address";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "city", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 2, name: "country", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 3, name: "line1", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 4, name: "line2", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 5, name: "postal_code", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 6, name: "state", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RootUser_Address {
    return new RootUser_Address().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RootUser_Address {
    return new RootUser_Address().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RootUser_Address {
    return new RootUser_Address().fromJsonString(jsonString, options);
  }

  static equals(a: RootUser_Address | PlainMessage<RootUser_Address> | undefined, b: RootUser_Address | PlainMessage<RootUser_Address> | undefined): boolean {
    return proto3.util.equals(RootUser_Address, a, b);
  }
}

/**
 * @generated from message perkup.v1.RootUser.Onboarding
 */
export class RootUser_Onboarding extends Message<RootUser_Onboarding> {
  /**
   * @generated from field: bool complete = 1;
   */
  complete = false;

  /**
   * @generated from field: int32 page = 2;
   */
  page = 0;

  constructor(data?: PartialMessage<RootUser_Onboarding>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "perkup.v1.RootUser.Onboarding";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "complete", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "page", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RootUser_Onboarding {
    return new RootUser_Onboarding().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RootUser_Onboarding {
    return new RootUser_Onboarding().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RootUser_Onboarding {
    return new RootUser_Onboarding().fromJsonString(jsonString, options);
  }

  static equals(a: RootUser_Onboarding | PlainMessage<RootUser_Onboarding> | undefined, b: RootUser_Onboarding | PlainMessage<RootUser_Onboarding> | undefined): boolean {
    return proto3.util.equals(RootUser_Onboarding, a, b);
  }
}

/**
 * @generated from message perkup.v1.RootUser.Profile
 */
export class RootUser_Profile extends Message<RootUser_Profile> {
  /**
   * @generated from field: string email = 1;
   */
  email = "";

  /**
   * @generated from field: optional string first_name = 2;
   */
  firstName?: string;

  /**
   * @generated from field: optional string last_name = 3;
   */
  lastName?: string;

  /**
   * @generated from field: optional string profile_picture = 4;
   */
  profilePicture?: string;

  constructor(data?: PartialMessage<RootUser_Profile>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "perkup.v1.RootUser.Profile";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "first_name", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 3, name: "last_name", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 4, name: "profile_picture", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RootUser_Profile {
    return new RootUser_Profile().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RootUser_Profile {
    return new RootUser_Profile().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RootUser_Profile {
    return new RootUser_Profile().fromJsonString(jsonString, options);
  }

  static equals(a: RootUser_Profile | PlainMessage<RootUser_Profile> | undefined, b: RootUser_Profile | PlainMessage<RootUser_Profile> | undefined): boolean {
    return proto3.util.equals(RootUser_Profile, a, b);
  }
}

/**
 * Firestore /users/:id/shippingAddresses and /organizations/:id/shippingAddresses
 *
 * @generated from message perkup.v1.ShippingAddress
 */
export class ShippingAddress extends Message<ShippingAddress> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string line1 = 2;
   */
  line1 = "";

  /**
   * @generated from field: optional string line2 = 3;
   */
  line2?: string;

  /**
   * @generated from field: string city = 4;
   */
  city = "";

  /**
   * @generated from field: string state = 5;
   */
  state = "";

  /**
   * ISO 3166-1 alpha2 US,CA,...
   *
   * @generated from field: string country = 6;
   */
  country = "";

  /**
   * @generated from field: string postal_code = 7;
   */
  postalCode = "";

  /**
   * @generated from field: optional string phone_number = 8;
   */
  phoneNumber?: string;

  /**
   * @generated from field: string name = 9;
   */
  name = "";

  /**
   * @generated from field: optional perkup.v1.ShippingAddress.LocalizationExtensions localization_extensions = 10;
   */
  localizationExtensions?: ShippingAddress_LocalizationExtensions;

  constructor(data?: PartialMessage<ShippingAddress>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "perkup.v1.ShippingAddress";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "line1", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "line2", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 4, name: "city", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "state", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "country", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "postal_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "phone_number", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 9, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "localization_extensions", kind: "message", T: ShippingAddress_LocalizationExtensions, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ShippingAddress {
    return new ShippingAddress().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ShippingAddress {
    return new ShippingAddress().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ShippingAddress {
    return new ShippingAddress().fromJsonString(jsonString, options);
  }

  static equals(a: ShippingAddress | PlainMessage<ShippingAddress> | undefined, b: ShippingAddress | PlainMessage<ShippingAddress> | undefined): boolean {
    return proto3.util.equals(ShippingAddress, a, b);
  }
}

/**
 * @generated from message perkup.v1.ShippingAddress.LocalizationExtensions
 */
export class ShippingAddress_LocalizationExtensions extends Message<ShippingAddress_LocalizationExtensions> {
  /**
   * Brazil CPF
   *
   * @generated from field: optional string shipping_credential_br = 1;
   */
  shippingCredentialBr?: string;

  /**
   * China Resident ID
   *
   * @generated from field: optional string shipping_credential_cn = 2;
   */
  shippingCredentialCn?: string;

  /**
   * South Korea Personal Customs Clearance Code (PCCC)
   *
   * @generated from field: optional string shipping_credential_kr = 3;
   */
  shippingCredentialKr?: string;

  /**
   * @generated from field: optional string tax_credential_br = 4;
   */
  taxCredentialBr?: string;

  /**
   * @generated from field: optional string tax_credential_it = 5;
   */
  taxCredentialIt?: string;

  /**
   * @generated from field: optional string tax_email_it = 6;
   */
  taxEmailIt?: string;

  constructor(data?: PartialMessage<ShippingAddress_LocalizationExtensions>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "perkup.v1.ShippingAddress.LocalizationExtensions";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "shipping_credential_br", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 2, name: "shipping_credential_cn", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 3, name: "shipping_credential_kr", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 4, name: "tax_credential_br", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 5, name: "tax_credential_it", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 6, name: "tax_email_it", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ShippingAddress_LocalizationExtensions {
    return new ShippingAddress_LocalizationExtensions().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ShippingAddress_LocalizationExtensions {
    return new ShippingAddress_LocalizationExtensions().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ShippingAddress_LocalizationExtensions {
    return new ShippingAddress_LocalizationExtensions().fromJsonString(jsonString, options);
  }

  static equals(a: ShippingAddress_LocalizationExtensions | PlainMessage<ShippingAddress_LocalizationExtensions> | undefined, b: ShippingAddress_LocalizationExtensions | PlainMessage<ShippingAddress_LocalizationExtensions> | undefined): boolean {
    return proto3.util.equals(ShippingAddress_LocalizationExtensions, a, b);
  }
}

/**
 * Firestore /invites/:id
 *
 * @generated from message perkup.v1.RootInvitedUser
 */
export class RootInvitedUser extends Message<RootInvitedUser> {
  /**
   * stored in firestore
   *
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string email = 2;
   */
  email = "";

  /**
   * @generated from field: string org_id = 3;
   */
  orgId = "";

  /**
   * @generated from field: perkup.v1.Individual.Role role = 4;
   */
  role = Individual_Role.ROLE_UNSPECIFIED;

  constructor(data?: PartialMessage<RootInvitedUser>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "perkup.v1.RootInvitedUser";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "org_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "role", kind: "enum", T: proto3.getEnumType(Individual_Role) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RootInvitedUser {
    return new RootInvitedUser().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RootInvitedUser {
    return new RootInvitedUser().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RootInvitedUser {
    return new RootInvitedUser().fromJsonString(jsonString, options);
  }

  static equals(a: RootInvitedUser | PlainMessage<RootInvitedUser> | undefined, b: RootInvitedUser | PlainMessage<RootInvitedUser> | undefined): boolean {
    return proto3.util.equals(RootInvitedUser, a, b);
  }
}

