import { GetOrgTemplateById } from 'api/databaseCalls'
import { Template } from 'gen/perkup/v1/org_template_pb'
import useIds from 'hooks/useIds'
import { useEffect, useState } from 'react'

export function useOrgTemplate({ templateId }: { templateId?: string }) {
  const { orgId } = useIds()
  const [template, setTemplate] = useState<Template>()
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (!templateId || !orgId) {
      setTemplate(undefined)
      return
    }
    setIsLoading(true)
    GetOrgTemplateById({ templateId, orgId })
      .then(setTemplate)
      .finally(() => setIsLoading(false))
  }, [templateId, orgId])

  return { template, isLoading }
}
