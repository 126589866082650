import { SearchOutlined } from '@ant-design/icons'
import {
  Breadcrumb,
  Button,
  Flex,
  InputNumber,
  InputNumberProps,
  message,
} from 'antd'
import { updateCartItems } from 'api/databaseCalls/writes/carts'
import {
  AddressShortDisplay,
  BackIconButton,
  PerkEmpty,
  PerkLoader,
  VariantEstimatedShippingTime,
} from 'components'
import { COLLECTION, PRODUCT_VARIANT_ID, QUANITTY } from 'constants/params'
import { CARD, DEFAULT_ROUTES, HOME } from 'constants/routes'
import { SwagCollectionIdsContext, UserShippingAddressesContext } from 'context'
import { Heading } from 'evergreen-ui'
import { ProductDetails, ProductRetails } from 'features'
import { Supplier, Variable } from 'gen/canvas/designs_pb'
import { Cart, Cart_Item } from 'gen/perkup/v1/cart_pb'
import { ProductVariant } from 'gen/perkup/v1/product_variant_pb'
import { ShippingAddress } from 'gen/perkup/v1/root_user_pb'
import { useCollectionById } from 'hooks'
import useListAllProductVariantsByProductId from 'hooks/productVariants/useListAllProductVariantsByProductId'
import useIds from 'hooks/useIds'
import { isUndefined } from 'lodash-es'
import { useContext, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { Helmet } from 'react-helmet-async'
import {
  Link,
  useNavigate,
  useOutletContext,
  useParams,
  useSearchParams,
} from 'react-router-dom'
import { createCanvasDesign } from 'services'
import { ProductRetailsData, WithSelectedQuantity } from 'types'
import { convertMockupIdsToUrls } from 'utils'
import { designsAreIdentical } from 'utils/canvas-design'
import {
  buildFakeCustomizedProductVariant,
  shouldSkipPvInventoryChecks,
} from 'utils/productVariant'

function SwagStoreProductPageAsCustomizer({
  defaultVariant,
  onAddToCart,
}: {
  defaultVariant: ProductVariant
  onAddToCart: (addedPv: WithSelectedQuantity<ProductVariant>) => void
}) {
  const { orgId, individualId, userId } = useIds()

  const outletContext = useOutletContext() as {
    cart?: Cart
  }

  const cart = outletContext?.cart
  const cartLineItems = cart?.lineItems || []

  const [selectedQuantity, setSelectedQuantity] = useState(1)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [messageApi, contextHolder] = message.useMessage()

  const handleChangeQuantity: InputNumberProps['onChange'] = value => {
    if (typeof value === 'number') setSelectedQuantity(value)
  }

  const handleAddCustomizedProductToCart = async (data: ProductRetailsData) => {
    setIsSubmitting(true)

    if (!cart) {
      messageApi.error('Cart not found')
      setIsSubmitting(false)
      return
    }

    const {
      formData: {
        selectedProductVariant,
        selectedCustomerCanvasDesignId,
        selectedCustomerCanvasVariantId,
        selectedCustomerCanvasMockupIds,
        selectedCustomerCanvasOption,
        selectedVariables,
      },
    } = data

    const mockupUrls = convertMockupIdsToUrls(
      selectedCustomerCanvasMockupIds,
      selectedCustomerCanvasDesignId,
      selectedVariables
    )

    let updatedLineItems = cartLineItems

    // Before we create a new design, we need to check if the design already exists in the cart.
    const indexOfExistingItem = cartLineItems.findIndex(li =>
      designsAreIdentical({
        a: {
          productId: li.productId,
          productVariantId: li.productVariantId,
          variables: li.canvasDesign?.variables ?? [],
        },
        b: {
          productId: selectedProductVariant.productId,
          productVariantId: selectedProductVariant.id,
          variables: selectedVariables as Variable[],
        },
      })
    )

    // If the design already exists in the cart, update the quantity on that line item, else create a new design and add it to the cart.
    if (indexOfExistingItem !== -1) {
      updatedLineItems[indexOfExistingItem].quantity += selectedQuantity
    } else {
      const newDesign = await createCanvasDesign({
        orgId,
        individualId,
        userId,
        sku: selectedProductVariant?.sku,
        options: [selectedCustomerCanvasOption],
        customerCanvasVariantId: selectedCustomerCanvasVariantId,
        publicDesignId: selectedCustomerCanvasDesignId,
        mockupUrls,
        variables: selectedVariables,
        supplier: Supplier.printful,
        productId: selectedProductVariant.productId,
      })

      if (!newDesign) {
        messageApi.error('Failed to create design')
        setIsSubmitting(false)
        return
      }

      const newCartItem = new Cart_Item({
        productVariantId: selectedProductVariant.id,
        productId: selectedProductVariant.productId,
        provider: selectedProductVariant.provider,
        type: selectedProductVariant.type,
        quantity: selectedQuantity,
        canvasDesign: newDesign,
      })

      updatedLineItems = [...cartLineItems, newCartItem]
    }

    await updateCartItems({
      cartId: cart.id,
      lineItems: updatedLineItems,
    })

    const fakeProductVariant = buildFakeCustomizedProductVariant(
      selectedProductVariant,
      mockupUrls
    )

    const pvWithQty = Object.assign(fakeProductVariant, {
      selectedQuantity,
    })

    onAddToCart(pvWithQty)

    setIsSubmitting(false)
  }

  return (
    <>
      {contextHolder}

      <main className="mb-16">
        <ProductRetails
          onSubmit={handleAddCustomizedProductToCart}
          productVariant={defaultVariant}
        >
          <ProductRetails.Title />
          <ProductRetails.Price />
          <ProductRetails.Variants />
          <ProductRetails.Inputs />
          <Flex vertical gap={4}>
            <Heading size={400}>Quantity</Heading>
            <InputNumber
              min={1}
              defaultValue={1}
              onChange={handleChangeQuantity}
            />
          </Flex>
          <section className="fixed flex justify-end w-full py-4 px-8 bottom-0 left-0 bg-white z-10 gap-2">
            <Button loading={isSubmitting} htmlType="submit" type="primary">
              Add to cart
            </Button>
          </section>
          <ProductRetails.Shipping />
          <ProductRetails.Description />
        </ProductRetails>
      </main>
    </>
  )
}

function SwagStoreProductPageAsDetails({
  defaultVariant,
  onAddToCart,
}: {
  defaultVariant: ProductVariant
  onAddToCart: (addedPv: WithSelectedQuantity<ProductVariant>) => void
}) {
  const navigate = useNavigate()

  const shippingAddresses = useContext(UserShippingAddressesContext)
  const outletContext = useOutletContext() as {
    cart?: Cart
    shippingAddress?: ShippingAddress
    onShippingAddressChange: (address?: ShippingAddress) => void
  }

  const cart = outletContext?.cart
  const cartLineItems = cart?.lineItems || []

  const [messageApi, contextHolder] = message.useMessage()
  const [selectedQuantity, setSelectedQuantity] = useState(1)
  const [isSavingCart, setIsSavingCart] = useState(false)
  const [selectedProductVariant, setSelectedProductVariant] =
    useState<ProductVariant>()

  const handleAddNormalProductToCart = async (pvToAdd: ProductVariant) => {
    if (!cart) {
      messageApi.error('Cart not found')
      return
    }

    setIsSavingCart(true)

    // At the moment, highest distinction is by product variant id
    const cartItemIsAlreadyInCart = cartLineItems.find(
      li => li.productVariantId === pvToAdd.id
    )

    // If the product variant is already in the cart, update the quantity on that line item, else just add a the line item to the cart.
    const updatedLineItems = cartItemIsAlreadyInCart
      ? cartLineItems.map(cli =>
          cli.productVariantId === pvToAdd.id
            ? Object.assign(cli, {
                quantity: cli.quantity + selectedQuantity,
              })
            : cli
        )
      : [
          ...cartLineItems,
          new Cart_Item({
            productVariantId: pvToAdd.id,
            productId: pvToAdd.productId,
            quantity: selectedQuantity,
            provider: pvToAdd.provider,
            type: pvToAdd.type,
          }),
        ]

    await updateCartItems({
      cartId: cart.id,
      lineItems: updatedLineItems,
    })

    const pvWithQty = Object.assign(pvToAdd, {
      selectedQuantity,
    })

    onAddToCart(pvWithQty)

    setIsSavingCart(false)
  }

  const handleBuyNow = (product?: ProductVariant) => {
    if (!product) return
    const searchParams = new URLSearchParams({
      [PRODUCT_VARIANT_ID]: product.id,
      [QUANITTY]: selectedQuantity.toString(),
    })
    navigate({
      pathname: `${DEFAULT_ROUTES.SWAG.ROOT}/checkout`,
      search: searchParams.toString(),
    })
  }

  const handleChangeQuantity: InputNumberProps['onChange'] = value => {
    if (typeof value === 'number') setSelectedQuantity(value)
  }

  const pvForInventoryCheck = selectedProductVariant || defaultVariant

  // Disable the CTA if: Specific pv selected, inventory of pv is tracked, policy is not continue, and the selected quantity is greater than the inventory quantity
  const disableCta =
    !shouldSkipPvInventoryChecks(pvForInventoryCheck) &&
    !isUndefined(pvForInventoryCheck?.inventoryQuantity) &&
    pvForInventoryCheck.inventoryQuantity < selectedQuantity

  return (
    <>
      {contextHolder}
      <ProductDetails
        productVariant={defaultVariant}
        showPrice
        showShipping
        showShippingCountries
        submitButtonProps={{
          children: 'Add to cart',
          loading: isSavingCart,
        }}
        onSubmit={isMobile ? undefined : handleAddNormalProductToCart}
        onSelectedVariantChange={setSelectedProductVariant}
        disableSubmit={disableCta}
      >
        <ProductDetails.Slot name="aboveSubmit">
          <Flex vertical gap={4}>
            <Heading size={400}>Quantity</Heading>
            <InputNumber
              min={1}
              defaultValue={1}
              onChange={handleChangeQuantity}
            />
          </Flex>
        </ProductDetails.Slot>
        {!isMobile && (
          <ProductDetails.Slot name="nextToSubmit">
            <Button
              style={{ flex: 1 }}
              onClick={() =>
                handleBuyNow(selectedProductVariant ?? defaultVariant)
              }
            >
              Buy now
            </Button>
          </ProductDetails.Slot>
        )}
        {outletContext?.shippingAddress && (
          <ProductDetails.Slot name="aboveOptions">
            <AddressShortDisplay
              isMuted
              shippingAddress={outletContext?.shippingAddress}
              shippingAddresses={shippingAddresses}
              onShippingAddressChange={outletContext.onShippingAddressChange}
            />
          </ProductDetails.Slot>
        )}
        <ProductDetails.Slot name="shippingEstimation">
          <VariantEstimatedShippingTime
            productVariant={defaultVariant}
            shippingAddress={outletContext?.shippingAddress}
          />
        </ProductDetails.Slot>
      </ProductDetails>

      {isMobile && (
        <Flex
          gap={8}
          style={{
            position: 'fixed',
            padding: 16,
            width: '100%',
            bottom: 0,
            left: 0,
            backgroundColor: 'white',
          }}
        >
          <Button
            type="primary"
            size="large"
            style={{ flex: 1 }}
            loading={isSavingCart}
            onClick={() =>
              handleAddNormalProductToCart(
                selectedProductVariant ?? defaultVariant
              )
            }
          >
            Add to cart
          </Button>
          <Button
            size="large"
            style={{ flex: 1 }}
            onClick={() =>
              handleBuyNow(selectedProductVariant ?? defaultVariant)
            }
          >
            Buy now
          </Button>
        </Flex>
      )}
    </>
  )
}

function SwagStoreProductPageBreadcumbs({
  productName,
  collectionId,
}: {
  productName: string
  collectionId: string | undefined
}) {
  const swagCollections = useContext(SwagCollectionIdsContext)

  const { collection } = useCollectionById({
    id: collectionId,
  })

  const orgHasMultipleCollections = swagCollections.length > 1
  return (
    <Flex align="center" gap={8}>
      <BackIconButton
        to={
          collection?.id
            ? `${DEFAULT_ROUTES.SWAG.ROOT}/${collection.id}`
            : DEFAULT_ROUTES.SWAG.ROOT
        }
      />
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to={CARD}>Home</Link>
        </Breadcrumb.Item>
        {orgHasMultipleCollections && (
          <Breadcrumb.Item>
            <Link to={DEFAULT_ROUTES.SWAG.ROOT}>Collections</Link>
          </Breadcrumb.Item>
        )}
        {collection && (
          <Breadcrumb.Item>
            <Link to={`${DEFAULT_ROUTES.SWAG.ROOT}/${collection.id}`}>
              {collection.name}
            </Link>
          </Breadcrumb.Item>
        )}
        <Breadcrumb.Item>{productName}</Breadcrumb.Item>
      </Breadcrumb>
    </Flex>
  )
}

export function SwagStoreProductPage({
  onAddToCart,
}: {
  onAddToCart: (addedPv: WithSelectedQuantity<ProductVariant>) => void
}) {
  const navigate = useNavigate()
  const { productId } = useParams()
  const [searchParams] = useSearchParams()

  const collectionIdParam = searchParams.get(COLLECTION) || undefined

  const { defaultVariant, isLoadingInitial } =
    useListAllProductVariantsByProductId({
      productId,
    })

  if (isLoadingInitial) return <PerkLoader />

  if (!productId || !defaultVariant) {
    return (
      <PerkEmpty
        header="Product not found"
        description="We couldn’t find the product you were looking for."
        iconNode={<SearchOutlined style={{ fontSize: 42 }} />}
        ctaProps={{
          children: 'Go home',
          onClick: () => navigate(`${HOME}`),
          type: 'primary',
        }}
      />
    )
  }

  const productCanBeCustomized = defaultVariant.tags.includes('customizable')

  return (
    <>
      <Helmet>
        <title>{defaultVariant.productName}</title>
      </Helmet>
      <Flex
        vertical
        gap={32}
        style={{
          margin: 'auto',
          marginBottom: isMobile ? 104 : undefined,
          width: '100%',
          maxWidth: 1120, // Temporary fix for the product page width
        }}
      >
        <SwagStoreProductPageBreadcumbs
          productName={defaultVariant.productName}
          collectionId={collectionIdParam}
        />

        {productCanBeCustomized ? (
          <SwagStoreProductPageAsCustomizer
            defaultVariant={defaultVariant}
            onAddToCart={onAddToCart}
          />
        ) : (
          <SwagStoreProductPageAsDetails
            defaultVariant={defaultVariant}
            onAddToCart={onAddToCart}
          />
        )}
      </Flex>
    </>
  )
}
