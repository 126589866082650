import ThunderboltTwoTone from '@ant-design/icons/ThunderboltTwoTone'
import { captureMessage } from '@sentry/react'
import Button from 'antd/es/button'
import { createNewAction, createNewRule } from 'api/databaseCalls'
import { DEFAULT_ROUTES } from 'constants/routes'
import { OrgContext } from 'context'
import { CalendarIcon, EmptyState, Pane, toaster } from 'evergreen-ui'
import { AnimatePresence, motion } from 'framer-motion'
import { Individual } from 'gen/perkup/v1/individual_pb'
import { ProgramOccasion } from 'gen/perkup/v1/program_pb'
import { Action, ActionType_Enum, CustomAction } from 'gen/perkup/v1/rules_pb'
import { useIndividualRole } from 'hooks'
import isEmpty from 'lodash-es/isEmpty'
import { useContext, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { CreateRewardLocation } from 'types'
import { NotificationOccassions } from 'types/Notifications'
import { RuleType, RuleTypes } from 'types/rules'
import { getNotificationOccassionByPath } from 'utils'
import { instanceOfCustomAction } from './CalendarUtils'
import { EventAction } from './EventAction'
import { IEventInfo } from './EventDrawer'

export function EventActions({
  eventInfo,
  actions,
  ruleType,
}: {
  eventInfo: IEventInfo
  actions: Array<Action | CustomAction>
  ruleType: RuleType
}) {
  const navigate = useNavigate()
  const { yearsDiff, isBirthday } = eventInfo
  const org = useContext(OrgContext)
  const orgId = org?.id
  const orgName = org?.name

  const { isAdmin } = useIndividualRole()

  const { pathname } = useLocation()
  const ruleOccasion = getNotificationOccassionByPath(pathname)

  const [currentActionIndex, setCurrentActionIndex] = useState<number>(0)
  // Creates a new default rule with according to the individuals year
  const handleCTAClick = ({
    isAdmin,
    individuals = [],
  }: {
    isAdmin: boolean
    individuals: Individual[]
  }) => {
    if (isAdmin) {
      const anniversaryYear = ruleType === RuleTypes.newHires ? -1 : yearsDiff
      const year = isBirthday ? 0 : anniversaryYear

      if (!ruleOccasion) {
        captureMessage('No occasion, invalid path')
        toaster.warning('Error creating rule, please contact support')
        return
      }

      createNewRule({ orgId, occasion: ruleOccasion, year }) // First, create the rule according to the year
        .then(doc => {
          if (doc) {
            createNewAction({
              orgId,
              ruleId: doc.id,
              isBirthday,
              from: orgName,
              year,
              actionType: ActionType_Enum.createProgram,
            })
          }
        }) // Using the rule reference, create an action
        .then(() => toaster.success('Rule created'))
        .catch((error: any) => {
          console.error(error)
          toaster.warning('Something went wrong, try again or contact support.')
        })
    } else {
      const defaultOccasion =
        ruleOccasion === NotificationOccassions.ANNIVERSARY
          ? ProgramOccasion.workAnniversary
          : ProgramOccasion.birthday

      const createRewardState: CreateRewardLocation = {
        state: {
          recipientIndividuals: individuals,
          occasion: defaultOccasion,
        },
      }
      navigate(
        DEFAULT_ROUTES.ORGANIZATION.REWARDS.NEW_REWARD,
        createRewardState
      )
    }
  }

  if (isEmpty(actions)) {
    const firstName = eventInfo.eventIndividual?.firstName

    const lastName = eventInfo.eventIndividual?.lastName

    const displayName =
      firstName && lastName ? `${firstName} ${lastName}` : undefined

    const managerEmptyActionsText = displayName
      ? `Nothing scheduled for ${displayName}'s ${ruleOccasion}`
      : `Nothing scheduled for this ${ruleOccasion}`

    const emptyActionsText = isAdmin
      ? 'Nothing planned for this event'
      : managerEmptyActionsText

    const descriptionText = isAdmin
      ? 'To make this day special, create a rule.'
      : 'To make this day special, schedule a gift.'

    const CTAText = isAdmin ? 'Add rule' : 'Schedule gift'

    return (
      <Pane>
        <EmptyState
          background="light"
          title={emptyActionsText}
          orientation="vertical"
          icon={<CalendarIcon color="#EBAC91" />}
          iconBgColor="#F8E3DA"
          description={descriptionText}
          primaryCta={
            <Button
              style={{ marginTop: 16 }}
              type="primary"
              onClick={() =>
                handleCTAClick({
                  isAdmin,
                  individuals: [eventInfo.eventIndividual],
                })
              }
            >
              {CTAText}
            </Button>
          }
        />
      </Pane>
    )
  }

  // Determine action to render based on current selected index
  const currentAction = actions[currentActionIndex]
  const isCustomAction = instanceOfCustomAction(actions[currentActionIndex])

  return (
    <Pane display="flex" justifyContent="space-between">
      {/** Depending on selected rule, display the content appropriately */}
      <AnimatePresence mode="wait">
        <motion.div
          key={currentAction.ruleId}
          initial={{ y: 10, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          exit={{ y: -10, opacity: 0 }}
          transition={{ duration: 0.25 }}
        >
          <EventAction
            currentAction={currentAction}
            isCustomAction={isCustomAction}
            eventInfo={eventInfo}
          />
        </motion.div>
      </AnimatePresence>

      {/** Side menu of rules scheduled on this event */}
      <Pane display="flex" gap={8} flexDirection="column">
        {actions.map((action, index) => (
          <motion.div
            key={action.ruleId}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.97 }}
            whileFocus={{ scale: 1.05 }}
          >
            <Button
              onClick={() => setCurrentActionIndex(index)}
              type="default"
              icon={<ThunderboltTwoTone twoToneColor="#ffc400" />}
            >
              Rule {index + 1}
            </Button>
          </motion.div>
        ))}
      </Pane>
    </Pane>
  )
}
