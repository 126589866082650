export const currencies = [
  { value: 'usd', label: 'USD' },
  { value: 'aed', label: 'AED' },
  { value: 'afn', label: 'AFN' },
  { value: 'all', label: 'ALL' },
  { value: 'amd', label: 'AMD' },
  { value: 'ang', label: 'ANG' },
  { value: 'aoa', label: 'AOA' },
  { value: 'ars', label: 'ARS' },
  { value: 'aud', label: 'AUD' },
  { value: 'awg', label: 'AWG' },
  { value: 'azn', label: 'AZN' },
  { value: 'bam', label: 'BAM' },
  { value: 'bbd', label: 'BBD' },
  { value: 'bdt', label: 'BDT' },
  { value: 'bgn', label: 'BGN' },
  { value: 'bhd', label: 'BHD' },
  { value: 'bif', label: 'BIF' },
  { value: 'bmd', label: 'BMD' },
  { value: 'bnd', label: 'BND' },
  { value: 'bob', label: 'BOB' },
  { value: 'brl', label: 'BRL' },
  { value: 'bsd', label: 'BSD' },
  { value: 'bwp', label: 'BWP' },
  { value: 'byn', label: 'BYN' },
  { value: 'bzd', label: 'BZD' },
  { value: 'cad', label: 'CAD' },
  { value: 'cdf', label: 'CDF' },
  { value: 'chf', label: 'CHF' },
  { value: 'clp', label: 'CLP' },
  { value: 'cny', label: 'CNY' },
  { value: 'cop', label: 'COP' },
  { value: 'crc', label: 'CRC' },
  { value: 'cve', label: 'CVE' },
  { value: 'czk', label: 'CZK' },
  { value: 'djk', label: 'DJK' },
  { value: 'dkk', label: 'DKK' },
  { value: 'dop', label: 'DOP' },
  { value: 'dzd', label: 'DZD' },
  { value: 'egp', label: 'EGP' },
  { value: 'etb', label: 'ETB' },
  { value: 'eur', label: 'EUR' },
  { value: 'fjd', label: 'FJD' },
  { value: 'fkp', label: 'FKP' },
  { value: 'gbp', label: 'GBP' },
  { value: 'gel', label: 'GEL' },
  { value: 'gip', label: 'GIP' },
  { value: 'gmd', label: 'GMD' },
  { value: 'gnf', label: 'GNF' },
  { value: 'gtq', label: 'GTQ' },
  { value: 'gyd', label: 'GYD' },
  { value: 'hkd', label: 'HKD' },
  { value: 'hnl', label: 'HNL' },
  { value: 'hrk', label: 'HRK' },
  { value: 'htg', label: 'HTG' },
  { value: 'huf', label: 'HUF' },
  { value: 'idr', label: 'IDR' },
  { value: 'ils', label: 'ILS' },
  { value: 'inr', label: 'INR' },
  { value: 'isk', label: 'ISK' },
  { value: 'jmd', label: 'JMD' },
  { value: 'jod', label: 'JOD' },
  { value: 'jpy', label: 'JPY' },
  { value: 'kes', label: 'KES' },
  { value: 'kgs', label: 'KGS' },
  { value: 'khr', label: 'KHR' },
  { value: 'kmf', label: 'KMF' },
  { value: 'kwd', label: 'KWD' },
  { value: 'kyd', label: 'KYD' },
  { value: 'kzt', label: 'KZT' },
  { value: 'lak', label: 'LAK' },
  { value: 'lbp', label: 'LBP' },
  { value: 'lkr', label: 'LKR' },
  { value: 'lrd', label: 'LRD' },
  { value: 'lsl', label: 'LSL' },
  { value: 'mad', label: 'MAD' },
  { value: 'mdl', label: 'MDL' },
  { value: 'mga', label: 'MGA' },
  { value: 'mkd', label: 'MKD' },
  { value: 'mmk', label: 'MMK' },
  { value: 'mnt', label: 'MNT' },
  { value: 'mop', label: 'MOP' },
  { value: 'mro', label: 'MRO' },
  { value: 'mur', label: 'MUR' },
  { value: 'mvr', label: 'MVR' },
  { value: 'mwk', label: 'MWK' },
  { value: 'mxn', label: 'MXN' },
  { value: 'myr', label: 'MYR' },
  { value: 'mzn', label: 'MZN' },
  { value: 'nad', label: 'NAD' },
  { value: 'ngn', label: 'NGN' },
  { value: 'nio', label: 'NIO' },
  { value: 'nok', label: 'NOK' },
  { value: 'npr', label: 'NPR' },
  { value: 'nzd', label: 'NZD' },
  { value: 'omr', label: 'OMR' },
  { value: 'pab', label: 'PAB' },
  { value: 'pen', label: 'PEN' },
  { value: 'pgk', label: 'PGK' },
  { value: 'php', label: 'PHP' },
  { value: 'pkr', label: 'PKR' },
  { value: 'pln', label: 'PLN' },
  { value: 'pyg', label: 'PYG' },
  { value: 'qar', label: 'QAR' },
  { value: 'ron', label: 'RON' },
  { value: 'rsd', label: 'RSD' },
  { value: 'rub', label: 'RUB' },
  { value: 'rwf', label: 'RWF' },
  { value: 'sar', label: 'SAR' },
  { value: 'sbd', label: 'SBD' },
  { value: 'scr', label: 'SCR' },
  { value: 'sek', label: 'SEK' },
  { value: 'sgd', label: 'SGD' },
  { value: 'shp', label: 'SHP' },
  { value: 'sll', label: 'SLL' },
  { value: 'sos', label: 'SOS' },
  { value: 'srd', label: 'SRD' },
  { value: 'std', label: 'STD' },
  { value: 'szl', label: 'SZL' },
  { value: 'thb', label: 'THB' },
  { value: 'tjs', label: 'TJS' },
  { value: 'tnd', label: 'TND' },
  { value: 'top', label: 'TOP' },
  { value: 'try', label: 'TRY' },
  { value: 'ttd', label: 'TTD' },
  { value: 'twd', label: 'TWD' },
  { value: 'tzs', label: 'TZS' },
  { value: 'uah', label: 'UAH' },
  { value: 'ugx', label: 'UGX' },
  { value: 'uyu', label: 'UYU' },
  { value: 'uzs', label: 'UZS' },
  { value: 'vnd', label: 'VND' },
  { value: 'vuv', label: 'VUV' },
  { value: 'wst', label: 'WST' },
  { value: 'xaf', label: 'XAF' },
  { value: 'xcd', label: 'XCD' },
  { value: 'xof', label: 'XOF' },
  { value: 'xpf', label: 'XPF' },
  { value: 'yer', label: 'YER' },
  { value: 'zar', label: 'ZAR' },
  { value: 'zmw', label: 'ZMW' },
  { value: 'svc', label: 'SVC' },
  { value: 'vef', label: 'VEF' },
]

export const currencySymbols = {
  aed: 'د.إ',
  afn: '؋',
  all: 'L',
  amd: '֏',
  ang: 'ƒ',
  aoa: 'Kz',
  ars: 'AR$',
  aud: 'A$',
  awg: 'ƒ',
  azn: '₼',
  bam: 'KM',
  bbd: 'BAR$',
  bdt: '৳',
  bgn: 'лв',
  bhd: '.د.ب',
  bif: 'FBu',
  bmd: 'BE$',
  bnd: 'BR$',
  bob: '$b',
  bov: 'BOV',
  brl: 'R$',
  bsd: 'BAH$',
  btc: '₿',
  btn: 'Nu.',
  bwp: 'P',
  byn: 'Br',
  byr: 'Br',
  bzd: 'BZ$',
  cad: 'C$',
  cdf: 'FC',
  che: 'CHE',
  chf: 'CHF',
  chw: 'CHW',
  clf: 'CLF',
  clp: 'CH$',
  cny: '¥',
  cop: 'COP$',
  cou: 'COU',
  crc: '₡',
  cuc: 'CUC$',
  cup: '₱',
  cve: 'CV$',
  czk: 'Kč',
  djk: 'Fdj',
  dkk: 'kr',
  dop: 'RD$',
  dzd: 'دج',
  eek: 'kr',
  egp: '£',
  ern: 'Nfk',
  etb: 'Br',
  eth: 'Ξ',
  eur: '€',
  fjd: 'F$',
  fkp: '£',
  gbp: '£',
  gel: '₾',
  ggp: '£',
  ghc: '₵',
  ghs: 'GH₵',
  gip: '£',
  gmd: 'D',
  gnf: 'FG',
  gtq: 'Q',
  gyd: 'G$',
  hkd: 'HK$',
  hnl: 'L',
  hrk: 'kn',
  htg: 'G',
  huf: 'Ft',
  idr: 'Rp',
  ils: '₪',
  imp: '£',
  inr: '₹',
  iqd: 'ع.د',
  irr: '﷼',
  isk: 'kr',
  jep: '£',
  jmd: 'J$',
  jod: 'JD',
  jpy: '¥',
  kes: 'KSh',
  kgs: 'лв',
  khr: '៛',
  kmf: 'CF',
  kpw: '₩',
  krw: '₩',
  kwd: 'KD',
  kyd: 'K$',
  kzt: '₸',
  lak: '₭',
  lbp: '£',
  lkr: '₨',
  lrd: 'L$',
  lsl: 'M',
  ltc: 'Ł',
  ltl: 'Lt',
  lvl: 'Ls',
  lyd: 'LD',
  mad: 'MAD',
  mdl: 'lei',
  mga: 'Ar',
  mkd: 'ден',
  mmk: 'K',
  mnt: '₮',
  mop: 'MOP$',
  mro: 'UM',
  mru: 'UM',
  mur: '₨',
  mvr: 'Rf',
  mwk: 'MK',
  mxn: 'M$',
  mxv: 'MXV',
  myr: 'RM',
  mzn: 'MT',
  nad: 'NA$',
  ngn: '₦',
  nio: 'C$',
  nok: 'kr',
  npr: '₨',
  nzd: 'NZ$',
  omr: '﷼',
  pab: 'B/.',
  pen: 'S/.',
  pgk: 'K',
  php: '₱',
  pkr: '₨',
  pln: 'zł',
  pyg: 'Gs',
  qar: '﷼',
  rmb: '￥',
  ron: 'lei',
  rsd: 'Дин.',
  rub: '₽',
  rwf: 'R₣',
  sar: '﷼',
  sbd: 'SBD$',
  scr: '₨',
  sdg: 'ج.س.',
  sek: 'kr',
  sgd: 'S$',
  shp: '£',
  sll: 'Le',
  sos: 'S',
  srd: 'SRD$',
  ssp: '£',
  std: 'Db',
  stn: 'Db',
  svc: '$',
  syp: '£',
  szl: 'E',
  thb: '฿',
  tjs: 'SM',
  tmt: 'T',
  tnd: 'د.ت',
  top: 'T$',
  trl: '₤',
  try: '₺',
  ttd: 'TT$',
  tvd: 'T$',
  twd: 'NT$',
  tzs: 'TSh',
  uah: '₴',
  ugx: 'USh',
  usd: '$',
  uyi: 'UYI',
  uyu: '$U',
  uyw: 'UYW',
  uzs: 'лв',
  vef: 'Bs',
  ves: 'Bs.S',
  vnd: '₫',
  vuv: 'VT',
  wst: 'WS$',
  xaf: 'FCFA',
  xbt: 'Ƀ',
  xcd: 'EC$',
  xof: 'CFA',
  xpf: '₣',
  xsu: 'Sucre',
  xua: 'XUA',
  yer: '﷼',
  zar: 'R',
  zmw: 'ZK',
  zwd: 'Z$',
  zwl: '$',
}

export const USD = 'usd'
