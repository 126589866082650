import { GlobalOutlined, SearchOutlined } from '@ant-design/icons'
import { Flex, Input } from 'antd'
import { PerkScrollbars } from 'components'
import { Text } from 'evergreen-ui'
import { useState } from 'react'
import { Country } from 'types'
import { FlagAvatar } from './FlagAvatar'

function CountryNotFound() {
  return (
    <Flex vertical gap={8} align="center" style={{ marginTop: 16 }}>
      <GlobalOutlined style={{ fontSize: 24 }} />
      <Text>Country not found</Text>
    </Flex>
  )
}

export function CountryOverflowMenu({ countries }: { countries: Country[] }) {
  const [search, setSearch] = useState('')

  const countriesToDisplay = countries
    .filter(({ name }) => name.toLowerCase().includes(search.toLowerCase()))
    .sort((a, b) => a.name.localeCompare(b.name))

  return (
    <Flex vertical onClick={e => e.stopPropagation()}>
      <Input
        autoFocus
        allowClear
        value={search}
        onChange={e => setSearch(e.currentTarget.value)}
        style={{ position: 'sticky', top: 0, zIndex: 1 }}
        prefix={<SearchOutlined />}
      />

      <PerkScrollbars style={{ height: 180 }}>
        <Flex
          vertical
          gap={12}
          style={{
            padding: '12px 4px 0px 4px',
          }}
        >
          {countriesToDisplay.length === 0 ? (
            <CountryNotFound />
          ) : (
            countriesToDisplay.map(({ name, iso2 }) => (
              <Flex
                key={iso2}
                gap={4}
                align="center"
                style={{ marginRight: 32 }}
              >
                <FlagAvatar iso2={iso2} />
                <Text>{name}</Text>
              </Flex>
            ))
          )}
        </Flex>
      </PerkScrollbars>
    </Flex>
  )
}
