import {
  DollarOutlined,
  ShopOutlined,
  SkinOutlined,
  TrophyOutlined,
  UserOutlined,
} from '@ant-design/icons'
import { Flex } from 'antd'
import * as ROUTES from 'constants/routes'
import { SwagCollectionIdsContext } from 'context'
import { Text, useTheme } from 'evergreen-ui'
import { useDefaultOrgColors, useShopRoute } from 'hooks'
import { useSwagStoreRoute } from 'hooks/routingHooks/useSwagStoreRoute'
import { isEmpty } from 'lodash-es'
import { useContext } from 'react'
import { useLocation } from 'react-router'
import { Link } from 'react-router-dom'
import { NavigationTab } from 'types'

export function MemberMobileBottomNav() {
  const swagCollectionIds = useContext(SwagCollectionIdsContext)

  const { swagRoute } = useSwagStoreRoute()
  const { shopRoute } = useShopRoute()
  const { defaultColor: orgDefaultColor, backgroundColor: orgBackgroundColor } =
    useDefaultOrgColors()

  const theme = useTheme()
  const location = useLocation()

  const memberTabs: NavigationTab[] = [
    {
      name: 'Rewards',
      icon: <TrophyOutlined size={18} />,
      route: ROUTES.CARD,
      isSelected:
        location.pathname.includes(ROUTES.CARD) &&
        !(location.pathname === ROUTES.CARD_DETAILS),
    },
    {
      name: 'Shop',
      icon: <ShopOutlined size={18} />,
      route: shopRoute,
      isSelected: location.pathname.includes(ROUTES.SHOP),
    },
    {
      name: 'Swag',
      icon: <SkinOutlined size={18} />,
      route: swagRoute,
      isSelected: location.pathname.includes(ROUTES.SWAG),
      hidden: isEmpty(swagCollectionIds),
    },
    {
      name: 'Transactions',
      icon: <DollarOutlined size={18} />,
      route: ROUTES.TRANSACTIONS,
      isSelected: location.pathname.includes(ROUTES.TRANSACTIONS),
    },
    {
      name: 'Account',
      icon: <UserOutlined size={18} />,
      route: ROUTES.ACCOUNT,
      isSelected: location.pathname.includes(ROUTES.ACCOUNT),
    },
  ]

  return (
    <Flex
      style={{
        zIndex: 1,
        width: '100%',
        display: 'flex',
        position: 'fixed',
        bottom: 0,
        backgroundColor: theme.colors.white,
      }}
    >
      {memberTabs.map(tab => {
        if (tab.hidden) return null
        return (
          <Link
            key={tab.name}
            to={tab.route}
            style={{
              backgroundColor: tab.isSelected ? orgBackgroundColor : undefined,
              color: tab.isSelected ? orgDefaultColor : theme.colors.dark,
              borderTop: `1px solid ${
                tab.isSelected ? orgDefaultColor : theme.colors.gray100
              }`,
              padding: '16px 4px',
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: 8,
            }}
          >
            {tab.icon}
            <Text color={tab.isSelected ? orgDefaultColor : theme.colors.dark}>
              {tab.name}
            </Text>
          </Link>
        )
      })}
    </Flex>
  )
}
