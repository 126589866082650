import { GetVendorById } from 'api/databaseCalls'
import {
  REWARD_ACCEPTANCE_CHECKOUT,
  REWARD_ACCEPTANCE_CONFIRMATION,
  REWARD_ACCEPTANCE_REVEAL,
} from 'constants/routes'
import { MemberContext, OrgContext, ProgramContext } from 'context'
import { ProductCollection } from 'gen/perkup/v1/product_collections_pb'
import { ProductVariant } from 'gen/perkup/v1/product_variant_pb'
import { Program_Gift_FulfilledBy } from 'gen/perkup/v1/program_pb'
import { Vendor, VendorProduct } from 'gen/perkup/v1/vendor_pb'
import { Product } from 'gen/shopifyapi/admingql_pb'
import { isEmpty } from 'lodash-es'
import { useContext, useEffect, useState } from 'react'
import { AmazonProduct } from 'types'
import { buildProductVariantDisplayName } from './productVariant'

export const isRewardAcceptanceIndexRoute = () => {
  const path = window.location.pathname

  const isIndexRoute =
    !path.includes(REWARD_ACCEPTANCE_CHECKOUT) &&
    !path.includes(REWARD_ACCEPTANCE_REVEAL) &&
    !path.includes(REWARD_ACCEPTANCE_CONFIRMATION)

  return isIndexRoute
}

export function getRewardPreviewConfirmationDefaultThankYouText(
  isGift: boolean
) {
  const monetaryThankYouMessages = [
    "I'm truly touched by your generosity! 🌟",
    'Such a wonderful surprise! Thanks a lot! 😊',
    'This means a lot. Thank you! 🌼',
    'Overwhelmed by your kindness! 🤗 Thanks!',
    'Gratitude from the bottom of my heart!',
    "What a lovely gesture! Can't thank you enough. 😄",
    "I'm genuinely thankful for this!",
    'You made my day brighter! 🌞 Thanks!',
    'Beyond grateful for this token of appreciation! 🙌',
    'Touched by your thoughtfulness! 💐 Thank you!',
    'What a delightful surprise! 🎈 Cheers!',
    'This will be cherished! Heartfelt thanks! 🎉',
    'Grinning from ear to ear! 😁 Thank you!',
    'Your generosity is unparalleled! 💎 Thanks!',
    'This truly warmed my heart! 🔥 Thank you!',
    "Thank you so much for recognizing my work. It's greatly appreciated! 😊",
    "I'm really touched by your gesture of appreciation. Thanks a lot!",
    'Your acknowledgment of my efforts means a lot. Thank you! 🌟',
    "I'm grateful for your appreciation. It really means a lot!",
    "Thank you for valuing my contributions. It's very encouraging!",
    'Your recognition of my hard work is truly heartwarming. Thanks!',
    "I appreciate your gesture of thanks. It's a great morale booster! 🎉",
    'Thanks for acknowledging my efforts. It means a great deal to me.',
    "I'm honored by your recognition. Thank you so much! 💖",
    'Your appreciation has made my day. Thank you!',
    'Thanks for the recognition. It means a lot!',
    'Your thoughtfulness is really inspiring. Thank you! 😍',
    'I feel very appreciated. Thank you for acknowledging my hard work!',
    "Thank you for your kind gesture. It's very motivating!",
    "I'm overjoyed by your recognition. Thanks for making my efforts feel valued! 🌈",
    'Your appreciation means a lot to me. Thank you!',
    'Thanks for valuing my work. Your gesture is deeply appreciated.',
    "I'm truly grateful for your recognition. Thank you! 🙏",
    'Your gesture of appreciation is very encouraging. Thank you!',
    "I'm thankful for your acknowledgement. It's a great encouragement!",
    "Thank you for appreciating my efforts. It's very motivating!",
    'Your recognition is a big morale booster. Thank you! 😊',
    "I'm delighted by your gesture of appreciation. Thank you so much!",
    "Thanks for recognizing my hard work. It's really inspiring.",
    'Your appreciation is as wonderful as your leadership. Thank you! 🌟',
    'I feel honored to be recognized. Thank you for your thoughtful gesture!',
    "Thank you for your kind acknowledgement. It's truly motivating!",
    'Your appreciation is a great reward. Thank you for being considerate! 💕',
    "Thank you for recognizing my efforts. It's deeply appreciated!",
    'Your gesture of appreciation makes me feel valued. Thank you! 🙌',
    'I’m grateful for your recognition. Thanks for the encouragement!',
    'Your acknowledgement is very motivating. Thank you for your support!',
    "Thank you for your kind gesture. It's really appreciated.",
    'Your recognition means a lot. Thank you so much!',
    "Thank you for acknowledging my hard work. It's very inspiring!",
    "I'm thankful for your appreciation. It really means a lot.",
    "Thank you for recognizing my contributions. It's a great motivation.",
    'Your gesture is very inspiring. Thanks for valuing my hard work!',
    'Thank you for making my efforts feel appreciated! 🌞',
    "I'm really grateful for your recognition. It's truly motivating!",
    'Your appreciation is a great honor. Thank you for your support! 🌟',
    "Thank you for acknowledging my hard work. It's very encouraging!",
    'Your thoughtful recognition is much appreciated. Thank you!',
    "Thank you for your encouraging gesture. It's made my day! 😊",
    "I'm overwhelmed by your recognition. Thanks for the motivation!",
    'Your acknowledgement of my efforts is very inspiring. Thank you!',
    "Thank you for appreciating my work. It's very motivating!",
    "I'm grateful for your recognition. It's a big morale booster. Thank you!",
    "Thank you for making me feel valued with your recognition. It's truly special! 🌈",
  ]

  const giftThankYouMessages = [
    'Thank you for the thoughtful gift! 🎁 Much appreciated!',
    'I love it! You know me so well! 🙏',
    'This gift is perfect! Huge thanks! 🎉',
    "You've outdone yourself! Thanks a bunch! 🍀",
    'Over the moon with this gift! 🌙 Big thanks!',
    'Thank you so much for the thoughtful gift! I really appreciate it. 😊',
    'I was pleasantly surprised by your gift. Thanks a ton!',
    'Your gift made my day! Thank you for being so considerate. 🌟',
    "I can't thank you enough for such a wonderful gift. It means a lot to me!",
    'Your gift was incredibly generous. Thank you for your kindness! 🙏',
    'Thank you for the amazing gift! I really appreciate it!',
    "I'm truly grateful for your gift. It's perfect! Thanks a million. 🎁",
    'What a fantastic surprise! Thank you for your thoughtfulness.',
    "I'm really touched by your gift. Thank you for thinking of me. 🙌",
    'This gift brightened up my day! Thank you so much!',
    "Thanks for the wonderful gift. It's just what I needed!",
    'I absolutely love the gift you gave me. Thank you for being so thoughtful! 😍',
    'Your generosity is overwhelming. Thank you for the incredible gift!',
    'Thank you for the gift! You have a great sense of what I like!',
    "I'm overjoyed with the gift you gave me. Thanks for making my day special! 🎉",
    'Your gift was a delightful surprise. Thanks for being so generous!',
    'I appreciate your gift more than words can say. Thank you!',
    'The gift is amazing. Thank you for going out of your way to choose something so perfect! 🌈',
    'Thank you for your thoughtful gift. It really means a lot!',
    "I'm so grateful for your gift and your kindness. Thank you!",
    'Your gift was both thoughtful and practical. Thanks a lot!',
    "Thank you for the fantastic gift. It's just what I wanted! 😊",
    'I can’t stop smiling because of your gift. Thank you so much!',
    'Thanks for the awesome gift! You really know my taste well.',
    'Your gift is as wonderful as you are. Thank you for being amazing! 🌟',
    "I'm touched by your generosity. Thank you for the wonderful gift!",
    "Thank you for the unique gift. It's truly one-of-a-kind, just like you!",
    'Your gift is just perfect. Thank you for thinking of me! 💕',
    "Thank you for the gift, it's exactly what I needed!",
    'You have a special way of making people feel valued. Thank you for the gift! 🙌',
    'I’m blown away by your thoughtfulness. Thanks for the great gift!',
    'Your gift is deeply appreciated. Thank you for your kindness!',
    "Thank you for the lovely gift. It's very special, just like you.",
    "I'm truly grateful for your gift. It's perfect in every way! 🎁",
    'Your gift left me speechless. Thank you so much!',
    "Thank you for the gift, it's both thoughtful and beautiful!",
    "I'm really thankful for your gift. It shows how much you care. 💖",
    'Thank you for the wonderful surprise! Your gift is fantastic.',
    'Your gift is just what I wanted. Thanks for being so thoughtful!',
    'Thank you for brightening my day with your wonderful gift! 🌞',
    "I'm so thankful for your thoughtful gift. It's absolutely perfect!",
    'Your gift is a treasure. Thank you for your generosity! 🌟',
    'Thank you for the perfect gift! You have a great eye for detail.',
    'Your gift was a lovely surprise. Thank you for thinking of me!',
    "Thank you for the gift! It's really made my day special. 😊",
    "I'm overwhelmed by your kindness. Thanks for the amazing gift!",
    'Your gift is just wonderful. Thank you for being so thoughtful!',
    "Thank you for the gift, it's very thoughtful of you!",
    "I'm grateful for your kind gift. It's exactly what I needed. Thank you!",
    'Thank you for the incredible gift. You really made me feel special! 🌈',
  ]

  const defaultThankYouMessages = isGift
    ? giftThankYouMessages
    : monetaryThankYouMessages

  const randomIndex = Math.floor(Math.random() * defaultThankYouMessages.length)
  return defaultThankYouMessages[randomIndex]
}

export type RewardPreviewProductType =
  | AmazonProduct
  | Product
  | VendorProduct
  | ProductCollection

export function useProductType() {
  const member = useContext(MemberContext)
  const program = useContext(ProgramContext)

  const productId = member?.gift?.giftId || program.gift?.id

  const { gift } = program

  // TODO @CD - yoink this gid and airtable stuff out when we remove airtable

  const swappedToPvGift =
    program.gift?.id.slice(0, 3) === 'rec' &&
    !!member?.gift?.giftId &&
    member?.gift?.giftId.slice(0, 3) !== 'rec'

  const isNonSpecificVariantGift =
    gift?.productIds && gift?.productIds.length === 1

  const isProductVariantGift =
    !!gift?.productVariantId || !!isNonSpecificVariantGift || swappedToPvGift

  const isVendorGift =
    productId?.slice(0, 3) === 'rec' &&
    gift?.fulfilledBy === Program_Gift_FulfilledBy.manual &&
    !isProductVariantGift

  return {
    isAmazonGift: gift?.fulfilledBy === Program_Gift_FulfilledBy.amazon,
    isProductVariantGift,
    isVendorGift,
    isMultiProductGift: !!gift?.productIds && gift?.productIds.length > 1,
    isCollectionGift: !!gift?.productCollectionId,
  }
}

export function useProductInfo({
  product,
  isAmazonGift,
  isProductVariantGift,
  isVendorGift,
  isCollectionGift,
  isMultiProductGift,
  selectedProductVariants,
}: {
  product: RewardPreviewProductType | undefined
  isAmazonGift: boolean
  isProductVariantGift: boolean
  isVendorGift: boolean
  isCollectionGift: boolean
  isMultiProductGift: boolean
  selectedProductVariants: ProductVariant[]
}) {
  const [vendor, setVendor] = useState<Vendor>()
  const org = useContext(OrgContext)
  const orgName = org?.name

  useEffect(() => {
    if (isVendorGift && product) {
      const vendorProduct = product as VendorProduct
      const { vendorId } = vendorProduct
      if (vendorId) {
        GetVendorById({ vendorId }).then(setVendor)
      }
    }
  }, [isVendorGift, product])

  let productTitle: string = ''
  let productVendorTitle: string = ''
  let productImage: string = ''

  if (product) {
    if (isAmazonGift) {
      const amazonProduct = product as AmazonProduct
      productVendorTitle = 'Amazon Business'
      productTitle = amazonProduct.title
      productImage = amazonProduct.includedDataTypes.IMAGES?.length
        ? amazonProduct.includedDataTypes.IMAGES[0].large.url
        : ''
    }

    if (isVendorGift) {
      const vendorProduct = product as VendorProduct
      productVendorTitle = vendor?.name ?? ''
      productTitle = vendorProduct.name
      productImage = vendorProduct.images[0] ?? ''
    }
  }

  if (
    !isEmpty(selectedProductVariants) &&
    (isProductVariantGift || isCollectionGift || isMultiProductGift)
  ) {
    productVendorTitle = orgName
    productTitle = buildProductVariantDisplayName({
      productVariant: selectedProductVariants[0],
    })
    productImage = selectedProductVariants[0]?.imageUrl || ''
  }
  return { productTitle, productVendorTitle, productImage }
}

/**
 * @param pathname of the current page/location
 * @param isGift the type of reward of the program
 * @returns completion percentage of the accept reward experience
 */
export function getPageProgressPercent(pathname: string): number {
  // Percentages per page depending on isGift
  const LANDING = 10
  const REVEAL = 50
  const CHECKOUT = 75
  const CONFIRMATION = 100

  if (pathname.includes(REWARD_ACCEPTANCE_REVEAL)) {
    return REVEAL
  }

  if (pathname.includes(REWARD_ACCEPTANCE_CHECKOUT)) {
    return CHECKOUT
  }

  if (pathname.includes(REWARD_ACCEPTANCE_CONFIRMATION)) {
    return CONFIRMATION
  }

  return LANDING
}
