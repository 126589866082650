import { callFunction } from 'api/functionCalls'
import { OrgContext } from 'context'
import { Program } from 'gen/perkup/v1/program_pb'
import { useContext, useEffect, useState } from 'react'

export function useListMemberIdsByProgram({ program }: { program: Program }) {
  const org = useContext(OrgContext)
  const { id: programId, totalMembers } = program
  const [memberIds, setMemberIds] = useState<string[]>([])
  const [isLoadingMore, setIsLoadingMore] = useState(false)
  const [hasLoadedInitial, setHasLoadedInitial] = useState(false)

  useEffect(() => {
    callFunction('firestore-ListMemberIdsByProgramId', {
      programId,
      orgId: org.id,
    })
      .then(res => {
        if (!res) return
        setMemberIds(res)
      })
      .finally(() => {
        setHasLoadedInitial(true)
      })
  }, [org.id, programId])

  // 5 seconds after totalMembers changes, re-fetch memberIds if they don't match
  useEffect(() => {
    if (!hasLoadedInitial) return undefined
    if (totalMembers !== memberIds.length) {
      const timer = setTimeout(() => {
        setIsLoadingMore(true)
        callFunction('firestore-ListMemberIdsByProgramId', {
          programId,
          orgId: org.id,
        })
          .then(res => {
            if (!res) return
            setMemberIds(res)
          })
          .finally(() => {
            setIsLoadingMore(false)
          })
      }, 10000)
      return () => clearTimeout(timer)
    }
    return undefined
  }, [hasLoadedInitial, memberIds.length, org.id, programId, totalMembers])

  const isSendingRewards = hasLoadedInitial && memberIds.length !== totalMembers

  return {
    isLoadingMore,
    hasLoadedInitial,
    memberIds,
    setMemberIds,
    isSendingRewards,
  }
}
