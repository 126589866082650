import { GetOrderFulfillmentsByOrderId } from 'api/databaseCalls/reads/orderFullfilments'
import { Order, OrderFulfillment } from 'gen/perkup/v1/vendor_pb'
import { useEffect, useState } from 'react'

export default function useOrderFulfillmentsByOrder({
  order,
}: {
  order?: Order
}) {
  const [orderFulfillments, setOrderFullfilments] =
    useState<OrderFulfillment[]>()

  useEffect(() => {
    if (order) {
      GetOrderFulfillmentsByOrderId({ orderId: order.id }).then(
        setOrderFullfilments
      )
    } else {
      setOrderFullfilments(undefined)
    }
  }, [order])

  return { orderFulfillments }
}
