import { Tag } from 'antd'
import { FulfillmentStatus } from 'api/tinybirdCalls/types'
import { ORDER_FULFILLMENT_STATUS } from 'constants/orders'
import { OrderFulfillment_FulfillmentStatus_Enum } from 'gen/perkup/v1/vendor_pb'

export function OrderStatusTag({
  status,
  withIcon = false,
}: {
  status:
    | FulfillmentStatus
    | OrderFulfillment_FulfillmentStatus_Enum
    | undefined
  withIcon?: boolean
}) {
  if (!status || !ORDER_FULFILLMENT_STATUS[status])
    return (
      <Tag color="error" style={{ width: 'fit-content' }}>
        Error
      </Tag>
    )

  const { color, label, Icon } = ORDER_FULFILLMENT_STATUS[status]

  return (
    <Tag
      color={color}
      icon={withIcon ? <Icon /> : undefined}
      style={{ width: 'fit-content' }}
    >
      {label}
    </Tag>
  )
}
