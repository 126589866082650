import { captureException } from '@sentry/react'
import {
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  query,
  where,
} from 'firebase/firestore'
import { CanvasDesign } from 'gen/canvas/designs_pb'
import { toSentry } from 'utils'
import { storedId } from 'utils/firestore'

export async function GetCanvasDesignById(designId: string) {
  try {
    const db = getFirestore()
    const docRef = doc(db, `canvasDesigns/${designId}`).withConverter(
      storedId(CanvasDesign)
    )
    const canvasDesign = await getDoc(docRef)
    return canvasDesign.data()
  } catch (error) {
    console.error(error)
    captureException(toSentry(error), {
      contexts: {
        GetCanvasDesignById: { designId },
      },
    })
  }
}

/**
 * Get by using in operator, this is a batch read. Need to paginate if number of ids is larger than 30
 */
export async function ListCanvasDesignsByIds({
  ids,
  orgId,
}: {
  ids: string[]
  orgId: string
}) {
  try {
    const db = getFirestore()
    const collectionRef = collection(db, 'canvasDesigns').withConverter(
      storedId(CanvasDesign)
    )

    const q = query(
      collectionRef,
      where('id', 'in', ids),
      where('orgId', '==', orgId)
    )
    const querySnapshot = await getDocs(q)

    return querySnapshot.docs.map(doc => doc.data())
  } catch (error) {
    console.error(error)
    captureException(toSentry(error), {
      contexts: {
        ListCanvasDesignsByIds: { ids },
      },
    })
    return []
  }
}
