import { RewardUseCase } from 'constants/newReward/rewardUseCases'
import {
  ALL_USE_CASES,
  CurrentUseCase,
  FEATURED,
  regularSearchCategories,
} from 'constants/themeEditor'
import { ProgramTemplate } from 'gen/perkup/v1/contentful_pb'
import { ProgramOccasion } from 'gen/perkup/v1/program_pb'
import { useBrandedImagesByUseCase } from 'hooks/images/use-branded-images-by-use-case'
import { filterTemplatesByUseCase } from 'pages/NewReward/utils/program-templates'
import { getSavedProgramImageQuery } from 'pages/NewReward/utils/programs'
import {
  getDefaultSearchQueryFromOccasion,
  getUseCasesFromOccasion,
} from 'pages/NewReward/utils/uiUtils'
import useInfiniteProgramTemplates from 'pages/Templates/hooks/useInfiniteProgramTemplates'
import { useEffect, useMemo, useState } from 'react'
import { getKeys } from 'utils'
import { isNotAllUseCasesOrFeatured } from './util'

export function useRewardsUseCases({
  occasion,
  defaultQuery,
  programId,
}: {
  occasion: ProgramOccasion | undefined
  defaultQuery: string | undefined
  programId: string | undefined
}) {
  const [currentUseCase, setCurrentUseCase] = useState<CurrentUseCase>(
    occasion ? ALL_USE_CASES : FEATURED
  )

  const [searchQuery, setSearchQuery] = useState('')

  const useCaseOnProgram = useMemo(() => {
    if (currentUseCase === ALL_USE_CASES || currentUseCase === FEATURED) {
      return undefined
    }
    return currentUseCase
  }, [currentUseCase])

  const currentOccasionUseCases = useMemo(() => {
    return occasion ? getUseCasesFromOccasion(occasion) : undefined
  }, [occasion])

  const rewardUseCasesToRender: CurrentUseCase[] = useMemo(() => {
    if (currentOccasionUseCases) {
      return [ALL_USE_CASES, ...currentOccasionUseCases]
    }
    return regularSearchCategories
  }, [currentOccasionUseCases])

  // If the defaultQuery ever changes from the parent, update the search bar state
  useEffect(() => {
    const savedQuery = getSavedProgramImageQuery({ programId })
    if (defaultQuery) {
      setCurrentUseCase(FEATURED)
      setSearchQuery(defaultQuery)
    } else if (savedQuery) {
      setSearchQuery(savedQuery)
    } else if (occasion) {
      const query = getDefaultSearchQueryFromOccasion({ occasion })
      setSearchQuery(query)
    }
  }, [defaultQuery, occasion, programId])

  const rewardUseCasesToSearch = useMemo(() => {
    return !isNotAllUseCasesOrFeatured(currentUseCase)
      ? currentOccasionUseCases
      : [currentUseCase]
  }, [currentUseCase, currentOccasionUseCases])

  return {
    rewardUseCasesToRender,
    currentUseCase,
    setCurrentUseCase,
    searchQuery,
    setSearchQuery,
    useCaseOnProgram,
    rewardUseCasesToSearch,
  }
}

export function useProgramTemplates({
  rewardUseCasesToSearch,
}: {
  rewardUseCasesToSearch: RewardUseCase[] | undefined
}) {
  const { programTemplates } = useInfiniteProgramTemplates({
    startingMaxIndex: 1,
  })

  const { brandedImages } = useBrandedImagesByUseCase(
    rewardUseCasesToSearch ?? []
  )

  const programTemplatesToRender = useMemo(() => {
    return (rewardUseCasesToSearch ?? []).reduce(
      (acc, useCase) => ({
        ...acc,
        [useCase]: filterTemplatesByUseCase({
          templates: programTemplates,
          useCases: [useCase],
        }),
      }),
      {} as Record<RewardUseCase, ProgramTemplate[]>
    )
  }, [programTemplates, rewardUseCasesToSearch])

  const featured = Array.from(
    new Set([...getKeys(programTemplatesToRender), ...getKeys(brandedImages)])
  )
    .map(useCase => ({
      useCase,
      programTemplates: programTemplatesToRender[useCase] ?? [],
      brandedImages: brandedImages[useCase] ?? [],
    }))
    .filter(
      ({ programTemplates, brandedImages }) =>
        programTemplates.length > 0 || brandedImages.length > 0
    )

  return { featured }
}
