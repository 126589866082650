import Table, { ColumnsType } from 'antd/es/table'
import { FetchTinyBirdDataByOrgId } from 'api/tinybirdCalls/reads'
import { PerkLoader, PerkScrollbars, UserAvatar } from 'components'
import { tablePaginationSettings } from 'constants/antdesign'
import { TOP_SENDERS_URL } from 'constants/tinybird'
import { Pane, Strong, Text } from 'evergreen-ui'
import { useDefaultOrgColors } from 'hooks'
import { useDevSafeOrgId } from 'hooks/useDevSafeOrgId'
import { isEmpty } from 'lodash-es'
import { useEffect, useState } from 'react'
import { TopSendersData } from 'types/TinyBird'
import {
  COLUMN_MAX_HEIGHT,
  COLUMN_MIN_HEIGHT,
  COLUMN_WIDTH,
} from '../constants/insights'
import { NoData } from './NoData'

interface TopSendersFormattedData extends TopSendersData {
  rank: number
  defaultColor: string
}

const columns: ColumnsType<TopSendersFormattedData> = [
  {
    title: '',
    dataIndex: 'rank',
    key: 'rank',
    render: rank => <Text>{rank}</Text>,
  },
  {
    title: 'Name',
    dataIndex: 'firstName',
    key: 'name',
    render: (firstName, record) => {
      const senderName = `${firstName} ${record.lastName}`
      return (
        <Pane display="flex" gap={16} alignItems="center">
          <UserAvatar
            email={record.email}
            name={isEmpty(senderName.trim()) ? record.email : senderName}
            profilePicture={record.profilePicture}
            pfpSize={40}
          />
          <Pane display="flex" flexDirection="column" gap={8}>
            <Text>{senderName}</Text>
            <Text color={record.defaultColor}>{record.email}</Text>
          </Pane>
        </Pane>
      )
    },
  },
  {
    title: 'Rewards sent',
    key: 'rewardsSent',
    dataIndex: 'rewardsSent',
    render: rewardsSent => {
      return <Text>{rewardsSent}</Text>
    },
  },
]

export function TopSenders() {
  const { defaultColor } = useDefaultOrgColors()
  const orgId = useDevSafeOrgId()

  const [topSenders, setTopSenders] = useState<TopSendersData[]>()
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    setIsLoading(true)
    FetchTinyBirdDataByOrgId({
      orgId,
      cb: setTopSenders,
      dataTitle: 'top senders',
      baseUrl: TOP_SENDERS_URL,
    }).finally(() => setIsLoading(false))
  }, [orgId])

  const formattedData = topSenders?.map((sender, index) => {
    return {
      ...sender,
      rank: index + 1,
      defaultColor,
    }
  })

  const hasData = !isLoading && !isEmpty(formattedData)
  const hasNoData = !isLoading && isEmpty(formattedData)

  return (
    <Pane
      width={COLUMN_WIDTH}
      maxHeight={COLUMN_MAX_HEIGHT}
      minHeight={COLUMN_MIN_HEIGHT}
      border="muted"
      display="flex"
      flexDirection="column"
      borderRadius={8}
      paddingY={16}
      gap={16}
    >
      <Pane display="flex" flexDirection="column" paddingX={32} gap={16}>
        <Pane display="flex" flexDirection="column" gap={4}>
          <Strong>Top senders</Strong>
          <Text size={300} color="muted">
            Last 30 days
          </Text>
        </Pane>
        {hasNoData && <NoData />}
      </Pane>
      {isLoading && <PerkLoader />}

      {hasData && (
        <PerkScrollbars>
          <Table
            columns={columns}
            dataSource={formattedData}
            pagination={tablePaginationSettings}
          />
        </PerkScrollbars>
      )}
    </Pane>
  )
}
