import { Account_Permission } from 'gen/perkup/v1/account_pb'
import { ProductCollection_Permission } from 'gen/perkup/v1/product_collections_pb'

export const PERMISSION_ADMIN = 'admin'
export const PERMISSION_MANAGER = 'manager'

export type Permission = Account_Permission | ProductCollection_Permission
export type Permissions = Record<string, Permission>

export interface PermissionOption {
  value: Permission
  name: string
  description: string
}
