import { MemberContext, ProgramContext } from 'context'
import { useContext } from 'react'

export function useGiftRedeemed(): boolean {
  const program = useContext(ProgramContext)
  const member = useContext(MemberContext)
  const isGift = !!program?.gift
  const giftRedeemed = isGift && member?.spent ? member.spent > 0 : false
  return giftRedeemed
}
