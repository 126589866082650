import { captureException } from '@sentry/react'
import {
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  onSnapshot,
  query,
  where,
} from 'firebase/firestore'
import {
  Domain,
  InvitedUser,
  Organization,
} from 'gen/perkup/v1/organization_pb'
import { ShippingAddress } from 'gen/perkup/v1/root_user_pb'
import compact from 'lodash-es/compact'
import { converter } from 'utils/firestore'
import { toSentry } from 'utils/sentry'

/**
 * Organizations
 */

export function ListenToOrgById({
  id,
  cb,
}: {
  id: string
  cb: React.Dispatch<React.SetStateAction<Organization | undefined>>
}) {
  const db = getFirestore()
  const docRef = doc(db, `organizations/${id}`).withConverter(
    converter(Organization)
  )
  return onSnapshot(
    docRef,
    doc => cb(doc.data()),
    error => {
      console.error(error)
      captureException(toSentry(error), {
        contexts: { ListenToOrgById: { orgId: id } },
        tags: { orgId: id },
      })
    }
  )
}

/**
 * Invited Users
 */

export async function getInvitedUsersByEmail({
  orgId,
  email,
}: {
  orgId: string
  email: string
}) {
  try {
    const db = getFirestore()
    const colRef = collection(
      db,
      `organizations/${orgId}/invitedUsers`
    ).withConverter(converter(InvitedUser))
    const q = query(colRef, where('email', '==', email))
    return await getDocs(q).then(query => query.docs.map(doc => doc.data()))
  } catch (error) {
    console.error(error)
    captureException(toSentry(error), {
      contexts: {
        getInvitedUserByEmail: { orgId, email },
      },
      tags: { orgId },
    })
  }
  return []
}

export function listenToPendingMembersByOrgId({
  id,
  cb,
}: {
  id: string
  cb: (data: InvitedUser[]) => void
}) {
  const db = getFirestore()
  const colRef = collection(
    db,
    `organizations/${id}/invitedUsers`
  ).withConverter(converter(InvitedUser))
  const q = query(colRef)
  return onSnapshot(
    q,
    query => cb(query.docs.map(doc => doc.data())),
    error => {
      console.error(error)
      captureException(toSentry(error), {
        contexts: {
          listenToPendingMembersByOrgID: { orgId: id },
        },
        tags: { orgId: id },
      })
    }
  )
}

export async function ListOrgsByOrgIds({ orgIds }: { orgIds: string[] }) {
  const orgs: Organization[] = []
  try {
    const db = getFirestore()
    compact(
      await Promise.all(
        orgIds.map(orgId => {
          const docRef = doc(db, `organizations/${orgId}`).withConverter(
            converter(Organization)
          )
          return getDoc(docRef).then(doc => doc.data())
        })
      )
    ).forEach(org => orgs.push(org))
  } catch (error) {
    console.error(error)
    captureException(toSentry(error), {
      contexts: {
        ListOrgsByOrgIds: { orgIds },
      },
    })
  }

  return orgs
}

export function ListenToOrgDomains({
  orgId,
  cb,
}: {
  orgId: string
  cb: React.Dispatch<React.SetStateAction<Domain[]>>
}) {
  if (!orgId || !cb) return undefined
  const db = getFirestore()
  const colRef = collection(db, 'domains').withConverter(converter(Domain))
  const q = query(colRef, where('orgId', '==', orgId))
  return onSnapshot(
    q,
    querySnapshot => cb(querySnapshot.docs.map(domain => domain.data())),
    error => {
      console.error(error)
      captureException(toSentry(error), {
        contexts: {
          ListenToOrgDomains: { orgId },
        },
        tags: { orgId },
      })
    }
  )
}

export async function GetDomainById({ domain }: { domain: string }) {
  if (!domain) return undefined
  try {
    const db = getFirestore()
    const docRef = doc(db, `domains/${domain}`).withConverter(converter(Domain))
    return await getDoc(docRef).then(doc => doc.data())
  } catch (error) {
    console.error(error)
    captureException(toSentry(error), {
      contexts: {
        GetDomainById: { domain },
      },
    })
  }
}

export async function ListOrgShippingAddresses({ orgId }: { orgId: string }) {
  try {
    const db = getFirestore()
    const colRef = collection(
      db,
      `organizations/${orgId}/shippingAddresses`
    ).withConverter(converter(ShippingAddress))
    const querySnapshot = await getDocs(colRef)
    const shippingAddresses = querySnapshot.docs.map(doc => doc.data())
    return shippingAddresses
  } catch (error) {
    console.error(error)
    captureException(toSentry(error), {
      contexts: {
        ListOrgShippingAddresses: { orgId },
      },
    })
    return []
  }
}

export function ListenToOrgShippingAddresses({
  orgId,
  cb,
}: {
  orgId: string
  cb: (data: ShippingAddress[]) => void
}) {
  const db = getFirestore()
  const colRef = collection(
    db,
    `organizations/${orgId}/shippingAddresses`
  ).withConverter(converter(ShippingAddress))
  const q = query(colRef)
  return onSnapshot(
    q,
    querySnapshot => cb(querySnapshot.docs.map(doc => doc.data())),
    error => {
      console.error(error)
      captureException(toSentry(error), {
        contexts: {
          ListenToOrgShippingAddresses: { orgId },
        },
        tags: { orgId },
      })
    }
  )
}
