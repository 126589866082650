import { PlacidTags } from 'constants/placid/placid-tags'
import { UseCaseRecord } from 'pages/NewReward/types/forms'
import { CategoryTitle } from 'pages/Templates/constants'
import { RewardUseCasesKeysType } from './RewardUseCasesKeys'

export const companyEventsUseCases = {
  COMPANY_EVENTS: {
    label: 'Company Events',
    details: {
      searchQuery: 'celebrate',
      templateCategories: [CategoryTitle.COMPANY_EVENTS],
      placidTags: [
        PlacidTags.TEAM_EVENT,
        PlacidTags.MOVIE_NIGHT,
        PlacidTags.ALL_HANDS,
        PlacidTags.GAME_NIGHT,
        PlacidTags.TRIVIA_NIGHT,
        PlacidTags.CONTEST_WINNER,
        PlacidTags.SCAVENGER_HUNT,
      ],
    },
    emailData: [
      {
        title: 'Eventful Times!',
        note: 'Thank you for making our company event a memorable one!',
      },
      {
        title: 'Success Together 🎉',
        note: 'Every event is a milestone that we achieve together as a team!',
      },
      {
        title: 'Company Pride!',
        note: 'Your participation made our company event shine even brighter!',
      },
    ],
  },
  MOVIE_NIGHT: {
    label: 'Movie night',
    details: {
      searchQuery: 'popcorn',
      templateCategories: [CategoryTitle.MOVIE_NIGHT],
      placidTags: [PlacidTags.MOVIE_NIGHT],
    },
    emailData: [
      {
        title: 'Movie Buff!',
        note: 'Thanks for the film recommendations. Movie night was a hit!',
      },
      {
        title: 'Popcorn & Flicks 🍿',
        note: 'Your presence made movie night even more special. Thanks for joining!',
      },
      {
        title: 'Cinema Star!',
        note: 'From thrillers to comedies, you make movie nights awesome!',
      },
    ],
  },
  GAME_NIGHT: {
    label: 'Game night',
    details: {
      searchQuery: 'victory',
      templateCategories: [CategoryTitle.GAME_NIGHT],
      placidTags: [PlacidTags.GAME_NIGHT],
    },
    emailData: [
      {
        title: 'Game Master!',
        note: "Thanks for making game night a blast. Can't wait for the next one!",
      },
      {
        title: 'Board Boss 🎲',
        note: 'Your strategies and skills lit up game night!',
      },
      {
        title: 'Roll the Dice!',
        note: 'Thanks for joining in on the fun. Game nights are better with you!',
      },
    ],
  },
  CONTEST_WINNER: {
    label: 'Contest winner',
    details: {
      searchQuery: 'congratulations',
      templateCategories: [CategoryTitle.CONTEST_WINNER],
      placidTags: [PlacidTags.CONTEST_WINNER],
    },
    emailData: [
      {
        title: 'Winner Alert!',
        note: 'Congratulations on bagging the top spot. You earned it!',
      },
      {
        title: 'Victory Lap 🏆',
        note: 'Brilliant performance! Enjoy the win!',
      },
      {
        title: 'Champion!',
        note: 'Your hard work paid off. Cheers to the champion!',
      },
    ],
  },
  TRIVIA_NIGHT: {
    label: 'Trivia night',
    details: {
      searchQuery: 'trophy',
      templateCategories: [CategoryTitle.TRIVIA_NIGHT],
      placidTags: [PlacidTags.TRIVIA_NIGHT],
    },
    emailData: [
      {
        title: 'Trivia Titan!',
        note: "Your knowledge is impressive! Can't wait for the next trivia night.",
      },
      {
        title: 'Brainiac Alert 🧠',
        note: "You rocked trivia night. Here's to more fun and learning!",
      },
      {
        title: 'Quiz Whiz!',
        note: 'Your trivia skills are top-notch. Great job!',
      },
    ],
  },
  BOOK_CLUB: {
    label: 'Book club',
    details: {
      searchQuery: 'books',
      templateCategories: [],
      placidTags: [],
    },
    emailData: [
      {
        title: 'Bookworm Alert!',
        note: 'Thanks for your insights at the book club. Until the next chapter!',
      },
      {
        title: 'Turn the Page 📖',
        note: 'Appreciating your active participation in our book club.',
      },
      {
        title: 'Literary Genius!',
        note: 'Your book recommendations and insights are top-notch!',
      },
    ],
  },
  COMPANY_OFFSITE: {
    label: 'Company offsite',
    details: {
      searchQuery: 'relax',
      templateCategories: [],
      placidTags: [],
    },
    emailData: [
      {
        title: 'Adventure Awaits!',
        note: 'Join us for an exciting company offsite. Fun and bonding await!',
      },
      {
        title: 'Team Getaway 🌴',
        note: 'Pack your bags for a memorable team offsite. See you there!',
      },
      {
        title: 'Offsite Alert!',
        note: "Get ready for some team fun and adventure. Can't wait to see you there!",
      },
    ],
  },
} satisfies Partial<Record<RewardUseCasesKeysType, UseCaseRecord>>
