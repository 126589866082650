import { ListenToEligibleCategories } from 'api/databaseCalls'
import { EligibleCategories } from 'constants/EligibleCategories'
import { OrgContext, UserContext } from 'context'
import { useContext, useEffect, useState } from 'react'

export default function useAmazonBudget() {
  const org = useContext(OrgContext)
  const user = useContext(UserContext)

  const orgId = org?.id
  const userId = user?.id

  const [amazonBudget, setAmazonBudget] = useState(0)

  // Get user's budget to spend on Amazon
  useEffect(() => {
    if (!userId || !orgId) return undefined

    return ListenToEligibleCategories({
      orgId,
      userId,
      eligibleCategoryIds: [
        EligibleCategories.AMAZON,
        EligibleCategories.ALL_MERCHANTS,
      ],
      cb: setAmazonBudget,
    })
  }, [userId, orgId])

  return amazonBudget
}
