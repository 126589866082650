import { MailOutlined } from '@ant-design/icons'
import { captureMessage } from '@sentry/react'
import { Button, Radio, Space } from 'antd'
import { createIndividualByEmail, inviteMember } from 'api/databaseCalls'
import { SendEmail } from 'api/functionCalls'
import { InviteEmail } from 'components'
import { defaultButtonStyles } from 'constants/antdesign'
import * as EVENTS from 'constants/events'
import { OrgContext, UserContext } from 'context'
import { Dialog, Heading, Pane, SideSheet, Text, toaster } from 'evergreen-ui'
import { Individual_Role } from 'gen/perkup/v1/individual_pb'
import { useCallback, useContext, useState } from 'react'
import { CopyToClipboardWithFormatting, logEvent } from 'utils'

const memberInviteOptions: {
  label: string
  value: Individual_Role
}[] = [
  { label: 'Invite as Member', value: Individual_Role.member },
  {
    label: 'Invite as Manager',
    value: Individual_Role.manager,
  },
  {
    label: 'Invite as Admin (full access)',
    value: Individual_Role.admin,
  },
]
const hereLink = 'https://my.perkupapp.com/signin'
const appleWalletLink =
  'https://help.perkupapp.com/articles/356891-add-perk-card-to-apple-wallet'
const googlePayLink =
  'https://help.perkupapp.com/articles/712186-add-perk-card-to-google-pay'

const email = `<div style="font-family: arial, sans-serif">We’re thrilled to introduce PerkUp as our newest employee benefit!
<br/>
<br/>
PerkUp gives you access to a virtual or physical Visa card you can use to buy the products and services you love.
<br/>
<br/>
Here’s what you need to know to get started.
<br/>
<br/>
<b>How do I access my PerkUp account?</b>
<br/>
On the day your PerkUp account goes live, you will receive an invite to your work email address. If you didn’t receive your invite email, you can create your account <a href="${hereLink}">here</a>. It is crucial you <b>use the email address you were invited with</b> to create your account. 
<br/>
<br/>
<b>How do I use my card?</b>
<br/>
Use your PerkUp Visa card just like you would a regular credit card! Alternatively, you can add your card to <a href="${appleWalletLink}">Apple Wallet</a> or <a href="${googlePayLink}">Google Pay</a>, and shop locally at physical stores.
<br/>
<br/>
<b>What can I buy?</b>
<br/>
There are over 200 suggested perks on PerkUp, but your options are endless. You can make purchases online, at local stores, or chain businesses that fall within your approved categories. 
<br/>
<br/>
<b>Need help?</b>
<br/>
The PerkUp support team is here to help! If you have a question, click the blue chat button in the bottom right.
</div>
`

export default function InviteMember({
  isShown,
  onCloseComplete,
}: {
  isShown: boolean
  onCloseComplete: () => void
}) {
  const org = useContext(OrgContext)
  const user = useContext(UserContext)

  const userId = user.id

  const [emails, setEmails] = useState<string[]>([])
  const [inviteRole, setInviteRole] = useState<Individual_Role>(
    Individual_Role.member
  )
  const [inviteSending, setInviteSending] = useState<boolean>(false)
  const [showDialog, setShowDialog] = useState<boolean>(false)

  const orgId = org?.id

  async function handleInviteEmails() {
    setInviteSending(true)

    await Promise.all(
      emails.map(async email => {
        await createIndividualByEmail({ email, orgId, role: inviteRole })
        await inviteMember({
          orgId,
          email: email.toLowerCase(),
          role: inviteRole,
        })

        // Only send emails on production
        if (process.env.NODE_ENV === 'production') {
          if (!user.profile?.email) {
            toaster.danger(`Error sending invite to ${email}`)
            captureMessage('Missing user profile', 'error')
            return
          }
          await SendEmail({
            toAddress: email,
            fromName: `${user.profile.firstName} ${user.profile.lastName}`,
            fromEmail: user.profile.email,
            orgName: org.name,
          })
            .then(() => {
              // Track member invite sent event
              logEvent(EVENTS.MEMBER_INVITED, {
                email,
                orgId,
                orgName: org.name,
                userId,
              })
            })
            .catch(() => {
              toaster.danger(`Error sending invite to ${email}`)
            })
        }
      })
    )
    if (emails.length === 1) {
      toaster.success(`Successfully sent invite to ${emails[0]}`)
    } else {
      toaster.success('Successfully sent invites')
    }
    setInviteSending(false)
    onCloseComplete()
    setEmails([])
  }

  const inviteEmailCallback = useCallback(handleInviteEmails, [
    emails,
    inviteRole,
    onCloseComplete,
    org.name,
    orgId,
    user.profile?.email,
    user.profile?.firstName,
    user.profile?.lastName,
    userId,
  ])

  return (
    <SideSheet isShown={isShown} onCloseComplete={onCloseComplete}>
      <Pane background="tint1" height="100%">
        <Pane
          background="white"
          paddingTop={36}
          paddingBottom={16}
          paddingX={72}
        >
          <Heading size={900} marginBottom={8}>
            Invite People
          </Heading>

          <Pane>
            <Pane marginY={16}>
              <InviteEmail emails={emails} setEmails={setEmails} />
            </Pane>
            <Radio.Group
              value={inviteRole}
              onChange={e => setInviteRole(e.target.value)}
            >
              <Space direction="vertical">
                {memberInviteOptions.map(option => (
                  <Radio key={option.value} value={option.value}>
                    {option.label}
                  </Radio>
                ))}
              </Space>
            </Radio.Group>

            <Pane marginTop={16} marginBottom={16}>
              <Button
                disabled={inviteSending || !emails?.length}
                type="primary"
                onClick={inviteEmailCallback}
                loading={inviteSending}
              >
                {inviteSending ? 'Sending...' : 'Invite'}
              </Button>
            </Pane>
          </Pane>
        </Pane>
        <Pane>
          <Pane marginY={32} paddingY={16} paddingX={72}>
            <Pane marginBottom={16}>
              <Heading size={600} marginBottom={8}>
                Announcing PerkUp
              </Heading>
              <Text color="muted">
                Easily create communication announcements.
              </Text>
            </Pane>

            <Button
              style={{ ...defaultButtonStyles, gap: 8 }}
              onClick={() => setShowDialog(true)}
            >
              Get email template
              <MailOutlined />
            </Button>
          </Pane>

          <Dialog
            isShown={showDialog}
            onCloseComplete={() => setShowDialog(false)}
            topOffset={4}
            hasFooter={false}
            title="Announcing PerkUp to your team"
          >
            <Pane>
              <div
                id="to-copy"
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{ __html: `${email}` }}
              />
              <Pane display="flex" justifyContent="center" marginY={32}>
                <Button
                  type="primary"
                  onClick={() =>
                    CopyToClipboardWithFormatting(
                      document?.getElementById('to-copy')?.innerHTML ||
                        'Missing to-copy'
                    )
                  }
                >
                  Copy template
                </Button>
              </Pane>
            </Pane>
          </Dialog>
        </Pane>
      </Pane>
    </SideSheet>
  )
}
