import { Flex, Image, Select } from 'antd'
import { defaultOccasions } from 'constants/newReward/occasions'
import { Heading, Text } from 'evergreen-ui'
import { ProgramOccasion } from 'gen/perkup/v1/program_pb'

export function OccasionForm({
  onOccasionChange,
  occasion,
}: {
  onOccasionChange: (selectedOccasion: ProgramOccasion) => void
  occasion: ProgramOccasion
}) {
  return (
    <Flex vertical gap={8}>
      <Heading>Reward type</Heading>
      <Select
        style={{ width: 188 }}
        popupMatchSelectWidth={false}
        value={occasion}
        onSelect={onOccasionChange}
      >
        {defaultOccasions.map(occasion => {
          return (
            <Select.Option
              value={occasion.occasion}
              key={occasion.occasion}
              style={{ display: 'flex' }}
            >
              <Flex align="center" gap={8}>
                <Image alt={occasion.label} src={occasion.image} width={24} />
                <Text>{occasion.label}</Text>
              </Flex>
            </Select.Option>
          )
        })}
      </Select>
    </Flex>
  )
}
