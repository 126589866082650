import { Badge, BadgeProps, Flex } from 'antd'
import { PerkLink } from 'components'
import { PropsWithChildren } from 'react'

function TrackingBadge({ status }: { status: BadgeProps['status'] }) {
  return <Badge status={status} />
}

function TrackingLink({ url, children }: PropsWithChildren<{ url: string }>) {
  return (
    <PerkLink
      to={url}
      className="hoverline"
      rel="noopener noreferrer"
      target="_blank"
      onClick={e => e.stopPropagation()}
    >
      {children}
    </PerkLink>
  )
}

function Tracking({ children }: PropsWithChildren) {
  return (
    <Flex gap={4} align="center">
      {children}
    </Flex>
  )
}

Tracking.Badge = TrackingBadge
Tracking.Link = TrackingLink

export default Tracking
