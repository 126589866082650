import { USD } from 'constants/currencies'
import { ExchangeRateContext } from 'context'
import { useContext } from 'react'

export function useConvertCurrency({
  amount,
  fromCurrency,
  toCurrency,
}: {
  amount: number
  fromCurrency: string
  toCurrency: string
}): number {
  const exchangeRate = useContext(ExchangeRateContext)

  if (fromCurrency === toCurrency) return amount

  if (toCurrency === USD) return amount / exchangeRate
  return amount * exchangeRate
}
