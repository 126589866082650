import { Timestamp } from '@bufbuild/protobuf'
import { captureException } from '@sentry/react'
import {
  addDoc,
  collection,
  doc,
  getDoc,
  getFirestore,
  serverTimestamp,
  updateDoc,
} from 'firebase/firestore'
import { Cart, Cart_Item } from 'gen/perkup/v1/cart_pb'
import { toSentry } from 'utils'
import { converter, messageToFirestore } from 'utils/firestore'

export async function createCart({
  individualId,
  orgId,
  lineItems = [],
}: {
  individualId: string
  orgId: string
  lineItems?: Cart_Item[]
}) {
  try {
    const db = getFirestore()
    const colRef = collection(db, 'carts').withConverter(converter(Cart))

    const newCart = new Cart({
      individualId,
      orgId,
      lineItems,
      isActive: true,
      createdAt: Timestamp.now(),
      updatedAt: Timestamp.now(),
    })

    const addedDocRef = await addDoc(colRef, newCart)
    const addedDocSnap = await getDoc(addedDocRef)
    return addedDocSnap.data()
  } catch (err) {
    console.error(err)
    captureException(toSentry(err), {
      contexts: {
        createCart: {
          individualId,
          orgId,
          lineItems,
        },
      },
    })
    return undefined
  }
}
export async function updateCartItems({
  cartId,
  lineItems,
}: {
  cartId: string
  lineItems: Cart_Item[]
}) {
  try {
    const db = getFirestore()
    const docRef = doc(db, `carts/${cartId}`).withConverter(converter(Cart))
    const cart = messageToFirestore(Cart, { lineItems })
    updateDoc(docRef, {
      lineItems: cart.lineItems,
      updatedAt: serverTimestamp(),
    })
  } catch (err) {
    console.error(err)
    captureException(toSentry(err), {
      contexts: {
        updateCartItems: {
          cartId,
          lineItems,
        },
      },
    })
  }
}

export async function deleteCart({ cartId }: { cartId: string }) {
  try {
    const db = getFirestore()
    const docRef = doc(db, `carts/${cartId}`)

    await updateDoc(docRef, {
      isActive: false,
      updatedAt: serverTimestamp(),
    })
  } catch (err) {
    console.error(err)
    captureException(toSentry(err), {
      contexts: {
        deleteCart: {
          cartId,
        },
      },
    })
  }
}
