export const BALANCE_DETAILED_REPORT_URL =
  'https://api.us-east.tinybird.co/v0/pipes/accounts_balance_transactions_pipe_6904.json?'

export const BALANCE_REPORT_URL =
  'https://api.us-east.tinybird.co/v0/pipes/perkup_balanceReports_pipe_1112.json?'

export const REWARDS_REPORT_URL =
  'https://api.us-east.tinybird.co/v0/pipes/perkup_rewardReports_pipe_9916.json?orgId='

export const TRANSACTION_DATA_URL =
  'https://api.us-east.tinybird.co/v0/pipes/perkup_transactions_pipe.json?orgId='

export const THANKS_MESSAGE_URL =
  'https://api.us-east.tinybird.co/v0/pipes/thanks_message_pipe.json?orgId='

export const REWARDS_SENT_URL =
  'https://api.us-east.tinybird.co/v0/pipes/rewards_sent_pipe.json?orgId='

export const REWARD_SENDERS_URL =
  'https://api.us-east.tinybird.co/v0/pipes/reward_senders_pipe.json?orgId='

export const REWARDS_DAILY_URL =
  'https://api.us-east.tinybird.co/v0/pipes/rewards_by_day_pipe.json?orgId='

export const TOP_SENDERS_URL =
  'https://api.us-east.tinybird.co/v0/pipes/top_senders_pipe.json?orgId='

export const TOP_RECIPIENTS_URL =
  'https://api.us-east.tinybird.co/v0/pipes/top_recipients_pipe.json?orgId='

export const ORDERS_REPORT_BASE_URL =
  'https://api.us-east.tinybird.co/v0/pipes/orders_report_pipe.json?orgId='
