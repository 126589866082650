import { ListenToTransactionsByUserId } from 'api/databaseCalls'
import { OrgTransaction } from 'gen/perkup/v1/organization_pb'
import { useEffect, useState } from 'react'
import useIds from './useIds'

export function useTransactionsByUserId() {
  const { orgId, userId } = useIds()
  const [transactions, setTransactions] = useState<OrgTransaction[]>([])

  useEffect(() => {
    if (!orgId || !userId) return undefined
    return ListenToTransactionsByUserId({
      orgId,
      userId,
      cb: setTransactions,
    })
  }, [orgId, userId])

  return { transactions }
}
