import { ListenToProgramsByOrgId } from 'api/databaseCalls'
import { OrgContext } from 'context'
import { QuerySnapshot } from 'firebase/firestore'
import { Program } from 'gen/perkup/v1/program_pb'
import { useContext, useEffect, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import { ProgramQueryFilters } from 'types'

export function useInfinitePrograms({
  numProgramsOnMount,
  numProgramsOnPagination,
  filters,
}: {
  numProgramsOnMount: number
  numProgramsOnPagination: number
  filters?: ProgramQueryFilters
}) {
  const org = useContext(OrgContext)

  const startingIndex = numProgramsOnMount - 1
  const [currentIndex, setCurrentIndex] = useState<number>(startingIndex)
  const [hasLoaded, setHasLoaded] = useState(false)
  const [programs, setPrograms] = useState<Program[]>([])

  // On mount, fetch programs and attach a listener to listen for updates
  useEffect(() => {
    return ListenToProgramsByOrgId({
      orgId: org.id,
      filters,
      callback: (querySnapshot: QuerySnapshot<Program>) => {
        setPrograms(querySnapshot.docs.map(doc => doc.data()))
        setHasLoaded(true)
      },
    })
  }, [filters, org.id])

  // Observe sentinal div, and shift the index if the div is in view
  const { ref: sentinelRef } = useInView({
    onChange: inView => {
      if (inView) {
        setCurrentIndex(prevIndex => prevIndex + numProgramsOnPagination)
      }
    },
  })

  return {
    programs: programs.slice(0, currentIndex), // Only show the programs up to the current index
    sentinelRef,
    hasLoaded,
    isLastPage: currentIndex >= programs.length, // If the current index is greater than the number of programs, we've reached the end
  }
}
