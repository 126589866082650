import Color from 'color'

// https://github.com/mantinedev/mantine/blob/b0902662c103732e3861694f98d3024b3bb1988c/packages/%40mantine/colors-generator/src/generate-colors.ts#L3
const LIGHTNESS_MAP = [96, 90.7, 80.5, 69.7, 60.5, 54.7, 51.8, 44.5, 39.5, 34]
const SATURATION_MAP = [0.32, 0.16, 0.08, 0.04, 0, 0, 0.04, 0.08, 0.16, 0.32]

function getClosestLightness(colorObject: Color) {
  const lightnessGoal = colorObject.lightness()
  return LIGHTNESS_MAP.reduce((prev, curr) =>
    Math.abs(curr - lightnessGoal) < Math.abs(prev - lightnessGoal)
      ? curr
      : prev
  )
}

export function generateColorsMap(...args: Parameters<typeof Color>) {
  const colorObject = Color(...args)
  const closestLightness = getClosestLightness(colorObject)
  const baseColorIndex = LIGHTNESS_MAP.findIndex(l => l === closestLightness)

  const colors = LIGHTNESS_MAP.map(l => colorObject.lightness(l))
    .map(c => Color(c))
    .map((c, i) => {
      const saturationDelta = SATURATION_MAP[i] - SATURATION_MAP[baseColorIndex]
      return saturationDelta >= 0
        ? c.saturate(saturationDelta)
        : c.desaturate(saturationDelta * -1)
    })

  return { baseColorIndex, colors }
}

export function generateColors(...args: Parameters<typeof Color>) {
  return generateColorsMap(...args).colors
}

// Some services (like Mapbox) explicitly require hsl strings to be formatted with commas and percentages
export function convertStringToHslString(threeNumbers: string) {
  const [hue, saturation, lightness] = threeNumbers.split(' ')
  return `hsl(${hue}, ${saturation}%, ${lightness}%)`
}
