import { ListMembershipsByIndividualId } from 'api/databaseCalls/reads/memberships'
import { Membership } from 'gen/perkup/v1/program_pb'
import useIds from 'hooks/useIds'
import { useEffect, useState } from 'react'

export function useMemberships() {
  const { individualId, orgId } = useIds()

  const [memberships, setMemberships] = useState<Membership[]>([])
  const [hasLoaded, setHasLoaded] = useState(false)
  const [isLoadingMore, setIsLoadingMore] = useState(false)

  useEffect(() => {
    if (!individualId || !orgId) {
      setHasLoaded(true)
      return
    }
    setIsLoadingMore(true)
    ListMembershipsByIndividualId({ individualId, orgId })
      .then(setMemberships)
      .finally(() => {
        setHasLoaded(true)
        setIsLoadingMore(false)
      })
  }, [individualId, orgId])

  return {
    memberships,
    setMemberships,
    hasLoaded,
    isLoadingMore,
    isLoading: !hasLoaded || isLoadingMore,
  }
}
