import { ListProductVariantsByProgramItems } from 'api/databaseCalls'
import { BUNDLE_TAG, SCRAPE_IT_TAG } from 'constants/algolia'
import {
  ProductVariant_Provider,
  ProductVariant_Type,
} from 'gen/perkup/v1/product_variant_pb'
import { Item } from 'gen/perkup/v1/program_pb'
import { ShippingAddress } from 'gen/perkup/v1/root_user_pb'
import {
  CalculatedDraftOrder,
  DraftOrderLineItemInput,
} from 'gen/shopifyapi/admingql_pb'
import { intersectionWith } from 'lodash-es'
import { useEffect, useState } from 'react'
import { CalculateShopifyDraftOrder } from 'services'

async function calculateDraftOrderHelper({
  programItems,
  shippingAddress,
}: {
  programItems: Item[]
  shippingAddress: ShippingAddress
}) {
  // First we need to filter out bundled items - need pv to check
  const productVariants = await ListProductVariantsByProgramItems({
    programItems,
  })
  if (!productVariants) return undefined

  const nonBundleVariants = productVariants.filter(
    pv => !pv.tags.includes(BUNDLE_TAG)
  )

  const nonBundledItems = intersectionWith(
    programItems,
    nonBundleVariants,
    (item, variant) => item.productVariantId === variant.id
  )

  const lineItems: DraftOrderLineItemInput[] = nonBundledItems.map(item => {
    const variantForItem = nonBundleVariants.find(
      variant => variant.id === item.productVariantId
    )

    const isScraped = variantForItem?.tags?.includes(SCRAPE_IT_TAG)

    if (isScraped) {
      return new DraftOrderLineItemInput({
        quantity: item.quantity,
        title: variantForItem?.productName,
        taxable: variantForItem?.taxable,
        sku: variantForItem?.sku,
        requiresShipping: variantForItem?.requiresShipping,
        customAttributes: [
          { key: 'variantId', value: variantForItem?.id },
          {
            key: 'productId',
            value: variantForItem?.productId,
          },
          { key: 'handle', value: variantForItem?.handle },
        ],
      })
    }

    return new DraftOrderLineItemInput({
      quantity: item.quantity,
      variantId: variantForItem?.adminGraphqlApiId,
      customAttributes: [
        {
          key: 'sourceType',
          value: 'fullPrepaid',
        },
        {
          key: 'itemType',
          value: ProductVariant_Type[variantForItem?.type ?? 0],
        },
      ],
    })
  })

  // Then hit shopify api
  const draftOrder = await CalculateShopifyDraftOrder({
    lineItems,
    shippingAddress,
  })

  return draftOrder?.calculatedDraftOrder
}

/**
 *
 * @deprecated Not sure we need to keep maintaining this hook. Its only used in one super specific place.
 */
export function useDraftOrderFromProgramItems({
  programItems,
  shippingAddress,
}: {
  programItems?: Item[]
  shippingAddress?: ShippingAddress
}) {
  const [draftOrderCalculation, setDraftOrderCalculation] =
    useState<CalculatedDraftOrder>()
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (!programItems || !shippingAddress?.country) return

    const shopifyItemsWithQuantity = programItems.filter(
      item =>
        item.quantity > 0 && item.provider === ProductVariant_Provider.shopify
    )

    if (shopifyItemsWithQuantity.length > 0) {
      setIsLoading(true)

      calculateDraftOrderHelper({
        programItems: shopifyItemsWithQuantity,
        shippingAddress,
      })
        .then(setDraftOrderCalculation)
        .finally(() => {
          setIsLoading(false)
        })
    }
  }, [shippingAddress, programItems])

  return { draftOrderCalculation, isLoading }
}
