// @generated by protoc-gen-es v1.6.0 with parameter "target=ts"
// @generated from file perkup/v1/firebase_auth.proto (package perkup.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";

/**
 * @generated from message perkup.v1.CustomTokenResponse
 */
export class CustomTokenResponse extends Message<CustomTokenResponse> {
  /**
   * @generated from field: string custom_token = 1;
   */
  customToken = "";

  constructor(data?: PartialMessage<CustomTokenResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "perkup.v1.CustomTokenResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "custom_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CustomTokenResponse {
    return new CustomTokenResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CustomTokenResponse {
    return new CustomTokenResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CustomTokenResponse {
    return new CustomTokenResponse().fromJsonString(jsonString, options);
  }

  static equals(a: CustomTokenResponse | PlainMessage<CustomTokenResponse> | undefined, b: CustomTokenResponse | PlainMessage<CustomTokenResponse> | undefined): boolean {
    return proto3.util.equals(CustomTokenResponse, a, b);
  }
}

/**
 * @generated from message perkup.v1.CustomTokenRequest
 */
export class CustomTokenRequest extends Message<CustomTokenRequest> {
  /**
   * @generated from field: optional string preview_id = 1;
   */
  previewId?: string;

  /**
   * @generated from field: optional string workos_code = 2;
   */
  workosCode?: string;

  constructor(data?: PartialMessage<CustomTokenRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "perkup.v1.CustomTokenRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "preview_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 2, name: "workos_code", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CustomTokenRequest {
    return new CustomTokenRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CustomTokenRequest {
    return new CustomTokenRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CustomTokenRequest {
    return new CustomTokenRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CustomTokenRequest | PlainMessage<CustomTokenRequest> | undefined, b: CustomTokenRequest | PlainMessage<CustomTokenRequest> | undefined): boolean {
    return proto3.util.equals(CustomTokenRequest, a, b);
  }
}

