import { SearchClient } from 'algoliasearch/lite'
import { incrementProductVariantClick } from 'api/databaseCalls'
import { PUBLIC_GIFT_TAG, TEST_ORG_ID } from 'constants/algolia'
import { UNITES_STATES_ISO3 } from 'constants/countries'
import { PRODUCT_CLICKED } from 'constants/events'
import { isProduction } from 'constants/keys'
import { ACTIVE_ISO3 } from 'constants/sessionOrLocalStorage'
import type { UiState } from 'instantsearch.js'
import { InstantSearch } from 'react-instantsearch'
import { logEvent } from './firebaseUtils'
import { startCaseWithAmpersand } from './labels'
import { makePlural } from './uiUtils'

export interface IInstantSearchWrapper {
  children: JSX.Element
  searchClient: SearchClient | undefined
  indexName: string
  [key: string]: unknown
}

export function InstantSearchWrapper({
  children,
  searchClient,
  indexName,
  ...rest
}: IInstantSearchWrapper) {
  if (searchClient) {
    return (
      <InstantSearch
        searchClient={searchClient}
        indexName={indexName}
        {...rest}
      >
        {children}
      </InstantSearch>
    )
  }
  return <div />
}

export function getAlgoliaFilters({ uiState }: { uiState: UiState }) {
  let filterString = ''
  const refinementList = uiState[Object.keys(uiState)[0]]?.refinementList
  if (refinementList) {
    const refinementKeys = Object.keys(refinementList)
    refinementKeys.forEach((key, keyIndex) => {
      let keyString = ''
      const activeFilterFacets = refinementList[key]
      activeFilterFacets.forEach((facet, index) => {
        keyString += `"${key}":"${facet}"`
        if (index === activeFilterFacets.length - 1) {
          if (keyIndex !== refinementKeys.length - 1) keyString += ' AND '
        } else {
          keyString += ' OR '
        }
      })
      filterString += keyString
    })
  }
  return { filterString }
}

export function getAlgoliaTenureFilterOptions({
  customDate,
}: {
  customDate?: Date
}) {
  const comparisonDate = customDate || new Date()
  const tenureFilterOptions = Array.from(Array(50).keys())
  const formattedTenureFilterOptions = tenureFilterOptions.map(option => {
    const fixedOption = option + 1
    return {
      value: `${comparisonDate.getFullYear() - fixedOption}`,
      label: makePlural(`${fixedOption} year`, fixedOption),
    }
  })
  return formattedTenureFilterOptions
}

export const getAlgoliaIndividualInitialStatuses = (isAdmin: boolean) => {
  return isAdmin
    ? ['none', 'active', 'invited', 'blocked']
    : ['none', 'active', 'invited']
}

export interface AlgoliaInsightsProps {
  orgId: string
  userId: string
  userToken: string
  productId: string
  productVariantId: string
  index: string
  eventType: 'click' | 'conversion' | 'view'
  queryID: string
  objectIDs: Array<string>
  positions: Array<number>
}

export const AlgoliaProductClicked = (properties: AlgoliaInsightsProps) => {
  const { productVariantId } = properties
  logEvent(PRODUCT_CLICKED, properties)
  incrementProductVariantClick({ id: productVariantId })
}

/**
 * @deprecated Use useAlgoliaOrgSwagFilters instead.
 */
export const getAlgoliaOrgSwagFilters = ({ orgId }: { orgId: string }) => {
  const filterOrgId = isProduction ? orgId : TEST_ORG_ID

  return `tags:${filterOrgId}`
}

export const cleanCategoryLabel = (itemLabel: string) => {
  if (itemLabel.toLowerCase() === 'food, beverages & tobacco') {
    return 'Food & Beverages'
  }
  return startCaseWithAmpersand(itemLabel)
}

export const getAlgoliaFeaturedCollectionFilter = ({
  maxAmount,
  currentIso3,
}: {
  maxAmount: number
  currentIso3: string
}) => {
  return `tags:${PUBLIC_GIFT_TAG} AND shippingCountries:${currentIso3} AND amount >= ${
    maxAmount - 2500
  } AND amount <= ${maxAmount}`
}

export const getAlgoliaShippingCountriesFilter = () =>
  localStorage.getItem(ACTIVE_ISO3) || UNITES_STATES_ISO3
