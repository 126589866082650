import { isProduction, PERKUP_ORG_ID } from 'constants/keys'
import { OrgContext } from 'context'
import { useContext } from 'react'

export const useDevSafeOrgId = () => {
  const org = useContext(OrgContext)
  if (isProduction) {
    return org?.id
  }
  return PERKUP_ORG_ID
}
