import { captureException } from '@sentry/react'
import { Button } from 'antd'
import Badge from 'antd/es/badge'
import { deleteCustomAction, updateCustomAction } from 'api/databaseCalls'
import { OrgContext } from 'context'
import { Heading, Image, Pane, Strong, Text, toaster } from 'evergreen-ui'
import { AnimatePresence, motion } from 'framer-motion'
import { CustomAction } from 'gen/perkup/v1/rules_pb'
import { useContext } from 'react'
import { numToDollars } from 'utils'
import { toSentry } from 'utils/sentry'
import { IEventInfo } from './EventDrawer'

export function EventActionDisabled({
  eventInfo,
  currentAction,
  wasBasicAction,
  disabled,
}: {
  eventInfo: IEventInfo
  currentAction: CustomAction
  wasBasicAction: boolean
  disabled?: boolean
}) {
  const org = useContext(OrgContext)
  const orgId = org.id

  const { firstName } = eventInfo.eventIndividual
  const warningMessage = disabled ? 'Disabled rule' : `${firstName} is excluded`

  const {
    ruleId,
    image,
    amount,
    title,
    message,
    from,
    gift,
    actionId,
    id: customActionId,
  } = currentAction

  const handleEnableAction = () => {
    const successMessage = 'Successfully enabled'
    if (wasBasicAction) {
      deleteCustomAction({
        orgId,
        ruleId,
        actionId,
        customActionId,
      })
        .then(() => {
          toaster.success(successMessage)
        })
        .catch(error => {
          console.error(error)
          toaster.warning('Something went wrong, try again or contact support.')
          captureException(toSentry(error), {
            contexts: {
              EventActionDisabled: {
                currentAction,
                wasBasicAction,
                disabled,
              },
            },
          })
        })
      return
    }

    updateCustomAction({
      orgId,
      ruleId,
      actionId,
      customActionId,
      newData: { excluded: false },
    })
      .then(() => {
        toaster.success(successMessage)
      })
      .catch(error => {
        console.error(error)
        toaster.warning('Something went wrong, try again or contact support.')
        captureException(toSentry(error), {
          contexts: {
            EventActionDisabled: {
              currentAction,
              wasBasicAction,
              disabled,
            },
          },
        })
      })
  }

  return (
    <AnimatePresence exitBeforeEnter>
      <motion.div
        key={currentAction?.ruleId}
        initial={{ y: 10, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        exit={{ y: -10, opacity: 0 }}
        transition={{ duration: 0.3 }}
      >
        <Pane display="flex" flexDirection="column" gap={16} paddingRight={48}>
          {/** Reward image */}
          <Image
            borderRadius="10px"
            width={300}
            src={image}
            style={{ filter: 'grayscale(100%)' }}
            opacity={0.5}
          />

          <Pane display="flex" alignItems="center" gap={16}>
            {/** Reward title */}
            <Heading size={700} opacity={0.5}>
              {numToDollars(amount)}
            </Heading>

            {/** Reward gift image */}
            <Image
              borderRadius="10%"
              width={50}
              src={gift?.imageUrl}
              style={{ filter: 'grayscale(50%)' }}
              opacity={0.5}
            />
          </Pane>

          {/** Reward title */}
          <Heading size={700} opacity={0.5}>
            {title}
          </Heading>

          {/** Reward message */}
          <Text opacity={0.5}>{message}</Text>

          {/** Reward sender */}
          <Strong opacity={0.5}>{from}</Strong>

          <Pane display="flex" alignItems="center" gap={16}>
            <Button disabled>Preview Email</Button>
            {!disabled && (
              <Button type="primary" onClick={handleEnableAction}>
                Enable
              </Button>
            )}
          </Pane>

          <Badge color="red" text={<Strong>{warningMessage}</Strong>} />
        </Pane>
      </motion.div>
    </AnimatePresence>
  )
}
