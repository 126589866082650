import { PlusOutlined } from '@ant-design/icons'
import { Button } from 'antd'

export function AddButton({ setEdit }: { setEdit: (edit: boolean) => void }) {
  return (
    <Button
      onClick={() => setEdit(true)}
      icon={<PlusOutlined />}
      style={{ display: 'flex', alignItems: 'center' }}
      size="small"
      type="text"
    >
      Add
    </Button>
  )
}
