import { ListenToOrgShippingAddresses } from 'api/databaseCalls'
import { OrgContext } from 'context'
import { ShippingAddress } from 'gen/perkup/v1/root_user_pb'
import { useContext, useEffect, useState } from 'react'

export const useOrgShippingAddresses = () => {
  const { id: orgId } = useContext(OrgContext)
  const [shippingAddresses, setShippingAddresses] = useState<ShippingAddress[]>(
    []
  )
  const [hasLoaded, sethasLoaded] = useState(false)

  useEffect(() => {
    ListenToOrgShippingAddresses({
      orgId,
      cb: address => {
        setShippingAddresses(address)
        sethasLoaded(true)
      },
    })
  }, [orgId])

  return {
    shippingAddresses,
    hasLoaded,
  }
}
