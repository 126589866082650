import { PartialMessage, Timestamp } from '@bufbuild/protobuf'
import {
  ListProductVariantsByProgramItems,
  addProgramToOrg,
  createNewIndividualsByEmails,
  removeProgramField,
  updateProgram,
} from 'api/databaseCalls'
import { callFunction } from 'api/functionCalls'
import {
  ApprovedCategories,
  allApprovedCats,
} from 'constants/EligibleCategories'
import * as EVENTS from 'constants/events'
import {
  RewardUseCaseEmailData,
  RewardUseCases,
} from 'constants/newReward/rewardUseCases'
import { COLOR_OPTION } from 'constants/productVariants'
import { rewardSteps } from 'constants/rewards'
import { EDIT, IMAGE, OCCASION } from 'constants/routes'
import { IMAGE_SEARCH_QUERY } from 'constants/sessionOrLocalStorage'
import { Dayjs } from 'dayjs'
import { PartialWithFieldValue } from 'firebase/firestore'
import { Template, Template_Expiry } from 'gen/perkup/v1/org_template_pb'
import {
  Organization,
  Organization_SubscriptionStatus,
} from 'gen/perkup/v1/organization_pb'
import {
  Item,
  Program,
  ProgramStatus,
  ProgramType,
  Program_DraftDataField,
  Program_RewardTab,
} from 'gen/perkup/v1/program_pb'
import { groupBy, intersectionWith, keys, sample } from 'lodash-es'
import { CreateRewardLocation } from 'types'
import { RangeValue } from 'types/RewardsDate'
import { afterLastSlash, logEvent } from 'utils'
import { groupItemsByQuantity } from 'utils/items'
import { v4 as uuid } from 'uuid'
import { RewardFormData } from '../types/forms'
import { isFoodProgramTemplate } from './program-templates'

export function getProgramEndDate({
  expiryDate,
  duration,
  wantsFood = false,
}: {
  expiryDate: Dayjs | undefined
  duration: RangeValue
  wantsFood?: boolean
}) {
  if (wantsFood && duration && duration[1]) return duration[1]
  if (expiryDate) return expiryDate

  if (duration) {
    if (duration[1]) return duration[1]
  }

  return undefined
}

export async function createProgram({
  org,
  program,
  recipientEmails,
  recipientIds,
  sendDate,
  userId,
  senderEmail,
}: {
  org: Organization
  program: Program
  recipientEmails: string[]
  recipientIds: string[]
  sendDate?: Dayjs
  userId?: string
  senderEmail?: string
}) {
  const orgId = org.id

  // If program is a draft, write update
  if (program.id) {
    await updateProgram({
      orgId,
      programId: program.id,
      program,
      merge: false,
    })
  }
  // Add the program
  const addedProgram = program.id
    ? program
    : await addProgramToOrg({ orgId, program })

  if (!addedProgram) return undefined

  // Log program created
  logEvent(EVENTS.PROGRAM_CREATED, {
    program: program.toJson(),
    orgId,
    orgSubscriptionStatus:
      Organization_SubscriptionStatus[org.subscriptionStatus],
    status: program.status,
    userId,
    email: senderEmail,
  })

  // Create individuals from recipient emails
  const newIndividualIds = await createNewIndividualsByEmails({
    orgId,
    emails: recipientEmails,
  })
  const selectedIds = [...newIndividualIds, ...recipientIds]
  // Dont schedule members if draft program
  if (program.status !== ProgramStatus.draft) {
    // Determine whether or not to send the gift
    if (sendDate) {
      // Don't wait for everyone to be scheduled
      callFunction('firestore-SchedulePeopleIdsToProgram', {
        orgId,
        programId: addedProgram.id,
        selectedIds,
        type: ProgramType[ProgramType.rewards],
        sendAtTimestamp: sendDate.unix(),
      })
    }
    // Wait for individual to be added if only one recipient
    // To make sure the program is added to the order.
    else if (selectedIds.length === 1) {
      await callFunction('firestore-CreateMember', {
        orgId,
        program: addedProgram.toJson(),
        memberId: selectedIds[0],
      })
    } else {
      // Don't wait for everyone to be added
      callFunction('firestore-AddPeopleIdsToProgram', {
        orgId,
        programId: addedProgram.id,
        selectedIds,
      })
    }
  }

  return addedProgram
}

export function parseDraftData({ program }: { program?: Partial<Program> }) {
  if (program && program?.draftData) {
    const parsedData = Program_DraftDataField.fromJsonString(program.draftData)

    return parsedData
  }

  return undefined
}

export function getRewardQuantity({
  recipientCount,
  isDirectMail,
}: {
  recipientCount: number
  isDirectMail: boolean
}) {
  if (isDirectMail) {
    return 1
  }
  return recipientCount
}

export const getDefaultCats = ({
  location,
}: {
  location: CreateRewardLocation
}) => {
  if (location?.state?.defaultCats) {
    return location.state.defaultCats
  }
  if (
    location?.state?.startingTemplate &&
    isFoodProgramTemplate(location.state.startingTemplate)
  ) {
    return [ApprovedCategories.FOOD]
  }
  return allApprovedCats
}

export const createRewardLocation = ({
  location,
}: {
  location: CreateRewardLocation
}): RewardFormData | undefined => {
  const { state } = location

  if (!state) return undefined

  const budget = state?.budget ? Math.round(state.budget) : undefined
  const email = state?.email
  const gift = state?.gift
  const giftGroup = state?.giftGroup
  const recipientIndividuals = state?.recipientIndividuals || []
  const startingTemplate = state?.startingTemplate
  const startingTab = state?.startingTab
  const defaultCats = getDefaultCats({ location })
  const startsOn = state?.startsOn
  const endsOn = state?.endsOn
  const memo = state?.memo
  const coreValues = state?.coreValues
  const items = state?.items
  const occasion = state?.occasion
  const templateId = state?.templateId
  return {
    budget,
    email,
    gift,
    giftGroup,
    recipientIndividuals,
    startingTemplate,
    startingTab,
    defaultCats,
    startsOn,
    endsOn,
    memo,
    coreValues,
    items,
    occasion,
    templateId,
  }
}

export const getSavedProgramImageQuery = ({
  programId,
}: {
  programId?: string
}) => {
  if (!programId) return undefined
  const savedQuery = sessionStorage.getItem(
    `${programId}_${IMAGE_SEARCH_QUERY}`
  )
  return savedQuery || undefined
}

export const removeSavedProgramImageQuery = ({
  programId,
}: {
  programId?: string
}) => {
  if (!programId) return
  sessionStorage.removeItem(`${programId}_${IMAGE_SEARCH_QUERY}`)
}

export const saveProgramImageQuery = ({
  programId,
  query,
}: {
  programId: string
  query: string
}) => {
  sessionStorage.setItem(`${programId}_${IMAGE_SEARCH_QUERY}`, query)
}

export const getDraftProgramNavigationRoute = ({
  defaultData,
}: {
  defaultData?: RewardFormData
}) => {
  if (defaultData?.email) {
    return EDIT
  }
  if (defaultData?.occasion) {
    return IMAGE
  }
  return OCCASION
}

export const saveProgram = async ({
  updatedProgram,
  removalField,
  orgId,
  programId,
}: {
  updatedProgram?: PartialWithFieldValue<Program>
  removalField?: keyof Program
  orgId: string
  programId: string
}) => {
  await updateProgram({
    orgId,
    programId,
    program: {
      ...updatedProgram,
      isDeleted: false,
    },
    merge: true,
  })

  if (removalField) {
    await removeProgramField({
      orgId,
      programId,
      field: removalField,
    })
  }
}

export const programItemsToMap = async ({ items }: { items: Item[] }) => {
  const variants = await ListProductVariantsByProgramItems({
    programItems: items,
  })

  const draftItems = new Map<string, Item[]>()
  if (!variants) return draftItems
  const variantsByProductId = groupBy(variants, variant => variant.productId)

  // We need to do a grouping on productId and color
  const productIds = keys(variantsByProductId)
  productIds.forEach(productId => {
    const productIdVariants = variantsByProductId[productId]
    const variantsGroupedByColor = groupBy(
      productIdVariants,
      variant => variant.options[COLOR_OPTION]?.value
    )
    const colorKeys = keys(variantsGroupedByColor)
    colorKeys.forEach(colorKey => {
      const colorVariants = variantsGroupedByColor[colorKey]

      // We find items corresponding to this product and color
      const draftLineItems = intersectionWith(
        items,
        colorVariants,
        (item, variant) => afterLastSlash(item.productVariantId) === variant.id
      )
      const lineItemsToSet = groupItemsByQuantity({
        items: draftLineItems,
      })

      if (lineItemsToSet.length > 0) {
        draftItems.set(uuid(), lineItemsToSet)
      }
    })
  })
  return draftItems
}

export const getUseCaseEmailData = ({
  rewardUseCase,
}: {
  rewardUseCase?: string
}) => {
  if (!rewardUseCase) return undefined
  const emailData = RewardUseCaseEmailData[rewardUseCase]
  return sample(emailData)
}

export const getUseSearchCaseQuery = ({
  rewardUseCase,
}: {
  rewardUseCase?: string | keyof typeof RewardUseCases
}) => {
  if (!rewardUseCase) return undefined
  const useCase = RewardUseCases[rewardUseCase as keyof typeof RewardUseCases]
  if (!useCase) return ''

  return useCase.details.searchQuery
}

export const getRewardStepFromPathname = ({
  pathname,
}: {
  pathname: string
}) => {
  const rewardStep = rewardSteps.find(step => pathname.includes(step.path))
  return rewardStep?.step
}

export const stringifyDraftData = (
  fields: PartialMessage<Program_DraftDataField>
) => {
  const programDraftData = new Program_DraftDataField(fields)
  return programDraftData.toJsonString()
}

export const getProgramEndsOnFromTemplateExpiry = ({
  templateExpiry,
}: {
  templateExpiry?: Template_Expiry
}) => {
  const daysUntilExpiration = templateExpiry?.days

  const expirationDate = daysUntilExpiration ? new Date() : undefined

  if (expirationDate && daysUntilExpiration) {
    expirationDate.setDate(expirationDate.getDate() + daysUntilExpiration)
  }

  const endsOn = expirationDate ? Timestamp.fromDate(expirationDate) : undefined

  return endsOn
}

export const getDefaultRewardTabByTemplate = (template?: Template) => {
  const eligibleRewards = template?.eligibleRewards
  if (eligibleRewards?.cash) {
    return Program_RewardTab.perkupDollars
  }
  if (eligibleRewards?.swag) {
    return Program_RewardTab.swag
  }
  if (eligibleRewards?.gift) {
    return Program_RewardTab.catalog
  }
  if (eligibleRewards?.giftCard) {
    return Program_RewardTab.giftCard
  }
  return Program_RewardTab.perkupDollars
}
