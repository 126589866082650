import { captureException } from '@sentry/react'
import {
  collection,
  doc,
  getCountFromServer,
  getDoc,
  getDocs,
  getFirestore,
  onSnapshot,
  orderBy,
  query,
  where,
} from 'firebase/firestore'
import { ProgramTemplate } from 'gen/perkup/v1/contentful_pb'
import { Individual_Role } from 'gen/perkup/v1/individual_pb'
import { Template, Template_Access } from 'gen/perkup/v1/org_template_pb'
import { converter } from 'utils/firestore'
import { toSentry } from 'utils/sentry'

export async function ListProgramTemplates() {
  const programTemplates: ProgramTemplate[] = []
  try {
    const db = getFirestore()
    const colRef = collection(db, `programTemplates`).withConverter(
      converter(ProgramTemplate)
    )
    const q = query(colRef, orderBy('rank', 'desc'))
    await getDocs(q).then(qSnaps =>
      qSnaps.docs.forEach(qSnap => programTemplates.push(qSnap.data()))
    )
  } catch (error) {
    console.error(error)
    captureException(toSentry(error), {
      contexts: {
        ListProgramTemplates: {},
      },
    })
  }
  return programTemplates
}

export async function GetFeaturedProgramTemplates() {
  const programTemplates: ProgramTemplate[] = []
  try {
    const db = getFirestore()
    const colRef = collection(db, `programTemplates`).withConverter(
      converter(ProgramTemplate)
    )
    const q = query(colRef, where('featured', '==', true))
    await getDocs(q).then(qSnaps =>
      qSnaps.docs.forEach(qSnap => programTemplates.push(qSnap.data()))
    )
  } catch (error) {
    console.error(error)
    captureException(toSentry(error), {
      contexts: {
        GetFeaturedProgramTemplates: {},
      },
    })
  }
  return programTemplates
}

export async function ListOrgTemplates({ orgId }: { orgId: string }) {
  const templates: Template[] = []
  try {
    const db = getFirestore()
    const colRef = collection(
      db,
      `organizations/${orgId}/templates`
    ).withConverter(converter(Template))
    const q = query(colRef, where('isDeleted', '==', false))
    const querySnapshot = await getDocs(q)
    querySnapshot.forEach(doc => templates.push(doc.data()))
  } catch (error) {
    console.error(error)
    captureException(toSentry(error), {
      contexts: {
        ListOrgTemplates: { orgId },
      },
    })
  }
  return templates
}

export function ListenToOrgTemplates({
  orgId,
  cb,
  onRequestComplete,
}: {
  orgId: string
  cb: (templates: Template[]) => void
  onRequestComplete: () => void
}) {
  const db = getFirestore()
  const colRef = collection(
    db,
    `organizations/${orgId}/templates`
  ).withConverter(converter(Template))
  const q = query(colRef, where('isDeleted', '==', false))

  return onSnapshot(
    q,
    query => {
      cb(query.docs.map(doc => doc.data()))
      onRequestComplete()
    },
    error => {
      console.error(error)
      captureException(toSentry(error), {
        contexts: {
          ListenToOrgTemplates: {
            orgId,
          },
        },
      })
      onRequestComplete()
    }
  )
}

export async function GetOrgTemplateById({
  orgId,
  templateId,
}: {
  orgId: string
  templateId: string
}) {
  try {
    const db = getFirestore()
    const docRef = doc(
      db,
      `organizations/${orgId}/templates/${templateId}`
    ).withConverter(converter(Template))
    return await getDoc(docRef).then(dSnap => dSnap.data())
  } catch (error) {
    console.error(error)
    captureException(toSentry(error), {
      contexts: {
        GetOrgTemplateById: {
          orgId,
          templateId,
        },
      },
    })
  }
}

export async function GetProgramTemplateById(data: {
  programTemplateId: string
}) {
  const { programTemplateId } = data
  try {
    const db = getFirestore()
    const docRef = doc(
      db,
      `programTemplates/${programTemplateId}`
    ).withConverter(converter(ProgramTemplate))
    return await getDoc(docRef).then(dSnap => dSnap.data())
  } catch (error) {
    console.error(error)
    captureException(toSentry(error), {
      contexts: {
        GetProgramTemplateById: {
          programTemplateId,
        },
      },
    })
  }
}

export async function ListOrgTemplatesByAccess({
  orgId,
  role,
}: {
  orgId: string
  role: Individual_Role
}) {
  const templateAccess = [
    Template_Access[Template_Access.all],
    Individual_Role[role],
  ]

  try {
    const db = getFirestore()
    const colRef = collection(
      db,
      `organizations/${orgId}/templates`
    ).withConverter(converter(Template))
    const q = query(
      colRef,
      where('access', 'in', templateAccess),
      where('isDeleted', '==', false)
    )
    return await getDocs(q).then(qSnapshot => {
      const templates = qSnapshot.docs.map(qSnap => qSnap.data())
      return templates
    })
  } catch (error) {
    console.error(error)
    captureException(toSentry(error), {
      contexts: {
        ListOrgTemplatesByAccess: {},
      },
    })
  }
  return []
}

export async function CountOrgTemplates({ orgId }: { orgId: string }) {
  try {
    const db = getFirestore()
    const colRef = collection(
      db,
      `organizations/${orgId}/templates`
    ).withConverter(converter(Template))
    const q = query(colRef, where('isDeleted', '==', false))
    const snapshot = await getCountFromServer(q)
    return snapshot.data().count
  } catch (error) {
    console.error(error)
    captureException(toSentry(error), {
      contexts: {
        CountOrgTemplates: { orgId },
      },
    })
  }
  return 0
}
