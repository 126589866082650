export const isProduction = process.env.NODE_ENV === 'production'

export const PERKUP_ORG_ID = 'HGFJ9XSUEY2jheQgzM1U'

export const STRIPE_API_PUBLIC_KEY = isProduction
  ? 'pk_live_51HWlQ4HiqA41OAsJRRKaryMrO6AT4bCLLsTBShjQJhTqWH6UqfOqMhjwnlWLuiTXRjAyF1FGJzxXYs3F7eKJU47E00CWb759vt' // Stripe Public Live
  : 'pk_test_51HWlQ4HiqA41OAsJ0RhSRCqycMlghN6nfUakBOwE9vBtW28oYlG19fmKQtvE6HQu7znyXiOUuu2CsooLrC5DPcft00WfxUdqHu' // Stripe Public Test

export const FINCH_CLIENT_ID = 'e140b4c1-18ce-4aaa-bd70-243528681b15'

export const UNSPLASH_ACCESS_KEY = 'zvvTdCoMG44JPZ7n2rtFlVBxrXTfPtYbp68Flzh9-KE'

export const GIPHY_API_KEY = 'z03Mec9OGppWIJNHZNnbbG8DGSEh8Pjr'

export const COURIER_CLIENT_KEY = isProduction
  ? 'ZmVlMTAyOTItNDljYS00NWE4LThjNWUtMTZiNzc2OWM5NTFi'
  : 'ZmVlMTAyOTItNDljYS00NWE4LThjNWUtMTZiNzc2OWM5NTFiL3Rlc3Q='

export const MAPBOX_PUBLIC_TOKEN =
  'pk.eyJ1IjoidGhvbWFzbWlybW90YWhhcmkiLCJhIjoiY2t1em9sbjhkMnVkMTJwbzZ0ZG50aHJjMSJ9.KRPmqnePiG1SK1DyF7u__w'

export const FLATFILE_PUBLIC_KEY = isProduction
  ? 'pk_gibpft6rTQQys256Wem4jn6WvLFPFFMW'
  : 'pk_AJ7kLMNrd80HVV7jItZLCJCsJtXLgEED'

export const FLATFILE_ENV_ID = isProduction
  ? 'us_env_KKx5GtdY'
  : 'us_env_IcclVkpa'

export const CUSTOM_INPUT_KEY = '-1'

export const BE_BASE_URL = isProduction
  ? 'https://amazon-punchout-y52g2palyq-uc.a.run.app'
  : 'http://localhost:6001'
