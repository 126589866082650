import { captureException } from '@sentry/react'
import { Button, Image } from 'antd'
import GoogleGLogo from 'assets/logos/google-g-logo.svg'
import * as EVENTS from 'constants/events'
import { OrgContext } from 'context'
import { toaster } from 'evergreen-ui'
import { GoogleAuthProvider, getAuth, signInWithPopup } from 'firebase/auth'
import { PropsWithChildren, useContext } from 'react'
import { logEvent, toSentry } from 'utils'

const openGoogleOAuth = (orgId: string | undefined) => {
  const authProvider = 'Google'
  signInWithPopup(getAuth(), new GoogleAuthProvider())
    .then(() =>
      // Segment Event
      // userId is undefined because we don't know who the user is yet
      logEvent(EVENTS.SIGNED_IN, { authProvider, orgId, userId: undefined })
    )
    .catch(error => {
      console.error(error)
      const { code } = error
      if (code === 'auth/popup-closed-by-user') {
        // Thrown if the popup window is closed by the user without completing the sign in to the provider.
        // Do not trigger a toast
        // Do not send to Sentry
      } else if (code === 'auth/popup-blocked') {
        // Thrown if the popup was blocked by the browser,
        // typically when this operation is triggered outside of a click handler.
        toaster.warning('Please allow popups to sign in with Google')
        // Do not send to Sentry
      } else if (code === 'auth/cancelled-popup-request') {
        // Thrown if successive popup operations are triggered.
        // Only one popup request is allowed at one time.
        // All the popups would fail with this error except for the last one.
        // Do not trigger a toast
        // Do not send to Sentry
      } else if (code === 'auth/internal-error') {
        // The Authentication server encountered an unexpected error while trying to process the request.
        // The error message should contain the response from the Authentication server containing additional information.
        // Do not trigger a toast
        // Do not send to Sentry
      } else {
        captureException(toSentry(error), {
          contexts: {
            signInWithPopup: {
              authProvider,
              orgId,
              userId: undefined,
            },
          },
        })
      }
    })
}

export function GoogleAuthButton({ children }: PropsWithChildren) {
  const org = useContext(OrgContext)
  const orgId = org?.id

  return (
    <Button
      className="gray"
      type="text"
      size="large"
      icon={<Image preview={false} src={GoogleGLogo} />}
      style={{ fontWeight: 500, width: '100%' }}
      onClick={() => openGoogleOAuth(orgId)}
    >
      {children}
    </Button>
  )
}
