import { ListenToActiveCartsByIndividualId } from 'api/databaseCalls/reads/carts'
import { createCart } from 'api/databaseCalls/writes/carts'
import { Cart } from 'gen/perkup/v1/cart_pb'
import useIds from 'hooks/useIds'
import { isEmpty } from 'lodash-es'
import { useEffect, useState } from 'react'

export function useActiveCartByIndividual() {
  const { individualId, orgId } = useIds()
  const [cart, setCart] = useState<Cart>()
  const [hasLoaded, setHasLoaded] = useState(false)

  useEffect(() => {
    return ListenToActiveCartsByIndividualId({
      individualId,
      orgId,
      cb: carts => {
        if (isEmpty(carts)) {
          createCart({ orgId, individualId })
            .then(setCart)
            .finally(() => setHasLoaded(true))
        } else {
          setCart(carts[0])
          setHasLoaded(true)
        }
      },
    })
  }, [orgId, individualId])

  return { cart, hasLoaded }
}
