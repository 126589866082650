import { useTheme } from 'evergreen-ui'
import { motion } from 'framer-motion'
import { CSSProperties, PropsWithChildren } from 'react'

export function PerkActionFooter({
  width = '100%',
  height,
  children,
}: PropsWithChildren<{
  width?: CSSProperties['width']
  height?: CSSProperties['height']
}>) {
  const theme = useTheme()
  return (
    <motion.div
      variants={{ open: { height: 250 }, closed: { height: undefined } }}
      style={{
        display: 'flex',
        justifyContent: 'end',
        position: 'fixed',
        width,
        background: theme.colors.white,
        border: `1px solid ${theme.colors.gray300}`,
        bottom: 0,
        right: 0,
        padding: '16px 32px',
        zIndex: 2,
        height,
      }}
    >
      {children}
    </motion.div>
  )
}
