import { CountrySelection } from 'components/Countries/CountrySelection'
import { CountryContext, UserContext } from 'context'
import { EmptyState, MapMarkerIcon, Pane, useTheme } from 'evergreen-ui'
import { useContext } from 'react'

export function AmazonNotAvailable() {
  const country = useContext(CountryContext)
  const user = useContext(UserContext)

  const theme = useTheme()

  return (
    <EmptyState
      title="Change region"
      description={`Amazon direct ordering is currently not available in ${country?.name}.`}
      background="light"
      orientation="horizontal"
      icon={<MapMarkerIcon color={theme.colors.gray500} />}
      iconBgColor={theme.colors.gray200}
      primaryCta={
        <Pane width="100%">
          <CountrySelection user={user} />
        </Pane>
      }
    />
  )
}
