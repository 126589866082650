import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  DollarOutlined,
  MonitorOutlined,
  NumberOutlined,
} from '@ant-design/icons'
import { useCubeQuery } from '@cubejs-client/react'
import {
  ChartConfig,
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from '@repo/shadcn/es/chart'
import { ErrorBoundary } from '@sentry/react'
import { Segmented, Skeleton, Tag } from 'antd'
import { PerkEmpty } from 'components'
import { Heading } from 'evergreen-ui'
import { useDevSafeOrgId } from 'hooks/useDevSafeOrgId'
import { round } from 'lodash-es'
import { useState } from 'react'
import { CartesianGrid, Line, LineChart, XAxis, YAxis } from 'recharts'
import { getDateTimeString, numToDollars } from 'utils'
import { determineGranularity } from 'utils/insights'
import {
  DateRangeArray,
  DayJsDateRange,
  useInsightsContext,
} from './insights-context'

type RewardsResultSet = ReturnType<typeof useRewardsSentQuery>[0]
type MetricToDisplay = '#' | '$'
type Granularity = 'day' | 'month' | 'week'

function getChartData(
  results: NonNullable<RewardsResultSet>,
  metricToDisplay: MetricToDisplay,
  granularity: Granularity
) {
  const chartDataPoints = results.chartPivot().map(data => {
    const dateString = data.x

    let formattedTime = ''

    if (granularity === 'day') {
      formattedTime = getDateTimeString(new Date(dateString), {
        dateOnly: true,
        hideWeekday: true,
      }) // Jan 3, 2024
    }

    if (granularity === 'week') {
      formattedTime = `Week of ${getDateTimeString(new Date(dateString), {
        dateOnly: true,
        hideWeekday: true,
      })}` // Week of Jan 3, 2024
    }

    if (granularity === 'month') {
      const date = getDateTimeString(new Date(dateString), {
        dateOnly: true,
        hideWeekday: true,
        longDisplay: true,
      }).split(' ')
      formattedTime = `${date[0]} ${date[2]}` // January 2024
    }

    return {
      time: formattedTime,
      sent:
        metricToDisplay === '#'
          ? Number(data['memberships.count'] || 0)
          : round(Number(data['memberships.programCentsBudgeted'] || 0), -2), // These values are in cents, so round to the nearest hundred to get nearest dollar
      accepted:
        metricToDisplay === '#'
          ? Number(data['memberships.acceptedProgramsCount'] || 0)
          : round(Number(data['memberships.acceptedProgramsSpent'] || 0), -2), // These values are in cents, so round to the nearest hundred to get nearest dollar
    }
  })

  const totalSent = chartDataPoints.reduce((acc, { sent }) => acc + sent, 0)

  const totalAccepted = chartDataPoints.reduce(
    (acc, { accepted }) => acc + accepted,
    0
  )

  const largestSentValue = Math.max(...chartDataPoints.map(({ sent }) => sent))

  return { chartDataPoints, totalSent, totalAccepted, largestSentValue }
}

function RewardsSentGraph({
  valueFormatter,
  currentPeriodData,
  granularity,
}: {
  valueFormatter: (value: number) => string
  currentPeriodData: ReturnType<typeof getChartData>
  granularity: Granularity
}) {
  const { chartDataPoints, largestSentValue } = currentPeriodData

  const calculateYAxisLeftMargin = () => {
    // Getting our value to the same number of digits
    const lengthOfLongestYValue = valueFormatter(largestSentValue).length

    if (lengthOfLongestYValue > 6) return 8
    if (lengthOfLongestYValue > 5) return 4
    if (lengthOfLongestYValue > 4) return 0
    if (lengthOfLongestYValue > 3) return -4
    if (lengthOfLongestYValue > 2) return -14
    return -24
  }

  const chartConfig = {
    sent: {
      label: 'Sent',
      color: 'hsl(var(--chart-darker))',
    },
    accepted: {
      label: 'Accepted',
      color: 'hsl(var(--chart-base))',
    },
  } satisfies ChartConfig

  return (
    <ChartContainer className="max-h-44 p-4" config={chartConfig}>
      <LineChart
        accessibilityLayer
        data={chartDataPoints}
        margin={{
          left: calculateYAxisLeftMargin(),
          right: 12,
          top: 12,
        }}
      >
        <CartesianGrid vertical={false} />
        <XAxis
          dataKey="time"
          tickLine={false}
          axisLine={false}
          tickMargin={8}
          tickFormatter={value => {
            // For day granularity, trim the string to just remove the year
            if (granularity === 'day') return value.split(',')[0] // Jan 3

            // For week granularity, trim the string to remove 'Week of' and the year
            if (granularity === 'week') {
              return value.replace('Week of ', '').split(',')[0] // Jan 3
            }

            // For month granularity, trim the string to just show the month
            return value.split(' ')[0] // January
          }}
        />
        <YAxis
          tickLine={false}
          axisLine={false}
          tickMargin={8}
          tickFormatter={valueFormatter}
        />
        <ChartTooltip
          cursor={false}
          content={<ChartTooltipContent valueFormatter={valueFormatter} />}
        />

        <Line
          dataKey="sent"
          type="monotone"
          stroke="var(--color-sent)"
          strokeWidth={2}
          dot={false}
        />

        <Line
          dataKey="accepted"
          type="monotone"
          stroke="var(--color-accepted)"
          strokeWidth={2}
          dot={false}
        />
      </LineChart>
    </ChartContainer>
  )
}

function Error() {
  return (
    <>
      <section className="flex items-center border-b border-b-muted">
        <div className="flex-1 p-4 lg:px-6">
          <Heading>Rewards</Heading>
        </div>
        <div className="flex-1 flex justify-between items-center border-l p-4 lg:px-6">
          <Heading>Sent</Heading>

          <div className="flex gap-2 items-center">
            <div className="w-3 h-3 bg-primary-foreground rounded-full" />
            <Heading size={700}>0</Heading>
          </div>
        </div>
        <div className="flex-1 flex justify-between items-center border-l p-4 lg:px-6">
          <Heading>Accepted</Heading>

          <div className="flex gap-2 items-center">
            <div className="w-3 h-3 bg-primary/70 rounded-full" />
            <Skeleton.Button />
          </div>
        </div>
      </section>

      <section className="p-4 lg:px-6">
        <PerkEmpty
          iconNode={<MonitorOutlined />}
          header="No data found"
          description="Try a different date range or filter"
        />
      </section>
    </>
  )
}

function useRewardsSentQuery(
  dateRange: DateRangeArray | undefined,
  dayJsDateRange: DayJsDateRange | undefined
) {
  const orgId = useDevSafeOrgId()
  const { insightsGlobalFilters } = useInsightsContext()
  const granularity = determineGranularity(dayJsDateRange)

  const { resultSet, isLoading, error } = useCubeQuery({
    limit: 5000,
    measures: [
      'memberships.count',
      'memberships.acceptedProgramsCount',
      'memberships.programCentsBudgeted',
      'memberships.acceptedProgramsSpent',
    ],
    filters: [
      {
        member: 'organizations.id',
        operator: 'equals',
        values: [orgId],
      },
      {
        member: 'programs.status',
        operator: 'notEquals',
        values: ['draft'],
      },
      ...insightsGlobalFilters,
    ],
    timeDimensions: [
      {
        dimension: 'memberships.created',
        granularity,
        dateRange,
      },
    ],
  })

  return [resultSet, isLoading, error, granularity] as const
}

function RewardsSentComponent() {
  const [metricToDisplay, setMetricToDisplay] = useState<MetricToDisplay>('#')
  const { dateRange, dayJsDateRange, previousPeriodDateRange } =
    useInsightsContext()

  const [resultSet, isLoading, error, granularity] = useRewardsSentQuery(
    dateRange,
    dayJsDateRange
  )

  const [previousPeriodResultSet] = useRewardsSentQuery(
    previousPeriodDateRange,
    dayJsDateRange
  )

  function computeRenderData() {
    if (isLoading)
      return {
        loading: true,
      } as const

    if (error || !resultSet || !resultSet.chartPivot().length)
      return { error: true } as const

    const currentPeriodData = getChartData(
      resultSet,
      metricToDisplay,
      granularity
    )

    const previousPeriodData =
      previousPeriodResultSet &&
      // if no date range is selected, we don't want to show the previous period data
      // since there's no previous period to compare to
      dateRange
        ? getChartData(previousPeriodResultSet, metricToDisplay, granularity)
        : undefined

    const periodDiff = previousPeriodData
      ? {
          sent: {
            hasIncreased:
              currentPeriodData.totalSent > previousPeriodData.totalSent,
            percentual:
              100 *
              Math.abs(
                (currentPeriodData.totalSent - previousPeriodData.totalSent) /
                  previousPeriodData.totalSent
              ),
          },
          accepted: {
            hasIncreased:
              currentPeriodData.totalAccepted >
              previousPeriodData.totalAccepted,
            percentual:
              100 *
              Math.abs(
                (currentPeriodData.totalAccepted -
                  previousPeriodData.totalAccepted) /
                  previousPeriodData.totalAccepted
              ),
          },
        }
      : undefined

    const valueFormatter =
      metricToDisplay === '#'
        ? (value: number) => value.toLocaleString() // These values need to be formatted with a comma
        : (value: number) => numToDollars(value, 0) ?? '' // These values are in cents, so this will display in dollar

    return {
      loading: false,
      error: false,
      currentPeriodData,
      totalSent: valueFormatter(currentPeriodData.totalSent),
      totalAccepted: valueFormatter(currentPeriodData.totalAccepted),
      valueFormatter,
      periodDiff: {
        sent:
          periodDiff?.sent &&
          // If the percentual is NaN or 0, we don't want to show it
          !Number.isNaN(periodDiff.sent.percentual) &&
          periodDiff.sent.percentual !== 0
            ? periodDiff?.sent
            : undefined,
        accepted:
          periodDiff?.accepted &&
          // If the percentual is NaN or 0, we don't want to show it
          !Number.isNaN(periodDiff.accepted.percentual) &&
          periodDiff.accepted.percentual !== 0
            ? periodDiff?.accepted
            : undefined,
      },
    } as const
  }

  const state = computeRenderData()
  if (state.error) return <Error />

  return (
    <>
      <section className="flex items-center border-b border-b-muted">
        <div className="flex-1 flex justify-between p-4 lg:px-6">
          <Heading>Rewards</Heading>
          {!state.loading && (
            <Segmented
              className="print:hidden"
              options={[
                { value: '#', icon: <NumberOutlined /> },
                { value: '$', icon: <DollarOutlined /> },
              ]}
              value={metricToDisplay}
              onChange={setMetricToDisplay}
            />
          )}
        </div>
        <div className="flex-1 flex justify-between items-center border-l p-4 lg:px-6">
          <Heading>Sent</Heading>

          <div className="flex gap-2 items-center">
            <div className="w-3 h-3 bg-primary-foreground rounded-full" />
            {state.loading ? (
              <Skeleton.Button active />
            ) : (
              <Heading size={700}>{state.totalSent}</Heading>
            )}
            <div>
              {state.periodDiff?.sent && (
                <Tag
                  className="ml-2"
                  color={
                    state.periodDiff.sent.hasIncreased ? 'success' : 'error'
                  }
                  icon={
                    state.periodDiff.sent.hasIncreased ? (
                      <ArrowUpOutlined />
                    ) : (
                      <ArrowDownOutlined />
                    )
                  }
                >
                  {state.periodDiff.sent.percentual.toFixed(0)}%
                </Tag>
              )}
            </div>
          </div>
        </div>
        <div className="flex-1 flex justify-between items-center border-l p-4 lg:px-6">
          <Heading>Accepted</Heading>

          <div className="flex gap-2 items-center">
            <div className="w-3 h-3 bg-primary/70 rounded-full" />
            {state.loading ? (
              <Skeleton.Button active />
            ) : (
              <Heading size={700}>{state.totalAccepted}</Heading>
            )}
            <div>
              {state.periodDiff?.accepted && (
                <Tag
                  className="ml-2"
                  color={
                    state.periodDiff.accepted.hasIncreased ? 'success' : 'error'
                  }
                  icon={
                    state.periodDiff.accepted.hasIncreased ? (
                      <ArrowUpOutlined />
                    ) : (
                      <ArrowDownOutlined />
                    )
                  }
                >
                  {state.periodDiff.accepted.percentual.toFixed(0)}%
                </Tag>
              )}
            </div>
          </div>
        </div>
      </section>

      {state.loading ? (
        <section className="p-4 lg:px-6">
          <Skeleton active paragraph={{ rows: 3 }} />
        </section>
      ) : (
        <RewardsSentGraph
          valueFormatter={state.valueFormatter}
          currentPeriodData={state.currentPeriodData}
          granularity={granularity}
        />
      )}
    </>
  )
}

export function RewardsSent() {
  return (
    <div className="flex flex-col w-full rounded-lg border border-muted">
      <ErrorBoundary fallback={<Error />}>
        <RewardsSentComponent />
      </ErrorBoundary>
    </div>
  )
}
