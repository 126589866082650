import { CheckOutlined, CloseOutlined } from '@ant-design/icons'
import { Button, Flex, Image, Switch } from 'antd'
import { ThemeEditorModal } from 'components'
import { TemplateContext } from 'context'
import { Text } from 'evergreen-ui'
import { Template_Image } from 'gen/perkup/v1/org_template_pb'
import { useContext } from 'react'

export function TemplateImageForm({
  onTemplateImageChange,
}: {
  onTemplateImageChange: (newTemplateImage: Template_Image) => void
}) {
  const template = useContext(TemplateContext)
  const templateImage = template?.image
  const occasion = template?.occasion

  return (
    <Flex vertical gap={16}>
      {templateImage?.url && (
        <Image
          style={{
            maxHeight: 256,
            maxWidth: 256,
            objectFit: 'contain',
            borderRadius: 8,
          }}
          src={templateImage.url}
        />
      )}

      <ThemeEditorModal
        onConfirmImage={image => {
          onTemplateImageChange(
            new Template_Image({
              ...templateImage,
              url: image,
            })
          )
        }}
        modalTrigger={
          <Button size="small" type={templateImage ? 'default' : 'primary'}>
            {templateImage ? 'Change image' : 'Select image'}
          </Button>
        }
        defaultOccasion={occasion}
      />
      <Flex gap={8}>
        <Switch
          unCheckedChildren={<CloseOutlined />}
          checkedChildren={<CheckOutlined />}
          checked={templateImage?.writable}
          onChange={checked => {
            onTemplateImageChange(
              new Template_Image({
                ...templateImage,
                writable: checked,
              })
            )
          }}
        />
        <Text>Let sender customize image</Text>
      </Flex>
    </Flex>
  )
}
