import { SearchOutlined } from '@ant-design/icons'
import { Input } from 'antd'
import { AMAZON_GRAY } from 'constants/amazon'
import { Pane, Text, useTheme } from 'evergreen-ui'
import { isMobile } from 'react-device-detect'

export function SearchbarCard({
  Logo,
  helpText,
  backgroundColor = AMAZON_GRAY,
  textColor = 'white',
  searchbarPlaceholder = '',
  handleSearch,
  disabled = false,
  defaultValue,
}: {
  Logo?: () => JSX.Element
  helpText?: string
  backgroundColor?: string
  textColor?: string
  searchbarPlaceholder?: string
  handleSearch: (value: string) => void
  disabled?: boolean
  defaultValue?: string | undefined
}) {
  const theme = useTheme()
  return (
    <Pane
      backgroundColor={backgroundColor}
      borderRadius={8}
      height="100%"
      width="100%"
      display="flex"
      flexWrap="wrap"
      justifyContent="center"
      flexDirection="column"
      alignItems="center"
      padding={32}
    >
      {Logo && (
        <Pane height={56} width="100%" marginBottom={24}>
          <Logo />
        </Pane>
      )}

      {helpText && (
        <Text
          color={textColor}
          fontSize={16}
          width={isMobile ? '90%' : '35%'}
          textAlign="center"
          marginBottom={48}
        >
          {helpText}
        </Text>
      )}

      <Pane width="75%">
        <Input
          defaultValue={defaultValue}
          placeholder={searchbarPlaceholder}
          size="large"
          prefix={<SearchOutlined style={{ color: theme?.colors?.gray500 }} />}
          disabled={disabled}
          onPressEnter={e => handleSearch(e.currentTarget.value)}
        />
      </Pane>
    </Pane>
  )
}
