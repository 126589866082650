import { ListenToPersonalFundsProgramByUserId } from 'api/databaseCalls'
import { Program } from 'gen/perkup/v1/program_pb'
import useIds from 'hooks/useIds'
import { useEffect, useState } from 'react'

export function useListenToPersonalFundsProgram() {
  const { userId, orgId } = useIds()
  const [program, setProgram] = useState<Program>()
  const [hasLoaded, setHasLoaded] = useState(false)

  useEffect(() => {
    return ListenToPersonalFundsProgramByUserId({
      orgId,
      userId,
      cb: program => {
        setProgram(program)
        setHasLoaded(true)
      },
    })
  }, [userId, orgId])

  return { program, hasLoaded }
}
