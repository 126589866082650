import { Select } from 'antd'
import { Pane, Text, toaster, useTheme } from 'evergreen-ui'
import { Account } from 'gen/perkup/v1/account_pb'
import { useFullAccessAccounts } from 'hooks/accounts/use-full-access-accounts'
import { numToDollars } from 'utils'

export function SelectFullAccessAccount({
  setSelectedAccount,
  selectedAccount,
}: {
  setSelectedAccount: (account: Account | undefined) => void
  selectedAccount?: Account
}) {
  const theme = useTheme()

  const { fullAccessAccounts } = useFullAccessAccounts()
  const accountId = selectedAccount?.id

  const handleSelectAccount = (newAccountId: string) => {
    const selectedAccount = fullAccessAccounts.find(
      account => account.id === newAccountId
    )
    if (selectedAccount) {
      setSelectedAccount(selectedAccount)
    } else {
      toaster.warning('Failed to find account, please contact support')
    }
  }

  return (
    <Select
      style={{ width: 256 }}
      dropdownStyle={{ width: 'fit-content' }}
      onSelect={handleSelectAccount}
      value={accountId}
      placement="bottomRight"
      placeholder="Select a balance"
      showSearch
      optionFilterProp="label"
    >
      {fullAccessAccounts.map(account => {
        const balanceDisplay = numToDollars(Number(account.balance))
        return (
          <Select.Option
            key={account.id}
            value={account.id}
            label={account.name}
          >
            <Pane
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              height="100%"
              gap={8}
            >
              <Text className="one-line-truncate">{account.name}</Text>
              <Text color={theme.colors.green400}>{balanceDisplay}</Text>
            </Pane>
          </Select.Option>
        )
      })}
    </Select>
  )
}
