import { defaultOccasions } from 'constants/newReward/occasions'
import {
  RewardUseCase,
  RewardUseCases,
} from 'constants/newReward/rewardUseCases'
import { ProgramTemplate } from 'gen/perkup/v1/contentful_pb'
import { Program, ProgramType } from 'gen/perkup/v1/program_pb'
import { findKey, flatten, intersectionWith } from 'lodash-es'
import placidData from 'constants/placid/placid-data.json'
import { CategoryTitle } from 'pages/Templates/constants'

/**
 *
 * @param template to check
 * @returns whether or not the input template is food related
 */
export function isFoodProgramTemplate(template: ProgramTemplate): boolean {
  return !!template.categories.find(cat => cat.title === CategoryTitle.LUNCHES)
}

export function programsToTemplates({ programs }: { programs: Program[] }) {
  return programs.map((program, index): ProgramTemplate => {
    return new ProgramTemplate({
      id: program.id,
      name: program.name,
      previewImage: program?.email?.banner,
      programImage: program?.email?.banner,
      programTitle: program?.email?.title,
      programType: ProgramType.rewards,
      rank: index,
      categories: [],
      imageQuery: '',
      programMessage: program.note,
    })
  })
}

export const filterTemplatesByUseCase = ({
  templates,
  useCases,
}: {
  templates: ProgramTemplate[]
  useCases: RewardUseCase[]
}) => {
  const allowedTemplateCategories = useCases.map(rewardUseCase => {
    const templateCategories =
      RewardUseCases[rewardUseCase]?.details.templateCategories
    return templateCategories ? templateCategories[0] : undefined
  })
  return templates.filter(template => {
    return (
      intersectionWith(
        template.categories,
        allowedTemplateCategories,
        (template, category) => template.title === category
      ).length > 0
    )
  })
}

export const getDefaultOccasionFromTemplate = ({
  template,
}: {
  template?: ProgramTemplate
}) => {
  if (!template) return undefined
  const templateCategoryTitles = template.categories.map(
    category => category.title
  )

  const rewardUseCase = findKey(RewardUseCases, rewardUseCase =>
    templateCategoryTitles.includes(
      rewardUseCase.details.templateCategories[0] ?? ''
    )
  )
  if (!rewardUseCase) return undefined

  const defaultOccasion = defaultOccasions.find(occasion =>
    occasion.useCases.some(occasionUseCase => {
      return occasionUseCase.toString() === rewardUseCase
    })
  )
  return defaultOccasion
}

export const getPlacidTemplateIdsFromUseCases = ({
  useCases,
}: {
  useCases: RewardUseCase[]
}) => {
  const tags = flatten(
    useCases.map(useCase => RewardUseCases[useCase]?.details.placidTags)
  )

  return placidData
    .filter(template => {
      return (
        intersectionWith(
          template.tags,
          tags,
          (tag, category) => tag === category
        ).length > 0
      )
    })
    .map(template => template.uuid)
}
