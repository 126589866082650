/**
 *
 * https://www.geeksforgeeks.org/how-to-strip-out-html-tags-from-a-string-using-javascript/
 */
export function removeHtmlTags(htmlString: string) {
  // Create a new DOMParser instance
  const parser = new DOMParser()
  // Parse the HTML string
  const doc = parser.parseFromString(htmlString, 'text/html')
  // Extract text content
  const textContent = doc.body.textContent || ''
  // Trim whitespace
  return textContent.trim()
}
