import { Flex } from 'antd'
import { Variant } from 'antd/es/form/hooks/useVariants'
import { CountrySelect } from 'components'
import { FILTER_DISPLAY_NAMES } from 'constants/algolia'
import { ACTIVE_ISO3 } from 'constants/sessionOrLocalStorage'
import { Strong } from 'evergreen-ui'
import startCase from 'lodash-es/startCase'
import { useClearRefinements, useMenu } from 'react-instantsearch'
import { compareAlgoliaCountryFacets } from 'utils'

export function AlgoliaCountryFilter({
  variant,
  withTitle = false,
}: {
  variant?: Variant
  withTitle?: boolean
}) {
  const attribute = 'shippingCountries'
  const { items: algoliaCountries, refine: refineCountries } = useMenu({
    attribute,
    limit: 200,
    sortBy: compareAlgoliaCountryFacets,
  })

  const { refine: clearRefine } = useClearRefinements({
    includedAttributes: [attribute],
  })

  const iso3s = algoliaCountries.map(country => country.value)
  const activeCountry = algoliaCountries.find(country => country.isRefined)

  const handleCountryClick = (countryMenuItemKey: string) => {
    if (countryMenuItemKey === activeCountry?.value) return
    localStorage.setItem(ACTIVE_ISO3, countryMenuItemKey)
    refineCountries(countryMenuItemKey)
  }

  const handleCountryClear = () => {
    localStorage.removeItem(ACTIVE_ISO3)
    clearRefine()
  }

  if (withTitle) {
    return (
      <Flex vertical gap={8}>
        <Strong>{startCase(FILTER_DISPLAY_NAMES[attribute])}</Strong>
        <CountrySelect
          activeIso3={activeCountry?.value}
          variant={variant}
          iso3s={iso3s}
          onSelect={handleCountryClick}
          onClear={handleCountryClear}
        />
      </Flex>
    )
  }

  return (
    <CountrySelect
      activeIso3={activeCountry?.value}
      variant={variant}
      iso3s={iso3s}
      onSelect={handleCountryClick}
      onClear={handleCountryClear}
    />
  )
}
