import { IndividualContext, UserContext } from 'context'
import { useContext } from 'react'

export function useIsSignedInUser(data: {
  email: string
  memberId: string
}): boolean {
  const { email, memberId } = data

  const user = useContext(UserContext)
  const individual = useContext(IndividualContext)

  const isSameEmail = user?.profile?.email === email
  const isSameUserId = user?.id === memberId
  const isSameIndividualId = individual?.id === memberId
  const isCorrectUser = isSameEmail || isSameUserId || isSameIndividualId

  return isCorrectUser
}
