import { captureException } from '@sentry/react'
import { PLUM, PRINTFUL, PV_DEFAULT_TAGS, SHOPIFY_TAG } from 'constants/algolia'
import {
  and,
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  onSnapshot,
  or,
  query,
  where,
} from 'firebase/firestore'
import { ProductVariant } from 'gen/perkup/v1/product_variant_pb'
import { Item } from 'gen/perkup/v1/program_pb'
import { compact, concat } from 'lodash-es'
import { afterLastSlash, toSentry } from 'utils'
import { converter } from 'utils/firestore'

export async function listProductVariantsByProductId({
  productId,
  orgId,
}: {
  productId: string
  orgId: string
}): Promise<ProductVariant[]> {
  const db = getFirestore()

  const colRef = collection(db, `productVariants`).withConverter(
    converter(ProductVariant)
  )

  const q = query(
    colRef,
    and(
      where('productId', '==', productId),
      or(
        where('tags', 'array-contains-any', [...PV_DEFAULT_TAGS, orgId]),
        where('provider', 'in', [PLUM, PRINTFUL, SHOPIFY_TAG])
      )
    )
  )

  return getDocs(q)
    .then(query => query.docs.map(doc => doc.data()))
    .catch(error => {
      console.error(error)
      captureException(toSentry(error), {
        contexts: {
          listProductVariantsByProductId: { productId },
        },
      })
      return []
    })
}

export async function listenToProductVariantsByProductId({
  productId,
  cb,
  orgId,
}: {
  productId: string
  cb: Function
  orgId: string
}) {
  const db = getFirestore()
  const colRef = collection(db, `productVariants`).withConverter(
    converter(ProductVariant)
  )

  const q = query(
    colRef,
    and(
      where('productId', '==', productId),
      or(
        where('provider', 'in', [PLUM, PRINTFUL]),
        where('tags', 'array-contains-any', [...PV_DEFAULT_TAGS, orgId])
      )
    )
  )

  return onSnapshot(
    q,
    query => cb(query.docs.map(doc => doc.data())),
    error => {
      console.error(error)
      captureException(toSentry(error), {
        contexts: {
          listenToProductVariantsByProductId: { productId },
        },
      })
    }
  )
}

export async function GetProductVariantById({
  productVariantId,
}: {
  productVariantId: string
}) {
  try {
    const db = getFirestore()
    const docRef = doc(db, `productVariants/${productVariantId}`).withConverter(
      converter(ProductVariant)
    )
    return await getDoc(docRef).then(doc => doc.data())
  } catch (error) {
    console.error(error)
    captureException(toSentry(error), {
      contexts: {
        GetProductVariantById: { productVariantId },
      },
    })
    return undefined
  }
}

export async function ListProductVariantsByProgramItems({
  programItems,
}: {
  programItems: Item[]
}) {
  const products: ProductVariant[] = []
  try {
    compact(
      await Promise.all(
        programItems.map(async item => {
          const productVariantId = afterLastSlash(item.productVariantId)
          const productVariant = await GetProductVariantById({
            productVariantId,
          })
          return productVariant
        })
      )
    ).forEach(product => products.push(product))
    return products
  } catch (error) {
    console.error(error)
    captureException(toSentry(error), {
      contexts: {
        ListProductVariantsByProgramItems: { programItems },
      },
    })
    return undefined
  }
}

export async function ListProductVariantsByIds({ ids }: { ids: string[] }) {
  const products: ProductVariant[] = []
  try {
    compact(
      await Promise.all(
        ids.map(async id => {
          const productVariant = await GetProductVariantById({
            productVariantId: id,
          })
          return productVariant
        })
      )
    ).forEach(product => products.push(product))
    return products
  } catch (error) {
    console.error(error)
    captureException(toSentry(error), {
      contexts: {
        ListProductVariantsByIds: { ids },
      },
    })
    return undefined
  }
}

/** @deprecated we should opt to use the simpler V2 version below */
export async function ListProductVariantsByProductIds({
  productIds,
  uniq,
  orgId,
}: {
  productIds: string[]
  uniq?: boolean
  orgId: string
}) {
  let productsVariants: ProductVariant[] = []

  try {
    const products = compact(
      await Promise.all(
        productIds.map(async productId => {
          const productVariant = await listProductVariantsByProductId({
            productId,
            orgId,
          })
          return productVariant
        })
      )
    )

    products.forEach(productVariantArray => {
      if (uniq) {
        productsVariants.push(productVariantArray[0])
      } else {
        productsVariants = concat(productsVariants, productVariantArray)
      }
    })

    return productsVariants
  } catch (error) {
    console.error(error)
    captureException(toSentry(error), {
      contexts: {
        ListProductVariantsByProductIds: { productIds },
      },
    })
    return []
  }
}

export async function ListProductVariantsByProductIdsV2({
  productIds,
  orgId,
}: {
  productIds: string[]
  orgId: string
}) {
  try {
    const products = compact(
      await Promise.all(
        productIds.map(async productId => {
          const productVariant = await listProductVariantsByProductId({
            productId,
            orgId,
          })
          return productVariant
        })
      )
    )

    const allProductVariants = products.flat()

    return allProductVariants
  } catch (error) {
    console.error(error)
    captureException(toSentry(error), {
      contexts: {
        ListProductVariantsByProductIdsV2: { productIds },
      },
    })
    return []
  }
}

export function ListenToProductVariantsByCollectionId({
  collectionId,
  cb,
}: {
  collectionId: string
  cb: (productVariants: ProductVariant[]) => void
}) {
  const db = getFirestore()
  const colRef = collection(db, `productVariants`).withConverter(
    converter(ProductVariant)
  )

  const q = query(
    colRef,
    and(where('collectionIds', 'array-contains', collectionId))
  )

  return onSnapshot(
    q,
    query => cb(query.docs.map(doc => doc.data())),
    error => {
      console.error(error)
      captureException(toSentry(error), {
        contexts: {
          listenToProductVariantsByCollectionId: { collectionId },
        },
      })
    }
  )
}
