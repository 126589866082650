// @generated by protoc-gen-es v1.6.0 with parameter "target=ts"
// @generated from file canvas/designs.proto (package canvas, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, Timestamp } from "@bufbuild/protobuf";

/**
 * @generated from enum canvas.Supplier
 */
export enum Supplier {
  /**
   * @generated from enum value: SUPPLIER_UNSPECIFIED = 0;
   */
  SUPPLIER_UNSPECIFIED = 0,

  /**
   * @generated from enum value: printful = 1;
   */
  printful = 1,

  /**
   * @generated from enum value: fulfillEngine = 2;
   */
  fulfillEngine = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(Supplier)
proto3.util.setEnumType(Supplier, "canvas.Supplier", [
  { no: 0, name: "SUPPLIER_UNSPECIFIED" },
  { no: 1, name: "printful" },
  { no: 2, name: "fulfillEngine" },
]);

/**
 * Firestore /canvasDesigns/:id/
 *
 * @generated from message canvas.CanvasDesign
 */
export class CanvasDesign extends Message<CanvasDesign> {
  /**
   * customer canvas private design id
   *
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: canvas.CanvasDesign.Type type = 2;
   */
  type = CanvasDesign_Type.TYPE_UNSPECIFIED;

  /**
   * @generated from field: repeated canvas.Variable variables = 3;
   */
  variables: Variable[] = [];

  /**
   * shopify product id
   *
   * @generated from field: string shopify_product_id = 4;
   */
  shopifyProductId = "";

  /**
   * @generated from field: string org_id = 5;
   */
  orgId = "";

  /**
   * @generated from field: string individual_id = 6;
   */
  individualId = "";

  /**
   * @generated from field: string user_id = 7;
   */
  userId = "";

  /**
   * @generated from field: canvas.CanvasDesign.CustomersCanvas customers_canvas = 8;
   */
  customersCanvas?: CanvasDesign_CustomersCanvas;

  /**
   * @generated from field: canvas.Supplier supplier = 9;
   */
  supplier = Supplier.SUPPLIER_UNSPECIFIED;

  /**
   * @generated from field: canvas.CanvasDesign.PrintMetadata print_metadata = 11;
   */
  printMetadata?: CanvasDesign_PrintMetadata;

  /**
   * @generated from field: google.protobuf.Timestamp created = 12;
   */
  created?: Timestamp;

  /**
   * @generated from field: string customer_canvas_variant_id = 13;
   */
  customerCanvasVariantId = "";

  /**
   * @generated from field: repeated canvas.CanvasDesign.Option options = 14;
   */
  options: CanvasDesign_Option[] = [];

  constructor(data?: PartialMessage<CanvasDesign>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "canvas.CanvasDesign";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "type", kind: "enum", T: proto3.getEnumType(CanvasDesign_Type) },
    { no: 3, name: "variables", kind: "message", T: Variable, repeated: true },
    { no: 4, name: "shopify_product_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "org_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "individual_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "user_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "customers_canvas", kind: "message", T: CanvasDesign_CustomersCanvas },
    { no: 9, name: "supplier", kind: "enum", T: proto3.getEnumType(Supplier) },
    { no: 11, name: "print_metadata", kind: "message", T: CanvasDesign_PrintMetadata },
    { no: 12, name: "created", kind: "message", T: Timestamp },
    { no: 13, name: "customer_canvas_variant_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 14, name: "options", kind: "message", T: CanvasDesign_Option, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CanvasDesign {
    return new CanvasDesign().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CanvasDesign {
    return new CanvasDesign().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CanvasDesign {
    return new CanvasDesign().fromJsonString(jsonString, options);
  }

  static equals(a: CanvasDesign | PlainMessage<CanvasDesign> | undefined, b: CanvasDesign | PlainMessage<CanvasDesign> | undefined): boolean {
    return proto3.util.equals(CanvasDesign, a, b);
  }
}

/**
 * @generated from enum canvas.CanvasDesign.Type
 */
export enum CanvasDesign_Type {
  /**
   * @generated from enum value: TYPE_UNSPECIFIED = 0;
   */
  TYPE_UNSPECIFIED = 0,

  /**
   * @generated from enum value: public = 1;
   */
  public = 1,

  /**
   * @generated from enum value: private = 2;
   */
  private = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(CanvasDesign_Type)
proto3.util.setEnumType(CanvasDesign_Type, "canvas.CanvasDesign.Type", [
  { no: 0, name: "TYPE_UNSPECIFIED" },
  { no: 1, name: "public" },
  { no: 2, name: "private" },
]);

/**
 * @generated from message canvas.CanvasDesign.CustomersCanvas
 */
export class CanvasDesign_CustomersCanvas extends Message<CanvasDesign_CustomersCanvas> {
  /**
   * @generated from field: string public_design_id = 1;
   */
  publicDesignId = "";

  /**
   * @generated from field: string project_id = 2;
   */
  projectId = "";

  /**
   * owner of this private design
   *
   * @generated from field: string owner_id = 3;
   */
  ownerId = "";

  constructor(data?: PartialMessage<CanvasDesign_CustomersCanvas>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "canvas.CanvasDesign.CustomersCanvas";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "public_design_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "project_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "owner_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CanvasDesign_CustomersCanvas {
    return new CanvasDesign_CustomersCanvas().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CanvasDesign_CustomersCanvas {
    return new CanvasDesign_CustomersCanvas().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CanvasDesign_CustomersCanvas {
    return new CanvasDesign_CustomersCanvas().fromJsonString(jsonString, options);
  }

  static equals(a: CanvasDesign_CustomersCanvas | PlainMessage<CanvasDesign_CustomersCanvas> | undefined, b: CanvasDesign_CustomersCanvas | PlainMessage<CanvasDesign_CustomersCanvas> | undefined): boolean {
    return proto3.util.equals(CanvasDesign_CustomersCanvas, a, b);
  }
}

/**
 * @generated from message canvas.CanvasDesign.PrintMetadata
 */
export class CanvasDesign_PrintMetadata extends Message<CanvasDesign_PrintMetadata> {
  /**
   * @generated from field: string print_sku = 1;
   */
  printSku = "";

  /**
   * @generated from field: repeated canvas.CanvasDesign.PrintMetadata.PrintItem print_items = 2;
   */
  printItems: CanvasDesign_PrintMetadata_PrintItem[] = [];

  constructor(data?: PartialMessage<CanvasDesign_PrintMetadata>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "canvas.CanvasDesign.PrintMetadata";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "print_sku", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "print_items", kind: "message", T: CanvasDesign_PrintMetadata_PrintItem, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CanvasDesign_PrintMetadata {
    return new CanvasDesign_PrintMetadata().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CanvasDesign_PrintMetadata {
    return new CanvasDesign_PrintMetadata().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CanvasDesign_PrintMetadata {
    return new CanvasDesign_PrintMetadata().fromJsonString(jsonString, options);
  }

  static equals(a: CanvasDesign_PrintMetadata | PlainMessage<CanvasDesign_PrintMetadata> | undefined, b: CanvasDesign_PrintMetadata | PlainMessage<CanvasDesign_PrintMetadata> | undefined): boolean {
    return proto3.util.equals(CanvasDesign_PrintMetadata, a, b);
  }
}

/**
 * @generated from message canvas.CanvasDesign.PrintMetadata.PrintItem
 */
export class CanvasDesign_PrintMetadata_PrintItem extends Message<CanvasDesign_PrintMetadata_PrintItem> {
  /**
   * @generated from field: string print_url = 1;
   */
  printUrl = "";

  /**
   * @generated from field: optional string print_preview_url = 2;
   */
  printPreviewUrl?: string;

  /**
   * @generated from field: string print_location = 3;
   */
  printLocation = "";

  /**
   * @generated from field: string print_method = 4;
   */
  printMethod = "";

  constructor(data?: PartialMessage<CanvasDesign_PrintMetadata_PrintItem>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "canvas.CanvasDesign.PrintMetadata.PrintItem";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "print_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "print_preview_url", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 3, name: "print_location", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "print_method", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CanvasDesign_PrintMetadata_PrintItem {
    return new CanvasDesign_PrintMetadata_PrintItem().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CanvasDesign_PrintMetadata_PrintItem {
    return new CanvasDesign_PrintMetadata_PrintItem().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CanvasDesign_PrintMetadata_PrintItem {
    return new CanvasDesign_PrintMetadata_PrintItem().fromJsonString(jsonString, options);
  }

  static equals(a: CanvasDesign_PrintMetadata_PrintItem | PlainMessage<CanvasDesign_PrintMetadata_PrintItem> | undefined, b: CanvasDesign_PrintMetadata_PrintItem | PlainMessage<CanvasDesign_PrintMetadata_PrintItem> | undefined): boolean {
    return proto3.util.equals(CanvasDesign_PrintMetadata_PrintItem, a, b);
  }
}

/**
 * @generated from message canvas.CanvasDesign.Option
 */
export class CanvasDesign_Option extends Message<CanvasDesign_Option> {
  /**
   * shopifyProducts/:id/options/n/name
   *
   * @generated from field: string name = 1;
   */
  name = "";

  /**
   * must an slice of shopifyProducts/:id/options/n/id/values
   *
   * @generated from field: repeated string values = 2;
   */
  values: string[] = [];

  constructor(data?: PartialMessage<CanvasDesign_Option>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "canvas.CanvasDesign.Option";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "values", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CanvasDesign_Option {
    return new CanvasDesign_Option().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CanvasDesign_Option {
    return new CanvasDesign_Option().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CanvasDesign_Option {
    return new CanvasDesign_Option().fromJsonString(jsonString, options);
  }

  static equals(a: CanvasDesign_Option | PlainMessage<CanvasDesign_Option> | undefined, b: CanvasDesign_Option | PlainMessage<CanvasDesign_Option> | undefined): boolean {
    return proto3.util.equals(CanvasDesign_Option, a, b);
  }
}

/**
 * atoms api design variables
 *
 * @generated from message canvas.Variable
 */
export class Variable extends Message<Variable> {
  /**
   * @generated from field: string name = 1;
   */
  name = "";

  /**
   * @generated from field: string value = 2;
   */
  value = "";

  /**
   * @generated from field: string type = 3;
   */
  type = "";

  constructor(data?: PartialMessage<Variable>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "canvas.Variable";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "value", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Variable {
    return new Variable().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Variable {
    return new Variable().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Variable {
    return new Variable().fromJsonString(jsonString, options);
  }

  static equals(a: Variable | PlainMessage<Variable> | undefined, b: Variable | PlainMessage<Variable> | undefined): boolean {
    return proto3.util.equals(Variable, a, b);
  }
}

/**
 * @generated from message canvas.BuildCanvasDesignRequest
 */
export class BuildCanvasDesignRequest extends Message<BuildCanvasDesignRequest> {
  /**
   * @generated from field: string product_id = 1;
   */
  productId = "";

  /**
   * @generated from field: string public_design_id = 2;
   */
  publicDesignId = "";

  /**
   * @generated from field: canvas.Supplier supplier = 3;
   */
  supplier = Supplier.SUPPLIER_UNSPECIFIED;

  /**
   * @generated from field: string org_id = 4;
   */
  orgId = "";

  /**
   * @generated from field: string individual_id = 5;
   */
  individualId = "";

  /**
   * @generated from field: string user_id = 6;
   */
  userId = "";

  /**
   * @generated from field: string sku = 7;
   */
  sku = "";

  /**
   * @generated from field: repeated string mockup_urls = 8;
   */
  mockupUrls: string[] = [];

  /**
   * @generated from field: string customer_canvas_variant_id = 9;
   */
  customerCanvasVariantId = "";

  /**
   * @generated from field: repeated canvas.CanvasDesign.Option options = 10;
   */
  options: CanvasDesign_Option[] = [];

  /**
   * @generated from field: repeated canvas.Variable variables = 11;
   */
  variables: Variable[] = [];

  constructor(data?: PartialMessage<BuildCanvasDesignRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "canvas.BuildCanvasDesignRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "product_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "public_design_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "supplier", kind: "enum", T: proto3.getEnumType(Supplier) },
    { no: 4, name: "org_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "individual_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "user_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "sku", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "mockup_urls", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 9, name: "customer_canvas_variant_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "options", kind: "message", T: CanvasDesign_Option, repeated: true },
    { no: 11, name: "variables", kind: "message", T: Variable, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): BuildCanvasDesignRequest {
    return new BuildCanvasDesignRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): BuildCanvasDesignRequest {
    return new BuildCanvasDesignRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): BuildCanvasDesignRequest {
    return new BuildCanvasDesignRequest().fromJsonString(jsonString, options);
  }

  static equals(a: BuildCanvasDesignRequest | PlainMessage<BuildCanvasDesignRequest> | undefined, b: BuildCanvasDesignRequest | PlainMessage<BuildCanvasDesignRequest> | undefined): boolean {
    return proto3.util.equals(BuildCanvasDesignRequest, a, b);
  }
}

