import { GiphyFetch } from '@giphy/js-fetch-api'
import { GIPHY_API_KEY, UNSPLASH_ACCESS_KEY } from 'constants/keys'
import { createApi } from 'unsplash-js'

export const unsplashApi = createApi({
  // See https://unsplash.com/developers
  accessKey: UNSPLASH_ACCESS_KEY,
})

export const giphyApi = new GiphyFetch(GIPHY_API_KEY)
