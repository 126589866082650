import { useRef, useState } from 'react'
import { useInView } from 'react-intersection-observer'

const INITIAL_LIMIT = 15
const TIME_TO_REFRESH = 5_000
const TIME_TO_WAIT = 2_500

export function useInfiniteScroll() {
  const [currentLimit, setCurrentLimit] = useState(INITIAL_LIMIT)
  const lastUpdated = useRef(Date.now())
  const incrementLimit = () => {
    setCurrentLimit(prev => prev + INITIAL_LIMIT)
    lastUpdated.current = Date.now()
  }

  const handleUpdateLimit = (depth = 0) => {
    if (depth > 2) return

    if (Date.now() - lastUpdated.current > TIME_TO_REFRESH) {
      incrementLimit()
    } else {
      setTimeout(() => {
        handleUpdateLimit(depth + 1)
      }, TIME_TO_WAIT)
    }
  }

  const { ref: sentinelRef } = useInView({
    onChange: inView => {
      if (inView) {
        handleUpdateLimit()
      }
    },
  })

  return {
    currentLimit,
    sentinelRef,
    isFirstPage: currentLimit === INITIAL_LIMIT,
  }
}
