import { ProgramStatus } from 'gen/perkup/v1/program_pb'

export const MEMBERSHIP_TABS: ProgramStatus[] = [
  ProgramStatus.active,
  ProgramStatus.inactive,
]

export const MEMBERSHIP_TAB_LABELS: Record<ProgramStatus, string> = {
  [ProgramStatus.UNKNOWN_ProgramStatus]: 'unknown',
  [ProgramStatus.active]: 'active',
  [ProgramStatus.inactive]: 'inactive',
  [ProgramStatus.draft]: 'draft',
}
