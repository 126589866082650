import Color from 'color'
import { defaultTheme, mergeTheme } from 'evergreen-ui'
import merge from 'lodash-es/merge'
import { fontFamilies } from './perkUpTheme'

export const customEvergreenTheme = ({
  primaryColor,
}: {
  primaryColor?: string
}) => {
  let customTheme = defaultTheme

  customTheme = merge(defaultTheme, {
    fontFamilies: {
      display: fontFamilies.display,
    },
    components: {
      Heading: {
        sizes: {
          900: {
            fontWeight: 700,
            fontSize: '32px',
            lineHeight: '40px',
          },
          800: {
            fontWeight: 700,
            fontSize: '24px',
            lineHeight: '32px',
          },
          700: {
            fontWeight: 600,
            fontSize: '24px',
            lineHeight: '24px',
          },
          600: {
            fontWeight: 600,
            fontSize: '16px',
            lineHeight: '24px',
          },
          500: {
            fontWeight: 600,
            fontSize: '16px',
            lineHeight: '24px',
          },
          400: {
            fontWeight: 500,
            fontSize: '14px',
            lineHeight: '24px',
          },
          300: {
            fontWeight: 500,
            fontSize: '14px',
            lineHeight: '22px',
          },
          200: {
            fontWeight: 500,
            fontSize: '12px',
            lineHeight: '20px',
          },
          100: {
            fontWeight: 500,
            fontSize: '12px',
            lineHeight: '12px',
          },
        },
      },
      Text: {
        sizes: {
          500: {
            fontWeight: 400,
            fontSize: '16px',
            lineHeight: '24px',
          },
          400: {
            fontWeight: 400,
            fontSize: '14px',
            lineHeight: '22px',
          },
          300: {
            fontWeight: 400,
            fontSize: '12px',
            lineHeight: '20px',
          },
        },
      },
      Parargraph: {
        sizes: {
          500: {
            fontWeight: 400,
            fontSize: '16px',
            lineHeight: '24px',
          },
          400: {
            fontWeight: 400,
            fontSize: '14px',
            lineHeight: '22px',
          },
          300: {
            fontWeight: 400,
            fontSize: '12px',
            lineHeight: '20px',
          },
        },
      },
    },
  })

  if (primaryColor) {
    const color = Color(primaryColor)
    const lighterBackgroundColor = color.lightness(95).toString()

    customTheme = mergeTheme(defaultTheme, {
      colors: {
        selected: primaryColor,
      },
      components: {
        Tab: {
          appearances: {
            primary: {
              selectors: {
                _before: {
                  backgroundColor: color.hex(),
                },
                _current: {
                  color: color.hex(),
                  '&:focus': {
                    color: color.hex(),
                  },
                },
              },
            },

            secondary: {
              selectors: {
                _current: {
                  backgroundColor: lighterBackgroundColor,
                  color: color.hex(),
                },

                _disabled: {
                  pointerEvents: 'none',
                  cursor: 'not-allowed',
                  color: 'colors.gray500',

                  '&[aria-current="page"],&[aria-selected="true"]': {
                    backgroundColor: lighterBackgroundColor,
                  },
                },
              },
            },
          },
        },
        Button: {
          appearances: {
            primary: {
              color: 'white',
              backgroundColor: color.toString(),
              selectors: {
                _hover: {
                  backgroundColor: color.darken(0.1).toString(),
                },
                _active: {
                  backgroundColor: color.darken(0.1).toString(),
                },
                _focus: {
                  boxShadow: color.darken(0.1).toString(),
                },
                _disabled: {
                  backgroundColor: color.lightness(80).toString(),
                },
              },
            },
          },
        },
      },
    })
  }
  return mergeTheme(defaultTheme, customTheme)
}
