import { ListenToOrgAccountById } from 'api/databaseCalls'
import { Account } from 'gen/perkup/v1/account_pb'
import useIds from 'hooks/useIds'
import { useEffect, useState } from 'react'

export function usePersonalFundsAccount() {
  const { individualId, orgId } = useIds()
  const [account, setAccount] = useState<Account>()
  const [hasLoaded, setHasLoaded] = useState(false)

  useEffect(() => {
    return ListenToOrgAccountById({
      accountId: `pf_${individualId}`,
      orgId,
      cb: Account => {
        setAccount(Account)
        setHasLoaded(true)
      },
    })
  }, [individualId, orgId])

  return { account, hasLoaded }
}
