import { createContentfulAssetUrl } from 'utils'

export const AMAZON_LOGO = createContentfulAssetUrl({
  url: 'knc1roo454gw/6rUtf92eAJjlUWx8lQQ8nx/4fd2b2ebc81a18ba0873f115a0349c53/amazon-logo.png',
  height: 248,
  width: 248,
})

export const DISCOVER_LOGO = createContentfulAssetUrl({
  url: 'knc1roo454gw/3jaNwoaY9SkYkGk7KO7P07/602ca7e14c05052e842eb8da22d1ccb7/discover-logo.svg',
  height: 248,
  width: 248,
})

export const AMAZON_CARD = createContentfulAssetUrl({
  url: 'knc1roo454gw/1FMx4qfXqsI5R4qt2r6Rs9/7451377925a97ee92aba61f208fbeee2/amazon-card.png',
  height: 1080,
  width: 1920,
})

export const CHARITY_CARD = createContentfulAssetUrl({
  url: 'knc1roo454gw/3v4UjCFnrLVonwbz9TCza9/da56915bfa7adb08fb683f43df136e3b/charity-card.png',
  height: 1498,
  width: 2140,
})

export const CHARITY_LOGO = createContentfulAssetUrl({
  url: 'knc1roo454gw/QVGhjYI7j120i4sNxSJvA/32631bcfdfea82e215ff90c5f9e41445/charity-logo.png',
  height: 248,
  width: 248,
})

export const GIFT_LOGO = createContentfulAssetUrl({
  url: 'knc1roo454gw/5DQIN3iDN2LwA6ohNHKLUW/8d065e4c255e0cc96aee73cfc8db8743/gift-card-logo.png',
  height: 248,
  width: 248,
})

export const SHOP_CARD = createContentfulAssetUrl({
  url: 'knc1roo454gw/YP6xIPKooOraumMnvIEgg/ea4487820a2813d97d8becb2c419ba32/shop-card.png',
  height: 1216,
  width: 2140,
})

export const SWAG_CARD = createContentfulAssetUrl({
  url: 'knc1roo454gw/7f5B5BlLfczpOoEUEwpASt/08f7fb684edaa2d914eb8cd6e5d15c00/swag-card.svg',
  height: 1216,
  width: 1120,
})

export const SWAG_LOGO = createContentfulAssetUrl({
  url: 'knc1roo454gw/ZcZ4qgcfhvXXR526wwe3e/ce2a3e9de854b889ea5f0879b0b41308/swag-logo.png',
  height: 248,
  width: 248,
})

export const FOOD_LOGO = createContentfulAssetUrl({
  url: 'knc1roo454gw/43Z6naCMtkDjT1sXE3UloC/b23ab35907111243f03f7fac809602d2/food-logo.png',
  height: 248,
  width: 248,
})

export const FOOD_CARD = createContentfulAssetUrl({
  url: 'knc1roo454gw/4SUkG1z8j1TEBpRT2RHmMn/e97e66433a26ea25c1198de6753e84c2/food-card.png',
  height: 1216,
  width: 2140,
})

export const GIFT_CARD = createContentfulAssetUrl({
  url: 'knc1roo454gw/3NLQdx7TE5gnMSxoupjU36/b4c1d2978b377d77623cfa35e8fbcc59/plum-card.png',
  height: 2140,
  width: 2140,
})

export const SHOP_LOGO = createContentfulAssetUrl({
  url: 'knc1roo454gw/57SxOVB0Emiedgws9x6S4r/2090fdc334d409c0fe25a98809f9eb0f/shopping-logo.png',
  height: 248,
  width: 248,
})

export const LUGGAGE_ICON = createContentfulAssetUrl({
  url: 'knc1roo454gw/2g8IepT9CyVOrUhvwti05k/a68e42f687962de2ce4eb425fd6eaa94/Luggage.svg',
  height: 248,
  width: 248,
})

export const MAGIC_HAT_GIF = createContentfulAssetUrl({
  url: 'knc1roo454gw/5bhSAC89wXfCQnfCNR0rhb/8ae76af6b4a2cb43125a41cdc7138cc0/LoadingScreen.gif',
  height: 248,
  width: 248,
})

export const EMPTY_CART = createContentfulAssetUrl({
  url: 'knc1roo454gw/3dY2Mf051ZJ3Rq1Ws36XVJ/87f5f44e6a45ec5682a30cda0161449d/Frame.svg',
  height: 248,
  width: 248,
})

export const ATLAS_LOGO = createContentfulAssetUrl({
  url: 'knc1roo454gw/46G4r5bHY8dxgdPHndrdg7/b32b5007004932b46e8f0fdde28a15cc/icon-chat-PerkUp_purple.png',
  height: 248,
  width: 248,
})
