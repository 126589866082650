import { captureException } from '@sentry/react'
import { Button } from 'antd'
import { completeUserOnboarding } from 'api/databaseCalls'
import { callFunction } from 'api/functionCalls'
import { ReactComponent as PieChartSymbol } from 'assets/pieChart.svg'
import { ShortNavigation } from 'components'
import { defaultButtonStyles } from 'constants/antdesign'
import * as EVENTS from 'constants/events'
import * as ROUTES from 'constants/routes'
import { OrgContext, UserContext } from 'context'
import { Heading, Pane, Text, TextInput, toaster } from 'evergreen-ui'
import { useContext, useState } from 'react'
import { isDesktop } from 'react-device-detect'
import { useForm } from 'react-hook-form'
import { Navigate, useNavigate } from 'react-router-dom'
import { logEvent } from 'utils'
import { toSentry } from 'utils/sentry'

export default function CreateOrg() {
  const user = useContext(UserContext)
  const org = useContext(OrgContext)
  const orgId = org?.id
  const userId = user?.id

  const { handleSubmit, register } = useForm<{ name: string }>()
  const nameRegister = register('name')
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false)

  const onSubmit = async (formData: { name: string }) => {
    try {
      setIsLoading(true)

      await callFunction('firestore-createOrganization', {
        ...formData,
        userId: user.id,
      })

      // Track Org creation events
      logEvent(EVENTS.ORG_CREATED, {
        name: formData?.name,
        orgId,
        userId,
      })
      await completeUserOnboarding({ userId: user.id })

      navigate(ROUTES.FIRST_REWARD)
      toaster.success('Successfully created company profile')
      setIsLoading(false)
    } catch (error: any) {
      console.error(error)
      captureException(toSentry(error), {
        contexts: { createOrganization: { user } },
      })

      setIsLoading(false)
      toaster.danger(error?.message)
    }
  }

  if (user.currentOrganization) {
    return <Navigate to={ROUTES.CARD} />
  }

  return (
    <>
      <ShortNavigation showNavName={false} />
      <Pane display="flex" justifyContent="center" height="100vh">
        <Pane
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          width={isDesktop ? '50%' : '100%'}
        >
          <Pane
            display="flex"
            flexDirection="column"
            justifyContent="center"
            paddingX={24}
            paddingY={48}
            margin={24}
            width="fit-content"
            minWidth="320px"
          >
            <Pane width={96} marginBottom={32} marginX="auto">
              <PieChartSymbol style={{ width: '100%', height: 'auto' }} />
            </Pane>

            <Pane textAlign="center" marginBottom={16}>
              <Heading size={800} marginBottom={12}>
                Company account
              </Heading>
              <Text size={600}>Send rewards people love.</Text>
            </Pane>

            <Pane
              is="form"
              marginY={16}
              marginX="auto"
              width="100%"
              onSubmit={handleSubmit(onSubmit)}
            >
              <Pane marginBottom={16}>
                <TextInput
                  name="name"
                  placeholder="Company name"
                  ref={nameRegister.ref}
                  onChange={nameRegister.onChange}
                  required
                  height={40}
                  autoFocus
                  borderRadius={8}
                />
              </Pane>

              <Button
                htmlType="submit"
                type="primary"
                loading={isLoading}
                disabled={!!user.currentOrganization}
                style={{
                  ...defaultButtonStyles,
                  marginBottom: 16,
                  width: '100%',
                }}
              >
                Continue
              </Button>
            </Pane>
          </Pane>
        </Pane>
      </Pane>
    </>
  )
}
