import { useEffect, useState } from 'react'
import { getDesignVariablesByDesignFileId } from 'services'
import { DesignVariable } from 'types'

export function useDesignVariables(designFileId: string) {
  const [hasLoaded, setHasLoaded] = useState(false)
  const [variables, setVariables] = useState<DesignVariable[]>([])

  useEffect(() => {
    getDesignVariablesByDesignFileId(designFileId)
      .then(setVariables)
      .finally(() => {
        setHasLoaded(true)
      })
  }, [designFileId])

  return { variables, hasLoaded }
}
