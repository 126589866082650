import { ListenToFullAccessOrgAccounts } from 'api/databaseCalls'
import { Account } from 'gen/perkup/v1/account_pb'
import { useIndividualRole } from 'hooks'
import useIds from 'hooks/useIds'
import { sortBy } from 'lodash-es'
import { useEffect, useState } from 'react'

export function useFullAccessAccounts() {
  const individualRole = useIndividualRole().role
  const { individualId, orgId } = useIds()

  const [fullAccessAccounts, setFullAccessAccounts] = useState<Account[]>([])

  useEffect(() => {
    if (!orgId || !individualRole || !individualId) return undefined
    return ListenToFullAccessOrgAccounts({
      individualId,
      individualRole,
      orgId,
      cb: accounts => {
        const sortedAccounts = sortBy(accounts, account => -account.balance)
        if (sortedAccounts) setFullAccessAccounts(sortedAccounts)
      },
    })
  }, [orgId, setFullAccessAccounts, individualId, individualRole])

  return { fullAccessAccounts }
}
