// @generated by protoc-gen-es v1.6.0 with parameter "target=ts"
// @generated from file manual/v1/manual.proto (package manual.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";
import { Item } from "../../perkup/v1/program_pb.js";
import { VenueEvent_Info } from "../../perkup/v1/venue_event_pb.js";

/**
 * @generated from message manual.v1.LineItemDiscountDescription
 */
export class LineItemDiscountDescription extends Message<LineItemDiscountDescription> {
  /**
   * discount percentage
   *
   * @generated from field: float percentage = 1;
   */
  percentage = 0;

  /**
   * minimum amount of eligible items to apply the current discount
   *
   * @generated from field: int32 threshold = 2;
   */
  threshold = 0;

  /**
   * explanation of the discount
   *
   * @generated from field: string description = 3;
   */
  description = "";

  /**
   * eligible items count in the whole order
   *
   * @generated from field: int32 count = 4;
   */
  count = 0;

  constructor(data?: PartialMessage<LineItemDiscountDescription>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "manual.v1.LineItemDiscountDescription";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "percentage", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 2, name: "threshold", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): LineItemDiscountDescription {
    return new LineItemDiscountDescription().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): LineItemDiscountDescription {
    return new LineItemDiscountDescription().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): LineItemDiscountDescription {
    return new LineItemDiscountDescription().fromJsonString(jsonString, options);
  }

  static equals(a: LineItemDiscountDescription | PlainMessage<LineItemDiscountDescription> | undefined, b: LineItemDiscountDescription | PlainMessage<LineItemDiscountDescription> | undefined): boolean {
    return proto3.util.equals(LineItemDiscountDescription, a, b);
  }
}

/**
 * @generated from message manual.v1.CreateOrderRequest
 */
export class CreateOrderRequest extends Message<CreateOrderRequest> {
  /**
   * @generated from field: repeated perkup.v1.Item items = 1;
   */
  items: Item[] = [];

  /**
   * @generated from field: manual.v1.CreateOrderRequest.Address address = 2;
   */
  address?: CreateOrderRequest_Address;

  /**
   * optional ordering of programs to allocate into
   *
   * @generated from field: repeated string program_ids = 3;
   */
  programIds: string[] = [];

  /**
   * @generated from field: optional string shipping_method = 4;
   */
  shippingMethod?: string;

  /**
   * if set to true, perkup fee will not be charged
   *
   * @generated from field: optional bool no_perkup_fee = 5;
   */
  noPerkupFee?: boolean;

  /**
   * @generated from field: optional perkup.v1.VenueEvent.Info venue_event_info = 6;
   */
  venueEventInfo?: VenueEvent_Info;

  constructor(data?: PartialMessage<CreateOrderRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "manual.v1.CreateOrderRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "items", kind: "message", T: Item, repeated: true },
    { no: 2, name: "address", kind: "message", T: CreateOrderRequest_Address },
    { no: 3, name: "program_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 4, name: "shipping_method", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 5, name: "no_perkup_fee", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 6, name: "venue_event_info", kind: "message", T: VenueEvent_Info, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateOrderRequest {
    return new CreateOrderRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateOrderRequest {
    return new CreateOrderRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateOrderRequest {
    return new CreateOrderRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CreateOrderRequest | PlainMessage<CreateOrderRequest> | undefined, b: CreateOrderRequest | PlainMessage<CreateOrderRequest> | undefined): boolean {
    return proto3.util.equals(CreateOrderRequest, a, b);
  }
}

/**
 * @generated from message manual.v1.CreateOrderRequest.Address
 */
export class CreateOrderRequest_Address extends Message<CreateOrderRequest_Address> {
  /**
   * @generated from field: string line1 = 1;
   */
  line1 = "";

  /**
   * @generated from field: optional string line2 = 2;
   */
  line2?: string;

  /**
   * @generated from field: optional string city = 3;
   */
  city?: string;

  /**
   * @generated from field: optional string state = 4;
   */
  state?: string;

  /**
   * ISO 3166-1 US,CA,...
   *
   * @generated from field: string country = 5;
   */
  country = "";

  /**
   * @generated from field: string postal_code = 6;
   */
  postalCode = "";

  /**
   * @generated from field: optional string phone_number = 7;
   */
  phoneNumber?: string;

  /**
   * @generated from field: string first_name = 8;
   */
  firstName = "";

  /**
   * @generated from field: string last_name = 9;
   */
  lastName = "";

  /**
   * @generated from field: optional manual.v1.CreateOrderRequest.Address.LocalizationExtensions localization_extensions = 10;
   */
  localizationExtensions?: CreateOrderRequest_Address_LocalizationExtensions;

  constructor(data?: PartialMessage<CreateOrderRequest_Address>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "manual.v1.CreateOrderRequest.Address";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "line1", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "line2", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 3, name: "city", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 4, name: "state", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 5, name: "country", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "postal_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "phone_number", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 8, name: "first_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "last_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "localization_extensions", kind: "message", T: CreateOrderRequest_Address_LocalizationExtensions, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateOrderRequest_Address {
    return new CreateOrderRequest_Address().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateOrderRequest_Address {
    return new CreateOrderRequest_Address().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateOrderRequest_Address {
    return new CreateOrderRequest_Address().fromJsonString(jsonString, options);
  }

  static equals(a: CreateOrderRequest_Address | PlainMessage<CreateOrderRequest_Address> | undefined, b: CreateOrderRequest_Address | PlainMessage<CreateOrderRequest_Address> | undefined): boolean {
    return proto3.util.equals(CreateOrderRequest_Address, a, b);
  }
}

/**
 * @generated from message manual.v1.CreateOrderRequest.Address.LocalizationExtensions
 */
export class CreateOrderRequest_Address_LocalizationExtensions extends Message<CreateOrderRequest_Address_LocalizationExtensions> {
  /**
   * @generated from field: optional string shipping_credential_br = 1;
   */
  shippingCredentialBr?: string;

  /**
   * @generated from field: optional string shipping_credential_cn = 2;
   */
  shippingCredentialCn?: string;

  /**
   * @generated from field: optional string shipping_credential_kr = 3;
   */
  shippingCredentialKr?: string;

  /**
   * @generated from field: optional string tax_credential_br = 4;
   */
  taxCredentialBr?: string;

  /**
   * @generated from field: optional string tax_credential_it = 5;
   */
  taxCredentialIt?: string;

  /**
   * @generated from field: optional string tax_email_it = 6;
   */
  taxEmailIt?: string;

  constructor(data?: PartialMessage<CreateOrderRequest_Address_LocalizationExtensions>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "manual.v1.CreateOrderRequest.Address.LocalizationExtensions";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "shipping_credential_br", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 2, name: "shipping_credential_cn", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 3, name: "shipping_credential_kr", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 4, name: "tax_credential_br", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 5, name: "tax_credential_it", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 6, name: "tax_email_it", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateOrderRequest_Address_LocalizationExtensions {
    return new CreateOrderRequest_Address_LocalizationExtensions().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateOrderRequest_Address_LocalizationExtensions {
    return new CreateOrderRequest_Address_LocalizationExtensions().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateOrderRequest_Address_LocalizationExtensions {
    return new CreateOrderRequest_Address_LocalizationExtensions().fromJsonString(jsonString, options);
  }

  static equals(a: CreateOrderRequest_Address_LocalizationExtensions | PlainMessage<CreateOrderRequest_Address_LocalizationExtensions> | undefined, b: CreateOrderRequest_Address_LocalizationExtensions | PlainMessage<CreateOrderRequest_Address_LocalizationExtensions> | undefined): boolean {
    return proto3.util.equals(CreateOrderRequest_Address_LocalizationExtensions, a, b);
  }
}

/**
 * error message transported in grpc/connect error
 *
 * @generated from message manual.v1.OrderErrorDetail
 */
export class OrderErrorDetail extends Message<OrderErrorDetail> {
  /**
   * @generated from field: string reason = 1;
   */
  reason = "";

  constructor(data?: PartialMessage<OrderErrorDetail>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "manual.v1.OrderErrorDetail";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "reason", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): OrderErrorDetail {
    return new OrderErrorDetail().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): OrderErrorDetail {
    return new OrderErrorDetail().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): OrderErrorDetail {
    return new OrderErrorDetail().fromJsonString(jsonString, options);
  }

  static equals(a: OrderErrorDetail | PlainMessage<OrderErrorDetail> | undefined, b: OrderErrorDetail | PlainMessage<OrderErrorDetail> | undefined): boolean {
    return proto3.util.equals(OrderErrorDetail, a, b);
  }
}

/**
 * @generated from message manual.v1.CreateOrderResponse
 */
export class CreateOrderResponse extends Message<CreateOrderResponse> {
  /**
   * @generated from field: repeated string order_ids = 1;
   */
  orderIds: string[] = [];

  constructor(data?: PartialMessage<CreateOrderResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "manual.v1.CreateOrderResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "order_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateOrderResponse {
    return new CreateOrderResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateOrderResponse {
    return new CreateOrderResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateOrderResponse {
    return new CreateOrderResponse().fromJsonString(jsonString, options);
  }

  static equals(a: CreateOrderResponse | PlainMessage<CreateOrderResponse> | undefined, b: CreateOrderResponse | PlainMessage<CreateOrderResponse> | undefined): boolean {
    return proto3.util.equals(CreateOrderResponse, a, b);
  }
}

