import { Button } from 'antd'
import { callFunction } from 'api/functionCalls'
import { DEFAULT_LOGO, DEFAULT_PROGRAM_BANNER } from 'constants/programs'
import { OrgContext } from 'context'
import { Pane, toaster } from 'evergreen-ui'
import { Program } from 'gen/perkup/v1/program_pb'
import { useDefaultOrgColors } from 'hooks'
import useIds from 'hooks/useIds'
import { useContext, useState } from 'react'

export default function SendEmailButton({
  program,
  individualId,
  email,
}: {
  program: Program
  individualId: string
  email: string
}) {
  const org = useContext(OrgContext)
  const { orgId } = useIds()
  const { defaultColor } = useDefaultOrgColors()
  const [emailSent, setEmailSent] = useState(false)

  const handleResendEmail = async () => {
    const logo = org?.logoUrl || DEFAULT_LOGO
    const banner = program?.email?.banner || DEFAULT_PROGRAM_BANNER
    const programId = program?.id
    const programName = program?.name
    const programNote = program?.note || 'Thank you for everything you do!'
    const fromName = program?.email?.fromName || org.name

    const sendBulkDetails = {
      orgId,
      programId,
      logo,
      banner,
      title: programName,
      programName,
      programNote,
      fromName,
      color: defaultColor,
    }

    await callFunction('mailjet-SendBulk', {
      ...sendBulkDetails,
      peopleIds: [individualId],
    })
      .then(() => {
        setEmailSent(true)
        toaster.success(`Resent email to ${email}`)
      })
      .catch(err => {
        toaster.danger(`Error resending email to ${email}: ${err}`)
      })
  }

  if (emailSent) return <Pane>Email sent!</Pane>

  return (
    <Pane
      onClick={(e: React.MouseEvent<HTMLDivElement>) => e.stopPropagation()}
    >
      <Button onClick={handleResendEmail} size="small">
        Resend email
      </Button>
    </Pane>
  )
}
