import { OrgContext } from 'context'
import { Image, Pane } from 'evergreen-ui'
import { useContext } from 'react'
import { CompanyHeading } from './CompanyHeading'

export function CompanyDisplay({ whiteLogo }: { whiteLogo?: boolean }) {
  const org = useContext(OrgContext)

  return org.logoUrl ? (
    <Pane display="flex" alignItems="center" gap={8} flexWrap="wrap">
      <Image
        alt={org.name}
        src={org.logoUrl}
        style={{
          height: 32,

          filter: whiteLogo ? 'brightness(0) invert(1)' : '',
        }}
      />
      {org.showNameLogo && <CompanyHeading />}
    </Pane>
  ) : (
    <CompanyHeading />
  )
}
