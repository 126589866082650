import {
  ErrorBoundary,
  wrapCreateBrowserRouter as sentryCreateBrowserRouterWrapper,
} from '@sentry/react'
import { isProduction } from 'constants/keys'
import { CODE } from 'constants/params'
import * as ROUTES from 'constants/routes'
import { getAuth } from 'firebase/auth'
import { Layout } from 'layout'
import {
  createBrowserRouter,
  LoaderFunctionArgs,
  replace,
  RouteObject,
} from 'react-router-dom'
import { appProtectedRoutes } from './app-protected-routes'
import { authRoutes } from './auth-routes'

const getIsAuthenticated = ({ params, request }: LoaderFunctionArgs) => {
  if (new URL(request.url).pathname.startsWith(ROUTES.PREVIEW_REWARDS))
    return false

  const isSignInPath = request.url.includes(ROUTES.SIGN_IN)
  const code = params[CODE]

  const SSOworkOS = isSignInPath && code

  return !!getAuth().currentUser || SSOworkOS
}

function AuthRouteLoader(loader: RouteObject['loader']) {
  const loaderFn = async (a: LoaderFunctionArgs) => {
    if (getIsAuthenticated(a)) {
      const params = new URLSearchParams(window.location.search)
      const to = params.get('_from') || ROUTES.HOME
      params.delete('_from')

      if (params.toString()) {
        return replace(`${to}?${params.toString()}`)
      }

      return replace(to)
    }

    if (typeof loader === 'boolean') {
      return null
    }

    return loader?.(a) || null
  }

  return loaderFn
}

function ProtectedRouteLoader(loader: RouteObject['loader']) {
  const loaderFn = async (a: LoaderFunctionArgs) => {
    if (!getIsAuthenticated(a)) {
      const params = new URLSearchParams(window.location.search)
      const from = params.get('_from') || window.location.pathname
      if (![ROUTES.HOME, ROUTES.SIGN_IN, ROUTES.SIGN_UP].includes(from))
        params.set('_from', from)

      if (params.toString()) {
        return replace(`${ROUTES.SIGN_IN}?${params.toString()}`)
      }

      return replace(ROUTES.SIGN_IN)
    }

    if (typeof loader === 'boolean') {
      return null
    }

    return loader?.(a) || null
  }

  return loaderFn
}

export const router = sentryCreateBrowserRouterWrapper(createBrowserRouter)([
  {
    path: '/',
    id: 'root',
    Component: Layout,
    errorElement: isProduction ? <ErrorBoundary /> : undefined,
    loader: (a: LoaderFunctionArgs) => ({
      isAuthenticated: getIsAuthenticated(a),
    }),
    children: [
      ...authRoutes.map(route => ({
        ...route,
        loader: AuthRouteLoader(route.loader),
      })),
      ...appProtectedRoutes.map(route => ({
        ...route,
        loader: ProtectedRouteLoader(route.loader),
      })),
    ],
  },
])
