import { DatePicker, Flex } from 'antd'
import {
  CountIndividualsMissingField,
  ListenToActionsByOrgId,
  ListenToListsByOrgId,
  ListenToRulesByOrgId,
} from 'api/databaseCalls'
import {
  DownloadRewardReportButton,
  PageHeader,
  withOrgSidebar,
} from 'components'
import { ALGOLIA_INDIVIDUALS_INDEX } from 'constants/algolia'
import {
  CALENDAR,
  DEFAULT_ROUTES,
  MISSING,
  NOTIFICATIONS,
  RULES,
  SENT,
} from 'constants/routes'
import {
  ActionsContext,
  IndividualContext,
  OrgContext,
  RulesContext,
} from 'context'
import { Pane, Tab, TabNavigation } from 'evergreen-ui'
import { Individual_Role } from 'gen/perkup/v1/individual_pb'
import { OrgList } from 'gen/perkup/v1/organization_pb'
import { ProgramType } from 'gen/perkup/v1/program_pb'
import { Action, Rule } from 'gen/perkup/v1/rules_pb'
import { intersectionWith } from 'lodash-es'
import capitalize from 'lodash-es/capitalize'
import RewardCalendarFiltered from 'pages/Automations/RewardCalendarFiltered'
import RulesTable from 'pages/Automations/RulesTable'
import NoMatch404 from 'pages/NoMatch404'
import { useContext, useEffect, useMemo, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { Index } from 'react-instantsearch'
import { Link, Route, Routes, useLocation } from 'react-router-dom'
import { individualsSearchClient } from 'services/algolia'
import { RuleGroups } from 'types/Notifications'
import { RangeValue } from 'types/RewardsDate'
import { RuleTypes } from 'types/rules'
import { getRuleType } from 'utils'
import { InstantSearchWrapper } from 'utils/Algolia'
import { MissingIndividualsTab } from './MissingIndividualsTab'
import Notifications from './Notifications'
import SentRewards from './SentRewards'

export interface IPrimaryTabs {
  label: string
  singular: string
  emptyState: {
    label: string
    description: string
  }
  title: string
  value: string
  type: ProgramType
  route: string
  field: 'startDate' | 'dob'
  description: string
  subTabLabel: string
  blogLink: string
  vlogLink: string
  hiddenTabs: string[]
}

const primaryTabs: IPrimaryTabs[] = [
  {
    label: 'Work Anniversaries',
    singular: 'Anniversary',
    emptyState: {
      label: 'Anniversary Rewards',
      description: `Recognize people who have spent another year at your company`,
    },
    title: 'Anniversaries 🎉',
    value: ProgramType[ProgramType.anniversaries],
    type: ProgramType.anniversaries,
    route: DEFAULT_ROUTES.ORGANIZATION.REWARDS.ANNIVERSARIES.ROOT,
    field: 'startDate',
    description: 'Automate rewards based on years of service.',
    subTabLabel: 'anniversaries',
    blogLink:
      'https://help.perkupapp.com/articles/353888-how-to-schedule-work-anniversary-gifts',
    vlogLink:
      'https://www.loom.com/embed/40989e1107794e34ac7460058db57670?sid=fbbfcdf7-c2f1-4010-b062-5cf031f3f025',
    hiddenTabs: [],
  },
  {
    label: 'Birthdays',
    singular: 'Birthday',
    emptyState: {
      label: `Birthday Rewards`,
      description: `Don't miss out on everyone's special day!`,
    },
    value: ProgramType[ProgramType.birthdays],
    type: ProgramType.birthdays,
    title: 'Birthdays 🎂',
    route: DEFAULT_ROUTES.ORGANIZATION.REWARDS.BIRTHDAYS.ROOT,
    field: 'dob',
    description: 'Automate birthday rewards for your team.',
    subTabLabel: 'birthdays',
    blogLink:
      'https://help.perkupapp.com/articles/196410-how-to-schedule-birthday-gifts',
    vlogLink:
      'https://www.loom.com/embed/40989e1107794e34ac7460058db57670?sid=fbbfcdf7-c2f1-4010-b062-5cf031f3f025',
    hiddenTabs: [],
  },
  {
    label: 'New Hires',
    singular: 'New hire',
    emptyState: {
      label: `New Hire Rewards`,
      description: `Automatically send your new employees rewards!`,
    },
    value: ProgramType[ProgramType.anniversaries],
    type: ProgramType.anniversaries,
    title: 'New Hires 🎉',
    route: DEFAULT_ROUTES.ORGANIZATION.REWARDS.NEW_HIRE.ROOT,
    field: 'startDate',
    description: 'Automate new hire swag, through kits or on-demand swag.',
    subTabLabel: 'new hires',
    blogLink: '',
    vlogLink:
      'https://www.loom.com/embed/40989e1107794e34ac7460058db57670?sid=fbbfcdf7-c2f1-4010-b062-5cf031f3f025',
    hiddenTabs: [NOTIFICATIONS, MISSING],
  },
]

interface ISubTab {
  label: string
  key: string
  route: string
  hidden: boolean
}

function RewardsTabWrapper({
  subTabs,
  selectedTab,
}: {
  subTabs: ISubTab[]
  selectedTab: IPrimaryTabs
}) {
  const location = useLocation()

  const selectedSubTab = subTabs.find(tab =>
    location.pathname.includes(tab.route)
  )
  return (
    <>
      <Helmet>
        <title>
          {selectedTab?.singular} {capitalize(selectedSubTab?.label)} — PerkUp
        </title>
      </Helmet>
      <TabNavigation marginY={16}>
        {subTabs.map((subTab: ISubTab) => {
          if (subTab.hidden) return null
          const isSelected = location.pathname.includes(subTab.route)
          return (
            <Tab
              is={Link}
              to={subTab.route.replace('/', '')}
              id={subTab.key}
              key={subTab.key}
              isSelected={isSelected}
              appearance="primary"
            >
              {subTab.label}
            </Tab>
          )
        })}
      </TabNavigation>
    </>
  )
}

function Rewards() {
  const individual = useContext(IndividualContext)
  const org = useContext(OrgContext)
  const orgId = org?.id

  const isAdmin = individual?.role === Individual_Role.admin

  const location = useLocation()
  const [lists, setLists] = useState<OrgList[]>([])
  const [dates, setDates] = useState<RangeValue>(null)
  const [rules, setRules] = useState<Rule[]>([])
  const [actions, setActions] = useState<Action[]>([])

  const selectedTab = primaryTabs.filter(tab =>
    location.pathname.includes(tab.route)
  )[0]

  const [missingIndividualsCount, setMissingIndividualsCount] = useState<
    number | undefined
  >()

  useEffect(() => {
    if (!orgId) return undefined
    return ListenToListsByOrgId({ orgId, cb: setLists })
  }, [orgId])

  useEffect(() => {
    if (!orgId) return
    CountIndividualsMissingField({
      orgId,
      fieldSelector: selectedTab.field,
      cb: setMissingIndividualsCount,
    })
  }, [orgId, selectedTab.field])
  const ruleType = getRuleType({ pathName: window.location.pathname })

  // Listen to all rules
  useEffect(() => {
    const isBirthdayRule = ruleType === RuleTypes.birthdays
    const isNewHire = ruleType === RuleTypes.newHires

    if (!orgId) return undefined
    return ListenToRulesByOrgId({
      orgId,
      ruleGroup: isBirthdayRule
        ? RuleGroups.BIRTHDAY_RULE_GROUP
        : RuleGroups.ANNIVERSARY_RULE_GROUP,
      year: isNewHire ? -1 : undefined,
      cb: setRules,
    })
  }, [ruleType, orgId])

  // Listen to all actions, filter by ruleIds after to avoid unnecessary listeners
  useEffect(() => {
    if (!orgId) return undefined
    return ListenToActionsByOrgId({
      orgId,
      cb: setActions,
    })
  }, [orgId])

  const searchClient = individualsSearchClient(orgId)

  const missingIndividualTasks = missingIndividualsCount
    ? `(${missingIndividualsCount})`
    : ''

  const subTabs = [
    {
      label: 'Calendar',
      key: CALENDAR,
      route: CALENDAR,
      hidden: selectedTab.hiddenTabs?.includes(CALENDAR),
    },
    {
      label: 'Rules ⚡',
      key: RULES,
      route: RULES,
      hidden: selectedTab.hiddenTabs?.includes(RULES),
    },
    {
      label: 'Notifications',
      key: NOTIFICATIONS,
      route: NOTIFICATIONS,
      hidden: selectedTab.hiddenTabs?.includes(NOTIFICATIONS),
    },
    {
      label: `Missing ${selectedTab.subTabLabel?.toLowerCase()} ${missingIndividualTasks}`,
      key: MISSING,
      route: MISSING,
      hidden: selectedTab.hiddenTabs?.includes(MISSING),
    },
    {
      label: `Recently sent`,
      key: SENT,
      route: SENT,
      hidden: selectedTab.hiddenTabs?.includes(SENT),
    },
  ]

  const programType = selectedTab?.type

  const showDatePicker = isAdmin && location.pathname.includes(SENT)

  const actionsMap = useMemo(() => {
    const filterdActions = intersectionWith(
      actions,
      rules,
      (action, rule) => action.ruleId === rule.id
    )
    const actionMap = new Map<string, Action>()
    filterdActions.forEach(action => {
      actionMap.set(action.ruleId, action)
    })
    return actionMap
  }, [actions, rules])

  if (ruleType === RuleTypes.newHires && !isAdmin) {
    return <NoMatch404 />
  }

  return (
    <InstantSearchWrapper
      searchClient={searchClient}
      indexName={ALGOLIA_INDIVIDUALS_INDEX}
    >
      <Index indexName={ALGOLIA_INDIVIDUALS_INDEX}>
        <Pane>
          <PageHeader
            title={selectedTab?.label}
            description={selectedTab?.description}
            blogLink={isAdmin ? selectedTab?.blogLink : ''}
            vlogLink={isAdmin ? selectedTab?.vlogLink : ''}
            buttonsToRenderOnRight={
              <Flex gap={8}>
                {showDatePicker && (
                  <DatePicker.RangePicker
                    format="YYYY/MM/DD"
                    onChange={setDates}
                  />
                )}
                {isAdmin && (
                  <DownloadRewardReportButton
                    dates={dates}
                    programTypes={[programType]}
                  />
                )}
              </Flex>
            }
          />

          {isAdmin && (
            <RewardsTabWrapper subTabs={subTabs} selectedTab={selectedTab} />
          )}
          <RulesContext.Provider value={rules}>
            <ActionsContext.Provider value={actionsMap}>
              <Routes>
                <Route
                  key={RULES}
                  path={`${RULES}/*`}
                  element={<RulesTable ruleType={ruleType} />}
                />

                <Route
                  key={SENT}
                  path={SENT}
                  element={<SentRewards ruleType={ruleType} dates={dates} />}
                />
                <Route
                  key={CALENDAR}
                  path={`${CALENDAR}/*`}
                  element={
                    <RewardCalendarFiltered ruleType={ruleType} lists={lists} />
                  }
                />
                {(ruleType === RuleTypes.birthdays ||
                  ruleType === RuleTypes.anniversaries) && (
                  <>
                    <Route
                      key={MISSING}
                      path={MISSING}
                      element={
                        <MissingIndividualsTab
                          orgId={orgId}
                          selectedTab={selectedTab}
                        />
                      }
                    />
                    <Route
                      key={NOTIFICATIONS}
                      path={NOTIFICATIONS}
                      element={<Notifications />}
                    />
                  </>
                )}
                <Route path="*" element={<NoMatch404 hasFooter={false} />} />
              </Routes>
            </ActionsContext.Provider>
          </RulesContext.Provider>
        </Pane>
      </Index>
    </InstantSearchWrapper>
  )
}

export default withOrgSidebar(Rewards)
