import { Grid } from 'antd'
import { PropsWithChildren } from 'react'

const { useBreakpoint } = Grid

export function ProductCollectionsGrid({ children }: PropsWithChildren) {
  const screens = useBreakpoint()

  const isMediumScreen = screens.md || screens.lg
  const isBigScreen = screens.xl || screens.xxl

  const gridCols1 = 'repeat(1, minmax(0, 1fr))'
  const gridCols2 = 'repeat(2, minmax(0, 1fr))'
  const gridCols3 = 'repeat(3, minmax(0, 1fr))'

  const getGridCols = () => {
    if (isBigScreen) return gridCols3
    if (isMediumScreen) return gridCols2
    return gridCols1
  }

  return (
    <section
      style={{
        display: 'grid',
        width: '100%',
        gridTemplateColumns: getGridCols(),
        gap: isBigScreen ? 24 : 16,
      }}
    >
      {children}
    </section>
  )
}
