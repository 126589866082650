import { PlacidTags } from 'constants/placid/placid-tags'
import { UseCaseRecord } from 'pages/NewReward/types/forms'
import { CategoryTitle } from 'pages/Templates/constants'
import { RewardUseCasesKeysType } from './RewardUseCasesKeys'

export const mealsAndBreaksUseCases = {
  TEAM_LUNCH: {
    label: 'Team lunch',
    details: {
      searchQuery: 'lunch',
      templateCategories: [CategoryTitle.LUNCHES],
      placidTags: [PlacidTags.LUNCH],
    },
    emailData: [
      {
        title: 'Lunch Break!',
        note: "Good food with great team members! Let's enjoy.",
      },
      {
        title: 'Feast Mode 🍴',
        note: "Here's to delicious memories and team bonding over lunch.",
      },
      {
        title: 'Lunch Vibes!',
        note: 'Lunches with the team are always special. Thanks for being there!',
      },
    ],
  },
  COFFEE_BREAK: {
    label: 'Coffee break',
    details: {
      searchQuery: 'coffee break',
      templateCategories: [],
      placidTags: [PlacidTags.COFFEE],
    },
    emailData: [
      {
        title: 'Brew-tiful Break!',
        note: 'Every sip is a recharge. Enjoy your coffee break!',
      },
      {
        title: 'Java Joy ☕',
        note: 'A little break with a coffee cup makes everything better.',
      },
      {
        title: 'Espresso Yourself!',
        note: 'Take a moment, relax, and enjoy your coffee break.',
      },
    ],
  },
  SNACK_BREAK: {
    label: 'Snack break',
    details: {
      searchQuery: 'snack break',
      templateCategories: [],
      placidTags: [PlacidTags.SNACKS],
    },
    emailData: [
      {
        title: 'Snack & Relax!',
        note: 'A little treat to keep the energy high. Enjoy the snack break!',
      },
      {
        title: 'Crunch Time 🍪',
        note: "Take a delicious break, you've earned it!",
      },
      { title: "Treat O'Clock!", note: "It's snack time! Refuel and refresh." },
    ],
  },
  TEAM_DINNER: {
    label: 'Team dinner',
    details: {
      searchQuery: 'dinner',
      templateCategories: [],
      placidTags: [],
    },
    emailData: [
      {
        title: 'Dinner Delights!',
        note: "Here's to good food and even better company. Cheers to the team dinner!",
      },
      {
        title: 'Feast & Fun 🍲',
        note: 'Celebrating our successes with a delightful team dinner.',
      },
      {
        title: 'Dine & Shine!',
        note: 'A meal shared among colleagues makes for the best memories. Enjoy the team dinner!',
      },
    ],
  },
  DONUT_DAY: {
    label: 'Donut Day',
    details: {
      searchQuery: 'donut day',
      templateCategories: [],
      placidTags: [],
    },
    emailData: [
      {
        title: 'Donut Worry!',
        note: 'Have a sweet treat on us! Happy Donut Day!',
      },
      {
        title: 'Sweet Surprises 🍩',
        note: "Life's sweeter with donuts. Enjoy!",
      },
      {
        title: 'Dough-lightful Day!',
        note: 'A little sugar rush to brighten your day. Happy Donut Day!',
      },
    ],
  },
  ICE_CREAM_PARTY: {
    label: 'Ice cream party',
    details: {
      searchQuery: 'ice cream party',
      templateCategories: [],
      placidTags: [],
    },
    emailData: [
      {
        title: 'Scoop of Joy!',
        note: "Here's to a creamy, dreamy ice cream party!",
      },
      {
        title: 'Chill & Thrill 🍦',
        note: 'Beat the heat with a sweet treat. Enjoy the ice cream party!',
      },
      {
        title: 'Ice Cream Cheers!',
        note: "For moments of melt and delight, let's have an ice cream party!",
      },
    ],
  },
  WELCOME_LUNCH: {
    label: 'Welcome lunch',
    details: {
      searchQuery: 'lunch time',
      templateCategories: [],
      placidTags: [PlacidTags.NEW_EMPLOYEE, PlacidTags.LUNCH],
    },
    emailData: [
      {
        title: 'Lunch on Us!',
        note: "A warm welcome to the team. Let's lunch and get to know each other better!",
      },
      {
        title: 'First of Many 🍽️',
        note: 'This is just the beginning. Welcome to the family!',
      },
      {
        title: 'Bon Appétit!',
        note: "What's better than good food and great company? Welcome aboard!",
      },
    ],
  },
} satisfies Partial<Record<RewardUseCasesKeysType, UseCaseRecord>>
