import { captureException, captureMessage } from '@sentry/react'
import { Tag } from 'antd'
import { callFunction } from 'api/functionCalls'
import * as ROUTES from 'constants/routes'
import { OrgContext, OrgUserContext } from 'context'
import { Pane } from 'evergreen-ui'
import { useSpendingOptions } from 'hooks/useSpendingOptions'
import { capitalize } from 'lodash-es'
import { useContext, useEffect, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { useNavigate } from 'react-router'
import Stripe from 'stripe'
import { getCardBadgeStatusColor } from 'utils'
import { ActivateCard } from './ActivateCard'
import { CardSidesheet } from './CardSidesheet'
import VirtualCard from './VirtualCard'

function CardUI({ card }: { card?: Stripe.Issuing.Card }) {
  const status = card?.status || 'inactive'
  const org = useContext(OrgContext)
  const showPerkCard = !org.settings?.hidePerkCard

  if (!showPerkCard) return null

  return (
    <>
      <ActivateCard card={card} />
      <VirtualCard {...card} />
      {/* Card Status */}
      <Tag
        color={getCardBadgeStatusColor(status)}
        style={{
          margin: 'auto',
          marginTop: 16,
        }}
      >
        {capitalize(status)}
      </Tag>
    </>
  )
}

export default function PerkCard({
  children,
  addPadding = false,
  showSidesheet,
  setShowSidesheet,
}: {
  children?: React.ReactNode
  addPadding?: boolean
  showSidesheet: boolean
  setShowSidesheet: (show: boolean) => void
}) {
  const navigate = useNavigate()
  const orgUser = useContext(OrgUserContext)

  const [card, setCard] = useState<Stripe.Issuing.Card>()

  // Get Card
  useEffect(() => {
    if (!orgUser?.cardId) return undefined
    callFunction('stripe-RetrieveCard', {})
      .then(card => {
        if (card?.id) {
          setCard(card)
        } else {
          captureException(card)
        }
      })
      .catch(error => {
        captureMessage(error)
      })
    return undefined
  }, [orgUser?.cardId])

  const { showPerkCard } = useSpendingOptions()

  const hasDesktopPadding = !isMobile && addPadding

  if (showPerkCard) {
    return (
      <Pane
        display="flex"
        justifyContent="space-between"
        flexDirection={isMobile ? 'column-reverse' : 'row'}
        onClick={() => {
          if (isMobile) {
            navigate(ROUTES.CARD_DETAILS)
          } else {
            setShowSidesheet(true)
          }
        }}
        cursor="pointer"
        paddingX={!hasDesktopPadding ? undefined : 92}
        paddingBottom={addPadding ? 40 : 0}
        paddingTop={!hasDesktopPadding ? undefined : 40}
        gap={16}
      >
        {children}
        <Pane
          display="flex"
          flexDirection="column"
          alignItems="center"
          marginTop={isMobile ? 24 : 0}
          marginX={isMobile ? 'auto' : 0}
        >
          <CardUI card={card} />
          <CardSidesheet
            isShown={showSidesheet}
            onCloseComplete={() => setShowSidesheet(false)}
          />
        </Pane>
      </Pane>
    )
  }

  return null
}

export * from './ActivatedPerkCard'
export * from './CardSidesheet'
export * from './PerkCardPreview'
