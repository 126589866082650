import { EnvironmentOutlined } from '@ant-design/icons'
import { Modal } from 'antd'
import { Pane, Text } from 'evergreen-ui'
import { ShippingAddress } from 'gen/perkup/v1/root_user_pb'
import words from 'lodash-es/words'
import { useState } from 'react'
import { AddressesList } from './addresses-list'

export function AddressShortDisplay({
  isMuted,
  shippingAddress,
  shippingAddresses,
  onShippingAddressChange,
}: {
  isMuted?: boolean
  shippingAddress: ShippingAddress
  shippingAddresses: ShippingAddress[]
  onShippingAddressChange: (address?: ShippingAddress) => void
}) {
  const [showAddressChangeModal, setShowAddressChangeModal] = useState(false)

  return (
    <>
      <Pane cursor="pointer" onClick={() => setShowAddressChangeModal(true)}>
        <Text color={isMuted ? 'muted' : 'default'}>
          <EnvironmentOutlined /> Deliver to {words(shippingAddress?.name)[0]},{' '}
          {shippingAddress?.state} {shippingAddress?.postalCode}
        </Text>
      </Pane>
      <Modal
        open={showAddressChangeModal}
        onClose={() => setShowAddressChangeModal(false)}
        title="Choose your address"
        footer={null}
        onCancel={() => setShowAddressChangeModal(false)}
        styles={{
          body: {
            height: 600,
            overflowY: 'auto',
          },
        }}
      >
        <AddressesList
          onSelectAddress={address => {
            onShippingAddressChange(address)
            setShowAddressChangeModal(false)
          }}
          selectedAddress={shippingAddress}
          shippingAddresses={shippingAddresses}
        />
      </Modal>
    </>
  )
}
