// Connect - Sentry
// https://connect.build/docs/web/using-clients#managing-clients-and-transports
// https://develop.sentry.dev/sdk/event-payloads/span/
import { ServiceType } from '@bufbuild/protobuf'
import {
  Code,
  ConnectError,
  createPromiseClient,
  Interceptor,
  PromiseClient,
} from '@connectrpc/connect'
import { createConnectTransport } from '@connectrpc/connect-web'
import { getCurrentHub, SpanStatusType } from '@sentry/react'
import { useMemo } from 'react'
import { getCloudRunHost } from 'utils'

const connectToSentryStatus: Record<Code, SpanStatusType> = {
  [Code.Canceled]: 'cancelled',
  [Code.Unknown]: 'unknown_error',
  [Code.InvalidArgument]: 'invalid_argument',
  [Code.DeadlineExceeded]: 'deadline_exceeded',
  [Code.NotFound]: 'not_found',
  [Code.AlreadyExists]: 'already_exists',
  [Code.PermissionDenied]: 'permission_denied',
  [Code.ResourceExhausted]: 'resource_exhausted',
  [Code.FailedPrecondition]: 'failed_precondition',
  [Code.Aborted]: 'aborted',
  [Code.OutOfRange]: 'out_of_range',
  [Code.Unimplemented]: 'unimplemented',
  [Code.Internal]: 'internal_error',
  [Code.Unavailable]: 'unavailable',
  [Code.DataLoss]: 'data_loss',
  [Code.Unauthenticated]: 'unauthenticated',
}

const traceparentInterceptor: Interceptor = next => async req => {
  const transaction = getCurrentHub()?.getScope()?.getTransaction()
  if (transaction) {
    const span = transaction.startChild({
      op: `${req.service.typeName}/${req.method.name}`,
      // description: req.,
    })
    const traceparent = span.toTraceparent()
    if (traceparent) {
      const parts = traceparent.split('-')
      req.header.set('traceparent', `00-${parts[0]}-${parts[1]}-0${parts[2]}`)
      req.header.set(
        'X-Cloud-Trace-Context',
        `${parts[0]}/${parts[1]};o=${parts[2]}`
      )
    }
    try {
      const res = await next(req)
      span.setStatus('ok')
      return res
    } catch (err) {
      if (err instanceof ConnectError) {
        span.setStatus(connectToSentryStatus[err.code])
        span.setData('error_details', err.details)
        span.setData('error_name', err.name)
        span.setData('error_message', err.message)
        err.message = `${req.service.typeName}/${req.method.name} ${err.message}`
      } else {
        span.setStatus('unknown_error')
      }
      throw err
    } finally {
      span.finish()
    }
  } else {
    try {
      const res = await next(req)
      return res
    } catch (err) {
      if (err instanceof ConnectError) {
        err.message = `${req.service.typeName}/${req.method.name} ${err.message}`
      }
      throw err
    }
  }
}

// This transport is going to be used throughout the app
const transport = createConnectTransport({
  baseUrl: getCloudRunHost(),
  interceptors: [traceparentInterceptor],
})

/**
 * Get a promise client for the given service.
 */
export function createClient<T extends ServiceType>(
  service: T
): PromiseClient<T> {
  return createPromiseClient(service, transport)
}
/**
 * Get a promise client for the given service.
 */
export function useClient<T extends ServiceType>(service: T): PromiseClient<T> {
  // We memoize the client, so that we only create one instance per service.
  return useMemo(() => createPromiseClient(service, transport), [service])
}
