import Skeleton from 'antd/es/skeleton'
import Space from 'antd/es/space'
import { CreditCardIcon, Link, Pane, Strong, Text } from 'evergreen-ui'
import capitalize from 'lodash-es/capitalize'
import useBillingUrl from '../hooks/useBillingUrl'
import useDefaultPaymentMethod from '../hooks/useDefaultPaymentMethod'

export default function BillingStatus() {
  const { defaultPaymentMethod, isLoading } = useDefaultPaymentMethod()
  const { billingUrl, isLoading: isUrlLoading } = useBillingUrl()

  if (isLoading) {
    return (
      <Space>
        <Skeleton.Avatar active size="large" />
        <Skeleton.Input active size="large" />
      </Space>
    )
  }

  const { card, type } = defaultPaymentMethod || {}

  const paymentMethodDetails = `${capitalize(card?.brand)} ${
    card?.funding
  } ${type} ending in ${card?.last4}`

  const defaultPaymentMethodTitle = defaultPaymentMethod
    ? 'Default payment method'
    : 'No default payment method'

  return (
    <Pane>
      <Pane
        display="flex"
        alignItems="center"
        border
        paddingX={24}
        paddingY={16}
        borderRadius={8}
        background="tint1"
        marginBottom={16}
      >
        <CreditCardIcon size={24} color="muted" marginRight={16} />

        <Pane display="flex" flexDirection="column">
          <Strong>{defaultPaymentMethodTitle}</Strong>
          {defaultPaymentMethod && (
            <Text color="muted">{paymentMethodDetails}</Text>
          )}
        </Pane>
      </Pane>

      {!isUrlLoading && <Link href={billingUrl}>Update payment method</Link>}
    </Pane>
  )
}
