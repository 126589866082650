import { OrgContext } from 'context'
import { Heading } from 'evergreen-ui'
import { useContext } from 'react'

export function CompanyHeading() {
  const org = useContext(OrgContext)

  return (
    <Heading size={700} color="inherit">
      {org.name}
    </Heading>
  )
}
