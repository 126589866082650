import { IndividualContext, OrgContext } from 'context'
import { Account } from 'gen/perkup/v1/account_pb'
import { useContext, useEffect, useState } from 'react'
import {
  createDefaultCompanyAccount,
  getIndividualsDefaultCompanyAccount,
} from 'services/accounts'

export function useDefaultCompanyAccount() {
  const individual = useContext(IndividualContext)
  const { id: orgId } = useContext(OrgContext)
  const [hasLoaded, setHasLoaded] = useState(false)
  const [account, setAccount] = useState<Account>()

  useEffect(() => {
    // Search for existing company account
    getIndividualsDefaultCompanyAccount({ individual, orgId })
      .then(account => {
        if (account) {
          setAccount(account)
        }
        // Create default company account if it doesn't exist
        else {
          createDefaultCompanyAccount({ individual, orgId }).then(account => {
            setAccount(account)
          })
        }
      })
      .finally(() => setHasLoaded(true))
  }, [individual, orgId])

  return { account, hasLoaded }
}
