import { Tag } from 'antd'
import { capitalize } from 'lodash-es'

export function TransactionStatus({
  type,
  approved,
  status,
}: {
  type: string
  approved: boolean
  status: string
}) {
  if (type === 'refund') {
    return <Tag color="orange">Refunded</Tag>
  }

  if (status === 'pending') {
    return (
      <>
        {approved ? (
          <Tag color="green">Approved</Tag>
        ) : (
          <Tag color="neutral">Declined</Tag>
        )}
        <Tag color="blue">Pending</Tag>
      </>
    )
  }

  if (type === 'capture') {
    return <Tag color="green">Approved</Tag>
  }

  if (status === 'closed') {
    return approved ? (
      <Tag color="green">Approved</Tag>
    ) : (
      <Tag color="default">Declined</Tag>
    )
  }

  if (status === 'reversed') {
    return <Tag color="orange">Reversed</Tag>
  }

  return <Tag color="default">{capitalize(status)}</Tag>
}
