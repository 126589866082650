import { Heading, Pane, Text } from 'evergreen-ui'
import useCustomer from 'hooks/useCustomer'

export default function BillingEmail() {
  const { customer } = useCustomer()

  return (
    <Pane>
      <Pane display="flex" marginBottom={16} gap={4}>
        <Heading size={600}>Billing email</Heading>
        <Text color="muted">- Invoices are sent to this email address</Text>
      </Pane>
      <Text>{customer?.email}</Text>
    </Pane>
  )
}
