import { CameraOutlined, DeleteOutlined } from '@ant-design/icons'
import { Alert, Button, Form, Input } from 'antd'
import { useForm } from 'antd/es/form/Form'
import Upload, { UploadChangeParam, UploadFile } from 'antd/es/upload'
import {
  removeUserPfp,
  updateUserProfile,
  uploadUserPfp,
} from 'api/databaseCalls'
import { IndividualContext, UserContext } from 'context'
import { Avatar, Pane, PaneProps, Text, toaster } from 'evergreen-ui'
import { validateFullName } from 'pages/NewReward/utils/uiUtils'
import { useContext, useState } from 'react'
import { getUTCIndividualDate } from 'utils'
import { shortName } from 'utils/individual'

export function ProfileSettings() {
  const user = useContext(UserContext)
  const individual = useContext(IndividualContext)
  const userId = user.id
  const [form] = useForm()
  const [disableFormSubmit, setDisableFormSubmit] = useState(true)
  const [isLoading, setIsLoading] = useState(false)

  const FIRST_NAME = 'firstName'
  const LAST_NAME = 'lastName'
  const onSubmit = (data: { firstName: string; lastName: string }) => {
    const { firstName, lastName } = data
    const validFullName = validateFullName({
      firstName,
      lastName,
    })

    if (validFullName) {
      updateUserProfile({ userId: user.id, data })
      toaster.success('Successfully updated name')
    }
  }

  const handlePfpUpload = (upload: UploadChangeParam<UploadFile<any>>) => {
    if (upload.file.percent === 100) {
      setIsLoading(true)
      uploadUserPfp({ userId, upload })
        .then(() =>
          toaster.success('Successfully updated profile picture', {
            id: upload.file.fileName,
          })
        )
        .catch(() =>
          toaster.danger('Failed to upload image. Please contact support.', {
            id: upload.file.fileName,
          })
        )
        .finally(() => setIsLoading(false))
    }
  }

  const handleRemovePfp = () => {
    setIsLoading(true)
    removeUserPfp({ userId })
      .then(() => toaster.success('Successfully removed profile picture'))
      .catch(() =>
        toaster.danger('Failed to remove image. Please contact support.')
      )
      .finally(() => setIsLoading(false))
  }

  const buttonStyleProps = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }
  const handleValuesChange = () => {
    const firstName = form.getFieldValue(FIRST_NAME)
    const lastName = form.getFieldValue(LAST_NAME)
    if (
      firstName === user.profile?.firstName &&
      lastName === user.profile?.lastName
    ) {
      setDisableFormSubmit(true)
    } else {
      setDisableFormSubmit(false)
    }
  }

  const infoWrapperProps: PaneProps = {
    display: 'flex',
    flexDirection: 'column',
    gap: 4,
  }

  if (!user.profile) return null

  const hasShortName = shortName(user)

  const { profilePicture, email } = user.profile
  return (
    <Pane>
      <Pane
        maxWidth={528}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        marginBottom={24}
        gap={24}
      >
        <Pane display="flex" gap={16} alignItems="center">
          <Avatar
            src={profilePicture}
            name={`${user.profile?.firstName} ${user.profile?.lastName}`}
            size={100}
          />
          <Pane display="flex" gap={16} alignItems="center" flexWrap="wrap">
            <Upload
              onChange={handlePfpUpload}
              maxCount={1}
              showUploadList={false}
              accept="image/*"
            >
              <Button
                style={buttonStyleProps}
                loading={isLoading}
                icon={<CameraOutlined />}
                type="text"
              >
                {profilePicture ? 'Change' : 'Upload'} image
              </Button>
            </Upload>
            {profilePicture && (
              <Button
                onClick={handleRemovePfp}
                style={buttonStyleProps}
                danger
                icon={<DeleteOutlined />}
                type="text"
              >
                Remove
              </Button>
            )}
          </Pane>
        </Pane>
        <Form
          form={form}
          initialValues={{
            firstName: user.profile.firstName,
            lastName: user.profile.lastName,
          }}
          onFinish={onSubmit}
          onValuesChange={handleValuesChange}
        >
          <Pane display="flex" gap={24}>
            <Pane>
              <Text>First name</Text>
              <Form.Item shouldUpdate name={FIRST_NAME}>
                <Input />
              </Form.Item>
            </Pane>
            <Pane>
              <Text>Last name</Text>
              <Form.Item shouldUpdate name={LAST_NAME}>
                <Input />
              </Form.Item>
            </Pane>
          </Pane>
          <Button disabled={disableFormSubmit} type="primary" htmlType="submit">
            Save
          </Button>
          {hasShortName && (
            <Alert
              type="warning"
              message="First and last name must have at least two characters"
              style={{ marginTop: 16 }}
              showIcon
            />
          )}
        </Form>
      </Pane>

      <Pane display="flex" flexDirection="column" gap={16}>
        <Pane {...infoWrapperProps}>
          <Text fontWeight={600}>Email</Text>
          <Text>{email}</Text>
        </Pane>
        {individual.startDate && (
          <Pane {...infoWrapperProps}>
            <Text fontWeight={600}>Start date</Text>
            <Text>
              {getUTCIndividualDate(
                individual.startDate.toDate()
              )?.toLocaleDateString(undefined, {
                month: 'long',
                day: '2-digit',
                year: 'numeric',
              })}
            </Text>
          </Pane>
        )}
        {individual.dob && (
          <Pane {...infoWrapperProps}>
            <Text fontWeight={600}>Birthday</Text>
            <Text>
              {getUTCIndividualDate(
                individual.dob.toDate()
              )?.toLocaleDateString(undefined, {
                month: 'long',
                day: '2-digit',
              })}
            </Text>
          </Pane>
        )}
      </Pane>
    </Pane>
  )
}
