import { Select } from 'evergreen-ui'
import { AmazonOffer } from 'types/Amazon'

export function QuantitySelection(props: {
  offer: AmazonOffer
  quantity: number
  setQuantity: React.Dispatch<React.SetStateAction<number>>
}) {
  const { offer, quantity, setQuantity } = props

  const { minQuantity = 1, maxQuantity = 1 } = offer.quantityLimits

  return (
    <Select
      value={quantity}
      onChange={event => setQuantity(parseInt(event.target.value, 10))}
      paddingY={4}
    >
      {Array.from(Array(maxQuantity - minQuantity + 1), (_, i) => {
        const quantity = i + minQuantity
        return (
          <option key={quantity} value={quantity}>
            {quantity}
          </option>
        )
      })}
    </Select>
  )
}
