import { updateIndividualPrivacySettings } from 'api/databaseCalls'
import ToggleWithTitleAndSubtitle from 'components/ToggleWithTitleAndSubtitle'
import { IndividualContext, OrgContext } from 'context'
import { Heading, Pane, Text } from 'evergreen-ui'
import { Individual_PrivacySettings } from 'gen/perkup/v1/individual_pb'
import { NotificationSetting_RuleGroup } from 'gen/perkup/v1/notification_pb'
import { capitalize } from 'lodash-es'
import { useContext } from 'react'
import { getIndividualPrivacySettings } from 'utils/individual'

export function NotificationSettings({
  notificationSettingRuleGroup,
}: {
  notificationSettingRuleGroup: NotificationSetting_RuleGroup
}) {
  const individual = useContext(IndividualContext)
  const org = useContext(OrgContext)
  const individualId = individual.id
  const orgId = org.id

  const handleHideFromAdmins = (value: boolean) => {
    const privacySettings = new Individual_PrivacySettings({
      allowAdmins: !value,
      allowManagers: !value,
      allowMembers: !value,
    })
    updateIndividualPrivacySettings({
      orgId,
      individualId,
      privacySettings,
      notificationSettingRuleGroup,
    })
  }

  const handleHideFromManagers = (value: boolean) => {
    const privacySettings = new Individual_PrivacySettings({
      allowAdmins: true,
      allowManagers: !value,
      allowMembers: false,
    })
    updateIndividualPrivacySettings({
      orgId,
      individualId,
      privacySettings,
      notificationSettingRuleGroup,
    })
  }

  const handleHideFromMembers = (value: boolean) => {
    const privacySettings = new Individual_PrivacySettings({
      allowAdmins: true,
      allowManagers: true,
      allowMembers: !value,
    })
    updateIndividualPrivacySettings({
      orgId,
      individualId,
      privacySettings,
      notificationSettingRuleGroup,
    })
  }

  const privacySettingType =
    NotificationSetting_RuleGroup[notificationSettingRuleGroup]

  const privacySettings = getIndividualPrivacySettings({
    individual,
    ruleGroup: notificationSettingRuleGroup,
  })

  const denyAdmins = !privacySettings.allowAdmins
  const denyManagers = !privacySettings.allowManagers
  const denyMembers = !privacySettings.allowMembers

  return (
    <Pane display="flex" flexDirection="column" gap={24}>
      <Pane display="flex" flexDirection="column" gap={8}>
        <Heading size={600}>
          {capitalize(
            NotificationSetting_RuleGroup[notificationSettingRuleGroup]
          )}
        </Heading>
        <Text>
          Configure notification settings for your {privacySettingType}.
        </Text>
      </Pane>
      <ToggleWithTitleAndSubtitle
        onChange={handleHideFromAdmins}
        title={`Keep ${privacySettingType} private`}
        subtitle={`Your ${privacySettingType} will be kept private and will not be celebrated in any
        capacity`}
        checked={denyAdmins}
      />

      <Pane marginLeft={32}>
        <Pane marginBottom={16}>
          <ToggleWithTitleAndSubtitle
            onChange={handleHideFromManagers}
            checked={denyManagers}
            title={`Hide ${privacySettingType} from managers`}
            subtitle={`PerkUp will hide your ${privacySettingType} from your manager’s ${privacySettingType} calendar.`}
          />
        </Pane>
        <ToggleWithTitleAndSubtitle
          onChange={handleHideFromMembers}
          checked={denyMembers}
          title={`Hide ${privacySettingType} from public Slack channels`}
          subtitle={`A public Slack announcement will not be sent for your ${privacySettingType}.`}
        />
      </Pane>
    </Pane>
  )
}
