import { CustomCheckoutProvider } from '@stripe/react-stripe-js'
import { Alert, Flex } from 'antd'
import { callFunction } from 'api/functionCalls'
import { CustomCheckoutForm, PerkEmpty, PerkLoader } from 'components'
import { OrgContext } from 'context'
import { Organization_SubscriptionStatus } from 'gen/perkup/v1/organization_pb'
import { useStripe } from 'hooks/Stripe/useStripe'
import { isString } from 'lodash-es'
import { useContext, useEffect, useState } from 'react'
import Stripe from 'stripe'

/**
 *
 * Used to create a checkout session for line items. This allows the spender to visually see what they are paying for on the invoice. This does not involve funding an account directly - it creates adjustments.
 */
export function LineItemsPayment({
  amount,
  onComplete,
  disabled = false,
  lineItems,
  metadata,
}: {
  amount: number
  onComplete: (sessionId?: string) => void
  disabled?: boolean
  lineItems: Stripe.Checkout.SessionCreateParams.LineItem[]
  metadata: Stripe.MetadataParam
}) {
  const { subscriptionStatus, customerId } = useContext(OrgContext)
  const isActiveOrg =
    subscriptionStatus === Organization_SubscriptionStatus.active
  const [clientSecret, setClientSecret] = useState<string>()
  const [isLoadingClientSecret, setIsLoadingClientSecret] = useState(false)
  const { stripe, isLoading: isLoadingStripe } = useStripe()

  useEffect(() => {
    if (!customerId || !isActiveOrg) {
      setIsLoadingClientSecret(false)
      return
    }

    setIsLoadingClientSecret(true)
    callFunction('stripe-CreateLineItemsCheckoutSession', {
      budget: amount,
      customer: customerId,
      idempotencyKey: `checkout_${crypto.randomUUID()}`,
      metadata,
      lineItems,
    })
      .then(res => {
        if (!res) return
        if (isString(res)) setClientSecret(res)
      })
      .finally(() => setIsLoadingClientSecret(false))
  }, [amount, customerId, isActiveOrg, lineItems, metadata])

  if (isLoadingClientSecret || isLoadingStripe) {
    return (
      <Flex vertical style={{ height: '100%', margin: -48 }} justify="center">
        <PerkLoader />
      </Flex>
    )
  }

  if (!clientSecret || !stripe)
    return (
      <PerkEmpty header="Looks like we made a mistake, please try again or contact suppport" />
    )

  const getDisabledAlertText = () => {
    if (!isActiveOrg) {
      return 'Please upgrade your organization subscription to active to add personal funds'
    }

    return undefined
  }

  const disabledAlertText = getDisabledAlertText()

  if (disabledAlertText) {
    return <Alert message={disabledAlertText} showIcon type="warning" />
  }

  return (
    <CustomCheckoutProvider stripe={stripe} options={{ clientSecret }}>
      <CustomCheckoutForm
        amount={amount}
        onComplete={onComplete}
        disabled={disabled}
      />
    </CustomCheckoutProvider>
  )
}
