import { ReloadOutlined } from '@ant-design/icons'
import { Flex, Input } from 'antd'
import { EmojiPickerButton, PerkIconTooltipButton } from 'components'
import { THANKS_MESSAGE_TAB } from 'constants/events'
import { useTheme } from 'evergreen-ui'
import useIds from 'hooks/useIds'
import { isEmpty, isFunction } from 'lodash-es'
import { useState } from 'react'
import { logEvent } from 'utils'

export function EmojiTextInput({
  value,
  onChange,
  asParagraph = false,
  disabled = false,
  hideEmojiPicker = false,
  maxLength,
  autoFocus,
  placeholder,
  rows = 4,
  eventType = 'onBlur',
  handleSubmitCTA,
  onRefresh,
}: {
  value: string
  onChange: (newValue: string) => void
  asParagraph?: boolean
  hideEmojiPicker?: boolean
  disabled?: boolean
  maxLength?: number
  autoFocus?: boolean
  placeholder?: string
  rows?: number
  eventType?: 'onBlur' | 'onChange'
  handleSubmitCTA?: () => void
  onRefresh?: () => void
}) {
  const theme = useTheme()
  const { orgId, userId } = useIds()
  const [textIndexForInsertion, setTextIndexForInsertion] = useState<
    number | null
  >(value.length)
  const [inputValue, setInputValue] = useState<string>(value)

  // We need UI state or else the emoji's will not render in the input
  const handleUpdateTextAndUiState = (newValue: string) => {
    onChange(newValue)
    setInputValue(newValue)
  }

  const handleSaveCursorIndex = (
    e: React.MouseEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    if (e.currentTarget) {
      setTextIndexForInsertion(e.currentTarget.selectionStart)
    }
  }

  const handleSelectEmoji = (newEmojiString: string) => {
    const messageWithEmoji = textIndexForInsertion
      ? value.substring(0, textIndexForInsertion) +
        newEmojiString +
        value.substring(textIndexForInsertion, value.length)
      : value + newEmojiString

    handleUpdateTextAndUiState(messageWithEmoji)
    // Setting textIndexForInsertion by +=2 because  emoji counts as two characters - if you augment by 1 it breaks the emojis in half
    if (textIndexForInsertion) {
      setTextIndexForInsertion(textIndexForInsertion + 2)
    }
  }

  const handleTextInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleUpdateTextAndUiState(e.currentTarget.value)
    if (e.currentTarget.selectionStart) {
      setTextIndexForInsertion(e.currentTarget.selectionStart)
    }
  }

  const handleTextAreaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    handleUpdateTextAndUiState(e.currentTarget.value)
    if (e.currentTarget.selectionStart) {
      setTextIndexForInsertion(e.currentTarget.selectionStart)
    }
  }

  const handleKeyUp = (
    e: React.KeyboardEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    if (e.currentTarget) {
      setTextIndexForInsertion(e.currentTarget.selectionStart)
    }
  }

  const handleKeyDown = (
    e: React.KeyboardEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    if (e.key === 'Tab' && isEmpty(value)) {
      e.preventDefault()

      if (!placeholder) return
      logEvent(THANKS_MESSAGE_TAB, {
        orgId,
        userId,
      })
      handleUpdateTextAndUiState(placeholder)
    }
    if (e.key === 'Enter' && !isEmpty(value)) {
      if (isFunction(handleSubmitCTA)) {
        e.preventDefault()
        handleSubmitCTA()
      }
    }
  }

  return (
    <Flex align="center" gap={4}>
      {asParagraph ? (
        <Input.TextArea
          required
          autoSize={{ minRows: rows }}
          value={inputValue}
          onClick={handleSaveCursorIndex}
          disabled={disabled}
          onKeyUp={handleKeyUp}
          onKeyDown={handleKeyDown}
          maxLength={maxLength}
          autoFocus={autoFocus}
          placeholder={placeholder}
          onChange={e => setInputValue(e.target.value)}
          {...{ [eventType]: handleTextAreaChange }}
        />
      ) : (
        <Input
          disabled={disabled}
          width="100%"
          required
          value={inputValue}
          onClick={handleSaveCursorIndex}
          onKeyUp={handleKeyUp}
          onKeyDown={handleKeyDown}
          maxLength={maxLength}
          autoFocus={autoFocus}
          placeholder={placeholder}
          onChange={e => setInputValue(e.target.value)}
          {...{ [eventType]: handleTextInputChange }}
        />
      )}
      <Flex gap={4}>
        {!hideEmojiPicker && (
          <EmojiPickerButton
            disabled={disabled}
            onEmojiSelect={handleSelectEmoji}
          />
        )}
        {!!onRefresh && (
          <PerkIconTooltipButton
            title="Generate new message"
            disabled={disabled}
            type="text"
            icon={<ReloadOutlined style={{ color: theme.colors.gray500 }} />}
            onClick={onRefresh}
          />
        )}
      </Flex>
    </Flex>
  )
}
