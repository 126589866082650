import { Flex, Tag } from 'antd'
import { Member } from 'gen/perkup/v1/program_pb'

interface GiftStatusTagType {
  color: 'blue' | 'default'
  label: 'Redeemed' | 'Unredeemed' | 'Swapped'
}
export function GiftStatusTag({ member }: { member: Member }) {
  const { spent } = member

  const determineStatusToDisplay = (): GiftStatusTagType[] => {
    const statuses: GiftStatusTagType[] = []
    if (spent > 0) {
      statuses.push({ color: 'blue', label: 'Redeemed' })
      if (member?.convertedTo) {
        statuses.push({ color: 'default', label: 'Swapped' })
      }
    } else {
      statuses.push({ color: 'default', label: 'Unredeemed' })
    }

    return statuses
  }

  const statuses = determineStatusToDisplay()

  return (
    <Flex gap={8}>
      {statuses.map(({ color, label }) => (
        <Tag key={label} color={color}>
          {label}
        </Tag>
      ))}
    </Flex>
  )
}
