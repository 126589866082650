import { FilterOutlined } from '@ant-design/icons'
import { Cascader, DatePicker, Tag } from 'antd'
import { BaseOptionType } from 'antd/es/cascader'
import { ANT_DESIGN_DATE_FORMAT, DATE_CHIPS } from 'constants/insights'
import { defaultOccasions } from 'constants/newReward/occasions'
import { useOrgLists } from 'hooks'
import { partition, startCase } from 'lodash-es'
import { useMemo } from 'react'
import { getDateTimeString } from 'utils'
import { dateRangeToJoinedString } from 'utils/insights'
import { useInsightsContext } from './insights-context'

// Just show the latest item.
const displayRender = (labels: string[]) => labels[labels.length - 1]

function OrgListCascader() {
  const { updateInsightsGlobalFilters } = useInsightsContext()
  const { orgLists, hasLoaded } = useOrgLists()
  const options = useMemo<BaseOptionType[]>(() => {
    return orgLists
      .map(list => ({
        value: list.id,
        label: (
          <div className="hide-antd-disabled-sibling-checkbox">
            {startCase(list.name)}
          </div>
        ),
        disableCheckbox: true,
        children: list.options.map(option => ({
          value: option,
          label: option,
        })),
      }))
      .concat([
        {
          value: '_OCCASIONS_FILTERS',
          label: (
            <div className="hide-antd-disabled-sibling-checkbox">Occasions</div>
          ),
          disableCheckbox: true,
          children: defaultOccasions.map(occasion => ({
            value: occasion.label,
            label: occasion.label,
          })),
        },
      ])
  }, [orgLists])

  if (!hasLoaded) {
    return null
  }

  return (
    <Cascader
      suffixIcon={<FilterOutlined />}
      options={options}
      displayRender={displayRender}
      multiple
      allowClear
      style={{ minWidth: 100 }}
      maxTagCount="responsive"
      placeholder="Filters"
      onChange={selectedValues => {
        const filters = selectedValues
          .map(([key, value]) => ({ key, value }))
          .filter(({ value, key }) => value && key) as {
          key: string
          value: string
        }[]

        const [occasions, programOwnersLabels] = partition(
          filters,
          ({ key }) => key === '_OCCASIONS_FILTERS'
        )

        updateInsightsGlobalFilters({
          programOwnersLabels,
          occasions: occasions.map(({ value }) => value),
        })
      }}
    />
  )
}

export function InsightsFilters() {
  const { dayJsDateRange, dateSource, updateDateRange } = useInsightsContext()
  return (
    <section className="flex gap-4 items-center">
      <DatePicker.RangePicker
        key={`${dateSource === 'date-picker' ? 'date-picker' : dateRangeToJoinedString(dayJsDateRange, ANT_DESIGN_DATE_FORMAT)}`}
        defaultValue={dayJsDateRange}
        onChange={v => {
          if (v !== null) {
            if (v[0] && v[1]) updateDateRange([v[0], v[1]], 'date-picker')
          } else updateDateRange(undefined, 'date-picker')
        }}
        allowClear
        format={v =>
          getDateTimeString(v.toDate(), {
            dateOnly: true,
          })
        }
      />
      <div className="flex">
        {DATE_CHIPS.map(({ value, label }) => {
          const checkIsCurrent = () =>
            dateRangeToJoinedString(dayJsDateRange, ANT_DESIGN_DATE_FORMAT) ===
            dateRangeToJoinedString(value, ANT_DESIGN_DATE_FORMAT)
          return (
            <Tag.CheckableTag
              checked={checkIsCurrent()}
              onClick={() => {
                if (checkIsCurrent()) updateDateRange(undefined, 'chips')
                else updateDateRange(value, 'chips')
              }}
              key={value?.toString()}
            >
              {label}
            </Tag.CheckableTag>
          )
        })}
      </div>

      <OrgListCascader />
    </section>
  )
}
