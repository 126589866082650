import { Input } from 'antd'
import { AddButton } from 'components/Buttons/AddButton'
import { Heading, Pane, Text } from 'evergreen-ui'

export function ProgramMemo({
  memo,
  setMemo,
  edit,
  setEdit,
}: {
  memo?: string
  setMemo: (memo: string) => void
  edit: boolean
  setEdit: (edit: boolean) => void
}) {
  const hasMemo = memo && memo.length > 0

  return (
    <>
      <Heading size={100}>Memo</Heading>

      {!edit && (
        <Pane>
          {hasMemo && <Text>{memo}</Text>}
          {!hasMemo && <AddButton setEdit={setEdit} />}
        </Pane>
      )}
      {edit && (
        <Input
          defaultValue={memo}
          placeholder="Program memo"
          onChange={e => setMemo(e.target.value)}
          style={{ maxWidth: 360 }}
        />
      )}
    </>
  )
}
