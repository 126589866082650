import { PartialMessage } from '@bufbuild/protobuf'
import { captureException } from '@sentry/react'
import { doc, getFirestore, setDoc } from 'firebase/firestore'
import { Integration } from 'gen/perkup/v1/integration_pb'
import { Organization_SyncSettings } from 'gen/perkup/v1/organization_pb'
import { toSentry } from 'utils'
import { converter } from 'utils/firestore'

export function updateIntegrationFinchSyncSettings({
  integrationId,
  finchSyncSettings,
}: {
  integrationId: string
  finchSyncSettings: Organization_SyncSettings | undefined
}) {
  const db = getFirestore()
  const docRef = doc(db, `integrations/${integrationId}`).withConverter(
    converter(Integration)
  )

  const updateOrg: PartialMessage<Integration> = {
    finchSyncSettings,
  }

  setDoc(docRef, updateOrg, { merge: true }).catch(e => {
    console.error(e)
    captureException(toSentry(e), {
      contexts: {
        updateIntegrationFinchSyncSettings: {
          integrationId,
          finchSyncSettings,
        },
      },
    })
  })
}
