import { Heading, Pane, Text } from 'evergreen-ui'

import { Button } from 'antd'
import { ReactComponent as ForceRefreshIllustration } from 'assets/ForcedRefresh.svg'
import { Footer } from 'components'

export function SessionExpired() {
  return (
    <Pane maxWidth={1092} marginTop={68} marginX="auto">
      <Pane display="flex" alignItems="center" justifyContent="space-between">
        <Pane marginRight={32}>
          <Heading size={900} marginBottom={16}>
            Welcome back!
          </Heading>
          <Text size={600}>
            Looks like your session was disconnected. Refresh the page to get
            the latest updates.
          </Text>
          <Pane>
            <Button
              type="primary"
              autoFocus
              style={{ marginTop: 32 }}
              size="large"
              onClick={() => {
                window.location.reload()
              }}
            >
              Refresh
            </Button>
          </Pane>
        </Pane>
        <Pane>
          <ForceRefreshIllustration
            style={{ width: '100%', maxWidth: 640, height: 'auto' }}
          />
        </Pane>
      </Pane>
      <Footer />
    </Pane>
  )
}
