import { useAccount } from 'hooks'
import { getAccountBalances } from 'utils/accounts'

export function useAccountBalances(accountId?: string) {
  const { account } = useAccount({ accountId })

  const { balance, outstandingFunds, unallocatedFunds, spent } =
    getAccountBalances({
      account,
    })
  return {
    account,
    balance,
    outstandingFunds,
    unallocatedFunds,
    spent,
  }
}
