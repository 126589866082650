import { LinkOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import { PerkIconTooltipButton } from 'components'
import { copyProgramAcceptanceLink } from 'utils'

export function RewardLinkButton({
  withText = false,
  programId,
}: {
  withText?: boolean
  programId: string
}) {
  const handleCopyGiftLink = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.preventDefault()
    copyProgramAcceptanceLink(programId)
  }

  const text = 'Copy gift link'

  if (withText) {
    return (
      <Button icon={<LinkOutlined />} type="text" onClick={handleCopyGiftLink}>
        {text}
      </Button>
    )
  }

  return (
    <PerkIconTooltipButton
      title={text}
      icon={<LinkOutlined />}
      onClick={handleCopyGiftLink}
      type="text"
    />
  )
}
