import { Request, Requester } from '@algolia/requester-common'
import { Timestamp } from '@bufbuild/protobuf'
import { captureException } from '@sentry/react'
import algoliasearch, { SearchClient } from 'algoliasearch'
import { ALGOLIA_ID } from 'constants/algolia'
import { getAuth } from 'firebase/auth'
import { SecuredAPIKeyGenerator } from 'gen/algolia/api_keys_connect'
import { SecuredAPIKeyResponse } from 'gen/algolia/api_keys_pb'
import { createClient } from 'hooks/useClient'
import { toSentry } from 'utils/sentry'

function getToken(key: string): string | undefined {
  const tokenData = sessionStorage.getItem(key)
  if (tokenData) {
    try {
      const parsedTokenData = SecuredAPIKeyResponse.fromJsonString(tokenData)
      const isExpired =
        parsedTokenData.expiresAt &&
        Timestamp.now().toDate() >= parsedTokenData.expiresAt.toDate()

      if (!isExpired) {
        return parsedTokenData.token
      }
    } catch (error: any) {
      captureException(toSentry(error), {
        contexts: {
          ParseToken: {},
        },
      })
    }
    sessionStorage.removeItem(key)
  }
  return undefined
}

function setToken(key: string, token: string) {
  sessionStorage.setItem(key, token)
}

async function getSecuredAlgoliaToken(
  securedTokener: () => Promise<SecuredAPIKeyResponse>,
  sessionStorageKey: string
): Promise<string | void> {
  try {
    const algoliaToken = getToken(sessionStorageKey)
    if (algoliaToken) {
      return algoliaToken
    }
    const securedTokenResponse = await securedTokener()
    setToken(sessionStorageKey, securedTokenResponse.toJsonString())
    return securedTokenResponse.token
  } catch (error: any) {
    console.error(error)

    captureException(toSentry(error), {
      contexts: {
        GetToken: {},
      },
    })

    return undefined
  }
}

function securedRequester(
  securedTokener: () => Promise<SecuredAPIKeyResponse>,
  key: string
): Requester {
  const { requester } = algoliasearch('', '').transporter
  return {
    send: (request: Request) => {
      return getSecuredAlgoliaToken(securedTokener, key).then(token => {
        if (token) {
          const url = new URL(request.url)
          if (url.searchParams.get('x-algolia-api-key')) {
            url.searchParams.set('x-algolia-api-key', token)
          }
          const headers = request.headers as Record<string, string>
          if (headers['x-algolia-api-key']) {
            headers['x-algolia-api-key'] = token
          }
          const r: Request = {
            ...request,
            url: url.toString(),
            headers,
          }
          return requester.send(r)
        }
        return requester.send(request)
      })
    },
  }
}

function securedIndividualsToken(): () => Promise<SecuredAPIKeyResponse> {
  return async () => {
    const accessToken = await getAuth().currentUser?.getIdToken()
    const client = createClient(SecuredAPIKeyGenerator)
    return client.individualsKey(
      {},
      { headers: { Authorization: `Bearer ${accessToken}` } }
    )
  }
}

const securedProductVariantsTokener = async () => {
  const accessToken = await getAuth().currentUser?.getIdToken()
  const client = createClient(SecuredAPIKeyGenerator)
  return client.productVariantsKey(
    {},
    { headers: { Authorization: `Bearer ${accessToken}` } }
  )
}

export function individualsSearchClient(orgId: string): SearchClient {
  return algoliasearch(ALGOLIA_ID, 'no-token', {
    requester: securedRequester(
      securedIndividualsToken(),
      `algolia_individuals_${orgId}`
    ),
  })
}

export function ProductVariantsSearchClient(orgId: string) {
  return algoliasearch(ALGOLIA_ID, 'no-token', {
    requester: securedRequester(
      securedProductVariantsTokener,
      `algolia_product_variants_${orgId}`
    ),
  })
}
