import { Select } from 'antd'
import { Account } from 'gen/perkup/v1/account_pb'
import { numToDollars } from 'utils'
import { AccountItemLabel } from './AccountItemLabel'

export function SelectAccountDropdown({
  selectedAccount,
  accounts,
  onAccountChange,
}: {
  selectedAccount?: Account
  accounts: Account[]
  onAccountChange: (selectedAccountId: string) => void
}) {
  const accountOptions = accounts
    .sort((a, b) => Number(a.balance) - Number(b.balance))
    .map(account => {
      const balanceDisplay = numToDollars(Number(account.balance))
      return {
        label: AccountItemLabel({ accountName: account.name, balanceDisplay }),
        value: account.id,
      }
    })

  return (
    <Select
      variant="borderless"
      style={{ width: '100%' }}
      value={selectedAccount?.id}
      onChange={onAccountChange}
      options={accountOptions}
    />
  )
}
