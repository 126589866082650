import { Flex, Input } from 'antd'
import { Strong } from 'evergreen-ui'

export function UserNameForm({
  firstName,
  lastName,
  onFirstNameChange,
  onLastNameChange,
}: {
  firstName: string
  lastName: string
  onFirstNameChange: (firstName: string) => void
  onLastNameChange: (lastName: string) => void
}) {
  return (
    <Flex gap={8}>
      <Flex vertical gap={8}>
        <Strong>First name</Strong>
        <Input
          value={firstName}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            onFirstNameChange(e.currentTarget.value)
          }
          placeholder="First name"
        />
      </Flex>

      <Flex vertical gap={8}>
        <Strong>Last name</Strong>

        <Input
          value={lastName}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            onLastNameChange(e.currentTarget.value)
          }
          placeholder="Last Name"
        />
      </Flex>
    </Flex>
  )
}
