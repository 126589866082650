import {
  ApiOutlined,
  DotChartOutlined,
  MonitorOutlined,
} from '@ant-design/icons'
import { ResultSet } from '@cubejs-client/core'
import { useCubeQuery } from '@cubejs-client/react'
import { Skeleton } from 'antd'
import { FlagAvatarWithText, PerkEmpty, PerkScrollbars } from 'components'
import ISO2_TO_COUNTRY from 'constants/iso2ToCountry'
import { MAPBOX_PUBLIC_TOKEN } from 'constants/keys'
import { OrgStylesContext } from 'context'
import { Heading, Text } from 'evergreen-ui'
import { useDevSafeOrgId } from 'hooks/useDevSafeOrgId'
import { compact } from 'lodash-es'
import { useContext, useEffect, useRef } from 'react'
import { convertStringToHslString } from 'utils'
import { useInsightsContext } from '.'

// @ts-ignore:next-line
// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl, { LngLatLike } from '!mapbox-gl'

import 'mapbox-gl/dist/mapbox-gl.css'

// A feature is an individual point on the map
interface MapboxFeature {
  type: 'Feature'
  properties: {
    id: string
  }
  geometry: {
    type: 'Point'
    coordinates: [number, number]
  }
}

// The data that we will pass to the map, containing a list of points
interface MapboxData {
  type: string
  features: MapboxFeature[]
}

function CountriesMap({
  data,
  startingPosition,
}: {
  data: MapboxData
  startingPosition: LngLatLike
}) {
  const orgStyles = useContext(OrgStylesContext)

  const mapContainerRef = useRef<any | null>(null)
  const mapRef = useRef<any | null>(null)

  // Mapbox countries map configuration
  useEffect(() => {
    mapboxgl.accessToken = MAPBOX_PUBLIC_TOKEN

    mapRef.current = new mapboxgl.Map({
      preserveDrawingBuffer: true, // This is needed to be able to download the map as an image in the print
      container: mapContainerRef.current,
      style: 'mapbox://styles/mapbox/light-v11',
      center: startingPosition,
      zoom: 3,
    })

    mapRef.current.on('load', () => {
      mapRef.current.addSource('orders', {
        type: 'geojson',
        data,
        cluster: true,
        clusterMaxZoom: 14,
        clusterRadius: 50,
      })

      const singlePointColor = convertStringToHslString(
        orgStyles['--chart-light']
      )
      const firstStepColor = convertStringToHslString(
        orgStyles['--chart-light']
      )
      const firstStepRadius = 20
      const amountToJumpToSecondStep = 10
      const secondStepColor = convertStringToHslString(orgStyles['--chart-9'])
      const secondStepRadius = 30
      const amountToJumpToThirdStep = 20
      const thirdStepColor = convertStringToHslString(orgStyles['--chart-base'])
      const thirdStepRadius = 40

      // This draws a circle for each cluster. Depending on the current zoom level and number of points in the vacinity, the circle will be larger or smaller, and the color will change as well.
      mapRef.current.addLayer({
        id: 'clusters',
        type: 'circle',
        source: 'orders',
        paint: {
          'circle-color': [
            'step',
            ['get', 'point_count'],
            firstStepColor,
            amountToJumpToSecondStep,
            secondStepColor,
            amountToJumpToThirdStep,
            thirdStepColor,
          ],
          'circle-radius': [
            'step',
            ['get', 'point_count'],
            firstStepRadius,
            amountToJumpToSecondStep,
            secondStepRadius,
            amountToJumpToThirdStep,
            thirdStepRadius,
          ],
        },
      })

      // This draws a text label for each cluster
      mapRef.current.addLayer({
        id: 'cluster-count',
        type: 'symbol',
        source: 'orders',
        filter: ['has', 'point_count'],
        layout: {
          'text-field': ['get', 'point_count_abbreviated'],
          'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
          'text-size': 12,
        },
      })

      // This draws a circle for points that are not yet in a cluster. This is the smallest unit of data on the map.
      mapRef.current.addLayer({
        id: 'unclustered-point',
        type: 'circle',
        source: 'orders',
        filter: ['!', ['has', 'point_count']],
        paint: {
          'circle-color': singlePointColor,
          'circle-radius': 10,
        },
      })

      // This draws a text label for points that are not yet in a cluster. This is the smallest unit of data on the map.
      mapRef.current.addLayer({
        id: 'unclustered-count',
        type: 'symbol',
        source: 'orders',
        filter: ['!', ['has', 'point_count']],
        layout: {
          'text-field': '1',
          'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
          'text-size': 12,
        },
      })

      // Center a cluster on click, and adjust the zoom.
      mapRef.current.on('click', 'clusters', (e: any) => {
        const features = mapRef.current.queryRenderedFeatures(e.point, {
          layers: ['clusters', 'unclustered-point'],
        })
        const clusterId = features[0].properties.cluster_id
        mapRef.current
          .getSource('orders')
          .getClusterExpansionZoom(clusterId, (err: any) => {
            if (err) return

            mapRef.current.easeTo({
              center: features[0].geometry.coordinates,
              zoom: 2,
            })
          })
      })

      // Change the cursor to a pointer when the mouse is over a cluster
      mapRef.current.on('mouseenter', 'clusters', () => {
        mapRef.current.getCanvas().style.cursor = 'pointer'
      })

      // Change it back to the default when it’s not
      mapRef.current.on('mouseleave', 'clusters', () => {
        mapRef.current.getCanvas().style.cursor = ''
      })
    })

    return () => mapRef.current.remove()
  }, [startingPosition, data, orgStyles])

  return <div id="map" ref={mapContainerRef} className=" h-full w-full" />
}

function OrdersOverview({
  results,
}: {
  results: ResultSet<{
    'orders.count': string | number | boolean | null
    'orders.country': string | number | boolean | null
  }>
}) {
  const cleanFullCountries = compact(
    results.rawData().map(result => {
      const country = result['orders.country']
      const count = result['orders.count']

      if (typeof country !== 'string' || typeof count !== 'string')
        return undefined

      const fullCountry = ISO2_TO_COUNTRY[country]

      if (!fullCountry) return undefined

      return {
        iso2: country,
        ...fullCountry,
        count: Number(count || 0),
      }
    })
  ).sort((a, b) => b.count - a.count)

  const mapData: MapboxData = {
    type: 'Countries',
    features: cleanFullCountries.flatMap(
      ({ iso2, iso3, latitude, longitude, count }, i) =>
        // Map box needs individual points to create a cluster, so for each country we create a number of points equal to the count
        new Array(Number(count)).fill(undefined).map((count, j) => ({
          type: 'Feature',
          properties: {
            id: iso2 + iso3 + count + i + j,
          },
          geometry: {
            type: 'Point',
            coordinates: [longitude, latitude], // At the moment, we are not going deeper than the country level, so all the points for a country will have the same coordinates
          },
        }))
    ),
  }

  const countryWithMostOrders = cleanFullCountries[0]

  return (
    <div className="flex flex-col gap-4">
      <section className="grid grid-cols-4 gap-6 h-[520px]">
        <div className="col-span-3 h-full rounded-lg overflow-hidden">
          <CountriesMap
            data={mapData}
            startingPosition={[
              countryWithMostOrders.longitude,
              countryWithMostOrders.latitude,
            ]}
          />
        </div>

        <PerkScrollbars className="max-h-full">
          <div className="col-span-1 flex flex-col gap-4">
            {cleanFullCountries.map(({ iso2, count }) => {
              return (
                <article
                  key={iso2}
                  className="flex items-center justify-between gap-2"
                >
                  <FlagAvatarWithText iso2={iso2} />
                  <Text>{count}</Text>
                </article>
              )
            })}
          </div>
        </PerkScrollbars>
      </section>

      <section className="flex gap-1">
        <Heading>{cleanFullCountries.length}</Heading>
        <Text color="muted">Total countries</Text>
      </section>
    </div>
  )
}

export function OrderDistribution() {
  const orgId = useDevSafeOrgId()

  const { dateRange, insightsGlobalFilters } = useInsightsContext()

  const { resultSet, isLoading, error } = useCubeQuery({
    limit: 5000,
    measures: ['orders.count'],
    dimensions: ['orders.country'],
    filters: [
      {
        member: 'organizations.id',
        operator: 'equals',
        values: [orgId],
      },
      ...insightsGlobalFilters,
    ],
    timeDimensions: [
      {
        dimension: 'orders.created',
        dateRange,
      },
    ],
  })

  const header = (
    <div className="flex justify-between items-center">
      <Heading>Order Distribution</Heading>
      <Text color="muted">By numbers of orders</Text>
    </div>
  )

  if (isLoading) {
    return (
      <div className="flex flex-col gap-6 w-full h-full">
        {header}

        <div className="flex flex-col gap-4">
          <section className="grid grid-cols-4 gap-6 h-[520px]">
            <div className="col-span-3 bg-[#d9d9d9] animate-pulse rounded-lg flex justify-center items-center">
              <DotChartOutlined className="text-5xl text-muted-foreground" />
            </div>

            <div className="col-span-1">
              <Skeleton active paragraph={{ rows: 12 }} />
            </div>
          </section>

          <Skeleton.Button active block className="max-w-32" />
        </div>
      </div>
    )
  }

  if (!resultSet || error) {
    return (
      <div className="flex flex-col gap-6 w-full h-full">
        {header}

        <section className="rounded-lg w-full h-[520px] overflow-hidden">
          <PerkEmpty
            iconNode={
              <ApiOutlined
                className="text-muted-foreground"
                style={{ fontSize: 32 }}
              />
            }
            header="Oops! Something went wrong"
            description="We couldn't load your data. Please try again later."
          />
        </section>
      </div>
    )
  }

  if (resultSet.rawData().length === 0) {
    return (
      <div className="flex flex-col gap-6 w-full h-full">
        {header}

        <section className="rounded-lg w-full h-[520px] overflow-hidden">
          <PerkEmpty
            iconNode={
              <MonitorOutlined
                className="text-muted-foreground"
                style={{ fontSize: 32 }}
              />
            }
            header="Nothing found"
            description="Try a different date range or filter."
          />
        </section>
      </div>
    )
  }

  return (
    <div className="flex flex-col gap-6 w-full h-full">
      {header}

      <OrdersOverview results={resultSet} />
    </div>
  )
}
