import { ListenToListsByOrgId } from 'api/databaseCalls'
import { withOrgSidebar } from 'components'
import {
  ALGOLIA_INDIVIDUALS_INDEX,
  STATUS_NONE_OR_INVITED_OR_ACTIVE,
} from 'constants/algolia'
import { HOME, LABELS } from 'constants/routes'
import { IndividualContext, OrgContext } from 'context'
import { Individual_Role } from 'gen/perkup/v1/individual_pb'
import { OrgList } from 'gen/perkup/v1/organization_pb'
import NoMatch404 from 'pages/NoMatch404'
import { useContext, useEffect, useState } from 'react'
import { Configure, InstantSearch } from 'react-instantsearch'
import { Route, Routes } from 'react-router'
import { individualsSearchClient } from 'services/algolia'
import { Directory } from './Directory'
import { Labels } from './Labels'

function People() {
  const org = useContext(OrgContext)
  const individual = useContext(IndividualContext)
  const isAdmin = individual.role === Individual_Role.admin
  const orgId = org?.id
  const [lists, setLists] = useState<OrgList[]>([])

  const searchClient = individualsSearchClient(org.id)
  // Get Lists
  useEffect(() => {
    if (!orgId) return undefined
    return ListenToListsByOrgId({ orgId, cb: setLists })
  }, [orgId])

  const searchFilter = isAdmin ? undefined : STATUS_NONE_OR_INVITED_OR_ACTIVE

  const initialRefinements = ['active', 'invited', 'none', 'blocked']

  return (
    <InstantSearch
      searchClient={searchClient}
      indexName={ALGOLIA_INDIVIDUALS_INDEX}
      initialUiState={{
        [ALGOLIA_INDIVIDUALS_INDEX]: {
          refinementList: {
            status: initialRefinements,
          },
        },
      }}
    >
      <>
        <Configure filters={searchFilter} />
        <Routes>
          <Route path={HOME} element={<Directory lists={lists} />} />
          <Route path={LABELS} element={<Labels labels={lists} />} />

          <Route path="*" element={<NoMatch404 hasFooter={false} />} />
        </Routes>
      </>
    </InstantSearch>
  )
}

export default withOrgSidebar(People)
