import { listProductVariantsByProductId } from 'api/databaseCalls'
import { ProductVariant } from 'gen/perkup/v1/product_variant_pb'
import useIds from 'hooks/useIds'
import { useEffect, useState } from 'react'

export default function useListAllProductVariantsByProductId({
  productId,
}: {
  productId: string | undefined
}) {
  const { orgId } = useIds()
  const [isLoadingInitial, setIsLoadingInitial] = useState(true)
  const [isLoadingMore, setIsLoadingMore] = useState(false)
  const [allProductVariants, setAllProductVariants] = useState<
    ProductVariant[]
  >([])

  useEffect(() => {
    if (!productId) {
      setIsLoadingInitial(false)
      setAllProductVariants([])
      return
    }

    setIsLoadingMore(true)
    listProductVariantsByProductId({ productId, orgId })
      .then(setAllProductVariants)
      .finally(() => {
        setIsLoadingInitial(false)
        setIsLoadingMore(false)
      })
  }, [productId, orgId])

  const defaultVariant = allProductVariants?.[0]

  return {
    allProductVariants,
    isLoading: isLoadingInitial || isLoadingMore,
    defaultVariant,
    isLoadingInitial,
    isLoadingMore,
  }
}
