import { Flex } from 'antd'
import Skeleton from 'antd/es/skeleton'
import { BillingHistoryTable } from 'components'
import { Heading } from 'evergreen-ui'
import { useListInvoicesByCustomerId } from 'hooks'

export default function BillingHistory() {
  const { invoices, isLoading } = useListInvoicesByCustomerId()

  if (isLoading) return <Skeleton active paragraph={{ rows: 8 }} />

  if (invoices.length === 0) return null

  return (
    <Flex vertical gap={16}>
      <Heading size={600}>Billing history</Heading>
      <BillingHistoryTable invoices={invoices} />
    </Flex>
  )
}
