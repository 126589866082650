import { ListProductVariantsByProductIdsV2 } from 'api/databaseCalls'
import { ProductVariant } from 'gen/perkup/v1/product_variant_pb'
import useIds from 'hooks/useIds'
import { useEffect, useState } from 'react'

export default function useListAllProductVariantsByProductIds({
  productIds,
}: {
  productIds: string[]
}) {
  const { orgId } = useIds()
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingInitial, setIsLoadingInitial] = useState(true)
  const [productVariants, setProductVariants] = useState<ProductVariant[]>([])

  useEffect(() => {
    if (productIds?.length === 0) {
      setIsLoadingInitial(false)
      setProductVariants([])
      setIsLoading(false)
      return () => {}
    }

    let current = true

    setIsLoading(true)
    ListProductVariantsByProductIdsV2({ productIds, orgId })
      .then(res => {
        if (current) {
          setProductVariants(res)
        }
      })
      .finally(() => {
        if (current) {
          setIsLoading(false)
          setIsLoadingInitial(false)
        }
      })

    return () => {
      current = false
    }
  }, [productIds, orgId])

  return { productVariants, isLoading, isLoadingInitial }
}
