import { StarOutlined } from '@ant-design/icons'
import { DraftOrderContext, ExchangeRateContext } from 'context'
import { useDisplayCurrency } from 'hooks'
import { useContext } from 'react'
import { determineTotalSavingsForLineItems, numToDollars } from 'utils'

export function BigSavingsTag({
  inLocalAmounts = false,
}: {
  inLocalAmounts?: boolean
}) {
  const exchangeRate = useContext(ExchangeRateContext)
  const { draftOrderCalculation } = useContext(DraftOrderContext)

  const displayCurrency = useDisplayCurrency()

  if (!draftOrderCalculation) return null

  // Pass in all the line items to determine the total savings for the entire order
  const totalSavingsForEntireOrder = determineTotalSavingsForLineItems(
    draftOrderCalculation.lineItems
  )

  if (totalSavingsForEntireOrder === 0) return null

  const totalSavingsToDisplay = inLocalAmounts
    ? numToDollars(
        totalSavingsForEntireOrder * exchangeRate,
        2,
        false,
        displayCurrency
      )
    : numToDollars(totalSavingsForEntireOrder, 2)

  return (
    <div className="flex items-center gap-1 bg-primary/10 text-primary-foreground p-2 rounded-[4px] text-sm">
      <StarOutlined />

      <p>
        You are saving <b className="font-bold">{totalSavingsToDisplay}</b> with
        the On demand bulk discount
      </p>
    </div>
  )
}
