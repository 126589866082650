import { DownloadOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import { isEmpty } from 'lodash-es'
import { useMemo } from 'react'
import { CSVLink } from 'react-csv'
import { BalanceReport } from 'types/TinyBird'

interface ReportDownloadData {
  date: string
  openingBalance: number
  deposited: number
  refunded: number
  spent: number
  endingBalance: number
}

export function DownloadBalanceReportButton({
  reports,
}: {
  reports: BalanceReport[]
}) {
  const formattedReports: ReportDownloadData[] = useMemo(() => {
    return reports.map(br => {
      return {
        date: br.date,
        openingBalance: br.openingBalance
          ? br.openingBalance / 100
          : br.openingBalance,
        endingBalance: br.endingBalance
          ? br.endingBalance / 100
          : br.endingBalance,
        deposited: br.deposited ? br.deposited / 100 : br.deposited,
        refunded: br.refunded ? br.refunded / 100 : br.refunded,
        spent: br.spent ? br.spent / 100 : br.spent,
        adjustment: br.adjustment ? br.adjustment / 100 : br.adjustment,
      }
    })
  }, [reports])

  const headers = [
    { label: 'Date', key: 'date' },
    { label: 'Opening Balance', key: 'openingBalance' },
    { label: 'Deposited', key: 'deposited' },
    { label: 'Refunded', key: 'refunded' },
    { label: 'Spent', key: 'spent' },
    { label: 'Adjustment', key: 'adjustment' },
    { label: 'Ending Balance', key: 'endingBalance' },
  ]

  const disableDownloadBtn = isEmpty(formattedReports)

  return (
    <CSVLink
      data={formattedReports}
      headers={headers}
      filename="perkup_balance_report.csv"
    >
      <Button disabled={disableDownloadBtn} icon={<DownloadOutlined />}>
        Download summary
      </Button>
    </CSVLink>
  )
}
