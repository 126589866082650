import { AnyMessage } from '@bufbuild/protobuf'
import { captureException } from '@sentry/react'
import { Select } from 'antd'
import { toaster } from 'evergreen-ui'
import { deleteField, DocumentReference, setDoc } from 'firebase/firestore'
import { OrgList } from 'gen/perkup/v1/organization_pb'
import { startCase } from 'lodash-es'
import { toSentry } from 'utils/sentry'

const EVERYONE = 'Everyone'

export function RecipientsCell({
  docRef,
  labels,
  lists,
}: {
  docRef: DocumentReference<AnyMessage>
  labels: { [key: string]: string }
  lists: OrgList[]
}) {
  const hasLabels = Object.values(labels).length > 0
  const values = hasLabels
    ? Object.entries(labels).map(([key, value]) => `${key}:${value}`)
    : EVERYONE

  const options = [
    {
      label: EVERYONE,
      options: [{ label: EVERYONE, value: EVERYONE }],
    },
    ...lists.map(list => {
      return {
        label: startCase(list.name),
        options: list.options.map(v => ({
          label: v,
          value: `${list.id}:${v}`,
        })),
      }
    }),
  ]

  const handleSelect = ({ key, value }: { key: string; value: string }) => {
    setDoc(
      docRef,
      {
        labels: { [key]: value },
      },
      { merge: true }
    )
      .then(() => {
        toaster.success(`Labels updated`, { id: 'lists' })
      })
      .catch(error => {
        console.error(error)
        captureException(toSentry(error), {
          contexts: {
            RecipientsCell: {
              docRef,
            },
          },
        })
        toaster.warning('Something went wrong, try again or contact support.')
      })
  }

  const handleDeselect = ({ key }: { key: string }) => {
    setDoc(
      docRef,
      {
        labels: { [key]: deleteField() },
      },
      { merge: true }
    )
      .then(() => {
        toaster.success(`Labels updated`, { id: 'lists' })
      })
      .catch(error => {
        console.error(error)
        captureException(toSentry(error), {
          contexts: {
            RecipientsCell: {
              docRef,
            },
          },
        })
        toaster.warning('Something went wrong, try again or contact support.')
      })
  }

  return (
    <Select
      key="Label"
      showSearch
      style={{ width: '100%' }}
      mode={hasLabels ? 'multiple' : undefined}
      value={values}
      options={options}
      onSelect={value => {
        const labelKey = value.substring(0, value.indexOf(':'))
        const labelValue = value.substring(value.indexOf(':') + 1, value.length)
        handleSelect({ key: labelKey, value: labelValue })
      }}
      onDeselect={value => {
        const labelKey = value.substring(0, value.indexOf(':'))
        handleDeselect({ key: labelKey })
      }}
      bordered={false}
      popupMatchSelectWidth={false}
    />
  )
}
