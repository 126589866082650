// @generated by protoc-gen-connect-es v1.1.4 with parameter "target=ts"
// @generated from file manual/v1/manual.proto (package manual.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { CreateOrderRequest, CreateOrderResponse } from "./manual_pb.js";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service manual.v1.Orders
 */
export const Orders = {
  typeName: "manual.v1.Orders",
  methods: {
    /**
     * @generated from rpc manual.v1.Orders.CreateOrder
     */
    createOrder: {
      name: "CreateOrder",
      I: CreateOrderRequest,
      O: CreateOrderResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

