import { warehouses } from 'constants/warehouses'
import { ProductVariant } from 'gen/perkup/v1/product_variant_pb'
import { compact, flatten, values } from 'lodash-es'

export const filterWarehousesByProductVariants = (
  productVariants: ProductVariant[],
  orgWarehouseIds: string[]
) => {
  const orgWarehouses = warehouses.filter(w => orgWarehouseIds.includes(w.id))

  const pvWarehouseIds = compact(
    flatten(
      productVariants.map(pv =>
        values(pv.inventoryLevels).map(il => il.location?.id)
      )
    )
  )
  if (pvWarehouseIds.length === 0) return orgWarehouses

  return orgWarehouses.filter(w => w.id && pvWarehouseIds.includes(w.id))
}
