import { COLLECTION_PARAM, NEW_REWARD_PARAM, TEMPLATE_PARAM } from './params'

export const HOME = '/'

// Authentication
export const SIGN_UP = '/signup'
export const SIGN_IN = '/signin'
export const VERIFY_EMAIL = '/verify-email'
export const EMAIL_CONFIRMATION = '/email-confirmation'

// Card
export const CARD = '/card'
export const CARD_SETUP = `${CARD}/setup`
export const CARD_ACTIVATED = `${CARD}/activated`

// User
export const ONBOARDING = '/onboarding'
export const SELECT_COUNTRY = '/select-country'
export const PERKS = '/perks'
export const PERK = `${PERKS}/:id`
export const LOCAL_SEARCH = '/local-search'
export const DASHBOARD = CARD
export const ACTIVITY = '/activity'
export const TRANSACTIONS = '/transactions'
export const TRANSACTIONS_DETAILS = '/transactions/:id'
export const CARD_DETAILS = '/card-details'

// Accounts
export const ACCOUNT = '/account'

// Organization
export const ORGANIZATION = '/organization'
export const ORGANIZATION_NEW = '/organization-new'

// Directory
export const PENDING_INVITES = '/invites'
export const LABELS = '/labels'

// Manage Organization
export const ORG_ACCOUNT = '/:accountId'

// Params
export const PARAM_NEW_PROGRAM = 'newProgram'
export const TERM = 'term'

// Rewards
export const CHECKOUT = 'checkout'
export const OCCASION = '/occasion'
export const IMAGE = '/image'
export const EDIT = '/edit'
export const RECIPIENT = '/recipient'
export const REVIEW = '/review'

export const FIRST_REWARD = '/first-reward'

export const REWARD_ACCEPTANCE = '/g/:programId'

export const PREVIEW_REWARDS = '/rewards/preview'
export const PREVIEW_REWARD = `${PREVIEW_REWARDS}/:id`

export const REWARD_ACCEPTANCE_REVEAL = '/reveal'
export const REWARD_ACCEPTANCE_CHECKOUT = '/checkout'
export const REWARD_ACCEPTANCE_CONFIRMATION = '/confirmation'

// Amazon
export const AMAZON = '/amazon'
export const AMAZON_SEARCH = `${AMAZON}/search`
export const AMAZON_PRODUCT = `${AMAZON}/product`
export const AMAZON_PRODUCT_BY_ASIN = `${AMAZON_PRODUCT}/:asin`
export const AMAZON_CHECKOUT = `${AMAZON}/checkout`

// Swag
export const SWAG = '/swag'
export const ORDER = '/order'
export const SWAG_PRODUCTS = `/:collectionId`
export const SWAG_ORDERS_TABLE = `/orders`
export const SWAG_PRODUCT = `product/:productId`
export const SWAG_CHECKOUT = `${SWAG}/checkout`
export const CUSTOMIZE = '/customize'
export const BULK = '/bulk'
export const BLANKS = '/blanks'
export const SEARCH = '/search'
export const SEARCH_PRODUCT = `${SEARCH}/:prodEId`
export const SEARCH_CONFIRMATION = `${SEARCH}/confirmation`

// Collections
export const SWAG_COLLECTION = '/collections/:collectionId'
export const DETAILS = '/details'
export const LOGO = '/logo'
// Swag Inventory
export const SWAG_INVENTORY_PRODUCT = '/products/:productId'

// Gifts
export const GIFTS = '/gifts'
export const BROWSE_VENDORS = '/:vid'
export const BROWSE_PRODUCTS = '/:pid'

export const AccountRoutes = {
  PROFILE: 'profile',
  ADDRESSES: 'addresses',
  NOTIFICATIONS: 'notifications',
}

// User marketplace
export const SHOP = '/shop'

export const DISCOVER = '/discover'

export const FOOD = '/food'
export const SHOP_FOOD = `${SHOP}/food`

export const GIFT_CARDS = '/gift-cards'
export const SHOP_GIFT_CARDS = `${SHOP}${GIFT_CARDS}`

export const PRODUCTS = '/products'
export const SHOP_PRODUCTS = `${SHOP}${PRODUCTS}`

export const VARIANT_ID = '/:vid'
export const BROWSE = '/browse'
export const PRODUCT_ID = '/:pid'
export const VIEW_PRODUCT = `${BROWSE}${PRODUCT_ID}`
export const PRODUCT_CHECKOUT = `${VIEW_PRODUCT}${VARIANT_ID}/checkout`

// // subroutes
export const SETUP = '/setup'
export const ACTIVATED = '/activated'
const REWARDS = '/rewards'
const ANNIVERSARIES = '/anniversaries'
export const NEW = '/new'
const BIRTHDAYS = '/birthdays'
const NEW_HIRE = '/new-hire'
const SPOT = '/spot'
const DIRECTORY = '/directory'
const ACCOUNTS = '/accounts'
const THEME = '/theme'
const INSIGHTS = `/insights`
const SETTINGS = '/settings'
export const REPORTS = 'reports'
export const LOGS = 'logs'

const INTEGRATIONS = '/integrations'

export const COLLECTIONS = '/collections'
const PERK_PROGRAMS = '/perk-programs'
const PAYLOAD_PROVIDER_ID_PARAM = 'payroll_provider_id'

const PROGRAM_DETAILS_PARAM = 'programId'

export const TEMPLATES = '/templates'
export const DEFAULT = '/default'
const BILLING = '/billing'

export const CALENDAR = '/calendar'
export const RULES = '/rules'
export const MISSING = '/missing'
export const SENT = '/sent'
export const NOTIFICATIONS = '/notifications'
export const CUSTOMER_SUCCESS = '/customer-success'

export const DEFAULT_ROUTES = {
  ORGANIZATION: {
    ROOT: ORGANIZATION,
    REWARDS: {
      ROOT: `${ORGANIZATION}${REWARDS}`,
      SPOT: `${ORGANIZATION}${REWARDS}${SPOT}`,
      REWARD: `${ORGANIZATION}${REWARDS}/:${NEW_REWARD_PARAM}`,
      EDIT: `${ORGANIZATION}${REWARDS}/:${NEW_REWARD_PARAM}${EDIT}`,
      RECIPIENT: `${ORGANIZATION}${REWARDS}/:${NEW_REWARD_PARAM}${RECIPIENT}`,
      REVIEW: `${ORGANIZATION}${REWARDS}/:${NEW_REWARD_PARAM}${REVIEW}`,
      OCCASION: {
        ROOT: `${ORGANIZATION}${REWARDS}/:${NEW_REWARD_PARAM}${OCCASION}`,
        TEMPLATES: `${ORGANIZATION}${REWARDS}/:${NEW_REWARD_PARAM}${OCCASION}${TEMPLATES}`,
        DEFAULT: `${ORGANIZATION}${REWARDS}/:${NEW_REWARD_PARAM}${OCCASION}${DEFAULT}`,
      },
      REWARD_TEMPLATE: `${ORGANIZATION}${REWARDS}/:${NEW_REWARD_PARAM}${TEMPLATES}`,
      IMAGE: `${ORGANIZATION}${REWARDS}/:${NEW_REWARD_PARAM}/${IMAGE}`,
      NEW_REWARD: `${ORGANIZATION}${REWARDS}${NEW}`,
      TEMPLATES: {
        ROOT: `${ORGANIZATION}${REWARDS}${TEMPLATES}`,
        NEW: `${ORGANIZATION}${REWARDS}${TEMPLATES}${NEW}`,
        EDIT: `${ORGANIZATION}${REWARDS}${TEMPLATES}/:${TEMPLATE_PARAM}`,
      },
      ANNIVERSARIES: {
        ROOT: `${ORGANIZATION}${REWARDS}${ANNIVERSARIES}`,
        CALENDAR: `${ORGANIZATION}${REWARDS}${ANNIVERSARIES}${CALENDAR}`,
        RULES: `${ORGANIZATION}${REWARDS}${ANNIVERSARIES}${RULES}`,
        NOTICATIONS: `${ORGANIZATION}${REWARDS}${ANNIVERSARIES}${NOTIFICATIONS}`,
        MISSING: `${ORGANIZATION}${REWARDS}${ANNIVERSARIES}${MISSING}`,
        SENT: `${ORGANIZATION}${REWARDS}${ANNIVERSARIES}${SENT}`,
      },
      BIRTHDAYS: {
        ROOT: `${ORGANIZATION}${REWARDS}${BIRTHDAYS}`,
        CALENDAR: `${ORGANIZATION}${REWARDS}${BIRTHDAYS}${CALENDAR}`,
        RULES: `${ORGANIZATION}${REWARDS}${BIRTHDAYS}${RULES}`,
        NOTICATIONS: `${ORGANIZATION}${REWARDS}${BIRTHDAYS}${NOTIFICATIONS}`,
        MISSING: `${ORGANIZATION}${REWARDS}${BIRTHDAYS}${MISSING}`,
        SENT: `${ORGANIZATION}${REWARDS}${BIRTHDAYS}${SENT}`,
      },
      NEW_HIRE: {
        ROOT: `${ORGANIZATION}${REWARDS}${NEW_HIRE}`,
        CALENDAR: `${ORGANIZATION}${REWARDS}${NEW_HIRE}${CALENDAR}`,
        RULES: `${ORGANIZATION}${REWARDS}${NEW_HIRE}${RULES}`,
        SENT: `${ORGANIZATION}${REWARDS}${NEW_HIRE}${SENT}`,
      },
    },
    SWAG: {
      ROOT: `${ORGANIZATION}${SWAG}`,
      PRODUCTS: `${ORGANIZATION}${SWAG}${PRODUCTS}`,
      COLLECTIONS: `${ORGANIZATION}${SWAG}${COLLECTIONS}`,
      BULK_CATALOGS: `${ORGANIZATION}${SWAG}${BULK}`,
      NEW_COLLECTION: `${ORGANIZATION}${SWAG}${COLLECTIONS}${NEW}`,
      NEW_COLLECTION_DETAILS: `${ORGANIZATION}${SWAG}${COLLECTIONS}${NEW}/:${COLLECTION_PARAM}${DETAILS}`,
      NEW_COLLECTION_LOGO: `${ORGANIZATION}${SWAG}${COLLECTIONS}${NEW}/:${COLLECTION_PARAM}${LOGO}`,
      NEW_COLLECTION_PRODUCTS: `${ORGANIZATION}${SWAG}${COLLECTIONS}${NEW}/:${COLLECTION_PARAM}${PRODUCTS}`,
      ORDERS_TABLE: `${ORGANIZATION}${SWAG}${SWAG_ORDERS_TABLE}`,
      CUSTOMIZE: `${ORGANIZATION}${SWAG}${CUSTOMIZE}`,
      SEARCH: `${ORGANIZATION}${SWAG}${SEARCH}`,
      BLANKS: `${ORGANIZATION}${SWAG}${BLANKS}`,
      SEARCH_PRODUCT: `${ORGANIZATION}${SWAG}${SEARCH_PRODUCT}`,
      SEARCH_CONFIRMATION: `${ORGANIZATION}${SWAG}${SEARCH_CONFIRMATION}`,
    },
    INSIGHTS: {
      ROOT: `${ORGANIZATION}${INSIGHTS}`,
      REPORTS: `${ORGANIZATION}${INSIGHTS}/${REPORTS}`,
      LOGS: `${ORGANIZATION}${INSIGHTS}/${LOGS}`,
    },
    CUSTOMER_SUCCESS: {
      ROOT: `${ORGANIZATION}${CUSTOMER_SUCCESS}`,
    },
    DIRECTORY: {
      ROOT: `${ORGANIZATION}${DIRECTORY}`,
      LABELS: `${ORGANIZATION}${DIRECTORY}${LABELS}`,
    },
    TRANSACTIONS: `${ORGANIZATION}${TRANSACTIONS}`,
    BILLING: `${ORGANIZATION}${BILLING}`,
    ACCOUNTS: {
      ROOT: `${ORGANIZATION}${ACCOUNTS}`,
    },
    BRANDING: `${ORGANIZATION}${THEME}`,
    SETTINGS: `${ORGANIZATION}${SETTINGS}`,
    INTEGRATIONS: {
      ROOT: `${ORGANIZATION}${INTEGRATIONS}`,
      PAYLOAD_PROVIDER_ID: `${ORGANIZATION}${INTEGRATIONS}/:${PAYLOAD_PROVIDER_ID_PARAM}`,
    },
    GIFTS: {
      ROOT: `${ORGANIZATION}${GIFTS}${BROWSE}`,
      COLLECTIONS: `${ORGANIZATION}${GIFTS}${BROWSE}${COLLECTIONS}`,
    },
    COLLECTIONS: `${ORGANIZATION}${COLLECTIONS}`,
    PERK_PROGRAMS: {
      ROOT: `${ORGANIZATION}${PERK_PROGRAMS}`,
      PROGRAM_DETAILS: `${ORGANIZATION}${PERK_PROGRAMS}/:${PROGRAM_DETAILS_PARAM}`,
    },
    ORDER: {
      ROOT: `${ORGANIZATION}${ORDER}`,
      SWAG: `${ORGANIZATION}${ORDER}${SWAG}`,
      GIFTS: `${ORGANIZATION}${ORDER}${GIFTS}`,
    },
  },
  CARD: {
    ROOT: CARD,
  },
  SWAG: {
    ROOT: SWAG,
  },
}
