import { allCategories, ApprovedCategories } from 'constants/EligibleCategories'
import { EDIT, IMAGE, OCCASION, RECIPIENT, REVIEW } from 'constants/routes'
import { RewardStep } from 'types/Rewards'

export const selectableCats = [
  allCategories,
  ApprovedCategories.SWAG,
  ApprovedCategories.FOOD,
]

export const defaultBudgets = [2500, 5000, 10000, 15000, 25000]

export const rewardSteps: RewardStep[] = [
  {
    title: 'Occasion',
    path: OCCASION,
    step: 1,
  },
  {
    title: 'Image',
    path: IMAGE,
    step: 2,
  },
  {
    title: 'Reward',
    path: EDIT,
    step: 3,
  },
  {
    title: 'Recipient',
    path: RECIPIENT,
    step: 4,
  },
  {
    title: 'Review',
    path: REVIEW,
    step: 5,
  },
]

export const LUNCH = 'Lunch'

export enum RewardSteps {
  OCCASION = 'occasion',
  IMAGE = 'image',
  REWARD = 'reward',
  PERSONALIZATION = 'personalization',
  REVIEW = 'review',
}

export const CHOOSE_YOUR_GIFT = 'Choose your gift'

export const DEFAULT_DIRECT_MAIL_ORDER_TITLE = 'Direct mail order'
