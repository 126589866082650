import { Popover, Tag } from 'antd'
import { useTheme } from 'evergreen-ui'
import { useDefaultOrgColors } from 'hooks'
import { useSelectRewardContext } from '../SelectRewardContext'
import { CurrentSelectedReward } from './current-selected-reward'
import { EditCurrentSelectedRewardForm } from './edit-current-selected-reward-form'

export function CurrentSelectedRewardPopover({
  readOnly = false,
}: {
  readOnly?: boolean
}) {
  const { noRewardSelected, selectedReward } = useSelectRewardContext()
  const { colors } = useTheme()
  const { defaultColor } = useDefaultOrgColors()

  const isSingleProduct =
    (selectedReward?.gift?.productIds?.length ?? 0) <= 1 && !noRewardSelected

  const disablePopover = noRewardSelected || isSingleProduct

  let tagColor = noRewardSelected ? colors.muted : defaultColor
  if (isSingleProduct) {
    tagColor = 'black'
  }

  return (
    <Popover
      key={`${disablePopover}`}
      trigger={disablePopover ? [] : 'hover'}
      content={
        disablePopover ? null : (
          <EditCurrentSelectedRewardForm readOnly={readOnly} />
        )
      }
      mouseLeaveDelay={0.3}
      placement="topRight"
      overlayInnerStyle={{ padding: 16 }}
    >
      <Tag
        style={{
          userSelect: 'none',
          padding: '4px 8px',
          flex: 5,
          display: 'flex',
          justifyContent: 'center',
          color: tagColor,
          backgroundColor: 'white',
        }}
        bordered={!noRewardSelected}
      >
        <CurrentSelectedReward readOnly={readOnly} />
      </Tag>
    </Popover>
  )
}
