export const fontFamilies = {
  display: `"Poppins", "SF UI Display", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`,
  mono: `"Inter", "SF Mono", "Monaco", "Inconsolata", "Fira Mono", "Droid Sans Mono", "Source Code Pro", monospace`,
  ui: `"Inter", "SF UI Text", -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif`,
  digits: `'Cutive Mono', monospace`,
  external: `"Open Sans", "SF UI Text", -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif`,
}

export const colors = {
  primary: '#1070CA',
  secondary: '#252351',
  green: '#00ba88',
}

export const zIndex = {
  layer2: 5,
}
