import { Image } from 'antd'
import { unsplash } from 'assets/icons'
import { PerkLoader } from 'components'
import { CustomCarousel } from 'components/Carousels/CustomCarousel'
import { CarouselImageCard } from 'components/Images/CarouselImageCard'
import { Pane } from 'evergreen-ui'
import { useInfiniteUnsplashedScroll } from 'hooks'
import { Basic } from 'unsplash-js/dist/methods/photos/types'
import { unsplashApi } from 'utils'

function UnsplashImage({
  photo,
  handleDownload,
  photoRef,
}: {
  photo: Basic
  handleDownload: (photo: Basic) => void
  photoRef: ((node?: Element | null | undefined) => void) | null
}) {
  return (
    <Pane
      ref={photoRef}
      onClick={() => handleDownload(photo)}
      borderRadius={8}
      overflow="hidden"
      hoverElevation={1}
      cursor="pointer"
      height="fit-content"
    >
      <img
        key={photo.id}
        src={photo.urls?.regular}
        alt={photo.alt_description || ''}
        width="100%"
      />
    </Pane>
  )
}

function UnsplashGrid({
  photos,
  handleDownload,
  lastPhotoRef,
}: {
  photos: Basic[]
  handleDownload: (photo: Basic) => void
  lastPhotoRef: (node?: Element | null | undefined) => void
}) {
  const gap = 16

  const getPhotoRef = (photoId: string) => {
    const lastPhoto = photos[photos.length - 1]
    if (lastPhoto?.id === photoId) {
      return lastPhotoRef
    }
    return null
  }

  const columnStyles: {
    display: string
    flexDirection: 'column'
    gap: number
  } = {
    display: 'flex',
    flexDirection: 'column',
    gap,
  }

  return (
    <Pane
      display="grid"
      gridTemplateColumns="repeat(3, 1fr)"
      gridAutoFlow="row dense"
      gap={gap}
    >
      {/* 1,2,3 represents three columns */}
      {[1, 2, 3].map((value, columnIndex) => {
        const columnPhotos = photos.filter((_, i) => i % 3 === columnIndex)
        return (
          <Pane key={value} {...columnStyles}>
            {columnPhotos.map(photo => {
              return (
                <UnsplashImage
                  key={photo.id}
                  photo={photo}
                  handleDownload={handleDownload}
                  photoRef={getPhotoRef(photo.id)}
                />
              )
            })}
          </Pane>
        )
      })}
    </Pane>
  )
}

export default function UnsplashResults({
  query,
  onPhotoClick,
  useCarousel = false,
  handleSeeMoreClick,
}: {
  query: string
  onPhotoClick: (photo: Basic) => void
  useCarousel?: boolean
  handleSeeMoreClick?: () => void
}) {
  const { photos, lastPhotoRef, isLoadingInitial, isLoadingMore } =
    useInfiniteUnsplashedScroll({ query })

  const handleDownload = (photo: Basic) => {
    unsplashApi.photos.trackDownload({
      downloadLocation: photo?.links?.download_location,
    })
    onPhotoClick(photo)
  }

  const unsplashCarouselItems = photos.map(photo => {
    return (
      <Pane ref={lastPhotoRef} key={photo.id}>
        <CarouselImageCard
          imageUrl={photo.urls?.regular}
          onClick={() => handleDownload(photo)}
        />
      </Pane>
    )
  })

  return (
    <>
      {isLoadingInitial && <PerkLoader />}
      {useCarousel && (
        <CustomCarousel
          header="Unsplash"
          headerIcon={<Image preview={false} src={unsplash} height={16} />}
          handleSeeMoreClick={handleSeeMoreClick}
          handleCTAText="See more"
          showSeeAllButton
        >
          {unsplashCarouselItems}
        </CustomCarousel>
      )}
      {!useCarousel && (
        <UnsplashGrid
          photos={photos}
          handleDownload={handleDownload}
          lastPhotoRef={lastPhotoRef}
        />
      )}
      {isLoadingMore && <PerkLoader />}
    </>
  )
}
