import CloseCircleOutlined from '@ant-design/icons/CloseCircleOutlined'
import GiftOutlined from '@ant-design/icons/GiftOutlined'
import { captureException } from '@sentry/react'
import { Button, Image } from 'antd'
import Badge from 'antd/es/badge'
import Drawer from 'antd/es/drawer'
import Tooltip from 'antd/es/tooltip'
import { createNewCustomAction } from 'api/databaseCalls'
import {
  EditableAmountCell,
  EditableImageCell,
  EditableTextInput,
  EmailPreview,
  EventActionDisabled,
} from 'components'
import { NUMBER_GREEN } from 'constants/colors'
import { OrgContext, RulesContext } from 'context'
import { Pane, Text, toaster } from 'evergreen-ui'
import { deleteDoc, doc, getFirestore, setDoc } from 'firebase/firestore'
import { AnimatePresence, motion } from 'framer-motion'
import { Action, ActionType_Enum, CustomAction } from 'gen/perkup/v1/rules_pb'
import { useDefaultOrgColors } from 'hooks'
import isUndefined from 'lodash-es/isUndefined'
import { useContext, useEffect, useState } from 'react'
import { useReward } from 'react-rewards'
import { storedId } from 'utils/firestore'
import { toSentry } from 'utils/sentry'
import { IEventInfo } from './EventDrawer'

export function EventAction({
  currentAction,
  isCustomAction,
  eventInfo,
}: {
  currentAction: CustomAction | Action
  isCustomAction: boolean
  eventInfo: IEventInfo
}) {
  const rules = useContext(RulesContext)
  const rewardRef = 'reward-target'
  const { reward } = useReward(rewardRef, 'confetti')
  const org = useContext(OrgContext)
  const orgId = org?.id
  const eventIndividualId = eventInfo.eventIndividual.id

  const [wasBasicAction, setWasBasicAction] = useState(true)
  const [childrenDrawer, setChildrenDrawer] = useState(false)
  const [isHoveringRibbon, setIsHoveringRibbon] = useState(false)
  const { id, ruleId, image, amount, title, message, from, gift } =
    currentAction

  const isExcluded = isCustomAction && (currentAction as CustomAction).excluded
  const isDisabled =
    !isCustomAction && rules.find(rule => rule.id === ruleId)?.disabled

  const { defaultColor } = useDefaultOrgColors()

  const actionId = isCustomAction
    ? (currentAction as CustomAction).actionId
    : id
  const db = getFirestore()
  const docRef = doc(
    db,
    `organizations/${orgId}/rules/${ruleId}/actions/${actionId}/customActions/${eventIndividualId}`
  ).withConverter(storedId(CustomAction))

  useEffect(() => {
    if (isCustomAction) {
      reward()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCustomAction])

  const handleCreateCustomAction = () => {
    if (!isCustomAction) {
      createNewCustomAction({
        orgId,
        individualId: eventIndividualId,
        currentAction,
        excluded: false,
        actionType: ActionType_Enum.createProgram,
      })
    }
  }

  const handleSetExcluded = () => {
    if (!isCustomAction) {
      createNewCustomAction({
        orgId,
        individualId: eventIndividualId,
        currentAction,
        excluded: true,
        actionType: ActionType_Enum.createProgram,
      })
      toaster.warning('Excluded from rule')
      setWasBasicAction(true)
      return
    }

    const excluded = true

    setDoc(
      docRef,
      {
        excluded,
      },
      { merge: true }
    )
      .then(() => {
        toaster.warning('Excluded from rule')
        setWasBasicAction(false)
      })
      .catch(error => {
        console.error(error)
        toaster.warning('Something went wrong, try again or contact support.')
        captureException(toSentry(error), {
          contexts: {
            handleSetExcluded: {
              orgId,
              eventIndividualId,
              currentAction,
              excluded,
              actionType: ActionType_Enum.createProgram,
            },
          },
        })
      })
  }

  const handleRestoreAction = () => {
    deleteDoc(docRef)
      .then(() => {
        toaster.warning('Removed custom content')
      })
      .catch(error => {
        console.error(error)
        toaster.warning('Something went wrong, try again or contact support.')
        captureException(toSentry(error), {
          contexts: {
            handleRestoreAction: {
              orgId,
              eventIndividualId,
              currentAction,
              excluded: false,
              actionType: ActionType_Enum.createProgram,
            },
          },
        })
      })
  }

  if (isExcluded || isDisabled) {
    return (
      <EventActionDisabled
        eventInfo={eventInfo}
        disabled={isDisabled}
        currentAction={currentAction as CustomAction}
        wasBasicAction={wasBasicAction}
      />
    )
  }

  return (
    <>
      {/** Second hidden Drawer to preview email */}
      <Drawer
        title="Email Preview"
        width="auto"
        closable={false}
        onClose={() => setChildrenDrawer(false)}
        open={childrenDrawer}
      >
        <EmailPreview
          banner={image}
          title={title}
          programNote={message}
          fromName={from}
          isGift={!isUndefined(gift)}
          amount={amount}
        />
      </Drawer>

      {/** Render all the content for the current action */}
      <AnimatePresence mode="wait">
        <motion.div
          key={currentAction?.ruleId}
          initial={{
            scale: isCustomAction ? 0.1 : 1,
            opacity: isCustomAction ? 1 : 0,
          }}
          animate={{
            scale: isCustomAction ? 1 : 1,
            opacity: 1,
          }}
          exit={{
            scale: isCustomAction ? 0.1 : 1,
            opacity: isCustomAction ? 1 : 0,
          }}
          transition={{ duration: 0.2 }}
        >
          {/** Display the "personlized" ribbon if current action is customized */}
          {isCustomAction && (
            <Badge.Ribbon
              text={
                <Pane
                  paddingX={2}
                  marginTop={1}
                  display="flex"
                  alignItems="center"
                  gap={6}
                  onMouseEnter={() => setIsHoveringRibbon(true)}
                  onMouseLeave={() => setIsHoveringRibbon(false)}
                >
                  <Text color="white">Personalized</Text>

                  {/** Render gift icon if admin isn't hovering on the ribbon */}
                  {!isHoveringRibbon && (
                    <AnimatePresence exitBeforeEnter>
                      <motion.div
                        style={{ display: 'flex' }}
                        key={currentAction?.ruleId}
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.3 }}
                      >
                        <GiftOutlined />
                      </motion.div>
                    </AnimatePresence>
                  )}

                  {/** Render close icon if admin is hovering on the ribbon */}
                  {isHoveringRibbon && (
                    <AnimatePresence exitBeforeEnter>
                      <motion.div
                        style={{ display: 'flex' }}
                        key={currentAction?.ruleId}
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.3 }}
                      >
                        <Tooltip
                          title="Reset"
                          mouseEnterDelay={0.01}
                          mouseLeaveDelay={0.01}
                        >
                          <CloseCircleOutlined onClick={handleRestoreAction} />
                        </Tooltip>
                      </motion.div>
                    </AnimatePresence>
                  )}
                </Pane>
              }
              color={defaultColor}
              placement="start"
              style={{ zIndex: '1' }}
            />
          )}

          {/** Display editable reward information */}
          <Pane display="flex" flexDirection="column" gap={8} paddingRight={48}>
            {/** Reward image */}
            <Pane id={rewardRef} marginBottom={8}>
              <EditableImageCell
                docRef={docRef}
                columnTitle="Image"
                currentValue={image}
                isInDrawer
                onSubmit={handleCreateCustomAction}
              />
            </Pane>

            <Pane display="flex" alignItems="center" gap={16}>
              {/** Reward amount */}
              <EditableAmountCell
                docRef={docRef}
                currentValue={amount}
                extraStyles={{
                  fontFamily: 'Poppins',
                  padding: '0',
                  fontSize: '20px',
                  fontWeight: '600',
                  color: NUMBER_GREEN,
                  border: '1px solid transparent',
                }}
                currentGift={gift}
                handleCreateCustomAction={handleCreateCustomAction}
              />

              <Tooltip title={gift?.title}>
                <Image
                  preview={false}
                  src={gift?.imageUrl}
                  width={40}
                  style={{
                    borderRadius: 4,
                    objectFit: 'cover',
                    aspectRatio: '1:1',
                  }}
                />
              </Tooltip>
            </Pane>

            {/** Reward title */}
            <EditableTextInput
              docRef={docRef}
              fieldKey="title"
              columnTitle="Title"
              currentValue={title}
              extraStyles={{
                fontFamily: 'Poppins',
                padding: '0',
                fontSize: '20px',
                fontWeight: '600',
                border: '1px solid transparent',
              }}
              handleCreateCustomAction={handleCreateCustomAction}
            />

            {/** Reward message */}
            <EditableTextInput
              docRef={docRef}
              fieldKey="message"
              columnTitle="Message"
              currentValue={message}
              extraStyles={{
                padding: '0',
                fontSize: '14px',
                fontColor: '#474d66',
                border: '1px solid transparent',
              }}
              isTextArea
              handleCreateCustomAction={handleCreateCustomAction}
            />

            {/** Reward sender */}
            <EditableTextInput
              docRef={docRef}
              fieldKey="from"
              columnTitle="From"
              currentValue={from}
              extraStyles={{
                padding: '0',
                fontColor: '#474d66',
                fontWeight: '500',
                border: '1px solid transparent',
              }}
              handleCreateCustomAction={handleCreateCustomAction}
            />

            {/** Button section */}
            <Pane display="flex" alignItems="center" gap={8} marginTop={8}>
              <Button onClick={() => setChildrenDrawer(true)}>
                Preview Email
              </Button>
              <Button danger type="text" onClick={handleSetExcluded}>
                Exclude
              </Button>
            </Pane>
          </Pane>
        </motion.div>
      </AnimatePresence>
    </>
  )
}
