import { SendOutlined } from '@ant-design/icons'
import { Button, Flex, Image } from 'antd'
import { paperAirplaneAsset } from 'assets'
import { DEFAULT_ROUTES } from 'constants/routes'
import { IndividualContext } from 'context'
import { Strong, Text } from 'evergreen-ui'
import { useContext } from 'react'
import { useNavigate } from 'react-router'

export function SendAProgram({
  hasSentRewards = false,
  isLandscape = false,
  hideCTA = false,
}: {
  hasSentRewards?: boolean
  isLandscape?: boolean
  hideCTA?: boolean
}) {
  const navigate = useNavigate()
  const individual = useContext(IndividualContext)
  const firstName = individual?.firstName

  const headerCopy =
    !hasSentRewards && firstName
      ? `Send your first reward, ${firstName}`
      : 'Send a reward'

  const textCopy = "Brighten someone's day with a reward they'll love"

  return (
    <Flex
      vertical={!isLandscape}
      justify={isLandscape ? 'space-between' : undefined}
      align="center"
      gap={16}
      style={{ paddingTop: 8, paddingBottom: 8 }}
    >
      <Flex
        gap={16}
        vertical={!isLandscape}
        align={!isLandscape ? 'center' : undefined}
      >
        <Image
          src={paperAirplaneAsset}
          style={{ width: 44, height: 44 }}
          preview={false}
        />
        <Flex
          vertical
          gap={isLandscape ? 8 : 16}
          align={!isLandscape ? 'center' : undefined}
        >
          <Strong>{headerCopy}</Strong>
          <Text>{textCopy}</Text>
        </Flex>
      </Flex>
      {!hideCTA && (
        <Button
          onClick={() =>
            navigate(DEFAULT_ROUTES.ORGANIZATION.REWARDS.NEW_REWARD)
          }
          icon={<SendOutlined />}
          type={isLandscape ? 'default' : 'primary'}
        >
          Send reward
        </Button>
      )}
    </Flex>
  )
}
