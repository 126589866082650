import { ComponentProps, useState } from 'react'
import { ConditionalSkeletonBtn } from './conditional-skeleton-button'

export function AsyncButton({
  onClick,
  disabled,
  ...props
}: Omit<
  ComponentProps<typeof ConditionalSkeletonBtn>,
  'loading' | 'onClick'
> & {
  onClick: React.MouseEventHandler<HTMLButtonElement> | (() => Promise<void>)
}) {
  const [loading, setLoading] = useState(false)

  const handleClick: React.MouseEventHandler<HTMLButtonElement> = async e => {
    setLoading(true)
    try {
      await onClick(e)
    } finally {
      setLoading(false)
    }
  }

  return (
    <ConditionalSkeletonBtn
      onClick={handleClick}
      disabled={disabled || loading}
      loading={loading}
      {...props}
    />
  )
}
