import { uniqBy } from 'lodash-es'
import { useState } from 'react'
import {
  UseNumericMenuProps,
  useClearRefinements,
  useNumericMenu,
} from 'react-instantsearch'
import { numToDollars } from 'utils'
import { insertElementIf } from 'utils/arrays'

const staticBudgetOptions = () => [
  { label: numToDollars(25_00, 0) || '', end: 2500 },
  { label: numToDollars(30_00, 0) || '', start: 15_00, end: 30_00 },
  { label: numToDollars(40_00, 0) || '', start: 20_00, end: 40_00 },
  { label: numToDollars(50_00, 0) || '', start: 25_00, end: 50_00 },
  { label: numToDollars(60_00, 0) || '', start: 45_00, end: 60_00 },
  { label: numToDollars(75_00, 0) || '', start: 50_00, end: 75_00 },
  { label: numToDollars(80_00, 0) || '', start: 60_00, end: 80_00 },
  { label: numToDollars(90_00, 0) || '', start: 70_00, end: 90_00 },
  { label: numToDollars(100_00, 0) || '', start: 75_00, end: 100_00 },
  { label: numToDollars(150_00, 0) || '', start: 100_00, end: 150_00 },
  { label: numToDollars(200_00, 0) || '', start: 150_00, end: 200_00 },
  { label: numToDollars(250_00, 0) || '', start: 500_00, end: 200_00 },
]

export function useAlgoliaBudgetOptions({
  defaultSelectedBudget,
}: {
  defaultSelectedBudget?: UseNumericMenuProps['items'][0]
}) {
  const [budgetOptions, setBudgetOptions] = useState<
    UseNumericMenuProps['items']
  >(() =>
    uniqBy(
      [
        ...insertElementIf(!!defaultSelectedBudget, defaultSelectedBudget),
        ...staticBudgetOptions(),
      ],
      'end'
    )
  )

  const { refine: clearBudgetRefinements } = useClearRefinements({
    includedAttributes: ['amount'],
  })

  const { items: algoliaBudgets, refine: refineBudgets } = useNumericMenu({
    attribute: 'amount',
    items: budgetOptions,
  })

  const activeAlgoliaBudget = algoliaBudgets.find(budget => budget.isRefined)

  return {
    budgetOptions,
    activeAlgoliaBudget,
    refineBudgets,
    setBudgetOptions,
    clearBudgetRefinements,
    algoliaBudgets,
  }
}
