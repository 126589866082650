import {
  AMAZON_CONDITIONS_OF_USE,
  AMAZON_PRIVACY_POLICY,
} from 'constants/amazon'
import { Link, Paragraph } from 'evergreen-ui'

export function AmazonTerms() {
  return (
    <Paragraph size={300}>
      By placing your order, you agree to Amazon&apos;s{' '}
      <Link
        size={300}
        href={AMAZON_PRIVACY_POLICY}
        target="_blank"
        rel="noopener noreferrer"
      >
        privacy notice
      </Link>{' '}
      and{' '}
      <Link size={300} href={AMAZON_CONDITIONS_OF_USE} target="_blank">
        condition of use
      </Link>
    </Paragraph>
  )
}
