import { captureException } from '@sentry/react'
import { USD } from 'constants/currencies'
import { doc, getDoc, getFirestore } from 'firebase/firestore'
import { ExchangeRate } from 'gen/perkup/v1/rates_pb'
import { converter } from 'utils/firestore'
import { toSentry } from 'utils/sentry'

export async function GetExchangeRate({
  fromCurrency = USD,
  toCurrency,
}: {
  fromCurrency: string
  toCurrency?: string
}) {
  let exchangeRate = 1
  if (!toCurrency) return exchangeRate
  try {
    const db = getFirestore()
    const docRef = doc(
      db,
      `exchangeRates/${fromCurrency.toLowerCase()}`
    ).withConverter(converter(ExchangeRate))
    await getDoc(docRef).then(doc => {
      if (!doc.exists) return

      const exchangeRates = doc.data()
      if (exchangeRates?.rates) {
        exchangeRate = exchangeRates.rates[toCurrency.toLowerCase()]
        if (!exchangeRate) exchangeRate = 1
      }
    })
  } catch (error) {
    console.error(error)
    captureException(toSentry(error), {
      contexts: {
        GetExchangeRate: { fromCurrency, toCurrency },
      },
    })
  }
  return exchangeRate
}

export async function ListExchangeRates() {
  try {
    const db = getFirestore()
    const docRef = doc(db, `exchangeRates/usd`).withConverter(
      converter(ExchangeRate)
    )
    return await getDoc(docRef).then(doc => doc.data())
  } catch (error) {
    console.error(error)
    captureException(toSentry(error), {
      contexts: {
        ListExchangeRates: {},
      },
    })
  }
}
