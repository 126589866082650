export const AMAZON_PRICE = '#B12704'
export const AMAZON_THUMBNAIL_ORANGE_BORDER = '#e77600'
export const NUMBER_GREEN = '#52BD95'
export const NUMBER_RED = '#D14343'
export const CALENDAR_DATE_ORANGE = '#F66800'
export const CALENDAR_DATE_BROWN = '#A05600'
export const PERKUP_PRIMARY_COLOR = '#5C33FF'
export const PERKUP_ACCENT_COLOR = '#FF5C35'
export const ANT_DESIGN_SUCCESS = '#52c41a'
export const ANT_DESIGN_PURPLE = '#541cab'
