import { useEffect, useState } from 'react'

export function useScrollObserver({
  hideThreshold = 0,
}: {
  hideThreshold?: number
}) {
  const [show, setShow] = useState(true)
  const [hasReachedBottom, setHasReachedBottom] = useState(false)

  useEffect(() => {
    let previousScrollPos = 0
    let currentScrollPos = 0

    window.addEventListener('scroll', () => {
      currentScrollPos = window.scrollY

      const scrollPosNotFarEnough = currentScrollPos < hideThreshold

      if (currentScrollPos + window.innerHeight >= document.body.scrollHeight) {
        setHasReachedBottom(true)
      } else setHasReachedBottom(false)

      if (scrollPosNotFarEnough) setShow(true)
      else if (previousScrollPos - currentScrollPos < 0) setShow(false)
      else if (previousScrollPos - currentScrollPos > 10) setShow(true)

      // Update the previous value
      previousScrollPos = currentScrollPos
    })
  }, [hideThreshold])

  return { show, hasReachedBottom }
}
