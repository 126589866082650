import { CheckOutlined, SettingOutlined } from '@ant-design/icons'
import { captureException } from '@sentry/react'
import {
  Alert,
  Button,
  Checkbox,
  CheckboxProps,
  Divider,
  Flex,
  GetProp,
  Switch,
  Tag,
} from 'antd'
import { updateIntegrationFinchSyncSettings } from 'api/databaseCalls'
import { callFunction } from 'api/functionCalls'
import { IntegrationLogo } from 'components'
import { Dialog, Heading, Pane, Paragraph, Text, toaster } from 'evergreen-ui'
import { Integration } from 'gen/perkup/v1/integration_pb'
import {
  Organization_SubEmployment,
  Organization_SyncSettings,
} from 'gen/perkup/v1/organization_pb'
import { useFinchConnectOpen } from 'hooks/useFinchConnectOpen'
import useIds from 'hooks/useIds'
import { isUndefined, startCase } from 'lodash-es'
import { useState } from 'react'
import { integrationNeedsReauthentication, toSentry } from 'utils'
import FinchButton from './FinchButton'

type FieldKeys = keyof Organization_SyncSettings

interface FieldOption {
  label: string
  fieldKey: FieldKeys
}

const fieldOptions: FieldOption[] = [
  { label: 'Email', fieldKey: 'email' },
  { label: 'Start date (work anniversary)', fieldKey: 'startDate' },
  { label: 'Birthday', fieldKey: 'dob' },
  { label: 'Manager', fieldKey: 'manager' },
]

function FieldOptionSwitch(data: {
  fieldOption: FieldOption
  integration: Integration
}) {
  const { fieldOption, integration } = data
  const { label, fieldKey } = fieldOption

  const toggleHRISSetting = (checked: boolean) => {
    const finchSyncSettings = new Organization_SyncSettings({
      ...integration.finchSyncSettings,
      [fieldKey]: checked,
    })
    updateIntegrationFinchSyncSettings({
      integrationId: integration.id,
      finchSyncSettings,
    })
  }

  const settingValue =
    integration.finchSyncSettings && integration.finchSyncSettings[fieldKey]

  return (
    <Pane>
      <Checkbox
        checked={!!settingValue}
        onChange={e => toggleHRISSetting(e.target.checked)}
      >
        {label}
      </Checkbox>
    </Pane>
  )
}

type CheckboxValueType = GetProp<typeof Checkbox.Group, 'value'>[number]

const subEmploymentOptions = Organization_SubEmployment.fields
  .list()
  .map(field => ({
    label: startCase(field?.jsonName),
    value: field?.jsonName,
  }))

function EmploymentTypeSettings({
  primaryKey,
  integration,
}: {
  primaryKey: string
  integration: Integration
}) {
  const integrationId = integration.id
  const syncEmployment = integration.finchSyncSettings?.syncEmployment
  const employmentSettings: Organization_SubEmployment | undefined =
    syncEmployment ? syncEmployment[primaryKey] : undefined

  const hasEmploymentSettings = Object.keys(employmentSettings || {}).length > 0

  const trueValues: string[] = Object.entries(employmentSettings || {})
    .filter(([, value]) => value)
    .map(([key]) => key)

  const defaultValues = hasEmploymentSettings
    ? trueValues
    : subEmploymentOptions.map(opt => opt.value)

  const [checkedValues, setCheckedValues] =
    useState<CheckboxValueType[]>(defaultValues)

  const checkAll = subEmploymentOptions.length === checkedValues.length
  const indeterminate =
    checkedValues.length > 0 &&
    checkedValues.length < subEmploymentOptions.length

  const onChange = (newCheckedValues: CheckboxValueType[]) => {
    setCheckedValues(newCheckedValues)
    const finchSyncSettings = new Organization_SyncSettings({
      ...integration.finchSyncSettings,
      syncEmployment: {
        [primaryKey]: subEmploymentOptions.reduce(
          (acc, opt) => {
            acc[opt.value] = newCheckedValues.includes(opt.value)
            return acc
          },
          {} as Record<string, boolean>
        ),
      },
    })
    updateIntegrationFinchSyncSettings({
      integrationId,
      finchSyncSettings,
    })
  }

  const onCheckAllChange: CheckboxProps['onChange'] = e => {
    let valuesToSet = subEmploymentOptions.map(opt => opt.value)
    let finchSyncSettings = new Organization_SyncSettings({
      ...integration.finchSyncSettings,
      syncEmployment: {
        [primaryKey]: subEmploymentOptions.reduce(
          (acc, opt) => {
            acc[opt.value] = true // Set each option value to true in the accumulator object
            return acc
          },
          {} as Record<string, boolean>
        ),
      },
    })

    const uncheckAll = !e.target.checked

    if (uncheckAll) {
      valuesToSet = []
      finchSyncSettings = new Organization_SyncSettings({
        ...integration.finchSyncSettings,
        syncEmployment: {
          [primaryKey]: subEmploymentOptions.reduce(
            (acc, opt) => {
              acc[opt.value] = false
              return acc
            },
            {} as Record<string, boolean>
          ),
        },
      })
    }

    setCheckedValues(valuesToSet)
    updateIntegrationFinchSyncSettings({
      integrationId,
      finchSyncSettings,
    })
  }
  return (
    <Pane border="muted" background="tint2" borderRadius={8} padding={16}>
      <Heading marginBottom={16}>Sync {startCase(primaryKey)}s</Heading>
      <Flex vertical>
        <Checkbox
          indeterminate={indeterminate}
          onChange={onCheckAllChange}
          checked={checkAll}
        >
          {startCase(primaryKey)}s
        </Checkbox>
        <Divider />
        <Checkbox.Group
          options={subEmploymentOptions}
          value={checkedValues}
          onChange={onChange}
        />
      </Flex>
    </Pane>
  )
}

function ConnectedIntegration({ integration }: { integration: Integration }) {
  const { orgId } = useIds()
  const integrationId = integration.id
  const [showDisconnect, setShowDisconnect] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [showSettings, setShowSettings] = useState(false)

  const [isSyncing, setSyncing] = useState(false)

  const handleRefresh = () => {
    setSyncing(true)
    callFunction('firestore-WriteIndividuals', { orgId })
      .then(() => toaster.success('Successfully refreshed people directory'))
      .catch(error => {
        captureException(toSentry(error), {
          contexts: {
            FirestoreWriteIndividuals: { orgId },
          },
        })
        console.error(error)
        toaster.warning(
          'Internal error refreshing directory. Please contact PerkUp support.'
        )
      })
      .finally(() => {
        setSyncing(false)
      })
  }

  const handleDisconnect = () => {
    setIsLoading(true)
    callFunction('finch-DisconnectFinch', {
      integrationId,
    })
      .then(({ status }) => {
        if (status === 'success') {
          toaster.warning('Disconnected integration')
        } else {
          toaster.danger('Something went wrong. Please contact PerkUp support.')
        }
      })
      .catch(() => {
        toaster.danger('Something went wrong. Please contact PerkUp support.')
      })
      .finally(() => {
        setIsLoading(false)
        setShowDisconnect(false)
      })
  }

  const { open, contextHolder } = useFinchConnectOpen()

  if (!integration) return null

  const syncDob = isUndefined(integration.finchSyncSettings?.syncDob)
    ? true
    : integration.finchSyncSettings?.syncDob

  const syncPersonalEmails = integration.finchSyncSettings?.syncPersonalEmails

  const toggleSyncDob = (checked: boolean) => {
    const finchSyncSettings = new Organization_SyncSettings({
      ...integration.finchSyncSettings,
      syncDob: checked,
    })

    updateIntegrationFinchSyncSettings({
      integrationId,
      finchSyncSettings,
    })
  }

  const toggleSyncPersonalEmails = (checked: boolean) => {
    const finchSyncSettings = new Organization_SyncSettings({
      ...integration.finchSyncSettings,
      syncPersonalEmails: checked,
    })

    updateIntegrationFinchSyncSettings({
      integrationId,
      finchSyncSettings,
    })
  }

  const reauthenticate = integrationNeedsReauthentication(integration)

  return (
    <>
      {contextHolder}
      {reauthenticate && (
        <Alert
          message={`Please reauthenticate ${integration.provider} connection`}
          showIcon
          type="warning"
          style={{
            maxWidth: 712,
            marginBottom: 16,
          }}
          description={
            <Pane marginTop={8}>
              <Paragraph color="muted">
                The credentials for your {integration.provider} are no longer
                valid.
              </Paragraph>

              <Pane marginTop={16} marginBottom={8}>
                <FinchButton
                  cta="Reauthenticate"
                  isButton
                  buttonProps={{ type: 'primary' }}
                  provider={integration.provider}
                  onClick={() => open()}
                />
              </Pane>
            </Pane>
          }
        />
      )}
      <Pane
        border
        borderRadius={8}
        paddingX={36}
        paddingY={24}
        minWidth={312}
        maxWidth={712}
      >
        <Pane display="flex" alignItems="center" gap={32}>
          <IntegrationLogo payroll_provider_id={integration.provider} />
          {reauthenticate ? (
            <Tag color="gold">Reauthenticate</Tag>
          ) : (
            <Tag color="green">Connected</Tag>
          )}
          <Text color="muted">{integration.externalOrgName}</Text>
          <Pane display="flex" gap={16} marginLeft="auto">
            <Button type="text" onClick={handleRefresh} loading={isSyncing}>
              Sync now
            </Button>

            <Button
              icon={<SettingOutlined />}
              type={showSettings ? 'default' : 'primary'}
              onClick={() => setShowSettings(prev => !prev)}
            >
              Settings
            </Button>
          </Pane>
        </Pane>
        {showSettings && (
          <Pane borderTop="muted" paddingTop={24} marginTop={16}>
            <Flex vertical gap={32}>
              <EmploymentTypeSettings
                primaryKey="employee"
                integration={integration}
              />
              <EmploymentTypeSettings
                primaryKey="contractor"
                integration={integration}
              />
              <Flex gap={16}>
                <Switch
                  checkedChildren={<CheckOutlined />}
                  checked={syncDob}
                  onChange={toggleSyncDob}
                />
                <Heading>Import birthdays into PerkUp</Heading>
              </Flex>
              <Flex gap={16}>
                <Switch
                  checkedChildren={<CheckOutlined />}
                  checked={syncPersonalEmails}
                  onChange={toggleSyncPersonalEmails}
                />
                <Heading>Import personal emails into PerkUp</Heading>
              </Flex>
              <Pane>
                <Heading>Override people data</Heading>
                <Text color="muted">
                  Every 24 hours, updates from {integration.provider} will
                  override the information in PerkUp.
                </Text>
                <Pane marginTop={16}>
                  <Pane
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-start"
                    gap={4}
                  >
                    {fieldOptions.map(fieldOption => (
                      <FieldOptionSwitch
                        key={fieldOption.fieldKey}
                        fieldOption={fieldOption}
                        integration={integration}
                      />
                    ))}
                  </Pane>
                </Pane>
              </Pane>

              <Pane>
                <Button danger onClick={() => setShowDisconnect(true)}>
                  Disconnect
                </Button>
              </Pane>
            </Flex>
          </Pane>
        )}

        <Dialog
          title="Disconnect integration"
          isShown={showDisconnect}
          onCloseComplete={() => setShowDisconnect(false)}
          onConfirm={handleDisconnect}
          confirmLabel="Disconnect"
          intent="danger"
          isConfirmLoading={isLoading}
        >
          Are you sure you&lsquo;d like to disconnect {integration.provider}?
        </Dialog>
      </Pane>
    </>
  )
}

export default ConnectedIntegration
