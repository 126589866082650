import { PlusCircleOutlined, PlusOutlined } from '@ant-design/icons'
import { Button, ButtonProps, Dropdown, Flex, Grid, message, Modal } from 'antd'
import InputNumber from 'antd/es/input-number'
import { ItemType } from 'antd/es/menu/interface'
import { PerkIconTooltipButton, PerkScrollbars } from 'components'
import { PersonalFundsPayment } from 'components/Stripe'
import { USD } from 'constants/currencies'
import { DEFAULT_PERSONAL_FUNDS_DEPOSIT } from 'constants/money'
import { defaultBudgets } from 'constants/rewards'
import { Heading, Link, Pane, Tab, Tablist, Text } from 'evergreen-ui'
import { useDisplayCurrency, useOrgUserBalances } from 'hooks'
import { isNaN } from 'lodash-es'
import { useEffect, useMemo, useState } from 'react'

const { useBreakpoint } = Grid

function PersonalFundsDisclaimer() {
  const displayCurrency = useDisplayCurrency()

  return (
    <Pane display="flex" flexDirection="column" gap={8}>
      <Text color="muted">
        Personal funds allow you to top up your perk budget with your own money.
        These funds do not expire and can be refunded at any time.
      </Text>
      <Text color="muted">
        For more details, refer to our guide on{' '}
        <Link
          href="https://help.perkupapp.com/articles/287235-how-to-add-personal-funds-to-your-perkup-balance"
          target="_blank"
          rel="noopener noreferrer"
        >
          How to add personal funds
        </Link>
        .
      </Text>

      {displayCurrency?.toLowerCase() !== USD && (
        <Text color="muted">
          Note: You will be charged the selected amount in USD.
        </Text>
      )}
    </Pane>
  )
}

function PredefinedAmounts({
  startingAmount,
  onAmountChange,
}: {
  startingAmount: number
  onAmountChange: (newBudget: number) => void
}) {
  const screens = useBreakpoint()

  const [customBudget, setCustomBudget] = useState<number | undefined>(
    !defaultBudgets.includes(startingAmount) ? startingAmount : undefined
  )

  const handleCustomBudgetChange = (newBudget: number | null) => {
    // Ant design sets value to null if the input field was cleared or invalid
    if (!newBudget) {
      onAmountChange(defaultBudgets[0])
    } else {
      setCustomBudget(newBudget)
      onAmountChange(newBudget)
    }
  }

  return (
    <Flex vertical={screens.xs} gap={16} align="center">
      <Tablist display="flex" alignItems="center" width="100%">
        {defaultBudgets.map(num => (
          <Tab
            flex={1}
            key={num}
            id={num.toString()}
            onSelect={() => {
              onAmountChange(Math.round(num))
              setCustomBudget(undefined)
            }}
            isSelected={num === startingAmount}
            whiteSpace="nowrap"
            size={500}
            paddingX={screens.xs ? 8 : 16}
            paddingY={20}
          >
            $ {num / 100}
          </Tab>
        ))}
      </Tablist>
      <InputNumber
        size="large"
        addonBefore="$"
        style={{ width: '100%' }}
        placeholder="Custom"
        value={customBudget ? customBudget / 100 : undefined}
        min={1}
        onBlur={e => {
          const newBudget = Number(e.target.value)
          if (!newBudget || isNaN(newBudget)) {
            setCustomBudget(undefined)
            return
          }
          // Don't allow more than two decimals places in the custom budget field
          const roundedAmount = Math.round(newBudget * 100)

          handleCustomBudgetChange(roundedAmount)
        }}
      />
    </Flex>
  )
}

export function AddPersonalFundsButton({
  defaultAmount = DEFAULT_PERSONAL_FUNDS_DEPOSIT,
  asIcon = false,
  oneClickCheckout = false,
  ctaProps,
  asDropdown = false,
  dropdownItems = [],
  onSubmit,
}: {
  defaultAmount?: number
  asIcon?: boolean
  oneClickCheckout?: boolean
  ctaProps?: ButtonProps
  asDropdown?: boolean
  dropdownItems?: ItemType[]
  onSubmit?: () => void
}) {
  const screens = useBreakpoint()

  const { orgUserPersonalBalance } = useOrgUserBalances()

  const [isShown, setIsShown] = useState(false)
  const [amount, setAmount] = useState(defaultAmount)
  const [isAddingFunds, setIsAddingFunds] = useState(false)

  const [messageApi, contextHolder] = message.useMessage()
  const [trackedPFBalance, setTrackedPFBalance] = useState(
    orgUserPersonalBalance || 0
  )

  useEffect(() => {
    if ((orgUserPersonalBalance || 0) > trackedPFBalance) {
      messageApi.open({
        key: 'balanceToast',
        type: 'success',
        content: 'Funds added successfully',
      })
    }
    setTrackedPFBalance(orgUserPersonalBalance)
    setIsAddingFunds(false)
  }, [messageApi, orgUserPersonalBalance, trackedPFBalance])

  const customerCreationIdempotencyKey = useMemo(() => {
    // This is used to ensure that stripe always returns us the same customer response when we create a customer. If we leave and come back we get a different key. Stable for page.
    return crypto.randomUUID()
  }, [])

  return (
    <>
      {contextHolder}

      {asIcon && (
        <PerkIconTooltipButton
          title="Add personal funds"
          onClick={() => setIsShown(true)}
          type="text"
          size="small"
          icon={<PlusCircleOutlined />}
          loading={isAddingFunds}
          disabled={isAddingFunds}
          {...ctaProps}
        />
      )}

      {asDropdown && (
        <Dropdown
          menu={{
            items: [
              ...dropdownItems,
              {
                key: 'pf-button',
                label: (
                  <Button
                    icon={<PlusCircleOutlined />}
                    style={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'start',
                    }}
                    type="text"
                    onClick={() => setIsShown(true)}
                  >
                    Add additional personal funds
                  </Button>
                ),
                style: { padding: 0 },
              },
            ],
          }}
          overlayStyle={{ padding: 0 }}
          disabled={isAddingFunds}
        >
          <Button icon={<PlusOutlined />} loading={isAddingFunds}>
            Add funds
          </Button>
        </Dropdown>
      )}

      {!asIcon && !asDropdown && (
        <Button
          onClick={() => setIsShown(true)}
          loading={isAddingFunds}
          disabled={isAddingFunds}
          {...ctaProps}
        >
          Add personal funds
        </Button>
      )}

      <Modal
        open={isShown}
        title={
          <Heading style={{ padding: screens.xs ? '0 16px' : '0 24px' }}>
            Add Personal Funds
          </Heading>
        }
        onClose={() => setIsShown(false)}
        okText="Checkout"
        centered
        width={600}
        styles={{
          content: {
            padding: '16px 0 0 0',
          },
          body: {
            padding: 0,
          },
        }}
        footer={null}
        destroyOnClose
        onCancel={() => setIsShown(false)}
      >
        <Flex vertical gap={16}>
          {!oneClickCheckout && (
            <div style={{ padding: screens.xs ? '0 16px' : '0 24px' }}>
              <PredefinedAmounts
                startingAmount={amount}
                onAmountChange={setAmount}
              />
            </div>
          )}
          <PerkScrollbars
            style={{
              maxHeight: 624,
              padding: screens.xs ? '0 16px' : '0 24px',
            }}
            options={{ overflow: { x: 'hidden' } }}
          >
            <PersonalFundsPayment
              key={amount}
              amount={amount}
              onComplete={() => {
                setIsShown(false)
                setIsAddingFunds(true)
                if (onSubmit) onSubmit()
                messageApi.open({
                  key: 'balanceToast',
                  type: 'loading',
                  content: `We're updating your balance, this might take take a moment. Please stay on this page.`,
                  duration: 0,
                })
              }}
              customerCreationIdempotencyKey={customerCreationIdempotencyKey}
            />
            <div style={{ padding: '8px 0 16px 0' }}>
              <PersonalFundsDisclaimer />
            </div>
          </PerkScrollbars>
        </Flex>
      </Modal>
    </>
  )
}
