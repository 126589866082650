import { ListDraftProgramsByOwnerId } from 'api/databaseCalls'
import { Program } from 'gen/perkup/v1/program_pb'
import useIds from 'hooks/useIds'
import { useEffect, useState } from 'react'

export function useDraftProgramsByOwnerId() {
  const { orgId, userId } = useIds()
  const [draftPrograms, setDraftPrograms] = useState<Program[]>([])
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    setIsLoading(true)
    ListDraftProgramsByOwnerId({ orgId, userId })
      .then(res => setDraftPrograms(res))
      .finally(() => setIsLoading(false))
  }, [orgId, userId])

  return { draftPrograms, isLoading }
}
