import { PRIVACY_URL, TERMS_OF_SERVICE_URL } from 'constants/perkupLinks'
import { Link, Paragraph } from 'evergreen-ui'

export function CheckoutTerms({ companyName }: { companyName: string }) {
  return (
    <Paragraph size={400}>
      By placing your order, you agree to {companyName}&apos;s{' '}
      <Link size={400} href={PRIVACY_URL} target="_blank">
        privacy policy
      </Link>{' '}
      and{' '}
      <Link size={400} href={TERMS_OF_SERVICE_URL} target="_blank">
        conditions of use
      </Link>
      .
    </Paragraph>
  )
}
