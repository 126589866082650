import { ListenToOrgAccountById } from 'api/databaseCalls'
import { OrgContext } from 'context'
import { Account } from 'gen/perkup/v1/account_pb'
import { useContext, useEffect, useState } from 'react'

export function useAccount({ accountId }: { accountId?: string }) {
  const org = useContext(OrgContext)
  const orgId = org.id
  const [account, setAccount] = useState<Account>()
  const [hasLoaded, setHasLoaded] = useState(false)

  useEffect(() => {
    if (!accountId) {
      setHasLoaded(true)
      return undefined
    }
    return ListenToOrgAccountById({
      orgId,
      accountId,
      cb: account => {
        setAccount(account)
        setHasLoaded(true)
      },
    })
  }, [accountId, orgId])

  return { account, hasLoaded }
}
