import { captureException, captureMessage } from '@sentry/react'
import { AMAZON_BUSINESS_STORE, ORDER_COMPLETED } from 'constants/events'
import { toaster } from 'evergreen-ui'
import { getAuth } from 'firebase/auth'
import { Order } from 'gen/amazon/fulfill_connect'
import {
  PlaceOrderRequest,
  PlaceOrderRequest_Item,
} from 'gen/amazon/fulfill_pb'
import { Search } from 'gen/amazon/search_connect'
import { GetProductRequest, GetProductResponse } from 'gen/amazon/search_pb'
import { PostalAddress } from 'gen/google/type/postal_address_pb'
import { ShippingAddress } from 'gen/perkup/v1/root_user_pb'
import { createClient } from 'hooks/useClient'
import toUpper from 'lodash-es/toUpper'
import { AmazonOffer, AmazonProduct } from 'types'
import { getAmazonDisplayPrices, logEvent, parsePhoneNumber } from 'utils'
import { toSentry } from 'utils/sentry'

export async function placeAmazonOrder(data: {
  addrOrganization: string
  shippingAddress: ShippingAddress
  offer: AmazonOffer
  product: AmazonProduct
  quantity: number
  unitAmount: number
  orderTotal: number
  itemTotal: number
  estimatedTax: number
  imageUrl?: string
  programIds?: string[]
  orgId: string
  userId: string
}): Promise<boolean | void> {
  const {
    addrOrganization,
    shippingAddress,
    offer,
    product,
    imageUrl,
    quantity,
    unitAmount,
    orderTotal,
    itemTotal,
    estimatedTax,
    programIds,
    orgId,
    userId,
  } = data

  const { offerAmount, offerCurrency } = getAmazonDisplayPrices(offer)
  if (!offerAmount) {
    toaster.warning('Error processing order, please contact support')
    captureMessage('Offer amount not set', 'error')
    return false
  }

  const addr = new PostalAddress({
    organization: addrOrganization,
    locality: shippingAddress?.city,
    administrativeArea: shippingAddress?.state,
    regionCode: shippingAddress?.country,
    postalCode: shippingAddress?.postalCode,
  })

  addr.addressLines.push(shippingAddress?.line1)
  if (shippingAddress?.line2) {
    addr.addressLines.push(shippingAddress?.line2)
  }

  const item = new PlaceOrderRequest_Item({
    unitPrice: unitAmount,
    currencyCode: toUpper(offerCurrency),
    offerId: offer?.offerId,
    supplierPartId: product.asin,
    quantity,
    title: product.title,
  })

  const order = new PlaceOrderRequest({
    postalAddress: addr,
    item,
    total: Math.round(orderTotal),
  })

  if (programIds?.length) {
    order.programIds = programIds
  }

  if (shippingAddress?.phoneNumber) {
    const parsePhoneNum =
      parsePhoneNumber(shippingAddress.phoneNumber)?.format('RFC3966') ?? ''
    if (parsePhoneNum) {
      order.phoneNumber = parsePhoneNum
    }
  }

  try {
    const accessToken = await getAuth().currentUser?.getIdToken()
    await createClient(Order)
      .placeOrder(order, {
        headers: { Authorization: `Bearer ${accessToken}` },
      })
      .then(() => {
        logEvent(ORDER_COMPLETED, {
          affiliation: AMAZON_BUSINESS_STORE,
          subTotal: itemTotal,
          shipping: 0,
          tax: estimatedTax,
          total: orderTotal,
          currency: toUpper(offerCurrency),
          orgId,
          userId,
          products: [
            {
              name: product.title,
              sku: product.asin,
              quantity,
              deliveryInformation: offer.deliveryInformation,
              image_url: imageUrl,
            },
          ],
        })
      })
    return true
  } catch (error) {
    toaster.warning('Error processing order, please contact support')

    console.error(error)

    captureException(toSentry(error), {
      contexts: {
        placeAmazonOrder: {
          asin: product.asin,
          unitPrice: offerAmount?.toString(),
          order,
          quantity,
          error,
          orgId,
        },
      },
    })
    return false
  }
}

export async function getAmazonProductByAsin(data: {
  asin: string
  country: string
}) {
  const { asin, country } = data
  const countryCode = toUpper(country)

  const getProductReq = new GetProductRequest({ asin, countryCode })

  const accessToken = await getAuth().currentUser?.getIdToken()

  return createClient(Search)
    .getProduct(getProductReq, {
      headers: { Authorization: `Bearer ${accessToken}` },
    })
    .then((value: GetProductResponse) => {
      return JSON.parse(value.productResponse) as AmazonProduct
    })
    .catch((error: any) => {
      captureException(toSentry(error), {
        contexts: {
          getProduct: {
            asin,
            countryCode,
          },
        },
      })
      console.error(error)
    })
}

export async function getAmazonProduct({
  asin,
  postalCode,
  countryCode,
}: {
  asin: string | undefined
  postalCode: string | undefined
  countryCode: string | undefined
}) {
  const getProductReq = new GetProductRequest({
    asin,
    postalCode,
    countryCode,
  })

  const accessToken = await getAuth().currentUser?.getIdToken()

  return createClient(Search)
    .getProduct(getProductReq, {
      headers: { Authorization: `Bearer ${accessToken}` },
    })
    .then((value: GetProductResponse) => {
      const productResponse = JSON.parse(value.productResponse) as AmazonProduct

      return productResponse
    })
    .catch((error: any) => {
      console.error(error)
      captureException(toSentry(error), {
        contexts: {
          getProduct: {
            asin,
            postalCode,
          },
        },
      })

      return undefined
    })
}
