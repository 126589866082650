import { AppstoreOutlined, GiftOutlined } from '@ant-design/icons'
import { Button, Flex, Modal, Segmented } from 'antd'
import { ConvertableToOptions, ProductCount, SwitchAndText } from 'components'
import {
  ALGOLIA_PRODUCT_VARIANTS_INDEX,
  INITIAL_AMOUNT_FILTER,
} from 'constants/algolia'
import { DEFAULT_PROGRAM_BUDGET_AMOUNT } from 'constants/money'
import { GIFT_COLLECTION, GIFT_PRODUCTS } from 'constants/newReward'
import {
  FEATURED_COLLECTION_ID,
  OCCASION_COLLECTION_IDS,
} from 'constants/productCollections'
import { ProgramContext, TemplateContext } from 'context'
import { Heading } from 'evergreen-ui'
import {
  AlgoliaBrowseCollections,
  AlgoliaBrowseProducts,
  ProductDetails,
} from 'features'
import { ProductVariant } from 'gen/perkup/v1/product_variant_pb'
import { Program_Gift } from 'gen/perkup/v1/program_pb'
import { useProductVariantByGift } from 'hooks/productVariants/useProductVariantByGift'
import useIds from 'hooks/useIds'
import { compact } from 'lodash-es'
import { useContext, useState } from 'react'
import { UseNumericMenuProps } from 'react-instantsearch'
import { ConvertableTo } from 'types/Gift'
import {
  calculateMaxVariantPriceByProductIds,
  calculateShopifyProductVariantTotal,
  numToDollars,
} from 'utils'
import { getAlgoliaShippingCountriesFilter } from 'utils/Algolia'
import {
  constructUnifiedOptionsText,
  getProductVariantOptions,
  getUniqAndFormatWithSelectable,
} from 'utils/productVariant'
import {
  buildProgramGiftAsGenericProduct,
  buildProgramGiftAsSpecificProductVariant,
  buildProgramGiftFromProductCollection,
} from '../utils/program-gifts'
import { parseDraftData } from '../utils/programs'

export default function GiftForm({
  gift,
  budget,
  onGiftChange,
}: {
  gift: Program_Gift | undefined
  budget: number
  onGiftChange: ({
    gift,
    budget,
    advance,
  }: {
    gift?: Program_Gift
    budget?: number
    advance?: boolean
    changingConvertableTo?: boolean
  }) => void
}) {
  const program = useContext(ProgramContext)
  const template = useContext(TemplateContext)

  const draftData = parseDraftData({ program })

  const { orgId } = useIds()
  const [tempProductConvertableTo, setTempProductConvertableTo] = useState<
    string[]
  >([ConvertableTo.gift])
  const [productVariantToShow, setProductVariantToShow] =
    useState<ProductVariant>()

  const defaultOption =
    gift?.productCollectionId &&
    OCCASION_COLLECTION_IDS.includes(gift.productCollectionId)
      ? GIFT_COLLECTION
      : GIFT_PRODUCTS

  const [currentGiftOption, setCurrentGiftOption] = useState<string | number>(
    defaultOption
  )
  const [sendingSpecificVariant, setSendingSpecificVariant] = useState(
    !!gift?.productVariantId
  )
  const templateMaxAmount = template?.budget?.maxAmount

  const getDefaultPriceFilter = () => {
    if (templateMaxAmount) {
      return `0:${templateMaxAmount}`
    }
    if (draftData?.collectionAmount) {
      return `${draftData.collectionAmount - 2500}:${
        draftData?.collectionAmount
      }`
    }
    return INITIAL_AMOUNT_FILTER
  }

  const defaultPriceFilter = getDefaultPriceFilter()

  const { productVariants: selectedVariants } = useProductVariantByGift({
    gift,
  })

  const uniqueSelectedVariants = getUniqAndFormatWithSelectable(
    compact(selectedVariants)
  )
  const productHasOptions =
    Object.keys(productVariantToShow?.options || {}).length >= 1

  const handleSelectProductCollection = (
    productCollectionId: string,
    budget: number,
    advance?: boolean
  ) => {
    const giftToAdd = buildProgramGiftFromProductCollection(productCollectionId)

    onGiftChange({
      gift: giftToAdd,
      budget: templateMaxAmount || budget,
      advance,
    })
  }

  const handleSelectProductVariant = async (productVariant: ProductVariant) => {
    if (sendingSpecificVariant || !productHasOptions) {
      const giftToAdd = buildProgramGiftAsSpecificProductVariant({
        productVariant,
        convertableTo: gift?.convertableTo || tempProductConvertableTo, // keep the old options if they exist
      })
      const budget = calculateShopifyProductVariantTotal(productVariant)

      onGiftChange({
        gift: giftToAdd,
        budget,
        advance: true,
      })
    } else {
      const budget = await calculateMaxVariantPriceByProductIds({
        orgId,
        productIds: [productVariant.productId],
      })

      const giftToAdd = buildProgramGiftAsGenericProduct({
        productVariant,
        convertableTo: gift?.convertableTo || tempProductConvertableTo, // keep the old options if they exist,
      })

      onGiftChange({
        gift: giftToAdd,
        budget,
        advance: true,
      })
    }

    setProductVariantToShow(undefined)
  }

  const handleSetProductVariantInView = (productVariant: ProductVariant) => {
    setProductVariantToShow(productVariant)
    const variantIsSelected =
      !!selectedVariants?.some(av => av.id === productVariant.id) &&
      !!gift?.productVariantId
    setSendingSpecificVariant(variantIsSelected)
  }

  const handleSetGiftOption = (option: string | number) => {
    setCurrentGiftOption(option)

    // If they navigate to the recipient pick option and there is no gift selected, we should default to the first collection
    if (option === GIFT_COLLECTION && !gift?.productCollectionId) {
      const defaultCollectionGift = buildProgramGiftFromProductCollection(
        OCCASION_COLLECTION_IDS[0]
      )
      const defaultBudget = templateMaxAmount || DEFAULT_PROGRAM_BUDGET_AMOUNT
      onGiftChange({ gift: defaultCollectionGift, budget: defaultBudget })
    }
  }

  /** This is not ideal...
   * Incase they've already selected a gift, this is currently the only
   * spot in the form we can update the convertableTo options
   */
  const handleConvertableToChange = (convertableTo: string[]) => {
    if (!gift) setTempProductConvertableTo(convertableTo)
    else {
      const updatedGift = new Program_Gift({
        ...gift,
        convertableTo,
      })

      onGiftChange({
        gift: updatedGift,
        budget,
        advance: false,
        changingConvertableTo: true,
      })
    }
  }

  const tabToggleAndProductCount = (
    <Flex gap={16} align="center">
      <ProductCount />
      <Segmented
        style={{ width: 'fit-content' }}
        className="small-custom-segment"
        options={[
          {
            value: GIFT_PRODUCTS,
            label: GIFT_PRODUCTS,
            icon: <GiftOutlined />,
          },
          {
            value: GIFT_COLLECTION,
            label: GIFT_COLLECTION,
            icon: <AppstoreOutlined />,
          },
        ]}
        value={currentGiftOption}
        onChange={handleSetGiftOption}
        size="large"
      />
    </Flex>
  )

  const selectedCollectionId =
    gift?.productCollectionId === FEATURED_COLLECTION_ID
      ? ''
      : gift?.productCollectionId || ''

  const selectedVariantInView = selectedVariants?.find(
    pv => pv?.productId === productVariantToShow?.productId
  )

  const defaultSelectedOptions =
    sendingSpecificVariant && selectedVariantInView
      ? {
          options: getProductVariantOptions({
            productVariant: selectedVariantInView,
          }),
          filterOtherOptions: false,
        }
      : undefined

  const getDefaultSelectedCollectionBudget = ():
    | UseNumericMenuProps['items'][0]
    | undefined => {
    if (templateMaxAmount) {
      return {
        label: numToDollars(templateMaxAmount, 0) || '',
        end: templateMaxAmount,
      }
    }
    if (draftData?.collectionAmount) {
      return {
        label: numToDollars(draftData.collectionAmount, 0) || '',
        start: draftData.collectionAmount - 2500,
        end: draftData.collectionAmount,
      }
    }
    return undefined
  }

  const shippingCountries = getAlgoliaShippingCountriesFilter()

  const defaultSelectedProductFilter: UseNumericMenuProps['items'] | undefined =
    templateMaxAmount
      ? [
          {
            label: `Gifts under $${templateMaxAmount / 100}`,
            end: templateMaxAmount,
          },
        ]
      : undefined

  return (
    <>
      <Modal
        width={1280}
        centered
        title="‎"
        open={!!productVariantToShow}
        footer={false}
        onCancel={() => setProductVariantToShow(undefined)}
      >
        {productVariantToShow && (
          <ProductDetails
            withQuantityTable
            key={productVariantToShow.id}
            productVariant={productVariantToShow}
            showPrice
            showShipping
            showShippingCountries
            withAmountInUsd
            submitButtonProps={{ children: 'Send gift' }}
            onSubmit={handleSelectProductVariant}
            defaultSelected={defaultSelectedOptions}
            disableVariantOptions={!sendingSpecificVariant}
          >
            <ProductDetails.Slot name="aboveOptions">
              {productHasOptions && (
                <SwitchAndText
                  label={`Let recipient select ${constructUnifiedOptionsText(productVariantToShow)}`}
                  checked={!sendingSpecificVariant}
                  onCheck={checked => setSendingSpecificVariant(!checked)}
                />
              )}
            </ProductDetails.Slot>
            {currentGiftOption === GIFT_COLLECTION && (
              <ProductDetails.Slot name="nextToSubmit">
                <Button
                  style={{ flex: 1 }}
                  onClick={() =>
                    handleSelectProductCollection(
                      gift?.productCollectionId || FEATURED_COLLECTION_ID,
                      budget,
                      true
                    )
                  }
                >
                  Send collection
                </Button>
              </ProductDetails.Slot>
            )}
          </ProductDetails>
        )}
      </Modal>
      <Flex vertical style={{ width: '100%' }}>
        {currentGiftOption === GIFT_COLLECTION ? (
          <Flex
            style={{
              padding: '16px 0',
            }}
          >
            <AlgoliaBrowseCollections
              onProductClick={handleSetProductVariantInView}
              onConfirmProductCollection={handleSelectProductCollection}
              hiddenStickyNavTopAmount={-80}
              navbarPrefix={
                <Heading size={700} paddingRight={52}>
                  Select collection
                </Heading>
              }
              extraElementsInNavBar={
                <Flex flex={1} justify="end">
                  {tabToggleAndProductCount}
                </Flex>
              }
              hideProductCount
              initialUiState={{
                [ALGOLIA_PRODUCT_VARIANTS_INDEX]: {
                  menu: {
                    collectionIds: selectedCollectionId,
                    shippingCountries,
                  },
                  numericMenu: {
                    amount: defaultPriceFilter,
                  },
                },
              }}
              defaultSelectedBudget={getDefaultSelectedCollectionBudget()}
              disableBudgetSelect={!!templateMaxAmount}
            />
          </Flex>
        ) : (
          <AlgoliaBrowseProducts
            initialUiState={{
              [ALGOLIA_PRODUCT_VARIANTS_INDEX]: {
                menu: {
                  shippingCountries,
                },
                numericMenu: defaultSelectedProductFilter
                  ? { amount: defaultPriceFilter }
                  : undefined,
              },
            }}
          >
            <AlgoliaBrowseProducts.Old
              onProductCardClick={handleSetProductVariantInView}
              withCountryFilter
              withPriceFilter
              withProductAmounts
              withOccasionsFilter
              withCategoriesFilter
              firstRowPrefix={<Heading size={700}>Select gift</Heading>}
              extraElementsInFirstRow={
                <Flex flex={1} justify="space-between">
                  <ConvertableToOptions
                    currentConvertableTo={
                      gift?.convertableTo || tempProductConvertableTo
                    }
                    onConvertableToChange={handleConvertableToChange}
                  />
                  {tabToggleAndProductCount}
                </Flex>
              }
              selectedVariants={uniqueSelectedVariants}
              defaultPriceFilter={defaultSelectedProductFilter}
              disablePriceFilter={!!templateMaxAmount}
              bottomOffsetAmount={73}
            />
          </AlgoliaBrowseProducts>
        )}
      </Flex>
    </>
  )
}
