import { Timestamp } from '@bufbuild/protobuf'
import { ApprovedCategories } from 'constants/EligibleCategories'
import { LOCAL_HOST_URL, PROD_HOSTNAME } from 'constants/hosts'
import { isProduction } from 'constants/keys'
import {
  DEFAULT_PROGRAM_BANNER,
  DEFAULT_PROGRAM_BUDGET,
  EMPTY,
  IMemberActions,
  PROGRAM_ACTION_TYPES,
} from 'constants/programs'
import { rewardSteps } from 'constants/rewards'
import * as ROUTES from 'constants/routes'
import { EDIT, IMAGE } from 'constants/routes'
import dayjs from 'dayjs'
import { Template } from 'gen/perkup/v1/org_template_pb'
import { Organization } from 'gen/perkup/v1/organization_pb'
import { ProductVariant_Type } from 'gen/perkup/v1/product_variant_pb'
import {
  Member,
  Member_Action,
  Member_ConvertedTo,
  Membership,
  Program,
  ProgramFrequency,
  ProgramStatus,
  ProgramType,
  Program_Gift,
  Program_Gift_FulfilledBy,
  Program_RewardTab,
} from 'gen/perkup/v1/program_pb'
import { VendorProduct } from 'gen/perkup/v1/vendor_pb'
import { isEmpty, isNull } from 'lodash-es'
import { calculateGiftCosts } from 'pages/NewReward/utils/program-gifts'
import { parseDraftData } from 'pages/NewReward/utils/programs'
import { CreateRewardLocation, PriceCalc } from 'types'
import { ConvertableTo } from 'types/Gift'
import { RangeValue } from 'types/RewardsDate'
import { daysDiff, getDateTimeString } from './dates'
import {
  copyToClipboard,
  getPriceCalculation,
  makePlural,
  numToDollars,
} from './uiUtils'

export function isSwappableGift({ gift }: { gift?: Program_Gift }) {
  if (!gift) return false
  return !isEmpty(gift?.convertableTo)
}

export function getVendorProductGift({
  product,
  countryIso2,
}: {
  product: VendorProduct
  countryIso2: string
}): Program_Gift {
  const gift = new Program_Gift({
    id: product.id,
    fulfilledBy: Program_Gift_FulfilledBy.manual,
    title: product.name,
    imageUrl: product.images[0] || '',
    vendorId: product.vendorId ?? '',
    country: countryIso2,
    convertableTo: [ConvertableTo.gift],
  })
  return gift
}

export function getProgramEngagement({
  memberActions,
  amountUsed,
  isGift,
}: {
  memberActions: IMemberActions
  amountUsed: number
  isGift: boolean
}) {
  if (amountUsed > 0) {
    if (isGift) return PROGRAM_ACTION_TYPES.acceptedGift
    return PROGRAM_ACTION_TYPES.acceptedMonetary
  }

  if (memberActions[Member_Action[Member_Action.emailClick]])
    return PROGRAM_ACTION_TYPES.emailClick

  if (memberActions[Member_Action[Member_Action.emailOpen]])
    return PROGRAM_ACTION_TYPES.emailOpen

  if (memberActions[Member_Action[Member_Action.emailSent]])
    return PROGRAM_ACTION_TYPES.emailSent

  if (memberActions[Member_Action[Member_Action.emailBlocked]])
    return PROGRAM_ACTION_TYPES.emailBlocked

  if (memberActions[Member_Action[Member_Action.emailBounce]])
    return PROGRAM_ACTION_TYPES.emailBounce

  return undefined
}

export function createRewardLocationState({
  program,
  org,
}: {
  program?: Program
  org: Organization
}) {
  const createRewardState: CreateRewardLocation = {
    state: {
      budget: program?.budget || DEFAULT_PROGRAM_BUDGET,
      email: {
        banner: program?.email?.banner || DEFAULT_PROGRAM_BANNER,
        title: program?.name ?? EMPTY,
        note: program?.note ?? EMPTY,
        fromName: program?.email?.fromName || org.name,
      },
      gift: program?.gift,
      defaultCats: program?.approvedCategories,
      memo: program?.internalMemo,
      coreValues: program?.coreValues,
      items: program?.items,
      occasion: program?.occasion,
    },
  }
  return createRewardState
}

export function getProgramKeyAsString(key: keyof Program) {
  const keyAsString = key as string
  return keyAsString
}

export function getRouteByProgramApprovedCats({
  approvedCategories,
  swagRoute,
  shopRoute,
  showNearCash,
}: {
  approvedCategories: string[]
  swagRoute: string
  shopRoute: string
  showNearCash: boolean
}) {
  if (approvedCategories.length === 1) {
    const approvedCat = approvedCategories[0]

    if (approvedCat === ApprovedCategories.FOOD && showNearCash)
      return ROUTES.SHOP_FOOD

    if (approvedCat === ApprovedCategories.SWAG) {
      return swagRoute
    }
  }

  return shopRoute
}

export function filterProgramsByDate({
  dates,
  programs,
}: {
  dates: RangeValue
  programs: Program[]
}) {
  return isNull(dates)
    ? programs
    : programs?.filter(reward => {
        const start = dates?.[0]
        const end = dates?.[1]

        if (!start || !end) return undefined

        const created = dayjs(reward.created?.toDate())
        return created > start && created < end
      })
}

export const getDraftProgramNavigationPath = ({
  programId,
  draftData,
}: {
  programId: string
  draftData?: string
}) => {
  const draftRewardStep = parseDraftData({
    program: { draftData },
  })?.rewardStep

  const draftNavRoute =
    rewardSteps.find(rewardStep => rewardStep.step === draftRewardStep)?.path ||
    ROUTES.EDIT

  const rewardPath = `${ROUTES.DEFAULT_ROUTES.ORGANIZATION.REWARDS.NEW_REWARD}/${programId}${draftNavRoute}`

  return rewardPath
}

export const getProgramEndDateCountdown = ({
  program,
}: {
  program?: Program
}) => {
  const expiredDateDiff = daysDiff(program?.endsOn?.toDate(), false, true)
  const displayExpiration =
    expiredDateDiff && expiredDateDiff <= 7 && expiredDateDiff !== 0
  if (!displayExpiration) return ''
  const countDownNumber =
    expiredDateDiff < 1 ? Math.ceil(expiredDateDiff * 24) : expiredDateDiff

  const unitOfTime = makePlural(
    expiredDateDiff < 1 ? 'HOUR' : 'DAY',
    countDownNumber
  )

  return `In ${countDownNumber} ${unitOfTime}`
}

export const getRewardTab = (program: Program) => {
  const draftData = parseDraftData({ program })
  const currentTab = draftData?.rewardTab || Program_RewardTab.perkupDollars
  const lookingAtSwagGift = currentTab === Program_RewardTab.swag
  const lookingAtCatalogGift = currentTab === Program_RewardTab.catalog
  const lookingAtPerkCard = currentTab === Program_RewardTab.perkupDollars
  const lookingAtGiftCards = currentTab === Program_RewardTab.giftCard

  return {
    currentTab,
    lookingAtSwagGift,
    lookingAtCatalogGift,
    lookingAtPerkCard,
    lookingAtGiftCards,
  }
}

export const getProgramPriceCalculation = async ({
  gift,
  orgId,
  maxBudget,
}: {
  gift: Program_Gift
  orgId: string
  maxBudget?: number
}): Promise<PriceCalc | undefined> => {
  const newPrices = await getPriceCalculation({ orgId, gift })
  if (!newPrices) return undefined
  const { totalCost, productPrice, collectionMinPrice } = calculateGiftCosts({
    newTotalCost: newPrices.totalCost,
    newProductPrice: newPrices.productPrice,
    newCollectionMinPrice: newPrices.collectionMinPrice,
    selectedQuantity: gift?.redeemableQuantity || 1,
    maxBudget,
  })
  const newPriceCalc: PriceCalc = {
    ...newPrices,
    collectionMinPrice,
    totalCost,
    productPrice,
  }
  return newPriceCalc
}

export const getRewardStepsFromTemplate = (template?: Template) => {
  const imageWritable = !template || !!template?.image?.writable

  const eligibleRewards = template?.eligibleRewards
  const canSendSwag = eligibleRewards?.swag
  const canSendGift = eligibleRewards?.gift

  const templateBudget = template?.budget

  const oneBudgetCashOnlyTemplate =
    !canSendGift &&
    !canSendSwag &&
    templateBudget &&
    templateBudget.amounts.length === 1

  const filteredRewardSteps = rewardSteps.filter(step => {
    if (step.path === IMAGE && !imageWritable) {
      return false
    }
    if (step.path === EDIT && oneBudgetCashOnlyTemplate) {
      return false
    }
    return true
  })

  return filteredRewardSteps
}

export const getProgramAcceptanceLink = (programId: string) => {
  const baseUrl = isProduction ? PROD_HOSTNAME : LOCAL_HOST_URL
  const rewardPath = ROUTES.REWARD_ACCEPTANCE.replace(':programId', programId)
  const fullUrl = `${baseUrl}${rewardPath}`
  return fullUrl
}

export const copyProgramAcceptanceLink = (programId: string) => {
  copyToClipboard(getProgramAcceptanceLink(programId), 'gift link')
}

export const redemptionStatus = ({
  program,
  member,
}: {
  program: Program
  member: Member
}) => {
  const isRedeemedGift = !!program?.gift && member.spent > 0
  const isFullyRedeemedProgram = program.budget === member.spent

  return { isRedeemedGift, isFullyRedeemedProgram }
}

export const isNearCashGiftProgram = (gift: Program_Gift) => {
  return gift?.productVariantType === ProductVariant_Type.nearCash
}

export const isPublicGiftProgram = (gift: Program_Gift) => {
  return gift?.productVariantType === ProductVariant_Type.publicGift
}

export const determineNextRedeemableQuantity = (
  currentQty: number,
  potentialQty: number,
  direction: 'up' | 'down'
) => {
  if (direction === 'up') {
    return currentQty + 1 === potentialQty ? potentialQty : currentQty
  }
  return currentQty < potentialQty ? currentQty : potentialQty // Keep the current quantity if it is still within bounds!
}

export function isSpentGiftList(memberships: Membership[]): boolean {
  return memberships.every(
    membership =>
      !!membership?.program?.gift &&
      membership?.member &&
      membership?.member.spent > 0
  )
}

export const getResetsOnDateString = (resetsOn: number | undefined) => {
  return resetsOn
    ? `${new Date(resetsOn).toLocaleDateString('en-us', {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        timeZoneName: 'short',
      })}`
    : undefined
}

export const getAmountRemaining = ({
  budget,
  spent,
  exchangeRate,
  userDisplayCurrency,
  orgDisplayCurrency,
}: {
  spent: number
  exchangeRate: number
  userDisplayCurrency: string | undefined
  orgDisplayCurrency: string | undefined
  budget: number
}) => {
  return numToDollars(
    (budget - spent) * exchangeRate,
    2,
    false,
    userDisplayCurrency || orgDisplayCurrency
  )
}

export const getAmountSpent = ({
  spent,
  exchangeRate,
  userDisplayCurrency,
  orgDisplayCurrency,
}: {
  spent: number
  exchangeRate: number
  userDisplayCurrency: string | undefined
  orgDisplayCurrency: string | undefined
}) => {
  return numToDollars(
    spent * exchangeRate,
    2,
    false,
    userDisplayCurrency || orgDisplayCurrency
  )
}

export const sortMembershipsByCreationDate = ({
  memberships,
  isGiftsOnTop = true,
  persoanlFundsLast = true,
}: {
  memberships: Membership[]
  isGiftsOnTop?: boolean
  persoanlFundsLast?: boolean
}) => {
  return memberships.sort((a, b) => {
    if (isGiftsOnTop) {
      if (a?.program?.gift && !b?.program?.gift) return -1
      if (b?.program?.gift && !a?.program?.gift) return 1
    }

    if (persoanlFundsLast) {
      const programAIsPF = a?.program?.type === ProgramType.personalFunds
      const programBIsPF = b?.program?.type === ProgramType.personalFunds
      if (programAIsPF && !programBIsPF) return 1
      if (programBIsPF && !programAIsPF) return -1
    }

    if (a?.member?.created && b?.member?.created) {
      return (
        Number(b.member.created.toDate()) - Number(a.member.created.toDate())
      )
    }
    return 1
  })
}

export function isNearCashMembership(membership: Membership) {
  if (!membership) return false
  const { program, member } = membership

  const convertedToNearCash =
    member?.convertedTo === Member_ConvertedTo.nearCash

  const programIsGift = program?.gift

  const convertedGift = programIsGift && convertedToNearCash
  const nonGift = !programIsGift

  return nonGift || convertedGift
}

export function getActiveMemberships(memberships: Membership[]) {
  return memberships.filter(membership => {
    const { member, program } = membership
    const endsOn = program?.endsOn
    const status = program?.status
    const budget = member?.budget
    const spent = member?.spent

    const isActive = status === ProgramStatus.active
    const isSpent = spent === budget
    const hasNotExpiredYet = endsOn && Timestamp.now().seconds < endsOn.seconds

    return !isSpent && (isActive || hasNotExpiredYet)
  })
}

export function getInactiveMemberships(memberships: Membership[]) {
  return memberships.filter(membership => {
    const { member, program } = membership
    const status = program?.status
    const endsOn = program?.endsOn
    const budget = member?.budget
    const spent = member?.spent

    const isInactive = status === ProgramStatus.inactive
    const hasExpired = endsOn && Timestamp.now().seconds > endsOn.seconds

    // Active programs need to be displayed as inactive if they are fully spent
    // We can't update the program status because that will affect all members
    const isSpentEntireBudget = spent === budget

    // Do not render expired gifts
    const membershipHasExpiredAndIsNearCash =
      (isInactive || hasExpired) && isNearCashMembership(membership)

    return isSpentEntireBudget || membershipHasExpiredAndIsNearCash
  })
}

export const getDateDisplayString = ({ program }: { program: Program }) => {
  const { startsOn, endsOn, frequency, status, resetsOn, rollover } = program
  const activeProgram = status === ProgramStatus.active

  const isExpired = !activeProgram && startsOn && startsOn.toDate() < new Date()

  const oneTimeProgram = frequency === ProgramFrequency.once

  const dateString = () => {
    if (!endsOn && !resetsOn) return undefined
    if (oneTimeProgram) {
      return `Expires ${getDateTimeString(endsOn)}`
    }

    if (rollover) {
      return `Funds rollover ${getResetsOnDateString(Number(resetsOn))}`
    }

    return `Resets ${getResetsOnDateString(Number(resetsOn))}`
  }

  const frequencyOrDateStr = activeProgram
    ? dateString()
    : `Starts ${startsOn?.toDate().toLocaleDateString('en-us', {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        timeZoneName: 'short',
      })}`

  const dateDisplayString = isExpired
    ? `Expired ${endsOn?.toDate().toLocaleDateString('en-us', {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        timeZoneName: 'short',
      })}`
    : frequencyOrDateStr

  return dateDisplayString
}

/**
 * If a program has a gift, we give wiggle room of $10 or 5%, whichever is less
 */
export const getMaxProgramBudgetFlexibility = (program: Program) => {
  if (!program.gift) return program.budget
  const fivePercentBudget = program.budget * 0.05
  const tenDollars = 1000
  return Math.min(fivePercentBudget, tenDollars) + program.budget
}
