import { CloseOutlined } from '@ant-design/icons'
import { Button, Flex, notification } from 'antd'
import { createShippingAddress } from 'api/databaseCalls'
import {
  BulkProductRowCard,
  CartSidesheet,
  MemberNavigation,
  PerkLoader,
} from 'components'
import { WidthBreakpoints } from 'constants/layout'
import {
  CHECKOUT,
  SWAG_CHECKOUT,
  SWAG_PRODUCT,
  SWAG_PRODUCTS,
} from 'constants/routes'
import { UserContext, UserShippingAddressesContext } from 'context'
import { Heading, toaster } from 'evergreen-ui'
import { ProductVariant } from 'gen/perkup/v1/product_variant_pb'
import { ShippingAddress } from 'gen/perkup/v1/root_user_pb'
import { useActiveCartByIndividual } from 'hooks/carts'
import NoMatch404 from 'pages/NoMatch404'
import { useContext, useState } from 'react'
import { isMobile } from 'react-device-detect'
import {
  Outlet,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router-dom'
import { WithSelectedQuantity } from 'types'
import { SwagCheckoutPage } from './swag-store-checkout-page'
import { SwagStoreCollectionPage } from './swag-store-collection-page'
import { SwagStoreCollectionsPage } from './swag-store-collections-page'
import { SwagStoreProductPage } from './swag-store-product-page'

function SwagStoreLayout({
  openCartSidesheet,
  setOpenCartSidesheet,
}: {
  openCartSidesheet: boolean
  setOpenCartSidesheet: (open: boolean) => void
}) {
  const { pathname } = useLocation()
  const { defaultShippingAddressId, id: userId } = useContext(UserContext)

  const userIsOnSwagCheckoutPage = pathname.includes(SWAG_CHECKOUT)
  const shippingAddresses = useContext(UserShippingAddressesContext)
  const [shippingAddress, setShippingAddress] = useState<
    ShippingAddress | undefined
  >(shippingAddresses.find(address => address?.id === defaultShippingAddressId))

  const { cart, hasLoaded: hasLoadedCart } = useActiveCartByIndividual()

  const showCartButton = cart && !userIsOnSwagCheckoutPage

  const handleShippingAddressChange = (address?: ShippingAddress) => {
    const isExistingAddress = shippingAddresses.some(
      existingAddress => existingAddress?.id === address?.id
    )
    if (!isExistingAddress && address && !address?.id) {
      createShippingAddress({
        shippingAddress: address,
        userId,
      }).then(() => toaster.success('Successfully created shipping address'))
    }
    setShippingAddress(address)
  }

  return (
    <>
      <MemberNavigation
        extraNodeOnRight={
          showCartButton && (
            <CartSidesheet
              cart={cart}
              openCartSidesheet={openCartSidesheet}
              setOpenCartSidesheet={setOpenCartSidesheet}
              checkoutHref={SWAG_CHECKOUT}
              shopHref="/swag"
              shippingAddress={shippingAddress}
            />
          )
        }
      />
      <Flex
        style={{
          height: '100%',
          width: '100%',
          padding: isMobile ? 16 : 32,
          maxWidth: WidthBreakpoints.XXL,
          margin: 'auto',
        }}
      >
        {/* Opting for outlet context here to allow the collection to be undefined */}
        {hasLoadedCart ? (
          <Outlet
            context={{
              cart,
              shippingAddress,
              onShippingAddressChange: handleShippingAddressChange,
            }}
          />
        ) : (
          <PerkLoader />
        )}
      </Flex>
    </>
  )
}

export default function Swag() {
  const navigate = useNavigate()

  const [openCartSidesheet, setOpenCartSidesheet] = useState(false)

  const [api, contextHolder] = notification.useNotification({
    stack: { threshold: 0 },
  })

  const showCartNotification = (
    addedProductVariant: WithSelectedQuantity<ProductVariant>
  ) => {
    api.open({
      message: (
        <Heading size={600} padding={12} paddingBottom={0}>
          Added to Cart
        </Heading>
      ),
      description: (
        <Flex vertical>
          <BulkProductRowCard
            productVariantsWithQuantities={[addedProductVariant]}
          />
          <Flex gap={8} style={{ width: '100%', padding: '0 12px' }}>
            <Button
              style={{ flex: 1 }}
              onClick={() => {
                api.destroy()
                setOpenCartSidesheet(true)
              }}
            >
              View Cart
            </Button>
            <Button
              style={{ flex: 1 }}
              type="primary"
              onClick={() => {
                api.destroy()
                navigate(SWAG_CHECKOUT)
              }}
            >
              Checkout
            </Button>
          </Flex>
        </Flex>
      ),
      btn: (
        <Button
          onClick={() => api.destroy()}
          size="small"
          type="text"
          icon={<CloseOutlined />}
          style={{ position: 'absolute', top: 12, right: 8 }}
        />
      ),
      closeIcon: false,
      pauseOnHover: true,
      style: {
        padding: 0,
      },
    })
  }

  return (
    <>
      {contextHolder}
      <Routes>
        <Route
          element={
            <SwagStoreLayout
              openCartSidesheet={openCartSidesheet}
              setOpenCartSidesheet={setOpenCartSidesheet}
            />
          }
        >
          <Route index element={<SwagStoreCollectionsPage />} />
          <Route path={SWAG_PRODUCTS} element={<SwagStoreCollectionPage />} />
          <Route
            path={SWAG_PRODUCT}
            element={
              <SwagStoreProductPage onAddToCart={showCartNotification} />
            }
          />
          <Route path={CHECKOUT} element={<SwagCheckoutPage />} />
          <Route path="*" element={<NoMatch404 hasFooter={false} />} />
        </Route>
      </Routes>
    </>
  )
}
