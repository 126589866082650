import { SmileOutlined } from '@ant-design/icons'
import { Button, Flex, Popover } from 'antd'
import EmojiPicker, { EmojiClickData } from 'emoji-picker-react'
import { useTheme } from 'evergreen-ui'
import { ReactNode, useState } from 'react'

export function EmojiPickerButton({
  onEmojiSelect,
  customStyles,
  buttonText,
  disabled = false,
}: {
  onEmojiSelect: (selectedEmoji: string) => void
  customStyles?: {
    [key: string]: string
  }
  buttonText?: ReactNode
  disabled?: boolean
}) {
  const theme = useTheme()
  const [showPopover, setShowPopover] = useState<boolean>(false)

  const handlePickEmoji = (emojiData: EmojiClickData) => {
    onEmojiSelect(emojiData.emoji)
    setShowPopover(false)
  }

  return (
    <Popover
      placement="right"
      trigger="click"
      content={<EmojiPicker onEmojiClick={handlePickEmoji} />}
      open={showPopover}
      onOpenChange={setShowPopover}
    >
      <Flex style={{ ...customStyles }}>
        <Button
          disabled={disabled}
          type="text"
          icon={<SmileOutlined style={{ color: theme.colors.gray500 }} />}
        />
        {buttonText}
      </Flex>
    </Popover>
  )
}
