import AmazonBasics from 'assets/amazonCategories/amazon-basics.svg'
import AmazonGiftCard from 'assets/amazonCategories/amazon-gift-card.svg'
import ArtsCraftsAndSewing from 'assets/amazonCategories/arts-crafts-and-sewing.svg'
import Automotive from 'assets/amazonCategories/automotive.svg'
import BabyAndKids from 'assets/amazonCategories/babies-and-kids.svg'
import Beauty from 'assets/amazonCategories/beauty.svg'
import Books from 'assets/amazonCategories/books.svg'
import ClothingAndApparel from 'assets/amazonCategories/clothing-and-apparel.svg'
import Gear from 'assets/amazonCategories/gear.svg'
import Grocery from 'assets/amazonCategories/grocery.svg'
import Handmade from 'assets/amazonCategories/handmade.svg'
import HealthAndWellness from 'assets/amazonCategories/health-and-wellness.svg'
import HomeAndKitchen from 'assets/amazonCategories/home-and-kitchen.svg'
import LawnAndGarden from 'assets/amazonCategories/lawn-and-garden.svg'
import LuggageAndTravel from 'assets/amazonCategories/luggage-and-travel.svg'
import MoviesAndTV from 'assets/amazonCategories/movies-and-tv.svg'
import MusicAndAudio from 'assets/amazonCategories/music-and-audio.svg'
import PetSupplies from 'assets/amazonCategories/pet-supplies.svg'
import SchoolAndOffice from 'assets/amazonCategories/school-and-office.svg'
import Software from 'assets/amazonCategories/software.svg'
import SportsAndOutdoors from 'assets/amazonCategories/sports-and-outdoors.svg'
import ToolsAndImprovement from 'assets/amazonCategories/tools-and-improvement.svg'
import ToysAndGames from 'assets/amazonCategories/toys-and-games.svg'
import { Pane } from 'evergreen-ui'

export function ToysAndGamesIcon() {
  return (
    <Pane
      width="100%"
      height="100%"
      backgroundImage={`url(${ToysAndGames})`}
      backgroundRepeat="no-repeat"
      backgroundSize="contain"
      backgroundPosition="center"
    />
  )
}

export function ToolsAndImprovementIcon() {
  return (
    <Pane
      width="100%"
      height="100%"
      backgroundImage={`url(${ToolsAndImprovement})`}
      backgroundRepeat="no-repeat"
      backgroundSize="contain"
      backgroundPosition="center"
    />
  )
}

export function SportsAndOutdoorsIcon() {
  return (
    <Pane
      width="100%"
      height="100%"
      backgroundImage={`url(${SportsAndOutdoors})`}
      backgroundRepeat="no-repeat"
      backgroundSize="contain"
      backgroundPosition="center"
    />
  )
}

export function SoftwareIcon() {
  return (
    <Pane
      width="100%"
      height="100%"
      backgroundImage={`url(${Software})`}
      backgroundRepeat="no-repeat"
      backgroundSize="contain"
      backgroundPosition="center"
    />
  )
}

export function SchoolAndOfficeIcon() {
  return (
    <Pane
      width="100%"
      height="100%"
      backgroundImage={`url(${SchoolAndOffice})`}
      backgroundRepeat="no-repeat"
      backgroundSize="contain"
      backgroundPosition="center"
    />
  )
}

export function PetSuppliesIcon() {
  return (
    <Pane
      width="100%"
      height="100%"
      backgroundImage={`url(${PetSupplies})`}
      backgroundRepeat="no-repeat"
      backgroundSize="contain"
      backgroundPosition="center"
    />
  )
}

export function MusicAndAudioIcon() {
  return (
    <Pane
      width="100%"
      height="100%"
      backgroundImage={`url(${MusicAndAudio})`}
      backgroundRepeat="no-repeat"
      backgroundSize="contain"
      backgroundPosition="center"
    />
  )
}

export function MoviesAndTVIcon() {
  return (
    <Pane
      width="100%"
      height="100%"
      backgroundImage={`url(${MoviesAndTV})`}
      backgroundRepeat="no-repeat"
      backgroundSize="contain"
      backgroundPosition="center"
    />
  )
}

export function LuggageAndTravelIcon() {
  return (
    <Pane
      width="100%"
      height="100%"
      backgroundImage={`url(${LuggageAndTravel})`}
      backgroundRepeat="no-repeat"
      backgroundSize="contain"
      backgroundPosition="center"
    />
  )
}
export function LawnAndGardenIcon() {
  return (
    <Pane
      width="100%"
      height="100%"
      backgroundImage={`url(${LawnAndGarden})`}
      backgroundRepeat="no-repeat"
      backgroundSize="contain"
      backgroundPosition="center"
    />
  )
}

export function HomeAndKitchenIcon() {
  return (
    <Pane
      width="100%"
      height="100%"
      backgroundImage={`url(${HomeAndKitchen})`}
      backgroundRepeat="no-repeat"
      backgroundSize="contain"
      backgroundPosition="center"
    />
  )
}

export function HealthAndWellnessIcon() {
  return (
    <Pane
      width="100%"
      height="100%"
      backgroundImage={`url(${HealthAndWellness})`}
      backgroundRepeat="no-repeat"
      backgroundSize="contain"
      backgroundPosition="center"
    />
  )
}

export function GroceryIcon() {
  return (
    <Pane
      width="100%"
      height="100%"
      backgroundImage={`url(${Grocery})`}
      backgroundRepeat="no-repeat"
      backgroundSize="contain"
      backgroundPosition="center"
    />
  )
}

export function GearIcon() {
  return (
    <Pane
      width="100%"
      height="100%"
      backgroundImage={`url(${Gear})`}
      backgroundRepeat="no-repeat"
      backgroundSize="contain"
      backgroundPosition="center"
    />
  )
}

export function ClothingAndApparelIcon() {
  return (
    <Pane
      width="100%"
      height="100%"
      backgroundImage={`url(${ClothingAndApparel})`}
      backgroundRepeat="no-repeat"
      backgroundSize="contain"
      backgroundPosition="center"
    />
  )
}

export function BeautyIcon() {
  return (
    <Pane
      width="100%"
      height="100%"
      backgroundImage={`url(${Beauty})`}
      backgroundRepeat="no-repeat"
      backgroundSize="contain"
      backgroundPosition="center"
    />
  )
}

export function BabyAndKidsIcon() {
  return (
    <Pane
      width="100%"
      height="100%"
      backgroundImage={`url(${BabyAndKids})`}
      backgroundRepeat="no-repeat"
      backgroundSize="contain"
      backgroundPosition="center"
    />
  )
}

export function AutomotiveIcon() {
  return (
    <Pane
      width="100%"
      height="100%"
      backgroundImage={`url(${Automotive})`}
      backgroundRepeat="no-repeat"
      backgroundSize="contain"
      backgroundPosition="center"
    />
  )
}

export function AmazonGiftCardIcon() {
  return (
    <Pane
      width="100%"
      height="100%"
      backgroundImage={`url(${AmazonGiftCard})`}
      backgroundRepeat="no-repeat"
      backgroundSize="contain"
      backgroundPosition="center"
    />
  )
}

export function AmazonBasicsIcon() {
  return (
    <Pane
      width="100%"
      height="100%"
      backgroundImage={`url(${AmazonBasics})`}
      backgroundRepeat="no-repeat"
      backgroundSize="contain"
      backgroundPosition="center"
    />
  )
}

export function HandmadeIcon() {
  return (
    <Pane
      width="100%"
      height="100%"
      backgroundImage={`url(${Handmade})`}
      backgroundRepeat="no-repeat"
      backgroundSize="contain"
      backgroundPosition="center"
    />
  )
}

export function BooksIcon() {
  return (
    <Pane
      width="100%"
      height="100%"
      backgroundImage={`url(${Books})`}
      backgroundRepeat="no-repeat"
      backgroundSize="contain"
      backgroundPosition="center"
    />
  )
}

export function ArtsCraftsAndSewingIcon() {
  return (
    <Pane
      width="100%"
      height="100%"
      backgroundImage={`url(${ArtsCraftsAndSewing})`}
      backgroundRepeat="no-repeat"
      backgroundSize="contain"
      backgroundPosition="center"
    />
  )
}
