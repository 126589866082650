import { CloseOutlined, DeleteOutlined, DownOutlined } from '@ant-design/icons'
import { Badge, Button, Flex, InputNumber, Popconfirm, Tooltip } from 'antd'
import {
  PerkIconTooltipButton,
  PerkImage,
  PerkLink,
  SmallSavingsTag,
  VariantEstimatedShippingTime,
} from 'components'
import { DraftOrderContext, ExchangeRateContext } from 'context'
import { Text } from 'evergreen-ui'
import { ProductVariant } from 'gen/perkup/v1/product_variant_pb'
import { ShippingAddress } from 'gen/perkup/v1/root_user_pb'
import { useDefaultOrgColors, useDisplayCurrency } from 'hooks'
import { useContext, useState } from 'react'
import { WithSelectedQuantity } from 'types'
import { determineTotalSavingsForLineItems, numToDollars } from 'utils'

function LineItemInput({
  currentQuantity,
  onQuantityChange,
}: {
  currentQuantity: number
  onQuantityChange: (newQuanity: number) => void
}) {
  const [showInput, setShowInput] = useState(false)

  return showInput ? (
    <Flex
      gap={4}
      align="center"
      onBlur={e => {
        if (
          e.relatedTarget === null ||
          e.relatedTarget.className.includes('ant-drawer') // Blurring doesn't work in cart drawer without this
        ) {
          setShowInput(false)
        }
      }}
    >
      <InputNumber
        value={currentQuantity}
        style={{ width: 56 }}
        autoFocus
        min={1}
        precision={0}
        onChange={n => {
          if (n) {
            onQuantityChange(n)
          }
        }}
        size="small"
      />
    </Flex>
  ) : (
    <Tooltip title="Edit quantity">
      <Button
        type="text"
        style={{ padding: '1px 4px', height: 'min-content' }}
        onClick={() => setShowInput(true)}
      >
        {currentQuantity}
        <DownOutlined />
      </Button>
    </Tooltip>
  )
}

function LineItem({
  item,
  discountPercentage,
  onQuantityChange,
  onRemoveClick,
  asFullProduct = false,
}: {
  item: WithSelectedQuantity<ProductVariant>
  discountPercentage: number
  onQuantityChange?: (pvId: string, newQuantity: number) => void
  onRemoveClick?: (pvId: string) => void
  asFullProduct?: boolean
}) {
  const { amount, selectedQuantity, id, productName, variantName } = item

  const displayCurrency = useDisplayCurrency()
  const exchangeRate = useContext(ExchangeRateContext)

  const [showPopover, setShowPopover] = useState(false)

  const unitAmountForThisRow = Number(amount) * exchangeRate
  const unitAmountWithDiscount =
    unitAmountForThisRow * (1 - discountPercentage / 100)

  const totalToDisplay = numToDollars(
    (discountPercentage > 0 ? unitAmountWithDiscount : unitAmountForThisRow) *
      selectedQuantity,
    2,
    false,
    displayCurrency
  )

  return (
    <section className="group/option flex gap-4 items-center w-full">
      {/** Unit price */}
      <div className="flex gap-1 items-baseline text-sm">
        <p
          data-discounted={discountPercentage > 0}
          className="text-muted-foreground data-[discounted=true]:line-through data-[discounted=true]:text-xs"
        >
          {numToDollars(unitAmountForThisRow, 2, false, displayCurrency)}
        </p>
        {discountPercentage > 0 && (
          <p className="text-red-500 font-semibold">
            {numToDollars(unitAmountWithDiscount, 2, false, displayCurrency)}
          </p>
        )}
      </div>

      {/** Quantity */}
      <Flex flex={1} gap={4} align="center">
        <Text color="muted">{asFullProduct ? 'QTY' : variantName}:</Text>
        {onQuantityChange ? (
          <LineItemInput
            currentQuantity={selectedQuantity ?? 1}
            onQuantityChange={q => onQuantityChange(id, q)}
          />
        ) : (
          <Text color="muted">{selectedQuantity}</Text>
        )}

        {onRemoveClick && (
          <Popconfirm
            open={showPopover}
            onOpenChange={setShowPopover}
            title={`Remove ${asFullProduct ? productName : variantName}`}
            description={`Do you want to remove ${asFullProduct ? `the ${productName}` : variantName}?`}
            okText="Yes"
            cancelText="No"
            onConfirm={() => onRemoveClick(id)}
          >
            <div className="group/remove relative">
              <Button
                data-persist={showPopover} // The button hides when we aren't hovering the row. So when the user's cursor moves to the popover, we don't want the button to disappear when the popover is open.
                className="opacity-0 group-hover/option:opacity-100 transition-opacity data-[persist=true]:opacity-100"
                type="text"
                size="small"
                danger
                icon={<DeleteOutlined />}
              />

              <div className="absolute pointer-events-none -top-7 text-xs min-w-max right-1/2 translate-x-1/2 bg-[#000] bg-opacity-80 text-white font-medium px-2 py-1 rounded-lg opacity-0 group-hover/remove:opacity-100 z-50 transition-opacity">
                Remove
              </div>
            </div>
          </Popconfirm>
        )}
      </Flex>

      {/** Total */}
      <Text>{totalToDisplay}</Text>
    </section>
  )
}

function LineItems({
  items = [],
  onQuantityChange,
  onRemoveClick,
  discountPercentage,
}: {
  items: WithSelectedQuantity<ProductVariant>[]
  onQuantityChange?: (pvId: string, newQuantity: number) => void
  onRemoveClick?: (pvId: string) => void
  discountPercentage: number
}) {
  const productHasNoOtherVariants =
    items.length === 1 && Number(items[0].variantsCount) === 1

  if (productHasNoOtherVariants) {
    return (
      <LineItem
        key={items[0].id}
        item={items[0]}
        asFullProduct
        discountPercentage={discountPercentage}
        onQuantityChange={onQuantityChange}
        onRemoveClick={onRemoveClick}
      />
    )
  }

  return (
    <Flex vertical gap={4}>
      {items.map(item => (
        <LineItem
          key={item.id}
          item={item}
          discountPercentage={discountPercentage}
          onQuantityChange={onQuantityChange}
          onRemoveClick={onRemoveClick}
        />
      ))}
    </Flex>
  )
}

/**
 *
 * TODO: Make this component compound (CD) https://linear.app/perkup/issue/ENG-7280/make-bulkproductrowcard-a-compound-component
 */
export function BulkProductRowCard({
  to,
  productVariantsWithQuantities = [],
  onRemoveProductClick,
  onRemoveVariantClick,
  onQuantityChange,
  onAfterNavigatingTo,
  withEstimatedShippingTime = false,
  shippingAddress,
  nextToProductName,
}: {
  to?: string
  productVariantsWithQuantities: WithSelectedQuantity<ProductVariant>[]
  onRemoveProductClick?: (productId: string) => void
  onRemoveVariantClick?: (pvId: string) => void
  onQuantityChange?: (pvId: string, newQuanity: number) => void
  onAfterNavigatingTo?: () => void
  withEstimatedShippingTime?: boolean
  shippingAddress?: ShippingAddress
  nextToProductName?: React.ReactNode
}) {
  const { draftOrderCalculation } = useContext(DraftOrderContext)

  const { defaultColor } = useDefaultOrgColors()

  const { productName, productImages, imageUrl, productImageUrl } =
    productVariantsWithQuantities[0]

  const totalQuantity = productVariantsWithQuantities.reduce(
    (acc, { selectedQuantity }) => acc + (selectedQuantity || 0),
    0
  )

  const lineItemsForThisProduct =
    draftOrderCalculation?.lineItems.filter(
      li =>
        productVariantsWithQuantities.some(pv => li.variant?.id.includes(pv.id)) // @todo: It would be way easier if we just stored the product id in the line item
    ) ?? []

  const totalSavingsForThisProduct = determineTotalSavingsForLineItems(
    lineItemsForThisProduct
  )

  const discountPercentageForThisProduct =
    lineItemsForThisProduct?.[0]?.appliedDiscount?.value ?? 0

  return (
    <article className="relative group flex gap-4 p-4 rounded-lg">
      <Badge count={totalQuantity} color={defaultColor}>
        <PerkImage
          preview
          src={productImageUrl || imageUrl || productImages?.[0]?.src}
          alt={`Small thumbnail cart image for ${productName}`}
          sizes="72px"
          style={{
            aspectRatio: '1/1',
            objectFit: 'cover',
            width: 72,
            height: 72,
            borderRadius: 8,
          }}
        />
      </Badge>

      <Flex vertical gap={4} flex={1}>
        <section className="flex items-center gap-2 justify-between">
          <div className="flex items-center gap-2">
            {to ? (
              <PerkLink to={to}>
                <Text className="hoverline" onClick={onAfterNavigatingTo}>
                  {productName}
                </Text>
              </PerkLink>
            ) : (
              <Text>{productName}</Text>
            )}
            {nextToProductName}
          </div>

          <SmallSavingsTag
            savings={totalSavingsForThisProduct}
            discount={discountPercentageForThisProduct}
            inLocalAmounts
          />
        </section>
        <LineItems
          items={productVariantsWithQuantities}
          onQuantityChange={onQuantityChange}
          onRemoveClick={pvId => {
            if (onRemoveVariantClick) onRemoveVariantClick(pvId)
          }}
          discountPercentage={discountPercentageForThisProduct}
        />
        {withEstimatedShippingTime && (
          <VariantEstimatedShippingTime
            productVariant={productVariantsWithQuantities[0]}
            shippingAddress={shippingAddress}
          />
        )}
      </Flex>

      {onRemoveProductClick && (
        <PerkIconTooltipButton
          title="Remove item"
          className="absolute right-2 top-2 rounded-lg transition-opacity opacity-0 group-hover:opacity-100"
          size="small"
          icon={<CloseOutlined />}
          onClick={() =>
            onRemoveProductClick(productVariantsWithQuantities[0].productId)
          }
        />
      )}
    </article>
  )
}
