import { IndividualContext } from 'context'
import { Individual_Role } from 'gen/perkup/v1/individual_pb'
import { useContext } from 'react'

export function useIndividualRole() {
  const individual = useContext(IndividualContext)
  const role = individual?.role
  const isAdmin = role === Individual_Role.admin
  const isManager = role === Individual_Role.manager
  const isMember = role === Individual_Role.member
  return { role, isAdmin, isManager, isMember }
}
