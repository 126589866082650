import { Timestamp } from '@bufbuild/protobuf'
import { captureException } from '@sentry/react'
import { UploadFile } from 'antd'
import { UploadChangeParam } from 'antd/es/upload'
import { SWAG_CARD } from 'assets/contentful'
import { PlacidTags } from 'constants/placid/placid-tags'
import { toaster } from 'evergreen-ui'
import { getAuth } from 'firebase/auth'
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getFirestore,
  PartialWithFieldValue,
  setDoc,
  updateDoc,
} from 'firebase/firestore'
import { getStorage, ref, uploadBytes } from 'firebase/storage'
import { ProductCollectionAdmin } from 'gen/perkup/v1/product_collections_connect'
import {
  ProductCollection,
  ProductCollection_CollectionAccess_Enum,
  ProductCollection_Permission,
  ProductCollection_ProductInfo,
  UpdateCollectionProductRequest,
} from 'gen/perkup/v1/product_collections_pb'
import { createClient } from 'hooks/useClient'
import { sample } from 'lodash-es'
import { listPlacidImagesByTag } from 'services/placid'
import { PERMISSION_ADMIN, PERMISSION_MANAGER } from 'types/Permissions'
import { toSentry } from 'utils'
import { converter, messageToFirestore, storedId } from 'utils/firestore'
import { formatDownloadUrl } from 'utils/images'

export async function createCollection({
  orgId,
  individualId,
  collectionName,
  collectionDescription,
}: {
  orgId: string
  individualId: string
  collectionName: string
  collectionDescription: string
}) {
  const collectionBannerImages = await listPlacidImagesByTag({
    tag: PlacidTags.COLLECTION_BANNER,
    orgId,
  })

  const randomBannerImage = sample(collectionBannerImages)

  const swagCollection = new ProductCollection({
    name: collectionName,
    description: collectionDescription,
    imageUrl: randomBannerImage ?? SWAG_CARD,
    access: ProductCollection_CollectionAccess_Enum.private,
    permissions: {
      [PERMISSION_ADMIN]: ProductCollection_Permission.full,
      [PERMISSION_MANAGER]: ProductCollection_Permission.send,
      [individualId]: ProductCollection_Permission.full,
    },
    orgId,
    createdAt: Timestamp.now(),
    individualId,
  })

  const colRef = collection(
    getFirestore(),
    `/productCollections`
  ).withConverter(storedId(ProductCollection))
  const newSwagCollection = await addDoc(colRef, swagCollection).catch(e => {
    captureException(toSentry(e), {
      contexts: {
        createCollection: {
          orgId,
          individualId,
          name: collectionName,
          description: collectionDescription,
        },
      },
    })
  })
  return newSwagCollection
}

export function updateCollection({
  id,
  collection,
}: {
  id: string
  collection: PartialWithFieldValue<ProductCollection>
}) {
  if (!id) return
  const db = getFirestore()
  const docRef = doc(db, `productCollections/${id}`).withConverter(
    storedId(ProductCollection)
  )
  setDoc(docRef, collection, { merge: true }).catch(error => {
    console.error(error)
    captureException(toSentry(error), {
      contexts: {
        updateCollection: {
          id,
        },
      },
    })
  })
}

export function updateCollectionProductRank({
  id,
  products,
}: {
  id?: string
  products?: { [key: string]: ProductCollection_ProductInfo }
}) {
  if (!id) return
  const db = getFirestore()
  const docRef = doc(db, `productCollections/${id}`).withConverter(
    storedId(ProductCollection)
  )
  setDoc(docRef, { products }, { merge: true }).catch(error => {
    console.error(error)
    captureException(toSentry(error), {
      contexts: {
        updateCollectionProductRank: {
          id,
        },
      },
    })
  })
}

export async function updateCollectionProducts({
  collectionId,
  productIds,
}: {
  collectionId?: string
  productIds: string[]
}) {
  try {
    if (!collectionId || !productIds.length) return
    const accessToken = await getAuth().currentUser?.getIdToken()

    const client = createClient(ProductCollectionAdmin)

    const updateCollectionRequest = new UpdateCollectionProductRequest({
      productCollectionId: collectionId,
      productIds,
    })

    await client.addProductsToCollection(updateCollectionRequest, {
      headers: { Authorization: `Bearer ${accessToken}` },
    })
  } catch (error) {
    toaster.warning(
      'Error adding product to collection, please contact support'
    )

    console.error(error)

    captureException(toSentry(error), {
      contexts: {
        UpdateCollectionProducts: {
          collectionId,
        },
      },
    })
  }
}

export async function removeCollectionProducts({
  collectionId,
  productIds,
}: {
  collectionId?: string
  productIds: string[]
}) {
  try {
    if (!collectionId || !productIds.length) return
    const accessToken = await getAuth().currentUser?.getIdToken()

    const client = createClient(ProductCollectionAdmin)

    const updateCollectionRequest = new UpdateCollectionProductRequest({
      productCollectionId: collectionId,
      productIds,
    })

    await client.removeProductsFromCollection(updateCollectionRequest, {
      headers: { Authorization: `Bearer ${accessToken}` },
    })
  } catch (error) {
    toaster.warning('Error removing product, please contact support')

    console.error(error)

    captureException(toSentry(error), {
      contexts: {
        removeCollectionProducts: {
          collectionId,
        },
      },
    })
  }
}

export async function uploadCollectionImage({
  collectionId,
  orgId,
  upload,
}: {
  collectionId: string
  orgId: string
  upload: UploadChangeParam<UploadFile<any>>
}) {
  try {
    if (!upload.file.originFileObj) return
    const collectionPicRef = ref(
      getStorage(),
      `${orgId}/${collectionId}/${upload.file.name}`
    )
    await uploadBytes(collectionPicRef, upload.file.originFileObj)
    const url = await formatDownloadUrl(collectionPicRef)

    const db = getFirestore()
    const docRef = doc(db, `productCollections/${collectionId}`).withConverter(
      converter(ProductCollection)
    )
    setDoc(
      docRef,
      {
        imageUrl: url,
      },
      { merge: true }
    )
  } catch (error) {
    console.error(error)
    captureException(toSentry(error), {
      contexts: {
        uploadCollectionImage: {
          collectionId,
          orgId,
        },
      },
    })
  }
}

export async function deleteCollectionById({
  collectionId,
}: {
  collectionId: string
}) {
  await removeCollectionProducts({ collectionId, productIds: [] })

  const db = getFirestore()
  const docRef = doc(db, `productCollections/${collectionId}`)

  await deleteDoc(docRef)
    .then(() => {
      toaster.notify('Collection deleted')
    })
    .catch(error => {
      console.error(error)
      toaster.warning('Error deleting collection')
      captureException(toSentry(error), {
        contexts: {
          deleteCollectionById: {
            collectionId,
          },
        },
      })
    })
}

export async function updateProductCollectionPermissions({
  id,
  permissions,
}: {
  id: string
  permissions: Record<string, ProductCollection_Permission>
}) {
  if (!id) return
  const db = getFirestore()
  const docRef = doc(db, `productCollections/${id}`).withConverter(
    storedId(ProductCollection)
  )
  const collection = messageToFirestore(ProductCollection, { permissions })
  await updateDoc(docRef, {
    permissions: collection.permissions,
  }).catch(error => {
    console.error(error)
    captureException(toSentry(error), {
      contexts: {
        updateProductCollectionPermissions: {
          id,
          permissions,
        },
      },
    })
  })
}
