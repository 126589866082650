import { Button, Modal } from 'antd'
import { AddressDisplay, NewAddressButton } from 'components'
import { Pane } from 'evergreen-ui'
import { ShippingAddress } from 'gen/perkup/v1/root_user_pb'
import { useState } from 'react'

export function ShippingAddressBlock({
  shippingAddress,
  isDefault,
  onAddressChange,
  onSetDefaultAddress,
}: {
  shippingAddress: ShippingAddress
  isDefault?: boolean
  onAddressChange: (addressFormData?: ShippingAddress) => void
  onSetDefaultAddress?: () => void
}) {
  const [showRemoveDialog, setShowRemoveDialog] = useState<boolean>(false)

  return (
    <>
      <Pane
        display="flex"
        flexDirection="column"
        justifyContent="flex-end"
        borderRadius={2}
        padding={16}
        border="muted"
        width="fit-content"
        minWidth={312}
      >
        <AddressDisplay
          address={shippingAddress}
          showDefaultBadge={isDefault}
        />

        <Pane display="flex" gap={8}>
          <NewAddressButton
            addAddressCTALabel="Edit"
            submitLabel="Save"
            dialogTitle="Edit shipping address"
            defaultAddress={shippingAddress}
            onAddressSubmit={onAddressChange}
            asSmallButton
          />

          <Button onClick={() => setShowRemoveDialog(true)} type="text" danger>
            Remove
          </Button>
          {!!onSetDefaultAddress && (
            <Button onClick={onSetDefaultAddress} type="text">
              Set as default
            </Button>
          )}
        </Pane>
      </Pane>

      {/* REMOVE ADDRESS DIALOG */}
      <Modal
        title="Remove shipping address"
        open={showRemoveDialog}
        destroyOnClose
        afterClose={() => setShowRemoveDialog(false)}
        onOk={() => {
          onAddressChange(undefined)
          setShowRemoveDialog(false)
        }}
        onCancel={() => setShowRemoveDialog(false)}
        okText="Remove"
      >
        <AddressDisplay address={shippingAddress} />
      </Modal>
    </>
  )
}
