import { ListProgramsByIds } from 'api/databaseCalls'
import { Program } from 'gen/perkup/v1/program_pb'
import useIds from 'hooks/useIds'
import { isEmpty } from 'lodash-es'
import { useEffect, useState } from 'react'

export function useProgramsByIds({ programIds }: { programIds: string[] }) {
  const { orgId } = useIds()

  const [isLoadingInitial, setIsLoadingInitial] = useState(true)
  const [isLoadingMore, setIsLoadingMore] = useState(false)

  const [programs, setPrograms] = useState<Program[]>([])

  useEffect(() => {
    if (isEmpty(programIds) || !orgId) {
      setIsLoadingInitial(false)
      return
    }
    ListProgramsByIds({ orgId, programIds })
      .then(setPrograms)
      .finally(() => {
        setIsLoadingInitial(false)
        setIsLoadingMore(false)
      })
  }, [orgId, programIds])

  return {
    programs,
    isLoadingInitial,
    isLoadingMore,
    isLoading: isLoadingInitial || isLoadingMore,
  }
}
