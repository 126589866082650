export const defaultButtonStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}

export const tablePaginationSettings = {
  hideOnSinglePage: true,
  pageSize: 100,
  showSizeChanger: true,
}
