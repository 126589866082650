// @generated by protoc-gen-connect-es v1.1.4 with parameter "target=ts"
// @generated from file algolia/api_keys.proto (package algolia, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { Empty } from "../google/protobuf/empty_pb.js";
import { SecuredAPIKeyResponse } from "./api_keys_pb.js";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service algolia.SecuredAPIKeyGenerator
 */
export const SecuredAPIKeyGenerator = {
  typeName: "algolia.SecuredAPIKeyGenerator",
  methods: {
    /**
     * individuals - admin, manager
     *
     * @generated from rpc algolia.SecuredAPIKeyGenerator.IndividualsKey
     */
    individualsKey: {
      name: "IndividualsKey",
      I: Empty,
      O: SecuredAPIKeyResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc algolia.SecuredAPIKeyGenerator.ProductVariantsKey
     */
    productVariantsKey: {
      name: "ProductVariantsKey",
      I: Empty,
      O: SecuredAPIKeyResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

