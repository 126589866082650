// @generated by protoc-gen-connect-es v1.1.4 with parameter "target=ts"
// @generated from file perkup/v1/individual.proto (package perkup.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { ListIndividualIdsRequest, ListIndividualIdsResponse } from "./individual_pb.js";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * Individual Service used by frontend to receive bulk individual data
 *
 * @generated from service perkup.v1.Individuals
 */
export const Individuals = {
  typeName: "perkup.v1.Individuals",
  methods: {
    /**
     * @generated from rpc perkup.v1.Individuals.ListIndividualIds
     */
    listIndividualIds: {
      name: "ListIndividualIds",
      I: ListIndividualIdsRequest,
      O: ListIndividualIdsResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

