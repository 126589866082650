import { ApiOutlined, MonitorOutlined } from '@ant-design/icons'
import { type ResultSet } from '@cubejs-client/core'
import { useCubeQuery } from '@cubejs-client/react'
import {
  ChartConfig,
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from '@repo/shadcn/es/chart'
import { Skeleton } from 'antd'
import { PerkEmpty } from 'components'
import { Heading, Strong, Text } from 'evergreen-ui'
import { useDevSafeOrgId } from 'hooks/useDevSafeOrgId'
import { compact, startCase } from 'lodash-es'
import { useState } from 'react'
import { Pie, PieChart, Sector } from 'recharts'
import { PieSectorDataItem } from 'recharts/types/polar/Pie'
import { useInsightsContext } from './insights-context'

const activeShape = ({ outerRadius = 0, ...props }: PieSectorDataItem) => (
  <Sector {...props} outerRadius={outerRadius + 10} />
)

function CoreValuesChart({
  results,
}: {
  results: ResultSet<{
    'programCoreValues.count': string | number | boolean | null
    'programCoreValues.coreValue': string | number | boolean | null
  }>
}) {
  const chartData = compact(
    results
      .rawData()
      .map(
        ({
          'programCoreValues.count': occurences,
          'programCoreValues.coreValue': coreValue,
        }) => {
          if (typeof coreValue !== 'string') return undefined
          const coreValueCleaned = coreValue
            .replace(/['"]+/g, '') // Remove quotes
            .replace(/\s/g, '-') // Replace spaces with dashes
            .toLowerCase() // Lowercase
          return {
            coreValue: coreValueCleaned,
            occurences: Number(occurences || 0),
            fill: `var(--color-${coreValueCleaned})`,
          }
        }
      )
  )

  const chartConfig = Object.assign(
    {},
    {
      occurences: {
        label: 'Occurences',
      },
    },
    ...chartData.map(({ coreValue }, i) => ({
      [coreValue]: {
        label: startCase(coreValue),
        color: `hsl(var(--chart-${i + 1}))`,
      },
    }))
  ) satisfies ChartConfig

  const [activeCoreValue, setActiveCoreValue] = useState<string>(
    chartData[0].coreValue
  )

  const activeIndex = chartData.findIndex(
    ({ coreValue }) => coreValue === activeCoreValue
  )

  const activeData = chartData[activeIndex]

  const totalOccurences = chartData.reduce(
    (acc, { occurences }) => acc + occurences,
    0
  )

  const activePercentage = Math.round(
    (activeData.occurences / totalOccurences) * 100
  )

  return (
    <div className="flex flex-col gap-2">
      <Heading>Core values</Heading>

      <ChartContainer
        config={chartConfig}
        className="mx-auto aspect-square max-h-[250px] h-full w-full"
      >
        <PieChart>
          <ChartTooltip
            cursor={false}
            content={<ChartTooltipContent hideLabel />}
          />
          <Pie
            className="cursor-pointer"
            data={chartData}
            dataKey="occurences"
            nameKey="coreValue"
            activeIndex={activeIndex}
            activeShape={activeShape}
            onClick={(_, index) =>
              setActiveCoreValue(chartData[index].coreValue)
            }
          />
        </PieChart>
      </ChartContainer>

      <div className="flex flex-col items-center">
        <Heading size={700}>{activePercentage}%</Heading>
        <Text size={300} color="muted" className="text-center">
          of rewards associated with core values <br /> were linked to{' '}
          <Strong size={300}>{startCase(activeCoreValue)}</Strong>
        </Text>
      </div>
    </div>
  )
}

export function CoreValues() {
  const orgId = useDevSafeOrgId()

  const { dateRange, insightsGlobalFilters } = useInsightsContext()

  const { resultSet, isLoading, error } = useCubeQuery({
    limit: 5000,
    measures: ['programCoreValues.count'],
    dimensions: ['programCoreValues.coreValue'],
    filters: [
      {
        member: 'organizations.id',
        operator: 'equals',
        values: [orgId],
      },
      {
        member: 'programCoreValues.coreValue',
        operator: 'set',
      },
      ...insightsGlobalFilters,
    ],
    timeDimensions: [
      {
        dimension: 'memberships.created',
        dateRange,
      },
    ],
  })

  if (isLoading) {
    return (
      <section className="flex flex-col gap-6">
        <Heading>Core values</Heading>
        <Skeleton active paragraph={{ rows: 6 }} />
      </section>
    )
  }

  if (error || !resultSet) {
    return (
      <section className="flex flex-col gap-6">
        <Heading>Core values</Heading>
        <PerkEmpty
          iconNode={
            <ApiOutlined
              className="text-muted-foreground"
              style={{ fontSize: 32 }}
            />
          }
          header="Oops! Something went wrong"
          description="We couldn't load your data. Please try again later."
        />
      </section>
    )
  }

  if (resultSet.chartPivot().length === 0) {
    return (
      <section className="flex flex-col gap-6">
        <Heading>Core values</Heading>
        <PerkEmpty
          iconNode={
            <MonitorOutlined
              className="text-muted-foreground"
              style={{ fontSize: 32 }}
            />
          }
          header="Nothing found"
          description="Try a different date range or filter."
        />
      </section>
    )
  }

  return <CoreValuesChart results={resultSet} />
}
