import { ShoppingOutlined } from '@ant-design/icons'
import { Badge, Button, Drawer, Flex, message } from 'antd'
import { updateCartItems } from 'api/databaseCalls/writes/carts'
import { EMPTY_CART } from 'assets/contentful'
import {
  DraftOrderContextBuilder,
  PerkEmpty,
  PerkLoader,
  PerkScrollbars,
} from 'components'
import { DraftOrderContext, ExchangeRateContext } from 'context'
import { Strong } from 'evergreen-ui'
import { MicroLines } from 'features'
import { Cart, Cart_Item } from 'gen/perkup/v1/cart_pb'
import { ProductVariant } from 'gen/perkup/v1/product_variant_pb'
import { ShippingAddress } from 'gen/perkup/v1/root_user_pb'
import { useDefaultOrgColors, useDisplayCurrency } from 'hooks'
import useProductVariantsByIds from 'hooks/productVariants/useProductVariantsByIds'
import { round } from 'lodash-es'
import { useContext, useMemo } from 'react'
import { isMobile } from 'react-device-detect'
import { useNavigate } from 'react-router'
import { MaybeWithDesign, WithSelectedQuantity } from 'types'
import { numToDollars } from 'utils'
import {
  buildProductVariantsFromCart,
  sumProductVariants,
} from 'utils/productVariant'

function TotalCost({
  productVariants,
}: {
  productVariants: MaybeWithDesign<WithSelectedQuantity<ProductVariant>>[]
}) {
  const displayCurrency = useDisplayCurrency()

  const exchangeRate = useContext(ExchangeRateContext)
  const { draftOrderCalculation } = useContext(DraftOrderContext)

  const totalCost = draftOrderCalculation?.totalPrice
    ? round(Number(draftOrderCalculation?.totalPrice) * 100, 2)
    : sumProductVariants(productVariants)

  return (
    <Flex justify="space-between">
      <Strong>Subtotal</Strong>
      <Strong>
        {numToDollars(totalCost * exchangeRate, 2, false, displayCurrency)}
      </Strong>
    </Flex>
  )
}

export function CartSidesheet({
  cart,
  openCartSidesheet,
  setOpenCartSidesheet,
  checkoutHref,
  shopHref,
  shippingAddress,
}: {
  cart: Cart
  openCartSidesheet: boolean
  setOpenCartSidesheet: (open: boolean) => void
  checkoutHref: string
  shopHref: string
  shippingAddress?: ShippingAddress
}) {
  const navigate = useNavigate()

  const [messageApi, contextHolder] = message.useMessage()

  const { defaultColor } = useDefaultOrgColors()

  // DANGER: REMOVING THIS IS CAUSING INFINITE LOOP
  const variantIds = useMemo(
    () => cart.lineItems.map(li => li.productVariantId),
    [cart.lineItems]
  )

  const isCartEmpty = cart.lineItems.length === 0

  const { productVariants, isLoadingInitital } = useProductVariantsByIds({
    variantIds,
  })

  const productVariantsWithQuantitiesAndMaybeDesigns =
    buildProductVariantsFromCart(productVariants, cart)

  const totalCartItems = cart.lineItems.reduce(
    (acc, li) => acc + li.quantity,
    0
  )

  const handleUpdateCart = async (
    updatedProductVariants: MaybeWithDesign<
      WithSelectedQuantity<ProductVariant>
    >[]
  ) => {
    const updatedLineItems = updatedProductVariants.map(pv => {
      return new Cart_Item({
        productId: pv.productId,
        productVariantId: pv.id,
        quantity: pv.selectedQuantity,
        provider: pv.provider,
        type: pv.type,
        imageUrl: pv.imageUrl,
        canvasDesign: pv.canvasDesign,
      })
    })

    await updateCartItems({
      cartId: cart.id,
      lineItems: updatedLineItems,
    })

    // This prevents a bunch of alerts from stacking up when the user changes stuff quickly in the cart
    messageApi.open({
      key: 'updated-key',
      type: 'success',
      content: 'Updated cart',
    })
  }

  if (isLoadingInitital) return <PerkLoader />

  return (
    <>
      {contextHolder}
      <Badge count={totalCartItems} color={defaultColor}>
        <Button
          type="text"
          onClick={() => setOpenCartSidesheet(true)}
          icon={<ShoppingOutlined />}
        />
      </Badge>

      <DraftOrderContextBuilder
        productVariants={productVariantsWithQuantitiesAndMaybeDesigns}
        shippingAddress={shippingAddress}
      >
        <Drawer
          placement="right"
          open={openCartSidesheet}
          onClose={() => setOpenCartSidesheet(false)}
          title="Cart"
          width={isMobile ? '100vw' : 560}
          styles={{
            body: {
              padding: 0,
            },
          }}
        >
          <Flex
            vertical
            justify="space-between"
            style={{
              height: '100%',
              paddingTop: isCartEmpty ? 64 : undefined,
            }}
          >
            <PerkScrollbars>
              <Flex vertical gap={16} style={{ padding: isMobile ? 8 : 24 }}>
                {isCartEmpty ? (
                  <PerkEmpty
                    ctaProps={{
                      children: 'Shop now',
                      onClick: () => {
                        navigate(shopHref)
                        setOpenCartSidesheet(false)
                      },
                    }}
                    header="Your cart is empty"
                    iconUrl={EMPTY_CART}
                  />
                ) : (
                  <MicroLines
                    productVariants={
                      productVariantsWithQuantitiesAndMaybeDesigns
                    }
                    onChange={handleUpdateCart}
                    shippingAddress={shippingAddress}
                    onAfterNavigatingTo={() => setOpenCartSidesheet(false)}
                  />
                )}
              </Flex>
            </PerkScrollbars>

            {!isCartEmpty && (
              <Flex vertical gap={16} style={{ padding: 24 }}>
                <TotalCost
                  productVariants={productVariantsWithQuantitiesAndMaybeDesigns}
                />

                <Button
                  onClick={() => {
                    navigate(checkoutHref)
                    setOpenCartSidesheet(false)
                  }}
                  style={{ width: '100%' }}
                  type="primary"
                >
                  Continue to checkout
                </Button>
              </Flex>
            )}
          </Flex>
        </Drawer>
      </DraftOrderContextBuilder>
    </>
  )
}
