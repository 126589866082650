import { DeleteTwoTone, EyeOutlined } from '@ant-design/icons'
import { Drawer, Flex } from 'antd'
import { deleteRule } from 'api/databaseCalls'
import { PerkIconTooltipButton } from 'components'
import { EmailPreview } from 'components/EmailPreview'
import { OrgContext } from 'context'
import { toaster } from 'evergreen-ui'
import { useContext, useState } from 'react'

interface EmailInformation {
  image: string
  title: string
  message: string
  from: string
}

export function RuleAdditionalOptions({
  ruleId,
  emailInfo,
  isGift,
  amount,
}: {
  ruleId: string
  emailInfo: EmailInformation
  isGift: boolean
  amount?: number
}) {
  const org = useContext(OrgContext)
  const orgId = org.id
  const [viewEmailPreview, setViewEmailPreview] = useState(false)

  const handleDeleteRule = () => {
    deleteRule({ orgId, ruleId })
    toaster.warning('Rule deleted', { id: ruleId })
  }

  return (
    <>
      <Flex gap={8}>
        <PerkIconTooltipButton
          title="Preview email"
          type="text"
          icon={<EyeOutlined />}
          onClick={() => setViewEmailPreview(true)}
        />

        <PerkIconTooltipButton
          title="Delete"
          type="text"
          icon={<DeleteTwoTone twoToneColor="red" />}
          onClick={handleDeleteRule}
        />
      </Flex>

      <Drawer
        title="Email Preview"
        placement="right"
        onClose={() => setViewEmailPreview(false)}
        size="large"
        open={viewEmailPreview}
      >
        <EmailPreview
          banner={emailInfo.image}
          title={emailInfo.title}
          programNote={emailInfo.message}
          fromName={emailInfo.from}
          isGift={isGift}
          amount={amount}
        />
      </Drawer>
    </>
  )
}
