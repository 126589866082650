import { Alert } from 'antd'
import { ProgramContext } from 'context'
import { useAccount, useGiftRedeemed } from 'hooks'
import { useIndividualByUserId } from 'hooks/individuals/useIndividualByUserId'
import { useContext } from 'react'

export function GiftAcceptanceAlert() {
  const program = useContext(ProgramContext)
  const giftRedeemed = useGiftRedeemed()
  const { accountId, ownerId } = program
  const { account, hasLoaded } = useAccount({ accountId })

  const { individual: creatorIndividual } = useIndividualByUserId({
    userId: ownerId,
  })

  if (!hasLoaded) return null

  if (giftRedeemed) {
    return (
      <Alert
        message="Gift has already been redeemed."
        type="info"
        style={{ width: 'fit-content' }}
      />
    )
  }

  if (!account || account.balance < program.budget) {
    return (
      <Alert
        type="warning"
        message="This reward has run out of available funds."
        showIcon
        description={`Contact ${
          creatorIndividual?.email || 'your admin'
        } to get this resolved.`}
        style={{ width: 'fit-content' }}
      />
    )
  }

  return null
}
