import { CardWithImage } from 'components/CardWithImage'
import { REWARD_ACCEPTANCE, REWARD_ACCEPTANCE_REVEAL } from 'constants/routes'
import { ProgramContext } from 'context'
import { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { PageDirection } from 'types'
import { AnimatedPage, ProgramInfo } from './components'

export function RewardAcceptanceLandingPage() {
  const program = useContext(ProgramContext)
  const navigate = useNavigate()
  const isGift = !!program?.gift

  const handleRevealClick = () => {
    navigate(
      `${REWARD_ACCEPTANCE.replace(
        ':programId',
        program.id
      )}${REWARD_ACCEPTANCE_REVEAL}`,
      {
        state: {
          direction: isGift ? PageDirection.FORWARD : undefined,
          confetti: true,
        },
      }
    )
  }

  return (
    <AnimatedPage>
      <CardWithImage imageUrl={program?.email?.banner}>
        <ProgramInfo
          blurReward
          CTAText="Reveal"
          handleCTAClick={handleRevealClick}
        />
      </CardWithImage>
    </AnimatedPage>
  )
}
