import { DownloadOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import { isEmpty } from 'lodash-es'
import { useMemo } from 'react'
import { CSVLink } from 'react-csv'
import { BalanceDetailedReport } from 'types/TinyBird'
import { numToDollars } from 'utils'

interface ReportDownloadData {
  id: string
  date: string
  type: string
  description: string
  amount: string
}

export function DownloadBalanceDetailedReportButton({
  reports,
}: {
  reports: BalanceDetailedReport[]
}) {
  const formattedReports: ReportDownloadData[] = useMemo(() => {
    return reports.map(bdr => {
      return {
        id: bdr.id,
        date: bdr.created,
        type: bdr.type,
        description: bdr.description,
        amount: numToDollars(bdr.amount) || '',
      }
    })
  }, [reports])

  const headers = [
    { label: 'ID', key: 'id' },
    { label: 'Date', key: 'date' },
    { label: 'Type', key: 'type' },
    { label: 'Description', key: 'description' },
    { label: 'Amount', key: 'amount' },
  ]

  const disableDownloadBtn = isEmpty(formattedReports)

  return (
    <CSVLink
      data={formattedReports}
      headers={headers}
      filename="perkup_balance_detailed_report.csv"
    >
      <Button disabled={disableDownloadBtn} icon={<DownloadOutlined />}>
        Download detailed report
      </Button>
    </CSVLink>
  )
}
