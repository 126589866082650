import React, { PropsWithChildren } from 'react'

type SlotProps<TName extends string = string> = PropsWithChildren<{
  // eslint-disable-next-line react/no-unused-prop-types
  name: TName
}>

interface SlotOptions<TNames extends readonly string[]> {
  fallback?: Partial<Record<TNames[number], () => React.ReactNode>>
}

export function Slot<TName extends string>({ children }: SlotProps<TName>) {
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>
}

function isSlotElement<TNames extends readonly string[]>(
  child: React.ReactNode | undefined,
  names: TNames
): child is React.ReactElement<SlotProps<TNames[number]>> {
  return (
    React.isValidElement(child) &&
    child.type === Slot &&
    'name' in child.props &&
    names.includes(child.props.name as TNames[number])
  )
}

type TRes<TNames extends readonly string[]> = Record<
  TNames[number],
  React.ReactNode
>

/**
 * https://hipsterbrown.com/musings/musing/simple-slots-for-react/
 *
 * Helper function to destructure a parent component's children into multiple named slot components
 *
 * @param names the names of the components that you would like to destructure from your parent component's children
 */
export function getSlots<const TNames extends readonly string[]>(
  names: TNames,
  children: React.ReactNode | React.ReactNode[],
  options?: SlotOptions<TNames>
): TRes<TNames> {
  const slotsMap = names.reduce<TRes<TNames>>(
    (acc, name) => {
      const tName = name as TNames[number]
      const FallBackComponent = options?.fallback?.[tName]
      if (FallBackComponent) {
        acc[tName] = FallBackComponent()
      } else {
        acc[tName] = null
      }
      return acc
    },
    {} as unknown as TRes<TNames>
  )

  React.Children.forEach(children, child => {
    if (isSlotElement(child, names)) {
      const slotName = child.props.name
      slotsMap[slotName] = child
    }
  })

  return slotsMap
}
