import {
  CreditCardOutlined,
  DollarCircleOutlined,
  GiftOutlined,
  SkinOutlined,
} from '@ant-design/icons'
import { Button, Flex } from 'antd'
import { PERKUP_DOLLARS } from 'constants/newReward'
import { Program_RewardTab } from 'gen/perkup/v1/program_pb'

export function RewardTabs({
  selectedRewardTab,
  onSelectedRewardTab,
  hideDollarsTab = false,
  hideSwagTab = false,
  hideGiftsTab = false,
  hideGiftCardsTab = false,
}: {
  selectedRewardTab: Program_RewardTab
  onSelectedRewardTab: (selectedType: Program_RewardTab) => void
  hideDollarsTab?: boolean
  hideSwagTab?: boolean
  hideGiftsTab?: boolean
  hideGiftCardsTab?: boolean
}) {
  const rewardTypeOptions = [
    {
      label: PERKUP_DOLLARS,
      icon: <DollarCircleOutlined />,
      value: Program_RewardTab.perkupDollars,
      hidden: hideDollarsTab,
    },
    {
      label: 'Swag',
      icon: <SkinOutlined />,
      value: Program_RewardTab.swag,
      hidden: hideSwagTab,
    },
    {
      label: 'Gift',
      icon: <GiftOutlined />,
      value: Program_RewardTab.catalog,
      hidden: hideGiftsTab,
    },
    {
      label: 'Gift card',
      icon: <CreditCardOutlined />,
      value: Program_RewardTab.giftCard,
      hidden: hideGiftCardsTab,
    },
  ]

  return (
    <Flex gap={16}>
      {rewardTypeOptions.map(({ label, value, icon, hidden }) => {
        const isSelected = selectedRewardTab === value
        if (hidden) return null
        return (
          <Button
            key={value}
            size="large"
            style={{ width: 176 }}
            type={isSelected ? 'primary' : 'default'}
            shape="round"
            icon={icon}
            onClick={() => onSelectedRewardTab(value)}
            ghost={isSelected}
          >
            {label}
          </Button>
        )
      })}
    </Flex>
  )
}
