import { listenToProductVariantsByProductId } from 'api/databaseCalls'
import { ProductVariant } from 'gen/perkup/v1/product_variant_pb'
import useIds from 'hooks/useIds'
import { useEffect, useState } from 'react'

export default function useListenToProductVariantsByProductId({
  productId,
}: {
  productId: string | undefined
}) {
  const { orgId } = useIds()
  const [hasLoaded, setHasLoaded] = useState(false)
  const [allProductVariants, setAllProductVariants] = useState<
    ProductVariant[]
  >([])

  useEffect(() => {
    if (!productId) return undefined
    listenToProductVariantsByProductId({
      productId,
      cb: (productVariants: ProductVariant[]) => {
        setAllProductVariants(productVariants)
        setHasLoaded(true)
      },
      orgId,
    })
    return undefined
  }, [productId, orgId])

  return { allProductVariants, hasLoaded }
}
