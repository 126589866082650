import { captureException, captureMessage } from '@sentry/react'
import {
  eligibleIndividualStatuses,
  nonRemovedIndividualStatuses,
} from 'constants/individuals'
import dayjs from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'
import {
  FieldPath,
  collection,
  doc,
  getCountFromServer,
  getDoc,
  getDocs,
  getFirestore,
  onSnapshot,
  orderBy,
  query,
  where,
} from 'firebase/firestore'
import { Individual, Individual_Status } from 'gen/perkup/v1/individual_pb'
import { compact, filter } from 'lodash-es'
import { storedId } from 'utils/firestore'
import { toSentry } from 'utils/sentry'

dayjs.extend(isBetween)

/**
 * INDIVIDUALS
 */

export function ListenToIndividualsMissingField({
  orgId,
  fieldSelector,
  cb,
}: {
  orgId: string
  fieldSelector: 'dob' | 'startDate'
  cb: React.Dispatch<React.SetStateAction<Individual[]>>
}) {
  const db = getFirestore()
  const colRef = collection(
    db,
    `organizations/${orgId}/individuals`
  ).withConverter(storedId(Individual))
  const q = query(
    colRef,
    where('status', 'in', eligibleIndividualStatuses),
    orderBy('email', 'asc')
  )

  return onSnapshot(
    q,
    query => {
      const individuals = query.docs.map(doc => doc.data())

      const individualsMissingField = individuals.filter(
        individual => !individual[fieldSelector]
      )
      return cb(individualsMissingField)
    },
    error => {
      console.error(error)
      captureException(toSentry(error), {
        contexts: {
          ListenToIndividualsMissingField: { orgId, fieldSelector },
        },
        tags: { orgId },
      })
    }
  )
}

export function CountIndividualsMissingField({
  orgId,
  fieldSelector,
  cb,
}: {
  orgId: string
  fieldSelector: 'dob' | 'startDate'
  cb: (value: number | undefined) => void
}) {
  let queryFieldSelector: string
  switch (fieldSelector) {
    case 'startDate':
      queryFieldSelector = 'start_date'
      break
    default:
      queryFieldSelector = fieldSelector
      break
  }

  const beforeAllTime = new Date('1900-01-01')
  const db = getFirestore()
  const colRef = collection(db, `organizations/${orgId}/individuals`)
  const queryAll = query(
    colRef,
    where('status', 'in', eligibleIndividualStatuses)
  )
  const queryField = query(
    colRef,
    where('status', 'in', eligibleIndividualStatuses),
    where(queryFieldSelector, '>=', beforeAllTime)
  )

  async function getCount() {
    const all = await getCountFromServer(queryAll)
    const allIndividuals = all.data().count
    const field = await getCountFromServer(queryField)
    const fieldIndividuals = field.data().count
    cb(allIndividuals - fieldIndividuals)
  }

  getCount().catch(e => {
    console.error(e)
    captureException(toSentry(e), {
      contexts: {
        CountIndividualsMissingField: { orgId, fieldSelector },
      },
      tags: { orgId },
    })
  })
}

export async function GetIndividualIdsByEmail({
  email,
  orgId,
}: {
  email: string
  orgId: string
}) {
  try {
    const db = getFirestore()
    const colRef = collection(
      db,
      `organizations/${orgId}/individuals`
    ).withConverter(storedId(Individual))
    const q = query(colRef, where('email', '==', email.toLowerCase().trim()))
    return await getDocs(q).then(querySnapshot =>
      querySnapshot.docs.map(doc => doc.id)
    )
  } catch (error) {
    console.error(error)
    captureException(toSentry(error), {
      contexts: {
        GetIndividualIdsByEmail: { orgId, email },
      },
      tags: { orgId },
    })
  }
  return []
}

export async function GetIndividualById({
  orgId,
  individualId,
}: {
  orgId: string
  individualId: string
}) {
  if (!orgId || !individualId) return undefined
  try {
    const db = getFirestore()
    const docRef = doc(
      db,
      `organizations/${orgId}/individuals/${individualId}`
    ).withConverter(storedId(Individual))
    return await getDoc(docRef).then(doc => doc.data())
  } catch (error) {
    console.error(error)
    captureException(toSentry(error), {
      contexts: {
        GetIndividualById: { orgId, individualId },
      },
      tags: { orgId },
    })
  }
}

export async function GetIndividualByUserId({
  orgId,
  userId,
}: {
  orgId: string
  userId: string
}) {
  if (!userId || !orgId) return undefined

  const captureContext = {
    contexts: { GetIndividualByUserId: { orgId, userId } },
    tags: { orgId },
  }
  try {
    const db = getFirestore()
    const colRef = collection(
      db,
      `organizations/${orgId}/individuals`
    ).withConverter(storedId(Individual))
    const q = query(
      colRef,
      where('userId', '==', userId),
      where('status', 'in', nonRemovedIndividualStatuses)
    )
    return await getDocs(q).then(querySnapshot => {
      const individuals = querySnapshot.docs.map(doc => doc.data())
      if (individuals.length > 1) {
        captureMessage('More than one individual for user ID', captureContext)
      }
      return individuals[0] ?? undefined
    })
  } catch (error) {
    console.error(error)
    captureException(toSentry(error), captureContext)
  }
}

export async function GetIndividualByEmail({
  orgId,
  email,
}: {
  orgId: string
  email: string
}) {
  if (!email || !orgId) return undefined

  const captureContext = {
    contexts: { GetIndividualByEmail: { orgId, email } },
    tags: { orgId },
  }
  try {
    const db = getFirestore()
    const colRef = collection(
      db,
      `organizations/${orgId}/individuals`
    ).withConverter(storedId(Individual))
    const q = query(
      colRef,
      where(
        new FieldPath('emails', email.toLowerCase().trim(), 'verified'),
        '==',
        true
      ),
      where('status', 'in', nonRemovedIndividualStatuses)
    )
    return await getDocs(q).then(querySnapshot => {
      const individuals = querySnapshot.docs.map(doc => doc.data())
      if (individuals.length > 1) {
        captureMessage('More than one individual for user ID', captureContext)
      }
      return individuals[0] ?? undefined
    })
  } catch (error) {
    console.error(error)
    captureException(toSentry(error), captureContext)
  }
}

export function ListenToIndividualByEmail({
  orgId,
  email,
  cb,
}: {
  orgId: string
  email: string
  cb: (individual: Individual | undefined) => void
}) {
  if (!email || !orgId) return undefined

  const captureContext = {
    contexts: { ListenToIndividualByEmail: { orgId, email } },
    tags: { orgId },
  }

  const db = getFirestore()
  const colRef = collection(
    db,
    `organizations/${orgId}/individuals`
  ).withConverter(storedId(Individual))
  const q = query(
    colRef,

    where(
      new FieldPath('emails', email.toLowerCase().trim(), 'verified'),
      '==',
      true
    ),
    where('status', 'in', nonRemovedIndividualStatuses)
  )

  return onSnapshot(
    q,
    query => {
      const individuals = query.docs.map(doc => doc.data())
      if (individuals.length <= 1) {
        cb(individuals[0])
      } else {
        // Defense against multiple individuals with the same email if one is blocked
        const nonBlockedIndividuals = filter(
          individuals,
          individual => individual.status !== Individual_Status.blocked
        )
        cb(nonBlockedIndividuals[0])
        captureMessage('More than one individual for user ID', captureContext)
      }
    },
    error => {
      console.error(error)
      captureException(toSentry(error), captureContext)
    }
  )
}

export async function ListEligibleIndividualsByOrgId(orgId: string) {
  const individuals: Individual[] = []
  try {
    const db = getFirestore()
    const colRef = collection(
      db,
      `organizations/${orgId}/individuals`
    ).withConverter(storedId(Individual))
    const q = query(colRef, where('status', 'in', eligibleIndividualStatuses))
    const docsSnapshot = await getDocs(q)

    docsSnapshot.docs.forEach(doc => individuals.push(doc.data()))
  } catch (error) {
    console.error(error)
    captureException(toSentry(error), {
      contexts: {
        ListIndividualIdsByOrgId: { orgId },
      },
      tags: { orgId },
    })
  }
  return individuals
}

export async function ListNonRemovedIndividualsByIds({
  individualIds,
  orgId,
}: {
  individualIds: string[]
  orgId: string
}): Promise<Individual[]> {
  try {
    const individuals = compact(
      await Promise.all(
        individualIds.map(async individualId => {
          const individual = await GetIndividualById({ orgId, individualId })
          if (individual?.status === Individual_Status.removed) return undefined
          return individual
        })
      )
    )
    return individuals
  } catch (error) {
    const captureContext = {
      contexts: { ListNonRemovedIndividualsByIds: { orgId, individualIds } },
      tags: { orgId },
    }
    if (!orgId) captureMessage('Missing orgId', captureContext)
    console.error(error)
    captureException(toSentry(error), captureContext)
  }
  return []
}

export function ListenToIndividualById({
  orgId,
  individualId,
  cb,
}: {
  orgId: string
  individualId: string
  cb: (individual: Individual | undefined) => void
}) {
  const db = getFirestore()
  const docRef = doc(
    db,
    `organizations/${orgId}/individuals/${individualId}`
  ).withConverter(storedId(Individual))
  return onSnapshot(
    docRef,
    doc => {
      if (doc.exists()) {
        return cb(doc.data())
      }
      return cb(undefined)
    },
    error => {
      console.error(error)
      captureException(toSentry(error), {
        contexts: {
          ListenToIndividualById: { orgId, individualId },
        },
        tags: { orgId },
      })
      return cb(undefined)
    }
  )
}
