import {
  DENOMINATION,
  SIZE_OPTION,
  SORTED_SIZES,
} from 'constants/productVariants'
import { sortBy } from 'lodash-es'

export function sortProductOptions({
  productOptions,
  productOption,
}: {
  productOptions: string[]
  productOption: string
}) {
  const optionValues = sortBy(productOptions, value => value)
  const isSize = productOption.includes(SIZE_OPTION)
  const isMonetary = productOption === DENOMINATION
  if (isMonetary) {
    return sortBy(optionValues, Number)
  }
  if (isSize) {
    return optionValues.sort(
      (a, b) => SORTED_SIZES.indexOf(a) - SORTED_SIZES.indexOf(b)
    )
  }
  return optionValues
}
