import { BrandedTags } from 'components/Typography'
import { Heading, Pane } from 'evergreen-ui'
import { isEmpty } from 'lodash-es'
import { CoreValuesSelection } from 'pages/NewReward/components/CoreValuesSelection'

export function ProgramCoreValues({
  coreValues,
  setCoreValues,
  edit,
}: {
  coreValues: string[]
  setCoreValues: (coreValues: string[]) => void
  edit: boolean
}) {
  const hasCoreValues = !isEmpty(coreValues)

  return (
    <>
      <Heading size={100}>Core values</Heading>
      {!edit && hasCoreValues && <BrandedTags coreValues={coreValues} />}
      {(edit || !hasCoreValues) && (
        <Pane maxWidth={360}>
          <CoreValuesSelection
            showTitle={false}
            setSelectedCoreValues={setCoreValues}
            selectedCoreValues={coreValues}
          />
        </Pane>
      )}
    </>
  )
}
