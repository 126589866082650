import {
  Heading,
  Pane,
  PaneOwnProps,
  Paragraph,
  PolymorphicBoxProps,
} from 'evergreen-ui'

export function AmazonReturnPolicy(
  paneProps: PolymorphicBoxProps<'div', PaneOwnProps>
) {
  return (
    <Pane {...paneProps}>
      <Heading marginBottom={8} size={400}>
        Return Policy
      </Heading>
      <Paragraph color="muted" size={300}>
        PerkUp cannot support Amazon returns related to sizing, address changes,
        or buyer’s remorse. If you have doubts about your order, you can always
        shop directly on Amazon.com with your Perk Card.
      </Paragraph>
      <Paragraph marginTop={8} color="muted" size={300}>
        Get in touch with PerkUp Support within 14 days if your order arrived
        defective/damaged or has not arrived within 5 days of the estimated
        delivery date.
      </Paragraph>
    </Pane>
  )
}
