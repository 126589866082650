import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  SyncOutlined,
} from '@ant-design/icons'
import { OrderFulfillment_FulfillmentStatus_Enum } from 'gen/perkup/v1/vendor_pb'

export const GHOST_FULFILLMENT_NAME = 'ghost'

const ORDER_FULFILLMENT_STATUS_BASE: Record<
  number | string,
  {
    label: string
    color: string
    Icon?: any
  }
> = {
  [OrderFulfillment_FulfillmentStatus_Enum.FULFILLMENT_STATUS_UNSPECIFIED]: {
    label: 'Unspecified',
    color: 'default',
  },
  [OrderFulfillment_FulfillmentStatus_Enum.pending]: {
    label: 'Fulfillment in progress',
    color: 'gold',
    Icon: SyncOutlined,
  },
  [OrderFulfillment_FulfillmentStatus_Enum.open]: {
    label: 'Open',
    color: 'primary',
  },
  [OrderFulfillment_FulfillmentStatus_Enum.success]: {
    label: 'Fulfilled',
    color: 'success',
    Icon: CheckCircleOutlined,
  },

  [OrderFulfillment_FulfillmentStatus_Enum.cancelled]: {
    label: 'Cancelled',
    color: 'error',
    Icon: CloseCircleOutlined,
  },

  [OrderFulfillment_FulfillmentStatus_Enum.error]: {
    label: 'Error',
    color: 'error',
  },

  [OrderFulfillment_FulfillmentStatus_Enum.failure]: {
    label: 'Failure',
    color: 'error',
  },
}

export const ORDER_FULFILLMENT_STATUS = {
  ...Object.fromEntries(
    Object.values(OrderFulfillment_FulfillmentStatus_Enum).map(status => [
      status,
      ORDER_FULFILLMENT_STATUS_BASE[
        OrderFulfillment_FulfillmentStatus_Enum[status as number]
      ],
    ])
  ),
  ...ORDER_FULFILLMENT_STATUS_BASE,
}
