import { Avatar, Flex, Tooltip } from 'antd'
import Skeleton from 'antd/es/skeleton/Skeleton'
import { productCollectionPermissionToLabel } from 'constants/productCollections'
import { Text } from 'evergreen-ui'
import { ProductCollection_Permission } from 'gen/perkup/v1/product_collections_pb'
import { useListIndividualsByIds } from 'hooks/individuals/useListIndividualsByIds'
import { useMemo } from 'react'
import {
  PERMISSION_ADMIN,
  PERMISSION_MANAGER,
  Permissions,
} from 'types/Permissions'
import { buildFullName, getInitials } from 'utils'

function AvatarGroupSkeleton() {
  return (
    <Avatar.Group>
      <Skeleton.Avatar
        active
        shape="circle"
        style={{ height: 20, width: 20 }}
      />
      <Skeleton.Avatar
        active
        shape="circle"
        style={{ height: 20, width: 20 }}
      />
      <Skeleton.Avatar
        active
        shape="circle"
        style={{ height: 20, width: 20 }}
      />
    </Avatar.Group>
  )
}

function IndividualsPermissions({ permissions }: { permissions: Permissions }) {
  const individualIdsOnPermissions = useMemo(() => {
    return Object.keys(permissions).filter(
      id => ![PERMISSION_ADMIN, PERMISSION_MANAGER].includes(id)
    )
  }, [permissions])

  const { individuals, isLoading } = useListIndividualsByIds({
    individualIds: individualIdsOnPermissions,
  })

  if (isLoading) return <AvatarGroupSkeleton />

  if (individuals.length === 0) return <Text>No access</Text>

  return (
    <Flex gap={8} align="center">
      <Text>{individuals.length}</Text>

      <Avatar.Group
        size={24}
        max={{
          count: 4,
        }}
      >
        {individuals.map(
          ({ firstName, lastName, profilePicture, id, email }) => {
            // Get initials
            const displayName =
              buildFullName({
                firstName,
                lastName,
              }) || email

            return (
              <Tooltip key={id} title={displayName}>
                <Avatar
                  key={id}
                  src={profilePicture}
                  style={{ cursor: 'default' }}
                >
                  {getInitials(displayName)}
                </Avatar>
              </Tooltip>
            )
          }
        )}
      </Avatar.Group>
    </Flex>
  )
}

export function PermissionsBreakdown({
  permissions,
}: {
  permissions: Permissions
}) {
  const determinePermissionLabel = (level: string) => {
    const permissionOnCollection =
      permissions[level] ?? ProductCollection_Permission.PERMISSION_UNSPECIFIED

    const labelForPermission =
      productCollectionPermissionToLabel[permissionOnCollection]

    return labelForPermission
  }

  return (
    <Flex gap={16}>
      <Flex vertical>
        <Text color="muted">Admins</Text>
        <Text>{determinePermissionLabel(PERMISSION_ADMIN)}</Text>
      </Flex>
      <Flex vertical>
        <Text color="muted">Managers</Text>
        <Text>{determinePermissionLabel(PERMISSION_MANAGER)}</Text>
      </Flex>
      <Flex vertical>
        <Text color="muted">Specific users</Text>
        <IndividualsPermissions permissions={permissions} />
      </Flex>
    </Flex>
  )
}
