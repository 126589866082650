import { Tag } from 'antd'
import { Program, ProgramStatus } from 'gen/perkup/v1/program_pb'

interface ProgramStatusTagType {
  color: 'green' | 'blue' | 'default' | 'red'
  label: 'Active' | 'Scheduled' | 'Expired' | 'Deleted'
}
export function ProgramStatusTag({ program }: { program: Program }) {
  const { status, isDeleted, startsOn, endsOn } = program

  const today = new Date()

  const determineStatusToDisplay = (): ProgramStatusTagType => {
    if (isDeleted) return { color: 'red', label: 'Deleted' }

    if (status === ProgramStatus.inactive) {
      if (startsOn && startsOn.toDate() > today)
        return { color: 'blue', label: 'Scheduled' }

      if (endsOn && endsOn.toDate() < today)
        return { color: 'default', label: 'Expired' }
    }

    return { color: 'green', label: 'Active' }
  }

  const { color, label } = determineStatusToDisplay()

  return <Tag color={color}>{label}</Tag>
}
