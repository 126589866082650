export const ITEM_TOTAL = 'Item total:'
export const SHIPPING_AND_HANDLING = 'Shipping & handling:'
export const TOTAL_BEFORE_TAX = 'Total before tax:'
export const ESTIMATED_TAX = 'Estimated tax:'

export enum PaymentMethod {
  PERKUP_BALANCE = 'PerkUp balance',
  CREDIT_CARD = 'Credit card',
  ORG_BALANCE = 'Organization balance',
}
