import { captureMessage } from '@sentry/react'
import InputNumber from 'antd/es/input-number'
import { updateEmailNotificationAction } from 'api/databaseCalls'
import { OrgContext } from 'context'
import { Alert, Pane, Text, toaster } from 'evergreen-ui'
import { Action } from 'gen/perkup/v1/rules_pb'
import { useContext } from 'react'

export default function EmailConfigForm({
  notificationAction,
}: {
  notificationAction: Action | undefined
}) {
  const org = useContext(OrgContext)
  const orgId = org?.id

  const handleChangeDateOffset = (value: number | null) => {
    if (!value) {
      return
    }
    if (!notificationAction) {
      const errorMsg = 'Error loading notification settings'
      captureMessage(errorMsg, 'error')
      return
    }
    updateEmailNotificationAction({
      orgId,
      ruleId: notificationAction.ruleId,
      actionType: notificationAction.actionType,
      dateOffset: value,
    }).then(() => {
      toaster.success(`Notication set to ${value} days before`)
    })
  }

  if (!notificationAction) {
    const errorMsg = 'Error loading notification settings'
    captureMessage(errorMsg)
    return (
      <Pane>
        <Alert title={errorMsg} intent="warning" />
      </Pane>
    )
  }

  return (
    <Pane display="grid" gridTemplateColumns="1fr 1fr" gap={16}>
      <Pane display="flex" gap={32} alignItems="center">
        <Text>Days before</Text>
        <Pane>
          <InputNumber
            min={0}
            value={notificationAction?.dateOffset || 3}
            onChange={handleChangeDateOffset}
          />
        </Pane>
      </Pane>
    </Pane>
  )
}
