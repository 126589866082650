import { SuperBulkProductRowCard } from 'components'
import { ProductVariant } from 'gen/perkup/v1/product_variant_pb'
import { groupBy } from 'lodash-es'
import { MaybeWithDesign, WithSelectedQuantity } from 'types'
import { smallDesignHash } from 'utils/canvas-design'

export function SuperLines({
  productVariants,
  onChange,
  onAddMoreClick,
}: {
  productVariants: MaybeWithDesign<WithSelectedQuantity<ProductVariant>>[]
  onChange: (
    productVariants: MaybeWithDesign<WithSelectedQuantity<ProductVariant>>[]
  ) => void
  onAddMoreClick: (
    productVariant: MaybeWithDesign<WithSelectedQuantity<ProductVariant>>
  ) => void
}) {
  const productVariantsWithoutDesigns = productVariants.filter(
    pv => !pv.canvasDesign
  )

  const unDesignedDictionary = groupBy(
    productVariantsWithoutDesigns,
    'productId'
  )

  const productVariantsWithDesigns = productVariants.filter(
    pv => !!pv.canvasDesign
  )

  const designedDictionary = groupBy(productVariantsWithDesigns, pv =>
    smallDesignHash(pv.productId, pv.canvasDesign?.variables ?? [])
  )

  const handleUnDesignedGroupUpdate = (
    productId: string,
    updatedUnDesignedPvs: WithSelectedQuantity<ProductVariant>[]
  ) => {
    const allTheOtherUnDesignedPvs = productVariantsWithoutDesigns.filter(
      pv => pv.productId !== productId
    )

    onChange([
      ...productVariantsWithDesigns,
      ...updatedUnDesignedPvs,
      ...allTheOtherUnDesignedPvs,
    ])
  }

  const handleRemoveEntireUnDesignedProduct = (productId: string) => {
    const updatedUnDesignedPvs = productVariantsWithoutDesigns.filter(
      pv => pv.productId !== productId
    )
    onChange([...productVariantsWithDesigns, ...updatedUnDesignedPvs])
  }

  const handleDesignedGroupUpdate = (
    hash: string,
    updatedDesignedPvs: MaybeWithDesign<WithSelectedQuantity<ProductVariant>>[]
  ) => {
    const allOtherDesignedPvs = Object.entries(designedDictionary).reduce(
      (acc, [key, value]) => {
        if (key !== hash) {
          return [...acc, ...value]
        }
        return acc
      },
      [] as MaybeWithDesign<WithSelectedQuantity<ProductVariant>>[]
    )

    onChange([
      ...productVariantsWithoutDesigns,
      ...updatedDesignedPvs,
      ...allOtherDesignedPvs,
    ])
  }

  const handleRemoveEntireDesignedProduct = (hash: string) => {
    const updatedDesignedPvs = Object.entries(designedDictionary).reduce(
      (acc, [key, value]) => {
        if (key !== hash) {
          return [...acc, ...value]
        }
        return acc
      },
      [] as MaybeWithDesign<WithSelectedQuantity<ProductVariant>>[]
    )

    onChange([...productVariantsWithoutDesigns, ...updatedDesignedPvs])
  }

  return (
    <section className="flex flex-col gap-2">
      {Object.entries(unDesignedDictionary)
        .sort() // This ensures that the order of the uncustomized products is consistent
        .map(([productId, productVariants]) => (
          <SuperBulkProductRowCard
            key={productId}
            selectedProductVariants={productVariants}
            onUpdate={updatedPvs =>
              handleUnDesignedGroupUpdate(productId, updatedPvs)
            }
            onAddMoreClick={onAddMoreClick}
            onRemoveClick={() => handleRemoveEntireUnDesignedProduct(productId)}
          />
        ))}
      {Object.entries(designedDictionary)
        .sort() // This ensures that the order of the customized products is consistent
        .map(([hash, productVariants]) => (
          <SuperBulkProductRowCard
            key={hash}
            selectedProductVariants={productVariants}
            onUpdate={updatedPvs => handleDesignedGroupUpdate(hash, updatedPvs)}
            onAddMoreClick={onAddMoreClick}
            onRemoveClick={() => handleRemoveEntireDesignedProduct(hash)}
          />
        ))}
    </section>
  )
}
