import { inviteMember, updateIndividualStatus } from 'api/databaseCalls'
import { SendEmail } from 'api/functionCalls'
import * as EVENTS from 'constants/events'
import { OrgContext, UserContext } from 'context'
import { Button, Pane, toaster } from 'evergreen-ui'
import {
  Individual,
  Individual_Role,
  Individual_Status,
} from 'gen/perkup/v1/individual_pb'
import { useContext, useState } from 'react'
import { useInstantSearch } from 'react-instantsearch'
import { logEvent } from 'utils'

export default function InviteIndividualButton({
  individual,
  onClick,
}: {
  individual: Individual
  onClick?: () => void
}) {
  const blocked = individual.status === Individual_Status.blocked
  const org = useContext(OrgContext)
  const user = useContext(UserContext)
  const userId = user?.id

  const [isInviting, setIsInviting] = useState(false)
  const [isDisabled, setIsDisabled] = useState(false)

  const { refresh } = useInstantSearch()

  const handleInvite = async () => {
    const { email } = individual
    if (onClick) onClick()
    if (!email) {
      toaster.warning('User is missing a work email address')
      return
    }
    setIsInviting(true)
    setIsDisabled(true)

    inviteMember({ orgId: org.id, email, role: Individual_Role.member })
    if (process.env.NODE_ENV === 'production') {
      SendEmail({
        toAddress: email,
        fromName: `${user.profile?.firstName} ${user.profile?.lastName}`,
        fromEmail: individual.email,
        orgName: org.name,
      })
        .then(() => {
          // Track member invite sent event
          logEvent(EVENTS.MEMBER_INVITED, {
            email,
            orgId: org.id,
            orgName: org.name,
            userId,
          })
          updateIndividualStatus({
            orgId: org.id,
            individualId: individual.id,
            status: Individual_Status.invited,
          })
          toaster.success(`Successfully sent invite to ${individual.email}`)
        })
        .catch(() =>
          toaster.danger(`Error sending invite to ${individual.firstName}`)
        )
        .finally(() => {
          refresh()
          setIsInviting(false)
        })
    } else {
      toaster.success(`Successfully sent invite to ${individual.email}`)
      await updateIndividualStatus({
        orgId: org.id,
        individualId: individual.id,
        status: Individual_Status.invited,
      })
      refresh()
      setIsInviting(false)
    }
  }

  if (!individual || blocked) return null

  return (
    <Pane>
      <Button
        appearance="primary"
        size="small"
        onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
          e.stopPropagation()
          handleInvite()
        }}
        isLoading={isInviting}
        disabled={isDisabled}
      >
        Invite
      </Button>
    </Pane>
  )
}
