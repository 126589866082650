import {
  GetCountryById,
  updateUserCountry,
  UpdateUserCurrency,
} from 'api/databaseCalls'
import { currencySymbols } from 'constants/currencies'
import { CountriesContext, CountryContext } from 'context'
import { findFlagUrlByIso2Code } from 'country-flags-svg'
import { Avatar, Position, SelectMenu, toaster } from 'evergreen-ui'
import { RootUser } from 'gen/perkup/v1/root_user_pb'
import { useContext } from 'react'
import { getCountryIsoAlpha2 } from 'utils'

export function CountrySelection({ user }: { user: RootUser }) {
  const userId = user?.id
  const country = useContext(CountryContext)
  const countries = useContext(CountriesContext)

  const countryFlag = country?.iso3
    ? findFlagUrlByIso2Code(getCountryIsoAlpha2(country.iso3))
    : undefined

  const handleSelectCountry = ({ id, name }: { id: string; name: string }) => {
    updateUserCountry({ userId, countryId: id })

    GetCountryById({ countryId: id }).then(country => {
      // Update currency is one is available with the country
      if (
        country?.currency &&
        currencySymbols[country?.currency as keyof typeof currencySymbols]
      ) {
        UpdateUserCurrency({
          userId,
          displayCurrency: country.currency,
        })
      }
    })

    toaster.success(`Country updated to ${name}`, { id })
  }

  // Component must receive a userId in order to render
  if (!userId) return null

  return (
    <SelectMenu
      title="Select country"
      options={countries?.map(country => ({
        label: country.name,
        value: country.id,
        icon: findFlagUrlByIso2Code(getCountryIsoAlpha2(country.iso3)),
      }))}
      onSelect={item =>
        handleSelectCountry({ id: item.value.toString(), name: item.label })
      }
      closeOnSelect
      position={Position.BOTTOM_RIGHT}
    >
      <Avatar
        size={24}
        src={countryFlag}
        shape="square"
        width={40}
        borderRadius="3px"
      />
    </SelectMenu>
  )
}
