import { Flex } from 'antd'
import { PerkImage, PerkLink } from 'components'
import { NUMBER_GREEN } from 'constants/colors'
import { DEFAULT_ROUTES } from 'constants/routes'
import { Text } from 'evergreen-ui'
import { ProductCollection } from 'gen/perkup/v1/product_collections_pb'
import { PropsWithChildren, createContext, useContext } from 'react'
import { makePlural, numToDollars } from 'utils'
import { getCollectionRange } from 'utils/productCollections'

const ProductCollectionContext = createContext<ProductCollection>(undefined!)

function ProductCollectionThumbnailDetails() {
  const collection = useContext(ProductCollectionContext)
  const { productsCount } = collection

  return (
    <Flex flex={1} vertical gap={4}>
      <PerkLink
        target="_blank"
        rel="noopener noreferrer"
        to={`${DEFAULT_ROUTES.ORGANIZATION.SWAG.COLLECTIONS}/${collection.id}`}
        style={{ fontSize: 14 }}
      >
        {collection.name}
      </PerkLink>
      <Text color="muted">{`${productsCount} ${makePlural('product', Number(productsCount))}`}</Text>
    </Flex>
  )
}

function ProductCollectionThumbnailPriceRange() {
  const collection = useContext(ProductCollectionContext)

  const { productsCount } = collection

  if (!productsCount) return null

  const { minCollectionValue, maxCollectionValue } =
    getCollectionRange(collection)

  return (
    <Text color={NUMBER_GREEN}>
      {numToDollars(minCollectionValue, 2, false)}
      {' - '}
      {numToDollars(maxCollectionValue, 2, false)}
    </Text>
  )
}

function ProductCollectionThumbnailCta({ children }: PropsWithChildren<{}>) {
  return <Flex>{children}</Flex>
}

function ProductCollectionThumbnail({
  productCollection,
  children,
}: PropsWithChildren<{
  productCollection: ProductCollection
}>) {
  return (
    <ProductCollectionContext.Provider value={productCollection}>
      <Flex gap={16} align="center">
        {/** This div is to make the preview however effect on the image also have a border radius */}
        <div style={{ borderRadius: 8, overflow: 'hidden' }}>
          <PerkImage
            preview
            src={productCollection?.imageUrl}
            width={180}
            sizes="360px" // Purposefully downloading a way larger image to keep product team happy
            style={{
              aspectRatio: '16 / 9',
              objectFit: 'cover',
            }}
          />
        </div>
        {children}
      </Flex>
    </ProductCollectionContext.Provider>
  )
}

ProductCollectionThumbnail.Details = ProductCollectionThumbnailDetails
ProductCollectionThumbnail.PriceRange = ProductCollectionThumbnailPriceRange
ProductCollectionThumbnail.Cta = ProductCollectionThumbnailCta
export default ProductCollectionThumbnail
