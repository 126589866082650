import { captureException } from '@sentry/react'
import {
  collection,
  getDocs,
  getFirestore,
  query,
  where,
} from 'firebase/firestore'
import { BulkSwagCatalog } from 'gen/perkup/v1/contentful_pb'
import { toSentry } from 'utils'
import { storedId } from 'utils/firestore'

export async function ListBulkSwagCatalogsByOrgId(orgId: string) {
  try {
    const db = getFirestore()
    const colRef = collection(db, 'bulkSwagCatalogs').withConverter(
      storedId(BulkSwagCatalog)
    )

    const q = query(colRef, where('orgId', '==', orgId))

    const qSnaps = await getDocs(q)

    return qSnaps.docs.map(doc => doc.data())
  } catch (e) {
    console.error(e)
    captureException(toSentry(e), {
      contexts: {
        ListBulkSwagCatalogsByOrgId: {
          orgId,
        },
      },
    })
    return []
  }
}
