import { DeleteOutlined, PlusOutlined } from '@ant-design/icons'
import { Button, Modal } from 'antd'
import { Pane } from 'evergreen-ui'
import { ShippingAddress } from 'gen/perkup/v1/root_user_pb'
import { useState } from 'react'
import { isMobile } from 'react-device-detect'
import { AddressDisplay } from './address-display'
import { AddressesList } from './addresses-list'
import { NewAddressButton } from './new-address-button'

/**
 *
 * Maybe try and move new-addresses-button.tsx to this file
 */
export function ChangeAddressButton({
  showDeleteButton = false,
  asPrimaryButton = false,
  selectedAddress,
  shippingAddresses,
  onAddressChange,
}: {
  showDeleteButton?: boolean
  asPrimaryButton?: boolean
  selectedAddress?: ShippingAddress
  shippingAddresses: ShippingAddress[]
  onAddressChange: (addressFormData?: ShippingAddress) => void
}) {
  const [showAddressesDialog, setShowAddressesDialog] = useState(false)
  const [showRemoveDialog, setShowRemoveDialog] = useState(false)

  const hasOtherShippingAddresses = shippingAddresses.length > 1

  const addressesDialog = (
    <Modal
      key="addresses-dialog"
      open={showAddressesDialog}
      onClose={() => setShowAddressesDialog(false)}
      title="Choose your address"
      footer={null}
      onCancel={() => setShowAddressesDialog(false)}
      styles={{
        body: {
          height: 'fit-content',
        },
      }}
    >
      <AddressesList
        onSelectAddress={address => {
          onAddressChange(address)
          setShowAddressesDialog(false)
        }}
        selectedAddress={selectedAddress}
        shippingAddresses={shippingAddresses}
      />
    </Modal>
  )

  if (selectedAddress) {
    return (
      <>
        {/** ADDRESS DISPLAY WITH EDIT AND REMOVE BUTTONS */}
        <Pane
          flex={1}
          display="flex"
          flexDirection="column"
          minWidth={isMobile ? undefined : 248}
        >
          <AddressDisplay address={selectedAddress} />

          <Pane marginTop={8} display="flex" gap={8}>
            <Button onClick={() => setShowAddressesDialog(true)}>Edit</Button>

            {showDeleteButton && (
              <Button
                onClick={() => setShowRemoveDialog(true)}
                type="text"
                icon={<DeleteOutlined />}
              >
                Remove
              </Button>
            )}
          </Pane>
        </Pane>

        {/** ADDRESSES DIALOG */}
        {addressesDialog}

        {/** REMOVE DIALOG */}
        <Modal
          title="Remove shipping address"
          open={showRemoveDialog}
          destroyOnClose
          afterClose={() => setShowRemoveDialog(false)}
          onOk={() => {
            onAddressChange(undefined)
            setShowRemoveDialog(false)
          }}
          onCancel={() => setShowRemoveDialog(false)}
          okText="Remove"
        >
          <AddressDisplay address={selectedAddress} />
        </Modal>
      </>
    )
  }

  return (
    <>
      {/** ADD EXISTING OR NEW ADDRESS */}
      {hasOtherShippingAddresses ? (
        <Button
          type={asPrimaryButton ? 'primary' : 'default'}
          style={{ width: 'fit-content' }}
          onClick={() => setShowAddressesDialog(true)}
        >
          Add address
        </Button>
      ) : (
        <NewAddressButton
          asPrimaryButton={asPrimaryButton}
          addAddressCTALabel="Add address"
          submitLabel="Add"
          dialogTitle="Add a new address"
          icon={<PlusOutlined />}
          asSmallButton
          onAddressSubmit={onAddressChange}
        />
      )}

      {/** ADDRESSES DIALOG */}
      {addressesDialog}
    </>
  )
}
