import { captureException } from '@sentry/react'
import { ALL_FILTER_OPTION } from 'constants/browseGifts'
import {
  collection,
  doc,
  getCountFromServer,
  getDoc,
  getDocs,
  getFirestore,
  limit as queryLimit,
  orderBy,
  query,
  QueryConstraint,
  QueryDocumentSnapshot,
  startAfter,
  where,
} from 'firebase/firestore'
import {
  Vendor,
  VendorCountry,
  VendorProduct,
  VendorProductPrice,
  VendorProductSubCategory,
  VendorSubCategoryGroup,
} from 'gen/perkup/v1/vendor_pb'
import { PriceRange } from 'types/BrowseGifts'
import { converter, storedId } from 'utils/firestore'
import { toSentry } from 'utils/sentry'
import { constructVendorProductCategories } from 'utils/vendors'

/**
 * GIFTS
 */
export async function ListVendorCountries() {
  const countries: VendorCountry[] = []
  try {
    const db = getFirestore()
    const colRef = collection(db, 'vendorCountries').withConverter(
      converter(VendorCountry)
    )
    const q = query(colRef, orderBy('name', 'asc'))
    await getDocs(q).then(query =>
      query.docs.forEach(doc => countries.push(doc.data()))
    )
  } catch (error) {
    console.error(error)
    captureException(toSentry(error), {
      contexts: {
        ListVendorCountries: {},
      },
    })
  }
  return countries
}

export async function ListVendorProductPricesBySubCategories({
  subCategories,
  priceRange,
  limit,
  queryIndex,
}: {
  subCategories: string[]
  priceRange?: PriceRange
  limit?: number
  queryIndex?: QueryDocumentSnapshot<VendorProductPrice>
}) {
  if (!subCategories?.length) return []
  const prices: QueryDocumentSnapshot<VendorProductPrice>[] = []
  try {
    const db = getFirestore()
    const colRef = collection(db, 'vendorProductPrices').withConverter(
      converter(VendorProductPrice)
    )
    const queryConstraints: QueryConstraint[] = [
      where('subCategories', 'array-contains-any', subCategories),
      ...(priceRange ? [where('totalAmount', '>=', priceRange[0])] : []),

      orderBy('totalAmount', 'desc'),
      ...(queryIndex ? [startAfter(queryIndex)] : []),
      ...(limit ? [queryLimit(limit)] : []),
    ]
    if (priceRange && priceRange[1] >= 0) {
      queryConstraints.push(where('totalAmount', '<=', priceRange[1]))
    }

    const q = query(colRef, ...queryConstraints)

    await getDocs(q).then(query => query.docs.forEach(doc => prices.push(doc)))
  } catch (error) {
    console.error(error)
    captureException(toSentry(error), {
      contexts: {
        ListVendorProductPricesBySubCategories: {
          subCategories,
          priceRange,
          limit,
          queryIndex,
        },
      },
    })
  }
  return prices
}

export async function ListVendorProductPricesByCountryCode({
  priceRange,
  countryCode,
  limit,
}: {
  priceRange?: PriceRange
  countryCode: string
  limit?: number
}) {
  const prices: VendorProductPrice[] = []

  try {
    const db = getFirestore()
    const colRef = collection(db, 'vendorProductPrices').withConverter(
      converter(VendorProductPrice)
    )
    const queryConstraints: QueryConstraint[] = []

    if (priceRange) {
      queryConstraints.push(where('totalAmount', '>=', priceRange[0]))
    }
    if (priceRange && priceRange[1] >= 0) {
      queryConstraints.push(where('totalAmount', '<=', priceRange[1]))
    }

    if (countryCode && countryCode !== ALL_FILTER_OPTION.value) {
      queryConstraints.push(where('iso2s', 'array-contains', countryCode))
    }

    const q = limit
      ? query(colRef, ...queryConstraints, queryLimit(limit))
      : query(colRef, ...queryConstraints)

    await getDocs(q).then(query =>
      query.docs.forEach(doc => prices.push(doc.data()))
    )
  } catch (error) {
    console.error(error)
    captureException(toSentry(error), {
      contexts: {
        ListVendorProductPricesByCountryCode: {
          priceRange,
          countryCode,
          limit,
        },
      },
    })
  }
  return prices
}

export async function CountVendorProductPricesBySubCategories({
  priceRange,
  subCategories,
  limit,
}: {
  priceRange: PriceRange
  subCategories: string[]
  limit?: number
}) {
  try {
    const db = getFirestore()
    const colRef = collection(db, 'vendorProductPrices').withConverter(
      converter(VendorProductPrice)
    )
    const queryConstraints: QueryConstraint[] = [
      where('totalAmount', '>=', priceRange[0]),
      where('totalAmount', '<=', priceRange[1]),
      where('subCategories', 'array-contains-any', subCategories),
    ]

    const q = limit
      ? query(colRef, ...queryConstraints, queryLimit(limit))
      : query(colRef, ...queryConstraints)

    const snapshot = await getCountFromServer(q)

    return snapshot.data().count
  } catch (error) {
    console.error(error)
    captureException(toSentry(error), {
      contexts: {
        CountVendorProductPricesBySubCategories: {
          priceRange,
          subCategories,
          limit,
        },
      },
    })
  }
  return undefined
}

export async function ListVendorProductPricesByProductId({
  productId,
  countryCode,
}: {
  productId: string
  countryCode?: string
}) {
  const prices: VendorProductPrice[] = []

  try {
    const db = getFirestore()
    const colRef = collection(db, 'vendorProductPrices').withConverter(
      converter(VendorProductPrice)
    )

    const queryConstraints: QueryConstraint[] = [
      ...(countryCode ? [where('iso2s', 'array-contains', countryCode)] : []),
      where('productId', '==', productId),
      orderBy('totalAmount', 'asc'),
    ]

    const q = query(colRef, ...queryConstraints)

    await getDocs(q).then(query =>
      query.docs.forEach(doc => prices.push(doc.data()))
    )
  } catch (error) {
    console.error(error)
    captureException(toSentry(error), {
      contexts: {
        ListVendorProductPricesByProductId: { productId, countryCode },
      },
    })
  }
  return prices
}

export async function ListVendorProductPricesByVendorId({
  vendorId,
}: {
  vendorId: string
}) {
  const prices: VendorProductPrice[] = []

  try {
    const db = getFirestore()
    const colRef = collection(db, 'vendorProductPrices').withConverter(
      converter(VendorProductPrice)
    )
    const q = query(
      colRef,
      where('vendorId', '==', vendorId),
      orderBy('totalAmount', 'asc')
    )
    await getDocs(q).then(query =>
      query.docs.forEach(doc => prices.push(doc.data()))
    )
  } catch (error) {
    console.error(error)
    captureException(toSentry(error), {
      contexts: {
        ListVendorProductPricesByVendorId: { vendorId },
      },
    })
  }
  return prices
}

export async function GetVendorById({ vendorId }: { vendorId: string }) {
  try {
    const db = getFirestore()
    const docRef = doc(db, `vendors/${vendorId}`).withConverter(
      converter(Vendor)
    )
    return await getDoc(docRef).then(doc => doc.data())
  } catch (error) {
    console.error(error)
    captureException(toSentry(error), {
      contexts: {
        GetVendorById: { vendorId },
      },
    })
  }
}

export async function ListVendorProductsById({
  vendorId,
}: {
  vendorId: string
}) {
  const products: VendorProduct[] = []

  try {
    const db = getFirestore()
    const colRef = collection(db, 'vendorProducts').withConverter(
      converter(VendorProduct)
    )
    const q = query(colRef, where('vendorId', '==', vendorId))
    await getDocs(q).then(query =>
      query.docs.forEach(doc => products.push(doc.data()))
    )
  } catch (error) {
    console.error(error)
    captureException(toSentry(error), {
      contexts: {
        ListVendorProductsById: { vendorId },
      },
    })
  }
  return products
}

export async function GetVendorProductById({
  productId,
}: {
  productId: string
}) {
  try {
    const db = getFirestore()
    const docRef = doc(db, `vendorProducts/${productId}`).withConverter(
      converter(VendorProduct)
    )
    return await getDoc(docRef).then(doc => doc.data())
  } catch (error) {
    console.error(error)
    captureException(toSentry(error), {
      contexts: {
        GetVendorProductById: { productId },
      },
    })
  }
}

export async function ListVendorProductCategories() {
  try {
    const db = getFirestore()
    const colRef = collection(db, 'vendorProductSubCategories').withConverter(
      storedId(VendorProductSubCategory)
    )
    const q = query(colRef, orderBy('categoryName', 'asc'))
    return await getDocs(q).then(query =>
      constructVendorProductCategories(query.docs.map(doc => doc.data()))
    )
  } catch (error) {
    console.error(error)
    captureException(toSentry(error), {
      contexts: {
        ListVendorProductCategories: {},
      },
    })
  }
  return []
}

export async function ListVendorSubCategoryGroups() {
  const groups: VendorSubCategoryGroup[] = []
  try {
    const db = getFirestore()

    const colRef = collection(db, 'vendorSubCategoryGroups').withConverter(
      converter(VendorSubCategoryGroup)
    )
    const q = query(colRef)

    const groupsDocs = await getDocs(q)

    groupsDocs.forEach(group => groups.push(group.data()))
  } catch (error) {
    console.error(error)
    captureException(toSentry(error), {
      contexts: {
        ListVendorSubCategoryGroups: {},
      },
    })
  }
  return groups
}

export async function GetVendorSubCategoryGroupById({
  groupId,
}: {
  groupId: string
}) {
  try {
    const db = getFirestore()
    const docRef = doc(db, `vendorSubCategoryGroups/${groupId}`).withConverter(
      converter(VendorSubCategoryGroup)
    )
    return await getDoc(docRef).then(doc => doc.data())
  } catch (error) {
    console.error(error)
    captureException(toSentry(error), {
      contexts: {
        GetVendorSubCategoryGroupById: { groupId },
      },
    })
  }
  return undefined
}
