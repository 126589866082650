import { Button, ButtonProps, Tooltip, TooltipProps } from 'antd'

export function PerkIconTooltipButton(
  props: ButtonProps & {
    title: string // This ensures that title prop is required
    placement?: TooltipProps['placement']
    open?: TooltipProps['open']
    icon: React.ReactNode // This ensures a icon prop is required
  }
) {
  const { title, placement, open } = props

  return (
    <Tooltip title={title} placement={placement} open={open}>
      <Button style={{ aspectRatio: '1/1' }} {...props} />
    </Tooltip>
  )
}
