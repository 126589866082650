import { Pane, Text } from 'evergreen-ui'

export interface ReceiptRow {
  label: string
  amount: string
}

export function PriceBreakdownV2({
  receiptInfo,
}: {
  receiptInfo: ReceiptRow[]
}) {
  return (
    <Pane display="flex" flexDirection="column" gap={16}>
      {receiptInfo.map(({ label, amount }) => (
        <Pane
          key={`${label}-${amount}`}
          display="flex"
          justifyContent="space-between"
          borderTop={label === 'Total amount'}
        >
          <Text>{label}</Text>
          <Text>{amount}</Text>
        </Pane>
      ))}
    </Pane>
  )
}
