import { Button, Checkbox, Flex, Input } from 'antd'
import { FILTER_DISPLAY_NAMES } from 'constants/algolia'
import { Strong, Text } from 'evergreen-ui'
import capitalize from 'lodash-es/capitalize'
import { useState } from 'react'
import { UseRefinementListProps, useRefinementList } from 'react-instantsearch'

const { Search } = Input

export function AlgoliaRefinementList({
  attribute,
}: {
  attribute: UseRefinementListProps['attribute']
}) {
  const [currentSearchValue, setCurrentSearchValue] = useState('')

  const {
    items,
    refine,
    searchForItems,
    canToggleShowMore,
    isShowingMore,
    toggleShowMore,
  } = useRefinementList({
    attribute,
    limit: 10,
    showMore: true,
    showMoreLimit: 1000,
    sortBy: ['name:asc'],
  })

  const attributeDisplayName = FILTER_DISPLAY_NAMES[attribute]

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentSearchValue(e.currentTarget.value)
    searchForItems(e.currentTarget.value)
  }

  return (
    <Flex vertical gap={8}>
      <Strong>{capitalize(attributeDisplayName)}</Strong>
      <Search
        placeholder={`Search ${attributeDisplayName.toLowerCase()}...`}
        allowClear
        onChange={handleSearchChange}
      />
      <Flex vertical gap={4}>
        {items.map(item => (
          <Checkbox
            style={{ width: 'max-content' }}
            key={item.value}
            checked={item.isRefined}
            onChange={() => refine(item.value)}
          >
            {item.label}
          </Checkbox>
        ))}

        {items.length === 0 && currentSearchValue && (
          <Text>
            No results for <Strong>&quot;{currentSearchValue}&quot;</Strong>
          </Text>
        )}
      </Flex>
      {canToggleShowMore && (
        <Button onClick={toggleShowMore}>
          {isShowingMore ? 'Show less' : 'Show more'}
        </Button>
      )}
    </Flex>
  )
}
