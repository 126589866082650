import { Flex, Popover, Tag } from 'antd'
import { Heading } from 'evergreen-ui'
import { Program_RewardTab } from 'gen/perkup/v1/program_pb'
import { useDefaultOrgColors } from 'hooks'
import { useSelectRewardContext } from '../SelectRewardContext'
import { RedemptionOptions } from './redemption-options'
import { RedemptionOptionsForm } from './redemption-options-form'

export function SwagRedemptionPopover({ readOnly }: { readOnly?: boolean }) {
  const { selectedTab } = useSelectRewardContext()
  const { backgroundColor, defaultColor } = useDefaultOrgColors()

  if (selectedTab.selectedRewardTab !== Program_RewardTab.swag) {
    return null
  }

  return (
    <Popover
      trigger={readOnly ? [] : 'hover'}
      mouseLeaveDelay={0.3}
      placement="topRight"
      overlayInnerStyle={{ padding: 16 }}
      content={
        <Flex gap={8} vertical>
          <Heading>Set redemption options:</Heading>
          <RedemptionOptionsForm />
        </Flex>
      }
    >
      <Tag
        color={backgroundColor}
        style={{
          userSelect: 'none',
          color: defaultColor,
          padding: '4px 16px',
          flex: 5,
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <RedemptionOptions />
      </Tag>
    </Popover>
  )
}
