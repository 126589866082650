import { CanvasDesign, Variable } from 'gen/canvas/designs_pb'
import { DesignVariable } from 'types'

interface ImportantDesignInfo {
  productId: string
  productVariantId: string
  variables: Variable[]
}

/**
 *
 * Two designs are identical if they are for the same product, the same variant, and have the same variables.
 */
export function designsAreIdentical({
  a,
  b,
}: {
  a: ImportantDesignInfo
  b: ImportantDesignInfo
}) {
  const differentProduct = a.productId !== b.productId
  if (differentProduct) return false

  const differentVariant = a.productVariantId !== b.productVariantId
  if (differentVariant) return false

  const differentVariablesLength = a.variables.length !== b.variables.length
  if (differentVariablesLength) return false

  const allVariablesAreTheSame = a.variables.every((aVariable, index) => {
    const bVariable = b.variables[index]
    return JSON.stringify(aVariable) === JSON.stringify(bVariable)
  })

  if (!allVariablesAreTheSame) return false

  return true
}

export const smallDesignHash = (productId: string, variables: Variable[]) => {
  return `${productId}-${JSON.stringify(variables)}`
}

export function convertCanvasDesignToProductRetailsFormData(
  canvasDesign: CanvasDesign
) {
  const defaultVariables: Record<string, DesignVariable> = {}

  canvasDesign.variables.forEach(variable => {
    defaultVariables[variable.name] = {
      name: variable.name,
      value: variable.value,
      type: variable.type as DesignVariable['type'],
    }
  })

  return {
    selectedCustomerCanvasVariantId: Number(
      canvasDesign.customerCanvasVariantId
    ),
    selectedVariables: defaultVariables,
    selectedCustomerCanvasOption: undefined,
    selectedProductVariant: undefined,
  }
}
