import { Timestamp } from '@bufbuild/protobuf'
import { captureException } from '@sentry/react'
import { UploadChangeParam, UploadFile } from 'antd/es/upload'
import { getAuth } from 'firebase/auth'
import {
  addDoc,
  collection,
  doc,
  getDoc,
  getFirestore,
  setDoc,
} from 'firebase/firestore'
import { getStorage, ref, uploadBytes } from 'firebase/storage'
import {
  RootUser,
  RootUser_Address,
  ShippingAddress,
} from 'gen/perkup/v1/root_user_pb'
import { toSentry } from 'utils'
import { converter } from 'utils/firestore'
import { formatDownloadUrl } from 'utils/images'
import { ListShippingAddressesByUserId } from '../reads'

export async function createUser() {
  const authUser = getAuth().currentUser
  if (!authUser) return

  const { uid, email, photoURL, displayName } = authUser
  const firstName = displayName?.split(' ')[0] ?? undefined
  const lastName = displayName?.split(' ')[1] ?? undefined
  const userPicture = photoURL ?? undefined
  const db = getFirestore()
  const docRef = doc(db, `users/${uid}`).withConverter(converter(RootUser))
  const existingUser = await getDoc(docRef)
    .then(doc => doc.exists())
    .catch(error => {
      console.error(error)
      captureException(toSentry(error), {
        contexts: {
          createUser: {
            uid,
            email,
            photoURL,
            displayName,
          },
        },
      })
    })

  if (existingUser) return

  // don't create a user on the FE if it's an anonymous user
  if (authUser.isAnonymous) return

  const newUser = new RootUser({
    profile: {
      email: email?.toLowerCase(),
      firstName,
      lastName,
      profilePicture: userPicture,
    },
    onboarding: {
      page: 0,
      complete: false,
    },
    created: Timestamp.now(),
    createdBy: 'frontend',
  })

  await setDoc(docRef, newUser).catch(error => {
    console.error(error)
    captureException(toSentry(error), {
      contexts: {
        createUser: {
          uid,
          email,
          photoURL,
          displayName,
        },
      },
    })
  })
}

export async function setUserBillingAddress({
  address,
  userId,
}: {
  address: RootUser_Address
  userId: string
}) {
  const db = getFirestore()
  const docRef = doc(db, `users/${userId}`).withConverter(converter(RootUser))
  await setDoc(
    docRef,
    {
      address,
    },
    { merge: true }
  ).catch(error => {
    console.error(error)
    captureException(toSentry(error), {
      contexts: {
        setUserBillingAddress: {
          address,
          userId,
        },
      },
    })
  })
}

export async function updateUserProfile({
  userId,
  data,
}: {
  userId: string
  data: { firstName?: string; lastName?: string }
}) {
  const db = getFirestore()
  const docRef = doc(db, `users/${userId}`).withConverter(converter(RootUser))
  try {
    await setDoc(
      docRef,
      { profile: { firstName: data?.firstName, lastName: data?.lastName } },
      { merge: true }
    )
  } catch (error) {
    console.error(error)
    captureException(toSentry(error), {
      contexts: {
        updateUserProfile: {
          userId,
          data,
        },
      },
    })
  }
}

export async function updateUserCountry({
  userId,
  countryId,
}: {
  userId: string
  countryId: string
}) {
  if (!userId || !countryId) return
  const db = getFirestore()
  const docRef = doc(db, `users/${userId}`).withConverter(converter(RootUser))
  try {
    await setDoc(
      docRef,
      {
        countryId,
      },
      { merge: true }
    )
  } catch (error) {
    console.error(error)
    captureException(toSentry(error), {
      contexts: {
        updateUserCountry: {
          userId,
          countryId,
        },
      },
    })
  }
}

export async function completeUserOnboarding({ userId }: { userId: string }) {
  const db = getFirestore()
  const docRef = doc(db, `users/${userId}`).withConverter(converter(RootUser))
  try {
    await setDoc(
      docRef,
      {
        onboarding: { complete: true },
      },
      { merge: true }
    )
  } catch (error) {
    console.error(error)
    captureException(toSentry(error), {
      contexts: {
        completeUserOnboarding: {
          userId,
        },
      },
    })
  }
}

export async function UpdateCurrentOrg({
  orgId,
  userId,
}: {
  orgId: string
  userId: string
}) {
  const db = getFirestore()
  const docRef = doc(db, `users/${userId}`).withConverter(converter(RootUser))
  try {
    await setDoc(docRef, { currentOrganization: orgId }, { merge: true })
  } catch (error) {
    console.error(error)
    captureException(toSentry(error), {
      contexts: {
        UpdateCurrentOrg: {
          orgId,
          userId,
        },
      },
    })
  }
}

export interface IUpdateUserCurrency {
  userId: string
  displayCurrency: string
}

export async function UpdateUserCurrency({
  userId,
  displayCurrency,
}: IUpdateUserCurrency) {
  const db = getFirestore()
  const docRef = doc(db, `users/${userId}`).withConverter(converter(RootUser))
  try {
    await setDoc(docRef, { displayCurrency }, { merge: true })
  } catch (error) {
    console.error(error)
    captureException(toSentry(error), {
      contexts: {
        UpdateUserCurrency: {
          userId,
          displayCurrency,
        },
      },
    })
  }
}

export async function updateUserLocation({
  userId,
  location,
}: {
  userId: string
  location: string
}) {
  const db = getFirestore()
  const docRef = doc(db, `users/${userId}`).withConverter(converter(RootUser))
  try {
    await setDoc(
      docRef,
      {
        location,
      },
      { merge: true }
    )
  } catch (error) {
    console.error(error)
    captureException(toSentry(error), {
      contexts: {
        updateUserLocation: {
          userId,
          location,
        },
      },
    })
  }
}

export async function updateDefaultShippingAddress({
  userId,
  shippingAddressId,
}: {
  userId: string
  shippingAddressId: string
}) {
  const db = getFirestore()
  const docRef = doc(db, `users/${userId}`).withConverter(converter(RootUser))
  try {
    await setDoc(
      docRef,
      {
        defaultShippingAddressId: shippingAddressId,
      },
      { merge: true }
    )
  } catch (error) {
    console.error(error)
    captureException(toSentry(error), {
      contexts: {
        updateDefaultShippingAddress: {
          userId,
          shippingAddressId,
        },
      },
    })
  }
}

export async function createShippingAddress({
  userId,
  shippingAddress,
}: {
  userId: string
  shippingAddress: ShippingAddress
}) {
  try {
    const db = getFirestore()
    const colRef = collection(
      db,
      `users/${userId}/shippingAddresses`
    ).withConverter(converter(ShippingAddress))

    const docRef = await addDoc(colRef, shippingAddress)
    const allAddresses = await ListShippingAddressesByUserId({ userId })

    // If the address we just added is the user's only address, set it as default
    if (allAddresses.length === 1) {
      await updateDefaultShippingAddress({
        userId,
        shippingAddressId: docRef.id,
      })
    }

    return docRef
  } catch (error) {
    console.error(error)
    captureException(toSentry(error), {
      contexts: {
        createShippingAddress: {
          userId,
          shippingAddress,
        },
      },
    })
    return undefined
  }
}

export async function updateShippingAddress({
  userId,
  shippingAddressId,
  shippingAddress,
}: {
  userId: string
  shippingAddressId: string
  shippingAddress: ShippingAddress
}) {
  const db = getFirestore()
  const docRef = doc(
    db,
    `users/${userId}/shippingAddresses/${shippingAddressId}`
  ).withConverter(converter(ShippingAddress))
  try {
    await setDoc(docRef, shippingAddress)
  } catch (error) {
    console.error(error)
    captureException(toSentry(error), {
      contexts: {
        updateShippingAddress: {
          userId,
          shippingAddressId,
          shippingAddress,
        },
      },
    })
  }
}

export async function uploadUserPfp({
  userId,
  upload,
}: {
  userId: string
  upload: UploadChangeParam<UploadFile<any>>
}) {
  try {
    if (!upload.file.originFileObj) return
    const profilePicRef = ref(getStorage(), `${userId}/${upload.file.name}`)
    await uploadBytes(profilePicRef, upload.file.originFileObj)
    const url = await formatDownloadUrl(profilePicRef)

    const db = getFirestore()
    const docRef = doc(db, `users/${userId}`).withConverter(converter(RootUser))
    setDoc(
      docRef,
      {
        profile: { profilePicture: url },
      },
      { merge: true }
    )
  } catch (error) {
    console.error(error)
    captureException(toSentry(error), {
      contexts: {
        uploadUserPfp: {
          userId,
        },
      },
    })
  }
}

export async function removeUserPfp({ userId }: { userId: string }) {
  const db = getFirestore()
  const docRef = doc(db, `users/${userId}`).withConverter(converter(RootUser))
  try {
    await setDoc(
      docRef,
      {
        profile: { profilePicture: '' },
      },
      { merge: true }
    )
  } catch (error) {
    console.error(error)
    captureException(toSentry(error), {
      contexts: {
        removeUserPfp: {
          userId,
        },
      },
    })
  }
}
