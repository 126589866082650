import { callFunction } from 'api/functionCalls'
import { OrgContext } from 'context'
import { useContext, useEffect, useState } from 'react'
import Stripe from 'stripe'

export function useListInvoicesByCustomerId() {
  const { customerId } = useContext(OrgContext)
  const [invoices, setInvoices] = useState<Stripe.Invoice[]>([])
  const [isLoading, setIsLoading] = useState(false)

  // List customer invoices
  useEffect(() => {
    if (customerId) {
      setIsLoading(true)
      callFunction('stripe-ListInvoicesByCustomerId', { customerId })
        .then(setInvoices)
        .finally(() => setIsLoading(false))
    }
  }, [customerId])

  return { invoices, isLoading }
}
