import { CaretDownOutlined } from '@ant-design/icons'
import { Badge, Flex } from 'antd'
import { OrderDetails } from 'api/tinybirdCalls/types'
import { ProductThumbnail } from 'components'
import { Text } from 'evergreen-ui'
import { parseBigQueryItemOptions } from 'utils/individual'

export function OrderItemsBreakdown({
  items,
}: {
  items: OrderDetails['items']
}) {
  const numItems = items.length

  if (numItems === 0) return null

  if (numItems === 1) {
    return (
      <ProductThumbnail>
        <ProductThumbnail.Image imageUrl={items[0].image} />

        <Flex vertical>
          <ProductThumbnail.Name>{items[0].productName}</ProductThumbnail.Name>
          <ProductThumbnail.Options
            options={parseBigQueryItemOptions(items[0].options)}
          />
        </Flex>
      </ProductThumbnail>
    )
  }

  return (
    <Text display="flex" gap={8} alignItems="center">
      <Badge
        style={{ backgroundColor: '#EAEAEA', color: 'inherit' }}
        count={numItems}
      />{' '}
      Products
      <CaretDownOutlined />
    </Text>
  )
}
