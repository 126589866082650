import { ItemInfo, PerkLoader, PrepaidBadge } from 'components'
import { Pane } from 'evergreen-ui'
import {
  ProductVariant,
  ProductVariant_SourceType,
} from 'gen/perkup/v1/product_variant_pb'
import { useListCanvasDesigns } from 'hooks'
import useListAllProductVariantsByProductIds from 'hooks/productVariants/useListProductVariantsByProductIds'
import {
  buildProductVariantsFromCanvasDesigns,
  getProductVariantProductImage,
} from 'utils/productVariant'

export function ProductsSummary({
  productIds,
  amountSuffix,
  onRemoveProductVariant,
  designIds = [],
}: {
  productIds: string[]
  amountSuffix?: string
  onRemoveProductVariant?: (
    productVariant: ProductVariant,
    remainingVariants: ProductVariant[]
  ) => void
  designIds?: string[]
}) {
  const { productVariants, isLoading: isLoadingPvs } =
    useListAllProductVariantsByProductIds({
      productIds,
    })

  const { canvasDesigns, hasLoaded: hasLoadedCanvasDesigns } =
    useListCanvasDesigns(designIds)

  const pvsToDisplay = buildProductVariantsFromCanvasDesigns({
    productVariants,
    canvasDesigns,
  })

  // Use reduce to create a new array with the highest amounts for each ProductId
  const highestProductVariantsPerProductId = pvsToDisplay.reduce(
    (currentList, currentPv) => {
      // Check if there is already an object with the same ProductId in the accumulator
      const existingPv = currentList.find(
        item => item.productId === currentPv.productId
      )

      // Check if the current object has a higher amount than the existing object
      const isCurrentLarger =
        Number(currentPv.amount) > Number(existingPv?.amount)

      // If no object with the same ProductId exists, or the current object has a higher amount, punch it in!
      if (!existingPv || isCurrentLarger) {
        const newList = currentList.filter(
          item => item.productId !== currentPv.productId
        ) // Remove existing object with the same id
        newList.push(currentPv) // Add the current object to the accumulator
        return newList
      }

      return currentList
    },
    [] as ProductVariant[]
  )

  if (isLoadingPvs || !hasLoadedCanvasDesigns) return <PerkLoader />

  if (highestProductVariantsPerProductId.length === 0) return null

  return (
    <Pane display="flex" flexDirection="column" gap={16}>
      {highestProductVariantsPerProductId.map(productVariant => {
        const isPrepaid =
          productVariant?.sourceType === ProductVariant_SourceType.fullPrepaid
        const remainingVariants = highestProductVariantsPerProductId.filter(
          pv => pv.productId !== productVariant.productId
        )
        return (
          <ItemInfo
            key={productVariant.id}
            name={productVariant.productName}
            amount={Number(productVariant.amount)}
            shippingCountries={productVariant.shippingCountries}
            image={getProductVariantProductImage(productVariant)}
            amountSuffix={amountSuffix}
            onRemoveItem={
              onRemoveProductVariant
                ? () =>
                    onRemoveProductVariant(productVariant, remainingVariants)
                : undefined
            }
            allowRemoval={!!onRemoveProductVariant}
          >
            {isPrepaid && <PrepaidBadge />}
          </ItemInfo>
        )
      })}
    </Pane>
  )
}
