/* eslint-disable react/no-unstable-nested-components */
import { captureException } from '@sentry/react'
import { Button } from 'antd'
import { PERKUP_ACCENT_COLOR, PERKUP_PRIMARY_COLOR } from 'constants/colors'
import { OrgContext } from 'context'
import { Heading, Pane, Popover, toaster } from 'evergreen-ui'
import { doc, getFirestore, setDoc } from 'firebase/firestore'
import { Organization } from 'gen/perkup/v1/organization_pb'
import { useDefaultOrgColors } from 'hooks'
import { useContext, useState } from 'react'
import { BlockPicker, ColorResult } from 'react-color'
import { toSentry } from 'utils'
import { converter } from 'utils/firestore'

const colors = [
  PERKUP_PRIMARY_COLOR,
  PERKUP_ACCENT_COLOR,
  '#33D6FF',
  '#FF7A4F',
  '#FF3392',
  '#C233FF',
  '#33FF5C',
  '#33FFE4',
  '#FF36FF',
  '#009DFF',
]

function ColorPicker({
  color,
  onColorChange,
}: {
  onColorChange: (color: ColorResult) => void
  color?: string
}) {
  return (
    <Pane>
      <BlockPicker
        color={color}
        colors={colors}
        onChangeComplete={color => {
          onColorChange(color)
        }}
        triangle="hide"
      />
    </Pane>
  )
}

export function ChangeCompanyColor() {
  const { id } = useContext(OrgContext)
  const { defaultColor } = useDefaultOrgColors()
  const [color, setColor] = useState(defaultColor)

  const handleChangeColor = (color: ColorResult) => {
    const db = getFirestore()
    const docRef = doc(db, `organizations/${id}`).withConverter(
      converter(Organization)
    )
    setDoc(docRef, { primaryColor: color.hex }, { merge: true }).catch(
      error => {
        console.error(error)
        captureException(toSentry(error), {
          contexts: {
            ChangeCompanyColor: {
              defaultColor,
              id,
            },
          },
        })
        toaster.warning('Something went wrong, try again or contact support.')
      }
    )
    setColor(color.hex)
    toaster.success(`Color set to ${color.hex}`)
  }

  return (
    <Pane
      display="flex"
      flexDirection="column"
      justifyContent="center"
      marginY={32}
      gap={16}
    >
      <Heading>Brand color</Heading>
      <Pane display="flex">
        <Pane
          height={28}
          width={28}
          background={color || defaultColor}
          borderRadius={25}
          marginRight={16}
        />

        <Popover
          content={() => (
            <ColorPicker color={color} onColorChange={handleChangeColor} />
          )}
          minWidth={5}
        >
          <Button type="default">Primary color</Button>
        </Popover>
      </Pane>
    </Pane>
  )
}
