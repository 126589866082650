export const isProduction = process.env.NODE_ENV === 'production'

export const GOOGLE_MAPS_API_KEY = 'AIzaSyDKitWFO_97GxbddF95HpKzxlkkVM_H8iE'
export const STRIPE_API_PUBLIC_KEY = isProduction
  ? 'pk_live_51HWlQ4HiqA41OAsJRRKaryMrO6AT4bCLLsTBShjQJhTqWH6UqfOqMhjwnlWLuiTXRjAyF1FGJzxXYs3F7eKJU47E00CWb759vt' // Stripe Public Live
  : 'pk_test_51HWlQ4HiqA41OAsJ0RhSRCqycMlghN6nfUakBOwE9vBtW28oYlG19fmKQtvE6HQu7znyXiOUuu2CsooLrC5DPcft00WfxUdqHu' // Stripe Public Test

export const FIREBASE_CONFIG = {
  apiKey: 'AIzaSyAPVLWU5kP79bZsqFIw2TBqcBcl6pnZ3mo',
  authDomain: 'my.perkupapp.com',
  projectId: 'perkup-app',
  storageBucket: 'gs://perkup-app.appspot.com',
  messagingSenderId: '295940089036',
  appId: '1:295940089036:web:8f978c22d62c356c9c0e69',
  measurementId: 'G-2VDXKT1RXJ',
  databaseURL: 'https://perkup-app.firebaseio.com',
}
