export enum NotificationOccassions {
  BIRTHDAY = 'birthday',
  ANNIVERSARY = 'anniversary',
}

export enum NotificationSendTypes {
  NOTIFY_ADMINS = 'notifyAdmins',
  NOTIFY_MANAGER = 'notifyManager',
  PUBLIC_SLACK = 'publicSlack',
  PRIVATE_SLACK = 'privateSlack',
}

export enum RuleGroups {
  BIRTHDAY_RULE_GROUP = 'birthdays',
  ANNIVERSARY_RULE_GROUP = 'anniversaries',
}

export enum RuleIds {
  BIRTHDAY_RULE_ID = 'everyBirthday',
  ANNIVERSARY_RULE_ID = 'everyAnniversary',
}

export interface NotificationOption {
  sendType: NotificationSendTypes
  statusTitle: string
  configDescription: string
  isShown: boolean
  Icon: JSX.Element
  hasAction: boolean
  ConfigForm: ((props: any) => JSX.Element) | undefined
  isSlack: boolean
  occasion: NotificationOccassions
}
