import { captureException } from '@sentry/react'
import { Button, Image, Input, message } from 'antd'
import PaperAirplane from 'assets/paper-airplane.svg'
import { PerkLoader } from 'components'
import * as EVENTS from 'constants/events'
import { localStorageEmailForSignIn } from 'constants/firebaseConfig'
import * as ROUTES from 'constants/routes'
import { PROGRAM_ID } from 'constants/sessionOrLocalStorage'
import { Heading, Text } from 'evergreen-ui'
import {
  getAuth,
  isSignInWithEmailLink,
  signInWithEmailLink,
} from 'firebase/auth'
import useIds from 'hooks/useIds'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { logEvent } from 'utils'
import { toSentry } from 'utils/sentry'

export default function EmailConfirmation() {
  const methods = useForm<{ email: string }>()
  const navigate = useNavigate()
  const auth = getAuth()
  const { orgId, userId } = useIds()
  const programId = window.localStorage.getItem(PROGRAM_ID)
  const storageEmail = window.localStorage.getItem(localStorageEmailForSignIn)
  const { formState, control, handleSubmit } = methods

  async function signIn(email: string) {
    try {
      await signInWithEmailLink(auth, email, window.location.href)
      window.localStorage.removeItem(localStorageEmailForSignIn)

      const afterSignInRoute = programId
        ? ROUTES.REWARD_ACCEPTANCE.replace(':programId', programId)
        : ROUTES.HOME
      navigate(afterSignInRoute)
      logEvent(EVENTS.SIGNED_IN, { authProvider: 'Email', orgId, userId })
    } catch (error: any) {
      console.error(error)
      if (error.code === 'auth/invalid-action-code') {
        // Thrown if the action code is invalid. Can happen if the code is malformed or has already been used.
        message.warning(
          'Link is no longer valid. Please try again or contact support'
        )
        // Do not send to Sentry
      } else {
        message.error(
          'That email appears invalid. Please try again or contact support'
        )
        captureException(toSentry(error), {
          contexts: {
            EmailConfirmation: {
              email,
            },
          },
        })
      }
      navigate(ROUTES.SIGN_IN)
    }
  }

  if (!storageEmail) {
    return (
      <form
        onSubmit={handleSubmit(({ email }) => {
          signIn(email)
        })}
      >
        <div
          className="flex flex-col gap-6 align-middle justify-center p-4 h-screen"
          style={{
            backgroundImage:
              'linear-gradient(to bottom right, rgba(92, 51, 255, 0.09), white, white, rgba(92, 51, 255, 0.09))',
          }}
        >
          <Image
            src={PaperAirplane}
            alt="Paper airplane"
            height={100}
            preview={false}
          />
          <div className="w-72 self-center flex flex-col gap-4">
            <Text className="self-center" size={600}>
              Confirm email address to sign in
            </Text>
            <Controller
              name="email"
              rules={{ required: 'Email is required' }}
              control={control}
              render={({ field, fieldState: { invalid } }) => (
                <Input
                  {...field}
                  status={invalid ? 'error' : undefined}
                  placeholder="Enter your email"
                  onChange={field.onChange}
                />
              )}
            />
            <Button
              htmlType="submit"
              type="primary"
              loading={formState.isLoading}
              disabled={formState.isLoading}
            >
              Confirm
            </Button>
          </div>
        </div>
      </form>
    )
  }

  if (isSignInWithEmailLink(auth, window.location.href)) {
    signIn(storageEmail)

    return (
      <div className="m-4">
        <Heading size={800}>Signing into PerkUp...</Heading>
        <PerkLoader />
      </div>
    )
  }
}
