import { Line, LineConfig } from '@ant-design/plots'
import { FetchTinyBirdDataByOrgId } from 'api/tinybirdCalls/reads'
import { PerkLoader } from 'components'
import { REWARDS_DAILY_URL } from 'constants/tinybird'
import { Pane, Strong, Text } from 'evergreen-ui'
import { useDefaultOrgColors } from 'hooks'
import useIds from 'hooks/useIds'
import { isEmpty } from 'lodash-es'
import { useEffect, useState } from 'react'
import { RewardsByDay } from 'types/TinyBird'
import {
  COLUMN_MAX_HEIGHT,
  COLUMN_MIN_HEIGHT,
  COLUMN_WIDTH,
} from '../constants/insights'
import { NoData } from './NoData'

interface RewardsDailyChartData {
  // Note: the created date needs to be stringified for the chart to render
  created: string
  rewardsSent: number
  orgId: string
}

export function RewardsDaily() {
  const { orgId } = useIds()
  const { defaultColor } = useDefaultOrgColors()
  const [rewardsDaily, setRewardsDaily] = useState<RewardsDailyChartData[]>()
  const [isLoading, setIsLoading] = useState(false)

  const formatRewardsByDay = (data: RewardsByDay[]) => {
    const formattedData: RewardsDailyChartData[] = data.map(
      (item: RewardsByDay) => {
        return {
          ...item,
          created: new Date(item.created).toLocaleDateString('default', {
            timeZone: 'UTC',
          }),
        }
      }
    )
    setRewardsDaily(formattedData)
  }

  useEffect(() => {
    setIsLoading(true)
    FetchTinyBirdDataByOrgId({
      orgId,
      cb: formatRewardsByDay,
      baseUrl: REWARDS_DAILY_URL,
      dataTitle: 'rewards by day',
    }).finally(() => setIsLoading(false))
  }, [orgId])

  const hasData = !isLoading && !isEmpty(rewardsDaily)
  const hasNoData = !isLoading && isEmpty(rewardsDaily)

  const config: LineConfig = {
    data: rewardsDaily || [],
    padding: 32,

    xField: 'created',
    yField: 'rewardsSent',

    xAxis: {
      tickCount: 4,
    },

    smooth: true,
    meta: {
      rewardsSent: {
        alias: 'Rewards sent',
      },
    },
    color: defaultColor,
  }

  return (
    <Pane
      width={COLUMN_WIDTH}
      maxHeight={COLUMN_MAX_HEIGHT}
      minHeight={COLUMN_MIN_HEIGHT}
      border="muted"
      display="flex"
      flexDirection="column"
      borderRadius={8}
      paddingX={32}
      paddingY={16}
      gap={16}
    >
      <Pane display="flex" flexDirection="column" gap={4}>
        <Strong>Rewards sent</Strong>
        <Text size={300} color="muted">
          Last 30 days
        </Text>
      </Pane>
      {isLoading && <PerkLoader />}
      {hasData && <Line {...config} />}
      {hasNoData && <NoData />}
    </Pane>
  )
}
