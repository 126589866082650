import { captureException } from '@sentry/react'
import type { FormProps } from 'antd'
import { Button, Form, Input } from 'antd'
import {
  actionCodeSettings,
  localStorageEmailForSignIn,
} from 'constants/firebaseConfig'
import * as ROUTES from 'constants/routes'
import { toaster } from 'evergreen-ui'
import { getAuth, sendSignInLinkToEmail } from 'firebase/auth'
import { useNavigate } from 'react-router'
import { toSentry } from 'utils/sentry'

interface FieldType {
  email?: string
}

export function EmailAuthForm({ ctaLabel }: { ctaLabel: string }) {
  const navigate = useNavigate()

  const onFinish: FormProps<FieldType>['onFinish'] = ({ email }) => {
    if (!email) {
      toaster.warning('An unknown error has occurred.')
      return
    }

    const auth = getAuth()
    sendSignInLinkToEmail(auth, email, actionCodeSettings)
      .then(() => {
        window.localStorage.setItem(localStorageEmailForSignIn, email)
        navigate(ROUTES.VERIFY_EMAIL)
      })
      .catch(error => {
        window.localStorage.removeItem(localStorageEmailForSignIn)
        console.error(error)
        const { code } = error
        if (code === 'auth/invalid-email') {
          toaster.danger(`${email} is an invalid email address`)
        } else if (code === 'auth/too-many-requests') {
          toaster.danger(`Too many requests. Please try again later.`)
        } else if (code === 'auth/operation-not-allowed') {
          toaster.danger(`Email sign in is not enabled.`)
        } else if (code === 'auth/internal-error') {
          toaster.warning(`An internal error has occurred.`)
        } else {
          toaster.warning(`An unknown error has occurred.`)
          captureException(toSentry(error), {
            level: 'info',
            contexts: {
              sendSignInLinkToEmail: {
                email,
                code,
              },
            },
          })
        }
      })
  }

  return (
    <Form
      name="basic"
      onFinish={onFinish}
      layout="vertical"
      style={{ width: '100%' }}
      validateMessages={{
        required: 'Email is required.',
        types: { email: 'Email is not valid.' },
      }}
    >
      <Form.Item<FieldType>
        name="email"
        rules={[{ required: true, type: 'email' }]}
        style={{ marginBottom: 8 }}
      >
        <Input size="large" placeholder="name@company.com" />
      </Form.Item>

      <Form.Item style={{ marginBottom: 0 }}>
        <Button htmlType="submit" size="large" style={{ width: '100%' }}>
          {ctaLabel}
        </Button>
      </Form.Item>
    </Form>
  )
}
