import { CountOrgTemplates } from 'api/databaseCalls'
import { useEffect, useState } from 'react'

export function useCountOrgTemplates({ orgId }: { orgId: string }) {
  const [numOrgTemplates, setNumOrgTemplates] = useState<number>(0)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    setIsLoading(true)
    CountOrgTemplates({ orgId })
      .then(setNumOrgTemplates)
      .finally(() => setIsLoading(false))
  }, [orgId])

  return { numOrgTemplates, isLoading }
}
