import { MinusCircleOutlined } from '@ant-design/icons'
import { Button, Popconfirm } from 'antd'
import { removeMemberFromProgram } from 'api/databaseCalls'
import { OrgContext } from 'context'
import { toaster } from 'evergreen-ui'
import { useContext } from 'react'

export function RemoveMember({
  programId,
  memberId,
  memberName,
  onAfterRemove,
}: {
  programId: string
  memberId: string
  memberName?: string
  onAfterRemove?: () => void
}) {
  const { id: orgId } = useContext(OrgContext)

  const popConfirmTitle = memberName ? `Remove ${memberName}` : 'Remove'
  const popConfirmDescription = memberName
    ? `Are you sure you want to remove ${memberName}?`
    : 'Are you sure you want to remove this recipient?'
  const toasterMessage = memberName
    ? `Removed ${memberName}`
    : 'Removed recipient'

  const handleRemove = async () => {
    await removeMemberFromProgram({
      orgId,
      programId,
      memberId,
    })
    toaster.warning(toasterMessage)
    if (onAfterRemove) onAfterRemove()
  }

  return (
    <Popconfirm
      title={popConfirmTitle}
      description={popConfirmDescription}
      okText="Remove"
      cancelText="Cancel"
      onCancel={e => {
        e?.preventDefault()
        e?.stopPropagation()
      }}
      onConfirm={e => {
        e?.preventDefault()
        e?.stopPropagation()
        handleRemove()
      }}
    >
      <Button
        type="text"
        danger
        icon={<MinusCircleOutlined />}
        onClick={e => {
          e.preventDefault()
          e.stopPropagation()
        }}
      />
    </Popconfirm>
  )
}
