import { Tabs } from 'antd'
import { PageHeader } from 'components'
import { ACCOUNT } from 'constants/routes'
import { Pane } from 'evergreen-ui'
import { NotificationSetting_RuleGroup } from 'gen/perkup/v1/notification_pb'
import { capitalize } from 'lodash-es'
import { useEffect } from 'react'
import { Route, Routes, useLocation, useNavigate } from 'react-router'
import { getLastUrlSegment } from 'utils/urlUtils'
import { AddressSettings } from './components/address-settings'
import { NotificationSettings } from './components/NotificationSettings'
import { ProfileSettings } from './components/ProfileSettings'

enum AccountSettingsTabs {
  PROFILE = 'profile',
  ADDRESSES = 'addresses',
  NOTIFICATIONS = 'notifications',
}

export default function Account() {
  const location = useLocation()
  const navigate = useNavigate()
  const tabOptions = Object.values(AccountSettingsTabs)

  useEffect(() => {
    if (location.pathname === ACCOUNT || location.pathname === `${ACCOUNT}/`) {
      navigate(`${ACCOUNT}/${AccountSettingsTabs.PROFILE}`)
    }
  }, [location, navigate])

  return (
    <Pane>
      <PageHeader title="Account" />
      <Tabs
        items={tabOptions.map(option => {
          const label = capitalize(option)
          return { label, key: option }
        })}
        onChange={navigate}
        className="customTabs"
        activeKey={getLastUrlSegment(location)}
      />
      <Pane marginTop={16}>
        <Routes>
          <Route
            index
            path={AccountSettingsTabs.PROFILE}
            element={<ProfileSettings />}
          />
          <Route
            index
            path={AccountSettingsTabs.ADDRESSES}
            element={<AddressSettings />}
          />
          <Route
            index
            path={AccountSettingsTabs.NOTIFICATIONS}
            element={
              <NotificationSettings
                notificationSettingRuleGroup={
                  NotificationSetting_RuleGroup.birthdays
                }
              />
            }
          />
        </Routes>
      </Pane>
    </Pane>
  )
}
