import { Collapse, CollapseProps, Flex, Tooltip } from 'antd'
import { AddPersonalFundsButton, RecentTransactions } from 'components'
import { CurrencySelection } from 'components/Currencies'
import PerkCard from 'components/PerkCard'
import MembershipSummaries from 'components/ProgramSummary/MembershipSummaries'
import {
  ExchangeRateContext,
  OrgContext,
  OrgUserContext,
  UserContext,
} from 'context'
import { Heading, Pane } from 'evergreen-ui'
import { useContext, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { numToDollars, preFixSpace } from 'utils'
import BalancesByCategory from './BalancesByCategory'

export default function Hero() {
  const user = useContext(UserContext)
  const orgUser = useContext(OrgUserContext)
  const org = useContext(OrgContext)
  const exchangeRate = useContext(ExchangeRateContext)
  const [showSidesheet, setShowSidesheet] = useState(false)

  const balancesCollapseMenu: CollapseProps['items'] = [
    {
      key: '0',
      label: (
        <Tooltip
          title={numToDollars(
            (orgUser?.balance ?? 0) * (exchangeRate || 1),
            2,
            false,
            user.displayCurrency || org.displayCurrency,
            false
          )}
        >
          <Pane marginBottom={8}>
            <Heading marginBottom={12} fontWeight={400} fontSize={28}>
              {numToDollars(
                (orgUser?.balance ?? 0) * (exchangeRate || 1),
                2,
                false,
                user.displayCurrency || org.displayCurrency,
                true
              )}
            </Heading>
            <Heading size={100}>Total Balance</Heading>
          </Pane>
        </Tooltip>
      ),
      children: <BalancesByCategory />,
      className: 'customCollapseMenu',
    },
  ]

  return (
    <Flex justify="space-between" wrap="wrap" gap={16}>
      <Flex vertical gap={32} style={{ width: 568 }}>
        {/* HEADER and ADD FUNDS BUTTON */}
        <Pane
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          flexWrap="wrap"
        >
          <Heading size={800} lineHeight="normal">
            Hello{preFixSpace(user.profile?.firstName ?? '')}
            {orgUser?.balance > 0 && ', you have'}
          </Heading>

          {!isMobile && <AddPersonalFundsButton oneClickCheckout={false} />}
        </Pane>
        <Pane display="flex" justifyContent="space-between" alignItems="start">
          <Collapse
            expandIconPosition="end"
            ghost
            items={balancesCollapseMenu}
          />
          <CurrencySelection />
        </Pane>
        {/* LIST USER'S PROGRAMS */}
        <MembershipSummaries />

        {/** Reposition the Add Funds button on Mobile view */}
        {isMobile && <AddPersonalFundsButton oneClickCheckout={false} />}
      </Flex>

      {!isMobile && (
        <Flex vertical align="center" gap={32} style={{ width: 350 }}>
          <PerkCard
            showSidesheet={showSidesheet}
            setShowSidesheet={setShowSidesheet}
          />
          <RecentTransactions />
        </Flex>
      )}
    </Flex>
  )
}
