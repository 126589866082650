import { Button } from 'antd'
import { Heading, Image, Pane, Paragraph } from 'evergreen-ui'
import { useState } from 'react'

export function IntegrationCard({
  logo,
  title,
  description,
  connectLabel = 'Connect',
  onConnectClick,
  disabled = false,
}: {
  logo: string
  title: string
  description: string
  connectLabel?: string
  onConnectClick: () => Promise<void>
  disabled?: boolean
}) {
  const [isLoadingConnect, setIsLoadingConnect] = useState(false)

  const handleConnectClick = () => {
    setIsLoadingConnect(true)
    onConnectClick().finally(() => setIsLoadingConnect(false))
  }

  return (
    <Pane
      display="flex"
      alignItems="center"
      width={712}
      elevation={1}
      borderRadius={4}
      paddingY={24}
      paddingX={36}
      gap={36}
    >
      <Image src={logo} width={80} />
      <Pane display="flex" alignItems="center" gap={24} flexGrow={1}>
        <Pane display="flex" flexDirection="column">
          <Heading marginBottom={12}>{title}</Heading>
          <Paragraph>{description}</Paragraph>
        </Pane>
        <Pane
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
          flexGrow={1}
        >
          <Button
            onClick={handleConnectClick}
            type="primary"
            disabled={disabled}
            loading={isLoadingConnect}
          >
            {connectLabel}
          </Button>
        </Pane>
      </Pane>
    </Pane>
  )
}
