import { hideShopAndGiftIso3s, INDIA_ISO3 } from 'constants/countries'
import {
  CountryContext,
  OrgContext,
  OrgUserContext,
  SwagCollectionIdsContext,
} from 'context'
import { Organization_SubscriptionStatus } from 'gen/perkup/v1/organization_pb'
import { isEmpty } from 'lodash-es'
import { useContext } from 'react'
import { getStripeCapabilitiesStatus } from 'utils'

export function useSpendingOptions() {
  const country = useContext(CountryContext)
  const countryIso3 = country?.iso3
  const org = useContext(OrgContext)
  const orgUser = useContext(OrgUserContext)

  const activeOrg =
    org.subscriptionStatus === Organization_SubscriptionStatus.active

  const swagCollectionIds = useContext(SwagCollectionIdsContext)

  const countryPreventsShopping = hideShopAndGiftIso3s.includes(countryIso3)

  const showShopping = !org.settings?.hideShopping && !countryPreventsShopping

  const showAmazon = country?.amazon && !org.settings?.hideAmazon

  const isIndiaAndNoPreviousCard = countryIso3 === INDIA_ISO3 && !orgUser.cardId

  const activeConnectedAccount = getStripeCapabilitiesStatus({ org })
  // only check for connected account if org is active
  const connectedAccoutCheck = activeOrg ? activeConnectedAccount : true

  const showPerkCard =
    !isIndiaAndNoPreviousCard &&
    !org.settings?.hidePerkCard &&
    connectedAccoutCheck

  const showSwag = !isEmpty(swagCollectionIds)

  const showNearCash = !org.settings?.hideNearCash

  const showFood = showNearCash && !org.settings?.hideFood

  return {
    showAmazon,
    showShopping,
    showSwag,
    showPerkCard,
    showNearCash,
    showFood,
  }
}
