import { Flex, Segmented } from 'antd'
import { TemplateContext } from 'context'
import { Heading } from 'evergreen-ui'
import { Template_Access } from 'gen/perkup/v1/org_template_pb'
import { useContext } from 'react'

export function TemplateAccessForm({
  onTemplateAccessChange,
}: {
  onTemplateAccessChange: (newTemplateAccess: Template_Access) => void
}) {
  const template = useContext(TemplateContext)
  const templateAccess = template?.access

  const templateAccessOptions = [
    { label: 'All', value: Template_Access.all },
    { label: 'Admins', value: Template_Access.admin },
    { label: 'Managers', value: Template_Access.manager },
  ]
  return (
    <Flex vertical gap={8}>
      <Heading>Access</Heading>
      <Segmented
        style={{ width: 320 }}
        block
        options={templateAccessOptions}
        value={templateAccess}
        defaultValue={Template_Access.all}
        className="small-custom-segment"
        onChange={access => {
          const newAccess = templateAccessOptions.find(
            option => option.value === access
          )
          if (!newAccess) return

          onTemplateAccessChange(newAccess.value)
        }}
      />
    </Flex>
  )
}
