import { Button, Flex, Input } from 'antd'
import {
  BackIconButton,
  CategoryForm,
  InternalMemoForm,
  PageHeader,
} from 'components'
import { OccasionForm } from 'components/Forms/OccasionForm'
import { allApprovedCats, allCategories } from 'constants/EligibleCategories'
import { selectableCats } from 'constants/rewards'
import { TemplateContext } from 'context'
import { Heading, toaster } from 'evergreen-ui'
import { Template } from 'gen/perkup/v1/org_template_pb'
import { isEmpty, values } from 'lodash-es'
import { useState } from 'react'
import { TemplateAccessForm } from './TemplateAccessForm'
import { TemplateBudgetForm } from './TemplateBudgetForm'
import { TemplateCoreValuesForm } from './TemplateCoreValuesForm'
import { TemplateEligibleRewardsForm } from './TemplateEligibleRewardsForm'
import { TemplateEmailForm } from './TemplateEmailForm'
import { TemplateExpiryForm } from './TemplateExpiryForm'
import { TemplateImageForm } from './TemplateImageForm'

export default function TemplateForm({
  onConfirmTemplate,
  isEditMode = false,
  defaultTemplate,
}: {
  onConfirmTemplate: (template: Template) => void
  isEditMode?: boolean
  defaultTemplate: Template
}) {
  const [template, setTemplate] = useState<Template>(defaultTemplate)

  const handleTemplateChange = (newTemplateVal: Partial<Template>) => {
    setTemplate(
      new Template({
        ...template,
        ...newTemplateVal,
      })
    )
  }

  const handleCTAClick = async () => {
    if (!template.name) {
      toaster.warning('Please enter a template title')
      return
    }

    const eligibleRewardOptions = values(template?.eligibleRewards)
    if (!eligibleRewardOptions.includes(true)) {
      toaster.warning('Please select at least one eligible reward option')
      return
    }

    if (!template?.email?.title || !template?.email?.message) {
      toaster.warning('Please complete the reward message')
      return
    }

    const budgetAmountWritable = template.budget?.writable
    const templateBudgetAmounts = template.budget?.amounts
    const hasEnteredAmounts =
      !!templateBudgetAmounts && !isEmpty(templateBudgetAmounts)

    if (
      !hasEnteredAmounts &&
      !budgetAmountWritable &&
      template?.eligibleRewards?.cash
    ) {
      // If no amounts entered and not writable, toaster
      toaster.warning('Please enter a budget')
      return
    }
    onConfirmTemplate(template)
  }

  const defaultSelectedApprovedCategory =
    template.approvedCategories.length > 1
      ? allCategories
      : template.approvedCategories[0]

  const showApprovedCategoriesForm = !!template?.eligibleRewards?.cash

  return (
    <TemplateContext.Provider value={template}>
      <Flex gap={16}>
        <BackIconButton />

        <Flex vertical gap={32}>
          <PageHeader
            title={isEditMode ? defaultTemplate.name : 'New template'}
          />
          <Flex vertical gap={8}>
            <Heading>Template name</Heading>
            <Input
              style={{ width: 376 }}
              required
              value={template.name}
              placeholder="Enter a name"
              onChange={e => handleTemplateChange({ name: e.target.value })}
              autoFocus
            />
          </Flex>
          <Flex style={{ maxWidth: 376 }}>
            <TemplateCoreValuesForm
              onCoreValuesChange={coreValues => {
                handleTemplateChange({
                  coreValues,
                })
              }}
            />
          </Flex>
          <TemplateEligibleRewardsForm
            onEligibleRewardsChange={eligibleRewards => {
              const cashIneligible = !eligibleRewards.cash
              handleTemplateChange({
                eligibleRewards,
                approvedCategories: cashIneligible ? [] : allApprovedCats,
              })
            }}
          />
          {showApprovedCategoriesForm && (
            <Flex vertical gap={8}>
              <Heading>PerkUp dollars approved categories</Heading>
              <CategoryForm
                defaultCat={defaultSelectedApprovedCategory}
                selectableCats={selectableCats}
                onChangeApprovedCategories={cats => {
                  handleTemplateChange({
                    approvedCategories: cats,
                  })
                }}
              />
            </Flex>
          )}
          <TemplateBudgetForm
            onBudgetChange={newBudget => {
              handleTemplateChange({
                budget: newBudget,
              })
            }}
          />

          <TemplateExpiryForm
            onExpiryChange={expiry => {
              handleTemplateChange({
                expiry,
              })
            }}
          />
          <OccasionForm
            occasion={template?.occasion}
            onOccasionChange={occasion => {
              handleTemplateChange({
                occasion,
              })
            }}
          />

          <Flex gap={32}>
            <Flex vertical gap={32}>
              <TemplateEmailForm
                onTemplateEmailChange={templateEmail => {
                  handleTemplateChange({
                    email: templateEmail,
                  })
                }}
              />
              <Flex style={{ width: 444 }}>
                <InternalMemoForm
                  defaultInternalMemo={template?.internalMemo}
                  onMemoChange={memo => {
                    handleTemplateChange({
                      internalMemo: memo,
                    })
                  }}
                />
              </Flex>

              <TemplateAccessForm
                onTemplateAccessChange={TemplateAccess => {
                  handleTemplateChange({
                    access: TemplateAccess,
                  })
                }}
              />
              <Button
                style={{ maxWidth: 320 }}
                size="large"
                type="primary"
                onClick={handleCTAClick}
              >
                {isEditMode ? 'Save changes' : 'Create'}
              </Button>
            </Flex>

            <TemplateImageForm
              onTemplateImageChange={templateImage => {
                handleTemplateChange({
                  image: templateImage,
                })
              }}
            />
          </Flex>
        </Flex>
      </Flex>
    </TemplateContext.Provider>
  )
}
