import { PageHeader, withOrgSidebar } from 'components'
import { Pane } from 'evergreen-ui'
import { ChangeCompanyColor } from './components/ChangeCompanyColor'
import { ChangeCompanyCoreValues } from './components/ChangeCompanyCoreValues'
import { ChangeCompanyLogo } from './components/ChangeCompanyLogo'
import { ChangeCompanyTitle } from './components/ChangeCompanyTitle'
import { CompanyBrandingPreview } from './components/CompanyBrandingPreview'

function CompanyBranding() {
  return (
    <>
      <Pane>
        <PageHeader
          title="Branding"
          description="Customize PerkUp for an on-brand experience."
          vlogLink="https://www.loom.com/embed/4711d1ea65a44898aa71ca39a12bd1b4?sid=393ce4a1-a11f-4b80-856d-ad3868839531"
        />
      </Pane>
      <Pane display="flex" gap={64} height="100%" flexWrap="wrap">
        <Pane display="flex" flexDirection="column" minWidth={450}>
          <ChangeCompanyTitle />

          <ChangeCompanyLogo />

          <ChangeCompanyColor />

          <ChangeCompanyCoreValues />
        </Pane>
        <CompanyBrandingPreview />
      </Pane>
    </>
  )
}

export default withOrgSidebar(CompanyBranding)
