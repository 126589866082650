import { DollarOutlined, GiftOutlined } from '@ant-design/icons'
import { Flex } from 'antd'
import { Text } from 'evergreen-ui'
import { ALL_CONVERTABLE_TO_OPTIONS } from 'types/Gift'
import { makePlural, numToDollars } from 'utils'
import { useSelectRewardContext } from '../SelectRewardContext'

const SWAPPABLE_ICONS = {
  gift: GiftOutlined,
  cash: DollarOutlined,
} satisfies Partial<
  Record<(typeof ALL_CONVERTABLE_TO_OPTIONS)[0], typeof GiftOutlined>
>

function SwappableText() {
  const { selectedReward } = useSelectRewardContext()

  return (
    <Flex gap={4} align="center">
      <Text color="currentcolor">, swappable for:</Text>
      <Flex
        gap={4}
        align="center"
        style={{
          height: '100%',
        }}
      >
        {Object.entries(SWAPPABLE_ICONS).map(
          ([key, Icon]) =>
            selectedReward?.gift?.convertableTo.includes(key) && (
              <Flex key={key} gap={4} align="center">
                <Icon
                  style={{
                    fontSize: 16,
                    marginTop: 2,
                  }}
                />
              </Flex>
            )
        )}
      </Flex>
    </Flex>
  )
}

export function RedemptionOptions() {
  const { selectedReward } = useSelectRewardContext()

  const hasConvertableTo =
    selectedReward.gift?.convertableTo &&
    selectedReward.gift?.convertableTo.length > 0

  if (selectedReward.gift?.redeemableQuantity === undefined) {
    return (
      <Flex align="center">
        <Text color="currentcolor">
          {numToDollars(selectedReward.budget, 2)} budget per recipient
        </Text>
        {hasConvertableTo && <SwappableText />}
      </Flex>
    )
  }
  return (
    <Flex align="center">
      <Text color="currentcolor">
        Recipients select {selectedReward.gift?.redeemableQuantity}{' '}
        {makePlural('item', selectedReward.gift?.redeemableQuantity)}
      </Text>
      {hasConvertableTo && <SwappableText />}
    </Flex>
  )
}
