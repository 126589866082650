import { Image } from 'antd'
import { defaultOccasions } from 'constants/newReward/occasions'
import { Heading, Pane, Text } from 'evergreen-ui'
import { Program, ProgramOccasion } from 'gen/perkup/v1/program_pb'

export function OccasionForm({
  program,
  onSelectOccasion,
}: {
  program: Program
  onSelectOccasion: (occasion: ProgramOccasion) => void
}) {
  return (
    <>
      <Heading size={800}>Select an occasion</Heading>
      <Pane display="grid" gridTemplateColumns="1fr 1fr 1fr" gap={16}>
        {defaultOccasions.map(occasion => (
          <Pane
            key={occasion.occasion}
            display="flex "
            flexDirection="column"
            alignItems="center"
            gap={8}
            border="muted"
            borderRadius={8}
            paddingX={16}
            paddingY={32}
            hoverElevation={1}
            cursor="pointer"
            elevation={program?.occasion === occasion.occasion ? 2 : undefined}
            onClick={() => onSelectOccasion(occasion.occasion)}
          >
            <Image width={36} preview={false} src={occasion.image} />
            <Text>{occasion.label}</Text>
          </Pane>
        ))}
      </Pane>
    </>
  )
}
