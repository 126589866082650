import { ApiOutlined, MonitorOutlined } from '@ant-design/icons'
import { useCubeQuery } from '@cubejs-client/react'
import {
  ChartConfig,
  ChartContainer,
  ChartLegend,
  ChartLegendContent,
  ChartTooltip,
  ChartTooltipContent,
} from '@repo/shadcn/es/chart'
import { Divider, Skeleton } from 'antd'
import { PerkEmpty } from 'components'
import { Heading } from 'evergreen-ui'
import { useDevSafeOrgId } from 'hooks/useDevSafeOrgId'
import { Label, Pie, PieChart } from 'recharts'
import { prepareChartResults } from 'utils/cube'
import { useInsightsContext } from './insights-context'

const useOccasionsQuery = () => {
  const orgId = useDevSafeOrgId()
  const { dateRange, insightsGlobalFilters } = useInsightsContext()
  return useCubeQuery({
    measures: ['programOccasions.count'],
    dimensions: ['programOccasions.occasion'],
    filters: [
      {
        member: 'organizations.id',
        operator: 'equals',
        values: [orgId],
      },
      {
        member: 'programOccasions.occasion',
        operator: 'set',
      },
      {
        member: 'programs.status',
        operator: 'notEquals',
        values: ['draft'],
      },
      ...insightsGlobalFilters,
    ],
    timeDimensions: [
      {
        dimension: 'programOccasions.created',
        dateRange,
      },
    ],
  })
}

export function ProgramOccasionsChart() {
  const { resultSet, isLoading, error } = useOccasionsQuery()

  if (isLoading) {
    return (
      <section className="flex flex-col gap-6">
        <Heading>Occasions</Heading>

        <div>
          <Skeleton active />
          <Divider />
          <Skeleton active />
        </div>
      </section>
    )
  }

  if (error || !resultSet) {
    return (
      <section className="flex flex-col gap-6">
        <Heading>Occasions</Heading>

        <PerkEmpty
          iconNode={
            <ApiOutlined
              className="text-muted-foreground"
              style={{ fontSize: 32 }}
            />
          }
          header="Oops! Something went wrong"
          description="We couldn't load your data. Please try again later."
        />
      </section>
    )
  }

  if (resultSet.chartPivot().length === 0) {
    return (
      <section className="flex flex-col gap-6">
        <Heading>Occasions</Heading>

        <PerkEmpty
          iconNode={
            <MonitorOutlined
              className="text-muted-foreground"
              style={{ fontSize: 32 }}
            />
          }
          header="Nothing found"
          description="Try a different date range or filter."
        />
      </section>
    )
  }

  const chartData = prepareChartResults(resultSet.chartPivot())

  const totalPrograms = chartData.reduce(
    (acc, curr) => acc + curr['programOccasions.count'],
    0
  )

  const chartConfig = {
    ...chartData.reduce(
      (agg, curr) => ({
        ...agg,
        [curr.x]: {
          label: curr.x,
          totalCount: curr['programOccasions.count'],
          totalPercentage: curr['programOccasions.count'] / totalPrograms,
        },
      }),
      {}
    ),
  } satisfies ChartConfig

  return (
    <section className="flex flex-col gap-6">
      <Heading>Occasions</Heading>

      <ChartContainer
        config={chartConfig}
        className="h-72 relative aspect-[23/9]"
      >
        <PieChart>
          <ChartLegend
            layout="vertical"
            verticalAlign="middle"
            align="right"
            content={<ChartLegendContent nameKey="x" />}
          />
          <ChartTooltip
            cursor={false}
            content={<ChartTooltipContent hideLabel />}
          />
          <Pie
            data={chartData}
            dataKey="programOccasions.count"
            nameKey="x"
            innerRadius={60}
          >
            <Label
              // eslint-disable-next-line react/no-unstable-nested-components, consistent-return
              content={({ viewBox }) => {
                if (viewBox && 'cx' in viewBox && 'cy' in viewBox) {
                  return (
                    <text
                      x={viewBox.cx}
                      y={viewBox.cy}
                      textAnchor="middle"
                      dominantBaseline="middle"
                    >
                      <tspan
                        x={viewBox.cx}
                        y={viewBox.cy}
                        className="fill-foreground text-3xl font-bold"
                      >
                        {totalPrograms.toLocaleString()}
                      </tspan>
                      <tspan
                        x={viewBox.cx}
                        y={(viewBox.cy || 0) + 24}
                        className="fill-muted-foreground"
                      >
                        Rewards
                      </tspan>
                    </text>
                  )
                }
              }}
            />
          </Pie>
        </PieChart>
      </ChartContainer>
    </section>
  )
}
