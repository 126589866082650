import { EmailPreview } from 'components/EmailPreview'
import { Pane } from 'evergreen-ui'
import { EmailFormData } from '../types/forms'

export default function RewardEmailPreview({
  email,
  isGift,
}: {
  email: EmailFormData
  isGift: boolean
}) {
  // const firstName = 'FIRST_NAME'
  // const subjectMessage = ` ${firstName}${firstName && ', '} ${
  //   email.fromName
  // } sent you a ${isGift ? 'gift' : 'reward'}`

  return (
    <Pane display="flex" padding={16} justifyContent="center">
      <Pane
        display="flex"
        flexDirection="column"
        gap={16}
        justifyContent="start"
      >
        {/* <Pane display="flex" flexDirection="column" gap={24}>
          <Heading size={SECTION_HEADING_SIZE}>Email preview</Heading>

          <Pane display="flex" gap={24} alignItems="baseline">
            <Strong>Subject</Strong>
            <Text>{subjectMessage}</Text>
          </Pane>
        </Pane> */}

        <EmailPreview
          banner={email.banner}
          title={email.title}
          programNote={email.note}
          fromName={email.fromName}
          isGift={isGift}
          amount={undefined}
        />
      </Pane>
    </Pane>
  )
}
