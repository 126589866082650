import { Select, Space } from 'antd'
import { Variant } from 'antd/es/form/hooks/useVariants'
import { AnimatePresence, motion } from 'framer-motion'
import { compact } from 'lodash-es'
import { useState } from 'react'
import { getCountryIsoAlpha2 } from 'utils'
import { RoundCountryImage } from './RoundCountryImage'

function CountryRender({
  label,
  value,
  hideFlag = false,
}: {
  label: React.ReactNode
  value: string
  hideFlag?: boolean
}) {
  return (
    <AnimatePresence>
      <Space>
        {!hideFlag && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <RoundCountryImage iso3={value} />
          </motion.div>
        )}

        {label}
      </Space>
    </AnimatePresence>
  )
}

export function CountrySelect({
  activeIso3,
  iso3s,
  placeHolderLabel = 'Select country',
  variant = 'outlined',
  onSelect,
  onClear,
}: {
  activeIso3: string | undefined
  iso3s: string[]
  placeHolderLabel?: string
  variant?: Variant
  onSelect: (itemKey: string) => void
  onClear?: () => void
}) {
  const [isFocused, setIsFocused] = useState(false)
  const regions = new Intl.DisplayNames(['en'], { type: 'region' })

  const filterOption = (userInput: string, labelToCheck: string) =>
    labelToCheck.toLowerCase().includes(userInput.toLowerCase())

  const options = compact(
    iso3s.map(iso3 => {
      const iso2 = getCountryIsoAlpha2(iso3)
      if (!iso2) return null

      return {
        label: regions.of(iso2.toUpperCase()),
        value: iso3,
      }
    })
  )

  return (
    <Select
      id="country-select"
      showSearch
      placeholder={placeHolderLabel}
      variant={variant}
      value={activeIso3}
      onSelect={value => {
        onSelect(value)
        document.getElementById('country-select')?.blur()
      }}
      filterOption={(input, option) => filterOption(input, option?.label ?? '')}
      allowClear={!!onClear}
      onClear={onClear}
      options={options}
      labelRender={({ label, value }) =>
        CountryRender({
          label,
          value: value as string,
          hideFlag: isFocused,
        })
      }
      optionRender={({ label, value }) =>
        CountryRender({
          label,
          value: value as string,
        })
      }
      onFocus={() => setIsFocused(true)}
      onBlur={() => setIsFocused(false)}
    />
  )
}
