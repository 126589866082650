import { captureException } from '@sentry/react'
import {
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  onSnapshot,
  query,
  where,
} from 'firebase/firestore'
import { ProductCollection } from 'gen/perkup/v1/product_collections_pb'
import { isFunction } from 'lodash-es'
import { toSentry } from 'utils'
import { converter } from 'utils/firestore'

export function ListenToCollectionById({
  id,
  cb,
  setHasLoaded,
}: {
  id: string
  cb: (collection: ProductCollection | undefined) => void
  setHasLoaded?: (hasLoaded: boolean) => void
}) {
  const db = getFirestore()
  const docRef = doc(db, `productCollections/${id}`).withConverter(
    converter(ProductCollection)
  )
  return onSnapshot(
    docRef,
    doc => {
      cb(doc.data())
      if (isFunction(setHasLoaded)) setHasLoaded(true)
    },
    error => {
      if (isFunction(setHasLoaded)) setHasLoaded(true)
      console.error(error)
      captureException(toSentry(error), {
        contexts: { ListenToCollectionById: { id } },
      })
    }
  )
}

export async function ListProductCollectionsByOrgId({
  orgId,
}: {
  orgId: string
}) {
  const productCollections: ProductCollection[] = []
  try {
    const db = getFirestore()
    const colRef = collection(db, 'productCollections').withConverter(
      converter(ProductCollection)
    )
    const q = query(colRef, where('orgId', '==', orgId))
    const querySnapshot = await getDocs(q)
    querySnapshot.forEach(doc => {
      productCollections.push(doc.data())
    })
  } catch (error) {
    console.error(error)
    captureException(toSentry(error), {
      contexts: { ListCollectionsByOrgId: { orgId } },
      tags: { orgId },
    })
  }

  return productCollections
}

export async function getProductCollectionById({ id }: { id: string }) {
  try {
    const db = getFirestore()
    const docRef = doc(db, `productCollections/${id}`).withConverter(
      converter(ProductCollection)
    )
    return await getDoc(docRef).then(doc => doc.data())
  } catch (error) {
    console.error(error)
    captureException(toSentry(error), {
      contexts: { getProductCollectionById: { id } },
    })
  }
  return undefined
}

export function ListenToPublicSwagCollections({
  orgId,
  cb,
}: {
  orgId: string
  cb: (collections: ProductCollection[]) => void
}) {
  const db = getFirestore()
  const colRef = collection(db, `productCollections`).withConverter(
    converter(ProductCollection)
  )
  const q = query(
    colRef,
    where('orgId', '==', orgId),
    where('access', '==', 'public')
  )
  return onSnapshot(
    q,
    { includeMetadataChanges: true },
    query => {
      cb(query.docs.map(doc => doc.data()))
    },
    error => {
      console.error(error)
      captureException(toSentry(error), {
        contexts: {
          ListenToPublicSwagCollections: { orgId },
        },
      })
    }
  )
}

export function ListenToProductCollectionsByOrgId({
  orgId,
  cb,
}: {
  orgId: string
  cb: (collections: ProductCollection[]) => void
}) {
  const db = getFirestore()
  const colRef = collection(db, `productCollections`).withConverter(
    converter(ProductCollection)
  )
  const q = query(colRef, where('orgId', '==', orgId))
  return onSnapshot(
    q,
    { includeMetadataChanges: true },
    query => {
      cb(query.docs.map(doc => doc.data()))
    },
    error => {
      cb([])
      console.error(error)
      captureException(toSentry(error), {
        contexts: {
          ListenToProductCollectionsByOrgId: { orgId },
        },
      })
    }
  )
}
