import { Timestamp } from '@bufbuild/protobuf'
import { captureException } from '@sentry/react'
import { Button } from 'antd'
import {
  updateIndividualRemovedField,
  updateIndividualStatus,
} from 'api/databaseCalls'
import { IndividualContext, OrgContext } from 'context'
import { Dialog, Pane, toaster } from 'evergreen-ui'
import { Individual_Status } from 'gen/perkup/v1/individual_pb'
import useIds from 'hooks/useIds'
import { useContext, useState } from 'react'
import { expressPossession } from 'utils'
import { toSentry } from 'utils/sentry'

export default function RemoveMemberButton({
  individualId,
  email,
  handleCloseSidesheet,
}: {
  individualId: string
  email: string
  handleCloseSidesheet: () => void
}) {
  const org = useContext(OrgContext)
  const currentIndividual = useContext(IndividualContext)
  const { orgId } = useIds()

  const [isShown, setIsShown] = useState(false)

  const handleConfirm = async () => {
    try {
      updateIndividualStatus({
        orgId,
        individualId,
        status: Individual_Status.removed,
      })

      updateIndividualRemovedField({
        orgId,
        individualId,
        removedBy: currentIndividual.id,
        removedAt: Timestamp.fromDate(new Date()), // Today, as a Timestamp
      })

      // Close dialog
      setIsShown(false)
      // Navigate back to people directory tab
      if (handleCloseSidesheet) handleCloseSidesheet()

      // Show active user that member has been removed
      toaster.warning(`Removed ${email}`)
    } catch (error) {
      console.error(error)
      captureException(toSentry(error), {
        contexts: {
          RemoveMemberButton: {
            orgId,
          },
        },
      })
    }
  }

  return (
    <Pane>
      <Button danger type="default" onClick={() => setIsShown(true)}>
        Remove from organization
      </Button>
      <Dialog
        title="Remove from organization"
        isShown={isShown}
        onCloseComplete={() => setIsShown(false)}
        onConfirm={handleConfirm}
        confirmLabel="Remove"
        intent="danger"
      >
        Remove <strong>{email}</strong> from {expressPossession(org.name)}{' '}
        organization.
      </Dialog>
    </Pane>
  )
}
