import { captureException } from '@sentry/react'
import { getAuth } from 'firebase/auth'
import { TinybirdTokens } from 'gen/perkup/v1/tinybird_connect'
import { createClient } from 'hooks/useClient'
import { toSentry } from 'utils/sentry'

export async function getTinybirdToken(orgId: string): Promise<string | void> {
  try {
    const currentToken = sessionStorage.getItem(`Tinybird Token ${orgId}`)
    if (currentToken) return currentToken

    const accessToken = await getAuth().currentUser?.getIdToken()

    const client = createClient(TinybirdTokens)

    return await client
      .getToken({}, { headers: { Authorization: `Bearer ${accessToken}` } })
      .then(TinybirdTokenResponse => {
        const { token } = TinybirdTokenResponse
        sessionStorage.setItem(`Tinybird Token ${orgId}`, token)
        return token
      })
  } catch (error: any) {
    console.error(error)

    captureException(toSentry(error), {
      contexts: {
        GetToken: {},
      },
    })

    return undefined
  }
}
