/* eslint-disable jsx-a11y/media-has-caption */
import { Button, Image } from 'antd'
import { Heading, Pane, useTheme } from 'evergreen-ui'
import { useEffect, useRef } from 'react'
import { MembershipCard } from './MembershipCard'

export interface INewGiftCard {
  rewardId: string
  dateDisplayString: string | undefined
  programImage: string
  handleAcceptGift: () => void
  expiredDateDiff?: number | null
  primaryColor: string | undefined
  programName: string
  isCardHovered: boolean
  setIsCardHovered: React.Dispatch<React.SetStateAction<boolean>>
  countDownString: string
}

export function NewGiftCard({
  rewardId,
  dateDisplayString,
  programImage,
  handleAcceptGift,
  expiredDateDiff,
  primaryColor,
  programName,
  isCardHovered,
  setIsCardHovered,
  countDownString,
}: INewGiftCard) {
  const theme = useTheme()
  const videoRef = useRef<HTMLVideoElement>(null)

  const handlePlay = () => {
    if (videoRef.current) {
      videoRef.current.play()
    }
  }

  const handlePause = () => {
    if (videoRef.current) {
      videoRef.current.pause()
    }
  }

  useEffect(() => {
    if (isCardHovered) {
      handlePlay()
    } else {
      handlePause()
    }
  }, [isCardHovered])

  return (
    <MembershipCard
      orgPrimaryColor={primaryColor}
      isCardHovered={isCardHovered}
      setIsCardHovered={setIsCardHovered}
    >
      <Pane display="flex">
        <Image
          preview={false}
          src={programImage}
          style={{
            objectFit: 'cover',
            borderRadius: 2,
            height: 50,
            width: 50,
          }}
        />
        <Pane
          display="flex"
          flexDirection="column"
          marginRight="auto"
          marginLeft={16}
          justifyContent={dateDisplayString ? 'space-between' : 'center'}
        >
          <Pane display="flex" alignItems="center" marginRight={16}>
            <Heading
              className="text-success"
              size={800}
              marginRight={16}
              lineHeight="22px"
            >
              {programName}
            </Heading>
          </Pane>

          <Pane display="flex" gap={4} alignItems="center">
            {dateDisplayString && (
              <Heading size={100} lineHeight="20px">
                {dateDisplayString}
              </Heading>
            )}
            {!!expiredDateDiff &&
              expiredDateDiff <= 7 &&
              expiredDateDiff >= 0 && (
                <Heading
                  display="flex"
                  alignItems="center"
                  marginRight={20}
                  color={theme?.colors?.red500}
                  size={100}
                >
                  {countDownString}
                </Heading>
              )}
          </Pane>
        </Pane>

        <Pane display="flex" alignItems="center">
          <Button id={rewardId} onClick={handleAcceptGift} type="primary">
            Accept
          </Button>
        </Pane>
      </Pane>
    </MembershipCard>
  )
}
