import {
  createAndToggleNotificationSetting,
  createNewRule,
  createNotificationAction,
  listenToNotificationAction,
  listenToNotificationSetting,
} from 'api/databaseCalls'
import { OrgContext } from 'context'
import { Heading, Pane, Switch, Text, toaster } from 'evergreen-ui'
import {
  GroupByType_Enum,
  NotificationSetting,
} from 'gen/perkup/v1/notification_pb'
import { Action } from 'gen/perkup/v1/rules_pb'
import { useContext, useEffect, useState } from 'react'
import { NotificationOption, NotificationSendTypes } from 'types/Notifications'
import {
  getNotificationActionType,
  getNotificationDetailsByOccassion,
  getNotificationSettingId,
} from 'utils'
import { personalizeOptions } from './SlackPublicConfiguration'

export default function NotificationStatus({
  sendType,
  statusTitle,
  configDescription,
  Icon,
  ConfigForm,
  hasAction,
  occasion,
}: NotificationOption) {
  const org = useContext(OrgContext)
  const orgId = org?.id

  const [isSettingsShown, setIsSettingsShown] = useState(false)
  const [notificationSetting, setNotificationSetting] =
    useState<NotificationSetting>()
  const [notificationAction, setNotificationAction] = useState<Action>()

  const { ruleGroup, ruleId } = getNotificationDetailsByOccassion(occasion)

  const notificationSettingId = getNotificationSettingId({
    sendType,
    ruleGroup,
  })
  const actionType = getNotificationActionType(sendType)

  // Go get the Notification setting
  useEffect(() => {
    if (!orgId || !ruleGroup || !sendType) return undefined
    return listenToNotificationSetting({
      orgId,
      sendType,
      ruleGroup,
      cb: setNotificationSetting,
    })
  }, [orgId, ruleGroup, sendType])

  // Go get the notification action
  useEffect(() => {
    if (!(orgId && hasAction && ruleId && sendType && actionType)) {
      return undefined
    }

    return listenToNotificationAction({
      orgId,
      sendType,
      ruleId,
      cb: action => {
        if (action) {
          setNotificationAction(action)
        } else {
          // if no action exists, create a new one
          createNewRule({ orgId, occasion, ruleId, year: 0 }).then(() => {
            createNotificationAction({
              orgId,
              ruleId,
              actionType,
            })
          })
        }
      },
    })
  }, [hasAction, orgId, ruleGroup, ruleId, sendType, actionType, occasion])

  const handleToggleEnable = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target
    const isPublicSlack = sendType === NotificationSendTypes.PUBLIC_SLACK

    const personalizedOptionsArray = personalizeOptions?.map(
      ({ beVar }) => beVar
    )
    const hasPersonalizedOptionsString = personalizedOptionsArray.some(
      str => notificationSetting?.message?.includes(str) || false
    )

    // If groupBy is active, and there are personalized option strings, we need to block the user from continuing.
    const hasForbiddenPersonalizedOptions =
      hasPersonalizedOptionsString &&
      notificationSetting?.groupBy === GroupByType_Enum.daily

    if (
      isPublicSlack &&
      checked &&
      (!notificationSetting?.channel || !notificationSetting?.images.length)
    ) {
      if (!notificationSetting?.channel) {
        toaster.warning(`Please select a Slack channel`)
        return
      }

      if (!notificationSetting?.images.length) {
        toaster.warning(`Please select at least one image`)
      }
      return
    }

    if (hasForbiddenPersonalizedOptions) {
      toaster.warning(`Please remove variables to enable grouped notifications`)
      return
    }

    if (notificationSettingId) {
      createAndToggleNotificationSetting({
        orgId,
        notificationSettingId,
        enabled: checked,
        ruleGroup,
      }).then(() => {
        const isEnabledText = checked ? 'Enabled' : 'Disabled'
        const toasterMsg = `${isEnabledText}: ${statusTitle}`
        if (checked) {
          toaster.success(toasterMsg)
        } else {
          toaster.warning(toasterMsg)
        }
      })
    }
  }

  return (
    <Pane elevation={1} display="flex" flexDirection="column">
      <Pane
        display="flex"
        gap={16}
        alignItems="center"
        justifyContent="space-between"
        cursor="pointer"
        padding={32}
        onClick={() => setIsSettingsShown(!isSettingsShown)}
      >
        <Pane display="flex" gap={32} alignItems="center">
          {Icon}
          <Heading size={400}>{statusTitle}</Heading>
        </Pane>
        <Pane onClick={(e: React.ChangeEvent<unknown>) => e.stopPropagation()}>
          <Switch
            checked={notificationSetting?.enabled}
            onChange={handleToggleEnable}
            hasCheckIcon
          />
        </Pane>
      </Pane>

      {isSettingsShown && (
        <Pane
          display="flex"
          flexDirection="column"
          gap={24}
          padding={28}
          paddingTop={0}
        >
          <Pane width="100%" borderTop="muted" />
          <Pane
            display="flex"
            gap={16}
            alignItems="center"
            justifyContent="space-between"
          >
            <Text>{configDescription}</Text>
          </Pane>
          {ConfigForm && (
            <ConfigForm
              notificationAction={notificationAction}
              notificationSetting={notificationSetting}
              occasion={occasion}
              sendType={sendType}
              ruleGroup={ruleGroup}
            />
          )}
        </Pane>
      )}
    </Pane>
  )
}
