import { ContactsTwoTone, UserAddOutlined } from '@ant-design/icons'
import { Checkbox, Flex, Tag, Tooltip, message } from 'antd'
import { CheckboxChangeEvent } from 'antd/es/checkbox'
import Select, { SelectProps } from 'antd/es/select'
import { ListEligibleIndividualsByOrgId } from 'api/databaseCalls'
import { EmailCsvUploadButton, LabelSelect } from 'components'
import {
  ALGOLIA_INDIVIDUALS_INDEX,
  STATUS_NONE_OR_INVITED_OR_ACTIVE,
} from 'constants/algolia'
import { eligibleIndividualStatuses } from 'constants/individuals'
import { OrgContext } from 'context'
import { Text, useTheme } from 'evergreen-ui'
import { Individual } from 'gen/perkup/v1/individual_pb'
import { Program_RecipientFilters } from 'gen/perkup/v1/program_pb'
import { useDefaultOrgColors, useIndividualIds } from 'hooks'
import { useInfiniteSelectableIndividuals } from 'hooks/individuals/useInfiniteSelectableIndividuals'
import { intersectionWith } from 'lodash-es'
import {
  PropsWithChildren,
  createContext,
  useContext,
  useMemo,
  useState,
} from 'react'
import { Configure, InstantSearch, useSearchBox } from 'react-instantsearch'
import { individualsSearchClient } from 'services/algolia'
import { getIndividualIds } from 'services/individuals'
import { isEmail } from 'utils'
import { getIndividualDisplayName } from 'utils/individual'

interface RecipientsControlCenterContextType {
  allIndividualIds: string[]
}

const RecipientsControlCenterContext =
  createContext<RecipientsControlCenterContextType>(
    {} as RecipientsControlCenterContextType
  )

interface OptionData {
  key: string
  label: string
  value: string
  individual: Individual
  ref: (node?: Element | null | undefined) => void | null
  inOrgColor: string
}

const MAX_TAG_COUNT = 10
const INCREMENT_AMOUNT = 10
const GHOST_INDIVIDUAL_ID = 'ghost-individual'

export function individualContainsQuery(individual: Individual, query: string) {
  const { firstName, lastName, email } = individual

  const separateWordsInQuery = query.toLowerCase().split(' ')

  return separateWordsInQuery.some(word => {
    if (firstName?.toLowerCase().includes(word)) return true
    if (lastName?.toLowerCase().includes(word)) return true
    if (email?.toLowerCase().includes(word)) return true

    return false
  })
}

const recipientOptionRender: SelectProps['optionRender'] = ({
  data,
  label,
  key,
}) => {
  const { individual, ref, inOrgColor } = data as OptionData
  const isGhostIndividual = individual?.id?.includes(GHOST_INDIVIDUAL_ID)

  return (
    <Flex key={key} ref={ref} justify="space-between" align="center">
      <Flex gap={8} align="center">
        <Text>{label}</Text>
        <Text color="muted" size={300}>
          {individual.email}
        </Text>
      </Flex>

      <Tooltip
        title={
          isGhostIndividual
            ? 'This individual is not yet in your organization'
            : 'This individual is already in your organization'
        }
      >
        {isGhostIndividual ? (
          <UserAddOutlined />
        ) : (
          <ContactsTwoTone twoToneColor={inOrgColor} />
        )}
      </Tooltip>
    </Flex>
  )
}

const recipientTagRender: SelectProps['tagRender'] = ({
  label,
  value,
  onClose,
}) => {
  return (
    <Tag
      onClose={onClose}
      style={{
        margin: 2,
        backgroundColor: 'rgba(0, 0, 0, 0.06)',
        fontSize: 14,
      }}
      bordered={false}
      onMouseDown={e => {
        e.preventDefault()
        e.stopPropagation()
      }}
      closable={!!value} // The overflow tag is the only one that doesn't have a value
    >
      {label}
    </Tag>
  )
}

function RecipientControlCenterInput({
  selectedIds,
  selectedEmails,
  selectedFilter,
  onChange,
}: {
  selectedIds: string[]
  selectedEmails: string[]
  selectedFilter: Program_RecipientFilters | undefined
  onChange: ({
    selectedIds,
    selectedEmails,
    selectedFilter,
  }: {
    selectedIds: string[]
    selectedEmails: string[]
    selectedFilter: Program_RecipientFilters | undefined
  }) => void
}) {
  const { id: orgId } = useContext(OrgContext)

  const { allIndividualIds: selectableIds } = useContext(
    RecipientsControlCenterContext
  )
  const { query, refine, clear } = useSearchBox()

  const theme = useTheme()
  const { defaultColor } = useDefaultOrgColors()

  const [messageApi, contextHolder] = message.useMessage()

  const isAllSelected = !!selectedFilter?.allSelected

  const [selectableEmails, setSelectableEmails] =
    useState<string[]>(selectedEmails)

  // This returned list of individuals not within the org is affected by the query above
  const individualsNotInOrg: Individual[] = selectableEmails.map(email => {
    return new Individual({ id: `${GHOST_INDIVIDUAL_ID}-${email}`, email })
  })

  // This returned list of individuals within the org is affected by the query above
  const { individuals: individualsInOrg, sentinelRef } =
    useInfiniteSelectableIndividuals({
      selectedIndividualIds: selectedIds,
      incrementAmount: INCREMENT_AMOUNT,
    })

  // All of these are just the ids directly from the program
  const selectedIdsInOrg = selectedIds

  // All of these are just the ghost email ids that we built
  const selectedIdsNotInOrg = intersectionWith(
    individualsNotInOrg,
    selectedEmails,
    (individual, email) => individual.email === email
  ).map(({ id }) => id)

  const handleSelectIndividual = (individualId: string) => {
    const isGhostIndividual = individualId.includes(GHOST_INDIVIDUAL_ID)
    if (isGhostIndividual) {
      const email = individualId.split('-')[2]

      const newSetOfEmailsToSave = [...selectedEmails, email]

      const userJustSelectedFinalRemainingEmail =
        newSetOfEmailsToSave.length === selectableEmails.length &&
        selectedIds.length === selectableIds.length

      onChange({
        selectedIds,
        selectedEmails: newSetOfEmailsToSave,
        selectedFilter: userJustSelectedFinalRemainingEmail
          ? new Program_RecipientFilters({
              allSelected: true,
            })
          : undefined,
      })
    } else {
      const newSetOfIdsToSave = [...selectedIds, individualId]

      const userJustSelectedFinalRemainingId =
        newSetOfIdsToSave.length === selectableIds.length &&
        selectedEmails.length === selectableEmails.length

      onChange({
        selectedIds: newSetOfIdsToSave,
        selectedEmails,
        selectedFilter: userJustSelectedFinalRemainingId
          ? new Program_RecipientFilters({
              allSelected: true,
            })
          : undefined,
      })
    }
    clear()
  }

  const handleDeselectIndividual = (individualId: string) => {
    const isGhostIndividual = individualId.includes(GHOST_INDIVIDUAL_ID)
    if (isGhostIndividual) {
      const emailToRemove = individualId.split('-')[2]
      onChange({
        selectedIds,
        selectedEmails: selectedEmails.filter(
          selectedEmail => selectedEmail !== emailToRemove
        ),
        selectedFilter: undefined,
      })
    } else {
      onChange({
        selectedIds: selectedIds.filter(
          selectedId => selectedId !== individualId
        ),
        selectedEmails,
        selectedFilter: undefined,
      })
    }
    clear()
  }

  const handleAddNewEmail = () => {
    if (!isEmail(query)) {
      messageApi.open({
        type: 'warning',
        content: 'Please enter a valid email address',
      })
      return
    }

    setSelectableEmails([...selectableEmails, query])
    onChange({
      selectedIds,
      selectedEmails: [...selectedEmails, query],
      selectedFilter,
    })
    clear()
  }

  const handleSelectAll = async (e: CheckboxChangeEvent) => {
    const wantsAllToBeSelected = e.target.checked

    // When the user selects all, we want to punch in both our selecable arrays
    if (wantsAllToBeSelected) {
      onChange({
        selectedIds: selectableIds, // All the possible selectable ids
        selectedEmails: selectableEmails, // All the possible selectable emails
        selectedFilter: new Program_RecipientFilters({
          allSelected: true,
        }),
      })
    } else {
      onChange({
        selectedIds: [],
        selectedEmails: [],
        selectedFilter: undefined,
      })
    }

    clear()
  }

  const handleSelectLabel = async ({
    value,
    key,
  }: {
    value: string
    key: string
  }) => {
    const { ids: idsFromLabel } = await getIndividualIds({
      labels: { [key]: value },
      statuses: eligibleIndividualStatuses,
    })

    if (idsFromLabel.length === 0) {
      messageApi.open({
        type: 'warning',
        content: 'This label is empty',
      })
      return
    }

    onChange({
      selectedIds: idsFromLabel,
      selectedEmails: [],
      selectedFilter: new Program_RecipientFilters({
        label: JSON.stringify({ [key]: value }),
        allSelected: false,
      }),
    })
  }

  const handleCsvRecipientsSubmit = async (emailsFromCsv: string[]) => {
    const eligibleIndividuals = await ListEligibleIndividualsByOrgId(orgId)
    const emailToIndividualMap = new Map<string, Individual>()

    eligibleIndividuals.forEach(individual =>
      emailToIndividualMap.set(individual.email, individual)
    )

    const desiredRecipientsInOrg: string[] = [] // existing ids
    const desiredRecipientNotInOrg: string[] = [] // new emails

    emailsFromCsv.forEach(email => {
      const desiredRecipientAlreadyInOrg = emailToIndividualMap.get(email)
      if (desiredRecipientAlreadyInOrg) {
        desiredRecipientsInOrg.push(desiredRecipientAlreadyInOrg.id)
      } else {
        desiredRecipientNotInOrg.push(email)
      }
    })

    const emailsFromCsvEndedUpBeingEveryone =
      eligibleIndividuals.length === desiredRecipientsInOrg.length // There's no need to also check desiredRecipientNotInOrg.length because those new emails are about to be selected for the user

    setSelectableEmails(desiredRecipientNotInOrg) // Update the email bank of so ant design select can register the new emails as options

    // Update the program
    onChange({
      selectedIds: desiredRecipientsInOrg,
      selectedEmails: desiredRecipientNotInOrg,
      selectedFilter: emailsFromCsvEndedUpBeingEveryone
        ? new Program_RecipientFilters({
            allSelected: true,
          })
        : undefined,
    })
  }

  const totalRecipients = selectedIds.length + selectedEmails.length

  return (
    <>
      <Flex vertical>
        {/** TOTAL SELECTED AND FILTERS */}
        <Flex align="center" justify="space-between">
          <Text size={300}>{totalRecipients} selected</Text>

          {/** FILTER */}
          <Flex align="center">
            <LabelSelect
              selectedLabel={selectedFilter?.label}
              onLabelSelect={handleSelectLabel}
            />
            <Flex align="center" gap={8}>
              <Text size={300}>Select all</Text>
              <Checkbox checked={isAllSelected} onChange={handleSelectAll} />
            </Flex>
          </Flex>
        </Flex>

        {/** RECIPIENTS INPUT AND CSV UPLOAD */}

        <Flex gap={8}>
          <Select
            style={{ width: '100%' }}
            mode="multiple"
            placeholder="Enter a name or email"
            value={[...selectedIdsNotInOrg, ...selectedIdsInOrg]}
            tagRender={recipientTagRender}
            onSelect={(_, option) => handleSelectIndividual(option.key)}
            onDeselect={(_, option) => handleDeselectIndividual(option.key)}
            onBlur={clear}
            onSearch={refine}
            searchValue={query}
            menuItemSelectedIcon={null}
            showSearch
            notFoundContent={
              <Text width="100%" cursor="pointer" onClick={handleAddNewEmail}>
                Add
                <Text
                  backgroundColor={theme.colors.gray200}
                  padding={4}
                  borderRadius={4}
                  marginLeft={4}
                >
                  {query}
                </Text>
              </Text>
            }
            maxTagCount={MAX_TAG_COUNT}
            maxTagPlaceholder={
              totalRecipients <= MAX_TAG_COUNT
                ? ''
                : `+ ${totalRecipients - MAX_TAG_COUNT} others...`
            }
            filterOption={false}
            options={[
              ...individualsNotInOrg.filter(({ email }) =>
                email.includes(query)
              ),
              ...individualsInOrg.filter(individual =>
                individualContainsQuery(individual, query)
              ),
            ].map((individual, index) => {
              const isLastIndex = index === individualsInOrg.length - 1
              return {
                key: individual.id,
                value: individual.id,
                label: getIndividualDisplayName(individual) || individual.email,
                individual,
                ref: isLastIndex ? sentinelRef : null,
                inOrgColor: defaultColor,
              }
            })}
            optionRender={recipientOptionRender}
          />
          <EmailCsvUploadButton onSubmit={handleCsvRecipientsSubmit} />
        </Flex>

        {/** RECIPIENTS BREAKDOWN */}
        <Flex vertical gap={4} style={{ marginTop: 8 }}>
          <Flex gap={8} align="center">
            <ContactsTwoTone
              style={{ fontSize: '14px' }}
              twoToneColor={defaultColor}
            />
            <Text size={300}>
              Recipients already in your organization: {selectedIds.length}
            </Text>
          </Flex>

          <Flex gap={8} align="center">
            <UserAddOutlined style={{ fontSize: '14px' }} />
            <Text size={300}>
              Recipients that will be added to your organization:{' '}
              {selectedEmails.length}
            </Text>
          </Flex>
        </Flex>
      </Flex>
      {contextHolder}
    </>
  )
}

function RecipientsControlCenter({ children }: PropsWithChildren) {
  const { id: orgId } = useContext(OrgContext)

  const { individualIds: allIndividualIds, hasLoaded } = useIndividualIds({
    statuses: eligibleIndividualStatuses,
  })

  const searchClient = individualsSearchClient(orgId)

  const recipientsControlCenterContext: RecipientsControlCenterContextType =
    useMemo(
      () => ({
        allIndividualIds,
      }),
      [allIndividualIds]
    )

  if (!searchClient || !hasLoaded) return null

  return (
    <InstantSearch
      indexName={ALGOLIA_INDIVIDUALS_INDEX}
      searchClient={searchClient}
      future={{
        preserveSharedStateOnUnmount: true,
      }}
    >
      <Configure
        hitsPerPage={INCREMENT_AMOUNT}
        filters={STATUS_NONE_OR_INVITED_OR_ACTIVE}
      />
      <RecipientsControlCenterContext.Provider
        value={recipientsControlCenterContext}
      >
        {children}
      </RecipientsControlCenterContext.Provider>
    </InstantSearch>
  )
}

RecipientsControlCenter.Input = RecipientControlCenterInput

export default RecipientsControlCenter
