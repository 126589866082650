import { GiftOutlined, LoadingOutlined, TeamOutlined } from '@ant-design/icons'
import { Spin } from 'antd'
import { FetchTinyBirdDataByOrgId } from 'api/tinybirdCalls/reads'
import { REWARDS_SENT_URL, REWARD_SENDERS_URL } from 'constants/tinybird'
import { Heading, Pane, Strong, Text } from 'evergreen-ui'
import { useDefaultOrgColors } from 'hooks'
import useIds from 'hooks/useIds'
import { ReactNode, useEffect, useState } from 'react'
import { RewardSenders, RewardsSent } from 'types/TinyBird'
import { COLUMN_WIDTH } from '../constants/insights'

export interface IStatBoxProps {
  title: string
  icon: ReactNode
  value: number
}

export function StatBoxes() {
  const { orgId } = useIds()
  const [rewardSenders, setRewardSenders] = useState<RewardSenders>()
  const [rewardsSent, setRewardsSent] = useState<RewardsSent>()
  const [isLoadingSenders, setIsLoadingSenders] = useState(false)
  const [isLoadingSent, setIsLoadingSent] = useState(false)

  useEffect(() => {
    setIsLoadingSenders(true)
    FetchTinyBirdDataByOrgId({
      orgId,
      cb: (data: RewardSenders[]) => setRewardSenders(data[0]),
      baseUrl: REWARD_SENDERS_URL,
      dataTitle: 'reward senders',
    }).finally(() => setIsLoadingSenders(false))

    setIsLoadingSent(true)
    FetchTinyBirdDataByOrgId({
      orgId,
      cb: (data: RewardsSent[]) => setRewardsSent(data[0]),
      baseUrl: REWARDS_SENT_URL,
      dataTitle: 'rewards sent',
    }).finally(() => setIsLoadingSent(false))
  }, [orgId])

  const { defaultColor } = useDefaultOrgColors()
  const rewardsSentStats: IStatBoxProps = {
    value: rewardsSent?.rewardsSent || 0,
    title: 'Rewards sent',
    icon: <GiftOutlined style={{ color: defaultColor, fontSize: '24px' }} />,
  }
  const rewardSendersStats: IStatBoxProps = {
    value: rewardSenders?.senders || 0,
    title: 'Senders',
    icon: <TeamOutlined style={{ color: defaultColor, fontSize: '24px' }} />,
  }

  const sendStats = [rewardsSentStats, rewardSendersStats]
  const isLoading = isLoadingSent || isLoadingSenders
  return (
    <Pane display="flex" gap={16} width="100%">
      {sendStats.map(stat => {
        return (
          <Pane
            key={stat.title}
            border="muted"
            display="flex"
            flexDirection="column"
            padding={32}
            gap={4}
            borderRadius={8}
            flex={1}
            maxWidth={COLUMN_WIDTH}
          >
            <Strong>{stat.title}</Strong>
            <Text color="muted" size={300}>
              Last 30 days
            </Text>
            <Pane display="flex" alignItems="center" gap={16}>
              {stat.icon}
              {isLoading && <Spin indicator={<LoadingOutlined />} />}
              {!isLoading && <Heading size={900}>{stat.value}</Heading>}
            </Pane>
          </Pane>
        )
      })}
    </Pane>
  )
}
