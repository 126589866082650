import { DeleteOutlined } from '@ant-design/icons'
import { Input } from 'antd'
import { updateOrgCoreValues } from 'api/databaseCalls'
import { CORE_VALUE_CREATED } from 'constants/events'
import { OrgContext, UserContext } from 'context'
import {
  Button,
  Heading,
  IconButton,
  Pane,
  PlusIcon,
  toaster,
} from 'evergreen-ui'
import { isEmpty } from 'lodash-es'
import { useContext, useState } from 'react'
import { logEvent } from 'utils'
import { CORE_VALUE_MAX } from '../constants/brandingConstants'

function CoreValueInput({
  allCoreValues,
  defaultCoreValue,
  index,
}: {
  allCoreValues: string[]
  defaultCoreValue: string
  index: number
}) {
  const org = useContext(OrgContext)
  const orgId = org.id
  const user = useContext(UserContext)
  const userId = user.id

  const [coreValue, setCoreValue] = useState<string>(defaultCoreValue)
  const [inView, setInView] = useState(false)
  const handleUpdateCoreValues = () => {
    if (coreValue !== defaultCoreValue) {
      const newCoreValues = allCoreValues
      newCoreValues[index] = coreValue
      logEvent(CORE_VALUE_CREATED, {
        coreValue,
        orgId,
        orgName: org.name,
        orgSubscriptionStatus: org.subscriptionStatus,
        userId,
      })
      updateOrgCoreValues({ orgId, coreValues: newCoreValues })
      toaster.success('Core value updated successfully')
    }
  }
  const handleDeleteCoreValue = () => {
    const newCoreValues = allCoreValues
    newCoreValues.splice(index, 1)
    updateOrgCoreValues({ orgId, coreValues: newCoreValues })
    toaster.success('Core value removed successfully')
  }
  return (
    <Pane
      onMouseEnter={() => setInView(true)}
      onMouseLeave={() => setInView(false)}
      display="flex"
      gap={8}
    >
      <Input
        onBlur={handleUpdateCoreValues}
        onPressEnter={handleUpdateCoreValues}
        value={coreValue}
        onChange={e => setCoreValue(e.target.value)}
        placeholder={`Core value ${index + 1}`}
        maxLength={128}
        style={{ width: 300 }}
      />
      {inView && allCoreValues.length > 0 && (
        <IconButton
          onClick={handleDeleteCoreValue}
          appearance="minimal"
          icon={<DeleteOutlined />}
        />
      )}
    </Pane>
  )
}

export function ChangeCompanyCoreValues() {
  const org = useContext(OrgContext)
  const orgId = org.id
  const { coreValues } = org

  const handleAddCoreValue = () => {
    const newCoreValues = isEmpty(coreValues) ? ['', ''] : [...coreValues, '']
    updateOrgCoreValues({ orgId, coreValues: newCoreValues })
  }
  return (
    <Pane display="flex" flexDirection="column" gap={8}>
      <Heading>Core values</Heading>

      {isEmpty(coreValues) && (
        <CoreValueInput
          defaultCoreValue=""
          index={0}
          allCoreValues={coreValues}
        />
      )}
      {coreValues.map((value, index) => (
        <CoreValueInput
          key={String(index) + value}
          defaultCoreValue={value}
          index={index}
          allCoreValues={coreValues}
        />
      ))}
      <Pane>
        {coreValues.length < CORE_VALUE_MAX && (
          <Button
            onClick={handleAddCoreValue}
            iconBefore={PlusIcon}
            appearance="minimal"
          >
            Add another
          </Button>
        )}
      </Pane>
    </Pane>
  )
}
