// @generated by protoc-gen-es v1.6.0 with parameter "target=ts"
// @generated from file perkup/v1/contentful.proto (package perkup.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";
import { ProgramType } from "./program_pb.js";

/**
 * Firestore /countries
 *
 * @generated from message perkup.v1.Country
 */
export class Country extends Message<Country> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: bool allMerchants = 2;
   */
  allMerchants = false;

  /**
   * @generated from field: bool amazon = 3;
   */
  amazon = false;

  /**
   * @generated from field: string currency = 4;
   */
  currency = "";

  /**
   * @generated from field: repeated string excluded_merchants = 5;
   */
  excludedMerchants: string[] = [];

  /**
   * @generated from field: repeated string included_merchants = 6;
   */
  includedMerchants: string[] = [];

  /**
   * @generated from field: string iso3 = 7;
   */
  iso3 = "";

  /**
   * @generated from field: string name = 8;
   */
  name = "";

  constructor(data?: PartialMessage<Country>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "perkup.v1.Country";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "allMerchants", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 3, name: "amazon", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 4, name: "currency", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "excluded_merchants", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 6, name: "included_merchants", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 7, name: "iso3", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Country {
    return new Country().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Country {
    return new Country().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Country {
    return new Country().fromJsonString(jsonString, options);
  }

  static equals(a: Country | PlainMessage<Country> | undefined, b: Country | PlainMessage<Country> | undefined): boolean {
    return proto3.util.equals(Country, a, b);
  }
}

/**
 * Firestore /macroCategories
 *
 * @generated from message perkup.v1.MacroCategory
 */
export class MacroCategory extends Message<MacroCategory> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: perkup.v1.ContentfulImage color_outline_icon = 2;
   */
  colorOutlineIcon?: ContentfulImage;

  /**
   * @generated from field: optional string description = 3;
   */
  description?: string;

  /**
   * @generated from field: string name = 4;
   */
  name = "";

  /**
   * @generated from field: optional int32 rank = 5;
   */
  rank?: number;

  /**
   * @generated from field: perkup.v1.ContentfulImage regular_icon = 6;
   */
  regularIcon?: ContentfulImage;

  /**
   * @generated from field: perkup.v1.ContentfulSys sys = 7;
   */
  sys?: ContentfulSys;

  constructor(data?: PartialMessage<MacroCategory>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "perkup.v1.MacroCategory";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "color_outline_icon", kind: "message", T: ContentfulImage },
    { no: 3, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 4, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "rank", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 6, name: "regular_icon", kind: "message", T: ContentfulImage },
    { no: 7, name: "sys", kind: "message", T: ContentfulSys },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): MacroCategory {
    return new MacroCategory().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): MacroCategory {
    return new MacroCategory().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): MacroCategory {
    return new MacroCategory().fromJsonString(jsonString, options);
  }

  static equals(a: MacroCategory | PlainMessage<MacroCategory> | undefined, b: MacroCategory | PlainMessage<MacroCategory> | undefined): boolean {
    return proto3.util.equals(MacroCategory, a, b);
  }
}

/**
 * Firestore /macroCategories/:id/approvedMCCs
 *
 * @generated from message perkup.v1.ApprovedMCC
 */
export class ApprovedMCC extends Message<ApprovedMCC> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: int32 mcc = 2;
   */
  mcc = 0;

  /**
   * @generated from field: string name = 3;
   */
  name = "";

  /**
   * @generated from field: string stripe_category_name = 4;
   */
  stripeCategoryName = "";

  /**
   * @generated from field: perkup.v1.ContentfulSys sys = 5;
   */
  sys?: ContentfulSys;

  constructor(data?: PartialMessage<ApprovedMCC>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "perkup.v1.ApprovedMCC";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "mcc", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "stripe_category_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "sys", kind: "message", T: ContentfulSys },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ApprovedMCC {
    return new ApprovedMCC().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ApprovedMCC {
    return new ApprovedMCC().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ApprovedMCC {
    return new ApprovedMCC().fromJsonString(jsonString, options);
  }

  static equals(a: ApprovedMCC | PlainMessage<ApprovedMCC> | undefined, b: ApprovedMCC | PlainMessage<ApprovedMCC> | undefined): boolean {
    return proto3.util.equals(ApprovedMCC, a, b);
  }
}

/**
 * Firestore /macroCategories/:id/approvedYelpCategories
 *
 * @generated from message perkup.v1.ApprovedYelpCategory
 */
export class ApprovedYelpCategory extends Message<ApprovedYelpCategory> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string alias = 2;
   */
  alias = "";

  /**
   * @generated from field: perkup.v1.ContentfulSys sys = 3;
   */
  sys?: ContentfulSys;

  /**
   * @generated from field: string title = 4;
   */
  title = "";

  constructor(data?: PartialMessage<ApprovedYelpCategory>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "perkup.v1.ApprovedYelpCategory";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "alias", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "sys", kind: "message", T: ContentfulSys },
    { no: 4, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ApprovedYelpCategory {
    return new ApprovedYelpCategory().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ApprovedYelpCategory {
    return new ApprovedYelpCategory().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ApprovedYelpCategory {
    return new ApprovedYelpCategory().fromJsonString(jsonString, options);
  }

  static equals(a: ApprovedYelpCategory | PlainMessage<ApprovedYelpCategory> | undefined, b: ApprovedYelpCategory | PlainMessage<ApprovedYelpCategory> | undefined): boolean {
    return proto3.util.equals(ApprovedYelpCategory, a, b);
  }
}

/**
 * Firestore /merchants
 *
 * @generated from message perkup.v1.Merchant
 */
export class Merchant extends Message<Merchant> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: optional perkup.v1.ContentfulImage banner = 2;
   */
  banner?: ContentfulImage;

  /**
   * @generated from field: string caption = 3;
   */
  caption = "";

  /**
   * @generated from field: perkup.v1.Merchant.CategoryCollection category_collection = 4;
   */
  categoryCollection?: Merchant_CategoryCollection;

  /**
   * @generated from field: int32 cohort_rank = 5;
   */
  cohortRank = 0;

  /**
   * @generated from field: optional string cta = 6;
   */
  cta?: string;

  /**
   * @generated from field: string description = 7;
   */
  description = "";

  /**
   * @generated from field: optional int32 discount_percent = 8;
   */
  discountPercent?: number;

  /**
   * @generated from field: optional int32 discount_price = 9;
   */
  discountPrice?: number;

  /**
   * @generated from field: optional bool display_name = 10;
   */
  displayName?: boolean;

  /**
   * @generated from field: perkup.v1.ContentfulImage image = 11;
   */
  image?: ContentfulImage;

  /**
   * @generated from field: string name = 12;
   */
  name = "";

  /**
   * @generated from field: optional int32 rank = 13;
   */
  rank?: number;

  /**
   * @generated from field: perkup.v1.ContentfulSys sys = 14;
   */
  sys?: ContentfulSys;

  /**
   * @generated from field: string url = 15;
   */
  url = "";

  constructor(data?: PartialMessage<Merchant>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "perkup.v1.Merchant";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "banner", kind: "message", T: ContentfulImage, opt: true },
    { no: 3, name: "caption", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "category_collection", kind: "message", T: Merchant_CategoryCollection },
    { no: 5, name: "cohort_rank", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 6, name: "cta", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 7, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "discount_percent", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 9, name: "discount_price", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 10, name: "display_name", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 11, name: "image", kind: "message", T: ContentfulImage },
    { no: 12, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 13, name: "rank", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 14, name: "sys", kind: "message", T: ContentfulSys },
    { no: 15, name: "url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Merchant {
    return new Merchant().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Merchant {
    return new Merchant().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Merchant {
    return new Merchant().fromJsonString(jsonString, options);
  }

  static equals(a: Merchant | PlainMessage<Merchant> | undefined, b: Merchant | PlainMessage<Merchant> | undefined): boolean {
    return proto3.util.equals(Merchant, a, b);
  }
}

/**
 * @generated from message perkup.v1.Merchant.CategoryCollection
 */
export class Merchant_CategoryCollection extends Message<Merchant_CategoryCollection> {
  /**
   * @generated from field: repeated perkup.v1.Merchant.CategoryCollection.Item items = 1;
   */
  items: Merchant_CategoryCollection_Item[] = [];

  constructor(data?: PartialMessage<Merchant_CategoryCollection>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "perkup.v1.Merchant.CategoryCollection";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "items", kind: "message", T: Merchant_CategoryCollection_Item, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Merchant_CategoryCollection {
    return new Merchant_CategoryCollection().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Merchant_CategoryCollection {
    return new Merchant_CategoryCollection().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Merchant_CategoryCollection {
    return new Merchant_CategoryCollection().fromJsonString(jsonString, options);
  }

  static equals(a: Merchant_CategoryCollection | PlainMessage<Merchant_CategoryCollection> | undefined, b: Merchant_CategoryCollection | PlainMessage<Merchant_CategoryCollection> | undefined): boolean {
    return proto3.util.equals(Merchant_CategoryCollection, a, b);
  }
}

/**
 * @generated from message perkup.v1.Merchant.CategoryCollection.Item
 */
export class Merchant_CategoryCollection_Item extends Message<Merchant_CategoryCollection_Item> {
  /**
   * @generated from field: string name = 1;
   */
  name = "";

  /**
   * @generated from field: perkup.v1.ContentfulSys sys = 2;
   */
  sys?: ContentfulSys;

  constructor(data?: PartialMessage<Merchant_CategoryCollection_Item>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "perkup.v1.Merchant.CategoryCollection.Item";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "sys", kind: "message", T: ContentfulSys },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Merchant_CategoryCollection_Item {
    return new Merchant_CategoryCollection_Item().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Merchant_CategoryCollection_Item {
    return new Merchant_CategoryCollection_Item().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Merchant_CategoryCollection_Item {
    return new Merchant_CategoryCollection_Item().fromJsonString(jsonString, options);
  }

  static equals(a: Merchant_CategoryCollection_Item | PlainMessage<Merchant_CategoryCollection_Item> | undefined, b: Merchant_CategoryCollection_Item | PlainMessage<Merchant_CategoryCollection_Item> | undefined): boolean {
    return proto3.util.equals(Merchant_CategoryCollection_Item, a, b);
  }
}

/**
 * Firestore /programTemplates
 *
 * @generated from message perkup.v1.ProgramTemplate
 */
export class ProgramTemplate extends Message<ProgramTemplate> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string name = 2;
   */
  name = "";

  /**
   * @generated from field: string preview_image = 3;
   */
  previewImage = "";

  /**
   * @generated from field: string program_image = 4;
   */
  programImage = "";

  /**
   * @generated from field: string program_message = 5;
   */
  programMessage = "";

  /**
   * @generated from field: string program_title = 6;
   */
  programTitle = "";

  /**
   * @generated from field: perkup.v1.ProgramType program_type = 7;
   */
  programType = ProgramType.UNKNOWN_ProgramType;

  /**
   * @generated from field: optional int32 rank = 8;
   */
  rank?: number;

  /**
   * @generated from field: repeated perkup.v1.ProgramTemplate.TemplateCategory categories = 9;
   */
  categories: ProgramTemplate_TemplateCategory[] = [];

  /**
   * @generated from field: string image_query = 10;
   */
  imageQuery = "";

  /**
   * @generated from field: optional bool featured = 11;
   */
  featured?: boolean;

  constructor(data?: PartialMessage<ProgramTemplate>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "perkup.v1.ProgramTemplate";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "preview_image", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "program_image", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "program_message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "program_title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "program_type", kind: "enum", T: proto3.getEnumType(ProgramType) },
    { no: 8, name: "rank", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 9, name: "categories", kind: "message", T: ProgramTemplate_TemplateCategory, repeated: true },
    { no: 10, name: "image_query", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "featured", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ProgramTemplate {
    return new ProgramTemplate().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ProgramTemplate {
    return new ProgramTemplate().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ProgramTemplate {
    return new ProgramTemplate().fromJsonString(jsonString, options);
  }

  static equals(a: ProgramTemplate | PlainMessage<ProgramTemplate> | undefined, b: ProgramTemplate | PlainMessage<ProgramTemplate> | undefined): boolean {
    return proto3.util.equals(ProgramTemplate, a, b);
  }
}

/**
 * @generated from message perkup.v1.ProgramTemplate.TemplateCategory
 */
export class ProgramTemplate_TemplateCategory extends Message<ProgramTemplate_TemplateCategory> {
  /**
   * @generated from field: perkup.v1.ContentfulSys sys = 1;
   */
  sys?: ContentfulSys;

  /**
   * @generated from field: string title = 2;
   */
  title = "";

  constructor(data?: PartialMessage<ProgramTemplate_TemplateCategory>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "perkup.v1.ProgramTemplate.TemplateCategory";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "sys", kind: "message", T: ContentfulSys },
    { no: 2, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ProgramTemplate_TemplateCategory {
    return new ProgramTemplate_TemplateCategory().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ProgramTemplate_TemplateCategory {
    return new ProgramTemplate_TemplateCategory().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ProgramTemplate_TemplateCategory {
    return new ProgramTemplate_TemplateCategory().fromJsonString(jsonString, options);
  }

  static equals(a: ProgramTemplate_TemplateCategory | PlainMessage<ProgramTemplate_TemplateCategory> | undefined, b: ProgramTemplate_TemplateCategory | PlainMessage<ProgramTemplate_TemplateCategory> | undefined): boolean {
    return proto3.util.equals(ProgramTemplate_TemplateCategory, a, b);
  }
}

/**
 * @generated from message perkup.v1.ContentfulImage
 */
export class ContentfulImage extends Message<ContentfulImage> {
  /**
   * @generated from field: string url = 1;
   */
  url = "";

  constructor(data?: PartialMessage<ContentfulImage>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "perkup.v1.ContentfulImage";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ContentfulImage {
    return new ContentfulImage().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ContentfulImage {
    return new ContentfulImage().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ContentfulImage {
    return new ContentfulImage().fromJsonString(jsonString, options);
  }

  static equals(a: ContentfulImage | PlainMessage<ContentfulImage> | undefined, b: ContentfulImage | PlainMessage<ContentfulImage> | undefined): boolean {
    return proto3.util.equals(ContentfulImage, a, b);
  }
}

/**
 * @generated from message perkup.v1.ContentfulSys
 */
export class ContentfulSys extends Message<ContentfulSys> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  constructor(data?: PartialMessage<ContentfulSys>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "perkup.v1.ContentfulSys";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ContentfulSys {
    return new ContentfulSys().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ContentfulSys {
    return new ContentfulSys().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ContentfulSys {
    return new ContentfulSys().fromJsonString(jsonString, options);
  }

  static equals(a: ContentfulSys | PlainMessage<ContentfulSys> | undefined, b: ContentfulSys | PlainMessage<ContentfulSys> | undefined): boolean {
    return proto3.util.equals(ContentfulSys, a, b);
  }
}

/**
 * Firestore /BulkSwagCatalogs
 *
 * @generated from message perkup.v1.BulkSwagCatalog
 */
export class BulkSwagCatalog extends Message<BulkSwagCatalog> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string name = 2;
   */
  name = "";

  /**
   * @generated from field: string link = 3;
   */
  link = "";

  /**
   * @generated from field: string org_id = 4;
   */
  orgId = "";

  /**
   * @generated from field: string image_url = 5;
   */
  imageUrl = "";

  constructor(data?: PartialMessage<BulkSwagCatalog>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "perkup.v1.BulkSwagCatalog";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "link", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "org_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "image_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): BulkSwagCatalog {
    return new BulkSwagCatalog().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): BulkSwagCatalog {
    return new BulkSwagCatalog().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): BulkSwagCatalog {
    return new BulkSwagCatalog().fromJsonString(jsonString, options);
  }

  static equals(a: BulkSwagCatalog | PlainMessage<BulkSwagCatalog> | undefined, b: BulkSwagCatalog | PlainMessage<BulkSwagCatalog> | undefined): boolean {
    return proto3.util.equals(BulkSwagCatalog, a, b);
  }
}

