import { PlacidTags } from 'constants/placid/placid-tags'
import { UseCaseRecord } from 'pages/NewReward/types/forms'
import { CategoryTitle } from 'pages/Templates/constants'
import { RewardUseCasesKeysType } from './RewardUseCasesKeys'

export const holidayUseCases = {
  HOLIDAYS: {
    label: 'Holidays',
    details: {
      searchQuery: 'holidays',
      templateCategories: [CategoryTitle.HOLIDAYS],
      placidTags: [PlacidTags.HOLIDAYS],
    },
    emailData: [
      {
        title: "Season's Greetings!",
        note: 'Warm wishes and joy this holiday season!',
      },
      {
        title: 'Happy Holidays!',
        note: 'Enjoy the festivities and the time with loved ones.',
      },
      {
        title: 'Holiday Cheers 🥂',
        note: "Here's to a season filled with warmth, comfort, and good cheer!",
      },
    ],
  },
  THANKSGIVING: {
    label: 'Thanksgiving',
    details: {
      searchQuery: 'happy Thanksgiving',
      templateCategories: [],
      placidTags: [PlacidTags.THANKSGIVING],
    },
    emailData: [
      {
        title: 'Thankful Moments!',
        note: 'Wishing you a season filled with warmth, comfort, and good cheer!',
      },
      {
        title: 'Turkey Day 🦃',
        note: 'Grateful for wonderful employees like you this Thanksgiving!',
      },
      {
        title: 'Give Thanks!',
        note: 'May your Thanksgiving be filled with blessings and joy.',
      },
    ],
  },
  CHRISTMAS: {
    label: 'Christmas',
    details: {
      searchQuery: 'merry Christmas',
      templateCategories: [],
      placidTags: [PlacidTags.CHRISTMAS],
    },
    emailData: [
      {
        title: "Season's Greetings!",
        note: 'Wishing you joy, peace, and warmth this Christmas!',
      },
      {
        title: 'Merry & Bright 🎄',
        note: 'May the magic of Christmas fill your heart and home!',
      },
      {
        title: 'Festive Cheers!',
        note: 'Thanks for bringing joy to our workplace. Merry Christmas!',
      },
    ],
  },
  HANUKKAH: {
    label: 'Hanukkah',
    details: {
      searchQuery: 'happy Hanukkah',
      templateCategories: [],
      placidTags: [PlacidTags.HANUKKAH],
    },
    emailData: [
      {
        title: 'Lights & Miracles!',
        note: 'Wishing you eight days filled with peace, joy, and lights!',
      },
      {
        title: 'Hanukkah Joy 🕎',
        note: 'May the Festival of Lights bring blessings to you and your family!',
      },
      {
        title: 'Celebrate the Light!',
        note: 'Wishing you a Hanukkah full of beauty and joy.',
      },
    ],
  },
  HALLOWEEN: {
    label: 'Halloween',
    details: {
      searchQuery: 'halloween',
      templateCategories: [],
      placidTags: [PlacidTags.HALLOWEEN],
    },
    emailData: [
      {
        title: 'Happy Halloween 🎃',
        note: 'Enjoy the spooky season and have a fang-tastic day filled with treats and maybe a few tricks! 🕸👻 #SpookySeason',
      },
      {
        title: 'Happy Halloween 🎃',
        note: 'Boo! 👻 Wishing everyone a spooktacular Halloween! 🎃 May your day be filled with fun, laughter, and lots of candy! 🍬',
      },
      {
        title: 'Happy Halloween 🎃',
        note: 'Don’t let the vampires bite! Enjoy the thrills and chills of the day and stay safe! 🕷🧡',
      },
    ],
  },
  MENTAL_HEALTH_DAY: {
    label: 'Mental Health Day',
    details: {
      searchQuery: 'relax',
      templateCategories: [],
      placidTags: [PlacidTags.WORLD_MENTAL_HEALTH_DAY],
    },
    emailData: [
      {
        title: 'World Mental Health Day',
        note: 'Take a moment to breathe, reflect, and relax. Reach out if you need support. Let’s foster a supportive and mindful workplace together. 🌟',
      },
    ],
  },
  NEW_YEAR: {
    label: 'New Year',
    details: {
      searchQuery: 'new year',
      templateCategories: [],
      placidTags: [PlacidTags.NEW_YEAR],
    },
    emailData: [
      {
        title: 'Happy New Year 🍾',
        note: 'Thank you for your hard work and dedication 🎉 Let’s make this our best year yet. Wishing you and your families joy and success!',
      },
      {
        title: 'Happy New Year ✨',
        note: 'Cheers to a fresh start! 🥂 Your hard work truly makes us shine. Here’s to another fantastic year together. Happy New Year!',
      },
      {
        title: 'Happy New Year 🎉',
        note: 'Let’s celebrate our successes and look forward to an amazing year ahead. Wishing you health, happiness, and success!',
      },
    ],
  },
} satisfies Partial<Record<RewardUseCasesKeysType, UseCaseRecord>>
