import Skeleton from 'antd/es/skeleton'
import { Pane } from 'evergreen-ui'

export function ProgramListLoadingState() {
  const rewardSkeletons: JSX.Element[] = []

  for (let i = 0; i < 5; i += 1) {
    rewardSkeletons.push(
      <Pane
        key={i}
        display="flex"
        alignItems="center"
        width="100%"
        border
        borderRadius={4}
        gap={16}
        padding={16}
      >
        <Skeleton.Image active />
        <Skeleton active paragraph={{ rows: 1 }} />
      </Pane>
    )
  }

  return (
    <Pane display="flex" flexDirection="column" gap={8}>
      {rewardSkeletons}
    </Pane>
  )
}
