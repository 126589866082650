import { Timestamp } from '@bufbuild/protobuf'
import { captureMessage } from '@sentry/react'
import { Button } from 'antd'
import { completeOnboarding } from 'api/databaseCalls/writes/organizations'
import { callFunction } from 'api/functionCalls'
import { ThemeEditorModal } from 'components'
import { EmailPreview } from 'components/EmailPreview'
import { defaultButtonStyles } from 'constants/antdesign'
import { PERKUP_PRIMARY_LOGO } from 'constants/logos'
import * as ROUTES from 'constants/routes'
import { DEFAULT_ROUTES } from 'constants/routes'
import { IndividualContext, OrgContext, UserContext } from 'context'
import {
  Heading,
  MediaIcon,
  Pane,
  SendMessageIcon,
  Strong,
  Text,
  TextInput,
  Textarea,
  toaster,
} from 'evergreen-ui'
import {
  Program,
  ProgramFrequency,
  ProgramStatus,
  ProgramType,
} from 'gen/perkup/v1/program_pb'
import { useDefaultOrgColors } from 'hooks'
import { EmailFormData } from 'pages/NewReward/types/forms'
import { createProgram } from 'pages/NewReward/utils/programs'
import { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'

export function FirstReward() {
  // Context
  const org = useContext(OrgContext)
  const orgId = org.id
  const user = useContext(UserContext)
  const userId = user.id
  const individual = useContext(IndividualContext)
  const individualId = individual?.id

  // React router
  const navigate = useNavigate()

  // Form state
  const [email, setEmail] = useState<EmailFormData>({
    title: 'Welcome to the team!',
    banner:
      'https://images.unsplash.com/photo-1600577916048-804c9191e36c?ixid=M3wyNzc5NjV8MHwxfHNlYXJjaHwyfHxXZWxjb21lfGVufDB8MHx8fDE3MTQ0NDc5MzN8MA&ixlib=rb-4.0.3',
    note: "Welcome! We're so happy to have you, we hope this makes your first day a little more special.",
    fromName: org.name,
  })
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false)

  const { defaultColor } = useDefaultOrgColors()

  // Handlers
  const handleChangeImage = (newBanner: string) => {
    setEmail({ ...email, banner: newBanner })
  }

  const handleChangeTitle = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail({ ...email, title: e.currentTarget.value })
  }

  const handleChangeNote = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setEmail({ ...email, note: e.currentTarget.value })
  }

  const handleChangeFrom = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail({ ...email, fromName: e.currentTarget.value })
  }

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    setIsLoadingSubmit(true)
    if (!individualId) {
      toaster.warning('Error creating reward')
      captureMessage('Error creating program', 'error')
      return
    }

    // Create the program
    const program = new Program({
      name: email.title,
      budget: 5000,
      frequency: ProgramFrequency.once,
      approvedCategories: [],
      autoEnrolled: false,
      type: ProgramType.rewards,
      allMerchants: true,
      startsOn: Timestamp.now(),
      note: email.note,
      email: {
        banner: email.banner,
        fromName: email.fromName,
        title: email.title,
      },
      ownerId: userId,
      status: ProgramStatus.active,
      prorate: false,
      created: Timestamp.now(),
      totalSpent: 0,
      totalBudget: 0,
      isDeleted: false,
      accountId: org?.defaultAccountId,
    })

    // Add program to org and add recipients to program
    const addedProgram = await createProgram({
      org,
      program,
      recipientEmails: [],
      recipientIds: [individualId],
      senderEmail: individual?.email,
    })

    // Check if added program is fine
    if (!addedProgram?.id) {
      toaster.warning('Error creating reward')
      captureMessage('Error creating program', 'error')
      setIsLoadingSubmit(false)
      return
    }

    callFunction('mailjet-SendBulk', {
      orgId,
      programId: addedProgram.id,
      logo: org.logoUrl || PERKUP_PRIMARY_LOGO,
      banner: email.banner,
      title: email.title,
      programName: email.title,
      programNote: email.note,
      fromName: email.fromName,
      peopleIds: [individualId],
      emails: [],
      color: defaultColor,
    })

    completeOnboarding({ id: org.id, collectionPath: 'organizations' })
    navigate(
      `${DEFAULT_ROUTES.ORGANIZATION.REWARDS.SPOT}?${ROUTES.PARAM_NEW_PROGRAM}=true`
    )
    setIsLoadingSubmit(false)
  }

  return (
    <Pane display="flex" justifyContent="center" width="100%" paddingX={16}>
      <Pane
        display="flex"
        flexWrap="wrap"
        justifyContent="space-evenly"
        maxWidth={1400}
        gap={32}
        width="100%"
      >
        {/** REWARD FORM */}
        <Pane
          flex={1}
          maxWidth={600}
          minWidth={450}
          onSubmit={handleSubmit}
          is="form"
          display="flex"
          flexDirection="column"
          gap={32}
        >
          <Pane>
            <Heading size={900} marginBottom={16}>
              Create your first reward
            </Heading>
            <Text size={500}>
              Don‘t worry, you can always delete this reward later.
            </Text>
          </Pane>

          <Pane borderBottom />

          <Pane display="flex" gap={32}>
            {/** TEXT INPUTS */}
            <Pane flex={2} display="flex" flexDirection="column">
              <Pane marginBottom={16}>
                <Strong>Title</Strong>
                <TextInput
                  width="100%"
                  required
                  value={email.title}
                  placeholder="title"
                  onChange={handleChangeTitle}
                  marginTop={4}
                  borderRadius={8}
                />
              </Pane>

              <Textarea
                width="100%"
                required
                value={email.note}
                placeholder="message"
                onChange={handleChangeNote}
                marginBottom={12}
                borderRadius={8}
              />

              <Pane>
                <Strong marginBottom={4}>From</Strong>

                <Pane
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <TextInput
                    required
                    value={email.fromName}
                    placeholder="from"
                    onChange={handleChangeFrom}
                    borderRadius={8}
                  />
                  <ThemeEditorModal
                    modalTrigger={
                      <Button
                        icon={<MediaIcon />}
                        type="text"
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: 8,
                        }}
                      >
                        Change image
                      </Button>
                    }
                    onConfirmImage={handleChangeImage}
                  />
                </Pane>
              </Pane>
            </Pane>
          </Pane>

          <Button
            htmlType="submit"
            size="large"
            type="primary"
            loading={isLoadingSubmit}
            style={{ ...defaultButtonStyles, gap: 16 }}
            disabled={!individualId}
          >
            Send to yourself
            <SendMessageIcon />
          </Button>
        </Pane>

        {/** REWARD EMAIL PREVIEW */}
        <Pane>
          <Pane marginTop={16}>
            <EmailPreview
              banner={email.banner}
              title={email.title}
              programNote={email.note}
              fromName={email.fromName}
              amount={5000}
              isGift={false}
            />
          </Pane>
        </Pane>
      </Pane>
    </Pane>
  )
}

export default FirstReward
