import { captureException } from '@sentry/react'
import {
  collection,
  collectionGroup,
  getDocs,
  getFirestore,
  onSnapshot,
  orderBy,
  query,
  where,
} from 'firebase/firestore'
import { Action, CustomAction, Rule } from 'gen/perkup/v1/rules_pb'
import { isFunction } from 'lodash-es'
import { RuleGroups, RuleIds } from 'types/Notifications'
import { storedId } from 'utils/firestore'
import { toSentry } from 'utils/sentry'

export function ListenToActionsByOrgId({
  orgId,
  cb,
}: {
  orgId: string
  cb: (actions: Action[]) => void
}) {
  const db = getFirestore()
  const colRef = collectionGroup(db, 'actions').withConverter(storedId(Action))
  const q = query(colRef, where('orgId', '==', orgId))

  return onSnapshot(
    q,
    query => cb(query.docs.map(doc => doc.data())),
    error => {
      console.error(error)
      captureException(toSentry(error), {
        contexts: {
          ListenToActionsByOrgId: { orgId },
        },
      })
    }
  )
}

export function ListenToRulesByOrgId({
  orgId,
  ruleGroup,
  cb,
  setHasLoaded,
  year,
}: {
  orgId: string
  ruleGroup: RuleGroups
  cb: (rules: Rule[]) => void
  setHasLoaded?: (loading: boolean) => void
  year?: number
}) {
  // Depending on groupName, return the appropriate filtered list of rules
  const db = getFirestore()
  const colRef = collection(db, `organizations/${orgId}/rules`).withConverter(
    storedId(Rule)
  )
  const q = !year
    ? query(
        colRef,
        where('group', '==', ruleGroup),
        where('year', '>=', 0),
        orderBy('year')
      )
    : query(colRef, where('group', '==', ruleGroup), where('year', '==', year))
  return onSnapshot(
    q,
    { includeMetadataChanges: true },
    query => {
      const unfilteredRules = query.docs.map(doc => doc.data())
      const filteredRules = unfilteredRules.filter(
        rule =>
          !(
            rule.id === RuleIds.ANNIVERSARY_RULE_ID ||
            rule.id === RuleIds.BIRTHDAY_RULE_ID
          )
      )
      cb(filteredRules)
      if (isFunction(setHasLoaded)) setHasLoaded(true)
    },
    error => {
      console.error(error)
      captureException(toSentry(error), {
        contexts: {
          ListenToRulesByOrgId: { orgId, ruleGroup },
        },
      })
      if (isFunction(setHasLoaded)) setHasLoaded(true)
    }
  )
}

export function ListenToCustomActions({
  individualId,
  cb,
}: {
  individualId: string
  cb: (customActions: CustomAction[]) => void
}) {
  const db = getFirestore()
  const colRef = collectionGroup(db, 'customActions').withConverter(
    storedId(CustomAction)
  )
  const q = query(colRef, where('id', '==', individualId))

  return onSnapshot(
    q,
    query => cb(query.docs.map(doc => doc.data())),
    error => {
      console.error(error)
      captureException(toSentry(error), {
        contexts: {
          ListenToCustomActions: { individualId },
        },
      })
    }
  )
}

export async function ListActionsByRuleId({
  orgId,
  ruleId,
}: {
  orgId: string
  ruleId: string
}) {
  try {
    const db = getFirestore()
    const colRef = collection(
      db,
      `organizations/${orgId}/rules/${ruleId}/actions`
    ).withConverter(storedId(Action))
    return await getDocs(colRef).then(query =>
      query.docs.map(doc => doc.data())
    )
  } catch (error) {
    console.error(error)
    captureException(toSentry(error), {
      contexts: {
        ListActionsByRuleId: { orgId, ruleId },
      },
    })
  }
  return []
}

export async function ListCustomActionsByActionId({
  orgId,
  ruleId,
  actionId,
}: {
  orgId: string
  ruleId: string
  actionId: string
}) {
  try {
    const db = getFirestore()
    const colRef = collection(
      db,
      `organizations/${orgId}/rules/${ruleId}/actions/${actionId}/customActions`
    ).withConverter(storedId(CustomAction))
    return await getDocs(query(colRef)).then(query =>
      query.docs.map(doc => doc.data())
    )
  } catch (error) {
    console.error(error)
    captureException(toSentry(error), {
      contexts: {
        ListCustomActionsByActionId: {
          orgId,
          ruleId,
          actionId,
        },
      },
    })
  }
}
