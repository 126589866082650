import Divider from 'antd/es/divider'
import SlackIntegration from 'components/Integrations/SlackIntegration'
import NotificationsList from 'components/Notifications/NotificationsList'
import { OrgContext } from 'context'
import { Pane } from 'evergreen-ui'
import { useContext } from 'react'

export default function Notifications() {
  const org = useContext(OrgContext)
  return (
    <Pane>
      <NotificationsList />
      {!org?.slackConnected && <Divider />}
      <SlackIntegration displayCard={!org?.slackConnected} />
    </Pane>
  )
}
