import { captureException } from '@sentry/react'
import { Flex } from 'antd'
import {
  AmazonReturnPolicy,
  CheckoutForm,
  PerkLoader,
  QuantitySelection,
} from 'components'
import { AmazonTerms } from 'components/Amazon/AmazonTerms'
import {
  ESTIMATED_TAX,
  SHIPPING_AND_HANDLING,
  TOTAL_BEFORE_TAX,
} from 'constants/checkout'
import {
  AMAZON_BUSINESS_STORE,
  CHECKOUT_STARTED,
  ORDER_UPDATED,
} from 'constants/events'
import { MAX_CHECKOUT_PAGE_WIDTH } from 'constants/layout'
import * as PARAMS from 'constants/params'
import * as ROUTES from 'constants/routes'
import { CountryContext, OrgContext, UserContext } from 'context'
import { Pane, Text, toaster } from 'evergreen-ui'
import { getAuth } from 'firebase/auth'
import { Search } from 'gen/amazon/search_connect'
import { GetProductRequest, GetProductResponse } from 'gen/amazon/search_pb'
import { ShippingAddress } from 'gen/perkup/v1/root_user_pb'
import { createClient } from 'hooks/useClient'
import toLower from 'lodash-es/toLower'
import toUpper from 'lodash-es/toUpper'
import { useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { placeAmazonOrder } from 'services/amazon'
import { AmazonOffer, AmazonProduct } from 'types/Amazon'
import { OrderConfirmationInfo } from 'types/Transactions'
import {
  getAmazonOrderAmounts,
  getAmazonUnitAmount,
  getCountryIsoAlpha2,
  logEvent,
} from 'utils'
import { toSentry } from 'utils/sentry'

function AmazonCheckout({
  amazonBudget,
  shippingAddress,
  onShippingAddressChange,
}: {
  amazonBudget: number
  shippingAddress?: ShippingAddress
  onShippingAddressChange: (address?: ShippingAddress) => void
}) {
  const country = useContext(CountryContext)
  const org = useContext(OrgContext)
  const orgId = org?.id
  const user = useContext(UserContext)
  const userId = user?.id

  const navigate = useNavigate()
  const location = useLocation() as {
    state: { quantity: number; product: AmazonProduct; offer: AmazonOffer }
  }

  const [searchParams, setSearchParams] = useSearchParams()

  const existingQty =
    location.state?.quantity || Number(searchParams.get(PARAMS.QUANITTY)) || 1

  const [product, setProduct] = useState<AmazonProduct>(location.state?.product)
  const [offer, setOffer] = useState<AmazonOffer>(location.state?.offer)
  const [quantity, setQuantity] = useState<number>(existingQty)

  const [isLoading, setIsLoading] = useState<boolean>(false)

  useEffect(() => {
    if (product) {
      logEvent(CHECKOUT_STARTED, {
        affiliation: AMAZON_BUSINESS_STORE,
        products: [
          {
            sku: product.asin,
            title: product.title,
            quantity,
          },
        ],
        orgId,
        userId,
      })
    }
  }, [product, quantity, orgId, userId])

  // Get product on page reload
  useEffect(() => {
    const asin = searchParams.get(PARAMS.ASIN)
    if (!product?.asin && asin) {
      setIsLoading(true)
      const getProductReq = new GetProductRequest({
        asin,
        countryCode: toUpper(getCountryIsoAlpha2(toLower(country.iso3))),
        postalCode: shippingAddress?.postalCode,
      })

      const getProduct = async () => {
        const accessToken = await getAuth().currentUser?.getIdToken()
        createClient(Search)
          .getProduct(getProductReq, {
            headers: { Authorization: `Bearer ${accessToken}` },
          })
          .then((value: GetProductResponse) => {
            const productResponse = JSON.parse(
              value.productResponse
            ) as AmazonProduct
            if (productResponse) {
              setProduct(productResponse)

              if (productResponse.includedDataTypes?.OFFERS) {
                setOffer(productResponse.includedDataTypes?.OFFERS[0])
              }
            }
          })
          .catch((error: any) => {
            console.error(error)
            captureException(toSentry(error), {
              contexts: {
                getProduct: { asin, postalCode: shippingAddress?.postalCode },
              },
            })
          })
          .finally(() => setIsLoading(false))
      }
      getProduct()
    }
  }, [product, shippingAddress?.postalCode, searchParams, country.iso3])

  if (isLoading && !product?.asin) {
    return <PerkLoader />
  }
  if (!product) {
    return <Text>No product selected</Text>
  }
  if (!offer?.price.value) {
    return <Text>Could not find price</Text>
  }

  const unitAmount = getAmazonUnitAmount({ offer })
  const { offerPriceInCents, itemTotal, estimatedTax, orderTotal } =
    getAmazonOrderAmounts({ unitAmount, quantity })

  const itemsLabel = quantity > 1 ? `Items (${quantity}):` : 'Items:'

  const orderSummary: { label: string; amount: number; border?: string }[] = [
    { label: itemsLabel, amount: itemTotal },
    { label: SHIPPING_AND_HANDLING, amount: 0, border: 'muted' },
    { label: TOTAL_BEFORE_TAX, amount: itemTotal },
    { label: ESTIMATED_TAX, amount: estimatedTax },
  ]

  const imageUrl = product?.includedDataTypes?.IMAGES?.length
    ? product.includedDataTypes.IMAGES[0].medium.url
    : undefined

  const handlePlaceOrder = async () => {
    setIsLoading(true)

    if (!shippingAddress) {
      toaster.warning('Please enter a shipping address.')
      return
    }

    placeAmazonOrder({
      addrOrganization: shippingAddress.name,
      shippingAddress,
      product,
      unitAmount,
      offer,
      quantity,
      orderTotal,
      itemTotal,
      estimatedTax,
      imageUrl,
      orgId,
      userId,
    })
      .then(response => {
        if (response) {
          toaster.success('Order successfully placed')

          const state: OrderConfirmationInfo = {
            transactionConfirmed: true,
            imageUrl,
            shippingAddress,
            deliveryInformation: offer.deliveryInformation ?? '',
          }

          navigate(ROUTES.AMAZON, { state })
        }
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const fulfillmentName =
    offer?.fulfillmentType === 'AMAZON_FULFILLMENT'
      ? 'Amazon'
      : offer.merchant.name

  return (
    <Flex justify="center" style={{ maxWidth: MAX_CHECKOUT_PAGE_WIDTH }}>
      <CheckoutForm
        budget={amazonBudget}
        orderTotal={orderTotal}
        deliveryInformation={offer.deliveryInformation}
        fulfillmentName={fulfillmentName}
        imageUrl={imageUrl}
        title={product.title}
        productPrice={offerPriceInCents}
        soldBy={offer.merchant?.name}
        onPlaceOrder={() => handlePlaceOrder()}
        isLoading={isLoading}
        termsElement={<AmazonTerms />}
        returnPolicyElement={<AmazonReturnPolicy marginTop={16} />}
        orderSummary={orderSummary}
        isPrime
        isLocalCurrency
        shippingAddress={shippingAddress}
        onAddressChange={onShippingAddressChange}
        quantityElement={
          <Pane display="flex" alignItems="center">
            <Text marginRight={4}>Qty:</Text>
            <Pane>
              <QuantitySelection
                offer={offer}
                quantity={quantity}
                setQuantity={newQuantity => {
                  setQuantity(newQuantity)
                  searchParams.set(PARAMS.QUANITTY, newQuantity.toString())
                  setSearchParams(searchParams)
                  logEvent(ORDER_UPDATED, {
                    affiliation: AMAZON_BUSINESS_STORE,
                    products: [
                      {
                        sku: product.asin,
                        name: product.title,
                        quantity,
                      },
                    ],
                    orgId,
                    userId,
                  })
                }}
              />
            </Pane>
          </Pane>
        }
      />
    </Flex>
  )
}

export default AmazonCheckout
