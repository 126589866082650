import { Image } from 'antd'
import { findFlagUrlByIso2Code } from 'country-flags-svg'
import { getCountryIsoAlpha2 } from 'utils'

export function RoundCountryImage({ iso3 }: { iso3: string }) {
  return (
    <Image
      width={16}
      height={16}
      preview={false}
      style={{
        borderRadius: '50%',
        aspectRatio: '1 / 1',
        objectFit: 'cover',
      }}
      src={findFlagUrlByIso2Code(getCountryIsoAlpha2(iso3).toUpperCase())}
    />
  )
}
