import { Flex } from 'antd'
import {
  OrderShipmentActionsTag,
  OrderStatusTag,
  ProductThumbnail,
  TruncatedCopyableText,
} from 'components'
import Tracking from 'components/Orders/Tracking'
import { GHOST_FULFILLMENT_NAME } from 'constants/orders'
import { Text } from 'evergreen-ui'
import { Order, OrderFulfillment } from 'gen/perkup/v1/vendor_pb'
import { buildGhostFulfillment2 } from 'utils'
import { insertElementIf } from 'utils/arrays'

export function OrderFulfillments({
  order,
  orderFulfillments = [],
}: {
  order: Order
  orderFulfillments: OrderFulfillment[]
}) {
  const ghostFulfillment = buildGhostFulfillment2(
    order.items,
    orderFulfillments
  )

  const fulfillmentsToDisplay: OrderFulfillment[] = [
    ...orderFulfillments,
    ...insertElementIf(!!ghostFulfillment, ghostFulfillment),
  ]

  return (
    <Flex vertical gap={16}>
      {fulfillmentsToDisplay.map((fulfillment, index) => {
        const {
          id,
          trackingCompany,
          trackingNumber,
          trackingUrl,
          shipmentActions,
          name,
          lineItems,
          status,
        } = fulfillment

        return (
          <>
            {/* FULFILLMENT */}
            <Flex key={id} vertical gap={8}>
              {/* HEADER */}
              <Flex vertical gap={8}>
                <Flex gap={8} align="center">
                  <OrderStatusTag withIcon status={status} />

                  {name && name !== GHOST_FULFILLMENT_NAME && (
                    <TruncatedCopyableText
                      text={name}
                      textMaxWidth={100}
                      type="fulfillment ID"
                    />
                  )}
                </Flex>
                <Flex gap={8} align="center">
                  {trackingCompany && (
                    <Text color="muted">{trackingCompany}:</Text>
                  )}
                  {trackingNumber && trackingUrl && (
                    <Tracking>
                      <Tracking.Link url={trackingUrl}>
                        {trackingNumber}
                      </Tracking.Link>
                    </Tracking>
                  )}
                  <OrderShipmentActionsTag shipmentActions={shipmentActions} />
                </Flex>
              </Flex>

              {/* ITEMS */}
              <Flex gap={8}>
                {lineItems.map(lineItem => {
                  // The lineItem in the fulfillment doesn't have enough info, so we need to find the item in the order
                  const itemForThisLineItem = order.items.find(
                    item => item.productVariantId === lineItem.variantId
                  )
                  return (
                    <ProductThumbnail key={itemForThisLineItem?.title}>
                      <ProductThumbnail.Image
                        imageUrl={itemForThisLineItem?.image ?? ''}
                      />

                      <Flex vertical>
                        <ProductThumbnail.Name>
                          {itemForThisLineItem?.title}
                        </ProductThumbnail.Name>
                      </Flex>
                    </ProductThumbnail>
                  )
                })}
              </Flex>
            </Flex>

            {/* DIVIDER */}
            {index < fulfillmentsToDisplay.length - 1 && (
              <div
                style={{
                  borderBottom: '1px solid #f0f0f0',
                }}
              />
            )}
          </>
        )
      })}
    </Flex>
  )
}
