import { Pane, PaneProps, Spinner } from 'evergreen-ui'

export function PerkLoader(props: PaneProps<'div'>) {
  return (
    <Pane
      display="flex"
      justifyContent="center"
      marginY={48}
      marginX="auto"
      {...props}
    >
      <Spinner />
    </Pane>
  )
}
