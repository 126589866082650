import { Tag } from 'antd'
import Color from 'color'
import { OrgContext } from 'context'
import { Heading, Pane, Strong, useTheme } from 'evergreen-ui'
import { useDefaultOrgColors } from 'hooks'
import { useContext } from 'react'
import { createDaysUntilString } from './CalendarUtils'
import { IEventInfo } from './EventDrawer'

export function EventDate({
  eventDate,
  eventInfo,
}: {
  eventDate: Date | undefined
  eventInfo: IEventInfo | undefined
}) {
  const org = useContext(OrgContext)
  const { defaultColor } = useDefaultOrgColors()
  // Extract date string from event
  const today = new Date()
  const selectedYear = eventInfo?.date.year()
  const eventDateInSelectedYear = eventDate
    ? new Date(eventDate.setFullYear(selectedYear || today.getFullYear()))
    : undefined

  const dayString = eventDate?.toLocaleDateString('en-us', { day: 'numeric' })
  const weekdayString = eventDate?.toLocaleDateString('en-us', {
    weekday: 'long',
  })
  const monthString = eventDate?.toLocaleDateString('en-us', { month: 'long' })

  // Create string for days until badge
  const daysUntilString = createDaysUntilString(
    eventDateInSelectedYear || today
  )

  const theme = useTheme()
  const color = Color(org.primaryColor)

  return (
    <Pane display="flex" flexDirection="column" alignItems="center">
      <Heading size={900} fontSize={72} color={defaultColor} marginBottom={16}>
        {dayString}
      </Heading>
      <Heading
        size={800}
        color={
          org.primaryColor
            ? color.lightness(45).toString()
            : theme.colors.blue700
        }
      >
        {weekdayString}
      </Heading>
      <Strong fontSize={12} color={defaultColor}>
        {monthString?.toUpperCase()}
      </Strong>
      <Tag color="default" style={{ marginTop: 8 }}>
        {daysUntilString}
      </Tag>
    </Pane>
  )
}
