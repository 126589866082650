import { cn } from '@repo/utils/ui'
import { Tag, TagProps } from 'antd'

export function CustomizableBadge({
  className,
}: {
  className?: TagProps['className']
}) {
  return (
    <Tag
      className={
        // Very important we use cn here to merge the tailwind classes. This allows the parent to pass new rules without overwriting the existing ones.
        cn('min-w-max', className)
      }
      color="processing"
    >
      Customizable
    </Tag>
  )
}
