import { CheckOutlined } from '@ant-design/icons'
import { Switch } from 'antd'
import { updateOrgSetting } from 'api/databaseCalls'
import { ConnectAlert } from 'components'
import { OrgContext } from 'context'
import {
  BanCircleIcon,
  Heading,
  ListItem,
  Pane,
  Text,
  TickCircleIcon,
  toaster,
  UnorderedList,
} from 'evergreen-ui'
import { Organization_StripeConnectAccountCapabilityStatus_Enum } from 'gen/perkup/v1/organization_pb'
import { useContext, useState } from 'react'
import { SettingOption } from 'types'

export function OrgSetting({
  settingOption,
}: {
  settingOption: SettingOption
}) {
  const { icon } = settingOption
  const org = useContext(OrgContext)

  const [checked, setChecked] = useState(settingOption.defaultValue)

  const handleSwitch = (value: boolean) => {
    setChecked(value)
    updateOrgSetting({ orgId: org.id, key: settingOption.key, value: !value })
    toaster.success(`${settingOption.title} turn ${value ? 'on' : 'off'}`)
  }

  return (
    <Pane padding={32} border borderRadius={4} height="fit-content">
      <Pane display="flex" gap={32} alignItems="flex-start">
        {icon}
        <Pane>
          <Pane display="flex" gap={16} alignItems="center" marginBottom={8}>
            <Heading size={600}>{settingOption.title}</Heading>
            <Switch
              checked={checked}
              onChange={checked => handleSwitch(checked)}
              checkedChildren={<CheckOutlined />}
            />
          </Pane>
          <Text>{settingOption.description}</Text>
          {checked && settingOption.checklistItems && (
            <UnorderedList>
              {settingOption.checklistItems.map(item => {
                const isActive =
                  item.status ===
                  Organization_StripeConnectAccountCapabilityStatus_Enum.active

                const displayStatus =
                  Organization_StripeConnectAccountCapabilityStatus_Enum[
                    item.status
                  ]

                return (
                  <ListItem
                    key={item.label}
                    icon={isActive ? TickCircleIcon : BanCircleIcon}
                    iconColor={isActive ? 'success' : 'danger'}
                  >
                    {item.label}: {displayStatus}
                  </ListItem>
                )
              })}
            </UnorderedList>
          )}
        </Pane>
      </Pane>
      {settingOption.hasWarning && <ConnectAlert />}
    </Pane>
  )
}
