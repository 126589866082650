import { SwagOrdersTable } from 'features'
import { Helmet } from 'react-helmet-async'

export default function OrgSwagOrdersPage() {
  return (
    <>
      <Helmet>
        <title>Orders | Swag</title>
      </Helmet>

      <main style={{ width: '100%', padding: '32px' }}>
        <SwagOrdersTable />
      </main>
    </>
  )
}
