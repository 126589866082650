// @generated by protoc-gen-es v1.6.0 with parameter "target=ts"
// @generated from file perkup/v1/rates.proto (package perkup.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";

/**
 * @generated from message perkup.v1.ExchangeRate
 */
export class ExchangeRate extends Message<ExchangeRate> {
  /**
   * @generated from field: map<string, float> rates = 1;
   */
  rates: { [key: string]: number } = {};

  constructor(data?: PartialMessage<ExchangeRate>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "perkup.v1.ExchangeRate";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "rates", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "scalar", T: 2 /* ScalarType.FLOAT */} },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ExchangeRate {
    return new ExchangeRate().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ExchangeRate {
    return new ExchangeRate().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ExchangeRate {
    return new ExchangeRate().fromJsonString(jsonString, options);
  }

  static equals(a: ExchangeRate | PlainMessage<ExchangeRate> | undefined, b: ExchangeRate | PlainMessage<ExchangeRate> | undefined): boolean {
    return proto3.util.equals(ExchangeRate, a, b);
  }
}

