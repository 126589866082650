import { Popover } from 'antd'
import { Text } from 'evergreen-ui'

export function TruncatedLongText({
  text,
  maxLength = 40,
}: {
  text: string
  maxLength?: number
}) {
  return (
    <Popover
      // avoid showing the popover if the string is not ellipsized
      // focus trigger will never trigger the popover
      trigger={text.length > maxLength ? 'hover' : 'focus'}
      content={<Text>{text}</Text>}
    >
      <Text width={150} className="two-line-truncate">
        {text}
      </Text>
    </Popover>
  )
}
