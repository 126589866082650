import { Tag } from 'antd'
import { Avatar, Pane, Text } from 'evergreen-ui'

export function CountryLabelWithFlag({
  flagUrl,
  label,
}: {
  flagUrl: string
  label: string
}) {
  return (
    <Pane display="flex" gap={4} alignItems="center">
      <Avatar alt="Country flag" src={flagUrl} size={12} />
      <Text fontWeight={400}>{label}</Text>
    </Pane>
  )
}

export function AllCountriesOptionPane() {
  return (
    <Tag>
      <Pane display="flex" gap={4} alignItems="center">
        🌐
        <Text fontWeight={400}>All countries</Text>
      </Pane>
    </Tag>
  )
}
