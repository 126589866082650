import {
  GetProductVariantById,
  ListProductVariantsByProductIds,
} from 'api/databaseCalls'
import { ProductVariant } from 'gen/perkup/v1/product_variant_pb'
import { Program_Gift } from 'gen/perkup/v1/program_pb'
import useIds from 'hooks/useIds'
import { isEmpty } from 'lodash-es'
import { useEffect, useState } from 'react'

export function useProductVariantByGift({ gift }: { gift?: Program_Gift }) {
  const { orgId } = useIds()
  const [productVariants, setProductVariants] = useState<ProductVariant[]>()

  useEffect(() => {
    if (!gift) {
      setProductVariants(undefined)
      return
    }
    if (gift?.productVariantId) {
      GetProductVariantById({ productVariantId: gift?.productVariantId }).then(
        pv => {
          if (!pv) {
            setProductVariants(undefined)
            return
          }
          setProductVariants([pv])
        }
      )
    } else if (!isEmpty(gift?.productIds)) {
      ListProductVariantsByProductIds({
        orgId,
        productIds: gift?.productIds,
        uniq: true,
      }).then(variants => {
        setProductVariants(variants)
      })
    }
  }, [gift, orgId])

  return {
    productVariants,
  }
}
