import cube from '@cubejs-client/core'

import { captureException } from '@sentry/react'
import { getAuth } from 'firebase/auth'
import { CubeTokens } from 'gen/perkup/v1/cube_connect'
import { createClient } from 'hooks/useClient'
import { toSentry } from 'utils/sentry'
import { isProduction } from '../constants/keys'

export const getCubeApi = (authToken: string) =>
  cube(authToken, {
    apiUrl: isProduction
      ? 'https://hilarious-wildebeest.gcp-us-central1.cubecloudapp.dev/cubejs-api/v1'
      : 'http://localhost:4044/cubejs-api/v1',
  })

export async function getCubeToken(): Promise<string> {
  try {
    const currentToken = sessionStorage.getItem('cubeToken')
    if (currentToken) return currentToken

    const accessToken = await getAuth().currentUser?.getIdToken()

    const client = createClient(CubeTokens)

    return await client
      .getToken({}, { headers: { Authorization: `Bearer ${accessToken}` } })
      .then(CubeTokenResponse => {
        const { token } = CubeTokenResponse
        sessionStorage.setItem('cubeToken', token)
        return token
      })
  } catch (error: any) {
    console.error(error)

    captureException(toSentry(error), {
      contexts: {
        GetToken: {},
      },
    })

    throw error
  }
}
