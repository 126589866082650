import type { SelectProps } from 'antd'
import { Select } from 'antd'
import { MIN_PROGRAM_BUDGET_AMOUNT } from 'constants/money'
import { Text, toaster } from 'evergreen-ui'
import { useAlgoliaBudgetOptions } from 'hooks'
import { toNumber, toSafeInteger } from 'lodash-es'
import { ComponentProps, Ref, useRef } from 'react'
import { UseNumericMenuProps } from 'react-instantsearch'
import { numToDollars, sortByDollarLabel } from 'utils'

type TagRender = SelectProps['tagRender']

const tagRender: TagRender = props => {
  return <Text paddingX={8}>{props.label}</Text>
}

type ExtractRefType<T> = T extends Ref<infer R> ? R : never

type SelectRef = ExtractRefType<
  NonNullable<ComponentProps<typeof Select>['ref']>
>

export function AlgoliaBudgetSelect({
  defaultSelectedBudget,
  onSelect = () => {},
  ...props
}: {
  defaultSelectedBudget?: UseNumericMenuProps['items'][0]
  onSelect?: (programBudget: number) => void
} & Omit<
  SelectProps<string | string[]>,
  | 'ref'
  | 'filterSort'
  | 'value'
  | 'onSelect'
  | 'onClear'
  | 'onDeselect'
  | 'tagRender'
  | 'options'
>) {
  const ref = useRef<SelectRef>(null)
  const {
    budgetOptions,
    activeAlgoliaBudget,
    refineBudgets,
    setBudgetOptions,
    clearBudgetRefinements,
    algoliaBudgets,
  } = useAlgoliaBudgetOptions({ defaultSelectedBudget })

  const addNewBudget = (newBudget: number) => {
    if (newBudget === 0) {
      toaster.warning('Please enter a valid number') // value was not a number, which is why toSafeInteger returned 0
    } else if (newBudget < MIN_PROGRAM_BUDGET_AMOUNT) {
      toaster.warning(
        `Budget must be greater than ${numToDollars(
          MIN_PROGRAM_BUDGET_AMOUNT,
          0
        )}`
      )
    } else {
      setBudgetOptions([
        ...budgetOptions,
        {
          label: numToDollars(newBudget, 0) ?? '',
          start: newBudget - MIN_PROGRAM_BUDGET_AMOUNT,
          end: newBudget,
        },
      ])
    }
  }

  const handleSelectOrAddBudget = (value: string) => {
    const amount = toSafeInteger(value) * 100

    // We're not sure if user simply selected an existing option or entered a new one, the AntDesign event is the same, so check both
    const existingBudget = algoliaBudgets.find(
      budget =>
        budget.value === value || budget.label === numToDollars(amount, 0)
    )

    if (!existingBudget) {
      addNewBudget(amount)
      return
    }

    refineBudgets(value)

    // Update program with user's selection
    const programBudget =
      toNumber(
        existingBudget.label.substring(existingBudget.label.indexOf('$') + 1)
      ) * 100

    onSelect(programBudget)
  }

  return (
    <Select
      size="large"
      mode="tags"
      allowClear
      maxTagCount={1}
      placeholder="Select a budget"
      style={{ width: 172 }}
      {...props}
      ref={ref}
      value={activeAlgoliaBudget?.value ? [activeAlgoliaBudget?.value] : []}
      filterSort={sortByDollarLabel}
      onSelect={value => {
        handleSelectOrAddBudget(value)
        ref.current?.blur() // Unfocus the select dropdown after selection
      }}
      onClear={clearBudgetRefinements}
      onDeselect={clearBudgetRefinements}
      tagRender={tagRender}
      options={algoliaBudgets.map(budget => ({
        label: budget.label,
        value: budget.value,
      }))}
    />
  )
}
