import { useInfiniteHits, useInstantSearch } from 'react-instantsearch'
import { useInView } from 'react-intersection-observer'
import { algoliaItemToProductVariant } from 'utils/productVariant'

export default function useInfiniteProductVariants() {
  const { items, isLastPage, showMore } = useInfiniteHits()
  const { status, results } = useInstantSearch()

  const { ref: sentinelRef } = useInView({
    onChange: inView => {
      if (inView && status === 'idle' && !isLastPage) {
        showMore()
      }
    },
  })

  // eslint-disable-next-line no-underscore-dangle
  const isEmpty = !results.__isArtificial && results.nbHits === 0

  // We know we're loading if algolia says so or if algolia promises the hits won't be empty
  const isLoadingInitial =
    items.length === 0 && (status === 'loading' || !isEmpty)

  const isLoadingMore = status === 'loading' && items.length > 0

  return {
    productVariants: items.map(algoliaItemToProductVariant),
    totalProductVariantCount: results.nbHits,
    isLoadingInitial,
    isLoadingMore,
    isEmpty,
    sentinelRef,
    status,
  }
}
