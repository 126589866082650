import { Image } from 'antd'
import { SWAG_LOGO } from 'assets/contentful'
import { allCategoriesIcon, lunch } from 'assets/icons'
import {
  allApprovedCats,
  allCategories,
  ApprovedCategories,
} from 'constants/EligibleCategories'
import { Pane, Tab, Tablist, Text } from 'evergreen-ui'
import isEqual from 'lodash-es/isEqual'

const getTabIcon = (cat: string) => {
  if (cat === allCategories) return allCategoriesIcon
  if (cat === ApprovedCategories.FOOD) return lunch
  return SWAG_LOGO
}

export function CategoryForm({
  onChangeApprovedCategories,
  selectableCats,
  defaultCat,
}: {
  onChangeApprovedCategories: (selectCats: string[]) => void
  selectableCats: string[]
  defaultCat: string
}) {
  const handleSelect = (value: string) => {
    const allCatsApproved = isEqual(value, allCategories)
    if (allCatsApproved) {
      onChangeApprovedCategories(allApprovedCats)
    } else {
      onChangeApprovedCategories([value])
    }
  }
  return (
    <Tablist>
      {selectableCats.map(cat => (
        <Tab
          flex={1}
          key={cat}
          id={`${cat}`}
          onSelect={() => handleSelect(cat)}
          isSelected={cat === defaultCat}
        >
          <Pane display="flex" gap={8} alignItems="center">
            <Image
              preview={false}
              src={getTabIcon(cat)}
              style={{ maxWidth: 16 }}
            />
            <Text>{cat}</Text>
          </Pane>
        </Tab>
      ))}
    </Tablist>
  )
}
