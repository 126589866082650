import { DEFAULT_ROUTES } from 'constants/routes'
import { NotificationSettingType_Enum } from 'gen/perkup/v1/notification_pb'
import { ActionType_Enum } from 'gen/perkup/v1/rules_pb'
import {
  NotificationOccassions,
  NotificationSendTypes,
  RuleGroups,
  RuleIds,
} from 'types/Notifications'

export function getNotificationDetailsByOccassion(
  occassion: NotificationOccassions | undefined
) {
  let ruleGroup: RuleGroups | undefined
  let ruleId: RuleIds | undefined
  let rewardName = ''
  switch (occassion) {
    case NotificationOccassions.ANNIVERSARY:
      ruleGroup = RuleGroups.ANNIVERSARY_RULE_GROUP
      ruleId = RuleIds.ANNIVERSARY_RULE_ID
      rewardName = 'work anniversary'
      break

    case NotificationOccassions.BIRTHDAY:
      ruleGroup = RuleGroups.BIRTHDAY_RULE_GROUP
      ruleId = RuleIds.BIRTHDAY_RULE_ID
      rewardName = 'birthday'
      break

    default:
      break
  }

  return { ruleGroup, ruleId, rewardName }
}

export const getNotificationOccassionByPath = (
  pathname: string
): NotificationOccassions | undefined => {
  let occassion: NotificationOccassions | undefined

  if (pathname.includes(DEFAULT_ROUTES.ORGANIZATION.REWARDS.BIRTHDAYS.ROOT)) {
    occassion = NotificationOccassions.BIRTHDAY
  } else {
    occassion = NotificationOccassions.ANNIVERSARY
  }

  return occassion
}

export const getNotificationActionType = (sendType: NotificationSendTypes) => {
  let actionType: ActionType_Enum = ActionType_Enum.ACTION_TYPE_UNSPECIFIED
  switch (sendType) {
    case NotificationSendTypes.NOTIFY_ADMINS:
      actionType = ActionType_Enum.notifyAdmins
      break

    case NotificationSendTypes.NOTIFY_MANAGER:
      actionType = ActionType_Enum.notifyManager
      break

    case NotificationSendTypes.PUBLIC_SLACK:
      actionType = ActionType_Enum.publicAnnouncement
      break

    default:
      break
  }

  return actionType
}

export const getNotificationSettingId = ({
  sendType,
  ruleGroup,
}: {
  sendType: NotificationSendTypes
  ruleGroup: RuleGroups | undefined
}): string => {
  let notificationSettingId: NotificationSettingType_Enum =
    NotificationSettingType_Enum.ACTION_TYPE_UNSPECIFIED
  if (ruleGroup === RuleGroups.BIRTHDAY_RULE_GROUP) {
    switch (sendType) {
      case NotificationSendTypes.NOTIFY_ADMINS:
        notificationSettingId =
          NotificationSettingType_Enum.notifyAdminsBirthdayEmail
        break
      case NotificationSendTypes.NOTIFY_MANAGER:
        notificationSettingId =
          NotificationSettingType_Enum.notifyManagerBirthdayEmail
        break
      case NotificationSendTypes.PUBLIC_SLACK:
        notificationSettingId = NotificationSettingType_Enum.publicSlackBirthday
        break
      case NotificationSendTypes.PRIVATE_SLACK:
        notificationSettingId =
          NotificationSettingType_Enum.privateSlackBirthdayMsg
        break

      default:
        break
    }
  } else if (ruleGroup === RuleGroups.ANNIVERSARY_RULE_GROUP) {
    switch (sendType) {
      case NotificationSendTypes.NOTIFY_ADMINS:
        notificationSettingId =
          NotificationSettingType_Enum.notifyAdminsAnniversaryEmail
        break
      case NotificationSendTypes.NOTIFY_MANAGER:
        notificationSettingId =
          NotificationSettingType_Enum.notifyManagerAnniversaryEmail
        break
      case NotificationSendTypes.PUBLIC_SLACK:
        notificationSettingId =
          NotificationSettingType_Enum.publicSlackAnniversary
        break
      case NotificationSendTypes.PRIVATE_SLACK:
        notificationSettingId =
          NotificationSettingType_Enum.privateSlackAnniversaryMsg
        break

      default:
        break
    }
  }

  return NotificationSettingType_Enum[notificationSettingId]
}

export const formatMessage = (
  message: string,
  replacements: Record<string, string>[],
  from: string,
  to: string
) => {
  let formattedMessage = message
  replacements.forEach(replacement => {
    formattedMessage = formattedMessage.replaceAll(
      replacement[from],
      replacement[to]
    )
  })
  return formattedMessage
}
