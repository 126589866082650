import FieldTimeOutlined from '@ant-design/icons/FieldTimeOutlined'
import HistoryOutlined from '@ant-design/icons/HistoryOutlined'
import { AnyMessage } from '@bufbuild/protobuf'
import { captureException } from '@sentry/react'
import { Tooltip } from 'antd'
import InputNumber from 'antd/es/input-number'
import { Pane, Spinner, Text, toaster } from 'evergreen-ui'
import { DocumentReference, setDoc } from 'firebase/firestore'
import capitalize from 'lodash-es/capitalize'
import isNaN from 'lodash-es/isNaN'
import isNull from 'lodash-es/isNull'
import isUndefined from 'lodash-es/isUndefined'
import round from 'lodash-es/round'
import { useEffect, useState } from 'react'
import { toSentry } from 'utils/sentry'

export function EditableYearCell({
  docRef,
  fieldKey,
  columnTitle,
  currentValue,
}: {
  docRef: DocumentReference<AnyMessage>
  fieldKey: string
  columnTitle: string
  currentValue: number
}) {
  const [isEditing, setIsEditing] = useState<boolean>(false)
  const [isDirty, setIsDirty] = useState<boolean>(false)
  const [tempValue, setTempValue] = useState<number>(currentValue)
  const [everyYear, setEveryYear] = useState(currentValue === 0) // If current value from firestore is 0, then render every year
  const [oldYearValue, setOldYearValue] = useState<number>(1) // Default is the min of the input component
  const [status, setStatus] = useState<'' | 'error' | 'warning' | undefined>(
    undefined
  )

  // Set useState hook default to currentValue prop.
  useEffect(() => {
    setTempValue(currentValue)
  }, [currentValue])

  // Input validation. Prompt admin with UI.
  useEffect(() => {
    if (isNaN(tempValue) || isNull(tempValue) || isUndefined(tempValue)) {
      setStatus('error')
    } else {
      setStatus(undefined)
    }
  }, [tempValue])

  // On focus change, or on press enter, try to submit to firestore.
  function handleSubmit() {
    if (isNaN(tempValue) || isNull(tempValue) || isUndefined(tempValue)) return
    // fieldKey refers to the year field on rule document
    if (isEditing) {
      setIsEditing(false)
      setDoc(
        docRef,
        {
          [fieldKey]: round(tempValue),
        },
        { merge: true }
      )
        .then(() => {
          if (isDirty) {
            toaster.success(`${capitalize(columnTitle)} updated`, {
              id: fieldKey,
            })
            setIsDirty(false)
          }
        })
        .catch((error: any) => {
          console.error(error)
          toaster.warning('Something went wrong, try again or contact support.')
          captureException(toSentry(error), {
            contexts: {
              EditableYearCell: {
                docRef,
                fieldKey,
                columnTitle,
                currentValue,
                tempValue,
              },
            },
          })
        })
    }
  }

  // If the admin chooses every year, set year to 0 and store the previous year
  const handleChooseEveryYear = () => {
    setEveryYear(true)
    setOldYearValue(tempValue)
    setDoc(
      docRef,
      {
        [fieldKey]: 0,
      },
      { merge: true }
    )
      .then(() => {
        if (isDirty) {
          toaster.success(`${capitalize(columnTitle)} updated`, {
            id: fieldKey,
          })
          setIsDirty(false)
        }
      })
      .catch((error: any) => {
        console.error(error)
        toaster.warning('Something went wrong, try again or contact support.')
        captureException(toSentry(error), {
          contexts: {
            EditableYearCell: {
              docRef,
              fieldKey,
              columnTitle,
              currentValue,
              tempValue,
            },
          },
        })
      })
  }

  // If the admin chooses tenure, set year back to previous year value
  const handleChooseTenure = () => {
    setEveryYear(false)
    setTempValue(oldYearValue)
    setDoc(
      docRef,
      {
        [fieldKey]: oldYearValue,
      },
      { merge: true }
    )
      .then(() => {
        if (isDirty) {
          toaster.success(`${capitalize(columnTitle)} updated`, {
            id: fieldKey,
          })
          setIsDirty(false)
        }
      })
      .catch((error: any) => {
        console.error(error)
        toaster.warning('Something went wrong, try again or contact support.')
        captureException(toSentry(error), {
          contexts: {
            EditableYearCell: {
              docRef,
              fieldKey,
              columnTitle,
              currentValue,
              tempValue,
            },
          },
        })
      })
  }

  // Display input component only if admin is in editing mode.
  if (isEditing) {
    return (
      <Pane display="flex">
        <InputNumber
          style={{ flexGrow: '2' }}
          min={1}
          value={tempValue}
          status={status}
          onBlur={() => handleSubmit()}
          onPressEnter={() => handleSubmit()}
          onChange={(value: number | null) => {
            if (value) {
              setIsDirty(true)
              setTempValue(value)
            }
          }}
        />
      </Pane>
    )
  }

  // Non clickable text component. Admin can only enter back into editing mode if setEveryYear = false
  if (everyYear) {
    return (
      <Pane display="flex" alignItems="center" gap={8}>
        <Pane flexGrow={2}>
          <Text>Every year</Text>
        </Pane>
        <Tooltip title="Set rule to a specific tenure?">
          <FieldTimeOutlined
            style={{ fontSize: '15px' }} // This icon appeared slighly smaller, so needed to match the size of the HistoryOutlined icon
            onClick={handleChooseTenure}
          />
        </Tooltip>
      </Pane>
    )
  }

  // Regular text component. On click, enter editing mode.
  return (
    <Pane display="flex" alignItems="center" gap={8}>
      <Pane
        className="editable-text-cell"
        onClick={() => setIsEditing(true)}
        flexGrow={2}
        cursor="pointer"
      >
        {isNaN(tempValue) ? <Spinner size={16} /> : <Text>{tempValue}</Text>}
      </Pane>
      <Tooltip title="Set rule to every year?">
        <HistoryOutlined onClick={handleChooseEveryYear} />
      </Tooltip>
    </Pane>
  )
}
