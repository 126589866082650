import { ListNonRemovedIndividualsByIds } from 'api/databaseCalls'
import { OrgContext } from 'context'
import { Individual } from 'gen/perkup/v1/individual_pb'
import { useContext, useEffect, useState } from 'react'

export function useListIndividualsByIds({
  individualIds,
}: {
  individualIds: string[]
}) {
  const { id: orgId } = useContext(OrgContext)
  const [individuals, setIndividuals] = useState<Individual[]>([])
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (individualIds.length === 0) {
      setIndividuals([])
      return
    }

    setIsLoading(true)

    ListNonRemovedIndividualsByIds({ individualIds, orgId })
      .then(setIndividuals)
      .finally(() => setIsLoading(false))
  }, [individualIds, orgId])

  return { individuals, isLoading }
}
