import { Button, Select } from 'antd'
import {
  GetCountryById,
  UpdateUserCurrency,
  completeUserOnboarding,
  updateUserCountry,
} from 'api/databaseCalls'
import { ReactComponent as Globe } from 'assets/globe.svg'
import { defaultButtonStyles } from 'constants/antdesign'
import { currencySymbols } from 'constants/currencies'
import * as ROUTES from 'constants/routes'
import { PROGRAM_ID } from 'constants/sessionOrLocalStorage'
import { CountriesContext, UserContext } from 'context'
import { findFlagUrlByIso2Code } from 'country-flags-svg'
import { Heading, Image, Pane, Text, toaster, useTheme } from 'evergreen-ui'
import { useContext, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { useNavigate } from 'react-router'
import { getCountryIsoAlpha2 } from 'utils'

export const US_NAME_STRING = 'United States'

export default function SelectCountry() {
  const navigate = useNavigate()
  const user = useContext(UserContext)
  const countries = useContext(CountriesContext)
  const theme = useTheme()
  const [countryName, setCountryName] = useState<String>(US_NAME_STRING)

  const handleSetCountry = () => {
    const countrySelected = countries.find(
      country => country.name === countryName
    )
    if (countrySelected) {
      updateUserCountry({ userId: user.id, countryId: countrySelected.id })
      GetCountryById({ countryId: countrySelected.id }).then(country => {
        if (
          country?.currency &&
          currencySymbols[country?.currency as keyof typeof currencySymbols]
        ) {
          UpdateUserCurrency({
            userId: user.id,
            displayCurrency: country.currency,
          })
        }
      })
    }
  }

  const programId = window.localStorage.getItem(PROGRAM_ID)

  const onFinishOnboardingRoute = programId
    ? ROUTES.REWARD_ACCEPTANCE.replace(':programId', programId)
    : ROUTES.CARD

  const handleFinishOnboarding = () => {
    toaster.success('Successfully created user profile')
    completeUserOnboarding({ userId: user.id })
    if (!user.countryId) {
      handleSetCountry()
    }
    navigate(onFinishOnboardingRoute)
  }

  const handleSkip = () => {
    toaster.success('Successfully created user profile')
    completeUserOnboarding({ userId: user.id })
    navigate(onFinishOnboardingRoute)
  }

  const selectableCountries = countries.map(country => {
    const label = country.name
    return (
      <Select.Option
        value={country.name}
        style={{ display: 'flex' }}
        key={country.id}
      >
        <Pane display="flex" alignItems="center" marginLeft="5px" gap={8}>
          <Image
            alt="Country flag"
            src={findFlagUrlByIso2Code(getCountryIsoAlpha2(country.iso3))}
            borderRadius="2px"
            width="30px"
          />
          <Text>{label}</Text>
        </Pane>
      </Select.Option>
    )
  })
  if (!user.profile) return null
  return (
    <>
      <Pane
        width={180}
        marginX="auto"
        marginBottom={16}
        marginTop={isMobile ? 90 : 0}
      >
        <Globe style={{ width: '100%', height: 'auto' }} />
      </Pane>
      <Pane width={320}>
        <Heading size={800} marginBottom={16} marginTop={16} textAlign="center">
          Select your country
        </Heading>
        <Heading
          textAlign="center"
          marginBottom={24}
          size={500}
          color={theme.colors.muted}
        >
          Set your location to get an experience that&apos;s most relevant to
          you.
        </Heading>
        <Select
          value={countryName}
          onSelect={countryName => {
            setCountryName(countryName)
            toaster.success(`Country set to ${countryName}`)
          }}
          style={{ width: '100%' }}
          showSearch
          className="customSelectionItem"
        >
          {selectableCountries}
        </Select>

        <Pane width={320} display="flex" flexDirection="column" marginTop={16}>
          <Button
            onClick={handleFinishOnboarding}
            type="primary"
            style={{ ...defaultButtonStyles, width: '100%' }}
            disabled={!user.id}
          >
            Continue
          </Button>
          <Button
            type="text"
            onClick={handleSkip}
            style={{ ...defaultButtonStyles, width: '100%' }}
          >
            Skip
          </Button>
        </Pane>
      </Pane>
    </>
  )
}
