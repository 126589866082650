import { captureException } from '@sentry/react'
import {
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  onSnapshot,
  orderBy,
  query,
  limit as queryLimit,
  where,
} from 'firebase/firestore'
import { Member, ScheduledMember } from 'gen/perkup/v1/program_pb'
import { compact } from 'lodash-es'
import flatten from 'lodash-es/flatten'
import map from 'lodash-es/map'
import loOrderBy from 'lodash-es/orderBy'
import uniq from 'lodash-es/uniq'
import { converter, storedId } from 'utils/firestore'
import { toSentry } from 'utils/sentry'
import { ListMembershipsByIndividualId } from './memberships'

/**
 * Member
 */
export async function GetMemberById({
  orgId,
  programId,
  memberId,
}: {
  orgId: string
  programId: string
  memberId: string
}) {
  try {
    const db = getFirestore()
    const docRef = doc(
      db,
      `organizations/${orgId}/programs/${programId}/members/${memberId}`
    ).withConverter(storedId(Member))
    return await getDoc(docRef).then(doc => doc.data())
  } catch (error) {
    console.error(error)
    captureException(toSentry(error), {
      contexts: { GetMemberById: { orgId, programId, memberId } },
      tags: { orgId },
    })
  }
}

export async function ListEnrolledMembersByProgramId({
  orgId,
  programId,
  limit,
}: {
  orgId: string
  programId: string
  limit?: number
}) {
  const members: Member[] = []
  try {
    const db = getFirestore()
    const colRef = collection(
      db,
      `organizations/${orgId}/programs/${programId}/members`
    ).withConverter(storedId(Member))
    const q = limit
      ? query(colRef, where('enrolled', '==', true), queryLimit(limit))
      : query(colRef, where('enrolled', '==', true))

    await getDocs(q).then(qSnaps =>
      qSnaps.docs.forEach(qSnap => members.push(qSnap.data()))
    )
  } catch (error) {
    console.error(error)
    captureException(toSentry(error), {
      contexts: { ListEnrolledMembersByProgramId: { orgId, programId } },
      tags: { orgId, programId },
    })
  }
  return members
}

export function ListenToMemberById({
  orgId,
  programId,
  memberId,
  cb,
}: {
  orgId: string
  programId: string
  memberId: string
  cb: (member: Member | undefined) => void
}) {
  const db = getFirestore()
  const docRef = doc(
    db,
    `organizations/${orgId}/programs/${programId}/members/${memberId}`
  ).withConverter(storedId(Member))
  return onSnapshot(
    docRef,
    doc => cb(doc.data()),
    error => {
      console.error(error)
      captureException(toSentry(error), {
        contexts: { ListenToMemberById: { orgId, programId, memberId } },
        tags: { orgId },
      })
    }
  )
}

export function ListenToEnrolledMemberUsersByProgramId({
  orgId,
  programId,
  cb,
}: {
  orgId: string
  programId: string
  cb: (members: Member[]) => void
}) {
  const db = getFirestore()
  const colRef = collection(
    db,
    `organizations/${orgId}/programs/${programId}/members`
  ).withConverter(storedId(Member))
  const q = query(colRef, where('enrolled', '==', true))
  return onSnapshot(
    q,
    querySnapshot =>
      cb(
        loOrderBy(
          querySnapshot.docs.map(doc => doc.data()),
          ['created.seconds'],
          'desc'
        )
      ),
    error => {
      console.error(error)
      captureException(toSentry(error), {
        contexts: {
          ListenToEnrolledMemberUsersByProgramId: { orgId, programId },
        },
        tags: { orgId },
      })
    }
  )
}

export async function ListAllApprovedCategoriesByOrgUser({
  orgId,
  individualId,
}: {
  orgId: string
  individualId: string
}) {
  if (!orgId || !individualId) return []

  const memberships = await ListMembershipsByIndividualId({
    orgId,
    individualId,
  })
  const membershipPrograms = compact(memberships.map(m => m?.program))
  return uniq(flatten(map(membershipPrograms, p => p.approvedCategories)))
}

export async function ListProgramCategoriesByOrgUser({
  orgId,
  userId,
  individualId,
  publishedMacroCategories,
}: {
  orgId: string
  userId: string
  individualId: string
  publishedMacroCategories: string[]
}) {
  if (!orgId || !userId || !individualId || !publishedMacroCategories.length)
    return []

  const memberships = await ListMembershipsByIndividualId({
    orgId,
    individualId,
  })
  const membershipPrograms = compact(memberships.map(m => m?.program))
  return membershipPrograms.map(p => {
    const categories = p.approvedCategories.filter(category =>
      publishedMacroCategories.includes(category)
    )
    return {
      label: p.name,
      value: categories.join(),
    }
  })
}

export function ListenToScheduledMembersByProgramId({
  orgId,
  programId,
  cb,
}: {
  orgId: string
  programId: string
  cb: Function
}) {
  if (!orgId || !programId || !cb) return undefined

  const db = getFirestore()
  const colRef = collection(
    db,
    `organizations/${orgId}/programs/${programId}/scheduledMembers`
  ).withConverter(converter(ScheduledMember))
  const q = query(colRef, orderBy('sendAt', 'asc'))
  return onSnapshot(
    q,
    query => cb(query.docs.map(doc => doc.data())),
    error => {
      console.error(error)
      captureException(toSentry(error), {
        contexts: {
          ListenToScheduledMembersByProgramId: { orgId, programId },
        },
        tags: { orgId },
      })
    }
  )
}
