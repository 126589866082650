import { ListProductCollectionsByOrgId } from 'api/databaseCalls'
import { CountryContext, OrgContext } from 'context'
import {
  ProductCollection,
  ProductCollection_CollectionAccess_Enum,
} from 'gen/perkup/v1/product_collections_pb'
import { useContext, useEffect, useState } from 'react'
import { filterProductCollectionsByCountry } from 'utils/productCollections'

export function usePublicProductCollections() {
  const { iso3 } = useContext(CountryContext)
  const { id: orgId } = useContext(OrgContext)

  const [collections, setCollections] = useState<ProductCollection[]>([])
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (!orgId) return
    setIsLoading(true)
    ListProductCollectionsByOrgId({ orgId })
      .then(collections => {
        if (!collections) return
        const publicCollections = collections.filter(collection => {
          return (
            collection.access === ProductCollection_CollectionAccess_Enum.public
          )
        })

        const collectionsFilteredByCountry = filterProductCollectionsByCountry({
          iso3,
          collections: publicCollections,
        })
        setCollections(collectionsFilteredByCountry)
      })

      .finally(() => setIsLoading(false))
  }, [orgId, iso3])

  return { collections, isLoading }
}
