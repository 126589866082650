// @generated by protoc-gen-es v1.6.0 with parameter "target=ts"
// @generated from file perkup/v1/integration.proto (package perkup.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, Timestamp } from "@bufbuild/protobuf";
import { Organization_SyncSettings } from "./organization_pb.js";

/**
 * Firestore /integrations/:id
 * Integrations for
 * - Finch
 * - WorkOS
 * - Slack (future)
 *
 * @generated from message perkup.v1.Integration
 */
export class Integration extends Message<Integration> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: google.protobuf.Timestamp created = 2;
   */
  created?: Timestamp;

  /**
   * Type of integration (finch, workos, slack)
   *
   * @generated from field: perkup.v1.Integration.Type type = 3;
   */
  type = Integration_Type.TYPE_UNSPECIFIED;

  /**
   * access_token is encrypted using Type specific encryption secret
   *
   * @generated from field: optional string access_token = 4;
   */
  accessToken?: string;

  /**
   * @generated from field: string provider = 5;
   */
  provider = "";

  /**
   * @generated from field: string org_id = 6;
   */
  orgId = "";

  /**
   * @generated from field: perkup.v1.Integration.Status status = 7;
   */
  status = Integration_Status.STATUS_UNSPECIFIED;

  /**
   * @generated from field: optional string external_status = 8;
   */
  externalStatus?: string;

  /**
   * @generated from field: optional string external_org_name = 9;
   */
  externalOrgName?: string;

  /**
   * @generated from field: optional string external_org_id = 10;
   */
  externalOrgId?: string;

  /**
   * @generated from field: optional perkup.v1.Organization.SyncSettings finch_sync_settings = 11;
   */
  finchSyncSettings?: Organization_SyncSettings;

  /**
   * Flag indicating there are individuals that need to be synced
   *
   * @generated from field: optional bool needs_processing = 12;
   */
  needsProcessing?: boolean;

  /**
   * List of individuals that need to be synced
   *
   * @generated from field: repeated string individual_ids = 13;
   */
  individualIds: string[] = [];

  /**
   * Last time the integration was synced
   *
   * @generated from field: optional google.protobuf.Timestamp last_synced = 14;
   */
  lastSynced?: Timestamp;

  constructor(data?: PartialMessage<Integration>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "perkup.v1.Integration";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "created", kind: "message", T: Timestamp },
    { no: 3, name: "type", kind: "enum", T: proto3.getEnumType(Integration_Type) },
    { no: 4, name: "access_token", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 5, name: "provider", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "org_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "status", kind: "enum", T: proto3.getEnumType(Integration_Status) },
    { no: 8, name: "external_status", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 9, name: "external_org_name", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 10, name: "external_org_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 11, name: "finch_sync_settings", kind: "message", T: Organization_SyncSettings, opt: true },
    { no: 12, name: "needs_processing", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 13, name: "individual_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 14, name: "last_synced", kind: "message", T: Timestamp, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Integration {
    return new Integration().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Integration {
    return new Integration().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Integration {
    return new Integration().fromJsonString(jsonString, options);
  }

  static equals(a: Integration | PlainMessage<Integration> | undefined, b: Integration | PlainMessage<Integration> | undefined): boolean {
    return proto3.util.equals(Integration, a, b);
  }
}

/**
 * @generated from enum perkup.v1.Integration.Status
 */
export enum Integration_Status {
  /**
   * @generated from enum value: STATUS_UNSPECIFIED = 0;
   */
  STATUS_UNSPECIFIED = 0,

  /**
   * @generated from enum value: active = 1;
   */
  active = 1,

  /**
   * @generated from enum value: disconnected = 2;
   */
  disconnected = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(Integration_Status)
proto3.util.setEnumType(Integration_Status, "perkup.v1.Integration.Status", [
  { no: 0, name: "STATUS_UNSPECIFIED" },
  { no: 1, name: "active" },
  { no: 2, name: "disconnected" },
]);

/**
 * @generated from enum perkup.v1.Integration.Type
 */
export enum Integration_Type {
  /**
   * @generated from enum value: TYPE_UNSPECIFIED = 0;
   */
  TYPE_UNSPECIFIED = 0,

  /**
   * @generated from enum value: finch = 1;
   */
  finch = 1,

  /**
   * @generated from enum value: workos = 2;
   */
  workos = 2,

  /**
   * @generated from enum value: slack = 3;
   */
  slack = 3,
}
// Retrieve enum metadata with: proto3.getEnumType(Integration_Type)
proto3.util.setEnumType(Integration_Type, "perkup.v1.Integration.Type", [
  { no: 0, name: "TYPE_UNSPECIFIED" },
  { no: 1, name: "finch" },
  { no: 2, name: "workos" },
  { no: 3, name: "slack" },
]);

