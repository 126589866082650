export const MARKETING_HOSTNAME = 'perkupapp.com'
export const PROD_HOSTNAME = 'my.perkupapp.com'
export const HTTPS_PROD_HOSTNAME = `https://${PROD_HOSTNAME}`
export const LOCALHOST = 'localhost'
export const TUNNELED_LOCALHOST = process.env.PERKUP_HOST || 'perkup.ngrok.io'
export const LOCAL_HOST_URL = 'http://localhost:3000'

// Do not put / characters at the end of these strings. If you do, then Line 97 of src/App.js will fail with a cors error.
export const PROD_FUNCTIONS_ROOT =
  'https://us-central1-perkup-app.cloudfunctions.net'
export const DEV_FUNCTIONS_ROOT = 'http://localhost:5001/perkup-app/us-central1'

export const PROD_RUN_ROOT = 'https://amazon-punchout-y52g2palyq-uc.a.run.app'
export const DEV_RUN_ROOT = 'http://localhost:6001'

// Slack Testing
export const SLACK_TUNNEL_HOST = 'https://perkup-slack-redirect.ngrok.io'
