import { captureMessage } from '@sentry/react'
import { StripeAddressElementChangeEvent } from '@stripe/stripe-js'
import { Button } from 'antd'
import { AddressForm } from 'components'
import * as ROUTES from 'constants/routes'
import {
  IndividualContext,
  OrgContext,
  OrgUserContext,
  UserContext,
} from 'context'
import {
  Checkbox,
  Dialog,
  Heading,
  Link,
  Pane,
  Text,
  toaster,
} from 'evergreen-ui'
import { Individual_Status } from 'gen/perkup/v1/individual_pb'
import useCustomer from 'hooks/useCustomer'
import { useContext, useEffect, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { useNavigate } from 'react-router-dom'
import { handleCreateCard } from 'services/stripeIssuing'
import Stripe from 'stripe'

const STRIPE_TOS_URL =
  'https://stripe.com/en-ca/legal/issuing/celtic-authorized-user-terms'

export function CardSetupDialog() {
  const [isShown, setIsShown] = useState(true)
  const user = useContext(UserContext)
  const org = useContext(OrgContext)
  const orgUser = useContext(OrgUserContext)
  const individaul = useContext(IndividualContext)

  const navigate = useNavigate()
  const { customer } = useCustomer()
  const companyAddress = customer?.shipping?.address

  const [isLoading, setIsLoading] = useState(false)
  const [useCompAddress, setUseCompAddress] = useState(false)
  const [formAddress, setFormAddress] = useState<Stripe.Address>()
  const [isTOSSigned, setIsTOSSigned] = useState<boolean>(false)

  useEffect(() => {
    if (companyAddress) setUseCompAddress(true)
  }, [companyAddress])

  const isConfirmDisabled = () => {
    if (useCompAddress) return false
    if (!formAddress) return true
    if (!isTOSSigned) return true
    return false
  }
  const disableConfirm = isConfirmDisabled()
  const formHeading = useCompAddress ? 'Company address' : 'Your home address'

  const handleAddressChange = (event: StripeAddressElementChangeEvent) => {
    if (event.complete) setFormAddress(event.value.address)
  }

  const handleSubmitAddress = async () => {
    const address = useCompAddress ? companyAddress : formAddress

    const isRemovedIndividual = individaul.status === Individual_Status.removed
    const isBlockedIndividual = individaul.status === Individual_Status.blocked

    if (isRemovedIndividual) {
      toaster.warning('You have been removed from the organization')
      return
    }

    if (isBlockedIndividual) {
      toaster.warning('You are blocked within from the organization')
      return
    }

    if (!address) {
      captureMessage('Missing address for card creation', 'error')
      toaster.warning('Missing address')
      return
    }

    setIsLoading(true)
    handleCreateCard({
      address,
      user,
      cardholderId: user?.cardholderId,
      cardId: orgUser?.cardId,
      userId: user?.id,
      orgId: org?.id,
    })
      .then(() => {
        navigate(ROUTES.CARD_ACTIVATED, { state: { activated: true } })
      })
      .finally(() => setIsLoading(false))
  }

  return (
    <Dialog
      hasHeader={false}
      isShown={isShown}
      onCloseComplete={() => {
        setIsShown(false)
        setFormAddress(undefined)
        navigate(ROUTES.CARD)
      }}
      contentContainerProps={{
        padding: 24,
        height: useCompAddress ? undefined : '60vh',
      }}
      // We do this in other components because Evergreen Dialogs need the footer to be a function
      // eslint-disable-next-line react/no-unstable-nested-components
      footer={({ close }) => (
        <Pane
          display="flex"
          justifyContent="space-between"
          width="100%"
          alignItems="center"
          flexWrap={isMobile ? 'wrap' : 'nowrap'}
        >
          <Checkbox
            label={
              <Pane
                display="flex"
                flexWrap="nowrap"
                width="max-content"
                gap={4}
              >
                <Text>I accept the</Text>
                <Link href={STRIPE_TOS_URL} target="_blank" rel="noreferrer">
                  Terms of Service
                </Link>
              </Pane>
            }
            checked={isTOSSigned}
            onChange={e => setIsTOSSigned(e.target.checked)}
            marginY={8}
            marginRight={isMobile ? 0 : 104}
            display="flex"
            alignItems="center"
          />
          <Pane
            display="flex"
            flexWrap={isMobile ? 'wrap' : 'nowrap'}
            flexFlow={isMobile ? 'column-reverse' : ''}
          >
            <Button
              style={{
                marginRight: '8px',
                width: isMobile ? '100%' : '',
                marginBottom: isMobile ? '8px' : '',
              }}
              onClick={close}
            >
              Cancel
            </Button>
            <Button
              onClick={handleSubmitAddress}
              type="primary"
              disabled={disableConfirm}
              loading={isLoading}
              style={{
                marginRight: '8px',
                width: isMobile ? '100%' : '',
                marginBottom: isMobile ? '8px' : '',
              }}
            >
              Confirm
            </Button>
          </Pane>
        </Pane>
      )}
    >
      <Pane>
        <Heading size={600} marginBottom={24}>
          Activate your Perk Card
        </Heading>

        {companyAddress && (
          <Checkbox
            label="Use company address"
            checked={useCompAddress}
            onChange={e => setUseCompAddress(e.target.checked)}
            marginY={8}
          />
        )}

        <Pane marginTop={16} marginBottom={8}>
          <Heading size={400} marginBottom={4} marginTop={8}>
            {formHeading}
          </Heading>

          <Text size={300} color="muted">
            Only used for the purpose of providing a billing address when making
            online purchases.
          </Text>
        </Pane>
        {!useCompAddress && (
          <Pane>
            <AddressForm
              mode="billing"
              allowedCountries={['US', 'CA']}
              hidePhoneNumber
              onAddressChange={handleAddressChange}
            />
          </Pane>
        )}
      </Pane>
    </Dialog>
  )
}
