import { captureException, getCurrentHub, Span } from '@sentry/react'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { toSentry } from 'utils/sentry'

// callFunction add Sentry span if possible and captures Errors if they occur.
export async function callFunction(
  functionName: string,
  data: any
): Promise<any> {
  if (!functionName || !data) return null

  let span: Span

  const transaction = getCurrentHub()?.getScope()?.getTransaction()
  if (transaction) {
    span = transaction.startChild({
      op: functionName,
    })
    const traceparent = span.toTraceparent()
    if (traceparent) {
      // sneak the trace id onto the callable payload
      // eslint-disable-next-line no-underscore-dangle, no-param-reassign
      data._sentrytrace = traceparent
    }
  }

  const functionCall = httpsCallable(getFunctions(), functionName)
  return functionCall(data)
    .then(res => res.data)
    .catch(err => {
      console.error(`${functionName} error:`, err)
      const sentryError = toSentry(err)
      if (err?.message === 'internal') {
        // Group internal errors together
        sentryError.name = 'FirebaseError: internal'
        sentryError.message = `${functionName} error: ${sentryError.name}`
      } else if (sentryError?.name) {
        // Make callFunction errors more readable
        sentryError.name = `${functionName} error: ${sentryError.name}`
      }
      captureException(sentryError, {
        contexts: {
          [functionName]: {
            ...data,
            sentryErrorName: sentryError.name,
            errName: err?.name,
          },
        },
      })
      return err
    })
    .finally(() => {
      span?.finish()
    })
}

export async function SendEmail(data: {
  toAddress: string
  fromName: string
  fromEmail: string
  orgName: string
  templateId?: string
}) {
  if (!data) return null
  try {
    const sendInvite = httpsCallable(getFunctions(), 'sendgrid-SendEmail')
    const response = await sendInvite(data)
    return response
  } catch (error) {
    console.error(error)
    captureException(toSentry(error), {
      contexts: {
        SendEmail: data,
      },
    })
    return error
  }
}
