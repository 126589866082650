const rewardWithoutMoneyEmail = `<!doctype html>
<html xmlns="http://www.w3.org/1999/xhtml" xmlns:v="urn:schemas-microsoft-com:vml"
    xmlns:o="urn:schemas-microsoft-com:office:office">

<head>
    <title>PerkUp Rewards</title>
    <!--[if !mso]><!-->
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <!--<![endif]-->
    <meta http-equiv="Content-Type" content="text/html; charset=UTF-8">
    <meta name="viewport" content="width=device-width,initial-scale=1">
    <style type="text/css">
        #outlook a {
            padding: 0;
        }

        body {
            margin: 0;
            padding: 0;
            -webkit-text-size-adjust: 100%;
            -ms-text-size-adjust: 100%;
        }

        table,
        td {
            border-collapse: collapse;
            mso-table-lspace: 0pt;
            mso-table-rspace: 0pt;
        }

        img {
            border: 0;
            height: auto;
            line-height: 100%;
            outline: none;
            text-decoration: none;
            -ms-interpolation-mode: bicubic;
        }

        p {
            display: block;
            margin: 13px 0;
        }
    </style>
    <!--[if mso]>
    <noscript>
    <xml>
    <o:OfficeDocumentSettings>
    <o:AllowPNG/>
    <o:PixelsPerInch>96</o:PixelsPerInch>
    </o:OfficeDocumentSettings>
    </xml>
    </noscript>
    <![endif]-->
    <!--[if lte mso 11]>
    <style type="text/css">
    .mj-outlook-group-fix { width:100% !important; }
    </style>
    <![endif]-->
    <style type="text/css">
        @media only screen and (min-width:480px) {
            .mj-column-per-100 {
                width: 100% !important;
                max-width: 100%;
            }

            .mj-column-px-536 {
                width: 536px !important;
                max-width: 536px;
            }
        }
    </style>
    <style media="screen and (min-width:480px)">
        .moz-text-html .mj-column-per-100 {
            width: 100% !important;
            max-width: 100%;
        }

        .moz-text-html .mj-column-px-536 {
            width: 536px !important;
            max-width: 536px;
        }
    </style>
    <style type="text/css">
        @media only screen and (max-width:480px) {
            table.mj-full-width-mobile {
                width: 100% !important;
            }

            td.mj-full-width-mobile {
                width: auto !important;
            }
        }
    </style>
    <style type="text/css">
        #MessageViewBody a {
            color: inherit;
            text-decoration: none;
            font-size: inherit;
            font-family: inherit;
            font-weight: inherit;
            line-height: inherit;
        }

        .mobilecontent {
            display: none;
            max-height: 0px;
            overflow: hidden;
        }

        @media only screen and (max-width:480px) {
            .padL20 {
                padding-left: 20px !important;
            }

            .padL30 {
                padding-left: 30px !important;
            }

            .padL40 {
                padding-left: 40px !important;
            }

            .padR20 {
                padding-right: 20px !important;
            }

            .padR30 {
                padding-right: 30px !important;
            }

            .padR40 {
                padding-right: 40px !important;
            }

            .w100pct {
                width: 100% !important;
            }

            .w100t>table {
                width: 100% !important;
            }

            .desktop {
                display: none !important;
            }

            .mobilecontent {
                display: block !important;
                max-height: none !important;
            }

            .text-center>div {
                text-align: center !important;
                margin: 0 auto !important;
            }

            .centerOnMobile>table {
                text-align: center !important;
                margin: 0 auto !important;
            }

            .full {
                display: block !important;
                width: 100% !important;
            }

            .fs16>div {
                font-size: 16px !important;
                line-height: 20px !important;
            }

            .fs14>div {
                font-size: 14px !important;
                line-height: 16px !important;
            }

            .fs12>div {
                font-size: 12px !important;
                line-height: 14px !important;
            }

            .fs28>div {
                font-size: 28px !important;
                line-height: 34px !important;
            }
        }
    </style>
    <!--[if !mso]><!-->
    <style type="text/css">
        @font-face {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 300;
            src: url(https://fonts.gstatic.com/s/inter/v7/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1ZL7.woff2) format('woff2');
        }

        @font-face {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            src: url(https://fonts.gstatic.com/s/inter/v7/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuLyfAZ9hiA.woff2) format('woff2');
        }

        @font-face {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 700;
            src: url(https://fonts.gstatic.com/s/poppins/v15/pxiByp8kv8JHgFVrLCz7Z1xlFQ.woff2) format('woff2');
        }
    </style>
    <!--<![endif]-->
</head>

<body style="word-spacing:normal;background-color:#FFFFFF;">
    <div
        style="display:none;font-size:1px;color:#ffffff;line-height:1px;max-height:0px;max-width:0px;opacity:0;overflow:hidden;">
        {{var:preview}}</div>
    <div style="background-color:#FFFFFF;">
        <table width="600" cellpadding="0" cellspacing="0" border="0" align="center" class="w100pct"
            style="width: 600px; min-width: 390px;" role="presentation">
            <tr>
                <td align="center"
                    style="-moz-border-top-right-radius: 10px; -webkit-border-top-right-radius: 10px; border-top-right-radius: 10px; -moz-border-top-left-radius: 10px; -webkit-border-top-left-radius: 10px; border-top-left-radius: 10px; border-collapse: separate !important;overflow:hidden">
                    <!--[if mso | IE]><table align="center" border="0" cellpadding="0" cellspacing="0" class="" role="presentation" style="width:600px;" width="600" ><tr><td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;"><![endif]-->
                    <div style="margin:0px auto;max-width:600px;">
                        <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation"
                            style="width:100%;">
                            <tbody>
                                <tr>
                                    <td style="direction:ltr;font-size:0px;padding:0;text-align:center;">
                                        <!--[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr><td class="" style="vertical-align:top;width:600px;" ><![endif]-->
                                        <div class="mj-column-per-100 mj-outlook-group-fix"
                                            style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;">
                                            <table border="0" cellpadding="0" cellspacing="0" role="presentation"
                                                style="background-color:{{var:color}};vertical-align:top;" width="100%">
                                                <tbody>
                                                    <tr>
                                                        <td style="font-size:0px;word-break:break-word;">
                                                            <div style="height:10px;line-height:10px;">&#8202;</div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <!--[if mso | IE]></td></tr></table><![endif]-->
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <!--[if mso | IE]></td></tr></table><table align="center" border="0" cellpadding="0" cellspacing="0" class="" role="presentation" style="width:600px;" width="600" ><tr><td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;"><![endif]-->
                    <div style="margin:0px auto;max-width:600px;">
                        <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation"
                            style="width:100%;">
                            <tbody>
                                <tr>
                                    <td
                                        style="direction:ltr;font-size:0px;padding:22px 0px 22px 0px;text-align:center;">
                                        <!--[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr><td class="" style="vertical-align:top;width:600px;" ><![endif]-->
                                        <div class="mj-column-per-100 mj-outlook-group-fix"
                                            style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;">
                                            <table border="0" cellpadding="0" cellspacing="0" role="presentation"
                                                style="vertical-align:top;" width="100%">
                                                <tbody>
                                                    <tr>
                                                        <td align="center"
                                                            style="font-size:0px;padding:0;word-break:break-word;">
                                                            <table border="0" cellpadding="0" cellspacing="0"
                                                                role="presentation"
                                                                style="border-collapse:collapse;border-spacing:0px;">
                                                                <tbody>
                                                                    <tr>
                                                                        <td style="width:120px;"><a
                                                                                href="https://www.perkupapp.com/"
                                                                                target="_blank"><img height="auto"
                                                                                    src="{{ var:logo }}"
                                                                                    style="border:0;display:block;outline:none;text-decoration:none;height:auto;width:100%;font-size:13px;"
                                                                                    width="120"></a></td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <!--[if mso | IE]></td></tr></table><![endif]-->
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <!--[if mso | IE]></td></tr></table><table align="center" border="0" cellpadding="0" cellspacing="0" class="" role="presentation" style="width:600px;" width="600" bgcolor="#F5F5F5" ><tr><td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;"><![endif]-->
                    <div style="background:#F5F5F5;background-color:#F5F5F5;margin:0px auto;max-width:600px;">
                        <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation"
                            style="background:#F5F5F5;background-color:#F5F5F5;width:100%;">
                            <tbody>
                                <tr>
                                    <td style="direction:ltr;font-size:0px;padding:0;text-align:center;">
                                        <!--[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr><td class="" width="600px" ><table align="center" border="0" cellpadding="0" cellspacing="0" class="" role="presentation" style="width:600px;" width="600" ><tr><td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;"><![endif]-->
                                        <div style="margin:0px auto;max-width:600px;">
                                            <table align="center" border="0" cellpadding="0" cellspacing="0"
                                                role="presentation" style="width:100%;">
                                                <tbody>
                                                    <tr>
                                                        <td
                                                            style="direction:ltr;font-size:0px;padding:0;padding-bottom:30px;text-align:center;">
                                                            <!--[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr><td class="" style="vertical-align:top;width:600px;" ><![endif]-->
                                                            <div class="mj-column-per-100 mj-outlook-group-fix"
                                                                style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;">
                                                                <table border="0" cellpadding="0" cellspacing="0"
                                                                    role="presentation" style="vertical-align:top;"
                                                                    width="100%">
                                                                    <tbody>
                                                                        <tr>
                                                                            <td align="center"
                                                                                style="font-size:0px;padding:0;padding-bottom:0px;word-break:break-word;">
                                                                                <table border="0" cellpadding="0"
                                                                                    cellspacing="0" role="presentation"
                                                                                    style="border-collapse:collapse;border-spacing:0px;">
                                                                                    <tbody>
                                                                                        <tr>
                                                                                            <td style="width:600px;">
                                                                                                <img alt="Image"
                                                                                                    height="auto"
                                                                                                    src="{{ var:banner }}"
                                                                                                    style="border:0;display:block;outline:none;text-decoration:none;height:auto;width:100%;font-size:13px;"
                                                                                                    width="600">
                                                                                            </td>
                                                                                        </tr>
                                                                                    </tbody>
                                                                                </table>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                            <!--[if mso | IE]></td></tr></table><![endif]-->
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <!--[if mso | IE]></td></tr></table></td></tr><tr><td class="w536-outlook w100t-outlook padL20-outlook padR20-outlook" width="600px" ><table align="center" border="0" cellpadding="0" cellspacing="0" class="w536-outlook w100t-outlook padL20-outlook padR20-outlook" role="presentation" style="width:600px;" width="600" ><tr><td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;"><![endif]-->
                                        <div class="w536 w100t padL20 padR20"
                                            style="margin:0px auto;border-radius:8px;max-width:600px;">
                                            <table align="center" border="0" cellpadding="0" cellspacing="0"
                                                role="presentation" style="border-radius: 8px; width: 536px;"
                                                width="536">
                                                <tbody>
                                                    <tr>
                                                        <td
                                                            style="direction:ltr;font-size:0px;padding:0;padding-bottom:24px;text-align:center;">
                                                            <!--[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr><td class="center-outlook box-shadow-outlook" style="vertical-align:middle;width:536px;" ><![endif]-->
                                                            <div class="mj-column-px-536 mj-outlook-group-fix center box-shadow"
                                                                style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:middle;width:100%;">
                                                                <table border="0" cellpadding="0" cellspacing="0"
                                                                    role="presentation" width="100%">
                                                                    <tbody>
                                                                        <tr>
                                                                            <td style="box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.05); background-color: #FFFFFF; border-radius: 8px; vertical-align: middle; padding-top: 30px;"
                                                                                bgcolor="#FFFFFF" valign="middle">
                                                                                <table border="0" cellpadding="0"
                                                                                    cellspacing="0" role="presentation"
                                                                                    style="text-align: center;"
                                                                                    width="100%" align="center">
                                                                                    <tbody>
                                                                                        <tr>
                                                                                            <td align="center"
                                                                                                class="fs28"
                                                                                                style="font-size:0px;padding:0 30px 40px 30px;word-break:break-word;">
                                                                                                <div
                                                                                                    style="font-family:Poppins, Helvetica, arial, sans-serif;font-size:36px;font-weight:700;line-height:54px;text-align:center;color:#002540;">
                                                                                                    {{var:title}}</div>
                                                                                            </td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td align="center"
                                                                                                class="fs16"
                                                                                                style="font-size:0px;padding:0 30px 12px 30px;word-break:break-word;">
                                                                                                <div
                                                                                                    style="font-family:Inter, Helvetica, arial, sans-serif;font-size:18px;font-weight:400;line-height:22px;text-align:center;color:#425A70;max-width:fit-content;">
                                                                                                    {{{var:programNote}}}
                                                                                                </div>
                                                                                            </td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td align="center"
                                                                                                class="fs14"
                                                                                                style="font-size:0px;padding:0 30px 40px 30px;word-break:break-word;">
                                                                                                <div
                                                                                                    style="font-family:Inter, Helvetica, arial, sans-serif;font-size:16px;font-style:italic;font-weight:300;line-height:19px;text-align:center;color:#425A70;">
                                                                                                    — {{var:fromName}}
                                                                                                </div>
                                                                                            </td>
                                                                                        </tr>
                                                                                       
                                                                                    </tbody>
                                                                                </table>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                            <!--[if mso | IE]></td></tr></table><![endif]-->
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <!--[if mso | IE]></td></tr></table></td></tr><tr><td class="" width="600px" ><table align="center" border="0" cellpadding="0" cellspacing="0" class="" role="presentation" style="width:600px;" width="600" ><tr><td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;"><![endif]-->
                                        <div style="margin:0px auto;max-width:600px;">
                                            <table align="center" border="0" cellpadding="0" cellspacing="0"
                                                role="presentation" style="width:100%;">
                                                <tbody>
                                                    <tr>
                                                        <td
                                                            style="direction:ltr;font-size:0px;padding:0;padding-bottom:30px;text-align:center;">
                                                            <!--[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr><td class="" style="vertical-align:top;width:600px;" ><![endif]-->
                                                            <div class="mj-column-per-100 mj-outlook-group-fix"
                                                                style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;">
                                                                <table border="0" cellpadding="0" cellspacing="0"
                                                                    role="presentation" style="vertical-align:top;"
                                                                    width="100%">
                                                                    <tbody>
                                                                        <tr>
                                                                            <td align="center"
                                                                                style="font-size:0px;padding:0;padding-bottom:16px;word-break:break-word;">
                                                                                <table border="0" cellpadding="0"
                                                                                    cellspacing="0" role="presentation"
                                                                                    style="border-collapse:collapse;border-spacing:0px;">
                                                                                    <tbody>
                                                                                        <tr>
                                                                                            <td style="width:24px;"><img
                                                                                                    alt="Image"
                                                                                                    height="auto"
                                                                                                    src="https://images.scalero.io/email_assets/1908/INY3YL5RTGR3TI4GFI9YC7YFXUEHL4NF3ZA3N86U8KUCSQDWEBH9D7RSSE72Z7NB.png"
                                                                                                    style="border:0;display:block;outline:none;text-decoration:none;height:auto;width:100%;font-size:13px;"
                                                                                                    width="24"></td>
                                                                                        </tr>
                                                                                    </tbody>
                                                                                </table>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td align="center"
                                                                                style="font-size:0px;padding:0;padding-bottom:18px;word-break:break-word;">
                                                                                <div
                                                                                    style="font-family:Inter, Helvetica, arial, sans-serif;font-size:12px;font-weight:400;line-height:20px;text-align:center;color:#425A70;">
                                                                                    Contact us at <a
                                                                                        href="mailto:help@perkupapp.com"
                                                                                        target="_blank"
                                                                                        style="color: #425A70;text-decoration: underline;">help@perkupapp.com</a><br><br>PerkUp,
                                                                                    Inc. | 2261 Market Street
                                                                                    #4456<br>San Francisco, CA 94114
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td align="center"
                                                                                style="font-size:0px;padding:0;word-break:break-word;">
                                                                                <table border="0" cellpadding="0"
                                                                                    cellspacing="0" role="presentation"
                                                                                    style="border-collapse:collapse;border-spacing:0px;">
                                                                                    <tbody>
                                                                                        <tr>
                                                                                            <td style="width:20px;"><a
                                                                                                    href="https://www.linkedin.com/company/perkup/"
                                                                                                    target="_blank"><img
                                                                                                        alt="Image"
                                                                                                        height="auto"
                                                                                                        src="https://images.scalero.io/email_assets/1908/E1G7JY4CTY6RSKMK4HIHFHVEVKM27H3ISCSDVJKAPLQQCHCA439FTALRDK9G7SUK.png"
                                                                                                        style="border:0;display:block;outline:none;text-decoration:none;height:auto;width:100%;font-size:13px;"
                                                                                                        width="20"></a>
                                                                                            </td>
                                                                                        </tr>
                                                                                    </tbody>
                                                                                </table>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                            <!--[if mso | IE]></td></tr></table><![endif]-->
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <!--[if mso | IE]></td></tr></table></td></tr></table><![endif]-->
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <!--[if mso | IE]></td></tr></table><![endif]-->
                </td>
            </tr>
        </table>
    </div>
</body>

</html>`
export default rewardWithoutMoneyEmail
