import {
  ProductCollection_CollectionAccess_Enum,
  ProductCollection_Permission,
} from 'gen/perkup/v1/product_collections_pb'
import { isProduction } from './keys'

export const ALL_ORGS = 'all'
export const FEATURED_COLLECTION_ID = 'featured'

const LOCAL_OCCASION_COLLECTION_ID_TO_NAME: Record<string, string> = {
  [FEATURED_COLLECTION_ID]: '⭐ Popular',
  '472710709529': '🌎 Global Products',
}

const OCCASION_PRODUCTION_COLLECTION_ID_TO_NAME: Record<string, string> = {
  [FEATURED_COLLECTION_ID]: '⭐ Popular',
  birthday: '🎂 Birthday',
  'work-anniversary': '🎉 Work Anniversary',
  'snacks-treats': '🍬 Snacks & Treats',
  'health-wellness': '🌿 Health & Wellness',
  'gift-boxes': '🎁 Gift Baskets',
  'new-hire': '👔 New Hire',
  'new-baby': '🍼 New Baby',
  'pet-gifts': '🐶 Pet Gifts',
  wedding: '💍 Wedding',
  bereavement: '🌹 Bereavement',
}

export const OCCASION_COLLECTION_IDS = isProduction
  ? Object.keys(OCCASION_PRODUCTION_COLLECTION_ID_TO_NAME)
  : Object.keys(LOCAL_OCCASION_COLLECTION_ID_TO_NAME)

export const OCCASION_COLLECTION_ID_TO_NAME = isProduction
  ? OCCASION_PRODUCTION_COLLECTION_ID_TO_NAME
  : LOCAL_OCCASION_COLLECTION_ID_TO_NAME

export const collectionAccessOptions = [
  { label: 'Public', value: ProductCollection_CollectionAccess_Enum.public },
  {
    label: 'Private',
    value: ProductCollection_CollectionAccess_Enum.private,
  },
]

const productCollectionPermissionOptions = [
  {
    value: ProductCollection_Permission.full,
    name: 'Full access',
    description:
      'Can set permissions, edit details, add or remove product, and send',
  },
  {
    value: ProductCollection_Permission.send,
    name: 'Can send',
    description: 'Can send collection',
  },
  {
    value: ProductCollection_Permission.view,
    name: 'Can view',
    description: 'Can view collection details',
  },
]

export const adminProductCollectionPermissionsOptions = [
  ...productCollectionPermissionOptions,
]

export const managerProductCollectionPermissionsOptions = [
  ...productCollectionPermissionOptions,
  {
    value: ProductCollection_Permission.PERMISSION_UNSPECIFIED,
    name: 'No access',
    description: '',
  },
]

export const individualProductCollectionPermissionsOptions = [
  ...productCollectionPermissionOptions,
  {
    value: ProductCollection_Permission.PERMISSION_UNSPECIFIED,
    name: 'Remove',
    description: '',
  },
]

export const productCollectionPermissionToLabel: Record<
  ProductCollection_Permission,
  string
> = {
  [ProductCollection_Permission.full]: 'Full access',
  [ProductCollection_Permission.send]: 'Can send',
  [ProductCollection_Permission.view]: 'Can view',
  [ProductCollection_Permission.PERMISSION_UNSPECIFIED]: 'No access',
}
