import { FundOutlined } from '@ant-design/icons'
import { Pane, Text } from 'evergreen-ui'
import { useDefaultOrgColors } from 'hooks'

export function NoData() {
  const { defaultColor } = useDefaultOrgColors()

  return (
    <Pane display="flex" gap={16} alignItems="center">
      <FundOutlined style={{ color: defaultColor, fontSize: '24px' }} />
      <Text>No data yet</Text>
    </Pane>
  )
}
