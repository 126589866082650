import { Button, Flex } from 'antd'
import { TrackingList } from 'components'
import * as ROUTES from 'constants/routes'
import { Heading, Pane, Text } from 'evergreen-ui'
import { OrgTransaction } from 'gen/perkup/v1/organization_pb'
import { useOrderFulfillmentsByOrgTransaction } from 'hooks/orders/useOrderFulfillmentsByOrgTransaction'
import { useTransactionsByUserId } from 'hooks/useTransactionsByUserId'
import { isEmpty } from 'lodash-es'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { transactionAmount } from 'utils'
import { getOrderTrackingNumbers } from 'utils/orders'
import { TransactionStatus } from './TransactionStatus'

function RecentTransaction({ transaction }: { transaction: OrgTransaction }) {
  const navigate = useNavigate()
  const [isHovered, setIsHovered] = useState(false)

  const { orderFulfillments } = useOrderFulfillmentsByOrgTransaction({
    transaction,
  })

  const { isGift } = transaction
  const amount = isGift ? '—' : transactionAmount(transaction.amount)

  const trackingNumbersAndUrls = getOrderTrackingNumbers({
    orderFulfillments,
    orgTransactions: [transaction],
  })

  return (
    <Pane
      display="flex"
      flexDirection="column"
      gap={4}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      background={isHovered ? 'tint2' : undefined}
      borderRadius={8}
      padding={8}
      cursor="pointer"
      onClick={() => navigate(`${ROUTES.TRANSACTIONS}/${transaction.id}`)}
    >
      <Flex gap={8}>
        <Text maxWidth={120} className="one-line-truncate">
          {transaction.description}
        </Text>

        <Flex flex={1}>
          <TransactionStatus
            type={transaction.type}
            approved={transaction.approved}
            status={transaction.status}
          />
        </Flex>
        <Text textAlign="end">{amount}</Text>
      </Flex>

      {!isEmpty(trackingNumbersAndUrls) && (
        <TrackingList trackingNumbers={trackingNumbersAndUrls} />
      )}
    </Pane>
  )
}

export function RecentTransactions() {
  const navigate = useNavigate()

  // transactions is set to a list of authorizations
  const { transactions } = useTransactionsByUserId()

  if (!transactions.length) return null

  return (
    <Flex vertical gap={8} style={{ width: '100%' }}>
      <Heading marginRight={16} size={100}>
        Recent transactions
      </Heading>

      {transactions.map((transaction, index) => {
        if (index > 2) return null
        return (
          <RecentTransaction key={transaction.id} transaction={transaction} />
        )
      })}

      <Button
        style={{ width: '100%' }}
        onClick={() => navigate(ROUTES.TRANSACTIONS)}
        type="default"
        size="small"
      >
        View all
      </Button>
    </Flex>
  )
}
