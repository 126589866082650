import { ListVendorProductPricesByProductId } from 'api/databaseCalls'
import { MemberContext, ProgramContext } from 'context'
import { VendorProductPrice } from 'gen/perkup/v1/vendor_pb'
import { useContext, useEffect, useState } from 'react'
import { useProductType } from 'utils/rewardAcceptance'

export function useMemberVendorProductPrices() {
  const member = useContext(MemberContext)
  const program = useContext(ProgramContext)

  const { isVendorGift } = useProductType()
  const [vendorProductPrices, setVendorProductPrices] = useState<
    VendorProductPrice[]
  >([])

  const productId = member?.gift?.giftId || program.gift?.id
  useEffect(() => {
    if (isVendorGift && productId) {
      ListVendorProductPricesByProductId({
        productId,
      }).then(setVendorProductPrices)
    }
  }, [productId, isVendorGift])

  return { vendorProductPrices }
}
