import { withProfiler } from '@sentry/react'
import { ScrollToTop } from 'components'
import * as HOSTS from 'constants/hosts'
import 'firebase/app-check'
import { SessionExpired } from 'pages/SessionExpired'
import { useEffect, useRef, useState } from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import { RouterProvider } from 'react-router-dom'
import { getHeadTitle } from 'utils'
import './custom.scss'

import 'overlayscrollbars/overlayscrollbars.css'

import { router } from './router'

const { hostname } = window.location
const isProdHost = hostname === HOSTS.PROD_HOSTNAME

// Remove Later: only for Slack Testing
if (hostname === 'perkup-slack-redirect.ngrok.io') {
  const currentHref = window.location.href
  const newHref = currentHref.replace(
    HOSTS.SLACK_TUNNEL_HOST,
    `http://localhost:3000`
  )
  window.location.replace(newHref)
}

// Only to be used for global providers
function AppWrapper() {
  const [isInactive, setIsInactive] = useState(false)
  const { pathname } = window.location

  // Set Segment Page
  const title = getHeadTitle(pathname)
  useEffect(() => {
    if (isProdHost) {
      window.analytics.page({ title })
    }
  }, [title])

  const hours = 12
  const msPerHour = 1000 * 60 * 60
  const timeoutMillis = hours * msPerHour
  const timeoutRef = useRef<null | NodeJS.Timeout>(null)

  useEffect(() => {
    const onInactive = () => {
      setIsInactive(true)
    }
    const resetTimeout = () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
      }

      timeoutRef.current = setTimeout(onInactive, timeoutMillis)
    }

    const handleUserActivity = () => {
      if (!isInactive) {
        resetTimeout()
      }
    }
    resetTimeout()

    document.addEventListener('mousemove', handleUserActivity)
    document.addEventListener('keydown', handleUserActivity)
    document.addEventListener('mousedown', handleUserActivity)
    document.addEventListener('touchstart', handleUserActivity)

    return () => {
      document.removeEventListener('mousemove', handleUserActivity)
      document.removeEventListener('keydown', handleUserActivity)
      document.removeEventListener('mousedown', handleUserActivity)
      document.removeEventListener('touchstart', handleUserActivity)
      if (timeoutRef.current) clearTimeout(timeoutRef.current)
    }
  }, [timeoutMillis, isInactive])

  return (
    <HelmetProvider>
      <Helmet>
        <title>{title}</title>
        <meta
          name="description"
          content="Welcome back! Log in to PerkUp. Sign In with Google SSO or Email address. Don't have an account? Sign Up."
        />
      </Helmet>
      {isInactive ? (
        <SessionExpired />
      ) : (
        <div>
          <ScrollToTop />
          <RouterProvider router={router} />
        </div>
      )}
    </HelmetProvider>
  )
}

export default withProfiler(AppWrapper)
