import { Divider, Progress, Table, TableProps } from 'antd'
import { GiftThumbnail, PerkImage, ProgramAmount } from 'components'
import { FREE_DELIVERY } from 'constants/transactions'
import { Heading, Pane, Text, useTheme } from 'evergreen-ui'
import { DirectRewardOrderStatus } from 'features'
import { Program } from 'gen/perkup/v1/program_pb'
import { Order, Order_Item } from 'gen/perkup/v1/vendor_pb'
import { flatten, sortBy } from 'lodash-es'
import { useMemo } from 'react'
import { numToDollars } from 'utils'

const DirectMailColumns: TableProps<Order_Item>['columns'] = [
  {
    title: '',
    key: 'image',
    render: (_, record) => (
      <PerkImage
        src={record.image}
        alt={`${record.title}-thumbnail`}
        sizes="64px"
      />
    ),
  },
  {
    title: 'Item',
    key: 'title',
    dataIndex: 'title',
  },
  {
    title: 'Price',
    key: 'amount',
    dataIndex: 'amount',
    render: amount => numToDollars(amount),
  },
  {
    title: 'Quantity',
    key: 'quantity',
    dataIndex: 'quantity',
  },
  {
    title: 'Amount',
    key: 'totalAmount',
    render: (_, record) =>
      numToDollars(Number(record.amount) * record.quantity),
  },
]

export default function ItemsDetails({
  orders,
  program,
  isDirectMail,
  editMode,
}: {
  isDirectMail: boolean
  orders: Order[]
  program: Program
  editMode: boolean
}) {
  const theme = useTheme()

  const isGift = !!program.gift
  const isGiftOrDirectMail = isGift || isDirectMail

  const orderItems = useMemo(() => {
    const allItems = flatten(orders.map(order => order.items))
    return sortBy(allItems, item => item.title)
  }, [orders])

  const { totalBudget = 0, totalSpent = 0 } = program ?? {}
  const availableSpendPercent = ((totalBudget - totalSpent) / totalBudget) * 100
  const spentPercent = (totalSpent / totalBudget) * 100

  const showProgressBar = totalBudget > 0 && !isGiftOrDirectMail && !editMode

  return (
    <Pane>
      {/* SUBTITLE */}
      {!isGiftOrDirectMail && !editMode && (
        <Pane display="flex" alignItems="flex-end" marginTop={32}>
          {program?.id && <ProgramAmount program={program} />}
        </Pane>
      )}
      {isGiftOrDirectMail && (
        <Pane display="flex" alignItems="center" gap={32} marginY={32}>
          {isGift && (
            <Pane width="100%" maxWidth={256}>
              <GiftThumbnail
                imageUrl={program.gift?.imageUrl}
                title={!isDirectMail ? program?.gift?.title : ''}
              />
            </Pane>
          )}
          {isDirectMail && (
            <Pane
              display="flex"
              flexDirection="column"
              width="100%"
              maxWidth={1080}
            >
              <Table
                columns={DirectMailColumns}
                dataSource={orderItems}
                pagination={false}
              />
              <Pane
                display="flex"
                justifyContent="space-between"
                marginTop={24}
              >
                <Text>Subtotal</Text>
                <Text>{numToDollars(totalSpent)}</Text>
              </Pane>
              <Pane display="flex" justifyContent="space-between">
                <Text>Shipping</Text>
                <Text>{FREE_DELIVERY}</Text>
              </Pane>
              <Divider />
              <Pane display="flex" justifyContent="space-between">
                <Text fontWeight={600}>Total</Text>
                <Text fontWeight={600}>{numToDollars(totalSpent)}</Text>
              </Pane>
            </Pane>
          )}
          {(!isDirectMail || program.gift?.title) && (
            <Pane width={600}>
              {!isDirectMail && (
                <>
                  <Heading size={600} marginBottom={8}>
                    Gift acceptance
                  </Heading>
                  <Progress
                    trailColor={theme.colors.green100}
                    strokeColor={theme.colors.green500}
                    percent={Math.round(
                      (program.totalAccepted / program.totalMembers) * 100
                    )}
                    strokeLinecap="round"
                    showInfo={false}
                  />

                  <Pane display="flex">
                    <Pane
                      display="flex"
                      justifyContent="space-between"
                      width="100%"
                    >
                      {program.totalMembers && (
                        <Pane display="flex" flexDirection="column" gap={8}>
                          <Text color={theme.colors.green500}>
                            {Math.round(
                              (program.totalAccepted / program.totalMembers) *
                                100
                            )}
                            %
                          </Text>
                        </Pane>
                      )}
                      {!Number.isNaN(program?.totalAccepted) && (
                        <Pane
                          display="flex"
                          flexDirection="column"
                          gap={8}
                          textAlign="right"
                        >
                          <Text>
                            {program.totalAccepted} / {program.totalMembers}{' '}
                            accepted
                          </Text>
                        </Pane>
                      )}
                    </Pane>
                  </Pane>
                </>
              )}
              {isDirectMail && program.gift?.title && (
                <DirectRewardOrderStatus
                  productVariantName={program.gift?.title}
                />
              )}
            </Pane>
          )}
        </Pane>
      )}

      {/* PROGRESS BAR */}
      {showProgressBar && (
        <Pane width={600} marginY={8}>
          <Progress
            trailColor={theme.colors.green100}
            strokeColor={theme.colors.green500}
            percent={100 - availableSpendPercent}
            strokeLinecap="round"
            showInfo={false}
          />
          <Pane display="flex">
            <Pane display="flex" justifyContent="space-between" width="100%">
              <Text color={theme.colors.green500}>
                {Math.round(spentPercent)}%
              </Text>
              <Text>
                {numToDollars(totalSpent)} / {numToDollars(totalBudget)} Spent
              </Text>
            </Pane>
          </Pane>
        </Pane>
      )}
    </Pane>
  )
}
