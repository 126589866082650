import { STRIPE_IAUTH_PREFIX, STRIPE_TXN_PREFIX } from 'constants/stripe'
import { MANUAL_TRANSACTION_CATEGORY } from 'constants/transactions'
import {
  OrgTransaction,
  OrgTransaction as Transaction,
} from 'gen/perkup/v1/organization_pb'
import { OrderInfoLocationState } from 'types'

export function getTransactionDescription(transaction: Transaction) {
  if (!transaction) return null
  const isAmazonBusinessDirectOrder = transaction.category === 'amazon'
  return isAmazonBusinessDirectOrder
    ? 'Amazon Direct Order'
    : transaction.description
}

export const dateStyle = {
  flexBasis: 128,
  flexShrink: 0,
  flexGrow: 0,
}

export const statusStyle = {
  flexBasis: 196,
  flexShrink: 0,
  flexGrow: 0,
}

export const amountStyle = {
  flexBasis: 144,
  flexShrink: 0,
  flexGrow: 0,
}

export function createOrderLocation({
  location,
}: {
  location: OrderInfoLocationState
}) {
  const { state } = location
  if (!state) return undefined
  const shippingAddress = state?.shippingAddress
  const deliveryInformation = state?.deliveryInformation
  const voucherCode = state?.voucherCode
  const transactionConfirmed = state?.transactionConfirmed
  const imageUrl = state?.imageUrl
  const orderId = state?.orderId

  return {
    shippingAddress,
    deliveryInformation,
    voucherCode,
    transactionConfirmed,
    imageUrl,
    orderId,
  }
}

export const isStripeTransaction = ({
  transaction,
}: {
  transaction?: OrgTransaction
}) => {
  const transactionId = transaction?.id
  const isStripeTransaction =
    transactionId?.startsWith(STRIPE_IAUTH_PREFIX) ||
    transactionId?.startsWith(STRIPE_TXN_PREFIX)

  return isStripeTransaction
}

export const isManualTransaction = ({
  transaction,
}: {
  transaction?: OrgTransaction
}) => {
  const isStripe = isStripeTransaction({ transaction })
  return transaction?.category === MANUAL_TRANSACTION_CATEGORY && !isStripe
}
