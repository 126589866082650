import { DollarCircleOutlined, GiftOutlined } from '@ant-design/icons'
import { Timestamp } from '@bufbuild/protobuf'
import { TagProps } from 'antd'
import {
  Member_Action,
  ProgramStatus,
  ProgramType,
} from 'gen/perkup/v1/program_pb'
import { ConvertableTo } from 'types/Gift'

export const DEFAULT_LOGO =
  'https://uploads-ssl.webflow.com/628589d2f1c61e2fcbe34777/62865fb2b5a6774dbcd53cdc_PerkUpLogo_Master_Full.svg'

export const PROGRAM_TABS: ProgramStatus[] = [
  ProgramStatus.active,
  ProgramStatus.inactive,
  ProgramStatus.draft,
]

// Create a new mapping for the 'inactive' to 'expired' pair
export const PROGRAM_TAB_LABELS: Record<ProgramStatus, string> = {
  [ProgramStatus.UNKNOWN_ProgramStatus]: 'unknown',
  [ProgramStatus.active]: 'active',
  [ProgramStatus.inactive]: 'expired',
  [ProgramStatus.draft]: 'draft',
}

export const CONVERTIBLE_TO_EXTRAS: Record<
  string,
  { name: string; icon: typeof DollarCircleOutlined }
> = {
  [ConvertableTo.cash]: { name: 'PerkUp dollars', icon: DollarCircleOutlined },
  [ConvertableTo.gift]: { name: 'Gift', icon: GiftOutlined },
}

export const ORG_PROGRAM_TYPES = [
  ProgramType[ProgramType.perks],
  ProgramType[ProgramType.rewards],
  ProgramType[ProgramType.anniversaries],
  ProgramType[ProgramType.birthdays],
  ProgramType[ProgramType.direct],
]

export const SPOT_RWARD_PROGRAM_TYPES = [ProgramType[ProgramType.rewards]]

export interface IMemberActions {
  [key: string]: Timestamp
}
export interface IMemberActionDetails extends TagProps {
  action: string
  label: string
}

export const PROGRAM_ACTION_TYPES: { [key: string]: IMemberActionDetails } = {
  emailSent: {
    action: Member_Action[Member_Action.emailSent],
    label: 'Email sent',
    color: undefined,
  },
  emailOpen: {
    action: Member_Action[Member_Action.emailOpen],
    label: 'Opened',
    color: 'purple',
  },
  emailClick: {
    action: Member_Action[Member_Action.emailClick],
    label: 'Clicked',
    color: 'gold',
  },
  acceptedGift: {
    action: Member_Action[Member_Action.accepted],
    label: 'Accepted',
    color: 'green',
  },
  acceptedMonetary: {
    action: Member_Action[Member_Action.accepted],
    label: 'Spent',
    color: 'green',
  },
  emailBounce: {
    action: Member_Action[Member_Action.emailBounce],
    label: 'Email bounced',
    color: 'red',
  },
  emailBlocked: {
    action: Member_Action[Member_Action.emailBlocked],
    label: 'Email blocked',
    color: 'red',
  },
}

const PROGRAM_TYPES_MAP = {
  gift: 'Gift',
  directMail: 'Direct Mail',
} as const

export const getProgramTypeLabel = (type: string): string => {
  return PROGRAM_TYPES_MAP[type as keyof typeof PROGRAM_TYPES_MAP] ?? type
}

export const EMPTY = ''
export const DEFAULT_PROGRAM_BUDGET = 5000
export const DEFAULT_PROGRAM_BANNER =
  'https://images.unsplash.com/photo-1525373612132-b3e820b87cea?crop=entropy&cs=srgb&fm=j[…]YzODM3NzE5OA&ixlib=rb-1.2.1&q=85&fit=crop&h=712&q=80&w=1080'
