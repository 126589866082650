import { ExportOutlined, PlusOutlined } from '@ant-design/icons'
import { Button, Dropdown, Flex, Image, Tooltip } from 'antd'
import { SWAG_CARD } from 'assets/contentful'
import PerkUpLogo from 'assets/perkup-logo.svg'
import { EditableImage, PerkLink, PerkLoader, withOrgSidebar } from 'components'
import { BULK_CATALOG_VIEWED } from 'constants/events'
import {
  BLANKS,
  BULK,
  CUSTOMIZE,
  DEFAULT_ROUTES,
  PRODUCTS,
  SEARCH,
  SEARCH_CONFIRMATION,
  SEARCH_PRODUCT,
  SWAG_COLLECTION,
  SWAG_INVENTORY_PRODUCT,
  SWAG_ORDERS_TABLE,
} from 'constants/routes'
import { IndividualContext, OrgContext, UserContext } from 'context'
import { Heading, Pane, Tab, Tablist, Text } from 'evergreen-ui'
import {
  useBulkSwagCatalogs,
  useIndividualRole,
  usePublicProductCollections,
  useTemporarySwagBannerFallbackUrl,
} from 'hooks'
import { useIsFlagged } from 'hooks/useIsFlagged'
import { isEmpty } from 'lodash-es'
import NoMatch404 from 'pages/NoMatch404'
import { useContext } from 'react'
import {
  Outlet,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router-dom'
import { logEvent } from 'utils'
import orgSwagBlankProductsPage from './org-swag-blank-products-page'
import { OrgSwagBulkCatalogsPage } from './org-swag-bulk-catalogs-page'
import { OrgSwagCollectionPage } from './org-swag-collection-page'
import OrgSwagCollectionsPage from './org-swag-collections-page'
import OrgSwagOrdersPage from './org-swag-orders-page'
import orgSwagProductDesignerPage from './org-swag-product-customizer-page'
import OrgSwagProductPage from './org-swag-product-page'
import OrgSwagProductsPage from './org-swag-products-page'
import { OrgSwagSearchConfirmation } from './org-swag-search-confirmation'
import { OrgSwagSearchProduct } from './org-swag-search-product'
import { OrgSwagSearchProducts } from './org-swag-search-products'

function SwagStoreButton() {
  const { collections } = usePublicProductCollections()

  const noPublicCollections = collections.length === 0

  return (
    <Tooltip
      title={
        noPublicCollections ? 'Publish a Swag Store to view' : `View Swag Store`
      }
    >
      <PerkLink
        to={
          collections.length === 1
            ? `${DEFAULT_ROUTES.SWAG.ROOT}/${collections[0].id}`
            : DEFAULT_ROUTES.SWAG.ROOT
        }
        target="_blank"
        rel="noopener noreferrer"
      >
        <Button
          disabled={noPublicCollections}
          icon={<ExportOutlined />}
          type="text"
        >
          Swag Store
        </Button>
      </PerkLink>
    </Tooltip>
  )
}

function OrgSwagLayout() {
  const { name, logoUrl, swagBannerUrl, id: orgId } = useContext(OrgContext)
  const { id: userId } = useContext(UserContext)
  const { id: individualId, email } = useContext(IndividualContext)

  const isFlagged = useIsFlagged()

  const navigate = useNavigate()
  const location = useLocation()

  const { isAdmin } = useIndividualRole()

  const { tempPlacidFallBackUrl, hasLoaded: hasLoadedSwagBanner } =
    useTemporarySwagBannerFallbackUrl() // Temporary
  const { bulkSwagCatalogs, hasLoaded: hasLoadedBulkSwagCatalogs } =
    useBulkSwagCatalogs()

  // const [isHoveringBanner, setIsHoveringBanner] = useState(false)

  const isOnOrdersPage = location.pathname.includes(SWAG_ORDERS_TABLE)
  const isOnProductsPage = location.pathname.includes(PRODUCTS)
  const isOnBlanksPage = location.pathname.includes(BLANKS)
  const isOnBulkCatalogsPage = location.pathname.includes(SEARCH)

  const tabOptions = [
    {
      label: 'Collections',
      route: DEFAULT_ROUTES.ORGANIZATION.SWAG.ROOT,
      isSelected:
        !isOnProductsPage &&
        !isOnOrdersPage &&
        !isOnBlanksPage &&
        !isOnBulkCatalogsPage,
    },
    {
      label: 'Products',
      route: DEFAULT_ROUTES.ORGANIZATION.SWAG.PRODUCTS,
      isSelected: isOnProductsPage,
    },
    {
      label: 'Orders',
      route: DEFAULT_ROUTES.ORGANIZATION.SWAG.ORDERS_TABLE,
      isSelected: isOnOrdersPage,
      isHidden: !isAdmin,
    },
    {
      label: 'On Demand',
      route: DEFAULT_ROUTES.ORGANIZATION.SWAG.BLANKS,
      isSelected: isOnBlanksPage,
      isHidden: !isAdmin || !isFlagged,
    },
    {
      label: 'Bulk Catalog',
      route: DEFAULT_ROUTES.ORGANIZATION.SWAG.SEARCH,
      isSelected: isOnBulkCatalogsPage,
      isHidden: !isAdmin,
      onSelect: () =>
        logEvent(BULK_CATALOG_VIEWED, {
          userId,
          individualId,
          email,
          orgId,
        }),
    },
  ]

  if (!hasLoadedSwagBanner || !hasLoadedBulkSwagCatalogs) return <PerkLoader />

  const hasNoBulkSwagCatalogs = isEmpty(bulkSwagCatalogs)

  // Bring back once DES-279 is done
  // const handleSwagBannerImageUpload = (
  //   upload: UploadChangeParam<UploadFile<any>>
  // ) => {
  //   if (!upload.file.originFileObj) return
  //   if (upload.file.percent === 100) {
  //     console.log('Maybe?')
  //     uploadOrgAsset({ upload, orgId, field: 'swagBannerUrl' })
  //       .then(() =>
  //         toaster.success('Successfully updated collection photo', {
  //           id: upload.file.fileName,
  //         })
  //       )
  //       .catch(() =>
  //         toaster.danger('Failed to upload image. Please contact support.', {
  //           id: upload.file.fileName,
  //         })
  //       )
  //   }
  // }

  return (
    <Flex vertical align="center" style={{ margin: '-32px -32px 0 -32px' }}>
      {/** ORG SWAG BANNER */}
      <Pane
        style={{ margin: '-32px -32px 0 -32px' }}
        // onMouseEnter={() => setIsHoveringBanner(true)} // Bring back once DES-279 is done
        // onMouseLeave={() => setIsHoveringBanner(false)} // Bring back once DES-279 is done
        width="100%"
      >
        <EditableImage
          src={swagBannerUrl || tempPlacidFallBackUrl || SWAG_CARD}
          // mode={isHoveringBanner ? Mode.edit : Mode.view} // Bring back once DES-279 is done
          maxHeight="135px"
          sizes="100vw"
          // onImageChange={handleSwagBannerImageUpload} // Bring back once DES-279 is done
        />
      </Pane>

      {/** SWAG STORES LINK */}
      <Flex
        justify="space-between"
        style={{ padding: '12px 32px', width: '100%' }}
      >
        <Flex gap={16}>
          <Dropdown
            menu={{
              items: [
                {
                  key: '1',
                  label: (
                    <PerkLink
                      to="https://projects.perkupapp.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      New 1-unit
                    </PerkLink>
                  ),
                },
                {
                  key: '2',
                  label: (
                    <PerkLink
                      to={
                        hasNoBulkSwagCatalogs
                          ? 'https://perkupapp.com/swag'
                          : DEFAULT_ROUTES.ORGANIZATION.SWAG.BULK_CATALOGS
                      }
                      target={hasNoBulkSwagCatalogs ? '_blank' : undefined}
                      rel={
                        hasNoBulkSwagCatalogs
                          ? 'noopener noreferrer'
                          : undefined
                      }
                    >
                      Bulk Catalog
                    </PerkLink>
                  ),
                },
              ],
            }}
          >
            <Button icon={<PlusOutlined />}>Add products</Button>
          </Dropdown>
          <PerkLink to={DEFAULT_ROUTES.ORGANIZATION.ORDER.SWAG}>
            <Button>Send to address</Button>
          </PerkLink>
        </Flex>

        <SwagStoreButton />
      </Flex>

      {/** ORG LOGO */}
      <Pane
        display="flex"
        justifyContent="center"
        elevation={1}
        padding={24}
        width={150}
        height={120}
        borderRadius={8}
        backgroundColor="white"
        zIndex={1}
        marginTop={-120}
        marginBottom={16}
      >
        <Image
          src={logoUrl || PerkUpLogo}
          preview={false}
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'contain',
          }}
        />
      </Pane>

      {/** ORG NAME AND SWAG TABS */}
      <Flex vertical gap={16} align="center">
        <Heading size={900}>{name} Swag</Heading>
        <Tablist>
          {tabOptions.map(tab => {
            if (tab.isHidden) return null
            return (
              <Tab
                key={tab.label}
                appearance="primary"
                isSelected={tab.isSelected}
                onSelect={() => {
                  if (tab.onSelect) tab.onSelect()
                  navigate(tab.route)
                }}
              >
                <Text size={500} color="primary">
                  {tab.label}
                </Text>
              </Tab>
            )
          })}
        </Tablist>
      </Flex>

      {/** ORG SWAG OUTLETS */}
      <Outlet />
    </Flex>
  )
}

function OrgSwag() {
  return (
    <Routes>
      <Route element={<OrgSwagLayout />}>
        <Route index element={<OrgSwagCollectionsPage />} />
        <Route path={PRODUCTS} element={<OrgSwagProductsPage />} />
        <Route path={SWAG_ORDERS_TABLE} element={<OrgSwagOrdersPage />} />
        <Route path={BLANKS} {...orgSwagBlankProductsPage} />
        <Route path={SEARCH} element={<OrgSwagSearchProducts />} />
        <Route path={SEARCH_PRODUCT} element={<OrgSwagSearchProduct />} />
        <Route
          path={SEARCH_CONFIRMATION}
          element={<OrgSwagSearchConfirmation />}
        />
        <Route path="*" element={<NoMatch404 hasFooter={false} />} />
      </Route>

      {/** We want the collection & product pages to be in full view, no layout / header */}
      <Route path={SWAG_COLLECTION} element={<OrgSwagCollectionPage />} />
      <Route path={SWAG_INVENTORY_PRODUCT} element={<OrgSwagProductPage />} />
      <Route path={CUSTOMIZE} {...orgSwagProductDesignerPage} />
      <Route path={BULK} element={<OrgSwagBulkCatalogsPage />} />
    </Routes>
  )
}

export default withOrgSidebar(OrgSwag)
