import { DownloadOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import { FetchTinyBirdRewardReportsByOrgId } from 'api/tinybirdCalls/reads'
import { ProgramType } from 'gen/perkup/v1/program_pb'
import useIds from 'hooks/useIds'
import flatten from 'lodash-es/flatten'
import { useEffect, useState } from 'react'
import { CSVLink } from 'react-csv'
import { RewardReport } from 'types'
import { RangeValue } from 'types/RewardsDate'

export function DownloadRewardReportButton({
  dates,
  programTypes,
}: {
  dates: RangeValue
  programTypes: ProgramType[]
}) {
  const { orgId } = useIds()

  const [formattedRewardReports, setFormattedRewardReports] = useState<
    RewardReport[]
  >([])

  // Set RewardReports data using tinybird
  useEffect(() => {
    if (orgId) {
      FetchTinyBirdRewardReportsByOrgId({
        dates,
        orgId,
        cb: (rewardReports: RewardReport[]) => {
          if (rewardReports) {
            setFormattedRewardReports(flatten(rewardReports))
          } else setFormattedRewardReports([])
        },
        programTypes,
      })
    }
  }, [orgId, dates, programTypes])

  const headers = [
    { label: 'Employee ID', key: 'employeeId' },
    { label: 'Name', key: 'name' },
    { label: 'Email', key: 'email' },
    { label: 'Location', key: 'userLocation' },
    { label: 'Department', key: 'userDepartment' },
    { label: 'Country', key: 'userCountry' },
    { label: 'Date Created', key: 'createdDate' },
    { label: 'Reward Amount ($)', key: 'rewardAmount' },
    { label: 'Reward Spent Amount ($)', key: 'rewardSpentAmount' },
    { label: 'Reward Name', key: 'rewardName' },
    { label: 'Reward Active', key: 'rewardActive' },
    { label: 'Removed At', key: 'removedAt' },
    { label: 'Currency', key: 'currency' },
    { label: 'Created By', key: 'createdBy' },
    { label: 'Reward Id', key: 'programId' },
    { label: 'Reward Type', key: 'programType' },
    { label: 'Internal Memo', key: 'programInternalMemo' },
  ]

  const disableDownloadBtn =
    !formattedRewardReports || formattedRewardReports.length === 0

  return (
    <CSVLink
      headers={headers}
      filename="perkup_reward_report.csv"
      data={formattedRewardReports}
    >
      <Button
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        icon={<DownloadOutlined />}
        disabled={disableDownloadBtn}
      >
        Download
      </Button>
    </CSVLink>
  )
}
