import { Slider } from 'antd'
import { NUM_UNITS_TO_PERCENTAGE } from 'constants/shopify'
import { useState } from 'react'
import { makePlural, numToDollars } from 'utils'
import { SmallSavingsTag } from './small-savings-tag'

const determineDiscountPercentage = (numUnits: number) => {
  if (numUnits >= 100) return NUM_UNITS_TO_PERCENTAGE[100]
  if (numUnits >= 40) return NUM_UNITS_TO_PERCENTAGE[40]
  if (numUnits >= 20) return NUM_UNITS_TO_PERCENTAGE[20]
  if (numUnits >= 10) return NUM_UNITS_TO_PERCENTAGE[10]
  if (numUnits >= 2) return NUM_UNITS_TO_PERCENTAGE[2]
  return NUM_UNITS_TO_PERCENTAGE[1]
}

const buildLabel = (numUnits: number | undefined) => {
  if (!numUnits) return ''

  const discountPercentage = determineDiscountPercentage(numUnits)

  return `${makePlural('unit', numUnits, true)} (${discountPercentage}% off)`
}

export function BulkPriceCalculator({ unitAmount }: { unitAmount: number }) {
  const [selectedNumUnits, setSelectedNumUnits] = useState(40)

  const discountPercentage = determineDiscountPercentage(selectedNumUnits)

  const unitAmountWithDiscount =
    unitAmount - unitAmount * (discountPercentage / 100)

  const totalSavingsForThisProduct = unitAmount * (discountPercentage / 100)

  return (
    <div className="flex flex-col gap-2">
      <Slider
        tooltip={{
          formatter: buildLabel,
        }}
        marks={{
          1: 1,
          2: 2,
          10: 10,
          20: 20,
          40: 40,
          100: 100,
        }}
        min={1}
        max={100}
        value={selectedNumUnits}
        onChange={setSelectedNumUnits}
      />

      <section className="flex items-center gap-2">
        <div className="flex gap-1 items-baseline text-base">
          <p>Discounted price:</p>

          <p
            data-discounted={discountPercentage > 0}
            className="text-muted-foreground data-[discounted=true]:line-through data-[discounted=true]:text-sm"
          >
            {numToDollars(unitAmount, 2)}
          </p>
          {discountPercentage > 0 && (
            <p className="text-red-500 font-semibold">
              {numToDollars(unitAmountWithDiscount, 2)}
            </p>
          )}
        </div>

        <SmallSavingsTag
          savings={totalSavingsForThisProduct}
          discount={discountPercentage}
        />
      </section>

      <p className="italic text-muted-foreground">
        Discount only applies to orders to same address.
      </p>
    </div>
  )
}
