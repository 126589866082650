import type { FormProps } from 'antd'
import { Button, Flex, Form, InputNumber, Spin } from 'antd'
import { updateProductVariantClicksAmount } from 'api/databaseCalls/writes'
import { Text, toaster } from 'evergreen-ui'
import { ProductVariant } from 'gen/perkup/v1/product_variant_pb'
import { useEffect, useState } from 'react'

/** Small helper component for the product team to quickly update a product variant's metrics
 * At the moment, the product team only needs to update the number of clicks for a product variant
 * @param productVariant - The product variant to update
 */
export function MetricsUpdater({
  productVariant,
}: {
  productVariant: ProductVariant
}) {
  const [form] = Form.useForm()
  const [isLoading, setIsLoading] = useState(false)
  const [submittable, setSubmittable] = useState(false)

  // Watch all values
  const values = Form.useWatch([], form)

  useEffect(() => {
    form
      .validateFields({ validateOnly: true })
      .then(() => setSubmittable(true))
      .catch(() => setSubmittable(false))
  }, [form, values])

  const onFinish: FormProps['onFinish'] = async values => {
    setIsLoading(true)
    await updateProductVariantClicksAmount({
      id: productVariant.id,
      clicksAmount: values.clicks,
    })
    setIsLoading(false)
    toaster.success(
      'Update successful. Please refresh to see changes in the grid.'
    )
    setSubmittable(false)
  }

  return (
    <Flex vertical>
      <Form
        form={form}
        name="basic"
        layout="inline"
        initialValues={{ clicks: productVariant.metrics?.clicks }}
        onFinish={onFinish}
        autoComplete="off"
      >
        <Form.Item
          hasFeedback
          label="Clicks"
          name="clicks"
          rules={[{ required: true, message: 'Please enter a valid number' }]}
        >
          <InputNumber controls={false} style={{ width: '100%' }} />
        </Form.Item>

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            disabled={!submittable || !form.isFieldsTouched(true) || isLoading}
          >
            {isLoading && <Spin size="small" />}
            Submit
          </Button>
        </Form.Item>
      </Form>

      <Text size={300} color="muted">
        <i>
          Note: After submitting, please be sure to refresh the page. <br /> You
          may need to reload the page a few times to see your changes reflected
          in the grid.
        </i>
      </Text>
    </Flex>
  )
}
