import { Flex } from 'antd'
import { Text } from 'evergreen-ui'
import { getCountryNameFromIso2 } from 'utils'
import { FlagAvatar } from './FlagAvatar'

export function FlagAvatarWithText({ iso2 }: { iso2: string }) {
  return (
    <Flex gap={8} align="center">
      <FlagAvatar iso2={iso2} />
      <Text width="max-content">{getCountryNameFromIso2(iso2)}</Text>
    </Flex>
  )
}
