import { captureException } from '@sentry/react'
import { doc, getDoc, getFirestore } from 'firebase/firestore'
import { Order } from 'gen/perkup/v1/vendor_pb'
import { compact } from 'lodash-es'
import { toSentry } from 'utils'
import { converter } from 'utils/firestore'

export async function GetOrderById({ orderId }: { orderId: string }) {
  try {
    const db = getFirestore()
    const docRef = doc(db, `orders/${orderId}`).withConverter(converter(Order))
    return await getDoc(docRef).then(doc => doc.data())
  } catch (error) {
    console.error(error)
    captureException(toSentry(error), {
      contexts: {
        GetUserById: { orderId },
      },
    })
  }
}

export async function GetOrdersByOrderIds({
  orderIds,
}: {
  orderIds: string[]
}) {
  try {
    const db = getFirestore()
    const docRefs = orderIds.map(orderId =>
      doc(db, `orders/${orderId}`).withConverter(converter(Order))
    )
    return await Promise.all(docRefs.map(docRef => getDoc(docRef))).then(docs =>
      compact(docs.map(doc => doc.data()))
    )
  } catch (error) {
    console.error(error)
    captureException(toSentry(error), {
      contexts: {
        GetOrdersByOrderIds: { orderIds },
      },
    })
  }
}
