import { Button } from 'antd'
import * as ROUTES from 'constants/routes'
import { OrgContext, OrgUserContext } from 'context'
import { Alert } from 'evergreen-ui'
import { Organization_SubscriptionStatus } from 'gen/perkup/v1/organization_pb'
import { useMemberships } from 'hooks'
import { useContext } from 'react'
import { useNavigate } from 'react-router'
import Stripe from 'stripe'
import { getStripeCapabilitiesStatus, isNearCashMembership } from 'utils'

export function ActivateCard({ card }: { card?: Stripe.Issuing.Card }) {
  const org = useContext(OrgContext)
  const orgUser = useContext(OrgUserContext)

  const navigate = useNavigate()

  const { memberships } = useMemberships()

  if (org.subscriptionStatus !== Organization_SubscriptionStatus.active) {
    return null
  }

  const cardIsCancelled = card?.status === 'canceled'
  if (orgUser?.cardId && !cardIsCancelled) return null

  // Users can only activate their card if they have funds available from cash rewards or their own personal funds
  const validMemberships = memberships.filter(membership =>
    isNearCashMembership(membership)
  )

  const isEnrolled = validMemberships.length > 0

  const alertMessage = isEnrolled
    ? 'Card not active'
    : 'Activate your card after receiving a reward'

  const activeConnectedAccount = getStripeCapabilitiesStatus({ org })

  return (
    <Alert
      hidden={isEnrolled === null}
      title={alertMessage}
      marginBottom={12}
      width="100%"
      position="relative"
      appearance="card"
      cursor="default"
      onClick={(e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation()
      }}
    >
      <Button
        hidden={!isEnrolled}
        type="primary"
        onClick={() => navigate(ROUTES.CARD_SETUP)}
        style={{
          height: 24,
          position: 'absolute',
          right: 10,
          top: 10,
          cursor: 'pointer',
          display: 'flex',
          alignItems: 'center',
        }}
        autoFocus
        disabled={!activeConnectedAccount}
      >
        Activate card
      </Button>
    </Alert>
  )
}
