import { Pane, Table } from 'evergreen-ui'
import { Individual } from 'gen/perkup/v1/individual_pb'
import { ProgramMemberRow } from './ProgramMemberRow'

export function ProgramMemberTable(props: { individuals: Individual[] }) {
  const { individuals } = props
  return (
    <Table>
      <Table.Body>
        {individuals?.map(individual => {
          return (
            <Pane key={individual.id}>
              <ProgramMemberRow individual={individual} />
            </Pane>
          )
        })}
      </Table.Body>
    </Table>
  )
}
