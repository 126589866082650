import { captureException } from '@sentry/react'
import axios from 'axios'
import { getFunctionsHost, toSentry } from 'utils'

export async function SendRewardReminderEmail({
  orgId,
  programId,
  memberId,
  manualReminder = 'reminder',
}: {
  orgId: string
  programId: string
  memberId?: string
  manualReminder?: 'reminder' | 'expiration'
}) {
  if (!orgId || !programId) return

  const requestBody = {
    orgId,
    programId,
    manualReminder,
    ...(memberId && { memberId }),
  }

  try {
    const host = getFunctionsHost()
    const url = `${host}/services-PublishRewardReminders`
    await axios.post(url, requestBody)
  } catch (error) {
    captureException(toSentry(error), {
      contexts: {
        SendRewardReminderEmail: requestBody,
      },
    })
  }
}
