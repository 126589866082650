import { PlusOutlined } from '@ant-design/icons'
import { Button, Flex } from 'antd'
import { OrgTemplateCardGrid, SendAProgram } from 'components'
import { FeaturedProgramTemplatesCarousel } from 'components/Carousels/ProgramTemplatesCarousel'
import { DEFAULT_ROUTES } from 'constants/routes'
import { OrgContext } from 'context'
import { Template } from 'gen/perkup/v1/org_template_pb'
import { useIndividualRole } from 'hooks'
import { isEmpty } from 'lodash-es'
import { getProgramEndsOnFromTemplateExpiry } from 'pages/NewReward/utils/programs'
import { useOrgTemplatesByAccess } from 'pages/Templates/hooks/useOrgTemplatesByAccess'
import { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { CreateRewardLocation } from 'types'

export function BrowseTemplates() {
  const org = useContext(OrgContext)
  const { isAdmin } = useIndividualRole()
  const navigate = useNavigate()

  const { templates, hasLoaded } = useOrgTemplatesByAccess()

  const handleOrgTemplateClick = (template: Template) => {
    const expiryWritable = template?.expiry?.writable
    const endsOn = expiryWritable
      ? getProgramEndsOnFromTemplateExpiry({
          templateExpiry: template?.expiry,
        })?.toDate()
      : undefined

    const budget = template?.budget?.amounts
      ? template.budget.amounts[0]
      : undefined
    const createRewardState: CreateRewardLocation = {
      state: {
        templateId: template.id,
        occasion: template.occasion,
        email: {
          banner: template?.image?.url || '',
          title: template?.email?.title || '',
          fromName: org.name,
          note: template?.email?.message || '',
        },
        memo: template?.internalMemo,
        defaultCats: template.approvedCategories,
        coreValues: template.coreValues?.values,
        endsOn,
        budget,
      },
    }

    navigate(
      `${DEFAULT_ROUTES.ORGANIZATION.REWARDS.NEW_REWARD}`,
      createRewardState
    )
  }

  const truncateOrgTemplates = templates.length > 3

  if (!hasLoaded) return null

  if (isEmpty(templates) || isAdmin) return <FeaturedProgramTemplatesCarousel />

  return (
    <Flex vertical gap={8}>
      <SendAProgram isLandscape hideCTA />
      <OrgTemplateCardGrid
        templates={truncateOrgTemplates ? templates.slice(0, 3) : templates}
        onSelectTemplate={handleOrgTemplateClick}
      />
      {truncateOrgTemplates && (
        <Button
          onClick={() =>
            navigate(DEFAULT_ROUTES.ORGANIZATION.REWARDS.NEW_REWARD)
          }
          type="text"
          style={{ width: 248, alignSelf: 'center' }}
          icon={<PlusOutlined />}
        >
          See all
        </Button>
      )}
    </Flex>
  )
}
