import { Timestamp } from '@bufbuild/protobuf'
import {
  addProgramToOrg,
  GetOrgTemplateById,
  ListProductVariantsByProductIds,
} from 'api/databaseCalls'
import { PUBLIC_GIFT_TAG } from 'constants/algolia'
import {
  allApprovedCats,
  ApprovedCategories,
} from 'constants/EligibleCategories'
import { OCCASION_COLLECTION_IDS } from 'constants/productCollections'
import { defaultBudgets } from 'constants/rewards'
import { DEFAULT_ROUTES } from 'constants/routes'
import { OrgContext } from 'context'
import { toaster } from 'evergreen-ui'
import {
  Program,
  Program_DraftDataField,
  Program_Gift,
  Program_RewardTab,
  ProgramFrequency,
  ProgramOccasion,
  ProgramStatus,
  ProgramType,
} from 'gen/perkup/v1/program_pb'
import useIds from 'hooks/useIds'
import { isEmpty, isEqual } from 'lodash-es'
import { RewardFormData } from 'pages/NewReward/types/forms'
import { getDefaultOccasionFromTemplate } from 'pages/NewReward/utils/program-templates'
import {
  createRewardLocation,
  getDefaultRewardTabByTemplate,
  getDraftProgramNavigationRoute,
} from 'pages/NewReward/utils/programs'
import { generateRandomEmail } from 'pages/NewReward/utils/uiUtils'
import { useContext, useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

const determineDefaultGiftDraftData = async ({
  defaultData,
  orgId,
}: {
  defaultData?: RewardFormData
  orgId: string
}): Promise<Program_DraftDataField> => {
  const gift = defaultData?.gift
  if (gift) {
    const isNewCollection = OCCASION_COLLECTION_IDS.includes(
      gift?.productCollectionId || ''
    )
    const isSwagCollection = gift?.productCollectionId && !isNewCollection

    // productId -> no specific variant, productVariantId -> specific variant
    const productIds = gift?.productIds || []

    const productVariantsFromProductIds =
      (await ListProductVariantsByProductIds({
        orgId,
        productIds,
      })) || []

    // Public gift in tags -> gift catalog tab, else swag tab
    const isAllPublicGiftVariants = productVariantsFromProductIds.every(pv =>
      pv.tags.includes(PUBLIC_GIFT_TAG)
    )

    // We never select a specific pv for swag
    const isPublicGift = !!gift?.productVariantId || isAllPublicGiftVariants

    const isSwagGift =
      !isEmpty(productVariantsFromProductIds) && !isAllPublicGiftVariants

    if (isSwagCollection || isSwagGift) {
      return new Program_DraftDataField({
        swagGift: new Program_Gift(gift),
        rewardTab: Program_RewardTab.swag,
      })
    }
    if (isPublicGift || isNewCollection) {
      return new Program_DraftDataField({
        catalogGift: new Program_Gift(gift),
        rewardTab: Program_RewardTab.catalog,
      })
    }
  }

  const templateId = defaultData?.templateId
  const template = templateId
    ? await GetOrgTemplateById({ orgId, templateId })
    : undefined

  const rewardTab = getDefaultRewardTabByTemplate(template)

  const defaultBudget = template?.budget?.amounts[0] || defaultBudgets[0]
  const defaultApprovedCats = template?.approvedCategories || allApprovedCats

  return new Program_DraftDataField({
    perkupDollars: {
      amount: defaultBudget,
      approvedCategories: defaultApprovedCats,
    },
    rewardTab,
  })
}

const createDraftProgram = async ({
  defaultData,
  orgId,
  draftProgram,
}: {
  defaultData?: RewardFormData
  orgId: string
  draftProgram: Program
}) => {
  const defaultIndividualIds = defaultData?.recipientIndividuals?.map(
    ind => ind.id
  )
  const draftGiftData = await determineDefaultGiftDraftData({
    defaultData,
    orgId,
  })

  const isCatalogCollection = !!draftGiftData?.catalogGift?.productCollectionId

  const updatedDraftData = new Program_DraftDataField({
    ...draftGiftData,
    individuals: defaultIndividualIds,
    collectionAmount: isCatalogCollection ? draftProgram.budget : undefined,
  }).toJsonString()

  const updatedProgramGift = draftProgram?.gift
    ? new Program_Gift({
        ...draftProgram.gift,
        country: undefined,
      })
    : undefined

  const updatedDraftProgram = new Program({
    ...draftProgram,
    draftData: updatedDraftData,
    gift: updatedProgramGift,
  })

  const newProgram = await addProgramToOrg({
    orgId,
    program: updatedDraftProgram,
  })

  return newProgram
}

export function useCreateDraftProgram() {
  const location = useLocation()
  const defaultData = createRewardLocation({ location })

  const org = useContext(OrgContext)
  const { programId } = useParams()
  const navigate = useNavigate()
  const { orgId, userId } = useIds()

  const [isCreatingDraftProgram, setIsCreatingDraftProgram] = useState(false)

  const needsProgram =
    !programId &&
    window.location.pathname === DEFAULT_ROUTES.ORGANIZATION.REWARDS.NEW_REWARD

  const allMerchantsAllowed = defaultData?.defaultCats
    ? isEqual(defaultData?.defaultCats?.sort(), allApprovedCats.sort())
    : true

  const defaultStartDate = defaultData?.startsOn
    ? Timestamp.fromDate(new Date(defaultData?.startsOn))
    : undefined

  const defaultEndDate = defaultData?.endsOn
    ? Timestamp.fromDate(new Date(defaultData?.endsOn))
    : undefined

  const defaultBudget = defaultData?.budget

  const defaultGift = useMemo(() => {
    return defaultData?.gift
      ? new Program_Gift({ ...defaultData?.gift })
      : undefined
  }, [defaultData?.gift])

  const defaultMemo = defaultData?.memo

  const defaultCoreValues = defaultData?.coreValues

  const defaultOccasion = useMemo(() => {
    if (defaultData?.startingTemplate) {
      return getDefaultOccasionFromTemplate({
        template: defaultData?.startingTemplate,
      })?.occasion
    }
    if (defaultData?.occasion) {
      return defaultData?.occasion
    }
    return undefined
  }, [defaultData?.occasion, defaultData?.startingTemplate])

  const defaultEmailData = defaultData?.email || generateRandomEmail(org.name)

  const defaultApprovedCategories = useMemo(() => {
    if (defaultOccasion === ProgramOccasion.lunch) {
      return [ApprovedCategories.FOOD]
    }
    if (defaultData?.defaultCats) {
      return defaultData?.defaultCats
    }
    return allApprovedCats
  }, [defaultData?.defaultCats, defaultOccasion])

  const defaultItems = defaultData?.items

  const draftProgram = useMemo(() => {
    // Create the program
    const program = new Program({
      id: programId,
      name: defaultEmailData?.title,
      frequency: ProgramFrequency.once,
      approvedCategories: defaultApprovedCategories,
      autoEnrolled: false,
      allMerchants: allMerchantsAllowed,
      startsOn: defaultStartDate || Timestamp.now(),
      endsOn: defaultEndDate,
      note: defaultEmailData.note,
      email: {
        banner: defaultEmailData?.banner,
        fromName: defaultEmailData?.fromName,
        title: defaultEmailData?.title,
      },
      budget: defaultBudget || defaultBudgets[0],
      ownerId: userId,
      status: ProgramStatus.draft,
      prorate: false,
      created: Timestamp.now(),
      totalSpent: 0,
      totalBudget: 0,
      isDeleted: true,
      type: ProgramType.rewards,
      occasion: defaultOccasion,
      gift: defaultGift,
      internalMemo: defaultMemo,
      coreValues: defaultCoreValues,
      items: defaultItems,
      templateId: defaultData?.templateId,
    })
    return program
  }, [
    programId,
    defaultEmailData?.title,
    defaultEmailData.note,
    defaultEmailData?.banner,
    defaultEmailData?.fromName,
    defaultApprovedCategories,
    allMerchantsAllowed,
    defaultStartDate,
    defaultEndDate,
    defaultBudget,
    userId,
    defaultOccasion,
    defaultGift,
    defaultMemo,
    defaultCoreValues,
    defaultItems,
    defaultData?.templateId,
  ])

  useEffect(() => {
    if (!needsProgram || isCreatingDraftProgram) return
    setIsCreatingDraftProgram(true)
    // Need to construct default data

    createDraftProgram({ orgId, defaultData, draftProgram })
      .then(newlyCreatedProgram => {
        if (newlyCreatedProgram) {
          const navigationRoute = getDraftProgramNavigationRoute({
            defaultData,
          })
          navigate(`${newlyCreatedProgram.id}${navigationRoute}`, {
            replace: true,
          })
        } else {
          toaster.warning('Error creating draft program')
        }
      })
      .finally(() => setIsCreatingDraftProgram(false))
  }, [
    defaultData,
    defaultData?.email,
    draftProgram,
    isCreatingDraftProgram,
    navigate,
    needsProgram,
    orgId,
    programId,
  ])

  return { isCreatingDraftProgram }
}
