import { motion } from 'framer-motion'
import isNull from 'lodash-es/isNull'
import { useLocation } from 'react-router-dom'
import { PageDirection } from 'types'

interface DirectionLocationState {
  state: {
    direction: PageDirection
  }
}

export function AnimatedPage({ children }: { children: JSX.Element }) {
  const location = useLocation() as DirectionLocationState

  const direction = isNull(location.state)
    ? PageDirection.FORWARD
    : location.state.direction

  const variants = {
    enter: () => {
      return {
        x: direction === PageDirection.FORWARD ? 1000 : -1000,
        opacity: 0,
      }
    },
    center: {
      x: 0,
      opacity: 1,
    },
    exit: () => {
      return {
        x: direction === PageDirection.FORWARD ? -1000 : 1000,
        opacity: 0,
      }
    },
  }

  return (
    <motion.div
      variants={variants}
      initial="enter"
      animate="center"
      exit="exit"
      style={{ width: '100%', display: 'flex', justifyContent: 'center' }}
      transition={{ duration: 0.4 }}
    >
      {children}
    </motion.div>
  )
}
