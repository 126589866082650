// @generated by protoc-gen-es v1.6.0 with parameter "target=ts"
// @generated from file perkup/v1/venue_event.proto (package perkup.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, Timestamp } from "@bufbuild/protobuf";

/**
 * Firestore /venueEvents/:id
 *
 * @generated from message perkup.v1.VenueEvent
 */
export class VenueEvent extends Message<VenueEvent> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string org_id = 2;
   */
  orgId = "";

  /**
   * The order_ids that are associated with this venue event
   *
   * @generated from field: repeated string order_ids = 3;
   */
  orderIds: string[] = [];

  /**
   * The individual who created the venue event
   *
   * @generated from field: string individual_id = 4;
   */
  individualId = "";

  /**
   * @generated from field: perkup.v1.VenueEvent.Info info = 5;
   */
  info?: VenueEvent_Info;

  /**
   * @generated from field: perkup.v1.VenueEvent.Status status = 6;
   */
  status = VenueEvent_Status.UNSPECIFIED;

  /**
   * @generated from field: google.protobuf.Timestamp created_at = 7;
   */
  createdAt?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp updated_at = 8;
   */
  updatedAt?: Timestamp;

  constructor(data?: PartialMessage<VenueEvent>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "perkup.v1.VenueEvent";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "org_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "order_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 4, name: "individual_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "info", kind: "message", T: VenueEvent_Info },
    { no: 6, name: "status", kind: "enum", T: proto3.getEnumType(VenueEvent_Status) },
    { no: 7, name: "created_at", kind: "message", T: Timestamp },
    { no: 8, name: "updated_at", kind: "message", T: Timestamp },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): VenueEvent {
    return new VenueEvent().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): VenueEvent {
    return new VenueEvent().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): VenueEvent {
    return new VenueEvent().fromJsonString(jsonString, options);
  }

  static equals(a: VenueEvent | PlainMessage<VenueEvent> | undefined, b: VenueEvent | PlainMessage<VenueEvent> | undefined): boolean {
    return proto3.util.equals(VenueEvent, a, b);
  }
}

/**
 * @generated from enum perkup.v1.VenueEvent.Status
 */
export enum VenueEvent_Status {
  /**
   * @generated from enum value: STATUS_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,
}
// Retrieve enum metadata with: proto3.getEnumType(VenueEvent_Status)
proto3.util.setEnumType(VenueEvent_Status, "perkup.v1.VenueEvent.Status", [
  { no: 0, name: "STATUS_UNSPECIFIED" },
]);

/**
 * @generated from message perkup.v1.VenueEvent.Info
 */
export class VenueEvent_Info extends Message<VenueEvent_Info> {
  /**
   * @generated from field: string event_name = 1;
   */
  eventName = "";

  /**
   * @generated from field: optional string venue_details = 2;
   */
  venueDetails?: string;

  /**
   * @generated from field: perkup.v1.VenueEvent.Info.DeliveryWindow delivery_window = 3;
   */
  deliveryWindow?: VenueEvent_Info_DeliveryWindow;

  /**
   * @generated from field: perkup.v1.VenueEvent.Info.ShippingLabel shipping_label = 4;
   */
  shippingLabel?: VenueEvent_Info_ShippingLabel;

  /**
   * @generated from field: bool requested_return_label = 5;
   */
  requestedReturnLabel = false;

  /**
   * The venue_id that is associated with this venue event
   * used only internally during the creation of the venue event
   *
   * @generated from field: optional string venue_id = 6;
   */
  venueId?: string;

  constructor(data?: PartialMessage<VenueEvent_Info>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "perkup.v1.VenueEvent.Info";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "event_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "venue_details", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 3, name: "delivery_window", kind: "message", T: VenueEvent_Info_DeliveryWindow },
    { no: 4, name: "shipping_label", kind: "message", T: VenueEvent_Info_ShippingLabel },
    { no: 5, name: "requested_return_label", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 6, name: "venue_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): VenueEvent_Info {
    return new VenueEvent_Info().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): VenueEvent_Info {
    return new VenueEvent_Info().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): VenueEvent_Info {
    return new VenueEvent_Info().fromJsonString(jsonString, options);
  }

  static equals(a: VenueEvent_Info | PlainMessage<VenueEvent_Info> | undefined, b: VenueEvent_Info | PlainMessage<VenueEvent_Info> | undefined): boolean {
    return proto3.util.equals(VenueEvent_Info, a, b);
  }
}

/**
 * @generated from message perkup.v1.VenueEvent.Info.DeliveryWindow
 */
export class VenueEvent_Info_DeliveryWindow extends Message<VenueEvent_Info_DeliveryWindow> {
  /**
   * @generated from field: google.protobuf.Timestamp from = 1;
   */
  from?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp to = 2;
   */
  to?: Timestamp;

  constructor(data?: PartialMessage<VenueEvent_Info_DeliveryWindow>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "perkup.v1.VenueEvent.Info.DeliveryWindow";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "from", kind: "message", T: Timestamp },
    { no: 2, name: "to", kind: "message", T: Timestamp },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): VenueEvent_Info_DeliveryWindow {
    return new VenueEvent_Info_DeliveryWindow().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): VenueEvent_Info_DeliveryWindow {
    return new VenueEvent_Info_DeliveryWindow().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): VenueEvent_Info_DeliveryWindow {
    return new VenueEvent_Info_DeliveryWindow().fromJsonString(jsonString, options);
  }

  static equals(a: VenueEvent_Info_DeliveryWindow | PlainMessage<VenueEvent_Info_DeliveryWindow> | undefined, b: VenueEvent_Info_DeliveryWindow | PlainMessage<VenueEvent_Info_DeliveryWindow> | undefined): boolean {
    return proto3.util.equals(VenueEvent_Info_DeliveryWindow, a, b);
  }
}

/**
 * @generated from message perkup.v1.VenueEvent.Info.ShippingLabel
 */
export class VenueEvent_Info_ShippingLabel extends Message<VenueEvent_Info_ShippingLabel> {
  /**
   * @generated from field: string url = 1;
   */
  url = "";

  constructor(data?: PartialMessage<VenueEvent_Info_ShippingLabel>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "perkup.v1.VenueEvent.Info.ShippingLabel";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): VenueEvent_Info_ShippingLabel {
    return new VenueEvent_Info_ShippingLabel().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): VenueEvent_Info_ShippingLabel {
    return new VenueEvent_Info_ShippingLabel().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): VenueEvent_Info_ShippingLabel {
    return new VenueEvent_Info_ShippingLabel().fromJsonString(jsonString, options);
  }

  static equals(a: VenueEvent_Info_ShippingLabel | PlainMessage<VenueEvent_Info_ShippingLabel> | undefined, b: VenueEvent_Info_ShippingLabel | PlainMessage<VenueEvent_Info_ShippingLabel> | undefined): boolean {
    return proto3.util.equals(VenueEvent_Info_ShippingLabel, a, b);
  }
}

