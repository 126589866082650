import { EditOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import { updateProgram } from 'api/databaseCalls'
import { store } from 'assets/icons'
import { CategorySelection } from 'components/Forms/CategorySelection'
import { Toggle } from 'components/Toggle'
import { CatContext } from 'context'
import { Heading, Image, Pane, Text, toaster } from 'evergreen-ui'
import { Program } from 'gen/perkup/v1/program_pb'
import { isFunction } from 'lodash-es'
import ProgramCategoriesList from 'pages/PerkProgram/ProgramCategories'
import { useContext } from 'react'

export function ProgramCategories({
  program,
  orgId,
  edit,
  approvedCategories,
  setApprovedCategories,
  allMerchants,
  setAllMerchants,
  showEditIcon = false,
  setEdit,
}: {
  program: Program
  orgId: string
  edit: boolean
  approvedCategories: string[]
  setApprovedCategories: (approvedCategories: string[]) => void
  allMerchants: boolean
  setAllMerchants: (allMerchants: boolean) => void
  showEditIcon?: boolean
  setEdit?: (edit: boolean) => void
}) {
  const cats = useContext(CatContext)

  if (!program?.id || !orgId) return null

  const allowEditing = showEditIcon && isFunction(setEdit)

  const handleCategoryChange = (categories: string[]) => {
    setAllMerchants(false)
    setApprovedCategories(categories)
  }

  const handleAllMerchantsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAllMerchants(e.target.checked)
    if (e.target.checked) {
      setApprovedCategories(cats?.map(cat => cat?.name))
    }
  }

  const handleCancel = () => {
    if (isFunction(setEdit)) setEdit(false)
    setApprovedCategories(program.approvedCategories)
    setAllMerchants(!!program.allMerchants)
  }

  const handleSubmit = () => {
    updateProgram({
      orgId,
      programId: program.id,
      program: {
        approvedCategories,
        allMerchants: allMerchants ?? false,
      },
    })
    toaster.success('Program categories updated')
    if (isFunction(setEdit)) setEdit(false)
  }

  if (edit) {
    return (
      <>
        <Heading size={100} marginBottom={16}>
          Approved categories
        </Heading>
        <Pane
          display="flex "
          flexDirection="column"
          gap={8}
          width="100%"
          maxWidth={1152}
        >
          <Toggle
            checked={allMerchants}
            label="Open program to all merchants"
            onChange={handleAllMerchantsChange}
          />
          <CategorySelection
            approvedCategories={approvedCategories}
            handleChange={handleCategoryChange}
          />
        </Pane>
        {allowEditing && (
          <Pane marginTop={16} display="flex" gap={8}>
            <Button onClick={handleCancel}>Cancel</Button>
            <Button onClick={handleSubmit} type="primary">
              Save
            </Button>
          </Pane>
        )}
      </>
    )
  }

  if (allowEditing) {
    return (
      <Pane display="flex" flexDirection="column" gap={8}>
        <Pane display="flex" alignItems="center" gap={8}>
          <Heading size={100}>APPROVED CATEGORIES</Heading>

          <Button
            type="text"
            style={{ display: 'flex', alignItems: 'center' }}
            icon={<EditOutlined />}
            onClick={() => setEdit(true)}
          >
            Edit categories
          </Button>
        </Pane>
        {allMerchants ? (
          <Pane display="flex" gap={8} alignItems="center">
            <Image width={24} src={store} />
            <Text>All merchants are approved</Text>
          </Pane>
        ) : (
          <ProgramCategoriesList
            approvedCategories={program.approvedCategories}
          />
        )}
      </Pane>
    )
  }

  return (
    <>
      <Heading size={100}>APPROVED CATEGORIES</Heading>

      {allMerchants ? (
        <Pane display="flex" gap={8} alignItems="center">
          <Image width={24} src={store} />
          <Text>All merchants are approved</Text>
        </Pane>
      ) : (
        <ProgramCategoriesList
          approvedCategories={program.approvedCategories}
        />
      )}
    </>
  )
}
