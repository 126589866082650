import { cn } from '@repo/utils/ui'
import { callFunction } from 'api/functionCalls'
import { PerkLoader } from 'components'
import {
  AURIGMA_STOREFRONT_ID,
  AURIGMA_TENANT_ID,
} from 'constants/customer-canvas'
import { DEFAULT_ROUTES } from 'constants/routes'
import { toaster } from 'evergreen-ui'
import useIds from 'hooks/useIds'
import { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router'
import { ProductLink } from 'types'

function useInitAurigma(token: string | null, productLink: ProductLink) {
  const iframeRef = useRef<HTMLIFrameElement | null>(null)
  const [selectedOptions, setSelectedOptions] = useState<
    { name: string; values: string[] }[]
  >([])
  const [aurigmaOwnerId, setAurigmaOwnerId] = useState<string>('')
  const { orgId, individualId } = useIds()
  const navigate = useNavigate()

  useEffect(() => {
    if (!token) return () => {}
    if (!iframeRef.current) return () => {}

    const iframe = iframeRef.current

    const handleMessages = (event: MessageEvent) => {
      switch (event.data.type) {
        case 'aurigma-addtocart':
          callFunction('aurigma-HandleCreateProduct', {
            ...event.data.payload,
            productId: productLink.storefrontProductId, // temp hardcode productId
            aurigmaProductId: productLink.productId,
            sku: '3319645_10798', // temp hardcode sku
            options: selectedOptions,
            orgId,
            aurigmaOwnerId,
            creatorId: individualId,
          })
            .then((data: any) => {
              if (!data?.newProduct?.id) {
                toaster.danger('Error creating product')
              } else {
                navigate(
                  `${DEFAULT_ROUTES.ORGANIZATION.SWAG.PRODUCTS}/${data.newProduct.id}`
                )
              }
            })
            .catch(error => {
              console.error('error creating canvas product', error)
              toaster.danger('Error creating product')
            })

          break
        case 'aurigma-setcurrentvariant':
          interface Option {
            productOptionId: number
            productOptionTitle: string
            productOptionTraits: any[]
            productOptionType: string
            productOptionValueId: number
            productOptionValueTitle: string
          }
          setSelectedOptions(
            event.data.payload.options.map((option: Option) => ({
              name: option.productOptionTitle,
              values: [option.productOptionValueTitle],
            }))
          )
          break
        case 'aurigma-setOwnerId':
          setAurigmaOwnerId(event.data.payload)
          break
        default:
          break
      }
    }

    const {
      productId,
      productFilterId,
      productVersionId,
      id,
      storefrontProductId,
    } = productLink

    iframe.contentWindow?.postMessage(
      {
        type: 'aurigma-init',
        payload: {
          configVersion: 2,
          component: 'handy-editor',
          input: {
            productLinkId: id,
            storefrontProductId,
            productId,
            productFilterId,
            productVersionId,
          },
          integration: {
            tenantId: AURIGMA_TENANT_ID,
            storefrontId: AURIGMA_STOREFRONT_ID,
            cchubApiGatewayUrl: 'https://api.customerscanvashub.com/',
            user: {
              id: `${orgId}-${individualId}`,
              token,
            },
          },
          settings: {},
          resources: {},
        },
      },
      '*'
    )

    window.addEventListener('message', handleMessages)

    return () => {
      window.removeEventListener('message', handleMessages)
    }
  }, [
    aurigmaOwnerId,
    individualId,
    navigate,
    orgId,
    productLink,
    selectedOptions,
    token,
  ])

  return { iframeRef }
}

export function CustomerCanvasFrame({
  productLink,
}: {
  productLink: ProductLink
}) {
  const [backOfficeToken, setBackOfficeToken] = useState<string | null>(null)
  const { iframeRef } = useInitAurigma(backOfficeToken, productLink)

  useEffect(() => {
    callFunction('aurigma-GetBackOfficeToken', {}).then(setBackOfficeToken)
  }, [])

  return (
    <section className="w-full h-full">
      {!backOfficeToken && <PerkLoader />}
      <iframe
        ref={iframeRef}
        className={cn('w-full h-full', backOfficeToken ? '' : 'hidden')}
        src="/product_customizer"
        title="product customizer"
      />
    </section>
  )
}
