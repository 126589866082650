import { PerkImage } from 'components'
import { Card, Pane, useTheme } from 'evergreen-ui'
import { DefaultBackgroundImageBanner } from './DefaultBackgroundImageBanner'

/**
 *
 * @deprecated
 */
export function BoxShadowImage({
  src,
  sizes,
}: {
  src?: string
  sizes: string
}) {
  const theme = useTheme()

  const childCardWidth = src ? 'fit-content' : '100%'
  const parentCardWidth = src ? 'fit-content' : '95%'
  const cardHeight = src ? 'fit-content' : '100%'

  return (
    <Card
      boxShadow={`16px 16px 0px 0px ${theme.colors.gray100}`}
      marginBottom={16}
      marginRight={16}
      width={parentCardWidth}
      height={cardHeight}
    >
      <Card
        overflow="hidden"
        elevation={3}
        width={childCardWidth}
        height="100%"
      >
        {src && (
          <PerkImage
            src={src}
            width="100%"
            style={{ objectFit: 'contain', maxHeight: '40vh' }}
            sizes={sizes}
          />
        )}
        {!src && (
          <Pane width="100%" maxHeight="40vh" height="100%">
            <DefaultBackgroundImageBanner />
          </Pane>
        )}
      </Card>
    </Card>
  )
}
