import { SelectGiftCard } from 'features'
import { useSelectRewardContext } from '../SelectRewardContext'

export function CardsForm2() {
  const { selectedReward, giftCardForm } = useSelectRewardContext()
  return (
    <SelectGiftCard
      currentGift={selectedReward?.gift}
      onSelectGiftCard={giftCardForm.handleSelectGiftCard}
    />
  )
}
