import { CheckOutlined, CloseOutlined } from '@ant-design/icons'
import { Flex, Switch } from 'antd'
import { Text } from 'evergreen-ui'

export function SwitchAndText({
  checked,
  onCheck,
  label,
}: {
  checked: boolean
  onCheck: (checked: boolean) => void
  label: string
}) {
  return (
    <Flex gap={8}>
      <Switch
        unCheckedChildren={<CloseOutlined />}
        checkedChildren={<CheckOutlined />}
        checked={checked}
        onChange={onCheck}
      />
      <Text>{label}</Text>
    </Flex>
  )
}
