import { PlacidTags } from 'constants/placid/placid-tags'
import { UseCaseRecord } from 'pages/NewReward/types/forms'
import { CategoryTitle } from 'pages/Templates/constants'
import { RewardUseCasesKeysType } from './RewardUseCasesKeys'

export const customersUseCases = {
  NEW_CUSTOMER: {
    label: 'New customer',
    details: {
      searchQuery: 'welcome',
      templateCategories: [CategoryTitle.PERFORMANCE],
      placidTags: [PlacidTags.ACCOUNT_RENEWAL, PlacidTags.CUSTOMER_RETENTION],
    },
    emailData: [
      {
        title: 'Thank You for Choosing Us',
        note: 'We’re thrilled to have you on board! Enjoy this little gift as a token of our appreciation.',
      },
      {
        title: 'Welcome Aboard!',
        note: 'Welcome to our family! Here’s a small gift to say thank you for joining us.',
      },
      {
        title: 'A Warm Welcome!',
        note: 'We’re excited to start this journey with you! Enjoy this gift as a sign of our commitment to your success.',
      },
      {
        title: 'Cheers to New Beginnings!',
        note: 'We’re excited to start this journey with you! Enjoy this gift as a sign of our commitment to your success.',
      },
      {
        title: 'We Appreciate You!',
        note: 'Thank you for being part of our community! Enjoy this gift as a gesture of our gratitude.',
      },
    ],
  },
  ACCOUNT_EXPANSION: {
    label: 'Account expansion',
    details: {
      searchQuery: 'thank you',
      templateCategories: [],
      placidTags: [PlacidTags.ACCOUNT_EXPANSION],
    },
    emailData: [
      {
        title: 'Exciting Times Ahead!',
        note: 'Thank you for expanding your partnership with us! Here’s a gift to celebrate this exciting new chapter together.',
      },
      {
        title: 'Cheers to Growth!',
        note: 'We appreciate your continued trust in us! Enjoy this gift as we embark on this expanded journey together.',
      },
      {
        title: 'Welcome to the Next Level!',
        note: ' Thank you for choosing to grow with us! Here’s a little something to show our appreciation for your expansion.',
      },
      {
        title: 'Celebrating Our Partnership!',
        note: 'We’re so excited about our future together! Enjoy this gift as a token of our gratitude and commitment to your success.',
      },
      {
        title: 'Here’s to New Opportunities!',
        note: 'Thank you for taking this step with us! We’re excited about the possibilities ahead and hope you enjoy this special gift.',
      },
    ],
  },
  CUSTOMER_RETENTION: {
    label: 'Customer retention',
    details: {
      searchQuery: 'celebrate',
      templateCategories: [],
      placidTags: [PlacidTags.CUSTOMER_RETENTION, PlacidTags.ACCOUNT_RENEWAL],
    },
    emailData: [
      {
        title: 'Thank You for Being with Us!',
        note: 'We truly value your loyalty! Here’s a little gift to show our appreciation for your continued support.',
      },
      {
        title: 'We Appreciate You!',
        note: 'Your trust means everything to us! Enjoy this special gift as a gesture of our gratitude for being a valued partner.',
      },
      {
        title: 'A Token of Our Appreciation!',
        note: 'Thank you for your continued partnership! We hope this gift brings you joy and shows how much we value our relationship.',
      },
      {
        title: 'Celebrating Our Journey Together!',
        note: 'We’re grateful to have you with us! Here’s a little something to express our thanks for being a part of our community.',
      },
    ],
  },
  ACCOUNT_RENEWAL: {
    label: 'Account renewal',
    details: {
      searchQuery: 'thank you',
      templateCategories: [],
      placidTags: [PlacidTags.ACCOUNT_RENEWAL],
    },
    emailData: [
      {
        title: 'We Appreciate You!',
        note: 'Thank you for being a valued member of our community. Your support means the world to us!',
      },
      {
        title: 'Cheers to You!',
        note: 'Here’s a little something to celebrate your continued journey with us. Enjoy!',
      },
      {
        title: 'Grateful for Your Trust!',
        note: 'Your trust in us inspires us every day. We’re excited to continue serving you!',
      },
      {
        title: 'You’re Amazing!',
        note: 'Thank you for sticking with us! We’re thrilled to have you on board.',
      },
      {
        title: 'Celebrating You!',
        note: 'We’re celebrating your continued partnership with us! Enjoy this special surprise.',
      },
    ],
  },
  POSITIVE_FEEDBACK: {
    label: 'Positive feedback',
    details: {
      searchQuery: 'appreciation',
      templateCategories: [],
      placidTags: [PlacidTags.POSITIVE_FEEDBACK],
    },
    emailData: [
      {
        title: 'Your Voice Matters!',
        note: 'Thank you for sharing your thoughts with us! Your feedback helps us grow and improve.',
      },
      {
        title: 'We Appreciate Your Insight!',
        note: 'Your positive feedback inspires our team! We’re grateful for your support and encouragement.',
      },
      {
        title: 'Thanks for the Kind Words!',
        note: 'Your feedback brightened our day! We’re thrilled to know you’re enjoying your experience with us.',
      },
      {
        title: 'You Make Us Better!',
        note: 'Thank you for taking the time to share your experience. Your insights are invaluable to us!',
      },
      {
        title: 'Grateful for Your Support!',
        note: 'We truly appreciate your feedback! It motivates us to keep delivering the best possible service.',
      },
    ],
  },
  NEGATIVE_FEEDBACK: {
    label: 'Negative feedback',
    details: {
      searchQuery: 'thank you',
      templateCategories: [],
      placidTags: [PlacidTags.NEGATIVE_FEEDBACK],
    },
    emailData: [
      {
        title: 'We’re Listening',
        note: 'Thank you for sharing your concerns with us. We’re committed to improving, and we hope this small gift brightens your day as a token of our appreciation.',
      },
      {
        title: 'Our Apologies',
        note: 'We’re sorry to hear about your experience. Here’s a small gift to express our gratitude for your honesty.',
      },
      {
        title: 'Thank You for Your Feedback',
        note: 'Your insights are invaluable, even when they’re tough to hear. We hope this gift serves as a reminder that we’re dedicated to making things right.',
      },
      {
        title: 'We Value Your Voice',
        note: 'Thank you for bringing this to our attention. We’re grateful for your feedback and hope this thoughtful gift conveys our commitment to improvement.',
      },
      {
        title: 'Sincere Apologies',
        note: 'We regret that we didn’t meet your expectations. Please accept this gift as a gesture of our appreciation for your candid feedback.',
      },
    ],
  },
  GREAT_REVIEW: {
    label: 'Great review',
    details: {
      searchQuery: 'thank you',
      templateCategories: [],
      placidTags: [PlacidTags.GREAT_REVIEW],
    },
    emailData: [
      {
        title: 'Your Kind Words Mean the World!',
        note: 'Thank you for your fantastic review! We’re thrilled to have your support and hope you enjoy this special gift as a token of our appreciation.',
      },
      {
        title: 'You Made Our Day!',
        note: 'Your glowing review brought smiles to our team! Here’s a little something to express our gratitude for your kind words.',
      },
      {
        title: 'We Appreciate Your Support!',
        note: 'Thank you for taking the time to share your positive experience! We hope this gift brightens your day as much as your review brightened ours.',
      },
      {
        title: 'Thanks for Spreading the Word!',
        note: 'Your wonderful review helps us grow and reach more amazing customers like you. Enjoy this gift as a small token of our appreciation!',
      },
      {
        title: 'Grateful for Your Feedback!',
        note: 'We’re so thankful for your encouraging review! Please accept this gift as a heartfelt thank you for being such a valued supporter of our journey.',
      },
    ],
  },
  MILESTONE_CELEBRATION: {
    label: 'Milestone celebration',
    details: {
      searchQuery: 'celebrate',
      templateCategories: [],
      placidTags: [PlacidTags.MILESTONE_CELEBRATION],
    },
    emailData: [
      {
        title: 'Cheers to Your Achievement!',
        note: 'Congratulations on reaching this significant milestone! We’re thrilled to celebrate with you and hope this gift adds a little extra joy to your day.',
      },
      {
        title: 'Happy Milestone Celebration!',
        note: 'We’re excited to commemorate this special moment in your journey with us! Enjoy this gift as a token of our appreciation for your continued partnership.',
      },
      {
        title: 'Here’s to Your Success!',
        note: 'Celebrating your milestone is a wonderful reminder of our shared journey. Please accept this gift as a heartfelt thank you for being such an important part of our community!',
      },
    ],
  },
  CUSTOMER_THANK_YOU: {
    label: 'Thank you',
    details: {
      searchQuery: 'thank you',
      templateCategories: [],
      placidTags: [PlacidTags.THANK_YOU],
    },
    emailData: [
      {
        title: 'We Appreciate You!',
        note: 'Thank you for choosing us! Your support means everything, and we hope this small gift expresses our gratitude for having you in our community.',
      },
      {
        title: 'Your Trust Matters!',
        note: 'We sincerely appreciate your trust in us. Thank you for being a valued customer; please enjoy this gift as a token of our appreciation!',
      },
      {
        title: 'Thanks for Your Loyalty!',
        note: 'Your continued support inspires us every day. We’re grateful for your loyalty and hope this gift brings a smile to your face!',
      },
      {
        title: 'Grateful for Your Feedback!',
        note: 'Thank you for sharing your thoughts with us! Your input helps us improve, and we hope this little gift shows how much we value your feedback.',
      },
      {
        title: 'You Make a Difference!',
        note: 'We want to express our heartfelt thanks for being with us. Your engagement truly makes a difference, and we hope this gift brightens your day!',
      },
    ],
  },
  REFERRAL: {
    label: 'Referral',
    details: {
      searchQuery: 'appreciation',
      templateCategories: [],
      placidTags: [PlacidTags.REFERRAL],
    },
    emailData: [
      {
        title: 'We Appreciate Your Trust!',
        note: 'Thank you for referring us to your friends! Your support means the world, and we hope this gift shows our gratitude for your trust.',
      },
      {
        title: 'Cheers to You 🥂',
        note: 'We’re thrilled to have you as a supporter! Thank you for spreading the word, and enjoy this gift as a token of our appreciation.',
      },
      {
        title: 'Your Referral Means Everything!',
        note: 'Thank you for recommending us! We’re grateful for your trust and hope this small gift conveys our appreciation.',
      },
      {
        title: 'Thanks for Sharing the Love!',
        note: 'Your referral is the highest compliment we can receive! Enjoy this gift as a thank you for helping us grow our community.',
      },
      {
        title: 'You’re a Star!',
        note: 'Thank you for being such a fantastic advocate for us! We appreciate your referral and hope this gift brings a little joy to your day.',
      },
    ],
  },
  CUSTOMER_APOLOGY: {
    label: 'Apology',
    details: {
      searchQuery: 'sorry',
      templateCategories: [],
      placidTags: [PlacidTags.APOLOGY],
    },
    emailData: [
      {
        title: 'We’re Truly Sorry',
        note: 'We regret that we didn’t meet your expectations. Please accept this gift as a heartfelt apology and a token of our commitment to making things right.',
      },
      {
        title: 'Our Apologies for the Inconvenience',
        note: 'We understand how frustrating this situation has been. We hope this small gift helps convey our genuine regret and appreciation for your patience.',
      },
      {
        title: 'Thank You for Your Understanding',
        note: 'We apologize for any trouble we caused and are grateful for your understanding. Here’s a gift to express our sincere apologies.',
      },
      {
        title: 'We Value Your Feedback',
        note: 'We’re sorry for the experience you had with us. Please accept this gift as a token of our appreciation for your feedback and our promise to improve.',
      },
      {
        title: 'Our Deepest Regrets',
        note: 'We truly apologize for the inconvenience you faced. We hope this gift serves as a small gesture of our commitment to providing you with better service in the future.',
      },
    ],
  },
} satisfies Partial<Record<RewardUseCasesKeysType, UseCaseRecord>>
