import { Flex, InputNumber, Tabs, TabsProps } from 'antd'
import { TemplateContext } from 'context'
import { Text, toaster } from 'evergreen-ui'
import { ProductCollection_ProductInfo } from 'gen/perkup/v1/product_collections_pb'
import { ProductVariant } from 'gen/perkup/v1/product_variant_pb'
import { Program_Gift } from 'gen/perkup/v1/program_pb'
import { useCollectionById } from 'hooks'
import useListAllProductVariantsByProductIds from 'hooks/productVariants/useListProductVariantsByProductIds'
import { keys } from 'lodash-es'
import { useContext, useState } from 'react'
import { AntdDollarFormatter, makePlural, numToDollars } from 'utils'

export type MaxGiftRedemption = 'Items' | 'Budget'

const isMaxGiftRedemption = (str: string): str is MaxGiftRedemption =>
  str === 'Items' || str === 'Budget'

const determineProductPriceRange = ({
  products,
  isCollectionGift,
  productVariants,
}: {
  products:
    | {
        [key: string]: ProductCollection_ProductInfo
      }
    | undefined
  isCollectionGift: boolean
  productVariants: ProductVariant[]
}) => {
  let lowestProductAmount: number = Number.MAX_SAFE_INTEGER
  let highestProductAmount: number = 0
  if (isCollectionGift) {
    const productKeys = keys(products)
    productKeys.forEach(key => {
      if (!products) return
      if (products[key].minAmount < lowestProductAmount) {
        lowestProductAmount = Number(products[key].minAmount)
      }
      if (products[key].maxAmount > highestProductAmount) {
        highestProductAmount = Number(products[key].maxAmount)
      }
    })
  } else {
    productVariants.forEach(variant => {
      if (!variant?.amount) return
      if (variant.amount < lowestProductAmount) {
        lowestProductAmount = Number(variant.amount)
      }
      if (variant.amount > highestProductAmount) {
        highestProductAmount = Number(variant.amount)
      }
    })
  }

  return { lowestProductAmount, highestProductAmount }
}

export function MaxQuantityForm({
  currentGift,
  budget,
  onRedeemableQuantityChange,
  onBudgetChange,
}: {
  currentGift: Program_Gift | undefined
  budget: number
  onRedeemableQuantityChange: (quantity: number) => void
  onBudgetChange: (budget: number) => void
}) {
  const template = useContext(TemplateContext)
  const templateMaxAmount = template?.budget?.maxAmount
  const currentReddemableQuantity = currentGift?.redeemableQuantity
  const currentBudget = template?.budget?.maxAmount || budget
  const userDoesNotWantReedemableQuantityForGift =
    !currentReddemableQuantity && currentGift

  const hasMultipleProducts = (currentGift?.productIds ?? []).length > 1

  const isCollectionGift = !!currentGift?.productCollectionId

  const [newBudgetAmount, setNewBudgetAmount] = useState<number | null>(
    currentBudget
  )
  const [newRedeemableQuantity, setNewRedeemableQuantity] = useState<
    number | null
  >(currentReddemableQuantity || 1)

  const { productVariants } = useListAllProductVariantsByProductIds({
    productIds: currentGift?.productIds || [],
  })
  const { products } = useCollectionById({
    id: currentGift?.productCollectionId,
  })

  const shouldLockInputs =
    !currentGift ||
    (!!templateMaxAmount && isCollectionGift) ||
    (!hasMultipleProducts && !isCollectionGift)

  const shouldStartWithBudgetTab =
    userDoesNotWantReedemableQuantityForGift ||
    currentReddemableQuantity === undefined

  const { lowestProductAmount, highestProductAmount } =
    determineProductPriceRange({
      products,
      isCollectionGift,
      productVariants,
    })

  const maxRedeemableQuantity = isCollectionGift
    ? keys(products).length || 0
    : currentGift?.productIds?.length || 0

  const minBudgetAmount = isCollectionGift
    ? lowestProductAmount
    : highestProductAmount

  const desiredBudgetIsInvalid =
    !newBudgetAmount || newBudgetAmount < minBudgetAmount
  const desiredQuantityIsInvalid =
    !newRedeemableQuantity || newRedeemableQuantity > maxRedeemableQuantity

  const handleTabChange = (value: string) => {
    if (!isMaxGiftRedemption(value)) return
    if (value === 'Items' && currentGift) {
      setNewRedeemableQuantity(maxRedeemableQuantity)
      onRedeemableQuantityChange(maxRedeemableQuantity)
    } else if (value === 'Budget' && currentGift) {
      setNewBudgetAmount(highestProductAmount)
      onBudgetChange(highestProductAmount)
    }
  }

  const handleConfirmRedeemableQuanity = () => {
    if (currentReddemableQuantity === newRedeemableQuantity) return // If no change, don't trigger the callback

    if (desiredQuantityIsInvalid) {
      toaster.warning(
        `Please enter a number greater than 0 and less than ${maxRedeemableQuantity + 1}`
      )
      return
    }
    onRedeemableQuantityChange(newRedeemableQuantity)
    toaster.success('Quantity updated')
  }

  const handleConfirmBudget = () => {
    if (newBudgetAmount === budget) return // If no change, don't trigger the callback

    if (desiredBudgetIsInvalid) {
      toaster.warning(
        `Budget must be greater than the ${
          isCollectionGift ? 'least' : 'most'
        } expensive item (${numToDollars(minBudgetAmount)})`
      )
      return
    }

    onBudgetChange(newBudgetAmount)
    toaster.success('Budget updated')
  }

  const items: TabsProps['items'] = [
    {
      key: 'Items',
      label: 'Items',
      children: (
        <Flex
          vertical
          gap={16}
          style={{
            width: '100%',
          }}
        >
          <Text>Set the number of items each recipient can select:</Text>
          <InputNumber
            disabled={shouldLockInputs}
            style={{ width: 160 }}
            min={1}
            addonAfter={makePlural('item', newRedeemableQuantity ?? undefined)}
            status={
              desiredQuantityIsInvalid && !shouldLockInputs
                ? 'error'
                : undefined
            }
            value={newRedeemableQuantity}
            onChange={setNewRedeemableQuantity}
            onBlur={handleConfirmRedeemableQuanity}
            onPressEnter={handleConfirmRedeemableQuanity}
          />
        </Flex>
      ),
    },
    {
      key: 'Budget',
      label: 'Budget',
      disabled: shouldLockInputs && !shouldStartWithBudgetTab,
      children: (
        <Flex
          vertical
          gap={16}
          style={{
            width: '100%',
          }}
        >
          <Text>Set a budget limit for each recipient:</Text>
          <InputNumber
            {...AntdDollarFormatter}
            disabled={!currentGift || !!templateMaxAmount}
            style={{ width: 100 }}
            // min={minBudgetAmount / 100} // In this scenario, setting a min will actually hinder the UX because the blur handlers will never trigger nor the error statuses
            status={
              desiredBudgetIsInvalid && (!currentGift || !!templateMaxAmount)
                ? 'error'
                : undefined
            }
            value={newBudgetAmount ? newBudgetAmount / 100 : null}
            onChange={value => setNewBudgetAmount(value ? value * 100 : null)}
            onBlur={handleConfirmBudget}
            onPressEnter={handleConfirmBudget}
          />
        </Flex>
      ),
    },
  ]

  return (
    <Flex
      vertical
      gap={16}
      style={{
        width: '100%',
      }}
    >
      <Tabs
        onChange={handleTabChange}
        defaultActiveKey={shouldStartWithBudgetTab ? 'Budget' : 'Items'}
        items={items}
      />
    </Flex>
  )
}
