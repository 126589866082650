import { captureException } from '@sentry/react'
import { callFunction } from 'api/functionCalls'
import { MEMBER_ADDED } from 'constants/events'
import { DEFAULT_LOGO, DEFAULT_PROGRAM_BANNER } from 'constants/programs'
import { getAuth } from 'firebase/auth'
import { Individuals } from 'gen/perkup/v1/individual_connect'
import { Individual } from 'gen/perkup/v1/individual_pb'
import {
  Organization,
  Organization_SubscriptionStatus,
} from 'gen/perkup/v1/organization_pb'
import { Program, ProgramType } from 'gen/perkup/v1/program_pb'
import { createClient } from 'hooks/useClient'
import { logEvent, toSentry } from 'utils'
import { messageToFirestore } from 'utils/firestore'

export async function getIndividualIds({
  labels,
  statuses,
}: {
  labels?: { [key: string]: string }
  statuses: string[]
}) {
  try {
    const accessToken = await getAuth().currentUser?.getIdToken()
    const client = createClient(Individuals)
    return await client.listIndividualIds(
      { labels, statuses },
      { headers: { Authorization: `Bearer ${accessToken}` } }
    )
  } catch (error) {
    console.error(error)
    captureException(toSentry(error), {
      contexts: {
        getIndividualIds: {
          labels,
          error,
        },
      },
    })
    throw error
  }
}

export async function addIndividualToProgram({
  individual,
  sendAtSeconds,
  org,
  program,
  defaultColor,
}: {
  individual: Individual
  sendAtSeconds?: number
  org: Organization
  program: Program
  defaultColor: string
}) {
  const individualId = individual.id

  try {
    if (sendAtSeconds) {
      await callFunction('firestore-SchedulePeopleIdsToProgram', {
        orgId: org.id,
        programId: program.id,
        selectedIds: [individualId],
        type: ProgramType[program.type],
        sendAtTimestamp: Number(sendAtSeconds), // Convert to number, because can't pass bigint
      })
    } else {
      await callFunction('firestore-CreateMember', {
        orgId: org.id,
        program: program?.toJson(),
        memberId: individual.id,
      })

      const logo = org?.logoUrl || DEFAULT_LOGO
      const banner = program?.email?.banner || DEFAULT_PROGRAM_BANNER
      const programId = program?.id
      const programName = program?.name
      const programNote = program?.note || 'Thank you for everything you do!'
      const fromName = program?.email?.fromName || org.name

      const sendBulkDetails = {
        orgId: org.id,
        programId,
        logo,
        banner,
        title: programName || 'A reward for you 🎉',
        programName,
        programNote,
        fromName,
        color: defaultColor,
      }

      await callFunction('mailjet-SendBulk', {
        ...sendBulkDetails,
        peopleIds: [individualId],
      })
    }

    const programObj = program ? messageToFirestore(Program, program) : {}
    logEvent(MEMBER_ADDED, {
      ...programObj,
      orgId: org.id,
      orgSubscriptionStatus: org?.subscriptionStatus
        ? Organization_SubscriptionStatus[org.subscriptionStatus]
        : null,
      userId: individual.userId,
    })
  } catch (error) {
    console.error(error)
    if (error instanceof Error)
      captureException(toSentry(error), {
        contexts: {
          CreateMember: {
            orgId: org.id,
            programId: program?.id,
            memberId: individualId,
          },
        },
      })
  }
}
