import { GetCountryById } from 'api/databaseCalls'
import { findFlagUrlByIso2Code } from 'country-flags-svg'
import { Avatar, Pane, useTheme } from 'evergreen-ui'
import { useEffect, useState } from 'react'
import { getCountryIsoAlpha2 } from 'utils'

export interface IUserAvatar {
  name?: string
  email: string
  profilePicture?: string
  countryId?: string
  flagSize?: number
  pfpSize?: number
}

export function UserAvatar({
  countryId,
  profilePicture,
  name,
  email,
  flagSize = 18,
  pfpSize = 48,
}: IUserAvatar) {
  const [userCountryFlag, setUserCountryFlag] = useState<string>('')
  const hasCountryFlag = countryId && userCountryFlag.length > 1
  const theme = useTheme()

  useEffect(() => {
    if (countryId) {
      GetCountryById({ countryId }).then(country => {
        if (country && country.iso3) {
          const flag = findFlagUrlByIso2Code(getCountryIsoAlpha2(country.iso3))
          setUserCountryFlag(flag)
        }
      })
    }
  }, [countryId])
  const onlineBorderSize = 2

  return (
    <Pane display="flex" height="min-content">
      <Pane
        borderRadius="100%"
        height={pfpSize + onlineBorderSize * 2}
        marginRight={hasCountryFlag ? -flagSize : 0}
      >
        <Avatar src={profilePicture} name={name || email} size={pfpSize} />
      </Pane>

      {hasCountryFlag && (
        <Avatar
          src={userCountryFlag}
          alignSelf="end"
          size={flagSize}
          border={`1.5px solid ${theme.colors.gray75}`}
        />
      )}
    </Pane>
  )
}
