import { PartialMessage, Timestamp } from '@bufbuild/protobuf'
import { captureException } from '@sentry/react'
import { Alert, Button, Flex, InputNumber, message, Radio, Select } from 'antd'
import { createPurchaseOrder } from 'api/databaseCalls/writes/purchaseOrders'
import { callFunction } from 'api/functionCalls'
import {
  AccountBalanceForm,
  BackIconButton,
  CreditCardsLogoGroup,
  LineItemsPayment,
  PerkLoader,
} from 'components'
import { BULK_ORDER_PLACED } from 'constants/events'
import { DEFAULT_ROUTES } from 'constants/routes'
import { IndividualContext, OrgContext, UserContext } from 'context'
import { Heading, Pane, Strong, Text } from 'evergreen-ui'
import { Account } from 'gen/perkup/v1/account_pb'
import {
  PurchaseOrder,
  PurchaseOrder_Item,
  PurchaseOrder_PaymentInfo,
  PurchaseOrder_PaymentMethod,
} from 'gen/perkup/v1/purchase_order_pb'
import { useSendableAccounts } from 'hooks'
import { round } from 'lodash-es'
import { useContext, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useNavigate, useParams } from 'react-router'
import { createOrderInvoice } from 'services'
import { adjustFunds } from 'services/accounts'
import { SageFullProductDetail } from 'types'
import { logEvent, numToDollars, toSentry } from 'utils'

/**
 *
 * We need to refactor this component to be more DRY - it's too big and has too many responsibilities. Also, we want to stop using panes and hittint api's in components.
 */
export function OrgSwagSearchProduct() {
  const { customerId, id: orgId } = useContext(OrgContext)
  const { id: individualId, email } = useContext(IndividualContext)
  const { id: userId } = useContext(UserContext)
  const { prodEId } = useParams()
  const [product, setProduct] = useState<any>(null) // Todo: Either create a separate type for this or use the full product detail type
  const [mainImage, setMainImage] = useState<any>(null)
  const [loading, setLoading] = useState(true)
  const [quantity, setQuantity] = useState(50)
  const [selectedImprintMethod, setSelectedImprintMethod] = useState('')
  const [selectedImprintLocation, setSelectedImprintLocation] = useState('')
  const [selectedColor, setSelectedColor] = useState('')
  const navigate = useNavigate()
  const [isLoadingPlaceOrder, setIsLoadingPlaceOrder] = useState(false)
  const [messageApi, contextHolder] = message.useMessage()

  enum PaymentMethod {
    CREDIT_CARD = 'Credit Card',
    ORG_BALANCE = 'Organization Balance',
    INVOICE = 'Invoice',
  }

  const [paymentMethod, setPaymentMethod] = useState<PaymentMethod>(
    PaymentMethod.ORG_BALANCE
  )
  const [selectedOrgBalanceAccount, setSelectedOrgBalanceAccount] =
    useState<Account>()

  const [fullProductDetail, setFullProductDetail] =
    useState<SageFullProductDetail | null>(null)

  const { sendableAccounts, hasLoadedAccounts } = useSendableAccounts()

  useEffect(() => {
    setLoading(true)
    // Get basic product details
    callFunction('sage-BasicProductDetail', {
      prodEId,
    }).then(result => {
      if (result?.product) {
        setProduct(result.product)
        if (result.product.pics?.[0]) {
          setMainImage(result.product.pics[0])
        }
        // Set default values
        if (result.product.colors) {
          const firstColor = result.product.colors.split(',')[0].trim()
          setSelectedColor(firstColor)
        }
      }
    })

    // Get full product details
    callFunction('sage-FullProductDetail', {
      prodEId,
    })
      .then(result => {
        if (result?.product) {
          setFullProductDetail(result.product)
          // Set default values
          if (result.product.decorationMethod) {
            const firstMethod = result.product.decorationMethod
              .split(',')[0]
              .trim()
            setSelectedImprintMethod(firstMethod)
          }
          if (result.product.imprintLoc) {
            setSelectedImprintLocation(result.product.imprintLoc)
          }
        }
      })
      .finally(() => {
        setLoading(false)
      })
  }, [prodEId])

  const mainImageCaption = mainImage?.caption || product?.pics?.[0]?.caption

  const getPriceForQuantity = (qty: number) => {
    if (!product?.qty || !product?.prc) return 0

    // Find the first quantity tier that is less than or equal to the requested quantity
    const priceIndex = product.qty
      .map((q: string) => Number(q))
      .filter((q: number) => q <= qty)
      .reduce(
        (maxIndex: number, q: number, i: number, arr: number[]) =>
          q === Math.max(...arr) ? i : maxIndex,
        0
      )

    // If no tier found, use first price tier
    // If tier found, use that tier's price
    return round(priceIndex >= 0 ? product.prc[priceIndex] : product.prc[0])
  }

  const pricePerUnit = getPriceForQuantity(quantity)
  const totalPrice = pricePerUnit * quantity

  // Todo: Put this into alert comp and disable button
  const validateOrder = () => {
    if (!quantity) {
      messageApi.warning('Please select a quantity')
      return false
    }
    if (quantity < 50) {
      messageApi.warning('Minimum quantity required is 50 units')
      return false
    }
    if (!selectedImprintMethod) {
      messageApi.warning('Please select an imprint method')
      return false
    }
    if (!selectedImprintLocation) {
      messageApi.warning('Please select an imprint location')
      return false
    }
    if (!selectedColor) {
      messageApi.warning('Please select a color')
      return false
    }
    if (quantity > (fullProductDetail?.onHand || 0)) {
      messageApi.warning('Selected quantity exceeds available stock')
      return false
    }
    return true
  }

  const baseEventFields = {
    userId,
    orgId,
    individualId,
    email,
    productId: product?.prodEid,
    productName: product?.prName,
    quantity,
    selectedColor,
    selectedImprintMethod,
    selectedImprintLocation,
    pricePerUnit,
    totalPrice,
    paymentMethod,
  }

  const handlCreateOrderInvoice = async () => {
    if (!validateOrder()) return

    if (!customerId) {
      messageApi.error('Organization does not have a customer ID')
      return
    }

    const invoice = await createOrderInvoice({
      customerId,
      description: `${product?.prName} - ${quantity} units`,
      metadata: {
        color: selectedColor,
        imprintMethod: selectedImprintMethod,
        imprintLocation: selectedImprintLocation,
        productId: product?.id,
        quantity,
        pricePerUnit,
        totalPrice,
      },
      invoiceItems: [
        {
          quantity,
          unit_amount: Math.round(pricePerUnit * 100),
          currency: 'usd',
          description: `${product?.prName} - Color: ${selectedColor}, Imprint: ${selectedImprintMethod} at ${selectedImprintLocation}`,
        },
      ],
    })

    if (invoice?.id) {
      messageApi.success('Invoice created successfully')
      // Should wait until after they pay the invoice to create the purchase order
      navigate(DEFAULT_ROUTES.ORGANIZATION.SWAG.SEARCH_CONFIRMATION, {
        state: {
          orderDetails: {
            product,
            quantity,
            selectedColor,
            selectedImprintMethod,
            selectedImprintLocation,
            pricePerUnit,
            totalPrice,
            paymentMethod,
            invoiceId: invoice.id,
            invoiceUrl: invoice.hosted_invoice_url,
            invoiceNumber: invoice.number,
          },
        },
      })
      logEvent(BULK_ORDER_PLACED, {
        ...baseEventFields,
        invoiceId: invoice.id,
        invoiceNumber: invoice.number,
      })
    }
  }

  const subtotal = round(pricePerUnit) * quantity * 100
  const taxRate = 0.08875
  const salesTax = round(subtotal * taxRate)
  const orderTotal = round(subtotal + salesTax)

  const productToPurchaseOrder = async (
    extraData?: PartialMessage<PurchaseOrder>
  ) => {
    const poId = await createPurchaseOrder(
      new PurchaseOrder({
        createdAt: Timestamp.now(),
        orgId,
        individualId,
        // shippingAddress,
        subtotal: BigInt(pricePerUnit * quantity * 100),
        total: BigInt(orderTotal),
        paymentMethod: PurchaseOrder_PaymentMethod.creditCard,
        items: [
          new PurchaseOrder_Item({
            quantity,
            description: `${product?.prName} - Color: ${selectedColor}, Imprint: ${selectedImprintMethod} at ${selectedImprintLocation}`,
            unitPrice: BigInt(pricePerUnit * 100),
            lineItemTotal: BigInt(totalPrice * 100),
            imageUrl: mainImage?.url,
            supplierId: product?.suppId ? product.suppId.toString() : '',
            productId: product?.prodEid
              ? product.prodEid.toString()
              : undefined,
            productName: product?.prName,
            options: {
              color: selectedColor,
              imprintMethod: selectedImprintMethod,
              imprintLocation: selectedImprintLocation,
            },
          }),
        ],
        ...extraData,
      })
    )
    return poId
  }

  const handleStripePayment = async (sessionId?: string) => {
    if (!validateOrder()) return
    messageApi.success('Payment successful', 2)
    const poId = await productToPurchaseOrder({
      paymentInfo: new PurchaseOrder_PaymentInfo({
        stripeCheckoutSessionId: sessionId,
      }),
    })

    if (poId) {
      navigate(DEFAULT_ROUTES.ORGANIZATION.SWAG.SEARCH_CONFIRMATION, {
        state: {
          orderDetails: {
            product,
            quantity,
            selectedColor,
            selectedImprintMethod,
            selectedImprintLocation,
            pricePerUnit,
            totalPrice,
            paymentMethod,
          },
        },
      })
      logEvent(BULK_ORDER_PLACED, {
        ...baseEventFields,
        purchaseOrderId: poId,
        stripeCheckoutSessionId: sessionId,
      })
    } else {
      messageApi.error('Error creating purchase order')
    }
  }

  const handleAccountBalancePayment = async () => {
    if (!validateOrder() || !selectedOrgBalanceAccount) return
    // We need to create an account transaction to deduct the amount from the account

    const purchaseOrderId = await productToPurchaseOrder({
      paymentInfo: new PurchaseOrder_PaymentInfo({
        adjustmentAccountId: selectedOrgBalanceAccount.id,
      }),
    })
    if (!purchaseOrderId) {
      messageApi.error('Error creating purchase order')
      return
    }

    const adjustFundsPayload = {
      accountId: selectedOrgBalanceAccount.id,
      amount: BigInt(orderTotal),
      sourceId: purchaseOrderId,
      memo: `Purchase Order: ${purchaseOrderId}`,
    }

    try {
      await adjustFunds(adjustFundsPayload)
      messageApi.success('Order placed successfully', 2)
      navigate(DEFAULT_ROUTES.ORGANIZATION.SWAG.SEARCH_CONFIRMATION, {
        state: {
          orderDetails: {
            product,
            quantity,
            selectedColor,
            selectedImprintMethod,
            selectedImprintLocation,
            pricePerUnit,
            totalPrice,
            paymentMethod,
          },
        },
      })
      logEvent(BULK_ORDER_PLACED, {
        ...baseEventFields,
        accountName: selectedOrgBalanceAccount.name,
        accountId: selectedOrgBalanceAccount.id,
      })
    } catch (e) {
      console.error(e)
      captureException(toSentry(e), {
        contexts: {
          handleTransferFunds: adjustFundsPayload,
        },
      })
      messageApi.error('Error creating account transaction')
    }
  }

  // Todo: change image with color selection instead of use effect
  useEffect(() => {
    if (selectedColor && product?.pics) {
      // Find image that matches the selected color
      const colorImage = product.pics.find((pic: { caption?: string }) =>
        pic.caption?.toLowerCase().includes(selectedColor.toLowerCase())
      )
      if (colorImage) {
        setMainImage(colorImage)
      }
    }
  }, [selectedColor, product?.pics])

  if (loading || !hasLoadedAccounts) {
    return (
      <Flex className="justify-middle align-middle h-screen">
        <PerkLoader />
      </Flex>
    )
  }
  if (!product) {
    return (
      <Flex className="p-8 gap-4">
        <BackIconButton />
        <Flex vertical>
          <Heading size={900}>Product Not Found</Heading>
          <Text>
            The requested product could not be found. Please try again or
            contact support if the issue persists.
          </Text>
        </Flex>
      </Flex>
    )
  }
  const onHand = Number(fullProductDetail?.onHand)

  return (
    <>
      <Helmet>
        <title>{product?.prName} | Swag</title>
      </Helmet>

      {contextHolder}
      <Pane display="flex" gap={32} padding={32}>
        <BackIconButton />
        <Pane width={512} marginRight={32}>
          {product?.pics && product.pics.length > 0 && (
            <>
              <Pane marginBottom={16}>
                <img
                  src={
                    mainImage?.url?.replace(/RS=\d+/, 'RS=1800') ||
                    product.pics[0].url.replace(/RS=\d+/, 'RS=1800')
                  }
                  alt={mainImage?.caption || product?.prName}
                  style={{
                    width: '100%',
                    height: 'auto',
                    borderRadius: 4,
                  }}
                />
                {mainImageCaption && (
                  <Text marginTop={4}>{mainImageCaption}</Text>
                )}
              </Pane>
              <Pane display="flex" gap={8} flexWrap="wrap">
                {product.pics.slice(1).map((pic: any) => (
                  <Pane
                    key={pic.url}
                    cursor="pointer"
                    onClick={() => setMainImage(pic)}
                  >
                    <img
                      src={pic.url}
                      alt={pic.caption || product?.prName}
                      style={{
                        width: 64,
                        height: 64,
                        borderRadius: 4,
                        objectFit: 'cover',
                      }}
                    />
                  </Pane>
                ))}
              </Pane>
            </>
          )}
        </Pane>
        <Pane flex={1} maxWidth={768}>
          <Pane display="flex" gap={32}>
            <Heading size={900} marginBottom={16}>
              {product?.prName}
            </Heading>
            {fullProductDetail?.verified === 1 && (
              <Pane width="fit-content" marginBottom={16}>
                <Alert message="Verified" type="success" showIcon />
              </Pane>
            )}
          </Pane>

          <Pane marginBottom={24}>
            <Text>{product?.description}</Text>
          </Pane>

          <Pane
            display="flex"
            flexDirection="column"
            gap={16}
            marginBottom={16}
          >
            <Pane display="flex" gap={8} flexWrap="wrap">
              {product?.qty?.map((quantity: string, index: number) => {
                if (quantity === '0' || !product?.prc) return null

                const highestPrice = Math.max(...product.prc)
                const currentPrice = product.prc[index]
                const discountPercent =
                  ((highestPrice - currentPrice) / highestPrice) * 100

                return (
                  <Pane
                    key={quantity}
                    border="default"
                    padding={8}
                    borderRadius={4}
                    display="flex"
                    flexDirection="column"
                    gap={8}
                  >
                    <Strong>Qty: {quantity}</Strong>
                    <Pane display="flex" gap={8}>
                      <Text>{numToDollars(currentPrice * 100, 2)}/ea</Text>

                      {discountPercent > 0 && (
                        <Text color="green500">
                          Save {Math.round(discountPercent)}%
                        </Text>
                      )}
                    </Pane>
                  </Pane>
                )
              })}
            </Pane>
            {product?.priceIncludes && (
              <Text>
                <Strong>Price Includes:</Strong> {product?.priceIncludes}
              </Text>
            )}
          </Pane>

          <Pane
            display="grid"
            gap={16}
            width="100%"
            marginY={32}
            gridTemplateColumns="1fr 1fr"
            gridTemplateRows="1fr 1fr"
          >
            <Pane display="flex" flexDirection="column" gap={4}>
              <Text>Quantity:</Text>
              <InputNumber
                min={50}
                max={999999}
                value={quantity}
                onChange={value => {
                  const newValue = Number(value)

                  if (newValue > onHand) {
                    messageApi.warning(
                      `Only ${onHand?.toLocaleString('en-US')} units available in stock`
                    )
                  }
                  setQuantity(newValue)
                }}
                style={{ width: '100%' }}
              />
              {quantity < 50 && (
                <Alert
                  message="Warning"
                  description="Minimum quantity required is 50 units"
                  type="warning"
                  showIcon
                  style={{ marginTop: 8 }}
                />
              )}
              {quantity > (onHand || 0) && (
                <Alert
                  message="Warning"
                  description={`Only ${onHand?.toLocaleString('en-US')} units available in stock`}
                  type="warning"
                  showIcon
                  style={{ marginTop: 8 }}
                />
              )}
            </Pane>

            {fullProductDetail?.decorationMethod && (
              <Pane display="flex" flexDirection="column" gap={4}>
                <Text>Imprint Method:</Text>
                <Select
                  value={selectedImprintMethod}
                  onChange={value => setSelectedImprintMethod(value)}
                  style={{ width: '100%' }}
                  placeholder="Select method"
                >
                  {fullProductDetail.decorationMethod.split(',').map(method => (
                    <Select.Option key={method} value={method.trim()}>
                      {method.trim()}
                    </Select.Option>
                  ))}
                </Select>
              </Pane>
            )}

            {product?.colors && (
              <Pane display="flex" flexDirection="column" gap={4}>
                <Text>Color:</Text>
                <Select
                  value={selectedColor}
                  onChange={value => {
                    setSelectedColor(value)
                    // Find matching image for selected color
                    const colorImage = product.pics?.find(
                      (pic: { caption?: string }) =>
                        pic.caption?.toLowerCase().includes(value.toLowerCase())
                    )
                    if (colorImage) {
                      setMainImage(colorImage)
                    }
                  }}
                  style={{ width: '100%' }}
                  placeholder="Select color"
                >
                  {product.colors.split(',').map((color: string) => (
                    <Select.Option key={color} value={color.trim()}>
                      {color.trim()}
                    </Select.Option>
                  ))}
                </Select>
              </Pane>
            )}

            {fullProductDetail?.imprintLoc && (
              <Pane display="flex" flexDirection="column" gap={4}>
                <Text>Imprint Location:</Text>
                <Select
                  value={selectedImprintLocation}
                  onChange={value => setSelectedImprintLocation(value)}
                  style={{ width: '100%' }}
                  placeholder="Select location"
                >
                  <Select.Option value={fullProductDetail.imprintLoc}>
                    {fullProductDetail.imprintLoc}
                  </Select.Option>
                  {fullProductDetail.secondImprintLoc && (
                    <Select.Option value={fullProductDetail.secondImprintLoc}>
                      {fullProductDetail.secondImprintLoc}
                    </Select.Option>
                  )}
                </Select>
              </Pane>
            )}
          </Pane>

          <Pane marginY={32}>
            <Pane display="flex" gap={32}>
              <Pane display="flex" flexDirection="column" gap={16} flex={1}>
                <Pane width="100%">
                  <Heading marginBottom={8} size={500}>
                    Payment method
                  </Heading>
                  <Radio.Group
                    value={paymentMethod}
                    onChange={e => setPaymentMethod(e.target.value)}
                    style={{ width: '100%' }}
                  >
                    <Flex vertical gap={8}>
                      {/** Org balance account */}
                      {sendableAccounts.length > 0 && (
                        <Pane
                          display="flex"
                          border
                          padding={16}
                          borderRadius={8}
                          cursor="pointer"
                          onClick={() => {
                            setPaymentMethod(PaymentMethod.ORG_BALANCE)
                          }}
                        >
                          <Radio value={PaymentMethod.ORG_BALANCE} />
                          <AccountBalanceForm
                            inLocaleAmounts
                            setSelectedAccount={acc => {
                              setSelectedOrgBalanceAccount(acc)
                            }}
                          />
                        </Pane>
                      )}

                      <Pane
                        display="flex"
                        flexDirection="column"
                        border
                        padding={16}
                        borderRadius={8}
                        cursor="pointer"
                        onClick={() =>
                          setPaymentMethod(PaymentMethod.CREDIT_CARD)
                        }
                        gap={16}
                      >
                        <Flex align="center">
                          <Radio value={PaymentMethod.CREDIT_CARD} />
                          <Flex flex={1} justify="space-between" align="center">
                            <Text>{PaymentMethod.CREDIT_CARD}</Text>
                            <CreditCardsLogoGroup />
                          </Flex>
                        </Flex>
                        {/* Allow stripe element to mount even if hidden to avoid loading time */}
                        <div
                          style={{
                            display:
                              PaymentMethod.CREDIT_CARD === paymentMethod
                                ? undefined
                                : 'none',
                          }}
                        >
                          <LineItemsPayment
                            amount={round(Number(orderTotal), 2)}
                            onComplete={handleStripePayment}
                            disabled={!quantity}
                            metadata={{
                              color: selectedColor,
                              imprintMethod: selectedImprintMethod,
                              imprintLocation: selectedImprintLocation,
                              productId: product?.id,
                              quantity,
                              pricePerUnit,
                              totalPrice,
                              productName: product?.prName,
                            }}
                            lineItems={[
                              {
                                quantity,
                                price_data: {
                                  currency: 'usd',
                                  unit_amount: Math.round(pricePerUnit * 100),
                                  product_data: {
                                    description: `${product?.prName} - Color: ${selectedColor}, Imprint: ${selectedImprintMethod} at ${selectedImprintLocation}`,
                                    name: product?.prName,
                                  },
                                },
                              },
                            ]}
                          />
                        </div>
                      </Pane>

                      <Pane
                        display="flex"
                        border
                        padding={16}
                        borderRadius={8}
                        cursor="pointer"
                        onClick={() => setPaymentMethod(PaymentMethod.INVOICE)}
                      >
                        <Radio value={PaymentMethod.INVOICE} />
                        <Flex justify="space-between" style={{ width: '100%' }}>
                          <Text>Create Invoice</Text>
                        </Flex>
                      </Pane>
                    </Flex>
                  </Radio.Group>
                </Pane>
                {paymentMethod !== PaymentMethod.CREDIT_CARD && (
                  <Flex vertical gap={8}>
                    <Button
                      style={{ width: '100%' }}
                      size="large"
                      type="primary"
                      disabled={!quantity || isLoadingPlaceOrder}
                      loading={isLoadingPlaceOrder}
                      onClick={async () => {
                        setIsLoadingPlaceOrder(true)
                        if (paymentMethod === PaymentMethod.INVOICE) {
                          await handlCreateOrderInvoice()
                        } else {
                          await handleAccountBalancePayment()
                        }
                        setIsLoadingPlaceOrder(false)
                      }}
                    >
                      {paymentMethod === PaymentMethod.INVOICE
                        ? 'Create Invoice'
                        : 'Place Order'}
                    </Button>
                  </Flex>
                )}
              </Pane>
              <Pane
                border="default"
                display="flex"
                flexDirection="column"
                gap={4}
                padding={16}
                borderRadius={8}
                width={384}
              >
                <Heading size={500} marginBottom={8}>
                  Order summary
                </Heading>

                <Pane display="flex" justifyContent="space-between">
                  <Text>Pay as you go warehousing fee</Text>
                  <Text>TBD</Text>
                </Pane>
                <Pane
                  display="flex"
                  justifyContent="space-between"
                  marginBottom={8}
                >
                  <Text>Pay as you go shipping fee</Text>
                  <Text>TBD</Text>
                </Pane>
                <Pane display="flex" justifyContent="space-between">
                  <Text>Price per unit</Text>
                  <Text>{numToDollars(pricePerUnit * 100, 2)}</Text>
                </Pane>
                <Pane display="flex" justifyContent="space-between">
                  <Text>Quantity</Text>
                  <Text>{Number(quantity).toLocaleString('en-US')}</Text>
                </Pane>
                <Pane display="flex" justifyContent="space-between">
                  <Text>Subtotal</Text>
                  <Text>{numToDollars(subtotal, 2)}</Text>
                </Pane>
                <Pane display="flex" justifyContent="space-between">
                  <Text>Tax (8.875%)</Text>
                  <Text>{numToDollars(salesTax, 2)}</Text>
                </Pane>
                <Pane
                  borderTop="default"
                  marginTop={8}
                  paddingTop={8}
                  display="flex"
                  flexDirection="column"
                  gap={8}
                >
                  <Pane display="flex" justifyContent="space-between">
                    <Heading size={500}>Order total</Heading>
                    <Heading size={500}>{numToDollars(orderTotal, 2)}</Heading>
                  </Pane>
                  {quantity &&
                    product?.prc &&
                    Math.max(...product.prc) * quantity > totalPrice && (
                      <Pane display="flex" justifyContent="space-between">
                        <Text color="green500">Bulk discount savings</Text>
                        <Text color="green500">
                          {numToDollars(
                            (Math.max(...product.prc) * quantity - totalPrice) *
                              100,
                            2
                          )}
                        </Text>
                      </Pane>
                    )}
                </Pane>
              </Pane>
            </Pane>
          </Pane>

          <Pane
            marginBottom={16}
            border="default"
            padding={16}
            borderRadius={8}
          >
            <Heading size={500} marginBottom={8}>
              Product Details
            </Heading>
            <Pane display="flex" flexDirection="column" gap={8}>
              {product?.itemNum && (
                <Text>
                  <Strong>Item Number:</Strong> {product.itemNum}
                </Text>
              )}
              {product?.colors && (
                <Text>
                  <Strong>Colors:</Strong> {product.colors}
                </Text>
              )}
              {product?.dimensions && (
                <Text>
                  <Strong>Dimensions:</Strong> {product.dimensions}
                </Text>
              )}
              {fullProductDetail?.madeInCountry && (
                <Text>
                  <Strong>Made in:</Strong> {fullProductDetail.madeInCountry}
                </Text>
              )}
              {fullProductDetail?.assembledInCountry && (
                <Text>
                  <Strong>Assembled in:</Strong>{' '}
                  {fullProductDetail.assembledInCountry}
                </Text>
              )}
              {fullProductDetail?.decoratedInCountry && (
                <Text>
                  <Strong>Decorated in:</Strong>{' '}
                  {fullProductDetail.decoratedInCountry}
                </Text>
              )}

              {fullProductDetail?.unitsPerCarton && (
                <Text>
                  <Strong>Units Per Carton:</Strong>{' '}
                  {fullProductDetail.unitsPerCarton}
                </Text>
              )}
              {fullProductDetail?.cartonL &&
                fullProductDetail?.cartonW &&
                fullProductDetail?.cartonH && (
                  <Text>
                    <Strong>Carton Dimensions (L×W×H):</Strong>{' '}
                    {fullProductDetail.cartonL}″ × {fullProductDetail.cartonW}″
                    × {fullProductDetail.cartonH}″
                  </Text>
                )}
              {fullProductDetail?.recyclable === 1 && (
                <Text>
                  <Strong>Recyclable:</Strong> Yes
                </Text>
              )}
              {fullProductDetail?.envFriendly === 1 && (
                <Text>
                  <Strong>Environmentally Friendly:</Strong> Yes
                </Text>
              )}
            </Pane>
          </Pane>

          <Pane
            marginBottom={16}
            border="default"
            padding={16}
            borderRadius={8}
          >
            <Heading size={500} marginBottom={8}>
              Decoration Details
            </Heading>
            <Pane display="flex" flexDirection="column" gap={8}>
              {fullProductDetail?.decorationMethod && (
                <Text>
                  <Strong>Available Imprint Methods:</Strong>{' '}
                  {fullProductDetail.decorationMethod}
                </Text>
              )}
              {fullProductDetail?.imprintArea && (
                <Text>
                  <Strong>Imprint Area:</Strong> {fullProductDetail.imprintArea}
                </Text>
              )}
              {fullProductDetail?.secondImprintArea && (
                <Text>
                  <Strong>Second Imprint Area:</Strong>{' '}
                  {fullProductDetail.secondImprintArea}
                </Text>
              )}
              {fullProductDetail?.supplier?.generalInfo?.artInfo && (
                <Text>
                  <Strong>Artwork Requirements:</Strong>{' '}
                  {fullProductDetail.supplier.generalInfo.artInfo}
                </Text>
              )}
              {fullProductDetail?.supplier?.generalInfo?.proofInfo && (
                <Text>
                  <Strong>Proof Information:</Strong>{' '}
                  {fullProductDetail.supplier.generalInfo.proofInfo}
                </Text>
              )}
            </Pane>
          </Pane>

          <Pane
            marginBottom={16}
            border="default"
            padding={16}
            borderRadius={8}
          >
            <Heading size={500} marginBottom={8}>
              Inventory & Shipping
            </Heading>
            <Pane display="flex" flexDirection="column" gap={8}>
              {fullProductDetail?.onHand && (
                <Text>
                  <Strong>Available Stock:</Strong>{' '}
                  {onHand?.toLocaleString('en-US')} units
                </Text>
              )}
              {fullProductDetail?.prodTime && (
                <Text>
                  <Strong>Production Time:</Strong> {fullProductDetail.prodTime}{' '}
                </Text>
              )}
              {fullProductDetail?.shipPointCountry && (
                <Text>
                  <Strong>Ships From:</Strong>{' '}
                  {fullProductDetail.shipPointCountry}
                  {fullProductDetail.shipPointZip &&
                    ` (${fullProductDetail.shipPointZip})`}
                </Text>
              )}
              {fullProductDetail?.inventoryLastUpdated && (
                <Text>
                  <Strong>Inventory Last Updated:</Strong>{' '}
                  {new Date(
                    fullProductDetail.inventoryLastUpdated
                  ).toLocaleDateString('en-US', {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                  })}
                </Text>
              )}
              {fullProductDetail?.supplier?.generalInfo?.shipInfo && (
                <Text>
                  <Strong>Shipping Information:</Strong>{' '}
                  {fullProductDetail.supplier.generalInfo.shipInfo}
                </Text>
              )}
            </Pane>
          </Pane>
        </Pane>
      </Pane>
    </>
  )
}
