import { Alert, Button, Flex, Radio, message } from 'antd'
import { EMPTY_CART } from 'assets/contentful'
import {
  AccountBalanceForm,
  AddPersonalFundsButton,
  BackIconButton,
  BigSavingsTag,
  CheckoutTerms,
  CreditCardsLogoGroup,
  DraftOrderContextBuilder,
  PerkEmpty,
  PersonalFundsPayment,
} from 'components'
import { ChangeAddressButton } from 'components/Addresses'
import { PaymentMethod } from 'constants/checkout'
import { NUMBER_GREEN } from 'constants/colors'
import { WidthBreakpoints } from 'constants/layout'
import { MIN_PERSONAL_FUNDS_DEPOSIT } from 'constants/money'
import { PERKUP_NAME } from 'constants/perkupLinks'
import {
  DraftOrderContext,
  ExchangeRateContext,
  IndividualContext,
  OrgContext,
  UserContext,
  UserShippingAddressesContext,
} from 'context'
import { Heading, Pane, Text, useTheme } from 'evergreen-ui'
import { Account } from 'gen/perkup/v1/account_pb'
import { Cart } from 'gen/perkup/v1/cart_pb'
import { ProductVariant } from 'gen/perkup/v1/product_variant_pb'
import { Item } from 'gen/perkup/v1/program_pb'
import { ShippingAddress } from 'gen/perkup/v1/root_user_pb'
import { CheckoutProduct } from 'gen/perkup/v1/stripe_pb'
import {
  useDisplayCurrency,
  useListenToPersonalFundsProgram,
  useOrgUserBalances,
  usePersonalFundsAccount,
  useSendableAccounts,
} from 'hooks'
import { isEmpty, round } from 'lodash-es'
import { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { useNavigate, useOutletContext } from 'react-router'
import { placeProductVariantsOrder } from 'services'
import { MaybeWithDesign, WithSelectedQuantity } from 'types'
import { numToDollars } from 'utils'
import {
  buildProductVariantDisplayName,
  invalidShippingCountryVariants,
  sumProductVariants,
} from 'utils/productVariant'
import { MicroLines } from './micro-lines'

/**
 *
 * If our stripe payment was successful, listen for the funds to land in the personal funds account/program and then place the order. We can't use a promise because this is all triggered via webhooks.
 */
const usePersonalFundsPayment = ({
  productVariants,
  amount,
  shippingAddress,
  onCheckoutComplete,
}: {
  productVariants: MaybeWithDesign<WithSelectedQuantity<ProductVariant>>[]
  amount: number
  shippingAddress?: ShippingAddress
  onCheckoutComplete?: (shippingAddress: ShippingAddress) => void
}) => {
  const user = useContext(UserContext)
  const org = useContext(OrgContext)
  const individual = useContext(IndividualContext)

  const { account: personalFundsAccount } = usePersonalFundsAccount()
  const { program: personalFundsProgram } = useListenToPersonalFundsProgram()
  const { orgUserPersonalBalance } = useOrgUserBalances()
  const [isLoading, setIsLoading] = useState(false)

  const stripePaymentSucceeded = useRef(false)

  // If we're placing a credit card order, we need to wait for the payment to be successful and for the funds to land in the personal funds account before placing the order

  useEffect(() => {
    if (
      stripePaymentSucceeded.current &&
      !isLoading &&
      personalFundsAccount &&
      personalFundsProgram &&
      orgUserPersonalBalance >= amount
    ) {
      if (!shippingAddress) return // Impossible, because button is disabled when shippingAddress is undefined
      setIsLoading(true)
      const orderIems = productVariants.map(productVariant => {
        return new Item({
          productVariantId: productVariant.id,
          quantity: productVariant.selectedQuantity,
          provider: productVariant.provider,
          productId: productVariant.productId,
          canvasDesign: productVariant.canvasDesign,
        })
      })

      placeProductVariantsOrder({
        shippingAddress,
        items: orderIems,
        user,
        org,
        individual,
        program: personalFundsProgram,
        orderTotal: amount,
      })
        .then(response => {
          if (response.status === 'error') {
            message.warning(response.message) // Ant design doesn't like us calling message message like this without the context holder
            return
          }
          message.success(response.message)
          if (onCheckoutComplete) onCheckoutComplete(shippingAddress)
        })
        .finally(() => {
          stripePaymentSucceeded.current = false
          setIsLoading(false)
        })
    }
  }, [
    individual,
    isLoading,
    onCheckoutComplete,
    org,
    personalFundsAccount,
    productVariants,
    shippingAddress,
    amount,
    user,
    personalFundsProgram,
    orgUserPersonalBalance,
  ])

  return {
    isLoading,
    stripePaymentSucceeded,
  }
}

function CheckoutForm({
  productVariants,
  onCheckoutComplete,
  onUpdateSelectedVariants,
}: {
  productVariants: MaybeWithDesign<WithSelectedQuantity<ProductVariant>>[]
  onCheckoutComplete?: () => void
  onUpdateSelectedVariants: (
    productVariants: MaybeWithDesign<WithSelectedQuantity<ProductVariant>>[]
  ) => Promise<void>
}) {
  const user = useContext(UserContext)
  const org = useContext(OrgContext)
  const individual = useContext(IndividualContext)
  const exchangeRate = useContext(ExchangeRateContext)
  const shippingAddresses = useContext(UserShippingAddressesContext)
  const { draftOrderCalculation } = useContext(DraftOrderContext)

  const [messageApi, contextHolder] = message.useMessage()

  const navigate = useNavigate()
  const displayCurrency = useDisplayCurrency()

  const outletContext: {
    cart: Cart | undefined
    shippingAddress: ShippingAddress | undefined
    onShippingAddressChange: (address?: ShippingAddress) => void
  } = useOutletContext()

  const { shippingAddress, onShippingAddressChange } = outletContext

  const { orgUserSwagBalance } = useOrgUserBalances()
  const { sendableAccounts, hasLoadedAccounts } = useSendableAccounts()

  const [selectedOrgBalanceAccount, setSelectedOrgBalanceAccount] =
    useState<Account>()
  const [paymentMethod, setPaymentMethod] = useState<PaymentMethod>(
    PaymentMethod.PERKUP_BALANCE
  )

  const totalCost = draftOrderCalculation?.totalPrice
    ? round(Number(draftOrderCalculation?.totalPrice) * 100, 2)
    : sumProductVariants(productVariants)

  const { stripePaymentSucceeded } = usePersonalFundsPayment({
    productVariants,
    amount: totalCost,
    onCheckoutComplete,
    shippingAddress,
  })

  const currentBalance =
    selectedOrgBalanceAccount && paymentMethod === PaymentMethod.ORG_BALANCE
      ? Number(selectedOrgBalanceAccount.balance)
      : orgUserSwagBalance

  const [isLoadingPlaceOrder, setIsLoadingPlaceOrder] = useState(false)

  const theme = useTheme()

  const pvsNotAvailableInShippingCountry = invalidShippingCountryVariants({
    shippingAddress,
    productVariants,
  })

  const determineAlertMessage = () => {
    if (!hasLoadedAccounts) return undefined
    const outOfStockProducts = productVariants.filter(
      pv => !pv.productIsAvailable
    )
    if (!isEmpty(pvsNotAvailableInShippingCountry)) {
      const notAvailableProductNames = pvsNotAvailableInShippingCountry.map(
        pv => buildProductVariantDisplayName({ productVariant: pv })
      )
      return {
        message: `The following products are not available in your shipping country: ${notAvailableProductNames.join(', ')}. Please remove them from your cart to continue.`,
      }
    }

    if (!isEmpty(outOfStockProducts)) {
      const outOfStockProductNames = outOfStockProducts.map(pv =>
        buildProductVariantDisplayName({ productVariant: pv })
      )
      return {
        message: `The following products are out of stock: ${outOfStockProductNames.join(', ')}`,
      }
    }
    const showInsufficientFundsAlert =
      sendableAccounts.length > 0 ? !!selectedOrgBalanceAccount : true
    // Don't let alert flash if we haven't attempted to select an account yet

    if (
      totalCost > currentBalance &&
      paymentMethod !== PaymentMethod.CREDIT_CARD &&
      showInsufficientFundsAlert
    ) {
      const difference = totalCost - currentBalance
      const differenceBelowMinimum = difference < MIN_PERSONAL_FUNDS_DEPOSIT
      const differenceInDisplayCurrency = numToDollars(
        difference * exchangeRate,
        2,
        false,
        displayCurrency
      )
      const message =
        paymentMethod === PaymentMethod.PERKUP_BALANCE
          ? `Insufficient funds, you need ${differenceInDisplayCurrency}`
          : `Insufficient funds in this account, you need ${differenceInDisplayCurrency}`

      const description = () => {
        if (paymentMethod === PaymentMethod.PERKUP_BALANCE) {
          if (differenceBelowMinimum) {
            return `You will be charged the minimum amount of ${numToDollars(MIN_PERSONAL_FUNDS_DEPOSIT * exchangeRate, 2, false, displayCurrency)} in USD.`
          }
          return 'You will be charged the appropriate amount in USD.'
        }
        return 'Please select a different account or reach out to an administrator to add funds to this account.'
      }

      const action =
        paymentMethod === PaymentMethod.PERKUP_BALANCE ? (
          <AddPersonalFundsButton
            defaultAmount={
              differenceBelowMinimum ? MIN_PERSONAL_FUNDS_DEPOSIT : difference
            }
            oneClickCheckout
          />
        ) : undefined

      return {
        message,
        description: description(),
        action,
      }
    }
    if (!user?.profile?.firstName || !user?.profile?.lastName) {
      return {
        message:
          'Please make sure your first and last name are entered correctly and try again.',
      }
    }

    return undefined
  }

  const prices = [
    {
      label: 'Subtotal',
      amount: totalCost,
    },
    {
      label: 'Shipping & handling',
      amount: 0,
    },
    {
      label: 'Estimated tax',
      amount: 0,
    },
    {
      label: 'Order total',
      amount: totalCost,
    },
  ]

  const customerCreationIdempotencyKey = useMemo(() => {
    // This is used to ensure that stripe always returns us the same customer response when we create a customer. If we leave and come back we get a different key. Stable for page.
    return crypto.randomUUID()
  }, [])

  const handlePlaceOrder = () => {
    if (!shippingAddress) return // Impossible, because button is disabled when shippingAddress is undefined

    setIsLoadingPlaceOrder(true)

    const orderIems = productVariants.map(productVariant => {
      return new Item({
        productVariantId: productVariant.id,
        quantity: productVariant.selectedQuantity,
        provider: productVariant.provider,
        productId: productVariant.productId,
        canvasDesign: productVariant.canvasDesign,
      })
    })

    const orderAccount =
      paymentMethod === PaymentMethod.ORG_BALANCE
        ? selectedOrgBalanceAccount
        : undefined

    placeProductVariantsOrder({
      shippingAddress,
      items: orderIems,
      user,
      org,
      individual,
      account: orderAccount,
      orderTotal: totalCost,
    })
      .then(response => {
        if (response.status === 'error') {
          messageApi.warning(response.message)
          return
        }
        messageApi.success(response.message)
        if (onCheckoutComplete) onCheckoutComplete()
      })
      .finally(() => {
        setIsLoadingPlaceOrder(false)
      })
  }

  const handleStripePayment = () => {
    stripePaymentSucceeded.current = true
    messageApi
      .success('Payment successful', 2)
      .then(() => messageApi.loading('Placing order', 0))
  }

  const alertMessage = determineAlertMessage()

  const disableCta =
    !!alertMessage || !shippingAddress || isEmpty(productVariants)

  return (
    <>
      {contextHolder}

      <Flex
        vertical
        gap={isMobile ? 16 : 32}
        style={{
          height: '100%',
          width: '100%',
          maxWidth: WidthBreakpoints.XL,
          margin: 'auto',
        }}
      >
        {/** Header with back button  */}
        <Flex align="center" gap={16}>
          <BackIconButton />

          <Heading height="fit-content" size={800}>
            Checkout
          </Heading>
        </Flex>

        {/** Main checkout content */}
        <Flex
          wrap={!isMobile}
          gap={isMobile ? 16 : 72}
          justify="center"
          style={{ flexDirection: isMobile ? 'column-reverse' : 'row' }}
        >
          {/** On mobile we render an additional special CTA with checkout terms */}
          {isMobile && (
            <Flex vertical gap={16}>
              <Flex gap={8} justify="space-between">
                <Heading size={500}>Total</Heading>
                <Heading size={700}>
                  {numToDollars(
                    totalCost * exchangeRate,
                    2,
                    false,
                    displayCurrency
                  )}
                </Heading>
              </Flex>
              {paymentMethod !== PaymentMethod.CREDIT_CARD && (
                <Flex vertical gap={8}>
                  <Button
                    loading={isLoadingPlaceOrder}
                    onClick={handlePlaceOrder}
                    style={{ width: '100%' }}
                    size="large"
                    type="primary"
                    disabled={disableCta}
                  >
                    Place order
                  </Button>

                  <CheckoutTerms companyName={PERKUP_NAME} />
                </Flex>
              )}
            </Flex>
          )}

          {/** Address and cart review sections */}
          <Flex flex={1} vertical gap={isMobile ? 16 : 32}>
            <Flex vertical gap={16}>
              <Heading size={500}>Shipping address</Heading>

              <ChangeAddressButton
                asPrimaryButton
                showDeleteButton
                onAddressChange={onShippingAddressChange}
                selectedAddress={shippingAddress}
                shippingAddresses={shippingAddresses}
              />
            </Flex>
            <Flex vertical gap={16}>
              <Heading size={500}>Review items</Heading>
              {isEmpty(productVariants) ? (
                <Flex align="center" justify="center" style={{ padding: 16 }}>
                  <PerkEmpty
                    ctaProps={{
                      children: 'Shop now',
                      onClick: () => navigate('/swag'),
                    }}
                    header="Your cart is empty"
                    iconUrl={EMPTY_CART}
                  />
                </Flex>
              ) : (
                <MicroLines
                  productVariants={productVariants}
                  invalidProductVariants={pvsNotAvailableInShippingCountry}
                  onChange={onUpdateSelectedVariants}
                  shippingAddress={shippingAddress}
                />
              )}
            </Flex>
          </Flex>

          {/** Order summary, alerts, and CTA */}
          <Flex
            flex={1}
            vertical
            gap={isMobile ? 16 : 32}
            style={{ minWidth: 360 }}
          >
            {/** Prices breakdown */}
            <Flex vertical gap={16}>
              {!isMobile && (
                <Flex vertical gap={8}>
                  <Heading size={500}>Total</Heading>
                  <Heading size={700}>
                    {numToDollars(
                      totalCost * exchangeRate,
                      2,
                      false,
                      displayCurrency
                    )}
                  </Heading>
                </Flex>
              )}

              <BigSavingsTag inLocalAmounts />

              <Flex vertical gap={4}>
                {prices.map(({ label, amount }, index) => {
                  const isTotal = index === prices.length - 1
                  const displayAmount = numToDollars(
                    amount * exchangeRate,
                    2,
                    false,
                    displayCurrency
                  )
                  return (
                    <Flex
                      key={label}
                      justify="space-between"
                      style={{
                        borderTop: isTotal
                          ? `1px solid ${theme.colors.gray300}`
                          : 'none',
                        padding: isTotal ? '8px 0 0 0' : undefined,
                      }}
                    >
                      {isTotal && isMobile ? (
                        <>
                          <Heading size={500}>Total</Heading>
                          <Heading size={700}>{displayAmount}</Heading>
                        </>
                      ) : (
                        <>
                          <Text>{label}</Text>
                          <Text>
                            {label === 'Shipping & handling'
                              ? 'FREE delivery'
                              : displayAmount}
                          </Text>
                        </>
                      )}
                    </Flex>
                  )
                })}
              </Flex>
            </Flex>

            {/** payment method, alert, and cta */}
            <Flex
              gap={8}
              style={{ flexDirection: isMobile ? 'column-reverse' : 'column' }}
            >
              {/** Payment method and alert */}
              <Flex
                gap={8}
                style={{
                  flexDirection: isMobile ? 'column' : 'column-reverse',
                }}
              >
                {alertMessage && !isLoadingPlaceOrder && (
                  <Alert
                    style={
                      isMobile
                        ? {
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 8,
                            padding: 16,
                          }
                        : undefined
                    }
                    message={alertMessage.message}
                    description={alertMessage.description}
                    action={alertMessage.action}
                    type="warning"
                    showIcon
                  />
                )}
                <Flex vertical gap={8}>
                  <Heading size={500}>Payment method</Heading>
                  <Radio.Group
                    value={paymentMethod}
                    onChange={e => setPaymentMethod(e.target.value)}
                  >
                    <Flex vertical gap={8}>
                      <Pane
                        display="flex"
                        border
                        padding={16}
                        borderRadius={8}
                        cursor="pointer"
                        onClick={() =>
                          setPaymentMethod(PaymentMethod.PERKUP_BALANCE)
                        }
                      >
                        <Radio
                          key={PaymentMethod.PERKUP_BALANCE}
                          value={PaymentMethod.PERKUP_BALANCE}
                        />

                        <Flex justify="space-between" style={{ width: '100%' }}>
                          <Text>{PaymentMethod.PERKUP_BALANCE}</Text>
                          <Flex align="center" gap={8}>
                            <Text color={NUMBER_GREEN}>
                              {numToDollars(
                                orgUserSwagBalance * exchangeRate,
                                2,
                                false,
                                displayCurrency
                              )}
                            </Text>
                            <AddPersonalFundsButton asIcon />
                          </Flex>
                        </Flex>
                      </Pane>

                      <Pane
                        display="flex"
                        flexDirection="column"
                        border
                        padding={16}
                        borderRadius={8}
                        cursor="pointer"
                        onClick={() =>
                          setPaymentMethod(PaymentMethod.CREDIT_CARD)
                        }
                        gap={16}
                      >
                        <Flex align="center">
                          <Radio
                            key={PaymentMethod.CREDIT_CARD}
                            value={PaymentMethod.CREDIT_CARD}
                          />

                          <Flex flex={1} justify="space-between" align="center">
                            <Text>{PaymentMethod.CREDIT_CARD}</Text>
                            <CreditCardsLogoGroup />
                          </Flex>
                        </Flex>
                        {/* Allow stripe element to mount even if hidden to avoid loading time */}
                        <div
                          style={{
                            display:
                              PaymentMethod.CREDIT_CARD === paymentMethod
                                ? undefined
                                : 'none',
                          }}
                        >
                          <PersonalFundsPayment
                            amount={totalCost}
                            onComplete={handleStripePayment}
                            disabled={disableCta}
                            product={CheckoutProduct.SWAG_STORE_CHECKOUT}
                            customerCreationIdempotencyKey={
                              customerCreationIdempotencyKey
                            }
                          />
                        </div>
                      </Pane>
                      {/** Org balance account */}
                      {sendableAccounts.length > 0 && (
                        <Pane
                          display="flex"
                          border
                          padding={16}
                          borderRadius={8}
                          cursor="pointer"
                          onClick={() => {
                            setPaymentMethod(PaymentMethod.ORG_BALANCE)
                            if (!selectedOrgBalanceAccount)
                              setSelectedOrgBalanceAccount(sendableAccounts[0])
                          }}
                        >
                          <Radio
                            key={PaymentMethod.ORG_BALANCE}
                            value={PaymentMethod.ORG_BALANCE}
                          />
                          <AccountBalanceForm
                            inLocaleAmounts
                            setSelectedAccount={acc => {
                              setSelectedOrgBalanceAccount(acc)
                            }}
                          />
                        </Pane>
                      )}
                    </Flex>
                  </Radio.Group>
                </Flex>
              </Flex>

              {/** CTA and terms of use  */}
              {paymentMethod !== PaymentMethod.CREDIT_CARD && (
                <Flex vertical gap={8}>
                  <Button
                    loading={isLoadingPlaceOrder}
                    onClick={handlePlaceOrder}
                    style={{ width: '100%' }}
                    size={isMobile ? 'large' : undefined}
                    type="primary"
                    disabled={disableCta}
                  >
                    Place order
                  </Button>
                  <CheckoutTerms companyName={PERKUP_NAME} />
                </Flex>
              )}
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </>
  )
}

export function ProductVariantsCheckout({
  productVariants,
  onCheckoutComplete,
  onUpdateSelectedVariants,
}: {
  productVariants: MaybeWithDesign<WithSelectedQuantity<ProductVariant>>[]
  onCheckoutComplete?: () => void
  onUpdateSelectedVariants: (
    productVariants: MaybeWithDesign<WithSelectedQuantity<ProductVariant>>[]
  ) => Promise<void>
}) {
  const outletContext: {
    shippingAddress: ShippingAddress | undefined
  } = useOutletContext()

  const { shippingAddress } = outletContext

  return (
    <DraftOrderContextBuilder
      productVariants={productVariants}
      shippingAddress={shippingAddress}
    >
      <CheckoutForm
        productVariants={productVariants}
        onCheckoutComplete={onCheckoutComplete}
        onUpdateSelectedVariants={onUpdateSelectedVariants}
      />
    </DraftOrderContextBuilder>
  )
}
