import { captureException } from '@sentry/react'
import { getSlackPublicDefaultsByOccasion } from 'components/Notifications/SlackPublicConfiguration'
import { deleteField, doc, getFirestore, setDoc } from 'firebase/firestore'
import {
  NotificationSetting,
  NotificationSetting_RuleGroup,
} from 'gen/perkup/v1/notification_pb'
import { Action, ActionType_Enum } from 'gen/perkup/v1/rules_pb'
import { NotificationOccassions, RuleGroups } from 'types/Notifications'
import { getNotificationDetailsByOccassion, toSentry } from 'utils'
import { converter, storedId } from 'utils/firestore'

const notificationSettingRuleGroupMapper = (
  ruleGroup: RuleGroups | undefined
) => {
  if (!ruleGroup)
    return NotificationSetting_RuleGroup.PRIVACY_SETTING_KEY_UNSPECIFIED

  return NotificationSetting_RuleGroup[ruleGroup]
}

export async function createAndToggleNotificationSetting({
  orgId,
  notificationSettingId,
  enabled,
  ruleGroup,
}: {
  orgId: string
  notificationSettingId: string
  enabled: boolean
  ruleGroup?: RuleGroups
}) {
  const db = getFirestore()
  const docRef = doc(
    db,
    `organizations/${orgId}/notificationSettings/${notificationSettingId}`
  ).withConverter(converter(NotificationSetting))

  const notificationSettingRuleGroup =
    notificationSettingRuleGroupMapper(ruleGroup)

  return setDoc(
    docRef,
    { enabled, ruleGroup: notificationSettingRuleGroup },
    { merge: true }
  ).catch(error => {
    console.error(error)
    captureException(toSentry(error), {
      contexts: {
        createAndToggleNotificationSetting: {
          orgId,
          notificationSettingId,
          enabled,
        },
      },
    })
  })
}

export async function updateSlackPublicNotificationSetting({
  orgId,
  notificationSettingId,
  notificationSetting,
  updatedSettings,
  occassion,
}: {
  orgId: string
  notificationSettingId: string
  notificationSetting: NotificationSetting | undefined
  updatedSettings: Partial<NotificationSetting>
  occassion: NotificationOccassions
}) {
  try {
    const db = getFirestore()
    const docRef = doc(
      db,
      `organizations/${orgId}/notificationSettings/${notificationSettingId}`
    ).withConverter(converter(NotificationSetting))
    const { defaultImages, placeholder, groupBy } =
      getSlackPublicDefaultsByOccasion(occassion)

    const { ruleGroup } = getNotificationDetailsByOccassion(occassion)
    const defaultSettings = {
      enabled: false,
      images: defaultImages,
      message: placeholder,
      ruleGroup: notificationSettingRuleGroupMapper(ruleGroup),
      groupBy,
    }
    const notifSettings = new NotificationSetting({
      ...defaultSettings,
      ...notificationSetting,
      ...updatedSettings,
    })
    return await setDoc(docRef, notifSettings, { merge: true })
  } catch (error) {
    console.error(error)
    captureException(toSentry(error), {
      contexts: {
        updateSlackPublicNotificationSetting: {
          orgId,
          notificationSettingId,
          notificationSetting,
          updatedSettings,
          occassion,
        },
      },
    })
    return error
  }
}

export async function deleteGroupBySlackPublicNotificationSetting({
  orgId,
  notificationSettingId,
}: {
  orgId: string
  notificationSettingId: string
}) {
  try {
    const db = getFirestore()
    const docRef = doc(
      db,
      `organizations/${orgId}/notificationSettings/${notificationSettingId}`
    ).withConverter(converter(NotificationSetting))

    return await setDoc(docRef, { groupBy: deleteField() }, { merge: true })
  } catch (error) {
    console.error(error)
    captureException(toSentry(error), {
      contexts: {
        deleteGroupBySlackPublicNotificationSetting: {
          orgId,
          notificationSettingId,
        },
      },
    })
    return error
  }
}

export async function createNotificationAction({
  orgId,
  ruleId,
  actionType,
}: {
  orgId: string
  ruleId: string
  actionType: ActionType_Enum
}) {
  const newNotificationAction = new Action({
    amount: -1,
    dateOffset: 3,
    from: 'PerkUp',
    ruleId,
    actionType,
    orgId,
  })
  // Need 0 date offset for public announcements -> potential for refactor for more general code
  if (actionType === ActionType_Enum.publicAnnouncement) {
    newNotificationAction.dateOffset = 0
  }
  const db = getFirestore()
  const docRef = doc(
    db,
    `organizations/${orgId}/rules/${ruleId}/actions/${ActionType_Enum[actionType]}`
  ).withConverter(storedId(Action))
  return setDoc(docRef, newNotificationAction).catch(error => {
    console.error(error)
    captureException(toSentry(error), {
      contexts: {
        createNotificationAction: {
          orgId,
          ruleId,
          actionType,
        },
      },
    })
  })
}

export async function updateEmailNotificationAction({
  orgId,
  ruleId,
  actionType,
  dateOffset,
}: {
  orgId: string
  ruleId: string
  actionType: ActionType_Enum
  dateOffset: number
}) {
  try {
    const db = getFirestore()
    const docRef = doc(
      db,
      `organizations/${orgId}/rules/${ruleId}/actions/${ActionType_Enum[actionType]}`
    ).withConverter(storedId(Action))
    return await setDoc(
      docRef,
      {
        dateOffset,
      },
      { merge: true }
    )
  } catch (error) {
    console.error(error)
    captureException(toSentry(error), {
      contexts: {
        updateEmailNotificationAction: {
          orgId,
          ruleId,
          actionType,
          dateOffset,
        },
      },
    })

    return error
  }
}
