import { captureException } from '@sentry/react'
import {
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  onSnapshot,
  orderBy,
  query,
  where,
} from 'firebase/firestore'
import { EligibleCategory, User } from 'gen/perkup/v1/org_user_pb'
import { OrgTransaction } from 'gen/perkup/v1/organization_pb'
import { BalanceTransaction } from 'gen/perkup/v1/transaction_pb'
import { converter, storedId } from 'utils/firestore'
import { toSentry } from 'utils/sentry'

/**
 * Organization Users
 */

export function ListenToOrgUserById({
  userId,
  orgId,
  cb,
}: {
  userId: string
  orgId: string
  cb: React.Dispatch<React.SetStateAction<User | undefined>>
}) {
  const db = getFirestore()
  const docRef = doc(
    db,
    `organizations/${orgId}/users/${userId}`
  ).withConverter(converter(User))
  return onSnapshot(
    docRef,
    doc => {
      return cb(doc.data())
    },
    error => {
      console.error(error)
      captureException(toSentry(error), {
        contexts: { ListenToOrgUserById: { orgId, userId } },
        tags: { orgId },
      })
    }
  )
}

export function ListenToOrgUsersByOrgId({
  orgId,
  cb,
}: {
  orgId: string
  cb: Function
}) {
  const db = getFirestore()
  const colRef = collection(db, `organizations/${orgId}/users`).withConverter(
    converter(User)
  )
  return onSnapshot(
    query(colRef),
    query => cb(query.docs.map(doc => doc.data())),
    error => {
      console.error(error)
      captureException(toSentry(error), {
        contexts: { ListenToOrgUsersByOrgId: { orgId } },
        tags: { orgId },
      })
    }
  )
}

export async function ListBalanceTransactionsByProgramId({
  orgId,
  userId,
  programId,
}: {
  orgId: string
  userId: string
  programId: string
}) {
  try {
    const db = getFirestore()
    const colRef = collection(
      db,
      `organizations/${orgId}/users/${userId}/balanceTransactions`
    ).withConverter(converter(BalanceTransaction))

    const q = query(colRef, where('programId', '==', programId))

    return await getDocs(q).then(query => query.docs.map(doc => doc.data()))
  } catch (error) {
    console.error(error)
    captureException(toSentry(error), {
      contexts: {
        ListBalanceTransactionsByProgramId: { orgId, userId },
      },
      tags: { orgId, userId },
    })
  }
}

export async function GetOrgUserById({
  orgId,
  userId,
}: {
  orgId: string
  userId: string
}) {
  if (!orgId || !userId) return undefined
  try {
    const db = getFirestore()
    const docRef = doc(
      db,
      `organizations/${orgId}/users/${userId}`
    ).withConverter(converter(User))
    return await getDoc(docRef).then(doc => doc.data())
  } catch (error) {
    console.error(error)
    captureException(toSentry(error), {
      contexts: {
        GetOrgUserById: { orgId, userId },
      },
      tags: { orgId },
    })
  }
}

export async function ListAllOrgUsersByOrgId({ orgId }: { orgId: string }) {
  const orgUsers: User[] = []
  try {
    const db = getFirestore()
    const colRef = collection(db, `organizations/${orgId}/users`).withConverter(
      converter(User)
    )
    await getDocs(query(colRef)).then(querySnapshot =>
      querySnapshot.docs.forEach(doc => orgUsers.push(doc.data()))
    )
  } catch (error) {
    console.error(error)
    captureException(toSentry(error), {
      contexts: { ListAllOrgUsersByOrgId: { orgId } },
      tags: { orgId },
    })
  }
  return orgUsers
}

export function ListenToTransactionsByUserId({
  orgId,
  userId,
  cb,
}: {
  orgId: string
  userId: string
  cb: React.Dispatch<React.SetStateAction<OrgTransaction[]>>
}) {
  const db = getFirestore()
  const colRef = collection(
    db,
    `organizations/${orgId}/transactions`
  ).withConverter(storedId(OrgTransaction))
  const q = query(
    colRef,
    where('userId', '==', userId),
    orderBy('created', 'desc')
  )
  return onSnapshot(
    q,
    query => cb(query.docs.map(doc => doc.data())),
    error => {
      console.error(error)
      captureException(toSentry(error), {
        contexts: {
          ListenToTransactionsByUserId: { orgId, userId },
        },
        tags: { orgId },
      })
    }
  )
}

export async function GetTransactionById({
  orgId,
  transactionId,
}: {
  orgId: string
  transactionId: string
}) {
  try {
    const db = getFirestore()
    const docRef = doc(
      db,
      `organizations/${orgId}/transactions/${transactionId}`
    ).withConverter(storedId(OrgTransaction))
    return await getDoc(docRef).then(doc => doc.data())
  } catch (error) {
    console.error(error)
    captureException(toSentry(error), {
      contexts: {
        GetTransactionById: { orgId, transactionId },
      },
      tags: { orgId },
    })
  }
}

export async function GetAvailablePersonalFundsByUserId({
  orgId,
  userId,
}: {
  orgId: string
  userId: string
}) {
  if (!orgId || !userId) return undefined
  try {
    const db = getFirestore()
    const docRef = doc(
      db,
      `organizations/${orgId}/users/${userId}/eligibleCategories/allMerchants`
    ).withConverter(converter(EligibleCategory))
    return await getDoc(docRef).then(
      doc => doc.data()?.availablePersonalFunds ?? 0
    )
  } catch (error) {
    console.error(error)
    captureException(toSentry(error), {
      contexts: {
        GetAvailablePersonalFundsByUserId: { orgId, userId },
      },
      tags: { orgId },
    })
  }
}

export async function ListBalanceTransactionsBySourceId({
  orgId,
  userId,
  sourceId,
}: {
  orgId: string
  userId: string
  sourceId: string
}) {
  try {
    const db = getFirestore()
    const colRef = collection(
      db,
      `organizations/${orgId}/users/${userId}/balanceTransactions`
    ).withConverter(storedId(BalanceTransaction))
    const q = query(colRef, where('sourceId', '==', sourceId))
    const qSnaps = await getDocs(q)
    return qSnaps.docs.map(doc => doc.data())
  } catch (error) {
    console.error(error)
    captureException(toSentry(error), {
      contexts: {
        ListBalanceTransactionsBySourceId: { orgId, userId, sourceId },
      },
      tags: { orgId },
    })
  }
  return []
}

export async function ListBalanceTransactionsByAuthId({
  orgId,
  userId,
  authId,
}: {
  orgId: string
  userId: string
  authId: string
}) {
  if (!orgId || !userId || !authId) return undefined

  try {
    const db = getFirestore()
    const colRef = collection(
      db,
      `organizations/${orgId}/users/${userId}/balanceTransactions`
    ).withConverter(storedId(BalanceTransaction))
    const q = query(colRef, where('authId', '==', authId))
    return await getDocs(q).then(query => query.docs.map(doc => doc.data()))
  } catch (error) {
    console.error(error)
    captureException(toSentry(error), {
      contexts: {
        ListBalanceTransactionsByAuthId: { orgId, userId, authId },
      },
      tags: { orgId },
    })
  }
}
