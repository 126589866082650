import { ListProgramTemplates } from 'api/databaseCalls'
import { ProgramTemplate } from 'gen/perkup/v1/contentful_pb'
import { allTemplateCategories, CategoryTitle } from 'pages/Templates/constants'
import { useEffect, useState } from 'react'
import { useInView } from 'react-intersection-observer'

export default function useInfiniteProgramTemplates({
  startingMaxIndex,
}: {
  startingMaxIndex: number
}) {
  const [
    programTemplateCategoriesToRender,
    setProgramTemplateCategoriesToRender,
  ] = useState<CategoryTitle[]>([])
  const [programTemplates, setProgramTemplates] = useState<ProgramTemplate[]>(
    []
  )
  const [maxIndex, setMaxIndex] = useState(startingMaxIndex)
  const [isLoading, setIsLoading] = useState(false)

  // On init load, get program templates and figure which cats to render
  useEffect(() => {
    setIsLoading(true)
    const initialCategories = allTemplateCategories.slice(0, startingMaxIndex)
    setProgramTemplateCategoriesToRender(initialCategories)

    ListProgramTemplates()
      .then(res => setProgramTemplates(res))
      .finally(() => setIsLoading(false))
  }, [startingMaxIndex])

  // On callback, add more categories to render
  const handleGetMoreCategories = () => {
    if (
      programTemplateCategoriesToRender.length === allTemplateCategories.length
    )
      return
    const startingIndex = maxIndex

    const newMaxIndex = maxIndex + 2

    const newCategories = allTemplateCategories.slice(
      startingIndex,
      newMaxIndex
    )

    setProgramTemplateCategoriesToRender(oldCategories => [
      ...oldCategories,
      ...newCategories,
    ])
    setMaxIndex(newMaxIndex)
  }

  // Observe last item, and trigger callback if is in view
  const { ref: lastCategoryRef } = useInView({
    onChange: inView => {
      if (inView) {
        handleGetMoreCategories()
      }
    },
  })

  return {
    programTemplates,
    programTemplateCategoriesToRender,
    lastCategoryRef,
    maxIndex,
    isLoadingProgramTemplates: isLoading,
  }
}
