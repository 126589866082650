import { protoInt64 } from '@bufbuild/protobuf'
import { captureException } from '@sentry/react'
import { bannedTaxonomies } from 'constants/amazon'
import { countryIdToCountryCode } from 'constants/countries'
import { getAuth } from 'firebase/auth'
import { Search as SearchClient } from 'gen/amazon/search_connect'
import { ListProductsRequest } from 'gen/amazon/search_pb'
import { Country } from 'gen/perkup/v1/contentful_pb'
import { ShippingAddress } from 'gen/perkup/v1/root_user_pb'
import { createClient } from 'hooks/useClient'
import { useCallback, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import { AmazonProduct, AmazonSearchResult, SearchAmazonRequest } from 'types'
import { toSentry } from 'utils/sentry'

export function useInfiniteAmazonScroll({
  country,
  keywordsParams,
  categoryParams,
  priceRefinementParams,
  searchRefinementParams,
  shippingAddress,
  ipPostalCode,
}: {
  country: Country
  keywordsParams: string
  categoryParams: string
  priceRefinementParams: string
  searchRefinementParams: string
  shippingAddress: ShippingAddress | undefined
  ipPostalCode: string | undefined
}) {
  const [isLoadingMore, setIsLoadingMore] = useState(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [products, setProducts] = useState<AmazonProduct[]>([])
  const [infiniteIsLoading, setInfinteIsLoading] = useState<boolean>(false)
  const [searchResult, setSearchResult] = useState<
    AmazonSearchResult | undefined
  >()
  const [error, setError] = useState<string>('')
  const [filteredProductAmount, setFilteredProductAmount] = useState<number>(0)

  const handleSearchAmazon = useCallback(
    async (data: SearchAmazonRequest, pageNumber: number = 0) => {
      setIsLoading(true)
      setFilteredProductAmount(0)

      let postalCode = ipPostalCode || ''
      let countryCode = countryIdToCountryCode.get(country?.id) || 'US'

      if (shippingAddress) {
        postalCode = shippingAddress?.postalCode
        countryCode = shippingAddress?.country
      }

      const listProductsReq = new ListProductsRequest({
        keywords: data.keywords || keywordsParams,
        category: data.category || categoryParams,
        pageNumber: protoInt64.parse(pageNumber),
        pageSize: protoInt64.zero,
        searchRefinement: [],
        postalCode,
        countryCode,
      })
      if (!data.resetRefinements) {
        const searchRefinement = data.searchRefinement || searchRefinementParams
        if (searchRefinement?.length) {
          listProductsReq.searchRefinement.push(searchRefinement)
        }

        const priceRefines = data.priceRefinement || priceRefinementParams
        if (priceRefines?.length) {
          listProductsReq.searchRefinement.push(priceRefines)
        }
      }

      const accessToken = await getAuth().currentUser?.getIdToken()
      createClient(SearchClient)
        .listProducts(listProductsReq, {
          headers: { Authorization: `Bearer ${accessToken}` },
        })
        .then(Response => {
          setError('')
          const searchResponse = JSON.parse(
            Response.searchResponse
          ) as AmazonSearchResult
          setSearchResult(() => searchResponse)
          setProducts(() =>
            [...searchResponse.products].filter(product => {
              // Filters out gift cards, vouchers, and digital currencies
              if (!product.taxonomies?.length) return false
              return !bannedTaxonomies.includes(
                product?.taxonomies[0].taxonomyCode
              )
            })
          )
        })
        .catch((error: any) => {
          console.error(error)
          setError('Error retrieving Amazon results')
          captureException(toSentry(error), {
            contexts: {
              listProducts: { postalCode: shippingAddress?.postalCode },
            },
          })
        })
        .finally(() => {
          setIsLoading(false)
        })
    },
    [
      country?.id,
      ipPostalCode,
      shippingAddress,
      keywordsParams,
      categoryParams,
      searchRefinementParams,
      priceRefinementParams,
    ]
  )

  const handleGetMoreResults = useCallback(async () => {
    if (isLoading || infiniteIsLoading) return
    const accessToken = await getAuth().currentUser?.getIdToken()
    const totalProducts = searchResult?.matchingProductCount || 1
    const totalPages = searchResult?.numberOfPages || 1
    const currentPageNumber = Math.ceil(
      (products.length + filteredProductAmount) / (totalProducts / totalPages)
    )
    let postalCode = ipPostalCode || ''
    let countryCode = countryIdToCountryCode.get(country?.id) || 'US'
    if (shippingAddress) {
      countryCode = shippingAddress?.country
      postalCode = shippingAddress?.postalCode
    }
    const listProductsReq = new ListProductsRequest({
      keywords: keywordsParams,
      category: categoryParams,
      pageNumber: protoInt64.parse(currentPageNumber),
      pageSize: protoInt64.zero,
      searchRefinement: [],
      postalCode,
      countryCode,
    })
    const searchRefinement = searchRefinementParams
    if (searchRefinement?.length) {
      listProductsReq.searchRefinement.push(searchRefinement)
    }
    if (priceRefinementParams?.length) {
      listProductsReq.searchRefinement.push(priceRefinementParams)
    }
    setInfinteIsLoading(true)
    createClient(SearchClient)
      .listProducts(listProductsReq, {
        headers: { Authorization: `Bearer ${accessToken}` },
      })
      .then(Response => {
        const searchResponse = JSON.parse(
          Response.searchResponse
        ) as AmazonSearchResult
        const filteredResult = [...searchResponse.products].filter(product => {
          // Filters out gift cards, vouchers, and digital currencies
          if (!product.taxonomies?.length) return false
          return !bannedTaxonomies.includes(product?.taxonomies[0].taxonomyCode)
        })
        setProducts(prevState => [...prevState, ...filteredResult])
        setFilteredProductAmount(
          prevAmount =>
            prevAmount + searchResponse.products.length - filteredResult.length
        )
      })
      .catch((error: any) => {
        console.error(error)
        setError('Error retrieving Amazon results')
        captureException(toSentry(error), {
          contexts: {
            listProducts: { postalCode: shippingAddress?.postalCode },
          },
        })
      })
      .finally(() => {
        setInfinteIsLoading(false)
      })
  }, [
    isLoading,
    infiniteIsLoading,
    searchResult?.matchingProductCount,
    searchResult?.numberOfPages,
    products,
    country?.id,
    ipPostalCode,
    shippingAddress,
    keywordsParams,
    categoryParams,
    searchRefinementParams,
    priceRefinementParams,
    filteredProductAmount,
  ])
  const { ref: lastResultRef } = useInView({
    onChange: inView => {
      const totalProducts = searchResult?.matchingProductCount ?? 1
      const totalPages = searchResult?.numberOfPages ?? 1
      const currentPageNumber = Math.ceil(
        (products.length + filteredProductAmount) / (totalProducts / totalPages)
      )
      if (inView && currentPageNumber < totalPages && currentPageNumber !== 0) {
        setIsLoadingMore(true)
        handleGetMoreResults()
        setIsLoadingMore(false)
      }
    },
  })
  return {
    products,
    lastResultRef,
    isLoading,
    isLoadingMore,
    infiniteIsLoading,
    handleSearchAmazon,
    error,
    searchResult,
  }
}
