import { PRIVACY_URL, TERMS_OF_SERVICE_URL } from 'constants/perkupLinks'
import { Link, Text } from 'evergreen-ui'

export function SignUpTerms() {
  return (
    <Text size={300} color="muted">
      By signing up, you agree to our{' '}
      <Link size={300} href={TERMS_OF_SERVICE_URL} target="_blank">
        Terms of Service
      </Link>{' '}
      and{' '}
      <Link size={300} href={PRIVACY_URL} target="_blank">
        Privacy Policy
      </Link>
      .
    </Text>
  )
}
