// @generated by protoc-gen-connect-es v1.1.4 with parameter "target=ts"
// @generated from file perkup/v1/slack.proto (package perkup.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { JoinChannelRequest } from "./slack_pb.js";
import { Empty } from "../../google/protobuf/empty_pb.js";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service perkup.v1.SlackChannel
 */
export const SlackChannel = {
  typeName: "perkup.v1.SlackChannel",
  methods: {
    /**
     * @generated from rpc perkup.v1.SlackChannel.JoinSlackChannel
     */
    joinSlackChannel: {
      name: "JoinSlackChannel",
      I: JoinChannelRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
  }
} as const;

