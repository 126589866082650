import { Switch } from 'antd'
import { Pane, Strong, Text } from 'evergreen-ui'

export default function ToggleWithTitleAndSubtitle({
  onChange,
  title,
  subtitle,
  checked,
}: {
  onChange: (value: boolean) => void
  title: string
  subtitle: string
  checked: boolean
}) {
  return (
    <Pane display="flex" gap={32}>
      <Switch onChange={onChange} title={title} checked={checked} />
      <Pane display="flex" flexDirection="column" gap={8}>
        <Strong>{title}</Strong>
        <Text>{subtitle}</Text>
      </Pane>
    </Pane>
  )
}
