import { ListenToProgramById } from 'api/databaseCalls'
import { Program } from 'gen/perkup/v1/program_pb'
import useIds from 'hooks/useIds'
import { useEffect, useState } from 'react'

export function useListenToProgram({ programId }: { programId?: string }) {
  const { orgId } = useIds()
  const [program, setProgram] = useState<Program>()
  const [hasLoaded, setHasLoaded] = useState(false)

  useEffect(() => {
    if (!orgId || !programId) {
      return undefined
    }

    return ListenToProgramById({
      orgId,
      programId,
      cb: program => {
        setProgram(program)
        setHasLoaded(true)
      },
    })
  }, [orgId, programId])

  return { program, hasLoaded }
}
