import { CopyOutlined } from '@ant-design/icons'
import { Flex, Tooltip } from 'antd'
import { PerkIconTooltipButton } from 'components'
import { Text } from 'evergreen-ui'
import { useState } from 'react'
import { copyToClipboard } from 'utils'

export function TruncatedCopyableText({
  text,
  textMaxWidth,
  type,
}: {
  text: string
  textMaxWidth?: number
  type?: string
}) {
  const [isHovered, setIsHovered] = useState(false)

  const handleCopyClick = (e: React.MouseEvent) => {
    e.preventDefault()
    e.stopPropagation()
    copyToClipboard(text, type)
  }
  return (
    <Flex
      gap={8}
      align="center"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={handleCopyClick}
      style={{ cursor: 'pointer', height: 22 }}
    >
      <Tooltip title={text}>
        <Text className="one-line-truncate" maxWidth={textMaxWidth}>
          {text}
        </Text>
      </Tooltip>

      {isHovered && (
        <PerkIconTooltipButton
          title="Copy to clipboard"
          icon={<CopyOutlined />}
          type="text"
        />
      )}
    </Flex>
  )
}
