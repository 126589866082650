import { LOGS, REPORTS } from 'constants/routes'
import NoMatch404 from 'pages/NoMatch404'
import { Route } from 'react-router'
import { LegacyInsightsDashboard } from './LegacyInsightsDashboard'
import { NotificationLogs } from './NotificationLogs'
import { RewardReports } from './Reports'
import insightsDashboard from './insights-dashboard'

const Insights = (
  <Route>
    <Route path="" index {...insightsDashboard} />
    <Route path="legacy" element={<LegacyInsightsDashboard />} />
    <Route path={REPORTS} element={<RewardReports />} />
    <Route path={LOGS} element={<NotificationLogs />} />
    <Route path="*" element={<NoMatch404 />} />
  </Route>
)

export default Insights
