import { ListSendableOrgAccounts } from 'api/databaseCalls'
import { IndividualContext, OrgContext } from 'context'
import { Account } from 'gen/perkup/v1/account_pb'
import { useContext, useEffect, useState } from 'react'

export function useSendableAccounts() {
  const org = useContext(OrgContext)
  const individual = useContext(IndividualContext)

  const orgId = org?.id
  const individualRole = individual?.role
  const individualId = individual?.id

  const [sendableAccounts, setSendableAccounts] = useState<Account[]>([])
  const [hasLoadedAccounts, setHasLoadedAccounts] = useState(false)

  useEffect(() => {
    if (!orgId || !individualRole || !individualId) return
    ListSendableOrgAccounts({
      individualId,
      individualRole,
      orgId,
    })
      .then(accounts => {
        if (accounts) setSendableAccounts(accounts)
      })
      .finally(() => {
        setHasLoadedAccounts(true)
      })
  }, [orgId, individualId, individualRole])

  sendableAccounts.sort((a, b) => {
    return Number(a.balance) - Number(b.balance)
  })

  return { sendableAccounts, hasLoadedAccounts }
}
