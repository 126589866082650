import {
  ApiOutlined,
  ArrowDownOutlined,
  ArrowUpOutlined,
  MonitorOutlined,
} from '@ant-design/icons'
import { useCubeQuery } from '@cubejs-client/react'
import { ChartConfig, ChartContainer } from '@repo/shadcn/es/chart'
import { Skeleton, Tag } from 'antd'
import { PerkEmpty } from 'components'
import { Heading, Text } from 'evergreen-ui'
import { useDevSafeOrgId } from 'hooks/useDevSafeOrgId'
import { Bar, BarChart, LabelList, XAxis, YAxis } from 'recharts'
import { DateRangeArray, useInsightsContext } from './insights-context'

function useSendersBreakdownQuery(dateRange: DateRangeArray | undefined) {
  const orgId = useDevSafeOrgId()

  const { insightsGlobalFilters } = useInsightsContext()
  const { resultSet, isLoading, error } = useCubeQuery({
    limit: 5000,
    measures: ['programs.distinctOwners'],
    dimensions: ['programs.status'],
    filters: [
      {
        member: 'organizations.id',
        operator: 'equals',
        values: [orgId],
      },
      ...insightsGlobalFilters,
    ],
    timeDimensions: [
      {
        dimension: 'programs.created',
        dateRange,
      },
    ],
  })

  return [resultSet, isLoading, error] as const
}

function computeChartData(
  resultSet: NonNullable<ReturnType<typeof useSendersBreakdownQuery>[0]>
) {
  const [draft, active, inactive] = resultSet
    .chartPivot()
    .map(row => row['programs.distinctOwners'] as number)

  const numOwnersOfDraftPrograms = draft || 0
  const numOwnersOfActivePrograms = active || 0
  const numOwnersOfInactivePrograms = inactive || 0

  const totalNumOfOwners =
    numOwnersOfDraftPrograms +
    numOwnersOfActivePrograms +
    numOwnersOfInactivePrograms

  const totalNumOfSenders =
    numOwnersOfActivePrograms + numOwnersOfInactivePrograms

  // const showGoodJobAlert = totalNumOfSenders / totalNumOfOwners > 0.75

  const chartData = [
    {
      status: 'draft', // For whatever reason, the data is being represented as "draft"
      numOwners: totalNumOfOwners,
      fill: 'var(--color-draft)',
    },
    {
      status: 'sent',
      numOwners: totalNumOfSenders,
      fill: 'var(--color-sent)',
    },
  ]
  const chartConfig = {
    numOwners: {
      label: 'Owners',
    },
    draft: {
      label: 'Draft', // For whatever reason, the data is being represented as "draft"
      color: 'hsl(var(--chart-1))',
    },
    sent: {
      label: 'Sent',
      color: 'hsl(var(--chart-2))',
    },
  } satisfies ChartConfig

  return {
    chartData,
    chartConfig,
    totalNumOfSenders,
    numOwnersOfDraftPrograms,
  }
}

export function SendersBreakdown() {
  const { dateRange, previousPeriodDateRange } = useInsightsContext()

  const [resultSet, isLoading, error] = useSendersBreakdownQuery(dateRange)
  const [previousResultSet] = useSendersBreakdownQuery(previousPeriodDateRange)

  if (isLoading) {
    return (
      <section className="flex flex-col gap-6">
        <Heading>Senders</Heading>
        <Skeleton active paragraph={{ rows: 6 }} />
      </section>
    )
  }

  if (error || !resultSet) {
    return (
      <section className="flex flex-col gap-6">
        <Heading>Senders</Heading>
        <PerkEmpty
          iconNode={
            <ApiOutlined
              className="text-muted-foreground"
              style={{ fontSize: 32 }}
            />
          }
          header="Oops! Something went wrong"
          description="We couldn't load your data. Please try again later."
        />
      </section>
    )
  }

  if (resultSet.chartPivot().length === 0) {
    return (
      <section className="flex flex-col gap-6">
        <Heading>Senders</Heading>
        <PerkEmpty
          iconNode={
            <MonitorOutlined
              className="text-muted-foreground"
              style={{ fontSize: 32 }}
            />
          }
          header="Nothing found"
          description="Try a different date range or filter."
        />
      </section>
    )
  }

  const {
    chartData,
    chartConfig,
    totalNumOfSenders,
    numOwnersOfDraftPrograms,
  } = computeChartData(resultSet)

  const previousPeriodTotalNumOfSenders =
    dateRange && previousResultSet
      ? computeChartData(previousResultSet).totalNumOfSenders
      : undefined

  const periodDiff =
    previousPeriodTotalNumOfSenders !== undefined &&
    previousPeriodTotalNumOfSenders !== totalNumOfSenders
      ? {
          hasIncreased: totalNumOfSenders > previousPeriodTotalNumOfSenders,
          percentual: Math.abs(
            ((totalNumOfSenders - previousPeriodTotalNumOfSenders) /
              previousPeriodTotalNumOfSenders) *
              100
          ),
        }
      : undefined

  return (
    <section className="flex flex-col gap-6 h-full">
      <Heading>Senders</Heading>

      <div className="h-full flex flex-col gap-4 items-center">
        <ChartContainer
          className="w-full h-full max-w-full max-h-full"
          config={chartConfig}
        >
          <BarChart
            accessibilityLayer
            data={chartData}
            margin={{
              top: 32,
            }}
          >
            <XAxis
              dataKey="status"
              tickLine={false}
              tickMargin={10}
              axisLine={false}
              tickFormatter={value =>
                chartConfig[value as keyof typeof chartConfig]?.label
              }
            />

            <YAxis dataKey="numOwners" type="number" hide />

            <Bar dataKey="numOwners" layout="vertical" radius={8}>
              <LabelList
                position="top"
                offset={16}
                className="fill-foreground"
                fontSize={12}
                fontWeight={700}
              />
            </Bar>
          </BarChart>
        </ChartContainer>

        <div className="flex flex-col items-center">
          <Heading size={700}>{totalNumOfSenders}</Heading>
          <Text size={300} color="muted">
            Senders
          </Text>
          {periodDiff && (
            <Tag
              className="mt-2"
              color={periodDiff.hasIncreased ? 'success' : 'error'}
              icon={
                periodDiff.hasIncreased ? (
                  <ArrowUpOutlined />
                ) : (
                  <ArrowDownOutlined />
                )
              }
            >
              {periodDiff.percentual.toFixed(0)}%
            </Tag>
          )}
        </div>

        <div className="flex flex-wrap gap-2 items-center justify-center">
          <Heading>{numOwnersOfDraftPrograms}</Heading>
          <Text size={300} color="muted" className="text-center">
            Users created a draft but didn&apos;t send
          </Text>
        </div>

        {/* <Alert
          className="w-full text-xs"
          message={
            showGoodJobAlert
              ? 'Great job! Over 75% of rewards that were created have been sent. Your employees must be super happy!'
              : 'Notable dropoff between drafts created and rewards sent.'
          }
          type="info"
          showIcon
          icon={<BulbOutlined />}
        /> */}
      </div>
    </section>
  )
}
