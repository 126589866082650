import { Timestamp } from '@bufbuild/protobuf'
import { createOrgTemplate } from 'api/databaseCalls/writes/templates'
import { allApprovedCats } from 'constants/EligibleCategories'
import { sampleBanners } from 'constants/newReward/emails'
import { defaultOccasions } from 'constants/newReward/occasions'
import { DEFAULT_ROUTES } from 'constants/routes'
import { toaster } from 'evergreen-ui'
import { Template, Template_Access } from 'gen/perkup/v1/org_template_pb'
import { ProgramOccasion } from 'gen/perkup/v1/program_pb'
import useIds from 'hooks/useIds'
import { sample } from 'lodash-es'
import { useLocation, useNavigate } from 'react-router'
import TemplateForm from './components/TemplateForm'

const defaultNewTemplate = new Template({
  name: '',
  coreValues: {
    values: [],
    writable: false,
  },
  budget: {
    amounts: [],
    writable: false,
  },
  occasion: ProgramOccasion.appreciation,
  email: {
    title: sample(defaultOccasions[0].emailData)?.title || '',
    message: sample(defaultOccasions[0].emailData)?.note || '',
    writable: true,
  },
  image: {
    url: sample(sampleBanners) || '',
    writable: true,
  },
  expiry: {
    writable: false,
  },
  eligibleRewards: {
    cash: true,
    swag: true,
    gift: true,
    giftCard: true,
  },
  access: Template_Access.all,
  approvedCategories: allApprovedCats,
  isDeleted: false,
})

export function NewTemplate() {
  const navigate = useNavigate()
  const { state } = useLocation()
  const { orgId, individualId } = useIds()

  const handleCreateTemplate = async (template: Template) => {
    const formattedTemplate = new Template({
      ...template,
      created: Timestamp.now(),
      ownerId: individualId,
    })
    await createOrgTemplate({ orgId, template: formattedTemplate })
    toaster.success('Successfully created template')
    navigate(DEFAULT_ROUTES.ORGANIZATION.REWARDS.TEMPLATES.ROOT)
  }

  const defaultTemplate = state?.template
    ? new Template(state.template)
    : defaultNewTemplate

  return (
    <TemplateForm
      defaultTemplate={defaultTemplate}
      onConfirmTemplate={handleCreateTemplate}
    />
  )
}
