import * as ROUTES from 'constants/routes'
import { SwagCollectionIdsContext } from 'context'
import { useContext } from 'react'

export function useSwagStoreRoute() {
  const swagCollectionIds = useContext(SwagCollectionIdsContext)

  let swagRoute = ROUTES.SHOP

  if (swagCollectionIds.length === 1) {
    swagRoute = `${ROUTES.SWAG}/${swagCollectionIds[0]}`
  }

  if (swagCollectionIds.length > 1) {
    swagRoute = ROUTES.SWAG
  }

  return { swagRoute }
}
