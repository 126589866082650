import { ListProductVariantsByIds } from 'api/databaseCalls'
import { ProductVariant } from 'gen/perkup/v1/product_variant_pb'
import { useEffect, useState } from 'react'

export default function useProductVariantsByIds({
  variantIds,
}: {
  variantIds: string[]
}) {
  const [productVariants, setProductVariants] = useState<ProductVariant[]>([])
  const [isLoadingInitital, setIsLoadingInitial] = useState(true)
  const [isLoadingMore, setIsLoadingMore] = useState(false)

  useEffect(() => {
    setIsLoadingMore(true)
    ListProductVariantsByIds({ ids: variantIds })
      .then(res => {
        if (!res) {
          setProductVariants([])
          setIsLoadingInitial(false)
          setIsLoadingMore(false)
          return
        }
        setProductVariants(res)
      })
      .finally(() => {
        setIsLoadingInitial(false)
        setIsLoadingMore(false)
      })
  }, [variantIds])

  return {
    productVariants,
    isLoading: isLoadingInitital || isLoadingMore,
    isLoadingInitital,
    isLoadingMore,
  }
}
