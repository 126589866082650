// Amazon search params
export const KEYWORDS = 'q'
export const CATEGORY = 'c'
export const SEARCH_REFINEMENTS = 'sR'
export const PRICE_REFINEMENT = 'pR'
export const ASIN = 'asin'
export const OFFER_ID = 'offerId'
export const QUANITTY = 'qty'

export const SHIPPING_ADDRESS_ID = 'sa'

// ProductVariant search params
export const PRODUCT_VARIANT_ID = 'pv'

// Gift search params

export const COUNTRY_ID = 'cid'

// Program search params

// Slack search params
export const SLACK_CODE = 'code'
export const SLACK_ERROR = 'error'
export const SLACK_STATE = 'state'

// Browse gift filter params
export const MIN_PRICE = 'min'
export const MAX_PRICE = 'max'

// WorkOS
export const CODE = 'code'

// New reward
export const NEW_REWARD_PARAM = 'programId'

export const COLLECTION_PARAM = 'collectionId'

export const INDIVIDUAL_ID = 'individualId'

export const CREATE_ORG = 'createOrg'

export const TEMPLATE_PARAM = 'templateId'

export const SURVEY = 'survey'

// Swag store
export const COLLECTION = 'collection'

// Org Swag
export const AURIGMA_PRODUCT_LINK_ID = 'id'
export const AURIGMA_PRODUCT_LINK_VERSION_ID = 'productVersionId'
export const AURIGMA_PRODUCT_LINK_PRODUCT_ID = 'productId'
export const AURIGMA_PRODUCT_LINK_FILTER_ID = 'productFilterId'
export const AURIGMA_PRODUCT_LINK_STOREFRONT_ID = 'storefrontProductId'
