import { CheckOutlined, CopyOutlined } from '@ant-design/icons'
import { PerkIconTooltipButton } from 'components'
import { Pane, Text } from 'evergreen-ui'
import { useState } from 'react'
import { copyToClipboard } from 'utils'

export function LinkBox({
  href,
  copyText,
}: {
  href: string
  copyText: string
}) {
  const [isCopied, setIsCopied] = useState(false)
  return (
    <Pane
      display="flex"
      borderRadius={8}
      padding={8}
      background="tint2"
      justifyContent="space-between"
      width="fit-content"
      alignItems="center"
      gap={16}
    >
      <Text>{href}</Text>
      {isCopied ? (
        <CheckOutlined />
      ) : (
        <PerkIconTooltipButton
          title={copyText}
          icon={<CopyOutlined />}
          type="text"
          onClick={() => {
            copyToClipboard(href, 'link')
            setIsCopied(true)
          }}
        />
      )}
    </Pane>
  )
}
