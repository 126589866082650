export const PAGE_MAX_WIDTH = 1400

export const FORM_INPUT_MAX_WIDTH = 450

export const SECTION_HEADING_SIZE = 600

export const TINTED_BACKGROUND_COLOR = 'tint2'

export const ORDER_SUMMARY_FORM_WIDTH = 344

export const SWAG_SECTION_MAX_WIDTH = 1200

export const SWAG_SECTION_MIN_WIDTH = 840
