import { createContentfulAssetUrl } from 'utils'

const OrangeCard = createContentfulAssetUrl({
  url: 'knc1roo454gw/76DDSNZFbcCPZ7byxfyIlp/333a38d39a7fdab02ee6056cfc0734ed/OrangeCard.png',
  height: 1200,
  width: 1200,
})

const LunchCard = createContentfulAssetUrl({
  url: 'knc1roo454gw/3LbkuLTyF0gkGcO4HhLX97/72a647572d67d2ee144b1be2defcedf9/LunchCard.png',
  height: 1200,
  width: 1200,
})

export { LunchCard, OrangeCard }
