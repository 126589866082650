import { Checkbox, Flex } from 'antd'
import { TemplateContext } from 'context'
import { Heading, Text } from 'evergreen-ui'
import { Template_EligibleRewards } from 'gen/perkup/v1/org_template_pb'
import { useContext } from 'react'
import { getEligibleTemplateRewardLabel } from 'utils/templates'
import { eligibleRewardKeys } from '../constants'

export function TemplateEligibleRewardsForm({
  onEligibleRewardsChange,
}: {
  onEligibleRewardsChange: (
    newEligibleRewards: Template_EligibleRewards
  ) => void
}) {
  const template = useContext(TemplateContext)
  const eligibleRewards = template?.eligibleRewards
  if (!eligibleRewards) return null

  const eligibleRewardOptions = eligibleRewardKeys.map(rewardKey => {
    const label = getEligibleTemplateRewardLabel(rewardKey)
    return {
      key: rewardKey,
      label,
      value: !!eligibleRewards[rewardKey],
    }
  })

  return (
    <Flex vertical gap={8}>
      <Heading>Reward</Heading>
      <Flex gap={32}>
        {eligibleRewardOptions.map(rewardOption => {
          return (
            <Flex key={rewardOption.key} align="center" gap={8}>
              <Checkbox
                checked={rewardOption.value}
                onChange={e => {
                  onEligibleRewardsChange(
                    new Template_EligibleRewards({
                      ...eligibleRewards,
                      [rewardOption.key]: e.target.checked,
                    })
                  )
                }}
              />
              <Text>{rewardOption.label}</Text>
            </Flex>
          )
        })}
      </Flex>
    </Flex>
  )
}
