import { captureException } from '@sentry/react'
import {
  collection,
  doc,
  documentId,
  getDoc,
  getDocs,
  getFirestore,
  onSnapshot,
  orderBy,
  query,
  where,
} from 'firebase/firestore'
import { EligibleCategory } from 'gen/perkup/v1/org_user_pb'
import {
  RootInvitedUser,
  RootUser,
  ShippingAddress,
} from 'gen/perkup/v1/root_user_pb'
import sum from 'lodash-es/sum'
import React from 'react'
import { converter, storedId } from 'utils/firestore'
import { toSentry } from 'utils/sentry'
import { createUser } from '../writes/users'

/**
 * USERS
 */
export function ListenCreateToUserById({
  id,
  cb,
}: {
  id: string
  cb: React.Dispatch<React.SetStateAction<RootUser | undefined>>
}) {
  const db = getFirestore()
  const docRef = doc(db, `users/${id}`).withConverter(converter(RootUser))
  return onSnapshot(
    docRef,
    doc => {
      if (doc.data()) {
        cb(doc.data())
      } else {
        // Create user if user doesn't exist
        createUser()
      }
    },
    error => {
      console.error(error)
      captureException(toSentry(error), {
        contexts: { ListenCreateToUserById: { id } },
      })
    }
  )
}

export async function GetUserById({ userId }: { userId: string }) {
  try {
    const db = getFirestore()
    const docRef = doc(db, `users/${userId}`).withConverter(converter(RootUser))
    return await getDoc(docRef).then(doc => doc.data())
  } catch (error) {
    console.error(error)
    captureException(toSentry(error), {
      contexts: {
        GetUserById: { userId },
      },
    })
  }
}

export function ListenToShippingAddressesByUserId({
  userId,
  cb,
}: {
  userId: string
  cb: React.Dispatch<React.SetStateAction<ShippingAddress[]>>
}) {
  const db = getFirestore()
  const colRef = collection(
    db,
    `users/${userId}/shippingAddresses`
  ).withConverter(converter(ShippingAddress))
  return onSnapshot(
    query(colRef),
    query => cb(query.docs.map(doc => doc.data())),
    error => {
      cb([])
      console.error(error)
      captureException(toSentry(error), {
        contexts: {
          ListenToShippingAddressesByUserId: { userId },
        },
      })
    }
  )
}

export async function ListShippingAddressesByUserId({
  userId,
}: {
  userId: string
}) {
  const shippingAddresses: ShippingAddress[] = []
  const db = getFirestore()
  const colRef = collection(
    db,
    `users/${userId}/shippingAddresses`
  ).withConverter(converter(ShippingAddress))
  const q = query(colRef, orderBy('line1', 'desc'))
  await getDocs(q)
    .then(query =>
      query.docs.forEach(doc => shippingAddresses.push(doc.data()))
    )
    .catch(error => {
      console.error(error)
      captureException(toSentry(error), {
        contexts: {
          ListShippingAddressesByUserId: { userId },
        },
      })
    })

  return shippingAddresses
}

export async function GetInvitesByEmail({ email }: { email: string }) {
  const invites: RootInvitedUser[] = []

  try {
    const db = getFirestore()
    const colRef = collection(db, 'invites').withConverter(
      storedId(RootInvitedUser)
    )
    const q = query(colRef, where('email', '==', email))
    await getDocs(q).then(querySnapshot =>
      querySnapshot.forEach(doc => invites.push(doc.data()))
    )
  } catch (error) {
    console.error(error)
    captureException(toSentry(error), {
      contexts: {
        GetInvitesByEmail: { email },
      },
    })
  }

  return invites
}

export async function ListEligibleCategories({
  orgId,
  userId,
}: {
  orgId: string
  userId: string
}) {
  const eligibleCategories: EligibleCategory[] = []
  try {
    const db = getFirestore()
    const colRef = collection(
      db,
      `organizations/${orgId}/users/${userId}/eligibleCategories`
    ).withConverter(converter(EligibleCategory))
    await getDocs(query(colRef)).then(query =>
      query.docs.forEach(doc => {
        eligibleCategories.push(doc.data())
      })
    )
  } catch (error) {
    console.error(error)
    captureException(toSentry(error), {
      contexts: {
        ListEligibleCategories: { orgId, userId },
      },
    })
  }
  return eligibleCategories
}

export function ListenToEligibleCategories({
  orgId,
  userId,
  eligibleCategoryIds,
  cb,
}: {
  orgId: string
  userId: string
  eligibleCategoryIds: string[]
  cb: React.Dispatch<React.SetStateAction<number>>
}) {
  const db = getFirestore()
  const colRef = collection(
    db,
    `organizations/${orgId}/users/${userId}/eligibleCategories`
  ).withConverter(converter(EligibleCategory))
  return onSnapshot(
    query(colRef),
    query =>
      cb(
        sum(
          query.docs
            .filter(doc => eligibleCategoryIds.includes(doc.id))
            .map(doc => doc.data().amount)
        )
      ),
    error => {
      console.error(error)
      captureException(toSentry(error), {
        contexts: {
          ListenToEligibleCategories: { orgId, userId, eligibleCategoryIds },
        },
      })
    }
  )
}

export function ListenToEligibleCategoryBalances({
  orgId,
  userId,
  eligibleCategoryIds,
  cb,
}: {
  orgId: string
  userId: string
  eligibleCategoryIds: string[]
  cb: React.Dispatch<React.SetStateAction<EligibleCategory[]>>
}) {
  const db = getFirestore()
  const colRef = collection(
    db,
    `organizations/${orgId}/users/${userId}/eligibleCategories`
  ).withConverter(converter(EligibleCategory))
  const q = query(colRef, where(documentId(), 'in', eligibleCategoryIds))

  return onSnapshot(
    q,
    query => {
      cb(query.docs.map(doc => doc.data()))
    },
    error => {
      console.error(error)
      captureException(toSentry(error), {
        contexts: {
          ListenToCategoryBalances: { orgId, userId, eligibleCategoryIds },
        },
      })
    }
  )
}

export function ListenToUsersByOrgId({
  orgId,
  cb,
}: {
  orgId: string
  cb: React.Dispatch<React.SetStateAction<RootUser[]>>
}) {
  const db = getFirestore()
  const colRef = collection(db, 'users').withConverter(converter(RootUser))
  const q = query(colRef, where('organizations', 'array-contains', orgId))
  return onSnapshot(
    q,
    query => cb(query.docs.map(doc => doc.data())),
    error => {
      console.error(error)
      captureException(toSentry(error), {
        contexts: { ListenToUsersByOrgId: { orgId } },
        tags: { orgId },
      })
    }
  )
}
