import { ListenToOrgTemplates } from 'api/databaseCalls'
import { Template } from 'gen/perkup/v1/org_template_pb'
import useIds from 'hooks/useIds'
import { useEffect, useState } from 'react'

export function useListenToOrgTemplates() {
  const { orgId } = useIds()
  const [orgTemplates, setOrgTemplates] = useState<Template[]>([])
  const [hasLoaded, setHasLoaded] = useState(false)

  useEffect(() => {
    if (!orgId) return undefined

    return ListenToOrgTemplates({
      orgId,
      cb: setOrgTemplates,
      onRequestComplete: () => setHasLoaded(true),
    })
  }, [orgId])

  return { orgTemplates, hasLoaded }
}
