// @generated by protoc-gen-connect-es v1.1.4 with parameter "target=ts"
// @generated from file amazon/search.proto (package amazon, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { GetProductRequest, GetProductResponse, ListProductsRequest, ListProductsResponse } from "./search_pb.js";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service amazon.Search
 */
export const Search = {
  typeName: "amazon.Search",
  methods: {
    /**
     * @generated from rpc amazon.Search.GetProduct
     */
    getProduct: {
      name: "GetProduct",
      I: GetProductRequest,
      O: GetProductResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc amazon.Search.ListProducts
     */
    listProducts: {
      name: "ListProducts",
      I: ListProductsRequest,
      O: ListProductsResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

