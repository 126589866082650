import { InfoCircleOutlined } from '@ant-design/icons'
import { Tooltip } from 'antd'
import { BulkProductRowCard } from 'components'
import { DEFAULT_ROUTES } from 'constants/routes'
import { ProductVariant } from 'gen/perkup/v1/product_variant_pb'
import { ShippingAddress } from 'gen/perkup/v1/root_user_pb'
import { groupBy } from 'lodash-es'
import { MaybeWithDesign, WithSelectedQuantity } from 'types'
import { getCountryNameFromIso2 } from 'utils'
import { smallDesignHash } from 'utils/canvas-design'

export function MicroLines({
  productVariants,
  invalidProductVariants,
  onChange,
  toPrefix = `${DEFAULT_ROUTES.SWAG.ROOT}/product`,
  onAfterNavigatingTo,
  shippingAddress,
}: {
  productVariants: MaybeWithDesign<WithSelectedQuantity<ProductVariant>>[]
  invalidProductVariants?: ProductVariant[]
  onChange: (
    updatedPvs: MaybeWithDesign<WithSelectedQuantity<ProductVariant>>[]
  ) => void
  toPrefix?: string
  onAfterNavigatingTo?: () => void
  shippingAddress?: ShippingAddress
}) {
  const productVariantsWithoutDesigns = productVariants.filter(
    pv => !pv.canvasDesign
  )

  const unDesignedDictionary = groupBy(
    productVariantsWithoutDesigns,
    'productId'
  )

  const productVariantsWithDesigns = productVariants.filter(
    pv => !!pv.canvasDesign
  )

  const designedDictionary = groupBy(productVariantsWithDesigns, pv =>
    smallDesignHash(pv.productId, pv.canvasDesign?.variables ?? [])
  )

  const handleRemoveEntireUnDesignedProduct = (productId: string) => {
    const updatedUnDesignedPvs = productVariantsWithoutDesigns.filter(
      pv => pv.productId !== productId
    )
    onChange([...productVariantsWithDesigns, ...updatedUnDesignedPvs])
  }

  const handleRemoveEntireUnDesignedProductVariant = (pvId: string) => {
    const updatedUnDesignedPvs = productVariantsWithoutDesigns.filter(
      pv => pv.id !== pvId
    )
    onChange([...productVariantsWithDesigns, ...updatedUnDesignedPvs])
  }

  const handleChangeUndesignedProductVariantQuantity = (
    pvId: string,
    quantity: number
  ) => {
    const updatedUnDesignedPvs = productVariantsWithoutDesigns.map(pv => {
      if (pv.id === pvId) {
        Object.assign(pv, { selectedQuantity: quantity })
      }
      return pv
    })
    onChange([...productVariantsWithDesigns, ...updatedUnDesignedPvs])
  }

  const handleRemoveEntireDesignedProduct = (hash: string) => {
    const updatedDesignedPvs = Object.entries(designedDictionary).reduce(
      (acc, [key, value]) => {
        // If the current hash is not the one we are looking for, just return the current value
        if (key !== hash) {
          return [...acc, ...value]
        }

        // We found the hash we are looking for, so we don't include it in the accumulator
        return acc
      },
      [] as MaybeWithDesign<WithSelectedQuantity<ProductVariant>>[]
    )

    onChange([...productVariantsWithoutDesigns, ...updatedDesignedPvs])
  }

  const handleRemoveEntireDesignedProductVariant = (
    hashId: string,
    pvId: string
  ) => {
    const updatedDesignedPvs = Object.entries(designedDictionary).reduce(
      (acc, [key, value]) => {
        // If the current hash is not the one we are looking for, just return the current value
        if (key !== hashId) {
          return [...acc, ...value]
        }

        // We found the hash we are looking for, so we need to filter out the product variant we are looking for before returning the value
        const updatedDesignedPvsWithoutPv = value.filter(pv => pv.id !== pvId)

        return [...acc, ...updatedDesignedPvsWithoutPv]
      },
      [] as MaybeWithDesign<WithSelectedQuantity<ProductVariant>>[]
    )

    onChange([...productVariantsWithoutDesigns, ...updatedDesignedPvs])
  }

  const handleChangeDesignedProductVariantQuantity = (
    hash: string,
    pvId: string,
    quantity: number
  ) => {
    const updatedDesignedPvs = Object.entries(designedDictionary).reduce(
      (acc, [key, value]) => {
        if (key !== hash) {
          return [...acc, ...value]
        }
        const updatedDesignedPvsWithPv = value.map(pv => {
          if (pv.id === pvId) {
            Object.assign(pv, { selectedQuantity: quantity })
          }
          return pv
        })
        return [...acc, ...updatedDesignedPvsWithPv]
      },
      [] as MaybeWithDesign<WithSelectedQuantity<ProductVariant>>[]
    )

    onChange([...productVariantsWithoutDesigns, ...updatedDesignedPvs])
  }

  return (
    <section>
      {Object.entries(unDesignedDictionary)
        .sort()
        .map(([productId, pvs]) => (
          <BulkProductRowCard
            key={productId}
            to={`${toPrefix}/${productId}`}
            nextToProductName={
              invalidProductVariants?.some(pv => pv.productId === productId) ? (
                <Tooltip
                  title={`Cannot be shipped to ${getCountryNameFromIso2(shippingAddress?.country)}`}
                >
                  <InfoCircleOutlined style={{ color: 'red' }} />
                </Tooltip>
              ) : null
            }
            onAfterNavigatingTo={onAfterNavigatingTo}
            productVariantsWithQuantities={pvs}
            onRemoveProductClick={handleRemoveEntireUnDesignedProduct}
            onRemoveVariantClick={handleRemoveEntireUnDesignedProductVariant}
            onQuantityChange={handleChangeUndesignedProductVariantQuantity}
            withEstimatedShippingTime
            shippingAddress={shippingAddress}
          />
        ))}
      {Object.entries(designedDictionary)
        .sort()
        .map(([hash, pvs]) => (
          <BulkProductRowCard
            key={hash}
            to={`${toPrefix}/${pvs[0].productId}`}
            nextToProductName={
              invalidProductVariants?.some(
                pv => pv.productId === pvs[0].productId
              ) ? (
                <Tooltip
                  title={`Cannot be shipped to ${getCountryNameFromIso2(shippingAddress?.country)}`}
                >
                  <InfoCircleOutlined style={{ color: 'red' }} />
                </Tooltip>
              ) : null
            }
            onAfterNavigatingTo={onAfterNavigatingTo}
            productVariantsWithQuantities={pvs}
            onRemoveProductClick={() => handleRemoveEntireDesignedProduct(hash)}
            onRemoveVariantClick={pvId =>
              handleRemoveEntireDesignedProductVariant(hash, pvId)
            }
            onQuantityChange={(pvId, quantity) =>
              handleChangeDesignedProductVariantQuantity(hash, pvId, quantity)
            }
            withEstimatedShippingTime
            shippingAddress={shippingAddress}
          />
        ))}
    </section>
  )
}
