import { ListenToEligibleCategoryBalances } from 'api/databaseCalls'
import { EligibleCategories } from 'constants/EligibleCategories'
import { OrgContext, UserContext } from 'context'
import { EligibleCategory } from 'gen/perkup/v1/org_user_pb'
import { useContext, useEffect, useState } from 'react'

export function useOrgUserBalances() {
  const org = useContext(OrgContext)
  const user = useContext(UserContext)

  const userId = user?.id
  const orgId = org?.id

  const [orgUserCategoryBalances, setCategoryBalances] = useState<
    EligibleCategory[]
  >([])

  useEffect(() => {
    if (!userId || !orgId) return undefined

    return ListenToEligibleCategoryBalances({
      orgId,
      userId,
      eligibleCategoryIds: [
        EligibleCategories.FOOD,
        EligibleCategories.SWAG,
        EligibleCategories.ALL_MERCHANTS,
        EligibleCategories.LIFESTYLE,
      ],
      cb: setCategoryBalances,
    })
  }, [userId, orgId])

  const allMerchants = orgUserCategoryBalances.find(
    cat => cat.id === EligibleCategories.ALL_MERCHANTS
  )

  const orgUserPersonalBalance = Number(allMerchants?.availablePersonalFunds)

  const orgUserAllCatsBalance = Number(allMerchants?.amount || 0)

  const foodOnly = Number(
    orgUserCategoryBalances.find(cat => cat.id === EligibleCategories.FOOD)
      ?.amount || 0
  )

  const swagOnly = Number(
    orgUserCategoryBalances.find(cat => cat.id === EligibleCategories.SWAG)
      ?.amount || 0
  )

  const lifeStyle = Number(
    orgUserCategoryBalances.find(cat => cat.id === EligibleCategories.LIFESTYLE)
      ?.amount || 0
  )

  const orgUserFoodBalance = foodOnly + orgUserAllCatsBalance

  const orgUserSwagBalance = swagOnly + orgUserAllCatsBalance

  const orgUserLifestyleBalance = lifeStyle + orgUserAllCatsBalance

  return {
    orgUserFoodBalance,
    orgUserSwagBalance,
    orgUserAllCatsBalance,
    orgUserPersonalBalance,
    orgUserLifestyleBalance,
  }
}
