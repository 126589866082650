import { CountrySelection } from 'components'
import * as ROUTES from 'constants/routes'
import { OrgContext, UserContext } from 'context'
import { ArrowLeftIcon, Button, CreditCardIcon, Pane } from 'evergreen-ui'
import { useContext } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { UserDropdown } from './UserDropdown'

export function MemberMobileHeader() {
  const location = useLocation()
  const navigate = useNavigate()
  const user = useContext(UserContext)
  const org = useContext(OrgContext)
  const isCardDetailsPage = location.pathname === ROUTES.CARD_DETAILS

  const perkCardAllowed = !org.settings?.hidePerkCard

  const showCreditCardButton =
    !location.pathname?.includes(ROUTES.AMAZON) &&
    !location.pathname?.includes(ROUTES.SWAG) &&
    perkCardAllowed

  return (
    <>
      {/* TOP RIGHT NAV */}
      {showCreditCardButton && (
        <Pane position="absolute" top={16} left={16}>
          {!isCardDetailsPage ? (
            <Button
              iconAfter={<CreditCardIcon />}
              onClick={() => navigate(ROUTES.CARD_DETAILS)}
              size="large"
            >
              View Card
            </Button>
          ) : (
            <Button iconBefore={ArrowLeftIcon} onClick={() => navigate(-1)}>
              Back
            </Button>
          )}
        </Pane>
      )}
      {!location.pathname?.includes(ROUTES.SWAG) && (
        <Pane
          display="flex"
          alignItems="center"
          position="absolute"
          top={16}
          right={16}
        >
          <Pane
            marginRight={24}
            paddingRight={24}
            borderRight="muted"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <CountrySelection user={user} />
          </Pane>
          <UserDropdown />
        </Pane>
      )}
    </>
  )
}
