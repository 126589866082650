import { ApiOutlined, MonitorOutlined } from '@ant-design/icons'
import { useCubeQuery } from '@cubejs-client/react'
import { ErrorBoundary } from '@sentry/react'
import { Divider, Skeleton } from 'antd'
import { PerkAvatar, PerkEmpty, PerkLoader, PerkScrollbars } from 'components'
import { Heading, Text } from 'evergreen-ui'
import { useHasMounted, useInfiniteScroll } from 'hooks'
import { useDevSafeOrgId } from 'hooks/useDevSafeOrgId'
import { isString } from 'lodash-es'
import { getDateTimeString } from 'utils'
import { useInsightsContext } from './insights-context'

function Error() {
  return (
    <div className="px-4 lg:px-6">
      <PerkEmpty
        iconNode={
          <ApiOutlined
            className="text-muted-foreground"
            style={{ fontSize: 32 }}
          />
        }
        header="Oops! Something went wrong"
        description="We couldn't load your data. Please try again later."
      />
    </div>
  )
}

function ThankYouNotesComponent() {
  const orgId = useDevSafeOrgId()
  const hasMounted = useHasMounted()

  const { dateRange, insightsGlobalFilters } = useInsightsContext()
  const { currentLimit, sentinelRef, isFirstPage } = useInfiniteScroll()

  const { resultSet, isLoading, error } = useCubeQuery(
    {
      limit: currentLimit,
      dimensions: [
        'thankYouNotes.programRecipients_email',
        'thankYouNotes.programRecipients_firstName',
        'thankYouNotes.programRecipients_lastName',
        'thankYouNotes.programRecipients_profilePicture',
        'thankYouNotes.programOwners_email',
        'thankYouNotes.programOwners_firstName',
        'thankYouNotes.programOwners_lastName',
        'thankYouNotes.programOwners_profilePicture',
        'thankYouNotes.thanksMessage',
        'thankYouNotes.programs_name',
        'thankYouNotes.memberships_created',
      ],
      timeDimensions: [
        {
          dimension: 'thankYouNotes.memberships_created',
          dateRange,
          granularity: 'day',
        },
      ],
      filters: [
        {
          member: 'organizations.id',
          operator: 'equals',
          values: [orgId],
        },
        {
          member: 'thankYouNotes.thanksMessage',
          operator: 'set',
        },
        ...insightsGlobalFilters,
      ],
      order: {
        'thankYouNotes.memberships_created': 'desc',
      },
    },
    {
      resetResultSetOnChange: false,
    }
  )

  if ((isLoading && isFirstPage) || !hasMounted) {
    return (
      <PerkScrollbars style={{ maxHeight: '100%' }}>
        <div className="px-4 lg:px-6">
          <Skeleton active paragraph={{ rows: 12 }} />
        </div>
      </PerkScrollbars>
    )
  }

  if (error || !resultSet) {
    return <Error />
  }

  if (resultSet.rawData().length === 0) {
    return (
      <div className="px-4 lg:px-6">
        <PerkEmpty
          iconNode={
            <MonitorOutlined
              className="text-muted-foreground"
              style={{ fontSize: 32 }}
            />
          }
          header="Nothing found"
          description="Try a different date range or filter."
        />
      </div>
    )
  }

  return (
    <PerkScrollbars style={{ maxHeight: '100%' }}>
      <div className="flex flex-col gap-4 px-4 lg:px-6">
        {resultSet.rawData().map((row, i) => {
          const isLast = i === resultSet.rawData().length - 1
          const {
            'thankYouNotes.programRecipients_email': recipientEmail,
            'thankYouNotes.programRecipients_firstName': recipientFirstName,
            'thankYouNotes.programRecipients_lastName': recipientLastName,
            'thankYouNotes.programRecipients_profilePicture':
              recipientProfilePicture,
            'thankYouNotes.programOwners_firstName': ownerFirstName,
            'thankYouNotes.programOwners_lastName': ownerLastName,
            'thankYouNotes.programOwners_profilePicture': ownerProfilePicture,
            'thankYouNotes.thanksMessage': thanksMessage,
            'thankYouNotes.programs_name': programName,
            'thankYouNotes.memberships_created': thanksMessageDate,
          } = row

          const recipientFullName = `${recipientFirstName} ${recipientLastName}`

          return (
            <>
              <article
                key={`${thanksMessage}-${recipientEmail}`}
                className="flex flex-col gap-2"
              >
                <div className="flex items-center gap-2">
                  <PerkAvatar
                    firstName={
                      isString(recipientFirstName)
                        ? recipientFirstName
                        : undefined
                    }
                    lastName={
                      isString(recipientLastName)
                        ? recipientLastName
                        : undefined
                    }
                    email={
                      isString(recipientEmail) ? recipientEmail : undefined
                    }
                    profilePicture={
                      isString(recipientProfilePicture)
                        ? recipientProfilePicture
                        : undefined
                    }
                  />

                  <Text>{recipientFullName}</Text>
                  <Text size={300} color="muted">
                    {recipientEmail}
                  </Text>
                </div>

                <Text>{thanksMessage}</Text>

                <div className="flex items-center gap-2">
                  <Text size={300} flex={1}>
                    {getDateTimeString(
                      new Date(thanksMessageDate?.toString() || ''),
                      {
                        longDisplay: true,
                        dateOnly: true,
                        hideWeekday: true,
                      }
                    )}
                  </Text>
                  <Text size={300} color="muted">
                    {programName}
                  </Text>
                  <PerkAvatar
                    size="small"
                    firstName={
                      isString(ownerFirstName) ? ownerFirstName : undefined
                    }
                    lastName={
                      isString(ownerLastName) ? ownerLastName : undefined
                    }
                    email={undefined}
                    profilePicture={
                      isString(ownerProfilePicture)
                        ? ownerProfilePicture
                        : undefined
                    }
                  />
                </div>
              </article>

              {!isLast && <Divider style={{ margin: 0 }} />}
            </>
          )
        })}
        <div
          className="pb-6"
          style={
            !isLoading && currentLimit > resultSet.rawData().length
              ? {
                  display: 'none',
                }
              : {}
          }
          ref={sentinelRef}
        >
          <PerkLoader marginY={0} />
        </div>
      </div>
    </PerkScrollbars>
  )
}

export function ThankYouNotes() {
  const { dateRange, insightsGlobalFilters } = useInsightsContext()

  return (
    <section className="flex flex-col gap-6 w-full h-full rounded-lg border border-muted py-4">
      <Heading className="px-4 lg:px-6">Thank you notes</Heading>

      <ErrorBoundary fallback={<Error />}>
        <ThankYouNotesComponent
          key={JSON.stringify({ dateRange, insightsGlobalFilters })}
        />
      </ErrorBoundary>
    </section>
  )
}
