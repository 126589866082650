const FEATURED_COLLECTION_ID = 'featured'

const OCCASION_COLLECTION_DETAILS = {
  [FEATURED_COLLECTION_ID]: { emoji: '⭐', text: 'Popular' },
  birthday: { emoji: '🎂', text: 'Birthday' },
  'work-anniversary': { emoji: '🎉', text: 'Work Anniversary' },
  'snacks-treats': { emoji: '🍬', text: 'Snacks & Treats' },
  'health-wellness': { emoji: '🌿', text: 'Health & Wellness' },
  'gift-boxes': { emoji: '🎁', text: 'Gift Baskets' },
  'new-hire': { emoji: '👔', text: 'New Hire' },
  'new-baby': { emoji: '🍼', text: 'New Baby' },
  'pet-gifts': { emoji: '🐶', text: 'Pet Gifts' },
  wedding: { emoji: '💍', text: 'Wedding' },
  bereavement: { emoji: '🌹', text: 'Bereavement' },
}

export const CATEGORY_COLLECTION_ID_TO_NAME = {
  'arts-crafts': 'Arts & Crafts',
  'baby-toddler': 'Baby & Toddler',
  drinkware: 'Drinkware',
  'flowers-plants': 'Flowers & Plants',
  'food-drinks': 'Food & Drinks',
  'gift-baskets': 'Gift Baskets',
  'home-living': 'Home Living',
  'home-office': 'Home Office',
  'pet-gifts': 'Pet Gifts',
  'sports-outdoor': 'Sports & Outdoor',
  tech: 'Tech',
  'travel-bags': 'Travel Bags',
  'beauty-wellness': 'Beauty & Wellness',
}

export const OCCASION_COLLECTION_DATA = OCCASION_COLLECTION_DETAILS

export const OCCASION_COLLECTION_IDS = Object.keys(OCCASION_COLLECTION_DETAILS)

export const CATEGORY_COLLECTION_IDS = Object.keys(
  CATEGORY_COLLECTION_ID_TO_NAME
)

export const OCCASION_COLLECTION_ID_TO_NAME_WITH_EMOJIS = Object.fromEntries(
  Object.entries(OCCASION_COLLECTION_DETAILS).map(([key, { emoji, text }]) => [
    key,
    `${emoji} ${text}`,
  ])
)

export const OCCASION_COLLECTION_ID_TO_NAME_WITHOUT_EMOJIS = Object.fromEntries(
  Object.entries(OCCASION_COLLECTION_DETAILS).map(([key, { text }]) => [
    key,
    text,
  ])
)
