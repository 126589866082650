import { Flex, Modal } from 'antd'
import {
  ALGOLIA_DELIVERY_TYPE_FACET_VALUE,
  ALGOLIA_PRODUCT_VARIANTS_INDEX,
  TYPE_NEAR_CASH,
} from 'constants/algolia'
import { AlgoliaBrowseProducts, ProductDetails } from 'features'
import {
  ProductVariant,
  ProductVariant_DeliveryType,
} from 'gen/perkup/v1/product_variant_pb'
import { Program_Gift } from 'gen/perkup/v1/program_pb'
import { useProductVariantByGift } from 'hooks/productVariants/useProductVariantByGift'
import { compact } from 'lodash-es'
import { ReactNode, useState } from 'react'
import { getAlgoliaShippingCountriesFilter } from 'utils/Algolia'
import {
  getProductVariantOptions,
  getUniqAndFormatWithSelectable,
} from 'utils/productVariant'

export function SelectGiftCard({
  maxBudget,
  currentGift,
  onSelectGiftCard,
  title,
}: {
  maxBudget?: number
  currentGift: Program_Gift | undefined
  onSelectGiftCard: (selectedProductVariant: ProductVariant) => void
  title?: ReactNode
}) {
  const [productVariantToShow, setProductVariantToShow] =
    useState<ProductVariant>()

  const { productVariants: selectedVariants } = useProductVariantByGift({
    gift: currentGift,
  })

  const uniqueSelectedVariants = getUniqAndFormatWithSelectable(
    compact(selectedVariants)
  )

  const handleSelectProductVariant = (productVariant: ProductVariant) => {
    onSelectGiftCard(productVariant)
    setProductVariantToShow(undefined)
  }

  const selectedVariantInView = selectedVariants?.find(
    pv => pv.productId === productVariantToShow?.productId
  )

  const defaultSelectedOptions = selectedVariantInView
    ? {
        options: getProductVariantOptions({
          productVariant: selectedVariantInView,
        }),
        filterOtherOptions: false,
      }
    : undefined

  const searchFilter = `${ALGOLIA_DELIVERY_TYPE_FACET_VALUE}:${
    ProductVariant_DeliveryType[ProductVariant_DeliveryType.realtime]
  } AND isAvailable:true AND ${TYPE_NEAR_CASH}${maxBudget ? ` AND amount<=${maxBudget}` : ''}`

  return (
    <>
      <Modal
        width={1280}
        centered
        title="‎"
        open={!!productVariantToShow}
        footer={false}
        onCancel={() => setProductVariantToShow(undefined)}
      >
        {productVariantToShow && (
          <ProductDetails
            key={productVariantToShow.id}
            productVariant={productVariantToShow}
            showPrice
            showShippingCountries
            withAmountInUsd
            onSubmit={handleSelectProductVariant}
            defaultSelected={defaultSelectedOptions}
            maxAmount={maxBudget}
          />
        )}
      </Modal>
      <Flex vertical style={{ width: '100%' }}>
        <AlgoliaBrowseProducts
          initialUiState={{
            [ALGOLIA_PRODUCT_VARIANTS_INDEX]: {
              menu: {
                shippingCountries: getAlgoliaShippingCountriesFilter(),
              },
            },
          }}
        >
          <AlgoliaBrowseProducts.Old
            onProductCardClick={setProductVariantToShow}
            withCountryFilter
            withPriceFilter
            withProductAmounts
            withProductCount
            selectedVariants={uniqueSelectedVariants}
            searchFilter={searchFilter}
            menuFilters={['productCategories']}
            firstRowPrefix={title}
          />
        </AlgoliaBrowseProducts>
      </Flex>
    </>
  )
}
