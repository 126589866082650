import { ClockCircleOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import DatePicker from 'antd/es/date-picker'
import dayjs, { Dayjs } from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import timezone from 'dayjs/plugin/timezone'

dayjs.extend(customParseFormat)
dayjs.extend(timezone)

export default function ScheduleForm({
  futureSendDate,
  setFutureSendDate,
  disabled,
}: {
  futureSendDate: Dayjs | undefined
  setFutureSendDate: (newDate: Dayjs | undefined) => void
  disabled?: boolean
}) {
  // Can not select days before today
  const disabledDate = (current: Dayjs) => {
    return current < dayjs().subtract(1, 'days')
  }

  const handleDateChange = (newDate: Dayjs | null) => {
    // Ant design sets value to null if the date field was cleared
    if (!newDate) {
      setFutureSendDate(undefined)
    } else {
      setFutureSendDate(newDate)
    }
  }

  if (!futureSendDate) {
    return (
      <Button
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: 248,
        }}
        icon={<ClockCircleOutlined />}
        size="large"
        onClick={() => setFutureSendDate(dayjs())}
        disabled={disabled}
      >
        Schedule for later
      </Button>
    )
  }

  const customDateFormat = 'MMM Do, YYYY, h:mm A zzz'

  return (
    <DatePicker
      format={customDateFormat}
      placeholder="Schedule for later (optional)"
      placement="topRight"
      showTime
      value={dayjs(futureSendDate, customDateFormat).minute(0)}
      disabledDate={disabledDate}
      onChange={handleDateChange}
      size="large"
      style={{ width: 401 }}
    />
  )
}
