import { Select } from 'antd'
import { UpdateUserCurrency } from 'api/databaseCalls'
import { currencySymbols, USD } from 'constants/currencies'
import {
  CountriesContext,
  ExchangeRateContext,
  OrgContext,
  UserContext,
} from 'context'
import { findFlagUrlByIso2Code } from 'country-flags-svg'
import { Avatar, Heading, Pane, Text, toaster, useTheme } from 'evergreen-ui'
import { concat, lowerCase, round, upperCase } from 'lodash-es'
import { useContext } from 'react'
import { getCountryIsoAlpha2 } from 'utils'

export function CurrencySelection() {
  const user = useContext(UserContext)
  const org = useContext(OrgContext)
  const userId = user?.id
  const countries = useContext(CountriesContext)
  const exchangeRate = useContext(ExchangeRateContext)
  const theme = useTheme()
  const selectedCurrency = (user.displayCurrency ||
    org.displayCurrency ||
    USD) as keyof typeof currencySymbols

  const EU_FLAG =
    'https://upload.wikimedia.org/wikipedia/commons/b/b7/Flag_of_Europe.svg'

  const createCurrencyItemObject = ({
    key,
    label,
    src,
  }: {
    key: string
    label: string
    src: string
  }) => {
    return (
      <Select.Option key={key} style={{ display: 'flex' }}>
        <Pane display="flex" alignItems="center" gap={8}>
          <Avatar
            alt="Country flag"
            src={src}
            size={18}
            border={`1px solid ${theme.colors.gray200}`}
          />
          <Text>{label}</Text>
        </Pane>
      </Select.Option>
    )
  }

  // Map through countries for currencies, filtering eu countries and ecuador (uses usd)
  const selectableCurrencies = concat(
    countries.map(country => {
      if (country.currency === 'eur' || country.iso3 === 'ecu') return null

      const label = `${upperCase(country.currency)} (${
        currencySymbols[country.currency as keyof typeof currencySymbols] || '$'
      })`
      const flagUrl = findFlagUrlByIso2Code(getCountryIsoAlpha2(country.iso3))

      return createCurrencyItemObject({
        key: lowerCase(country.currency),
        label,
        src: flagUrl,
      })
    }),
    // Concatenating the Euro since many countries use it
    createCurrencyItemObject({
      key: 'eur',
      label: `EUR (${currencySymbols.eur})`,
      src: EU_FLAG,
    })
  ).filter(currency => !!currency)
  // Sorting currencies alphabetically
  selectableCurrencies.sort((a, b) => {
    if (!a?.key || !b?.key) return 1
    return a.key > b.key ? 1 : -1
  })
  const currencySymbol = currencySymbols[selectedCurrency] || '$'
  return (
    <Pane
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="end"
    >
      <Select
        bordered={false}
        value={selectedCurrency.toLowerCase()}
        onSelect={currency => {
          UpdateUserCurrency({
            userId,
            displayCurrency: currency,
          })
          toaster.success(`Changed currency to ${currency.toUpperCase()}`)
        }}
        showSearch
        popupMatchSelectWidth={false}
        size="large"
        style={{
          minWidth: 128,
          marginRight: -11,
        }}
        className="customSelectionItem"
      >
        {selectableCurrencies}
      </Select>
      {selectedCurrency !== USD && (
        <Heading fontSize={12} fontWeight={400}>
          US$1.00 = {currencySymbol.toUpperCase()}
          {round(exchangeRate, 2)}
        </Heading>
      )}
    </Pane>
  )
}
