import { Timestamp } from '@bufbuild/protobuf'
import DatePicker from 'antd/es/date-picker'
import { updateIndividualDate } from 'api/databaseCalls'
import dayjs from 'dayjs'
import { Pane, Text, toaster } from 'evergreen-ui'
import isFunction from 'lodash-es/isFunction'
import { getUTCIndividualDate } from 'utils'

export function IndividualDate({
  fieldKey,
  displayText,
  orgId,
  individualId,
  dateData,
  onConfirm,
  placeholder,
  isAdmin,
  disabled,
  dataIsPrivate = false,
}: {
  fieldKey: string
  displayText: string
  orgId: string
  individualId: string
  dateData?: Timestamp
  onConfirm?: () => void
  placeholder: string
  isAdmin: boolean
  disabled?: boolean
  dataIsPrivate?: boolean
}) {
  const handleSubmit = (newDate: Date) => {
    updateIndividualDate({
      orgId,
      individualId,
      date: newDate,
      fieldKey,
    })

    if (isFunction(onConfirm)) onConfirm()

    toaster.success(`${displayText} updated successfully`)
  }

  const disabledDate = (current: dayjs.Dayjs) => {
    return current > dayjs()
  }

  const datePickerValue = dateData
    ? dayjs(
        getUTCIndividualDate(dateData?.toDate())?.toLocaleDateString('en-us')
      )
    : undefined

  return (
    <Pane
      display="flex"
      alignItems="center"
      gap="16px"
      justifyContent="space-between"
    >
      <Text>{displayText}:</Text>
      {!dataIsPrivate && (
        <DatePicker
          allowClear={false}
          style={{ width: '208px' }}
          format="MMMM Do"
          disabledDate={disabledDate}
          placeholder={`Select ${placeholder}`}
          defaultValue={datePickerValue}
          showNow={false}
          onChange={value => {
            if (!value) return
            const selectedDate = value.toDate()
            const newDate = new Date(
              Date.UTC(
                selectedDate.getFullYear(),
                selectedDate.getMonth(),
                selectedDate.getDate()
              )
            )
            handleSubmit(newDate)
          }}
          disabled={!isAdmin || disabled}
          placement="bottomRight"
        />
      )}
      {dataIsPrivate && (
        <Text color="muted">{`${displayText} kept private`}</Text>
      )}
    </Pane>
  )
}
