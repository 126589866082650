import { Timestamp } from '@bufbuild/protobuf'
import { DatePicker } from 'antd'
import dayjs from 'dayjs'
import { Pane, Strong, Switch, Tab, Text } from 'evergreen-ui'
import { ProgramFrequency } from 'gen/perkup/v1/program_pb'
import { isFunction } from 'lodash-es'
import capitalize from 'lodash-es/capitalize'

const frequencyOptions = [
  {
    value: ProgramFrequency.monthly,
    label: ProgramFrequency[ProgramFrequency.monthly],
    description: 'Limit applies to a month, starting on the 1st.',
  },
  {
    value: ProgramFrequency.yearly,
    label: ProgramFrequency[ProgramFrequency.yearly],
    description: 'Limit applies to a year, starting on January 1st.',
  },
  {
    value: ProgramFrequency.once,
    label: ProgramFrequency[ProgramFrequency.once],
    description: 'Limit applies to all time.',
  },
]

export function FrequencySelection({
  onChange,
  value,
  onChangeProrate,
  prorate,
  prorateDate,
  onChangeProrateDate,
}: {
  onChange: React.Dispatch<React.SetStateAction<ProgramFrequency>>
  value: ProgramFrequency
  onChangeProrate:
    | ((value: boolean) => void)
    | React.Dispatch<React.SetStateAction<boolean>>
  prorate: boolean
  prorateDate?: Timestamp
  onChangeProrateDate?: (newDate: dayjs.Dayjs | null) => void
}) {
  if (!onChange || !value || !onChangeProrate) return null

  const activeFrequency = frequencyOptions.filter(
    option => option.value === value
  )

  return (
    <Pane>
      <Pane display="flex" alignItems="center" marginBottom={8}>
        <Pane marginRight={16}>
          <Strong>Frequency:</Strong>
        </Pane>
        <Pane marginRight={8}>
          {frequencyOptions.map(frequency => (
            <Tab
              key={frequency.value}
              isSelected={value === frequency.value}
              height={40}
              onSelect={() => onChange(frequency.value)}
            >
              {capitalize(frequency.label)}
            </Tab>
          ))}
        </Pane>

        {/* Prorated option */}
        {value === ProgramFrequency.yearly && (
          <Pane display="flex" gap={16} alignItems="center">
            <Pane display="flex">
              <Switch
                marginRight={8}
                marginBottom={0}
                checked={prorate}
                onChange={e => onChangeProrate(e.target.checked)}
              />
              <Text size={300}>Prorate new users</Text>
            </Pane>
            {prorate && isFunction(onChangeProrateDate) && (
              <DatePicker
                format="MMM YYYY"
                picker="month"
                placeholder="Set proration start month"
                value={dayjs(prorateDate?.toDate())}
                onChange={onChangeProrateDate}
                allowClear={false}
              />
            )}
          </Pane>
        )}
      </Pane>

      <Text size={300} color="muted">
        {activeFrequency[0]?.description}
      </Text>
    </Pane>
  )
}
