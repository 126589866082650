import { DownOutlined } from '@ant-design/icons'
import { Button, Dropdown, InputNumber, MenuProps, Space } from 'antd'
import { CUSTOM_INPUT_KEY } from 'constants/keys'
import { Text, toaster } from 'evergreen-ui'
import { useState } from 'react'

type MenuItem = Required<MenuProps>['items'][number]

const DEFAULT_OPTIONS: MenuItem[] = Array.from({ length: 10 }, (_, i) => ({
  key: i === 9 ? CUSTOM_INPUT_KEY : i + 1,
  label: i === 9 ? '10+' : `${i + 1}`,
}))

export function QuantityPicker({
  currentQuantity,
  onQuantityChange,
  isStatic = false,
  quanitityOptions = DEFAULT_OPTIONS,
  customQuantityMax,
  suffix,
}: {
  currentQuantity: number
  onQuantityChange: (newQuanity: number) => void
  isStatic?: boolean
  quanitityOptions?: MenuItem[]
  customQuantityMax?: number
  suffix?: string
}) {
  const currentQtyisCustom = !quanitityOptions?.find(
    option => option?.key === currentQuantity
  )

  const [newCustomQty, setNewCustomQty] = useState<number>(currentQuantity)
  const [isInInputMode, setIsInInputMode] = useState(currentQtyisCustom)
  const [isEditingCustomQty, setIsEditingCustomQty] = useState(false)

  const userEnteredQtyGreaterThanMax =
    customQuantityMax && customQuantityMax < newCustomQty

  const handleCustomQtyBlur = () => {
    const newQtyExistsInDropdown = quanitityOptions.find(
      option => option?.key === newCustomQty
    )
    if (newQtyExistsInDropdown) {
      onQuantityChange(newCustomQty)
      setIsInInputMode(false)
      setIsEditingCustomQty(false)
    } else if (userEnteredQtyGreaterThanMax) {
      toaster.warning(
        `Custom input cannot be greater than ${customQuantityMax}`
      )
    } else if (newCustomQty !== currentQuantity) {
      onQuantityChange(newCustomQty)
      setIsEditingCustomQty(false)
    }
  }

  if (isStatic) {
    return (
      <Text>
        {currentQuantity} {suffix && <Text> {suffix}</Text>}{' '}
        {!currentQtyisCustom && (
          <DownOutlined style={{ marginLeft: 4, fontSize: 12 }} />
        )}
      </Text>
    )
  }

  if (isInInputMode) {
    return (
      <Space
        onClick={(e: React.MouseEvent<HTMLElement>) => {
          e.stopPropagation()
          e.preventDefault()
        }}
      >
        {isEditingCustomQty ? (
          <>
            <InputNumber
              status={userEnteredQtyGreaterThanMax ? 'error' : undefined}
              style={{ width: 70 }}
              autoFocus
              min={1}
              precision={0}
              defaultValue={currentQuantity}
              value={newCustomQty}
              onChange={q => setNewCustomQty(q || 1)}
              onBlur={handleCustomQtyBlur}
            />
            {suffix && <Text>{suffix}</Text>}
          </>
        ) : (
          <Text
            className="hoverline"
            cursor="pointer"
            onClick={() => setIsEditingCustomQty(true)}
          >
            {currentQuantity}
            {suffix && <Text> {suffix}</Text>}
          </Text>
        )}
      </Space>
    )
  }

  return (
    <Dropdown
      menu={{
        items: quanitityOptions ?? DEFAULT_OPTIONS,
        onClick: ({ key }) => {
          if (key === CUSTOM_INPUT_KEY) {
            setNewCustomQty(currentQuantity)
            setIsInInputMode(true)
            setIsEditingCustomQty(true)
            return
          }
          onQuantityChange(Number(key))
        },
      }}
      placement="bottom"
      trigger={['click']}
    >
      <Button type="text" style={{ padding: '1px 4px', height: 'min-content' }}>
        {currentQuantity}
        {suffix && <Text>{suffix}</Text>}
        <DownOutlined />
      </Button>
    </Dropdown>
  )
}
