import Select from 'antd/es/select'
import { STATUS_NONE_OR_INVITED_OR_ACTIVE } from 'constants/algolia'
import { Pane, Text } from 'evergreen-ui'
import { useInfiniteSelectableIndividuals } from 'hooks/individuals/useInfiniteSelectableIndividuals'
import { intersectionWith } from 'lodash-es'
import { useMemo } from 'react'
import { Configure, useSearchBox } from 'react-instantsearch'
import { getIndividualDisplayName } from 'utils/individual'

const MAX_TAG_COUNT = 20

export function IndividualsSelection({
  defaultLabel = 'Select people',
  selectedIndividualIds,
  onIndividualClick,
  filters = STATUS_NONE_OR_INVITED_OR_ACTIVE,
  mode = 'multiple',
  width = 280,
}: {
  defaultLabel?: string
  selectedIndividualIds: string[]
  onIndividualClick: (selectedIndividualIds: string[] | undefined) => void
  filters?: string
  mode?: 'multiple' | 'tags' | 'single'
  width?: string | number
}) {
  const { refine, clear } = useSearchBox()
  const INCREMENT_AMOUNT = 20

  const { individuals, sentinelRef, hits } = useInfiniteSelectableIndividuals({
    selectedIndividualIds,
    incrementAmount: INCREMENT_AMOUNT,
  })

  const isSingleEntryMode = mode === 'single'

  const handleSelect = (individualId: string) => {
    if (isSingleEntryMode) {
      // onDeselect only works in multiple or tags mode, need this block for single selection/deselection
      const individualAlreadySelected =
        selectedIndividualIds.includes(individualId)
      onIndividualClick(individualAlreadySelected ? undefined : [individualId])

      return
    }

    const concatenatedIndividualIds = [...selectedIndividualIds, individualId]

    onIndividualClick(concatenatedIndividualIds)
    clear()
  }

  const handleDeselect = (id: string) => {
    // Note that onDeselect doesn't work unless mode is multiple or tags
    const filteredIndividualIds = selectedIndividualIds.filter(
      individualId => id !== individualId
    )
    onIndividualClick(filteredIndividualIds)
    clear()
  }

  const maxTagPlaceholderText = useMemo(() => {
    if (selectedIndividualIds.length <= MAX_TAG_COUNT) {
      return ''
    }
    return `+ ${selectedIndividualIds.length - MAX_TAG_COUNT} others...`
  }, [selectedIndividualIds.length])

  const selectedOptions = intersectionWith(
    selectedIndividualIds,
    individuals,
    (id, individual) => id === individual.id
  )

  const options = individuals.map((individual, index) => {
    const isLastIndex = index === individuals.length - 1

    return {
      value: individual.id,
      label: (
        <Text ref={isLastIndex ? sentinelRef : null}>
          {getIndividualDisplayName(individual)}
        </Text>
      ),
      key: individual.id,
    }
  })

  return (
    <Pane width={width}>
      <Configure hitsPerPage={INCREMENT_AMOUNT} filters={filters} />

      <Select
        style={{ width: '100%' }}
        mode={isSingleEntryMode ? undefined : mode}
        placeholder={defaultLabel}
        value={selectedOptions}
        onSelect={(_, option) => {
          handleSelect(option.key)
        }}
        onDeselect={(_, option) => handleDeselect(option.key)}
        onBlur={clear}
        optionFilterProp="label"
        onSearch={e => refine(e)}
        popupMatchSelectWidth={false}
        showSearch
        maxTagCount={MAX_TAG_COUNT}
        maxTagPlaceholder={<Text>{maxTagPlaceholderText}</Text>}
        onClear={
          isSingleEntryMode ? () => onIndividualClick(undefined) : undefined
        }
        allowClear={isSingleEntryMode}
        filterOption={(_, option) => {
          //  Check if algolia hits includes option - lets us know whether to filter it. We want to leverage this prop because it keeps the Tag, and only filters the option.
          const searchHit = hits.find(hit => hit.id === option?.key)
          return !!searchHit
        }}
        options={options}
      />
    </Pane>
  )
}
