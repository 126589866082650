import { useEffect, useState } from 'react'
import { getCustomerCanvasAllProductLinks } from 'services'
import { ProductLink } from 'types'

export function useCustomerCanvasAllProductLinks() {
  const [productLinks, setProductLinks] = useState<ProductLink[]>([])
  const [hasLoaded, setHasLoaded] = useState(false)

  useEffect(() => {
    getCustomerCanvasAllProductLinks()
      .then(setProductLinks)
      .finally(() => setHasLoaded(true))
  }, [])

  return { productLinks, hasLoaded }
}
