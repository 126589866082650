import { sortBy } from 'lodash-es'
import {
  AmazonBasicsIcon,
  AmazonGiftCardIcon,
  ArtsCraftsAndSewingIcon,
  AutomotiveIcon,
  BabyAndKidsIcon,
  BeautyIcon,
  BooksIcon,
  ClothingAndApparelIcon,
  GearIcon,
  GroceryIcon,
  HandmadeIcon,
  HealthAndWellnessIcon,
  HomeAndKitchenIcon,
  LawnAndGardenIcon,
  LuggageAndTravelIcon,
  MoviesAndTVIcon,
  MusicAndAudioIcon,
  PetSuppliesIcon,
  SchoolAndOfficeIcon,
  SoftwareIcon,
  SportsAndOutdoorsIcon,
  ToolsAndImprovementIcon,
  ToysAndGamesIcon,
} from 'pages/Amazon/icons/AmazonIcons'
import { AmazonRefinementCategory } from 'types/Amazon'

export const AMAZON_PRIVACY_POLICY =
  'https://www.amazon.com/gp/help/customer/display.html?nodeId=201909010&pop-up=1'
export const AMAZON_CONDITIONS_OF_USE =
  'https://www.amazon.com/gp/help/customer/display.html?nodeId=201909000&pop-up=1'

export const AMAZON_GIFT_CARD = 'Amazon Gift Card'

const amazonCategories = [
  { displayName: 'Amazon Basics', icon: AmazonBasicsIcon() },
  { displayName: 'Handmade', icon: HandmadeIcon() },
  { displayName: 'Books', icon: BooksIcon() },
  { displayName: 'Arts, Crafts & Sewing', icon: ArtsCraftsAndSewingIcon() },
  { displayName: 'Automotive', icon: AutomotiveIcon() },
  { displayName: 'Baby & Kids', icon: BabyAndKidsIcon() },
  { displayName: 'Beauty', icon: BeautyIcon() },
  { displayName: 'Clothing & Apparel', icon: ClothingAndApparelIcon() },
  { displayName: 'Electronics', icon: GearIcon() },
  { displayName: 'Grocery', icon: GroceryIcon() },
  { displayName: 'Health & Wellness', icon: HealthAndWellnessIcon() },
  { displayName: 'Home & Kitchen', icon: HomeAndKitchenIcon() },
  { displayName: 'Lawn & Garden', icon: LawnAndGardenIcon() },
  { displayName: 'Luggage & Travel', icon: LuggageAndTravelIcon() },
  { displayName: 'Movies & TV', icon: MoviesAndTVIcon() },
  { displayName: 'Music & Audio', icon: MusicAndAudioIcon() },
  { displayName: 'Pet Supplies', icon: PetSuppliesIcon() },
  { displayName: 'School & Office', icon: SchoolAndOfficeIcon() },
  { displayName: 'Software', icon: SoftwareIcon() },
  { displayName: 'Sports & Outdoors', icon: SportsAndOutdoorsIcon() },
  { displayName: 'Tools & Improvement', icon: ToolsAndImprovementIcon() },
  { displayName: 'Toys & Games', icon: ToysAndGamesIcon() },
]

const sortedCategories = sortBy(amazonCategories, 'displayName')

export const CategoryLinks = [
  { displayName: AMAZON_GIFT_CARD, icon: AmazonGiftCardIcon() },
  ...sortedCategories,
]

export const searchRefinementValues = [
  {
    displayName: 'All',
    searchRefinementValue: '',
  },
  {
    displayName: 'Digital Music',
    searchRefinementValue: 'search-alias#digital-music',
  },
  {
    displayName: 'CDs & Vinyl',
    searchRefinementValue: 'search-alias#popular',
  },
  {
    displayName: 'Books',
    searchRefinementValue: 'search-alias#stripbooks',
  },
  {
    displayName: 'Clothing, Shoes & Jewelry',
    searchRefinementValue: 'search-alias#fashion',
  },
  {
    displayName: 'Movies & TV',
    searchRefinementValue: 'search-alias#dvd',
  },
  {
    displayName: 'Kindle Store',
    searchRefinementValue: 'search-alias#digital-text',
  },
  {
    displayName: 'Video Games',
    searchRefinementValue: 'search-alias#videogames',
  },
  {
    displayName: 'Prime Video',
    searchRefinementValue: 'search-alias#instant-video',
  },
  {
    displayName: 'Apps & Games',
    searchRefinementValue: 'search-alias#mobile-apps',
  },
  {
    displayName: 'Software',
    searchRefinementValue: 'search-alias#software',
  },
  {
    displayName: 'Alexa Skills',
    searchRefinementValue: 'search-alias#alexa-skills',
  },
  {
    displayName: 'Health, Household & Baby Care',
    searchRefinementValue: 'search-alias#hpc',
  },
  {
    displayName: 'Home & Kitchen',
    searchRefinementValue: 'search-alias#garden',
  },
  {
    displayName: 'Electronics',
    searchRefinementValue: 'search-alias#electronics',
  },
  {
    displayName: 'Beauty & Personal Care',
    searchRefinementValue: 'search-alias#beauty',
  },
  {
    displayName: 'Industrial & Scientific',
    searchRefinementValue: 'search-alias#industrial',
  },
  {
    displayName: 'Grocery & Gourmet Food',
    searchRefinementValue: 'search-alias#grocery',
  },
  {
    displayName: 'Tools & Home Improvement',
    searchRefinementValue: 'search-alias#tools',
  },
  {
    displayName: 'Office Products',
    searchRefinementValue: 'search-alias#office-products',
  },
  {
    displayName: 'Magazine Subscriptions',
    searchRefinementValue: 'search-alias#magazines',
  },
  {
    displayName: 'Toys & Games',
    searchRefinementValue: 'search-alias#toys-and-games',
  },
  {
    displayName: 'Sports & Outdoors',
    searchRefinementValue: 'search-alias#sporting',
  },
  {
    displayName: 'Pet Supplies',
    searchRefinementValue: 'search-alias#pets',
  },
  {
    displayName: 'Cell Phones & Accessories',
    searchRefinementValue: 'search-alias#mobile',
  },
  {
    displayName: 'Baby',
    searchRefinementValue: 'search-alias#baby-products',
  },
  {
    displayName: 'Automotive Parts & Accessories',
    searchRefinementValue: 'search-alias#automotive',
  },
  {
    displayName: 'Appliances',
    searchRefinementValue: 'search-alias#appliances',
  },
  {
    displayName: 'Arts, Crafts & Sewing',
    searchRefinementValue: 'search-alias#arts-crafts',
  },
  {
    displayName: 'Garden & Outdoor',
    searchRefinementValue: 'search-alias#lawngarden',
  },
  {
    displayName: 'Home & Business Services',
    searchRefinementValue: 'search-alias#local-services',
  },
  {
    displayName: 'Musical Instruments',
    searchRefinementValue: 'search-alias#mi',
  },
  {
    displayName: 'Gift Cards',
    searchRefinementValue: 'search-alias#gift-cards',
  },
  {
    displayName: 'Courses',
    searchRefinementValue: 'search-alias#courses',
  },
  {
    displayName: 'Kindle Accessories',
    searchRefinementValue: 'search-alias#kindle-accessories',
  },
  {
    displayName: 'Handmade',
    searchRefinementValue: 'search-alias#handmade',
  },
  {
    displayName: 'Everything Else',
    searchRefinementValue: 'search-alias#misc',
  },
  {
    displayName: 'Collectibles & Fine Art',
    searchRefinementValue: 'search-alias#collectibles',
  },
]

export const refinementCategories: AmazonRefinementCategory[] = [
  {
    displayName: 'Books',
    id: 'BOOKS',
    image:
      'https://images.unsplash.com/photo-1519682337058-a94d519337bc?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwyNzc5NjV8MHwxfGFsbHx8fHx8fHx8fDE2NDcxOTY0Njc&ixlib=rb-1.2.1&q=80&w=500',
  },
  {
    displayName: 'Clothing, Shoes & Jewelry',
    id: 'FASHION',
    image:
      'https://images.unsplash.com/photo-1516762689617-e1cffcef479d?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwyNzc5NjV8MHwxfGFsbHx8fHx8fHx8fDE2NDcxOTY1MzA&ixlib=rb-1.2.1&q=80&w=500',
  },

  {
    displayName: 'Health & Household',
    id: 'HEALTH_PERSONAL_CARE',
    image:
      'https://images.unsplash.com/photo-1619451334792-150fd785ee74?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwyNzc5NjV8MHwxfGFsbHx8fHx8fHx8fDE2NDcxOTY3NTI&ixlib=rb-1.2.1&q=80&w=500',
  },
  {
    displayName: 'Home & Kitchen',
    id: 'HOME_AND_KITCHEN',
    image:
      'https://images.unsplash.com/photo-1556911220-e15b29be8c8f?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwyNzc5NjV8MHwxfGFsbHx8fHx8fHx8fDE2NDcxOTY3OTU&ixlib=rb-1.2.1&q=80&w=500',
  },
  {
    displayName: 'Electronics',
    id: 'ELECTRONICS',
    image:
      'https://images.unsplash.com/photo-1505740420928-5e560c06d30e?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwyNzc5NjV8MHwxfGFsbHx8fHx8fHx8fDE2NDcxOTY5MDY&ixlib=rb-1.2.1&q=80&w=500',
  },
  {
    displayName: 'Beauty & Personal Care',
    id: 'BEAUTY',
    image:
      'https://images.unsplash.com/photo-1596462502278-27bfdc403348?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwyNzc5NjV8MHwxfGFsbHx8fHx8fHx8fDE2NDcxOTY5NTQ&ixlib=rb-1.2.1&q=80&w=500',
  },
  {
    displayName: 'Grocery & Gourmet Food',
    id: 'GROCERY_AND_GOURMET_FOOD',
    image:
      'https://images.unsplash.com/photo-1629405520266-2d48036fa057?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwyNzc5NjV8MHwxfGFsbHx8fHx8fHx8fDE2NDcxOTcxNDE&ixlib=rb-1.2.1&q=80&w=500',
  },
  {
    displayName: 'Tools & Home Improvement',
    id: 'TOOLS_AND_HOME_IMPROVEMENT',
    image:
      'https://images.unsplash.com/photo-1534398079543-7ae6d016b86a?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwyNzc5NjV8MHwxfGFsbHx8fHx8fHx8fDE2NDcxOTcyNTg&ixlib=rb-1.2.1&q=80&w=500',
  },
  {
    displayName: 'Office Products',
    id: 'OFFICE_PRODUCTS',
    image:
      'https://images.unsplash.com/photo-1501959181532-7d2a3c064642?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwyNzc5NjV8MHwxfGFsbHx8fHx8fHx8fDE2NDcxOTczMjU&ixlib=rb-1.2.1&q=80&w=500',
  },

  {
    displayName: 'Toys & Games',
    id: 'TOYS_AND_GAMES',
    image:
      'https://images.unsplash.com/photo-1501686637-b7aa9c48a882?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwyNzc5NjV8MHwxfGFsbHx8fHx8fHx8fDE2NDcxOTcyMzM&ixlib=rb-1.2.1&q=80&w=500',
  },
  {
    displayName: 'Sports & Outdoors',
    id: 'SPORTS_AND_OUTDOORS',
    image:
      'https://images.unsplash.com/photo-1584735935682-2f2b69dff9d2?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwyNzc5NjV8MHwxfGFsbHx8fHx8fHx8fDE2NDcxOTczNjM&ixlib=rb-1.2.1&q=80&w=500',
  },
  {
    displayName: 'Pet Supplies',
    id: 'PET_SUPPLIES',
    image:
      'https://images.unsplash.com/photo-1580597095981-8d21aa8cdfed?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwyNzc5NjV8MHwxfGFsbHx8fHx8fHx8fDE2NDcxOTc0Nzk&ixlib=rb-1.2.1&q=80&w=500',
  },
  {
    displayName: 'Cell Phones & Accessories',
    id: 'MOBILE_AND_ACCESSORIES',
    image:
      'https://images.unsplash.com/photo-1576107324820-c10884700b6b?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwyNzc5NjV8MHwxfGFsbHx8fHx8fHx8fDE2NDcxOTc1Mjg&ixlib=rb-1.2.1&q=80&w=500',
  },
  {
    displayName: 'Baby',
    id: 'BABY',
    image:
      'https://images.unsplash.com/photo-1566004100631-35d015d6a491?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwyNzc5NjV8MHwxfGFsbHx8fHx8fHx8fDE2NDcxOTc1NTA&ixlib=rb-1.2.1&q=80&w=500',
  },
  {
    displayName: 'Automotive Parts & Accessories',
    id: 'AUTOMOTIVE',
    image:
      'https://images.unsplash.com/photo-1590227763209-821c686b932f?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwyNzc5NjV8MHwxfGFsbHx8fHx8fHx8fDE2NDcxOTc1ODg&ixlib=rb-1.2.1&q=80&w=500',
  },
  {
    displayName: 'Appliances',
    id: 'APPLIANCES',
    image:
      'https://images.unsplash.com/photo-1624209190904-aca680ededc1?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwyNzc5NjV8MHwxfGFsbHx8fHx8fHx8fDE2NDcxOTc2MTA&ixlib=rb-1.2.1&q=80&w=500',
  },
  {
    displayName: 'Arts, Crafts & Sewing',
    id: 'ARTS_AND_CRAFTS',
    image:
      'https://images.unsplash.com/photo-1616706161242-f1d591350d1c?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwyNzc5NjV8MHwxfGFsbHx8fHx8fHx8fDE2NDcxOTc2NDQ&ixlib=rb-1.2.1&q=80&w=500',
  },
  {
    displayName: 'Garden & Outdoor',
    id: 'GARDEN_AND_OUTDOOR',
    image:
      'https://images.unsplash.com/photo-1611735341450-74d61e660ad2?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwyNzc5NjV8MHwxfGFsbHx8fHx8fHx8fDE2NDcxOTc3ODM&ixlib=rb-1.2.1&q=80&w=500',
  },
  {
    displayName: 'Home & Business Services',
    id: 'LOCAL_SERVICES',
    image:
      'https://images.unsplash.com/photo-1530546171585-cc042ea5d7ab?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwyNzc5NjV8MHwxfGFsbHx8fHx8fHx8fDE2NDcxOTc3MzY&ixlib=rb-1.2.1&q=80&w=500',
  },
  {
    displayName: 'Musical Instruments',
    id: 'MUSICAL_INSTRUMENTS',
    image:
      'https://images.unsplash.com/photo-1511379938547-c1f69419868d?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwyNzc5NjV8MHwxfGFsbHx8fHx8fHx8fDE2NDcxOTc3MDE&ixlib=rb-1.2.1&q=80&w=500',
  },
  {
    displayName: 'Handmade',
    id: 'HANDMADE',
    image:
      'https://images.unsplash.com/photo-1506806732259-39c2d0268443?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwyNzc5NjV8MHwxfGFsbHx8fHx8fHx8fDE2NDcxOTc2Nzk&ixlib=rb-1.2.1&q=80&w=500',
  },
]

export const amazonTaxRate = 0.07

const DIGITAL_CURRENCY = '64151505'
const VOUCHERS = '14111803'
const GIFT_CERTIFICATE = '14111608'

export const bannedTaxonomies = [DIGITAL_CURRENCY, VOUCHERS, GIFT_CERTIFICATE]

export const AMAZON_GRAY = '#232F3E'
