import { Avatar } from 'antd'
import { findFlagUrlByIso2Code } from 'country-flags-svg'
import { useTheme } from 'evergreen-ui'

export function FlagAvatar({ iso2 }: { iso2: string }) {
  const theme = useTheme()

  return (
    <Avatar
      src={findFlagUrlByIso2Code(iso2)}
      size="small"
      style={{
        border: `1px solid ${theme.colors.gray300}`,
        minWidth: 24,
      }}
    />
  )
}
