import { Text } from 'evergreen-ui'
import { Link, LinkProps } from 'react-router-dom'

export function PerkLink({ children, ...other }: LinkProps) {
  return (
    <Text className="text-blue-500">
      <Link {...other}>{children}</Link>
    </Text>
  )
}
