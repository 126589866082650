import { BgColorsOutlined } from '@ant-design/icons'
import { Button, Checkbox, Switch, Tabs } from 'antd'
import { CompanyDisplay } from 'components/Navs/CompanyDisplay'
import { Heading, Pane, Strong, Text } from 'evergreen-ui'
import { useDefaultOrgColors } from 'hooks'

export function CompanyBrandingPreview() {
  const { defaultColor } = useDefaultOrgColors()

  return (
    <Pane
      display="flex"
      height="max-content"
      borderRadius={4}
      paddingY={32}
      paddingX={32}
      background="tint2"
    >
      <Pane
        display="flex"
        flexDirection="column"
        background="white"
        borderRadius={16}
        elevation={1}
        maxWidth={624}
      >
        <Pane padding={16}>
          <CompanyDisplay />
        </Pane>
        <Pane display="flex" borderTop="muted">
          <Pane
            display="flex"
            flexDirection="column"
            gap={16}
            borderRight="muted"
            paddingY={32}
            paddingX={16}
          >
            <Tabs
              tabPosition="left"
              items={[1, 2].map(item => {
                const id = String(item)
                return {
                  label: (
                    <Pane>
                      <BgColorsOutlined />
                      Tab {id}
                    </Pane>
                  ),
                  key: id,
                }
              })}
            />
          </Pane>
          <Pane padding={32} display="flex" flexDirection="column" gap={24}>
            <Pane display="flex" flexDirection="column" gap={8}>
              <Strong size={500} color={defaultColor}>
                Kicker text
              </Strong>
              <Heading size={700}>Preview branding</Heading>
              <Text>
                This is what your app will look like in your chosen brand color.
                We suggest using high contrast colors to maintain readability.
              </Text>
            </Pane>
            <Pane
              display="flex"
              flexDirection="column"
              alignItems="end"
              width="max-content"
              gap={8}
            >
              <Pane display="flex" gap={8}>
                <Switch defaultChecked />
                <Text paddingRight={8}>Toggle switch</Text>
              </Pane>
              <Checkbox defaultChecked>Checkbox</Checkbox>
            </Pane>
            <Pane display="flex" gap={16}>
              <Button type="primary">Primary button</Button>
              <Button type="default">Secondary button</Button>
              <Button disabled>Disabled</Button>
            </Pane>
          </Pane>
        </Pane>
      </Pane>
    </Pane>
  )
}
