import { DatePicker } from 'antd'
import { Heading, Pane, Text } from 'evergreen-ui'
import { Program } from 'gen/perkup/v1/program_pb'
import { RangeValue } from 'types/RewardsDate'
import { getDateTimeString } from 'utils'

const { RangePicker } = DatePicker

export function ProgramDates({
  duration,
  orgId,
  program,
  edit,
  setDuration,
}: {
  duration: RangeValue
  orgId: string
  program: Program
  edit: boolean
  setDuration: (duration: RangeValue) => void
}) {
  const startDate = getDateTimeString(program?.startsOn)
  const endDate = getDateTimeString(program?.endsOn)

  if (!program?.id || !orgId) return null

  const hasExpiration = !!endDate

  if (edit) {
    return (
      <>
        <Heading size={100}>PROGRAM DATES</Heading>
        <Pane maxWidth={360}>
          <RangePicker
            style={{ width: '100%' }}
            value={duration}
            onChange={setDuration}
            disabled={[true, false]}
            allowEmpty={[false, true]}
          />
        </Pane>
      </>
    )
  }

  return (
    <>
      <Pane display="flex" alignItems="center">
        <Heading size={100}>
          {hasExpiration ? 'Date Range' : 'Start date'}
        </Heading>
      </Pane>
      <Text>
        <>
          {startDate} {endDate && 'to'} {endDate}{' '}
        </>
      </Text>
    </>
  )
}
