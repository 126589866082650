import { ApiOutlined, MonitorOutlined } from '@ant-design/icons'
import { useCubeQuery } from '@cubejs-client/react'
import { ErrorBoundary } from '@sentry/react'
import { Skeleton } from 'antd'
import { isString } from 'antd/es/button'
import { PerkAvatar, PerkEmpty, PerkLoader, PerkScrollbars } from 'components'
import { Heading, Text } from 'evergreen-ui'
import { useHasMounted, useInfiniteScroll } from 'hooks'
import { useDevSafeOrgId } from 'hooks/useDevSafeOrgId'
import { useInsightsContext } from './insights-context'

function Error() {
  return (
    <div className="px-4 lg:px-6">
      <PerkEmpty
        iconNode={
          <ApiOutlined
            className="text-muted-foreground"
            style={{ fontSize: 32 }}
          />
        }
        header="Oops! Something went wrong"
        description="We couldn't load your data. Please try again later."
      />
    </div>
  )
}

function TopRecipientsComponent() {
  const orgId = useDevSafeOrgId()
  const hasMounted = useHasMounted()

  const { dateRange, insightsGlobalFilters } = useInsightsContext()
  const { currentLimit, sentinelRef, isFirstPage } = useInfiniteScroll()

  const { resultSet, isLoading, error } = useCubeQuery(
    {
      limit: currentLimit,
      measures: ['topRecipients.rewardsReceived'],
      dimensions: [
        'topRecipients.id',
        'topRecipients.email',
        'topRecipients.firstName',
        'topRecipients.lastName',
        'topRecipients.profilePicture',
      ],
      filters: [
        {
          member: 'organizations.id',
          operator: 'equals',
          values: [orgId],
        },
        {
          member: 'topRecipients.id',
          operator: 'set',
        },
        ...insightsGlobalFilters,
      ],
      timeDimensions: [
        {
          dimension: 'topRecipients.memberships_created',
          dateRange,
        },
      ],
    },
    {
      resetResultSetOnChange: false,
    }
  )

  if ((isLoading && isFirstPage) || !hasMounted) {
    return (
      <PerkScrollbars style={{ maxHeight: '100%' }}>
        <div className="px-4 lg:px-6">
          <Skeleton active paragraph={{ rows: 12 }} />
        </div>
      </PerkScrollbars>
    )
  }

  if (error || !resultSet) {
    return <Error />
  }

  if (resultSet.rawData().length === 0) {
    return (
      <div className="px-4 lg:px-6">
        <PerkEmpty
          iconNode={
            <MonitorOutlined
              className="text-muted-foreground"
              style={{ fontSize: 32 }}
            />
          }
          header="Nothing found"
          description="Try a different date range or filter."
        />
      </div>
    )
  }

  return (
    <PerkScrollbars style={{ maxHeight: '100%' }}>
      <div className="flex flex-col gap-6 px-4 lg:px-6">
        {resultSet.rawData().map(row => {
          const {
            'topRecipients.id': recipientId,
            'topRecipients.email': recipientEmail,
            'topRecipients.firstName': recipientFirstName,
            'topRecipients.lastName': recipientLastName,
            'topRecipients.profilePicture': recipientProfilePicture,
            'topRecipients.rewardsReceived': rewardsReceived,
          } = row

          const recipientFullName = `${recipientFirstName || ''} ${recipientLastName || ''}`

          return (
            <article
              key={recipientId?.toString()}
              className="flex justify-between"
            >
              <div className="flex gap-2 items-center">
                <PerkAvatar
                  profilePicture={
                    isString(recipientProfilePicture)
                      ? recipientProfilePicture
                      : undefined
                  }
                  firstName={
                    isString(recipientFirstName)
                      ? recipientFirstName
                      : undefined
                  }
                  lastName={
                    isString(recipientLastName) ? recipientLastName : undefined
                  }
                  email={isString(recipientEmail) ? recipientEmail : undefined}
                />

                <div className="flex flex-col">
                  <Text>{recipientFullName}</Text>
                  <Text size={300} color="muted">
                    {recipientEmail}
                  </Text>
                </div>
              </div>

              <Text>{Number(rewardsReceived || 0).toLocaleString()}</Text>
            </article>
          )
        })}
        <div
          className="pb-6"
          style={
            !isLoading && currentLimit > resultSet.rawData().length
              ? {
                  display: 'none',
                }
              : {}
          }
          ref={sentinelRef}
        >
          <PerkLoader marginY={0} />
        </div>
      </div>
    </PerkScrollbars>
  )
}

export function TopRecipients() {
  const { dateRange, insightsGlobalFilters } = useInsightsContext()

  return (
    <section className="flex flex-col gap-6 w-full h-full rounded-lg border border-muted py-4">
      <div className="flex justify-between px-4 lg:px-6">
        <Heading>Top recipients</Heading>
        <Text color="muted">By rewards received</Text>
      </div>

      <ErrorBoundary fallback={<Error />}>
        <TopRecipientsComponent
          key={JSON.stringify({ dateRange, insightsGlobalFilters })}
        />
      </ErrorBoundary>
    </section>
  )
}
