import { Flex } from 'antd'
import { ConvertableToOptions } from 'components'
import { useIndividualRole } from 'hooks'
import { MaxQuantityForm } from 'pages/NewReward/components/MaxQuantityForm'
import { useSelectRewardContext } from '../SelectRewardContext'

export function RedemptionOptionsForm() {
  const { isAdmin } = useIndividualRole()
  const { redemptionOptionsForm, selectedReward } = useSelectRewardContext()

  return (
    <Flex vertical gap={16}>
      <Flex style={{ width: 400 }}>
        <MaxQuantityForm
          key={`${selectedReward.gift?.id}/${selectedReward.gift?.productIds}/${selectedReward.gift?.redeemableQuantity}`}
          currentGift={selectedReward.gift}
          budget={selectedReward.budget}
          onRedeemableQuantityChange={
            redemptionOptionsForm.handleRedeemableQuantityChange
          }
          onBudgetChange={redemptionOptionsForm.handleBudgetChange}
        />
      </Flex>
      {isAdmin && (
        <ConvertableToOptions
          currentConvertableTo={selectedReward?.gift?.convertableTo}
          variant="select"
          onConvertableToChange={
            redemptionOptionsForm.handleConvertableToChange
          }
        />
      )}
    </Flex>
  )
}
