import {
  CopyOutlined,
  DeleteOutlined,
  EditOutlined,
  EllipsisOutlined,
} from '@ant-design/icons'
import { Button, Dropdown, Flex, Image, MenuProps, Modal, Tooltip } from 'antd'
import { deleteOrgTemplate } from 'api/databaseCalls/writes/templates'
import { SWAG_LOGO } from 'assets/contentful'
import { allCategoriesIcon, lunch } from 'assets/icons'
import { BrandedTags } from 'components'
import { PerkLink } from 'components/perk/perk-link'
import { ApprovedCategories, allCategories } from 'constants/EligibleCategories'
import { PERKUP_DOLLARS } from 'constants/newReward'
import { defaultOccasions } from 'constants/newReward/occasions'
import { DEFAULT_ROUTES } from 'constants/routes'
import { Heading, Pane, Strong, Text } from 'evergreen-ui'
import { Template, Template_Access } from 'gen/perkup/v1/org_template_pb'
import useIds from 'hooks/useIds'
import { capitalize, compact, isEmpty } from 'lodash-es'
import { useState } from 'react'
import { useNavigate } from 'react-router'
import { makePlural } from 'utils'
import { eligibleRewardKeys } from '../constants'

export const TEMPLATE_DETAILS_CARD_WIDTH = 480

function TemplateDetails({ template }: { template: Template }) {
  const templateAccess = () => {
    if (template?.access === Template_Access.all) {
      return 'Admins and managers'
    }
    if (template?.access === Template_Access.admin) {
      return 'Admins only'
    }
    return 'Managers only'
  }

  const eligibleRewards = compact(
    eligibleRewardKeys.map(rewardKey => {
      const label =
        rewardKey === 'cash' ? PERKUP_DOLLARS : capitalize(rewardKey)
      return template?.eligibleRewards?.[rewardKey] ? label : undefined
    })
  )

  const eligibleRewardsString = isEmpty(eligibleRewards)
    ? ''
    : eligibleRewards.join(', ')

  const templateExpiry = template?.expiry?.days

  const templateExpiryString = templateExpiry
    ? `${templateExpiry} ${makePlural('day', templateExpiry)}`
    : 'No expiry'

  const occasion = defaultOccasions.find(defaultOccasion => {
    return defaultOccasion.occasion === template.occasion
  })

  const approvedCats = template.approvedCategories
  const hasApprovedCats = !isEmpty(approvedCats)

  const approvedCategoriesDisplay = () => {
    if (approvedCats.length > 1) {
      return {
        label: allCategories,
        image: allCategoriesIcon,
      }
    }
    if (approvedCats[0] === ApprovedCategories.FOOD) {
      return {
        label: ApprovedCategories.FOOD,
        image: lunch,
      }
    }
    return {
      label: ApprovedCategories.SWAG,
      image: SWAG_LOGO,
    }
  }

  return (
    <Pane
      display="grid"
      gridTemplateColumns="1fr 1.5fr"
      gap={8}
      alignItems="start"
      alignSelf="center"
      columnGap={32}
    >
      {!!template?.eligibleRewards && (
        <>
          <Heading size={100}>Reward</Heading>
          <Text>{eligibleRewardsString}</Text>
        </>
      )}
      <Heading size={100}>Template access</Heading>
      <Text>{templateAccess()}</Text>
      <Heading size={100}>Recipient expiry</Heading>
      <Text>{templateExpiryString}</Text>
      {hasApprovedCats && (
        <>
          <Heading size={100}>Approved categories</Heading>
          <Flex gap={8} align="center">
            <Image
              preview={false}
              style={{ width: 16, height: 16 }}
              src={approvedCategoriesDisplay().image}
            />
            <Text>{approvedCategoriesDisplay().label}</Text>
          </Flex>
        </>
      )}
      {!!occasion && (
        <>
          <Heading size={100}>Reward type</Heading>
          <Flex gap={8} align="center">
            <Image
              preview={false}
              style={{ width: 16, height: 16 }}
              src={occasion.image}
            />
            <Text>{occasion.label}</Text>
          </Flex>
        </>
      )}
      <>
        <Heading size={100}>Internal memo</Heading>
        <Text>{template?.internalMemo || '-'}</Text>
      </>
      <>
        <Heading size={100}>Reward message</Heading>
        <Flex vertical gap={8}>
          <Strong>{template?.email?.title}</Strong>
          <Text>{template?.email?.message}</Text>
        </Flex>
      </>
    </Pane>
  )
}

export function TemplateDetailsCard({
  template,
  onCardClick,
  isExpanded,
}: {
  template: Template
  onCardClick: () => void
  isExpanded: boolean
}) {
  const navigate = useNavigate()
  const { orgId } = useIds()
  const [showCTAs, setShowCTAs] = useState(false)

  const [nameHovered, setNameHovered] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [isLoadingDelete, setIsLoadingDelete] = useState(false)

  const templateBudgets = template?.budget?.amounts

  const templateBudgetsDisplayString =
    templateBudgets?.map(budget => `$${budget / 100}`).join(', ') || ''

  const coreValues = template?.coreValues?.values
  const hasCoreValues = !!coreValues && !isEmpty(coreValues)

  const dropdownItems: MenuProps['items'] = [
    {
      key: 'duplicate',
      label: 'Duplicate',
      icon: <CopyOutlined />,
      onClick: e => {
        e.domEvent.stopPropagation()
        navigate(DEFAULT_ROUTES.ORGANIZATION.REWARDS.TEMPLATES.NEW, {
          state: {
            template: {
              ...template,
              id: '',
            },
          },
        })
      },
    },
    {
      key: 'delete',
      label: 'Delete',
      icon: <DeleteOutlined />,
      danger: true,
      onClick: e => {
        e.domEvent.stopPropagation()
        setShowDeleteModal(true)
      },
    },
  ]

  const handleDeleteTemplate = async () => {
    setIsLoadingDelete(true)
    await deleteOrgTemplate({
      orgId,
      templateId: template.id,
    })
    setShowDeleteModal(false)
    setIsLoadingDelete(false)
  }

  return (
    <Flex
      vertical
      onMouseEnter={() => setShowCTAs(true)}
      onMouseLeave={() => setShowCTAs(false)}
      style={{
        minWidth: TEMPLATE_DETAILS_CARD_WIDTH,
        height: '100%',
      }}
      key={template.id}
    >
      <Modal
        open={showDeleteModal}
        title="Delete template"
        destroyOnClose
        onCancel={() => setShowDeleteModal(false)}
        onOk={handleDeleteTemplate}
        confirmLoading={isLoadingDelete}
        okText="Delete"
      >
        <Text>{`Are you sure you'd like to delete ${template.name}?`}</Text>
      </Modal>

      <Pane
        display="flex"
        flexDirection="column"
        height="100%"
        gap={16}
        border="muted"
        padding={16}
        onClick={onCardClick}
        cursor="pointer"
        borderTopLeftRadius={8}
        borderTopRightRadius={8}
        borderBottomLeftRadius={isExpanded ? 0 : 8}
        borderBottomRightRadius={isExpanded ? 0 : 8}
      >
        <Flex gap={16} style={{ width: '100%' }}>
          <Image
            src={template?.image?.url}
            style={{
              width: 72,
              height: 72,
              objectFit: 'cover',
              borderRadius: 8,
            }}
            preview={false}
          />
          <Flex style={{ width: '100%' }} gap={16} justify="space-between">
            <Flex vertical gap={8} style={{ maxWidth: 'calc(100% - 120px)' }}>
              <PerkLink
                rel="noopener noreferrer"
                onClick={e => e.stopPropagation()}
                to={`${DEFAULT_ROUTES.ORGANIZATION.REWARDS.TEMPLATES.ROOT}/${template.id}`}
                onMouseEnter={() => setNameHovered(true)}
                onMouseLeave={() => setNameHovered(false)}
              >
                <Heading
                  textDecoration={nameHovered ? 'underline' : 'none'}
                  alignSelf="start"
                >
                  {template.name}
                </Heading>
              </PerkLink>
              <Text>{templateBudgetsDisplayString}</Text>
            </Flex>

            {(showCTAs || isExpanded) && (
              <Flex>
                <Tooltip title="Edit template">
                  <PerkLink
                    rel="noopener noreferrer"
                    onClick={e => e.stopPropagation()}
                    to={`${DEFAULT_ROUTES.ORGANIZATION.REWARDS.TEMPLATES.ROOT}/${template.id}`}
                  >
                    <Button icon={<EditOutlined />} type="text" />
                  </PerkLink>
                </Tooltip>
                <Dropdown
                  menu={{
                    onClick: e => e.domEvent.stopPropagation(),
                    items: dropdownItems,
                  }}
                >
                  <Button
                    onClick={e => e.stopPropagation()}
                    icon={<EllipsisOutlined rotate={90} />}
                    type="text"
                  />
                </Dropdown>
              </Flex>
            )}
          </Flex>
        </Flex>
        {hasCoreValues && <BrandedTags coreValues={coreValues} />}
      </Pane>
      {isExpanded && (
        <Pane
          borderLeft="muted"
          borderRight="muted"
          borderBottom="muted"
          display="flex"
          flexDirection="column"
          paddingY={16}
          paddingX={32}
          borderBottomLeftRadius={8}
          borderBottomRightRadius={8}
        >
          <TemplateDetails template={template} />
        </Pane>
      )}
    </Flex>
  )
}
