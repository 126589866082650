import { captureException } from '@sentry/react'
import {
  deleteDoc,
  doc,
  getFirestore,
  serverTimestamp,
  setDoc,
} from 'firebase/firestore'
import { Member, Program, ProgramStatus } from 'gen/perkup/v1/program_pb'
import { toSentry } from 'utils'
import { storedId } from 'utils/firestore'

export async function deleteInvitedUser({
  orgId,
  invitedUserId,
}: {
  orgId: string
  invitedUserId: string
}) {
  if (!orgId || !invitedUserId) return undefined
  const db = getFirestore()
  const docRef = doc(db, `organizations/${orgId}/invitedUsers/${invitedUserId}`)
  return deleteDoc(docRef)
}

export function removeProgram({
  orgId,
  programId,
}: {
  orgId: string
  programId: string
}) {
  if (!orgId || !programId) return
  const db = getFirestore()
  const docRef = doc(
    db,
    `organizations/${orgId}/programs/${programId}`
  ).withConverter(storedId(Program))
  setDoc(
    docRef,
    {
      status: ProgramStatus.inactive,
      isDeleted: true,
      deletedAt: serverTimestamp(),
    },
    { merge: true }
  ).catch(error => {
    console.error(error)
    captureException(toSentry(error), {
      contexts: {
        removeProgram: {
          orgId,
          programId,
        },
      },
    })
  })
}

export async function removeMemberFromProgram({
  orgId,
  programId,
  memberId,
}: {
  orgId: string
  programId: string
  memberId: string
}) {
  const db = getFirestore()
  const docRef = doc(
    db,
    `organizations/${orgId}/programs/${programId}/members/${memberId}`
  ).withConverter(storedId(Member))
  setDoc(
    docRef,
    {
      enrolled: false,
      removedAt: serverTimestamp(),
    },
    { merge: true }
  ).catch(error => {
    console.error(error)
    captureException(toSentry(error), {
      contexts: {
        removeMemberFromProgram: {
          orgId,
          programId,
          memberId,
        },
      },
    })
  })
}

export function removeScheduledMember({
  orgId,
  programId,
  scheduledMemberId,
}: {
  orgId: string
  programId: string
  scheduledMemberId: string
}) {
  if (!orgId || !programId || !scheduledMemberId) return
  const db = getFirestore()
  const docRef = doc(
    db,
    `organizations/${orgId}/programs/${programId}/scheduledMembers/${scheduledMemberId}`
  )
  deleteDoc(docRef).catch(error => {
    console.error(error)
    captureException(toSentry(error), {
      contexts: {
        removeScheduledMember: {
          orgId,
          programId,
          scheduledMemberId,
        },
      },
    })
  })
}

export async function removeShippingAddress({
  userId,
  shippingAddressId,
}: {
  userId: string
  shippingAddressId: string
}) {
  const db = getFirestore()
  const docRef = doc(
    db,
    `users/${userId}/shippingAddresses/${shippingAddressId}`
  )
  deleteDoc(docRef).catch(error => {
    console.error(error)
    captureException(toSentry(error), {
      contexts: {
        removeShippingAddress: {
          userId,
          shippingAddressId,
        },
      },
    })
  })
}
