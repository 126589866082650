// @generated by protoc-gen-es v1.6.0 with parameter "target=ts"
// @generated from file perkup/v1/stripe.proto (package perkup.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";

/**
 * @generated from enum perkup.v1.CheckoutProduct
 */
export enum CheckoutProduct {
  /**
   * @generated from enum value: UNKNOWN = 0;
   */
  UNKNOWN = 0,

  /**
   * @generated from enum value: PERSONAL_FUNDS = 1;
   */
  PERSONAL_FUNDS = 1,

  /**
   * @generated from enum value: SWAG_STORE_CHECKOUT = 2;
   */
  SWAG_STORE_CHECKOUT = 2,

  /**
   * @generated from enum value: BULK_SWAG_CATALOG = 3;
   */
  BULK_SWAG_CATALOG = 3,
}
// Retrieve enum metadata with: proto3.getEnumType(CheckoutProduct)
proto3.util.setEnumType(CheckoutProduct, "perkup.v1.CheckoutProduct", [
  { no: 0, name: "UNKNOWN" },
  { no: 1, name: "PERSONAL_FUNDS" },
  { no: 2, name: "SWAG_STORE_CHECKOUT" },
  { no: 3, name: "BULK_SWAG_CATALOG" },
]);

/**
 * @generated from message perkup.v1.BannedMerchant
 */
export class BannedMerchant extends Message<BannedMerchant> {
  /**
   * @generated from field: string merchant = 1;
   */
  merchant = "";

  constructor(data?: PartialMessage<BannedMerchant>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "perkup.v1.BannedMerchant";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "merchant", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): BannedMerchant {
    return new BannedMerchant().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): BannedMerchant {
    return new BannedMerchant().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): BannedMerchant {
    return new BannedMerchant().fromJsonString(jsonString, options);
  }

  static equals(a: BannedMerchant | PlainMessage<BannedMerchant> | undefined, b: BannedMerchant | PlainMessage<BannedMerchant> | undefined): boolean {
    return proto3.util.equals(BannedMerchant, a, b);
  }
}

/**
 * @generated from message perkup.v1.NetworkId
 */
export class NetworkId extends Message<NetworkId> {
  /**
   * @generated from field: string merchant = 1;
   */
  merchant = "";

  /**
   * @generated from field: repeated string names = 2;
   */
  names: string[] = [];

  constructor(data?: PartialMessage<NetworkId>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "perkup.v1.NetworkId";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "merchant", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "names", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): NetworkId {
    return new NetworkId().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): NetworkId {
    return new NetworkId().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): NetworkId {
    return new NetworkId().fromJsonString(jsonString, options);
  }

  static equals(a: NetworkId | PlainMessage<NetworkId> | undefined, b: NetworkId | PlainMessage<NetworkId> | undefined): boolean {
    return proto3.util.equals(NetworkId, a, b);
  }
}

/**
 * @generated from message perkup.v1.Category
 */
export class Category extends Message<Category> {
  /**
   * @generated from field: repeated string names = 1;
   */
  names: string[] = [];

  constructor(data?: PartialMessage<Category>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "perkup.v1.Category";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "names", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Category {
    return new Category().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Category {
    return new Category().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Category {
    return new Category().fromJsonString(jsonString, options);
  }

  static equals(a: Category | PlainMessage<Category> | undefined, b: Category | PlainMessage<Category> | undefined): boolean {
    return proto3.util.equals(Category, a, b);
  }
}

