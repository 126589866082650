import CheckCircleTwoTone from '@ant-design/icons/CheckCircleTwoTone'
import ExclamationCircleTwoTone from '@ant-design/icons/ExclamationCircleTwoTone'
import Input, { InputRef } from 'antd/es/input'
import { Pane, Text } from 'evergreen-ui'
import isEmpty from 'lodash-es/isEmpty'
import { useEffect, useRef, useState } from 'react'

export function EditableTextCell({
  currentValue,
  isTextArea,
  onSubmit,
  disabled,
}: {
  currentValue: string
  isTextArea?: boolean
  onSubmit?: (newText: string) => void
  disabled?: boolean
}) {
  const [isEditing, setIsEditing] = useState<boolean>(false)
  const [newValue, setNewValue] = useState<string>('')

  const inputRef = useRef<InputRef>(null)

  // Set useState hook default to currentValue prop.
  useEffect(() => {
    if (!isEditing) {
      setNewValue(currentValue)
    }
  }, [currentValue, isEditing])

  // When admin enters editing mode, place cursor right away into input field.
  useEffect(() => {
    if (isEditing) {
      inputRef.current!.focus()
    }
  }, [isEditing])

  // On focus change, or on press enter, try to submit to firestore.
  const handleSubmit = () => {
    if (isEmpty(newValue) || !onSubmit) return

    if (newValue !== currentValue) {
      onSubmit(newValue)
    }

    setIsEditing(false)
  }

  const InputComponent = isTextArea ? Input.TextArea : Input

  // Input validation. Prompt admin with UI.
  const inputStatus = newValue ? undefined : 'error'
  const inputSuffixIcon = newValue ? (
    <CheckCircleTwoTone />
  ) : (
    <ExclamationCircleTwoTone twoToneColor="red" />
  )

  // Display input component only if admin is in editing mode.
  if (isEditing) {
    return (
      <InputComponent
        ref={inputRef}
        status={inputStatus}
        suffix={inputSuffixIcon}
        value={newValue}
        onBlur={handleSubmit}
        onPressEnter={handleSubmit}
        onChange={(e: any) => {
          setNewValue(e.target.value)
        }}
        maxLength={512}
        showCount={isTextArea}
        autoSize
      />
    )
  }

  // Regular text component. On click, enter editing mode.
  return (
    <Pane
      className={disabled ? '' : 'editable-text-cell'}
      width="100%"
      onClick={() => {
        if (disabled) return
        setIsEditing(true)
      }}
      cursor={disabled ? 'default' : 'pointer'}
    >
      <Text className={isTextArea ? 'three-line-truncate' : undefined}>
        {currentValue}
      </Text>
    </Pane>
  )
}
