// @generated by protoc-gen-es v1.6.0 with parameter "target=ts"
// @generated from file perkup/v1/org_user.proto (package perkup.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, protoInt64, Timestamp } from "@bufbuild/protobuf";

/**
 * Firestore /organizations/:id/users
 *
 * @generated from message perkup.v1.User
 * @deprecated
 */
export class User extends Message<User> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: int32 balance = 2;
   */
  balance = 0;

  /**
   * @generated from field: optional string card_id = 3;
   */
  cardId?: string;

  /**
   * @generated from field: optional string customer_id = 4;
   */
  customerId?: string;

  /**
   *  Individual.Role role = 5; REMOVED !!!
   *
   * @generated from field: int32 spent = 6;
   */
  spent = 0;

  constructor(data?: PartialMessage<User>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "perkup.v1.User";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "balance", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "card_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 4, name: "customer_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 6, name: "spent", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): User {
    return new User().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): User {
    return new User().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): User {
    return new User().fromJsonString(jsonString, options);
  }

  static equals(a: User | PlainMessage<User> | undefined, b: User | PlainMessage<User> | undefined): boolean {
    return proto3.util.equals(User, a, b);
  }
}

/**
 * Firestore /organizations/:id/users/:id/feedback
 *
 * @generated from message perkup.v1.Feedback
 * @deprecated
 */
export class Feedback extends Message<Feedback> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: google.protobuf.Timestamp created = 2;
   */
  created?: Timestamp;

  /**
   * @generated from field: string result = 3;
   */
  result = "";

  constructor(data?: PartialMessage<Feedback>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "perkup.v1.Feedback";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "created", kind: "message", T: Timestamp },
    { no: 3, name: "result", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Feedback {
    return new Feedback().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Feedback {
    return new Feedback().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Feedback {
    return new Feedback().fromJsonString(jsonString, options);
  }

  static equals(a: Feedback | PlainMessage<Feedback> | undefined, b: Feedback | PlainMessage<Feedback> | undefined): boolean {
    return proto3.util.equals(Feedback, a, b);
  }
}

/**
 * Firestore /organizations/:id/users/:id/eligibleCategories/allMerchants, Cell Phone, Child Care, Education...
 * TODO: movie to /organizations/:id/individuals/:id/eligibleCategories
 *
 * @generated from message perkup.v1.EligibleCategory
 * @deprecated
 */
export class EligibleCategory extends Message<EligibleCategory> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: int32 amount = 2;
   */
  amount = 0;

  /**
   * @generated from field: optional int32 available_personal_funds = 3;
   */
  availablePersonalFunds?: number;

  /**
   * @generated from field: repeated perkup.v1.EligibleCategory.ProgramInfo programs_info = 4;
   */
  programsInfo: EligibleCategory_ProgramInfo[] = [];

  constructor(data?: PartialMessage<EligibleCategory>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "perkup.v1.EligibleCategory";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "amount", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "available_personal_funds", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 4, name: "programs_info", kind: "message", T: EligibleCategory_ProgramInfo, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EligibleCategory {
    return new EligibleCategory().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EligibleCategory {
    return new EligibleCategory().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EligibleCategory {
    return new EligibleCategory().fromJsonString(jsonString, options);
  }

  static equals(a: EligibleCategory | PlainMessage<EligibleCategory> | undefined, b: EligibleCategory | PlainMessage<EligibleCategory> | undefined): boolean {
    return proto3.util.equals(EligibleCategory, a, b);
  }
}

/**
 * @generated from message perkup.v1.EligibleCategory.ProgramInfo
 */
export class EligibleCategory_ProgramInfo extends Message<EligibleCategory_ProgramInfo> {
  /**
   * @generated from field: string program_id = 1;
   */
  programId = "";

  /**
   * @generated from field: string program_name = 2;
   */
  programName = "";

  constructor(data?: PartialMessage<EligibleCategory_ProgramInfo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "perkup.v1.EligibleCategory.ProgramInfo";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "program_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "program_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EligibleCategory_ProgramInfo {
    return new EligibleCategory_ProgramInfo().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EligibleCategory_ProgramInfo {
    return new EligibleCategory_ProgramInfo().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EligibleCategory_ProgramInfo {
    return new EligibleCategory_ProgramInfo().fromJsonString(jsonString, options);
  }

  static equals(a: EligibleCategory_ProgramInfo | PlainMessage<EligibleCategory_ProgramInfo> | undefined, b: EligibleCategory_ProgramInfo | PlainMessage<EligibleCategory_ProgramInfo> | undefined): boolean {
    return proto3.util.equals(EligibleCategory_ProgramInfo, a, b);
  }
}

/**
 * Firestore /organizations/:id/users/:id/pendingAuthorizations/:id
 * TODO: delete
 *
 * @generated from message perkup.v1.PendingAuthorization
 * @deprecated
 */
export class PendingAuthorization extends Message<PendingAuthorization> {
  /**
   * stored in firestore
   *
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: int64 capture = 2;
   */
  capture = protoInt64.zero;

  /**
   * @generated from field: int64 hold = 3;
   */
  hold = protoInt64.zero;

  /**
   * @generated from field: repeated perkup.v1.PendingAuthorization.PendingProgram pending_programs = 4;
   */
  pendingPrograms: PendingAuthorization_PendingProgram[] = [];

  /**
   * @generated from field: int64 refund = 5;
   */
  refund = protoInt64.zero;

  /**
   * @generated from field: int64 release = 6;
   */
  release = protoInt64.zero;

  constructor(data?: PartialMessage<PendingAuthorization>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "perkup.v1.PendingAuthorization";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "capture", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "hold", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: "pending_programs", kind: "message", T: PendingAuthorization_PendingProgram, repeated: true },
    { no: 5, name: "refund", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 6, name: "release", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PendingAuthorization {
    return new PendingAuthorization().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PendingAuthorization {
    return new PendingAuthorization().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PendingAuthorization {
    return new PendingAuthorization().fromJsonString(jsonString, options);
  }

  static equals(a: PendingAuthorization | PlainMessage<PendingAuthorization> | undefined, b: PendingAuthorization | PlainMessage<PendingAuthorization> | undefined): boolean {
    return proto3.util.equals(PendingAuthorization, a, b);
  }
}

/**
 * @generated from message perkup.v1.PendingAuthorization.PendingProgram
 */
export class PendingAuthorization_PendingProgram extends Message<PendingAuthorization_PendingProgram> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: int32 capture = 2;
   */
  capture = 0;

  /**
   * @generated from field: int32 hold = 3;
   */
  hold = 0;

  /**
   * @generated from field: int32 pecking_order = 4;
   */
  peckingOrder = 0;

  /**
   * @generated from field: int32 refund = 5;
   */
  refund = 0;

  /**
   * @generated from field: int32 release = 6;
   */
  release = 0;

  constructor(data?: PartialMessage<PendingAuthorization_PendingProgram>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "perkup.v1.PendingAuthorization.PendingProgram";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "capture", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "hold", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "pecking_order", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "refund", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 6, name: "release", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PendingAuthorization_PendingProgram {
    return new PendingAuthorization_PendingProgram().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PendingAuthorization_PendingProgram {
    return new PendingAuthorization_PendingProgram().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PendingAuthorization_PendingProgram {
    return new PendingAuthorization_PendingProgram().fromJsonString(jsonString, options);
  }

  static equals(a: PendingAuthorization_PendingProgram | PlainMessage<PendingAuthorization_PendingProgram> | undefined, b: PendingAuthorization_PendingProgram | PlainMessage<PendingAuthorization_PendingProgram> | undefined): boolean {
    return proto3.util.equals(PendingAuthorization_PendingProgram, a, b);
  }
}

/**
 * Firestore /organizations/:id/users/:id/personalFundsTransactions/:id
 * TODO(mino): probably moving personal accounts etc.
 *
 * @generated from message perkup.v1.PersonalFundsTransaction
 * @deprecated
 */
export class PersonalFundsTransaction extends Message<PersonalFundsTransaction> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: int32 amount = 2;
   */
  amount = 0;

  /**
   * @generated from field: google.protobuf.Timestamp created = 3;
   */
  created?: Timestamp;

  /**
   * @generated from field: string currency = 4;
   */
  currency = "";

  /**
   * @generated from field: string name = 5;
   */
  name = "";

  /**
   * @generated from field: string program_id = 6;
   */
  programId = "";

  /**
   * @generated from field: string source_id = 7;
   */
  sourceId = "";

  /**
   * @generated from field: string type = 8;
   */
  type = "";

  constructor(data?: PartialMessage<PersonalFundsTransaction>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "perkup.v1.PersonalFundsTransaction";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "amount", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "created", kind: "message", T: Timestamp },
    { no: 4, name: "currency", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "program_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "source_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PersonalFundsTransaction {
    return new PersonalFundsTransaction().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PersonalFundsTransaction {
    return new PersonalFundsTransaction().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PersonalFundsTransaction {
    return new PersonalFundsTransaction().fromJsonString(jsonString, options);
  }

  static equals(a: PersonalFundsTransaction | PlainMessage<PersonalFundsTransaction> | undefined, b: PersonalFundsTransaction | PlainMessage<PersonalFundsTransaction> | undefined): boolean {
    return proto3.util.equals(PersonalFundsTransaction, a, b);
  }
}

