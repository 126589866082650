import { getInvitedUsersByEmail, inviteMember } from 'api/databaseCalls'
import { SendEmail } from 'api/functionCalls'
import { AsyncButton } from 'components/Buttons/AsyncButton'
import * as EVENTS from 'constants/events'
import { OrgContext, UserContext } from 'context'
import { toaster } from 'evergreen-ui'
import { Individual_Role } from 'gen/perkup/v1/individual_pb'
import useIds from 'hooks/useIds'
import { useContext } from 'react'
import { logEvent } from 'utils'

export function ResendInviteButton({
  email,
  role,
}: {
  email: string
  role: Individual_Role
}) {
  const org = useContext(OrgContext)
  const user = useContext(UserContext)
  const { orgId } = useIds()

  return (
    <AsyncButton
      size="small"
      onClick={() => {
        return new Promise<void>((resolve, reject) => {
          getInvitedUsersByEmail({ orgId, email }).then(invites => {
            if (!invites || invites.length === 0) {
              inviteMember({ orgId, role, email })
            }
          })
          if (process.env.NODE_ENV === 'production') {
            SendEmail({
              toAddress: email,
              fromName: `${user.profile?.firstName} ${user.profile?.lastName}`,
              fromEmail: user.profile?.email ?? '',
              orgName: org.name,
            })
              .then(() => {
                // Track member invite sent event
                logEvent(EVENTS.MEMBER_INVITED, {
                  email,
                  orgId: org.id,
                  orgName: org.name,
                  userId: user.id,
                })
                toaster.success(`Successfully resent invite to ${email}`)
                resolve()
              })
              .catch(() => {
                toaster.danger(`Error resending invite to ${email}`)
                reject()
              })
          } else {
            toaster.success(`Successfully resent invite to ${email}`)
            resolve()
          }
        })
      }}
    >
      Resend invite
    </AsyncButton>
  )
}
