// @generated by protoc-gen-es v1.6.0 with parameter "target=ts"
// @generated from file amazon/search.proto (package amazon, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, protoInt64 } from "@bufbuild/protobuf";

/**
 * @generated from message amazon.ListProductsRequest
 */
export class ListProductsRequest extends Message<ListProductsRequest> {
  /**
   * @generated from field: string keywords = 1;
   */
  keywords = "";

  /**
   * @generated from field: string category = 2;
   */
  category = "";

  /**
   * @generated from field: string postal_code = 3;
   */
  postalCode = "";

  /**
   * @generated from field: string country_code = 4;
   */
  countryCode = "";

  /**
   * @generated from field: int64 page_number = 5;
   */
  pageNumber = protoInt64.zero;

  /**
   * @generated from field: int64 page_size = 6;
   */
  pageSize = protoInt64.zero;

  /**
   * @generated from field: repeated string search_refinement = 7;
   */
  searchRefinement: string[] = [];

  constructor(data?: PartialMessage<ListProductsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "amazon.ListProductsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "keywords", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "category", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "postal_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "country_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "page_number", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 6, name: "page_size", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 7, name: "search_refinement", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListProductsRequest {
    return new ListProductsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListProductsRequest {
    return new ListProductsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListProductsRequest {
    return new ListProductsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListProductsRequest | PlainMessage<ListProductsRequest> | undefined, b: ListProductsRequest | PlainMessage<ListProductsRequest> | undefined): boolean {
    return proto3.util.equals(ListProductsRequest, a, b);
  }
}

/**
 * @generated from message amazon.ListProductsResponse
 */
export class ListProductsResponse extends Message<ListProductsResponse> {
  /**
   * @generated from field: string search_response = 1;
   */
  searchResponse = "";

  constructor(data?: PartialMessage<ListProductsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "amazon.ListProductsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "search_response", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListProductsResponse {
    return new ListProductsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListProductsResponse {
    return new ListProductsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListProductsResponse {
    return new ListProductsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListProductsResponse | PlainMessage<ListProductsResponse> | undefined, b: ListProductsResponse | PlainMessage<ListProductsResponse> | undefined): boolean {
    return proto3.util.equals(ListProductsResponse, a, b);
  }
}

/**
 * @generated from message amazon.GetProductRequest
 */
export class GetProductRequest extends Message<GetProductRequest> {
  /**
   * @generated from field: string asin = 1;
   */
  asin = "";

  /**
   * @generated from field: string postal_code = 2;
   */
  postalCode = "";

  /**
   * @generated from field: string country_code = 3;
   */
  countryCode = "";

  constructor(data?: PartialMessage<GetProductRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "amazon.GetProductRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "asin", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "postal_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "country_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetProductRequest {
    return new GetProductRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetProductRequest {
    return new GetProductRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetProductRequest {
    return new GetProductRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetProductRequest | PlainMessage<GetProductRequest> | undefined, b: GetProductRequest | PlainMessage<GetProductRequest> | undefined): boolean {
    return proto3.util.equals(GetProductRequest, a, b);
  }
}

/**
 * @generated from message amazon.GetProductResponse
 */
export class GetProductResponse extends Message<GetProductResponse> {
  /**
   * @generated from field: string product_response = 1;
   */
  productResponse = "";

  constructor(data?: PartialMessage<GetProductResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "amazon.GetProductResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "product_response", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetProductResponse {
    return new GetProductResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetProductResponse {
    return new GetProductResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetProductResponse {
    return new GetProductResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetProductResponse | PlainMessage<GetProductResponse> | undefined, b: GetProductResponse | PlainMessage<GetProductResponse> | undefined): boolean {
    return proto3.util.equals(GetProductResponse, a, b);
  }
}

