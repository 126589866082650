import { Popover } from 'antd'
import { NUM_UNITS_TO_PERCENTAGE } from 'constants/shopify'
import { ExchangeRateContext } from 'context'
import { useDisplayCurrency } from 'hooks'
import { useContext } from 'react'
import { numToDollars } from 'utils'

/**
 *
 * @param savings - The total savings amount
 * @param discount - The discount percentage
 * @param inLocalAmounts - Whether to display the total savings amount in local currency
 * @returns A small tag that displays the discount percentage and the total savings amount
 */
export function SmallSavingsTag({
  savings,
  discount,
  inLocalAmounts = false,
}: {
  savings: number
  discount: number
  inLocalAmounts?: boolean
}) {
  const exchangeRate = useContext(ExchangeRateContext)
  const displayCurrency = useDisplayCurrency()

  if (discount === 0) return null

  const totalSavingsToDisplay = inLocalAmounts
    ? numToDollars(savings * exchangeRate, 2, false, displayCurrency)
    : numToDollars(savings, 2)

  return (
    <Popover
      title="Bulk discount"
      content={
        <section className="flex flex-col gap-3 w-80">
          <p>
            Bulk discounts apply to the total On Demand items in your cart, even
            if they&apos;re different products. The more items you add, the
            bigger the discount.
          </p>

          <ul className="flex flex-col">
            {Object.entries(NUM_UNITS_TO_PERCENTAGE).map(
              ([numUnits, discount]) => {
                if (discount === 0) return null
                return (
                  <li key={numUnits} className="w-full grid grid-cols-3">
                    <p className="col-span-1">{numUnits}+ items</p>
                    <p className="col-span-2 font-bold">{discount}% off</p>
                  </li>
                )
              }
            )}
          </ul>
        </section>
      }
    >
      <p className="bg-primary/10 text-primary-foreground px-2 py-[2px] rounded-[4px] text-xs min-w-max cursor-default">
        {discount}% off (you save {totalSavingsToDisplay})
      </p>
    </Popover>
  )
}
