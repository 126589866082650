import { IndividualContext, OrgContext, UserContext } from 'context'
import { useContext } from 'react'

export default function useIds() {
  const user = useContext(UserContext)
  const org = useContext(OrgContext)
  const individual = useContext(IndividualContext)

  const userId = user?.id
  const orgId = org?.id
  const individualId = individual?.id

  return { userId, orgId, individualId }
}
