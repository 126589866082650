export const sampleMessages = [
  'Thank you for everything you do!',
  'It’s your dedication that contributed to our success. Thank you for inspiring us!',
  `It’s hard to imagine our team without you. Thank you!`,
  `Every idea shared and every opportunity seized by you helped us achieve these heights. Kudos to your efforts!`,
  `Your dedication is imperative for the growth of our company. Thank you for your efforts.`,
  `A good employee inspires his or her boss. Thanks for being an inspiration.`,
  `An employee’s hard work is the fuel to great company culture. Thanks for adding extra miles to ours.`,
  `Your attention to detail at work makes you more unique. Keep up the good work.`,
  `The service you provided exceeded all expectations. I would also like to add how much you mean to our company. Thank you, and keep up with the good work.`,
  `Your desire and willingness fuel our mutual efforts and guide us to success.`,
  `It’s the vision of employees like you who turn desired plans into success. We value you and your work to the moon and back.`,
  `Having you on the team is a matter of privilege for us. Thank you!`,
  `Every single day, you inspire us with your good work. Kudos!`,
  `Thank you for being a great addition to our team. Your willingness to deliver high-quality work is really admirable. Thank you!`,
  `It’s hard to imagine our team without you. Thank you!`,
  `A company dreams to have employees like you. We feel privileged to have you. Thank you.`,
  `Thank you for your commitment to your job and the work you do to achieve our company goals!`,
  `Your inspiration is contagious. Thank you for setting an excellent example for your peers.`,
  `You exemplify professionalism. Having you on the team is an absolute privilege.`,
  `The positive effect you have on others is contagious - thank you for lifting the office spirit.`,
  `You are great at what you do. Thank you for being the gift that keeps on giving.`,
  `The energy you bring to the office is great! Keep it up, rockstar.`,
  `Be proud of your success. You absolutely deserve it.`,
  `Well done on your achievement. You were born for this.`,
  `Leadership looks great on you. Good luck on the new journey.`,
  `You’ve kept your eye on the prize. Well done on achieving your dream job - you’ve certainly earned it.`,
  `You are phenomenal at your job. Thank you for continuously raising the bar.`,
  `We would be ten steps behind if it weren’t for your hard work. Thank you for setting an example and doing the best you can.`,
]

export const sampleBanners = [
  'https://media.giphy.com/media/Is1O1TWV0LEJi/giphy.gif',
  'https://media.giphy.com/media/l0MYt5jPR6QX5pnqM/giphy.gif',
  'https://media.giphy.com/media/BPJmthQ3YRwD6QqcVD/giphy.gif',
  'https://media.giphy.com/media/cklPOHnHepdwBLRnQp/giphy.gif',
  'https://media.giphy.com/media/l2JJyDYEX1tXFmCd2/giphy.gif',
  'https://media.giphy.com/media/DKnMqdm9i980E/giphy.gif',
]
