import { LogoutOutlined } from '@ant-design/icons'
import { PerkIconTooltipButton } from 'components'
import { PerkUpName } from 'components/Typography'
import * as EVENTS from 'constants/events'
import { MARKETING_HOSTNAME } from 'constants/hosts'
import * as ROUTES from 'constants/routes'
import { OrgContext, UserContext } from 'context'
import { Heading, Pane } from 'evergreen-ui'
import { getAuth, signOut } from 'firebase/auth'
import { useContext } from 'react'
import { isMobile } from 'react-device-detect'
import { Link, useNavigate } from 'react-router-dom'
import { logEvent } from 'utils'

export function ShortNavigation({ showNavName }: { showNavName: boolean }) {
  const user = useContext(UserContext)
  const org = useContext(OrgContext)
  const orgId = org?.id
  const navigate = useNavigate()
  const firstName = user?.profile?.firstName ?? null
  return (
    <Pane
      display="flex"
      alignItems="center"
      paddingX={24}
      paddingY={14}
      paddingRight={isMobile ? 16 : 40}
      position="fixed"
      top={0}
      right={0}
      left={0}
      width="100%"
      background="white"
    >
      <Link to={`https://${MARKETING_HOSTNAME}`}>
        <PerkUpName />
      </Link>
      <div className="mx-auto" />
      {showNavName && firstName ? (
        <Heading size={500} marginRight={16}>
          Hello, {firstName}
        </Heading>
      ) : (
        ''
      )}
      {user?.id && (
        <PerkIconTooltipButton
          title="Sign out"
          type="text"
          icon={<LogoutOutlined />}
          onClick={async () => {
            logEvent(EVENTS.SIGNED_OUT, { orgId, userId: user?.id })
            await signOut(getAuth())
            navigate(ROUTES.SIGN_IN)
            window.location.reload()
          }}
        />
      )}
    </Pane>
  )
}
