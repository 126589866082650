import { isProduction } from 'constants/keys'
import { getDownloadURL, StorageReference } from 'firebase/storage'

export const formatDownloadUrl = async (
  ref: StorageReference
): Promise<string> => {
  let url = await getDownloadURL(ref)
  if (!isProduction && url.startsWith('http://localhost:9199/')) {
    url = url.replace(
      'http://localhost:9199/',
      `${process.env.REACT_APP_NGROK_STORAGE_URL}/`
    )
  }
  return url
}
