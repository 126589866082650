import { ListBalanceTransactionsBySourceId } from 'api/databaseCalls'
import { BalanceTransaction } from 'gen/perkup/v1/transaction_pb'
import { useEffect, useState } from 'react'
import useIds from './useIds'

export function useBalanceTransactions({
  transactionId,
}: {
  transactionId?: string
}) {
  const { orgId, userId } = useIds()
  const [balanceTransactions, setBalanceTransactions] = useState<
    BalanceTransaction[]
  >([])
  const [isLoadingMore, setIsLoadingMore] = useState(false)
  const [isLoadingInitial, setIsLoadingInitial] = useState(true)

  useEffect(() => {
    if (!transactionId) {
      setIsLoadingInitial(false)
      return
    }
    setIsLoadingMore(true)
    ListBalanceTransactionsBySourceId({
      orgId,
      userId,
      sourceId: transactionId,
    })
      .then(setBalanceTransactions)
      .finally(() => {
        setIsLoadingMore(false)
        setIsLoadingInitial(false)
      })
  }, [orgId, userId, transactionId])

  return {
    balanceTransactions,
    isLoadingMore,
    isLoadingInitial,
    isLoading: isLoadingInitial || isLoadingMore,
  }
}
