import { ListOrgsByOrgIds, UpdateCurrentOrg } from 'api/databaseCalls'
import { OrgContext, UserContext } from 'context'
import { Menu, Popover, Position, toaster } from 'evergreen-ui'
import { Organization } from 'gen/perkup/v1/organization_pb'
import { useContext, useEffect, useState } from 'react'

function OrgSelect({ close, orgs }: { close: Function; orgs: Organization[] }) {
  const org = useContext(OrgContext)
  const user = useContext(UserContext)

  async function selectOrg(orgId: string, closePopover: Function) {
    if (org.id === orgId) {
      toaster.warning(`${org.name} is already selected.`)
    } else {
      await UpdateCurrentOrg({ orgId, userId: user.id })
      const selectedOrg = orgs?.filter(org => org.id === orgId)[0]
      toaster.success(`You're now managing ${selectedOrg?.name}.`)
      closePopover()
      window.location.reload()
    }
  }

  return (
    <Menu>
      <Menu.Group>
        <Menu.OptionsGroup
          options={orgs?.map(org => ({
            label: org.name,
            value: org.id,
          }))}
          selected={user.currentOrganization}
          onChange={selectedOrgId => selectOrg(selectedOrgId, close)}
        />
      </Menu.Group>
    </Menu>
  )
}

export function OrgDropdown({ children }: { children: JSX.Element }) {
  const user = useContext(UserContext)
  const org = useContext(OrgContext)
  const [orgs, setOrgs] = useState<Organization[]>([org])

  // List orgs by org IDs
  useEffect(() => {
    if (user?.organizations?.length) {
      ListOrgsByOrgIds({ orgIds: user.organizations }).then(setOrgs)
    }
  }, [org, user.organizations])

  return (
    <Popover
      position={Position.BOTTOM_LEFT}
      // eslint-disable-next-line react/no-unstable-nested-components
      content={({ close }) => <OrgSelect close={close} orgs={orgs} />}
    >
      {children}
    </Popover>
  )
}
