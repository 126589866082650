import { Image } from 'antd'
import PerkUpLogo from 'assets/perkup-logo.svg'
import { PageHeader } from 'components'
import { PDF_DATE_FORMAT } from 'constants/insights'
import { DEFAULT_INSIGHTS_PRINT_SECTIONS } from 'constants/print'
import { OrgContext, OrgStylesContext } from 'context'
import { Heading } from 'evergreen-ui'
import {
  AcceptanceRate,
  CoreValues,
  InsightsContextProvider,
  InsightsDownload,
  InsightsFilters,
  OrderDistribution,
  ProgramOccasionsChart,
  RewardsSent,
  SendersBreakdown,
  ThankYouNotes,
  TopRecipients,
  TopSenders,
  TopVariants,
  useInsightsContext,
} from 'features/insights'
import { useContext, useRef, useState } from 'react'
import { useLoaderData } from 'react-router'
import { getCubeToken } from 'services/cube'
import { InsightsPrintableSections } from 'types'
import { dateRangeToJoinedString } from 'utils/insights'

const loader = async () => {
  return getCubeToken()
}

function Error() {
  return <div>There was an error loading the insights page</div>
}

function InsightsDashboardHeader() {
  const { logoUrl, name: orgName } = useContext(OrgContext)

  const { dayJsDateRange } = useInsightsContext()

  return (
    <section>
      <div className="print:hidden">
        <PageHeader
          title="Insights"
          description="See how your team has been engaging with PerkUp."
          vlogLink="https://www.loom.com/embed/4563979f265d4055a6bb0df723a8cbbc?sid=1926afc0-06a3-4f4c-9671-99fc17769e9c"
        />
      </div>

      <div className="hidden print:flex print:justify-between print:items-start">
        {logoUrl ? (
          <Image
            src={logoUrl}
            preview={false}
            width={150}
            className="w-full h-full object-contain"
          />
        ) : (
          <Heading size={900}>{orgName}</Heading>
        )}

        <div className="flex flex-col items-end">
          <div className="flex items-start">
            <Image
              src={PerkUpLogo}
              preview={false}
              width={92}
              className="w-full h-full object-contain"
            />
            <Heading>Engagement Report</Heading>
          </div>
          {dayJsDateRange && (
            <Heading size={400}>
              {dateRangeToJoinedString(dayJsDateRange, PDF_DATE_FORMAT)}
            </Heading>
          )}
        </div>
      </div>
    </section>
  )
}

function InsightsDashboard() {
  const orgStyles = useContext(OrgStylesContext)

  const printRef = useRef<HTMLDivElement>(null)
  const cubeToken = useLoaderData() as string

  const [sectionsToPrint, setSectionsToPrint] =
    useState<InsightsPrintableSections>(DEFAULT_INSIGHTS_PRINT_SECTIONS)

  const {
    rewardsSentRow1,
    acceptanceRateRow2,
    sendersBreakdownRow2,
    programOccasionsRow3,
    coreValuesRow3,
    orderDistributionRow4,
    topSendersRow5,
    topRecipientsRow5,
    thankYouNotesRow6,
    topVariantsRow6,
  } = sectionsToPrint

  const row1 = rewardsSentRow1
  const row2 = acceptanceRateRow2 || sendersBreakdownRow2
  const row3 = programOccasionsRow3 || coreValuesRow3
  const row4 = orderDistributionRow4
  const row5 = topSendersRow5 || topRecipientsRow5
  const row6 = thankYouNotesRow6 || topVariantsRow6

  return (
    <InsightsContextProvider authToken={cubeToken}>
      <main
        style={orgStyles}
        ref={printRef}
        className="flex flex-col gap-6 max-w-screen-lg print:p-8 print:w-[1024px] w-full mx-auto"
      >
        <InsightsDashboardHeader />

        <section className="flex justify-between items-center print:hidden gap-4">
          <InsightsFilters />
          <InsightsDownload
            printRef={printRef}
            printableSections={sectionsToPrint}
            onPrintableSectionsChange={setSectionsToPrint}
          />
        </section>

        <section className="flex flex-col gap-2 w-full">
          {/** ROW 1 */}
          <div
            data-printable={row1}
            className="data-[printable=false]:print:hidden"
          >
            <RewardsSent />
          </div>

          {/** ROW 2 */}
          <div
            data-printable={row2}
            data-break-after={row1 && row2 && !row3} // Still don't let anything jump up if row 3 is not there
            className="grid grid-cols-3 gap-2 data-[printable=false]:print:hidden data-[break-after=true]:break-after-page"
          >
            <div
              data-printable={acceptanceRateRow2}
              className="col-span-2 rounded-lg border border-muted h-full p-4 lg:px-6 data-[printable=false]:print:hidden"
            >
              <AcceptanceRate />
            </div>

            <div
              data-printable={sendersBreakdownRow2}
              className="col-span-1 rounded-lg border border-muted h-full p-4 lg:px-6 data-[printable=false]:print:hidden"
            >
              <SendersBreakdown />
            </div>
          </div>

          {/** ROW 3 */}
          <div
            data-printable={row3}
            data-break-after={(row1 || row2) && row3} // Don't anything jump up if the first page is fully occupied
            className="grid grid-cols-3 gap-2 data-[printable=false]:print:hidden data-[break-after=true]:break-after-page"
          >
            <div
              data-printable={programOccasionsRow3}
              className="col-span-2 rounded-lg border border-muted h-full p-4 lg:px-6 data-[printable=false]:print:hidden "
            >
              <ProgramOccasionsChart />
            </div>

            <div
              data-printable={coreValuesRow3}
              className="col-span-1 rounded-lg border border-muted h-full p-4 lg:px-6 data-[printable=false]:print:hidden"
            >
              <CoreValues />
            </div>
          </div>

          {/** ROW 4 */}
          <div
            data-printable={row4}
            data-break-after={row1 && !row2 && !row3 && row4}
            className="rounded-lg border border-muted p-4 lg:px-6 data-[printable=false]:print:hidden data-[break-after=true]:break-after-page"
          >
            <OrderDistribution />
          </div>

          {/** ROW 5 */}
          <div
            data-printable={row5}
            data-break-after={row1 || row2 || row3 || row4} // Row 5 will almost always try and fit in if it can, so don't like row 6 follow it
            className="flex gap-2 h-[520px] data-[printable=false]:print:hidden data-[break-after=true]:break-after-page"
          >
            <div
              data-printable={topSendersRow5}
              className="flex-1 data-[printable=false]:print:hidden"
            >
              <TopSenders />
            </div>
            <div
              data-printable={topRecipientsRow5}
              className="flex-1 data-[printable=false]:print:hidden"
            >
              <TopRecipients />
            </div>
          </div>

          {/** ROW 6 */}
          <div
            data-printable={row6}
            data-break-before={row4}
            className="flex gap-2 h-[720px] data-[printable=false]:print:hidden data-[break-before=true]:break-before-page"
          >
            <div
              data-printable={thankYouNotesRow6}
              className="flex-1 data-[printable=false]:print:hidden"
            >
              <ThankYouNotes />
            </div>
            <div
              data-printable={topVariantsRow6}
              className="flex-1 data-[printable=false]:print:hidden"
            >
              <TopVariants />
            </div>
          </div>
        </section>
      </main>
    </InsightsContextProvider>
  )
}

export default {
  Component: InsightsDashboard,
  loader,
  ErrorBoundary: Error,
}
