import { OrgTemplateCard } from 'components'
import { Pane } from 'evergreen-ui'
import { Template } from 'gen/perkup/v1/org_template_pb'

export function OrgTemplateCardGrid({
  children,
  templates,
  onSelectTemplate,
}: {
  children?: React.ReactNode
  templates: Template[]
  onSelectTemplate: (template: Template) => void
}) {
  return (
    <Pane
      width="100%"
      display="grid"
      gridTemplateColumns="repeat(auto-fit, minmax(248px, 0.25fr))"
      justifyContent="center"
      gap={16}
    >
      {templates.map(template => (
        <OrgTemplateCard
          key={template.id}
          onClick={() => onSelectTemplate(template)}
          template={template}
        />
      ))}
      {children}
    </Pane>
  )
}
