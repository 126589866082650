import { Divider, Image } from 'antd'
import * as cards from 'assets/cards'
import { PerkImage, PerkLoader } from 'components'
import { NUMBER_GREEN } from 'constants/colors'
import { ExchangeRateContext, OrgContext, UserContext } from 'context'
import { Heading, Pane, Strong, Text } from 'evergreen-ui'
import { capitalize } from 'lodash-es'
import isEmpty from 'lodash-es/isEmpty'
import toUpper from 'lodash-es/toUpper'
import { useContext } from 'react'
import { numToDollars } from 'utils'

export function MoneyThumbnail({
  programTitle,
  budget,
  expiryDate,
  isFoodMoney,
  onClick,
}: {
  programTitle: string
  budget: number
  expiryDate: string | undefined
  isFoodMoney: boolean
  onClick?: () => void
}) {
  const exchangeRate = useContext(ExchangeRateContext)
  const user = useContext(UserContext)
  const org = useContext(OrgContext)
  const { primaryColor } = org

  const displayAmount = numToDollars(
    budget * exchangeRate,
    0,
    false,
    user?.displayCurrency || org?.displayCurrency
  )

  const cashRewardType = isFoodMoney ? 'TEAM LUNCH' : 'PERK CARD'
  const cardType = isFoodMoney ? cards.LunchCard : cards.OrangeCard

  return (
    <Pane
      display="flex"
      alignItems="center"
      gap={16}
      onClick={onClick}
      cursor="pointer"
    >
      <Image src={cardType} width={100} />

      <Pane display="flex" flexDirection="column" width="100%" gap={4}>
        <Strong fontWeight={800} color={primaryColor || 'default'}>
          {toUpper(cashRewardType)}
        </Strong>
        <Heading fontSize="clamp(14px, 2vw, 18px)" fontWeight={800}>
          {programTitle}
        </Heading>

        {expiryDate && (
          <Text size={300}>
            <b>Expiry:</b> {expiryDate}
          </Text>
        )}
      </Pane>

      <Strong color={NUMBER_GREEN} fontWeight={800}>
        {displayAmount}
      </Strong>
    </Pane>
  )
}

export function RewardGiftThumbnail({
  programTitle,
  imageUrl,
  productTitle,
  companyTitle,
  badge,
  onClick,
  showDivider = true,
  imageSize = 100,
  containerStyles,
  imageFit = 'cover',
}: {
  programTitle?: string
  imageUrl: string | undefined
  productTitle: string
  companyTitle?: string | undefined
  badge?: JSX.Element
  onClick?: () => void
  showDivider?: boolean
  imageSize?: number
  containerStyles?: React.CSSProperties
  imageFit?: 'contain' | 'cover' | 'fill' | 'none' | 'scale-down'
}) {
  const org = useContext(OrgContext)
  const { primaryColor } = org

  if (isEmpty(imageUrl)) return <PerkLoader />

  return (
    <>
      <Pane
        display="flex"
        alignItems="center"
        gap={16}
        onClick={onClick}
        cursor="pointer"
        style={containerStyles}
      >
        <PerkImage
          src={imageUrl}
          alt={`${programTitle}-thumbnail`}
          sizes="100px"
          style={{
            aspectRatio: imageFit === 'cover' ? '1 / 1' : undefined,
            objectFit: imageFit,
            width: imageSize,
            height: 'fit-content',
          }}
        />

        <Pane display="flex" flexDirection="column" gap={4}>
          <Strong size={400} fontWeight={800} color={primaryColor || 'default'}>
            {capitalize(programTitle)}
          </Strong>
          <Heading size={500} fontWeight={800}>
            {productTitle}
          </Heading>
          <Text size={400} color="muted">
            {capitalize(companyTitle)}
          </Text>
          {badge}
        </Pane>
      </Pane>
      {showDivider && <Divider style={{ marginTop: '16px' }} />}
    </>
  )
}
