import { RangeValue } from 'types/RewardsDate'

/**
 * Converts range value to YYYY-MM-DD format for tinybird
 */
export const convertDateRangeForTinybird = (dates: RangeValue | undefined) => {
  const startDate = dates?.[0]?.utc().format('YYYY-MM-DD')
  const endDate = dates?.[1]?.utc().format('YYYY-MM-DD')

  return [startDate, endDate]
}
