import { PlusOutlined } from '@ant-design/icons'
import { Button, Flex } from 'antd'
import { PageHeader, PerkLoader } from 'components'
import { DEFAULT_ROUTES } from 'constants/routes'

import { isEmpty } from 'lodash-es'
import { useNavigate } from 'react-router'
import { TemplateGrid } from './components/TemplateGrid'
import { useListenToOrgTemplates } from './hooks/useListenToOrgTemplates'

export function Templates() {
  const navigate = useNavigate()

  const { orgTemplates, hasLoaded } = useListenToOrgTemplates()
  if (!hasLoaded) return <PerkLoader />

  const hasTemplates = !isEmpty(orgTemplates)

  return (
    <Flex vertical gap={16} style={{ height: '100%' }}>
      <PageHeader
        title="Templates"
        description="Create and manage templates for your organization's rewards."
        vlogLink="https://www.loom.com/embed/5b74fe8e918e4796bca6827d779b01fb?sid=aeefa479-1a2d-40b0-b784-343c9695e129"
        buttonsToRenderOnLeft={
          hasTemplates ? (
            <Button
              icon={<PlusOutlined />}
              type="default"
              onClick={() =>
                navigate(DEFAULT_ROUTES.ORGANIZATION.REWARDS.TEMPLATES.NEW)
              }
            >
              Create template
            </Button>
          ) : undefined
        }
      />

      <TemplateGrid templates={orgTemplates} />
    </Flex>
  )
}
