import { Navbar } from '@nextui-org/react-LEGACY'
import { Flex } from 'antd'
import { CountrySelection } from 'components'
import { DEFAULT_ROUTES } from 'constants/routes'
import { UserContext } from 'context'
import React, { useContext } from 'react'
import { isMobile } from 'react-device-detect'
import { Link } from 'react-router-dom'
import { CompanyDisplay } from './CompanyDisplay'
import { UserDropdown } from './UserDropdown'

export function MemberNavigation({
  extraNodeOnRight,
}: {
  extraNodeOnRight?: React.ReactNode
}) {
  const user = useContext(UserContext)

  return (
    <nav
      style={{
        zIndex: 10,
        position: 'sticky',
        top: 0,
        padding: isMobile ? '16px' : '16px 32px',
        backdropFilter: 'blur(12px)',
        backgroundColor: 'rgba(255, 255, 255, 0.5)',
      }}
    >
      <Flex justify="space-between" align="center">
        <Link
          to={DEFAULT_ROUTES.CARD.ROOT}
          style={{ textDecoration: 'none', color: 'inherit' }}
        >
          <Navbar.Brand css={{ cursor: 'pointer' }}>
            <CompanyDisplay />
          </Navbar.Brand>
        </Link>

        <Flex gap={24} align="center">
          {extraNodeOnRight}
          <CountrySelection user={user} />
          <UserDropdown />
        </Flex>
      </Flex>
    </nav>
  )
}
