import dayjs, { Dayjs } from 'dayjs'
import { Individual } from 'gen/perkup/v1/individual_pb'
import { NotificationSetting_RuleGroup } from 'gen/perkup/v1/notification_pb'
import { CustomAction } from 'gen/perkup/v1/rules_pb'
import { useIndividualRole } from 'hooks'
import { IDateInfo } from 'pages/Automations/RewardCalendarFiltered'
import { RuleType, RuleTypes } from 'types/rules'
import {
  buildFullName,
  daysDiff,
  getUTCIndividualDate,
  ordinalSuffixOf,
} from 'utils'
import { getIndividualPrivacySettingsAllowRole } from 'utils/individual'

export function instanceOfCustomAction(object: any): object is CustomAction {
  return 'actionId' in object
}

export function createDaysUntilString(eventDate: Date) {
  const today = new Date()
  const hasDatePassed = eventDate < today
  const daysUntil = daysDiff(eventDate, hasDatePassed, false)

  if (daysUntil === 0) return 'today'

  const dayPluralOrSingular = daysUntil && daysUntil > 1 ? 'days' : 'day'

  const daysUntilString = hasDatePassed
    ? `${daysUntil} ${dayPluralOrSingular} ago`
    : `in ${daysUntil} ${dayPluralOrSingular}`

  return daysUntilString
}

interface ICalendarEvents {
  individual: Individual
  title: string
  date: Dayjs | undefined
  isBirthday: boolean
  yearsDiff: number
}

export function getEventInfo({
  individual,
  dateInfo,
  isBirthday,
}: {
  individual: Individual
  dateInfo: IDateInfo
  isBirthday: boolean
}) {
  const fieldTimestamp = isBirthday ? individual?.dob : individual?.startDate

  const individualDate = fieldTimestamp?.clone().toDate() // Create a clone, so that we don't mutate the original object

  // Determine tenure year - only get's rendered if !isBirthday
  const yearsDiff = dayjs(dateInfo.end).diff(dayjs(individualDate), 'years')
  const middleDate = dayjs(dateInfo.start).add(9, 'days')

  // Create the event date for the calendar
  const eventDate = individualDate
    ? dayjs(getUTCIndividualDate(individualDate)).set('year', middleDate.year())
    : undefined

  return { yearsDiff, eventDate }
}

export default function CreateCalendarEvents({
  individuals,
  ruleType,
  dateInfo,
}: {
  ruleType: RuleType
  individuals: Individual[]
  dateInfo: IDateInfo
}) {
  const individualRole = useIndividualRole()
  const isBirthday = ruleType === RuleTypes.birthdays
  const isAnniversary = ruleType === RuleTypes.anniversaries
  const isNewHire = ruleType === RuleTypes.newHires
  return individuals.map(individual => {
    if (ruleType === RuleTypes.birthdays) {
      if (
        !getIndividualPrivacySettingsAllowRole({
          individual,
          ruleGroup: NotificationSetting_RuleGroup.birthdays,
          role: individualRole.role,
        })
      ) {
        return null
      }
    }
    let emoji = ''
    if (isBirthday) {
      emoji = '🎂'
    } else if (isAnniversary) {
      emoji = '🎉'
    } else if (isNewHire) {
      emoji = '💼'
    }

    const { firstName, lastName } = individual
    const displayName =
      buildFullName({ firstName, lastName }) || individual.email

    const { yearsDiff, eventDate } = getEventInfo({
      individual,
      dateInfo,
      isBirthday,
    })
    let postLabel = ''
    if (isAnniversary) {
      postLabel = `, ${ordinalSuffixOf(yearsDiff)} year`
    }

    const title = `${emoji} ${displayName}${postLabel}`

    // Create birthday or anniversary event for current individual
    const calendarEvents: ICalendarEvents = {
      individual,
      title,
      date: eventDate,
      yearsDiff,
      isBirthday,
    }

    return calendarEvents
  })
}

export const getFirstDateAndLastDateOnCalendar = (date: Dayjs) => {
  const firstMonthDate = date.startOf('month')
  const lastMonthDate = date.endOf('month')

  const firstMonthDay = firstMonthDate.day()
  const firstPanelDate = firstMonthDate.subtract(firstMonthDay, 'days')
  const lastPanelDate = lastMonthDate.add(
    42 - Number(lastMonthDate.format('DD')) - firstMonthDay,
    'days'
  )

  return {
    start: firstPanelDate.toDate(),
    end: lastPanelDate.toDate(),
  }
}

export const getFirstAndLastDateOfYear = (date: Dayjs) => {
  const yearStart = date.startOf('year').toDate()
  const yearEnd = date.endOf('year').toDate()

  return {
    start: yearStart,
    end: yearEnd,
  }
}
