import { Account_Permission } from 'gen/perkup/v1/account_pb'
import { Individual_Role } from 'gen/perkup/v1/individual_pb'
import {
  ProductCollection,
  ProductCollection_Permission,
} from 'gen/perkup/v1/product_collections_pb'
import {
  Permission,
  PERMISSION_ADMIN,
  PERMISSION_MANAGER,
} from 'types/Permissions'

interface PermissionObject<P extends Permission = Permission> {
  /**
   * the hierarchy of permissions.
   *
   * the higher the index, the lower the permission.
   */
  levels: P[]
  /**
   * the permission that is considered as the default value.
   *
   * when comparing permissions, this will be used if `permissionBank` is empty.
   */
  getDefaultPermission: (
    permissions: Record<string, P>,
    role: Individual_Role
  ) => P
}

/**
 * Use this everywhere when calling getHighestPermission for accounts.
 */
export const ACCOUNT_LEVELS: PermissionObject<Account_Permission> = {
  levels: [
    Account_Permission.full,
    Account_Permission.send,
    Account_Permission.view,
  ],
  // if the permission is not specified, we default to PERMISSION_UNSPECIFIED
  // meaning that the individual has no permission.
  getDefaultPermission: () => Account_Permission.PERMISSION_UNSPECIFIED,
}

/**
 * Use this everywhere when calling getHighestPermission for product collections.
 */
export const PRODUCT_COLLECTION_LEVELS: PermissionObject<ProductCollection_Permission> =
  {
    levels: [
      ProductCollection_Permission.full,
      ProductCollection_Permission.send,
      ProductCollection_Permission.view,
    ],

    getDefaultPermission: permissions =>
      Object.keys(permissions).length === 0
        ? // if the permissions object is empty, we default to FULL
          // because this means that the product is not in any collection
          // so we default to the highest permission.
          ProductCollection_Permission.full
        : // if the permissions object is not empty, we default to PERMISSION_UNSPECIFIED
          // this means that the product is in a collection
          // but the individual has no associated permission to it.
          ProductCollection_Permission.PERMISSION_UNSPECIFIED,
  }

/**
 * Compare two permissions
 *
 * can be used to sort a permissions array in **descending order**
 * @returns - 0 if the permissions are equal, positive values if a is lower permission, negative values if b is lower permission.
 */
const permissionSorter =
  <P extends Permission>({ levels }: PermissionObject<P>) =>
  (a: P, b: P): number => {
    const aIndex = levels.indexOf(a)
    const bIndex = levels.indexOf(b)

    if (aIndex === -1 && bIndex === -1) return 0
    if (aIndex === -1) return 1
    if (bIndex === -1) return -1

    // the higher the index, the lower the permission
    if (aIndex < bIndex) return -1
    if (aIndex > bIndex) return 1

    return 0
  }

const permissionCompare =
  <P extends Permission>(permissionsObject: PermissionObject<P>) =>
  (a: P, operator: '>' | '<' | '>=' | '<=' | '=', b: P) => {
    switch (operator) {
      case '>':
        return permissionSorter(permissionsObject)(b, a) > 0
      case '<':
        return permissionSorter(permissionsObject)(b, a) < 0
      case '>=':
        return permissionSorter(permissionsObject)(b, a) >= 0
      case '<=':
        return permissionSorter(permissionsObject)(b, a) <= 0
      case '=':
        return permissionSorter(permissionsObject)(b, a) === 0
      default:
        return false
    }
  }

/**
 *
 * @param levels The order / hierarchy in which the permissions are checked.
 * @returns A function that takes in the role, individualId, and permissions. That function then returns the highest permission based on the hierarchy of levels passed in.
 */
export function getHighestPermission<P extends Permission>(
  permissionsObject: PermissionObject<P>
) {
  return ({
    role,
    individualId,
    permissions,
  }: {
    role: Individual_Role
    individualId: string
    permissions: Record<string, P>
  }): P => {
    const adminPermission =
      (role === Individual_Role.admin && permissions[PERMISSION_ADMIN]) || null

    const managerPermission =
      (role === Individual_Role.manager && permissions[PERMISSION_MANAGER]) ||
      null

    const individualPermission = permissions[individualId] || null

    const permissionBank: P[] = []
    if (adminPermission !== null) permissionBank.push(adminPermission)
    if (managerPermission !== null) permissionBank.push(managerPermission)
    if (individualPermission !== null) permissionBank.push(individualPermission)

    permissionBank.sort(permissionSorter(permissionsObject))

    return (
      permissionBank[0] ??
      permissionsObject.getDefaultPermission(permissions, role)
    )
  }
}

/**
 * Get the highest permission from an array of objects that each contain a set of permissions.
 * This function compares permissions across multiple objects and returns the highest
 * permission for each key, excluding any permissions with a value of `0`.
 *
 *
 * @param arr - An array of objects,
 * where each object contains an `from` and a `permissions` object. The `permissions` object is
 * a record of permission keys and their corresponding values of type `T`.
 *
 * @returns - A record where the keys are
 * permission names, and the values are objects containing the `from` (the object
 * where the highest permission was found) and the `permission` itself.
 */
export const getHighestPermissionByKey =
  <T extends Permission>(permissionsObject: PermissionObject<T>) =>
  <F>(
    arr: {
      from: F
      permissions: Record<string, T>
    }[]
  ) => {
    type PermissionsObject = Record<
      string,
      {
        from: F
        permission: T
      }
    >
    const permissions = arr.reduce<PermissionsObject>((acc, curr) => {
      // iterate through all permissions
      // from each array element
      Object.entries(curr.permissions).forEach(([key, value]) => {
        // if the permission is higher than the current permission in the accumulator
        // then replace it
        if (
          !acc[key] ||
          permissionCompare(permissionsObject)(value, '>', acc[key].permission)
        ) {
          // make sure we don't add 0 permissions
          // since 0 is the default value from the proto enum
          if (value !== 0) acc[key] = { from: curr.from, permission: value }
        }
      })
      return acc
    }, {})

    return permissions
  }

export const formatCollectionPermission = (
  permission: ProductCollection_Permission
) => {
  switch (permission) {
    case ProductCollection_Permission.full:
      return 'Full access'
    case ProductCollection_Permission.send:
      return 'Can send'
    case ProductCollection_Permission.view:
      return 'Can view'
    default:
      return ''
  }
}

/**
 * Checks if permission a is greater than or equal to permission b.
 */
export const permissionSatisfies =
  <P extends Permission>(permissionsObject: PermissionObject<P>) =>
  (a: P, b: P) =>
    permissionCompare(permissionsObject)(a, '>=', b)

export function getIndividualProductPermission({
  productId,
  productCollections,
  role,
  individualId,
}: {
  productId: string | undefined
  productCollections: Pick<ProductCollection, 'products' | 'permissions'>[]
  role: Individual_Role
  individualId: string
}): {
  permission: ProductCollection_Permission
  canEdit: boolean
  canSend: boolean
} {
  const getPermission = () => {
    if (!productId) return ProductCollection_Permission.PERMISSION_UNSPECIFIED

    const allProductPermissions = getHighestPermissionByKey(
      PRODUCT_COLLECTION_LEVELS
    )(
      productCollections
        .filter(pc => pc.products[productId])
        .map(collection => ({
          from: collection,
          permissions: collection.permissions,
        }))
    )

    const highestPermission = getHighestPermission(PRODUCT_COLLECTION_LEVELS)({
      role,
      individualId,
      permissions: Object.fromEntries(
        Object.entries(allProductPermissions).map(([id, permission]) => [
          id,
          permission.permission,
        ])
      ),
    })

    return highestPermission
  }

  const permission = getPermission()

  return {
    permission,
    canEdit: permissionSatisfies(PRODUCT_COLLECTION_LEVELS)(
      permission,
      ProductCollection_Permission.full
    ),
    canSend: permissionSatisfies(PRODUCT_COLLECTION_LEVELS)(
      permission,
      ProductCollection_Permission.send
    ),
  }
}
