import { Select } from 'antd'
import { GetIndividualByEmail } from 'api/databaseCalls'
import { OrgContext } from 'context'
import { Pane, Text, toaster } from 'evergreen-ui'
import { useContext, useState } from 'react'
import { isEmail } from 'utils'

export function InviteEmail({
  emails,
  setEmails,
}: {
  emails: string[]
  setEmails: (emails: string[]) => void
}) {
  const [showHelpText, setShowHelpText] = useState(false)
  const org = useContext(OrgContext)
  const orgId = org.id

  const onAddHandler = async (newEmail: string) => {
    const emailCheck = isEmail(newEmail.trim())

    if (!emailCheck) {
      toaster.warning('Oops, that looks like an invalid email. Try again.')
      return
    }

    const existingIndividual = await GetIndividualByEmail({
      email: newEmail,
      orgId,
    })

    if (existingIndividual?.id) {
      toaster.warning('This email is already exists in your people directory.')
      return
    }
    if (emails?.includes(newEmail)) {
      toaster.warning('Email already entered.')
      return
    }

    setEmails([...emails, newEmail])
  }

  const onRemoveHandler = (value: string) => {
    const filteredEmails = emails.filter(email => value !== email)
    setEmails(filteredEmails)
  }
  return (
    <Pane display="flex" flexDirection="column">
      <Select
        mode="tags"
        placeholder="Email..."
        value={emails}
        onSelect={onAddHandler}
        onDeselect={onRemoveHandler}
        style={{ width: '100%', maxWidth: 320 }}
        onFocus={() => setShowHelpText(true)}
      />
      {showHelpText && (
        <Text size={300} color="muted" marginTop={8}>
          Press enter to add another email address
        </Text>
      )}
    </Pane>
  )
}
