import { ListProductVariantsByIds } from 'api/databaseCalls'
import { NUMBER_RED } from 'constants/colors'
import { countryIso2To3 } from 'constants/countries'
import { isProduction } from 'constants/keys'
import { IN_STOCK, OUT_OF_STOCK } from 'constants/productVariants'
import { PRODUCT_VARIANT_IDS } from 'constants/sessionOrLocalStorage'
import { CanvasDesign } from 'gen/canvas/designs_pb'
import { Cart } from 'gen/perkup/v1/cart_pb'
import {
  ProductVariant,
  ProductVariant_Image,
  ProductVariant_InventoryPolicy,
  ProductVariant_Provider,
  ProductVariant_SourceType,
  ProductVariant_Type,
} from 'gen/perkup/v1/product_variant_pb'
import { Item } from 'gen/perkup/v1/program_pb'
import { ShippingAddress } from 'gen/perkup/v1/root_user_pb'
import { OrderFulfillment } from 'gen/perkup/v1/vendor_pb'
import { CalculatedDraftOrder } from 'gen/shopifyapi/admingql_pb'
import {
  compact,
  intersectionWith,
  isEmpty,
  isUndefined,
  keys,
  uniqBy,
  values,
} from 'lodash-es'
import {
  MaybeWithDesign,
  RankedVariantOptions,
  Selectable,
  SelectedVariantOption,
  WithSelectedQuantity,
} from 'types'
import { convertShopifyPricetoAmount } from 'utils'
import { objectToMessage } from './firestore'
import { afterLastSlash } from './uiUtils'

export function getSelectedProductVariant({
  productVariants,
  selectedVariantValues,
}: {
  productVariants: ProductVariant[]
  selectedVariantValues: SelectedVariantOption | undefined
}) {
  const productVariant = productVariants.length ? productVariants[0] : undefined
  if (!productVariant) return undefined
  const productOptions = Object.keys(productVariant.options || {})
  const filteredProduct = productVariants.filter(productVariant => {
    let isMatch = true
    productOptions.forEach(optionName => {
      if (
        productVariant.options[optionName]?.values.length === 1 &&
        productOptions.length === 1
      ) {
        isMatch = true
        return
      }
      if (
        productVariant.options[optionName]?.value !==
        selectedVariantValues?.[optionName]
      ) {
        isMatch = false
      }
    })
    return isMatch
  })

  if (filteredProduct.length > 0) {
    return filteredProduct[0]
  }
  return undefined
}

export function algoliaResultsToProductVariants(results: {
  hits: any[]
}): ProductVariant[] {
  return results.hits.map(hit => {
    const parseHit = {
      ...hit,
      id: hit.id || hit.objectID,
      createdAt: hit.createdAt && new Date(hit.createdAt),
      updatedAt: hit.updatedAt && new Date(hit.updatedAt),
      publishedAt: hit.publishedAt && new Date(hit.publishedAt),
    }
    return objectToMessage(ProductVariant, parseHit)
  })
}

export function algoliaItemToProductVariant(item: any) {
  const parseHit = {
    ...item,
    id: item.id || item.objectID,
    createdAt: item.createdAt && new Date(item.createdAt),
    updatedAt: item.updatedAt && new Date(item.updatedAt),
    publishedAt: item.publishedAt && new Date(item.publishedAt),
  }
  return objectToMessage(ProductVariant, parseHit)
}

export function getProductVariantProvider({
  productVariant,
}: {
  productVariant: ProductVariant
}) {
  const defaultToShopifyProvider =
    !isProduction &&
    productVariant.provider === ProductVariant_Provider.PROVIDER_UNSPECIFIED

  const provider = defaultToShopifyProvider
    ? ProductVariant_Provider.shopify
    : productVariant.provider

  return provider
}

export const getProductVariantOptions = ({
  productVariant,
}: {
  productVariant?: ProductVariant
}) => {
  if (!productVariant) return undefined
  const options: Record<string, string> = {}
  const optionKeys = keys(productVariant?.options || {})
  if (isEmpty(optionKeys)) return undefined
  optionKeys.forEach(key => {
    const value = productVariant?.options[key].value
    if (!value) return
    options[key] = value
  })
  return options
}

export const saveProductVariantsToSessionStorage = ({
  productVariants,
  programId,
}: {
  productVariants: ProductVariant[]
  programId?: string
}) => {
  if (!programId) return
  const productVariantIds = productVariants.map(
    productVariant => productVariant.id
  )
  sessionStorage.setItem(
    `${programId}_${PRODUCT_VARIANT_IDS}`,
    JSON.stringify(productVariantIds)
  )
}

export const getProductVariantsFromSessionStorage = ({
  programId,
}: {
  programId?: string
}) => {
  if (!programId) return []
  const storedVariants = sessionStorage.getItem(
    `${programId}_${PRODUCT_VARIANT_IDS}`
  )
  const parsedProductVariantIds: string[] = storedVariants
    ? JSON.parse(storedVariants)
    : []
  return parsedProductVariantIds
}

export const removeProductVariantsFromSessionStorage = ({
  programId,
}: {
  programId?: string
}) => {
  if (!programId) return
  sessionStorage.removeItem(`${programId}_${PRODUCT_VARIANT_IDS}`)
}

export const getOutOfStockProductVariants = async ({
  items,
}: {
  items: Item[]
}) => {
  const ids = items.map(item => afterLastSlash(item.productVariantId))
  const productVariants = await ListProductVariantsByIds({ ids })

  if (!productVariants) return []

  const variantsOutOfStock = intersectionWith(
    productVariants,
    items,
    (pv, item) => {
      if (pv.id !== afterLastSlash(item.productVariantId)) return false
      return !pv.isAvailable
    }
  )

  return variantsOutOfStock
}

export const getProductVariantIdsFromOrderFulfillments = ({
  orderFulfillments,
}: {
  orderFulfillments?: OrderFulfillment[]
}) => {
  if (!orderFulfillments) return []
  const productVariantIds: string[] = []
  orderFulfillments.forEach(orderFulfillment => {
    orderFulfillment.lineItems.forEach(lineItem => {
      productVariantIds.push(String(lineItem.variantId))
    })
  })
  return productVariantIds
}

export const shouldSkipPvInventoryChecks = (pv: ProductVariant) => {
  if (pv?.type === ProductVariant_Type.publicGift) return true // If it's a public gift, and it already passed the isAvailable check, then we don't want to show inventory quantity
  if (pv?.inventoryPolicy === ProductVariant_InventoryPolicy.continue)
    return true
  if (!pv?.inventoryTracked) return true
  return false
}

export const determineInventoryStatus = ({
  productVariants,
  displayInStockThreshold,
  level,
}: {
  productVariants: ProductVariant[]
  displayInStockThreshold: number
  level: 'product' | 'variant'
}) => {
  const genericPv = productVariants[0]
  const isProductLevel = level === 'product'
  const isAvailable = isProductLevel
    ? genericPv?.productIsAvailable
    : genericPv?.isAvailable

  // This should catch if the product or variant is not available
  if (!isAvailable)
    return {
      text: OUT_OF_STOCK,
      color: NUMBER_RED,
    }

  if (shouldSkipPvInventoryChecks(genericPv))
    return {
      text: IN_STOCK,
      color: undefined,
    }

  if (isProductLevel) {
    let totalInventory = 0
    productVariants.forEach(pv => {
      if (!pv.inventoryQuantity) return
      if (pv.inventoryQuantity < 0) return
      totalInventory += Number(pv.inventoryQuantity)
    })

    if (totalInventory >= displayInStockThreshold)
      return {
        text: IN_STOCK,
        color: undefined,
      }

    // Just in case the backend didn't update isAvailable
    if (totalInventory <= 0) {
      return {
        text: OUT_OF_STOCK,
        color: NUMBER_RED,
      }
    }

    return {
      text: `${totalInventory} available`,
      color: totalInventory < 10 ? NUMBER_RED : undefined,
    }
  }

  // If the inventory quantity is 0, we want to show that it's out of stock
  if (!isUndefined(genericPv?.inventoryQuantity)) {
    if (genericPv.inventoryQuantity >= displayInStockThreshold) {
      return {
        text: IN_STOCK,
        color: undefined,
      }
    }

    // Just in case the backend didn't update isAvailable
    if (genericPv.inventoryQuantity <= 0) {
      return {
        text: OUT_OF_STOCK,
        color: NUMBER_RED,
      }
    }

    return {
      text: `${genericPv.inventoryQuantity} available`,
      color: genericPv.inventoryQuantity < 10 ? NUMBER_RED : undefined,
    }
  }

  return {
    text: '',
    color: undefined,
  }
}

export const getProductVariantsSubtotal = ({
  productVariants,
  draftOrderCalculation,
}: {
  productVariants: ProductVariant[]
  draftOrderCalculation?: CalculatedDraftOrder
}) => {
  if (draftOrderCalculation) {
    return convertShopifyPricetoAmount(draftOrderCalculation?.subtotalPrice)
  }
  return productVariants.reduce((acc, pv) => {
    if (!pv.amount) return acc
    return acc + Number(pv.amount)
  }, 0)
}

export const invalidShippingCountryVariants = ({
  shippingAddress,
  productVariants,
}: {
  shippingAddress?: ShippingAddress
  productVariants: ProductVariant[]
}) => {
  if (!shippingAddress) return []
  const shippingAddressIso3 =
    countryIso2To3[shippingAddress?.country?.toLowerCase()]

  return productVariants.filter(pv => {
    if (isEmpty(pv.shippingCountries)) return false // If no shipping countries are set, assume it's available everywhere

    return !pv.shippingCountries.includes(shippingAddressIso3)
  })
}

export const getAvailableVariantOptions = ({
  productVariants,
}: {
  productVariants: ProductVariant[]
}) => {
  const allAvailableOptions: RankedVariantOptions = {}

  // Loop through all the filtered variants and find all the available options

  productVariants.forEach(variant => {
    keys(variant.options)
      .sort()
      .forEach(option => {
        const optionValue = variant.options[option].value
        if (!allAvailableOptions[option]) {
          allAvailableOptions[option] = {
            values: [optionValue],
            rank: keys(allAvailableOptions).length,
          }
          return
        }
        if (!allAvailableOptions[option]?.values.includes(optionValue)) {
          allAvailableOptions[option].values.push(optionValue)
        }
      })
  })
  return allAvailableOptions
}

export const extractProductCategoryName = (
  categories: ProductVariant['categories'],
  desiredLevel: 0 | 1 | 2 | 3 | 4 | 5
) => {
  const allCategories = categories?.paths[0]?.split('>')?.map(cat => cat.trim()) // Split the categories into a clean array of strings since they are separated by '>'
  const desiredCategoryName = allCategories?.[desiredLevel] // Get the category name at the desired level
  return desiredCategoryName
}

export const publicGiftIsNotAvailable = (productVariant: ProductVariant) => {
  return (
    productVariant.type === ProductVariant_Type.publicGift &&
    !productVariant.productIsAvailable
  )
}

export const getUniqAndFormatWithSelectable = (
  productVariants: ProductVariant[]
): Selectable<ProductVariant[]> => {
  return uniqBy(productVariants, 'productId').map(variant => {
    Object.assign(variant, { isSelected: true })
    return variant
  })
}

export const isNearCashProductVariant = (productVariant: ProductVariant) => {
  return productVariant.type === ProductVariant_Type.nearCash
}

export const isPublicGiftProductVariant = (productVariant: ProductVariant) => {
  return productVariant.type === ProductVariant_Type.publicGift
}

export const isPrepaidProductVariant = (productVariant: ProductVariant) => {
  return productVariant.sourceType === ProductVariant_SourceType.fullPrepaid
}

export const buildProductVariantDisplayName = ({
  productVariant,
}: {
  productVariant: ProductVariant
}) => {
  const options = getProductVariantOptions({ productVariant })
  if (isUndefined(options)) return productVariant.productName
  if (isNearCashProductVariant(productVariant))
    return productVariant.productName
  return `${productVariant.productName} - ${productVariant.variantName}`
}

export const getProductVariantOutOfStockMessage = ({
  variants,
}: {
  variants: ProductVariant[]
}) => {
  let errorMessage = ''
  variants.forEach((variant, index) => {
    const productName = buildProductVariantDisplayName({
      productVariant: variant,
    })
    const availableQuantity =
      variant?.inventoryQuantity && variant.inventoryQuantity < 0
        ? 0
        : variant.inventoryQuantity
    errorMessage += `${
      index + 1
    }. ${productName} - ${availableQuantity} units remaining <br />`
  })
  errorMessage += 'Please select something else.'
  return errorMessage
}

export const getProductVariantPriceRange = (
  productVariants: ProductVariant[]
) => {
  const prices = compact(productVariants.map(pv => Number(pv.amount)))
  const minPrice = Math.min(...prices)
  const maxPrice = Math.max(...prices)
  return { minPrice, maxPrice }
}

/**
 * For now we're going to use pv.productImages[0] as the default image as it's reliable
 * In the future we can easily change this to prefer pv.productImageUrl or pv.imageUrl
 */
export const getProductVariantProductImage = (pv: ProductVariant) => {
  return pv.productImages[0]?.src || pv?.productImageUrl || pv?.imageUrl
}

/**
 *
 * @param options a product variant's set of options
 * @returns the keys separated by commas, but the last key is separated by 'and'
 * @return empty string if no options
 */
export const constructUnifiedOptionsText = (productVariant: ProductVariant) => {
  const options = getProductVariantOptions({
    productVariant,
  })

  const optionKeys = Object.keys(options ?? [])

  const formatter = new Intl.ListFormat('en', {
    style: 'long',
    type: 'conjunction',
  })

  return formatter.format(optionKeys) // eg: sizes, colors, and materials
}

export function isCustomizable(productVariant: ProductVariant) {
  return productVariant.tags.includes('customizable')
}

export function buildFakeCustomizedProductVariant(
  productVariant: ProductVariant,
  productImageUrls: string[],
  canvasDesign?: CanvasDesign
): MaybeWithDesign<ProductVariant> {
  const newProductVariant = new ProductVariant({
    ...productVariant,
    imageUrl: productImageUrls[0],
    productImageUrl: productImageUrls[0],
    productImages: productImageUrls.map(
      (url, index) =>
        new ProductVariant_Image({
          src: url,
          id: url,
          position: BigInt(index),
        })
    ),
  })

  return Object.assign(newProductVariant, {
    canvasDesign,
  })
}

export function buildProductVariantsFromCanvasDesigns({
  productVariants,
  canvasDesigns,
}: {
  productVariants: ProductVariant[]
  canvasDesigns: CanvasDesign[]
}): MaybeWithDesign<ProductVariant>[] {
  return productVariants.map(pv => {
    const canvasDesignForPv = canvasDesigns.find(
      cd => cd.shopifyProductId === pv.productId
    )

    if (!canvasDesignForPv) return pv

    const printItems = canvasDesignForPv.printMetadata?.printItems
    const mockupUrls = compact(printItems?.map(pi => pi.printPreviewUrl))

    if (mockupUrls.length === 0) return pv

    return buildFakeCustomizedProductVariant(pv, mockupUrls, canvasDesignForPv)
  })
}

export function buildProductVariantFromCanvasDesign({
  productVariant,
  canvasDesign,
}: {
  productVariant: ProductVariant
  canvasDesign?: CanvasDesign
}): MaybeWithDesign<ProductVariant> {
  if (!canvasDesign) return productVariant
  const printItems = canvasDesign?.printMetadata?.printItems
  const mockupUrls = compact(printItems?.map(pi => pi.printPreviewUrl))

  if (mockupUrls.length === 0) return productVariant

  return buildFakeCustomizedProductVariant(
    productVariant,
    mockupUrls,
    canvasDesign
  )
}

/**
 * Allows us to determine which product options have already been selected from the canvas design. Then we can tell our ProductOptions component to exclude those options from the list of available options.
 */
export const determineProductOptionsAlreadySelectedFromCanvasDesign = ({
  productVariant,
  canvasDesign,
}: {
  productVariant: ProductVariant
  canvasDesign?: CanvasDesign
}) => {
  if (!canvasDesign) return []

  const canvasDesignOptionsSelected = canvasDesign.options.map(
    option => option.name
  )
  const pvOptionKeys = Object.keys(productVariant.options)

  // Return lowercase intersection of the two arrays

  return intersectionWith(pvOptionKeys, canvasDesignOptionsSelected, (a, b) => {
    return a.toLowerCase() === b.toLowerCase()
  })
}

/**
 *
 * Returns key value pairs of the variant options that are already selected from the canvas design
 */
export const getVariantOptionValuesFromCanvasDesign = ({
  productVariant,
  canvasDesign,
}: {
  productVariant: ProductVariant
  canvasDesign?: CanvasDesign
}): Record<string, string> => {
  if (!canvasDesign) return {}
  const optionsAlreadySelected =
    determineProductOptionsAlreadySelectedFromCanvasDesign({
      productVariant,
      canvasDesign,
    })

  const options: Record<string, string> = {}

  optionsAlreadySelected.forEach(option => {
    const matchingOption = canvasDesign.options.find(
      co => co.name.toLowerCase() === option.toLowerCase()
    )
    if (matchingOption) {
      const matchingOptionValue = matchingOption.values[0]
      options[option] = matchingOptionValue
    }
  })

  return options
}

export const buildProductVariantsFromCart = (
  productVariants: ProductVariant[],
  cart: Cart
): MaybeWithDesign<WithSelectedQuantity<ProductVariant>>[] => {
  return compact(
    cart.lineItems.map(cartItem => {
      const productVariant = productVariants.find(
        pv => pv.id === cartItem.productVariantId
      )
      if (!productVariant) return undefined

      if (cartItem.canvasDesign) {
        // Its important that we build a completely new product variant with the canvas design, not only for the images, but also so that the references are not the same as the original product variant
        const fakeProductVariant = buildProductVariantFromCanvasDesign({
          productVariant,
          canvasDesign: cartItem.canvasDesign,
        })
        return Object.assign(fakeProductVariant, {
          selectedQuantity: cartItem.quantity || 1,
        })
      }

      return Object.assign(productVariant, {
        selectedQuantity: cartItem.quantity || 1,
      })
    })
  )
}

export const filterProductVariantsByWarehouseId = ({
  warehouseId,
  productVariants,
}: {
  warehouseId: string
  productVariants: MaybeWithDesign<WithSelectedQuantity<ProductVariant>>[]
}) => {
  return productVariants.filter(pv => {
    if (!isPrepaidProductVariant(pv)) return true

    const invLocationIds = compact(
      values(pv.inventoryLevels).map(invLevel => invLevel?.location?.id)
    )

    return invLocationIds.includes(warehouseId)
  })
}

export const sumProductVariants = (
  productVariants: WithSelectedQuantity<ProductVariant>[]
) => {
  return productVariants.reduce(
    (acc, { amount, selectedQuantity }) =>
      acc + Number(amount) * selectedQuantity,
    0
  )
}
