export enum EligibleCategories {
  AMAZON = 'Amazon',
  CELL_PHONE = 'Cell Phone',
  CHILD_CARE = 'Child Care',
  EDUCATION = 'Education',
  FITNESS = 'Fitness',
  FOOD = 'Food',
  HOME_OFFICE = 'Home Office',
  INTERNET = 'Internet',
  LIFESTYLE = 'Lifestyle',
  MINDFULNESS = 'Mindfulness',
  NEWS = 'News',
  SWAG = 'Swag',
  ALL_MERCHANTS = 'allMerchants',
}

export enum ApprovedCategories {
  AMAZON = 'Amazon',
  CELL_PHONE = 'Cell Phone',
  CHILD_CARE = 'Child Care',
  EDUCATION = 'Education',
  FITNESS = 'Fitness',
  FOOD = 'Food',
  HOME_OFFICE = 'Home Office',
  INTERNET = 'Internet',
  LIFESTYLE = 'Lifestyle',
  MINDFULNESS = 'Mindfulness',
  NEWS = 'News',
  SWAG = 'Swag',
}

export const allApprovedCats = Object.values(ApprovedCategories)

export const allCategories = 'All categories'
