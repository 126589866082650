import { Tag } from 'antd'
import { ReactComponent as Logo } from 'assets/PerkUp-LogoIcon.svg'
import { SECTION_HEADING_SIZE } from 'constants/newReward/layout'
import { Heading, Image, Pane, Paragraph, useTheme } from 'evergreen-ui'
import { NotificationOccassions } from 'types/Notifications'

export interface ISlackPublicNotificationPreview {
  channel: string | undefined
  time: Date
  cadence: string
  message: string | undefined
  occassion: NotificationOccassions
  image: string | undefined
  separateNotifications: boolean
}

export function GetOccassionIcon(occassion: NotificationOccassions) {
  let emoji: string = ''
  switch (occassion) {
    case NotificationOccassions.BIRTHDAY:
      emoji = '🎂'
      break
    case NotificationOccassions.ANNIVERSARY:
      emoji = '🎉'
      break
    default:
      break
  }
  return <Pane>{emoji}</Pane>
}

export function SlackPublicNotificationPreview({
  channel,
  time,
  cadence,
  message,
  occassion,
  image,
  separateNotifications,
}: ISlackPublicNotificationPreview) {
  const theme = useTheme()
  const channelMsg = channel ? `#${channel}` : ''
  return (
    <Pane borderLeft="muted" paddingLeft={12}>
      <Heading size={SECTION_HEADING_SIZE}>Preview</Heading>
      <Paragraph size={400} color={theme.colors.muted}>{`Sends ${cadence} ${
        channel ? `in #${channel}` : ''
      }`}</Paragraph>
      <Pane marginTop={15} gridTemplateColumns="1fr 8fr" gap={4} display="grid">
        <Pane />

        <Pane>
          <Heading size={100} color={theme.colors.muted}>
            {channelMsg}
          </Heading>
        </Pane>

        <Pane display="flex">
          <Logo height={28} />
        </Pane>
        <Pane display="flex">
          <Heading size={500}>PerkUp</Heading>
          <Tag
            color="default"
            style={{
              marginLeft: 8,
              marginTop: 4,
            }}
          >
            APP
          </Tag>
        </Pane>
        <Pane />
        <Paragraph size={400}>{`${message}`}</Paragraph>
        <Pane />
        <Pane>
          <Paragraph
            size={400}
            fontWeight={600}
            marginBottom={8}
          >{`${time.toLocaleDateString('en-us', {
            month: 'long',
            weekday: 'long',
            day: 'numeric',
            year: 'numeric',
          })}`}</Paragraph>
          <Pane display="flex" alignItems="center" marginBottom={4} gap={2}>
            {GetOccassionIcon(occassion)}
            <Tag color="blue" style={{ height: '100%' }}>
              @Mrs. Potato Head
            </Tag>
          </Pane>
          {!separateNotifications && (
            <Pane display="flex" alignItems="center" gap={2}>
              {GetOccassionIcon(occassion)}
              <Tag color="blue" style={{ height: '100%' }}>
                @Mr. Lightyear
              </Tag>
            </Pane>
          )}
        </Pane>
        <Pane />
        <Image src={image} width={130} marginTop={16} borderRadius={8} />
      </Pane>
    </Pane>
  )
}
