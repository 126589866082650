import { Flex, Tag } from 'antd'
import { Link, Text } from 'evergreen-ui'
import { Individual, Individual_Role } from 'gen/perkup/v1/individual_pb'
import { capitalize } from 'lodash-es'
import { getMemberBadgeColor } from 'utils'
import { getIndividualDisplayName } from 'utils/individual'
import { UserAvatar } from './UserAvatar'

export function IndividualAvatar({
  individual,
  showIsYouBadge = false,
  // hideRoleBadge because default is to show the role badge
  hideRoleBadge = false,
}: {
  individual: Individual
  showIsYouBadge?: boolean
  hideRoleBadge?: boolean
}) {
  const role = individual?.role
  const name = getIndividualDisplayName(individual)
  const { email } = individual
  const profilePicture = individual?.profilePicture
  const countryId = individual?.countryId

  return (
    <Flex gap={16}>
      <UserAvatar
        countryId={countryId}
        profilePicture={profilePicture}
        name={name}
        email={email}
        flagSize={18}
      />

      <Flex vertical flex={1} gap={4}>
        <Flex justify="space-between" gap={16}>
          <Text minWidth="max-content" is={Link}>
            {name}
          </Text>

          <Flex>
            {showIsYouBadge && <Tag color="cyan">You</Tag>}
            {role !== undefined && !hideRoleBadge && (
              <Tag color={getMemberBadgeColor(role)}>
                {capitalize(Individual_Role[role])}
              </Tag>
            )}
          </Flex>
        </Flex>
        <Text color="muted">{email}</Text>
      </Flex>
    </Flex>
  )
}
