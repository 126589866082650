import { isProduction } from './keys'

const SHOPIFY_TEST_STOREFRONT_ID = 1466
const SHOPIFY_PROD_STOREFRONT_ID = 1470

export const AURIGMA_STOREFRONT_ID = isProduction
  ? SHOPIFY_PROD_STOREFRONT_ID
  : SHOPIFY_TEST_STOREFRONT_ID

export const AURIGMA_TENANT_ID = 1423

export const AURIGMA_BASE_URL = 'https://api.customerscanvashub.com'
