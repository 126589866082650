import { ALGOLIA_PRODUCT_VARIANTS_INDEX } from 'constants/algolia'
import React, { useEffect, useRef } from 'react'
import { useInstantSearch } from 'react-instantsearch'

type TUiState = ReturnType<typeof useInstantSearch>['uiState']

/**
 * Helper function to scroll back up to the top of the algolia search results when the user clicks a filter or types in the search bar
 * https://github.com/algolia/instantsearch/blob/master/examples/react/e-commerce/components/ScrollTo.tsx
 */
export function ScrollTo({ children }: { children: React.ReactNode }) {
  const { addMiddlewares } = useInstantSearch()
  const containerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const middleware = () => {
      return {
        onStateChange({ uiState }: { uiState: TUiState }) {
          const isFiltering = document.body.classList.contains('filtering')

          const isTyping =
            document.activeElement?.tagName === 'INPUT' &&
            document.activeElement?.getAttribute('type') === 'search'

          const pvUiState = uiState[ALGOLIA_PRODUCT_VARIANTS_INDEX]
          const isLoadingInitialResults = !pvUiState.page && !pvUiState.query

          // We want to check if the user is loading more results from infinite scroll
          const scrollSentinal = document.getElementById('scroll-sentinel') // This is the element that triggers the infinite scroll in our pv search results grid
          const isLoadingMoreResults =
            !!scrollSentinal &&
            scrollSentinal.getBoundingClientRect().top < window.innerHeight

          // If the user is filtering, typing, or loading more results from infinite scroll, don't hijack the scroll back up to the top!
          if (
            isFiltering ||
            isTyping ||
            isLoadingMoreResults ||
            isLoadingInitialResults
          ) {
            return
          }

          containerRef.current!.scrollIntoView()
        },
      }
    }

    return addMiddlewares(middleware)
  }, [addMiddlewares])

  return (
    <div ref={containerRef} className="ais-ScrollTo">
      {children}
    </div>
  )
}
