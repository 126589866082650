import { PlacidTags } from 'constants/placid/placid-tags'
import { UseCaseRecord } from 'pages/NewReward/types/forms'
import { CategoryTitle } from 'pages/Templates/constants'
import { RewardUseCasesKeysType } from './RewardUseCasesKeys'

export const anniversariesUseCases = {
  ANNIVERSARIES: {
    label: 'Work anniversaries',
    details: {
      searchQuery: 'work anniversary',
      templateCategories: [CategoryTitle.ANNIVERSARIES],
      placidTags: [
        PlacidTags.ANNIVERSARIES,
        PlacidTags.YEAR_1,
        PlacidTags.YEAR_2,
        PlacidTags.YEAR_3,
        PlacidTags.YEAR_4,
        PlacidTags.YEAR_5,
        PlacidTags.YEAR_6,
        PlacidTags.YEAR_7,
        PlacidTags.YEAR_8,
        PlacidTags.YEAR_9,
        PlacidTags.YEAR_10,
      ],
    },
    emailData: [
      {
        title: 'Happy Work-iversary!',
        note: 'Cheers to another fantastic year with us. Thank you for your dedication!',
      },
      {
        title: 'Celebrating You 🎉',
        note: "Marking another year of wonderful collaboration. Here's to many more!",
      },
      {
        title: 'Anniversary Cheers!',
        note: 'Reflecting on the wonderful moments and looking forward to more. Happy Anniversary!',
      },
    ],
  },
  FUNNY_WORK_ANNIVERSARY: {
    label: 'Funny work anniversary',
    details: {
      searchQuery: 'funny work anniversary',
      templateCategories: [CategoryTitle.ANNIVERSARIES],
      placidTags: [PlacidTags.ANNIVERSARIES],
    },
    emailData: [
      {
        title: 'Another Year of Surviving Meetings!',
        note: "Congrats on enduring another year of endless emails and coffee-fueled deadlines. We're glad you haven't run away... yet!",
      },
      {
        title: 'Level Up: Office Ninja 🥷',
        note: "You've mastered the art of looking busy while browsing memes. Here's to another year of professional procrastination!",
      },
      {
        title: "Still Here? We're Impressed!",
        note: "Congratulations on another year of pretending to understand what's going on in meetings. Your poker face is legendary!",
      },
    ],
  },

  ONE_YEAR_ANNIVERSARY: {
    label: '1 year anniversary',
    details: {
      searchQuery: '1 year work anniversary',
      templateCategories: [CategoryTitle.ANNIVERSARIES],
      placidTags: [PlacidTags.YEAR_1, PlacidTags.ANNIVERSARIES],
    },
    emailData: [
      {
        title: 'One Year Strong!',
        note: "Congratulations on your first year with us. Here's to many more!",
      },
      {
        title: '365 Days of Awesome 🎉',
        note: 'Your dedication over the past year has been exemplary. Cheers to one year!',
      },
      {
        title: 'Year One Complete!',
        note: "Time flies when we're achieving great things together. Happy 1-year anniversary!",
      },
    ],
  },
  TWO_YEAR_ANNIVERSARY: {
    label: '2 year anniversary',
    details: {
      searchQuery: '2 year work anniversary',
      templateCategories: [CategoryTitle.ANNIVERSARIES],
      placidTags: [PlacidTags.YEAR_2, PlacidTags.ANNIVERSARIES],
    },
    emailData: [
      {
        title: 'Two Years of Brilliance!',
        note: 'Congratulations on your 2-year work anniversary. Your hard work has not gone unnoticed!',
      },
      {
        title: 'Two Years and Counting!',
        note: 'Celebrating two years of your dedication and brilliance. Thank you for all that you do!',
      },
      {
        title: 'Two Years of Excellence!',
        note: 'Happy 2-year anniversary! Your passion and drive have not gone unnoticed.',
      },
    ],
  },
  THREE_YEAR_ANNIVERSARY: {
    label: '3 year anniversary',
    details: {
      searchQuery: '3 year work anniversary',
      templateCategories: [CategoryTitle.ANNIVERSARIES],
      placidTags: [PlacidTags.YEAR_3, PlacidTags.ANNIVERSARIES],
    },
    emailData: [
      {
        title: 'Happy 3 Year Anniversary',
        note: "Congratulations on your 3-year work anniversary! Your commitment and contributions have been invaluable to our team. Here's to celebrating many more milestones together!",
      },
      {
        title: 'Three Years of Excellence',
        note: "It's been three incredible years since you joined us, and what a journey it's been! Your dedication and hard work are truly appreciated. Happy work anniversary!",
      },
      {
        title: 'Celebrating Your 3-Year Milestone!',
        note: "Happy 3-Year Anniversary! Your passion and drive have not gone unnoticed. Thank you for all that you do, and here's to the journey ahead!",
      },
    ],
  },
  FOUR_YEAR_ANNIVERSARY: {
    label: '4 year anniversary',
    details: {
      searchQuery: '4 year work anniversary',
      templateCategories: [CategoryTitle.ANNIVERSARIES],
      placidTags: [PlacidTags.YEAR_4, PlacidTags.ANNIVERSARIES],
    },
    emailData: [
      {
        title: 'Four Fantastic Years!',
        note: "Congratulations on your 4-year work anniversary! Your dedication and contributions continue to inspire us all. Here's to many more years of success!",
      },
      {
        title: 'Celebrating 4 Years of Excellence',
        note: "Four years of growth, innovation, and teamwork. We're so grateful for your continued commitment. Happy work anniversary!",
      },
      {
        title: 'Four Years Strong!',
        note: "Time flies when you're doing great work! Thank you for four years of outstanding service and dedication. Cheers to your work anniversary!",
      },
    ],
  },
  FIVE_YEAR_ANNIVERSARY: {
    label: '5 year anniversary',
    details: {
      searchQuery: '5 year work anniversary',
      templateCategories: [CategoryTitle.ANNIVERSARIES],
      placidTags: [PlacidTags.YEAR_5, PlacidTags.ANNIVERSARIES],
    },
    emailData: [
      {
        title: 'Half a Decade!',
        note: "Five years of growth, hard work, and memories. Here's to you!",
      },
      {
        title: 'Cheers to 5 Years 🍾',
        note: 'Your commitment over the past five years has truly made a difference.',
      },
      {
        title: 'Golden Milestone!',
        note: 'Celebrating half a decade of your dedication and brilliance. Congrats!',
      },
    ],
  },
  SIX_YEAR_ANNIVERSARY: {
    label: '6 year anniversary',
    details: {
      searchQuery: '6 year work anniversary',
      templateCategories: [CategoryTitle.ANNIVERSARIES],
      placidTags: [PlacidTags.YEAR_6, PlacidTags.ANNIVERSARIES],
    },
    emailData: [
      {
        title: 'Six Years of Excellence!',
        note: "Congratulations on your 6-year work anniversary! Your dedication and hard work continue to inspire us all. Here's to many more years of success!",
      },
      {
        title: 'Celebrating 6 Years Together',
        note: "Six years of growth, innovation, and teamwork. We're incredibly grateful for your continued commitment. Happy work anniversary!",
      },
      {
        title: 'Six Years Strong!',
        note: "Time flies when you're making a difference! Thank you for six years of outstanding service and dedication. Cheers to your work anniversary!",
      },
    ],
  },
  SEVEN_YEAR_ANNIVERSARY: {
    label: '7 year anniversary',
    details: {
      searchQuery: '7 year work anniversary',
      templateCategories: [CategoryTitle.ANNIVERSARIES],
      placidTags: [PlacidTags.YEAR_7, PlacidTags.ANNIVERSARIES],
    },
    emailData: [
      {
        title: 'Lucky Seven Years!',
        note: "Seven years of brilliance and counting. We're fortunate to have you on our team!",
      },
      {
        title: 'Magnificent Seven 🌟',
        note: 'Celebrating seven years of your exceptional contributions and growth. Congratulations!',
      },
      {
        title: '7 Years of Impact!',
        note: "Your dedication over these seven years has truly made a difference. Here's to many more!",
      },
    ],
  },
  EIGHT_YEAR_ANNIVERSARY: {
    label: '8 year anniversary',
    details: {
      searchQuery: '8 year work anniversary',
      templateCategories: [CategoryTitle.ANNIVERSARIES],
      placidTags: [PlacidTags.YEAR_8, PlacidTags.ANNIVERSARIES],
    },
    emailData: [
      {
        title: 'Eight Great Years!',
        note: "Congratulations on eight years of excellence! Your commitment and contributions continue to drive our success. Here's to your remarkable journey!",
      },
      {
        title: 'Celebrating 8 Years of Brilliance',
        note: "Eight years of innovation, dedication, and teamwork. We're honored to have you with us. Happy work anniversary!",
      },
      {
        title: 'Eight Years and Elevating!',
        note: "Your growth over these eight years has been truly inspiring. Thank you for your continued excellence. Here's to many more achievements!",
      },
    ],
  },
  NINE_YEAR_ANNIVERSARY: {
    label: '9 year anniversary',
    details: {
      searchQuery: '9 year work anniversary',
      templateCategories: [CategoryTitle.ANNIVERSARIES],
      placidTags: [PlacidTags.YEAR_9, PlacidTags.ANNIVERSARIES],
    },
    emailData: [
      {
        title: 'Nine Years of Greatness!',
        note: "Wow, nine years! Your dedication and expertise have been instrumental to our success. We're so grateful for your continued commitment.",
      },
      {
        title: 'Celebrating a Fantastic Nine 🎉',
        note: "Nine years of growth, challenges overcome, and goals achieved. You've been an integral part of our journey. Happy work anniversary!",
      },
      {
        title: '9 Years and Still Shining!',
        note: "Your passion and drive haven't dimmed a bit in nine years. Thank you for your outstanding contributions. Here's to the decade mark!",
      },
    ],
  },
  TEN_YEAR_ANNIVERSARY: {
    label: '10 year anniversary',
    details: {
      searchQuery: '10 year work anniversary',
      templateCategories: [CategoryTitle.ANNIVERSARIES],
      placidTags: [PlacidTags.YEAR_10, PlacidTags.ANNIVERSARIES],
    },
    emailData: [
      {
        title: 'A Decade of Dedication!',
        note: "10 years of excellence and counting. We're lucky to have you!",
      },
      {
        title: 'Cheers to a Decade 🎊',
        note: 'A decade of memories, achievements, and growth. Congratulations!',
      },
      {
        title: '10 Years and Shining!',
        note: 'Celebrating a glorious decade of your journey with us.',
      },
    ],
  },
} satisfies Partial<Record<RewardUseCasesKeysType, UseCaseRecord>>
