import { callFunction } from 'api/functionCalls'
import { OrgContext } from 'context'
import { useContext, useEffect, useState } from 'react'
import Stripe from 'stripe'

export default function useCustomer() {
  const { customerId } = useContext(OrgContext)

  const [customer, setCustomer] = useState<Stripe.Customer>()
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (customerId) {
      setIsLoading(true)
      callFunction('stripe-RetrieveCustomer', {
        customerId,
      })
        .then(setCustomer)
        .finally(() => setIsLoading(false))
    }
  }, [customerId])

  return { customer, isLoading }
}
