import { Calendar } from 'antd'
import { CalendarMode } from 'antd/es/calendar/generateCalendar'
import { GetIndividualById } from 'api/databaseCalls'
import { INDIVIDUAL_ID } from 'constants/params'
import { IndividualContext } from 'context'
import { Dayjs } from 'dayjs'
import { Pane } from 'evergreen-ui'
import { Individual, Individual_Role } from 'gen/perkup/v1/individual_pb'
import useIds from 'hooks/useIds'
import { IDateInfo } from 'pages/Automations/RewardCalendarFiltered'
import { useContext, useEffect, useState } from 'react'
import { useInstantSearch } from 'react-instantsearch'
import { useSearchParams } from 'react-router-dom'
import { RuleType, RuleTypes } from 'types/rules'
import { algoliaResultsToIndividuals } from 'utils/individual'
import CreateCalendarEvents, {
  getEventInfo,
  getFirstAndLastDateOfYear,
  getFirstDateAndLastDateOnCalendar,
} from './CalendarUtils'
import { EventDrawer, IEventInfo } from './EventDrawer'

enum DateTypes {
  MONTH = 'month',
  DAY = 'day',
  YEAR = 'year',
}

export function RewardCalendar({
  ruleType,
  dateInfo,
  setDateInfo,
}: {
  ruleType: RuleType
  dateInfo: IDateInfo
  setDateInfo: (dateInfo: IDateInfo) => void
}) {
  const { orgId } = useIds()
  const [searchParams] = useSearchParams()
  const eventIndividualId = searchParams.get(INDIVIDUAL_ID)
  const individual = useContext(IndividualContext)
  const isAdmin = individual?.role === Individual_Role.admin
  const defaultView = isAdmin ? DateTypes.MONTH : DateTypes.YEAR

  const [calendarView, setCalendarView] = useState<CalendarMode>(defaultView)
  const [eventInfo, setEventInfo] = useState<IEventInfo | undefined>()
  const isBirthdayRule = ruleType === RuleTypes.birthdays

  useEffect(() => {
    if (!eventIndividualId) return
    GetIndividualById({ orgId, individualId: eventIndividualId }).then(
      eventIndividual => {
        if (!eventIndividual) return
        const { yearsDiff, eventDate } = getEventInfo({
          individual: eventIndividual,
          isBirthday: isBirthdayRule,
          dateInfo,
        })
        if (!eventDate) return
        const individualEventInfo: IEventInfo = {
          eventIndividual,
          isBirthday: isBirthdayRule,
          yearsDiff,
          date: eventDate,
        }
        setEventInfo(individualEventInfo)
      }
    )
  }, [dateInfo, eventIndividualId, isBirthdayRule, orgId])

  // Handle clicking a calendar event
  function handleEventClick({
    date,
    individual,
    yearsDiff,
  }: {
    date: Dayjs
    individual: Individual
    yearsDiff: number
  }) {
    setEventInfo({
      eventIndividual: individual,
      date,
      yearsDiff,
      isBirthday: isBirthdayRule,
    })
  }

  const handleCalendarChange = (date: Dayjs, mode: CalendarMode) => {
    setCalendarView(mode)
    if (mode === DateTypes.MONTH) {
      const newDateRange = getFirstDateAndLastDateOnCalendar(date)
      setDateInfo(newDateRange)
    } else {
      setDateInfo(getFirstAndLastDateOfYear(date))
    }
  }

  const { results } = useInstantSearch()

  const individuals = algoliaResultsToIndividuals(results)

  const dateCellRender = ({
    compareMetric,
    value,
  }: {
    compareMetric: DateTypes
    value: Dayjs
  }) => {
    const calendarEvents = CreateCalendarEvents({
      individuals,
      dateInfo,
      ruleType,
    })

    return (
      <Pane>
        {calendarEvents.map(event => {
          if (event && event.date && event.date.isSame(value, compareMetric)) {
            return (
              <Pane
                key={event.individual.id}
                onClick={() =>
                  handleEventClick({
                    date: value,
                    individual: event.individual,
                    yearsDiff: event.yearsDiff,
                  })
                }
              >
                {event.title}
              </Pane>
            )
          }
          return null
        })}
      </Pane>
    )
  }

  return (
    <Pane>
      {/** Entire calendar with all the events */}

      <Calendar
        onPanelChange={handleCalendarChange}
        cellRender={value =>
          dateCellRender({
            value,
            compareMetric:
              calendarView === 'month' ? DateTypes.DAY : DateTypes.MONTH,
          })
        }
        mode={calendarView}
      />
      {/** When an event on the calendar is clicked, display its info in a side menu / drawer */}
      <EventDrawer
        eventInfo={eventInfo}
        setEventInfo={setEventInfo}
        ruleType={ruleType}
      />
    </Pane>
  )
}
