import { Col, Flex, Row, Tag } from 'antd'
import { Link, Text } from 'evergreen-ui'
import capitalize from 'lodash-es/capitalize'
import Stripe from 'stripe'
import { numToDollars } from 'utils'

export function BillingHistoryTable({
  invoices,
}: {
  invoices: Stripe.Invoice[]
}) {
  return (
    <Flex vertical gap={16} style={{ width: '100%' }}>
      {invoices?.map((invoice: Stripe.Invoice) => {
        return (
          <Row id={invoice.id} key={invoice.id}>
            <Col span={8}>
              <Text whiteSpace="nowrap">
                {new Date(Number(invoice.created) * 1000).toLocaleDateString(
                  'en-us',
                  {
                    month: 'long',
                    day: 'numeric',
                    year: 'numeric',
                  }
                )}
              </Text>
            </Col>

            <Col span={6}>
              <Link
                href={invoice.hosted_invoice_url || ''}
                target="_blank"
                rel="noopener noreferrer"
                whiteSpace="nowrap"
              >
                {invoice.number}
              </Link>
            </Col>

            <Col span={6}>
              {invoice.status && (
                <Tag color={invoice.status === 'paid' ? 'green' : 'default'}>
                  {capitalize(invoice.status)}
                </Tag>
              )}
            </Col>
            <Col span={4}>
              <Text whiteSpace="nowrap">
                {numToDollars(Number(invoice.amount_due))}
              </Text>
            </Col>
          </Row>
        )
      })}
    </Flex>
  )
}
