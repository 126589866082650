// @generated by protoc-gen-es v1.6.0 with parameter "target=ts"
// @generated from file amazon/fulfill.proto (package amazon, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";
import { PostalAddress } from "../google/type/postal_address_pb.js";

/**
 * @generated from message amazon.PlaceOrderRequest
 */
export class PlaceOrderRequest extends Message<PlaceOrderRequest> {
  /**
   * @generated from field: google.type.PostalAddress postal_address = 2;
   */
  postalAddress?: PostalAddress;

  /**
   * @generated from field: string phone_number = 3;
   */
  phoneNumber = "";

  /**
   * @generated from field: amazon.PlaceOrderRequest.Item item = 4;
   */
  item?: PlaceOrderRequest_Item;

  /**
   * @generated from field: int32 total = 5;
   */
  total = 0;

  /**
   * optional ordering of programs to allocate into
   *
   * @generated from field: repeated string program_ids = 6;
   */
  programIds: string[] = [];

  constructor(data?: PartialMessage<PlaceOrderRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "amazon.PlaceOrderRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 2, name: "postal_address", kind: "message", T: PostalAddress },
    { no: 3, name: "phone_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "item", kind: "message", T: PlaceOrderRequest_Item },
    { no: 5, name: "total", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 6, name: "program_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PlaceOrderRequest {
    return new PlaceOrderRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PlaceOrderRequest {
    return new PlaceOrderRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PlaceOrderRequest {
    return new PlaceOrderRequest().fromJsonString(jsonString, options);
  }

  static equals(a: PlaceOrderRequest | PlainMessage<PlaceOrderRequest> | undefined, b: PlaceOrderRequest | PlainMessage<PlaceOrderRequest> | undefined): boolean {
    return proto3.util.equals(PlaceOrderRequest, a, b);
  }
}

/**
 * @generated from message amazon.PlaceOrderRequest.Item
 */
export class PlaceOrderRequest_Item extends Message<PlaceOrderRequest_Item> {
  /**
   * @generated from field: string supplier_part_id = 1;
   */
  supplierPartId = "";

  /**
   * @generated from field: string offer_id = 2;
   */
  offerId = "";

  /**
   * @generated from field: int32 quantity = 3;
   */
  quantity = 0;

  /**
   * @generated from field: int32 unit_price = 4;
   */
  unitPrice = 0;

  /**
   * @generated from field: string currency_code = 5;
   */
  currencyCode = "";

  /**
   * @generated from field: string unit_of_measure = 6;
   */
  unitOfMeasure = "";

  /**
   * @generated from field: string title = 7;
   */
  title = "";

  constructor(data?: PartialMessage<PlaceOrderRequest_Item>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "amazon.PlaceOrderRequest.Item";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "supplier_part_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "offer_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "quantity", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "unit_price", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "currency_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "unit_of_measure", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PlaceOrderRequest_Item {
    return new PlaceOrderRequest_Item().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PlaceOrderRequest_Item {
    return new PlaceOrderRequest_Item().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PlaceOrderRequest_Item {
    return new PlaceOrderRequest_Item().fromJsonString(jsonString, options);
  }

  static equals(a: PlaceOrderRequest_Item | PlainMessage<PlaceOrderRequest_Item> | undefined, b: PlaceOrderRequest_Item | PlainMessage<PlaceOrderRequest_Item> | undefined): boolean {
    return proto3.util.equals(PlaceOrderRequest_Item, a, b);
  }
}

