import { ListProgramsByOwnerId } from 'api/databaseCalls'
import { Program } from 'gen/perkup/v1/program_pb'
import { useEffect, useState } from 'react'
import useIds from './useIds'

export function useRecentlySentPrograms() {
  const { orgId, userId } = useIds()
  const [programs, setPrograms] = useState<Program[]>([])
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    setIsLoading(true)
    ListProgramsByOwnerId({ orgId, userId, limit: 15 })
      .then(res => {
        if (!res) return
        setPrograms(res)
      })
      .finally(() => setIsLoading(false))
  }, [orgId, userId])

  return { isLoading, programs }
}
