import { Image } from 'antd'
import { Pane, PaneProps, Strong } from 'evergreen-ui'
import { Template } from 'gen/perkup/v1/org_template_pb'

export const ORG_TEMPLATE_CARD_STYLES: PaneProps = {
  borderRadius: 8,
  border: 'muted',
  display: 'flex',
  alignItems: 'center',
  gap: 16,
  padding: 16,
  hoverElevation: 1,
  cursor: 'pointer',
  minWidth: 248,
  height: 104,
}

export function OrgTemplateCard({
  template,
  onClick,
}: {
  template: Template
  onClick: () => void
}) {
  return (
    <Pane {...ORG_TEMPLATE_CARD_STYLES} onClick={onClick}>
      <Image
        width={72}
        height={72}
        preview={false}
        style={{ objectFit: 'cover', borderRadius: 8 }}
        src={template?.image?.url}
      />
      <Pane display="flex" flexDirection="column" gap={4}>
        <Strong>{template.name}</Strong>
      </Pane>
    </Pane>
  )
}
