import { PlusOutlined } from '@ant-design/icons'
import { Input, Tag } from 'antd'
import { updateOrgCoreValues } from 'api/databaseCalls'
import { BrandedTag } from 'components'
import { CORE_VALUE_CREATED } from 'constants/events'
import { IndividualContext, OrgContext } from 'context'
import { Pane, Strong, toaster, useTheme } from 'evergreen-ui'
import { Individual_Role } from 'gen/perkup/v1/individual_pb'
import useIds from 'hooks/useIds'
import { compact, isEmpty, isFunction } from 'lodash-es'
import { CORE_VALUE_MAX } from 'pages/CompanyBranding/constants/brandingConstants'
import { useContext, useState } from 'react'
import { logEvent } from 'utils'

const { CheckableTag } = Tag

export function CoreValuesTags({
  selectedCoreValues,
  setSelectedCoreValues,
  showTitle = true,
  disabled = false,
}: {
  selectedCoreValues: string[]
  setSelectedCoreValues?: (coreValues: string[]) => void
  showTitle?: boolean
  disabled?: boolean
}) {
  const individual = useContext(IndividualContext)
  const isAdmin = individual.role === Individual_Role.admin
  const org = useContext(OrgContext)
  const { orgId, userId } = useIds()
  const theme = useTheme()

  const dashedTagStyles = {
    margin: 4,
    border: `1px dashed ${theme.colors.gray400}`,
  }

  const { coreValues } = org
  const [coreValueInput, setCoreValueInput] = useState<string>('')
  const [inputVisible, setInputVisible] = useState(false)

  const handleChange = (tag: string, checked: boolean) => {
    if (!isFunction(setSelectedCoreValues)) return
    const nextSelectedTags = checked
      ? [...selectedCoreValues, tag]
      : selectedCoreValues.filter(t => t !== tag)

    setSelectedCoreValues(nextSelectedTags)
  }

  const showAddCoreValue = isAdmin && coreValues.length < CORE_VALUE_MAX

  const handleAddCoreValue = () => {
    if (coreValueInput.trim() !== '') {
      const newCoreValues = [...coreValues, coreValueInput]

      logEvent(CORE_VALUE_CREATED, {
        coreValue: coreValueInput,
        orgId,
        orgName: org.name,
        orgSubscriptionStatus: org.subscriptionStatus,
        userId,
      })
      updateOrgCoreValues({ orgId, coreValues: newCoreValues })
      setCoreValueInput('')
      toaster.success('Core value added successfully')
    }
    setInputVisible(false)
  }

  if (isEmpty(selectedCoreValues) && disabled) return null

  const showAddCoreValueButton = !disabled && !inputVisible && showAddCoreValue

  const showInput = !disabled && inputVisible

  return (
    <Pane display="flex" flexDirection="column">
      {showTitle && (
        <Strong flex={1} marginBottom={12}>
          Core values
        </Strong>
      )}

      <Pane display="flex" flexWrap="wrap">
        {compact(coreValues).map(tag => {
          const isSelected = selectedCoreValues.includes(tag)

          if (disabled) {
            if (isSelected) return <BrandedTag key={tag}>{tag}</BrandedTag>
            return null
          }

          return (
            <CheckableTag
              key={tag}
              checked={selectedCoreValues.indexOf(tag) > -1}
              onChange={checked => handleChange(tag, checked)}
              style={
                isSelected
                  ? { margin: 4 }
                  : {
                      ...dashedTagStyles,
                    }
              }
            >
              {tag}
            </CheckableTag>
          )
        })}
        {showInput && (
          <Input
            type="text"
            size="small"
            style={{
              margin: 4,
              width: 128,
            }}
            value={coreValueInput}
            onChange={e => setCoreValueInput(e.target.value)}
            onBlur={handleAddCoreValue}
            onPressEnter={handleAddCoreValue}
            placeholder="Add core values"
          />
        )}

        {showAddCoreValueButton && (
          <Tag
            onClick={() => setInputVisible(true)}
            style={{
              ...dashedTagStyles,
              backgroundColor: 'white',
            }}
          >
            <PlusOutlined /> Add
          </Tag>
        )}
      </Pane>
    </Pane>
  )
}
