import { PartialMessage } from '@bufbuild/protobuf'
import {
  createAccount,
  GetOrgAccountById,
  ListSendableOrgAccounts,
  updateIndividualDefaultCompanyAccountId,
} from 'api/databaseCalls'
import { getAuth } from 'firebase/auth'
import { AccountTransfer } from 'gen/ledger/account_connect'
import { AdjustFundsRequest } from 'gen/ledger/account_pb'
import { Account_Permission, AccountType_Enum } from 'gen/perkup/v1/account_pb'
import { Individual } from 'gen/perkup/v1/individual_pb'
import { createClient } from 'hooks/useClient'
import { keys } from 'lodash-es'
import { PERMISSION_ADMIN } from 'types/Permissions'
import { getIndividualDisplayName } from 'utils/individual'

const createAccountClient = async () => {
  const accessToken = await getAuth().currentUser?.getIdToken()
  const client = createClient(AccountTransfer)
  return { client, accessToken }
}

export async function transferFunds({
  sourceAccount,
  destinationAccount,
  amount,
}: {
  sourceAccount: string
  destinationAccount: string
  amount: bigint
}): Promise<void> {
  const { client, accessToken } = await createAccountClient()

  await client.transferFunds(
    { sourceAccount, destinationAccount, amount },
    { headers: { Authorization: `Bearer ${accessToken}` } }
  )
}

export async function adjustFunds({
  accountId,
  amount,
  sourceId,
  memo,
}: PartialMessage<AdjustFundsRequest>) {
  const { client, accessToken } = await createAccountClient()
  await client.adjustFunds(
    {
      accountId,
      amount,
      sourceId,
      memo,
    },
    { headers: { Authorization: `Bearer ${accessToken}` } }
  )
}

/**
 * Creates a default company account for an individual. This is like a PF account, but for admins and managers to spend. Encapsulating logic here to avoid duplication.
 */
export async function createDefaultCompanyAccount({
  individual,
  orgId,
}: {
  individual: Individual
  orgId: string
}) {
  // Account permissions are set to full for the individual and view for the org admins
  const account = await createAccount({
    name: `${getIndividualDisplayName(individual)}'s reward budget`,
    permissions: {
      [PERMISSION_ADMIN]: Account_Permission.view,
      [individual.id]: Account_Permission.full,
    },
    orgId,
    type: AccountType_Enum.organizationFunds,
    individualId: individual.id,
  })

  if (account) {
    // Set the individual's default company account to the newly created account
    await updateIndividualDefaultCompanyAccountId({
      orgId,
      individualId: individual.id,
      defaultCompanyAccountId: account.id,
    })
    return GetOrgAccountById({ orgId, accountId: account.id })
  }
  return undefined
}

/**
 * Gets the default company account for an individual. Encapsulating logic here to avoid duplication.
 */
export async function getIndividualsDefaultCompanyAccount({
  individual,
  orgId,
}: {
  individual: Individual
  orgId: string
}) {
  const { id: individualId, defaultCompanyAccountId, role } = individual

  // First, check for existing explicit default company account
  if (defaultCompanyAccountId) {
    const explicitDefaultCompanyAccount = await GetOrgAccountById({
      orgId,
      accountId: defaultCompanyAccountId,
    })
    return explicitDefaultCompanyAccount
  }

  // Second, check to see if they have a default company account created, but not explicit on the individual. This is the case for managers whose default accounts were created before the individual default company account field was added.
  const sendableAccounts = await ListSendableOrgAccounts({
    individualId,
    individualRole: role,
    orgId,
  })

  // We will infer it's their sendable account if the permissions are admin view, individualId full - and there are no other keys in the permissions object
  const implicitDefaultCompanyAccount = sendableAccounts?.find(acc => {
    const { permissions } = acc
    const permissionKeys = keys(permissions)
    // permissions object should only have two keys
    if (permissionKeys.length !== 2) return false

    // one key should be the individualId, and the value should be full
    const fullIndividualPermission =
      permissions[individualId] === Account_Permission.full

    // the other key should be the admin key and the value should be view
    if (!permissionKeys.includes(PERMISSION_ADMIN)) return false
    const viewAdminPermission =
      permissions[PERMISSION_ADMIN] === Account_Permission.view

    return fullIndividualPermission && viewAdminPermission
  })

  return implicitDefaultCompanyAccount
}
