export const DIGITAL_ORDER_URL_TYPE = 'url'
export const DIGITAL_ORDER_CODE_TYPE = 'code'
export const DIGITAL_ORDER_PIN_TYPE = 'codePin'

export const FREE_DELIVERY = 'FREE delivery'

export const CODE_TOOLTIP_COPY = 'Copy code'
export const PIN_TOOLTIP_COPY = 'Copy PIN'

export const AMAZON_TRANSACTION_CATEGORY = 'amazon'
export const MANUAL_TRANSACTION_CATEGORY = 'manual'
