import { Select } from 'antd'
import { SizeType } from 'antd/es/config-provider/SizeContext'
import { CountriesContext } from 'context'
import { findFlagUrlByIso2Code } from 'country-flags-svg'
import { useContext } from 'react'
import { getCountryIsoAlpha2 } from 'utils'
import {
  AllCountriesOptionPane,
  CountryLabelWithFlag,
} from '../CustomCountryLabels'

export function CountryMultiSelect({
  disabled,
  size,
  onSubmitCountries,
  selectedCountries,
  maxTagCount,
}: {
  disabled?: boolean
  size?: SizeType
  maxTagCount?: number
  onSubmitCountries: (countries: string[]) => void
  selectedCountries: string[]
}) {
  const countries = useContext(CountriesContext)

  const countryOptions = countries.map(country => {
    const flagUrl = findFlagUrlByIso2Code(getCountryIsoAlpha2(country.iso3))

    const countryOption = {
      label: (
        <CountryLabelWithFlag
          flagUrl={flagUrl}
          label={country.name}
          key={`key-${country.name}`}
        />
      ),
      value: country.iso3,
    }
    return countryOption
  })

  return (
    <Select
      className="customSelectionItem"
      mode="tags"
      allowClear
      maxTagCount={maxTagCount}
      style={{ width: '100%', minWidth: 160 }}
      placeholder={<AllCountriesOptionPane />}
      options={countryOptions}
      value={selectedCountries}
      onChange={onSubmitCountries}
      disabled={disabled}
      size={size}
    />
  )
}
