import { Checkbox, Flex } from 'antd'
import { PROGRAM_TABS, PROGRAM_TAB_LABELS } from 'constants/programs'
import { Tab, Tablist } from 'evergreen-ui'
import { ProgramStatus, ProgramType } from 'gen/perkup/v1/program_pb'
import { useIndividualRole } from 'hooks'
import useIds from 'hooks/useIds'
import { capitalize } from 'lodash-es'
import { useState } from 'react'
import { RangeValue } from 'types/RewardsDate'
import { InfiniteProgramsList } from './InfiniteProgramsList'

export function BrowsePrograms({
  datesToFilter,
}: {
  datesToFilter?: RangeValue
}) {
  const { userId } = useIds()
  const { isAdmin, isManager } = useIndividualRole()

  const [isOwnerFilterChecked, setIsOwnerFilterChecked] = useState(false)
  const [currentProgramTab, setCurrentProgramTab] = useState<ProgramStatus>(
    ProgramStatus.active
  )

  const shouldFilterByOwner =
    isManager ||
    isOwnerFilterChecked ||
    currentProgramTab === ProgramStatus.draft
  const showOwnerFilterCheckbox =
    isAdmin && currentProgramTab !== ProgramStatus.draft

  const filtersToApply = {
    types: [ProgramType.rewards, ProgramType.direct],
    status: currentProgramTab,
    ownerId: shouldFilterByOwner ? userId : undefined,
    dates: datesToFilter,
  }

  return (
    <Flex vertical gap={16}>
      <Tablist>
        {PROGRAM_TABS.map(tab => (
          <Tab
            key={tab}
            appearance="primary"
            id={ProgramStatus[tab]}
            onSelect={() => setCurrentProgramTab(tab)}
            isSelected={tab === currentProgramTab}
          >
            {capitalize(PROGRAM_TAB_LABELS[tab])}
          </Tab>
        ))}
      </Tablist>

      {showOwnerFilterCheckbox && (
        <Checkbox
          checked={isOwnerFilterChecked}
          onChange={e => setIsOwnerFilterChecked(e.target.checked)}
        >
          Show my created rewards
        </Checkbox>
      )}

      <InfiniteProgramsList
        key={JSON.stringify(filtersToApply)} // If the filters change, tell React to completely re-mount this component so all the internal state starts fresh
        numProgramsOnMount={10}
        numProgramsOnPagination={5}
        filters={filtersToApply}
      />
    </Flex>
  )
}
