import {
  DollarOutlined,
  RightOutlined,
  SendOutlined,
  ShopOutlined,
  SkinOutlined,
  TrophyOutlined,
  UserOutlined,
} from '@ant-design/icons'
import { Button, Tag } from 'antd'
import { CountrySelection } from 'components'
import { PerkUpName } from 'components/Typography'
import { defaultButtonStyles } from 'constants/antdesign'
import { NUMBER_GREEN } from 'constants/colors'
import { PROGRAM_TEMPLATE_CLICKED } from 'constants/events'
import * as ROUTES from 'constants/routes'
import { DEFAULT_ROUTES } from 'constants/routes'
import {
  ExchangeRateContext,
  IndividualContext,
  OrgContext,
  OrgUserContext,
  SwagCollectionIdsContext,
  UserContext,
} from 'context'
import {
  Heading,
  Pane,
  Tab,
  TabNavigation,
  Tablist,
  Text,
  useTheme,
} from 'evergreen-ui'
import { Individual_Role } from 'gen/perkup/v1/individual_pb'
import { Organization_SubscriptionStatus } from 'gen/perkup/v1/organization_pb'
import { useIndividualRole, useShopRoute } from 'hooks'
import { useSwagStoreRoute } from 'hooks/routingHooks/useSwagStoreRoute'
import { isEmpty } from 'lodash-es'
import capitalize from 'lodash-es/capitalize'
import { useContext } from 'react'
import { isMobile } from 'react-device-detect'
import { Link, useLocation } from 'react-router-dom'
import { NavigationTab } from 'types'
import { logEvent, numToDollars } from 'utils'
import { shortName } from 'utils/individual'
import { CompanyDisplay } from './CompanyDisplay'
import { Footer } from './Footer'
import { MemberMobileBottomNav } from './MemberMobileBottomNav'
import { MemberMobileHeader } from './MemberMobileHeader'
import { OrgDropdown } from './OrgDropdown'
import { UserDropdown } from './UserDropdown'

export const withOrgUserSidebar = (BaseComponent: any) =>
  function HOC(props: any) {
    const org = useContext(OrgContext)
    const user = useContext(UserContext)
    const orgUser = useContext(OrgUserContext)
    const individual = useContext(IndividualContext)
    const exchangeRate = useContext(ExchangeRateContext)
    const swagCollectionIds = useContext(SwagCollectionIdsContext)
    const userBalance = numToDollars(
      orgUser?.balance ?? 0 * exchangeRate,
      2,
      false,
      user.displayCurrency
    )

    const theme = useTheme()
    const location = useLocation()

    const { swagRoute } = useSwagStoreRoute()
    const { shopRoute } = useShopRoute()
    const { isAdmin, isManager } = useIndividualRole()

    if (!BaseComponent) return null

    const tabDirection = 'vertical' as const
    const navStyles = {
      minWidth: 224,
      paddingY: 32,
      paddingLeft: 32,
      height: '100vh',
    }

    const tabStyles = { height: 32, direction: tabDirection, width: '100%' }

    const handleNewRewardClick = (name: string) => {
      logEvent(PROGRAM_TEMPLATE_CLICKED, {
        name,
        fromTemplate: false,
        orgName: org.name,
        orgSubscriptionStatus: org?.subscriptionStatus
          ? Organization_SubscriptionStatus[org.subscriptionStatus]
          : null,

        orgId: org?.id,
        userId: user?.id,
      })
    }

    const hasShortName = shortName(user)

    const userTabs: NavigationTab[] = [
      {
        name: 'Rewards',
        icon: <TrophyOutlined />,
        route: ROUTES.CARD,
        displayBalance: true,
        isSelected:
          location.pathname.includes(ROUTES.CARD) ||
          location.pathname === ROUTES.HOME,
      },
      {
        name: 'Shop',
        icon: <ShopOutlined />,
        route: shopRoute,
        isSelected: location.pathname.includes(ROUTES.SHOP),
      },

      {
        name: 'Swag Store',
        icon: <SkinOutlined />,
        route: swagRoute,
        hidden: isEmpty(swagCollectionIds),
        isSelected: false,
      },
      {
        name: 'Transactions',
        icon: <DollarOutlined />,
        route: ROUTES.TRANSACTIONS,
        isSelected: location.pathname.includes(ROUTES.TRANSACTIONS),
      },

      {
        name: 'Account',
        icon: <UserOutlined />,
        route: ROUTES.ACCOUNT,
        hasWarning: hasShortName,
        isSelected: location.pathname.includes(ROUTES.ACCOUNT),
      },
    ]

    if (isMobile) {
      return (
        <>
          <MemberMobileHeader />
          <Pane
            display="flex"
            alignItems="stretch"
            minHeight="100vh"
            maxWidth={window.innerWidth}
            width="100%"
          >
            {/* BASE COMPONENT */}
            <Pane
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              alignItems="center"
              paddingY={88}
              paddingX={16}
              width="100%"
              minHeight="100vh"
            >
              <Pane width="100vw" paddingX={16}>
                <BaseComponent {...props} />
              </Pane>
              <Footer />
            </Pane>
          </Pane>
          <MemberMobileBottomNav />
        </>
      )
    }

    return (
      <Pane
        display="flex"
        alignItems="stretch"
        minHeight="100vh"
        maxWidth={window.innerWidth}
        width="100%"
      >
        {/* TOP RIGHT NAV */}

        {!location.pathname?.includes(ROUTES.SWAG) && (
          <Pane
            display="flex"
            alignItems="center"
            position="absolute"
            top={16}
            right={16}
          >
            <Pane
              marginRight={24}
              paddingRight={24}
              borderRight="muted"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <CountrySelection user={user} />
            </Pane>
            <UserDropdown />
          </Pane>
        )}
        {/* PRIMARY NAV */}
        <Pane zIndex={5} {...navStyles}>
          <Pane marginBottom={32}>
            {org.name && (
              <OrgDropdown>
                <Pane>
                  <CompanyDisplay />
                </Pane>
              </OrgDropdown>
            )}
          </Pane>

          <Tablist marginBottom={16}>
            {userTabs.map(tab => {
              if (tab.hidden) return null

              return (
                <Tab
                  {...tabStyles}
                  key={tab.name}
                  id={tab.name}
                  display="flex"
                  justifyContent="space-between"
                  isSelected={tab.isSelected}
                  to={tab.route}
                  is={Link}
                  disabled={tab.disabled}
                >
                  <Pane display="flex" gap={8} alignItems="center">
                    {tab.icon} {tab.name}
                  </Pane>
                  {tab.displayBalance && (
                    <Text color={NUMBER_GREEN}>{userBalance} </Text>
                  )}
                  {tab?.isNew && <Tag color="blue">New</Tag>}
                </Tab>
              )
            })}
          </Tablist>

          {(isAdmin || isManager) && (
            <>
              <Pane borderBottom="muted" marginY={32} />
              {individual.role && (
                <Heading size={100} marginBottom={16}>
                  {capitalize(Individual_Role[individual.role])}
                </Heading>
              )}
              <Link to={DEFAULT_ROUTES.ORGANIZATION.REWARDS.NEW_REWARD}>
                <Button
                  type="primary"
                  style={{
                    ...defaultButtonStyles,
                    gap: 8,
                    marginBottom: 8,
                  }}
                  onClick={() => handleNewRewardClick('From member sidebar')}
                >
                  Send reward
                  <SendOutlined />
                </Button>
              </Link>
              <TabNavigation marginBottom={16}>
                {isAdmin && (
                  <Tab
                    is={Link}
                    to={DEFAULT_ROUTES.ORGANIZATION.REWARDS.TEMPLATES.ROOT}
                    {...tabStyles}
                  >
                    Templates
                  </Tab>
                )}
                <Tab
                  is={Link}
                  to={DEFAULT_ROUTES.ORGANIZATION.DIRECTORY.ROOT}
                  {...tabStyles}
                >
                  {isAdmin ? 'People' : 'Direct Reports'}
                </Tab>
              </TabNavigation>
              {individual.role && (
                <Link to={DEFAULT_ROUTES.ORGANIZATION.REWARDS.SPOT}>
                  <Button style={{ display: 'flex', alignItems: 'center' }}>
                    {capitalize(Individual_Role[individual.role])} view
                    <RightOutlined style={{ fontSize: '10px' }} />
                  </Button>
                </Link>
              )}
            </>
          )}

          <Pane position="fixed" bottom={24}>
            <Pane is={Link} to={ROUTES.DASHBOARD}>
              <PerkUpName color={theme.colors.gray400} />
            </Pane>
          </Pane>
        </Pane>

        {/* BASE COMPONENT */}
        <Pane
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          alignItems="center"
          paddingTop={88}
          paddingX={56}
          width="100%"
          minHeight="100vh"
        >
          <Pane width="clamp(320px, 70vw, 1400px)">
            <BaseComponent {...props} />
          </Pane>

          <Footer />
        </Pane>
      </Pane>
    )
  }
