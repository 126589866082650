import { USD } from 'constants/currencies'
import { ORGANIZATION } from 'constants/routes'
import { ExchangeRateContext, OrgContext, UserContext } from 'context'
import { Text } from 'evergreen-ui'
import { useIndividualRole } from 'hooks'
import { useContext } from 'react'
import { numToDollars } from 'utils'

export function BalanceText({
  size,
  amount,
  color,
  truncate = false,
  amountSuffix,
}: {
  size?: number
  amount: number
  color?: string
  truncate?: boolean
  amountSuffix?: string
}) {
  const { isAdmin, isManager } = useIndividualRole()
  const exchangeRate = useContext(ExchangeRateContext)
  const user = useContext(UserContext)
  const org = useContext(OrgContext)
  const memberDisplayCurrency = user?.displayCurrency || org?.displayCurrency
  const isAdminOnHomeRoute =
    (isAdmin || isManager) && window.location.pathname === '/'

  const isAdminRoute = window.location.pathname.includes(ORGANIZATION)

  const useAdminDisplayCurrency = isAdminRoute || isAdminOnHomeRoute

  const displayCurrency = useAdminDisplayCurrency ? USD : memberDisplayCurrency

  const exchangeAmount = useAdminDisplayCurrency
    ? amount
    : amount * exchangeRate

  const displayAmount = numToDollars(
    exchangeAmount,
    2,
    false,
    displayCurrency,
    truncate
  )

  const displayString = amountSuffix
    ? `${displayAmount}${amountSuffix}`
    : displayAmount
  return (
    <Text color={color} size={size}>
      {displayString}
    </Text>
  )
}
