import { BUNDLE_TAG, SCRAPE_IT_TAG } from 'constants/algolia'
import {
  ProductVariant,
  ProductVariant_Provider,
  ProductVariant_SourceType,
  ProductVariant_Type,
} from 'gen/perkup/v1/product_variant_pb'
import {
  CalculatedDraftOrderLineItem,
  DraftOrderLineItemInput,
} from 'gen/shopifyapi/admingql_pb'
import { WithSelectedQuantity } from 'types'

export function convertShopifyPricetoAmount(price?: string) {
  return Math.round(Number(price) * 100)
}

export function buildShopifyLineItemsFromProductVariants(
  productVariants: WithSelectedQuantity<ProductVariant>[]
) {
  const validProductVarianst = productVariants.filter(
    pv =>
      pv.selectedQuantity > 0 && // Only include items with a quantity greater than 0
      pv.provider === ProductVariant_Provider.shopify && // Only include items that are from Shopify
      !pv.tags.includes(BUNDLE_TAG) // Only include items that are not bundled
  )

  const shopifyLineItems = validProductVarianst.map(pv => {
    const isScraped = pv.tags.includes(SCRAPE_IT_TAG)

    if (isScraped) {
      return new DraftOrderLineItemInput({
        quantity: pv.selectedQuantity,
        title: pv.productName,
        taxable: pv.taxable,
        sku: pv.sku,
        requiresShipping: pv.requiresShipping,
        customAttributes: [
          { key: 'variantId', value: pv.id },
          {
            key: 'productId',
            value: pv?.productId,
          },
          { key: 'handle', value: pv.handle },
        ],
      })
    }

    return new DraftOrderLineItemInput({
      quantity: pv.selectedQuantity,
      variantId: pv.adminGraphqlApiId,
      customAttributes: [
        {
          key: 'sourceType',
          value: ProductVariant_SourceType[pv.sourceType ?? 0],
        },
        {
          key: 'itemType',
          value: ProductVariant_Type[pv.type ?? 0],
        },
      ],
    })
  })

  return shopifyLineItems
}

/**
 *
 * @param lineItem - a single line item from a draft order
 * @returns total savings for a single line item in cents
 */
export function determineTotalSavingsForLineItem(
  lineItem: CalculatedDraftOrderLineItem
) {
  const { originalUnitPrice, discountedUnitPrice, quantity } = lineItem

  // We always work in cents
  const originalUnitAmount = Number(originalUnitPrice?.amount ?? 0) * 100 // ex. 1000
  const discountedUnitAmount = Number(discountedUnitPrice?.amount ?? 0) * 100 // ex. 800

  const totalSavingsForUnit = originalUnitAmount - discountedUnitAmount // ex. 200
  const totalSavingsForLineItem = totalSavingsForUnit * quantity // ex. 200 * 3 = 600

  // Since we've already multiplied by 100, rounding is not necessary but makes everythig else safer so why not
  return Math.round(totalSavingsForLineItem) // ex. 600 which is 6 dollars
}

/**
 *
 * @param lineItems - an array of line items from a draft order that you want to calculate the total savings for
 * @returns total savings for the entire order in cents
 */
export function determineTotalSavingsForLineItems(
  lineItems: CalculatedDraftOrderLineItem[]
) {
  return lineItems.reduce((acc, lineItem) => {
    const savings = determineTotalSavingsForLineItem(lineItem)
    return acc + savings
  }, 0)
}
