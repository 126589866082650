import api, { Flatfile } from '@flatfile/api'
import { type FlatfileRecord } from '@flatfile/hooks'
import {
  FlatfileProvider,
  Sheet,
  Space,
  useEvent,
  useFlatfile,
} from '@flatfile/react'
import { isProduction } from '@repo/constants/keys'
import { captureException } from '@sentry/react'
import { Button } from 'antd'
import { callFunction } from 'api/functionCalls'
import { FLATFILE_PUBLIC_KEY } from 'constants/keys'
import { OrgContext } from 'context'
import { OrgList } from 'gen/perkup/v1/organization_pb'
import { useDefaultOrgColors, useOrgLists } from 'hooks'
import { isDate, isString, startCase } from 'lodash-es'
import { useContext } from 'react'
import { createSpaceConfig, isEmail } from 'utils'

enum PeopleSheetKeys {
  firstName = 'firstName',
  lastName = 'lastName',
  email = 'email',
  employeeId = 'employeeId',
  dob = 'dob',
  startDate = 'start_date',
  endDate = 'end_date',
  title = 'title',
  managerId = 'managerId',
  userRole = 'userRole',
  department = 'department',
  location = 'location',
}

const createPeopleSheetConfig = (orgId: string, orgLists: OrgList[]) => {
  const orgsWithoutEmails = [
    'eCYCdSvIcuiYQtkDkoFT', // Crestline
  ]

  const emailNotRequired = isProduction
    ? orgsWithoutEmails.includes(orgId)
    : true

  const sheet: Flatfile.SheetConfig = {
    name: 'People',
    slug: 'people',
    allowAdditionalFields: true,
    metadata: {
      primaryColour: 'red',
    },
    fields: [
      {
        key: PeopleSheetKeys.firstName,
        type: 'string',
        label: 'First Name',
      },
      {
        key: PeopleSheetKeys.lastName,
        type: 'string',
        label: 'Last Name',
      },
      {
        key: PeopleSheetKeys.email,
        type: 'string',
        label: 'Email',
        constraints: emailNotRequired
          ? []
          : [
              {
                type: 'required',
              },
            ],
      },
      {
        key: PeopleSheetKeys.employeeId,
        type: 'string',
        label: 'Employee ID',
      },
      {
        key: PeopleSheetKeys.dob,
        type: 'date',
        label: 'Birthday',
      },
      {
        key: PeopleSheetKeys.startDate,
        type: 'date',
        label: 'Start date',
      },
      {
        key: PeopleSheetKeys.endDate,
        type: 'date',
        label: 'End date',
      },
      {
        key: PeopleSheetKeys.title,
        type: 'string',
        label: 'Title',
      },
      {
        key: PeopleSheetKeys.managerId,
        type: 'string',
        label: 'Manager ID',
        description: 'The employee ID for the direct manager',
      },
      {
        key: PeopleSheetKeys.userRole,
        type: 'string',
        label: 'User Role',
        description: 'Must be admin, manager, or member',
      },
      {
        key: PeopleSheetKeys.department,
        type: 'string',
        label: 'Department',
      },
      {
        key: PeopleSheetKeys.location,
        type: 'string',
        label: 'Location',
      },
    ],
  }

  orgLists.forEach(list => {
    const existingField = sheet.fields.find(field => field.key === list.id)

    if (!existingField) {
      sheet.fields.push({
        key: list.id,
        type: 'string',
        label: startCase(list.name),
      })
    }
  })

  return sheet
}

const verifyPeopleRecord = (record: FlatfileRecord) => {
  const email = record.get(PeopleSheetKeys.email)
  const cleanedEmail = email?.toString().trim().toLowerCase()

  if (cleanedEmail && isEmail(cleanedEmail)) {
    record.set(PeopleSheetKeys.email, cleanedEmail)
  } else if (email) {
    record.addError(PeopleSheetKeys.email, 'Invalid email address')
  }

  const checkName = (key: string) => {
    const name = record.get(key)
    if (isString(name) && name?.trim().length >= 2) {
      record.set(key, name?.trim())
    } else if (name) {
      record.addError(key, `${key} must be at least 2 character`)
    }
  }

  checkName(PeopleSheetKeys.firstName)
  checkName(PeopleSheetKeys.lastName)

  const checkDate = (key: string) => {
    const dateValue = record.get(key)
    const formattedDate = dateValue
      ? new Date(dateValue?.toString())
      : undefined
    if (
      formattedDate &&
      isDate(formattedDate) &&
      !Number.isNaN(formattedDate.getTime())
    ) {
      record.set(key, formattedDate.toDateString())
    } else if (dateValue) {
      record.addError(key, `Invalid ${key}, must be MM/DD/YY`)
    }
  }

  checkDate(PeopleSheetKeys.dob)
  checkDate(PeopleSheetKeys.startDate)
  checkDate(PeopleSheetKeys.endDate)

  const userRole = record.get(PeopleSheetKeys.userRole)
  if (userRole) {
    const formattedUserRole = userRole?.toString().trim().toLowerCase()
    if (['admin', 'manager', 'member'].includes(formattedUserRole)) {
      record.set(PeopleSheetKeys.userRole, formattedUserRole)
    } else {
      record.addError(
        PeopleSheetKeys.userRole,
        'Invalid user role, must be admin, manager, or member'
      )
    }
  }

  return record
}

function JobAcknowlegedEventListener({
  sheetSlug,
  onConfirm,
}: {
  sheetSlug: string | undefined
  onConfirm: () => void
}) {
  // This will close the whole portal when the user clicks that inner dialog that pops up within the portal
  useEvent(
    'job:outcome-acknowledged',
    {
      operation: `sheetSubmitAction-${sheetSlug}`,
      status: 'complete',
    },
    async () => onConfirm()
  )

  return null
}

export function PeopleSpace() {
  const { id: orgId, name: orgName, logoUrl } = useContext(OrgContext)

  const { defaultColor } = useDefaultOrgColors()

  const { orgLists, hasLoaded } = useOrgLists()

  const { open, openPortal, closePortal } = useFlatfile()

  if (!hasLoaded)
    return (
      <Button disabled loading>
        Upload people
      </Button>
    )

  const peopleSheetConfig = createPeopleSheetConfig(orgId, orgLists)

  return (
    <>
      <Button onClick={() => (open ? closePortal() : openPortal())}>
        Upload people
      </Button>

      <Space config={createSpaceConfig(orgName, defaultColor, logoUrl, true)}>
        <Sheet
          config={peopleSheetConfig}
          onRecordHook={verifyPeopleRecord}
          onSubmit={async submitInfo => {
            try {
              await api.jobs.ack(submitInfo.job.jobId, {
                info: 'Uploading people into PerkUp',
                progress: 25,
                estimatedCompletionAt: new Date(Date.now() + 1000 * 60), // 1 minute from now
              })

              const response = await callFunction('services-CSVUploader', {
                sheetId: submitInfo.sheet.sheetId,
              })

              if (!response?.uploaded) {
                throw new Error('Failed to upload people into PerkUp')
              }
            } catch (error: any) {
              console.error('Error:', error.stack)
              captureException(error, {
                contexts: {
                  submitInfo,
                },
              })
              await api.jobs.fail(submitInfo.job.jobId, {
                outcome: {
                  message: 'Failed to upload people into PerkUp',
                },
              })
            }
          }}
        />
      </Space>
      <JobAcknowlegedEventListener
        sheetSlug={peopleSheetConfig.slug}
        onConfirm={closePortal}
      />
    </>
  )
}

export function PeopleCsvUploadButton() {
  return (
    <FlatfileProvider publishableKey={FLATFILE_PUBLIC_KEY}>
      <PeopleSpace />
    </FlatfileProvider>
  )
}
