import { ListListsByOrgId } from 'api/databaseCalls'
import { OrgContext } from 'context'
import { OrgList } from 'gen/perkup/v1/organization_pb'
import { useContext, useEffect, useState } from 'react'

export function useOrgLists() {
  const { id: orgId } = useContext(OrgContext)

  const [orgLists, setOrgLists] = useState<OrgList[]>([])
  const [hasLoaded, setHasLoaded] = useState(false)

  useEffect(() => {
    ListListsByOrgId({ orgId })
      .then(setOrgLists)
      .then(() => setHasLoaded(true))
  }, [orgId])

  return { orgLists, hasLoaded }
}
