import { CloseOutlined, PlusOutlined } from '@ant-design/icons'
import { Badge, Button, InputNumber, InputNumberProps, Tooltip } from 'antd'
import {
  PerkImage,
  PerkLoader,
  SmallSavingsTag,
  VariantEstimatedShippingTime,
} from 'components'
import { warehouses } from 'constants/warehouses'
import { DraftOrderContext, WarehouseIdContext } from 'context'
import { Text } from 'evergreen-ui'
import {
  ProductVariant,
  ProductVariant_SourceType,
} from 'gen/perkup/v1/product_variant_pb'
import { useDefaultOrgColors } from 'hooks'
import useListAllProductVariantsByProductId from 'hooks/productVariants/useListAllProductVariantsByProductId'
import { groupBy } from 'lodash-es'
import { createContext, useContext } from 'react'
import { MaybeWithDesign, WithSelectedQuantity } from 'types'
import { determineTotalSavingsForLineItems, numToDollars } from 'utils'
import { buildProductVariantFromCanvasDesign } from 'utils/productVariant'

const SuperBulkProductRowCardContext = createContext<ProductVariant[]>([])

function SuperBulkInput({
  productVariant,
  onUpdateProductVariant,
  withInputPrefix = false,
}: {
  productVariant: MaybeWithDesign<WithSelectedQuantity<ProductVariant>>
  onUpdateProductVariant: (
    updatedPv: MaybeWithDesign<WithSelectedQuantity<ProductVariant>>
  ) => void
  withInputPrefix?: boolean
}) {
  const handleChangeQuantity: InputNumberProps['onChange'] = value => {
    if (typeof value === 'number') {
      if (value === 0 && !productVariant.selectedQuantity) return // We don't want to trigger the callback if pv isn't even selected
      Object.assign(productVariant, { selectedQuantity: value })
      onUpdateProductVariant(productVariant)
    }
  }

  const prefix = withInputPrefix
    ? productVariant.variantName.split('/')[1]?.trim() ?? // Try and split the variant name by `/` and use the second part as the prefix. If it doesn't work, use the whole variant name. We can assume that the first part is the option name.
      productVariant.variantName
    : undefined

  const needsTooltip = (prefix?.length ?? 0) > 4

  return (
    <article className="group/input relative">
      <InputNumber
        disabled={!productVariant.isAvailable}
        className="w-20"
        min={0}
        value={productVariant.selectedQuantity ?? 0}
        onChange={handleChangeQuantity}
        prefix={<span className="max-w-8 truncate">{prefix}</span>}
      />
      {needsTooltip && (
        <div className="absolute pointer-events-none -top-7 text-xs min-w-max right-1/2 translate-x-1/2 bg-[#000] bg-opacity-80 text-white font-medium px-2 py-1 rounded-lg opacity-0 group-hover/input:opacity-100 z-50 transition-opacity">
          {prefix}
        </div>
      )}
    </article>
  )
}

function SuperBulkInputs({
  option,
  selectedProductVariants,
  onUpdateProductVariant,
  discountPercentage,
}: {
  option?: string
  selectedProductVariants: MaybeWithDesign<
    WithSelectedQuantity<ProductVariant>
  >[]
  onUpdateProductVariant: (
    updatedPv: MaybeWithDesign<WithSelectedQuantity<ProductVariant>>
  ) => void
  discountPercentage: number
}) {
  const warehouseId = useContext(WarehouseIdContext)
  // Only show the warehouse if the pv is prepaid
  const showWarehouse =
    selectedProductVariants[0] &&
    selectedProductVariants[0]?.sourceType ===
      ProductVariant_SourceType.fullPrepaid

  const matchingWarehouse = warehouses.find(wh => wh?.id === warehouseId)

  const allProductVariants = useContext(SuperBulkProductRowCardContext)

  const appropriateProductVariantsForOption = option
    ? allProductVariants.filter(
        pv => pv.variantName.split('/')[0]?.trim() === option
      )
    : allProductVariants

  const appropriateProductVariantsWithTheirSelectedQuantity =
    appropriateProductVariantsForOption.map(pv => {
      const selectedQuantity = selectedProductVariants.find(
        pvs => pvs.id === pv.id
      )?.selectedQuantity

      Object.assign(pv, { selectedQuantity })

      return pv as MaybeWithDesign<WithSelectedQuantity<ProductVariant>>
    })

  const totalQuantity =
    appropriateProductVariantsWithTheirSelectedQuantity.reduce(
      (acc, { selectedQuantity }) => acc + (selectedQuantity || 0),
      0
    )

  const unitAmountForThisRow = Number(
    appropriateProductVariantsForOption[0]?.amount ?? 0 // We can assume that all product variants in this row have the same amount
  )

  const unitAmountWithDiscount =
    unitAmountForThisRow * (1 - discountPercentage / 100)

  const totalToDisplay =
    discountPercentage > 0
      ? numToDollars(unitAmountWithDiscount * totalQuantity, 2)
      : numToDollars(unitAmountForThisRow * totalQuantity, 2)

  return (
    <div className="flex flex-col gap-2">
      <section className="flex justify-between items-center text-sm flex-wrap">
        <div className="flex gap-4 items-center text-muted-foreground">
          <p>
            <span
              data-discounted={discountPercentage > 0}
              className="mr-1 data-[discounted=true]:line-through data-[discounted=true]:text-xs"
            >
              {numToDollars(unitAmountForThisRow, 2)}
            </span>
            {discountPercentage > 0 && numToDollars(unitAmountWithDiscount, 2)}
          </p>

          <p>{option}</p>
        </div>

        <div className="flex gap-8 items-center">
          {showWarehouse && matchingWarehouse && (
            <p>{matchingWarehouse.name}</p>
          )}
          <p>{totalToDisplay}</p>
        </div>
      </section>

      <section className="flex flex-wrap gap-2">
        {appropriateProductVariantsWithTheirSelectedQuantity.map(pv => (
          <SuperBulkInput
            key={pv.id}
            productVariant={pv}
            onUpdateProductVariant={onUpdateProductVariant}
            withInputPrefix={
              appropriateProductVariantsWithTheirSelectedQuantity.length > 1 // Doesn't make sense to show the prefix if there's only one product variant for this option
            }
          />
        ))}
      </section>
    </div>
  )
}

export function SuperBulkProductRowCard({
  selectedProductVariants,
  onUpdate,
  onAddMoreClick,
  onRemoveClick,
}: {
  selectedProductVariants: MaybeWithDesign<
    WithSelectedQuantity<ProductVariant>
  >[]
  onUpdate: (
    updatedPvs: MaybeWithDesign<WithSelectedQuantity<ProductVariant>>[]
  ) => void
  onAddMoreClick: (
    productVariant: MaybeWithDesign<WithSelectedQuantity<ProductVariant>>
  ) => void
  onRemoveClick: () => void
}) {
  const { draftOrderCalculation } = useContext(DraftOrderContext)

  const defaultVariant = selectedProductVariants[0]

  const { productId, canvasDesign } = defaultVariant

  const { defaultColor } = useDefaultOrgColors()

  const { allProductVariants: allRawProductVariants, isLoadingInitial } =
    useListAllProductVariantsByProductId({
      productId,
    })

  if (isLoadingInitial) return <PerkLoader />

  const allProductVariants = canvasDesign
    ? allRawProductVariants.map(pv => {
        return buildProductVariantFromCanvasDesign({
          productVariant: pv,
          canvasDesign,
        })
      })
    : allRawProductVariants

  const { productName, productImages, imageUrl, productImageUrl } =
    defaultVariant

  const totalQuantity = selectedProductVariants.reduce(
    (acc, { selectedQuantity }) => acc + (selectedQuantity || 0),
    0
  )

  const productOnlyHasOneOption =
    Object.keys(defaultVariant.options || {}).length === 1
  const onlyOptionIsSize =
    productOnlyHasOneOption &&
    Object.keys(defaultVariant.options || {})[0] === 'size'

  const handleUpdateProductVariant = (
    updatedPv: MaybeWithDesign<WithSelectedQuantity<ProductVariant>>
  ) => {
    const existingPvIndex = selectedProductVariants.findIndex(
      pv => pv.id === updatedPv.id
    )

    const newDesiredQuantity = updatedPv.selectedQuantity

    if (existingPvIndex === -1 && newDesiredQuantity === 0) return

    // User is adding a new product variant using the input
    if (existingPvIndex === -1 && newDesiredQuantity > 0) {
      onUpdate([...selectedProductVariants, updatedPv])
      return
    }

    // User is setting the quantity to 0, remove the product variant
    if (newDesiredQuantity === 0) {
      const updatedProductVariants = selectedProductVariants.filter(
        pv => pv.id !== updatedPv.id
      )
      onUpdate(updatedProductVariants)
      return
    }

    // User is updating the quantity of an existing product variant
    const updatedProductVariants = selectedProductVariants.map((pv, index) =>
      index === existingPvIndex ? updatedPv : pv
    )

    onUpdate(updatedProductVariants)
  }

  const handleAddMore = () => {
    onAddMoreClick(defaultVariant)
  }

  const lineItemsForThisProduct =
    draftOrderCalculation?.lineItems.filter(
      li => selectedProductVariants.some(pv => li.variant?.id.includes(pv.id)) // @todo: It would be way easier if we just stored the product id in the line item
    ) ?? []

  const totalSavingsForThisProduct = determineTotalSavingsForLineItems(
    lineItemsForThisProduct
  )

  const discountPercentageForThisProduct =
    lineItemsForThisProduct?.[0]?.appliedDiscount?.value ?? 0

  return (
    <SuperBulkProductRowCardContext.Provider value={allProductVariants}>
      <article className="relative group flex gap-4 p-4 rounded-lg">
        {/** Thumbnail */}
        <Badge
          count={totalQuantity}
          color={defaultColor}
          offset={[-4, 0]}
          overflowCount={1000}
        >
          <PerkImage
            preview
            src={productImageUrl || imageUrl || productImages?.[0]?.src}
            alt={`Small thumbnail cart image for ${productName}`}
            sizes="72px"
            width={72}
            style={{
              aspectRatio: '1/1',
              objectFit: 'cover',
              width: 72,
              height: 72,
              borderRadius: 8,
            }}
          />
        </Badge>

        <section className="flex flex-col flex-1 gap-2">
          <div className="flex justify-between items-center">
            <Text>{productName}</Text>

            <SmallSavingsTag
              savings={totalSavingsForThisProduct}
              discount={discountPercentageForThisProduct}
            />
          </div>
          {onlyOptionIsSize ? (
            <SuperBulkInputs
              key={`single-layer-product-${defaultVariant.productId}`}
              selectedProductVariants={selectedProductVariants}
              onUpdateProductVariant={handleUpdateProductVariant}
              discountPercentage={discountPercentageForThisProduct}
            />
          ) : (
            <>
              {Object.entries(
                groupBy(selectedProductVariants, pv =>
                  pv.variantName.split('/')[0]?.trim()
                )
              ).map(([option, pvs]) => (
                <SuperBulkInputs
                  key={option}
                  option={option}
                  selectedProductVariants={pvs}
                  onUpdateProductVariant={handleUpdateProductVariant}
                  discountPercentage={discountPercentageForThisProduct}
                />
              ))}
            </>
          )}

          <Button
            className="w-fit px-0"
            type="link"
            onClick={handleAddMore}
            icon={<PlusOutlined />}
          >
            Add more
          </Button>

          <VariantEstimatedShippingTime productVariant={defaultVariant} />
        </section>

        <Tooltip title="Remove item">
          <Button
            className="absolute right-2 top-2 rounded-lg transition-opacity opacity-0 group-hover:opacity-100"
            size="small"
            icon={<CloseOutlined />}
            onClick={onRemoveClick}
          />
        </Tooltip>
      </article>
    </SuperBulkProductRowCardContext.Provider>
  )
}
