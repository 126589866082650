import { ListenToCustomActions } from 'api/databaseCalls'
import { INDIVIDUAL_ID } from 'constants/params'
import { ActionsContext, RulesContext } from 'context'
import { Dayjs } from 'dayjs'
import { Pane, SideSheet } from 'evergreen-ui'
import { Individual } from 'gen/perkup/v1/individual_pb'
import { Action, CustomAction } from 'gen/perkup/v1/rules_pb'
import { useIndividualById } from 'hooks/individuals/useIndividualById'
import { intersection } from 'lodash-es'
import { useContext, useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { RuleType, RuleTypes } from 'types/rules'
import { getUTCIndividualDate } from 'utils'
import { EventActions } from './EventActions'
import { EventDate } from './EventDate'
import { EventProfileCard } from './EventProfileCard'

export interface IEventInfo {
  eventIndividual: Individual
  yearsDiff: number
  date: Dayjs
  isBirthday: boolean
}

export function EventDrawer({
  ruleType,
  eventInfo,
  setEventInfo,
}: {
  ruleType: RuleType
  eventInfo: IEventInfo | undefined
  setEventInfo: (eventInfo: IEventInfo | undefined) => void
}) {
  const rules = useContext(RulesContext)
  const actionsMap = useContext(ActionsContext)
  const [searchParams, setSearchParams] = useSearchParams()
  const individualId = eventInfo?.eventIndividual.id
    ? eventInfo?.eventIndividual.id
    : ''
  const yearsDiff = eventInfo?.yearsDiff

  const isBirthday = ruleType === RuleTypes.birthdays

  const [actions, setActions] = useState<Array<Action | CustomAction>>([])

  const [individualsCustomActions, setIndividualsCustomActions] = useState<
    Map<string, CustomAction>
  >(new Map())

  const { individual } = useIndividualById({ individualId })

  const individualEventDate = isBirthday
    ? individual?.dob?.toDate()
    : individual?.startDate?.toDate()

  // useEffect to listen to custom actions. Creates a map of actionId to customAction
  useEffect(() => {
    if (!individualId) return undefined

    return ListenToCustomActions({
      individualId,
      cb: customActions => {
        const mapIndividualsCustomActions: Map<string, CustomAction> = new Map()
        customActions.forEach(customAction => {
          mapIndividualsCustomActions.set(customAction.actionId, customAction)
        })
        setIndividualsCustomActions(mapIndividualsCustomActions)
      },
    })
  }, [individualId])

  // Based on customAction map, and the map we received from props, build a final array of actions
  useEffect(() => {
    const actionsArray: Array<Action | CustomAction> = [] // Final action array

    // Traverse the map from props
    actionsMap.forEach(value => {
      let actionToRender: Action | CustomAction = value // Action to be added into the final array
      const actionId = value?.id
      const ruleForAction = rules.find(rule => rule.id === value.ruleId)
      const ruleLabels = Object.values(ruleForAction?.labels || {})
      const individualLabels =
        individual && individual.labels && Object.values(individual.labels)
      // If there are labels, determine if user matches labels, if not do not render
      if (
        ruleLabels.length &&
        individualLabels &&
        intersection(ruleLabels, individualLabels).length === 0
      ) {
        return
      }

      // If we're on the birthday tab, all our rules are every year, so no need to filter
      if (isBirthday) {
        // Determine if we need to override the current action with a custom action
        if (individualsCustomActions.has(actionId)) {
          actionToRender = individualsCustomActions.get(actionId)!
          actionsArray.push(actionToRender)
          return
        }
      }

      // If we're on the anniversary tab, filter the list of rules depending on individual's celebration year

      // Determine if we need to override the current action with a custom action
      const isAnniversaryYear =
        Number(yearsDiff) && ruleForAction?.year === Number(yearsDiff)
      const isYearly = ruleForAction?.year === 0
      const isNewHire = ruleForAction?.year === -1

      const renderAction = isAnniversaryYear || isYearly || isNewHire
      if (renderAction) {
        if (individualsCustomActions.has(actionId)) {
          actionToRender = individualsCustomActions.get(actionId)!
        }

        actionsArray.push(actionToRender)
      }
    })

    if (actionsArray) {
      setActions(actionsArray)
    }
  }, [
    actionsMap,
    individual,
    individualsCustomActions,
    isBirthday,
    rules,
    yearsDiff,
  ])

  if (!eventInfo) return null

  return (
    <SideSheet
      isShown={!!eventInfo}
      onCloseComplete={() => {
        setEventInfo(undefined)
        searchParams.delete(INDIVIDUAL_ID)
        setSearchParams(searchParams)
      }}
    >
      <Pane paddingY={32} paddingX={24}>
        {/** Render Header for Event */}
        <Pane
          display="flex"
          gap={16}
          justifyContent="space-between"
          marginBottom={32}
        >
          <EventProfileCard eventInfo={eventInfo} />
          <EventDate
            eventInfo={eventInfo}
            eventDate={getUTCIndividualDate(individualEventDate)}
          />
        </Pane>

        {/** Render content for Event, and side menu of rules */}
        <EventActions
          actions={actions}
          eventInfo={eventInfo}
          ruleType={ruleType}
        />
      </Pane>
    </SideSheet>
  )
}
