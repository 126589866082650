import { Pane } from 'evergreen-ui'
import { ShortNavigation } from './ShortNavigation'

export const withShortNav = (BaseComponent: any) =>
  function HOC(props: any) {
    return (
      <Pane>
        <ShortNavigation showNavName />
        <Pane paddingTop={96}>
          <BaseComponent {...props} />
        </Pane>
      </Pane>
    )
  }
