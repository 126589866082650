import {
  CarryOutOutlined,
  CloseOutlined,
  PlusOutlined,
  TruckOutlined,
} from '@ant-design/icons'
import { Timestamp } from '@bufbuild/protobuf'
import {
  Alert,
  AlertProps,
  Avatar,
  Button,
  Checkbox,
  Drawer,
  Flex,
  Modal,
  Radio,
  Select,
  message,
} from 'antd'
import { listProductVariantsByProductId } from 'api/databaseCalls'
import {
  AccountBalanceForm,
  AddressForm,
  BigSavingsTag,
  CompanyAccountPayment,
  CreditCardsLogoGroup,
  DraftOrderContextBuilder,
  InternalMemoForm,
  PerkEmpty,
  PerkLink,
  PerkLoader,
  PerkScrollbars,
  PriceBreakdown,
} from 'components'
import { EventDetailsForm } from 'components/Forms/event-details-form'
import { DEFAULT_ROUTES } from 'constants/routes'
import {
  DraftOrderContext,
  IndividualContext,
  OrgContext,
  UserContext,
  WarehouseIdContext,
} from 'context'
import { Heading, Pane, Text } from 'evergreen-ui'
import {
  AlgoliaBrowseProducts,
  ProductDetails,
  ProductRetails,
  SuperLines,
} from 'features'
import { Supplier } from 'gen/canvas/designs_pb'
import { Account } from 'gen/perkup/v1/account_pb'
import { Individual } from 'gen/perkup/v1/individual_pb'
import { Organization } from 'gen/perkup/v1/organization_pb'
import { ProductCollection_Permission } from 'gen/perkup/v1/product_collections_pb'
import { ProductVariant } from 'gen/perkup/v1/product_variant_pb'
import { Item } from 'gen/perkup/v1/program_pb'
import { RootUser, ShippingAddress } from 'gen/perkup/v1/root_user_pb'
import {
  VenueEvent_Info,
  VenueEvent_Info_DeliveryWindow,
  VenueEvent_Info_ShippingLabel,
} from 'gen/perkup/v1/venue_event_pb'
import { useAccount, useDefaultCompanyAccount } from 'hooks'
import { isEmpty, round, sum } from 'lodash-es'
import {
  PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import {
  Controller,
  FormProvider,
  useForm,
  useFormContext,
  useFormState,
  useWatch,
} from 'react-hook-form'
import { useLocation, useNavigate } from 'react-router'
import {
  CalculateShopifyDraftOrder,
  createCanvasDesign,
  placeProductVariantsOrder,
} from 'services'
import {
  MaybeWithDesign,
  OrderProductsLocationState,
  ProductRetailsData,
  SelectedVariantOption,
  WithSelectedQuantity,
} from 'types'
import { OrderProductsFormType } from 'types/react-hook-form'
import {
  buildShopifyLineItemsFromProductVariants,
  convertMockupIdsToUrls,
  filterWarehousesByProductVariants,
  getCountryNameFromIso2,
  numToDollars,
} from 'utils'
import { convertCanvasDesignToProductRetailsFormData } from 'utils/canvas-design'
import {
  buildFakeCustomizedProductVariant,
  filterProductVariantsByWarehouseId,
  invalidShippingCountryVariants,
  isCustomizable,
  sumProductVariants,
} from 'utils/productVariant'

/**
 * Handles the submission of the order form, exists at top of the file to encapsulate the logic
 * @param data Data from the form
 * @param basicData Data from context, ie user, org, individual
 * @returns The direct mail program id created for the order
 */
const handleSubmitOrder = async (
  data: OrderProductsFormType,
  basicData: {
    user: RootUser
    org: Organization
    individual: Individual
  }
): Promise<{
  status: 'success' | 'error'
  message: string
  addedProgramId?: string
}> => {
  const {
    event,
    internalMemo,
    selectedAccount,
    address: shippingAddress,
    orderType,
    requestedReturnLabel,
    productVariants,
  } = data
  const { user, org, individual } = basicData

  // @todo this should be checking before the submit
  if (!shippingAddress) {
    return { status: 'error', message: 'Shipping address required' }
  }

  const lineItems = buildShopifyLineItemsFromProductVariants(productVariants)

  // @todo this should be checked before the submit
  if (lineItems.length === 0) {
    return { status: 'error', message: 'No valid items to place order' }
  }

  // Its good that we're calculating the order before placing it again, but in the context hook we may be missing the shipping address.
  // However here, we have the shipping address.
  const calculateOrderResponse = await CalculateShopifyDraftOrder({
    lineItems,
    shippingAddress,
  })

  const draftOrderCalculation = calculateOrderResponse?.calculatedDraftOrder

  const orderTotal = draftOrderCalculation?.totalPrice
    ? round(Number(draftOrderCalculation?.totalPrice) * 100, 2)
    : sum(
        productVariants.map(pv => pv.selectedQuantity * Number(pv.amount || 0))
      )

  const orderPlacementResponse = await placeProductVariantsOrder({
    shippingAddress,
    items: productVariants.map(
      pv =>
        new Item({
          productId: pv.productId,
          productVariantId: pv.id,
          quantity: pv.selectedQuantity,
          provider: pv.provider,
          canvasDesign: pv.canvasDesign,
        })
    ),
    user,
    org,
    individual,
    account: selectedAccount,
    internalMemo,
    draftOrderCalculation,
    orderTotal,
    venueEventInfo:
      orderType === 'event' && event
        ? new VenueEvent_Info({
            eventName: event.name,
            venueDetails: event.venueInfo,
            deliveryWindow: new VenueEvent_Info_DeliveryWindow({
              from: event?.deliveryWindow?.[0]
                ? Timestamp.fromDate(event?.deliveryWindow[0].toDate())
                : undefined,
              to: event?.deliveryWindow?.[1]
                ? Timestamp.fromDate(event?.deliveryWindow[1].toDate())
                : undefined,
            }),
            shippingLabel: new VenueEvent_Info_ShippingLabel({
              url: event?.shippingLabel?.url,
            }),
            requestedReturnLabel,
          })
        : undefined,
  })

  if (orderPlacementResponse.status === 'error') {
    return orderPlacementResponse
  }

  const { addedProgramId } = orderPlacementResponse.data

  if (!addedProgramId) return { status: 'error', message: 'No program id' }

  return {
    status: 'success',
    message: orderPlacementResponse.message,
    addedProgramId,
  }
}

/**
 * We need to use this hook to trigger the order placement once the funded account balance has been updated
 */
const useTopUpInvoicePayment = (
  amount: number,
  formButtonRef: React.RefObject<HTMLButtonElement>
) => {
  const individual = useContext(IndividualContext)
  const stripePaymentSucceededRef = useRef(false)

  const { account } = useAccount({
    accountId: individual?.defaultCompanyAccountId,
  })

  useEffect(() => {
    if (
      stripePaymentSucceededRef.current &&
      account &&
      account.balance >= amount
    ) {
      if (formButtonRef.current) {
        formButtonRef.current.click()
        stripePaymentSucceededRef.current = false
      }
    }
  }, [account, amount, formButtonRef])

  return { stripePaymentSucceededRef }
}

// Todo: Need to clean this up some more
function Section4() {
  const { draftOrderCalculation } = useContext(DraftOrderContext)

  const formButtonRef = useRef<HTMLButtonElement>(null)
  const { control, setValue } = useFormContext<OrderProductsFormType>()

  // @todo come back to this
  const [a, b, c, d] = useWatch<OrderProductsFormType>({
    name: ['productVariants', 'address', 'paymentMethod', 'selectedAccount'],
  })

  const selectedProductVariants = useMemo(() => {
    return (a ?? []) as MaybeWithDesign<WithSelectedQuantity<ProductVariant>>[]
  }, [a])

  const shippingAddress = b as ShippingAddress
  const paymentMethod = c as 'account' | 'creditCard'
  const selectedAccount = d as Account | undefined

  const disableCCPayment =
    isEmpty(selectedProductVariants) || !shippingAddress || !selectedAccount

  const { isSubmitting } = useFormState()
  const {
    account: defaultCompanyAccount,
    hasLoaded: defaultCompanyAccountLoaded,
  } = useDefaultCompanyAccount()

  const totalCost = draftOrderCalculation?.totalPrice
    ? round(Number(draftOrderCalculation?.totalPrice) * 100, 2)
    : sumProductVariants(selectedProductVariants)

  const { stripePaymentSucceededRef } = useTopUpInvoicePayment(
    totalCost,
    formButtonRef
  )

  const insufficientFunds =
    paymentMethod === 'account' &&
    !!selectedAccount &&
    !!totalCost &&
    !isEmpty(selectedProductVariants) &&
    totalCost > (selectedAccount?.balance ?? 0)

  const accountCTADisabled =
    !shippingAddress ||
    !selectedAccount ||
    insufficientFunds ||
    isEmpty(selectedProductVariants)

  const invalidCountryVariants = invalidShippingCountryVariants({
    productVariants: selectedProductVariants,
    shippingAddress,
  })

  // Todo: Let's leverage form error state to determine whether or not we should render this
  const getProductAlertProps = (): AlertProps | undefined => {
    if (!shippingAddress) return { message: 'Shipping address required' }
    if (!isEmpty(invalidCountryVariants))
      return {
        message: `The following products are not available to ship to ${
          getCountryNameFromIso2(shippingAddress?.country) || 'this address'
        }:`,
        description: invalidCountryVariants
          .map(pv => pv.productName)
          .join(', '),
      }
    if (paymentMethod === 'account' && !selectedAccount && !!totalCost)
      return {
        message: 'Active budget required',
        description: (
          <Text>
            To place an order,{' '}
            <PerkLink to={DEFAULT_ROUTES.ORGANIZATION.ACCOUNTS.ROOT}>
              create a budget and add funds.
            </PerkLink>
          </Text>
        ),
      }

    if (insufficientFunds) {
      return {
        message: 'Insufficient funds',
        description: (
          <Text>
            The selected account does not have enough funds to place this order.
            Please select a different account or{' '}
            <PerkLink
              to={`${DEFAULT_ROUTES.ORGANIZATION.ACCOUNTS.ROOT}/${selectedAccount.id}`}
            >
              add funds
            </PerkLink>
          </Text>
        ),
      }
    }

    return undefined
  }

  if (!defaultCompanyAccountLoaded) return <PerkLoader />
  if (!defaultCompanyAccount)
    return <PerkEmpty header="No default company account found for user" />

  return (
    <div className="sticky top-6 flex flex-col gap-3 w-[520px] self-start">
      <div className="shadow-lg p-4 rounded-lg w-full">
        <div className="flex flex-col gap-4 w-full">
          <Heading size={600}>Total</Heading>
          <Heading size={700}>{numToDollars(totalCost, 2)}</Heading>

          <BigSavingsTag />

          <PriceBreakdown
            productPrice={totalCost}
            shippingCost={0}
            totalCost={totalCost}
            totalLabel="Total:"
            estimatedTax={0}
            perkUpFee={0}
            hideTaxAndPerkupFee
          />

          <div className="flex flex-col gap-4">
            <Heading size={600}>Payment method</Heading>

            <Controller
              control={control}
              name="paymentMethod"
              render={({ field: { onChange, value } }) => (
                <Radio.Group value={value}>
                  <div className="flex flex-col gap-2">
                    <Flex
                      className="flex flex-1 gap-2 rounded-md p-4 cursor-pointer border border-muted"
                      onClick={() => onChange('account')}
                    >
                      <Radio value="account" />

                      <Controller
                        control={control}
                        name="selectedAccount"
                        render={({ field: { onChange, value } }) => (
                          <AccountBalanceForm
                            selectedAccount={value}
                            setSelectedAccount={onChange}
                          />
                        )}
                      />
                    </Flex>
                    <Flex
                      className="flex flex-1 rounded-md p-4 cursor-pointer border border-muted"
                      onClick={() => {
                        onChange('creditCard')
                        setValue('selectedAccount', defaultCompanyAccount)
                      }}
                    >
                      <div className="flex flex-1 flex-col gap-2">
                        <div className="flex flex-1 justify-between align-middle">
                          <div className="flex gap-2">
                            <Radio value="creditCard" />
                            <Text>Credit card</Text>
                          </div>
                          <CreditCardsLogoGroup />
                        </div>

                        {paymentMethod === 'creditCard' && totalCost > 0 && (
                          <CompanyAccountPayment
                            amount={totalCost}
                            accountId={defaultCompanyAccount?.id}
                            onAfterSubmit={() => {
                              message.success('Payment successful')
                              stripePaymentSucceededRef.current = true
                            }}
                            disabled={disableCCPayment}
                          />
                        )}
                      </div>
                    </Flex>
                  </div>
                </Radio.Group>
              )}
            />

            <Button
              type="primary"
              htmlType="submit"
              disabled={accountCTADisabled}
              loading={isSubmitting}
              ref={formButtonRef}
              style={{
                display: paymentMethod === 'creditCard' ? 'none' : undefined,
              }}
            >
              Place order
            </Button>
          </div>
        </div>
      </div>

      {getProductAlertProps() && (
        <Alert
          showIcon
          type="warning"
          {...getProductAlertProps()}
          className="w-full"
        />
      )}
    </div>
  )
}

function Section3({
  onAddProductButtonClick,
  onAddMoreClick,
}: {
  onAddProductButtonClick: () => void
  onAddMoreClick: (
    productVariant: MaybeWithDesign<WithSelectedQuantity<ProductVariant>>
  ) => void
}) {
  const { warehouseIds } = useContext(OrgContext)
  const { control, setValue } = useFormContext<OrderProductsFormType>()

  const [a, b] = useWatch({
    name: ['orderType', 'productVariants'],
  })
  const orderType: OrderProductsFormType['orderType'] = a
  const productVariants: MaybeWithDesign<
    WithSelectedQuantity<ProductVariant>
  >[] = b

  const orgWarehouses = filterWarehousesByProductVariants(
    productVariants,
    warehouseIds
  )

  return (
    <div className="flex flex-col gap-4 flex-1">
      <div className="flex gap-4 align-middle">
        <Avatar className="bg-muted">
          <Text>2</Text>
        </Avatar>
        <Heading size={500} display="flex" alignItems="center">
          Select products
        </Heading>
      </div>

      <div className="flex gap-4">
        {orderType === 'event' && !isEmpty(orgWarehouses) && (
          <Controller
            control={control}
            name="warehouseId"
            render={({ field }) => (
              <Select
                {...field}
                allowClear
                onChange={value => {
                  field.onChange(value)
                  // Filter the prepaid product variants by the selected warehouse
                  setValue(
                    'productVariants',
                    filterProductVariantsByWarehouseId({
                      productVariants,
                      warehouseId: value,
                    })
                  )
                }}
                placeholder="Select Warehouse"
                options={orgWarehouses.map(warehouse => ({
                  label: warehouse.name,
                  value: warehouse.id,
                }))}
                popupMatchSelectWidth={false}
                className="min-w-60"
              />
            )}
          />
        )}
        <Button
          icon={<PlusOutlined />}
          style={{ width: 'fit-content' }}
          onClick={onAddProductButtonClick}
        >
          Add product
        </Button>
      </div>

      <Controller
        name="productVariants"
        control={control}
        render={({ field: { onChange, value } }) => (
          <SuperLines
            productVariants={value}
            onChange={onChange}
            onAddMoreClick={onAddMoreClick}
          />
        )}
      />
    </div>
  )
}

function Section2() {
  const { control } = useFormContext<OrderProductsFormType>()

  const orderType = useWatch<OrderProductsFormType>({ name: 'orderType' })

  return (
    <>
      {orderType === 'event' && <EventDetailsForm />}

      <Controller
        control={control}
        name="address"
        render={({ field: { onChange } }) => (
          <AddressForm
            onAddressChange={address => {
              if (address.complete) {
                onChange(
                  new ShippingAddress({
                    phoneNumber: address.value.phone,
                    line1: address.value.address.line1,
                    line2: address.value.address.line2 || undefined,
                    city: address.value.address.city,
                    state: address.value.address.state,
                    postalCode: address.value.address.postal_code,
                    country: address.value.address.country,
                    name: address.value.name,
                  })
                )
              }
            }}
          />
        )}
      />

      <Controller
        control={control}
        name="internalMemo"
        render={({ field: { onChange } }) => (
          <InternalMemoForm onMemoChange={onChange} />
        )}
      />

      {orderType === 'event' && (
        <Controller
          name="requestedReturnLabel"
          control={control}
          render={({ field: { onChange, value } }) => (
            <div className="flex gap-2 self-end">
              <Checkbox checked={value} onChange={onChange} />
              <Text>Request return label</Text>
            </div>
          )}
        />
      )}
    </>
  )
}

function Section1() {
  const { control, setValue } = useFormContext<OrderProductsFormType>()

  return (
    <>
      <div className="flex gap-4 align-middle">
        <Avatar className="bg-muted">
          <Text>1</Text>
        </Avatar>
        <Heading size={500} display="flex" alignItems="center">
          Order option & details
        </Heading>
      </div>

      {/** SECTION 1 */}
      <Controller
        control={control}
        name="orderType"
        render={({ field: { onChange, value } }) => (
          <Radio.Group value={value}>
            <div className="flex gap-4">
              <Flex
                className="flex flex-1 justify-between gap-2 rounded-md p-4 cursor-pointer border border-muted"
                onClick={() => {
                  onChange('directMail')
                  setValue('warehouseId', undefined) // Reset warehouse selection when switching to direct mail
                }}
              >
                <div className="flex gap-2">
                  <TruckOutlined style={{ fontSize: 24 }} />
                  <Text>Direct mail</Text>
                </div>
                <Radio value="directMail" />
              </Flex>

              <Flex
                className="flex flex-1 justify-between gap-2 rounded-md p-4 cursor-pointer border border-muted"
                onClick={() => onChange('event')}
              >
                <div className="flex gap-2">
                  <CarryOutOutlined style={{ fontSize: 24 }} />
                  <Text>Event send</Text>
                </div>
                <Radio value="event" />
              </Flex>
            </div>
          </Radio.Group>
        )}
      />
    </>
  )
}

function DraftOrderContextProvider({ children }: PropsWithChildren) {
  const [a, b] = useWatch<OrderProductsFormType>({
    name: ['productVariants', 'address'],
  })

  const productVariants = a as MaybeWithDesign<
    WithSelectedQuantity<ProductVariant>
  >[]
  const shippingAddress = b as ShippingAddress

  return (
    <DraftOrderContextBuilder
      productVariants={productVariants}
      shippingAddress={shippingAddress}
    >
      {children}
    </DraftOrderContextBuilder>
  )
}

function WarehouseIdContextProvider({ children }: PropsWithChildren) {
  const wid = useWatch<OrderProductsFormType>({
    name: 'warehouseId',
  })

  const warehouseId = wid as string | undefined

  return (
    <WarehouseIdContext.Provider value={warehouseId}>
      {children}
    </WarehouseIdContext.Provider>
  )
}

export function OrderSwagPage() {
  const org = useContext(OrgContext)
  const user = useContext(UserContext)
  const individual = useContext(IndividualContext)

  const orgId = org?.id
  const userId = user?.id
  const individualId = individual?.id

  const navigate = useNavigate()
  const location = useLocation()
  const { state } = location as OrderProductsLocationState
  const formRef = useRef<HTMLFormElement>(null)

  const [messageApi, contextHolder] = message.useMessage()

  const methods = useForm<OrderProductsFormType>({
    defaultValues: {
      productVariants: state?.productVariants ?? [],
      orderType: 'directMail',
      paymentMethod: 'account',
    },
    shouldUnregister: false,
  })
  const { handleSubmit, setValue, getValues } = methods

  const [showProductsDrawer, setShowProductsDrawer] = useState(false)
  const [productVariantToShow, setProductVariantToShow] =
    useState<MaybeWithDesign<ProductVariant>>()
  const [isAddingToOrder, setIsAddingToOrder] = useState(false)

  const handleAddUnDesignedProductVariants = async (
    productId: string,
    option: SelectedVariantOption | undefined
  ) => {
    setIsAddingToOrder(true)
    const allProductVariants = await listProductVariantsByProductId({
      productId,
      orgId,
    })

    // Option might be undefined if the product has no color options, in that case, just add everything to the order.
    // But if they did select an option, narrow down the product variants to only those that match the selected option.
    const appropriateProductVariantsForOption = option
      ? allProductVariants.filter(
          pv =>
            pv.variantName.split('/')[0]?.trim() === Object.values(option)[0]
        )
      : allProductVariants

    const newPvsWithQuantity: MaybeWithDesign<
      WithSelectedQuantity<ProductVariant>
    >[] = appropriateProductVariantsForOption.map(pv => {
      if (pv.isAvailable) {
        Object.assign(pv, { selectedQuantity: 1 })
      }
      return pv as MaybeWithDesign<WithSelectedQuantity<ProductVariant>>
    })

    const currentProductVariants = getValues('productVariants') || []

    setValue('productVariants', [
      ...currentProductVariants,
      ...newPvsWithQuantity,
    ])

    setShowProductsDrawer(false)
    setProductVariantToShow(undefined)
    setIsAddingToOrder(false)

    messageApi.success('Product added to order')
  }

  const handleAddDesignedProductVariants = async (data: ProductRetailsData) => {
    const {
      formData: {
        selectedProductVariant,
        selectedCustomerCanvasDesignId,
        selectedCustomerCanvasVariantId,
        selectedCustomerCanvasMockupIds,
        selectedCustomerCanvasOption,
        selectedVariables,
      },
    } = data

    setIsAddingToOrder(true)

    const mockupUrls = convertMockupIdsToUrls(
      selectedCustomerCanvasMockupIds,
      selectedCustomerCanvasDesignId,
      selectedVariables
    )

    const newDesign = await createCanvasDesign({
      orgId,
      individualId,
      userId,
      sku: selectedProductVariant?.sku,
      options: [selectedCustomerCanvasOption],
      customerCanvasVariantId: selectedCustomerCanvasVariantId,
      publicDesignId: selectedCustomerCanvasDesignId,
      mockupUrls,
      variables: selectedVariables,
      supplier: Supplier.printful,
      productId: selectedProductVariant?.productId,
    })

    if (!newDesign) {
      messageApi.error('Failed to create design')
      setIsAddingToOrder(false)
      return
    }

    const allProductVariants = await listProductVariantsByProductId({
      productId: selectedProductVariant.productId,
      orgId,
    })

    const option = selectedCustomerCanvasOption.values[0]

    const appropriateProductVariantsForOption = allProductVariants.filter(
      pv => pv.variantName.split('/')[0]?.trim() === option
    )

    const allDesignedProductVariantsToAdd: MaybeWithDesign<
      WithSelectedQuantity<ProductVariant>
    >[] = appropriateProductVariantsForOption.map(pv => {
      const newMockedUpPv = buildFakeCustomizedProductVariant(
        pv,
        mockupUrls,
        newDesign
      )

      Object.assign(newMockedUpPv, {
        selectedQuantity: 1,
      })

      return newMockedUpPv as MaybeWithDesign<
        WithSelectedQuantity<ProductVariant>
      >
    })

    const currentProductVariants = getValues('productVariants') || []

    setValue('productVariants', [
      ...currentProductVariants,
      ...allDesignedProductVariantsToAdd,
    ])

    setShowProductsDrawer(false)
    setProductVariantToShow(undefined)
    setIsAddingToOrder(false)

    messageApi.success('Product added to order')
  }

  const handlePlaceOrder = async (data: OrderProductsFormType) => {
    messageApi.open({
      content: 'Placing order...',
      key: 'placing-order',
      type: 'loading',
      duration: 0,
    })
    const res = await handleSubmitOrder(data, {
      user,
      org,
      individual,
    })
    if (res.addedProgramId) {
      navigate(
        `${DEFAULT_ROUTES.ORGANIZATION.REWARDS.ROOT}/${res.addedProgramId}`
      )
    } else {
      messageApi.open({
        content: res.message,
        key: 'placing-order',
        type: 'error',
      })
    }
  }

  return (
    <>
      {contextHolder}
      <FormProvider {...methods}>
        <WarehouseIdContextProvider>
          <DraftOrderContextProvider>
            <form
              ref={formRef}
              onSubmit={handleSubmit(handlePlaceOrder)}
              className="flex flex-col gap-8 items-center w-full max-w-[1440px] mx-auto px-16 py-8"
            >
              <section className="flex flex-col items-center gap-4 w-full">
                <div className="relative w-full text-center">
                  <Heading size={900}>Send swag to an address</Heading>
                  <Button
                    className="absolute top-0 right-0"
                    type="text"
                    icon={<CloseOutlined />}
                    style={{ alignSelf: 'end' }}
                    onClick={() =>
                      navigate(DEFAULT_ROUTES.ORGANIZATION.SWAG.PRODUCTS)
                    }
                  />
                </div>

                <Text>
                  Send swag directly to an address, an event, or order samples.
                </Text>
              </section>

              <section className="flex gap-8 w-full">
                <div className="flex flex-col gap-8 flex-1">
                  <div className="flex flex-col gap-4 flex-1">
                    <Section1 />
                    <Section2 />
                  </div>
                  <Section3
                    onAddProductButtonClick={() => setShowProductsDrawer(true)}
                    onAddMoreClick={setProductVariantToShow}
                  />
                </div>

                <Section4 />
              </section>
            </form>

            {productVariantToShow && (
              <Modal
                width={1280}
                centered
                title="‎"
                open={!!productVariantToShow}
                styles={{
                  content: {
                    padding: '16px 0',
                  },
                  body: {
                    padding: 0,
                  },
                }}
                footer={null}
                onCancel={() => setProductVariantToShow(undefined)}
              >
                <PerkScrollbars
                  style={{
                    height: '85vh',
                    padding: '0 24px',
                  }}
                  options={{ overflow: { x: 'hidden' } }}
                >
                  {isCustomizable(productVariantToShow) ? (
                    <ProductRetails
                      productVariant={productVariantToShow}
                      onSubmit={handleAddDesignedProductVariants}
                      defaultValues={
                        productVariantToShow.canvasDesign
                          ? convertCanvasDesignToProductRetailsFormData(
                              productVariantToShow.canvasDesign
                            )
                          : undefined
                      }
                    >
                      <ProductRetails.Title />
                      <ProductRetails.Price inUsd />
                      <ProductRetails.Shipping />
                      <ProductRetails.Variants optionsToExclude={['size']} />
                      <ProductRetails.Inputs />
                      <Button
                        htmlType="submit"
                        type="primary"
                        loading={isAddingToOrder}
                        disabled={isAddingToOrder}
                      >
                        Add to order
                      </Button>

                      <ProductRetails.Description />
                    </ProductRetails>
                  ) : (
                    <ProductDetails
                      withQuantityTable
                      key={productVariantToShow.id}
                      productVariant={productVariantToShow}
                      showPrice
                      showShipping
                      showShippingCountries
                      submitButtonProps={{
                        children: 'Add to order',
                        loading: isAddingToOrder,
                        disabled: isAddingToOrder,
                      }}
                      withAmountInUsd
                      optionsToExclude={['size']}
                      onOptionSubmit={option => {
                        handleAddUnDesignedProductVariants(
                          productVariantToShow.productId,
                          option
                        )
                      }}
                    />
                  )}
                </PerkScrollbars>
              </Modal>
            )}

            <Drawer
              zIndex={1}
              open={showProductsDrawer}
              placement="bottom"
              width="100vw"
              height="100vh"
              closeIcon={null}
              title={
                <Pane
                  display="flex"
                  justifyContent="space-between"
                  width="100%"
                  alignItems="center"
                  zIndex={2}
                >
                  <Heading size={500}>Add swag</Heading>
                  <Button
                    type="text"
                    icon={<CloseOutlined />}
                    onClick={() => setShowProductsDrawer(false)}
                  />
                </Pane>
              }
              styles={{
                header: {
                  borderBottom: 'none',
                  width: '100%',
                },
                body: {
                  paddingTop: '0px',
                },
              }}
              destroyOnClose
            >
              <AlgoliaBrowseProducts>
                <AlgoliaBrowseProducts.Swag
                  title=""
                  onProductCardClick={setProductVariantToShow}
                  bottomOffsetAmount={128}
                  allowCustomization
                  minPermission={ProductCollection_Permission.send}
                />
              </AlgoliaBrowseProducts>
            </Drawer>
          </DraftOrderContextProvider>
        </WarehouseIdContextProvider>
      </FormProvider>
    </>
  )
}
