import { captureException } from '@sentry/react'
import {
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  onSnapshot,
  query,
  where,
} from 'firebase/firestore'
import { Integration } from 'gen/perkup/v1/integration_pb'
import { toSentry } from 'utils'
import { converter } from 'utils/firestore'

export async function GetIntegrationById({ id }: { id: string }) {
  const db = getFirestore()

  const docRef = doc(db, `integrations/${id}`).withConverter(
    converter(Integration)
  )

  try {
    return await getDoc(docRef).then(doc => doc.data())
  } catch (error) {
    console.error(error)
    captureException(toSentry(error), {
      contexts: {
        GetIntegrationById: { id },
      },
    })
  }
}

export async function ListIntegrationsByOrgId({ orgId }: { orgId: string }) {
  const db = getFirestore()

  const colRef = collection(db, `integrations`).withConverter(
    converter(Integration)
  )

  const q = query(
    colRef,
    where('orgId', '==', orgId),
    where('status', '==', 'active'),
    where('type', '==', 'finch')
  )

  try {
    return await getDocs(q).then(query => query.docs.map(doc => doc.data()))
  } catch (error) {
    console.error(error)
    captureException(toSentry(error), {
      contexts: {
        ListIntegrationsByOrgId: { orgId },
      },
    })
    return []
  }
}

export function listenToIntegrationsByOrgId({
  orgId,
  cb,
}: {
  orgId: string
  cb: (integrations: Integration[]) => void
}) {
  const db = getFirestore()

  const colRef = collection(db, `integrations`).withConverter(
    converter(Integration)
  )

  const q = query(
    colRef,
    where('orgId', '==', orgId),
    where('status', '==', 'active'),
    where('type', '==', 'finch')
  )

  return onSnapshot(
    q,
    { includeMetadataChanges: true },
    query => cb(query.docs.map(doc => doc.data())),
    error => {
      console.error(error)
      captureException(toSentry(error), {
        contexts: {
          ListenToIntegrationsByOrgId: { orgId },
        },
      })
    }
  )
}
