import { ListenToProductVariantsByCollectionId } from 'api/databaseCalls'
import { ProductVariant } from 'gen/perkup/v1/product_variant_pb'
import { useEffect, useState } from 'react'

export function useListenToProductVariantsByCollectionId({
  collectionId,
}: {
  collectionId: string
}) {
  const [productVariants, setProductVariants] = useState<ProductVariant[]>([])
  const [hasLoaded, setHasLoaded] = useState(false)

  useEffect(() => {
    return ListenToProductVariantsByCollectionId({
      collectionId,
      cb: productVariants => {
        setProductVariants(productVariants)
        setHasLoaded(true)
      },
    })
  }, [collectionId])

  return {
    productVariants,
    hasLoaded,
  }
}
