import { PlusOutlined } from '@ant-design/icons'
import { Button, Flex } from 'antd'
import { IntegrationLogo, PageHeader, PeopleCsvUploadButton } from 'components'
import { IndividualContext, OrgIntegrationsContext } from 'context'
import { Pane, Tab, Tablist } from 'evergreen-ui'
import { Individual, Individual_Role } from 'gen/perkup/v1/individual_pb'
import { OrgList } from 'gen/perkup/v1/organization_pb'
import { CSVTemplateButton } from 'pages/Integrations'
import { useContext, useEffect, useState } from 'react'
import {
  Pagination,
  useClearRefinements,
  useInstantSearch,
  useRefinementList,
} from 'react-instantsearch'
import { algoliaResultsToIndividuals } from 'utils/individual'
import { DirectoryTable } from './component/DirectoryTable'
import InviteMember from './component/InviteMember'
import { RefreshButton } from './component/RefreshButton'
import { DirectoryTabs, directorySubTabs } from './constants/peopleTabs'

export function Directory({ lists }: { lists: OrgList[] }) {
  const integrations = useContext(OrgIntegrationsContext)
  const individual = useContext(IndividualContext)

  const [individuals, setIndividuals] = useState<Individual[]>([])
  const { results, status } = useInstantSearch()
  const isLoading = status === 'loading' || status === 'stalled'
  useEffect(() => {
    if (!isLoading) {
      const algoIndividuals = algoliaResultsToIndividuals(results)
      setIndividuals(algoIndividuals)
    }
  }, [results, isLoading])

  const { refine: refineByManagerId } = useRefinementList({
    attribute: 'managerId',
    operator: 'and',
  })

  const { refine: clearManagerIdRefine } = useClearRefinements({
    includedAttributes: ['managerId'],
  })

  const [isInviteShown, setIsInviteShown] = useState(false)

  const isAdmin = individual.role === Individual_Role.admin
  const [selectedDirectoryTab, setSelectedDirectoryTab] = useState(
    isAdmin ? DirectoryTabs.COMPANY : DirectoryTabs.DIRECT_REPORTS
  )
  const isDirectReports = selectedDirectoryTab === DirectoryTabs.DIRECT_REPORTS

  useEffect(() => {
    if (isDirectReports) {
      if (individual?.id) {
        refineByManagerId(individual.id)
      }
    } else clearManagerIdRefine()
  }, [isDirectReports, individual?.id, clearManagerIdRefine, refineByManagerId])

  // Retreive existing integrations

  const firstIntegration = integrations?.[0]

  return (
    <Pane display="flex" flexDirection="column" gap={16}>
      {/** HEADER */}
      <PageHeader
        title="People"
        vlogLink="https://www.loom.com/embed/02e755e4db4f41778e7fe8dbb95e4f11?sid=0eadc808-51c2-4e6b-ad72-23b451624295"
        buttonsToRenderOnLeft={
          <Flex gap={16}>
            {isAdmin && (
              <Pane display="flex" gap={16}>
                <Button
                  style={{ display: 'flex', alignItems: 'center' }}
                  type="primary"
                  icon={<PlusOutlined />}
                  onClick={() => setIsInviteShown(true)}
                >
                  Invite people
                </Button>
                <Pane>
                  <PeopleCsvUploadButton />
                  <CSVTemplateButton />
                </Pane>
              </Pane>
            )}
          </Flex>
        }
        buttonsToRenderOnRight={
          <Pane display="flex" alignItems="center">
            {firstIntegration && (
              <Pane display="flex" alignItems="center" gap={8}>
                <IntegrationLogo
                  payroll_provider_id={firstIntegration.provider}
                />
                {isAdmin && <RefreshButton />}
              </Pane>
            )}
          </Pane>
        }
      />

      {/** DIRECTORY TABS */}
      <Pane>
        <Tablist>
          {directorySubTabs.map(subTab => {
            return (
              <Tab
                id={subTab.label}
                key={subTab.key}
                isSelected={subTab.key === selectedDirectoryTab}
                appearance="primary"
                onSelect={() => setSelectedDirectoryTab(subTab.key)}
              >
                {subTab.label}
              </Tab>
            )
          })}
        </Tablist>
      </Pane>

      {/** DIRECTORY TABLE */}
      <Pane>
        <DirectoryTable
          individuals={individuals}
          isDirectReports={isDirectReports}
          lists={lists}
          numberOfResults={results.nbHits}
          isLoading={isLoading}
        />
      </Pane>
      <InviteMember
        isShown={isInviteShown}
        onCloseComplete={() => {
          setIsInviteShown(false)
        }}
      />
      <Pagination />
    </Pane>
  )
}
