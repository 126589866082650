import { PaginateNotificationSnapshotsByOrgId } from 'api/databaseCalls'
import { QueryDocumentSnapshot } from 'firebase/firestore'
import {
  Notification,
  Notification_Status,
  Notification_Type,
} from 'gen/perkup/v1/notification_pb'
import useIds from 'hooks/useIds'
import { useEffect, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import { RangeValue } from 'types/RewardsDate'

export function useInfiniteNotifications({
  individualId,
  dateRange,
  notificationType,
  notificationStatus,
}: {
  individualId?: string
  dateRange: RangeValue
  notificationType?: Notification_Type
  notificationStatus?: Notification_Status
}) {
  const { orgId } = useIds()
  const [notifications, setNotifications] = useState<
    QueryDocumentSnapshot<Notification>[]
  >([])
  const [isLoadingInitial, setIsLoadingInitial] = useState(true)
  const [isLoadingMore, setIsLoadingMore] = useState(false)
  const [isLastPage, setIsLastPage] = useState(false)

  const QUERY_LIMIT = 100

  const handleGetMoreNotifications = async () => {
    const lastNotification = notifications[notifications.length - 1]

    const newNotifications = await PaginateNotificationSnapshotsByOrgId({
      orgId,
      individualId,
      dateRange,
      index: lastNotification,
      queryLimit: QUERY_LIMIT,
      notificationType,
      notificationStatus,
    })

    setIsLastPage(newNotifications.length < QUERY_LIMIT)

    setNotifications(oldNotifications =>
      oldNotifications.concat(newNotifications)
    )
  }

  const { ref: sentinelRef } = useInView({
    onChange: inView => {
      if (inView) {
        setIsLoadingMore(true)
        handleGetMoreNotifications().finally(() => {
          setIsLoadingMore(false)
        })
      }
    },
  })

  useEffect(() => {
    PaginateNotificationSnapshotsByOrgId({
      orgId,
      individualId,
      dateRange,
      queryLimit: QUERY_LIMIT,
      notificationType,
      notificationStatus,
    })
      .then(notifications => {
        setNotifications(notifications)
        setIsLastPage(notifications.length < QUERY_LIMIT)
      })
      .finally(() => {
        setIsLoadingInitial(false)
      })
  }, [individualId, orgId, dateRange, notificationType, notificationStatus])

  return {
    notifications: notifications.map(notification => notification.data()),
    isLoadingInitial,
    isLoadingMore,
    sentinelRef,
    isLastPage,
  }
}
