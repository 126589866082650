import { CARD } from 'constants/routes'
import { EmptyState, SearchIcon, useTheme } from 'evergreen-ui'
import { useNavigate } from 'react-router-dom'

export function RewardNotFound({
  title = 'Reward not found',
}: {
  title?: string
}) {
  const navigate = useNavigate()
  const theme = useTheme()
  return (
    <EmptyState
      background="light"
      title={title}
      description="Try logging into your account or contact support."
      orientation="vertical"
      icon={<SearchIcon color={theme.colors.gray500} />}
      iconBgColor={theme.colors.gray200}
      primaryCta={
        <EmptyState.PrimaryButton onClick={() => navigate(CARD)}>
          View account
        </EmptyState.PrimaryButton>
      }
    />
  )
}
