import { Select } from 'antd'
import { SizeType } from 'antd/es/config-provider/SizeContext'
import { NUMBER_GREEN } from 'constants/colors'
import { SELECTED_ACCOUNT } from 'constants/sessionOrLocalStorage'
import { ExchangeRateContext, OrgContext } from 'context'
import { Pane, Text } from 'evergreen-ui'
import { Account } from 'gen/perkup/v1/account_pb'
import {
  useDisplayCurrency,
  useIndividualRole,
  useSendableAccounts,
} from 'hooks'
import { isEmpty, isFunction, sortBy } from 'lodash-es'
import { useCallback, useContext, useEffect, useState } from 'react'
import { numToDollars } from 'utils'
import { getAccountBalances } from 'utils/accounts'

export function AccountBalanceForm({
  setSelectedAccount,
  selectedAccount,
  size,
  inLocaleAmounts = false,
}: {
  setSelectedAccount?: (account: Account) => void
  selectedAccount?: Account
  size?: SizeType
  inLocaleAmounts?: boolean
}) {
  const org = useContext(OrgContext)
  const exchangeRate = useContext(ExchangeRateContext)

  const displayCurrency = useDisplayCurrency()

  const { sendableAccounts, hasLoadedAccounts } = useSendableAccounts()
  const { isManager } = useIndividualRole()

  const orgDefaultAccountId = org.defaultAccountId

  const [localAccount, setLocalAccount] = useState<Account | undefined>(
    selectedAccount
  )

  const handleSelectAccount = useCallback(
    (accountId: string) => {
      const selectedAccount = sendableAccounts.find(
        account => account.id === accountId
      )
      if (selectedAccount) {
        if (isFunction(setSelectedAccount)) {
          setSelectedAccount(selectedAccount)
        }
        setLocalAccount(selectedAccount)
        window.localStorage.setItem(
          SELECTED_ACCOUNT,
          JSON.stringify(selectedAccount)
        )
      }
    },
    [sendableAccounts, setSelectedAccount, setLocalAccount]
  )

  // if there is no selected account, set a default account
  useEffect(() => {
    if (!hasLoadedAccounts) return

    if (!selectedAccount) {
      // check for stored account
      const storedAccount = JSON.parse(
        window.localStorage.getItem(SELECTED_ACCOUNT) || '{}'
      ) as Account
      if (
        storedAccount?.id &&
        sendableAccounts.find(a => a.id === storedAccount.id)
      ) {
        handleSelectAccount(storedAccount.id)
      } else {
        window.localStorage.removeItem(SELECTED_ACCOUNT)
        const defaultAccountBalance = sendableAccounts.find(
          account => account.id === orgDefaultAccountId
        )
        if (defaultAccountBalance) {
          handleSelectAccount(defaultAccountBalance.id)
        } else if (sendableAccounts[0]) {
          handleSelectAccount(sendableAccounts[0].id)
        }
      }
    }
  }, [
    hasLoadedAccounts,
    selectedAccount,
    sendableAccounts,
    orgDefaultAccountId,
    setSelectedAccount,
    handleSelectAccount,
  ])

  if (!localAccount?.id || isEmpty(sendableAccounts)) return null

  return (
    <Pane
      onClick={(e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault()
        e.stopPropagation()
      }}
    >
      <Select
        showSearch
        value={localAccount?.id}
        popupMatchSelectWidth={false}
        className="customSelectionItem"
        onSelect={handleSelectAccount}
        size={size}
        style={{ width: 256 }}
        dropdownStyle={{ width: 'fit-content' }}
        placement="topRight"
        optionFilterProp="label"
      >
        {sortBy(sendableAccounts, account => -account.balance).map(account => {
          const { balance, unallocatedFunds } = getAccountBalances({ account })
          const balanceToDisplay = isManager ? unallocatedFunds : balance

          const balanceString = inLocaleAmounts
            ? numToDollars(
                Number(balanceToDisplay) * exchangeRate,
                2,
                false,
                displayCurrency
              )
            : numToDollars(Number(balanceToDisplay))

          return (
            <Select.Option
              key={account.id}
              value={account.id}
              label={account.name}
            >
              <Pane
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                height="100%"
                gap={8}
              >
                <Text className="one-line-truncate">{account.name}</Text>
                <Text color={NUMBER_GREEN}>{balanceString}</Text>
              </Pane>
            </Select.Option>
          )
        })}
      </Select>
    </Pane>
  )
}
