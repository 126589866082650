import { InfoCircleOutlined } from '@ant-design/icons'
import { Button, Flex, Skeleton } from 'antd'
import { DENOMINATION } from 'constants/productVariants'
import { BlankIcon, EmptyState, Heading, Pane, Text } from 'evergreen-ui'
import { ProductVariant } from 'gen/perkup/v1/product_variant_pb'
import { useDefaultOrgColors } from 'hooks'
import useListAllProductVariantsByProductId from 'hooks/productVariants/useListAllProductVariantsByProductId'
import { capitalize, isUndefined, keys, startCase, uniqueId } from 'lodash-es'
import { useState } from 'react'
import { SelectedVariantOption } from 'types'
import { numToDollars } from 'utils'
import {
  getAvailableVariantOptions,
  getSelectedProductVariant,
  publicGiftIsNotAvailable,
} from 'utils/productVariant'
import { sortProductOptions } from 'utils/sorting'

function ProductOptionsSkeleton() {
  return (
    <Flex gap={8} style={{ marginBottom: 16 }}>
      {new Array(3).fill(null).map(_ => (
        <Skeleton.Button key={uniqueId()} style={{ flex: 1 }} active block />
      ))}
    </Flex>
  )
}

function ProductOptionSelection({
  optionValues,
  productOption,
  selectedVariantValues,
  onSelectVariantValue,
  remainingProductVariants,
  showOptionsAlerts = false,
  asStatic = false,
}: {
  optionValues: string[]
  productOption: string
  selectedVariantValues: SelectedVariantOption | undefined
  onSelectVariantValue: (selectedOption: SelectedVariantOption) => void
  remainingProductVariants: ProductVariant[] | undefined
  showOptionsAlerts?: boolean
  asStatic?: boolean
}) {
  const { defaultColor, backgroundColor } = useDefaultOrgColors()

  const productOptionTitle = capitalize(productOption)
  const selectedVariantOptionValue = selectedVariantValues?.[productOption]
  const isMonetary = productOption === DENOMINATION
  const sortedOptionValues = sortProductOptions({
    productOptions: optionValues,
    productOption,
  })

  return (
    <Flex vertical gap={4}>
      <Pane
        flex={1}
        display="flex"
        flexWrap="nowrap"
        gap={8}
        alignItems="center"
      >
        <Heading size={400}>
          {/** Product wants these titles plural when the options are static */}
          {asStatic ? `${productOptionTitle}s` : productOptionTitle}
        </Heading>
        {!selectedVariantOptionValue && showOptionsAlerts && (
          <Flex align="center" gap={4}>
            <InfoCircleOutlined style={{ color: defaultColor }} />
            <Text color={defaultColor}>Select {productOption}</Text>
          </Flex>
        )}
      </Pane>
      <Pane
        display="flex"
        width="100%"
        gap={asStatic ? undefined : 8}
        flexWrap="wrap"
        alignItems="left"
        flex={6}
      >
        {sortedOptionValues.map((value, index) => {
          // Do we have a value in the list of available product variants
          const productVariantWithOptionValue = remainingProductVariants?.find(
            pv => pv.options[productOption]?.value === value
          )

          const disabled = !productVariantWithOptionValue

          const isSelected = value === selectedVariantOptionValue && !disabled

          const currencyCode = remainingProductVariants?.[0]?.currencyCode

          const displayValue = isMonetary
            ? numToDollars(Number(value) * 100, 2, true, currencyCode)
            : startCase(value)

          const isLastOptionInRow = sortedOptionValues.length - 1 === index
          const showComma = asStatic && !isLastOptionInRow

          if (asStatic) {
            return (
              <Button
                key={value}
                style={{
                  pointerEvents: 'none',
                  boxShadow: 'none',
                  padding: '0',
                  border: 'white',
                  marginRight: 8,
                }}
              >
                <Text>{`${displayValue}${showComma ? ',' : ''}`}</Text>
              </Button>
            )
          }

          return (
            <Button
              key={value}
              onClick={() => onSelectVariantValue({ [productOption]: value })}
              style={{
                backgroundColor: isSelected ? backgroundColor : undefined,
                borderColor: isSelected ? backgroundColor : undefined,
              }}
              disabled={disabled}
            >
              <Text color={isSelected ? 'selected' : undefined}>
                {displayValue}
              </Text>
            </Button>
          )
        })}
      </Pane>
    </Flex>
  )
}

export function ProductOptions({
  product,
  onSelectProductVariant,
  onOptionSelect,
  defaultOptions,
  maxBudget,
  showOptionsAlerts = false,
  asStatic = false,
  filterNonSelected = false,
  optionsToExclude = [],
}: {
  product: ProductVariant
  onSelectProductVariant?: (productVariant: ProductVariant | undefined) => void
  onOptionSelect?: (option: SelectedVariantOption) => void
  defaultOptions?: SelectedVariantOption
  maxBudget?: number
  showOptionsAlerts?: boolean // These are the colored alerts that show when no option is selected
  asStatic?: boolean
  filterNonSelected?: boolean
  optionsToExclude?: string[]
}) {
  const [selectedValues, setSelectedValues] = useState<
    SelectedVariantOption | undefined
  >(defaultOptions)

  const { allProductVariants, isLoading: isLoadingOtherOptions } =
    useListAllProductVariantsByProductId({
      productId: filterNonSelected ? undefined : product.productId,
    })

  // @todo this looks over complicated
  // Filter out product variants that are out of stock or over budget
  const getFilteredVariants = () => {
    if (filterNonSelected) {
      return [product]
    }
    if (allProductVariants) {
      return allProductVariants?.filter(pv => {
        if (maxBudget && pv?.amount && pv.amount > maxBudget) return false
        const hideProduct = publicGiftIsNotAvailable(pv)
        if (hideProduct) return false // Always show swag if no stock
        return true
      })
    }
    return []
  }

  const filteredVariants = getFilteredVariants()

  const defaultProductVariant = filteredVariants[0]

  // We have our filtered variants. Now we need to find out what option values we can choose from
  const allAvailableOptions = getAvailableVariantOptions({
    productVariants: filteredVariants,
  })

  const handleSelectVariantValue = (
    newSelectedValue: SelectedVariantOption
  ) => {
    if (!onSelectProductVariant) return

    if (onOptionSelect) {
      onOptionSelect(newSelectedValue)
    }

    const selectedOptionRank =
      allAvailableOptions[keys(newSelectedValue)[0]].rank

    // Only keep the selected values that are of the same rank or lower than the new selected option
    const optionsToKeep = keys(selectedValues).filter(key => {
      const optionRank = allAvailableOptions[key].rank
      return optionRank <= selectedOptionRank
    })

    const selectedVariantValues: SelectedVariantOption = {}

    optionsToKeep.forEach(option => {
      if (!isUndefined(selectedValues)) {
        selectedVariantValues[option] = selectedValues[option]
      }
    })

    // Our selected variant values are the prev values filtered by rank + the new selected value
    const selVariantValues = {
      ...selectedVariantValues,
      ...newSelectedValue,
    }

    const productVariant = getSelectedProductVariant({
      productVariants: filteredVariants,
      selectedVariantValues: selVariantValues,
    })

    setSelectedValues(selVariantValues)
    onSelectProductVariant(productVariant)
  }

  if (isLoadingOtherOptions) return <ProductOptionsSkeleton />

  if (!defaultProductVariant) {
    return (
      <EmptyState
        title="Could not find a product"
        icon={<BlankIcon />}
        iconBgColor=""
      />
    )
  }
  return (
    <Flex vertical gap={16}>
      {keys(allAvailableOptions)
        .filter(key => !optionsToExclude.includes(key))
        .map(productOption => {
          const { rank: currentOptionRank } = allAvailableOptions[productOption]
          // Look at rank to determine remaining variants
          const selectedOptionsToFilterBy = keys(selectedValues).filter(
            option => {
              const optionRank = allAvailableOptions[option].rank
              return optionRank < currentOptionRank
            }
          )
          // Only return variants that match every selected option value of the same rank or lower
          const remainingProductVariants = filteredVariants.filter(pv => {
            return selectedOptionsToFilterBy.every(key => {
              return pv.options[key]?.value === selectedValues?.[key]
            })
          })

          return (
            <ProductOptionSelection
              key={productOption}
              onSelectVariantValue={handleSelectVariantValue}
              optionValues={allAvailableOptions[productOption]?.values}
              productOption={productOption}
              selectedVariantValues={selectedValues}
              remainingProductVariants={remainingProductVariants}
              showOptionsAlerts={showOptionsAlerts}
              asStatic={asStatic}
            />
          )
        })}
    </Flex>
  )
}
