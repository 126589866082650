import { Timestamp } from '@bufbuild/protobuf'
import { PERKUP_DOLLARS } from 'constants/newReward'
import { PERKUP_NAME } from 'constants/perkupLinks'
import { ProgramTemplate } from 'gen/perkup/v1/contentful_pb'
import {
  Template,
  Template_Access,
  Template_EligibleRewards,
} from 'gen/perkup/v1/org_template_pb'
import { ProductVariant_Type } from 'gen/perkup/v1/product_variant_pb'
import { Program } from 'gen/perkup/v1/program_pb'

export function sortFeaturedTemplates({
  programTemplates,
}: {
  programTemplates: ProgramTemplate[]
}) {
  const featuredTemplates = programTemplates.sort((a, b) => {
    if (!a.rank && !b.rank) return 0
    if (!a.rank) return 1
    if (!b.rank) return -1
    return a.rank - b.rank
  })
  return featuredTemplates
}

export const tempateImageWidth = 333
export const tempateImageHeight = 192

export function getTemplateImageUrl({
  programImage,
}: {
  programImage: string
}) {
  const bucketSearchString = PERKUP_NAME.toLowerCase()
  const isBucketImage = programImage.includes(bucketSearchString)
  const imageUrl = !isBucketImage
    ? // Resize image for faster load time and 2x resolution for retina displays
      `${programImage}?w=${tempateImageWidth * 2}&h=${tempateImageHeight * 2}`
    : `${programImage}`
  return imageUrl
}

export const getEligibleTemplateRewardLabel = (
  rewardKey: keyof Template_EligibleRewards
) => {
  switch (rewardKey) {
    case 'cash':
      return PERKUP_DOLLARS
    case 'swag':
      return 'Swag'
    case 'gift':
      return 'Gift'
    case 'giftCard':
      return 'Gift Card'
    default:
      return ''
  }
}

export const convertProgramToTemplate = (program: Program) => {
  return new Template({
    name: program.name,
    occasion: program.occasion,
    email: {
      title: program?.email?.title,
      message: program?.note,
      writable: true,
    },
    image: {
      url: program?.email?.banner,
      writable: false,
    },
    internalMemo: program?.internalMemo,
    budget: {
      amounts: [program.budget],
      writable: false,
    },
    coreValues: {
      values: program.coreValues,
      writable: false,
    },
    eligibleRewards: {
      cash: !program?.gift,
      swag: program?.gift?.productVariantType === ProductVariant_Type.swag,
      giftCard:
        program?.gift?.productVariantType === ProductVariant_Type.nearCash,
      gift:
        program?.gift?.productVariantType === ProductVariant_Type.publicGift,
    },
    isDeleted: false,
    created: Timestamp.now(),
    approvedCategories: program.approvedCategories,
    access: Template_Access.all,
    expiry: {
      writable: true,
    },
  })
}
