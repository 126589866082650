import {
  CloseOutlined,
  DollarCircleOutlined,
  EditOutlined,
} from '@ant-design/icons'
import { Button, Drawer, Flex, Image, Tooltip } from 'antd'
import { PerkActionFooter, PerkIconTooltipButton } from 'components'
import { CONVERTIBLE_TO_EXTRAS } from 'constants/programs'
import { Heading, Text, useTheme } from 'evergreen-ui'
import { SelectReward } from 'features'
import { isEqual } from 'lodash-es'
import { useState } from 'react'
import { Reward } from 'types'

function RewardThumbnail({
  reward,
  onEditClick,
}: {
  reward: Reward
  onEditClick: () => void
}) {
  const theme = useTheme()
  const { gift } = reward

  if (!gift) {
    return (
      <Flex gap={8} align="center">
        <DollarCircleOutlined />
        <Text
          className="hoverable-underline-text"
          onClick={onEditClick}
          flex={1}
        >
          PerkUp dollars
        </Text>
        <PerkIconTooltipButton
          title="Edit reward"
          onClick={onEditClick}
          type="text"
          icon={<EditOutlined />}
        />
      </Flex>
    )
  }

  const { title, imageUrl, convertableTo, redeemableQuantity } = gift

  return (
    <Flex gap={8}>
      <Image
        preview={false}
        width={40}
        style={{ objectFit: 'cover', aspectRatio: '1/1', borderRadius: 4 }}
        src={imageUrl}
      />

      <Flex flex={1} vertical gap={4}>
        <Text className="hoverable-underline-text" onClick={onEditClick}>
          {title}
        </Text>

        {redeemableQuantity && (
          <Text color="muted">{redeemableQuantity} item per recipient</Text>
        )}

        {convertableTo.length > 0 && (
          <Flex gap={4}>
            <Text color="muted">Swappable for</Text>
            {convertableTo.map(convertable => {
              const ConvertableIcon = CONVERTIBLE_TO_EXTRAS[convertable].icon
              return (
                <Tooltip
                  key={convertable}
                  title={CONVERTIBLE_TO_EXTRAS[convertable].name}
                >
                  <ConvertableIcon style={{ color: theme.colors.muted }} />
                </Tooltip>
              )
            })}
          </Flex>
        )}
      </Flex>

      <PerkIconTooltipButton
        title="Edit reward"
        onClick={onEditClick}
        type="text"
        icon={<EditOutlined />}
      />
    </Flex>
  )
}

export function EditableRewardCell({
  currentReward,
  onRewardChange,
}: {
  currentReward: Reward
  onRewardChange: (updatedReward: Reward) => void
}) {
  const [showRewardDrawer, setShowRewardDrawer] = useState(false)

  const [tempSelectedReward, setTempSelectedReward] =
    useState<Reward>(currentReward)

  const handleConfirmReward = () => {
    onRewardChange(tempSelectedReward)
    setShowRewardDrawer(false)
  }

  const handleCancelReward = () => {
    setTempSelectedReward(currentReward)
    setShowRewardDrawer(false)
  }

  return (
    <>
      <RewardThumbnail
        reward={currentReward}
        onEditClick={() => setShowRewardDrawer(true)}
      />

      <Drawer
        zIndex={3}
        open={showRewardDrawer}
        placement="bottom"
        height="90vh"
        onClose={handleCancelReward}
        closeIcon={null}
        title={
          <Flex justify="space-between" align="center">
            <Heading size={500}>Select reward</Heading>
            <Button
              type="text"
              icon={<CloseOutlined />}
              onClick={handleCancelReward}
            />
          </Flex>
        }
        styles={{
          header: {
            borderBottom: 'none',
            width: '100%',
          },
          body: {
            paddingTop: 0,
            marginBottom: 64,
          },
        }}
        destroyOnClose
      >
        <SelectReward.Context
          selectedReward={tempSelectedReward}
          onSelectReward={newReward =>
            setTempSelectedReward(currentReward => ({
              ...currentReward,
              ...newReward,
            }))
          }
        >
          <SelectReward.Form>
            <SelectReward.Form.Slot name="tabs">
              <SelectReward.Form.Tabs />
            </SelectReward.Form.Slot>

            <SelectReward.Form.Slot name="perkupDollars">
              <SelectReward.Form.Dollars2 />
            </SelectReward.Form.Slot>

            <SelectReward.Form.Slot name="swag">
              <SelectReward.Form.Swag />
            </SelectReward.Form.Slot>

            <SelectReward.Form.Slot name="giftCatalog">
              <SelectReward.Form.Gift2 />
            </SelectReward.Form.Slot>

            <SelectReward.Form.Slot name="giftCard">
              <SelectReward.Form.Card2 />
            </SelectReward.Form.Slot>
          </SelectReward.Form>

          <PerkActionFooter>
            <Flex gap={16} align="center">
              <SelectReward.CurrentSelectedRewardPopover />
              <SelectReward.SwagRedemptionPopover />
              <Button
                size="large"
                type="primary"
                onClick={handleConfirmReward}
                disabled={isEqual(tempSelectedReward, currentReward)}
                style={{ width: 248 }}
              >
                Add to rule
              </Button>
            </Flex>
          </PerkActionFooter>
        </SelectReward.Context>
      </Drawer>
    </>
  )
}
