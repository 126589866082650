import { ArrowRightOutlined } from '@ant-design/icons'
import PerkCard from 'components/PerkCard'
import { Heading, Pane, Text } from 'evergreen-ui'
import { useSpendingOptions } from 'hooks/useSpendingOptions'
import { useState } from 'react'

export function PerkCardPreview() {
  const { showPerkCard } = useSpendingOptions()
  const [showSidesheet, setShowSidesheet] = useState(false)
  if (!showPerkCard) return null
  return (
    <Pane hoverElevation={1} borderRadius={8}>
      <PerkCard
        addPadding
        showSidesheet={showSidesheet}
        setShowSidesheet={setShowSidesheet}
      >
        <Pane
          display="flex"
          flexDirection="column"
          gap={16}
          justifyContent="center"
        >
          <Pane display="flex" alignItems="center" gap={16}>
            <Heading size={800}>Use your PerkUp Visa card</Heading>
            <ArrowRightOutlined />
          </Pane>
          <Text>
            Buy something online or in person, anywhere Visa is accepted.
          </Text>
        </Pane>
      </PerkCard>
    </Pane>
  )
}
