import { invert } from 'lodash-es'

/**
 * NORTH AMERICA ISO2s
 */
export const UNITES_STATES_ISO2 = 'us'
export const CANADA_ISO2 = 'ca'
export const MEXICO_ISO2 = 'mx'

/**
 * NORTH AMERICA ISO3s
 */
export const UNITES_STATES_ISO3 = 'usa'
export const CANADA_ISO3 = 'can'
export const MEXICO_ISO3 = 'mex'

/**
 * EUROPE ISO2s
 */
export const AUSTRIA_ISO2 = 'at'
export const BELGIUM_ISO2 = 'be'
export const BULGARIA_ISO2 = 'bg'
export const CROATIA_ISO2 = 'hr'
export const CYPRUS_ISO2 = 'cy'
export const CZECH_REPUBLIC_ISO2 = 'cz'
export const DENMARK_ISO2 = 'dk'
export const ESTONIA_ISO2 = 'ee'
export const FINLAND_ISO2 = 'fi'
export const FRANCE_ISO2 = 'fr'
export const GERMANY_ISO2 = 'de'
export const GREECE_ISO2 = 'gr'
export const HUNGARY_ISO2 = 'hu'
export const IRELAND_ISO2 = 'ie'
export const ITALY_ISO2 = 'it'
export const LATVIA_ISO2 = 'lv'
export const LITHUANIA_ISO2 = 'lt'
export const LUXEMBOURG_ISO2 = 'lu'
export const MALTA_ISO2 = 'mt'
export const NETHERLANDS_ISO2 = 'nl'
export const POLAND_ISO2 = 'pl'
export const PORTUGAL_ISO2 = 'pt'
export const ROMANIA_ISO2 = 'ro'
export const SLOVAKIA_ISO2 = 'sk'
export const SLOVENIA_ISO2 = 'si'
export const SPAIN_ISO2 = 'es'
export const SWEDEN_ISO2 = 'se'
export const GREAT_BRITAIN_ISO2 = 'gb'

/**
 * EUROPE ISO3s
 */
export const AUSTRIA_ISO3 = 'aut'
export const BELGIUM_ISO3 = 'bel'
export const BULGARIA_ISO3 = 'bgr'
export const CROATIA_ISO3 = 'hrv'
export const CYPRUS_ISO3 = 'cyp'
export const CZECH_REPUBLIC_ISO3 = 'cze'
export const DENMARK_ISO3 = 'dnk'
export const ESTONIA_ISO3 = 'est'
export const FINLAND_ISO3 = 'fin'
export const FRANCE_ISO3 = 'fra'
export const GERMANY_ISO3 = 'deu'
export const GREECE_ISO3 = 'grc'
export const HUNGARY_ISO3 = 'hun'
export const IRELAND_ISO3 = 'irl'
export const ITALY_ISO3 = 'ita'
export const LATVIA_ISO3 = 'lva'
export const LITHUANIA_ISO3 = 'ltu'
export const LUXEMBOURG_ISO3 = 'lux'
export const MALTA_ISO3 = 'mlt'
export const NETHERLANDS_ISO3 = 'nld'
export const POLAND_ISO3 = 'pol'
export const PORTUGAL_ISO3 = 'prt'
export const ROMANIA_ISO3 = 'rou'
export const SLOVAKIA_ISO3 = 'svk'
export const SLOVENIA_ISO3 = 'svn'
export const SPAIN_ISO3 = 'esp'
export const SWEDEN_ISO3 = 'swe'
export const GREAT_BRITAIN_ISO3 = 'gbr'

/**
 * ASIA ISO2s
 */
export const INDIA_ISO2 = 'in'

/**
 * ASIA ISO3s
 */
export const INDIA_ISO3 = 'ind'
export const VIETNAM_ISO3 = 'vnm'

export const US_ID = '1KPypLQqAglFz2bGYNbWsa'
export const CA_ID = '2Stl7RCau1sETG4fPgDBkZ'

export const hideShopAndGiftIso3s = [INDIA_ISO3, VIETNAM_ISO3]

export const countryIdToCountryCode = new Map<string, string>([
  [US_ID, 'US'],
  [CA_ID, 'CA'],
])

export const europeanIso2Set = new Set([
  AUSTRIA_ISO2,
  BELGIUM_ISO2,
  BULGARIA_ISO2,
  CROATIA_ISO2,
  CYPRUS_ISO2,
  CZECH_REPUBLIC_ISO2,
  DENMARK_ISO2,
  ESTONIA_ISO2,
  FINLAND_ISO2,
  FRANCE_ISO2,
  GERMANY_ISO2,
  GREECE_ISO2,
  HUNGARY_ISO2,
  IRELAND_ISO2,
  ITALY_ISO2,
  LATVIA_ISO2,
  LITHUANIA_ISO2,
  LUXEMBOURG_ISO2,
  MALTA_ISO2,
  NETHERLANDS_ISO2,
  POLAND_ISO2,
  PORTUGAL_ISO2,
  ROMANIA_ISO2,
  SLOVAKIA_ISO2,
  SLOVENIA_ISO2,
  SPAIN_ISO2,
  SWEDEN_ISO2,
  GREAT_BRITAIN_ISO2,
])

export const countryIso3ToIso2: Record<string, string> = {
  and: 'ad',
  are: 'ae',
  afg: 'af',
  atg: 'ag',
  aia: 'ai',
  alb: 'al',
  arm: 'am',
  ago: 'ao',
  ata: 'aq',
  arg: 'ar',
  asm: 'as',
  [AUSTRIA_ISO3]: AUSTRIA_ISO2,
  aus: 'au',
  abw: 'aw',
  ala: 'ax',
  aze: 'az',
  bih: 'ba',
  brb: 'bb',
  bgd: 'bd',
  [BELGIUM_ISO3]: BELGIUM_ISO2,
  bfa: 'bf',
  [BULGARIA_ISO3]: BULGARIA_ISO2,
  bhr: 'bh',
  bdi: 'bi',
  ben: 'bj',
  blm: 'bl',
  bmu: 'bm',
  brn: 'bn',
  bol: 'bo',
  bes: 'bq',
  bra: 'br',
  bhs: 'bs',
  btn: 'bt',
  bvt: 'bv',
  bwa: 'bw',
  blr: 'by',
  blz: 'bz',
  [CANADA_ISO3]: CANADA_ISO2,
  cck: 'cc',
  cod: 'cd',
  caf: 'cf',
  cog: 'cg',
  che: 'ch',
  civ: 'ci',
  cok: 'ck',
  chl: 'cl',
  cmr: 'cm',
  chn: 'cn',
  col: 'co',
  cri: 'cr',
  cub: 'cu',
  cpv: 'cv',
  cuw: 'cw',
  cxr: 'cx',
  [CYPRUS_ISO3]: CYPRUS_ISO2,
  [CZECH_REPUBLIC_ISO3]: CZECH_REPUBLIC_ISO2,
  [GERMANY_ISO3]: GERMANY_ISO2,
  dji: 'dj',
  [DENMARK_ISO3]: DENMARK_ISO2,
  dma: 'dm',
  dom: 'do',
  dza: 'dz',
  ecu: 'ec',
  [ESTONIA_ISO3]: ESTONIA_ISO2,
  egy: 'eg',
  esh: 'eh',
  eri: 'er',
  [SPAIN_ISO3]: SPAIN_ISO2,
  eth: 'et',
  [FINLAND_ISO3]: FINLAND_ISO2,
  fji: 'fj',
  flk: 'fk',
  fsm: 'fm',
  fro: 'fo',
  [FRANCE_ISO3]: FRANCE_ISO2,
  gab: 'ga',
  [GREAT_BRITAIN_ISO3]: GREAT_BRITAIN_ISO2,
  grd: 'gd',
  geo: 'ge',
  guf: 'gf',
  ggy: 'gg',
  gha: 'gh',
  gib: 'gi',
  grl: 'gl',
  gmb: 'gm',
  gin: 'gn',
  glp: 'gp',
  gnq: 'gq',
  [GREECE_ISO3]: GREECE_ISO2,
  sgs: 'gs',
  gtm: 'gt',
  gum: 'gu',
  gnb: 'gw',
  guy: 'gy',
  hkg: 'hk',
  hmd: 'hm',
  hnd: 'hn',
  [CROATIA_ISO3]: CROATIA_ISO2,
  hti: 'ht',
  [HUNGARY_ISO3]: HUNGARY_ISO2,
  idn: 'id',
  [IRELAND_ISO3]: IRELAND_ISO2,
  isr: 'il',
  imn: 'im',
  [INDIA_ISO3]: INDIA_ISO2,
  iot: 'io',
  irq: 'iq',
  irn: 'ir',
  isl: 'is',
  [ITALY_ISO3]: ITALY_ISO2,
  jey: 'je',
  jam: 'jm',
  jor: 'jo',
  jpn: 'jp',
  ken: 'ke',
  kgz: 'kg',
  khm: 'kh',
  kir: 'ki',
  com: 'km',
  kna: 'kn',
  prk: 'kp',
  kor: 'kr',
  kwt: 'kw',
  cym: 'ky',
  kaz: 'kz',
  lao: 'la',
  lbn: 'lb',
  lca: 'lc',
  lie: 'li',
  lka: 'lk',
  lbr: 'lr',
  lso: 'ls',
  [LITHUANIA_ISO3]: LITHUANIA_ISO2,
  [LUXEMBOURG_ISO3]: LUXEMBOURG_ISO2,
  [LATVIA_ISO3]: LATVIA_ISO2,
  lby: 'ly',
  mar: 'ma',
  mco: 'mc',
  mda: 'md',
  mne: 'me',
  maf: 'mf',
  mdg: 'mg',
  mhl: 'mh',
  mkd: 'mk',
  mli: 'ml',
  mmr: 'mm',
  mng: 'mn',
  mac: 'mo',
  mnp: 'mp',
  mtq: 'mq',
  mrt: 'mr',
  msr: 'ms',
  [MALTA_ISO3]: MALTA_ISO2,
  mus: 'mu',
  mdv: 'mv',
  mwi: 'mw',
  [MEXICO_ISO3]: MEXICO_ISO2,
  mys: 'my',
  moz: 'mz',
  nam: 'na',
  ncl: 'nc',
  ner: 'ne',
  nfk: 'nf',
  nga: 'ng',
  nic: 'ni',
  [NETHERLANDS_ISO3]: NETHERLANDS_ISO2,
  nor: 'no',
  npl: 'np',
  nru: 'nr',
  niu: 'nu',
  nzl: 'nz',
  omn: 'om',
  pan: 'pa',
  per: 'pe',
  pyf: 'pf',
  png: 'pg',
  phl: 'ph',
  pak: 'pk',
  [POLAND_ISO3]: POLAND_ISO2,
  spm: 'pm',
  pcn: 'pn',
  pri: 'pr',
  pse: 'ps',
  [PORTUGAL_ISO3]: PORTUGAL_ISO2,
  plw: 'pw',
  pry: 'py',
  qat: 'qa',
  reu: 're',
  [ROMANIA_ISO3]: ROMANIA_ISO2,
  srb: 'rs',
  rus: 'ru',
  rwa: 'rw',
  sau: 'sa',
  slb: 'sb',
  syc: 'sc',
  sdn: 'sd',
  [SWEDEN_ISO3]: SWEDEN_ISO2,
  sgp: 'sg',
  shn: 'sh',
  [SLOVENIA_ISO3]: SLOVENIA_ISO2,
  sjm: 'sj',
  [SLOVAKIA_ISO3]: SLOVAKIA_ISO2,
  sle: 'sl',
  smr: 'sm',
  sen: 'sn',
  som: 'so',
  sur: 'sr',
  ssd: 'ss',
  stp: 'st',
  slv: 'sv',
  sxm: 'sx',
  syr: 'sy',
  swz: 'sz',
  tca: 'tc',
  tcd: 'td',
  atf: 'tf',
  tgo: 'tg',
  tha: 'th',
  tjk: 'tj',
  tkl: 'tk',
  tls: 'tl',
  tkm: 'tm',
  tun: 'tn',
  ton: 'to',
  tur: 'tr',
  tto: 'tt',
  tuv: 'tv',
  twn: 'tw',
  tza: 'tz',
  ukr: 'ua',
  uga: 'ug',
  umi: 'um',
  [UNITES_STATES_ISO3]: UNITES_STATES_ISO2,
  ury: 'uy',
  uzb: 'uz',
  vat: 'va',
  vct: 'vc',
  ven: 've',
  vgb: 'vg',
  vir: 'vi',
  vnm: 'vn',
  vut: 'vu',
  wlf: 'wf',
  wsm: 'ws',
  yem: 'ye',
  myt: 'yt',
  zaf: 'za',
  zmb: 'zm',
  zwe: 'zw',
}

export const countryIso2To3: Record<string, string> = invert(countryIso3ToIso2)

export const iso3RankedList = [
  UNITES_STATES_ISO3,
  GERMANY_ISO3,
  GREAT_BRITAIN_ISO3,
  CANADA_ISO3,
  'aus',
  INDIA_ISO3,
  IRELAND_ISO3,
  'sgp',
  FRANCE_ISO3,
  'jpn',
  'che',
  'are',
  SWEDEN_ISO3,
  'nor',
  DENMARK_ISO3,
  NETHERLANDS_ISO3,
  ITALY_ISO3,
  'hkg',
  'brn',
  'isl',
  AUSTRIA_ISO3,
  POLAND_ISO3,
  'mac',
  'nzl',
  'sau',
  SLOVAKIA_ISO3,
  'and',
  'smr',
  BELGIUM_ISO3,
  'qat',
  MALTA_ISO3,
  PORTUGAL_ISO3,
  'guy',
  'bhr',
  FINLAND_ISO3,
  LUXEMBOURG_ISO3,
  'kor',
  'isr',
  CYPRUS_ISO3,
  'kwt',
  SLOVENIA_ISO3,
  'abw',
  SPAIN_ISO3,
  LITHUANIA_ISO3,
  CZECH_REPUBLIC_ISO3,
  ESTONIA_ISO3,
  'bhs',
  HUNGARY_ISO3,
  CROATIA_ISO3,
  'pan',
  'tur',
  'syc',
  ROMANIA_ISO3,
  LATVIA_ISO3,
  GREECE_ISO3,
  'omn',
  'mdv',
  'mys',
  'rus',
  BULGARIA_ISO3,
  'kaz',
  'tto',
  'chl',
  'kna',
  'mus',
  'ury',
  'mne',
  'arg',
  'cri',
  'dom',
  'srb',
  'atg',
  MEXICO_ISO3,
  'lby',
  'blr',
  'chn',
  'tha',
  'geo',
  'mkd',
  'grd',
  'bra',
  'irn',
  'tkm',
  'arm',
  'bih',
  'alb',
  'col',
  'bwa',
  'gab',
  'lca',
  'brb',
  'aze',
  'gnq',
  'sur',
  'vct',
  'egy',
  'mda',
  'fji',
  'zaf',
]
