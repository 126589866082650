import { captureException } from '@sentry/react'
import {
  addDoc,
  collection,
  doc,
  getFirestore,
  setDoc,
} from 'firebase/firestore'
import { Individual_Role } from 'gen/perkup/v1/individual_pb'
import { InvitedUser } from 'gen/perkup/v1/organization_pb'
import { toSentry } from 'utils'
import { converter } from 'utils/firestore'

export async function inviteMember({
  orgId,
  email,
  role,
}: {
  orgId: string
  email: string
  role: Individual_Role
}) {
  const db = getFirestore()
  const colRef = collection(
    db,
    `organizations/${orgId}/invitedUsers`
  ).withConverter(converter(InvitedUser))
  return addDoc(colRef, new InvitedUser({ role, email })).catch(error => {
    console.error(error)
    captureException(toSentry(error), {
      contexts: {
        inviteMember: {
          orgId,
          email,
          role,
        },
      },
    })
  })
}

export function updateInviteRole({
  orgId,
  inviteId,
  role,
}: {
  orgId: string
  inviteId: string
  role: Individual_Role
}) {
  const db = getFirestore()
  const docRef = doc(
    db,
    `organizations/${orgId}/invitedUsers/${inviteId}`
  ).withConverter(converter(InvitedUser))
  setDoc(docRef, { role }, { merge: true }).catch(error => {
    console.error(error)
    captureException(toSentry(error), {
      contexts: {
        updateInviteRole: {
          orgId,
          inviteId,
          role,
        },
      },
    })
  })
}
