import { Button, ButtonProps, Skeleton } from 'antd'
import { ComponentProps } from 'react'

export interface ConditionalSkeletonBtnProps extends ButtonProps {
  isSkeleton?: boolean
  skeletonProps?: ComponentProps<typeof Skeleton.Button>
}

export function ConditionalSkeletonBtn({
  isSkeleton,
  skeletonProps,
  ...props
}: ConditionalSkeletonBtnProps) {
  if (isSkeleton) {
    return <Skeleton.Button active {...skeletonProps} />
  }

  return <Button {...props} />
}
