// @generated by protoc-gen-connect-es v1.1.4 with parameter "target=ts"
// @generated from file perkup/v1/product_collections.proto (package perkup.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { UpdateCollectionProductRequest, UpdateCollectionProductSuperAdminRequest } from "./product_collections_pb.js";
import { Empty } from "../../google/protobuf/empty_pb.js";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service perkup.v1.ProductCollectionAdmin
 */
export const ProductCollectionAdmin = {
  typeName: "perkup.v1.ProductCollectionAdmin",
  methods: {
    /**
     * @generated from rpc perkup.v1.ProductCollectionAdmin.AddProductsToCollection
     */
    addProductsToCollection: {
      name: "AddProductsToCollection",
      I: UpdateCollectionProductRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc perkup.v1.ProductCollectionAdmin.RemoveProductsFromCollection
     */
    removeProductsFromCollection: {
      name: "RemoveProductsFromCollection",
      I: UpdateCollectionProductRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
  }
} as const;

/**
 * @generated from service perkup.v1.ProductCollectionSuperAdmin
 */
export const ProductCollectionSuperAdmin = {
  typeName: "perkup.v1.ProductCollectionSuperAdmin",
  methods: {
    /**
     * @generated from rpc perkup.v1.ProductCollectionSuperAdmin.AddProductsToCollection
     */
    addProductsToCollection: {
      name: "AddProductsToCollection",
      I: UpdateCollectionProductSuperAdminRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
  }
} as const;

/**
 * @generated from service perkup.v1.ProductCollectionSync
 */
export const ProductCollectionSync = {
  typeName: "perkup.v1.ProductCollectionSync",
  methods: {
    /**
     * @generated from rpc perkup.v1.ProductCollectionSync.SyncShopifySmartProductCollection
     */
    syncShopifySmartProductCollection: {
      name: "SyncShopifySmartProductCollection",
      I: Empty,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * sync function to look at productIds in productCollection and writes the collectionId into productVariants document
     *
     * @generated from rpc perkup.v1.ProductCollectionSync.SyncInternalProductCollectionAndVariants
     */
    syncInternalProductCollectionAndVariants: {
      name: "SyncInternalProductCollectionAndVariants",
      I: Empty,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc perkup.v1.ProductCollectionSync.CollectionProductInfoBackFill
     */
    collectionProductInfoBackFill: {
      name: "CollectionProductInfoBackFill",
      I: Empty,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * SyncProductVariantsCollections will
     *
     *  1. Delete invalid collectionId references
     *  2. make sure that hasNoCollection is correct
     *  3. make sure that swag products variant.boost is correct
     *
     * @generated from rpc perkup.v1.ProductCollectionSync.SyncProductVariantsCollections
     */
    syncProductVariantsCollections: {
      name: "SyncProductVariantsCollections",
      I: Empty,
      O: Empty,
      kind: MethodKind.Unary,
    },
  }
} as const;

