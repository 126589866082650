import GiftForm from 'pages/NewReward/components/GiftForm'
import { useSelectRewardContext } from '../SelectRewardContext'

export function GiftForm1() {
  const { selectedReward, giftCatalogForm, redemptionOptionsForm } =
    useSelectRewardContext()

  return (
    <GiftForm
      gift={selectedReward.gift}
      budget={selectedReward.budget}
      onGiftChange={({ changingConvertableTo, ...args }) => {
        // hack to make sure to always update convertableTo
        if (changingConvertableTo && args?.gift?.convertableTo) {
          redemptionOptionsForm.handleConvertableToChange(
            args.gift.convertableTo
          )
        } else {
          giftCatalogForm.handleUpdateGift(args)
        }
      }}
    />
  )
}
