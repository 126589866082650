import Color from 'color'
import { PERKUP_PRIMARY_COLOR } from 'constants/colors'
import { OrgContext } from 'context'
import { useContext } from 'react'

export function useDefaultOrgColors() {
  const org = useContext(OrgContext)
  const defaultColor = org?.primaryColor || PERKUP_PRIMARY_COLOR
  const backgroundColor = Color(defaultColor).lightness(95).toString()

  return { defaultColor, backgroundColor }
}
