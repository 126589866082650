import { UseCaseRecord } from 'pages/NewReward/types/forms'
import { RewardUseCasesKeysType } from './RewardUseCasesKeys'

export const otherUseCases = {
  DOG: {
    label: 'Dog',
    emailData: [],
    details: {
      searchQuery: 'dog',
      templateCategories: [],
      placidTags: [],
    },
  },
  CAT: {
    label: 'Cat',
    emailData: [],
    details: {
      searchQuery: 'cat',
      templateCategories: [],
      placidTags: [],
    },
  },
} satisfies Partial<Record<RewardUseCasesKeysType, UseCaseRecord>>
