import { LockFilled } from '@ant-design/icons'
import { PaymentElement, useCustomCheckout } from '@stripe/react-stripe-js'
import { Button, Flex, Form, message } from 'antd'
import { Text } from 'evergreen-ui'
import { useState } from 'react'
import { numToDollars } from 'utils'

export function CustomCheckoutForm({
  amount,
  onComplete,
  disabled = false,
}: {
  amount: number
  onComplete: (sessionId?: string) => void
  disabled?: boolean
}) {
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false)
  const [messageApi, contextHolder] = message.useMessage()

  const { confirm, canConfirm } = useCustomCheckout()

  const handleConfirmClick = () => {
    setIsLoadingSubmit(true)
    confirm({ redirect: 'if_required' }).then(result => {
      if (result.error) {
        messageApi.warning(result.error.message)
        return
      }
      onComplete(result.session.id)
    })
    // No need to set isLoadingSubmit to false, because the modal will close or we'll redirect
  }

  const disableCtaClick = isLoadingSubmit || disabled || !canConfirm

  return (
    <>
      {contextHolder}
      <Form onFinish={handleConfirmClick}>
        <Flex vertical gap={16}>
          <div style={{ margin: '-10px -12px' }}>
            <PaymentElement id="customStripeElement" />
          </div>
          <Button
            size="large"
            type="primary"
            loading={isLoadingSubmit}
            icon={<LockFilled />}
            htmlType="submit"
            disabled={disableCtaClick}
          >
            Pay {numToDollars(amount, 2)}
          </Button>
          <Text color="muted">
            By confirming your payment, you allow PerkUp Inc. to charge your
            card for this payment in accordance with their terms.
          </Text>
        </Flex>
      </Form>
    </>
  )
}
