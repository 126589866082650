// @generated by protoc-gen-connect-es v1.1.4 with parameter "target=ts"
// @generated from file shopify/catalog.proto (package shopify, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { UpdateCatalogProductRequest } from "./catalog_pb.js";
import { Empty } from "../google/protobuf/empty_pb.js";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service shopify.Catalog
 */
export const Catalog = {
  typeName: "shopify.Catalog",
  methods: {
    /**
     * @generated from rpc shopify.Catalog.UpdateProduct
     */
    updateProduct: {
      name: "UpdateProduct",
      I: UpdateCatalogProductRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
  }
} as const;

