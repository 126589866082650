import { UploadOutlined } from '@ant-design/icons'
import { Flatfile } from '@flatfile/api'
import { FlatfileRecord } from '@flatfile/hooks'
import {
  FlatfileProvider,
  Sheet,
  Space,
  useEvent,
  useFlatfile,
} from '@flatfile/react'
import { Button } from 'antd'
import { FLATFILE_PUBLIC_KEY } from 'constants/keys'
import { OrgContext } from 'context'
import { useDefaultOrgColors } from 'hooks'
import { compact } from 'lodash-es'
import { useContext } from 'react'
import { createSpaceConfig, isEmail } from 'utils'

interface EmailSheetData {
  records?: {
    values?: {
      email?: {
        value?: string
      }
    }
  }[]
}

enum EmailSheetKeys {
  email = 'email',
}

const emailSheetConfig: Flatfile.SheetConfig = {
  name: 'Recipients',
  slug: 'recipients-sheet',
  fields: [
    {
      key: EmailSheetKeys.email,
      type: 'string',
      label: 'Email',
    },
  ],
}

const verifyEmailRecord = (record: FlatfileRecord) => {
  const email = record.get(EmailSheetKeys.email)
  const cleanedEmail = email?.toString().trim().toLowerCase()

  if (cleanedEmail && isEmail(cleanedEmail)) {
    record.set(EmailSheetKeys.email, cleanedEmail)
  } else {
    record.addError(EmailSheetKeys.email, 'Invalid email address')
  }

  return record
}

function EmailsSpace({
  onSubmit,
}: {
  onSubmit: (emails: string[]) => Promise<void>
}) {
  const { name: orgName, logoUrl } = useContext(OrgContext)
  const { defaultColor } = useDefaultOrgColors()

  const { open, openPortal, closePortal } = useFlatfile()

  // This will close the Portal instance when you confirm the dialog after the Workbook onSubmit function runs
  useEvent(
    'job:outcome-acknowledged',
    {
      operation: `sheetSubmitAction-${emailSheetConfig.slug}`,
      status: 'complete',
    },
    () => closePortal()
  )
  return (
    <>
      <Button
        icon={<UploadOutlined />}
        onClick={() => (open ? closePortal() : openPortal())}
      >
        Upload CSV
      </Button>
      <Space config={createSpaceConfig(orgName, defaultColor, logoUrl, false)}>
        <Sheet
          config={emailSheetConfig}
          onRecordHook={verifyEmailRecord}
          onSubmit={async ({ sheet }) => {
            const data = (await sheet.allData()) as unknown as EmailSheetData
            const emails = compact(
              data?.records?.map(record => {
                const email = record.values?.email?.value
                if (email && isEmail(email)) return email // We need to run the check again because the user can still submit invalid emails
                return undefined
              })
            )
            await onSubmit(emails)
          }}
        />
      </Space>
    </>
  )
}

export function EmailCsvUploadButton({
  onSubmit,
}: {
  onSubmit: (emails: string[]) => Promise<void>
}) {
  return (
    <FlatfileProvider publishableKey={FLATFILE_PUBLIC_KEY}>
      <EmailsSpace onSubmit={onSubmit} />
    </FlatfileProvider>
  )
}
