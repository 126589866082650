import { captureException } from '@sentry/react'
import { callFunction } from 'api/functionCalls'
import { isEmpty } from 'lodash-es'
import { SageFullProductDetail } from 'types'
import { SageListItem, SageSearchFilters } from 'types/sage'
import { toSentry } from 'utils'

export async function listSageProducts({
  filters,
}: {
  filters: SageSearchFilters
}) {
  try {
    const response = await callFunction('sage-ProductSearch', {
      search: {
        ...filters,
        quickSearch: filters.quickSearch || 'tumblers',
        esg: !isEmpty(filters.esg) ? filters.esg?.join(',') : undefined,
        categories: !isEmpty(filters.categories)
          ? filters.categories?.join(',')
          : undefined,
      },
    })

    if (!response?.products) {
      throw new Error('No products found')
    }

    return response.products
  } catch (error) {
    console.error(error)
    captureException(toSentry(error), {
      contexts: {
        getSageProducts: {
          filters,
          error,
        },
      },
    })
    return []
  }
}

export async function getSageList({ listType }: { listType: string }) {
  try {
    const response = await callFunction('sage-ResearchList', {
      listType,
    })

    if (!response?.items) {
      throw new Error(`Could not find reasearch list for ${listType}`)
    }

    return response.items as SageListItem[]
  } catch (error) {
    console.error(error)
    captureException(toSentry(error), {
      contexts: {
        getSageProducts: {
          listType,
          error,
        },
      },
    })
    return []
  }
}

export async function getBasicProductDetails(productId: string) {
  try {
    const res = await callFunction('sage-BasicProductDetail', {
      productId,
    })
    if (!res.product) {
      throw new Error('Product not found')
    }

    return res.product
  } catch (e) {
    console.error(e)
    captureException(toSentry(e), {
      contexts: {
        getBasicProductDetails: {
          productId,
        },
      },
    })
    return undefined
  }
}

export async function getFullProductDetails(productId: string) {
  try {
    const res = await callFunction('sage-FullProductDetail', {
      productId,
    })
    if (!res.product) {
      throw new Error('Product not found')
    }

    return res.product as SageFullProductDetail
  } catch (e) {
    console.error(e)
    captureException(toSentry(e), {
      contexts: {
        getFullProductDetails: {
          productId,
        },
      },
    })
    return undefined
  }
}
