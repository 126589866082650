import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import { Button, Image, Input, Select, Skeleton } from 'antd'
import {
  CountryIconGroup,
  PerkImage,
  PerkLoader,
  ProductOptions,
  VariantEstimatedShippingTime,
} from 'components'
import { PERKUP_ORG_ID, isProduction } from 'constants/keys'
import { ExchangeRateContext, OrgContext, UserContext } from 'context'
import { Heading, Text } from 'evergreen-ui'
import { CanvasDesign_Option } from 'gen/canvas/designs_pb'
import { ProductVariant } from 'gen/perkup/v1/product_variant_pb'
import {
  useCustomerCanvasProduct,
  useDesignVariables,
  useGeneratePreviewImage,
} from 'hooks'
import { useGetFolderByFullPath } from 'hooks/customer-canvas/use-get-folder-by-org-id'
import parse from 'html-react-parser'
import { compact, flatMap } from 'lodash-es'
import {
  PropsWithChildren,
  createContext,
  useContext,
  useMemo,
  useState,
} from 'react'
import {
  Controller,
  FormProvider,
  useForm,
  useFormContext,
  useFormState,
  useWatch,
} from 'react-hook-form'
import {
  CustomerCanvasOption,
  CustomerCanvasProduct,
  DesignVariable,
  ProductRetailsData,
  SelectedVariantOption,
} from 'types'
import {
  buildAssetBucketString,
  buildPublicImageReferenceString,
  extractFileNameFromVariableValue,
  findDesignFileByVariantId,
  findMockupFilesByVariantId,
  numToDollars,
  removeHtmlTags,
  sortMockupsByName,
} from 'utils'

const SELECTED_VARIABLES_KEY = 'selectedVariables'
const SELECTED_CUSTOMER_CANVAS_VARIANT_ID_KEY =
  'selectedCustomerCanvasVariantId'
const SELECTED_CUSTOMER_CANVAS_OPTION_KEY = 'selectedCustomerCanvasOption'
const SELECTED_PRODUCT_VARIANT_KEY = 'selectedProductVariant'

const wiseOrgId = '67lFuvCqMmeM7iK9rugh' // This only gets used in local dev and our org (Perkup) production
const aegOrgId = 'URUzZhaCE6ejdqUDGpGr' // This only gets used in local dev and our org (Perkup) production

interface ProductRetailsFormDataType {
  [SELECTED_CUSTOMER_CANVAS_VARIANT_ID_KEY]: number | undefined
  [SELECTED_CUSTOMER_CANVAS_OPTION_KEY]: CustomerCanvasOption | undefined
  [SELECTED_PRODUCT_VARIANT_KEY]: ProductVariant | undefined
  [SELECTED_VARIABLES_KEY]: Record<string, DesignVariable>
}

interface ProductRetailsContextType {
  productVariant: ProductVariant
  customerCanvasProduct: CustomerCanvasProduct
}

const ProductRetailsContext = createContext<ProductRetailsContextType>(
  {} as ProductRetailsContextType
)

function ProductRetailsContextProvider({
  productVariant,
  children,
}: PropsWithChildren<{ productVariant: ProductVariant }>) {
  const { product, hasLoaded } = useCustomerCanvasProduct({
    referenceId: productVariant.productId,
  })

  const value = useMemo(
    () => ({
      productVariant,
      customerCanvasProduct: product as CustomerCanvasProduct,
    }),
    [productVariant, product]
  )

  if (!hasLoaded || !value.customerCanvasProduct) return <PerkLoader />

  return (
    <ProductRetailsContext.Provider value={value}>
      {children}
    </ProductRetailsContext.Provider>
  )
}

function ProductRetailsVariants({
  optionsToExclude = [],
  defaultSelectedOptions = {},
}: {
  optionsToExclude?: string[]
  defaultSelectedOptions?: Record<string, string>
}) {
  const { id: orgId } = useContext(OrgContext)
  const { setValue, getValues } = useFormContext()
  const { productVariant, customerCanvasProduct } = useContext(
    ProductRetailsContext
  )

  const { options } = productVariant
  const optionKeys = Object.keys(options || {})
  const hasOptions = optionKeys.length > 0

  if (!hasOptions) return null

  const handleSelectProductVariant = (
    selectedProductVariant: ProductVariant | undefined
  ) => {
    setValue(SELECTED_PRODUCT_VARIANT_KEY, selectedProductVariant)
  }

  const handleOptionSelect = (selectedPVOption: SelectedVariantOption) => {
    // We need to somehow find the associated customer canvas variant id to the desired shopify variant

    const selectedCustomerCanvasVariant = customerCanvasProduct.variants.find(
      variant =>
        variant.productVariantOptions.some(canvasOption => {
          const canvasOptionName = canvasOption.productOptionTitle.toLowerCase()
          return (
            selectedPVOption[canvasOptionName] ===
            canvasOption.productOptionValueTitle
          )
        })
    )

    // I know this is backwards but I needed to sprint
    const selectedCustomerCanvasOption =
      selectedCustomerCanvasVariant?.productVariantOptions.find(
        canvasOption =>
          canvasOption.productOptionTitle.toLowerCase() in selectedPVOption
      )

    if (!selectedCustomerCanvasVariant || !selectedCustomerCanvasOption) return

    setValue(SELECTED_CUSTOMER_CANVAS_OPTION_KEY, selectedCustomerCanvasOption)

    setValue(
      SELECTED_CUSTOMER_CANVAS_VARIANT_ID_KEY,
      selectedCustomerCanvasVariant.id
    )

    const currentVariables = getValues()[SELECTED_VARIABLES_KEY]

    // If our customer canvas product has icon variables, we need to swap their bucket paths when the user changes the variant
    const iconVariableKeys = Object.keys(currentVariables).filter(key =>
      key.includes('Icon')
    )

    // Silly turnary to just helps us in local dev and our org (Perkup) production to play around with the differnt org products without having to manually switch between them
    const playgroundIds = productVariant.productName.includes('AEG')
      ? aegOrgId
      : wiseOrgId

    // In local dev and our org (Perkup) production, we want to play around with Wise and AEG's org products
    const orgIdToUse =
      !isProduction || (isProduction && orgId === PERKUP_ORG_ID)
        ? playgroundIds
        : orgId

    // For each of the icon variables, we need to swap the bucket path to the associated new variant's bucket path
    iconVariableKeys.forEach(iconVariableKey => {
      const currentIconVariable: DesignVariable =
        currentVariables[iconVariableKey]

      const currentIconFileName = extractFileNameFromVariableValue(
        currentIconVariable.value
      )

      const updatedIconBucketPath = buildAssetBucketString({
        variable: currentIconVariable,
        optionValueTitle: selectedCustomerCanvasOption.productOptionValueTitle,
        orgId: orgIdToUse,
      })

      // Using the old file name, but with the new bucket path, we now have have our new appropriate icon reference!
      const updatedIconVariable: DesignVariable = {
        name: currentIconVariable.name,
        value: buildPublicImageReferenceString({
          fullPath: updatedIconBucketPath,
          fileName: currentIconFileName,
        }),
        type: currentIconVariable.type,
      }

      setValue(
        `${SELECTED_VARIABLES_KEY}.${currentIconVariable.name}`,
        updatedIconVariable
      )
    })
  }

  return (
    <ProductOptions
      product={productVariant}
      onSelectProductVariant={handleSelectProductVariant}
      onOptionSelect={handleOptionSelect}
      showOptionsAlerts
      optionsToExclude={optionsToExclude}
      defaultOptions={defaultSelectedOptions}
    />
  )
}

function ProductRetailsGeneratedImages() {
  const { customerCanvasProduct, productVariant } = useContext(
    ProductRetailsContext
  )

  const { variants, mockups, designs } = customerCanvasProduct

  const [selectedCustomerCanvasVariantId, selectedVariables] = useWatch({
    name: [SELECTED_CUSTOMER_CANVAS_VARIANT_ID_KEY, SELECTED_VARIABLES_KEY],
  })

  const [activeIndex, setActiveIndex] = useState(0)

  const imageGenerationVariables = useMemo(() => {
    const variantIdToUse = selectedCustomerCanvasVariantId || variants[0].id

    // Try and find the associated design file id to the selected variant, if not, use the first design file id
    const designFileIdToUse =
      findDesignFileByVariantId(designs, variantIdToUse)?.designId ||
      designs[0].designId

    // Try and find the associated mockup ids to the selected variant, if not, use the first mockup id
    const mockupIdsToUse = findMockupFilesByVariantId(mockups, variantIdToUse)

    const variablesToUse: DesignVariable[] = flatMap(selectedVariables)

    return {
      designId: designFileIdToUse,
      mockupIds: sortMockupsByName(mockupIdsToUse).map(
        mockup => mockup.mockupId
      ),
      variables: variablesToUse,
    }
  }, [
    designs,
    mockups,
    variants,
    selectedCustomerCanvasVariantId,
    selectedVariables,
  ])

  const { isLoading, previewImages } = useGeneratePreviewImage(
    imageGenerationVariables
  )

  return (
    <article className="flex-1 flex flex-col gap-2 min-w-80">
      <section className="relative rounded-lg overflow-hidden aspect-square w-full">
        <PerkImage
          preview
          src={
            previewImages[activeIndex] || productVariant.productImages[0].src // This renders the shopify product image while the preview image is being generated
          }
          sizes="100vw"
        />

        {isLoading && (
          <div className="absolute top-0 left-0 w-full h-full bg-gradient-to-br from-gray-300 to-gray-500 animate-pulse opacity-80" />
        )}
      </section>

      <section className="flex gap-2">
        {previewImages.map((image, index) => (
          // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
          <div
            key={image}
            data-active={activeIndex === index}
            className="relative cursor-pointer rounded-lg overflow-hidden aspect-square w-28 data-[active=true]:border data-[active=true]:border-primary flex justify-center items-center"
            onClick={() => setActiveIndex(index)}
          >
            {previewImages[index] && (
              <PerkImage src={previewImages[index]} sizes="10vw" width={96} />
            )}

            {isLoading && (
              <div className="absolute top-0 left-0 w-full h-full bg-gradient-to-br from-gray-300 to-gray-500 animate-pulse opacity-80" />
            )}
          </div>
        ))}
      </section>
    </article>
  )
}

function ProductRetailsDescription() {
  const {
    productVariant: { description },
  } = useContext(ProductRetailsContext)

  if (!description) return null

  return (
    <div className="flex flex-col gap-2">
      <Heading>Description</Heading>

      <Text>{parse(description)}</Text>
    </div>
  )
}

function AssetController({ variable }: { variable: DesignVariable }) {
  const { id: orgId } = useContext(OrgContext)

  const {
    customerCanvasProduct: { variants },
    productVariant,
  } = useContext(ProductRetailsContext)
  const { control } = useFormContext()

  const selectedOption: CustomerCanvasOption | undefined =
    useWatch({
      name: SELECTED_CUSTOMER_CANVAS_OPTION_KEY,
    }) || variants[0].productVariantOptions[0]

  // Silly turnary to just helps us in local dev and our org (Perkup) production to play around with the differnt org products without having to manually switch between them
  const playgroundIds = productVariant.productName.includes('AEG')
    ? aegOrgId
    : wiseOrgId

  // In local dev and our org (Perkup) production, we want to play around with Wise and AEG's org products
  const orgIdToUse =
    !isProduction || (isProduction && orgId === PERKUP_ORG_ID)
      ? playgroundIds
      : orgId

  const fullAssetBucketPath = buildAssetBucketString({
    variable,
    optionValueTitle: selectedOption?.productOptionValueTitle,
    orgId: orgIdToUse,
  })

  const { entities, hasLoaded } = useGetFolderByFullPath({
    fullPath: fullAssetBucketPath,
  })

  const [imageRange, setImageRange] = useState([0, 10])
  const [start, end] = imageRange

  if (!hasLoaded)
    return (
      <section className="flex items-center gap-4">
        {/** Preview button */}
        <Button className="min-w-8" icon={<LeftOutlined />} disabled />
        <div className="grid grid-cols-5 gap-2">
          {new Array(10).fill(0).map((_, index) => (
            <div
              className="aspect-square rounded-lg bg-gray-400 animate-pulse"
              key={`${Number(index)}`}
            />
          ))}
        </div>
        <Button className="min-w-8" icon={<RightOutlined />} disabled />
      </section>
    )

  if (entities.length === 0) return <Text>No images found</Text>

  const safeDefaultValue: DesignVariable = {
    name: variable.name,
    value: buildPublicImageReferenceString({
      fileName: entities[0].name,
      fullPath: fullAssetBucketPath,
    }),
    type: variable.type,
  }

  return (
    <Controller
      control={control}
      name={`${SELECTED_VARIABLES_KEY}.${variable.name}`}
      defaultValue={safeDefaultValue}
      render={({ field: { value: currentVariable, onChange } }) => (
        <section className="flex items-center gap-4">
          {/** Preview button */}
          <Button
            className="min-w-8"
            icon={<LeftOutlined />}
            disabled={start === 0} // Disable if we're at the start
            onClick={() => {
              const newRange = [start - 10, end - 10]
              setImageRange(newRange)
            }}
          />
          <div className="flex-1 w-full grid grid-cols-5 gap-2">
            {entities.slice(start, end).map((entity, index) => {
              const imageReference = buildPublicImageReferenceString({
                fileName: entity.name,
                fullPath: fullAssetBucketPath,
              })

              return (
                <div
                  key={entity.id}
                  data-selected={currentVariable.value === imageReference}
                  className="flex justify-center items-center aspect-square rounded-lg cursor-pointer data-[selected=true]:border data-[selected=true]:border-primary data-[selected=true]:bg-primary/10 overflow-hidden"
                >
                  <Image
                    width="100%"
                    preview={false}
                    onClick={() =>
                      onChange({
                        name: currentVariable.name,
                        value: imageReference,
                        type: currentVariable.type,
                      })
                    }
                    src={entity.previews['backoffice-preview']?.url}
                    alt={`Option ${index + 1}`}
                  />
                </div>
              )
            })}
          </div>
          {/** Next button */}
          <Button
            className="min-w-8"
            icon={<RightOutlined />}
            disabled={end >= entities.length} // Disable if we're at the end
            onClick={() => {
              const [start, end] = imageRange
              const newRange = [start + 10, end + 10]
              setImageRange(newRange)
            }}
          />
        </section>
      )}
    />
  )
}

function SelectController({ variable }: { variable: DesignVariable }) {
  const { control } = useFormContext() // retrieve all hook methods

  const wiseCoordinates = [
    {
      label: 'ATX',
      value: "30° 23' 40'' N 97° 43' 19'' W",
    },
    {
      label: 'BDP',
      value: "47° 31' 35'' N 19° 03' 48'' E",
    },
    {
      label: 'BRU',
      value: "50° 50' 13.2'' N 4° 22' 15.1'' E",
    },
    {
      label: 'CHK',
      value: "49° 26' 20'' N 32° 03' 38'' E",
    },
    {
      label: 'KL',
      value: "3° 07' 41'' N 101° 40' 43'' E",
    },
    {
      label: 'LDN',
      value: "51° 31' 20'' N 0° 05' 00'' W",
    },
    {
      label: 'NYC',
      value: "40° 44' 39'' N 73° 59' 25'' W",
    },
    {
      label: 'SAO',
      value: "23° 33' 15'' S 46° 41' 25'' W",
    },
    {
      label: 'SGP',
      value: "1° 19' 03'' N 103° 53' 38'' E",
    },
    {
      label: 'TLN',
      value: "59° 25' 26.508'' N 24° 44' 56.976'' E",
    },
    {
      label: 'TPA',
      value: "27° 58' 42'' N 82° 32' 59'' W",
    },
  ]

  const safeDefaultValue: DesignVariable = {
    name: variable.name,
    value: wiseCoordinates[0].value,
    type: variable.type,
  }

  return (
    <Controller
      control={control}
      name={`${SELECTED_VARIABLES_KEY}.${variable.name}`}
      defaultValue={safeDefaultValue}
      render={({ field: { value: currentVariable, onBlur, onChange } }) => (
        <Select
          value={currentVariable.value}
          onBlur={onBlur}
          options={wiseCoordinates}
          onChange={value =>
            onChange({
              name: currentVariable.name,
              value,
              type: currentVariable.type,
            })
          }
        />
      )}
    />
  )
}

function InputController({ variable }: { variable: DesignVariable }) {
  const { control } = useFormContext()
  const { defaultValues } = useFormState<ProductRetailsFormDataType>()

  const defaultVariableValues = defaultValues?.[SELECTED_VARIABLES_KEY]
  const defaultVariableValue = defaultVariableValues?.[variable.name]

  const safeDefaultValue: DesignVariable = {
    name: variable.name,
    value: removeHtmlTags(variable.value), // Sometimes these come in with HTML tags by accident from the design files
    type: variable.type,
  }

  return (
    <Controller
      control={control}
      name={`${SELECTED_VARIABLES_KEY}.${variable.name}`}
      defaultValue={safeDefaultValue}
      render={({ field: { value: currentVariable, onChange, onBlur } }) => {
        return (
          <Input
            placeholder="Enter your text here"
            defaultValue={
              defaultVariableValue ? defaultVariableValue.value : undefined
            }
            onBlur={e => {
              onBlur()
              onChange({
                name: currentVariable.name,
                value: e.currentTarget.value,
                type: currentVariable.type,
              })
            }}
          />
        )
      }}
    />
  )
}

function ProductRetailsInputs() {
  const {
    customerCanvasProduct: { designs },
  } = useContext(ProductRetailsContext)

  const variantId: number | undefined = useWatch({
    name: SELECTED_CUSTOMER_CANVAS_VARIANT_ID_KEY,
  })

  // Try and find the associated design file id to the selected variant, if not, use the first design file id
  const designFileIdToUse =
    findDesignFileByVariantId(designs, variantId)?.designId ||
    designs[0].designId

  const { variables, hasLoaded } = useDesignVariables(designFileIdToUse)

  if (!hasLoaded) return <Skeleton active paragraph={{ rows: 8 }} />

  if (variables.length === 0) return <Text>No design variables found</Text>

  const cleanDesignVariableLabel = (variableName: string) => {
    if (variableName.includes('Icon')) {
      // If the design file has more than one icon variable, then they'll be structured like so: Icon_outlined_1, Icon_filled_1, Icon_outlined_2, Icon_filled_2, etc.
      const iconNumber: string | undefined = variableName.split('_')?.[2]

      if (iconNumber) return `Icon ${iconNumber}`

      // If the icon variable doesn't have a number, then it's just a single icon variable
      return 'Icon'
    }
    return variableName.replace(/_/g, ' ')
  }

  return (
    <section className="flex flex-col gap-4">
      {variables.map(variable => (
        <div key={variable.name} className="flex flex-col gap-1">
          <Heading size={300}>
            {cleanDesignVariableLabel(variable.name)}
          </Heading>

          {variable.type === 'Text' &&
            variable.name === 'Front_design_coordinates' && (
              <SelectController variable={variable} />
            )}

          {variable.type === 'Text' &&
            variable.name !== 'Front_design_coordinates' && (
              <InputController variable={variable} />
            )}

          {variable.type === 'Image' && <AssetController variable={variable} />}
        </div>
      ))}
    </section>
  )
}

function ProductShipping() {
  const { productVariant } = useContext(ProductRetailsContext)

  const { shippingCountries } = productVariant

  const shippingCountriesToDisplay = compact(shippingCountries)

  if (shippingCountriesToDisplay.length === 0) return null

  return (
    <div className="flex flex-col gap-2">
      <CountryIconGroup iso3s={shippingCountries} />
      <VariantEstimatedShippingTime productVariant={productVariant} />
    </div>
  )
}

function ProductRetailsPrice({ inUsd = false }: { inUsd?: boolean }) {
  const {
    productVariant: { amount },
  } = useContext(ProductRetailsContext)
  const org = useContext(OrgContext)
  const user = useContext(UserContext)
  const exchangeRate = useContext(ExchangeRateContext)

  const safeAmount = Number(amount || 0)

  const displayAmountInUsd = numToDollars(safeAmount)
  const displayAmountInLocal = numToDollars(
    safeAmount * exchangeRate,
    2,
    false,
    user.displayCurrency || org?.displayCurrency
  )

  const amountToDisplay = inUsd ? displayAmountInUsd : displayAmountInLocal

  return (
    <div className="flex gap-4 items-center">
      <Heading size={500}>{amountToDisplay}</Heading>
      <Text color="muted">FREE shipping</Text>
    </div>
  )
}

function ProductRetailsTitle() {
  const {
    productVariant: { productName },
  } = useContext(ProductRetailsContext)

  return <Heading size={700}>{productName}</Heading>
}

function ProductRetailsForm({
  children,
  onRetailSubmit,
}: PropsWithChildren<{
  onRetailSubmit: (data: ProductRetailsData) => void
}>) {
  const { productVariant, customerCanvasProduct } = useContext(
    ProductRetailsContext
  )

  const { designs, variants, mockups } = customerCanvasProduct

  const { handleSubmit } = useFormContext<ProductRetailsFormDataType>()

  const handleFormSubmit = (data: ProductRetailsFormDataType) => {
    const variantIdToUse =
      data[SELECTED_CUSTOMER_CANVAS_VARIANT_ID_KEY] || variants[0].id

    const mockupsForDesiredVariant = findMockupFilesByVariantId(
      mockups,
      variantIdToUse
    )

    const optionToUse =
      data[SELECTED_CUSTOMER_CANVAS_OPTION_KEY] ||
      variants[0].productVariantOptions[0]

    const retailsReturn: ProductRetailsData = {
      storefrontProductId: productVariant.productId,
      formData: {
        selectedCustomerCanvasDesignId:
          findDesignFileByVariantId(designs, variantIdToUse)?.designId ||
          designs[0].designId,
        selectedCustomerCanvasVariantId: String(variantIdToUse),
        selectedCustomerCanvasOption: new CanvasDesign_Option({
          name: optionToUse.productOptionTitle,
          values: [optionToUse.productOptionValueTitle],
        }),
        selectedCustomerCanvasMockupIds: sortMockupsByName(
          mockupsForDesiredVariant
        ).map(mockup => mockup.mockupId),
        selectedProductVariant:
          data[SELECTED_PRODUCT_VARIANT_KEY] ?? productVariant,
        selectedVariables: flatMap(data[SELECTED_VARIABLES_KEY]),
      },
    }

    onRetailSubmit(retailsReturn)
  }

  return (
    <form
      className="flex-1 flex flex-col gap-4 min-w-80"
      onSubmit={handleSubmit(handleFormSubmit)}
    >
      {children}
    </form>
  )
}

function ProductRetails({
  productVariant,
  children,
  onSubmit,
  defaultValues = {} as ProductRetailsFormDataType,
}: PropsWithChildren<{
  productVariant: ProductVariant
  onSubmit: (formData: ProductRetailsData) => void
  defaultValues?: ProductRetailsFormDataType
}>) {
  const {
    selectedCustomerCanvasVariantId,
    selectedProductVariant,
    selectedVariables,
    selectedCustomerCanvasOption,
  } = defaultValues

  const methods = useForm<ProductRetailsFormDataType>({
    defaultValues: {
      [SELECTED_CUSTOMER_CANVAS_VARIANT_ID_KEY]:
        selectedCustomerCanvasVariantId,
      [SELECTED_CUSTOMER_CANVAS_OPTION_KEY]: selectedCustomerCanvasOption,
      [SELECTED_PRODUCT_VARIANT_KEY]: selectedProductVariant,
      [SELECTED_VARIABLES_KEY]: selectedVariables,
    },
    shouldUnregister: false,
  })

  return (
    <ProductRetailsContextProvider productVariant={productVariant}>
      <FormProvider {...methods}>
        <article className="flex flex-wrap gap-6 w-full">
          <ProductRetailsGeneratedImages />
          <ProductRetailsForm onRetailSubmit={onSubmit}>
            {children}
          </ProductRetailsForm>
        </article>
      </FormProvider>
    </ProductRetailsContextProvider>
  )
}

ProductRetails.Title = ProductRetailsTitle
ProductRetails.Price = ProductRetailsPrice
ProductRetails.Shipping = ProductShipping
ProductRetails.Inputs = ProductRetailsInputs
ProductRetails.Description = ProductRetailsDescription
ProductRetails.Variants = ProductRetailsVariants

export default ProductRetails
