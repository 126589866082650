import { ListBulkSwagCatalogsByOrgId } from 'api/databaseCalls'
import { OrgContext } from 'context'
import { BulkSwagCatalog } from 'gen/perkup/v1/contentful_pb'
import { useContext, useEffect, useState } from 'react'

export function useBulkSwagCatalogs() {
  const { id: orgId } = useContext(OrgContext)
  const [hasLoaded, setHasLoaded] = useState(false)
  const [bulkSwagCatalogs, setBulkSwagCatalogs] = useState<BulkSwagCatalog[]>(
    []
  )
  useEffect(() => {
    ListBulkSwagCatalogsByOrgId(orgId)
      .then(setBulkSwagCatalogs)
      .finally(() => setHasLoaded(true))
  }, [orgId])

  return { bulkSwagCatalogs, hasLoaded }
}
