import useIds from 'hooks/useIds'
import { useMemo } from 'react'
import { ProductVariantsSearchClient } from 'services/algolia'

export function useProductVariantSearchClient() {
  const { orgId } = useIds()

  const searchClient = useMemo(
    () => ProductVariantsSearchClient(orgId),
    [orgId]
  )

  return { searchClient }
}
