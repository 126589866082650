import { UploadOutlined } from '@ant-design/icons'
import { captureException } from '@sentry/react'
import { Button, Image, UploadFile } from 'antd'
import { UploadChangeParam } from 'antd/es/upload'
import Upload from 'antd/es/upload/Upload'
import { deleteOrgImage, uploadOrgImage } from 'api/databaseCalls'
import { custom_upload } from 'assets/icons'
import { CustomCarousel } from 'components/Carousels/CustomCarousel'
import { CarouselImageCard } from 'components/Images/CarouselImageCard'
import { CUSTOM_IMAGE_URL_SUFFIX } from 'constants/images'
import { Pane, Text } from 'evergreen-ui'
import useIds from 'hooks/useIds'
import { useEffect, useState } from 'react'
import { ListFirebaseStorageImages } from 'services/images'
import { FirebaseStorageImage } from 'types'
import { toSentry } from 'utils'
import { tempateImageHeight, tempateImageWidth } from 'utils/templates'

export function UploadCustomImage({
  onBannerClick,
  useCarousel = false,
  handleSeeMoreClick,
}: {
  onBannerClick: (banner: string) => void
  useCarousel?: boolean
  handleSeeMoreClick?: () => void
}) {
  const { orgId } = useIds()

  const [customImages, setCustomImages] = useState<FirebaseStorageImage[]>([])
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (orgId) {
      ListFirebaseStorageImages({
        orgId,
        suffix: CUSTOM_IMAGE_URL_SUFFIX,
      }).then(setCustomImages)
    }
  }, [orgId])

  const handleImageUpload = (upload: UploadChangeParam<UploadFile<any>>) => {
    if (upload.file.percent === 100) {
      setIsLoading(true)
      uploadOrgImage({ orgId, upload, directory: CUSTOM_IMAGE_URL_SUFFIX })
        .then(newImage => {
          if (newImage)
            setCustomImages([
              { name: newImage.metaData.name, url: newImage.url },
              ...customImages,
            ])
        })
        .catch(error => {
          console.error(error)
          captureException(toSentry(error), {
            contexts: {
              UploadCustomImage: { orgId },
            },
          })
        })
        .finally(() => setIsLoading(false))
    }
  }

  const handleDeleteUploadedImage = (imageToDelete: FirebaseStorageImage) => {
    setCustomImages(
      customImages.filter(image => image.url !== imageToDelete.url)
    )
    deleteOrgImage({
      orgId,
      directory: CUSTOM_IMAGE_URL_SUFFIX,
      imageName: imageToDelete.name,
    })
  }

  const uploadButton = (
    <Upload
      key="upload"
      onChange={handleImageUpload}
      maxCount={1}
      accept="image/*"
      showUploadList={false}
    >
      <Pane
        height={tempateImageHeight}
        width={tempateImageWidth}
        border="muted"
        display="flex"
        justifyContent="center"
        alignItems="center"
        borderRadius={8}
        marginY={4}
        hoverElevation={1}
      >
        <Button
          style={{ width: '100%', height: '100%' }}
          loading={isLoading}
          type="text"
          icon={<UploadOutlined />}
        >
          Upload
        </Button>
      </Pane>
    </Upload>
  )
  const imageCarouselItems = customImages.map(customImage => {
    return (
      <CarouselImageCard
        key={customImage.url}
        imageUrl={customImage.url}
        onClick={() => onBannerClick(customImage.url)}
        onDeleteClick={() => handleDeleteUploadedImage(customImage)}
      />
    )
  })

  const children = [uploadButton, ...imageCarouselItems]

  const dimensionNote = 'Suggested dimensions: 1920 x 820 or 21:9'

  return (
    <Pane>
      {useCarousel && (
        <CustomCarousel
          header="Custom"
          headerIcon={<Image preview={false} height={16} src={custom_upload} />}
          showSeeAllButton
          handleCTAText="See more"
          handleSeeMoreClick={handleSeeMoreClick}
          headerNote={dimensionNote}
        >
          {children}
        </CustomCarousel>
      )}
      {!useCarousel && (
        <Pane display="flex" flexDirection="column" gap={8}>
          <Text color="muted">{dimensionNote}</Text>
          <Pane display="flex" flexWrap="wrap" gap={16}>
            {children}
          </Pane>
        </Pane>
      )}
    </Pane>
  )
}
