// @generated by protoc-gen-es v1.6.0 with parameter "target=ts"
// @generated from file shopify/admin.proto (package shopify, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";
import { Collection, CollectionInput, MoveInput, Product, ProductInput } from "../shopifyapi/admingql_pb.js";

/**
 * @generated from message shopify.CollectionRequest
 */
export class CollectionRequest extends Message<CollectionRequest> {
  /**
   * Collection ID of the form `gid://shopify/Collection/{id}`
   *
   * @generated from field: optional string id = 1;
   */
  id?: string;

  /**
   * optional int32 products_per_collection = 2;
   *
   * @generated from field: optional string handle = 2;
   */
  handle?: string;

  constructor(data?: PartialMessage<CollectionRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "shopify.CollectionRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 2, name: "handle", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CollectionRequest {
    return new CollectionRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CollectionRequest {
    return new CollectionRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CollectionRequest {
    return new CollectionRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CollectionRequest | PlainMessage<CollectionRequest> | undefined, b: CollectionRequest | PlainMessage<CollectionRequest> | undefined): boolean {
    return proto3.util.equals(CollectionRequest, a, b);
  }
}

/**
 * @generated from message shopify.CreateCollectionRequest
 */
export class CreateCollectionRequest extends Message<CreateCollectionRequest> {
  /**
   * @generated from field: string title = 1;
   */
  title = "";

  constructor(data?: PartialMessage<CreateCollectionRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "shopify.CreateCollectionRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateCollectionRequest {
    return new CreateCollectionRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateCollectionRequest {
    return new CreateCollectionRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateCollectionRequest {
    return new CreateCollectionRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CreateCollectionRequest | PlainMessage<CreateCollectionRequest> | undefined, b: CreateCollectionRequest | PlainMessage<CreateCollectionRequest> | undefined): boolean {
    return proto3.util.equals(CreateCollectionRequest, a, b);
  }
}

/**
 * @generated from message shopify.CollectionResponse
 */
export class CollectionResponse extends Message<CollectionResponse> {
  /**
   * @generated from field: shopifyapi.Collection collection = 1;
   */
  collection?: Collection;

  constructor(data?: PartialMessage<CollectionResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "shopify.CollectionResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "collection", kind: "message", T: Collection },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CollectionResponse {
    return new CollectionResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CollectionResponse {
    return new CollectionResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CollectionResponse {
    return new CollectionResponse().fromJsonString(jsonString, options);
  }

  static equals(a: CollectionResponse | PlainMessage<CollectionResponse> | undefined, b: CollectionResponse | PlainMessage<CollectionResponse> | undefined): boolean {
    return proto3.util.equals(CollectionResponse, a, b);
  }
}

/**
 * @generated from message shopify.CollectionsRequest
 */
export class CollectionsRequest extends Message<CollectionsRequest> {
  /**
   * Collection ID of the form `gid://shopify/Collection/{id}`
   *
   * @generated from field: repeated string ids = 1;
   */
  ids: string[] = [];

  constructor(data?: PartialMessage<CollectionsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "shopify.CollectionsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CollectionsRequest {
    return new CollectionsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CollectionsRequest {
    return new CollectionsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CollectionsRequest {
    return new CollectionsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CollectionsRequest | PlainMessage<CollectionsRequest> | undefined, b: CollectionsRequest | PlainMessage<CollectionsRequest> | undefined): boolean {
    return proto3.util.equals(CollectionsRequest, a, b);
  }
}

/**
 * @generated from message shopify.CollectionsResponse
 */
export class CollectionsResponse extends Message<CollectionsResponse> {
  /**
   * @generated from field: repeated shopifyapi.Collection collections = 1;
   */
  collections: Collection[] = [];

  constructor(data?: PartialMessage<CollectionsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "shopify.CollectionsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "collections", kind: "message", T: Collection, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CollectionsResponse {
    return new CollectionsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CollectionsResponse {
    return new CollectionsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CollectionsResponse {
    return new CollectionsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: CollectionsResponse | PlainMessage<CollectionsResponse> | undefined, b: CollectionsResponse | PlainMessage<CollectionsResponse> | undefined): boolean {
    return proto3.util.equals(CollectionsResponse, a, b);
  }
}

/**
 * @generated from message shopify.ProductRequest
 */
export class ProductRequest extends Message<ProductRequest> {
  /**
   * @generated from field: optional string id = 1;
   */
  id?: string;

  /**
   * @generated from field: optional string handle = 2;
   */
  handle?: string;

  constructor(data?: PartialMessage<ProductRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "shopify.ProductRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 2, name: "handle", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ProductRequest {
    return new ProductRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ProductRequest {
    return new ProductRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ProductRequest {
    return new ProductRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ProductRequest | PlainMessage<ProductRequest> | undefined, b: ProductRequest | PlainMessage<ProductRequest> | undefined): boolean {
    return proto3.util.equals(ProductRequest, a, b);
  }
}

/**
 * @generated from message shopify.ProductResponse
 */
export class ProductResponse extends Message<ProductResponse> {
  /**
   * @generated from field: shopifyapi.Product product = 1;
   */
  product?: Product;

  constructor(data?: PartialMessage<ProductResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "shopify.ProductResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "product", kind: "message", T: Product },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ProductResponse {
    return new ProductResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ProductResponse {
    return new ProductResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ProductResponse {
    return new ProductResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ProductResponse | PlainMessage<ProductResponse> | undefined, b: ProductResponse | PlainMessage<ProductResponse> | undefined): boolean {
    return proto3.util.equals(ProductResponse, a, b);
  }
}

/**
 * @generated from message shopify.ProductsRequest
 */
export class ProductsRequest extends Message<ProductsRequest> {
  /**
   * @generated from field: repeated string ids = 1;
   */
  ids: string[] = [];

  constructor(data?: PartialMessage<ProductsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "shopify.ProductsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ProductsRequest {
    return new ProductsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ProductsRequest {
    return new ProductsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ProductsRequest {
    return new ProductsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ProductsRequest | PlainMessage<ProductsRequest> | undefined, b: ProductsRequest | PlainMessage<ProductsRequest> | undefined): boolean {
    return proto3.util.equals(ProductsRequest, a, b);
  }
}

/**
 * @generated from message shopify.ProductsResponse
 */
export class ProductsResponse extends Message<ProductsResponse> {
  /**
   * @generated from field: repeated shopifyapi.Product products = 1;
   */
  products: Product[] = [];

  constructor(data?: PartialMessage<ProductsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "shopify.ProductsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "products", kind: "message", T: Product, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ProductsResponse {
    return new ProductsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ProductsResponse {
    return new ProductsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ProductsResponse {
    return new ProductsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ProductsResponse | PlainMessage<ProductsResponse> | undefined, b: ProductsResponse | PlainMessage<ProductsResponse> | undefined): boolean {
    return proto3.util.equals(ProductsResponse, a, b);
  }
}

/**
 * @generated from message shopify.UpdateCollectionRequest
 */
export class UpdateCollectionRequest extends Message<UpdateCollectionRequest> {
  /**
   * @generated from field: shopifyapi.CollectionInput collection_input = 1;
   */
  collectionInput?: CollectionInput;

  constructor(data?: PartialMessage<UpdateCollectionRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "shopify.UpdateCollectionRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "collection_input", kind: "message", T: CollectionInput },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateCollectionRequest {
    return new UpdateCollectionRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateCollectionRequest {
    return new UpdateCollectionRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateCollectionRequest {
    return new UpdateCollectionRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateCollectionRequest | PlainMessage<UpdateCollectionRequest> | undefined, b: UpdateCollectionRequest | PlainMessage<UpdateCollectionRequest> | undefined): boolean {
    return proto3.util.equals(UpdateCollectionRequest, a, b);
  }
}

/**
 * @generated from message shopify.UpdateProductRequest
 */
export class UpdateProductRequest extends Message<UpdateProductRequest> {
  /**
   * @generated from field: shopifyapi.ProductInput product_input = 1;
   */
  productInput?: ProductInput;

  constructor(data?: PartialMessage<UpdateProductRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "shopify.UpdateProductRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "product_input", kind: "message", T: ProductInput },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateProductRequest {
    return new UpdateProductRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateProductRequest {
    return new UpdateProductRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateProductRequest {
    return new UpdateProductRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateProductRequest | PlainMessage<UpdateProductRequest> | undefined, b: UpdateProductRequest | PlainMessage<UpdateProductRequest> | undefined): boolean {
    return proto3.util.equals(UpdateProductRequest, a, b);
  }
}

/**
 * @generated from message shopify.ModifyCollectionProductsRequest
 */
export class ModifyCollectionProductsRequest extends Message<ModifyCollectionProductsRequest> {
  /**
   * @generated from field: string collection_id = 2;
   */
  collectionId = "";

  /**
   * @generated from field: repeated string product_ids = 3;
   */
  productIds: string[] = [];

  constructor(data?: PartialMessage<ModifyCollectionProductsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "shopify.ModifyCollectionProductsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 2, name: "collection_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "product_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ModifyCollectionProductsRequest {
    return new ModifyCollectionProductsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ModifyCollectionProductsRequest {
    return new ModifyCollectionProductsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ModifyCollectionProductsRequest {
    return new ModifyCollectionProductsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ModifyCollectionProductsRequest | PlainMessage<ModifyCollectionProductsRequest> | undefined, b: ModifyCollectionProductsRequest | PlainMessage<ModifyCollectionProductsRequest> | undefined): boolean {
    return proto3.util.equals(ModifyCollectionProductsRequest, a, b);
  }
}

/**
 * @generated from message shopify.ReorderCollectionProductsRequest
 */
export class ReorderCollectionProductsRequest extends Message<ReorderCollectionProductsRequest> {
  /**
   * @generated from field: string collection_id = 1;
   */
  collectionId = "";

  /**
   * @generated from field: repeated shopifyapi.MoveInput moves = 2;
   */
  moves: MoveInput[] = [];

  constructor(data?: PartialMessage<ReorderCollectionProductsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "shopify.ReorderCollectionProductsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "collection_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "moves", kind: "message", T: MoveInput, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ReorderCollectionProductsRequest {
    return new ReorderCollectionProductsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ReorderCollectionProductsRequest {
    return new ReorderCollectionProductsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ReorderCollectionProductsRequest {
    return new ReorderCollectionProductsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ReorderCollectionProductsRequest | PlainMessage<ReorderCollectionProductsRequest> | undefined, b: ReorderCollectionProductsRequest | PlainMessage<ReorderCollectionProductsRequest> | undefined): boolean {
    return proto3.util.equals(ReorderCollectionProductsRequest, a, b);
  }
}

/**
 * @generated from message shopify.ReorderProductImagesRequest
 */
export class ReorderProductImagesRequest extends Message<ReorderProductImagesRequest> {
  /**
   * @generated from field: string product_id = 1;
   */
  productId = "";

  /**
   * @generated from field: repeated shopifyapi.MoveInput moves = 2;
   */
  moves: MoveInput[] = [];

  constructor(data?: PartialMessage<ReorderProductImagesRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "shopify.ReorderProductImagesRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "product_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "moves", kind: "message", T: MoveInput, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ReorderProductImagesRequest {
    return new ReorderProductImagesRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ReorderProductImagesRequest {
    return new ReorderProductImagesRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ReorderProductImagesRequest {
    return new ReorderProductImagesRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ReorderProductImagesRequest | PlainMessage<ReorderProductImagesRequest> | undefined, b: ReorderProductImagesRequest | PlainMessage<ReorderProductImagesRequest> | undefined): boolean {
    return proto3.util.equals(ReorderProductImagesRequest, a, b);
  }
}

/**
 * @generated from message shopify.UpdateCollectionImageRequest
 */
export class UpdateCollectionImageRequest extends Message<UpdateCollectionImageRequest> {
  /**
   * @generated from field: string file_name = 1;
   */
  fileName = "";

  /**
   * @generated from field: int32 file_size = 2;
   */
  fileSize = 0;

  /**
   * @generated from field: string file_url = 3;
   */
  fileUrl = "";

  /**
   * @generated from field: bytes file = 4;
   */
  file = new Uint8Array(0);

  /**
   * @generated from field: string collection_id = 5;
   */
  collectionId = "";

  /**
   * @generated from field: string file_type = 6;
   */
  fileType = "";

  constructor(data?: PartialMessage<UpdateCollectionImageRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "shopify.UpdateCollectionImageRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "file_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "file_size", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "file_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "file", kind: "scalar", T: 12 /* ScalarType.BYTES */ },
    { no: 5, name: "collection_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "file_type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateCollectionImageRequest {
    return new UpdateCollectionImageRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateCollectionImageRequest {
    return new UpdateCollectionImageRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateCollectionImageRequest {
    return new UpdateCollectionImageRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateCollectionImageRequest | PlainMessage<UpdateCollectionImageRequest> | undefined, b: UpdateCollectionImageRequest | PlainMessage<UpdateCollectionImageRequest> | undefined): boolean {
    return proto3.util.equals(UpdateCollectionImageRequest, a, b);
  }
}

/**
 * @generated from message shopify.DeleteCollectionImageRequest
 */
export class DeleteCollectionImageRequest extends Message<DeleteCollectionImageRequest> {
  /**
   * @generated from field: string collection_id = 1;
   */
  collectionId = "";

  constructor(data?: PartialMessage<DeleteCollectionImageRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "shopify.DeleteCollectionImageRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "collection_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeleteCollectionImageRequest {
    return new DeleteCollectionImageRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeleteCollectionImageRequest {
    return new DeleteCollectionImageRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DeleteCollectionImageRequest {
    return new DeleteCollectionImageRequest().fromJsonString(jsonString, options);
  }

  static equals(a: DeleteCollectionImageRequest | PlainMessage<DeleteCollectionImageRequest> | undefined, b: DeleteCollectionImageRequest | PlainMessage<DeleteCollectionImageRequest> | undefined): boolean {
    return proto3.util.equals(DeleteCollectionImageRequest, a, b);
  }
}

