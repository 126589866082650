import { GetOrderById } from 'api/databaseCalls/reads/orders'
import { Order } from 'gen/perkup/v1/vendor_pb'
import useIds from 'hooks/useIds'
import { useEffect, useState } from 'react'

export function useOrderById({ orderId }: { orderId?: string }) {
  const { userId, orgId } = useIds()

  const [order, setOrder] = useState<Order>()
  const [isLoadingInitial, setIsLoadingInitial] = useState(true)
  const [isLoadingMore, setIsLoadingMore] = useState(false)

  useEffect(() => {
    if (!orderId) {
      setIsLoadingInitial(false)
      return
    }
    setIsLoadingMore(true)
    GetOrderById({ orderId })
      .then(setOrder)
      .finally(() => {
        setIsLoadingMore(false)
        setIsLoadingInitial(false)
      })
  }, [orderId, orgId, userId])

  return {
    order,
    isLoadingInitial,
    isLoadingMore,
    isLoading: isLoadingInitial || isLoadingMore,
  }
}
