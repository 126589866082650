import { Modal } from 'antd'
import * as ROUTES from 'constants/routes'
import { DEFAULT_ROUTES } from 'constants/routes'
import { Heading } from 'evergreen-ui'
import { useMemo } from 'react'
import { useLocation, useSearchParams } from 'react-router-dom'

export function NewProgramModal() {
  const [searchParams, setSearchParams] = useSearchParams()

  const location = useLocation()

  const isRewards = location.pathname?.includes(
    DEFAULT_ROUTES.ORGANIZATION.REWARDS.ROOT
  )

  const title = isRewards ? 'reward' : 'perk program'
  const confirmLabel = isRewards ? 'See reward' : 'See program'

  const handleClose = () => {
    searchParams.delete(ROUTES.PARAM_NEW_PROGRAM)
    setSearchParams(searchParams)
  }

  const dialogOpen = useMemo(
    () => !!searchParams.get(ROUTES.PARAM_NEW_PROGRAM),
    [searchParams]
  )

  return (
    <Modal
      open={dialogOpen}
      onClose={handleClose}
      onCancel={handleClose}
      okText={confirmLabel}
      onOk={handleClose}
      cancelButtonProps={{ style: { display: 'none' } }}
      styles={{
        body: {
          height: 64,
          padding: '16px 0 0 0',
        },
      }}
    >
      <Heading size={600}>
        Congratulations, you created a {title}!{' '}
        <span role="img" aria-label="party">
          🥳
        </span>
      </Heading>
    </Modal>
  )
}
