import { FREE_DELIVERY } from 'constants/transactions'
import { Pane, Text } from 'evergreen-ui'
import { numToDollars } from 'utils'

enum Sections {
  ItemPrice = 'Item price:',
  Shipping = 'Shipping & handling:',
  PerkUpFee = 'PerkUp fee:',
  EstimatedTax = 'Estimated tax:',
  Total = 'Total per recipient:',
}

export function PriceBreakdown({
  productPrice,
  shippingCost,
  estimatedTax,
  perkUpFee,
  totalCost,
  totalLabel = Sections.Total,
  hideTaxAndPerkupFee = false,
}: {
  productPrice: number
  shippingCost: number
  estimatedTax: number
  perkUpFee: number
  totalCost: number
  totalLabel?: string
  hideTaxAndPerkupFee?: boolean
}) {
  // Defines properties of each breakdown section
  const breakdownSections = [
    {
      label: Sections.ItemPrice,
      price: productPrice,
    },
    {
      label: Sections.Shipping,
      price: shippingCost,
      border: 'muted',
    },
    {
      label: Sections.PerkUpFee,
      price: perkUpFee,
      hidden: hideTaxAndPerkupFee,
    },
    {
      label: Sections.EstimatedTax,
      price: estimatedTax,
      border: 'muted',
      hidden: hideTaxAndPerkupFee,
    },
    {
      label: totalLabel,
      price: totalCost,
      hidden: false,
    },
  ]

  return (
    <Pane width="100%">
      {breakdownSections.map(({ label, price, border, hidden }) => {
        if (hidden) return null
        return (
          <Pane key={`${label} + ${price}`} marginBottom={4}>
            <Pane display="flex" justifyContent="space-between" gap={8}>
              <Text>{label}</Text>
              <Text>
                {price === 0 && label === Sections.Shipping
                  ? FREE_DELIVERY
                  : numToDollars(price)}
              </Text>
            </Pane>
            {border && <Pane borderBottom="muted" marginY={16} />}
          </Pane>
        )
      })}
    </Pane>
  )
}
