import {
  RewardUseCase,
  RewardUseCases,
} from 'constants/newReward/rewardUseCases'
import { ALL_USE_CASES, CurrentUseCase, FEATURED } from 'constants/themeEditor'

export function isNotAllUseCasesOrFeatured(
  value: CurrentUseCase
): value is RewardUseCase {
  return value !== ALL_USE_CASES && value !== FEATURED
}

export const getUseCaseLabel = (useCase: CurrentUseCase) => {
  if (useCase === FEATURED || useCase === ALL_USE_CASES) {
    return useCase
  }

  return RewardUseCases[useCase].label
}
