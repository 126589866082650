import { Heading, Pane, Text } from 'evergreen-ui'

import { Button } from 'antd'
import { ReactComponent as PageNotFoundIllustration } from 'assets/PageNotFound.svg'
import { Footer } from 'components'
import * as ROUTES from 'constants/routes'
import { useNavigate } from 'react-router-dom'

function NoMatch404({ hasFooter = true }: { hasFooter?: boolean }) {
  const navigate = useNavigate()

  return (
    <Pane maxWidth={1092} marginTop={68} marginX="auto">
      <Pane display="flex" alignItems="center" justifyContent="space-between">
        <Pane marginRight={32}>
          <Heading size={900} fontSize={128} lineHeight="1">
            404
          </Heading>
          <Text size={600}>The page you’re looking for can’t be found.</Text>
          <Pane>
            <Button
              type="primary"
              autoFocus
              onClick={() => navigate(ROUTES.HOME)}
              style={{ marginTop: 32 }}
              size="large"
            >
              Go home
            </Button>
          </Pane>
        </Pane>
        <Pane>
          <PageNotFoundIllustration
            style={{ width: '100%', maxWidth: 640, height: 'auto' }}
          />
        </Pane>
      </Pane>
      {hasFooter && <Footer />}
    </Pane>
  )
}

export default NoMatch404
