import { ConnectError } from '@connectrpc/connect'
import { captureMessage } from '@sentry/react'
import { OrderErrorDetail } from 'gen/manual/v1/manual_pb'
import { OutOfInventoryErrorDetail } from 'gen/perkup/v1/decline_report_pb'
import { isEmpty } from 'lodash-es'

export interface ErrorMessage {
  title: string
  description?: string
}

export const getOrderErrorMessage = ({
  error,
}: {
  error: ConnectError
}): ErrorMessage => {
  const fallBackMessage = 'Something went wrong, please try again later.'
  const orderItems = error.findDetails(OutOfInventoryErrorDetail)

  if (!isEmpty(orderItems)) {
    let errorMessage = ''
    orderItems[0]?.items.forEach((item, index) => {
      const productName =
        item.productName === item.variantName
          ? item.productName
          : `${item.productName} (${item.variantName})`
      const availableQuantity =
        item.availableQuantity < 0 ? 0 : item.availableQuantity
      errorMessage += `${orderItems[0].items.length > 1 ? `${index + 1}. ` : ''}
       ${productName} - ${availableQuantity} units remaining <br />`
    })
    errorMessage += 'Please select something else.'
    return {
      title: 'The following items have insufficient inventory:',
      description: errorMessage,
    }
  }

  const errorDetails = error.findDetails(OrderErrorDetail)

  const noErrorReason = !errorDetails[0]?.reason

  const pleaseTryAgainMessage =
    errorDetails[0]?.reason === 'Please retry after a few minutes'

  if (pleaseTryAgainMessage) {
    captureMessage('Fallback to BE error message', 'info')
    return { title: error.message }
  }

  if (isEmpty(errorDetails) || noErrorReason) {
    return { title: fallBackMessage }
  }

  return {
    title: errorDetails[0].reason,
  }
}
