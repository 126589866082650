import { GetProductVariantById } from 'api/databaseCalls'
import { ProductVariant } from 'gen/perkup/v1/product_variant_pb'
import { useEffect, useState } from 'react'

export default function useProductVariantById({
  productVariantId,
}: {
  productVariantId: string | undefined
}) {
  const [productVariant, setProductVariant] = useState<ProductVariant>()
  const [isLoadingInitial, setIsLoadingInitial] = useState(true)
  const [isLoadingMore, setIsLoadingMore] = useState(false)

  useEffect(() => {
    if (!productVariantId) {
      setProductVariant(undefined)
      setIsLoadingInitial(false)
      return
    }
    setIsLoadingMore(true)
    GetProductVariantById({ productVariantId })
      .then(setProductVariant)
      .finally(() => {
        setIsLoadingInitial(false)
        setIsLoadingMore(false)
      })
  }, [productVariantId])

  return {
    productVariant,
    isLoading: isLoadingInitial || isLoadingMore,
    isLoadingInitial,
    isLoadingMore,
  }
}
