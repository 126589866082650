import { Timestamp } from '@bufbuild/protobuf'
import { Tag } from 'antd'
import { Individual, Individual_Status } from 'gen/perkup/v1/individual_pb'
import { useIndividualById } from 'hooks/individuals/useIndividualById'
import { getIndividualDisplayName } from 'utils/individual'

function RemovedTagWithDetail({
  removedBy,
  removedAt,
}: {
  removedBy: string
  removedAt: Timestamp
}) {
  const removedDate = removedAt
    ? new Intl.DateTimeFormat('en-US', {
        day: 'numeric',
        month: 'short',
        year: 'numeric',
      }).format(removedAt.toDate())
    : null

  const removedByIntegration =
    removedBy.endsWith('integration') || removedBy === 'csv-uploader'

  // If removed by integration, removedBy = "{integrationName} integration"
  // If removed by individual, removedBy = individualId
  const individualId = removedByIntegration ? undefined : removedBy

  // Find the individual that did the remove
  const { individual: individualWhoRemoved, isLoading } = useIndividualById({
    individualId,
  })

  if (removedByIntegration) {
    return (
      <Tag color="red">
        Removed {removedDate} by {removedBy}
      </Tag>
    )
  }

  if (isLoading || !individualWhoRemoved) {
    return <Tag color="red">Removed {removedDate}</Tag>
  }

  return (
    <Tag color="red">
      Removed {removedDate} by {getIndividualDisplayName(individualWhoRemoved)}
    </Tag>
  )
}

export function IndividualStatusTag({
  withDetail = false,
  individual,
}: {
  withDetail?: boolean
  individual: Individual
}) {
  const { status, removedAt, removedBy } = individual
  const isRemoved = status === Individual_Status.removed
  const showLongRemovedBadge = isRemoved && withDetail && removedBy && removedAt

  if (showLongRemovedBadge) {
    return (
      <RemovedTagWithDetail removedBy={removedBy} removedAt={removedAt} /> // Separate component to keep the hook it uses more localized
    )
  }

  if (isRemoved) {
    return <Tag color="red">Removed</Tag>
  }

  if (status === Individual_Status.active) {
    return <Tag color="green">Active</Tag>
  }

  if (status === Individual_Status.invited) {
    return <Tag color="blue">Invited</Tag>
  }

  if (status === Individual_Status.blocked) {
    return <Tag color="yellow">Blocked</Tag>
  }

  return null
}
