import { RichTextEditor } from '@mantine/tiptap'
import Underline from '@tiptap/extension-underline'
import { useEditor } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import { Button, Flex } from 'antd'
import { PerkScrollbars } from 'components'
import { EmojiPickerButton } from 'components/Buttons'
import { Text, toaster, useTheme } from 'evergreen-ui'
import parse from 'html-react-parser'
import { useEffect, useState } from 'react'

export function TextEditor({
  htmlContent,
  confirmLabel = 'Save',
  onConfirm,
  onBlur,
  disabled = false,
  persistEditorMode = false,
  hideCancelButton = false,
  preventEventPropagation = false,
  extraButtons,
}: {
  htmlContent: string
  confirmLabel?: string
  onConfirm?: (htmlContent: string) => void
  onBlur?: (htmlContent: string) => void
  disabled?: boolean
  persistEditorMode?: boolean
  hideCancelButton?: boolean
  preventEventPropagation?: boolean
  extraButtons?: React.ReactNode
}) {
  const theme = useTheme()

  const [edit, setEdit] = useState(false)

  const editor = useEditor({
    extensions: [StarterKit, Underline],
    content: htmlContent,
    onBlur: ({ editor, event }) => {
      if (!onBlur) return
      if (preventEventPropagation && event.relatedTarget) return // User just clicked the mantine buttons, don't blur because they're still editing!
      if (editor.isEmpty) {
        toaster.warning('Text cannot be empty')
        return
      }
      setEdit(false)
      onBlur(editor.getHTML())
    },
  })

  // Ideally we use keys in the parent component instead of useEffects, but the key was creating a big flash in here
  useEffect(() => {
    if (!editor) return

    // User hit the refresh button in the parent element, so brand new starting html content was passed in
    if (htmlContent !== editor.getHTML()) {
      editor.commands.setContent(htmlContent)
    }
  }, [editor, htmlContent])

  const handleSelectEmoji = (emoji: string) => {
    if (!editor) return
    editor.chain().focus().insertContent(emoji).run()
  }

  const showCancelButton = !hideCancelButton && !persistEditorMode
  const inputBorderGray = theme.colors.gray400 // Mantine uses their own gray, separate from Evergreen and AntDesign, so let's at least match one...

  if (edit || persistEditorMode) {
    return (
      <PerkScrollbars
        style={{
          maxHeight: 240,
          position: 'relative',
          border: `0.0625rem solid ${inputBorderGray}`, // Matches mantines styling
          borderRadius: 8,
        }}
      >
        <RichTextEditor
          style={{ width: '100%', border: 'none' }}
          editor={editor}
        >
          <RichTextEditor.Toolbar
            sticky
            top={0}
            style={{ borderColor: inputBorderGray }}
          >
            <RichTextEditor.ControlsGroup>
              <RichTextEditor.Bold style={{ borderColor: inputBorderGray }} />
              <RichTextEditor.Italic style={{ borderColor: inputBorderGray }} />
              <RichTextEditor.Underline
                style={{ borderColor: inputBorderGray }}
              />
              <RichTextEditor.Strikethrough
                style={{ borderColor: inputBorderGray }}
              />
              <RichTextEditor.ClearFormatting
                style={{ borderColor: inputBorderGray }}
              />
            </RichTextEditor.ControlsGroup>

            <RichTextEditor.ControlsGroup>
              <RichTextEditor.H1 style={{ borderColor: inputBorderGray }} />
              <RichTextEditor.H2 style={{ borderColor: inputBorderGray }} />
              <RichTextEditor.H3 style={{ borderColor: inputBorderGray }} />
              <RichTextEditor.H4 style={{ borderColor: inputBorderGray }} />
            </RichTextEditor.ControlsGroup>

            <Flex align="center" gap={8}>
              {showCancelButton && (
                <Button size="small" onClick={() => setEdit(false)}>
                  Cancel
                </Button>
              )}

              {!!onConfirm && (
                <Button
                  type="primary"
                  size="small"
                  disabled={disabled}
                  onClick={() => {
                    if (!editor) return
                    if (editor.isEmpty) {
                      toaster.warning('Text cannot be empty')
                      return
                    }
                    setEdit(false)
                    onConfirm(editor.getHTML())
                  }}
                >
                  {confirmLabel}
                </Button>
              )}
            </Flex>
          </RichTextEditor.Toolbar>

          <RichTextEditor.Content
            style={{
              paddingBottom: 20,
            }}
          />
        </RichTextEditor>

        <Flex gap={4} style={{ position: 'absolute', bottom: 4, right: 4 }}>
          <EmojiPickerButton onEmojiSelect={handleSelectEmoji} />
          {extraButtons}
        </Flex>
      </PerkScrollbars>
    )
  }

  return (
    <PerkScrollbars>
      <Text
        onClick={() => {
          if (disabled) return
          setEdit(true)
        }}
      >
        {parse(htmlContent)}
      </Text>
    </PerkScrollbars>
  )
}
