import { CopyOutlined } from '@ant-design/icons'
import { PerkIconTooltipButton } from 'components'
import { VariantDeliveryLabel } from 'components/ProductVariants'
import { DIGITAL_ORDER_URL_TYPE } from 'constants/transactions'
import { Heading, Link, Pane, Text } from 'evergreen-ui'
import { OrgTransaction_DigitalOrderInfo } from 'gen/perkup/v1/organization_pb'
import { ProductVariant_DeliveryType } from 'gen/perkup/v1/product_variant_pb'
import parse from 'html-react-parser'
import { copyToClipboard } from 'utils'

export function DigitalOrderInfo({
  info,
}: {
  info: OrgTransaction_DigitalOrderInfo
}) {
  const isLink = info?.type === DIGITAL_ORDER_URL_TYPE
  const hasRedeemLinkOrCode = !!info?.redeemLinkOrCode
  const hasRedeemPin = !!info?.redeemPin

  const hasRedeemInfo = info?.redeemLinkOrCode || info?.redeemPin

  return (
    <Pane
      display="flex"
      flexDirection="column"
      justifyContent="center"
      gap={16}
    >
      {hasRedeemInfo && (
        <>
          {hasRedeemLinkOrCode && (
            <Pane>
              {isLink && (
                <Heading>
                  Redemption Link:{' '}
                  <Link
                    target="_blank"
                    href={info.redeemLinkOrCode}
                    rel="noopener noreferrer"
                  >
                    {info.redeemLinkOrCode}
                  </Link>
                </Heading>
              )}
              {!isLink && (
                <Pane display="flex" alignItems="center" gap={8}>
                  <Heading>
                    Code: <Text>{info.redeemLinkOrCode}</Text>
                  </Heading>
                  <PerkIconTooltipButton
                    title="Copy code"
                    icon={<CopyOutlined />}
                    type="text"
                    onClick={() => {
                      if (!info.redeemLinkOrCode) return
                      copyToClipboard(info.redeemLinkOrCode, 'code')
                    }}
                  />
                </Pane>
              )}
            </Pane>
          )}
          {hasRedeemPin && (
            <Pane display="flex" alignItems="center" gap={8}>
              <Heading>
                PIN: <Text>{info.redeemPin}</Text>
              </Heading>

              <PerkIconTooltipButton
                title="Copy PIN"
                icon={<CopyOutlined />}
                type="text"
                onClick={() => {
                  if (!info.redeemPin) return
                  copyToClipboard(info.redeemPin, 'PIN')
                }}
              />
            </Pane>
          )}
        </>
      )}
      {!isLink && !info.redemptionInstructions && (
        <Text color="muted">
          To redeem this gift card, enter the provided code directly on the
          merchant&apos;s website.
        </Text>
      )}
      {info.redemptionInstructions && (
        <Pane display="flex" flexDirection="column" gap={8}>
          <Heading>Redemption Instructions</Heading>
          <Pane>{parse(info.redemptionInstructions)}</Pane>
        </Pane>
      )}
      {!hasRedeemInfo && (
        <VariantDeliveryLabel
          deliveryType={ProductVariant_DeliveryType.delayed}
        />
      )}
    </Pane>
  )
}
