import { FilterOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import DatePicker from 'antd/es/date-picker'
import { FetchTinyBirdDataByOrgId } from 'api/tinybirdCalls/reads'
import { TRANSACTION_DATA_URL } from 'constants/tinybird'
import { APPROVED, DECLINED, PENDING, REFUND } from 'constants/txnStates'
import { OrgContext } from 'context'
import {
  Checkbox,
  Pane,
  Pill,
  Popover,
  Position,
  Select,
  Strong,
} from 'evergreen-ui'
import filter from 'lodash-es/filter'
import sortBy from 'lodash-es/sortBy'
import uniqBy from 'lodash-es/uniqBy'
import { useContext, useEffect, useState } from 'react'
import { RangeValue } from 'types/RewardsDate'
import { TinyBirdTransactionData } from 'types/TinyBird'

export function FilterTransactions({
  setTransactions,
  disabled,
}: {
  setTransactions: React.Dispatch<
    React.SetStateAction<TinyBirdTransactionData[]>
  >
  disabled: boolean
}) {
  const org = useContext(OrgContext)
  const orgId = org?.id

  const [nameChecked, setNameChecked] = useState(false)
  const [selectedUserId, setSelectedUserId] = useState<string>()

  const [statusChecked, setStatusChecked] = useState(false)
  const [selectedStatus, setSelectedStatus] = useState<string>()

  const [localTxns, setLocalTxns] = useState<TinyBirdTransactionData[]>([])

  // Get local list of all transactions
  useEffect(() => {
    if (orgId) {
      FetchTinyBirdDataByOrgId({
        orgId,
        cb: setLocalTxns,
        baseUrl: TRANSACTION_DATA_URL,
        dataTitle: 'transaction data',
      })
    }
  }, [orgId])

  // Set default values
  useEffect(() => {
    const defaultUserId = localTxns?.length ? localTxns[0].userId : undefined
    setSelectedUserId(defaultUserId)
  }, [localTxns])

  const handleFilter = ({ dates }: { dates: RangeValue }) => {
    let filteredTxns = localTxns

    // Filter by user
    if (nameChecked) {
      filteredTxns = filter(filteredTxns, txn => txn.userId === selectedUserId)
    }

    // Filter by status
    if (statusChecked) {
      if (selectedStatus === DECLINED) {
        filteredTxns = filter(filteredTxns, txn => txn.approved === 'false')
      } else if (selectedStatus === APPROVED) {
        filteredTxns = filter(filteredTxns, txn => txn.approved === 'true')
      } else if (selectedStatus === PENDING) {
        filteredTxns = filter(filteredTxns, txn => txn.status === PENDING)
      } else if (selectedStatus === REFUND) {
        filteredTxns = filter(filteredTxns, txn => txn.type === REFUND)
      }
    }

    // Filter by date
    if (dates?.length) {
      const [start, end] = dates

      if (start && end) {
        filteredTxns = filter(filteredTxns, txn => {
          const txnAfterStartDate =
            new Date(txn.created).getTime() >
            start?.startOf('day').toDate().getTime()
          const txnBeforeEndDate =
            new Date(txn.created).getTime() <
            end?.endOf('day').toDate().getTime()
          return txnAfterStartDate && txnBeforeEndDate
        })
      }
    }

    setTransactions(filteredTxns)
  }

  const clearFilter = () => {
    setNameChecked(false)
    setSelectedUserId(undefined)

    setStatusChecked(false)
    setSelectedStatus(undefined)
    setTransactions(localTxns)
  }

  const filterCnt = Number(statusChecked) + Number(nameChecked)

  const checkboxStyles = { width: 225, height: 20, margin: 10 }

  const uniqueTransactionsByUserFullName = sortBy(
    uniqBy(localTxns, 'userId'),
    'userFullName'
  )

  return (
    <>
      <Pane>
        <DatePicker.RangePicker
          onChange={dates => {
            handleFilter({ dates })
          }}
          format="MMM D, YYYY"
        />
      </Pane>
      <Popover
        onCloseComplete={() => handleFilter({ dates: null })}
        // eslint-disable-next-line react/no-unstable-nested-components
        content={({ close }) => (
          <Pane width={225}>
            <Pane display="flex" height={40}>
              <Button
                onClick={() => {
                  clearFilter()
                  close()
                }}
              >
                Clear
              </Button>
              <Pane
                display="flex"
                alignItems="center"
                justifyContent="center"
                flexDirection="column"
                flex={1}
              >
                <Strong>Filters</Strong>
              </Pane>
              <Button
                onClick={() => {
                  handleFilter({ dates: null })
                  close()
                }}
                type="primary"
              >
                Done
              </Button>
            </Pane>

            <Checkbox
              label="User"
              {...checkboxStyles}
              checked={nameChecked}
              onChange={e => {
                setNameChecked(e.target.checked)
              }}
            />
            {nameChecked && (
              <Select
                marginLeft={10}
                width={205}
                value={selectedUserId}
                onChange={e => setSelectedUserId(e.target.value)}
              >
                {uniqueTransactionsByUserFullName?.map(transaction => {
                  return (
                    <option key={transaction.userId} value={transaction.userId}>
                      {transaction.userFullName}
                    </option>
                  )
                })}
              </Select>
            )}

            <Checkbox
              label="Status"
              {...checkboxStyles}
              checked={statusChecked}
              onChange={e => setStatusChecked(e.target.checked)}
            />
            {statusChecked && (
              <Select
                marginLeft={10}
                marginBottom={10}
                width={205}
                value={selectedStatus}
                onChange={e => {
                  setSelectedStatus(e.target.value)
                }}
              >
                <option value={APPROVED}>Approved</option>
                <option value={PENDING}>Pending</option>
                <option value={REFUND}>Refunded</option>
              </Select>
            )}
          </Pane>
        )}
        position={Position.BOTTOM_RIGHT}
      >
        <Button icon={<FilterOutlined />} disabled={disabled}>
          Filter{filterCnt > 0 && <Pill marginLeft={8}>{filterCnt}</Pill>}
        </Button>
      </Popover>
    </>
  )
}
