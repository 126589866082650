import { Select } from 'antd'
import { PropsWithChildren } from 'react'
import { Permission } from 'types/Permissions'

export function PermissonSelect({
  currentPermission,
  handleSelectPermission,
  children,
}: PropsWithChildren<{
  currentPermission: Permission
  handleSelectPermission: (permission: Permission) => void
}>) {
  return (
    <Select
      variant="borderless"
      optionLabelProp="label"
      defaultValue={currentPermission}
      popupMatchSelectWidth={false}
      onSelect={handleSelectPermission}
      style={{ height: '34px' }}
    >
      {children}
    </Select>
  )
}
