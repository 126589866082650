import { useEffect, useState } from 'react'
import { generatePreviewImages } from 'services'
import { DesignVariable } from 'types'

export function useGeneratePreviewImage({
  designId,
  mockupIds,
  variables,
}: {
  designId: string
  mockupIds: string[]
  variables: DesignVariable[]
}) {
  const [previewImages, setPreviewImages] = useState<string[]>([])
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    let tempPreviewImages: string[] = []

    setIsLoading(true)
    generatePreviewImages({ designId, mockupIds, variables })
      .then(blobUrls => {
        if (blobUrls) {
          tempPreviewImages = blobUrls
          setPreviewImages(blobUrls)
        }
      })
      .finally(() => {
        setIsLoading(false)
      })

    return () => {
      tempPreviewImages.forEach(previewImage => {
        URL.revokeObjectURL(previewImage)
      })
    }
  }, [designId, mockupIds, variables])

  return {
    previewImages,
    isLoading,
  }
}
