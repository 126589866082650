import { Template_EligibleRewards } from 'gen/perkup/v1/org_template_pb'

export enum CategoryTitle {
  THANKS = 'Thank you',
  ANNIVERSARIES = 'Anniversaries',
  BIRTHDAYS = 'Birthdays',
  COMPANY_EVENTS = 'Company events',
  HOLIDAYS = 'Holidays',
  PERFORMANCE = 'Performance',
  LUNCHES = 'Lunches',
  NEW_EMPLOYEE = 'New employee',
  ABOVE_AND_BEYOND = 'Above & beyond',
  CONGRATULATIONS = 'Congratulations',
  SHOUTOUTS = 'Shoutouts',
  CONTEST_WINNER = 'Contest winner',
  TRIVIA_NIGHT = 'Trivia night',
  GAME_NIGHT = 'Game night',
  MOVIE_NIGHT = 'Movie night',
  WEDDING = 'Wedding',
  NEW_BABY = 'New baby',
  GET_WELL = 'Get well soon',
  RETIREMENT = 'Retirement',
  BEREAVEMENT = 'Bereavement',
}

export const allTemplateCategories: CategoryTitle[] = [
  CategoryTitle.NEW_EMPLOYEE,
  CategoryTitle.ANNIVERSARIES,
  CategoryTitle.BIRTHDAYS,
  CategoryTitle.THANKS,
  CategoryTitle.ABOVE_AND_BEYOND,
  CategoryTitle.CONGRATULATIONS,
  CategoryTitle.SHOUTOUTS,
  CategoryTitle.PERFORMANCE,
  CategoryTitle.COMPANY_EVENTS,
  CategoryTitle.CONTEST_WINNER,
  CategoryTitle.LUNCHES,
  CategoryTitle.TRIVIA_NIGHT,
  CategoryTitle.GAME_NIGHT,
  CategoryTitle.MOVIE_NIGHT,
  CategoryTitle.WEDDING,
  CategoryTitle.NEW_BABY,
  CategoryTitle.GET_WELL,
  CategoryTitle.RETIREMENT,
  CategoryTitle.BEREAVEMENT,
  CategoryTitle.HOLIDAYS,
]

export const eligibleRewardKeys: (keyof Template_EligibleRewards)[] = [
  'cash',
  'swag',
  'gift',
  'giftCard',
]
