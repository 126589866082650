// @generated by protoc-gen-es v1.6.0 with parameter "target=ts"
// @generated from file perkup/v1/stripe_invoice.proto (package perkup.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, protoInt64 } from "@bufbuild/protobuf";

/**
 * Firestore /organizations/:id/accounts
 *
 * @generated from message perkup.v1.TopUpInvoiceRequest
 */
export class TopUpInvoiceRequest extends Message<TopUpInvoiceRequest> {
  /**
   * @generated from field: int64 amount = 1;
   */
  amount = protoInt64.zero;

  /**
   * @generated from field: string customer = 2;
   */
  customer = "";

  /**
   * @generated from field: string payment_method = 3;
   */
  paymentMethod = "";

  /**
   * @generated from field: string org_id = 4;
   */
  orgId = "";

  /**
   * @generated from field: string account_id = 5;
   */
  accountId = "";

  /**
   * @generated from field: bool is_embedded_checkout = 6;
   */
  isEmbeddedCheckout = false;

  /**
   * @generated from field: optional bool include_take_rate = 7;
   */
  includeTakeRate?: boolean;

  /**
   * @generated from field: optional string memo = 8;
   */
  memo?: string;

  constructor(data?: PartialMessage<TopUpInvoiceRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "perkup.v1.TopUpInvoiceRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "amount", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "customer", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "payment_method", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "org_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "account_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "is_embedded_checkout", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 7, name: "include_take_rate", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 8, name: "memo", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TopUpInvoiceRequest {
    return new TopUpInvoiceRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TopUpInvoiceRequest {
    return new TopUpInvoiceRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TopUpInvoiceRequest {
    return new TopUpInvoiceRequest().fromJsonString(jsonString, options);
  }

  static equals(a: TopUpInvoiceRequest | PlainMessage<TopUpInvoiceRequest> | undefined, b: TopUpInvoiceRequest | PlainMessage<TopUpInvoiceRequest> | undefined): boolean {
    return proto3.util.equals(TopUpInvoiceRequest, a, b);
  }
}

/**
 * Firestore /invoices/:id
 *
 * @generated from message perkup.v1.Invoice
 */
export class Invoice extends Message<Invoice> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string account_country = 2 [json_name = "account_country"];
   */
  accountCountry = "";

  /**
   * @generated from field: string account_name = 3 [json_name = "account_name"];
   */
  accountName = "";

  /**
   * @generated from field: int64 amount_due = 4 [json_name = "amount_due"];
   */
  amountDue = protoInt64.zero;

  /**
   * @generated from field: int64 amount_paid = 5 [json_name = "amount_paid"];
   */
  amountPaid = protoInt64.zero;

  /**
   * @generated from field: int64 amount_remaining = 6 [json_name = "amount_remaining"];
   */
  amountRemaining = protoInt64.zero;

  /**
   * @generated from field: string invoice_pdf = 7 [json_name = "invoice_pdf"];
   */
  invoicePdf = "";

  /**
   * @generated from field: string hosted_invoice_url = 8 [json_name = "hosted_invoice_url"];
   */
  hostedInvoiceUrl = "";

  /**
   * @generated from field: map<string, string> metadata = 9;
   */
  metadata: { [key: string]: string } = {};

  /**
   * @generated from field: optional perkup.v1.Invoice.Lines lines = 10;
   */
  lines?: Invoice_Lines;

  /**
   * @generated from field: optional string charge = 11;
   */
  charge?: string;

  /**
   * @generated from field: int64 created = 12;
   */
  created = protoInt64.zero;

  /**
   * @generated from field: string status = 13;
   */
  status = "";

  /**
   * @generated from field: string customer = 14;
   */
  customer = "";

  /**
   * not a field from Stripe. for createInvoiceTopUp endpoint resp to FE
   *
   * @generated from field: string payment_intent_client_secret = 15;
   */
  paymentIntentClientSecret = "";

  constructor(data?: PartialMessage<Invoice>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "perkup.v1.Invoice";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "account_country", jsonName: "account_country", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "account_name", jsonName: "account_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "amount_due", jsonName: "amount_due", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 5, name: "amount_paid", jsonName: "amount_paid", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 6, name: "amount_remaining", jsonName: "amount_remaining", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 7, name: "invoice_pdf", jsonName: "invoice_pdf", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "hosted_invoice_url", jsonName: "hosted_invoice_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "metadata", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "scalar", T: 9 /* ScalarType.STRING */} },
    { no: 10, name: "lines", kind: "message", T: Invoice_Lines, opt: true },
    { no: 11, name: "charge", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 12, name: "created", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 13, name: "status", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 14, name: "customer", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 15, name: "payment_intent_client_secret", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Invoice {
    return new Invoice().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Invoice {
    return new Invoice().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Invoice {
    return new Invoice().fromJsonString(jsonString, options);
  }

  static equals(a: Invoice | PlainMessage<Invoice> | undefined, b: Invoice | PlainMessage<Invoice> | undefined): boolean {
    return proto3.util.equals(Invoice, a, b);
  }
}

/**
 * @generated from message perkup.v1.Invoice.Lines
 */
export class Invoice_Lines extends Message<Invoice_Lines> {
  /**
   * @generated from field: repeated perkup.v1.Invoice.LineItem data = 1;
   */
  data: Invoice_LineItem[] = [];

  constructor(data?: PartialMessage<Invoice_Lines>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "perkup.v1.Invoice.Lines";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "data", kind: "message", T: Invoice_LineItem, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Invoice_Lines {
    return new Invoice_Lines().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Invoice_Lines {
    return new Invoice_Lines().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Invoice_Lines {
    return new Invoice_Lines().fromJsonString(jsonString, options);
  }

  static equals(a: Invoice_Lines | PlainMessage<Invoice_Lines> | undefined, b: Invoice_Lines | PlainMessage<Invoice_Lines> | undefined): boolean {
    return proto3.util.equals(Invoice_Lines, a, b);
  }
}

/**
 * @generated from message perkup.v1.Invoice.LineItem
 */
export class Invoice_LineItem extends Message<Invoice_LineItem> {
  /**
   * @generated from field: int64 amount = 1;
   */
  amount = protoInt64.zero;

  /**
   * @generated from field: int64 amount_excluding_tax = 2 [json_name = "amount_excluding_tax"];
   */
  amountExcludingTax = protoInt64.zero;

  /**
   * @generated from field: string currency = 3;
   */
  currency = "";

  /**
   * @generated from field: optional string description = 4;
   */
  description?: string;

  /**
   * @generated from field: string id = 5;
   */
  id = "";

  /**
   * @generated from field: string invoice_item = 6 [json_name = "invoice_item"];
   */
  invoiceItem = "";

  /**
   * @generated from field: optional perkup.v1.Invoice.LineItemPrice price = 7;
   */
  price?: Invoice_LineItemPrice;

  /**
   * @generated from field: int64 quantity = 8;
   */
  quantity = protoInt64.zero;

  /**
   * @generated from field: string subscription = 9;
   */
  subscription = "";

  /**
   * @generated from field: string type = 10;
   */
  type = "";

  constructor(data?: PartialMessage<Invoice_LineItem>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "perkup.v1.Invoice.LineItem";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "amount", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "amount_excluding_tax", jsonName: "amount_excluding_tax", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "currency", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 5, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "invoice_item", jsonName: "invoice_item", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "price", kind: "message", T: Invoice_LineItemPrice, opt: true },
    { no: 8, name: "quantity", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 9, name: "subscription", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Invoice_LineItem {
    return new Invoice_LineItem().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Invoice_LineItem {
    return new Invoice_LineItem().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Invoice_LineItem {
    return new Invoice_LineItem().fromJsonString(jsonString, options);
  }

  static equals(a: Invoice_LineItem | PlainMessage<Invoice_LineItem> | undefined, b: Invoice_LineItem | PlainMessage<Invoice_LineItem> | undefined): boolean {
    return proto3.util.equals(Invoice_LineItem, a, b);
  }
}

/**
 * @generated from message perkup.v1.Invoice.LineItemPrice
 */
export class Invoice_LineItemPrice extends Message<Invoice_LineItemPrice> {
  /**
   * @generated from field: bool active = 1;
   */
  active = false;

  /**
   * @generated from field: int64 created = 2;
   */
  created = protoInt64.zero;

  /**
   * @generated from field: string currency = 3;
   */
  currency = "";

  /**
   * @generated from field: string id = 4;
   */
  id = "";

  /**
   * @generated from field: string type = 6;
   */
  type = "";

  /**
   * @generated from field: int64 unit_amount = 7 [json_name = "unit_amount"];
   */
  unitAmount = protoInt64.zero;

  /**
   * @generated from field: string product = 8;
   */
  product = "";

  constructor(data?: PartialMessage<Invoice_LineItemPrice>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "perkup.v1.Invoice.LineItemPrice";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "active", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "created", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "currency", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "unit_amount", jsonName: "unit_amount", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 8, name: "product", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Invoice_LineItemPrice {
    return new Invoice_LineItemPrice().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Invoice_LineItemPrice {
    return new Invoice_LineItemPrice().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Invoice_LineItemPrice {
    return new Invoice_LineItemPrice().fromJsonString(jsonString, options);
  }

  static equals(a: Invoice_LineItemPrice | PlainMessage<Invoice_LineItemPrice> | undefined, b: Invoice_LineItemPrice | PlainMessage<Invoice_LineItemPrice> | undefined): boolean {
    return proto3.util.equals(Invoice_LineItemPrice, a, b);
  }
}

