import { RewardUseCase } from 'constants/newReward/rewardUseCases'
import { RewardUseCasesKeys } from 'constants/newReward/rewardUseCases/RewardUseCasesKeys'

export enum ImageSearchEngine {
  ALL = 'All',
  FEATURED = 'Featured',
  GIPHY = 'Giphy',
  UNSPLASH = 'Unsplash',
  CUSTOM = 'Custom',
}

export const FEATURED = 'Featured'

export const regularSearchCategories = [
  FEATURED,
  RewardUseCasesKeys.BIRTHDAYS,
  RewardUseCasesKeys.ANNIVERSARIES,
  RewardUseCasesKeys.NEW_CUSTOMER,
  RewardUseCasesKeys.THANKS,
  RewardUseCasesKeys.WELCOME,
  RewardUseCasesKeys.CONGRATS,
  RewardUseCasesKeys.NEW_BABY,
  RewardUseCasesKeys.DOG,
  RewardUseCasesKeys.CAT,
] satisfies (RewardUseCase | typeof FEATURED)[]

export const ALL_USE_CASES = 'All'

export type CurrentUseCase =
  | typeof FEATURED
  | typeof ALL_USE_CASES
  | RewardUseCase
