import { useEffect, useState } from 'react'
import { customTokenSignIn } from 'services'
import { useDecryptRewardDetails } from './useDecryptRewardDetails'
import { useIsSignedInUser } from './useIsSignedInUser'

export function useCustomTokenSignIn({ previewId }: { previewId?: string }) {
  const [isLoading, setIsLoading] = useState(false)

  const { rewardDetails } = useDecryptRewardDetails({ previewId })

  const email = rewardDetails?.email
  const memberId = rewardDetails?.memberId

  const isSignedInUser = useIsSignedInUser({
    email: email || '',
    memberId: memberId || '',
  })

  useEffect(() => {
    if (previewId && !isSignedInUser) {
      setIsLoading(true)
      customTokenSignIn({ previewId }).finally(() => {
        setIsLoading(false)
      })
    }
  }, [previewId, isSignedInUser])

  return { isLoading }
}
