import { captureException } from '@sentry/react'
import type { PhoneNumber } from 'libphonenumber-js'
import { parsePhoneNumber as parse } from 'libphonenumber-js'
import { toSentry } from 'utils/sentry'

export function parsePhoneNumber(
  phoneNumber: string | null | undefined
): PhoneNumber | undefined {
  let parsed: PhoneNumber | undefined
  try {
    parsed = phoneNumber ? parse(phoneNumber) : undefined
  } catch (error) {
    console.error(error)
    captureException(toSentry(error), {
      contexts: { parsePhoneNumber: { phoneNumber } },
    })
  }
  return parsed
}
