const ISO2_TO_COUNTRY: Record<
  string,
  {
    iso3: string
    numeric: number
    latitude: number
    longitude: number
  }
> = {
  AF: {
    iso3: 'AFG',
    numeric: 4,
    latitude: 33,
    longitude: 65,
  },
  AX: {
    iso3: 'ALA',
    numeric: 248,
    latitude: 60.116667,
    longitude: 19.9,
  },
  AL: {
    iso3: 'ALB',
    numeric: 8,
    latitude: 41,
    longitude: 20,
  },
  DZ: {
    iso3: 'DZA',
    numeric: 12,
    latitude: 28,
    longitude: 3,
  },
  AS: {
    iso3: 'ASM',
    numeric: 16,
    latitude: -14.3333,
    longitude: -170,
  },
  AD: {
    iso3: 'AND',
    numeric: 20,
    latitude: 42.5,
    longitude: 1.6,
  },
  AO: {
    iso3: 'AGO',
    numeric: 24,
    latitude: -12.5,
    longitude: 18.5,
  },
  AI: {
    iso3: 'AIA',
    numeric: 660,
    latitude: 18.25,
    longitude: -63.1667,
  },
  AQ: {
    iso3: 'ATA',
    numeric: 10,
    latitude: -90,
    longitude: 0,
  },
  AG: {
    iso3: 'ATG',
    numeric: 28,
    latitude: 17.05,
    longitude: -61.8,
  },
  AR: {
    iso3: 'ARG',
    numeric: 32,
    latitude: -34,
    longitude: -64,
  },
  AM: {
    iso3: 'ARM',
    numeric: 51,
    latitude: 40,
    longitude: 45,
  },
  AW: {
    iso3: 'ABW',
    numeric: 533,
    latitude: 12.5,
    longitude: -69.9667,
  },
  AU: {
    iso3: 'AUS',
    numeric: 36,
    latitude: -27,
    longitude: 133,
  },
  AT: {
    iso3: 'AUT',
    numeric: 40,
    latitude: 47.3333,
    longitude: 13.3333,
  },
  AZ: {
    iso3: 'AZE',
    numeric: 31,
    latitude: 40.5,
    longitude: 47.5,
  },
  BS: {
    iso3: 'BHS',
    numeric: 44,
    latitude: 24.25,
    longitude: -76,
  },
  BH: {
    iso3: 'BHR',
    numeric: 48,
    latitude: 26,
    longitude: 50.55,
  },
  BD: {
    iso3: 'BGD',
    numeric: 50,
    latitude: 24,
    longitude: 90,
  },
  BB: {
    iso3: 'BRB',
    numeric: 52,
    latitude: 13.1667,
    longitude: -59.5333,
  },
  BY: {
    iso3: 'BLR',
    numeric: 112,
    latitude: 53,
    longitude: 28,
  },
  BE: {
    iso3: 'BEL',
    numeric: 56,
    latitude: 50.8333,
    longitude: 4,
  },
  BZ: {
    iso3: 'BLZ',
    numeric: 84,
    latitude: 17.25,
    longitude: -88.75,
  },
  BJ: {
    iso3: 'BEN',
    numeric: 204,
    latitude: 9.5,
    longitude: 2.25,
  },
  BM: {
    iso3: 'BMU',
    numeric: 60,
    latitude: 32.3333,
    longitude: -64.75,
  },
  BT: {
    iso3: 'BTN',
    numeric: 64,
    latitude: 27.5,
    longitude: 90.5,
  },
  BO: {
    iso3: 'BOL',
    numeric: 68,
    latitude: -17,
    longitude: -65,
  },
  BQ: {
    iso3: 'BES',
    numeric: 535,
    latitude: 12.183333,
    longitude: -68.233333,
  },
  BA: {
    iso3: 'BIH',
    numeric: 70,
    latitude: 44,
    longitude: 18,
  },
  BW: {
    iso3: 'BWA',
    numeric: 72,
    latitude: -22,
    longitude: 24,
  },
  BV: {
    iso3: 'BVT',
    numeric: 74,
    latitude: -54.4333,
    longitude: 3.4,
  },
  BR: {
    iso3: 'BRA',
    numeric: 76,
    latitude: -10,
    longitude: -55,
  },
  IO: {
    iso3: 'IOT',
    numeric: 86,
    latitude: -6,
    longitude: 71.5,
  },
  BN: {
    iso3: 'BRN',
    numeric: 96,
    latitude: 4.5,
    longitude: 114.6667,
  },
  BG: {
    iso3: 'BGR',
    numeric: 100,
    latitude: 43,
    longitude: 25,
  },
  BF: {
    iso3: 'BFA',
    numeric: 854,
    latitude: 13,
    longitude: -2,
  },
  MM: {
    iso3: 'MMR',
    numeric: 104,
    latitude: 22,
    longitude: 98,
  },
  BI: {
    iso3: 'BDI',
    numeric: 108,
    latitude: -3.5,
    longitude: 30,
  },
  KH: {
    iso3: 'KHM',
    numeric: 116,
    latitude: 13,
    longitude: 105,
  },
  CM: {
    iso3: 'CMR',
    numeric: 120,
    latitude: 6,
    longitude: 12,
  },
  CA: {
    iso3: 'CAN',
    numeric: 124,
    latitude: 60,
    longitude: -95,
  },
  CV: {
    iso3: 'CPV',
    numeric: 132,
    latitude: 16,
    longitude: -24,
  },
  KY: {
    iso3: 'CYM',
    numeric: 136,
    latitude: 19.5,
    longitude: -80.5,
  },
  CF: {
    iso3: 'CAF',
    numeric: 140,
    latitude: 7,
    longitude: 21,
  },
  TD: {
    iso3: 'TCD',
    numeric: 148,
    latitude: 15,
    longitude: 19,
  },
  CL: {
    iso3: 'CHL',
    numeric: 152,
    latitude: -30,
    longitude: -71,
  },
  CN: {
    iso3: 'CHN',
    numeric: 156,
    latitude: 35,
    longitude: 105,
  },
  CX: {
    iso3: 'CXR',
    numeric: 162,
    latitude: -10.5,
    longitude: 105.6667,
  },
  CC: {
    iso3: 'CCK',
    numeric: 166,
    latitude: -12.5,
    longitude: 96.8333,
  },
  CO: {
    iso3: 'COL',
    numeric: 170,
    latitude: 4,
    longitude: -72,
  },
  KM: {
    iso3: 'COM',
    numeric: 174,
    latitude: -12.1667,
    longitude: 44.25,
  },
  CD: {
    iso3: 'COD',
    numeric: 180,
    latitude: 0,
    longitude: 25,
  },
  CG: {
    iso3: 'COG',
    numeric: 178,
    latitude: -1,
    longitude: 15,
  },
  CK: {
    iso3: 'COK',
    numeric: 184,
    latitude: -21.2333,
    longitude: -159.7667,
  },
  CR: {
    iso3: 'CRI',
    numeric: 188,
    latitude: 10,
    longitude: -84,
  },
  CI: {
    iso3: 'CIV',
    numeric: 384,
    latitude: 8,
    longitude: -5,
  },
  HR: {
    iso3: 'HRV',
    numeric: 191,
    latitude: 45.1667,
    longitude: 15.5,
  },
  CU: {
    iso3: 'CUB',
    numeric: 192,
    latitude: 21.5,
    longitude: -80,
  },
  CW: {
    iso3: 'CUW',
    numeric: 531,
    latitude: 12.166667,
    longitude: -68.966667,
  },
  CY: {
    iso3: 'CYP',
    numeric: 196,
    latitude: 35,
    longitude: 33,
  },
  CZ: {
    iso3: 'CZE',
    numeric: 203,
    latitude: 49.75,
    longitude: 15.5,
  },
  DK: {
    iso3: 'DNK',
    numeric: 208,
    latitude: 56,
    longitude: 10,
  },
  DJ: {
    iso3: 'DJI',
    numeric: 262,
    latitude: 11.5,
    longitude: 43,
  },
  DM: {
    iso3: 'DMA',
    numeric: 212,
    latitude: 15.4167,
    longitude: -61.3333,
  },
  DO: {
    iso3: 'DOM',
    numeric: 214,
    latitude: 19,
    longitude: -70.6667,
  },
  EC: {
    iso3: 'ECU',
    numeric: 218,
    latitude: -2,
    longitude: -77.5,
  },
  EG: {
    iso3: 'EGY',
    numeric: 818,
    latitude: 27,
    longitude: 30,
  },
  SV: {
    iso3: 'SLV',
    numeric: 222,
    latitude: 13.8333,
    longitude: -88.9167,
  },
  GQ: {
    iso3: 'GNQ',
    numeric: 226,
    latitude: 2,
    longitude: 10,
  },
  ER: {
    iso3: 'ERI',
    numeric: 232,
    latitude: 15,
    longitude: 39,
  },
  EE: {
    iso3: 'EST',
    numeric: 233,
    latitude: 59,
    longitude: 26,
  },
  ET: {
    iso3: 'ETH',
    numeric: 231,
    latitude: 8,
    longitude: 38,
  },
  FK: {
    iso3: 'FLK',
    numeric: 238,
    latitude: -51.75,
    longitude: -59,
  },
  FO: {
    iso3: 'FRO',
    numeric: 234,
    latitude: 62,
    longitude: -7,
  },
  FJ: {
    iso3: 'FJI',
    numeric: 242,
    latitude: -18,
    longitude: 175,
  },
  FI: {
    iso3: 'FIN',
    numeric: 246,
    latitude: 64,
    longitude: 26,
  },
  FR: {
    iso3: 'FRA',
    numeric: 250,
    latitude: 46,
    longitude: 2,
  },
  GF: {
    iso3: 'GUF',
    numeric: 254,
    latitude: 4,
    longitude: -53,
  },
  PF: {
    iso3: 'PYF',
    numeric: 258,
    latitude: -15,
    longitude: -140,
  },
  TF: {
    iso3: 'ATF',
    numeric: 260,
    latitude: -43,
    longitude: 67,
  },
  GA: {
    iso3: 'GAB',
    numeric: 266,
    latitude: -1,
    longitude: 11.75,
  },
  GM: {
    iso3: 'GMB',
    numeric: 270,
    latitude: 13.4667,
    longitude: -16.5667,
  },
  GE: {
    iso3: 'GEO',
    numeric: 268,
    latitude: 42,
    longitude: 43.5,
  },
  DE: {
    iso3: 'DEU',
    numeric: 276,
    latitude: 51,
    longitude: 9,
  },
  GH: {
    iso3: 'GHA',
    numeric: 288,
    latitude: 8,
    longitude: -2,
  },
  GI: {
    iso3: 'GIB',
    numeric: 292,
    latitude: 36.1833,
    longitude: -5.3667,
  },
  GR: {
    iso3: 'GRC',
    numeric: 300,
    latitude: 39,
    longitude: 22,
  },
  GL: {
    iso3: 'GRL',
    numeric: 304,
    latitude: 72,
    longitude: -40,
  },
  GD: {
    iso3: 'GRD',
    numeric: 308,
    latitude: 12.1167,
    longitude: -61.6667,
  },
  GP: {
    iso3: 'GLP',
    numeric: 312,
    latitude: 16.25,
    longitude: -61.5833,
  },
  GU: {
    iso3: 'GUM',
    numeric: 316,
    latitude: 13.4667,
    longitude: 144.7833,
  },
  GT: {
    iso3: 'GTM',
    numeric: 320,
    latitude: 15.5,
    longitude: -90.25,
  },
  GG: {
    iso3: 'GGY',
    numeric: 831,
    latitude: 49.5,
    longitude: -2.56,
  },
  GW: {
    iso3: 'GNB',
    numeric: 624,
    latitude: 12,
    longitude: -15,
  },
  GN: {
    iso3: 'GIN',
    numeric: 324,
    latitude: 11,
    longitude: -10,
  },
  GY: {
    iso3: 'GUY',
    numeric: 328,
    latitude: 5,
    longitude: -59,
  },
  HT: {
    iso3: 'HTI',
    numeric: 332,
    latitude: 19,
    longitude: -72.4167,
  },
  HM: {
    iso3: 'HMD',
    numeric: 334,
    latitude: -53.1,
    longitude: 72.5167,
  },
  VA: {
    iso3: 'VAT',
    numeric: 336,
    latitude: 41.9,
    longitude: 12.45,
  },
  HN: {
    iso3: 'HND',
    numeric: 340,
    latitude: 15,
    longitude: -86.5,
  },
  HK: {
    iso3: 'HKG',
    numeric: 344,
    latitude: 22.25,
    longitude: 114.1667,
  },
  HU: {
    iso3: 'HUN',
    numeric: 348,
    latitude: 47,
    longitude: 20,
  },
  IS: {
    iso3: 'ISL',
    numeric: 352,
    latitude: 65,
    longitude: -18,
  },
  IN: {
    iso3: 'IND',
    numeric: 356,
    latitude: 20,
    longitude: 77,
  },
  ID: {
    iso3: 'IDN',
    numeric: 360,
    latitude: -5,
    longitude: 120,
  },
  IR: {
    iso3: 'IRN',
    numeric: 364,
    latitude: 32,
    longitude: 53,
  },
  IQ: {
    iso3: 'IRQ',
    numeric: 368,
    latitude: 33,
    longitude: 44,
  },
  IE: {
    iso3: 'IRL',
    numeric: 372,
    latitude: 53,
    longitude: -8,
  },
  IM: {
    iso3: 'IMN',
    numeric: 833,
    latitude: 54.23,
    longitude: -4.55,
  },
  IL: {
    iso3: 'ISR',
    numeric: 376,
    latitude: 31.5,
    longitude: 34.75,
  },
  IT: {
    iso3: 'ITA',
    numeric: 380,
    latitude: 42.8333,
    longitude: 12.8333,
  },
  JM: {
    iso3: 'JAM',
    numeric: 388,
    latitude: 18.25,
    longitude: -77.5,
  },
  JP: {
    iso3: 'JPN',
    numeric: 392,
    latitude: 36,
    longitude: 138,
  },
  JE: {
    iso3: 'JEY',
    numeric: 832,
    latitude: 49.21,
    longitude: -2.13,
  },
  JO: {
    iso3: 'JOR',
    numeric: 400,
    latitude: 31,
    longitude: 36,
  },
  KZ: {
    iso3: 'KAZ',
    numeric: 398,
    latitude: 48,
    longitude: 68,
  },
  KE: {
    iso3: 'KEN',
    numeric: 404,
    latitude: 1,
    longitude: 38,
  },
  KI: {
    iso3: 'KIR',
    numeric: 296,
    latitude: 1.4167,
    longitude: 173,
  },
  KP: {
    iso3: 'PRK',
    numeric: 408,
    latitude: 40,
    longitude: 127,
  },
  KR: {
    iso3: 'KOR',
    numeric: 410,
    latitude: 37,
    longitude: 127.5,
  },
  XK: {
    iso3: 'XKX',
    numeric: 95,
    latitude: 42.583333,
    longitude: 21,
  },
  KW: {
    iso3: 'KWT',
    numeric: 414,
    latitude: 29.3375,
    longitude: 47.6581,
  },
  KG: {
    iso3: 'KGZ',
    numeric: 417,
    latitude: 41,
    longitude: 75,
  },
  LA: {
    iso3: 'LAO',
    numeric: 418,
    latitude: 18,
    longitude: 105,
  },
  LV: {
    iso3: 'LVA',
    numeric: 428,
    latitude: 57,
    longitude: 25,
  },
  LB: {
    iso3: 'LBN',
    numeric: 422,
    latitude: 33.8333,
    longitude: 35.8333,
  },
  LS: {
    iso3: 'LSO',
    numeric: 426,
    latitude: -29.5,
    longitude: 28.5,
  },
  LR: {
    iso3: 'LBR',
    numeric: 430,
    latitude: 6.5,
    longitude: -9.5,
  },
  LY: {
    iso3: 'LBY',
    numeric: 434,
    latitude: 25,
    longitude: 17,
  },
  LI: {
    iso3: 'LIE',
    numeric: 438,
    latitude: 47.1667,
    longitude: 9.5333,
  },
  LT: {
    iso3: 'LTU',
    numeric: 440,
    latitude: 56,
    longitude: 24,
  },
  LU: {
    iso3: 'LUX',
    numeric: 442,
    latitude: 49.75,
    longitude: 6.1667,
  },
  MO: {
    iso3: 'MAC',
    numeric: 446,
    latitude: 22.1667,
    longitude: 113.55,
  },
  MK: {
    iso3: 'MKD',
    numeric: 807,
    latitude: 41.8333,
    longitude: 22,
  },
  MG: {
    iso3: 'MDG',
    numeric: 450,
    latitude: -20,
    longitude: 47,
  },
  MW: {
    iso3: 'MWI',
    numeric: 454,
    latitude: -13.5,
    longitude: 34,
  },
  MY: {
    iso3: 'MYS',
    numeric: 458,
    latitude: 2.5,
    longitude: 112.5,
  },
  MV: {
    iso3: 'MDV',
    numeric: 462,
    latitude: 3.25,
    longitude: 73,
  },
  ML: {
    iso3: 'MLI',
    numeric: 466,
    latitude: 17,
    longitude: -4,
  },
  MT: {
    iso3: 'MLT',
    numeric: 470,
    latitude: 35.8333,
    longitude: 14.5833,
  },
  MH: {
    iso3: 'MHL',
    numeric: 584,
    latitude: 9,
    longitude: 168,
  },
  MQ: {
    iso3: 'MTQ',
    numeric: 474,
    latitude: 14.6667,
    longitude: -61,
  },
  MR: {
    iso3: 'MRT',
    numeric: 478,
    latitude: 20,
    longitude: -12,
  },
  MU: {
    iso3: 'MUS',
    numeric: 480,
    latitude: -20.2833,
    longitude: 57.55,
  },
  YT: {
    iso3: 'MYT',
    numeric: 175,
    latitude: -12.8333,
    longitude: 45.1667,
  },
  MX: {
    iso3: 'MEX',
    numeric: 484,
    latitude: 23,
    longitude: -102,
  },
  FM: {
    iso3: 'FSM',
    numeric: 583,
    latitude: 6.9167,
    longitude: 158.25,
  },
  MD: {
    iso3: 'MDA',
    numeric: 498,
    latitude: 47,
    longitude: 29,
  },
  MC: {
    iso3: 'MCO',
    numeric: 492,
    latitude: 43.7333,
    longitude: 7.4,
  },
  MN: {
    iso3: 'MNG',
    numeric: 496,
    latitude: 46,
    longitude: 105,
  },
  ME: {
    iso3: 'MNE',
    numeric: 499,
    latitude: 42,
    longitude: 19,
  },
  MS: {
    iso3: 'MSR',
    numeric: 500,
    latitude: 16.75,
    longitude: -62.2,
  },
  MA: {
    iso3: 'MAR',
    numeric: 504,
    latitude: 32,
    longitude: -5,
  },
  MZ: {
    iso3: 'MOZ',
    numeric: 508,
    latitude: -18.25,
    longitude: 35,
  },
  NA: {
    iso3: 'NAM',
    numeric: 516,
    latitude: -22,
    longitude: 17,
  },
  NR: {
    iso3: 'NRU',
    numeric: 520,
    latitude: -0.5333,
    longitude: 166.9167,
  },
  NP: {
    iso3: 'NPL',
    numeric: 524,
    latitude: 28,
    longitude: 84,
  },
  AN: {
    iso3: 'ANT',
    numeric: 530,
    latitude: 12.25,
    longitude: -68.75,
  },
  NL: {
    iso3: 'NLD',
    numeric: 528,
    latitude: 52.5,
    longitude: 5.75,
  },
  NC: {
    iso3: 'NCL',
    numeric: 540,
    latitude: -21.5,
    longitude: 165.5,
  },
  NZ: {
    iso3: 'NZL',
    numeric: 554,
    latitude: -41,
    longitude: 174,
  },
  NI: {
    iso3: 'NIC',
    numeric: 558,
    latitude: 13,
    longitude: -85,
  },
  NE: {
    iso3: 'NER',
    numeric: 562,
    latitude: 16,
    longitude: 8,
  },
  NG: {
    iso3: 'NGA',
    numeric: 566,
    latitude: 10,
    longitude: 8,
  },
  NU: {
    iso3: 'NIU',
    numeric: 570,
    latitude: -19.0333,
    longitude: -169.8667,
  },
  NF: {
    iso3: 'NFK',
    numeric: 574,
    latitude: -29.0333,
    longitude: 167.95,
  },
  MP: {
    iso3: 'MNP',
    numeric: 580,
    latitude: 15.2,
    longitude: 145.75,
  },
  NO: {
    iso3: 'NOR',
    numeric: 578,
    latitude: 62,
    longitude: 10,
  },
  OM: {
    iso3: 'OMN',
    numeric: 512,
    latitude: 21,
    longitude: 57,
  },
  PK: {
    iso3: 'PAK',
    numeric: 586,
    latitude: 30,
    longitude: 70,
  },
  PW: {
    iso3: 'PLW',
    numeric: 585,
    latitude: 7.5,
    longitude: 134.5,
  },
  PS: {
    iso3: 'PSE',
    numeric: 275,
    latitude: 32,
    longitude: 35.25,
  },
  PA: {
    iso3: 'PAN',
    numeric: 591,
    latitude: 9,
    longitude: -80,
  },
  PG: {
    iso3: 'PNG',
    numeric: 598,
    latitude: -6,
    longitude: 147,
  },
  PY: {
    iso3: 'PRY',
    numeric: 600,
    latitude: -23,
    longitude: -58,
  },
  PE: {
    iso3: 'PER',
    numeric: 604,
    latitude: -10,
    longitude: -76,
  },
  PH: {
    iso3: 'PHL',
    numeric: 608,
    latitude: 13,
    longitude: 122,
  },
  PN: {
    iso3: 'PCN',
    numeric: 612,
    latitude: -24.7,
    longitude: -127.4,
  },
  PL: {
    iso3: 'POL',
    numeric: 616,
    latitude: 52,
    longitude: 20,
  },
  PT: {
    iso3: 'PRT',
    numeric: 620,
    latitude: 39.5,
    longitude: -8,
  },
  PR: {
    iso3: 'PRI',
    numeric: 630,
    latitude: 18.25,
    longitude: -66.5,
  },
  QA: {
    iso3: 'QAT',
    numeric: 634,
    latitude: 25.5,
    longitude: 51.25,
  },
  RE: {
    iso3: 'REU',
    numeric: 638,
    latitude: -21.1,
    longitude: 55.6,
  },
  RO: {
    iso3: 'ROU',
    numeric: 642,
    latitude: 46,
    longitude: 25,
  },
  RU: {
    iso3: 'RUS',
    numeric: 643,
    latitude: 60,
    longitude: 100,
  },
  RW: {
    iso3: 'RWA',
    numeric: 646,
    latitude: -2,
    longitude: 30,
  },
  BL: {
    iso3: 'BLM',
    numeric: 652,
    latitude: 17.897728,
    longitude: -62.834244,
  },
  SH: {
    iso3: 'SHN',
    numeric: 654,
    latitude: -15.9333,
    longitude: -5.7,
  },
  KN: {
    iso3: 'KNA',
    numeric: 659,
    latitude: 17.3333,
    longitude: -62.75,
  },
  LC: {
    iso3: 'LCA',
    numeric: 662,
    latitude: 13.8833,
    longitude: -61.1333,
  },
  MF: {
    iso3: 'MAF',
    numeric: 663,
    latitude: 18.075278,
    longitude: -63.06,
  },
  PM: {
    iso3: 'SPM',
    numeric: 666,
    latitude: 46.8333,
    longitude: -56.3333,
  },
  VC: {
    iso3: 'VCT',
    numeric: 670,
    latitude: 13.25,
    longitude: -61.2,
  },
  WS: {
    iso3: 'WSM',
    numeric: 882,
    latitude: -13.5833,
    longitude: -172.3333,
  },
  SM: {
    iso3: 'SMR',
    numeric: 674,
    latitude: 43.7667,
    longitude: 12.4167,
  },
  ST: {
    iso3: 'STP',
    numeric: 678,
    latitude: 1,
    longitude: 7,
  },
  SA: {
    iso3: 'SAU',
    numeric: 682,
    latitude: 25,
    longitude: 45,
  },
  SN: {
    iso3: 'SEN',
    numeric: 686,
    latitude: 14,
    longitude: -14,
  },
  RS: {
    iso3: 'SRB',
    numeric: 688,
    latitude: 44,
    longitude: 21,
  },
  SC: {
    iso3: 'SYC',
    numeric: 690,
    latitude: -4.5833,
    longitude: 55.6667,
  },
  SL: {
    iso3: 'SLE',
    numeric: 694,
    latitude: 8.5,
    longitude: -11.5,
  },
  SG: {
    iso3: 'SGP',
    numeric: 702,
    latitude: 1.3667,
    longitude: 103.8,
  },
  SX: {
    iso3: 'SXM',
    numeric: 534,
    latitude: 18.033333,
    longitude: -63.05,
  },
  SK: {
    iso3: 'SVK',
    numeric: 703,
    latitude: 48.6667,
    longitude: 19.5,
  },
  SI: {
    iso3: 'SVN',
    numeric: 705,
    latitude: 46,
    longitude: 15,
  },
  SB: {
    iso3: 'SLB',
    numeric: 90,
    latitude: -8,
    longitude: 159,
  },
  SO: {
    iso3: 'SOM',
    numeric: 706,
    latitude: 10,
    longitude: 49,
  },
  ZA: {
    iso3: 'ZAF',
    numeric: 710,
    latitude: -29,
    longitude: 24,
  },
  GS: {
    iso3: 'SGS',
    numeric: 239,
    latitude: -54.5,
    longitude: -37,
  },
  SS: {
    iso3: 'SSD',
    numeric: 728,
    latitude: 8,
    longitude: 30,
  },
  ES: {
    iso3: 'ESP',
    numeric: 724,
    latitude: 40,
    longitude: -4,
  },
  LK: {
    iso3: 'LKA',
    numeric: 144,
    latitude: 7,
    longitude: 81,
  },
  SD: {
    iso3: 'SDN',
    numeric: 736,
    latitude: 15,
    longitude: 30,
  },
  SR: {
    iso3: 'SUR',
    numeric: 740,
    latitude: 4,
    longitude: -56,
  },
  SJ: {
    iso3: 'SJM',
    numeric: 744,
    latitude: 78,
    longitude: 20,
  },
  SZ: {
    iso3: 'SWZ',
    numeric: 748,
    latitude: -26.5,
    longitude: 31.5,
  },
  SE: {
    iso3: 'SWE',
    numeric: 752,
    latitude: 62,
    longitude: 15,
  },
  CH: {
    iso3: 'CHE',
    numeric: 756,
    latitude: 47,
    longitude: 8,
  },
  SY: {
    iso3: 'SYR',
    numeric: 760,
    latitude: 35,
    longitude: 38,
  },
  TW: {
    iso3: 'TWN',
    numeric: 158,
    latitude: 23.5,
    longitude: 121,
  },
  TJ: {
    iso3: 'TJK',
    numeric: 762,
    latitude: 39,
    longitude: 71,
  },
  TZ: {
    iso3: 'TZA',
    numeric: 834,
    latitude: -6,
    longitude: 35,
  },
  TH: {
    iso3: 'THA',
    numeric: 764,
    latitude: 15,
    longitude: 100,
  },
  TL: {
    iso3: 'TLS',
    numeric: 626,
    latitude: -8.55,
    longitude: 125.5167,
  },
  TG: {
    iso3: 'TGO',
    numeric: 768,
    latitude: 8,
    longitude: 1.1667,
  },
  TK: {
    iso3: 'TKL',
    numeric: 772,
    latitude: -9,
    longitude: -172,
  },
  TO: {
    iso3: 'TON',
    numeric: 776,
    latitude: -20,
    longitude: -175,
  },
  TT: {
    iso3: 'TTO',
    numeric: 780,
    latitude: 11,
    longitude: -61,
  },
  TN: {
    iso3: 'TUN',
    numeric: 788,
    latitude: 34,
    longitude: 9,
  },
  TR: {
    iso3: 'TUR',
    numeric: 792,
    latitude: 39,
    longitude: 35,
  },
  TM: {
    iso3: 'TKM',
    numeric: 795,
    latitude: 40,
    longitude: 60,
  },
  TC: {
    iso3: 'TCA',
    numeric: 796,
    latitude: 21.75,
    longitude: -71.5833,
  },
  TV: {
    iso3: 'TUV',
    numeric: 798,
    latitude: -8,
    longitude: 178,
  },
  UG: {
    iso3: 'UGA',
    numeric: 800,
    latitude: 1,
    longitude: 32,
  },
  UA: {
    iso3: 'UKR',
    numeric: 804,
    latitude: 49,
    longitude: 32,
  },
  AE: {
    iso3: 'ARE',
    numeric: 784,
    latitude: 24,
    longitude: 54,
  },
  GB: {
    iso3: 'GBR',
    numeric: 826,
    latitude: 54,
    longitude: -2,
  },
  UM: {
    iso3: 'UMI',
    numeric: 581,
    latitude: 19.2833,
    longitude: 166.6,
  },
  US: {
    iso3: 'USA',
    numeric: 840,
    latitude: 38,
    longitude: -97,
  },
  UY: {
    iso3: 'URY',
    numeric: 858,
    latitude: -33,
    longitude: -56,
  },
  UZ: {
    iso3: 'UZB',
    numeric: 860,
    latitude: 41,
    longitude: 64,
  },
  VU: {
    iso3: 'VUT',
    numeric: 548,
    latitude: -16,
    longitude: 167,
  },
  VE: {
    iso3: 'VEN',
    numeric: 862,
    latitude: 8,
    longitude: -66,
  },
  VN: {
    iso3: 'VNM',
    numeric: 704,
    latitude: 16,
    longitude: 106,
  },
  VG: {
    iso3: 'VGB',
    numeric: 92,
    latitude: 18.5,
    longitude: -64.5,
  },
  VI: {
    iso3: 'VIR',
    numeric: 850,
    latitude: 18.3333,
    longitude: -64.8333,
  },
  WF: {
    iso3: 'WLF',
    numeric: 876,
    latitude: -13.3,
    longitude: -176.2,
  },
  EH: {
    iso3: 'ESH',
    numeric: 732,
    latitude: 24.5,
    longitude: -13,
  },
  YE: {
    iso3: 'YEM',
    numeric: 887,
    latitude: 15,
    longitude: 48,
  },
  ZM: {
    iso3: 'ZMB',
    numeric: 894,
    latitude: -15,
    longitude: 30,
  },
  ZW: {
    iso3: 'ZWE',
    numeric: 716,
    latitude: -20,
    longitude: 30,
  },
}

export default ISO2_TO_COUNTRY
