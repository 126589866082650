import placidData from 'constants/placid/placid-data.json'
import useIds from 'hooks/useIds'
import { compact } from 'lodash-es'
import { useEffect, useState } from 'react'
import { GetFirebaseStorageImage } from 'services/images'

export function useAllBrandedImages() {
  const { orgId } = useIds()
  const [allBrandedImages, setAllBrandedImages] = useState<string[]>([])
  const [hasLoaded, setHasLoaded] = useState<boolean>(false)

  useEffect(() => {
    // Get all branded images from Firebase Storage separately to avoid unnecessarily fetching resizes images from the bucket
    Promise.all(
      placidData.map(async ({ uuid }) => {
        const res = await GetFirebaseStorageImage({
          orgId,
          imageName: `${uuid}.jpg`,
          suffix: 'placidImages',
        })
        return res?.url
      })
    )
      .then(urls => setAllBrandedImages(compact(urls)))
      .finally(() => setHasLoaded(true))
  }, [orgId])

  return { allBrandedImages, hasLoaded }
}
