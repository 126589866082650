import { ConnectError } from '@connectrpc/connect'
import { captureException } from '@sentry/react'
import { Flex } from 'antd'
import { GetProductVariantById } from 'api/databaseCalls/reads/productVariants'
import { CheckoutForm, CheckoutTerms, PerkLoader } from 'components'
import { VariantEstimatedShippingTime } from 'components/ProductVariants/variant-estimated-shipping-time'
import {
  ALGOLIA_PRODUCT_VARIANTS_INDEX,
  CARRO_TAG,
  SYNCEE_TAG,
} from 'constants/algolia'
import {
  ESTIMATED_TAX,
  ITEM_TOTAL,
  SHIPPING_AND_HANDLING,
  TOTAL_BEFORE_TAX,
} from 'constants/checkout'
import { UNITES_STATES_ISO3, countryIso3ToIso2 } from 'constants/countries'
import { USD } from 'constants/currencies'
import { MARKETPLACE, ORDER_COMPLETED } from 'constants/events'
import { MAX_CHECKOUT_PAGE_WIDTH } from 'constants/layout'
import { MANUAL_GIFT_TAX_RATE } from 'constants/money'
import { PERKUP_NAME } from 'constants/perkupLinks'
import * as ROUTES from 'constants/routes'
import { CountryContext, OrgContext, UserContext } from 'context'
import { EmptyState, SearchIcon, Text, toaster, useTheme } from 'evergreen-ui'
import { ProductVariant } from 'gen/perkup/v1/product_variant_pb'
import { Item } from 'gen/perkup/v1/program_pb'
import { ShippingAddress } from 'gen/perkup/v1/root_user_pb'
import { lowerCase } from 'lodash-es'
import { useContext, useEffect, useState } from 'react'
import { useNavigate, useOutletContext, useParams } from 'react-router-dom'
import { placeManualOrder } from 'services/manual'
import { OrderConfirmationInfo } from 'types/Transactions'
import { logEvent } from 'utils'
import { getOrderErrorMessage } from 'utils/errors'
import {
  buildProductVariantDisplayName,
  isNearCashProductVariant,
} from 'utils/productVariant'

// TODO: Eventually this should be deprecated and the marketplace should use ProductVariantsCheckout.tsx and a cart
export function MarketplaceCheckout({ balance }: { balance: number }) {
  const user = useContext(UserContext)
  const userId = user?.id
  const country = useContext(CountryContext)
  const org = useContext(OrgContext)
  const orgId = org?.id

  const countryIso3 = lowerCase(country.iso3)
  const theme = useTheme()
  const navigate = useNavigate()
  const { vid: productVariantId } = useParams()

  const [productVariant, setProductVariant] = useState<ProductVariant>()
  const [isPurchaseLoading, setIsPurchaseLoading] = useState(false)
  const [isVariantLoading, setIsVariantLoading] = useState(false)

  const outletContext: {
    shippingAddress?: ShippingAddress
    onShippingAddressChange: (address?: ShippingAddress) => void
  } = useOutletContext()

  const shippingAddress = outletContext?.shippingAddress
  const onShippingAddressChange = outletContext?.onShippingAddressChange

  useEffect(() => {
    if (productVariantId) {
      setIsVariantLoading(true)
      GetProductVariantById({ productVariantId })
        .then(res => {
          if (res) {
            setProductVariant(res)
          }
        })
        .finally(() => setIsVariantLoading(false))
    }
  }, [productVariantId])

  if (isVariantLoading) return <PerkLoader />

  if (!productVariant) {
    return (
      <EmptyState
        background="light"
        title="Product not found"
        orientation="horizontal"
        icon={<SearchIcon color={theme.colors.gray500} />}
        iconBgColor={theme.colors.gray200}
      />
    )
  }

  const handlePurchaseProduct = ({
    buttonLocation,
  }: {
    buttonLocation?: string
  }) => {
    if (!shippingAddress) {
      toaster.warning('Missing shipping address')

      captureException('Missing user shipping address', {
        contexts: {
          ProductCheckout: {
            productId: productVariant.id,
            vendor: productVariant.vendor,
            user,
            shippingAddress,
          },
        },
      })
      return
    }

    setIsPurchaseLoading(true)
    const productVariantId = productVariant?.id

    const orderItem = new Item({
      productVariantId,
      productId: productVariant.productId,
      quantity: 1,
      provider: productVariant.provider,
    })

    placeManualOrder({
      shippingAddress,
      firstName: user.profile?.firstName ?? '',
      lastName: user.profile?.lastName ?? '',
      items: [orderItem],
      programIds: [],
      noPerkupFee: true,
      orgId,
      userId,
    })
      .then(response => {
        if (response) {
          // Shopping will only have one orderId for now
          const orderId = response.orderIds[0]
          toaster.success('Order placed successfully')
          const locationState: OrderConfirmationInfo = {
            shippingAddress,
            imageUrl: productVariant.imageUrl,
            transactionConfirmed: true,
            orderId,
          }
          logEvent(ORDER_COMPLETED, {
            affiliation: MARKETPLACE,
            orgId,
            userId,
            orderId,
            buttonLocation,
            userToken: userId,
            index: ALGOLIA_PRODUCT_VARIANTS_INDEX,
            objectIDs: [productVariantId],
            eventType: 'conversion',
          })

          navigate(ROUTES.SHOP, {
            state: locationState,
          })
        }
      })
      .catch((error: ConnectError) => {
        const { title, description } = getOrderErrorMessage({ error })

        toaster.warning(title, {
          description: description ? (
            <Text dangerouslySetInnerHTML={{ __html: description }} />
          ) : null,
        })
      })
      .finally(() => setIsPurchaseLoading(false))
  }
  const itemTotal = Number(productVariant.amount) || 0
  const tax = isNearCashProductVariant(productVariant)
    ? 0
    : itemTotal * MANUAL_GIFT_TAX_RATE
  const orderTotal = Math.round(itemTotal + tax)

  const defaultOrderSummary: {
    label: string
    amount: number
    border?: string
  }[] = [
    {
      label: ITEM_TOTAL,
      amount: Number(productVariant.amount),
    },
    {
      label: SHIPPING_AND_HANDLING,
      amount: 0,
      border: 'muted',
    },
    {
      label: TOTAL_BEFORE_TAX,
      amount: Number(productVariant.amount),
    },
    { label: ESTIMATED_TAX, amount: tax },
  ]

  // Don't show shipping and handling for near cash products
  const orderSummary = defaultOrderSummary.filter(item => {
    if (
      isNearCashProductVariant(productVariant) &&
      item.label === SHIPPING_AND_HANDLING
    ) {
      return false
    }
    return true
  })

  const restrictedToUSA =
    productVariant.tags.includes(CARRO_TAG) ||
    productVariant.tags.includes(SYNCEE_TAG)

  const shippingAddressCountry = lowerCase(shippingAddress?.country)

  const { shippingCountries } = productVariant

  const hasUSAShippingAddress =
    shippingAddressCountry === lowerCase(countryIso3ToIso2[UNITES_STATES_ISO3])

  const meetsUSARestrictedRequirements =
    restrictedToUSA &&
    hasUSAShippingAddress &&
    countryIso3 === UNITES_STATES_ISO3

  const determineInEligible = () => {
    // For now, only allow carro for US users
    if (meetsUSARestrictedRequirements) {
      return undefined
    }
    if (restrictedToUSA && !hasUSAShippingAddress) {
      return 'Shipping address must be in the United States to purchase this product.'
    }

    if (!shippingCountries.includes(countryIso3)) {
      return `Product is not available in ${country.name}.`
    }

    const invaludShippingAddress = !shippingCountries.some(sc => {
      const shippingCountryIso2 = countryIso3ToIso2[sc]
      return shippingCountryIso2 === shippingAddressCountry
    })

    if (invaludShippingAddress) {
      return 'Product not available in your country. Change your shipping address or select a different product.'
    }

    return undefined
  }

  const productTitle = buildProductVariantDisplayName({
    productVariant,
  })
  const showExchangeRateNote = lowerCase(productVariant.currencyCode) !== USD

  return (
    <Flex
      justify="center"
      style={{ maxWidth: MAX_CHECKOUT_PAGE_WIDTH, margin: 'auto' }}
    >
      <CheckoutForm
        inEligibleReason={determineInEligible()}
        budget={balance}
        orderTotal={orderTotal}
        title={productTitle}
        imageUrl={productVariant.imageUrl}
        productPrice={Number(productVariant.amount) || 0}
        deliveryType={productVariant?.deliveryType}
        soldBy={productVariant.productName}
        onPlaceOrder={({ buttonLocation }: { buttonLocation?: string }) =>
          handlePurchaseProduct({ buttonLocation })
        }
        isLoading={isPurchaseLoading}
        orderSummary={orderSummary}
        showExchangeRateNote={showExchangeRateNote}
        termsElement={<CheckoutTerms companyName={PERKUP_NAME} />}
        deliveryEstimateElement={
          <VariantEstimatedShippingTime
            productVariant={productVariant}
            shippingAddress={shippingAddress}
          />
        }
        shippingAddress={shippingAddress}
        onAddressChange={onShippingAddressChange}
      />
    </Flex>
  )
}
