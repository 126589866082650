import { PlusOutlined } from '@ant-design/icons'
import { captureMessage } from '@sentry/react'
import { Button, ButtonProps } from 'antd'
import { createOrgList } from 'api/databaseCalls'
import {
  Code,
  Dialog,
  Pane,
  Strong,
  TextInputField,
  toaster,
} from 'evergreen-ui'
import useIds from 'hooks/useIds'
import { camelCase } from 'lodash-es'
import { useState } from 'react'

export function CreateLabel({ buttonProps }: { buttonProps?: ButtonProps }) {
  const { orgId } = useIds()

  const [isShown, setIsShown] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [labelName, setLabelName] = useState<string>('')

  const productName = 'custom labels'
  const title = `Create ${productName}`

  const handleClose = () => {
    setIsShown(false)
    setLabelName('')
  }

  const handleSubmit = () => {
    if (!labelName) {
      toaster.warning(`Missing field ${productName}`)
      return
    }
    setIsLoading(true)
    createOrgList({ orgId, name: labelName })
      .then(() => {
        toaster.success(`New ${productName} created`)
        handleClose()
      })
      .catch(() => {
        captureMessage(`Error creating ${productName}`)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  return (
    <Pane>
      <Button
        style={{ display: 'flex', alignItems: 'center' }}
        icon={<PlusOutlined />}
        type="primary"
        onClick={() => setIsShown(true)}
        {...buttonProps}
      >
        {title}
      </Button>
      <Dialog
        isShown={isShown}
        title={title}
        onCloseComplete={handleClose}
        onCancel={handleClose}
        onConfirm={handleSubmit}
        confirmLabel="Create"
        preventBodyScrolling
        isConfirmLoading={isLoading}
        isConfirmDisabled={!labelName}
      >
        <Pane>
          <form onSubmit={handleSubmit}>
            <TextInputField
              label="Name"
              placeholder="For example: Department, Team, Division, Country"
              value={labelName}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setLabelName(e.currentTarget.value)
              }
              autoFocus
              required
            />
          </form>
          <Pane display="flex" gap={8} alignItems="center">
            <Strong>Key:</Strong>
            {labelName && <Code>{camelCase(labelName)}</Code>}
          </Pane>
        </Pane>
      </Dialog>
    </Pane>
  )
}
