import { SyncOutlined } from '@ant-design/icons'
import { Timestamp } from '@bufbuild/protobuf'
import { Button, Flex, Progress, Tag, Tooltip } from 'antd'
import {
  ListenToEnrolledMemberUsersByProgramId,
  ListenToProgramById,
  ListenToScheduledMembersByProgramId,
  updateProgram,
  updateProgramEnrollment,
} from 'api/databaseCalls'
import {
  BackIconButton,
  DeleteProgram,
  EditableTitle,
  FrequencySelection,
  ProgramCategories,
  Toggle,
  withOrgSidebar,
} from 'components'
import { ProgramStatusTag } from 'components/Programs/ProgramStatusTag'
import { MemberTable } from 'components/Tables/Users/MemberTable'
import { ScheduledMembersTable } from 'components/Tables/Users/ScheduledMembersTable'
import { DEFAULT_ROUTES } from 'constants/routes'
import { OrgContext, ProgramContext } from 'context'
import { Dayjs } from 'dayjs'
import { Heading, Pane, Text, toaster, useTheme } from 'evergreen-ui'
import { SelectAmount } from 'features'
import {
  Member,
  Program,
  ProgramFrequency,
  ProgramType,
  ScheduledMember,
} from 'gen/perkup/v1/program_pb'
import isFunction from 'lodash-es/isFunction'
import sum from 'lodash-es/sum'
import { useContext, useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { numToDollars } from 'utils'

const MIN_BUDGET = 100

function ProgramAmount({
  program,
  orgId,
}: {
  program: Program
  orgId: string
}) {
  const [edit, setEdit] = useState(false)
  const [budgetVal, setBudgetVal] = useState(program.budget)
  const [frequency, setFrequency] = useState(program.frequency)
  const [prorate, setProrate] = useState(program.prorate ?? false)
  const [prorateDate, setProrateDate] = useState(program.prorateDate)
  const [rollover, setRollover] = useState(program.rollover ?? false)

  if (!program || !orgId) return null

  const handleCancel = () => {
    setEdit(false)
    setBudgetVal(program.budget)
    setFrequency(program.frequency)
    setProrate(program.prorate ?? false)
    setProrateDate(program.prorateDate)
    setRollover(program.rollover ?? false)
  }

  const handleSubmit = () => {
    if (budgetVal < MIN_BUDGET) {
      toaster.warning('Budget must be at least $1.00')
      return
    }

    const programUpdates: {
      budget: number
      frequency: ProgramFrequency
      prorate: boolean
      rollover: boolean
      prorateDate?: Timestamp
    } = {
      budget: Math.round(budgetVal),
      frequency,
      rollover,
      prorate,
      prorateDate: prorateDate || Timestamp.fromDate(new Date()),
    }

    updateProgram({
      orgId,
      programId: program.id,
      program: programUpdates,
    })
    toaster.success('Program updated')
    setEdit(false)
  }

  if (edit) {
    return (
      <Pane background="tint2" padding={16} borderRadius={2} width="100%">
        <Heading size={100} marginBottom={16}>
          Program spending limits
        </Heading>
        <SelectAmount amount={budgetVal} onAmountChange={setBudgetVal} />
        <Pane marginY={8} />
        <FrequencySelection
          value={frequency}
          onChange={setFrequency}
          prorate={prorate}
          onChangeProrate={(value: boolean) => setProrate(value)}
          prorateDate={prorateDate}
          onChangeProrateDate={(newDate: Dayjs | null) => {
            if (newDate) setProrateDate(Timestamp.fromDate(newDate.toDate()))
          }}
        />

        <Pane marginTop={16}>
          <Toggle
            checked={rollover}
            label="Rollover unused budgets"
            onChange={e => setRollover(e.target.checked)}
          />
        </Pane>
        <Pane marginTop={32}>
          <Button
            type="default"
            onClick={handleCancel}
            style={{ marginRight: 8 }}
          >
            Cancel
          </Button>
          <Button
            type="primary"
            disabled={!budgetVal || budgetVal <= 0}
            onClick={handleSubmit}
          >
            Save
          </Button>
        </Pane>
      </Pane>
    )
  }

  return (
    <Pane display="flex" alignItems="center">
      <Heading size={800} marginRight={8} className="text-success">
        {numToDollars(program?.budget)}
      </Heading>
      <Text size={600}>{ProgramFrequency[program?.frequency]} per person</Text>
      {program.rollover && (
        <Tooltip title="Budgets rollover">
          <SyncOutlined />
        </Tooltip>
      )}
      <Button style={{ marginLeft: 32 }} onClick={() => setEdit(true)}>
        Edit Budget
      </Button>
    </Pane>
  )
}

function PerkProgramDetails() {
  const theme = useTheme()
  const org = useContext(OrgContext)
  const location = useLocation()
  const { programId } = useParams()

  const orgId = org?.id

  const [program, setProgram] = useState<Program>()
  const [totalBudget, setTotalBudget] = useState(0)
  const [totalSpent, setTotalSpent] = useState(0)

  const [scheduledMembers, setScheduledMembers] = useState<ScheduledMember[]>(
    []
  )
  const [enrolledMembers, setEnrolledMembers] = useState<Member[]>([])

  const [approvedCategories, setApprovedCategories] = useState<string[]>([])
  const [allMerchants, setAllMerchants] = useState(false)
  const [editMode, setEditMode] = useState(false)
  const isRewards = location.pathname.includes(
    DEFAULT_ROUTES.ORGANIZATION.REWARDS.ROOT
  )

  useEffect(() => {
    if (program) {
      setAllMerchants(!!program.allMerchants)
      setApprovedCategories(program.approvedCategories)
    }
  }, [program])

  useEffect(() => {
    if (!orgId || !programId) return undefined
    const unsubscribeProgram = ListenToProgramById({
      orgId,
      programId,
      cb: setProgram,
    })

    const unsubscribeMembers = ListenToEnrolledMemberUsersByProgramId({
      orgId,
      programId,
      cb: setEnrolledMembers,
    })

    const unsubscribeScheduleMembers = ListenToScheduledMembersByProgramId({
      orgId,
      programId,
      cb: setScheduledMembers,
    })

    return () => {
      if (isFunction(unsubscribeProgram)) unsubscribeProgram()
      if (isFunction(unsubscribeMembers)) unsubscribeMembers()
      if (isFunction(unsubscribeScheduleMembers)) unsubscribeScheduleMembers()
    }
  }, [programId, orgId])

  useEffect(() => {
    if (enrolledMembers.length) {
      const newBudget = sum(
        enrolledMembers.map(enrolledMember => enrolledMember.budget)
      )
      const newSpent = sum(
        enrolledMembers.map(enrolledMember => enrolledMember.spent)
      )
      setTotalBudget(newBudget)
      setTotalSpent(newSpent)
    }
  }, [enrolledMembers])

  const availableSpendPercent = (1 - totalSpent / totalBudget) * 100

  if (!program || !programId) return null

  const handleConfirmTitle = (name: string) => {
    updateProgram({
      orgId,
      programId: program.id,
      program: { name },
    }).then(() => toaster.success('Title updated', { id: name }))
  }

  return (
    <Pane>
      {/* HEADING */}
      <Pane
        display="flex"
        alignItems="center"
        marginBottom={32}
        justifyContent="space-between"
      >
        <Pane display="flex" gap={8} alignItems="center">
          <BackIconButton />

          <EditableTitle
            currentTitle={program.name}
            onConfirmTitle={handleConfirmTitle}
          />

          <Flex gap={8} align="center" style={{ marginLeft: 16 }}>
            {program.type === ProgramType.direct && (
              <Tag color="gold">Direct mail</Tag>
            )}
            <ProgramStatusTag program={program} />
          </Flex>
        </Pane>

        <DeleteProgram program={program} />
      </Pane>
      {/** PROGRAM DETAILS */}
      <Pane paddingX={40}>
        {/* SUBTITLE */}
        <Pane display="flex" alignItems="flex-end" marginBottom={16}>
          <ProgramAmount program={program} orgId={org.id} />
        </Pane>
        {/* PROGRESS BAR */}
        {totalBudget > 0 && (
          <Pane width={600} marginBottom={24}>
            <Pane display="flex" marginBottom={8}>
              <Pane display="flex" justifyContent="space-between" width="100%">
                <Text>
                  {numToDollars(totalBudget - totalSpent, 0)} Remaining budget
                </Text>
                <Text>{numToDollars(totalSpent, 0)} Spent</Text>
              </Pane>
            </Pane>
            <Progress
              trailColor={theme.colors.green100}
              strokeColor={theme.colors.green500}
              percent={availableSpendPercent}
              strokeLinecap="round"
              showInfo={false}
            />
          </Pane>
        )}
        <Pane display="flex" alignItems="center" flexWrap="wrap" marginTop={32}>
          <ProgramCategories
            approvedCategories={approvedCategories}
            setApprovedCategories={setApprovedCategories}
            allMerchants={allMerchants}
            setAllMerchants={setAllMerchants}
            edit={editMode}
            program={program}
            orgId={org.id}
            showEditIcon
            setEdit={setEditMode}
          />
        </Pane>
        {/* DIVIDER */}
        <Pane borderBottom marginY={32} />
        <ProgramContext.Provider value={program}>
          {/* SCHEDULED REWARDS */}
          {scheduledMembers?.length > 0 && (
            <Pane marginY={32}>
              <Heading size={700} marginBottom={16}>
                {isRewards ? 'Scheduled Rewards' : 'Scheduled Members'}
              </Heading>
              <ScheduledMembersTable scheduledMembers={scheduledMembers} />
            </Pane>
          )}

          {/* MEMBERS */}

          <MemberTable />
        </ProgramContext.Provider>
        {/* TOGGLE AUTO ENROLL */}
        {!isRewards && (
          <Pane marginY={32}>
            <Toggle
              checked={program.autoEnrolled}
              onChange={e =>
                updateProgramEnrollment({
                  orgId: org.id,
                  programId: program.id,
                  autoEnrolled: e.target.checked,
                })
              }
              label="Automatically enroll new users"
            />
          </Pane>
        )}
      </Pane>
    </Pane>
  )
}

export default withOrgSidebar(PerkProgramDetails)
