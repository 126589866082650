import AmazonBusinessLogo from 'assets/amazon-business-logo.svg'
import OrderConfirmation from 'components/Amazon/OrderConfirmation'
import { SearchbarCard } from 'components/SearchbarCard'
import { AMAZON_GIFT_CARD, CategoryLinks } from 'constants/amazon'
import { US_ID } from 'constants/countries'
import { PRODUCT_CATEGORY_CLICKED, PRODUCT_SEARCHED } from 'constants/events'
import * as PARAMS from 'constants/params'
import { AMAZON_SEARCH, BROWSE, GIFT_CARDS, SHOP } from 'constants/routes'
import { CountryContext, UserContext } from 'context'
import { Button, Pane } from 'evergreen-ui'
import { useSpendingOptions } from 'hooks/useSpendingOptions'
import { compact } from 'lodash-es'
import { useContext } from 'react'
import { isMobile } from 'react-device-detect'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { SearchAmazonRequest } from 'types/Amazon'
import { logEvent } from 'utils'

function AmazonBusinessIcon() {
  return (
    <Pane
      width="100%"
      height="100%"
      backgroundImage={`url(${AmazonBusinessLogo})`}
      backgroundRepeat="no-repeat"
      backgroundSize="contain"
      backgroundPosition="center"
    />
  )
}

function CategoryOptionLinkGroup({
  items,
}: {
  items: {
    displayName: string
    onClick: () => void
    icon?: JSX.Element
  }[]
}) {
  const columns = isMobile ? 1 : 4

  return (
    <Pane
      display="grid"
      gridTemplateColumns={`repeat(${columns}, minmax(176px, 1fr))`}
      gap="2rem"
    >
      {items.map(item => (
        <Button
          key={item.displayName}
          onClick={item.onClick}
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            fontSize: '12px',
          }}
          size="large"
          iconBefore={
            <Pane height="24px" width="24px">
              {item.icon}
            </Pane>
          }
        >
          {item.displayName}
        </Button>
      ))}
    </Pane>
  )
}

export default function Home({
  handleSearchAmazon,
}: {
  handleSearchAmazon: (data: SearchAmazonRequest) => void
}) {
  const user = useContext(UserContext)
  const country = useContext(CountryContext)
  const { showNearCash } = useSpendingOptions()
  const isUS = country.id === US_ID

  const canadaPlumAmazonGiftCard = 'plum_53259_500'
  const unitedStatesPlumAmazonGiftCard = 'plum_54372_500'

  const giftCardId = isUS
    ? unitedStatesPlumAmazonGiftCard
    : canadaPlumAmazonGiftCard

  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const amazonCats = compact(
    CategoryLinks.map(catLink => {
      if (catLink.displayName === AMAZON_GIFT_CARD) {
        if (!showNearCash) return null
        return {
          ...catLink,
          onClick: () => {
            logEvent(PRODUCT_CATEGORY_CLICKED, {
              userId: user.id,
              orgId: user.currentOrganization,
              category: AMAZON_GIFT_CARD,
            })
            navigate(`${SHOP}${GIFT_CARDS}${BROWSE}/${giftCardId}`)
          },
        }
      }
      return {
        ...catLink,
        onClick: () => {
          logEvent(PRODUCT_CATEGORY_CLICKED, {
            userId: user.id,
            orgId: user.currentOrganization,
            category: catLink.displayName,
          })
          handleSearchAmazon({ keywords: catLink.displayName })
          searchParams.set(PARAMS.KEYWORDS, catLink.displayName)
          navigate(`${AMAZON_SEARCH}?${searchParams.toString()}`)
        },
      }
    })
  )

  return (
    <>
      {/* Category Options */}
      <Pane>
        <OrderConfirmation />

        <Pane width="100%" height="432px" marginBottom={32}>
          <SearchbarCard
            handleSearch={(value: string) => {
              handleSearchAmazon({ keywords: value })
              searchParams.set(PARAMS.KEYWORDS, value)
              logEvent(PRODUCT_SEARCHED, {
                userId: user.id,
                orgId: user.currentOrganization,
                keywords: value,
                userToken: user.id,
              })
              navigate(`${AMAZON_SEARCH}?${searchParams.toString()}`)
            }}
            helpText="Prime shipping included on all orders, with discounts up to 10%."
            searchbarPlaceholder="Search Amazon..."
            disabled={!country?.amazon}
            Logo={AmazonBusinessIcon}
          />
        </Pane>
      </Pane>
      <Pane marginBottom={32}>
        <CategoryOptionLinkGroup items={amazonCats} />
      </Pane>
    </>
  )
}
