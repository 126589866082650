import { captureException } from '@sentry/react'
import { Button, Flex, Grid, Image } from 'antd'
import PaperAirplane from 'assets/paper-airplane.svg'
import { PerkUpName } from 'components'
import {
  actionCodeSettings,
  localStorageEmailForSignIn,
} from 'constants/firebaseConfig'
import { MARKETING_URL } from 'constants/perkupLinks'
import * as ROUTES from 'constants/routes'
import { Heading, Text, toaster } from 'evergreen-ui'
import { getAuth, sendSignInLinkToEmail } from 'firebase/auth'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toSentry } from 'utils/sentry'

const { useBreakpoint } = Grid

export default function VerifyEmailPage() {
  const navigate = useNavigate()

  const screens = useBreakpoint()
  const isBigScreen = screens.md || screens.lg || screens.xl || screens.xxl

  const [showResendButton, setShowResendButton] = useState(true)

  const email = window.localStorage.getItem(localStorageEmailForSignIn)

  if (!email) {
    navigate(ROUTES.HOME)
    return null
  }

  const handlResendEmail = () => {
    sendSignInLinkToEmail(getAuth(), email, actionCodeSettings)
      .then(() => {
        window.localStorage.setItem(localStorageEmailForSignIn, email)
        toaster.success(`Resent email to ${email}`)
      })
      .catch(error => {
        console.error(error)
        captureException(toSentry(error), { level: 'info' })
      })
      .finally(() => {
        setShowResendButton(false)
      })
  }

  return (
    <Flex
      vertical
      gap={isBigScreen ? 52 : 32}
      align="center"
      justify="center"
      style={{
        padding: 16,
        height: '100svh',
        backgroundImage:
          'linear-gradient(to bottom right, rgba(92, 51, 255, 0.09), white, white, rgba(92, 51, 255, 0.09))',
      }}
    >
      <Image src={PaperAirplane} alt="Paper airplane" height={100} />

      <Flex vertical align="center" gap={32}>
        <Heading size={900} marginBottom={12}>
          Check your email
        </Heading>

        <Flex vertical align="center" gap={8}>
          <Text size={500} textAlign="center">
            Verify your email by clicking the link sent to
          </Text>
          <Heading size={300}>{email}</Heading>
        </Flex>

        {showResendButton && (
          <Button
            size="large"
            style={{
              width: isBigScreen ? 'fit-content' : '100%',
            }}
            onClick={handlResendEmail}
          >
            Resend email
          </Button>
        )}
      </Flex>

      <a href={MARKETING_URL}>
        <PerkUpName height={21} color="#c8c8c8" />
      </a>
    </Flex>
  )
}
