import { CatContext } from 'context'
import { Checkbox, Image, Pane } from 'evergreen-ui'
import { uniq } from 'lodash-es'
import { useContext } from 'react'

export function CategorySelection({
  approvedCategories = [],
  handleChange,
}: {
  approvedCategories: string[]
  handleChange: Function
}) {
  const cats = useContext(CatContext)

  const handleInternalChange = ({
    checked,
    name,
  }: {
    checked: boolean
    name: string
  }) => {
    if (!checked) {
      handleChange(uniq([name, ...approvedCategories]))
    } else {
      handleChange(approvedCategories.filter(value => value !== name))
    }
  }

  const updateApprovedCategories = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked, name } = e.target
    handleInternalChange({ checked, name })
  }

  return (
    <Pane
      display="grid"
      gridTemplateColumns="repeat(auto-fill, 168px)"
      columnGap={24}
      width="100%"
    >
      {cats?.map(category => {
        const { name, regularIcon } = category
        if (!name || !regularIcon) return null
        const isActive = approvedCategories.includes(name)
        return (
          <Pane
            key={name}
            display="flex"
            alignItems="center"
            gap={8}
            cursor="pointer"
            onClick={() => handleInternalChange({ checked: isActive, name })}
          >
            <Checkbox
              name={name}
              checked={isActive}
              onChange={updateApprovedCategories}
            />
            <Image
              marginRight={8}
              marginLeft={4}
              src={regularIcon.url}
              height={16}
              width={16}
            />
            <Pane marginRight={4}>{name}</Pane>
          </Pane>
        )
      })}
    </Pane>
  )
}
