import { ItemInfo, PrepaidBadge } from 'components'
import useProductVariantById from 'hooks/productVariants/useProductVariantById'
import {
  isNearCashProductVariant,
  isPrepaidProductVariant,
} from 'utils/productVariant'

/**
 *
 * So far just a helper feature component for the Review page
 */
export function ProductSummary({
  productVariantId,
}: {
  productVariantId: string
}) {
  const { productVariant } = useProductVariantById({
    productVariantId,
  })

  if (!productVariant) return null

  const isPrepaid = isPrepaidProductVariant(productVariant)

  return (
    <ItemInfo
      name={productVariant.productName}
      image={productVariant.imageUrl}
      imageSize={80}
      imageFit={isNearCashProductVariant(productVariant) ? 'contain' : 'cover'}
      shippingCountries={productVariant.shippingCountries}
      amount={Number(productVariant.amount)}
      amountSuffix="/person"
    >
      {isPrepaid && <PrepaidBadge />}
    </ItemInfo>
  )
}
