import { captureMessage } from '@sentry/react'
import { GetVendorProductById } from 'api/databaseCalls'
import { CountryContext, MemberContext, ProgramContext } from 'context'
import { toaster } from 'evergreen-ui'
import { ShippingAddress } from 'gen/perkup/v1/root_user_pb'
import toUpper from 'lodash-es/toUpper'
import { useContext, useEffect, useState } from 'react'
import { getAmazonProduct } from 'services/amazon'
import {
  RewardPreviewProductType,
  useProductType,
} from 'utils/rewardAcceptance'

// tempIsShopify is a temporary prop to get Shopify products on the GiftReveal page,
// it's temporary because we want to get the value from Algolia eventually.
export function useMemberProduct({
  shippingAddress,
}: {
  shippingAddress?: ShippingAddress
}) {
  const { isAmazonGift, isVendorGift } = useProductType()

  const country = useContext(CountryContext)
  const member = useContext(MemberContext)
  const program = useContext(ProgramContext)
  const { gift } = program

  const [isLoading, setIsLoading] = useState(false)
  const [product, setProduct] = useState<RewardPreviewProductType>()

  // Get Amazon product on page reload
  useEffect(() => {
    if (isAmazonGift && gift?.id) {
      if (!gift?.country) {
        captureMessage('Required country is not set on Amazon Gift program')
        toaster.warning('Something went wrong. Please contact support.')
        return
      }

      setIsLoading(true)

      getAmazonProduct({
        asin: gift.id,
        postalCode: shippingAddress?.postalCode,
        countryCode: toUpper(gift?.country) || 'US',
      })
        .then(setProduct)
        .finally(() => setIsLoading(false))
    }
  }, [gift, shippingAddress, country.id, isAmazonGift])

  // Get vendor product with member info
  const memberGiftId = member?.gift?.giftId || program?.gift?.id
  useEffect(() => {
    const productId = memberGiftId
    if (isVendorGift && productId) {
      setIsLoading(true)

      GetVendorProductById({
        productId,
      })
        .then(setProduct)
        .finally(() => setIsLoading(false))
    }
  }, [isVendorGift, memberGiftId])

  return {
    isLoading,
    product,
  }
}
