import {
  AmazonOutlined,
  AppstoreTwoTone,
  CreditCardTwoTone,
  GiftTwoTone,
  RestTwoTone,
  ShoppingTwoTone,
  UserOutlined,
} from '@ant-design/icons'
import { Flex } from 'antd'
import {
  createOrgShippingAddress,
  removeOrgShippingAddress,
  updateOrgShippingAddress,
} from 'api/databaseCalls'
import {
  AllowedDomainsTable,
  ChangeCompanyCurrency,
  NewAddressButton,
  OrgSetting,
  PageHeader,
  PerkLoader,
  ShippingAddressBlock,
  withOrgSidebar,
} from 'components'
import { OrgContext } from 'context'
import { Heading, Pane, toaster } from 'evergreen-ui'
import { Organization_StripeConnectAccountCapability } from 'gen/perkup/v1/organization_pb'
import { ShippingAddress } from 'gen/perkup/v1/root_user_pb'
import { useCountOrgTemplates, useOrgShippingAddresses } from 'hooks'
import { useContext } from 'react'
import { getStripeAccountStatus, isConnectAccountActive } from 'services'
import { SettingOption } from 'types'
import { insertElementIf } from 'utils/arrays'

function OrgSettingsPage() {
  const org = useContext(OrgContext)

  const { numOrgTemplates } = useCountOrgTemplates({ orgId: org.id })

  const activeConnectAccount = isConnectAccountActive({ org })

  const { stripeConnectAccountCapabilities } = org

  const acctCap = Organization_StripeConnectAccountCapability

  const iconProps = {
    twoToneColor: org.primaryColor,
    style: { fontSize: 32 },
  }
  const settingOptions: SettingOption[] = [
    {
      title: 'PerkUp Shopping',
      description: 'Allow members to purchase products directly from PerkUp.',
      icon: <ShoppingTwoTone {...iconProps} />,
      key: 'hideShopping',
      defaultValue: !org.settings?.hideShopping,
    },
    {
      title: 'Gift Cards',
      description: 'Allow members to purchase gift cards directly from PerkUp.',
      icon: <AppstoreTwoTone {...iconProps} />,
      key: 'hideNearCash',
      defaultValue: !org.settings?.hideNearCash,
    },
    {
      title: 'Food & Beverage',
      description:
        'Allow members to purchase food gift cards directly from PerkUp.',
      icon: <RestTwoTone {...iconProps} />,
      key: 'hideFood',
      defaultValue: !org.settings?.hideFood,
    },
    {
      title: 'Amazon Direct Ordering',
      description: 'Allow members to purchase directly from Amazon on PerkUp.',
      icon: <AmazonOutlined {...iconProps} />,
      key: 'hideAmazon',
      defaultValue: !org.settings?.hideAmazon,
    },
    {
      title: 'Perk Card',
      description:
        'Allow members to access their own Visa Perk Card towards popular and local merchants.',
      icon: <CreditCardTwoTone {...iconProps} />,
      key: 'hidePerkCard',
      defaultValue: !org.settings?.hidePerkCard,
      hasWarning: !activeConnectAccount && !org.settings?.hidePerkCard,
      checklistItems: [
        {
          label: 'Card issuing',
          status: getStripeAccountStatus({
            stripeConnectAccountCapabilities,
            acctCap: acctCap.cardIssuing,
          }),
        },
        {
          label: 'Card payments',
          status: getStripeAccountStatus({
            stripeConnectAccountCapabilities,
            acctCap: acctCap.cardPayments,
          }),
        },
        {
          label: 'Transfers',
          status: getStripeAccountStatus({
            stripeConnectAccountCapabilities,
            acctCap: acctCap.cardPayments,
          }),
        },
      ],
    },

    ...insertElementIf(numOrgTemplates > 0, {
      title: 'Send rewards from scratch',
      description: 'Allow senders to create new rewards from scratch',
      icon: <GiftTwoTone {...iconProps} />,
      key: 'hideStartFromScratch',
      defaultValue: !org.settings?.hideStartFromScratch,
    }),
    {
      title: 'Manager invoice creation',
      description: 'Allow managers to create invoices with PerkUp.',
      icon: <UserOutlined {...iconProps} />,
      key: 'hideManagerInvoicing',
      defaultValue: !org.settings?.hideManagerInvoicing,
    },
  ]

  const { shippingAddresses, hasLoaded: hasLoadedOrgAddresses } =
    useOrgShippingAddresses()

  const handleCreateOrgAddress = async (address: ShippingAddress) => {
    await createOrgShippingAddress({
      shippingAddress: address,
      orgId: org.id,
    })
    toaster.success('Address added successfully')
  }

  const handleRemoveOrgAddress = async (addressId: string) => {
    await removeOrgShippingAddress({ orgId: org.id, addressId })
    toaster.success('Address removed successfully')
  }

  const handleUpdateOrgAddress = async (address: ShippingAddress) => {
    await updateOrgShippingAddress({
      shippingAddress: address,
      orgId: org.id,
    })
    toaster.success('Address updated')
  }

  return (
    <Flex vertical gap={32}>
      <PageHeader
        title="Settings"
        description="Customize your team's PerkUp experience."
        vlogLink="https://www.loom.com/embed/c0d1ea9afab04c5592e3546e4104cb1c?sid=607e041a-766a-4fe1-8470-458f3e5e915d"
      />

      <Flex vertical gap={32}>
        <Pane
          display="grid"
          gap={32}
          marginBottom={32}
          gridTemplateColumns="repeat(auto-fill, 712px)"
        >
          {settingOptions.map(option => (
            <OrgSetting key={option.key} settingOption={option} />
          ))}
        </Pane>
        <Flex vertical gap={8} style={{ width: 'fit-content' }}>
          <Heading size={600}>Display currency</Heading>
          <ChangeCompanyCurrency />
        </Flex>
        <Flex style={{ width: 'fit-content', maxWidth: 752 }}>
          <AllowedDomainsTable />
        </Flex>
        <Flex vertical gap={8}>
          <Heading size={600}>Addresses</Heading>
          {hasLoadedOrgAddresses ? (
            <Flex wrap="wrap" align="stretch" gap={32}>
              <NewAddressButton
                addAddressCTALabel="Add address"
                submitLabel="Add"
                dialogTitle="Add a new address"
                asSmallButton={false}
                onAddressSubmit={handleCreateOrgAddress}
              />

              {shippingAddresses.map(shippingAddress => (
                <ShippingAddressBlock
                  key={shippingAddress.id}
                  shippingAddress={shippingAddress}
                  onAddressChange={addressData => {
                    if (!addressData) {
                      handleRemoveOrgAddress(shippingAddress.id)
                    } else if (addressData.id) {
                      handleUpdateOrgAddress(addressData)
                    } else {
                      handleCreateOrgAddress(addressData)
                    }
                  }}
                />
              ))}
            </Flex>
          ) : (
            <PerkLoader />
          )}
        </Flex>
      </Flex>
    </Flex>
  )
}

export default withOrgSidebar(OrgSettingsPage)
