import {
  CheckCircleOutlined,
  ClockCircleOutlined,
  CloseCircleOutlined,
  EyeOutlined,
  MailOutlined,
  SendOutlined,
  WarningOutlined,
} from '@ant-design/icons'
import {
  Notification_Status,
  Notification_Type,
} from 'gen/perkup/v1/notification_pb'
import { compact, values } from 'lodash-es'

interface NotificationDisplayProps {
  color?: string
  label: string
  icon: typeof SendOutlined
}

export const resendNotificationLabels: Record<number, string> = {
  [Notification_Type.weeklyThanksDigest]: 'Weekly Thanks Digest',
  [Notification_Type.thanksMessage]: 'Thank you note received',
  [Notification_Type.rewardAccepted]: 'Gift accepted',
  [Notification_Type.stripeAuthorizationNotification]:
    'Transaction authorization',
  [Notification_Type.orderFulfillmentStatusUpdate]: 'Shipment update',
  [Notification_Type.amazonOrderStatusUpdate]: 'Amazon order update',
  [Notification_Type.lowAccountBalanceNotification]: 'Low account balance',
  [Notification_Type.plumOrderPlaced]: 'Gift card confirmation',
  [Notification_Type.upcomingOccasion]: 'Upcoming occasion',
  [Notification_Type.accountBalanceDecline]: 'Account balance decline',
  [Notification_Type.rewardReminderNotification]: 'Reward reminder',
  [Notification_Type.rewardExpirationNotification]: 'Reward expiration',
  [Notification_Type.manualOrderConfirmation]: 'Order confirmation',
  [Notification_Type.stripeInvoiceNotification]: 'Invoice notification',
  [Notification_Type.reauthenticateIntegration]: 'Reauthenticate integration',
}

export const notificationStatusDetails: Record<
  string,
  NotificationDisplayProps
> = {
  [Notification_Status[Notification_Status.providerDelivered]]: {
    color: 'green',
    label: 'Delivered',
    icon: MailOutlined,
  },
  [Notification_Status[Notification_Status.providerClicked]]: {
    color: 'purple',
    label: 'Clicked',
    icon: CheckCircleOutlined,
  },
  [Notification_Status[Notification_Status.providerOpened]]: {
    color: 'blue',
    label: 'Opened',
    icon: EyeOutlined,
  },
  [Notification_Status[Notification_Status.providerBounced]]: {
    color: 'red',
    label: 'Bounced',
    icon: CloseCircleOutlined,
  },
  [Notification_Status[Notification_Status.providerDeliveryDelayed]]: {
    color: 'gold',
    label: 'Delayed',
    icon: ClockCircleOutlined,
  },
  [Notification_Status[Notification_Status.providerComplained]]: {
    color: 'gold',
    label: 'Spam',
    icon: WarningOutlined,
  },
  [Notification_Status[Notification_Status.providerEnqueued]]: {
    label: 'Sent',
    icon: SendOutlined,
    color: 'default',
  },
}

export const notificationTypeSelectOptions = compact(
  values(Notification_Type)
    .filter(
      type =>
        type !== Notification_Type.TYPE_UNSPECIFIED &&
        type !== Notification_Type.publishWeeklyThanksDigest
    )
    .map(type => {
      if (typeof type === 'string') return undefined
      const label = resendNotificationLabels[type] || Notification_Type[type]

      return {
        key: type,
        value: type,
        label,
      }
    })
)

export const notificationStatusSelectOptions = compact(
  values(Notification_Status)
    .filter(
      status =>
        typeof status !== 'string' &&
        !!notificationStatusDetails[Notification_Status[status]]
    )
    .map(status => {
      if (typeof status === 'string') return undefined
      const { label } = notificationStatusDetails[Notification_Status[status]]

      return {
        key: status,
        value: status,
        label,
      }
    })
)
