import { CloseOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import { PerkImage } from 'components'
import { CountryIconGroup } from 'components/Countries'
import { BalanceText } from 'components/Typography/BalanceText'
import { Pane, Size, Text } from 'evergreen-ui'

/** @deprecated We should move away from anything related to "Items" and instead use our ProductVariant components, now use product-thumbnail.tsx */
export function ItemInfo({
  amount,
  children,
  image,
  name,
  shippingCountries = [],
  totalAmount,
  headingSize = 500,
  containerStyles,
  amountSuffix,
  allowRemoval = false,
  onRemoveItem,
  removeIcon = <CloseOutlined />,
  iconButtonStyles,
  imageSize = 64,
  imageFit = 'contain',
}: {
  amount?: number
  children?: React.ReactNode
  image?: string
  name?: string
  shippingCountries?: string[]
  totalAmount?: number
  headingSize?: Size
  containerStyles?: React.CSSProperties
  amountSuffix?: string
  allowRemoval?: boolean
  onRemoveItem?: () => void
  removeIcon?: React.ReactNode
  iconButtonStyles?: React.CSSProperties
  imageSize?: number
  imageFit?: 'contain' | 'cover' | 'fill' | 'none' | 'scale-down'
}) {
  return (
    <Pane
      display="flex"
      alignItems="start"
      justifyContent="space-between"
      gap={64}
    >
      <Pane display="flex" gap={16}>
        {image && (
          <PerkImage
            preview
            src={image}
            alt={`${name}-thumbnail`}
            sizes={`${imageSize}px`}
            style={{
              aspectRatio: imageFit === 'cover' ? '1 / 1' : undefined,
              objectFit: imageFit,
              width: imageSize,
              height: 'fit-content',
            }}
          />
        )}
        <Pane
          style={containerStyles}
          display="flex"
          flexDirection="column"
          gap={8}
        >
          {name && <Text size={headingSize}>{name}</Text>}
          <Pane display="flex" gap={16}>
            {amount && (
              <BalanceText amountSuffix={amountSuffix} amount={amount} />
            )}
            <Pane display="flex" flexWrap="wrap" gap={8}>
              {children}
            </Pane>
          </Pane>

          {shippingCountries.length > 0 && (
            <CountryIconGroup iso3s={shippingCountries} />
          )}
        </Pane>
      </Pane>
      {totalAmount && (
        <BalanceText amountSuffix={amountSuffix} amount={totalAmount} />
      )}
      {allowRemoval && (
        <Button
          style={iconButtonStyles}
          onClick={onRemoveItem}
          icon={removeIcon}
          type="text"
        />
      )}
    </Pane>
  )
}
