// @generated by protoc-gen-es v1.6.0 with parameter "target=ts"
// @generated from file perkup/v1/vendor.proto (package perkup.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, protoInt64, Timestamp } from "@bufbuild/protobuf";
import { ProductVariant_Provider, ProductVariant_SourceType, ProductVariant_Type } from "./product_variant_pb.js";

/**
 * Firestore /vendorProductSubCategories/:id
 *
 * @generated from message perkup.v1.VendorProductSubCategory
 */
export class VendorProductSubCategory extends Message<VendorProductSubCategory> {
  /**
   * stored in firestore
   *
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string category_name = 2;
   */
  categoryName = "";

  /**
   * @generated from field: string name = 3;
   */
  name = "";

  /**
   * @generated from field: string icon = 4;
   */
  icon = "";

  constructor(data?: PartialMessage<VendorProductSubCategory>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "perkup.v1.VendorProductSubCategory";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "category_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "icon", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): VendorProductSubCategory {
    return new VendorProductSubCategory().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): VendorProductSubCategory {
    return new VendorProductSubCategory().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): VendorProductSubCategory {
    return new VendorProductSubCategory().fromJsonString(jsonString, options);
  }

  static equals(a: VendorProductSubCategory | PlainMessage<VendorProductSubCategory> | undefined, b: VendorProductSubCategory | PlainMessage<VendorProductSubCategory> | undefined): boolean {
    return proto3.util.equals(VendorProductSubCategory, a, b);
  }
}

/**
 * Firestore /vendors
 *
 * @generated from message perkup.v1.Vendor
 */
export class Vendor extends Message<Vendor> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string name = 2;
   */
  name = "";

  /**
   * @generated from field: string description = 3;
   */
  description = "";

  /**
   * @generated from field: string image = 4;
   */
  image = "";

  /**
   * @generated from field: optional bool has_near_cash = 5;
   */
  hasNearCash?: boolean;

  constructor(data?: PartialMessage<Vendor>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "perkup.v1.Vendor";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "image", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "has_near_cash", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Vendor {
    return new Vendor().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Vendor {
    return new Vendor().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Vendor {
    return new Vendor().fromJsonString(jsonString, options);
  }

  static equals(a: Vendor | PlainMessage<Vendor> | undefined, b: Vendor | PlainMessage<Vendor> | undefined): boolean {
    return proto3.util.equals(Vendor, a, b);
  }
}

/**
 * Firestore /vendorProducts
 *
 * @generated from message perkup.v1.VendorProduct
 */
export class VendorProduct extends Message<VendorProduct> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string vendor_id = 2;
   */
  vendorId = "";

  /**
   * @generated from field: string name = 3;
   */
  name = "";

  /**
   * @generated from field: string description = 4;
   */
  description = "";

  /**
   * @generated from field: repeated string images = 5;
   */
  images: string[] = [];

  /**
   * @generated from field: optional bool is_near_cash = 7;
   */
  isNearCash?: boolean;

  constructor(data?: PartialMessage<VendorProduct>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "perkup.v1.VendorProduct";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "vendor_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "images", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 7, name: "is_near_cash", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): VendorProduct {
    return new VendorProduct().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): VendorProduct {
    return new VendorProduct().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): VendorProduct {
    return new VendorProduct().fromJsonString(jsonString, options);
  }

  static equals(a: VendorProduct | PlainMessage<VendorProduct> | undefined, b: VendorProduct | PlainMessage<VendorProduct> | undefined): boolean {
    return proto3.util.equals(VendorProduct, a, b);
  }
}

/**
 * Firestore /vendorProductPrices
 *
 * @generated from message perkup.v1.VendorProductPrice
 */
export class VendorProductPrice extends Message<VendorProductPrice> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string product_id = 2;
   */
  productId = "";

  /**
   * @generated from field: string vendor_id = 3;
   */
  vendorId = "";

  /**
   * @generated from field: string region_id = 4;
   */
  regionId = "";

  /**
   * product in cents
   *
   * @generated from field: int32 product_amount = 5;
   */
  productAmount = 0;

  /**
   * shipping in cents
   *
   * @generated from field: int32 shipping_amount = 6;
   */
  shippingAmount = 0;

  /**
   * product + shipping in cents
   *
   * @generated from field: int32 total_amount = 7;
   */
  totalAmount = 0;

  /**
   * @generated from field: string purchase_url = 8;
   */
  purchaseUrl = "";

  /**
   * ISO 3166-1 alpha2 US,CA,...
   *
   * @generated from field: repeated string iso2s = 9;
   */
  iso2s: string[] = [];

  /**
   * @generated from field: repeated string categories = 10;
   */
  categories: string[] = [];

  /**
   * @generated from field: repeated string sub_categories = 11;
   */
  subCategories: string[] = [];

  constructor(data?: PartialMessage<VendorProductPrice>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "perkup.v1.VendorProductPrice";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "product_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "vendor_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "region_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "product_amount", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 6, name: "shipping_amount", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 7, name: "total_amount", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 8, name: "purchase_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "iso2s", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 10, name: "categories", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 11, name: "sub_categories", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): VendorProductPrice {
    return new VendorProductPrice().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): VendorProductPrice {
    return new VendorProductPrice().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): VendorProductPrice {
    return new VendorProductPrice().fromJsonString(jsonString, options);
  }

  static equals(a: VendorProductPrice | PlainMessage<VendorProductPrice> | undefined, b: VendorProductPrice | PlainMessage<VendorProductPrice> | undefined): boolean {
    return proto3.util.equals(VendorProductPrice, a, b);
  }
}

/**
 * Firestore /vendorCountries
 *
 * @generated from message perkup.v1.VendorCountry
 */
export class VendorCountry extends Message<VendorCountry> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string name = 2;
   */
  name = "";

  /**
   * ISO 3166-1 alpha2 US,CA,...
   *
   * @generated from field: string iso2 = 3;
   */
  iso2 = "";

  constructor(data?: PartialMessage<VendorCountry>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "perkup.v1.VendorCountry";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "iso2", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): VendorCountry {
    return new VendorCountry().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): VendorCountry {
    return new VendorCountry().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): VendorCountry {
    return new VendorCountry().fromJsonString(jsonString, options);
  }

  static equals(a: VendorCountry | PlainMessage<VendorCountry> | undefined, b: VendorCountry | PlainMessage<VendorCountry> | undefined): boolean {
    return proto3.util.equals(VendorCountry, a, b);
  }
}

/**
 * Firestore /vendorSubCategoryGroup
 *
 * @generated from message perkup.v1.VendorSubCategoryGroup
 */
export class VendorSubCategoryGroup extends Message<VendorSubCategoryGroup> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string name = 2;
   */
  name = "";

  /**
   * @generated from field: repeated string sub_categories = 3;
   */
  subCategories: string[] = [];

  /**
   * @generated from field: string image = 4;
   */
  image = "";

  /**
   * @generated from field: string description = 5;
   */
  description = "";

  /**
   * @generated from field: int32 max_price = 6;
   */
  maxPrice = 0;

  /**
   * @generated from field: int32 min_price = 7;
   */
  minPrice = 0;

  constructor(data?: PartialMessage<VendorSubCategoryGroup>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "perkup.v1.VendorSubCategoryGroup";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "sub_categories", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 4, name: "image", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "max_price", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 7, name: "min_price", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): VendorSubCategoryGroup {
    return new VendorSubCategoryGroup().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): VendorSubCategoryGroup {
    return new VendorSubCategoryGroup().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): VendorSubCategoryGroup {
    return new VendorSubCategoryGroup().fromJsonString(jsonString, options);
  }

  static equals(a: VendorSubCategoryGroup | PlainMessage<VendorSubCategoryGroup> | undefined, b: VendorSubCategoryGroup | PlainMessage<VendorSubCategoryGroup> | undefined): boolean {
    return proto3.util.equals(VendorSubCategoryGroup, a, b);
  }
}

/**
 * Firestore /orders/:id
 *
 * @generated from message perkup.v1.Order
 */
export class Order extends Message<Order> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string org_id = 2;
   */
  orgId = "";

  /**
   * @generated from field: string user_id = 3;
   */
  userId = "";

  /**
   * @generated from field: perkup.v1.Order.Address shipping_address = 4;
   */
  shippingAddress?: Order_Address;

  /**
   * ramp transaction url
   *
   * @generated from field: optional string payment_url = 6;
   */
  paymentUrl?: string;

  /**
   * Created
   *
   * hold is placed
   *
   * @generated from field: google.protobuf.Timestamp created = 7;
   */
  created?: Timestamp;

  /**
   * hold is placed
   *
   * @generated from field: optional google.protobuf.Timestamp payment_paid_at = 8;
   */
  paymentPaidAt?: Timestamp;

  /**
   * @generated from field: optional perkup.v1.PaymentStatus.Enum payment_status = 9;
   */
  paymentStatus?: PaymentStatus_Enum;

  /**
   * deprecated to use order fulfillment status which has value fulfilled, partially_fulfilled, unfulfilled...
   *
   * @generated from field: optional perkup.v1.OrderFulfillment.FulfillmentStatus.Enum fulfillment_status = 10;
   */
  fulfillmentStatus?: OrderFulfillment_FulfillmentStatus_Enum;

  /**
   * @generated from field: optional perkup.v1.ShipmentStatus.Enum shipment_status = 11;
   */
  shipmentStatus?: ShipmentStatus_Enum;

  /**
   * @generated from field: optional string carrier = 12;
   */
  carrier?: string;

  /**
   * @generated from field: optional string tracking_number = 13;
   */
  trackingNumber?: string;

  /**
   * @generated from field: optional string external_id = 15;
   */
  externalId?: string;

  /**
   * @generated from field: optional string tracking_url = 16;
   */
  trackingUrl?: string;

  /**
   * @generated from field: repeated perkup.v1.Order.Item items = 17;
   */
  items: Order_Item[] = [];

  /**
   * @generated from field: string individual_id = 18;
   */
  individualId = "";

  /**
   * iso3 shipping country code
   *
   * @generated from field: string shipping_country = 19;
   */
  shippingCountry = "";

  /**
   * key is program id, value is ProgramMapping object
   *
   * @generated from field: map<string, perkup.v1.Order.Program> programs = 20;
   */
  programs: { [key: string]: Order_Program } = {};

  /**
   * key is account id, value is amount spent in cents
   *
   * @generated from field: map<string, perkup.v1.Order.Account> accounts = 21;
   */
  accounts: { [key: string]: Order_Account } = {};

  /**
   * recipient email
   *
   * @generated from field: string email = 22;
   */
  email = "";

  /**
   * /venueEvents/:id
   *
   * @generated from field: optional string venue_event_id = 23;
   */
  venueEventId?: string;

  constructor(data?: PartialMessage<Order>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "perkup.v1.Order";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "org_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "user_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "shipping_address", kind: "message", T: Order_Address },
    { no: 6, name: "payment_url", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 7, name: "created", kind: "message", T: Timestamp },
    { no: 8, name: "payment_paid_at", kind: "message", T: Timestamp, opt: true },
    { no: 9, name: "payment_status", kind: "enum", T: proto3.getEnumType(PaymentStatus_Enum), opt: true },
    { no: 10, name: "fulfillment_status", kind: "enum", T: proto3.getEnumType(OrderFulfillment_FulfillmentStatus_Enum), opt: true },
    { no: 11, name: "shipment_status", kind: "enum", T: proto3.getEnumType(ShipmentStatus_Enum), opt: true },
    { no: 12, name: "carrier", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 13, name: "tracking_number", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 15, name: "external_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 16, name: "tracking_url", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 17, name: "items", kind: "message", T: Order_Item, repeated: true },
    { no: 18, name: "individual_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 19, name: "shipping_country", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 20, name: "programs", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "message", T: Order_Program} },
    { no: 21, name: "accounts", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "message", T: Order_Account} },
    { no: 22, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 23, name: "venue_event_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Order {
    return new Order().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Order {
    return new Order().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Order {
    return new Order().fromJsonString(jsonString, options);
  }

  static equals(a: Order | PlainMessage<Order> | undefined, b: Order | PlainMessage<Order> | undefined): boolean {
    return proto3.util.equals(Order, a, b);
  }
}

/**
 * PaymentStatus
 * initial state is typically `authorized` corresponding to balanceTransaction `<provider>_hold`
 *
 * @generated from message perkup.v1.Order.Address
 */
export class Order_Address extends Message<Order_Address> {
  /**
   * @generated from field: string line1 = 1;
   */
  line1 = "";

  /**
   * @generated from field: optional string line2 = 2;
   */
  line2?: string;

  /**
   * @generated from field: optional string city = 3;
   */
  city?: string;

  /**
   * @generated from field: optional string state = 4;
   */
  state?: string;

  /**
   * ISO 3166-1 alpha2 US,CA,...
   *
   * @generated from field: string country = 5;
   */
  country = "";

  /**
   * @generated from field: string postal_code = 6;
   */
  postalCode = "";

  /**
   * @generated from field: optional string phone_number = 7;
   */
  phoneNumber?: string;

  /**
   * @generated from field: string first_name = 8;
   */
  firstName = "";

  /**
   * @generated from field: string last_name = 9;
   */
  lastName = "";

  constructor(data?: PartialMessage<Order_Address>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "perkup.v1.Order.Address";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "line1", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "line2", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 3, name: "city", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 4, name: "state", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 5, name: "country", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "postal_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "phone_number", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 8, name: "first_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "last_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Order_Address {
    return new Order_Address().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Order_Address {
    return new Order_Address().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Order_Address {
    return new Order_Address().fromJsonString(jsonString, options);
  }

  static equals(a: Order_Address | PlainMessage<Order_Address> | undefined, b: Order_Address | PlainMessage<Order_Address> | undefined): boolean {
    return proto3.util.equals(Order_Address, a, b);
  }
}

/**
 * @generated from message perkup.v1.Order.Item
 */
export class Order_Item extends Message<Order_Item> {
  /**
   * @generated from field: string product_id = 1;
   */
  productId = "";

  /**
   * @generated from field: string product_name = 3;
   */
  productName = "";

  /**
   * @generated from field: optional string product_variant_id = 2;
   */
  productVariantId?: string;

  /**
   * @generated from field: string vendor_id = 4;
   */
  vendorId = "";

  /**
   * @generated from field: int32 quantity = 5;
   */
  quantity = 0;

  /**
   * from shopify inventory levels
   *
   * @generated from field: string fulfillment_service = 14;
   */
  fulfillmentService = "";

  /**
   * @generated from field: perkup.v1.ProductVariant.Provider provider = 13;
   */
  provider = ProductVariant_Provider.PROVIDER_UNSPECIFIED;

  /**
   * @generated from field: optional string purchase_url = 12;
   */
  purchaseUrl?: string;

  /**
   * source type prepaid item
   *
   * @generated from field: optional perkup.v1.ProductVariant.SourceType source_type = 10;
   */
  sourceType?: ProductVariant_SourceType;

  /**
   * @generated from field: string title = 15;
   */
  title = "";

  /**
   * @generated from field: string description = 16;
   */
  description = "";

  /**
   * @generated from field: string image = 17;
   */
  image = "";

  /**
   * in cents
   *
   * @generated from field: int64 amount = 18;
   */
  amount = protoInt64.zero;

  /**
   * original amount is the amount before discount in cents
   *
   * omitted if the amount is not discounted
   *
   * @generated from field: optional int64 original_amount = 22;
   */
  originalAmount?: bigint;

  /**
   * discount amount is the amount discounted in cents
   *
   *  (original_amount - discount_amount) = amount
   *
   * omitted if the amount is not discounted
   *
   * @generated from field: optional int64 discount_amount = 23;
   */
  discountAmount?: bigint;

  /**
   * @generated from field: optional string discount_title = 24;
   */
  discountTitle?: string;

  /**
   * @generated from field: map<string, string> options = 19;
   */
  options: { [key: string]: string } = {};

  /**
   * voucher specific
   * redeem link is used to redeem the order. currently for plum voucher orders
   *
   * @generated from field: optional string voucher_redeem_link_or_code = 7;
   */
  voucherRedeemLinkOrCode?: string;

  /**
   * @generated from field: optional string voucher_redeem_pin = 8;
   */
  voucherRedeemPin?: string;

  /**
   * validity shows the expire date with format 2006-01-02
   *
   * @generated from field: optional string validity = 9;
   */
  validity?: string;

  /**
   * @generated from field: optional string voucher_type = 11;
   */
  voucherType?: string;

  /**
   * @generated from field: optional string voucher_redemption_instructions = 20;
   */
  voucherRedemptionInstructions?: string;

  /**
   * @generated from field: optional perkup.v1.ProductVariant.Type type = 21;
   */
  type?: ProductVariant_Type;

  constructor(data?: PartialMessage<Order_Item>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "perkup.v1.Order.Item";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "product_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "product_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "product_variant_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 4, name: "vendor_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "quantity", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 14, name: "fulfillment_service", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 13, name: "provider", kind: "enum", T: proto3.getEnumType(ProductVariant_Provider) },
    { no: 12, name: "purchase_url", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 10, name: "source_type", kind: "enum", T: proto3.getEnumType(ProductVariant_SourceType), opt: true },
    { no: 15, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 16, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 17, name: "image", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 18, name: "amount", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 22, name: "original_amount", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 23, name: "discount_amount", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 24, name: "discount_title", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 19, name: "options", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "scalar", T: 9 /* ScalarType.STRING */} },
    { no: 7, name: "voucher_redeem_link_or_code", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 8, name: "voucher_redeem_pin", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 9, name: "validity", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 11, name: "voucher_type", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 20, name: "voucher_redemption_instructions", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 21, name: "type", kind: "enum", T: proto3.getEnumType(ProductVariant_Type), opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Order_Item {
    return new Order_Item().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Order_Item {
    return new Order_Item().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Order_Item {
    return new Order_Item().fromJsonString(jsonString, options);
  }

  static equals(a: Order_Item | PlainMessage<Order_Item> | undefined, b: Order_Item | PlainMessage<Order_Item> | undefined): boolean {
    return proto3.util.equals(Order_Item, a, b);
  }
}

/**
 * @generated from message perkup.v1.Order.Program
 */
export class Order_Program extends Message<Order_Program> {
  /**
   * @generated from field: string program_id = 1;
   */
  programId = "";

  /**
   * @generated from field: string account_id = 2;
   */
  accountId = "";

  /**
   * amount in cents allocated to the program using the specific account
   *
   * @generated from field: int64 program_amount = 3;
   */
  programAmount = protoInt64.zero;

  /**
   * amount in cents spent using the specific account
   *
   * this does not include prepaid items amount
   *
   * @generated from field: int64 account_amount = 4;
   */
  accountAmount = protoInt64.zero;

  constructor(data?: PartialMessage<Order_Program>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "perkup.v1.Order.Program";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "program_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "account_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "program_amount", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: "account_amount", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Order_Program {
    return new Order_Program().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Order_Program {
    return new Order_Program().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Order_Program {
    return new Order_Program().fromJsonString(jsonString, options);
  }

  static equals(a: Order_Program | PlainMessage<Order_Program> | undefined, b: Order_Program | PlainMessage<Order_Program> | undefined): boolean {
    return proto3.util.equals(Order_Program, a, b);
  }
}

/**
 * @generated from message perkup.v1.Order.Account
 */
export class Order_Account extends Message<Order_Account> {
  /**
   * @generated from field: string account_id = 1;
   */
  accountId = "";

  /**
   * amount in cents spent using the specific account
   *
   * this does not include prepaid items amount
   *
   * @generated from field: int64 account_amount = 2;
   */
  accountAmount = protoInt64.zero;

  constructor(data?: PartialMessage<Order_Account>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "perkup.v1.Order.Account";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "account_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "account_amount", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Order_Account {
    return new Order_Account().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Order_Account {
    return new Order_Account().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Order_Account {
    return new Order_Account().fromJsonString(jsonString, options);
  }

  static equals(a: Order_Account | PlainMessage<Order_Account> | undefined, b: Order_Account | PlainMessage<Order_Account> | undefined): boolean {
    return proto3.util.equals(Order_Account, a, b);
  }
}

/**
 * @generated from message perkup.v1.PaymentStatus
 */
export class PaymentStatus extends Message<PaymentStatus> {
  constructor(data?: PartialMessage<PaymentStatus>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "perkup.v1.PaymentStatus";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PaymentStatus {
    return new PaymentStatus().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PaymentStatus {
    return new PaymentStatus().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PaymentStatus {
    return new PaymentStatus().fromJsonString(jsonString, options);
  }

  static equals(a: PaymentStatus | PlainMessage<PaymentStatus> | undefined, b: PaymentStatus | PlainMessage<PaymentStatus> | undefined): boolean {
    return proto3.util.equals(PaymentStatus, a, b);
  }
}

/**
 * @generated from enum perkup.v1.PaymentStatus.Enum
 */
export enum PaymentStatus_Enum {
  /**
   * @generated from enum value: PAYMENT_STATUS_UNSPECIFIED = 0;
   */
  PAYMENT_STATUS_UNSPECIFIED = 0,

  /**
   * The payments are pending. Payment might fail in this state. Check again to confirm whether the payments have been paid successfully.
   *
   * @generated from enum value: pending = 1;
   */
  pending = 1,

  /**
   * The payments have been authorized.
   *
   * @generated from enum value: authorized = 2;
   */
  authorized = 2,

  /**
   * The order has been partially paid.
   *
   * @generated from enum value: partiallyPaid = 3;
   */
  partiallyPaid = 3,

  /**
   * The payments have been paid.
   *
   * @generated from enum value: paid = 4;
   */
  paid = 4,

  /**
   * The payments have been partially refunded.
   *
   * @generated from enum value: partiallyRefunded = 5;
   */
  partiallyRefunded = 5,

  /**
   * The payments have been refunded.
   *
   * @generated from enum value: refunded = 6;
   */
  refunded = 6,

  /**
   * The payments have been voided.
   *
   * @generated from enum value: voided = 7;
   */
  voided = 7,
}
// Retrieve enum metadata with: proto3.getEnumType(PaymentStatus_Enum)
proto3.util.setEnumType(PaymentStatus_Enum, "perkup.v1.PaymentStatus.Enum", [
  { no: 0, name: "PAYMENT_STATUS_UNSPECIFIED" },
  { no: 1, name: "pending" },
  { no: 2, name: "authorized" },
  { no: 3, name: "partiallyPaid" },
  { no: 4, name: "paid" },
  { no: 5, name: "partiallyRefunded" },
  { no: 6, name: "refunded" },
  { no: 7, name: "voided" },
]);

/**
 * @generated from message perkup.v1.ShipmentStatus
 */
export class ShipmentStatus extends Message<ShipmentStatus> {
  constructor(data?: PartialMessage<ShipmentStatus>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "perkup.v1.ShipmentStatus";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ShipmentStatus {
    return new ShipmentStatus().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ShipmentStatus {
    return new ShipmentStatus().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ShipmentStatus {
    return new ShipmentStatus().fromJsonString(jsonString, options);
  }

  static equals(a: ShipmentStatus | PlainMessage<ShipmentStatus> | undefined, b: ShipmentStatus | PlainMessage<ShipmentStatus> | undefined): boolean {
    return proto3.util.equals(ShipmentStatus, a, b);
  }
}

/**
 * @generated from enum perkup.v1.ShipmentStatus.Enum
 */
export enum ShipmentStatus_Enum {
  /**
   * @generated from enum value: SHIPMENT_STATUS_UNSPECIFIED = 0;
   */
  SHIPMENT_STATUS_UNSPECIFIED = 0,

  /**
   * A label for the shipment was purchased, but not printed.
   *
   * @generated from enum value: labelPurchased = 1;
   */
  labelPurchased = 1,

  /**
   * A label for the shipment was purchased and printed.
   *
   * @generated from enum value: labelPrinted = 2;
   */
  labelPrinted = 2,

  /**
   * The carrier is aware of the shipment, but hasn't received it yet.
   *
   * @generated from enum value: confirmed = 3;
   */
  confirmed = 3,

  /**
   * The shipment is being transported between shipping facilities on the way to its destination.
   *
   * @generated from enum value: inTransit = 4;
   */
  inTransit = 4,

  /**
   * The shipment is delayed (possibly Weather delay).
   *
   * @generated from enum value: delayed = 5;
   */
  delayed = 5,

  /**
   * The shipment is ready for pickup at a shipping depot.
   *
   * @generated from enum value: readyForPickup = 6;
   */
  readyForPickup = 6,

  /**
   * The shipment is being delivered to its final destination.
   *
   * @generated from enum value: outForDelivery = 7;
   */
  outForDelivery = 7,

  /**
   * Delivery of the shipment was attempted, but unable to be completed.
   *
   * @generated from enum value: attemptedDelivery = 8;
   */
  attemptedDelivery = 8,

  /**
   * The shipment was succesfully delivered.
   *
   * @generated from enum value: delivered = 9;
   */
  delivered = 9,

  /**
   * Something went wrong when pulling tracking information for the shipment, such as the tracking number was invalid or the shipment was canceled.
   *
   * @generated from enum value: failure = 10;
   */
  failure = 10,
}
// Retrieve enum metadata with: proto3.getEnumType(ShipmentStatus_Enum)
proto3.util.setEnumType(ShipmentStatus_Enum, "perkup.v1.ShipmentStatus.Enum", [
  { no: 0, name: "SHIPMENT_STATUS_UNSPECIFIED" },
  { no: 1, name: "labelPurchased" },
  { no: 2, name: "labelPrinted" },
  { no: 3, name: "confirmed" },
  { no: 4, name: "inTransit" },
  { no: 5, name: "delayed" },
  { no: 6, name: "readyForPickup" },
  { no: 7, name: "outForDelivery" },
  { no: 8, name: "attemptedDelivery" },
  { no: 9, name: "delivered" },
  { no: 10, name: "failure" },
]);

/**
 * orderFulfillments is an aggregation of third party vendor order fulfillment events
 * Firestore /orderFulfillments/:id/
 *
 * @generated from message perkup.v1.OrderFulfillment
 */
export class OrderFulfillment extends Message<OrderFulfillment> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * internal order id
   *
   * @generated from field: string order_id = 2;
   */
  orderId = "";

  /**
   * @generated from field: optional perkup.v1.OrderFulfillment.FulfillmentStatus.Enum status = 3;
   */
  status?: OrderFulfillment_FulfillmentStatus_Enum;

  /**
   * @generated from field: google.protobuf.Timestamp created_at = 4;
   */
  createdAt?: Timestamp;

  /**
   * @generated from field: string tracking_company = 7;
   */
  trackingCompany = "";

  /**
   * @generated from field: optional perkup.v1.ShipmentStatus.Enum shipment_status = 8;
   */
  shipmentStatus?: ShipmentStatus_Enum;

  /**
   * @generated from field: int64 location_id = 9;
   */
  locationId = protoInt64.zero;

  /**
   * @generated from field: perkup.v1.OrderFulfillment.Destination destination = 12;
   */
  destination?: OrderFulfillment_Destination;

  /**
   * @generated from field: repeated perkup.v1.OrderFulfillment.LineItem line_items = 13;
   */
  lineItems: OrderFulfillment_LineItem[] = [];

  /**
   * @generated from field: string tracking_number = 14;
   */
  trackingNumber = "";

  /**
   * @generated from field: repeated string tracking_numbers = 15;
   */
  trackingNumbers: string[] = [];

  /**
   * @generated from field: string tracking_url = 16;
   */
  trackingUrl = "";

  /**
   * @generated from field: repeated string tracking_urls = 17;
   */
  trackingUrls: string[] = [];

  /**
   * @generated from field: string name = 19;
   */
  name = "";

  /**
   * fulfillment external id from third party vendor
   *
   * @generated from field: string external_id = 20;
   */
  externalId = "";

  /**
   * external id is the order id from the third party vendor. if the order is from shopify, it should have the prefix `gid://shopify/Order/`
   *
   * @generated from field: string external_order_id = 21;
   */
  externalOrderId = "";

  /**
   * the key is shipment status enum string
   *
   * @generated from field: map<string, google.protobuf.Timestamp> shipment_actions = 22;
   */
  shipmentActions: { [key: string]: Timestamp } = {};

  constructor(data?: PartialMessage<OrderFulfillment>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "perkup.v1.OrderFulfillment";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "order_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "status", kind: "enum", T: proto3.getEnumType(OrderFulfillment_FulfillmentStatus_Enum), opt: true },
    { no: 4, name: "created_at", kind: "message", T: Timestamp },
    { no: 7, name: "tracking_company", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "shipment_status", kind: "enum", T: proto3.getEnumType(ShipmentStatus_Enum), opt: true },
    { no: 9, name: "location_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 12, name: "destination", kind: "message", T: OrderFulfillment_Destination },
    { no: 13, name: "line_items", kind: "message", T: OrderFulfillment_LineItem, repeated: true },
    { no: 14, name: "tracking_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 15, name: "tracking_numbers", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 16, name: "tracking_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 17, name: "tracking_urls", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 19, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 20, name: "external_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 21, name: "external_order_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 22, name: "shipment_actions", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "message", T: Timestamp} },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): OrderFulfillment {
    return new OrderFulfillment().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): OrderFulfillment {
    return new OrderFulfillment().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): OrderFulfillment {
    return new OrderFulfillment().fromJsonString(jsonString, options);
  }

  static equals(a: OrderFulfillment | PlainMessage<OrderFulfillment> | undefined, b: OrderFulfillment | PlainMessage<OrderFulfillment> | undefined): boolean {
    return proto3.util.equals(OrderFulfillment, a, b);
  }
}

/**
 * @generated from message perkup.v1.OrderFulfillment.Destination
 */
export class OrderFulfillment_Destination extends Message<OrderFulfillment_Destination> {
  /**
   * @generated from field: string first_name = 1;
   */
  firstName = "";

  /**
   * @generated from field: string address1 = 2;
   */
  address1 = "";

  /**
   * @generated from field: string phone = 3;
   */
  phone = "";

  /**
   * @generated from field: string city = 4;
   */
  city = "";

  /**
   * @generated from field: string zip = 5;
   */
  zip = "";

  /**
   * @generated from field: string province = 6;
   */
  province = "";

  /**
   * @generated from field: string country = 7;
   */
  country = "";

  /**
   * @generated from field: string last_name = 8;
   */
  lastName = "";

  /**
   * @generated from field: string address2 = 9;
   */
  address2 = "";

  /**
   * @generated from field: string company = 10;
   */
  company = "";

  /**
   * @generated from field: string name = 13;
   */
  name = "";

  /**
   * @generated from field: string country_code = 14;
   */
  countryCode = "";

  /**
   * @generated from field: string province_code = 15;
   */
  provinceCode = "";

  constructor(data?: PartialMessage<OrderFulfillment_Destination>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "perkup.v1.OrderFulfillment.Destination";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "first_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "address1", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "phone", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "city", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "zip", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "province", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "country", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "last_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "address2", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "company", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 13, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 14, name: "country_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 15, name: "province_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): OrderFulfillment_Destination {
    return new OrderFulfillment_Destination().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): OrderFulfillment_Destination {
    return new OrderFulfillment_Destination().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): OrderFulfillment_Destination {
    return new OrderFulfillment_Destination().fromJsonString(jsonString, options);
  }

  static equals(a: OrderFulfillment_Destination | PlainMessage<OrderFulfillment_Destination> | undefined, b: OrderFulfillment_Destination | PlainMessage<OrderFulfillment_Destination> | undefined): boolean {
    return proto3.util.equals(OrderFulfillment_Destination, a, b);
  }
}

/**
 * @generated from message perkup.v1.OrderFulfillment.LineItem
 */
export class OrderFulfillment_LineItem extends Message<OrderFulfillment_LineItem> {
  /**
   * internal variant id which should not have gid:// prefix even from shopify
   *
   * @generated from field: string variant_id = 1;
   */
  variantId = "";

  /**
   * @generated from field: int32 quantity = 2;
   */
  quantity = 0;

  /**
   * @generated from field: optional string sku = 3;
   */
  sku?: string;

  /**
   * @generated from field: optional string vendor = 4;
   */
  vendor?: string;

  /**
   * @generated from field: optional string fulfillment_service = 5;
   */
  fulfillmentService?: string;

  /**
   * internal product id which should not have gid:// prefix even from shopify
   *
   * @generated from field: optional string product_id = 6;
   */
  productId?: string;

  /**
   * @generated from field: optional bool requires_shipping = 10;
   */
  requiresShipping?: boolean;

  /**
   * name is variant title - option1/option2 e.g. "POLO TSHIRT - Black/XL"
   *
   * @generated from field: optional string name = 13;
   */
  name?: string;

  /**
   * @generated from field: perkup.v1.ProductVariant.Provider provider = 14;
   */
  provider = ProductVariant_Provider.PROVIDER_UNSPECIFIED;

  /**
   * @generated from field: optional string image_url = 15;
   */
  imageUrl?: string;

  constructor(data?: PartialMessage<OrderFulfillment_LineItem>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "perkup.v1.OrderFulfillment.LineItem";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "variant_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "quantity", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "sku", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 4, name: "vendor", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 5, name: "fulfillment_service", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 6, name: "product_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 10, name: "requires_shipping", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 13, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 14, name: "provider", kind: "enum", T: proto3.getEnumType(ProductVariant_Provider) },
    { no: 15, name: "image_url", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): OrderFulfillment_LineItem {
    return new OrderFulfillment_LineItem().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): OrderFulfillment_LineItem {
    return new OrderFulfillment_LineItem().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): OrderFulfillment_LineItem {
    return new OrderFulfillment_LineItem().fromJsonString(jsonString, options);
  }

  static equals(a: OrderFulfillment_LineItem | PlainMessage<OrderFulfillment_LineItem> | undefined, b: OrderFulfillment_LineItem | PlainMessage<OrderFulfillment_LineItem> | undefined): boolean {
    return proto3.util.equals(OrderFulfillment_LineItem, a, b);
  }
}

/**
 * @generated from message perkup.v1.OrderFulfillment.FulfillmentStatus
 */
export class OrderFulfillment_FulfillmentStatus extends Message<OrderFulfillment_FulfillmentStatus> {
  constructor(data?: PartialMessage<OrderFulfillment_FulfillmentStatus>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "perkup.v1.OrderFulfillment.FulfillmentStatus";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): OrderFulfillment_FulfillmentStatus {
    return new OrderFulfillment_FulfillmentStatus().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): OrderFulfillment_FulfillmentStatus {
    return new OrderFulfillment_FulfillmentStatus().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): OrderFulfillment_FulfillmentStatus {
    return new OrderFulfillment_FulfillmentStatus().fromJsonString(jsonString, options);
  }

  static equals(a: OrderFulfillment_FulfillmentStatus | PlainMessage<OrderFulfillment_FulfillmentStatus> | undefined, b: OrderFulfillment_FulfillmentStatus | PlainMessage<OrderFulfillment_FulfillmentStatus> | undefined): boolean {
    return proto3.util.equals(OrderFulfillment_FulfillmentStatus, a, b);
  }
}

/**
 * @generated from enum perkup.v1.OrderFulfillment.FulfillmentStatus.Enum
 */
export enum OrderFulfillment_FulfillmentStatus_Enum {
  /**
   * @generated from enum value: FULFILLMENT_STATUS_UNSPECIFIED = 0;
   */
  FULFILLMENT_STATUS_UNSPECIFIED = 0,

  /**
   * PerkUp has created the fulfillment and is waiting for the third-party fulfillment service to transition it to 'open' or 'success'.
   *
   * @generated from enum value: pending = 1;
   */
  pending = 1,

  /**
   * The fulfillment has been acknowledged by the service and is in processing.
   *
   * @generated from enum value: open = 2;
   */
  open = 2,

  /**
   * The fulfillment was successful.
   *
   * @generated from enum value: success = 3;
   */
  success = 3,

  /**
   * The fulfillment was cancelled.
   *
   * @generated from enum value: cancelled = 4;
   */
  cancelled = 4,

  /**
   * There was an error with the fulfillment request.
   *
   * @generated from enum value: error = 5;
   */
  error = 5,

  /**
   * The fulfillment request failed.
   *
   * @generated from enum value: failure = 6;
   */
  failure = 6,
}
// Retrieve enum metadata with: proto3.getEnumType(OrderFulfillment_FulfillmentStatus_Enum)
proto3.util.setEnumType(OrderFulfillment_FulfillmentStatus_Enum, "perkup.v1.OrderFulfillment.FulfillmentStatus.Enum", [
  { no: 0, name: "FULFILLMENT_STATUS_UNSPECIFIED" },
  { no: 1, name: "pending" },
  { no: 2, name: "open" },
  { no: 3, name: "success" },
  { no: 4, name: "cancelled" },
  { no: 5, name: "error" },
  { no: 6, name: "failure" },
]);

/**
 * Firestore /shopifyStores/:id
 *
 * @generated from message perkup.v1.ShopifyStore
 */
export class ShopifyStore extends Message<ShopifyStore> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string url = 2;
   */
  url = "";

  /**
   * countries that the store ships to, as an iso3 array 
   *
   * @generated from field: repeated string countries = 3;
   */
  countries: string[] = [];

  /**
   * @generated from field: string brand_name = 4;
   */
  brandName = "";

  /**
   * @generated from field: optional string brand_description = 5;
   */
  brandDescription?: string;

  /**
   * url of the image
   *
   * @generated from field: optional string brand_image = 6;
   */
  brandImage?: string;

  /**
   * @generated from field: repeated string brand_tags = 7;
   */
  brandTags: string[] = [];

  /**
   * @generated from field: bool best_sellers_only = 8;
   */
  bestSellersOnly = false;

  /**
   * @generated from field: bool requires_shipping_only = 9;
   */
  requiresShippingOnly = false;

  /**
   * @generated from field: perkup.v1.ShopifyStore.Status status = 10;
   */
  status = ShopifyStore_Status.STATUS_UNSPECIFIED;

  /**
   * @generated from field: int32 min_product_amount = 11;
   */
  minProductAmount = 0;

  /**
   * currency get from web scraper with /cart.js url suffix. back filled during scrape it sync
   *
   * @generated from field: optional string currency = 12;
   */
  currency?: string;

  /**
   * cloud storage to product variant sync
   *
   * @generated from field: optional google.protobuf.Timestamp last_synced = 13;
   */
  lastSynced?: Timestamp;

  /**
   * shopify store to cloud storage sync
   *
   * @generated from field: optional google.protobuf.Timestamp last_run = 14;
   */
  lastRun?: Timestamp;

  /**
   * @generated from field: optional perkup.v1.ShopifyStore.LastRunStatus last_run_status = 15;
   */
  lastRunStatus?: ShopifyStore_LastRunStatus;

  /**
   * @generated from field: repeated string collection_handles = 16;
   */
  collectionHandles: string[] = [];

  constructor(data?: PartialMessage<ShopifyStore>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "perkup.v1.ShopifyStore";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "countries", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 4, name: "brand_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "brand_description", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 6, name: "brand_image", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 7, name: "brand_tags", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 8, name: "best_sellers_only", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 9, name: "requires_shipping_only", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 10, name: "status", kind: "enum", T: proto3.getEnumType(ShopifyStore_Status) },
    { no: 11, name: "min_product_amount", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 12, name: "currency", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 13, name: "last_synced", kind: "message", T: Timestamp, opt: true },
    { no: 14, name: "last_run", kind: "message", T: Timestamp, opt: true },
    { no: 15, name: "last_run_status", kind: "enum", T: proto3.getEnumType(ShopifyStore_LastRunStatus), opt: true },
    { no: 16, name: "collection_handles", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ShopifyStore {
    return new ShopifyStore().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ShopifyStore {
    return new ShopifyStore().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ShopifyStore {
    return new ShopifyStore().fromJsonString(jsonString, options);
  }

  static equals(a: ShopifyStore | PlainMessage<ShopifyStore> | undefined, b: ShopifyStore | PlainMessage<ShopifyStore> | undefined): boolean {
    return proto3.util.equals(ShopifyStore, a, b);
  }
}

/**
 * @generated from enum perkup.v1.ShopifyStore.Status
 */
export enum ShopifyStore_Status {
  /**
   * @generated from enum value: STATUS_UNSPECIFIED = 0;
   */
  STATUS_UNSPECIFIED = 0,

  /**
   * @generated from enum value: active = 1;
   */
  active = 1,

  /**
   * @generated from enum value: paused = 2;
   */
  paused = 2,

  /**
   * @generated from enum value: removed = 3;
   */
  removed = 3,
}
// Retrieve enum metadata with: proto3.getEnumType(ShopifyStore_Status)
proto3.util.setEnumType(ShopifyStore_Status, "perkup.v1.ShopifyStore.Status", [
  { no: 0, name: "STATUS_UNSPECIFIED" },
  { no: 1, name: "active" },
  { no: 2, name: "paused" },
  { no: 3, name: "removed" },
]);

/**
 * @generated from enum perkup.v1.ShopifyStore.LastRunStatus
 */
export enum ShopifyStore_LastRunStatus {
  /**
   * @generated from enum value: LAST_RUN_STATUS_UNSPECIFIED = 0;
   */
  LAST_RUN_STATUS_UNSPECIFIED = 0,

  /**
   * @generated from enum value: success = 1;
   */
  success = 1,

  /**
   * @generated from enum value: failure = 2;
   */
  failure = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(ShopifyStore_LastRunStatus)
proto3.util.setEnumType(ShopifyStore_LastRunStatus, "perkup.v1.ShopifyStore.LastRunStatus", [
  { no: 0, name: "LAST_RUN_STATUS_UNSPECIFIED" },
  { no: 1, name: "success" },
  { no: 2, name: "failure" },
]);

