import { Button, ButtonProps, Flex, Image } from 'antd'
import { Heading, Text } from 'evergreen-ui'
import React from 'react'

export function PerkEmpty({
  iconUrl,
  iconNode,
  header,
  description,
  ctaProps,
}: {
  iconUrl?: string
  iconNode?: React.ReactNode
  header: string
  description?: string
  ctaProps?: ButtonProps
}) {
  return (
    <Flex vertical align="center" gap={8} style={{ width: '100%' }}>
      {iconUrl && (
        <Image preview={false} src={iconUrl} style={{ maxWidth: 100 }} />
      )}
      {iconNode && iconNode}
      <Heading marginTop={8}>{header}</Heading>
      {description && <Text className="text-center">{description}</Text>}
      {ctaProps && <Button {...ctaProps}>{ctaProps.children}</Button>}
    </Flex>
  )
}
