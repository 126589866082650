import { Modal } from 'antd'
import { PerkScrollbars } from 'components'
import { Pane } from 'evergreen-ui'
import { ProgramOccasion } from 'gen/perkup/v1/program_pb'
import { useState } from 'react'
import { ThemeEditor } from './theme-editor'

export function ThemeEditorModal({
  onConfirmImage,
  modalTrigger,
  defaultQuery,
  defaultOccasion,
}: {
  modalTrigger: React.ReactNode
  defaultQuery?: string
  defaultOccasion?: ProgramOccasion
  onConfirmImage: (selectedImage: string) => void
}) {
  const [open, setOpen] = useState(false)

  return (
    <>
      <Pane cursor="pointer" onClick={() => setOpen(true)}>
        {modalTrigger}
      </Pane>

      <Modal
        open={open}
        afterClose={() => setOpen(false)}
        onCancel={() => setOpen(false)}
        title="Select an image"
        footer={false}
        centered
        width="80vw"
      >
        <PerkScrollbars style={{ height: '80vh', paddingTop: 32 }}>
          <ThemeEditor
            defaultQuery={defaultQuery}
            occasion={defaultOccasion}
            onSelectTheme={({ emailData }) => {
              if (emailData?.banner) onConfirmImage(emailData?.banner)
              setOpen(false)
            }}
          />
        </PerkScrollbars>
      </Modal>
    </>
  )
}
