// @generated by protoc-gen-connect-es v1.1.4 with parameter "target=ts"
// @generated from file perkup/v1/stripe_invoice.proto (package perkup.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { Invoice, TopUpInvoiceRequest } from "./stripe_invoice_pb.js";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service perkup.v1.StripeService
 */
export const StripeService = {
  typeName: "perkup.v1.StripeService",
  methods: {
    /**
     * @generated from rpc perkup.v1.StripeService.CreateTopUpInvoice
     */
    createTopUpInvoice: {
      name: "CreateTopUpInvoice",
      I: TopUpInvoiceRequest,
      O: Invoice,
      kind: MethodKind.Unary,
    },
  }
} as const;

