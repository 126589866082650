// @generated by protoc-gen-connect-es v1.1.4 with parameter "target=ts"
// @generated from file courier/auth.proto (package courier, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { Empty } from "../google/protobuf/empty_pb.js";
import { AuthResponse } from "./auth_pb.js";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service courier.CourierAuth
 */
export const CourierAuth = {
  typeName: "courier.CourierAuth",
  methods: {
    /**
     * @generated from rpc courier.CourierAuth.AuthenticateCourier
     */
    authenticateCourier: {
      name: "AuthenticateCourier",
      I: Empty,
      O: AuthResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

