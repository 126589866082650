import {
  CheckOutlined,
  DeleteOutlined,
  EditOutlined,
  EllipsisOutlined,
  EyeOutlined,
} from '@ant-design/icons'
import { Button, Dropdown, Flex, message, Modal, Switch, Tooltip } from 'antd'
import { UploadChangeParam, UploadFile } from 'antd/es/upload'
import {
  deleteCollectionById,
  updateCollection,
  updateProductCollectionPermissions,
  uploadCollectionImage,
} from 'api/databaseCalls'
import {
  BackIconButton,
  CountriesWidget,
  CreatedBy,
  Editable,
  EditableImage,
  PerkIconTooltipButton,
  PerkLink,
  PermissionsBreakdown,
  PermissionsOverview,
} from 'components'
import { SIDEBAR_WIDTH } from 'constants/layout'
import {
  adminProductCollectionPermissionsOptions,
  individualProductCollectionPermissionsOptions,
  managerProductCollectionPermissionsOptions,
} from 'constants/productCollections'
import { DEFAULT_ROUTES } from 'constants/routes'
import { IndividualContext, OrgContext } from 'context'
import { Heading, Pane, Text, useTheme } from 'evergreen-ui'
import { ProductCollectionProducts } from 'features'
import {
  ProductCollection,
  ProductCollection_CollectionAccess_Enum,
  ProductCollection_Permission,
} from 'gen/perkup/v1/product_collections_pb'
import { Program_Gift } from 'gen/perkup/v1/program_pb'
import { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Mode } from 'types/edit'
import { Permissions } from 'types/Permissions'
import { calculateProductCollectionMinPrice } from 'utils'
import { insertElementIf } from 'utils/arrays'
import {
  getHighestPermission,
  PRODUCT_COLLECTION_LEVELS,
} from 'utils/permissions'

export function ProductCollectionDetails({
  intitialMode,
  collection,
}: {
  intitialMode?: Mode
  collection: ProductCollection
}) {
  const {
    id: collectionId,
    permissions,
    productsCount,
    imageUrl,
    shippingCountries,
    name,
    access,
    individualId: createdBy,
    createdAt,
  } = collection

  const { id: orgId } = useContext(OrgContext)
  const { id: individualId, role } = useContext(IndividualContext)

  const theme = useTheme()

  const [messageApi, contextHolder] = message.useMessage()

  const navigate = useNavigate()

  const isCollectionStillEmpty = Number(productsCount) === 0

  const [showDeleteCollectionModal, setShowDeleteCollectionModal] =
    useState(false)
  const [showPermissionsModal, setShowPermissionsModal] = useState(false)

  const highestCollectionPermission = getHighestPermission(
    PRODUCT_COLLECTION_LEVELS
  )({
    role,
    individualId,
    permissions,
  })

  const individualOnlyHasViewAccess =
    highestCollectionPermission === ProductCollection_Permission.view
  const individualHasFullAccess =
    highestCollectionPermission === ProductCollection_Permission.full
  const isStorePublished =
    access === ProductCollection_CollectionAccess_Enum.public

  const defaultMode =
    intitialMode ?? (isCollectionStillEmpty && individualHasFullAccess)
      ? Mode.edit
      : Mode.view
  const [mode, setMode] = useState<Mode>(defaultMode)

  const isInEditMode = mode === Mode.edit

  const handleDeleteCollection = () => {
    navigate(DEFAULT_ROUTES.ORGANIZATION.SWAG.ROOT)
    deleteCollectionById({ collectionId })
  }

  const handleCollectionImageUpload = (
    upload: UploadChangeParam<UploadFile<any>>
  ) => {
    if (!collectionId) return
    uploadCollectionImage({ collectionId, upload, orgId })
      .then(() => messageApi.success('Successfully updated collection photo'))
      .catch(() =>
        messageApi.error('Failed to upload image. Please contact support.')
      )
  }

  const handleSetAccess = (isPublic: boolean) => {
    const access = isPublic
      ? ProductCollection_CollectionAccess_Enum.public
      : ProductCollection_CollectionAccess_Enum.private

    const boost = isPublic
      ? // if we're setting the collection to public, we're setting the boost to 1
        // which is the default value for public collections
        1
      : // if we're setting the collection to private, we're setting the boost to 0
        // which is the default value for private collections
        0

    updateCollection({ id: collectionId, collection: { access, boost } })
    messageApi.success(
      `Access set to ${ProductCollection_CollectionAccess_Enum[access]}`
    )
  }

  const handleSetHeading = (value: string) => {
    updateCollection({ id: collectionId, collection: { name: value } })
  }

  const handleSetDescription = (value: string) => {
    updateCollection({ id: collectionId, collection: { description: value } })
  }

  const handleUpdateShippingCountries = (shippingCountries: string[]) => {
    updateCollection({ id: collectionId, collection: { shippingCountries } })
  }

  const handleUpdatePermissions = (permissions: Permissions) => {
    updateProductCollectionPermissions({
      id: collectionId,
      permissions: permissions as Record<string, ProductCollection_Permission>,
    })
  }

  const handleSendCollection = () => {
    const productCollectionId = collection?.id

    const gift = new Program_Gift({
      id: productCollectionId,
      title: name,
      imageUrl,
      productCollectionId,
      redeemableQuantity: 1,
    })
    const budget = calculateProductCollectionMinPrice(collection)
    navigate(DEFAULT_ROUTES.ORGANIZATION.REWARDS.NEW_REWARD, {
      state: { gift, budget },
    })
  }

  return (
    <>
      {contextHolder}
      <Flex vertical flex={1} style={{ position: 'relative' }}>
        {/** Collection image */}
        <div style={{ position: 'relative' }}>
          <div style={{ position: 'absolute', top: 8, left: 8, zIndex: 1 }}>
            <BackIconButton
              type="default"
              to={DEFAULT_ROUTES.ORGANIZATION.SWAG.ROOT}
            />
          </div>
          <EditableImage
            src={collection?.imageUrl}
            onImageChange={handleCollectionImageUpload}
            mode={mode}
            sizes="100vw"
          />
        </div>

        {/** Collection details and products grid */}
        <Flex vertical gap={24} style={{ padding: 32 }}>
          {/** Collection details */}
          <Flex align="center">
            {/** Header and description */}
            <Flex flex={1} vertical gap={8}>
              <Editable
                type="heading"
                size={900}
                value={collection?.name || ''}
                onSubmit={handleSetHeading}
                disabled={!isInEditMode}
              />

              <Editable
                type="text"
                value={
                  collection?.description ||
                  'Type your swag store description here.'
                }
                onSubmit={handleSetDescription}
                disabled={!isInEditMode}
              />
            </Flex>

            {/** Collection settings and stats */}
            <Flex flex={1} vertical align="end" gap={16}>
              {/** Settings */}
              <Flex gap={40} align="center">
                <Flex gap={8} align="center">
                  {isStorePublished ? (
                    <Tooltip title="View in Swag Store">
                      <PerkLink
                        to={`${DEFAULT_ROUTES.SWAG.ROOT}/${collectionId}`}
                        style={{ fontSize: 14, minWidth: 'max-content' }}
                      >
                        Swag Store
                      </PerkLink>
                    </Tooltip>
                  ) : (
                    <Text minWidth="max-content">Swag Store</Text>
                  )}

                  <Switch
                    checkedChildren={<CheckOutlined />}
                    value={isStorePublished}
                    onChange={handleSetAccess}
                    disabled={!individualHasFullAccess}
                  />

                  {isStorePublished && (
                    <CountriesWidget
                      title="Available in"
                      countryIso3s={shippingCountries}
                      onCountriesChange={handleUpdateShippingCountries}
                      withCountriesSuffix
                      mode={mode}
                    />
                  )}
                </Flex>

                <Flex gap={8} align="center">
                  <Button
                    onClick={handleSendCollection}
                    type="primary"
                    disabled={
                      individualOnlyHasViewAccess || isCollectionStillEmpty
                    }
                  >
                    Send collection
                  </Button>

                  <Dropdown
                    trigger={['click']}
                    menu={{
                      items: [
                        isInEditMode
                          ? {
                              key: '1',
                              label: (
                                <Text>
                                  <EyeOutlined style={{ marginRight: 8 }} />
                                  View collection
                                </Text>
                              ),
                              onClick: () => setMode(Mode.view),
                            }
                          : {
                              key: '1',
                              disabled: !individualHasFullAccess,
                              label: (
                                <Tooltip
                                  title={
                                    !individualHasFullAccess
                                      ? 'You do not have permission to edit this collection'
                                      : undefined
                                  }
                                >
                                  <Text
                                    cursor={
                                      !individualHasFullAccess
                                        ? 'not-allowed'
                                        : undefined
                                    }
                                    color={
                                      !individualHasFullAccess
                                        ? theme.colors.gray500
                                        : undefined
                                    }
                                  >
                                    <EditOutlined style={{ marginRight: 8 }} />
                                    Edit collection
                                  </Text>
                                </Tooltip>
                              ),
                              onClick: () => setMode(Mode.edit),
                            },
                        {
                          key: '2',
                          disabled: !individualHasFullAccess,
                          label: (
                            <Tooltip
                              title={
                                !individualHasFullAccess
                                  ? 'You do not have permission to delete this collection'
                                  : undefined
                              }
                            >
                              <Text
                                cursor={
                                  !individualHasFullAccess
                                    ? 'not-allowed'
                                    : undefined
                                }
                                color={
                                  !individualHasFullAccess
                                    ? theme.colors.gray500
                                    : undefined
                                }
                              >
                                <DeleteOutlined style={{ marginRight: 8 }} />
                                Delete collection
                              </Text>
                            </Tooltip>
                          ),
                          onClick: () => setShowDeleteCollectionModal(true),
                        },
                        ...insertElementIf(!!createdBy && !!createdAt, {
                          type: 'divider',
                        }),
                        ...insertElementIf(!!createdBy && !!createdAt, {
                          type: 'group',
                          label: (
                            <Flex justify="center">
                              <CreatedBy
                                createdDate={createdAt?.toDate()!}
                                vertical
                              >
                                <CreatedBy.Individual
                                  individualId={createdBy!}
                                />
                              </CreatedBy>
                            </Flex>
                          ),
                        }),
                      ],
                    }}
                  >
                    <Button icon={<EllipsisOutlined />} />
                  </Dropdown>
                </Flex>
              </Flex>

              {/** Permissions */}
              <Flex gap={8} align="center">
                <PermissionsBreakdown permissions={permissions} />
                {isInEditMode && (
                  <PerkIconTooltipButton
                    title="Change permissions"
                    type="text"
                    icon={<EditOutlined />}
                    onClick={() => setShowPermissionsModal(true)}
                  />
                )}
              </Flex>
            </Flex>
          </Flex>

          {/** Products grid */}
          <ProductCollectionProducts collection={collection} mode={mode} />
        </Flex>
      </Flex>

      {/** Edit mode banner */}
      {isInEditMode && (
        <Flex
          gap={8}
          align="center"
          style={{
            position: 'fixed',
            borderRadius: 4,
            top: 72,
            left: `calc(50% + ${SIDEBAR_WIDTH * 0.5}px)`,
            transform: 'translate(-50%)',
            backgroundColor: '#ABABAB',
            padding: '8px 12px',
            color: 'white',
          }}
        >
          <EditOutlined />
          <Text color="inherit" marginRight={32}>
            You are in Edit Mode
          </Text>
          <Button
            size="small"
            type="primary"
            onClick={() => setMode(Mode.view)}
          >
            View Mode
          </Button>
        </Flex>
      )}

      {/* Delete collection modal  */}
      <Modal
        title="Delete collection"
        open={showDeleteCollectionModal}
        onCancel={() => setShowDeleteCollectionModal(false)}
        destroyOnClose
        onOk={handleDeleteCollection}
        okText="Delete"
        cancelText="Cancel"
      >
        <Pane marginBottom={32}>
          <Text>Are you sure you want to delete the following collection?</Text>
        </Pane>
        <Pane
          marginRight={16}
          marginBottom={32}
          display="flex"
          justifyContent="space-between"
        >
          <Heading size={300}>{name}</Heading>
          <Text>{Number(productsCount) || 0} products </Text>
        </Pane>
      </Modal>

      {/** Permissions modal */}
      <Modal
        open={showPermissionsModal}
        afterClose={() => setShowPermissionsModal(false)}
        onCancel={() => setShowPermissionsModal(false)}
        title={`${name} permissions`}
        width={512}
        footer={null}
      >
        {showPermissionsModal && (
          <Pane display="flex" minHeight="100px">
            <Pane
              display="flex"
              flexDirection="column"
              gap={8}
              marginTop={32}
              width="100%"
            >
              <PermissionsOverview
                currentPermissions={permissions}
                onPermissionsChange={handleUpdatePermissions}
                adminPermissionOptions={
                  adminProductCollectionPermissionsOptions
                }
                managerPermissionOptions={
                  managerProductCollectionPermissionsOptions
                }
                individualPermissionOptions={
                  individualProductCollectionPermissionsOptions
                }
                isModal
              />
            </Pane>
          </Pane>
        )}
      </Modal>
    </>
  )
}
