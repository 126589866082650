import {
  ListAllApprovedCategoriesByOrgUser,
  ListenToEligibleCategories,
} from 'api/databaseCalls'
import { EligibleCategories } from 'constants/EligibleCategories'
import { CatContext } from 'context'
import { useMemberships } from 'hooks'
import useIds from 'hooks/useIds'
import { useContext, useEffect, useState } from 'react'

export function useOrgUserSwagBalance() {
  const { orgId, userId, individualId } = useIds()
  const macroCategories = useContext(CatContext)

  const [approvedCategories, setApprovedCategories] = useState<string[]>([])

  const [orgUserSwagBalance, setSwagBalance] = useState(0)

  const { memberships } = useMemberships()

  const userOnlyHasSwagFunds =
    approvedCategories &&
    approvedCategories.length === 1 &&
    approvedCategories.includes(EligibleCategories.SWAG)

  // List approved categories
  useEffect(() => {
    if (
      orgId &&
      individualId &&
      macroCategories?.length &&
      memberships?.length
    ) {
      ListAllApprovedCategoriesByOrgUser({
        orgId,
        individualId,
      }).then(res => {
        if (res) {
          setApprovedCategories(res)
        } else setApprovedCategories(macroCategories?.map(({ name }) => name))
      })
    } else setApprovedCategories(macroCategories?.map(({ name }) => name))
  }, [orgId, individualId, macroCategories, memberships])

  // get users budget to spend on swag
  // This should likely move to Swag page
  useEffect(() => {
    if (!userId || !orgId) return undefined

    return ListenToEligibleCategories({
      orgId,
      userId,
      eligibleCategoryIds: [
        EligibleCategories.SWAG,
        EligibleCategories.ALL_MERCHANTS,
      ],
      cb: setSwagBalance,
    })
  }, [userId, orgId])

  return { orgUserSwagBalance, userOnlyHasSwagFunds }
}
