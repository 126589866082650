import { Flex } from 'antd'
import { PerkScrollbars } from 'components'
import { UserContext } from 'context'
import { Pane } from 'evergreen-ui'
import { ShippingAddress } from 'gen/perkup/v1/root_user_pb'
import { useDefaultOrgColors } from 'hooks'
import { useContext } from 'react'
import { AddressDisplay } from './address-display'
import { NewAddressButton } from './new-address-button'

export function AddressesList({
  shippingAddresses,
  onSelectAddress,
  selectedAddress,
}: {
  shippingAddresses: ShippingAddress[]
  onSelectAddress: (address: ShippingAddress) => void
  selectedAddress?: ShippingAddress
}) {
  const { defaultShippingAddressId } = useContext(UserContext)
  const { backgroundColor } = useDefaultOrgColors()

  return (
    <Flex vertical gap={8}>
      <NewAddressButton
        addAddressCTALabel="Add new address"
        onAddressSubmit={address => {
          onSelectAddress(address)
        }}
        submitLabel="Add"
        dialogTitle="Add new address"
        asSmallButton
      />
      <PerkScrollbars style={{ maxHeight: 520 }}>
        <Flex vertical gap={4}>
          {shippingAddresses.map(shippingAddress => {
            return (
              <Pane
                margin={1}
                key={shippingAddress?.id}
                background={
                  selectedAddress?.id === shippingAddress?.id
                    ? backgroundColor
                    : undefined
                }
                hoverElevation={1}
                paddingX={16}
                paddingY={8}
                cursor="pointer"
                onClick={() => onSelectAddress(shippingAddress)}
                borderRadius={8}
              >
                <AddressDisplay
                  address={shippingAddress}
                  showDefaultBadge={
                    defaultShippingAddressId === shippingAddress?.id
                  }
                />
              </Pane>
            )
          })}
        </Flex>
      </PerkScrollbars>
    </Flex>
  )
}
