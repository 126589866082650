import { captureException } from '@sentry/react'
import {
  collection,
  getDocs,
  getFirestore,
  onSnapshot,
  query,
  where,
} from 'firebase/firestore'
import { OrgList, OrgList_ListStatus } from 'gen/perkup/v1/organization_pb'
import { converter } from 'utils/firestore'
import { toSentry } from 'utils/sentry'

export function ListenToListsByOrgId({
  orgId,
  cb,
}: {
  orgId: string
  cb: (orgList: OrgList[]) => void
}) {
  const db = getFirestore()
  const colRef = collection(db, `organizations/${orgId}/lists`).withConverter(
    converter(OrgList)
  )
  const q = query(
    colRef,
    where('status', '==', OrgList_ListStatus[OrgList_ListStatus.active])
  )
  return onSnapshot(
    q,
    query => {
      cb(query.docs.map(doc => doc.data()))
    },
    error => {
      console.error(error)
      captureException(toSentry(error), {
        contexts: {
          ListenToListsByOrgId: { orgId },
        },
      })
    }
  )
}

export async function ListListsByOrgId({ orgId }: { orgId: string }) {
  const lists: OrgList[] = []
  try {
    const db = getFirestore()
    const colRef = collection(db, `organizations/${orgId}/lists`).withConverter(
      converter(OrgList)
    )
    const q = query(
      colRef,
      where('status', '==', OrgList_ListStatus[OrgList_ListStatus.active])
    )
    await getDocs(q).then(query => {
      const orgLists = query.docs.map(doc => doc.data())
      lists.push(...orgLists)
    })
  } catch (error) {
    console.error(error)
    captureException(toSentry(error), {
      contexts: {
        ListListsByOrgId: { orgId },
      },
    })
  }
  return lists
}
