import { CategoryButton } from 'components/Buttons/CategoryButton'
import { ApprovedCategories } from 'constants/EligibleCategories'
import { CatContext } from 'context'
import { Pane } from 'evergreen-ui'
import { useShopRoute } from 'hooks'
import { useSpendingOptions } from 'hooks/useSpendingOptions'
import isEmpty from 'lodash-es/isEmpty'
import { useContext } from 'react'
import { useNavigate } from 'react-router-dom'

export default function ProgramCategories({
  approvedCategories,
  withName = true,
  applyOrgSettings = false,
}: {
  approvedCategories: string[]
  withName?: boolean
  applyOrgSettings?: boolean
}) {
  const allCategories = useContext(CatContext)
  const navigate = useNavigate()
  const { showAmazon, showSwag } = useSpendingOptions()

  const { shopRoute } = useShopRoute()

  if (isEmpty(approvedCategories)) return null

  const categoriesToRender = allCategories.filter(({ name }) => {
    if (!applyOrgSettings) {
      return approvedCategories.includes(name)
    }
    if (name === ApprovedCategories.AMAZON && !showAmazon) return false
    if (name === ApprovedCategories.SWAG && !showSwag) return false
    return approvedCategories.includes(name)
  })

  return (
    <Pane display="flex" gap={8} flexWrap="wrap">
      {categoriesToRender.map(({ name, colorOutlineIcon, sys }) => (
        <CategoryButton
          key={sys?.id}
          name={name}
          withName={withName}
          icon={colorOutlineIcon?.url ?? ''}
          onClick={() => navigate(shopRoute)}
        />
      ))}
    </Pane>
  )
}
