import {
  PLACID_BUCKET_URL_SUFFIX,
  TEMPORARY_SWAG_BANNER_FALLBACK_PLACID_FILE_NAME,
} from 'constants/images'
import { OrgContext } from 'context'
import { useContext, useEffect, useState } from 'react'
import { GetFirebaseStorageImage } from 'services/images'

export function useTemporarySwagBannerFallbackUrl() {
  const { id: orgId } = useContext(OrgContext)

  const [hasLoaded, setHasLoaded] = useState(false)
  const [tempPlacidFallBackUrl, setTemplacidFallBackUrl] = useState<string>()

  useEffect(() => {
    GetFirebaseStorageImage({
      orgId,
      suffix: PLACID_BUCKET_URL_SUFFIX,
      imageName: TEMPORARY_SWAG_BANNER_FALLBACK_PLACID_FILE_NAME,
    })
      .then(image => image && setTemplacidFallBackUrl(image.url))
      .finally(() => setHasLoaded(true))
  }, [orgId])

  return { tempPlacidFallBackUrl, hasLoaded }
}
