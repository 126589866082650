import { EyeOutlined } from '@ant-design/icons'
import { PerkIconTooltipButton } from 'components'
import { EmailPreview } from 'components/EmailPreview'
import { DEFAULT_PROGRAM_BANNER } from 'constants/programs'
import { OrgContext } from 'context'
import { Dialog, Pane } from 'evergreen-ui'
import { Program } from 'gen/perkup/v1/program_pb'
import { useContext, useState } from 'react'

export function ProgramEmailPreviewButton({ program }: { program: Program }) {
  const [showEmail, setShowEmail] = useState(false)
  const org = useContext(OrgContext)
  const isGift = !!program.gift
  return (
    <>
      <PerkIconTooltipButton
        title="Email preview"
        icon={<EyeOutlined />}
        type="text"
        onClick={() => setShowEmail(true)}
      />
      <Dialog
        isShown={showEmail}
        onCloseComplete={() => setShowEmail(false)}
        hasHeader={false}
        hasFooter={false}
        width={672}
      >
        <Pane marginTop={16} marginBottom={16}>
          <EmailPreview
            banner={program.email?.banner || DEFAULT_PROGRAM_BANNER}
            title={program.name || 'A reward for you 🎉'}
            programNote={program.note || 'Thank you for everything you do!'}
            fromName={program.email?.fromName || org.name}
            isGift={isGift}
            amount={program.budget}
          />
        </Pane>
      </Dialog>
    </>
  )
}
