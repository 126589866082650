// @generated by protoc-gen-es v1.6.0 with parameter "target=ts"
// @generated from file perkup/v1/rules.proto (package perkup.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, Timestamp } from "@bufbuild/protobuf";
import { Program_Gift } from "./program_pb.js";

/**
 * Firestore /organizations/:id/rules/:id
 *
 * @generated from message perkup.v1.Rule
 */
export class Rule extends Message<Rule> {
  /**
   * stored in firestore
   *
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * specify the individual info field to do date matching calculation
   *
   * @generated from field: perkup.v1.DateSelectorType.Enum date_selector = 2;
   */
  dateSelector = DateSelectorType_Enum.DATE_SELECTOR_UNSPECIFIED;

  /**
   * type of event - birthdays or anniversaries (should consistent with its parent rule)
   *
   * @generated from field: perkup.v1.GroupType.Enum group = 3;
   */
  group = GroupType_Enum.ACTION_TYPE_UNSPECIFIED;

  /**
   * the offset year for anniversary and new hire. year = -1 means the current year new hire. year = 0 means the every year. takes value -1, 0, 1, 2, 3...
   *
   * @generated from field: int32 year = 4;
   */
  year = 0;

  /**
   * @generated from field: string org_id = 5;
   */
  orgId = "";

  /**
   * Lists is a list of label IDs which are a sub collection on the org (subject to change)
   *
   * @generated from field: map<string, string> labels = 6;
   */
  labels: { [key: string]: string } = {};

  /**
   * @generated from field: repeated string eligible_categories = 7;
   */
  eligibleCategories: string[] = [];

  /**
   * @generated from field: optional bool disabled = 8;
   */
  disabled?: boolean;

  /**
   * @generated from field: optional string account_id = 9;
   */
  accountId?: string;

  /**
   * @generated from field: optional int32 expiry_days = 10;
   */
  expiryDays?: number;

  constructor(data?: PartialMessage<Rule>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "perkup.v1.Rule";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "date_selector", kind: "enum", T: proto3.getEnumType(DateSelectorType_Enum) },
    { no: 3, name: "group", kind: "enum", T: proto3.getEnumType(GroupType_Enum) },
    { no: 4, name: "year", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "org_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "labels", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "scalar", T: 9 /* ScalarType.STRING */} },
    { no: 7, name: "eligible_categories", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 8, name: "disabled", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 9, name: "account_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 10, name: "expiry_days", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Rule {
    return new Rule().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Rule {
    return new Rule().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Rule {
    return new Rule().fromJsonString(jsonString, options);
  }

  static equals(a: Rule | PlainMessage<Rule> | undefined, b: Rule | PlainMessage<Rule> | undefined): boolean {
    return proto3.util.equals(Rule, a, b);
  }
}

/**
 * Firestore /organizations/:id/rules/:id/actions/:id
 *
 * @generated from message perkup.v1.Action
 */
export class Action extends Message<Action> {
  /**
   * stored in firestore
   *
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: int32 date_offset = 2;
   */
  dateOffset = 0;

  /**
   * @generated from field: string message = 3;
   */
  message = "";

  /**
   * @generated from field: string title = 4;
   */
  title = "";

  /**
   * @generated from field: int32 amount = 5;
   */
  amount = 0;

  /**
   * @generated from field: string image = 6;
   */
  image = "";

  /**
   * @generated from field: string from = 7;
   */
  from = "";

  /**
   * @generated from field: string org_id = 8;
   */
  orgId = "";

  /**
   * @generated from field: string rule_id = 9;
   */
  ruleId = "";

  /**
   * @generated from field: optional perkup.v1.Program.Gift gift = 10;
   */
  gift?: Program_Gift;

  /**
   * @generated from field: perkup.v1.ActionType.Enum action_type = 11;
   */
  actionType = ActionType_Enum.ACTION_TYPE_UNSPECIFIED;

  constructor(data?: PartialMessage<Action>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "perkup.v1.Action";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "date_offset", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "amount", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 6, name: "image", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "from", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "org_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "rule_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "gift", kind: "message", T: Program_Gift, opt: true },
    { no: 11, name: "action_type", kind: "enum", T: proto3.getEnumType(ActionType_Enum) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Action {
    return new Action().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Action {
    return new Action().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Action {
    return new Action().fromJsonString(jsonString, options);
  }

  static equals(a: Action | PlainMessage<Action> | undefined, b: Action | PlainMessage<Action> | undefined): boolean {
    return proto3.util.equals(Action, a, b);
  }
}

/**
 * Firestore /organizations/:id/rules/:id/actions/:id/customActions/:id
 *
 * @generated from message perkup.v1.CustomAction
 */
export class CustomAction extends Message<CustomAction> {
  /**
   * stored in firestore
   *
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: int32 date_offset = 2;
   */
  dateOffset = 0;

  /**
   * @generated from field: string message = 3;
   */
  message = "";

  /**
   * @generated from field: string title = 4;
   */
  title = "";

  /**
   * @generated from field: int32 amount = 5;
   */
  amount = 0;

  /**
   * @generated from field: string image = 6;
   */
  image = "";

  /**
   * @generated from field: string from = 7;
   */
  from = "";

  /**
   * @generated from field: string org_id = 8;
   */
  orgId = "";

  /**
   * @generated from field: bool excluded = 9;
   */
  excluded = false;

  /**
   * @generated from field: string rule_id = 10;
   */
  ruleId = "";

  /**
   * @generated from field: string action_id = 11;
   */
  actionId = "";

  /**
   * @generated from field: optional perkup.v1.Program.Gift gift = 12;
   */
  gift?: Program_Gift;

  /**
   * @generated from field: perkup.v1.ActionType.Enum action_type = 13;
   */
  actionType = ActionType_Enum.ACTION_TYPE_UNSPECIFIED;

  constructor(data?: PartialMessage<CustomAction>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "perkup.v1.CustomAction";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "date_offset", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "amount", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 6, name: "image", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "from", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "org_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "excluded", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 10, name: "rule_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "action_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 12, name: "gift", kind: "message", T: Program_Gift, opt: true },
    { no: 13, name: "action_type", kind: "enum", T: proto3.getEnumType(ActionType_Enum) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CustomAction {
    return new CustomAction().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CustomAction {
    return new CustomAction().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CustomAction {
    return new CustomAction().fromJsonString(jsonString, options);
  }

  static equals(a: CustomAction | PlainMessage<CustomAction> | undefined, b: CustomAction | PlainMessage<CustomAction> | undefined): boolean {
    return proto3.util.equals(CustomAction, a, b);
  }
}

/**
 * GroupAction contains all the required information to create a program send notification for individuals having the same rule settings.
 *
 * @generated from message perkup.v1.GroupAction
 */
export class GroupAction extends Message<GroupAction> {
  /**
   * list of individual ids sharing same settings for rule and action
   *
   * @generated from field: repeated string individual_ids = 1;
   */
  individualIds: string[] = [];

  /**
   * @generated from field: perkup.v1.Action action = 2;
   */
  action?: Action;

  /**
   * @generated from field: string org_id = 3;
   */
  orgId = "";

  /**
   * @generated from field: perkup.v1.Rule rule = 4;
   */
  rule?: Rule;

  /**
   * @generated from field: google.protobuf.Timestamp today = 5;
   */
  today?: Timestamp;

  /**
   * @generated from field: optional string program_id = 6;
   */
  programId?: string;

  constructor(data?: PartialMessage<GroupAction>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "perkup.v1.GroupAction";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "individual_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 2, name: "action", kind: "message", T: Action },
    { no: 3, name: "org_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "rule", kind: "message", T: Rule },
    { no: 5, name: "today", kind: "message", T: Timestamp },
    { no: 6, name: "program_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GroupAction {
    return new GroupAction().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GroupAction {
    return new GroupAction().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GroupAction {
    return new GroupAction().fromJsonString(jsonString, options);
  }

  static equals(a: GroupAction | PlainMessage<GroupAction> | undefined, b: GroupAction | PlainMessage<GroupAction> | undefined): boolean {
    return proto3.util.equals(GroupAction, a, b);
  }
}

/**
 * @generated from message perkup.v1.ActionType
 */
export class ActionType extends Message<ActionType> {
  constructor(data?: PartialMessage<ActionType>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "perkup.v1.ActionType";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ActionType {
    return new ActionType().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ActionType {
    return new ActionType().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ActionType {
    return new ActionType().fromJsonString(jsonString, options);
  }

  static equals(a: ActionType | PlainMessage<ActionType> | undefined, b: ActionType | PlainMessage<ActionType> | undefined): boolean {
    return proto3.util.equals(ActionType, a, b);
  }
}

/**
 * @generated from enum perkup.v1.ActionType.Enum
 */
export enum ActionType_Enum {
  /**
   * @generated from enum value: ACTION_TYPE_UNSPECIFIED = 0;
   */
  ACTION_TYPE_UNSPECIFIED = 0,

  /**
   * @generated from enum value: createProgram = 1;
   */
  createProgram = 1,

  /**
   * @generated from enum value: notifyAdmins = 2;
   */
  notifyAdmins = 2,

  /**
   * @generated from enum value: notifyManager = 3;
   */
  notifyManager = 3,

  /**
   * @generated from enum value: publicAnnouncement = 4;
   */
  publicAnnouncement = 4,
}
// Retrieve enum metadata with: proto3.getEnumType(ActionType_Enum)
proto3.util.setEnumType(ActionType_Enum, "perkup.v1.ActionType.Enum", [
  { no: 0, name: "ACTION_TYPE_UNSPECIFIED" },
  { no: 1, name: "createProgram" },
  { no: 2, name: "notifyAdmins" },
  { no: 3, name: "notifyManager" },
  { no: 4, name: "publicAnnouncement" },
]);

/**
 * type of event - birthdays or anniversaries (should consistent with its parent rule)
 *
 * @generated from message perkup.v1.GroupType
 */
export class GroupType extends Message<GroupType> {
  constructor(data?: PartialMessage<GroupType>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "perkup.v1.GroupType";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GroupType {
    return new GroupType().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GroupType {
    return new GroupType().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GroupType {
    return new GroupType().fromJsonString(jsonString, options);
  }

  static equals(a: GroupType | PlainMessage<GroupType> | undefined, b: GroupType | PlainMessage<GroupType> | undefined): boolean {
    return proto3.util.equals(GroupType, a, b);
  }
}

/**
 * @generated from enum perkup.v1.GroupType.Enum
 */
export enum GroupType_Enum {
  /**
   * @generated from enum value: ACTION_TYPE_UNSPECIFIED = 0;
   */
  ACTION_TYPE_UNSPECIFIED = 0,

  /**
   * @generated from enum value: anniversaries = 1;
   */
  anniversaries = 1,

  /**
   * @generated from enum value: birthdays = 2;
   */
  birthdays = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(GroupType_Enum)
proto3.util.setEnumType(GroupType_Enum, "perkup.v1.GroupType.Enum", [
  { no: 0, name: "ACTION_TYPE_UNSPECIFIED" },
  { no: 1, name: "anniversaries" },
  { no: 2, name: "birthdays" },
]);

/**
 * specify the individual info field to do date matching calculation
 *
 * @generated from message perkup.v1.DateSelectorType
 */
export class DateSelectorType extends Message<DateSelectorType> {
  constructor(data?: PartialMessage<DateSelectorType>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "perkup.v1.DateSelectorType";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DateSelectorType {
    return new DateSelectorType().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DateSelectorType {
    return new DateSelectorType().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DateSelectorType {
    return new DateSelectorType().fromJsonString(jsonString, options);
  }

  static equals(a: DateSelectorType | PlainMessage<DateSelectorType> | undefined, b: DateSelectorType | PlainMessage<DateSelectorType> | undefined): boolean {
    return proto3.util.equals(DateSelectorType, a, b);
  }
}

/**
 * @generated from enum perkup.v1.DateSelectorType.Enum
 */
export enum DateSelectorType_Enum {
  /**
   * @generated from enum value: DATE_SELECTOR_UNSPECIFIED = 0;
   */
  DATE_SELECTOR_UNSPECIFIED = 0,

  /**
   * for anniversaries
   *
   * @generated from enum value: startDate = 1;
   */
  startDate = 1,

  /**
   * individual dob field
   *
   * @generated from enum value: birthday = 2;
   */
  birthday = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(DateSelectorType_Enum)
proto3.util.setEnumType(DateSelectorType_Enum, "perkup.v1.DateSelectorType.Enum", [
  { no: 0, name: "DATE_SELECTOR_UNSPECIFIED" },
  { no: 1, name: "startDate" },
  { no: 2, name: "birthday" },
]);

/**
 * @generated from message perkup.v1.RuleCheckRequest
 */
export class RuleCheckRequest extends Message<RuleCheckRequest> {
  /**
   * @generated from field: optional perkup.v1.ReqDate time = 1;
   */
  time?: ReqDate;

  /**
   * @generated from field: bool generate_report = 2;
   */
  generateReport = false;

  /**
   * @generated from field: repeated string force_run_org_ids = 3;
   */
  forceRunOrgIds: string[] = [];

  constructor(data?: PartialMessage<RuleCheckRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "perkup.v1.RuleCheckRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "time", kind: "message", T: ReqDate, opt: true },
    { no: 2, name: "generate_report", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 3, name: "force_run_org_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RuleCheckRequest {
    return new RuleCheckRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RuleCheckRequest {
    return new RuleCheckRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RuleCheckRequest {
    return new RuleCheckRequest().fromJsonString(jsonString, options);
  }

  static equals(a: RuleCheckRequest | PlainMessage<RuleCheckRequest> | undefined, b: RuleCheckRequest | PlainMessage<RuleCheckRequest> | undefined): boolean {
    return proto3.util.equals(RuleCheckRequest, a, b);
  }
}

/**
 * @generated from message perkup.v1.ReqDate
 */
export class ReqDate extends Message<ReqDate> {
  /**
   * @generated from field: int32 day = 1;
   */
  day = 0;

  /**
   * @generated from field: int32 month = 2;
   */
  month = 0;

  /**
   * @generated from field: int32 year = 3;
   */
  year = 0;

  constructor(data?: PartialMessage<ReqDate>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "perkup.v1.ReqDate";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "day", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "month", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "year", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ReqDate {
    return new ReqDate().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ReqDate {
    return new ReqDate().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ReqDate {
    return new ReqDate().fromJsonString(jsonString, options);
  }

  static equals(a: ReqDate | PlainMessage<ReqDate> | undefined, b: ReqDate | PlainMessage<ReqDate> | undefined): boolean {
    return proto3.util.equals(ReqDate, a, b);
  }
}

