import { Button, Flex, Select, Tabs } from 'antd'
import { ALL_PRODUCTS, FILTER_DISPLAY_NAMES, LABELS } from 'constants/algolia'
import { Strong } from 'evergreen-ui'
import { ProductVariant } from 'gen/perkup/v1/product_variant_pb'
import { startCase } from 'lodash-es'
import { useClearRefinements, useMenu } from 'react-instantsearch'
import { startCaseWithAmpersand } from 'utils/labels'

export function AlgoliaMenuFilter({
  attribute,
  limit,
  asSelectDropdown,
}: {
  attribute: Exclude<keyof ProductVariant, 'collectionIds' | 'categories'> // These should use algolia-collections-filter.tsx
  limit: number
  asSelectDropdown?: boolean
}) {
  const { refine: clearRefinements } = useClearRefinements({
    includedAttributes: [attribute],
  })

  const { items, refine, canToggleShowMore, toggleShowMore, isShowingMore } =
    useMenu({
      limit,
      attribute,
      sortBy: ['name:asc'],
      showMore: true,
      showMoreLimit: 1000,
    })

  const itemsWithAllTab = [
    {
      key: ALL_PRODUCTS,
      label: 'All',
      value: ALL_PRODUCTS,
      isRefined: !items.some(item => item.isRefined),
    },
    ...items.map(item => ({
      key: item.value,
      label: LABELS[item.label] || startCaseWithAmpersand(item.label),
      value: item.value,
      isRefined: item.isRefined,
    })),
  ]

  if (asSelectDropdown) {
    return (
      <Select
        style={{ width: '100%' }}
        defaultValue={ALL_PRODUCTS}
        variant="borderless"
        placement="bottomRight"
        onClear={clearRefinements}
        popupMatchSelectWidth={false}
        placeholder={startCase(FILTER_DISPLAY_NAMES[attribute])}
        onSelect={(_, { key }) =>
          key === ALL_PRODUCTS ? clearRefinements() : refine(key)
        }
        options={itemsWithAllTab}
      />
    )
  }

  return (
    <Flex vertical gap={8}>
      <Strong>{startCase(FILTER_DISPLAY_NAMES[attribute])}</Strong>

      <Tabs
        className="custom-ant-vertical-tabs"
        defaultActiveKey="All"
        tabPosition="right"
        items={itemsWithAllTab}
        onChange={activeKey =>
          activeKey === ALL_PRODUCTS ? clearRefinements() : refine(activeKey)
        }
        tabBarGutter={1}
      />

      {canToggleShowMore && (
        <Button onClick={toggleShowMore}>
          {isShowingMore ? 'Show less' : 'Show more'}
        </Button>
      )}
    </Flex>
  )
}
