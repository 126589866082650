import { Flex } from 'antd'
import DatePicker from 'antd/es/date-picker'
import { FORM_INPUT_MAX_WIDTH } from 'constants/newReward/layout'
import dayjs from 'dayjs'
import { Strong } from 'evergreen-ui'
import { RangeValue } from 'types/RewardsDate'

export default function FoodForm({
  dateRange,
  onRangeChange,
}: {
  dateRange: RangeValue
  onRangeChange: (newDuration: RangeValue) => void
}) {
  // Can not select days before today
  const disabledDate = (current: dayjs.Dayjs) => {
    return current < dayjs().subtract(1, 'days')
  }

  return (
    <Flex vertical gap={8}>
      <Strong>Duration</Strong>

      <DatePicker.RangePicker
        style={{ width: '100%', maxWidth: FORM_INPUT_MAX_WIDTH }}
        format="MMM D, YYYY"
        value={dateRange}
        onChange={onRangeChange}
        disabledDate={disabledDate}
      />
    </Flex>
  )
}
