import { PlacidTags } from 'constants/placid/placid-tags'
import { UseCaseRecord } from 'pages/NewReward/types/forms'
import { CategoryTitle } from 'pages/Templates/constants'
import { RewardUseCasesKeysType } from './RewardUseCasesKeys'

export const personalEventsUseCases = {
  WEDDING: {
    label: 'Wedding',
    details: {
      searchQuery: 'cheers',
      templateCategories: [CategoryTitle.WEDDING],
      placidTags: [PlacidTags.WEDDING],
    },
    emailData: [
      {
        title: 'Congratulations!',
        note: 'Wishing you both a lifetime of happiness and love!',
      },
      {
        title: 'To Forever 💍',
        note: 'May your joining together bring you more joy than you can imagine!',
      },
      {
        title: 'Wedded Bliss!',
        note: 'Best wishes on this wonderful journey, as you build your new lives together!',
      },
    ],
  },
  NEW_BABY: {
    label: 'New baby',
    details: {
      searchQuery: 'baby',
      templateCategories: [CategoryTitle.NEW_BABY],
      placidTags: [PlacidTags.NEW_BABY],
    },
    emailData: [
      {
        title: 'New Arrival!',
        note: 'Warmest congratulations on the birth of your sweet baby!',
      },
      {
        title: 'Bundle of Joy 🍼',
        note: 'Best wishes and sleep-filled nights to the newest addition to the family!',
      },
      {
        title: 'Hello Little One!',
        note: "Can't wait to meet the tiny toes and those wonder-filled eyes. Congratulations!",
      },
    ],
  },
  NEW_HOME: {
    label: 'New home',
    details: {
      searchQuery: 'new home',
      templateCategories: [],
      placidTags: [PlacidTags.NEW_HOME, PlacidTags.CONGRATULATIONS],
    },
    emailData: [
      {
        title: 'Home Sweet Home!',
        note: 'Congratulations on your new home! Wishing you many happy memories in your new space.',
      },
      {
        title: 'Welcome to Your New Address 🏡',
        note: 'May your new home be filled with love, laughter, and endless joy!',
      },
      {
        title: 'New Keys, New Adventures!',
        note: 'Excited for you as you start this new chapter. Enjoy making your new house a home!',
      },
    ],
  },
  GET_WELL: {
    label: 'Get well soon',
    details: {
      searchQuery: 'get well soon',
      templateCategories: [CategoryTitle.GET_WELL],
      placidTags: [PlacidTags.GET_WELL_SOON],
    },
    emailData: [
      {
        title: 'Speedy Recovery!',
        note: 'Sending well wishes for your quick recovery and good health.',
      },
      {
        title: 'Get Well Soon 🌷',
        note: 'Looking forward to seeing you back at your best in no time!',
      },
      {
        title: 'Healing Thoughts',
        note: 'Rest up, and get back in the swing of things!',
      },
    ],
  },
  RETIREMENT: {
    label: 'Retirement',
    details: {
      searchQuery: 'happy retirement',
      templateCategories: [CategoryTitle.RETIREMENT],
      placidTags: [PlacidTags.RETIREMENT],
    },
    emailData: [
      {
        title: 'Happy Retirement!',
        note: "Here's to endless vacations and joyous memories!",
      },
      {
        title: 'To New Adventures 🌅',
        note: "May this next chapter bring all the joy and relaxation you've dreamt of!",
      },
      {
        title: 'Cheers to You!',
        note: 'Your hard work has earned you a grand and restful retirement!',
      },
    ],
  },
  BEREAVEMENT: {
    label: 'Bereavement',
    details: {
      searchQuery: 'sorry for your loss',
      templateCategories: [CategoryTitle.BEREAVEMENT],
      placidTags: [PlacidTags.BEREAVEMENT],
    },
    emailData: [
      {
        title: 'Deepest Sympathies',
        note: 'May loving memories ease your loss and bring you comfort.',
      },
      {
        title: 'With Condolences 🕊️',
        note: 'Sending you strength and comfort during this difficult time.',
      },
      {
        title: 'In Loving Memory',
        note: 'May the love of those around you provide comfort and peace.',
      },
    ],
  },
} satisfies Partial<Record<RewardUseCasesKeysType, UseCaseRecord>>
