import { CheckOutlined, CloseOutlined } from '@ant-design/icons'
import { captureException } from '@sentry/react'
import { Flex } from 'antd'
import Switch from 'antd/es/switch'
import Table, { ColumnsType } from 'antd/es/table'
import { ListenToListsByOrgId, updateRule } from 'api/databaseCalls'
import { ReactComponent as Bolt } from 'assets/lightning-strike.svg'
import {
  AddRule,
  EditableAmountCell,
  EditableImageCell,
  EditableRewardCell,
  EditableTextCell,
  EditableYearCell,
  ExpiryDaysSegment,
  RecipientsCell,
  RuleAdditionalOptions,
  SelectAccountDropdown,
  TextEditor,
} from 'components'
import { tablePaginationSettings } from 'constants/antdesign'
import { SIDEBAR_WIDTH } from 'constants/layout'
import { ActionsContext, OrgContext, RulesContext } from 'context'
import { Heading, Pane, Paragraph, Text, toaster } from 'evergreen-ui'
import {
  collection,
  deleteField,
  doc,
  getFirestore,
  setDoc,
} from 'firebase/firestore'
import { OrgList } from 'gen/perkup/v1/organization_pb'
import { Program_Gift } from 'gen/perkup/v1/program_pb'
import { Action, Rule } from 'gen/perkup/v1/rules_pb'
import { useSendableAccounts } from 'hooks'
import { startCase } from 'lodash-es'
import compact from 'lodash-es/compact'
import { formatGiftFieldValues } from 'pages/NewReward/utils/program-gifts'
import { useContext, useEffect, useMemo, useState } from 'react'
import { Reward } from 'types'
import { RuleType, RuleTypes } from 'types/rules'
import { storedId } from 'utils/firestore'
import { toSentry } from 'utils/sentry'

interface DataType {
  key: string
  disabled: boolean
  year: number
  amount: number
  image: string
  title: string
  message: string
  from: string
  labels: { [key: string]: string }
  actionId: string
  ruleId: string
  gift?: Program_Gift
  eligibleCategories: string[]
  expiryDays?: number
}

interface ItableFilterValueText {
  text: string
  value: string | number
}
interface IColumnYearFilters {
  [key: string]: boolean
}

export default function RulesTable({ ruleType }: { ruleType: RuleType }) {
  const org = useContext(OrgContext)
  const orgId = org.id
  const rules = useContext(RulesContext)
  const rewardActions = useContext(ActionsContext)
  const [lists, setLists] = useState<OrgList[]>([])

  const { sendableAccounts } = useSendableAccounts()

  useEffect(() => {
    if (!orgId) return undefined
    return ListenToListsByOrgId({ orgId, cb: setLists })
  }, [orgId])

  const db = getFirestore()
  const rulesRef = collection(db, `organizations/${orgId}/rules`).withConverter(
    storedId(Rule)
  )

  const label = useMemo(() => {
    if (ruleType === RuleTypes.birthdays) {
      return 'birthday'
    }
    if (ruleType === RuleTypes.anniversaries) {
      return 'anniversary'
    }
    return 'new hire'
  }, [ruleType])

  const hasNoRulesDescription = useMemo(() => {
    if (ruleType !== RuleTypes.newHires) {
      return `Streamline ${ruleType} by creating a rule for every year. Customize the
      gift, title, message and GIF. PerkUp will automatically notify each
      individual on their special day.`
    }
    return 'Streamline new hire swag by creating a rule for when new individuals join your team. Customize the gift, title, message and GIF. PerkUp will automatically notify each individual on their first day.'
  }, [ruleType])

  const hasRulesDescription = useMemo(() => {
    if (ruleType !== RuleTypes.newHires) {
      return `Streamline ${ruleType} by creating a rule for every year.`
    }
    return 'Streamline new hires by creating a rule for when new individuals onboard.'
  }, [ruleType])

  const isBirthday = ruleType === RuleTypes.birthdays

  // Matching data for antDesgin Table columns
  const data = compact(
    rules.map((rule: Rule) => {
      const action = rewardActions.get(rule.id)

      if (!action) return undefined // Need to make the linter happy, even though we can be certain that the action for the rule exists

      // @todo probably better to have the rule fields separate from the actions fields in the future
      return {
        key: action.id,
        actionId: action.id,
        ruleId: rule.id,
        disabled: !!rule.disabled,
        year: rule.year,
        amount: action.amount,
        image: action.image,
        title: action.title,
        message: action.message,
        from: action.from,
        labels: rule.labels,
        gift: action.gift,
        eligibleCategories: rule.eligibleCategories,
        expiryDays: rule.expiryDays,
      }
    })
  )

  const columnYearFilters = useMemo(() => {
    const columnYearFiltersMap: IColumnYearFilters = {}
    const columnYearFilters: ItableFilterValueText[] = []
    data.forEach(column => {
      if (!columnYearFiltersMap[column.year]) {
        columnYearFiltersMap[column.year] = true
        if (column.year === 0) {
          columnYearFilters.push({
            text: `Every Year`,
            value: column.year,
          })
        } else {
          columnYearFilters.push({
            text: `${column.year}`,
            value: column.year,
          })
        }
      }
    })
    return columnYearFilters
  }, [data])

  const columnRecipentsFilters = useMemo(() => {
    const columnRecipientFilters: ItableFilterValueText[] = []
    lists.forEach(orgList => {
      const subMenu = {
        text: startCase(orgList.name),
        value: orgList.name,
        children: [] as ItableFilterValueText[],
      }
      orgList.options.forEach(option => {
        subMenu.children.push({
          text: startCase(option),
          value: option,
        })
      })
      columnRecipientFilters.push(subMenu)
    })
    return columnRecipientFilters
  }, [lists])

  if (rules.length === 0)
    return (
      <Pane
        display="flex"
        border="muted"
        gap={16}
        padding={32}
        width="fit-content"
      >
        <Pane>
          <Heading marginBottom={8} size={600}>
            Welcome to {label} rules ⚡
          </Heading>
          <Paragraph marginBottom={32} size={500} maxWidth={712}>
            {hasNoRulesDescription}
          </Paragraph>
          <AddRule rules={rules} />
        </Pane>
        <Bolt height="72" />
      </Pane>
    )

  // Columns for antDesgin Table componenent
  const columns: ColumnsType<DataType> = [
    {
      title: '',
      dataIndex: 'disabled',
      key: 'disabled',
      width: 50,
      fixed: 'left',
      render: (_, record) => {
        const ruleRef = doc(rulesRef, record.ruleId)
        const onChange = (isChecked: boolean) => {
          setDoc(ruleRef, { disabled: !isChecked }, { merge: true })
            .then(() => {
              const ruleStatus = isChecked ? 'enabled' : 'disabled'
              toaster.success(`Rule ${ruleStatus}`, { id: 'rule' })
            })
            .catch(error => {
              console.error(error)
              captureException(toSentry(error), {
                contexts: {
                  disableRule: {
                    isChecked,
                  },
                },
                tags: { orgId },
              })
              toaster.warning(
                'Something went wrong, try again or contact support.'
              )
            })
        }
        const { disabled } = record

        return (
          <Switch
            checked={!disabled}
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
            onChange={onChange}
          />
        )
      },
    },
    {
      title: 'Year',
      dataIndex: 'year',
      key: 'year',
      width: 75,
      fixed: 'left',
      filters: isBirthday ? undefined : columnYearFilters,
      onFilter: (value: string | number | boolean, record) =>
        record.year === value,
      sorter: {
        compare: (a: DataType, b: DataType) => a.year - b.year, // Add sorting option for this column
      },
      render: (text, record) => {
        const ruleRef = doc(rulesRef, record.ruleId)

        // Only allow admin to edit year if we're in anniversary tab
        if (isBirthday) {
          return <Text>Every year</Text>
        }
        if (record.year === -1) {
          return <Text>New hire</Text>
        }
        return (
          <EditableYearCell
            docRef={ruleRef}
            fieldKey="year"
            columnTitle="Year"
            currentValue={text}
          />
        )
      },
    },
    {
      title: 'Budget',
      dataIndex: 'amount',
      key: 'amount',
      width: 100,
      render: (_, record) => {
        const { ruleId, actionId, gift, amount } = record
        const actionRef = doc(
          rulesRef,
          `${ruleId}/actions/${actionId}`
        ).withConverter(storedId(Action))
        return (
          <EditableAmountCell
            key={`${actionId}/${amount}/${gift?.title}`}
            docRef={actionRef}
            currentGift={gift}
            currentValue={amount}
          />
        )
      },
    },
    {
      title: 'Reward',
      dataIndex: 'reward',
      key: 'reward',
      width: 200,
      render: (_, record) => {
        const {
          ruleId,
          actionId,
          gift,
          amount: budget,
          eligibleCategories: approvedCategories,
        } = record

        const actionRef = doc(
          rulesRef,
          `${ruleId}/actions/${actionId}`
        ).withConverter(storedId(Action))
        const ruleRef = doc(rulesRef, record.ruleId).withConverter(
          storedId(Rule)
        )

        const handleRewardChange = async (reward: Reward) => {
          const { gift, budget, approvedCategories } = reward
          await setDoc(
            actionRef,
            {
              gift: gift ? formatGiftFieldValues({ gift }) : deleteField(),
              amount: budget,
            },
            { merge: true }
          )
          await setDoc(
            ruleRef,
            {
              eligibleCategories: approvedCategories,
            },
            { merge: true }
          )
        }

        return (
          <EditableRewardCell
            key={`${actionId}/${budget}/${gift?.title}/${approvedCategories}`}
            currentReward={{ gift, budget, approvedCategories }}
            onRewardChange={handleRewardChange}
          />
        )
      },
    },
    {
      title: 'Image',
      dataIndex: 'image',
      key: 'image',
      width: 75,
      render: (text, record) => {
        const handleSubmit = (newImage: string) => {
          const { actionId, ruleId } = record
          const actionRef = doc(
            rulesRef,
            `${ruleId}/actions/${actionId}`
          ).withConverter(storedId(Action))
          setDoc(
            actionRef,
            {
              image: newImage,
            },
            { merge: true }
          )
            .then(() => {
              toaster.success(`Image updated`, { id: 'image' })
            })
            .catch(error => {
              console.error(error)
              captureException(toSentry(error), {
                contexts: {
                  updateImage: {
                    newImage,
                  },
                },
                tags: { orgId },
              })
              toaster.warning(
                'Something went wrong, try again or contact support.'
              )
            })
        }

        return <EditableImageCell onSubmit={handleSubmit} currentValue={text} />
      },
    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      width: 150,
      render: (text, record) => {
        const handleSubmit = (newText: string) => {
          const { actionId, ruleId } = record
          const actionRef = doc(
            rulesRef,
            `${ruleId}/actions/${actionId}`
          ).withConverter(storedId(Action))
          setDoc(
            actionRef,
            {
              title: newText,
            },
            { merge: true }
          )
            .then(() => {
              toaster.success(`Title updated`, { id: 'title' })
            })
            .catch(error => {
              console.error(error)
              captureException(toSentry(error), {
                contexts: {
                  updateTitle: {
                    newText,
                  },
                },
                tags: { orgId },
              })
              toaster.warning(
                'Something went wrong, try again or contact support.'
              )
            })
        }

        return <EditableTextCell currentValue={text} onSubmit={handleSubmit} />
      },
    },
    {
      title: 'Message',
      dataIndex: 'message',
      key: 'message',
      width: 300,
      render: (text, record) => {
        const handleSubmit = (newText: string) => {
          const { actionId, ruleId } = record
          const actionRef = doc(
            rulesRef,
            `${ruleId}/actions/${actionId}`
          ).withConverter(storedId(Action))
          setDoc(
            actionRef,
            {
              message: newText,
            },
            { merge: true }
          )
            .then(() => {
              toaster.success(`Message updated`, { id: 'message' })
            })
            .catch(error => {
              console.error(error)
              captureException(toSentry(error), {
                contexts: {
                  updateMessage: {
                    newText,
                  },
                },
              })
              toaster.warning(
                'Something went wrong, try again or contact support.'
              )
            })
        }

        return (
          <TextEditor
            htmlContent={text}
            onBlur={handleSubmit}
            hideCancelButton
            preventEventPropagation
          />
        )
      },
    },
    {
      title: 'From',
      dataIndex: 'from',
      key: 'from',
      width: 150,
      render: (text, record) => {
        const handleSubmit = (newText: string) => {
          const { actionId, ruleId } = record
          const actionRef = doc(
            rulesRef,
            `${ruleId}/actions/${actionId}`
          ).withConverter(storedId(Action))
          setDoc(
            actionRef,
            {
              from: newText,
            },
            { merge: true }
          )
            .then(() => {
              toaster.success(`From updated`, { id: 'from' })
            })
            .catch(error => {
              console.error(error)
              captureException(toSentry(error), {
                contexts: {
                  updateFrom: {
                    newText,
                  },
                },
              })
              toaster.warning(
                'Something went wrong, try again or contact support.'
              )
            })
        }

        return <EditableTextCell currentValue={text} onSubmit={handleSubmit} />
      },
    },
    {
      title: 'Recipients',
      dataIndex: 'labels',
      key: 'labels',
      width: 150,
      filters: columnRecipentsFilters,
      onFilter: (value: string | number | boolean, record) => {
        return Object.keys(record.labels).some(
          label => record.labels[label] === value
        )
      },
      render: (_, record) => {
        const ruleRef = doc(rulesRef, record.ruleId).withConverter(
          storedId(Rule)
        )
        return (
          <RecipientsCell
            docRef={ruleRef}
            labels={record.labels}
            lists={lists}
          />
        )
      },
    },
    {
      title: 'Recipient expiry',
      dataIndex: 'expiryDays',
      key: 'expiryDays',
      width: 200,
      render: (_, record) => {
        const { ruleId, expiryDays } = record

        const handleUpdateExpiryDays = (numDays: number) => {
          const ruleRef = doc(rulesRef, ruleId).withConverter(storedId(Rule))
          const userDoesNotWantExpiry = numDays === 0
          setDoc(
            ruleRef,
            {
              expiryDays: userDoesNotWantExpiry ? deleteField() : numDays,
            },
            { merge: true }
          ).then(() => {
            if (userDoesNotWantExpiry) toaster.success(`Expiry removed`)
            else toaster.success(`Expiry updated to ${numDays}`)
          })
        }

        return (
          <ExpiryDaysSegment
            currentExpiryDays={expiryDays}
            onConfirmDays={handleUpdateExpiryDays}
          />
        )
      },
    },
    {
      title: 'Balance',
      dataIndex: 'balance',
      key: 'balance',
      width: 225,
      render: (_, record) => {
        const { ruleId } = record

        const rule = rules?.find(({ id }) => id === record.ruleId)
        const selectedAccount = sendableAccounts?.find(
          ({ id }) => id === rule?.accountId
        )

        return (
          <SelectAccountDropdown
            accounts={sendableAccounts}
            selectedAccount={selectedAccount}
            onAccountChange={newAccountId => {
              updateRule({
                orgId,
                ruleId,
                newData: { accountId: newAccountId },
              })
                .then(() => {
                  toaster.success('Rule account updated')
                })
                .catch(() => {
                  toaster.danger(`failed to update rule account`)
                })
            }}
          />
        )
      },
    },
    {
      key: 'actions',
      width: 50,
      fixed: 'right',
      render: (record: DataType) => {
        const { gift, amount } = record
        return (
          <RuleAdditionalOptions
            ruleId={record.ruleId}
            emailInfo={{
              image: record.image,
              message: record.message,
              title: record.title,
              from: record.from,
            }}
            isGift={!!gift}
            amount={amount}
          />
        )
      },
    },
  ]

  const widthOfAllColumns = columns.reduce(
    (acc, column) => acc + Number(column.width),
    0
  )

  return (
    <Flex vertical gap={16}>
      <Flex justify="space-between" align="center">
        <Text color="muted" size={500}>
          {hasRulesDescription}
        </Text>
        <AddRule rules={rules} />
      </Flex>

      <Table
        columns={columns}
        dataSource={data}
        pagination={tablePaginationSettings}
        scroll={{ x: `calc(${widthOfAllColumns}px + 50%)` }}
        style={{ width: `calc(100vw - ${SIDEBAR_WIDTH}px - 65px)` }}
      />
    </Flex>
  )
}
