import { CheckOutlined, SearchOutlined } from '@ant-design/icons'
import { Button, Checkbox, Input, Modal } from 'antd'
import { IndividualAvatar } from 'components/IndividualAvatar'
import { ALGOLIA_INDIVIDUALS_INDEX } from 'constants/algolia'
import { OrgContext, ProgramContext } from 'context'
import { Pane, Text, toaster, useTheme } from 'evergreen-ui'
import { Individual } from 'gen/perkup/v1/individual_pb'
import { useDefaultOrgColors, useIndividualRole } from 'hooks'
import { useInfiniteSelectableIndividuals } from 'hooks/individuals/useInfiniteSelectableIndividuals'

import { useContext, useState } from 'react'
import {
  Configure,
  useInstantSearch,
  useRefinementList,
  useSearchBox,
} from 'react-instantsearch'
import { individualsSearchClient } from 'services/algolia'
import { addIndividualToProgram } from 'services/individuals'
import { makePlural } from 'utils'
import {
  InstantSearchWrapper,
  getAlgoliaIndividualInitialStatuses,
} from 'utils/Algolia'
import { getIndividualDisplayName } from 'utils/individual'

const defaultButtonCTA = 'Add people'

function IndividualsListModal({
  buttonCTA = defaultButtonCTA,
  enrolledIndividualIds,
  sendAtSeconds,
  onAddIndividual,
}: {
  buttonCTA?: string
  enrolledIndividualIds: string[]
  sendAtSeconds?: number
  onAddIndividual?: (individual: Individual) => void
}) {
  const theme = useTheme()

  const program = useContext(ProgramContext)
  const org = useContext(OrgContext)
  const [hideAdded, setHideAdded] = useState(false)
  const [isModelOpen, setIsModelOpen] = useState(false)

  const { results } = useInstantSearch()
  const { query, refine } = useSearchBox()
  useRefinementList({
    attribute: 'status',
    operator: 'or',
  })

  const { individuals, sentinelRef } = useInfiniteSelectableIndividuals({
    selectedIndividualIds: [],
  })
  const { defaultColor } = useDefaultOrgColors()

  const filters = hideAdded
    ? enrolledIndividualIds.map(id => `NOT objectID:${id}`).join(' AND ')
    : ''

  const handleAddIndividual = async (individual: Individual) => {
    const individualId = individual.id

    try {
      const displayName = getIndividualDisplayName(individual)
      const action = sendAtSeconds ? 'Scheduled' : 'Added'
      toaster.success(`${action} ${displayName}`, {
        id: individualId,
      })

      await addIndividualToProgram({
        individual,
        org,
        program,
        defaultColor,
        sendAtSeconds,
      })

      if (onAddIndividual) {
        onAddIndividual(individual)
      }
    } catch (error) {
      if (error instanceof Error) {
        toaster.danger(error.message, { id: individualId })
      }
    }
  }

  return (
    <>
      <Configure filters={filters} />
      <Button type="primary" onClick={() => setIsModelOpen(true)}>
        {buttonCTA}
      </Button>

      <Modal
        title={`${buttonCTA} to ${program?.name}`}
        open={isModelOpen}
        onCancel={() => setIsModelOpen(false)}
        footer={null}
      >
        <Pane display="flex" flexDirection="column">
          <Input
            placeholder="Search by name or email"
            value={query}
            onChange={e => refine(e.target.value)}
            autoFocus
            allowClear
            prefix={<SearchOutlined />}
            style={{ width: 312, marginBottom: 8 }}
          />
          <Text color="muted" size={300}>
            {results.nbHits} {makePlural('result', results.nbHits)}
          </Text>
        </Pane>
        <Pane marginY={16}>
          <Checkbox
            checked={hideAdded}
            onChange={e => setHideAdded(e.target.checked)}
          >
            Hide added people
          </Checkbox>
        </Pane>

        <Pane display="grid">
          {individuals.map((individual, index) => {
            const isAdded = enrolledIndividualIds.includes(individual.id)
            if (isAdded && hideAdded) return null
            const isLastIndex = index === individuals.length - 1
            return (
              <Pane
                display="flex"
                gap={16}
                justifyContent="space-between"
                alignItems="center"
                cursor={isAdded ? 'default' : 'pointer'}
                hoverElevation={isAdded ? undefined : 1}
                padding={16}
                borderRadius={4}
                onClick={() => !isAdded && handleAddIndividual(individual)}
                background={isAdded ? 'tint2' : ''}
                key={individual.id}
                ref={isLastIndex ? sentinelRef : null}
              >
                <IndividualAvatar key={individual.id} individual={individual} />
                {isAdded && (
                  <CheckOutlined
                    style={{ color: theme.colors.green500, fontSize: 18 }}
                  />
                )}
              </Pane>
            )
          })}
        </Pane>
      </Modal>
    </>
  )
}

export function AddIndividualToProgramButton({
  buttonCTA = defaultButtonCTA,
  enrolledIndividualIds = [],
  sendAtSeconds,
  onAddIndividual,
}: {
  buttonCTA?: string
  enrolledIndividualIds?: string[]
  sendAtSeconds?: number
  onAddIndividual?: (individual: Individual) => void
}) {
  const org = useContext(OrgContext)
  const searchClient = individualsSearchClient(org.id)
  const { isAdmin } = useIndividualRole()

  return (
    <InstantSearchWrapper
      searchClient={searchClient}
      indexName={ALGOLIA_INDIVIDUALS_INDEX}
      initialUiState={{
        [ALGOLIA_INDIVIDUALS_INDEX]: {
          refinementList: {
            status: getAlgoliaIndividualInitialStatuses(isAdmin),
          },
        },
      }}
    >
      <IndividualsListModal
        enrolledIndividualIds={enrolledIndividualIds}
        buttonCTA={buttonCTA}
        sendAtSeconds={sendAtSeconds}
        onAddIndividual={onAddIndividual}
      />
    </InstantSearchWrapper>
  )
}
