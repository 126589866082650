// @generated by protoc-gen-connect-es v1.1.4 with parameter "target=ts"
// @generated from file perkup/v1/cube.proto (package perkup.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { Empty } from "../../google/protobuf/empty_pb.js";
import { CubeTokenResponse } from "./cube_pb.js";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service perkup.v1.CubeTokens
 */
export const CubeTokens = {
  typeName: "perkup.v1.CubeTokens",
  methods: {
    /**
     * @generated from rpc perkup.v1.CubeTokens.GetToken
     */
    getToken: {
      name: "GetToken",
      I: Empty,
      O: CubeTokenResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

