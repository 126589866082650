import { GetCanvasDesignById } from 'api/databaseCalls/reads/canvas-designs'
import { CanvasDesign } from 'gen/canvas/designs_pb'
import { useEffect, useState } from 'react'

export function useCanvasDesignById(id?: string) {
  const [canvasDesign, setCanvasDesign] = useState<CanvasDesign>()
  const [hasLoaded, setHasLoaded] = useState(false)

  useEffect(() => {
    if (!id) {
      setCanvasDesign(undefined)
      setHasLoaded(true)
      return
    }
    GetCanvasDesignById(id)
      .then(setCanvasDesign)
      .finally(() => {
        setHasLoaded(true)
      })
  }, [id])

  return { canvasDesign, hasLoaded }
}
