import { Flex, Grid } from 'antd'
import { MARKETING_HOSTNAME } from 'constants/hosts'
import { Text } from 'evergreen-ui'

const { useBreakpoint } = Grid

export function Footer() {
  const screens = useBreakpoint()

  const isLargeScreen = screens.md || screens.lg || screens.xl

  return (
    <Flex
      style={{ width: '100%' }}
      gap={isLargeScreen ? 16 : 8}
      justify="space-between"
      align="baseline"
    >
      <Text size={300} color="muted">
        © PerkUp, Inc. {new Date().getFullYear()}
      </Text>

      <Flex gap={isLargeScreen ? 16 : 8} align="baseline">
        {/* 
          the footer may be rendered in the SessionExpired component
          which is rendered outside of the RouterProvider
          so we can't use the react-router Link component here
        */}
        <a
          href={`https://${MARKETING_HOSTNAME}/terms-of-service`}
          target="_blank"
          rel="noopener noreferrer"
          color="muted"
        >
          <Text size={300} color="muted">
            Terms of Service
          </Text>
        </a>
        <a
          href={`https://${MARKETING_HOSTNAME}/privacy-policy`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Text size={300} color="muted">
            Privacy Policy
          </Text>
        </a>
      </Flex>
    </Flex>
  )
}
