import Table, { ColumnsType } from 'antd/es/table'
import { PerkImage } from 'components'
import { tablePaginationSettings } from 'constants/antdesign'
import { IN_STOCK_THRESHOLD } from 'constants/productVariants'
import { Pane, Text } from 'evergreen-ui'
import { ProductVariant } from 'gen/perkup/v1/product_variant_pb'
import { capitalize, isEmpty, keys, slice } from 'lodash-es'
import { WithKey } from 'types'
import { numToDollars } from 'utils'
import { determineInventoryStatus } from 'utils/productVariant'

function VariantQuantityTableSummary(data: readonly ProductVariant[]) {
  const productVariants = slice(data)
  const inventoryStatus = determineInventoryStatus({
    productVariants,
    displayInStockThreshold: IN_STOCK_THRESHOLD,
    level: 'product',
  })

  return (
    <Table.Summary fixed="bottom">
      <Table.Summary.Row>
        <Table.Summary.Cell index={0}>Total</Table.Summary.Cell>
        <Table.Summary.Cell index={1}>
          <Text color={inventoryStatus.color}>{inventoryStatus.text}</Text>
        </Table.Summary.Cell>
        <Table.Summary.Cell index={2}> </Table.Summary.Cell>
      </Table.Summary.Row>
    </Table.Summary>
  )
}

export function VariantQuantityTable({
  variants,
}: {
  variants: ProductVariant[]
}) {
  const defaultOptions = variants[0]?.options
  const variantOptionFilters = keys(defaultOptions).map(key => {
    const isSize = key === 'size'

    const children = defaultOptions[key].values.map(value => {
      return {
        text: isSize ? value.toUpperCase() : capitalize(value),
        value,
      }
    })
    return {
      text: capitalize(key),
      value: key,
      children,
    }
  })

  const columns: ColumnsType<WithKey<ProductVariant>> = [
    {
      title: 'Variant',
      dataIndex: 'imageUrl',
      key: 'variant',
      render: (imageUrl, record) => {
        return (
          <Pane display="flex" alignItems="center" gap={8}>
            <PerkImage
              preview
              src={imageUrl}
              alt={record.variantName}
              sizes="48px"
              width={48}
              style={{
                minWidth: 48,
                aspectRatio: '1/1',
                objectFit: 'contain',
              }}
            />
            <Pane display="flex" flexDirection="column">
              <Text>{record.variantName}</Text>
              <Text color="muted">{record.id}</Text>
            </Pane>
          </Pane>
        )
      },
      filters: isEmpty(variantOptionFilters) ? undefined : variantOptionFilters,
      filterMode: 'menu',
      onFilter: (value, record) => {
        if (typeof value === 'boolean') return value
        const optionKeys = keys(record.options)
        return optionKeys.some(key => {
          const option = record.options[key]
          return option.value === value
        })
      },
    },
    {
      title: 'Inventory',
      dataIndex: 'inventoryQuantity',
      key: 'inventoryQuantity',
      render: (_, record) => {
        const inventoryStatus = determineInventoryStatus({
          productVariants: [record],
          displayInStockThreshold: IN_STOCK_THRESHOLD,
          level: 'variant',
        })

        return <Text color={inventoryStatus.color}>{inventoryStatus.text}</Text>
      },
      sorter: (a, b) =>
        Number(a?.inventoryQuantity) - Number(b?.inventoryQuantity),
    },
    {
      title: 'Price',
      dataIndex: 'amount',
      key: 'price',
      render: amount => {
        const displayAmount = numToDollars(Number(amount)) || '-'

        return <Text>{displayAmount}</Text>
      },
      sorter: (a, b) => Number(a?.amount) - Number(b?.amount),
    },
  ]

  const formattedData: WithKey<ProductVariant>[] = variants.map(pv => {
    Object.assign(pv, { key: pv.id })
    return pv
  })

  return (
    <Table
      dataSource={formattedData}
      columns={columns}
      pagination={tablePaginationSettings}
      style={{ minWidth: 520 }}
      summary={VariantQuantityTableSummary}
    />
  )
}
