export interface RewardDetails {
  orgId: string
  programId: string
  memberId: string
  email: string
}

export interface RewardReport {
  name: string
  email: string
  createdDate: string
  rewardAmount: string
  rewardName: string
  rewardActive: string
  removedAt: string
  currency: string
}

export enum PageDirection {
  BACK,
  FORWARD,
}

export interface CampaignOverview {
  ProcessedCount: number
  DeliveredCount: number
  OpenedCount: number
  ClickedCount: number
}

export type CampaignOverviewKeys = keyof CampaignOverview

export interface PriceCalc {
  productPrice: number
  shippingCost: number
  estimatedTax: number
  perkUpFee: number
  totalCost: number
  collectionMinPrice: number
}
