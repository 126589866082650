import { DeleteOutlined } from '@ant-design/icons'
import { message, Modal } from 'antd'
import { removeProgram } from 'api/databaseCalls'
import { PerkIconTooltipButton } from 'components'
import { DEFAULT_ROUTES } from 'constants/routes'
import { OrgContext } from 'context'
import { Program, ProgramType } from 'gen/perkup/v1/program_pb'
import { useContext, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

export function DeleteProgram({
  program,
  setStopPropagation,
}: {
  program: Program
  setStopPropagation?: React.Dispatch<React.SetStateAction<boolean>>
}) {
  const org = useContext(OrgContext)
  const navigate = useNavigate()
  const location = useLocation()
  const [showDelete, setShowDelete] = useState(false)
  const [messageApi, contextHolder] = message.useMessage()

  if (!program) return null

  const { id, name, type } = program
  if (!id) return null
  if (!name) return null

  const isRewards = [
    ProgramType.rewards,
    ProgramType.anniversaries,
    ProgramType.birthdays,
  ].includes(type)
  const title = isRewards ? 'Reward' : 'Program'

  return (
    <>
      {contextHolder}
      <PerkIconTooltipButton
        title="Delete"
        icon={<DeleteOutlined />}
        type="text"
        onClick={e => {
          e.stopPropagation()
          if (setStopPropagation) setStopPropagation(true)
          setShowDelete(true)
        }}
      />
      <Modal
        title={`Delete ${title.toLowerCase()}`}
        open={showDelete}
        onClose={() => {
          setShowDelete(false)
          if (setStopPropagation) setStopPropagation(false)
        }}
        onCancel={() => {
          setShowDelete(false)
          if (setStopPropagation) setStopPropagation(false)
        }}
        onOk={() => {
          removeProgram({ orgId: org.id, programId: program.id })
          messageApi.warning(`${title} deleted`)
          setShowDelete(false)
          if (
            isRewards ||
            location.pathname !== '/organization/perk-programs'
          ) {
            navigate(DEFAULT_ROUTES.ORGANIZATION.REWARDS.SPOT)
          }
        }}
        okText="Delete"
        okType="danger"
      >
        Are you sure you&lsquo;d like to delete <b>{name}</b>?
      </Modal>
    </>
  )
}
