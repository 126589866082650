import { ProductVariant } from 'gen/perkup/v1/product_variant_pb'
import { ShippingAddress } from 'gen/perkup/v1/root_user_pb'
import { CalculatedDraftOrder } from 'gen/shopifyapi/admingql_pb'
import { useEffect, useState } from 'react'
import { CalculateShopifyDraftOrder } from 'services/shopify'
import { WithSelectedQuantity } from 'types'
import { buildShopifyLineItemsFromProductVariants } from 'utils'

export function useDraftOrder({
  productVariants,
  shippingAddress,
}: {
  productVariants: WithSelectedQuantity<ProductVariant>[]
  shippingAddress: ShippingAddress | undefined
}) {
  const [draftOrderCalculation, setDraftOrderCalculation] =
    useState<CalculatedDraftOrder>()
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (productVariants.length === 0) {
      setDraftOrderCalculation(undefined)
      return
    }

    const lineItems = buildShopifyLineItemsFromProductVariants(productVariants)

    if (lineItems.length > 0) {
      setIsLoading(true)

      CalculateShopifyDraftOrder({
        lineItems,
        shippingAddress,
      })
        .then(response =>
          setDraftOrderCalculation(response?.calculatedDraftOrder)
        )
        .finally(() => setIsLoading(false))
    }
  }, [productVariants, shippingAddress])

  return { draftOrderCalculation, isLoading }
}
