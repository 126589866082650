import { Button, ButtonProps } from 'antd'
import { DEFAULT_ROUTES } from 'constants/routes'
import { SendMessageIcon } from 'evergreen-ui'
import { Individual } from 'gen/perkup/v1/individual_pb'
import { useNavigate } from 'react-router-dom'
import { CreateRewardLocation } from 'types'

interface NewRewardButtonProps extends ButtonProps {
  title?: string
  individuals?: Individual[]
}

export function NewRewardButton({
  title = 'Create reward',
  individuals,
  ...styles
}: NewRewardButtonProps) {
  const navigate = useNavigate()

  const handleClick = () => {
    const locationState: CreateRewardLocation = {
      state: {
        recipientIndividuals: individuals,
      },
    }
    navigate(DEFAULT_ROUTES.ORGANIZATION.REWARDS.NEW_REWARD, locationState)
  }

  return (
    <Button onClick={handleClick} {...styles}>
      {title}
      <SendMessageIcon />
    </Button>
  )
}
