import { CheckOutlined, CloseOutlined } from '@ant-design/icons'
import { Flex, InputNumber, Select, Switch } from 'antd'
import { TemplateContext } from 'context'
import { Heading, Text, toaster } from 'evergreen-ui'
import { Template_Budget } from 'gen/perkup/v1/org_template_pb'
import { isNaN, round, uniq, uniqBy } from 'lodash-es'
import { useContext, useState } from 'react'

interface OptionType {
  label: string
  value: string
}

const getDefaultBudgetOptions = (templateBudget?: Template_Budget) => {
  const templateAmounts = templateBudget?.amounts
    ? [...templateBudget.amounts]
    : []

  for (let i = 25; i <= 250; i += 25) {
    templateAmounts.push(i * 100)
  }
  const defaultBudgetOptions: OptionType[] = uniq(templateAmounts)
    .sort((a, b) => (a > b ? 1 : -1))
    .map(amount => {
      return {
        label: `$${amount / 100}`,
        value: amount.toString(),
      }
    })
  return defaultBudgetOptions
}

export function TemplateBudgetForm({
  onBudgetChange,
}: {
  onBudgetChange: (newBudget: Template_Budget) => void
}) {
  const template = useContext(TemplateContext)
  const templateBudget = template?.budget
  const rewardTypes = template?.eligibleRewards
  const showBudgetAmountsForm = !!rewardTypes?.cash

  const [budgetOptions, setBudgetOptions] = useState<OptionType[]>(
    getDefaultBudgetOptions(templateBudget)
  )

  const [maxBudget, setMaxBudget] = useState<number | undefined>(
    templateBudget?.maxAmount
  )

  const selectedBudgets =
    templateBudget?.amounts?.map(budget => budget.toString()) || []

  return (
    <Flex vertical gap={8}>
      <Flex align="center" gap={16}>
        {showBudgetAmountsForm && (
          <Flex vertical gap={8}>
            <Heading>Budget amounts</Heading>
            <Select
              mode="tags"
              style={{ width: 256 }}
              placeholder="Enter amount"
              options={budgetOptions}
              allowClear
              value={selectedBudgets}
              onClear={() =>
                onBudgetChange(
                  new Template_Budget({
                    ...templateBudget,
                    amounts: [],
                  })
                )
              }
              onDeselect={value => {
                const filteredBudgets = selectedBudgets.filter(
                  budget => budget.toString() !== value
                )
                onBudgetChange(
                  new Template_Budget({
                    ...templateBudget,
                    amounts: filteredBudgets.map(budget => Number(budget)),
                  })
                )
              }}
              onSelect={(value, option) => {
                if (isNaN(Number(value))) {
                  toaster.warning('Please enter a valid number')
                  return
                }
                const newBudgets = selectedBudgets.map(budget => Number(budget))
                const numberValue = round(Number(value))

                // If no label, the user entered a manual amount and we need to create the option
                if (!option?.label) {
                  const centsValue = numberValue * 100
                  const newOption = {
                    label: `$${numberValue.toString()}`,
                    value: centsValue.toString(),
                  }
                  const newBudgetOptions = uniqBy(
                    [...budgetOptions, newOption],
                    'value'
                  )
                  const sortedOptions = newBudgetOptions.sort((a, b) =>
                    Number(a.value) > Number(b.value) ? 1 : -1
                  )

                  setBudgetOptions(sortedOptions)
                  newBudgets.push(centsValue)
                } else {
                  newBudgets.push(numberValue)
                }

                const sortedBudgets = newBudgets.sort((a, b) =>
                  Number(a) > Number(b) ? 1 : -1
                )

                onBudgetChange(
                  new Template_Budget({
                    ...templateBudget,
                    amounts: sortedBudgets,
                  })
                )
              }}
            />
          </Flex>
        )}
        <Flex vertical gap={8}>
          <Heading>Max amount</Heading>
          <InputNumber
            addonBefore="$"
            placeholder="Optional"
            value={maxBudget ? maxBudget / 100 : undefined}
            onBlur={e => {
              const newMaxBudget = Number(e.target.value) * 100
              if (isNaN(newMaxBudget) || newMaxBudget <= 0) {
                if (e.target.value.length > 0) {
                  toaster.warning('Please enter a valid number')
                }
                setMaxBudget(undefined)
                onBudgetChange(
                  new Template_Budget({
                    ...templateBudget,
                    maxAmount: undefined,
                  })
                )
                return
              }
              setMaxBudget(round(Number(newMaxBudget)))
              onBudgetChange(
                new Template_Budget({
                  ...templateBudget,
                  maxAmount: newMaxBudget,
                })
              )
            }}
            controls={false}
          />
        </Flex>
      </Flex>
      {showBudgetAmountsForm && (
        <Flex gap={8}>
          <Switch
            checked={templateBudget?.writable}
            unCheckedChildren={<CloseOutlined />}
            checkedChildren={<CheckOutlined />}
            onChange={checked => {
              onBudgetChange(
                new Template_Budget({
                  ...templateBudget,
                  writable: checked,
                  maxAmount: undefined,
                })
              )
            }}
          />

          <Text>Let sender customize amount</Text>
        </Flex>
      )}
    </Flex>
  )
}
