import { PlusOutlined } from '@ant-design/icons'
import { captureException } from '@sentry/react'
import { Button, Input, Modal, Skeleton } from 'antd'
import { createAccount } from 'api/databaseCalls/writes/accounts'
import { DEFAULT_ROUTES } from 'constants/routes'
import { IndividualContext, OrgContext } from 'context'
import { Pane, Text, toaster } from 'evergreen-ui'
import { AccountType_Enum, Account_Permission } from 'gen/perkup/v1/account_pb'
import { useIndividualRole } from 'hooks'
import { useContext, useState } from 'react'
import { useNavigate } from 'react-router'
import { PERMISSION_ADMIN } from 'types/Permissions'
import { insertFieldIf, toSentry } from 'utils'
import { getIndividualDisplayName } from 'utils/individual'

export function CreateAccountButton({
  type = 'primary',
}: {
  type?: 'primary' | 'default'
}) {
  const navigate = useNavigate()
  const org = useContext(OrgContext)
  const individual = useContext(IndividualContext)
  const individualId = individual.id
  const { isManager } = useIndividualRole()

  const displayName = getIndividualDisplayName(individual)

  const defaultAccountName = isManager ? `${displayName}'s reward budget` : ''

  const [showAccountModal, setShowAccountModal] = useState(false)
  const [accountName, setAccountName] = useState<string>(defaultAccountName)

  const [isLoading, setIsLoading] = useState(false)

  const handleCancel = () => {
    setShowAccountModal(false)

    setAccountName(defaultAccountName)
  }

  const handleCreateAccount = async () => {
    setIsLoading(true)
    try {
      const account = await createAccount({
        name: accountName,
        orgId: org?.id,
        permissions: {
          [PERMISSION_ADMIN]: Account_Permission.full,
          ...insertFieldIf(!!individualId, {
            [individualId]: Account_Permission.full,
          }),
        },
        type: AccountType_Enum.organizationFunds,
        individualId,
      })

      if (!account) {
        throw new Error('No account id returned')
      }
      toaster.success(`Successfully created ${accountName}`)

      navigate(`${DEFAULT_ROUTES.ORGANIZATION.ACCOUNTS.ROOT}/${account.id}`)
      setIsLoading(false)
    } catch (error) {
      toaster.warning('Error creating account, please contact support')
      console.error(error)
      captureException(toSentry(error), {
        contexts: {
          CreateAccountButton: {
            accountName,
            orgId: org?.id,
            individualId,
            type: AccountType_Enum.organizationFunds,
          },
        },
      })
    }
    setIsLoading(false)
  }

  const modalFooter = (
    <Button loading={isLoading} onClick={handleCreateAccount} type={type}>
      Create
    </Button>
  )

  return (
    <>
      <Button
        icon={<PlusOutlined />}
        onClick={() => setShowAccountModal(true)}
        type={type}
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        New balance
      </Button>

      <Modal
        open={showAccountModal}
        afterClose={handleCancel}
        title="New balance"
        width={512}
        footer={modalFooter}
        onCancel={handleCancel}
      >
        <Pane display="flex" minHeight="100px">
          <Pane
            display="flex"
            flexDirection="column"
            gap={8}
            marginTop={32}
            width="100%"
          >
            {isLoading && <Skeleton active />}
            {!isLoading && (
              <>
                <Text fontWeight={700}>Balance name</Text>
                <Input
                  value={accountName}
                  onChange={e => setAccountName(e.target.value)}
                  autoFocus
                  placeholder="Ex. Anniversary budget, Holiday budget, etc."
                  onKeyDown={({ key }) => {
                    if (key === 'Enter') {
                      handleCreateAccount()
                    }
                  }}
                />
              </>
            )}
          </Pane>
        </Pane>
      </Modal>
    </>
  )
}
