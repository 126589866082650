import { PlusOutlined } from '@ant-design/icons'
import { createShippingAddress, updateUserCountry } from 'api/databaseCalls'
import axios from 'axios'
import {
  AddressShortDisplay,
  AmazonNotAvailable,
  NewAddressButton,
  PerkEmpty,
  PerkLoader,
  withOrgUserSidebar,
} from 'components'
import { SearchBar } from 'components/Amazon/SearchBar'
import { NUMBER_GREEN } from 'constants/colors'
import { MAPBOX_PUBLIC_TOKEN } from 'constants/keys'
import * as PARAMS from 'constants/params'
import * as ROUTES from 'constants/routes'
import {
  CountriesContext,
  CountryContext,
  ExchangeRateContext,
  OrgContext,
  UserContext,
  UserShippingAddressesContext,
} from 'context'
import { Alert, Heading, Pane, toaster, useTheme } from 'evergreen-ui'
import { ShippingAddress } from 'gen/perkup/v1/root_user_pb'
import { useShopRoute } from 'hooks'
import useAmazonBudget from 'hooks/amazon/useAmazonBudget'
import find from 'lodash-es/find'
import toLower from 'lodash-es/toLower'
import uniqBy from 'lodash-es/uniqBy'
import NoMatch404 from 'pages/NoMatch404'
import { useCallback, useContext, useEffect, useState } from 'react'
import {
  Navigate,
  Outlet,
  Route,
  Routes,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom'
import { getCountryIsoAlpha2, numToDollars } from 'utils'
import Checkout from './Checkout'
import Home from './Home'
import Product from './Product'
import Search from './Search'
import { useInfiniteAmazonScroll } from './hooks/useInfiniteAmazonScroll'

function useIPPostalCode() {
  const [ipPostalCode, setIPPostalCode] = useState<string | undefined>()

  useEffect(() => {
    const getIPPostalCode = async () => {
      const mapBoxResponse = await axios.get(
        `https://api.mapbox.com/geocoding/v5/mapbox.places/post+office.json?limit=1&proximity=ip&access_token=${MAPBOX_PUBLIC_TOKEN}`
      )

      if (mapBoxResponse.data?.features?.length) {
        const feature = mapBoxResponse.data.features[0]
        interface Context {
          id: string
          text: string
        }
        const postalContext: Context = feature.context?.find(
          ({ id }: Context) => id.startsWith('postcode.')
        )
        const countryContext: Context = feature.context?.find(
          ({ id }: Context) => id.startsWith('country.')
        )
        const isUnitedStates = countryContext?.text === 'United States'
        if (isUnitedStates && postalContext?.text) {
          setIPPostalCode(postalContext.text)
        }
      }
      return undefined
    }

    getIPPostalCode()
  })

  return { ipPostalCode }
}

function Amazon() {
  const amazonBudget = useAmazonBudget()

  const user = useContext(UserContext)
  const country = useContext(CountryContext)
  const countries = useContext(CountriesContext)
  const org = useContext(OrgContext)
  const exchangeRate = useContext(ExchangeRateContext)
  const shippingAddresses = useContext(UserShippingAddressesContext)
  const [shippingAddress, setShippingAddress] = useState<
    ShippingAddress | undefined
  >(shippingAddresses.find(sa => sa.id === user?.defaultShippingAddressId))

  const theme = useTheme()

  const displayCurrency =
    (user?.displayCurrency as string) || (org?.displayCurrency as string)

  const navigate = useNavigate()

  const handleChangeShippingAddress = useCallback(
    (address?: ShippingAddress) => {
      if (!address) {
        setShippingAddress(undefined)

        return
      }
      const isExistingAddress = shippingAddresses.some(
        sa => sa.id === address.id
      )
      if (!isExistingAddress) {
        createShippingAddress({
          userId: user.id,
          shippingAddress: address,
        }).then(() => toaster.success('Successfully created shipping address'))
      }
      setShippingAddress(address)
    },
    [shippingAddresses, user.id]
  )

  const [searchParams] = useSearchParams()

  const keywordsParams = searchParams.get(PARAMS.KEYWORDS) || ''
  const categoryParams = searchParams.get(PARAMS.CATEGORY) || ''
  const priceRefinementParams = searchParams.get(PARAMS.PRICE_REFINEMENT) || ''
  const searchRefinementParams: string =
    searchParams.get(PARAMS.SEARCH_REFINEMENTS) || ''

  const { ipPostalCode } = useIPPostalCode()

  const [initialPageLoad, setInitialPageLoad] = useState(true)

  const location = useLocation()

  const { shopRoute } = useShopRoute()

  useEffect(() => {
    if (shippingAddress?.country && user?.id && countries) {
      const newCountry = find(
        countries,
        country =>
          getCountryIsoAlpha2(country.iso3) ===
          toLower(shippingAddress?.country)
      )
      if (newCountry) {
        updateUserCountry({
          userId: user.id,
          countryId: newCountry.id,
        })
      }
    }
  }, [shippingAddress?.country, user?.id, countries])

  const {
    products,
    lastResultRef,
    isLoading,
    infiniteIsLoading,
    handleSearchAmazon,
    error,
    searchResult,
  } = useInfiniteAmazonScroll({
    country,
    keywordsParams,
    categoryParams,
    priceRefinementParams,
    searchRefinementParams,
    shippingAddress,
    ipPostalCode,
  })

  // Get search results for initial page load
  useEffect(() => {
    if (
      !searchResult &&
      !isLoading &&
      initialPageLoad &&
      shippingAddress?.postalCode
    ) {
      setInitialPageLoad(false)
      handleSearchAmazon({})
    }
  }, [
    isLoading,
    searchResult,
    keywordsParams,
    categoryParams,
    handleSearchAmazon,
    initialPageLoad,
    shippingAddress?.postalCode,
  ])

  // Get Amazon results again if postalCode changes
  useEffect(() => {
    if (shippingAddress?.postalCode) {
      handleSearchAmazon({})
      setInitialPageLoad(true)
    }
  }, [handleSearchAmazon, shippingAddress?.postalCode])

  // Get Amazon results again if country changes
  useEffect(() => {
    if (country?.id) {
      handleSearchAmazon({})
    }
  }, [handleSearchAmazon, country?.id])

  const isHomePage = location.pathname === ROUTES.AMAZON
  const AmazonHeader = useCallback(() => {
    return (
      <Pane>
        <Pane
          display="flex"
          alignItems="center"
          marginBottom={16}
          flexWrap="wrap"
          gap={16}
        >
          <Heading
            size={600}
            color={theme.colors.gray900}
            flex="1"
            minWidth={256}
          >
            You have{' '}
            <span style={{ color: NUMBER_GREEN }}>
              {numToDollars(
                amazonBudget * exchangeRate,
                2,
                false,
                displayCurrency
              )}
            </span>{' '}
            to spend on Amazon
          </Heading>
          {shippingAddress ? (
            <AddressShortDisplay
              shippingAddress={shippingAddress}
              isMuted
              onShippingAddressChange={handleChangeShippingAddress}
              shippingAddresses={shippingAddresses}
            />
          ) : (
            <NewAddressButton
              onAddressSubmit={handleChangeShippingAddress}
              addAddressCTALabel="Add address"
              submitLabel="Confirm"
              icon={<PlusOutlined />}
              dialogTitle="Add a new address"
              asSmallButton
            />
          )}
        </Pane>
        {!isHomePage && (
          <SearchBar
            defaultValue={keywordsParams}
            handleSearchAmazon={handleSearchAmazon}
            disabled={!country?.amazon}
          />
        )}
        {isLoading ? <PerkLoader /> : <Outlet />}
      </Pane>
    )
  }, [
    amazonBudget,
    country?.amazon,
    displayCurrency,
    exchangeRate,
    handleChangeShippingAddress,
    handleSearchAmazon,
    isHomePage,
    isLoading,
    keywordsParams,
    shippingAddress,
    shippingAddresses,
    theme.colors.gray900,
  ])

  if (!country?.amazon) {
    return <AmazonNotAvailable />
  }

  const countryAlpha2Code = getCountryIsoAlpha2(country.iso3).toLowerCase()

  if (
    shippingAddress?.country &&
    shippingAddress.country.toLowerCase() !== countryAlpha2Code
  ) {
    return (
      <PerkEmpty
        header="Change shipping address"
        description={`Amazon ${country?.name} does not ship to ${shippingAddress?.country}.`}
        ctaProps={{
          children: 'Go home',
          onClick: () => navigate(ROUTES.HOME),
        }}
      />
    )
  }

  if (!['us', 'ca'].includes(countryAlpha2Code)) {
    return <Navigate to={shopRoute} />
  }

  if (isLoading) {
    return <PerkLoader />
  }

  if (error) {
    return <Alert title={error} />
  }

  return (
    <Routes>
      <Route path="/" element={<AmazonHeader />}>
        {/* Amazon Home */}
        <Route
          index
          element={<Home handleSearchAmazon={handleSearchAmazon} />}
        />

        {/* Amazon Product Results */}
        <Route
          path={ROUTES.AMAZON_SEARCH.replace(ROUTES.AMAZON, '')}
          element={
            <Search
              searchResult={searchResult}
              handleSearchAmazon={handleSearchAmazon}
              products={uniqBy(products, 'asin')} // Filters out Amazon products that have the same asin
              lastProductElmRef={lastResultRef}
              infiniteIsLoading={infiniteIsLoading}
              shippingAddress={shippingAddress}
            />
          }
        />
        {/* Amazon Product */}
        <Route
          path={ROUTES.AMAZON_PRODUCT_BY_ASIN.replace(ROUTES.AMAZON, '')}
          element={
            <Product
              onAddressChange={handleChangeShippingAddress}
              shippingAddress={shippingAddress}
            />
          }
        />
      </Route>

      {/* Amazon Checkout */}
      <Route
        path={ROUTES.AMAZON_CHECKOUT.replace(ROUTES.AMAZON, '')}
        element={
          <Checkout
            amazonBudget={amazonBudget}
            shippingAddress={shippingAddress}
            onShippingAddressChange={handleChangeShippingAddress}
          />
        }
      />
      <Route path="*" element={<NoMatch404 hasFooter={false} />} />
    </Routes>
  )
}

export default withOrgUserSidebar(Amazon)
