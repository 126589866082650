import { createContentfulAssetUrl } from 'utils'

const emptyStateCard = createContentfulAssetUrl({
  url: 'knc1roo454gw/4jpI6my4buFFQsmcmPTzUV/19968a77143285400c5bd97935dc4ebf/ShoppingLocal_EmptyState_Card.png',
  height: 1200,
  width: 1200,
})
const checkEmailImg = createContentfulAssetUrl({
  url: 'knc1roo454gw/31GvM6aoqkDlFpD9iULTdm/bf1007f7928906ddc2b469fdb598c6d5/check-email.png',
  height: 1200,
  width: 1200,
})

const signInGirlipad = createContentfulAssetUrl({
  url: 'knc1roo454gw/1t4XiFtVyf6MyBgzALYsYk/24a42f62a58ba8888f2b2ca0163a17bc/signIn-girlipad.png',
  height: 1200,
  width: 1200,
})

const swagStoreEmptyStateAsset = createContentfulAssetUrl({
  url: 'knc1roo454gw/3wtI6zHCWVAs3DBLhK2Yaf/6b6e8167d63568269daa75cdf0b3c0e5/SwagStoreEmptyStateAsset.png',
  height: 1200,
  width: 1200,
})

const noOrgAsset = createContentfulAssetUrl({
  url: 'knc1roo454gw/2LXRji263d4Z8wVq2u9m8y/23c2fb885eede78eeeb61800c38178a4/sofa.svg',
  height: 1200,
  width: 1200,
})

const orgTemplateAsset = createContentfulAssetUrl({
  url: 'knc1roo454gw/2yQq9zXKR4AID3bB5oUBeM/b753c18332f3cbc9c8a2f3ec3f3f1dc7/org_template.svg',
  height: 1200,
  width: 1200,
})

const paperAirplaneAsset = createContentfulAssetUrl({
  url: 'knc1roo454gw/wEUQRuXzqb0w2xRl2CAGT/b13b3ee8b4c35e375d1ff535434772b4/paper_airplane.svg',
  height: 1200,
  width: 1200,
})

const addCollectionProductsAsset = createContentfulAssetUrl({
  url: 'knc1roo454gw/3aBVwfqLL8GVlWrB68j6eN/13af61053107b9290ca15e4639e21486/addProducts.svg',
  height: 1200,
  width: 1200,
})

const noMemberAsset = createContentfulAssetUrl({
  url: 'knc1roo454gw/630O6l9uBj6HdF8Bd0YnvP/0bf08ce60cddf67da0728f6a1f8d7d2d/Frame.svg',
  height: 1200,
  width: 1200,
})

const giftNotAvailableAsset = createContentfulAssetUrl({
  url: 'knc1roo454gw/3u1bgMIWushYABZQo2TXQc/abb3552a4d96835eaa0745b8dde817c3/gift_not_available.svg',
  height: 1200,
  width: 1200,
})

export {
  addCollectionProductsAsset,
  checkEmailImg,
  emptyStateCard,
  giftNotAvailableAsset,
  noMemberAsset,
  noOrgAsset,
  orgTemplateAsset,
  paperAirplaneAsset,
  signInGirlipad,
  swagStoreEmptyStateAsset,
}
