import { useEffect, useState } from 'react'
import { getFullCustomerCanvasProduct } from 'services'
import { CustomerCanvasProduct } from 'types'

export function useCustomerCanvasProduct({
  referenceId,
}: {
  referenceId: string
}) {
  const [product, setProduct] = useState<CustomerCanvasProduct>()
  const [hasLoaded, setHasLoaded] = useState(false)

  useEffect(() => {
    getFullCustomerCanvasProduct(referenceId)
      .then(setProduct)
      .finally(() => setHasLoaded(true))
  }, [referenceId])

  return { product, hasLoaded }
}
