// User
export const SIGNED_IN = 'Signed In'
export const SIGNED_OUT = 'Signed Out'

// Organization
export const ORG_CREATED = 'Org Created'
export const CORE_VALUE_CREATED = 'Core Value Created'
// Perk Program
export const PROGRAM_CREATED = 'Program Created'
export const PROGRAM_UPDATED = 'Program Updated'
export const PROGRAM_DUPLICATED = 'Program Duplicated'
export const DRAFT_PROGRAM_CLICKED = 'Draft Program Clicked'

// Perk
export const PERK_CLICKED = 'Perk Clicked'

// Perk Category
export const PERK_CATEGORY_CLICKED = 'Perk Category Clicked'

// Member
export const MEMBER_ADDED = 'Member Added'
export const MEMBER_INVITED = 'Member Invited'

// Subscriptions
export const SUBSCRIPTION_CREATED = 'Subscription Created'

// Card
export const CARD_ACTIVATED = 'Card Activated'

// User Onboarding Flows
export const CREATE_PROFILE_CLICKED = 'Create Profile Clicked'
export const CONFIRM_NAME_CLICKED = 'Confirm Name Clicked'
export const FEEDBACK_SUBMITTED = 'Feedback Submitted'

// Affiliation Stores
export const AMAZON_BUSINESS_STORE = 'Amazon Business'
export const MANUAL_STORE = 'Manual'
export const MARKETPLACE = 'Marketplace'

// Browsing Overview
export const PRODUCTS_SEARCHED = 'Products Searched'
export const PRODUCT_LIST_VIEWED = 'Product List Viewed'
export const PRODUCT_LIST_FILTERED = 'Product List Filtered'

// Core Ordering
export const PRODUCT_CLICKED = 'Product Clicked'
export const PRODUCT_VIEWED = 'Product Viewed'
export const PRODUCT_ADDED = 'Product Added'
export const CHECKOUT_STARTED = 'Checkout Started'
export const ORDER_UPDATED = 'Order Updated'
export const ORDER_COMPLETED = 'Order Completed'

// Rules
export const CREATE_RULE = 'Create Rule'
export const DELETE_RULE = 'Delete Rule'

export const THANKS_MESSAGE_TAB = 'Thanks Message Tab'

// Program Templates
export const PROGRAM_TEMPLATE_CLICKED = 'Program Template Clicked'

export const PROGRAM_TEMPLATE_CATEGORY_CLICKED =
  'Program Template Category Clicked'

export const BRANDED_IMAGE_CLICKED = 'Branded Template Clicked'

// Manager Checklist
export const CHECKLIST_CLICKED = 'Checklist Clicked'

export const PRODUCT_CATALOG_CLICKED = 'Product Catalog Clicked'

export const PRODUCT_CATEGORY_CLICKED = 'Product Category Clicked'

export const PRODUCT_SEARCHED = 'Product Searched'

export const PRODUCT_VARIANT_SELECTED = 'Product Variant Selected'

export const CHECKOUT_STEP_COMPLETED = 'Checkout Step Completed'

// Activation checklist
export const CHECKLIST_VIEWED = 'Checklist Viewed'

export const PROGRAM_STEP_COMPLETED = 'Program Step Completed'

// Occasions and useCases
export const USE_CASE_CLICKED = 'Use Case Clicked'

// Mockup generation
export const SAMPLE_MOCKUPS_GENERATED = 'Sample Mockups Generated'

// Product Catalog
export const CATALOG_PRODUCT_CLICKED = 'Catalog Product Clicked'
export const CATALOG_PRODUCT_CUSTOMIZED = 'Catalog Product Customized'

// Bulk swag catalog
export const BULK_CATALOG_VIEWED = 'Bulk Catalog Viewed'
export const BULK_PRODUCT_CLICKED = 'Bulk Product Clicked'
export const BULK_ORDER_PLACED = 'Bulk Order Placed'
