import { callFunction } from 'api/functionCalls'
import { OrgContext } from 'context'
import { useContext, useEffect, useState } from 'react'
import Stripe from 'stripe'

export default function useUpcomingInvoice() {
  const { customerId } = useContext(OrgContext)

  const [isLoading, setIsLoading] = useState(false)
  const [upcomingInvoice, setUpcomingInvoice] = useState<Stripe.Invoice>()

  // Retrieve customer's upcoming invoice line items
  useEffect(() => {
    if (customerId) {
      setIsLoading(true)
      callFunction('stripe-RetrieveUpcomingInvoice', {
        customerId,
      })
        .then(setUpcomingInvoice)
        .finally(() => setIsLoading(false))
    }
  }, [customerId])

  return { upcomingInvoice, isLoading }
}
