import { ListProductCollectionsByOrgId } from 'api/databaseCalls'
import { OrgContext } from 'context'
import { ProductCollection } from 'gen/perkup/v1/product_collections_pb'
import { useContext, useEffect, useState } from 'react'

export function useListProductCollectionsByOrgId() {
  const { id: orgId } = useContext(OrgContext)

  const [productCollections, setProductCollections] = useState<
    ProductCollection[]
  >([])
  const [isLoadingMore, setIsLoadingMore] = useState(false)
  const [isLoadingInitial, setIsLoadingInitial] = useState(true)

  useEffect(() => {
    setIsLoadingMore(true)
    ListProductCollectionsByOrgId({ orgId })
      .then(setProductCollections)
      .finally(() => {
        setIsLoadingMore(false)
        setIsLoadingInitial(false)
      })
  }, [orgId])

  return {
    productCollections,
    isLoading: isLoadingInitial || isLoadingMore,
    isLoadingInitial,
    isLoadingMore,
  }
}
