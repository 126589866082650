// @generated by protoc-gen-es v1.6.0 with parameter "target=ts"
// @generated from file perkup/v1/notification.proto (package perkup.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, Timestamp } from "@bufbuild/protobuf";
import { GroupAction } from "./rules_pb.js";

/**
 * Firestore /notifications/:id
 * id:string
 * data: {
 *  from: string
 *  subject: string
 *  to: string
 *  tags: map<string, string>
 *  createdAt: string
 *  emailId: string
 * }
 * events: map<EventType, timestamp>
 * createdAt: timestamp
 * updatedAt: timestamp
 *
 * @generated from message perkup.v1.Notification
 */
export class Notification extends Message<Notification> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string org_id = 2;
   */
  orgId = "";

  /**
   * if sent to an individual
   *
   * @generated from field: optional string individual_id = 3;
   */
  individualId?: string;

  /**
   * @generated from field: map<string, perkup.v1.Notification.StatusEvent> events = 4;
   */
  events: { [key: string]: Notification_StatusEvent } = {};

  /**
   * should only be increased, not decreased
   *
   * @generated from field: perkup.v1.Notification.Status status = 5;
   */
  status = Notification_Status.STATUS_UNSPECIFIED;

  /**
   * @generated from field: google.protobuf.Timestamp created_at = 6;
   */
  createdAt?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp updated_at = 7;
   */
  updatedAt?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp status_updated_at = 8;
   */
  statusUpdatedAt?: Timestamp;

  /**
   * @generated from field: optional string idempotency_key = 9;
   */
  idempotencyKey?: string;

  /**
   * @generated from field: perkup.v1.Notification.Type type = 10;
   */
  type = Notification_Type.TYPE_UNSPECIFIED;

  /**
   * @generated from field: perkup.v1.Notification.Message message = 11;
   */
  message?: Notification_Message;

  /**
   * generally pubsub event id
   *
   * @generated from field: optional string event_id = 12;
   */
  eventId?: string;

  /**
   * external id from 3rd party
   *
   * @generated from field: optional string external_id = 13;
   */
  externalId?: string;

  constructor(data?: PartialMessage<Notification>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "perkup.v1.Notification";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "org_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "individual_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 4, name: "events", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "message", T: Notification_StatusEvent} },
    { no: 5, name: "status", kind: "enum", T: proto3.getEnumType(Notification_Status) },
    { no: 6, name: "created_at", kind: "message", T: Timestamp },
    { no: 7, name: "updated_at", kind: "message", T: Timestamp },
    { no: 8, name: "status_updated_at", kind: "message", T: Timestamp },
    { no: 9, name: "idempotency_key", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 10, name: "type", kind: "enum", T: proto3.getEnumType(Notification_Type) },
    { no: 11, name: "message", kind: "message", T: Notification_Message },
    { no: 12, name: "event_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 13, name: "external_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Notification {
    return new Notification().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Notification {
    return new Notification().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Notification {
    return new Notification().fromJsonString(jsonString, options);
  }

  static equals(a: Notification | PlainMessage<Notification> | undefined, b: Notification | PlainMessage<Notification> | undefined): boolean {
    return proto3.util.equals(Notification, a, b);
  }
}

/**
 * @generated from enum perkup.v1.Notification.Status
 */
export enum Notification_Status {
  /**
   * @generated from enum value: STATUS_UNSPECIFIED = 0;
   */
  STATUS_UNSPECIFIED = 0,

  /**
   * intent to send to 3rd party
   *
   * @generated from enum value: pending = 1;
   */
  pending = 1,

  /**
   * sent to 3rd party
   *
   * @generated from enum value: submitted = 2;
   */
  submitted = 2,

  /**
   * acknowledged by 3rd party (resend: sent status)
   *
   * @generated from enum value: providerEnqueued = 3;
   */
  providerEnqueued = 3,

  /**
   * @generated from enum value: providerDelivered = 6;
   */
  providerDelivered = 6,

  /**
   * @generated from enum value: providerDeliveryDelayed = 5;
   */
  providerDeliveryDelayed = 5,

  /**
   * @generated from enum value: providerComplained = 9;
   */
  providerComplained = 9,

  /**
   * @generated from enum value: providerBounced = 4;
   */
  providerBounced = 4,

  /**
   * @generated from enum value: providerOpened = 7;
   */
  providerOpened = 7,

  /**
   * @generated from enum value: providerClicked = 8;
   */
  providerClicked = 8,
}
// Retrieve enum metadata with: proto3.getEnumType(Notification_Status)
proto3.util.setEnumType(Notification_Status, "perkup.v1.Notification.Status", [
  { no: 0, name: "STATUS_UNSPECIFIED" },
  { no: 1, name: "pending" },
  { no: 2, name: "submitted" },
  { no: 3, name: "providerEnqueued" },
  { no: 6, name: "providerDelivered" },
  { no: 5, name: "providerDeliveryDelayed" },
  { no: 9, name: "providerComplained" },
  { no: 4, name: "providerBounced" },
  { no: 7, name: "providerOpened" },
  { no: 8, name: "providerClicked" },
]);

/**
 * @generated from enum perkup.v1.Notification.Type
 */
export enum Notification_Type {
  /**
   * @generated from enum value: TYPE_UNSPECIFIED = 0;
   */
  TYPE_UNSPECIFIED = 0,

  /**
   * @generated from enum value: accountBalanceDecline = 1;
   */
  accountBalanceDecline = 1,

  /**
   * @generated from enum value: amazonOrderStatusUpdate = 2;
   */
  amazonOrderStatusUpdate = 2,

  /**
   * @generated from enum value: lowAccountBalanceNotification = 3;
   */
  lowAccountBalanceNotification = 3,

  /**
   * @generated from enum value: manualOrderConfirmation = 4;
   */
  manualOrderConfirmation = 4,

  /**
   * @generated from enum value: orderFulfillmentStatusUpdate = 5;
   */
  orderFulfillmentStatusUpdate = 5,

  /**
   * @generated from enum value: plumOrderPlaced = 6;
   */
  plumOrderPlaced = 6,

  /**
   * @generated from enum value: reauthenticateIntegration = 7;
   */
  reauthenticateIntegration = 7,

  /**
   * @generated from enum value: rewardAccepted = 8;
   */
  rewardAccepted = 8,

  /**
   * @generated from enum value: rewardExpirationNotification = 9;
   */
  rewardExpirationNotification = 9,

  /**
   * @generated from enum value: rewardReminderNotification = 10;
   */
  rewardReminderNotification = 10,

  /**
   * @generated from enum value: stripeAuthorizationNotification = 11;
   */
  stripeAuthorizationNotification = 11,

  /**
   * @generated from enum value: stripeInvoiceNotification = 12;
   */
  stripeInvoiceNotification = 12,

  /**
   * @generated from enum value: thanksMessage = 13;
   */
  thanksMessage = 13,

  /**
   * @generated from enum value: upcomingOccasion = 14;
   */
  upcomingOccasion = 14,

  /**
   * Is the job that sends individual digests
   *
   * @generated from enum value: weeklyThanksDigest = 15;
   */
  weeklyThanksDigest = 15,

  /**
   * Is the job to format and send ALL the digests
   *
   * @generated from enum value: publishWeeklyThanksDigest = 16;
   */
  publishWeeklyThanksDigest = 16,
}
// Retrieve enum metadata with: proto3.getEnumType(Notification_Type)
proto3.util.setEnumType(Notification_Type, "perkup.v1.Notification.Type", [
  { no: 0, name: "TYPE_UNSPECIFIED" },
  { no: 1, name: "accountBalanceDecline" },
  { no: 2, name: "amazonOrderStatusUpdate" },
  { no: 3, name: "lowAccountBalanceNotification" },
  { no: 4, name: "manualOrderConfirmation" },
  { no: 5, name: "orderFulfillmentStatusUpdate" },
  { no: 6, name: "plumOrderPlaced" },
  { no: 7, name: "reauthenticateIntegration" },
  { no: 8, name: "rewardAccepted" },
  { no: 9, name: "rewardExpirationNotification" },
  { no: 10, name: "rewardReminderNotification" },
  { no: 11, name: "stripeAuthorizationNotification" },
  { no: 12, name: "stripeInvoiceNotification" },
  { no: 13, name: "thanksMessage" },
  { no: 14, name: "upcomingOccasion" },
  { no: 15, name: "weeklyThanksDigest" },
  { no: 16, name: "publishWeeklyThanksDigest" },
]);

/**
 * Message
 * * resend email
 * * send slack message (future)
 *
 * @generated from message perkup.v1.Notification.Message
 */
export class Notification_Message extends Message<Notification_Message> {
  /**
   * @generated from field: string from = 1;
   */
  from = "";

  /**
   * @generated from field: string subject = 2;
   */
  subject = "";

  /**
   * @generated from field: repeated string to = 3;
   */
  to: string[] = [];

  /**
   * @generated from field: repeated string cc = 4;
   */
  cc: string[] = [];

  /**
   * @generated from field: repeated string bcc = 5;
   */
  bcc: string[] = [];

  /**
   * @generated from field: optional string body = 6;
   */
  body?: string;

  /**
   * metadata
   * id of Notification
   *
   * @generated from field: map<string, string> metadata = 7;
   */
  metadata: { [key: string]: string } = {};

  /**
   * @generated from field: optional string email_id = 8;
   */
  emailId?: string;

  constructor(data?: PartialMessage<Notification_Message>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "perkup.v1.Notification.Message";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "from", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "subject", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "to", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 4, name: "cc", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 5, name: "bcc", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 6, name: "body", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 7, name: "metadata", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "scalar", T: 9 /* ScalarType.STRING */} },
    { no: 8, name: "email_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Notification_Message {
    return new Notification_Message().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Notification_Message {
    return new Notification_Message().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Notification_Message {
    return new Notification_Message().fromJsonString(jsonString, options);
  }

  static equals(a: Notification_Message | PlainMessage<Notification_Message> | undefined, b: Notification_Message | PlainMessage<Notification_Message> | undefined): boolean {
    return proto3.util.equals(Notification_Message, a, b);
  }
}

/**
 * @generated from message perkup.v1.Notification.StatusEvent
 */
export class Notification_StatusEvent extends Message<Notification_StatusEvent> {
  /**
   * @generated from field: perkup.v1.Notification.Status status = 1;
   */
  status = Notification_Status.STATUS_UNSPECIFIED;

  /**
   * first happened at
   *
   * @generated from field: google.protobuf.Timestamp created_at = 2;
   */
  createdAt?: Timestamp;

  /**
   * last happened at
   *
   * @generated from field: google.protobuf.Timestamp updated_at = 3;
   */
  updatedAt?: Timestamp;

  constructor(data?: PartialMessage<Notification_StatusEvent>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "perkup.v1.Notification.StatusEvent";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "enum", T: proto3.getEnumType(Notification_Status) },
    { no: 2, name: "created_at", kind: "message", T: Timestamp },
    { no: 3, name: "updated_at", kind: "message", T: Timestamp },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Notification_StatusEvent {
    return new Notification_StatusEvent().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Notification_StatusEvent {
    return new Notification_StatusEvent().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Notification_StatusEvent {
    return new Notification_StatusEvent().fromJsonString(jsonString, options);
  }

  static equals(a: Notification_StatusEvent | PlainMessage<Notification_StatusEvent> | undefined, b: Notification_StatusEvent | PlainMessage<Notification_StatusEvent> | undefined): boolean {
    return proto3.util.equals(Notification_StatusEvent, a, b);
  }
}

/**
 * Firestore /organizations/:id/notificationSettings
 * organization-wised notification settings
 *
 * @generated from message perkup.v1.NotificationSetting
 */
export class NotificationSetting extends Message<NotificationSetting> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: bool enabled = 2;
   */
  enabled = false;

  /**
   * @generated from field: perkup.v1.NotificationSetting.RuleGroup rule_group = 3;
   */
  ruleGroup = NotificationSetting_RuleGroup.PRIVACY_SETTING_KEY_UNSPECIFIED;

  /**
   * publicSlackNotification
   *
   * @generated from field: optional string channel = 4;
   */
  channel?: string;

  /**
   * publicSlackNotification
   *
   * @generated from field: optional string message = 5;
   */
  message?: string;

  /**
   * publicSlackNotification
   *
   * @generated from field: repeated string images = 6;
   */
  images: string[] = [];

  /**
   * group notification setting
   *
   * @generated from field: optional perkup.v1.GroupByType.Enum group_by = 7;
   */
  groupBy?: GroupByType_Enum;

  /**
   * @generated from field: optional perkup.v1.DayInWeek.Enum weekly_notification_day = 8;
   */
  weeklyNotificationDay?: DayInWeek_Enum;

  constructor(data?: PartialMessage<NotificationSetting>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "perkup.v1.NotificationSetting";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "enabled", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 3, name: "rule_group", kind: "enum", T: proto3.getEnumType(NotificationSetting_RuleGroup) },
    { no: 4, name: "channel", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 5, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 6, name: "images", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 7, name: "group_by", kind: "enum", T: proto3.getEnumType(GroupByType_Enum), opt: true },
    { no: 8, name: "weekly_notification_day", kind: "enum", T: proto3.getEnumType(DayInWeek_Enum), opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): NotificationSetting {
    return new NotificationSetting().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): NotificationSetting {
    return new NotificationSetting().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): NotificationSetting {
    return new NotificationSetting().fromJsonString(jsonString, options);
  }

  static equals(a: NotificationSetting | PlainMessage<NotificationSetting> | undefined, b: NotificationSetting | PlainMessage<NotificationSetting> | undefined): boolean {
    return proto3.util.equals(NotificationSetting, a, b);
  }
}

/**
 * @generated from enum perkup.v1.NotificationSetting.RuleGroup
 */
export enum NotificationSetting_RuleGroup {
  /**
   * @generated from enum value: PRIVACY_SETTING_KEY_UNSPECIFIED = 0;
   */
  PRIVACY_SETTING_KEY_UNSPECIFIED = 0,

  /**
   * @generated from enum value: birthdays = 1;
   */
  birthdays = 1,

  /**
   * @generated from enum value: anniversaries = 2;
   */
  anniversaries = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(NotificationSetting_RuleGroup)
proto3.util.setEnumType(NotificationSetting_RuleGroup, "perkup.v1.NotificationSetting.RuleGroup", [
  { no: 0, name: "PRIVACY_SETTING_KEY_UNSPECIFIED" },
  { no: 1, name: "birthdays" },
  { no: 2, name: "anniversaries" },
]);

/**
 * Firestore /notificationIdempotencyKeys/:id
 *
 * @generated from message perkup.v1.NotificationIdempotencyKey
 */
export class NotificationIdempotencyKey extends Message<NotificationIdempotencyKey> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: google.protobuf.Timestamp date = 2;
   */
  date?: Timestamp;

  /**
   * @generated from field: string token = 3;
   */
  token = "";

  /**
   * @generated from field: google.protobuf.Timestamp created = 4;
   */
  created?: Timestamp;

  /**
   * @generated from field: perkup.v1.GroupAction groupAction = 5;
   */
  groupAction?: GroupAction;

  constructor(data?: PartialMessage<NotificationIdempotencyKey>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "perkup.v1.NotificationIdempotencyKey";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "date", kind: "message", T: Timestamp },
    { no: 3, name: "token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "created", kind: "message", T: Timestamp },
    { no: 5, name: "groupAction", kind: "message", T: GroupAction },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): NotificationIdempotencyKey {
    return new NotificationIdempotencyKey().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): NotificationIdempotencyKey {
    return new NotificationIdempotencyKey().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): NotificationIdempotencyKey {
    return new NotificationIdempotencyKey().fromJsonString(jsonString, options);
  }

  static equals(a: NotificationIdempotencyKey | PlainMessage<NotificationIdempotencyKey> | undefined, b: NotificationIdempotencyKey | PlainMessage<NotificationIdempotencyKey> | undefined): boolean {
    return proto3.util.equals(NotificationIdempotencyKey, a, b);
  }
}

/**
 * constraints on the docref/id for notification setting
 *
 * @generated from message perkup.v1.NotificationSettingType
 */
export class NotificationSettingType extends Message<NotificationSettingType> {
  constructor(data?: PartialMessage<NotificationSettingType>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "perkup.v1.NotificationSettingType";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): NotificationSettingType {
    return new NotificationSettingType().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): NotificationSettingType {
    return new NotificationSettingType().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): NotificationSettingType {
    return new NotificationSettingType().fromJsonString(jsonString, options);
  }

  static equals(a: NotificationSettingType | PlainMessage<NotificationSettingType> | undefined, b: NotificationSettingType | PlainMessage<NotificationSettingType> | undefined): boolean {
    return proto3.util.equals(NotificationSettingType, a, b);
  }
}

/**
 * @generated from enum perkup.v1.NotificationSettingType.Enum
 */
export enum NotificationSettingType_Enum {
  /**
   * @generated from enum value: ACTION_TYPE_UNSPECIFIED = 0;
   */
  ACTION_TYPE_UNSPECIFIED = 0,

  /**
   * @generated from enum value: notifyAdminsAnniversaryEmail = 1;
   */
  notifyAdminsAnniversaryEmail = 1,

  /**
   * @generated from enum value: notifyAdminsBirthdayEmail = 2;
   */
  notifyAdminsBirthdayEmail = 2,

  /**
   * @generated from enum value: notifyManagerAnniversaryEmail = 3;
   */
  notifyManagerAnniversaryEmail = 3,

  /**
   * @generated from enum value: notifyManagerBirthdayEmail = 4;
   */
  notifyManagerBirthdayEmail = 4,

  /**
   * @generated from enum value: privateSlackAnniversaryMsg = 5;
   */
  privateSlackAnniversaryMsg = 5,

  /**
   * @generated from enum value: privateSlackBirthdayMsg = 6;
   */
  privateSlackBirthdayMsg = 6,

  /**
   * @generated from enum value: publicSlackAnniversary = 7;
   */
  publicSlackAnniversary = 7,

  /**
   * @generated from enum value: publicSlackBirthday = 8;
   */
  publicSlackBirthday = 8,
}
// Retrieve enum metadata with: proto3.getEnumType(NotificationSettingType_Enum)
proto3.util.setEnumType(NotificationSettingType_Enum, "perkup.v1.NotificationSettingType.Enum", [
  { no: 0, name: "ACTION_TYPE_UNSPECIFIED" },
  { no: 1, name: "notifyAdminsAnniversaryEmail" },
  { no: 2, name: "notifyAdminsBirthdayEmail" },
  { no: 3, name: "notifyManagerAnniversaryEmail" },
  { no: 4, name: "notifyManagerBirthdayEmail" },
  { no: 5, name: "privateSlackAnniversaryMsg" },
  { no: 6, name: "privateSlackBirthdayMsg" },
  { no: 7, name: "publicSlackAnniversary" },
  { no: 8, name: "publicSlackBirthday" },
]);

/**
 * group notification setting
 *
 * @generated from message perkup.v1.GroupByType
 */
export class GroupByType extends Message<GroupByType> {
  constructor(data?: PartialMessage<GroupByType>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "perkup.v1.GroupByType";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GroupByType {
    return new GroupByType().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GroupByType {
    return new GroupByType().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GroupByType {
    return new GroupByType().fromJsonString(jsonString, options);
  }

  static equals(a: GroupByType | PlainMessage<GroupByType> | undefined, b: GroupByType | PlainMessage<GroupByType> | undefined): boolean {
    return proto3.util.equals(GroupByType, a, b);
  }
}

/**
 * @generated from enum perkup.v1.GroupByType.Enum
 */
export enum GroupByType_Enum {
  /**
   * @generated from enum value: GROUP_BY_TYPE_UNSPECIFIED = 0;
   */
  GROUP_BY_TYPE_UNSPECIFIED = 0,

  /**
   * @generated from enum value: daily = 1;
   */
  daily = 1,

  /**
   * @generated from enum value: weekly = 2;
   */
  weekly = 2,

  /**
   * @generated from enum value: monthly = 3;
   */
  monthly = 3,
}
// Retrieve enum metadata with: proto3.getEnumType(GroupByType_Enum)
proto3.util.setEnumType(GroupByType_Enum, "perkup.v1.GroupByType.Enum", [
  { no: 0, name: "GROUP_BY_TYPE_UNSPECIFIED" },
  { no: 1, name: "daily" },
  { no: 2, name: "weekly" },
  { no: 3, name: "monthly" },
]);

/**
 * group notification setting
 *
 * @generated from message perkup.v1.DayInWeek
 */
export class DayInWeek extends Message<DayInWeek> {
  constructor(data?: PartialMessage<DayInWeek>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "perkup.v1.DayInWeek";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DayInWeek {
    return new DayInWeek().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DayInWeek {
    return new DayInWeek().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DayInWeek {
    return new DayInWeek().fromJsonString(jsonString, options);
  }

  static equals(a: DayInWeek | PlainMessage<DayInWeek> | undefined, b: DayInWeek | PlainMessage<DayInWeek> | undefined): boolean {
    return proto3.util.equals(DayInWeek, a, b);
  }
}

/**
 * @generated from enum perkup.v1.DayInWeek.Enum
 */
export enum DayInWeek_Enum {
  /**
   * @generated from enum value: DAY_IN_WEEK_TYPE_UNSPECIFIED = 0;
   */
  DAY_IN_WEEK_TYPE_UNSPECIFIED = 0,

  /**
   * @generated from enum value: monday = 1;
   */
  monday = 1,

  /**
   * @generated from enum value: tuesday = 2;
   */
  tuesday = 2,

  /**
   * @generated from enum value: wednesday = 3;
   */
  wednesday = 3,

  /**
   * @generated from enum value: thursday = 4;
   */
  thursday = 4,

  /**
   * @generated from enum value: friday = 5;
   */
  friday = 5,

  /**
   * @generated from enum value: saturday = 6;
   */
  saturday = 6,

  /**
   * @generated from enum value: sunday = 7;
   */
  sunday = 7,
}
// Retrieve enum metadata with: proto3.getEnumType(DayInWeek_Enum)
proto3.util.setEnumType(DayInWeek_Enum, "perkup.v1.DayInWeek.Enum", [
  { no: 0, name: "DAY_IN_WEEK_TYPE_UNSPECIFIED" },
  { no: 1, name: "monday" },
  { no: 2, name: "tuesday" },
  { no: 3, name: "wednesday" },
  { no: 4, name: "thursday" },
  { no: 5, name: "friday" },
  { no: 6, name: "saturday" },
  { no: 7, name: "sunday" },
]);

