import * as ROUTES from 'constants/routes'
import * as P from 'pages'
import { Outlet, RouteObject, useOutletContext } from 'react-router-dom'

function AuthRoutesRoot() {
  const { isAuthenticated } = useOutletContext() as {
    isAuthenticated: boolean
  }

  if (
    isAuthenticated &&
    !window.location.pathname.startsWith(ROUTES.PREVIEW_REWARDS)
  ) {
    return null
  }

  return <Outlet />
}

export const authRoutes: RouteObject[] = [
  {
    Component: AuthRoutesRoot,
    children: [
      {
        path: ROUTES.SIGN_IN,
        Component: P.SignInPage,
      },
      {
        path: ROUTES.SIGN_UP,
        Component: P.SignUpPage,
      },
      {
        path: ROUTES.EMAIL_CONFIRMATION,
        Component: P.EmailConfirmation,
      },
      {
        path: ROUTES.VERIFY_EMAIL,
        Component: P.VerifyEmailPage,
      },
      {
        path: `${ROUTES.PREVIEW_REWARD}/*`,
        Component: P.RewardPreview,
      },
    ],
  },
]
