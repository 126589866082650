import { Image } from 'antd'
import { SWAG_CARD } from 'assets/contentful'
import { useDefaultOrgColors } from 'hooks'

export function DefaultBackgroundImageBanner({
  src = SWAG_CARD,
  height = '100%',
  width = '100%',
  borderRadius,
  maxHeight,
  objectFit = 'cover',
}: {
  src?: string
  height?: string | number
  maxHeight?: string | number
  width?: string | number
  borderRadius?: number
  objectFit?: 'cover' | 'contain'
}) {
  const { backgroundColor } = useDefaultOrgColors()
  const collectionImage = src || SWAG_CARD

  const isSwagCard = src === SWAG_CARD || !src

  const imageBackgroundColor = isSwagCard ? backgroundColor : undefined
  return (
    <Image
      src={collectionImage}
      style={{
        backgroundColor: imageBackgroundColor,
        aspectRatio: '1 / 1',
        objectFit,
        borderRadius,
        maxHeight,
      }}
      preview={false}
      height={height}
      width={width}
    />
  )
}
