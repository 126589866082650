import { Flex, Image } from 'antd'
import AmexLogo from 'assets/logos/amex-logo.svg'
import MastercardLogo from 'assets/logos/mastercard-logo.svg'
import UnionpayLogo from 'assets/logos/unionpay-logo.svg'
import VisaLogo from 'assets/logos/visa-logo.svg'

const partnerLogos = [
  { src: VisaLogo, alt: 'Visa logo' },
  { src: MastercardLogo, alt: 'Mastercard logo' },
  { src: AmexLogo, alt: 'Amex logo' },
  { src: UnionpayLogo, alt: 'Unionpay logo' },
]

export function CreditCardsLogoGroup() {
  return (
    <Flex gap={4} align="center">
      {partnerLogos.map(({ src, alt }) => (
        <Image
          preview={false}
          key={src}
          src={src}
          alt={alt}
          height={18}
          style={{ marginTop: -6 }}
        />
      ))}
    </Flex>
  )
}
