// @generated by protoc-gen-es v1.6.0 with parameter "target=ts"
// @generated from file perkup/v1/decline_report.proto (package perkup.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, protoInt64, Timestamp } from "@bufbuild/protobuf";
import { Account } from "./account_pb.js";
import { VendorProduct } from "./vendor_pb.js";

/**
 * stripe authorization decline and order decline report
 * Firestore /declineReports/:id
 *
 * @generated from message perkup.v1.DeclineReport
 */
export class DeclineReport extends Message<DeclineReport> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * does the user have money in an all merchants
   *
   * @generated from field: bool all_merchants = 2;
   */
  allMerchants = false;

  /**
   * pending amount on the authorization, also the required amount for order
   *
   * @generated from field: int64 amount = 3;
   */
  amount = protoInt64.zero;

  /**
   * categories approved for the MCC
   *
   * @generated from field: repeated string approved_categories = 4;
   */
  approvedCategories: string[] = [];

  /**
   * @generated from field: string auth_id = 5;
   */
  authId = "";

  /**
   * categories which the user has eligible funds for and the mcc is approved for
   *
   * @generated from field: repeated string candidate_categories = 6;
   */
  candidateCategories: string[] = [];

  /**
   * @generated from field: google.protobuf.Timestamp created = 7;
   */
  created?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp updated = 8;
   */
  updated?: Timestamp;

  /**
   * @generated from field: repeated string error_messages = 9;
   */
  errorMessages: string[] = [];

  /**
   * network id on merchant data
   *
   * @generated from field: string network_id = 12;
   */
  networkId = "";

  /**
   * @generated from field: string org_id = 14;
   */
  orgId = "";

  /**
   * @generated from field: optional string org_status = 15;
   */
  orgStatus?: string;

  /**
   * list of the users programs and their state at the time of decline
   *
   * @generated from field: repeated perkup.v1.DeclineReport.PossibleProgram possible_programs = 16;
   */
  possiblePrograms: DeclineReport_PossibleProgram[] = [];

  /**
   * message to the user as to why their authorization was declined
   *
   * @generated from field: string reason = 18;
   */
  reason = "";

  /**
   * @generated from field: string internal_reason = 19;
   */
  internalReason = "";

  /**
   * the stripe category of the merchant
   *
   * @generated from field: string stripe_mcc = 20 [json_name = "stripeMCC"];
   */
  stripeMcc = "";

  /**
   * @generated from field: int32 user_card_balance = 21;
   */
  userCardBalance = 0;

  /**
   * @generated from field: string user_id = 22;
   */
  userId = "";

  /**
   * @generated from field: perkup.v1.DeclineReport.VerificationData verification_data = 23;
   */
  verificationData?: DeclineReport_VerificationData;

  /**
   * sum of all the funds the user has available for the merchant category
   *
   * @generated from field: int32 total_eligible_funds = 25;
   */
  totalEligibleFunds = 0;

  /**
   * @generated from field: repeated perkup.v1.Account possible_accounts = 26;
   */
  possibleAccounts: Account[] = [];

  /**
   * @generated from field: perkup.v1.DeclineReport.Source source = 27;
   */
  source = DeclineReport_Source.SOURCE_UNSPECIFIED;

  /**
   * @generated from field: optional string orderId = 28;
   */
  orderId?: string;

  /**
   * @generated from field: optional int64 allocated_amount = 30;
   */
  allocatedAmount?: bigint;

  /**
   * @generated from field: optional int64 unallocated_amount = 31;
   */
  unallocatedAmount?: bigint;

  /**
   * @generated from field: repeated perkup.v1.VendorProduct products = 32;
   */
  products: VendorProduct[] = [];

  /**
   * @generated from field: repeated perkup.v1.DeclineReport.AllocatedProgram allocated_programs = 33;
   */
  allocatedPrograms: DeclineReport_AllocatedProgram[] = [];

  /**
   * @generated from field: repeated perkup.v1.OutOfInventoryErrorDetail.Item out_of_inventory_items = 34;
   */
  outOfInventoryItems: OutOfInventoryErrorDetail_Item[] = [];

  /**
   * @generated from field: optional bool stripe_approved = 35;
   */
  stripeApproved?: boolean;

  constructor(data?: PartialMessage<DeclineReport>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "perkup.v1.DeclineReport";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "all_merchants", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 3, name: "amount", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: "approved_categories", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 5, name: "auth_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "candidate_categories", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 7, name: "created", kind: "message", T: Timestamp },
    { no: 8, name: "updated", kind: "message", T: Timestamp },
    { no: 9, name: "error_messages", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 12, name: "network_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 14, name: "org_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 15, name: "org_status", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 16, name: "possible_programs", kind: "message", T: DeclineReport_PossibleProgram, repeated: true },
    { no: 18, name: "reason", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 19, name: "internal_reason", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 20, name: "stripe_mcc", jsonName: "stripeMCC", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 21, name: "user_card_balance", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 22, name: "user_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 23, name: "verification_data", kind: "message", T: DeclineReport_VerificationData },
    { no: 25, name: "total_eligible_funds", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 26, name: "possible_accounts", kind: "message", T: Account, repeated: true },
    { no: 27, name: "source", kind: "enum", T: proto3.getEnumType(DeclineReport_Source) },
    { no: 28, name: "orderId", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 30, name: "allocated_amount", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 31, name: "unallocated_amount", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 32, name: "products", kind: "message", T: VendorProduct, repeated: true },
    { no: 33, name: "allocated_programs", kind: "message", T: DeclineReport_AllocatedProgram, repeated: true },
    { no: 34, name: "out_of_inventory_items", kind: "message", T: OutOfInventoryErrorDetail_Item, repeated: true },
    { no: 35, name: "stripe_approved", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeclineReport {
    return new DeclineReport().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeclineReport {
    return new DeclineReport().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DeclineReport {
    return new DeclineReport().fromJsonString(jsonString, options);
  }

  static equals(a: DeclineReport | PlainMessage<DeclineReport> | undefined, b: DeclineReport | PlainMessage<DeclineReport> | undefined): boolean {
    return proto3.util.equals(DeclineReport, a, b);
  }
}

/**
 * @generated from enum perkup.v1.DeclineReport.Source
 */
export enum DeclineReport_Source {
  /**
   * @generated from enum value: SOURCE_UNSPECIFIED = 0;
   */
  SOURCE_UNSPECIFIED = 0,

  /**
   * @generated from enum value: stripeIssueAuthorizationRequest = 1;
   */
  stripeIssueAuthorizationRequest = 1,

  /**
   * @generated from enum value: stripeIssueAuthorizationCreate = 2;
   */
  stripeIssueAuthorizationCreate = 2,

  /**
   * @generated from enum value: stripeIssueAuthorizationUpdate = 3;
   */
  stripeIssueAuthorizationUpdate = 3,

  /**
   * @generated from enum value: airtableOrder = 4;
   */
  airtableOrder = 4,

  /**
   * @generated from enum value: shopifyOrder = 5;
   */
  shopifyOrder = 5,

  /**
   * @generated from enum value: plumOrder = 6;
   */
  plumOrder = 6,

  /**
   * @generated from enum value: amazonOrder = 7;
   */
  amazonOrder = 7,

  /**
   * @generated from enum value: otherManualOrder = 8;
   */
  otherManualOrder = 8,
}
// Retrieve enum metadata with: proto3.getEnumType(DeclineReport_Source)
proto3.util.setEnumType(DeclineReport_Source, "perkup.v1.DeclineReport.Source", [
  { no: 0, name: "SOURCE_UNSPECIFIED" },
  { no: 1, name: "stripeIssueAuthorizationRequest" },
  { no: 2, name: "stripeIssueAuthorizationCreate" },
  { no: 3, name: "stripeIssueAuthorizationUpdate" },
  { no: 4, name: "airtableOrder" },
  { no: 5, name: "shopifyOrder" },
  { no: 6, name: "plumOrder" },
  { no: 7, name: "amazonOrder" },
  { no: 8, name: "otherManualOrder" },
]);

/**
 * @generated from message perkup.v1.DeclineReport.VerificationData
 */
export class DeclineReport_VerificationData extends Message<DeclineReport_VerificationData> {
  /**
   * @generated from field: string address_line1_check = 1;
   */
  addressLine1Check = "";

  /**
   * @generated from field: string address_postal_code_check = 2;
   */
  addressPostalCodeCheck = "";

  /**
   * @generated from field: string cvc_check = 3;
   */
  cvcCheck = "";

  /**
   * @generated from field: string expiry_check = 4;
   */
  expiryCheck = "";

  constructor(data?: PartialMessage<DeclineReport_VerificationData>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "perkup.v1.DeclineReport.VerificationData";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "address_line1_check", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "address_postal_code_check", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "cvc_check", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "expiry_check", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeclineReport_VerificationData {
    return new DeclineReport_VerificationData().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeclineReport_VerificationData {
    return new DeclineReport_VerificationData().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DeclineReport_VerificationData {
    return new DeclineReport_VerificationData().fromJsonString(jsonString, options);
  }

  static equals(a: DeclineReport_VerificationData | PlainMessage<DeclineReport_VerificationData> | undefined, b: DeclineReport_VerificationData | PlainMessage<DeclineReport_VerificationData> | undefined): boolean {
    return proto3.util.equals(DeclineReport_VerificationData, a, b);
  }
}

/**
 * @generated from message perkup.v1.DeclineReport.PossibleProgram
 */
export class DeclineReport_PossibleProgram extends Message<DeclineReport_PossibleProgram> {
  /**
   * @generated from field: int64 member_budget = 1;
   */
  memberBudget = protoInt64.zero;

  /**
   * @generated from field: bool member_enrolled = 2;
   */
  memberEnrolled = false;

  /**
   * @generated from field: int64 member_unspent = 3;
   */
  memberUnspent = protoInt64.zero;

  /**
   * @generated from field: int64 program_budget = 4;
   */
  programBudget = protoInt64.zero;

  /**
   * @generated from field: string program_id = 5;
   */
  programId = "";

  /**
   * @generated from field: int64 program_spent = 6;
   */
  programSpent = protoInt64.zero;

  /**
   * @generated from field: repeated string approved_categories = 7;
   */
  approvedCategories: string[] = [];

  /**
   * @generated from field: string account_id = 8;
   */
  accountId = "";

  /**
   * @generated from field: int64 member_spent = 9;
   */
  memberSpent = protoInt64.zero;

  constructor(data?: PartialMessage<DeclineReport_PossibleProgram>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "perkup.v1.DeclineReport.PossibleProgram";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "member_budget", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "member_enrolled", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 3, name: "member_unspent", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: "program_budget", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 5, name: "program_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "program_spent", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 7, name: "approved_categories", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 8, name: "account_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "member_spent", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeclineReport_PossibleProgram {
    return new DeclineReport_PossibleProgram().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeclineReport_PossibleProgram {
    return new DeclineReport_PossibleProgram().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DeclineReport_PossibleProgram {
    return new DeclineReport_PossibleProgram().fromJsonString(jsonString, options);
  }

  static equals(a: DeclineReport_PossibleProgram | PlainMessage<DeclineReport_PossibleProgram> | undefined, b: DeclineReport_PossibleProgram | PlainMessage<DeclineReport_PossibleProgram> | undefined): boolean {
    return proto3.util.equals(DeclineReport_PossibleProgram, a, b);
  }
}

/**
 * @generated from message perkup.v1.DeclineReport.AllocatedProgram
 */
export class DeclineReport_AllocatedProgram extends Message<DeclineReport_AllocatedProgram> {
  /**
   * @generated from field: string program_id = 1;
   */
  programId = "";

  /**
   * @generated from field: string account_id = 2;
   */
  accountId = "";

  /**
   * @generated from field: string member_id = 3;
   */
  memberId = "";

  /**
   * @generated from field: int64 allocated_amount = 4;
   */
  allocatedAmount = protoInt64.zero;

  constructor(data?: PartialMessage<DeclineReport_AllocatedProgram>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "perkup.v1.DeclineReport.AllocatedProgram";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "program_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "account_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "member_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "allocated_amount", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeclineReport_AllocatedProgram {
    return new DeclineReport_AllocatedProgram().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeclineReport_AllocatedProgram {
    return new DeclineReport_AllocatedProgram().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DeclineReport_AllocatedProgram {
    return new DeclineReport_AllocatedProgram().fromJsonString(jsonString, options);
  }

  static equals(a: DeclineReport_AllocatedProgram | PlainMessage<DeclineReport_AllocatedProgram> | undefined, b: DeclineReport_AllocatedProgram | PlainMessage<DeclineReport_AllocatedProgram> | undefined): boolean {
    return proto3.util.equals(DeclineReport_AllocatedProgram, a, b);
  }
}

/**
 * @generated from message perkup.v1.OutOfInventoryErrorDetail
 */
export class OutOfInventoryErrorDetail extends Message<OutOfInventoryErrorDetail> {
  /**
   * @generated from field: repeated perkup.v1.OutOfInventoryErrorDetail.Item items = 1;
   */
  items: OutOfInventoryErrorDetail_Item[] = [];

  constructor(data?: PartialMessage<OutOfInventoryErrorDetail>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "perkup.v1.OutOfInventoryErrorDetail";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "items", kind: "message", T: OutOfInventoryErrorDetail_Item, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): OutOfInventoryErrorDetail {
    return new OutOfInventoryErrorDetail().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): OutOfInventoryErrorDetail {
    return new OutOfInventoryErrorDetail().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): OutOfInventoryErrorDetail {
    return new OutOfInventoryErrorDetail().fromJsonString(jsonString, options);
  }

  static equals(a: OutOfInventoryErrorDetail | PlainMessage<OutOfInventoryErrorDetail> | undefined, b: OutOfInventoryErrorDetail | PlainMessage<OutOfInventoryErrorDetail> | undefined): boolean {
    return proto3.util.equals(OutOfInventoryErrorDetail, a, b);
  }
}

/**
 * @generated from message perkup.v1.OutOfInventoryErrorDetail.Item
 */
export class OutOfInventoryErrorDetail_Item extends Message<OutOfInventoryErrorDetail_Item> {
  /**
   * @generated from field: string variant_id = 1;
   */
  variantId = "";

  /**
   * @generated from field: string product_id = 2;
   */
  productId = "";

  /**
   * @generated from field: string product_name = 3;
   */
  productName = "";

  /**
   * @generated from field: string variant_name = 4;
   */
  variantName = "";

  /**
   * @generated from field: int32 order_quantity = 5;
   */
  orderQuantity = 0;

  /**
   * @generated from field: int32 available_quantity = 6;
   */
  availableQuantity = 0;

  constructor(data?: PartialMessage<OutOfInventoryErrorDetail_Item>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "perkup.v1.OutOfInventoryErrorDetail.Item";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "variant_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "product_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "product_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "variant_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "order_quantity", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 6, name: "available_quantity", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): OutOfInventoryErrorDetail_Item {
    return new OutOfInventoryErrorDetail_Item().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): OutOfInventoryErrorDetail_Item {
    return new OutOfInventoryErrorDetail_Item().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): OutOfInventoryErrorDetail_Item {
    return new OutOfInventoryErrorDetail_Item().fromJsonString(jsonString, options);
  }

  static equals(a: OutOfInventoryErrorDetail_Item | PlainMessage<OutOfInventoryErrorDetail_Item> | undefined, b: OutOfInventoryErrorDetail_Item | PlainMessage<OutOfInventoryErrorDetail_Item> | undefined): boolean {
    return proto3.util.equals(OutOfInventoryErrorDetail_Item, a, b);
  }
}

