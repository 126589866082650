import { captureException } from '@sentry/react'
import {
  updateIndividualIsActive,
  updateIndividualRemovedField,
  updateIndividualStatus,
} from 'api/databaseCalls'
import { OrgContext } from 'context'
import { Button, Dialog, Pane, toaster } from 'evergreen-ui'
import {
  arrayUnion,
  deleteField,
  doc,
  getFirestore,
  setDoc,
} from 'firebase/firestore'
import { Individual_Status } from 'gen/perkup/v1/individual_pb'
import { RootUser } from 'gen/perkup/v1/root_user_pb'
import { useContext, useState } from 'react'
import { expressPossession } from 'utils'
import { converter } from 'utils/firestore'
import { toSentry } from 'utils/sentry'

function ReAddIndividualButton({
  individualId,
  email,
  handleCloseSidesheet,
  userId,
}: {
  individualId: string
  userId?: string
  email: string
  handleCloseSidesheet: () => void
}) {
  const { id: orgId, name: orgName } = useContext(OrgContext)
  const [isShown, setIsShown] = useState(false)

  async function handleConfirm() {
    try {
      updateIndividualStatus({
        orgId,
        individualId,
        status: userId ? Individual_Status.active : Individual_Status.none,
      })

      if (userId) {
        // Re-add organization to list of User's organizations
        const db = getFirestore()
        const docRef = doc(db, `users/${userId}`).withConverter(
          converter(RootUser)
        )
        await setDoc(
          docRef,
          {
            currentOrganization: orgId,
            organizations: arrayUnion(orgId),
          },
          { merge: true }
        ).catch(error => {
          console.error(error)
          captureException(toSentry(error), {
            contexts: {
              ReAddMemberButton: {
                orgId,
              },
            },
          })
        })
      }

      updateIndividualIsActive({
        orgId,
        individualId,
        isActive: true,
      })

      updateIndividualRemovedField({
        orgId,
        individualId,
        removedAt: deleteField(),
        removedBy: deleteField(),
      })

      // Close dialog
      setIsShown(false)
      // Navigate back to people directory tab
      if (handleCloseSidesheet) handleCloseSidesheet()
      // Show active user that member has been re-added
      toaster.success(`Added ${email}`)
    } catch (error) {
      console.error(error)
      captureException(toSentry(error), {
        contexts: {
          ReAddMemberButton: {
            orgId,
          },
        },
      })
    }
  }

  return (
    <Pane>
      <Button appearance="primary" onClick={() => setIsShown(true)}>
        Add to organization
      </Button>
      <Dialog
        title="Add to organization"
        isShown={isShown}
        onCloseComplete={() => setIsShown(false)}
        onConfirm={() => handleConfirm()}
        confirmLabel="Add"
      >
        Add <strong>{email}</strong> to {expressPossession(orgName)}{' '}
        organization.
      </Dialog>
    </Pane>
  )
}

export default ReAddIndividualButton
