import dayjs from 'dayjs'
import type { DayJsDateRange } from 'features/insights'

export const ANT_DESIGN_DATE_FORMAT = 'YYYY-MM-DD' // 2018-08-02
export const PDF_DATE_FORMAT = 'MMM D, YYYY' // Aug 2, 2018

export const DATE_CHIPS: {
  value: DayJsDateRange | undefined
  label: string
}[] = [
  {
    label: 'Last 7 days',
    value: [dayjs().subtract(7, 'days'), dayjs()],
  },
  {
    label: 'Last 30 days',
    value: [dayjs().subtract(30, 'days'), dayjs()],
  },
  {
    label: 'Last 90 days',
    value: [dayjs().subtract(90, 'days'), dayjs()],
  },
  {
    label: 'Last 365 days',
    value: [dayjs().subtract(365, 'days'), dayjs()],
  },
  {
    label: 'All time',
    value: undefined,
  },
]

export const getDefaultDateRange = () => DATE_CHIPS[1].value
