import { IndividualContext } from 'context'
import { ProductCollection_Permission } from 'gen/perkup/v1/product_collections_pb'
import { useListProductCollectionsByOrgId } from 'hooks'
import { useContext } from 'react'
import {
  PRODUCT_COLLECTION_LEVELS,
  getHighestPermission,
  permissionSatisfies,
} from 'utils/permissions'

export function useAllowedCollections({
  minPermission,
}: {
  minPermission: ProductCollection_Permission
}) {
  const { productCollections, isLoading } = useListProductCollectionsByOrgId()
  const { role, id: individualId } = useContext(IndividualContext)

  // if the min permission is unspecified, we don't need to filter
  if (minPermission === ProductCollection_Permission.PERMISSION_UNSPECIFIED) {
    return {
      allowedCollections: productCollections,
      isLoading: false,
      productCollections,
    }
  }

  const allowedCollections = productCollections
    .sort((a, b) => a.name.localeCompare(b.name))
    .filter(collection => {
      const highestCollectionPermission = getHighestPermission(
        PRODUCT_COLLECTION_LEVELS
      )({
        role,
        individualId,
        permissions: collection.permissions,
      })

      return permissionSatisfies(PRODUCT_COLLECTION_LEVELS)(
        highestCollectionPermission,
        minPermission
      )
    })

  return { allowedCollections, isLoading, productCollections }
}
