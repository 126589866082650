import { captureException, captureMessage } from '@sentry/react'
import { getAuth, signInWithCustomToken, UserCredential } from 'firebase/auth'
import { FirebaseTokens } from 'gen/perkup/v1/firebase_auth_connect'
import { createClient } from 'hooks/useClient'
import { toSentry } from 'utils/sentry'

export async function customTokenSignIn(props: {
  workosCode?: string
  previewId?: string
}): Promise<UserCredential | void> {
  const { previewId, workosCode } = props
  const client = createClient(FirebaseTokens)
  const auth = getAuth()

  const details = {
    contexts: {
      customTokenSignIn: { previewId, workosCode },
    },
  }

  if (workosCode && auth?.currentUser?.uid) {
    await auth.signOut()
  }

  return client
    .customToken({ previewId, workosCode })
    .then(resp => {
      const { customToken } = resp
      if (customToken) {
        signInWithCustomToken(auth, customToken).catch(error => {
          console.error(error)
          captureException(toSentry(error), {
            contexts: {
              signInWithCustomToken: { auth, customToken },
            },
          })
        })
      } else {
        captureMessage('Error signing user in with custom token', details)
      }
    })
    .catch((error: any) => {
      console.error(error)
      captureException(toSentry(error), details)
    })
}
