import { InfoCircleOutlined } from '@ant-design/icons'
import { Button, Tooltip } from 'antd'
import { updateIndividualStatus } from 'api/databaseCalls'
import { Dialog, Pane, Text, toaster } from 'evergreen-ui'
import { Individual_Status } from 'gen/perkup/v1/individual_pb'
import { useState } from 'react'

function BlockOrgUserButton({
  orgId,
  individualId,
  email,
  status,
  handleCloseSidesheet,
}: {
  orgId: string
  individualId?: string
  email: string
  status: Individual_Status
  handleCloseSidesheet: () => void
}) {
  const [isShown, setIsShown] = useState(false)

  if (!orgId || !individualId) return null

  const isBlocked = status === Individual_Status.blocked

  const handleConfirm = () => {
    // Block member if active
    if (!isBlocked) {
      updateIndividualStatus({
        orgId,
        individualId,
        status: Individual_Status.blocked,
      })

      // Show active user that member has been blocked
      toaster.warning(`Blocked ${email}`)
    } else {
      // Unblock member
      updateIndividualStatus({
        orgId,
        individualId,
        status: Individual_Status.active,
      })

      // Show active user that member has been blocked
      toaster.success(`Unblocked ${email}`)
    }

    // Close dialog
    setIsShown(false)
    // Navigate back to people page
    if (handleCloseSidesheet) handleCloseSidesheet()
  }

  return (
    <Pane display="flex" gap={16}>
      <Button
        type={isBlocked ? 'primary' : 'default'}
        onClick={() => setIsShown(true)}
      >
        {isBlocked ? 'Unblock' : `Block ${email}`}
      </Button>
      <Tooltip title="Blocked users have access to PerkUp but can't receive rewards.">
        <InfoCircleOutlined color="grey" />
      </Tooltip>

      <Dialog
        title={`${isBlocked ? 'Unblock' : 'Block'} receiving any rewards`}
        isShown={isShown}
        onCloseComplete={() => setIsShown(false)}
        onConfirm={handleConfirm}
        confirmLabel={isBlocked ? 'Unblock' : 'Block'}
        intent={isBlocked ? 'none' : 'danger'}
      >
        <Text>
          {isBlocked ? 'Unblock' : 'Block'} <strong>{email}</strong> from
          receiving any rewards.
        </Text>
      </Dialog>
    </Pane>
  )
}

export default BlockOrgUserButton
