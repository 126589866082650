import { NUMBER_GREEN } from 'constants/colors'
import { ExchangeRateContext, OrgContext, UserContext } from 'context'
import { Heading } from 'evergreen-ui'
import { lowerCase } from 'lodash-es'
import { useContext } from 'react'
import { numToDollars } from 'utils'

export function BalanceSubHeading({
  amount,
  category,
  showCategory = true,
}: {
  amount: number
  category: string
  showCategory?: boolean
}) {
  const exchangeRate = useContext(ExchangeRateContext)
  const user = useContext(UserContext)
  const org = useContext(OrgContext)

  return (
    <Heading size={500}>
      You have{' '}
      <span style={{ color: NUMBER_GREEN }}>
        {numToDollars(
          amount * exchangeRate,
          2,
          false,
          user.displayCurrency || org?.displayCurrency
        )}
      </span>{' '}
      to spend
      {showCategory ? ` on ${lowerCase(category)}` : ''}
    </Heading>
  )
}
