import { withOrgSidebar } from 'components'
import { TEMPLATE_PARAM } from 'constants/params'
import { NEW } from 'constants/routes'
import { useIndividualRole } from 'hooks'
import NoMatch404 from 'pages/NoMatch404'
import { Route, Routes } from 'react-router-dom'
import { ExistingTemplate } from './ExistingTemplate'
import { NewTemplate } from './NewTemplate'
import { Templates } from './Templates'

function TemplatePages() {
  const { isAdmin } = useIndividualRole()
  if (!isAdmin) return <NoMatch404 />
  return (
    <Routes>
      <Route path="/" element={<Templates />} />
      <Route path={NEW} element={<NewTemplate />} />
      <Route path={`/:${TEMPLATE_PARAM}`} element={<ExistingTemplate />} />
    </Routes>
  )
}

export default withOrgSidebar(TemplatePages)
