import { captureException } from '@sentry/react'
import { DEFAULT_LOGO } from 'constants/programs'
import { OrgContext } from 'context'
import giftEmail from 'emails/gift'
import rewardEmail from 'emails/reward'
import rewardWithoutMoneyEmail from 'emails/rewardWithoutMoney'
import { compile } from 'handlebars'
import { useDefaultOrgColors } from 'hooks'
import parse from 'html-react-parser'
import { useContext } from 'react'
import { toSentry } from 'utils/sentry'

export function EmailPreview(props: {
  banner: string
  title: string
  programName?: string
  programNote: string
  fromName: string
  isGift: boolean | undefined
  amount: number | undefined
}) {
  const { banner, title, programName, programNote, fromName, isGift, amount } =
    props

  const org = useContext(OrgContext)
  const { defaultColor } = useDefaultOrgColors()

  const monetaryEmail = amount === 0 ? rewardWithoutMoneyEmail : rewardEmail
  const email = isGift ? giftEmail : monetaryEmail

  try {
    const template = compile(email)
    const emailPreview = template({
      'var:logo': org?.logoUrl || DEFAULT_LOGO,
      'var:banner': banner,
      'var:title': title,
      'var:programName': programName,
      'var:programNote': programNote,
      'var:fromName': fromName,
      'var:color': defaultColor,
    })

    return <div>{parse(emailPreview)}</div>
  } catch (error) {
    console.error(error)
    captureException(toSentry(error), {
      contexts: {
        EmailPreview: {
          banner,
          title,
          programName,
          programNote,
          fromName,
          isGift,
          amount,
        },
      },
    })
    return null
  }
}
