import { Select } from 'antd'
import { updateIndividualRole } from 'api/databaseCalls'

import { OrgContext } from 'context'
import { Heading, Pane, toaster } from 'evergreen-ui'
import { Individual_Role } from 'gen/perkup/v1/individual_pb'
import { capitalize } from 'lodash-es'
import { useContext, useState } from 'react'

export default function Permissions({
  individualId,
  role,
}: {
  individualId: string
  role: Individual_Role
}) {
  const org = useContext(OrgContext)
  const [currentRole, setCurrentRole] = useState(role)

  const selectRole = (roleSelected: Individual_Role) => {
    if (currentRole === roleSelected) {
      toaster.warning('Role is already selected')
    } else {
      updateIndividualRole({
        orgId: org.id,
        individualId,
        role: roleSelected,
      })

      toaster.success(`Role updated to ${Individual_Role[roleSelected]}`, {
        id: individualId,
      })
      setCurrentRole(roleSelected)
    }
  }

  const allUserRoles: Individual_Role[] = [
    Individual_Role.admin,
    Individual_Role.manager,
    Individual_Role.member,
  ]

  return (
    <Pane>
      <Heading marginBottom={12}>Role</Heading>
      <Select
        style={{ minWidth: 160 }}
        onSelect={(_, role) => {
          selectRole(role.role)
        }}
        value={Individual_Role[role]}
        popupMatchSelectWidth={false}
        options={allUserRoles.map(role => {
          const roleLabel = capitalize(Individual_Role[role])
          return {
            label: roleLabel,
            value: Individual_Role[role],
            role,
          }
        })}
      />
    </Pane>
  )
}
