import { SelectProductCollection } from 'features'
import { useSelectRewardContext } from '../SelectRewardContext'

export function GiftsForm2() {
  const { giftCatalogForm, selectedReward } = useSelectRewardContext()

  return (
    <SelectProductCollection
      currentCollectionId={selectedReward?.gift?.productCollectionId}
      currentBudget={selectedReward?.budget}
      onSelectProductCollection={giftCatalogForm.handleSelectProductCollection}
    />
  )
}
