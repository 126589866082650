import { useFinchConnect } from '@tryfinch/react-connect'
import { message } from 'antd'
import { callFunction } from 'api/functionCalls'
import { products } from 'constants/finch'
import { FINCH_CLIENT_ID } from 'constants/keys'
import { toaster } from 'evergreen-ui'
import useIds from './useIds'

export function useFinchConnectOpen() {
  const { orgId } = useIds()

  const [messageApi, contextHolder] = message.useMessage()

  const onSuccess = ({ code }: { code: string }) => {
    if (!code) return

    messageApi.open({
      type: 'loading',
      content: 'Connecting HRIS...',
      duration: 0,
    })

    callFunction('finch-ExchangeAuthCode', { code, orgId })
      .then(() => toaster.success('Successfully connected', { id: code }))
      .catch(() => toaster.warning('Error connecting HRIS. Contact support'))
      .finally(messageApi.destroy)
  }
  const onError = ({ errorMessage }: { errorMessage: string }) =>
    console.error(errorMessage)
  const onClose = () => {
    console.error('User exited Finch Connect')
    toaster.warning('Integration canceled ')
  }

  const { open } = useFinchConnect({
    clientId: FINCH_CLIENT_ID,
    products,
    onSuccess,
    onError,
    onClose,
    sandbox: process.env.NODE_ENV !== 'production',
    manual: true,
  })
  return { open, contextHolder }
}
