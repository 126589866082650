import { LoadingOutlined } from '@ant-design/icons'
import { Alert, Button, Spin } from 'antd'
import { Heading, Pane, Strong, Text } from 'evergreen-ui'
import { useLocation } from 'react-router-dom'
import { numToDollars } from 'utils'

export function OrgSwagSearchConfirmation() {
  const location = useLocation()
  const orderDetails = location.state?.orderDetails

  if (!orderDetails) {
    return (
      <Pane
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} />
      </Pane>
    )
  }

  const {
    product,
    quantity,
    selectedColor,
    selectedImprintMethod,
    selectedImprintLocation,
    pricePerUnit,
    totalPrice,
    paymentMethod,
    invoiceId,
    invoiceNumber,
    invoiceUrl,
  } = orderDetails

  return (
    <Pane display="flex" gap={32} padding={32}>
      <Pane marginBottom={32} display="flex" flexDirection="column" gap={16}>
        <Heading size={900}>Order Confirmation</Heading>

        <Alert
          message="Order Confirmed"
          description="Your order has been successfully placed."
          type="success"
          showIcon
        />

        <Pane display="flex" flexWrap="wrap" gap={16}>
          <Pane
            flex={1}
            minWidth={400}
            border="default"
            padding={24}
            borderRadius={8}
          >
            <Heading size={500} marginBottom={16}>
              Payment Details
            </Heading>

            <Pane display="flex" flexDirection="column" gap={8}>
              <Text>
                <Strong>Payment Method:</Strong> {paymentMethod}
              </Text>
              {paymentMethod === 'Invoice' && invoiceUrl && (
                <Pane key={invoiceId}>
                  <Button type="primary" href={invoiceUrl} target="_blank">
                    View Invoice {invoiceNumber}
                  </Button>
                </Pane>
              )}
              <Text>
                <Strong>Price per Unit:</Strong>{' '}
                {numToDollars(pricePerUnit * 100, 2)}
              </Text>
              <Text>
                <Strong>Subtotal:</Strong> {numToDollars(totalPrice * 100, 2)}
              </Text>
              <Text>
                <Strong>Tax (8.875%):</Strong>{' '}
                {numToDollars(totalPrice * 100 * 0.08875, 2)}
              </Text>
              <Text>
                <Strong>Total:</Strong>{' '}
                {numToDollars(totalPrice * 100 * 1.08875, 2)}
              </Text>
            </Pane>
          </Pane>
          <Pane
            flex={1}
            minWidth={400}
            border="default"
            padding={24}
            borderRadius={8}
          >
            <Heading size={500} marginBottom={16}>
              Product Details
            </Heading>

            <Pane display="flex" flexDirection="column" gap={8}>
              <Text>
                <Strong>Product Name:</Strong> {product?.prName}
              </Text>
              <Text>
                <Strong>Item Number:</Strong> {product?.itemNum}
              </Text>
              <Text>
                <Strong>Selected Color:</Strong> {selectedColor}
              </Text>
              <Text>
                <Strong>Quantity:</Strong> {quantity?.toLocaleString('en-US')}{' '}
                units
              </Text>
            </Pane>
          </Pane>

          <Pane
            flex={1}
            minWidth={400}
            border="default"
            padding={24}
            borderRadius={8}
          >
            <Heading size={500} marginBottom={16}>
              Imprint Details
            </Heading>

            <Pane display="flex" flexDirection="column" gap={8}>
              <Text>
                <Strong>Imprint Method:</Strong> {selectedImprintMethod}
              </Text>
              <Text>
                <Strong>Imprint Location:</Strong> {selectedImprintLocation}
              </Text>
            </Pane>
          </Pane>

          <Pane
            flex={1}
            minWidth={400}
            border="default"
            padding={24}
            borderRadius={8}
          >
            <Heading size={500} marginBottom={16}>
              Additional Information
            </Heading>

            <Pane display="flex" flexDirection="column" gap={8}>
              <Text>
                <Strong>Warehousing Fee:</Strong> TBD based on usage
              </Text>
              <Text>
                <Strong>Shipping Fee:</Strong> TBD based on delivery location
              </Text>
              {product?.prodTime && (
                <Text>
                  <Strong>Estimated Production Time:</Strong> {product.prodTime}
                </Text>
              )}
            </Pane>
          </Pane>
        </Pane>
      </Pane>
    </Pane>
  )
}
