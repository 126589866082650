import { PlusOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import { createNewAction, createNewRule } from 'api/databaseCalls'
import { CREATE_RULE } from 'constants/events'
import { OrgContext } from 'context'
import { toaster } from 'evergreen-ui'
import { ActionType_Enum, Rule } from 'gen/perkup/v1/rules_pb'
import useIds from 'hooks/useIds'
import { useContext } from 'react'
import { NotificationOccassions } from 'types/Notifications'
import { RuleTypes } from 'types/rules'
import { getRuleType, getRuleYear, logEvent } from 'utils'

export function AddRule({ rules }: { rules: Rule[] }) {
  const org = useContext(OrgContext)
  const { orgId, userId } = useIds()
  const ruleYear = getRuleYear({
    rules,
    pathName: window.location.pathname,
  })

  const isBirthday =
    getRuleType({
      pathName: window.location.pathname,
    }) === RuleTypes.birthdays

  const occasion = isBirthday
    ? NotificationOccassions.BIRTHDAY
    : NotificationOccassions.ANNIVERSARY

  // Creates a new default rule with a default action
  const handleClick = async () => {
    // Set rule year field according to current highest rule
    try {
      const rule = await createNewRule({
        orgId,
        occasion,
        year: ruleYear,
        accountId: org.defaultAccountId,
      }) // First, create the rule

      if (!rule) {
        throw new Error('No rule created')
      }

      await createNewAction({
        orgId,
        ruleId: rule.id,
        isBirthday,
        from: org.name,
        year: ruleYear,
        actionType: ActionType_Enum.createProgram,
      }) // Using the rule reference, create an action

      toaster.success('Rule created')
      logEvent(CREATE_RULE, {
        orgId,
        isBirthday,
        from: org.name,
        year: ruleYear,
        actionType: ActionType_Enum.createProgram,
        userId,
      })
    } catch (error) {
      console.error(error)
      toaster.warning('Something went wrong, try again or contact support.')
    }
  }
  return (
    <Button icon={<PlusOutlined />} onClick={handleClick} type="primary">
      Create rule
    </Button>
  )
}
