import {
  OverlayScrollbarsComponent,
  OverlayScrollbarsComponentProps,
} from 'overlayscrollbars-react'

export function PerkScrollbars(
  props: Omit<OverlayScrollbarsComponentProps, 'defer'>
) {
  const { children, options } = props

  return (
    <OverlayScrollbarsComponent
      element="div"
      defer
      options={{
        ...options,
        scrollbars: { autoHide: 'leave', autoHideDelay: 100 },
      }}
      {...props}
    >
      {children}
    </OverlayScrollbarsComponent>
  )
}
