import { Tag } from 'antd'
import Skeleton from 'antd/es/skeleton'
import * as HOSTS from 'constants/hosts'
import { Button, Heading, Pane, Strong, Text } from 'evergreen-ui'
import { numToDollars } from 'utils'
import useBillingUrl from '../hooks/useBillingUrl'
import useUpcomingInvoice from '../hooks/useUpcomingInvoice'

export default function BillingSubscription() {
  const { upcomingInvoice, isLoading: isInvoiceLoading } = useUpcomingInvoice()
  const { billingUrl, isLoading: isUrlLoading } = useBillingUrl()

  if (isInvoiceLoading) return <Skeleton active paragraph={{ rows: 4 }} />

  if (!upcomingInvoice) {
    return <Text>No upcoming invoices</Text>
  }

  const startingBalance = upcomingInvoice.starting_balance

  // Ending balance might be null if an invoice has not been finalized yet.
  const endingBalance =
    upcomingInvoice.ending_balance || Number.MAX_SAFE_INTEGER // Fallback to big int, so that appliedBalance is less than 0

  const appliedBalance = startingBalance - endingBalance
  const displayAppliedBalance = appliedBalance > 0

  const nextPaymentAttempt = upcomingInvoice.next_payment_attempt

  const isProdHost = window.location.hostname === HOSTS.PROD_HOSTNAME
  const prodTopUp = isProdHost ? 'prod_ImYhAz36zZmEUU' : 'prod_Il5kVitmHIHokx'

  return (
    <Pane>
      <Pane display="flex" alignItems="center" marginBottom={16}>
        <Heading size={600} marginRight={16}>
          Current plan
        </Heading>
        <Tag color="green">Active</Tag>
      </Pane>

      {upcomingInvoice.lines.data.map(invoiceItem => {
        if (!invoiceItem.price || !invoiceItem.plan?.amount) return null

        const name =
          invoiceItem.price.product === prodTopUp
            ? 'Top-up funds'
            : 'Platform fees (per member)'

        const quantity = invoiceItem.amount / invoiceItem.plan.amount
        return (
          <Pane
            key={invoiceItem.id}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            marginBottom={8}
          >
            <Pane display="flex" flexDirection="column">
              <Text>{name}</Text>
              <Text color="muted" size={300}>
                Qty {quantity}
              </Text>
            </Pane>
            <Text>{numToDollars(invoiceItem.amount)}</Text>
          </Pane>
        )
      })}

      <Pane
        display="flex"
        alignItems="flex-end"
        justifyContent="space-between"
        marginTop={16}
      >
        <Button
          is="a"
          href={billingUrl}
          appearance="minimal"
          disabled={!billingUrl}
          isLoading={isUrlLoading}
        >
          Change subscription
        </Button>

        <Pane
          display="grid"
          gridTemplateColumns="max-content auto"
          alignItems="center"
          rowGap={8}
          columnGap={48}
          textAlign="right"
        >
          {displayAppliedBalance && (
            <>
              <Text>Total</Text>
              <Text>{numToDollars(upcomingInvoice.total)}</Text>

              <Text color="muted">Applied balance</Text>
              <Text>{numToDollars(appliedBalance)}</Text>
            </>
          )}

          {nextPaymentAttempt && (
            <>
              <Text>
                Amount due on{' '}
                {new Date(nextPaymentAttempt * 1000).toLocaleDateString(
                  'en-us',
                  {
                    month: 'short',
                    day: 'numeric',
                  }
                )}
              </Text>
              <Pane flexBasis={64} flexShrink={0} flexGrow={0}>
                <Strong>{numToDollars(upcomingInvoice.amount_due)}</Strong>
              </Pane>
            </>
          )}
        </Pane>
      </Pane>
    </Pane>
  )
}
