import { GetTransactionById } from 'api/databaseCalls'
import { TransactionDrawer } from 'components'
import * as ROUTES from 'constants/routes'
import { OrgTransaction } from 'gen/perkup/v1/organization_pb'
import useIds from 'hooks/useIds'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

export default function Transaction() {
  const navigate = useNavigate()
  const { id } = useParams()
  const { orgId } = useIds()

  const [transaction, setTransaction] = useState<OrgTransaction>()

  useEffect(() => {
    if (id) {
      GetTransactionById({ orgId, transactionId: id }).then(setTransaction)
    }
  }, [id, orgId])

  if (!transaction) return null

  return (
    <TransactionDrawer
      key={id}
      transaction={transaction}
      onCloseComplete={() => navigate(ROUTES.TRANSACTIONS)}
    />
  )
}
