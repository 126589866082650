import { Alert, Button } from 'antd'
import { IndividualContext, OrgContext } from 'context'
import { useContext, useState } from 'react'
import { Link } from 'react-router-dom'
import { handleConnectOnboarding } from 'services'

export function ConnectAlert() {
  const org = useContext(OrgContext)
  const individual = useContext(IndividualContext)
  const [isLoading, setIsLoading] = useState(false)
  const [url, setUrl] = useState<string>()

  return (
    <Alert
      message="Additional information required"
      description="Stripe has requested us to refresh our KYC/KYB data on all our US credit card customers."
      showIcon
      type="warning"
      action={
        url ? (
          <Link to={url} target="_blank" rel="noopener noreferrer">
            Open form
          </Link>
        ) : (
          <Button
            type="primary"
            loading={isLoading}
            onClick={() => {
              setIsLoading(true)
              handleConnectOnboarding({
                stripeConnectAccountId: org.stripeConnectAccountId,
                individualEmail: individual.email,
                orgId: org.id,
              })
                .then(setUrl)
                .finally(() => setIsLoading(false))
            }}
          >
            Complete form
          </Button>
        )
      }
      style={{ marginTop: 16 }}
    />
  )
}
