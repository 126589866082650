import { Avatar, AvatarProps, Tooltip } from 'antd'
import { buildFullName, getInitials } from 'utils'

export function PerkAvatar({
  firstName,
  lastName,
  email,
  size,
  profilePicture,
}: {
  firstName: string | undefined
  lastName: string | undefined
  email: string | undefined
  profilePicture: string | undefined
  size?: AvatarProps['size']
}) {
  // This just means that the individual is a bot
  if (!firstName && !lastName && !profilePicture && !email) {
    return (
      <Tooltip title="Automation">
        <Avatar size={size}>🤖</Avatar>
      </Tooltip>
    )
  }

  const fullName = buildFullName({ firstName, lastName })

  const infoToDisplay = fullName || email

  return (
    <Tooltip title={infoToDisplay}>
      <Avatar
        src={profilePicture}
        size={size}
        className="bg-primary/10 text-primary-foreground"
      >
        {infoToDisplay ? getInitials(infoToDisplay) : '?'}
      </Avatar>
    </Tooltip>
  )
}
