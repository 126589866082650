import { PerkupWarehouse } from 'gen/shopify/inventory_item_pb'
import { isProduction } from './keys'

export const warehouses: Pick<PerkupWarehouse, 'id' | 'name'>[] = isProduction
  ? [
      { id: 'US1', name: 'CO, US Warehouse' }, // TopShelf
      { id: 'US2', name: 'TX, US Warehouse' }, // ShipMonk (Many)
      { id: 'US3', name: 'Offbeat, US Warehouse' }, // Offbeat
      { id: 'US4', name: 'NC, US Warehouse' }, // Printful (Many)
      { id: 'IN1', name: 'Chennai, India Warehouse' }, // Printrove
      { id: 'BR1', name: 'São Paulo Warehouse' }, // Yoobe
      { id: 'IR1', name: 'Ireland Warehouse' }, // SwagEU
    ]
  : [
      { id: 'DEV1', name: 'Test Default Warehouse' }, // Test Default
      { id: 'DEV2', name: 'Test Printful Warehouse' }, // Test Printful
    ]
