import { CheckOutlined, SettingOutlined } from '@ant-design/icons'
import { Button, Checkbox, Flex, Popover, Switch } from 'antd'
import { CONVERTIBLE_TO_EXTRAS } from 'constants/programs'
import { Heading, Text } from 'evergreen-ui'
import { ReactNode } from 'react'
import { ALL_CONVERTABLE_TO_OPTIONS, ConvertableTo } from 'types/Gift'

function ConvertableToOptionsSelect({ checkboxes }: { checkboxes: ReactNode }) {
  return (
    <Flex vertical gap={8}>
      <Heading fontSize={14}>Swappable for:</Heading>
      <Flex vertical gap={10}>
        {checkboxes}
      </Flex>
    </Flex>
  )
}

export function ConvertableToOptions({
  currentConvertableTo = [],
  onConvertableToChange,
  variant = 'switch',
}: {
  currentConvertableTo: string[] | undefined
  onConvertableToChange: (convertableTo: string[]) => void
  variant?: 'select' | 'switch'
}) {
  const checkboxes = ALL_CONVERTABLE_TO_OPTIONS.map(convertableOption => (
    <Checkbox
      key={convertableOption}
      checked={currentConvertableTo?.includes(convertableOption)}
      onChange={e => {
        const updatedConvertableTo = e.target.checked
          ? [...currentConvertableTo, convertableOption]
          : currentConvertableTo.filter(option => option !== convertableOption)
        onConvertableToChange(updatedConvertableTo)
      }}
    >
      {CONVERTIBLE_TO_EXTRAS[convertableOption].name}
    </Checkbox>
  ))

  if (variant === 'select') {
    return <ConvertableToOptionsSelect checkboxes={checkboxes} />
  }

  return (
    <Flex align="center">
      <Text marginRight={8}>Swappable</Text>
      <Switch
        checked={currentConvertableTo.length > 0}
        checkedChildren={<CheckOutlined />}
        onChange={checked =>
          onConvertableToChange(checked ? [ConvertableTo.gift] : [])
        }
      />
      <Popover
        content={
          <Flex vertical gap={8}>
            {checkboxes}
          </Flex>
        }
        trigger="hover"
      >
        <Button type="text" icon={<SettingOutlined />} />
      </Popover>
    </Flex>
  )
}
