import { PlusOutlined } from '@ant-design/icons'
import { Flex, Skeleton, Tag, TagProps, Tooltip } from 'antd'
import Table, { ColumnsType } from 'antd/es/table'
import {
  ListenToOrgAccounts,
  updateAccountPermissions,
} from 'api/databaseCalls'
import {
  AccountTopUpButton,
  BrandedTag,
  EditPermissionsButton,
  PageHeader,
  withOrgSidebar,
} from 'components'
import { TransferFundsButton } from 'components/Buttons/TransferFundsButton'
import { BalanceText } from 'components/Typography/BalanceText'
import {
  AccountBalanceLabels,
  adminAccountPermissionsOptions,
  individualAccountPermissionsOptions,
  managerAccountPermissionsOptions,
} from 'constants/accounts'
import { tablePaginationSettings } from 'constants/antdesign'
import { NUMBER_RED } from 'constants/colors'
import { ORG_ACCOUNT } from 'constants/routes'
import { IndividualContext, OrgContext } from 'context'
import { Pane, Text } from 'evergreen-ui'
import { Account, Account_Permission } from 'gen/perkup/v1/account_pb'
import { useCanCreateAccountInvoices } from 'hooks'
import { capitalize } from 'lodash-es'
import NoMatch404 from 'pages/NoMatch404'
import { useContext, useEffect, useState } from 'react'
import { Link, Route, Routes } from 'react-router-dom'
import { Permissions } from 'types/Permissions'
import { numToDollars } from 'utils'
import { getTopUpDefault } from 'utils/accounts'
import { ACCOUNT_LEVELS, getHighestPermission } from 'utils/permissions'
import OrgAccount from './OrgAccount'
import { CreateAccountButton } from './components/CreateAccountButton'
import { DeleteAccountButton } from './components/DeleteAccountButton'

interface DataType {
  key: string
  name: string
  orgId: string
  balance: number
  accountId: string
  isDefaultAccount: boolean
  defaultPermissions: Record<string, Account_Permission>
  highestAccountPermission: Account_Permission | undefined
  outstanding: number
  unallocated: number
  canCreateInvoices: boolean
}

const permissionBadgeStyle: Record<string, TagProps['color']> = {
  full: 'green',
  send: 'blue',
  view: 'default',
}

const columns: ColumnsType<DataType> = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    render: (name, record) => (
      <Pane display="flex" gap={16} alignItems="center">
        <Link to={`${record.accountId}`}>{name}</Link>
        {record.isDefaultAccount && <BrandedTag>Default</BrandedTag>}
      </Pane>
    ),
    sorter: (a, b) => a.name.localeCompare(b.name),
  },
  {
    title: 'Current Balance',
    key: 'balance',
    dataIndex: 'balance',
    render: (balance, record) => {
      const {
        canCreateInvoices,
        highestAccountPermission,
        unallocated: unallocatedFunds,
      } = record
      const hasViewAccessOnly =
        highestAccountPermission === Account_Permission.view
      const defaultTopup = getTopUpDefault({
        unallocatedFunds,
      })
      return (
        <Pane display="flex" gap={16} alignItems="center">
          {balance > 0 && (
            <Pane display="flex" alignItems="center" gap={8}>
              <BalanceText amount={balance} size={500} />
              {!hasViewAccessOnly && canCreateInvoices && (
                <AccountTopUpButton
                  name="Add funds"
                  useIconButton
                  accountId={record.accountId}
                  defaultAmount={defaultTopup}
                />
              )}
            </Pane>
          )}
          {balance <= 0 && (
            <Flex>
              {canCreateInvoices && !hasViewAccessOnly ? (
                <AccountTopUpButton
                  name="Add funds"
                  type="default"
                  accountId={record.accountId}
                  size="small"
                  icon={<PlusOutlined />}
                  defaultAmount={defaultTopup}
                />
              ) : (
                <Text>{numToDollars(balance, 2)}</Text>
              )}
            </Flex>
          )}
        </Pane>
      )
    },
    sorter: (a, b) => a.balance - b.balance,
    defaultSortOrder: 'descend',
  },
  {
    title: AccountBalanceLabels.UNREDEEMED_REWARDS,
    dataIndex: 'outstanding',
    key: 'outstanding',
    render: outstanding => {
      return <BalanceText truncate amount={outstanding} />
    },
    sorter: (a, b) => a.outstanding - b.outstanding,
  },
  {
    title: 'Exposure',
    dataIndex: 'unallocated',
    key: 'unallocated',
    render: unallocated => {
      const balanceColor = unallocated < 0 ? NUMBER_RED : ''
      return <BalanceText color={balanceColor} truncate amount={unallocated} />
    },
    sorter: (a, b) => a.unallocated - b.unallocated,
  },
  {
    title: 'Access',
    dataIndex: 'highestAccountPermission',
    key: 'access',
    render: highestAccountPermission => {
      const permission = Account_Permission[highestAccountPermission]
      const color = permissionBadgeStyle[permission]
      return (
        <Pane display="flex" gap={16} alignItems="center">
          <Tooltip title={`You have ${permission} access`}>
            <Tag color={color}>{capitalize(permission)}</Tag>
          </Tooltip>
        </Pane>
      )
    },
    sorter: (a, b) =>
      (a.highestAccountPermission || 0) - (b.highestAccountPermission || 0),
  },
  {
    title: '',
    dataIndex: 'name',
    key: 'edit',
    render: (name, record) => {
      const hasFullAccess =
        record.highestAccountPermission === Account_Permission.full

      if (record.isDefaultAccount || !hasFullAccess) return null

      const handleUpdatePermissions = (newPermissions: Permissions) => {
        updateAccountPermissions({
          orgId: record.orgId,
          accountId: record.accountId,
          permissions: newPermissions as Record<string, Account_Permission>,
        })
      }

      return (
        <Pane display="flex">
          <EditPermissionsButton
            modalTitle={`${name} permissions`}
            currentPermissions={record.defaultPermissions}
            onPermissionsChange={handleUpdatePermissions}
            adminPermissionOptions={adminAccountPermissionsOptions}
            managerPermissionOptions={managerAccountPermissionsOptions}
            individualPermissionOptions={individualAccountPermissionsOptions}
          />
          <DeleteAccountButton accountId={record.accountId} />
        </Pane>
      )
    },
  },
]

function OrgAccounts() {
  const org = useContext(OrgContext)
  const orgId = org?.id
  const currentIndividual = useContext(IndividualContext)
  const [accounts, setAccounts] = useState<Account[]>()
  const [isLoading, setIsLoading] = useState(false)

  const canCreateInvoices = useCanCreateAccountInvoices()

  useEffect(() => {
    if (!orgId) return undefined
    return ListenToOrgAccounts({
      orgId,
      cb: setAccounts,
      setIsLoading,
    })
  }, [orgId])

  const currentIndividualRole = currentIndividual?.role
  const currentIndividualId = currentIndividual?.id

  const data = accounts
    ?.filter(account => {
      const { permissions } = account
      if (!currentIndividualId) return false

      const highestAccountPermission = getHighestPermission(ACCOUNT_LEVELS)({
        role: currentIndividualRole,
        individualId: currentIndividualId,
        permissions,
      })

      return (
        highestAccountPermission !== Account_Permission.PERMISSION_UNSPECIFIED
      )
    })
    .sort(acc => (acc.id === org.defaultAccountId ? -1 : 1))
    .map(a => {
      const highestAccountPermission = getHighestPermission(ACCOUNT_LEVELS)({
        role: currentIndividualRole,
        individualId: currentIndividualId,
        permissions: a.permissions,
      })

      const outstandingFunds = Number(a.programReserved)

      const unallocatedFunds = Number(a.balance) - Number(outstandingFunds)

      return {
        key: a.id,
        name: a.name,
        orgId,
        balance: Number(a.balance),
        accountId: a.id,
        isDefaultAccount: a.id === org.defaultAccountId,
        defaultPermissions: a.permissions,
        highestAccountPermission,
        outstanding: outstandingFunds,
        unallocated: unallocatedFunds,
        canCreateInvoices,
      }
    })

  return (
    <Pane height="100%">
      <Pane marginBottom={32}>
        <PageHeader
          title="Balances"
          buttonsToRenderOnLeft={
            <Pane display="flex" alignItems="center" gap={16}>
              <CreateAccountButton />
              <TransferFundsButton />
            </Pane>
          }
          description="You're only charged for redeemed gifts."
          vlogLink="https://www.loom.com/embed/334594da71ce4ad9bdbeefe37b6d4171?sid=4253ed39-f655-4dae-ba33-564f627504c3"
        />
      </Pane>
      {isLoading && (
        <Pane>
          <Skeleton active title={false} paragraph={{ rows: 6 }} />
        </Pane>
      )}

      {!isLoading && data && data.length === 0 && (
        <Pane
          display="flex"
          flexDirection="column"
          height="100%"
          justifyContent="center"
          alignItems="center"
          gap={16}
        >
          <Text size={600}>Create an account to start sending rewards</Text>
          <CreateAccountButton />
        </Pane>
      )}

      {!isLoading && data && data.length > 0 && (
        <Table
          columns={columns}
          dataSource={data}
          pagination={tablePaginationSettings}
        />
      )}
    </Pane>
  )
}

function OrgAccountsRouter() {
  return (
    <Routes>
      <Route index element={<OrgAccounts />} />
      <Route path={`${ORG_ACCOUNT}/*`} element={<OrgAccount />} />
      <Route path="*" element={<NoMatch404 hasFooter={false} />} />
    </Routes>
  )
}

export default withOrgSidebar(OrgAccountsRouter)
