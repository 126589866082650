import { useEffect, useState } from 'react'
import { isMobile } from 'react-device-detect'

export function useIsImagePortrait({ imageUrl }: { imageUrl?: string }) {
  const [isImagePortrait, setIsImagePortrait] = useState<boolean>()

  const handleImageLoad = (event: Event) => {
    const img = event.target as HTMLImageElement
    const isImagePortrait = img.naturalHeight / img.naturalWidth >= 0.75

    if (!isMobile) setIsImagePortrait(isImagePortrait)
  }

  useEffect(() => {
    const img = new Image()
    img.src = imageUrl || ''
    img.addEventListener('load', handleImageLoad)

    return () => {
      img.removeEventListener('load', handleImageLoad)
    }
  }, [imageUrl])

  return { isImagePortrait }
}
