import { Button, Image } from 'antd'
import { noOrgAsset } from 'assets'
import { CountrySelection, UserAvatar } from 'components'
import * as EVENTS from 'constants/events'
import { PERKUP_PRIMARY_LOGO } from 'constants/logos'
import * as ROUTES from 'constants/routes'
import { UserContext } from 'context'
import { Heading, Pane, Text } from 'evergreen-ui'
import { getAuth, signOut } from 'firebase/auth'
import { useContext } from 'react'
import { useNavigate } from 'react-router'
import { buildFullName, logEvent } from 'utils'

function NoOrg() {
  const navigate = useNavigate()
  const user = useContext(UserContext)
  const userId = user?.id
  const email = user?.profile?.email

  const emailAssociationWarning = `${email} isn't associated with an active organization`
  return (
    <Pane padding={32}>
      <Pane display="flex" justifyContent="space-between" marginBottom={120}>
        <Image
          preview={false}
          src={PERKUP_PRIMARY_LOGO}
          style={{ width: 128 }}
        />
        <Pane display="flex" gap={24} alignItems="center">
          <Pane paddingRight={24} borderRight="muted">
            <CountrySelection user={user} />
          </Pane>
          {email && (
            <UserAvatar
              email={email}
              name={buildFullName({
                firstName: user?.profile?.firstName,
                lastName: user?.profile?.lastName,
              })}
              pfpSize={40}
            />
          )}
        </Pane>
      </Pane>
      <Pane
        display="flex"
        flexDirection="column"
        gap={48}
        alignItems="center"
        justifyContent="center"
        height="100%"
      >
        <Image preview={false} src={noOrgAsset} style={{ maxWidth: 440 }} />
        <Heading paddingX={32} textAlign="center" size={600}>
          {emailAssociationWarning}
        </Heading>
        <Text textAlign="center">
          Ask for access, or switch to an account with access.
        </Text>
        <Button
          onClick={async () => {
            logEvent(EVENTS.SIGNED_OUT, { userId, orgId: undefined })

            await signOut(getAuth())
            navigate(ROUTES.SIGN_IN)
            window.location.reload()
          }}
          style={{ width: 'min-content' }}
        >
          Sign out
        </Button>
      </Pane>
    </Pane>
  )
}

export default NoOrg
