import { captureException } from '@sentry/react'
import { Input } from 'antd'
import { OrgContext } from 'context'
import { Heading, Pane, toaster } from 'evergreen-ui'
import { doc, getFirestore, setDoc } from 'firebase/firestore'
import { Organization } from 'gen/perkup/v1/organization_pb'
import isEmpty from 'lodash-es/isEmpty'
import React, { useContext, useState } from 'react'
import { toSentry } from 'utils'
import { converter } from 'utils/firestore'

export function ChangeCompanyTitle() {
  const org = useContext(OrgContext)
  const orgName = org.name

  const [newName, setNewName] = useState<string>(orgName)
  const disableSubmit = newName === orgName || isEmpty(newName)

  const submitNewName = () => {
    if (!disableSubmit) {
      const db = getFirestore()
      const docRef = doc(db, `organizations/${org.id}`).withConverter(
        converter(Organization)
      )
      setDoc(
        docRef,
        {
          name: newName,
        },
        { merge: true }
      ).catch(error => {
        console.error(error)
        captureException(toSentry(error), {
          contexts: {
            updateCompanyName: { newName },
          },
        })
        toaster.danger('Failed to update company name')
      })
      toaster.success('Successfully updated company name')
    }
  }

  const handleChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewName(event.currentTarget.value)
  }

  return (
    <Pane maxWidth={320}>
      <Heading marginBottom={8}>Company name</Heading>
      <Input
        value={newName}
        onChange={handleChangeName}
        onBlur={submitNewName}
        onPressEnter={submitNewName}
      />
    </Pane>
  )
}
