// @generated by protoc-gen-connect-es v1.1.4 with parameter "target=ts"
// @generated from file ledger/account.proto (package ledger, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { AdjustFundsRequest, TransferFundsRequest } from "./account_pb.js";
import { Empty } from "../google/protobuf/empty_pb.js";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service ledger.AccountTransfer
 */
export const AccountTransfer = {
  typeName: "ledger.AccountTransfer",
  methods: {
    /**
     * @generated from rpc ledger.AccountTransfer.TransferFunds
     */
    transferFunds: {
      name: "TransferFunds",
      I: TransferFundsRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc ledger.AccountTransfer.AdjustFunds
     */
    adjustFunds: {
      name: "AdjustFunds",
      I: AdjustFundsRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
  }
} as const;

