import { Badge, Image } from 'antd'
import { ALGOLIA_PRODUCT_VARIANTS_INDEX } from 'constants/algolia'
import { Pane } from 'evergreen-ui'
import useIds from 'hooks/useIds'
import { AlgoliaProductClicked } from 'utils/Algolia'

export function GiftCard({
  onClick,
  productId,
  productVariantId,
  productImage,
  eventProps,
  position,
  queryID,
  cardWidth,
  showSelectedRibbon = false,
  selectedRibbonText = 'Selected',
}: {
  onClick?: () => void
  productId: string
  productVariantId: string
  productImage: string | undefined
  position?: number
  queryID?: string // Algolia queryID
  eventProps?: any
  cardWidth?: number | string
  showSelectedRibbon?: boolean
  selectedRibbonText?: string
}) {
  const { orgId, userId } = useIds()

  const handleClick = () => {
    if (!onClick) return
    AlgoliaProductClicked({
      orgId,
      userId,
      userToken: userId,
      productId,
      productVariantId,
      index: ALGOLIA_PRODUCT_VARIANTS_INDEX,
      eventType: 'click',
      queryID,
      objectIDs: [productVariantId],
      positions: [position],
      ...eventProps,
    })

    onClick()
  }

  return (
    <Pane
      hoverElevation={!onClick ? undefined : 1}
      cursor={!onClick ? undefined : 'pointer'}
      borderRadius={8}
      width={cardWidth}
      position="relative"
    >
      {showSelectedRibbon && (
        <Badge.Ribbon
          text={selectedRibbonText}
          style={{
            position: 'absolute',
            zIndex: 1,
            top: 8,
            right: -8,
          }}
        />
      )}
      <Image
        style={{ borderRadius: 8 }}
        onClick={handleClick}
        src={productImage}
        preview={false}
      />
    </Pane>
  )
}
