import { CheckCircleOutlined } from '@ant-design/icons'
import { Tag } from 'antd'
import { CSSProperties } from 'react'

// Use wherever we want to display a badge for a product. Can rank badges by importance.
export function PrepaidBadge({ margin }: { margin?: CSSProperties['margin'] }) {
  return (
    <Tag
      style={{ width: 'fit-content', margin }}
      icon={<CheckCircleOutlined />}
      color="success"
    >
      Prepaid
    </Tag>
  )
}
