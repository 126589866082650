import { toaster } from 'evergreen-ui'
import { useCallback, useEffect, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import { Basic } from 'unsplash-js/dist/methods/photos/types'
import { unsplashApi } from 'utils'

export function useInfiniteUnsplashedScroll({ query }: { query: string }) {
  const [isLoadingInitial, setIsLoadingInitial] = useState(true)
  const [isLoadingMore, setIsLoadingMore] = useState(false)
  const [photos, setPhotos] = useState<Basic[]>([])
  const [index, setIndex] = useState<number>(1)

  const searchUnsplash = useCallback(() => {
    setIsLoadingMore(true)
    unsplashApi.search
      .getPhotos({ query, page: index, perPage: 20, orientation: 'landscape' })
      .then(({ response }) => {
        if (response?.results?.length) {
          setPhotos(p => [...p, ...response.results])
          setIndex(index + 1)
        }
        setIsLoadingMore(false)
      })
      .catch(() => {
        toaster.danger('Error searching photos')
      })
  }, [setIndex, setPhotos, index, query])

  // initial fetch
  useEffect(() => {
    setIsLoadingInitial(true)
    setPhotos([])

    unsplashApi.search
      .getPhotos({ query, page: 1, perPage: 20, orientation: 'landscape' })
      .then(({ response }) => {
        if (response?.results?.length) {
          setPhotos([...response.results])
          setIndex(2)
        }
        setIsLoadingInitial(false)
      })
      .catch(() => {
        toaster.danger('Error searching photos')
      })
  }, [query])

  const { ref: lastPhotoRef } = useInView({
    onChange: inView => {
      if (inView) {
        setIsLoadingMore(true)
        searchUnsplash()
        setIsLoadingMore(false)
      }
    },
  })
  return { photos, lastPhotoRef, isLoadingInitial, isLoadingMore }
}
