import { SearchOutlined } from '@ant-design/icons'
import { Breadcrumb, Flex } from 'antd'
import {
  AddPersonalFundsButton,
  BackIconButton,
  BoxShadowImage,
  PerkEmpty,
  PerkLoader,
} from 'components'
import { NUMBER_GREEN } from 'constants/colors'
import { isProduction } from 'constants/keys'
import { COLLECTION } from 'constants/params'
import { DEFAULT_ROUTES } from 'constants/routes'
import {
  CountryContext,
  ExchangeRateContext,
  OrgContext,
  SwagCollectionIdsContext,
  UserContext,
} from 'context'
import { Heading, Text } from 'evergreen-ui'
import { AlgoliaBrowseProducts } from 'features'
import { ProductVariant } from 'gen/perkup/v1/product_variant_pb'
import { useCollectionById, useOrgUserSwagBalance } from 'hooks'
import { useContext } from 'react'
import { isMobile } from 'react-device-detect'
import { Helmet } from 'react-helmet-async'
import { useNavigate } from 'react-router'
import { Link, useParams } from 'react-router-dom'
import { numToDollars } from 'utils'
import { getAlgoliaOrgSwagFilters } from 'utils/Algolia'

export function SwagStoreCollectionPage() {
  const org = useContext(OrgContext)
  const swagCollections = useContext(SwagCollectionIdsContext)
  const exchangeRate = useContext(ExchangeRateContext)
  const user = useContext(UserContext)
  const country = useContext(CountryContext)

  const { collectionId } = useParams()

  const { collection, hasLoaded: hasLoadedCollection } = useCollectionById({
    id: collectionId,
  })

  const navigate = useNavigate()

  const { orgUserSwagBalance } = useOrgUserSwagBalance()

  if (!hasLoadedCollection) return <PerkLoader />

  if (!collection) {
    return (
      <PerkEmpty
        header="Swag store not found"
        description="We couldn't find the swag store associated with this id."
        iconNode={<SearchOutlined style={{ fontSize: 42 }} />}
        ctaProps={{
          children: 'Go home',
          onClick: () => navigate(DEFAULT_ROUTES.CARD.ROOT),
          type: 'primary',
        }}
      />
    )
  }

  const countryFilter =
    isProduction && country ? `shippingCountries:${country.iso3}` : undefined

  const searchFilter = `${getAlgoliaOrgSwagFilters({
    orgId: org.id,
  })} AND collectionIds:${collection.id}${countryFilter ? ` AND ${countryFilter}` : ''}`

  const handleProductCardClick = (pv: ProductVariant) => {
    navigate(`/swag/product/${pv.productId}?${COLLECTION}=${collection.id}`)
  }

  return (
    <>
      <Helmet>
        <title>{collection?.name}</title>
      </Helmet>

      <Flex vertical gap={16} style={{ width: '100%' }}>
        <Flex align="center" gap={8}>
          <BackIconButton
            to={
              swagCollections.length > 1
                ? DEFAULT_ROUTES.SWAG.ROOT
                : DEFAULT_ROUTES.CARD.ROOT
            }
          />
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to={DEFAULT_ROUTES.CARD.ROOT}>Home</Link>
            </Breadcrumb.Item>
            {swagCollections.length > 1 && (
              <Breadcrumb.Item>
                <Link to={DEFAULT_ROUTES.SWAG.ROOT}>Collections</Link>
              </Breadcrumb.Item>
            )}
            <Breadcrumb.Item>{collection?.name}</Breadcrumb.Item>
          </Breadcrumb>
        </Flex>

        <Flex
          gap={16}
          align={isMobile ? undefined : 'center'}
          style={{
            marginTop: 16,
            flexDirection: isMobile ? 'column' : 'row-reverse',
          }}
        >
          <Flex flex={1}>
            {collection?.imageUrl && (
              <BoxShadowImage
                src={collection.imageUrl}
                sizes="(max-width: 1540px) 50vw, 712px"
              />
            )}
          </Flex>
          <Flex vertical gap={16} flex={1}>
            <Heading size={isMobile ? 800 : 900}>{collection?.name}</Heading>
            <Text>{collection?.description}</Text>
          </Flex>
        </Flex>

        <Flex vertical gap={8}>
          <Flex gap={8}>
            <Heading>Balance:</Heading>
            <Heading color={NUMBER_GREEN}>
              {numToDollars(
                orgUserSwagBalance * exchangeRate,
                2,
                false,
                user.displayCurrency || org?.displayCurrency
              )}
            </Heading>
            <AddPersonalFundsButton asIcon />
          </Flex>

          <AlgoliaBrowseProducts>
            <AlgoliaBrowseProducts.Old
              onProductCardClick={handleProductCardClick}
              withPriceFilter
              withProductAmounts
              withLocalAmounts
              menuFilters={['productType']}
              searchFilter={searchFilter}
              withCountryFilter={!countryFilter}
              filterUiProps={{
                priceFilterPrefix: '',
              }}
              stickyNavTopAmount={60}
              showCustomizableBadge
              allowCustomization
            />
          </AlgoliaBrowseProducts>
        </Flex>
      </Flex>
    </>
  )
}
