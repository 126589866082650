import integrations from 'constants/integrations'
import { Heading, Image, Pane } from 'evergreen-ui'

export function IntegrationLogo({
  payroll_provider_id: payrollProvider,
}: {
  payroll_provider_id: string
}) {
  if (!payrollProvider) return null

  const integrationDetails = integrations.filter(
    i => i.name === payrollProvider
  )[0]

  return (
    <Pane display="flex" style={{ maxHeight: 40, maxWidth: 128 }}>
      {integrationDetails?.logoUrl ? (
        <Image
          src={integrationDetails?.logoUrl}
          alt={payrollProvider}
          maxWidth="100%"
          maxHeight="inherit"
        />
      ) : (
        <Heading>{payrollProvider}</Heading>
      )}
    </Pane>
  )
}
