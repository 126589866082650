import { ArrowLeftOutlined, CloseOutlined } from '@ant-design/icons'
import { Button, StepProps, Steps } from 'antd'
import { defaultButtonStyles } from 'constants/antdesign'
import { Pane } from 'evergreen-ui'

export interface UiStepProps {
  title: string
  path: string
  step: number
}

export function StepsBar({
  currentStep,
  stepItems,
  handleClose,
  handleGoBack,
  showSteps,
}: {
  currentStep: number
  stepItems: StepProps[]
  handleClose: () => void
  handleGoBack: () => void
  showSteps: boolean
}) {
  return (
    <Pane
      width="100%"
      display="flex"
      alignItems="start"
      justifyContent="space-between"
      gap={24}
    >
      <Button
        icon={<ArrowLeftOutlined />}
        style={defaultButtonStyles}
        onClick={handleGoBack}
        type="text"
      />
      {showSteps && (
        <Pane width={900} userSelect="none">
          <Steps current={currentStep} items={stepItems} size="small" />
        </Pane>
      )}
      <Button
        icon={<CloseOutlined />}
        style={defaultButtonStyles}
        onClick={handleClose}
        type="text"
      />
    </Pane>
  )
}
