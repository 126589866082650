import { MinusCircleOutlined } from '@ant-design/icons'
import { Button, Flex, Popover } from 'antd'
import { NUMBER_GREEN } from 'constants/colors'
import { Text } from 'evergreen-ui'
import { ProductVariant } from 'gen/perkup/v1/product_variant_pb'
import { useDefaultOrgColors } from 'hooks'
import { makePlural, numToDollars } from 'utils'
import {
  getProductVariantPriceRange,
  getProductVariantProductImage,
} from 'utils/productVariant'
import ProductThumbnail from './product-thumbnail'

export function ActiveProductsPopover({
  productVariants,
  onRemoveProductVariant,
}: {
  productVariants: ProductVariant[]
  onRemoveProductVariant: (productVariant: ProductVariant) => void
}) {
  const { defaultColor } = useDefaultOrgColors()

  if (productVariants.length === 0) return null

  if (productVariants.length === 1) {
    const productVariant = productVariants[0]

    return (
      <ProductThumbnail>
        <ProductThumbnail.Image
          imageUrl={getProductVariantProductImage(productVariant)}
        />
        <ProductThumbnail.Name>
          {productVariant.productName}
        </ProductThumbnail.Name>
        <ProductThumbnail.Price price={productVariant.amount} />
        <ProductThumbnail.RemoveButton
          icon={<MinusCircleOutlined />}
          onClick={() => onRemoveProductVariant(productVariant)}
          title="Remove product"
        />
      </ProductThumbnail>
    )
  }

  const { minPrice, maxPrice } = getProductVariantPriceRange(productVariants)

  return (
    <Popover
      content={
        <Flex vertical gap={8}>
          {productVariants.map(productVariant => (
            <ProductThumbnail key={productVariant.id}>
              <ProductThumbnail.Image
                imageUrl={getProductVariantProductImage(productVariant)}
              />
              <Flex flex={1}>
                <ProductThumbnail.Name>
                  {productVariant.productName}
                </ProductThumbnail.Name>
              </Flex>
              <ProductThumbnail.Price price={productVariant.amount} />
              <ProductThumbnail.RemoveButton
                icon={<MinusCircleOutlined />}
                onClick={() => onRemoveProductVariant(productVariant)}
                title="Remove product"
              />
            </ProductThumbnail>
          ))}
        </Flex>
      }
      trigger="hover"
    >
      <Button>
        <Text color={defaultColor}>
          {makePlural('product', productVariants.length, true)} selected{' '}
        </Text>

        <Text color={NUMBER_GREEN} marginLeft={4}>
          {numToDollars(minPrice)} - {numToDollars(maxPrice)}
        </Text>
      </Button>
    </Popover>
  )
}
