import { FetchTinyBirdDataByOrgId } from 'api/tinybirdCalls/reads'
import { TRANSACTION_DATA_URL } from 'constants/tinybird'
import { OrgContext } from 'context'
import { flatten } from 'lodash-es'
import { useContext, useEffect, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import {
  FormattedTinyBirdTransactionData,
  TinyBirdTransactionData,
} from 'types/TinyBird'
import { getDateTimeString } from 'utils'

export function useInfiniteOrgTransactions() {
  const [isLoadingInitial, setIsLoadingInitial] = useState(false)

  const [isLoadingMore, setIsLoadingMore] = useState(false)
  const org = useContext(OrgContext)
  const orgId = org?.id
  const [transactions, setTransactions] = useState<TinyBirdTransactionData[]>(
    []
  )
  const [transactionsToRender, setTransactionsToRender] = useState<
    TinyBirdTransactionData[]
  >([])
  const [formattedTxns, setFormattedTxns] = useState<
    FormattedTinyBirdTransactionData[]
  >([])
  // initial fetch
  useEffect(() => {
    setIsLoadingInitial(true)
    if (orgId) {
      FetchTinyBirdDataByOrgId({
        orgId,
        cb: setTransactions,
        baseUrl: TRANSACTION_DATA_URL,
        dataTitle: 'transaction data',
      }).finally(() => setIsLoadingInitial(false))
    }
  }, [orgId])

  // Once initial fetch happens and transactions set, set transactionsToRender
  useEffect(() => {
    if (transactions) {
      const slicedTransactions = transactions.slice(0, 20)
      setTransactionsToRender(slicedTransactions)
    }
  }, [transactions])

  // Format transactions for CSV download
  useEffect(() => {
    const formatTxns = transactions?.map(t => {
      const adjustedDate = new Date(t.created)

      adjustedDate.setTime(
        adjustedDate.getTime() - adjustedDate.getTimezoneOffset() * 60 * 1000
      )
      return {
        created: getDateTimeString(new Date(adjustedDate)),
        description: t.description,
        name: t.userFullName,
        employeeId: t.employeeId,
        location: t?.userLocation,
        department: t?.userDepartment,
        country: t?.userCountry,
        approved: t.approved ? 'approved' : 'declined',
        status: t.status,
        refund: t.type === 'refund' ? 'true' : 'false',
        currency: t.currency,
        amount: t?.programType !== 'personalFunds' ? t.amount / 100 : 0,
        personalAmount: t?.programType === 'personalFunds' ? t.amount / 100 : 0,
        programName: t.programName,
        programType: t.programType,
        programInternalMemo: t.programInternalMemo,
        id: t.resolvedId,
        authId: t.authId,
        programId: t.programId,
        isPrepaid: t.isPrepaid,
        category: t?.category,
        accountName: t?.accountName,
        accountId: t?.accountId,
      } as FormattedTinyBirdTransactionData
    })

    setFormattedTxns(flatten(formatTxns))
  }, [transactions])

  const handleGetMoreTransactions = () => {
    if (transactionsToRender.length === transactions.length) return

    const startingIndex = transactionsToRender.length
    const endingIndex = transactionsToRender.length + 20

    const newTransactions = transactions.slice(startingIndex, endingIndex)

    setTransactionsToRender(oldTransactions =>
      oldTransactions.concat(newTransactions)
    )
  }

  const { ref: lastOrgTransactionRef } = useInView({
    onChange: inView => {
      if (inView) {
        setIsLoadingMore(true)
        handleGetMoreTransactions()
        setIsLoadingMore(false)
      }
    },
  })
  return {
    lastOrgTransactionRef,
    isLoadingMore,
    transactions,
    formattedTxns,
    isLoadingInitial,
    setTransactions,
    transactionsToRender,
  }
}
