import { Text } from 'evergreen-ui'
import { getMinutesBetweenDates, makePlural } from 'utils'

export function ExpiredText({ endsOn }: { endsOn: Date }) {
  const today = new Date()
  const minuteDifference = getMinutesBetweenDates(endsOn, today)

  return (
    <Text size={300} color="muted">
      Expired {minuteDifference} {makePlural('minute', minuteDifference)} ago
    </Text>
  )
}
