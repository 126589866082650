// @generated by protoc-gen-es v1.6.0 with parameter "target=ts"
// @generated from file perkup/v1/purchase_order.proto (package perkup.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, protoInt64, Timestamp } from "@bufbuild/protobuf";
import { Order_Address } from "./vendor_pb.js";

/**
 * Firestore /purchaseOrders
 *
 * @generated from message perkup.v1.PurchaseOrder
 */
export class PurchaseOrder extends Message<PurchaseOrder> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: google.protobuf.Timestamp created_at = 2;
   */
  createdAt?: Timestamp;

  /**
   * @generated from field: string org_id = 3;
   */
  orgId = "";

  /**
   * @generated from field: string individual_id = 4;
   */
  individualId = "";

  /**
   * @generated from field: perkup.v1.Order.Address shipping_address = 5;
   */
  shippingAddress?: Order_Address;

  /**
   * Subtotal of the purchase order (without tax and shipping)
   *
   * @generated from field: int64 subtotal = 6;
   */
  subtotal = protoInt64.zero;

  /**
   * Total amount of the purchase order (with tax and shipping)
   *
   * @generated from field: int64 total = 7;
   */
  total = protoInt64.zero;

  /**
   * @generated from field: repeated perkup.v1.PurchaseOrder.Item items = 8;
   */
  items: PurchaseOrder_Item[] = [];

  /**
   * @generated from field: perkup.v1.PurchaseOrder.PaymentMethod payment_method = 9;
   */
  paymentMethod = PurchaseOrder_PaymentMethod.PAYMENT_METHOD_UNSPECIFIED;

  /**
   * @generated from field: perkup.v1.PurchaseOrder.PaymentInfo payment_info = 10;
   */
  paymentInfo?: PurchaseOrder_PaymentInfo;

  constructor(data?: PartialMessage<PurchaseOrder>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "perkup.v1.PurchaseOrder";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "created_at", kind: "message", T: Timestamp },
    { no: 3, name: "org_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "individual_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "shipping_address", kind: "message", T: Order_Address },
    { no: 6, name: "subtotal", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 7, name: "total", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 8, name: "items", kind: "message", T: PurchaseOrder_Item, repeated: true },
    { no: 9, name: "payment_method", kind: "enum", T: proto3.getEnumType(PurchaseOrder_PaymentMethod) },
    { no: 10, name: "payment_info", kind: "message", T: PurchaseOrder_PaymentInfo },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PurchaseOrder {
    return new PurchaseOrder().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PurchaseOrder {
    return new PurchaseOrder().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PurchaseOrder {
    return new PurchaseOrder().fromJsonString(jsonString, options);
  }

  static equals(a: PurchaseOrder | PlainMessage<PurchaseOrder> | undefined, b: PurchaseOrder | PlainMessage<PurchaseOrder> | undefined): boolean {
    return proto3.util.equals(PurchaseOrder, a, b);
  }
}

/**
 * @generated from enum perkup.v1.PurchaseOrder.PaymentMethod
 */
export enum PurchaseOrder_PaymentMethod {
  /**
   * @generated from enum value: PAYMENT_METHOD_UNSPECIFIED = 0;
   */
  PAYMENT_METHOD_UNSPECIFIED = 0,

  /**
   * Using stripe payment element directly charging the card
   *
   * @generated from enum value: creditCard = 1;
   */
  creditCard = 1,

  /**
   * Drawing from an org balance ie company balance
   *
   * @generated from enum value: orgBalance = 2;
   */
  orgBalance = 2,

  /**
   * Creating an invoice for the org to pay using stripe
   *
   * @generated from enum value: invoice = 3;
   */
  invoice = 3,
}
// Retrieve enum metadata with: proto3.getEnumType(PurchaseOrder_PaymentMethod)
proto3.util.setEnumType(PurchaseOrder_PaymentMethod, "perkup.v1.PurchaseOrder.PaymentMethod", [
  { no: 0, name: "PAYMENT_METHOD_UNSPECIFIED" },
  { no: 1, name: "creditCard" },
  { no: 2, name: "orgBalance" },
  { no: 3, name: "invoice" },
]);

/**
 * @generated from message perkup.v1.PurchaseOrder.Item
 */
export class PurchaseOrder_Item extends Message<PurchaseOrder_Item> {
  /**
   * @generated from field: string description = 1;
   */
  description = "";

  /**
   * @generated from field: int32 quantity = 2;
   */
  quantity = 0;

  /**
   * Price per item
   *
   * @generated from field: int64 unit_price = 3;
   */
  unitPrice = protoInt64.zero;

  /**
   * Total price for the line item
   *
   * @generated from field: int64 line_item_total = 4;
   */
  lineItemTotal = protoInt64.zero;

  /**
   * @generated from field: optional string image_url = 5;
   */
  imageUrl?: string;

  /**
   * Used to identify the supplier
   *
   * @generated from field: string supplier_id = 6;
   */
  supplierId = "";

  /**
   * @generated from field: string product_name = 7;
   */
  productName = "";

  /**
   * @generated from field: string product_id = 8;
   */
  productId = "";

  /**
   * Options for the item, ie size, color, etc
   *
   * @generated from field: map<string, string> options = 9;
   */
  options: { [key: string]: string } = {};

  constructor(data?: PartialMessage<PurchaseOrder_Item>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "perkup.v1.PurchaseOrder.Item";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "quantity", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "unit_price", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: "line_item_total", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 5, name: "image_url", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 6, name: "supplier_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "product_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "product_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "options", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "scalar", T: 9 /* ScalarType.STRING */} },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PurchaseOrder_Item {
    return new PurchaseOrder_Item().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PurchaseOrder_Item {
    return new PurchaseOrder_Item().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PurchaseOrder_Item {
    return new PurchaseOrder_Item().fromJsonString(jsonString, options);
  }

  static equals(a: PurchaseOrder_Item | PlainMessage<PurchaseOrder_Item> | undefined, b: PurchaseOrder_Item | PlainMessage<PurchaseOrder_Item> | undefined): boolean {
    return proto3.util.equals(PurchaseOrder_Item, a, b);
  }
}

/**
 * @generated from message perkup.v1.PurchaseOrder.PaymentInfo
 */
export class PurchaseOrder_PaymentInfo extends Message<PurchaseOrder_PaymentInfo> {
  /**
   * If payment method is credit card, this is the stripe checkout session id
   *
   * @generated from field: string stripe_checkout_session_id = 1;
   */
  stripeCheckoutSessionId = "";

  /**
   * If payment method is invoice, this is the stripe invoice id
   *
   * @generated from field: string stripe_invoice_id = 2;
   */
  stripeInvoiceId = "";

  /**
   * If payment method is org balance, this is the account id
   *
   * @generated from field: string adjustment_account_id = 3;
   */
  adjustmentAccountId = "";

  constructor(data?: PartialMessage<PurchaseOrder_PaymentInfo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "perkup.v1.PurchaseOrder.PaymentInfo";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "stripe_checkout_session_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "stripe_invoice_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "adjustment_account_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PurchaseOrder_PaymentInfo {
    return new PurchaseOrder_PaymentInfo().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PurchaseOrder_PaymentInfo {
    return new PurchaseOrder_PaymentInfo().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PurchaseOrder_PaymentInfo {
    return new PurchaseOrder_PaymentInfo().fromJsonString(jsonString, options);
  }

  static equals(a: PurchaseOrder_PaymentInfo | PlainMessage<PurchaseOrder_PaymentInfo> | undefined, b: PurchaseOrder_PaymentInfo | PlainMessage<PurchaseOrder_PaymentInfo> | undefined): boolean {
    return proto3.util.equals(PurchaseOrder_PaymentInfo, a, b);
  }
}

