import { ShortNavigation } from 'components'
import { CARD, HOME, ORGANIZATION_NEW, SELECT_COUNTRY } from 'constants/routes'
import { UserContext } from 'context'
import { Pane } from 'evergreen-ui'
import { useContext } from 'react'
// content
import { isMobile } from 'react-device-detect'
import { Route, Routes } from 'react-router'
import CreateOrg from './CreateOrg'
import SelectCountry from './Onboarding/SelectCountry'
import UserName from './Onboarding/UserName'

function UserOnboarding() {
  const user = useContext(UserContext)

  if (!user.onboarding) return null
  return (
    <>
      <ShortNavigation showNavName={user.onboarding.page > 1} />

      <Pane
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        height={isMobile ? 'auto' : '100vh'}
        padding={24}
      >
        <Routes>
          <Route path={HOME} element={<UserName />} />
          <Route path={CARD} element={<UserName />} />
          <Route path={SELECT_COUNTRY} element={<SelectCountry />} />
          <Route path={ORGANIZATION_NEW} element={<CreateOrg />} />
          <Route path="*" element={<UserName />} />
        </Routes>
      </Pane>
    </>
  )
}

export default UserOnboarding
