export enum RewardUseCasesKeys {
  // general.ts
  THANKS = 'THANKS',
  ABOVE_AND_BEYOND = 'ABOVE_AND_BEYOND',
  CONGRATULATIONS = 'CONGRATULATIONS',
  SHOUTOUTS = 'SHOUTOUTS',
  KUDOS = 'KUDOS',
  HIGH_IMPACT = 'HIGH_IMPACT',

  // anniversaries.ts
  ANNIVERSARIES = 'ANNIVERSARIES',
  FUNNY_WORK_ANNIVERSARY = 'FUNNY_WORK_ANNIVERSARY',
  ONE_YEAR_ANNIVERSARY = 'ONE_YEAR_ANNIVERSARY',
  TWO_YEAR_ANNIVERSARY = 'TWO_YEAR_ANNIVERSARY',
  THREE_YEAR_ANNIVERSARY = 'THREE_YEAR_ANNIVERSARY',
  FOUR_YEAR_ANNIVERSARY = 'FOUR_YEAR_ANNIVERSARY',
  FIVE_YEAR_ANNIVERSARY = 'FIVE_YEAR_ANNIVERSARY',
  SIX_YEAR_ANNIVERSARY = 'SIX_YEAR_ANNIVERSARY',
  SEVEN_YEAR_ANNIVERSARY = 'SEVEN_YEAR_ANNIVERSARY',
  EIGHT_YEAR_ANNIVERSARY = 'EIGHT_YEAR_ANNIVERSARY',
  NINE_YEAR_ANNIVERSARY = 'NINE_YEAR_ANNIVERSARY',
  TEN_YEAR_ANNIVERSARY = 'TEN_YEAR_ANNIVERSARY',

  // birthdays.ts
  BIRTHDAYS = 'BIRTHDAYS',
  FUNNY_BIRTHDAY = 'FUNNY_BIRTHDAY',
  PET_BIRTHDAY = 'PET_BIRTHDAY',

  // employeeRecognition.ts
  WELCOME = 'WELCOME',
  CONGRATS = 'CONGRATS',
  NEW_EMPLOYEE = 'NEW_EMPLOYEE',
  NEW_HIRE_SWAG = 'NEW_HIRE_SWAG',
  EMPLOYEE_OF_THE_WEEK = 'EMPLOYEE_OF_THE_WEEK',
  LEADERSHIP = 'LEADERSHIP',
  INNOVATION = 'INNOVATION',
  TEAMWORK = 'TEAMWORK',
  ROLE_MODEL = 'ROLE_MODEL',

  // mealsAndBreaks.ts
  TEAM_LUNCH = 'TEAM_LUNCH',
  COFFEE_BREAK = 'COFFEE_BREAK',
  SNACK_BREAK = 'SNACK_BREAK',
  TEAM_DINNER = 'TEAM_DINNER',
  DONUT_DAY = 'DONUT_DAY',
  ICE_CREAM_PARTY = 'ICE_CREAM_PARTY',
  WELCOME_LUNCH = 'WELCOME_LUNCH',

  // companyEvents.ts
  COMPANY_EVENTS = 'COMPANY_EVENTS',
  MOVIE_NIGHT = 'MOVIE_NIGHT',
  GAME_NIGHT = 'GAME_NIGHT',
  CONTEST_WINNER = 'CONTEST_WINNER',
  TRIVIA_NIGHT = 'TRIVIA_NIGHT',
  BOOK_CLUB = 'BOOK_CLUB',
  COMPANY_OFFSITE = 'COMPANY_OFFSITE',

  // holidays.ts
  HOLIDAYS = 'HOLIDAYS',
  THANKSGIVING = 'THANKSGIVING',
  CHRISTMAS = 'CHRISTMAS',
  HANUKKAH = 'HANUKKAH',
  HALLOWEEN = 'HALLOWEEN',
  MENTAL_HEALTH_DAY = 'MENTAL_HEALTH_DAY',
  NEW_YEAR = 'NEW_YEAR',

  // personalEvents.ts
  WEDDING = 'WEDDING',
  NEW_BABY = 'NEW_BABY',
  NEW_HOME = 'NEW_HOME',
  GET_WELL = 'GET_WELL',
  RETIREMENT = 'RETIREMENT',
  BEREAVEMENT = 'BEREAVEMENT',

  // performanceAndSales.ts
  PERFORMANCE_BONUS = 'PERFORMANCE_BONUS',
  SALES_SPIFF = 'SALES_SPIFF',

  // customers.ts
  NEW_CUSTOMER = 'NEW_CUSTOMER',
  ACCOUNT_EXPANSION = 'ACCOUNT_EXPANSION',
  CUSTOMER_RETENTION = 'CUSTOMER_RETENTION',
  CUSTOMER_THANK_YOU = 'CUSTOMER_THANK_YOU',
  ACCOUNT_RENEWAL = 'ACCOUNT_RENEWAL',
  POSITIVE_FEEDBACK = 'POSITIVE_FEEDBACK',
  NEGATIVE_FEEDBACK = 'NEGATIVE_FEEDBACK',
  GREAT_REVIEW = 'GREAT_REVIEW',
  MILESTONE_CELEBRATION = 'MILESTONE_CELEBRATION',
  REFERRAL = 'REFERRAL',
  CUSTOMER_APOLOGY = 'CUSTOMER_APOLOGY',

  // other.ts
  DOG = 'DOG',
  CAT = 'CAT',
}

export type RewardUseCasesKeysType = keyof typeof RewardUseCasesKeys
