import { captureException, captureMessage } from '@sentry/react'
import { setUserBillingAddress } from 'api/databaseCalls'
import { callFunction } from 'api/functionCalls'
import { CARD_ACTIVATED } from 'constants/events'
import { toaster } from 'evergreen-ui'
import { doc, getFirestore, setDoc } from 'firebase/firestore'
import { User } from 'gen/perkup/v1/org_user_pb'
import { RootUser, RootUser_Address } from 'gen/perkup/v1/root_user_pb'
import Stripe from 'stripe'
import { logEvent } from 'utils'
import { converter } from 'utils/firestore'
import { toSentry } from 'utils/sentry'

export const handleCreateCard = async (data: {
  address: Stripe.Address
  user: RootUser
  cardholderId?: string
  cardId?: string
  orgId?: string
  userId?: string
}) => {
  const { address, user, userId, orgId } = data

  let { cardholderId, cardId } = data

  // Create cardholder if one doesn't exist
  if (!cardholderId) {
    try {
      const cardholder = await callFunction('stripe-CreateCardholder', {
        user: user.toJson(),
        address,
      })

      if (cardholder?.type === 'StripeInvalidRequestError') {
        const errorMessage =
          cardholder?.raw?.message ||
          "There's an error. If the issue persists, please contact support"
        toaster.danger(errorMessage)
        captureMessage('User error creating cardholder', 'info')
        return
      }
      if (!cardholder.id) {
        toaster.danger('Error creating Cardholder, please contact support')
        captureMessage('Error creating Cardholder', 'error')
        return
      }
      cardholderId = cardholder.id

      // Add cardholderId to user
      const db = getFirestore()
      const docRef = doc(db, `users/${user.id}`).withConverter(
        converter(RootUser)
      )
      await setDoc(
        docRef,
        {
          cardholderId,
        },
        { merge: true }
      )
    } catch (error: any) {
      console.error(error)
      captureException(toSentry(error), {
        contexts: {
          handleCreateCard: {
            address,
            user,
            userId,
            orgId,
            cardholderId,
            cardId,
          },
        },
      })
      toaster.danger(error?.message)
      return
    }
  }

  let cardIsCanceled = false

  if (cardId) {
    const card = await callFunction('stripe-RetrieveCard', {})
    if (card.status === 'canceled') {
      cardIsCanceled = true
    }
  }

  // Create card if one doesn't exist
  // or if they have an existing card that is canceled, we will create a new one
  if (!cardId || cardIsCanceled) {
    try {
      const card = await callFunction('stripe-CreateCard', {
        cardholder: cardholderId,
        orgId,
        userId,
        status: 'active',
      })
      if (!card.id) {
        toaster.danger('Error activating Card, please contact support')
        captureMessage('Error creating Card', 'error')
        return
      }
      cardId = card.id
      // Add cardId and balance to orgUser
      const db = getFirestore()
      const docRef = doc(
        db,
        `organizations/${orgId}/users/${userId}`
      ).withConverter(converter(User))
      await setDoc(docRef, { cardId }, { merge: true })
    } catch (error: any) {
      console.error(error)
      captureException(toSentry(error), {
        contexts: {
          handleCreateCard: {
            address,
            user,
            userId,
            orgId,
            cardholderId,
            cardId,
          },
        },
      })
      toaster.danger(error?.message)
      return
    }
  }

  if (userId) {
    const { city, country, line1, line2, state } = address
    await setUserBillingAddress({
      address: new RootUser_Address({
        city: city ?? undefined,
        country: country ?? undefined,
        line1: line1 ?? undefined,
        line2: line2 ?? undefined,
        state: state ?? undefined,
        postalCode: address.postal_code ?? undefined,
      }),
      userId,
    })
  }
  logEvent(CARD_ACTIVATED, { orgId, userId })
}
