import { Button, ButtonProps } from 'antd'
import { Pane } from 'evergreen-ui'

interface FinchButtonProps {
  provider?: string
  logoUrl?: string
  isButton?: boolean
  buttonProps?: ButtonProps
  cta?: string
}

function FinchButton(props: FinchButtonProps & { onClick: () => void }) {
  const {
    provider,
    logoUrl,
    isButton,
    onClick,
    buttonProps,
    cta = 'Add HRIS integration',
  } = props

  if (isButton) {
    return (
      <Button onClick={onClick} {...buttonProps}>
        {cta}
      </Button>
    )
  }

  return (
    <Pane
      display="flex"
      alignItems="center"
      justifyContent="center"
      elevation={1}
      borderRadius={4}
      padding={24}
      cursor="pointer"
      className="merchant"
      onClick={onClick}
    >
      <Pane display="flex" width={160} style={{ maxHeight: 40 }}>
        <img
          src={logoUrl}
          alt={provider}
          style={{
            maxWidth: '100%',
            maxHeight: 'inherit',
            margin: 'auto',
          }}
        />
      </Pane>
    </Pane>
  )
}

export default FinchButton
