import { Flex } from 'antd'
import { Heading, Pane } from 'evergreen-ui'
import { getDefaultUseCaseForBrandedImage } from 'pages/NewReward/utils/uiUtils'
import { ProgramTheme } from 'types'
import { getTemplateImageUrl } from 'utils/templates'
import { useProgramTemplates } from './hooks'
import { getUseCaseLabel } from './util'

export function FeaturedGrid({
  featured,
  onSelectTheme,
  useCaseOnProgram,
}: {
  featured: ReturnType<typeof useProgramTemplates>['featured']
  useCaseOnProgram: string | undefined
  onSelectTheme: ({
    emailData,
    advance,
    rewardUseCase,
  }: {
    emailData: ProgramTheme
    advance?: boolean
    rewardUseCase?: string
  }) => void
}) {
  return (
    <Flex style={{ width: '100%' }} vertical gap={32} flex={4}>
      {featured.map(({ useCase, programTemplates, brandedImages }) => (
        <Flex key={useCase} vertical gap={8}>
          <Heading size={600}>{getUseCaseLabel(useCase)}</Heading>
          <Pane
            display="grid"
            gridTemplateColumns="repeat(3, 1fr)"
            gridAutoFlow="row dense"
            gap={16}
          >
            {[1, 2, 3].map((value, columnIndex) => {
              // Modeled after the unsplashed grid, is dynamic w 3 columns
              const columnChildren = [
                ...brandedImages.map(brandedImage => ({
                  brandedImage,
                  programTemplate: undefined,
                })),
                ...programTemplates.map(programTemplate => ({
                  programTemplate,
                  brandedImage: undefined,
                })),
              ].filter((_, i) => i % 3 === columnIndex)
              return (
                <Pane
                  key={value}
                  display="flex"
                  flexDirection="column"
                  gap={16}
                >
                  {columnChildren.map(({ programTemplate, brandedImage }) => (
                    <>
                      {brandedImage && (
                        <Pane
                          key={brandedImage}
                          onClick={() => {
                            const defaultUseCase =
                              getDefaultUseCaseForBrandedImage({
                                image: brandedImage,
                              })
                            const useCaseEmail = defaultUseCase?.emailData
                            const defaultTitle = useCaseEmail?.[0]?.title
                            const defaultNote = useCaseEmail?.[0]?.note
                            onSelectTheme({
                              emailData: {
                                banner: brandedImage,
                                title: defaultTitle,
                                note: defaultNote,
                              },
                              advance: true,
                              rewardUseCase: defaultUseCase?.label,
                            })
                          }}
                          borderRadius={8}
                          border="1px solid var(--nextui-colors-gray500)"
                          overflow="hidden"
                          hoverElevation={1}
                          cursor="pointer"
                          height="fit-content"
                        >
                          <img
                            alt={`${useCase} template`}
                            src={brandedImage}
                            width="100%"
                          />
                        </Pane>
                      )}
                      {programTemplate && (
                        <Pane
                          key={programTemplate.id}
                          borderRadius={8}
                          overflow="hidden"
                          hoverElevation={1}
                          cursor="pointer"
                          height="fit-content"
                          border="1px solid var(--nextui-colors-gray500)"
                          onClick={() => {
                            onSelectTheme({
                              emailData: {
                                banner: programTemplate.programImage,
                                title: programTemplate.programTitle,
                                note: programTemplate.programMessage,
                                categories: programTemplate.categories,
                              },
                              advance: true,
                              rewardUseCase: useCaseOnProgram,
                            })
                          }}
                        >
                          <img
                            alt={`${useCase} template`}
                            src={getTemplateImageUrl({
                              programImage: programTemplate.programImage,
                            })}
                            width="100%"
                          />
                        </Pane>
                      )}
                    </>
                  ))}
                </Pane>
              )
            })}
          </Pane>
        </Flex>
      ))}
    </Flex>
  )
}
