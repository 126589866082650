import { captureMessage } from '@sentry/react'
import { callFunction } from 'api/functionCalls'
import SlackLogo from 'assets/slack-logo.svg'
import { OrgContext } from 'context'
import {
  ArrowDownIcon,
  Heading,
  Pane,
  SendMessageIcon,
  useTheme,
} from 'evergreen-ui'
import { PageNotFound } from 'pages'
import { useContext, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { NotificationOption, NotificationSendTypes } from 'types/Notifications'
import {
  getNotificationDetailsByOccassion,
  getNotificationOccassionByPath,
} from 'utils'
import EmailConfigForm from './EmailConfigForm'
import NotificationStatus from './NotificationStatus'
import SlackPublicConfiguration from './SlackPublicConfiguration'

const slackIcon = (
  <Pane
    width={24}
    height={24}
    backgroundImage={`url(${SlackLogo})`}
    backgroundRepeat="no-repeat"
    backgroundSize="cover"
    backgroundPosition="center"
  />
)

export default function NotificationsList() {
  const org = useContext(OrgContext)
  const theme = useTheme()

  const { pathname } = useLocation()
  const occasion = getNotificationOccassionByPath(pathname)
  const { rewardName } = getNotificationDetailsByOccassion(occasion)

  const emailIcon = <SendMessageIcon color={theme.colors.blue400} margin={4} />

  const notificationOptions: Omit<NotificationOption, 'occasion'>[] = [
    {
      sendType: NotificationSendTypes.NOTIFY_ADMINS,
      statusTitle: 'Notify admins',
      configDescription: `Sends an email notification to admins to alert them about all upcoming ${rewardName}.`,
      isShown: true,
      Icon: emailIcon,
      hasAction: true,
      ConfigForm: EmailConfigForm,
      isSlack: false,
    },
    {
      sendType: NotificationSendTypes.NOTIFY_MANAGER,
      statusTitle: 'Notify managers',
      configDescription: `Sends an email to each individual's manager to alert them about each upcoming ${rewardName}.`,
      isShown: true,
      Icon: emailIcon,
      hasAction: true,
      ConfigForm: EmailConfigForm,
      isSlack: false,
    },
    {
      sendType: NotificationSendTypes.PUBLIC_SLACK,
      statusTitle: 'Send a public Slack announcement',
      configDescription: `Send daily ${rewardName} notifications.`,
      isShown: org?.slackConnected ?? false,
      Icon: slackIcon,
      hasAction: true,
      ConfigForm: SlackPublicConfiguration,
      isSlack: true,
    },
    {
      sendType: NotificationSendTypes.PRIVATE_SLACK,
      statusTitle: 'Send a private Slack message',
      configDescription: `Sends a private Slack message to each individual with the message and image on their ${rewardName} rule(s) if they use the same email on Slack and PerkUp.`,
      isShown: org?.slackConnected ?? false,
      Icon: slackIcon,
      hasAction: false,
      ConfigForm: undefined,
      isSlack: true,
    },
  ]

  // Warm up function to list Slack channels
  useEffect(() => {
    if (org?.slackConnected) {
      callFunction('slack-ListChannels', {})
    }
  }, [org?.slackConnected])

  if (!occasion) {
    captureMessage('No occassion, invalid path?')
    return <PageNotFound />
  }

  return (
    <Pane>
      <Pane
        display="flex"
        alignItems="center"
        gap={32}
        padding={32}
        marginBottom={32}
        borderBottom="muted"
      >
        <ArrowDownIcon color="muted" />
        <Heading>For each individual&lsquo;s {rewardName}</Heading>
      </Pane>
      <Pane display="flex" flexDirection="column" gap={32}>
        {notificationOptions.map(notificationOption => {
          return (
            notificationOption.isShown && (
              <NotificationStatus
                key={notificationOption.sendType}
                occasion={occasion}
                {...notificationOption}
              />
            )
          )
        })}
      </Pane>
    </Pane>
  )
}
