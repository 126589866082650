/* eslint-disable import/no-cycle */
import { captureException, captureMessage } from '@sentry/react'
import {
  GetProductVariantById,
  ListProductVariantsByProductIds,
  getProductCollectionById,
  listProductVariantsByProductId,
} from 'api/databaseCalls'
import { UiStepProps } from 'components/StepsBar'
import { currencySymbols } from 'constants/currencies'
import {
  AMAZON_PERKUP_FEE_MULTIPLIER,
  AMAZON_TAX_RATE,
  SHOPIFY_PERKUP_FEE_MULTIPLIER,
} from 'constants/money'
import * as ROUTES from 'constants/routes'
import { DEFAULT_ROUTES } from 'constants/routes'
import { toaster } from 'evergreen-ui'
import {
  Item,
  Program_Gift,
  Program_Gift_FulfilledBy,
} from 'gen/perkup/v1/program_pb'
import { RootUser_Address } from 'gen/perkup/v1/root_user_pb'
import { indexOf, isUndefined, toLower } from 'lodash-es'
import compact from 'lodash-es/compact'
import isNumber from 'lodash-es/isNumber'
import startCase from 'lodash-es/startCase'
import uniq from 'lodash-es/uniq'
import { getAmazonProductByAsin } from 'services/amazon'
import { Stripe } from 'stripe'
import { getAmazonDisplayPrices } from './amazon'
import { toSentry } from './sentry'

export function toMenuItems(values: (string | undefined)[]) {
  return uniq(compact(values)).map((value: string) => ({
    label: startCase(value),
    value,
  }))
}

export function numberWithCommas(x: number) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export function afterLastSlash(str: string) {
  if (!str) return str
  return str.substring(str.lastIndexOf('/') + 1)
}

/**
 *
 * @param str to be modified
 *
 * @returns a string where every word starts with an upper case
 */
export function startCaseString(str: string) {
  return startCase(toLower(str))
}

export const numToDollars = (
  num?: number,
  decimel?: number,
  _?: boolean,
  displayCurrency?: string,
  truncate?: boolean
) => {
  const dec = decimel ?? 2
  if (Number.isNaN(num)) return null
  if (!num && num !== 0) return null

  const absoluteVal = Math.abs(Math.round(num)) / 100
  const { language } = window.navigator

  let stringDollars = absoluteVal
    .toFixed(dec)
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')

  let currencySymbol = '$'
  if (displayCurrency) {
    const displayCurrencyWType = displayCurrency as keyof typeof currencySymbols
    currencySymbol = currencySymbols[displayCurrencyWType] || '$'
    stringDollars = `${currencySymbol}${stringDollars}`
  }
  const useShortCompactDisplay = absoluteVal > 1000 && truncate
  const currency = displayCurrency?.toUpperCase() || 'USD'
  try {
    if (Intl.NumberFormat) {
      stringDollars = new Intl.NumberFormat(language, {
        style: 'currency',
        notation: useShortCompactDisplay ? 'compact' : 'standard',
        compactDisplay: useShortCompactDisplay ? 'short' : 'long',
        currency,
        minimumFractionDigits: dec,
        maximumFractionDigits: dec,
      }).format(absoluteVal)
    }
  } catch (error) {
    console.error('error', error)
    captureException(toSentry(error), {
      contexts: {
        numToDollars: { displayCurrency, language, absoluteVal },
      },
    })
  }

  return (num < 0 ? '-' : '') + stringDollars
}

export function numToString(num: number) {
  if (!num) return num
  const trueNumber = isNumber(num) ? num : parseInt(num, 10)

  return trueNumber.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export function expressPossession(str: string) {
  if (!str) return str
  const endsWithS = str.charAt(str.length - 1) === 's'
  if (endsWithS) {
    return `${str}'`
  }
  return `${str}'s`
}

export function preFixSpace(str: string) {
  if (!str) return null
  return ` ${str}`
}

export function makePlural(
  str: string,
  cnt?: number,
  inclusive: boolean = false
) {
  if (!str) return null
  if (cnt !== 1) {
    return inclusive && !isUndefined(cnt) ? `${cnt} ${str}s` : `${str}s`
  }
  return inclusive && !isUndefined(cnt) ? `${cnt} ${str}` : str
}

export function getHeadTitle(currentRoute: string) {
  const headTitles = {
    [ROUTES.HOME]: 'Perk Card',
    [ROUTES.CARD]: 'Perk Card',
    [ROUTES.CARD_SETUP]: 'Setup Perk Card',
    [ROUTES.CARD_ACTIVATED]: 'Activated Perk Card',
    [ROUTES.PERKS]: 'Explore Perks',
    [ROUTES.ACCOUNT]: 'Account',
    [ROUTES.TRANSACTIONS]: 'Transactions',
    [ROUTES.ORGANIZATION]: 'Company',
    [ROUTES.ORGANIZATION_NEW]: 'Create Company Profile',
    [DEFAULT_ROUTES.ORGANIZATION.PERK_PROGRAMS.ROOT]: 'Recurring Programs',
    [DEFAULT_ROUTES.ORGANIZATION.REWARDS.ANNIVERSARIES.ROOT]: 'Anniversaries',
    [DEFAULT_ROUTES.ORGANIZATION.REWARDS.BIRTHDAYS.ROOT]: 'Birthdays',
    [DEFAULT_ROUTES.ORGANIZATION.REWARDS.SPOT]: 'Spot Rewards',
    [DEFAULT_ROUTES.ORGANIZATION.DIRECTORY.ROOT]: 'People',
    [DEFAULT_ROUTES.ORGANIZATION.BILLING]: 'Billing',
    [DEFAULT_ROUTES.ORGANIZATION.ACCOUNTS.ROOT]: 'Balances',
    [DEFAULT_ROUTES.ORGANIZATION.BRANDING]: 'Branding',
    [DEFAULT_ROUTES.ORGANIZATION.SETTINGS]: 'Settings',
    [DEFAULT_ROUTES.ORGANIZATION.INTEGRATIONS.ROOT]: 'Integrations',
    [ROUTES.SIGN_IN]: 'Sign In',
    [ROUTES.SIGN_UP]: 'Sign Up',
    [DEFAULT_ROUTES.ORGANIZATION.TRANSACTIONS]: 'Transactions',
    [ROUTES.VERIFY_EMAIL]: 'Verify Email',
    [ROUTES.AMAZON]: 'Amazon',
    [ROUTES.AMAZON_SEARCH]: 'Amazon Search',
    [ROUTES.AMAZON_CHECKOUT]: 'Amazon Checkout',
    [DEFAULT_ROUTES.ORGANIZATION.SWAG.ROOT]: 'Swag',
    [DEFAULT_ROUTES.ORGANIZATION.REWARDS.NEW_REWARD]: 'New reward',
  }
  const title = headTitles[currentRoute] ? `${headTitles[currentRoute]} — ` : ''
  return `${title}PerkUp`
}

export const getCardBadgeStatusColor = (
  status: Stripe.Issuing.Card.Status | undefined
) => {
  if (status === 'active') return 'green'
  if (status === 'canceled') return 'red'
  return 'gold'
}

export const transactionAmount = (amount: number) => {
  return numToDollars(amount > 0 ? amount : amount * -1)
}

export function ordinalSuffixOf(i: number) {
  const j = i % 10
  const k = i % 100
  if (j === 1 && k !== 11) {
    return `${i}st`
  }
  if (j === 2 && k !== 12) {
    return `${i}nd`
  }
  if (j === 3 && k !== 13) {
    return `${i}rd`
  }
  return `${i}th`
}

export function CombineAddress(address?: RootUser_Address): string
export function CombineAddress(address?: Stripe.Address): string
export function CombineAddress(address?: any) {
  if (!address) return undefined
  if (address instanceof RootUser_Address) {
    return (
      address?.line1 &&
      [address?.line1, address?.city, address?.state, address?.postalCode].join(
        ', '
      )
    )
  }
  if (address instanceof Object && 'postal_code' in address) {
    const stripeAddress = address as Stripe.Address
    return (
      stripeAddress?.line1 &&
      [
        stripeAddress?.line1,
        stripeAddress?.city,
        stripeAddress?.state,
        stripeAddress?.postal_code,
      ].join(', ')
    )
  }
}

export function copyToClipboard(text: string, type?: string) {
  if (navigator.clipboard) {
    navigator.clipboard.writeText(text)
  } else {
    const tempInput = document.createElement('input')
    tempInput.value = text
    document.body.appendChild(tempInput)
    tempInput.select()
    document.execCommand('copy')
    document.body.removeChild(tempInput)
  }

  setTimeout(() => {
    toaster.success(`Copied ${type} to clipboard`, {
      id: text,
    })
  }, 250)
}

export function CopyToClipboardWithFormatting(htmlString: string) {
  // Create an iframe (isolated container) for the HTML
  const container = document.createElement('div')
  container.innerHTML = htmlString

  // Hide element
  container.style.position = 'fixed'
  container.style.pointerEvents = 'none'
  container.style.opacity = '0'

  // Detect all style sheets of the page
  const activeSheets = Array.prototype.slice
    .call(document.styleSheets)
    .filter(sheet => {
      return !sheet.disabled
    })

  // Mount the iframe to the DOM to make `contentWindow` available
  document.body.appendChild(container)

  // Copy to clipboard
  window.getSelection()?.removeAllRanges()

  const range = document.createRange()
  range.selectNode(container)
  window.getSelection()?.addRange(range)

  document.execCommand('copy')
  activeSheets.forEach(activeSheet => {
    // eslint-disable-next-line no-param-reassign
    activeSheet.disabled = true
    document.execCommand('copy')
  })
  activeSheets.forEach(activeSheet => {
    // eslint-disable-next-line no-param-reassign
    activeSheet.disabled = false
  })

  // Remove the iframe
  document.body.removeChild(container)
  toaster.success('Successfully copied to clipboard')
}

/**
 * As per https://stackoverflow.com/a/46181
 */
const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export function isEmail(email: string): boolean {
  if (email.endsWith('@gmail.com')) return true
  return emailRegex.test(String(email).toLowerCase().trim())
}

export async function getBulkOrderPriceCalculation({
  programItems,
}: {
  programItems: Item[]
}) {
  let productPrice = 0

  await Promise.all(
    programItems.map(async item => {
      const variant = await GetProductVariantById({
        productVariantId: afterLastSlash(item.productVariantId),
      })
      if (variant) {
        productPrice += Number(variant.amount) * item.quantity
      }
    })
  )
  const shippingCost = 0
  const estimatedTax = 0
  const perkUpFee = productPrice * SHOPIFY_PERKUP_FEE_MULTIPLIER
  const totalCost = productPrice + shippingCost + estimatedTax + perkUpFee
  const collectionMinPrice = 0
  return {
    productPrice,
    perkUpFee,
    estimatedTax,
    shippingCost,
    totalCost,
    collectionMinPrice,
  }
}

export async function getPriceCalculation({
  gift,
  orgId,
}: {
  gift: Program_Gift
  orgId: string
}) {
  const {
    fulfilledBy,
    country,
    productCollectionId,
    productIds,
    productVariantId,
  } = gift

  const isSwagSingleGift = productIds && productIds.length === 1
  const isGiftCatalogSingleGift = !!productVariantId && !isSwagSingleGift
  const isMultiSelectGift = productIds?.length > 1

  let shippingCost = 0
  let productPrice = 0
  let estimatedTax = 0
  let perkUpFee = 0
  let collectionMinPrice = 0

  if (isMultiSelectGift) {
    const variants = await ListProductVariantsByProductIds({
      productIds,
      uniq: true,
      orgId,
    })
    if (!variants) return undefined
    const maxPrice = variants.reduce((acc, product) => {
      if (Number(product.amount) > acc) {
        return Number(product.amount)
      }
      return acc
    }, 0)
    productPrice = maxPrice
    shippingCost = 0
    estimatedTax = 0
    perkUpFee = productPrice * SHOPIFY_PERKUP_FEE_MULTIPLIER
    collectionMinPrice = 0
  } else if (isSwagSingleGift) {
    // If gift has a Product Variant ID, use that to get the product variant
    // Otherwise, use the Product ID to get the first product variant
    if (gift?.productVariantId) {
      const productVariant = await GetProductVariantById({
        productVariantId: gift.productVariantId,
      })

      if (!productVariant?.productId) {
        captureMessage('Error retrieving Shopify product', 'error')
        return undefined
      }

      productPrice = Number(productVariant.amount)
      perkUpFee = productPrice * SHOPIFY_PERKUP_FEE_MULTIPLIER
    } else {
      const productVariants = await listProductVariantsByProductId({
        productId: gift.productIds[0],
        orgId,
      })

      // Find most expensive variant
      const maxPrice = productVariants.reduce((acc, variant) => {
        if (Number(variant.amount) > acc) {
          return Number(variant.amount)
        }
        return acc
      }, 0)

      productPrice = Number(maxPrice)
    }

    // Manual shipping costs to handle variable shipping rates
    shippingCost = 0
    perkUpFee = productPrice * SHOPIFY_PERKUP_FEE_MULTIPLIER
  } else if (isGiftCatalogSingleGift) {
    const productVariant = await GetProductVariantById({
      productVariantId,
    })

    if (!productVariant?.productId) {
      captureMessage('Error retrieving Shopify product', 'error')
      return undefined
    }

    productPrice = Number(productVariant.amount)
    perkUpFee = productPrice * SHOPIFY_PERKUP_FEE_MULTIPLIER
  } else if (productCollectionId) {
    const productCollection = await getProductCollectionById({
      id: productCollectionId,
    })
    if (!productCollection) return undefined

    const collectionProducts = Object.values(productCollection.products)
    if (!collectionProducts.length) return undefined
    const maxPrice = collectionProducts.reduce((acc, product) => {
      if (Number(product.maxAmount) > acc) {
        return Number(product.maxAmount)
      }
      return acc
    }, 0)
    productPrice = maxPrice

    // Get collection minimum price
    let minPrice = collectionProducts.reduce((acc, product) => {
      if (Number(product.minAmount) < acc) {
        return Number(product.minAmount)
      }
      return acc
    }, Infinity)

    // Making sure we never return Infinity
    if (minPrice === Infinity) {
      minPrice = 0
    }

    collectionMinPrice = minPrice
  } else if (fulfilledBy === Program_Gift_FulfilledBy.amazon) {
    if (!country) return undefined

    const amazonProduct = await getAmazonProductByAsin({
      asin: gift?.id,
      country,
    })

    if (!amazonProduct) {
      captureMessage('Error retrieving Amazon product', 'error')
      return undefined
    }

    const offer =
      amazonProduct.includedDataTypes.OFFERS &&
      amazonProduct.includedDataTypes.OFFERS[0]

    if (!offer) {
      captureMessage('Missing Amazon product offer', 'error')
      return undefined
    }

    const { listAmount, offerAmount } = getAmazonDisplayPrices(offer)

    productPrice = listAmount > 0 ? listAmount : offerAmount
    estimatedTax = (listAmount > 0 ? listAmount : offerAmount) * AMAZON_TAX_RATE
    perkUpFee = productPrice * AMAZON_PERKUP_FEE_MULTIPLIER
  }

  const totalCost = productPrice + shippingCost + estimatedTax + perkUpFee

  return {
    productPrice,
    shippingCost,
    estimatedTax,
    perkUpFee,
    totalCost,
    collectionMinPrice,
  }
}

export function getCurrentStepIndex({ steps }: { steps: UiStepProps[] }) {
  const currentPath = window.location.pathname

  const currentStep = indexOf(
    steps,
    steps.find(step => currentPath.includes(step.path))
  )
  return currentStep < 0 ? 0 : currentStep
}

export function removeQuotes(str: string) {
  return str.replaceAll(/^"|"$/g, '')
}

export const AntdDollarFormatter = {
  // https://github.com/ant-design/ant-design/blob/b36f02a9a3ef2cda54e74fd66b03c08d5b52e17f/components/input-number/demo/formatter.tsx#L13-L14
  formatter: (value: number | undefined) =>
    `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ','),
  parser: (value: string | undefined) =>
    value?.replace(/\$\s?|(,*)/g, '') as unknown as number,
}
export const getKeys = Object.keys as <T extends object>(
  obj: T
) => Array<keyof T>
