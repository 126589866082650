import { ListenToIndividualsMissingField } from 'api/databaseCalls'
import { ProgramMemberTable } from 'components'
import { Pane, Text } from 'evergreen-ui'
import { Individual } from 'gen/perkup/v1/individual_pb'
import { useEffect, useState } from 'react'
import { IPrimaryTabs } from '.'

export function MissingIndividualsTab({
  orgId,
  selectedTab,
}: {
  orgId: string
  selectedTab: IPrimaryTabs
}) {
  const [missingIndividuals, setMissingIndividuals] = useState<Individual[]>([])

  useEffect(() => {
    if (orgId && selectedTab.field) {
      return ListenToIndividualsMissingField({
        orgId,
        fieldSelector: selectedTab.field,
        cb: setMissingIndividuals,
      })
    }
    return undefined
  }, [orgId, selectedTab.field])

  return (
    <Pane>
      {missingIndividuals.length > 0 ? (
        <ProgramMemberTable individuals={missingIndividuals} />
      ) : (
        <Text>
          Everyone&apos;s {selectedTab.singular.toLowerCase()} is in the system.
        </Text>
      )}
    </Pane>
  )
}
