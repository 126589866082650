import { Image } from 'antd'
import { store, wallet } from 'assets/icons'
import { BalanceText } from 'components/Typography/BalanceText'
import { CatContext } from 'context'
import { Heading, Pane } from 'evergreen-ui'
import { useOrgUserBalances } from 'hooks'
import { useContext } from 'react'
import { isMobile } from 'react-device-detect'

export function RenderCategoryBalance({
  balance,
  heading,
  iconWidth,
  iconSrc,
}: {
  balance: number
  heading: string
  iconWidth: string
  iconSrc: string | undefined
}) {
  if (balance > 0) {
    return (
      <Pane
        display="grid"
        gridTemplateColumns={isMobile ? '1fr' : 'max-content 1fr 2fr'}
        gridTemplateRows={isMobile ? '1fr 1fr' : ''}
        justifyContent="start"
        alignItems="center"
        gap={8}
      >
        {isMobile && (
          <Pane display="flex" gap={8}>
            <Image preview={false} src={iconSrc} width={iconWidth} />
            <BalanceText size={400} amount={balance} />
          </Pane>
        )}
        {!isMobile && (
          <>
            <Image preview={false} src={iconSrc} width={iconWidth} />

            <BalanceText size={400} amount={balance} />
          </>
        )}
        <Heading marginLeft={isMobile ? 0 : 16} size={100} whiteSpace="nowrap">
          {heading}
        </Heading>
      </Pane>
    )
  }
  return null
}

export default function BalancesByCategory() {
  const categories = useContext(CatContext)
  const {
    orgUserFoodBalance,
    orgUserSwagBalance,
    orgUserAllCatsBalance,
    orgUserPersonalBalance,
  } = useOrgUserBalances()

  const SWAG_ICON = categories.find(cat => cat.name === 'Swag')
    ?.colorOutlineIcon?.url

  const FOOD_ICON = categories.find(cat => cat.name === 'Food')
    ?.colorOutlineIcon?.url

  const allCatsHeading = 'APPROVED EVERYWHERE'

  const foodHeading = 'APPROVED FOR FOOD'

  const swagHeading = 'APPROVED FOR SWAG'

  const personalFundsHeading = 'PERSONAL FUNDS'

  const iconWidth = '20px'

  return (
    // Setting width as 0 here to avoid collapse arrow moving to the right
    <Pane
      borderTop="muted"
      paddingTop={12}
      display="flex"
      flexDirection="column"
      gap={8}
    >
      <RenderCategoryBalance
        balance={orgUserAllCatsBalance}
        heading={allCatsHeading}
        iconWidth={iconWidth}
        iconSrc={store}
      />
      <RenderCategoryBalance
        balance={orgUserFoodBalance}
        heading={foodHeading}
        iconWidth={iconWidth}
        iconSrc={FOOD_ICON}
      />
      <RenderCategoryBalance
        balance={orgUserSwagBalance}
        heading={swagHeading}
        iconWidth={iconWidth}
        iconSrc={SWAG_ICON}
      />
      <RenderCategoryBalance
        balance={orgUserPersonalBalance}
        heading={personalFundsHeading}
        iconWidth={iconWidth}
        iconSrc={wallet}
      />
    </Pane>
  )
}
