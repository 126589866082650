import { GetUserById } from 'api/databaseCalls'
import { RootUser } from 'gen/perkup/v1/root_user_pb'
import { useEffect, useState } from 'react'

export function useUserById({ userId }: { userId?: string }) {
  const [user, setUser] = useState<RootUser>()
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (!userId) return
    setIsLoading(true)
    GetUserById({ userId })
      .then(setUser)
      .finally(() => setIsLoading(false))
  }, [userId])

  return { user, isLoading }
}
