import { ShippingAddress } from 'gen/perkup/v1/root_user_pb'
import { StripeAddressData } from 'types/Stripe'
import { getCountryIsoAlpha2 } from './countries'

export function getCountryComponent(iso3: string): `country:${string}` {
  const iso2 = getCountryIsoAlpha2(iso3.toLowerCase())
  return `country:${iso2}`
}

export function getFirstAndLastNameFromAddress(
  address: ShippingAddress | undefined
) {
  if (!address) return { firstName: '', lastName: '' }
  const splitFullName = address?.name.split(' ') || []
  const firstName = splitFullName[0] || ''
  const lastName = splitFullName[splitFullName.length - 1] || ''
  return { firstName, lastName }
}

interface AddressvalidationResponse {
  validationSuccessful: boolean
  invalidFields: (keyof ShippingAddress)[]
}
export const minValidFieldLength = 2

export const verifyAddress = (
  address: ShippingAddress
): AddressvalidationResponse => {
  let validationSuccessful = true
  const invalidFields: (keyof ShippingAddress)[] = []
  const fieldsToValidate: (keyof ShippingAddress)[] = [
    'line1',
    'country',
    'postalCode',
    'name',
  ]

  const { firstName, lastName } = getFirstAndLastNameFromAddress(address)

  const nameFieldsTooShort =
    firstName.length < minValidFieldLength ||
    lastName.length < minValidFieldLength

  // Map through fields to validate and check if they are valid

  fieldsToValidate.forEach(field => {
    const fieldValue = address[field]

    if (field === 'name' && nameFieldsTooShort) {
      validationSuccessful = false
      invalidFields.push(field)
    }

    if (
      typeof fieldValue === 'string' &&
      fieldValue.length < minValidFieldLength
    ) {
      validationSuccessful = false
      invalidFields.push(field)
    }
  })

  return { validationSuccessful, invalidFields }
}

export const stripeAddressToShippingAddress = (
  stripeAddress: StripeAddressData
) => {
  return new ShippingAddress({
    ...stripeAddress.address,
    name: stripeAddress.name,
    phoneNumber: stripeAddress.phone,
    localizationExtensions: stripeAddress.localizationExtensions,
  })
}
