import { PRODUCT } from 'constants/productVariants'
import { Text } from 'evergreen-ui'
import { useStats } from 'react-instantsearch'
import { makePlural } from 'utils'

export function ProductCount() {
  const { nbHits } = useStats()

  if (nbHits === 0) return null

  return (
    <Text color="muted" minWidth="fit-content">
      {nbHits.toLocaleString()} {makePlural(PRODUCT, nbHits)}
    </Text>
  )
}
