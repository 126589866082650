import { PlusOutlined } from '@ant-design/icons'
import { Button, Input, Select, Space } from 'antd'
import { updateOrgCoreValues } from 'api/databaseCalls'
import { CORE_VALUE_CREATED } from 'constants/events'
import { IndividualContext, OrgContext, UserContext } from 'context'
import { Pane, Strong, toaster } from 'evergreen-ui'
import { Individual_Role } from 'gen/perkup/v1/individual_pb'
import { CORE_VALUE_MAX } from 'pages/CompanyBranding/constants/brandingConstants'
import { useContext, useState } from 'react'
import { logEvent } from 'utils'

export function CoreValuesSelection({
  selectedCoreValues,
  setSelectedCoreValues,
  showTitle = true,
}: {
  selectedCoreValues: string[]
  setSelectedCoreValues: (coreValues: string[]) => void
  showTitle?: boolean
}) {
  const individual = useContext(IndividualContext)
  const isAdmin = individual.role === Individual_Role.admin
  const org = useContext(OrgContext)
  const orgId = org.id
  const user = useContext(UserContext)
  const userId = user?.id
  const { coreValues } = org
  const [coreValueInput, setCoreValueInput] = useState<string>('')

  const handleAddCoreValue = () => {
    if (coreValueInput.trim() !== '') {
      const newCoreValues = [...coreValues, coreValueInput]

      logEvent(CORE_VALUE_CREATED, {
        coreValue: coreValueInput,
        orgId,
        orgName: org.name,
        orgSubscriptionStatus: org.subscriptionStatus,
        userId,
      })
      updateOrgCoreValues({ orgId, coreValues: newCoreValues })
      setCoreValueInput('')
      toaster.success('Core value added successfully')
    } else {
      toaster.warning('Core value cannot be empty')
    }
  }

  const dropdownContent = (
    menu: React.ReactElement<any, string | React.JSXElementConstructor<any>>
  ) => {
    return (
      <>
        {menu}
        <Space style={{ marginBottom: 8 }} />
        <Pane
          display="flex"
          gap={8}
          alignItems="cemter"
          padding={8}
          borderTop="muted"
        >
          <Input
            value={coreValueInput}
            onChange={e => setCoreValueInput(e.target.value)}
            placeholder="Add core values"
          />
          <Button
            style={{ display: 'flex', alignItems: 'center' }}
            type="text"
            icon={<PlusOutlined />}
            onClick={handleAddCoreValue}
          >
            Add item
          </Button>
        </Pane>
      </>
    )
  }

  const showAddCoreValue = isAdmin && coreValues.length < CORE_VALUE_MAX

  return (
    <Pane display="flex" flexDirection="column" gap={4}>
      {showTitle && <Strong>Core values</Strong>}
      <Select
        allowClear
        mode="multiple"
        placeholder="Add core values"
        options={coreValues.map(value => {
          return { label: value, value }
        })}
        dropdownRender={showAddCoreValue ? dropdownContent : undefined}
        onChange={(values: string[]) => {
          setSelectedCoreValues(values)
        }}
        value={selectedCoreValues}
      />
    </Pane>
  )
}
