import { Flex } from 'antd'
import { RewardTabs } from 'components'
import { getSlots, Slot } from 'components/Slot'
import { OrgContext } from 'context'
import { Program_RewardTab } from 'gen/perkup/v1/program_pb'
import { useContext } from 'react'
import { useSelectRewardContext } from './SelectRewardContext'
import {
  CardsForm1,
  CardsForm2,
  DollarsForm1,
  DollarsForm2,
  GiftForm1,
  GiftsForm2,
  SwagForm,
} from './forms'

function Tabs() {
  const { selectedTab } = useSelectRewardContext()
  const org = useContext(OrgContext)
  return (
    <RewardTabs
      selectedRewardTab={selectedTab.selectedRewardTab}
      onSelectedRewardTab={selectedTab.handleSelectRewardTab}
      hideGiftCardsTab={org?.settings?.hideNearCash}
    />
  )
}

const SLOTS = [
  'tabs',
  'perkupDollars',
  'swag',
  'giftCatalog',
  'giftCard',
] as const

function SelectRewardForm({ children }: { children?: React.ReactNode }) {
  const { tabs, perkupDollars, swag, giftCatalog, giftCard } = getSlots(
    SLOTS,
    children
  )

  const { selectedTab } = useSelectRewardContext()

  return (
    <Flex
      vertical
      align="center"
      style={{
        width: '100%',
      }}
      gap={32}
    >
      {tabs}

      {selectedTab.selectedRewardTab === Program_RewardTab.perkupDollars &&
        perkupDollars}

      {selectedTab.selectedRewardTab === Program_RewardTab.swag && swag}

      {selectedTab.selectedRewardTab === Program_RewardTab.catalog &&
        giftCatalog}

      {selectedTab.selectedRewardTab === Program_RewardTab.giftCard && giftCard}
    </Flex>
  )
}

SelectRewardForm.Slot = Slot<(typeof SLOTS)[number]>
SelectRewardForm.Tabs = Tabs
SelectRewardForm.Dollars1 = DollarsForm1
SelectRewardForm.Dollars2 = DollarsForm2
SelectRewardForm.Swag = SwagForm
SelectRewardForm.Gift1 = GiftForm1
SelectRewardForm.Gift2 = GiftsForm2
SelectRewardForm.Card1 = CardsForm1
SelectRewardForm.Card2 = CardsForm2

export default SelectRewardForm
