import { ApiOutlined, MonitorOutlined } from '@ant-design/icons'
import { PerkEmpty } from 'components'
import {
  AURIGMA_PRODUCT_LINK_FILTER_ID,
  AURIGMA_PRODUCT_LINK_ID,
  AURIGMA_PRODUCT_LINK_PRODUCT_ID,
  AURIGMA_PRODUCT_LINK_STOREFRONT_ID,
  AURIGMA_PRODUCT_LINK_VERSION_ID,
} from 'constants/params'
import { CustomerCanvasFrame } from 'features'
import { useSearchParams } from 'react-router-dom'
import { ProductLink } from 'types'

function ErrorPage() {
  return (
    <main>
      <PerkEmpty
        iconNode={
          <ApiOutlined
            className="text-muted-foreground"
            style={{ fontSize: 32 }}
          />
        }
        header="Oops! Something went wrong"
        description="We couldn't load your data. Please try again later."
      />
    </main>
  )
}

function EmptyPage() {
  return (
    <PerkEmpty
      iconNode={
        <MonitorOutlined
          className="text-muted-foreground"
          style={{ fontSize: 32 }}
        />
      }
      header="Nothing found"
      description="We couldn't find the product you're looking for."
    />
  )
}

function OrgSwagProductCustomizerPage() {
  const [searchParams] = useSearchParams()

  const id = searchParams.get(AURIGMA_PRODUCT_LINK_ID)
  const productVersionId = searchParams.get(AURIGMA_PRODUCT_LINK_VERSION_ID)
  const productId = searchParams.get(AURIGMA_PRODUCT_LINK_PRODUCT_ID)
  const productFilterId = searchParams.get(AURIGMA_PRODUCT_LINK_FILTER_ID)
  const storefrontProductId = searchParams.get(
    AURIGMA_PRODUCT_LINK_STOREFRONT_ID
  )

  if (
    !id ||
    !productVersionId ||
    !productId ||
    !productFilterId ||
    !storefrontProductId
  ) {
    return <EmptyPage />
  }

  const productLink: ProductLink = {
    id: Number(id),
    productVersionId: Number(productVersionId),
    productId: Number(productId),
    productFilterId: Number(productFilterId),
    storefrontProductId,
  }

  return (
    <main className="flex-1">
      <CustomerCanvasFrame productLink={productLink} />
    </main>
  )
}

export default {
  Component: OrgSwagProductCustomizerPage,
  ErrorBoundary: ErrorPage,
}
