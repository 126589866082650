import { GetFeaturedProgramTemplates } from 'api/databaseCalls'
import { ProgramTemplate } from 'gen/perkup/v1/contentful_pb'
import { useEffect, useState } from 'react'
import { sortFeaturedTemplates } from 'utils/templates'

export function useFeaturedProgramTemplates() {
  const [
    isLoadingFeaturedProgramTemplates,
    setIsLoadingFeaturedProgramTemplates,
  ] = useState(false)
  const [featuredProgramTemplates, setFeaturedProgramTemplates] = useState<
    ProgramTemplate[]
  >([])

  useEffect(() => {
    setIsLoadingFeaturedProgramTemplates(true)
    GetFeaturedProgramTemplates()
      .then(templates => {
        const rankedTemplates = templates.filter(t => t.rank)
        const nonRankedTemplates = templates.filter(t => !t.rank)
        const sortedTemplates = sortFeaturedTemplates({
          programTemplates: rankedTemplates,
        })
        setFeaturedProgramTemplates(sortedTemplates.concat(nonRankedTemplates))
      })
      .finally(() => setIsLoadingFeaturedProgramTemplates(false))
  }, [])

  return { featuredProgramTemplates, isLoadingFeaturedProgramTemplates }
}
