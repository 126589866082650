import { ApiOutlined, EditOutlined } from '@ant-design/icons'
import { Modal, message } from 'antd'
import { PerkEmpty, PerkScrollbars } from 'components'
import {
  AURIGMA_PRODUCT_LINK_FILTER_ID,
  AURIGMA_PRODUCT_LINK_ID,
  AURIGMA_PRODUCT_LINK_PRODUCT_ID,
  AURIGMA_PRODUCT_LINK_STOREFRONT_ID,
  AURIGMA_PRODUCT_LINK_VERSION_ID,
} from 'constants/params'
import { DEFAULT_ROUTES } from 'constants/routes'
import { AlgoliaBrowseProducts, ProductDetails } from 'features'
import { ProductVariant } from 'gen/perkup/v1/product_variant_pb'
import { useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useNavigate } from 'react-router-dom'
import { getCustomerCanvasProductLinkByReference } from 'services'

function Error() {
  return (
    <main>
      <PerkEmpty
        iconNode={
          <ApiOutlined
            className="text-muted-foreground"
            style={{ fontSize: 32 }}
          />
        }
        header="Oops! Something went wrong"
        description="We couldn't load this page. Please try again later."
      />
    </main>
  )
}

function OrgSwagBlankProductsPage() {
  const navigate = useNavigate()

  const [messageApi, contextHolder] = message.useMessage()

  const [isLoadingCustomizer, setIsLoadingCustomizer] = useState(false)
  const [productVariantToShow, setProductVariantToShow] =
    useState<ProductVariant>()

  const handleNavigateToCustomize = async (productVariant: ProductVariant) => {
    setIsLoadingCustomizer(true)
    const productLink = await getCustomerCanvasProductLinkByReference(
      productVariant.productId
    )

    if (!productLink) {
      messageApi.error('Could not open product customizer')
      return
    }

    const {
      id,
      productVersionId,
      productId,
      productFilterId,
      storefrontProductId,
    } = productLink

    navigate(
      `${DEFAULT_ROUTES.ORGANIZATION.SWAG.CUSTOMIZE}?${AURIGMA_PRODUCT_LINK_ID}=${id}&${AURIGMA_PRODUCT_LINK_VERSION_ID}=${productVersionId}&${AURIGMA_PRODUCT_LINK_PRODUCT_ID}=${productId}&${AURIGMA_PRODUCT_LINK_FILTER_ID}=${productFilterId}&${AURIGMA_PRODUCT_LINK_STOREFRONT_ID}=${storefrontProductId}`
    )

    setIsLoadingCustomizer(false)
  }

  return (
    <>
      <Helmet>
        <title>On Demand Catalog | Swag</title>
      </Helmet>

      {contextHolder}
      <main style={{ width: '100%', padding: '32px' }}>
        <AlgoliaBrowseProducts>
          <AlgoliaBrowseProducts.Blank
            title="On Demand Catalog"
            stickyNavTopAmount={60}
            onProductCardClick={setProductVariantToShow}
            onCardSelectButtonClick={handleNavigateToCustomize}
          />
        </AlgoliaBrowseProducts>
      </main>

      <Modal
        width={1280}
        centered
        title="‎"
        open={!!productVariantToShow}
        footer={false}
        onCancel={() => setProductVariantToShow(undefined)}
      >
        <PerkScrollbars style={{ maxHeight: '88vh' }}>
          {productVariantToShow && (
            <ProductDetails
              key={productVariantToShow.id}
              productVariant={productVariantToShow}
              showShipping
              showPrice
              showShippingCountries
              submitButtonProps={{
                children: 'Customize',
                icon: <EditOutlined />,
                loading: isLoadingCustomizer,
                disabled: isLoadingCustomizer,
              }}
              onSubmit={handleNavigateToCustomize}
              disableVariantOptions
              withQuantityTable
            />
          )}
        </PerkScrollbars>
      </Modal>
    </>
  )
}

export default {
  Component: OrgSwagBlankProductsPage,
  ErrorBoundary: Error,
}
