import { PartialMessage, Timestamp } from '@bufbuild/protobuf'
import { captureException } from '@sentry/react'
import {
  collection,
  deleteField,
  doc,
  getDocs,
  getFirestore,
  query,
  setDoc,
  where,
} from 'firebase/firestore'
import { Individual } from 'gen/perkup/v1/individual_pb'
import { OrgList, OrgList_ListStatus } from 'gen/perkup/v1/organization_pb'
import { Rule } from 'gen/perkup/v1/rules_pb'
import { camelCase } from 'lodash-es'
import { toSentry } from 'utils'
import { converter, storedId } from 'utils/firestore'

export async function createOrgList({
  orgId,
  name,
}: {
  orgId: string
  name: string
}) {
  const fieldKey = camelCase(name)

  const db = getFirestore()
  const colRef = doc(
    db,
    `organizations/${orgId}/lists/${fieldKey}`
  ).withConverter(converter(OrgList))
  return setDoc(
    colRef,
    new OrgList({
      name,
      status: OrgList_ListStatus.active,
      id: fieldKey,
      created: Timestamp.now(),
    })
  ).catch(error => {
    console.error(error)
    captureException(toSentry(error), {
      contexts: {
        createOrgList: {
          orgId,
          name,
        },
      },
    })
  })
}

async function removeListFromRules({
  orgId,
  listId,
}: {
  orgId: string
  listId: string
}) {
  const db = getFirestore()
  const colRef = collection(db, `organizations/${orgId}/rules`).withConverter(
    converter(Rule)
  )
  const q = query(colRef, where('labels', 'array-contains', listId))
  const rulesWithList = await getDocs(q)
  rulesWithList.forEach(ruleDoc =>
    setDoc(
      ruleDoc.ref,
      { labels: { [listId]: deleteField() } },
      { merge: true }
    ).catch(error => {
      console.error(error)
      captureException(toSentry(error), {
        contexts: {
          removeListFromRules: {
            orgId,
            listId,
          },
        },
      })
    })
  )
}

export function archiveOrgList({
  orgId,
  listId,
}: {
  orgId: string
  listId: string
}) {
  const db = getFirestore()
  const docRef = doc(
    db,
    `organizations/${orgId}/lists/${listId}`
  ).withConverter(converter(OrgList))

  const update: PartialMessage<OrgList> = {
    status: OrgList_ListStatus.archived,
  }

  setDoc(docRef, update, { merge: true }).catch(error => {
    console.error(error)
    captureException(toSentry(error), {
      contexts: {
        archiveOrgList: {
          orgId,
          listId,
        },
      },
    })
  })

  removeListFromRules({ orgId, listId }).catch(error => {
    console.error(error)
    captureException(toSentry(error), {
      contexts: {
        archiveOrgList: {
          orgId,
          listId,
        },
      },
    })
  })
}

export function addLabelToIndividual({
  orgId,
  key,
  value,

  individualId,
}: {
  orgId: string
  key: string
  value: string
  individualId: string
}) {
  const db = getFirestore()
  const docRef = doc(
    db,
    `organizations/${orgId}/individuals/${individualId}`
  ).withConverter(storedId(Individual))

  setDoc(docRef, { labels: { [key]: value } }, { merge: true }).catch(error => {
    console.error(error)
    captureException(toSentry(error), {
      contexts: {
        addLabelToIndividual: {
          orgId,
          key,
          value,
          individualId,
        },
      },
    })
  })
}

export function removeLabelFromIndividual({
  orgId,
  key,
  individualId,
}: {
  orgId: string
  key: string
  individualId: string
}) {
  const db = getFirestore()
  const docRef = doc(
    db,
    `organizations/${orgId}/individuals/${individualId}`
  ).withConverter(storedId(Individual))
  setDoc(docRef, { labels: { [key]: deleteField() } }, { merge: true }).catch(
    error => {
      console.error(error)
      captureException(toSentry(error), {
        contexts: {
          removeLabelFromIndividual: {
            orgId,
            key,
            individualId,
          },
        },
      })
    }
  )
}

export function removeEmailFromindividual({
  orgId,
  email,
  individualId,
}: {
  orgId: string
  email: string
  individualId: string
}) {
  const db = getFirestore()
  const docRef = doc(
    db,
    `organizations/${orgId}/individuals/${individualId}`
  ).withConverter(storedId(Individual))
  return setDoc(
    docRef,
    { emails: { [email]: deleteField() } },
    { merge: true }
  ).catch(error => {
    console.error(error)
    captureException(toSentry(error), {
      contexts: {
        removeEmailFromindividual: {
          orgId,
          email,
          individualId,
        },
      },
    })
  })
}
