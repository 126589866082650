import { useEffect, useState } from 'react'
import { getSageList } from 'services/sage'
import { SageListItem } from 'types/sage'

export function useSageList({ listType }: { listType: string }) {
  const [items, setItems] = useState<SageListItem[]>([])
  const [hasLoaded, setHasLoaded] = useState(false)

  useEffect(() => {
    getSageList({ listType })
      .then(setItems)
      .finally(() => setHasLoaded(true))
  }, [listType])

  return { items, hasLoaded }
}
