import { Button, Heading, Pane, Text } from 'evergreen-ui'

function PageNotFound() {
  return (
    <Pane textAlign="center" marginTop={48}>
      <Heading size={900} marginBottom={12}>
        404
      </Heading>
      <Text size={500}>Page not found</Text>
      <div>
        <Button is="a" appearance="minimal" href="/" marginTop={16}>
          Return to home page
        </Button>
      </div>
    </Pane>
  )
}

export default PageNotFound
