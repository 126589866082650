import { InsightsPrintableSections } from 'types'

export const DEFAULT_INSIGHTS_PRINT_SECTIONS: InsightsPrintableSections = {
  rewardsSentRow1: true,
  acceptanceRateRow2: true,
  sendersBreakdownRow2: true,
  programOccasionsRow3: true,
  coreValuesRow3: true,
  orderDistributionRow4: true,
  topSendersRow5: true,
  topRecipientsRow5: true,
  thankYouNotesRow6: true,
  topVariantsRow6: true,
}

export const INSIGHTS_PRINT_KEY_TO_LABEL: Record<
  keyof InsightsPrintableSections,
  string
> = {
  rewardsSentRow1: 'Rewards Sent',
  acceptanceRateRow2: 'Acceptance Rate',
  sendersBreakdownRow2: 'Senders Breakdown',
  programOccasionsRow3: 'Program Occasions',
  coreValuesRow3: 'Core Values',
  orderDistributionRow4: 'Order Distribution',
  topSendersRow5: 'Top Senders',
  topRecipientsRow5: 'Top Recipients',
  thankYouNotesRow6: 'Thank You Notes',
  topVariantsRow6: 'Top Variants',
}
