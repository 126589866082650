import { CheckOutlined, CloseOutlined, UploadOutlined } from '@ant-design/icons'
import { Alert, Button, DatePicker, Input, Switch, Upload, message } from 'antd'
import { UploadChangeParam, UploadFile } from 'antd/es/upload'
import { uploadOrgImage } from 'api/databaseCalls'
import { OrgContext } from 'context'
import dayjs from 'dayjs'
import { Heading, Text } from 'evergreen-ui'
import { useContext, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { OrderProductsFormType } from 'types/react-hook-form'

function UploadShippingLabel() {
  const { id: orgId } = useContext(OrgContext)
  const { control } = useFormContext<OrderProductsFormType>()

  const [isLoadingUpload, setIsLoadingUpload] = useState(false)

  const [messageApi, contextHolder] = message.useMessage()

  return (
    <>
      {contextHolder}
      <Controller
        rules={{
          validate: value => {
            if (!!value?.required && !value?.url) {
              return 'Please upload a shipping label'
            }
            return undefined
          },
        }}
        control={control}
        name="event.shippingLabel"
        render={({ field: { value, onChange }, fieldState: { invalid } }) => (
          <div className="flex flex-col gap-2">
            <div className="flex gap-2">
              <Text>Upload shipping label or shipping instructions</Text>
              <Switch
                value={!!value?.required}
                onChange={checked => {
                  onChange({
                    required: checked,
                  })
                }}
                unCheckedChildren={<CloseOutlined />}
                checkedChildren={<CheckOutlined />}
              />
            </div>
            {!!value?.required && (
              <div className="flex align-middle gap-2">
                <Upload
                  disabled={isLoadingUpload}
                  maxCount={1}
                  showUploadList={false}
                  onChange={async (
                    upload: UploadChangeParam<UploadFile<any>>
                  ) => {
                    if (upload.file.percent === 100) {
                      setIsLoadingUpload(true)
                      const img = await uploadOrgImage({
                        orgId,
                        upload,
                        directory: 'shippingLabels',
                      })
                      if (!img) {
                        messageApi.error('Failed to upload image')
                      } else {
                        onChange({
                          ...value,
                          url: img.url,
                          fileName: img.metaData.name,
                        })
                        messageApi.success('Image uploaded successfully')
                      }
                    }
                    setIsLoadingUpload(false)
                  }}
                >
                  <Button
                    danger={invalid}
                    disabled={isLoadingUpload}
                    loading={isLoadingUpload}
                    icon={<UploadOutlined />}
                  >
                    Upload
                  </Button>
                </Upload>
                {value && (
                  <Text display="flex" alignItems="center">
                    {value.fileName}
                  </Text>
                )}
                {invalid && (
                  <Text color="red" display="flex" alignItems="center">
                    Please upload a shipping label.
                  </Text>
                )}
              </div>
            )}
          </div>
        )}
      />
    </>
  )
}

export function EventDetailsForm() {
  const { control } = useFormContext<OrderProductsFormType>()

  return (
    <div className="flex flex-col gap-4">
      <Heading size={600}>Event details</Heading>
      <Alert
        type="info"
        showIcon
        message="Earliest arrival date must be at least 5 business days from today."
      />
      <div className="flex gap-4">
        <div className="flex flex-1 flex-col gap-2">
          <Text>Event name</Text>
          <Controller
            control={control}
            name="event.name"
            rules={{
              required: 'Please enter an event name.',
            }}
            render={({ field, fieldState: { invalid } }) => (
              <Input
                {...field}
                status={invalid ? 'error' : undefined}
                placeholder="Event name"
              />
            )}
          />
        </div>
        <div className="flex flex-1 flex-col gap-2">
          <Text>Delivery window</Text>
          <Controller
            control={control}
            name="event.deliveryWindow"
            rules={{
              required: 'Please enter a delivery window.',
              validate: rangeVals => {
                return !rangeVals?.some(v => v === null)
              },
            }}
            render={({ field, fieldState: { invalid } }) => (
              <DatePicker.RangePicker
                {...field}
                status={invalid ? 'error' : undefined}
                format="YYYY/MM/DD"
                allowClear
                disabledDate={current =>
                  current && current < dayjs().add(5, 'day').startOf('day')
                }
              />
            )}
          />
        </div>
      </div>

      <div className="flex flex-col gap-2">
        <Text>
          Venue information (e.g. booth number, specific instructions)
        </Text>
        <Controller
          control={control}
          name="event.venueInfo"
          rules={{
            required: 'Please enter a venue information.',
          }}
          render={({ field, fieldState: { invalid } }) => (
            <Input.TextArea
              {...field}
              status={invalid ? 'error' : undefined}
              placeholder="Venue / Company / Booth"
              className="min-h-28"
            />
          )}
        />
      </div>
      <UploadShippingLabel />
    </div>
  )
}
