export function createContentfulAssetUrl({
  url,
  height,
  width,
}: {
  url: string
  height: number
  width: number
}) {
  return `https://images.ctfassets.net/${url}?w=${width}&h=${height}`
}
