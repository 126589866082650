import { Flex, Grid, Image, Space } from 'antd'
import GiftsThatGoTheExtraMile from 'assets/gifts-that-go-the-extra-mile.png'
import ChowNowLogoSmall from 'assets/partner-logos/chow-now-logo-small.png'
import CornerstoneLogoSmall from 'assets/partner-logos/cornerstone-logo-small.png'
import LumenLogoSmall from 'assets/partner-logos/lumen-logo-small.png'
import MicrosoftLogoSmall from 'assets/partner-logos/microsoft-logo-small.png'
import ProductHuntLogoSmall from 'assets/partner-logos/product-hunt-logo-small.png'
import SpringHealthLogoSmall from 'assets/partner-logos/spring-health-logo-small.png'
import { AuthenticationForm } from 'components'
import { PERKUP_PRIMARY_COLOR } from 'constants/colors'
import { Heading } from 'evergreen-ui'

const { useBreakpoint } = Grid

const partnerLogos = [
  { src: CornerstoneLogoSmall, alt: 'Cornerstone logo' },
  { src: SpringHealthLogoSmall, alt: 'Spring Health logo' },
  { src: MicrosoftLogoSmall, alt: 'Microsoft logo' },
  { src: LumenLogoSmall, alt: 'Lumen logo' },
  { src: ProductHuntLogoSmall, alt: 'Product Hunt logo' },
  { src: ChowNowLogoSmall, alt: 'Chow Now logo' },
]

function Hero() {
  return (
    <div style={{ position: 'relative' }}>
      <Image
        preview={false}
        src={GiftsThatGoTheExtraMile}
        alt="Banner of gifts that go the extra mile"
        width="100%"
        style={{ maxWidth: 700, minWidth: 400 }}
      />

      <Heading position="absolute" top={0} left={0} size={900}>
        <span style={{ color: PERKUP_PRIMARY_COLOR }}>Gifts</span> that go the{' '}
        <br />
        extra mile.
      </Heading>
    </div>
  )
}

export default function SignUpPage() {
  const screens = useBreakpoint()

  const lgScreen = screens.lg
  const xxlScreen = screens.xxl

  const determinePartnersGap = () => {
    if (xxlScreen) return 40
    if (lgScreen) return 32
    return 24
  }

  const determinePartnersHeight = () => {
    if (xxlScreen) return 56
    if (lgScreen) return 48
    return 32
  }

  return (
    <Flex
      vertical={!lgScreen}
      gap={xxlScreen ? 120 : 72}
      align="center"
      justify="center"
      style={{ padding: lgScreen ? 72 : 16, height: '100svh' }}
    >
      <Space style={{ maxWidth: 356, minWidth: 320 }}>
        <AuthenticationForm />
      </Space>

      <Flex vertical align="center" gap={32}>
        {lgScreen && <Hero />}

        <Flex vertical align="center" gap={16}>
          <Heading size={200}>Trusted by leading companies</Heading>
          <Flex gap={determinePartnersGap()} wrap justify="center">
            {partnerLogos.map(({ src, alt }) => (
              <Image
                preview={false}
                key={src}
                src={src}
                alt={alt}
                height={determinePartnersHeight()}
              />
            ))}
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )
}
