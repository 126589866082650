import { ListProductVariantsByProductIds } from 'api/databaseCalls'
import { SHOPIFY_PERKUP_FEE_MULTIPLIER } from 'constants/money'
import { ProductCollection } from 'gen/perkup/v1/product_collections_pb'
import { ProductVariant } from 'gen/perkup/v1/product_variant_pb'
import { toNumber } from 'lodash-es'

export function getDisplayCurrency({
  org,
  user,
}: {
  org: any
  user: any
}): string {
  return (
    (user.displayCurrency as string) ||
    (org?.displayCurrency as string) ||
    'usd'
  )
}

export function calculateShopifyProductVariantTotal(
  productVariant: ProductVariant
) {
  const productPrice = Number(productVariant.amount)
  const perkUpFee = productPrice * SHOPIFY_PERKUP_FEE_MULTIPLIER
  const shippingCost = 0
  const estimatedTax = 0
  const totalCost = productPrice + shippingCost + estimatedTax + perkUpFee

  return totalCost
}

export async function calculateMaxVariantPriceByProductIds({
  productIds,
  orgId,
}: {
  productIds: string[]
  orgId: string
}) {
  const variants = await ListProductVariantsByProductIds({
    productIds,
    uniq: false,
    orgId,
  })
  if (!variants) return undefined
  const maxPrice = variants.reduce((acc, product) => {
    if (Number(product.amount) > acc) {
      return Number(product.amount)
    }
    return acc
  }, 0)
  const productPrice = maxPrice
  const shippingCost = 0
  const estimatedTax = 0
  const perkUpFee = productPrice * SHOPIFY_PERKUP_FEE_MULTIPLIER
  const totalCost = productPrice + shippingCost + estimatedTax + perkUpFee

  return totalCost
}

export function calculateProductCollectionMinPrice(
  productCollection?: ProductCollection
) {
  const collectionProducts = Object.values(productCollection?.products || {})
  if (!collectionProducts.length) return undefined

  // Get collection minimum price
  const maxPrice = collectionProducts.reduce((acc, product) => {
    if (Number(product.maxAmount) > acc) {
      return Number(product.maxAmount)
    }
    return acc
  }, 0)

  return maxPrice
}

export function sortByDollarLabel(
  a: {
    label: string
    value: string
  },
  b: {
    label: string
    value: string
  }
) {
  const aLabelNumber = toNumber(a.label.substring(a.label.indexOf('$') + 1))
  const bLabelNumber = toNumber(b.label.substring(b.label.indexOf('$') + 1))
  if (aLabelNumber < bLabelNumber) return -1
  if (aLabelNumber > bLabelNumber) return 1
  return 0
}
