import { Flex } from 'antd'
import { Text } from 'evergreen-ui'
import { getDateTimeString } from 'utils'

export function LongDateDisplay({
  date,
}: {
  date: Parameters<typeof getDateTimeString>[0]
}) {
  return (
    <Flex
      style={{
        maxWidth: '190px',
        flexWrap: 'wrap',
        width: 'max-content',
      }}
      gap={4}
    >
      <Text>
        {getDateTimeString(date, {
          dateOnly: true,
          longDisplay: true,
        })}
      </Text>
      <Text color="muted">{getDateTimeString(date, { timeOnly: true })}</Text>
    </Flex>
  )
}
