import { Skeleton } from 'antd'
import { Pane } from 'evergreen-ui'

export function DirectorySkeleton({ rows }: { rows: number }) {
  return (
    <Pane marginTop={16}>
      {new Array<undefined>(rows).fill(undefined).map((_, i) => {
        const id = i

        return (
          <Pane
            key={id}
            height={64}
            display="flex"
            width="100%"
            alignItems="center"
          >
            <Pane width="36%" display="flex" alignItems="center">
              <Skeleton
                avatar={{ size: 'large' }}
                title={false}
                active
                paragraph={false}
                style={{ width: '20%', marginBottom: '15px' }}
              />

              <Skeleton
                title={false}
                active
                paragraph={{ width: '80%', rows: 1 }}
              />
            </Pane>
            <Pane width="16%">
              <Skeleton active title={false} paragraph={{ rows: 1 }} />
            </Pane>
            <Pane width="16%">
              <Skeleton active title={false} paragraph={{ rows: 1 }} />
            </Pane>
            <Pane width="16%">
              <Skeleton active title={false} paragraph={{ rows: 1 }} />
            </Pane>
            <Pane width="16%">
              <Skeleton active title={false} paragraph={{ rows: 1 }} />
            </Pane>
          </Pane>
        )
      })}
    </Pane>
  )
}
