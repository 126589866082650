import { TruckOutlined } from '@ant-design/icons'
import { Flex } from 'antd'
import { countryIso3ToIso2 } from 'constants/countries'
import { CountryContext } from 'context'
import { Text, useTheme } from 'evergreen-ui'
import { ProductVariant } from 'gen/perkup/v1/product_variant_pb'
import { ShippingAddress } from 'gen/perkup/v1/root_user_pb'
import { useContext } from 'react'
import {
  estimateShippingTimeline,
  quickestShippingTimeline,
} from 'services/shipping'
import { Iso2 } from 'types/countries'

export function VariantEstimatedShippingTime({
  productVariant,
  shippingAddress,
  quickest = false,
}: {
  productVariant: ProductVariant
  shippingAddress?: ShippingAddress
  quickest?: boolean
}) {
  const theme = useTheme()
  const country = useContext(CountryContext)

  const timelineStringToDisplay = () => {
    if (quickest) {
      const timeline = quickestShippingTimeline(productVariant)
      return timeline
        ? ` Quickest delivery ${timeline.displayStr}`
        : 'Error calculating shipping time'
    }

    const shippingTarget = (shippingAddress?.country.toLowerCase() ||
      countryIso3ToIso2[country.iso3]) as Iso2

    const timeline = estimateShippingTimeline(productVariant, shippingTarget)

    return timeline
      ? ` Estimated delivery ${timeline.displayStr} to ${shippingTarget.toUpperCase()}`
      : `Shipping not available to ${shippingTarget.toUpperCase()}`
  }

  return (
    <Flex gap={4} vertical>
      <Flex gap={8} align="center">
        <TruckOutlined style={{ fontSize: 24, color: theme.colors.muted }} />
        <Text color="muted">{timelineStringToDisplay()}</Text>
      </Flex>
    </Flex>
  )
}
