import { ArrowLeftOutlined } from '@ant-design/icons'
import { ButtonProps } from 'antd'
import { PerkIconTooltipButton } from 'components'
import { To, useNavigate } from 'react-router'

export function BackIconButton({
  to,
  type = 'text',
}: {
  to?: To
  type?: ButtonProps['type']
}) {
  const navigate = useNavigate()
  return (
    <PerkIconTooltipButton
      title="Go back"
      type={type}
      icon={<ArrowLeftOutlined />}
      onClick={() => {
        if (to) {
          navigate(to)
        } else {
          navigate(-1)
        }
      }}
    />
  )
}
