import { Flex, InputNumber } from 'antd'
import {
  updateAccountThreshold,
  updateIndividualBlockedNotifications,
} from 'api/databaseCalls'
import { IndividualContext, OrgContext } from 'context'
import { Heading, Text } from 'evergreen-ui'
import { Account } from 'gen/perkup/v1/account_pb'
import { Notification_Type } from 'gen/perkup/v1/notification_pb'
import { useContext, useState } from 'react'
import { SwitchAndText } from './SwitchAndText'

export function ThresholdNotifications({ account }: { account: Account }) {
  const org = useContext(OrgContext)
  const individual = useContext(IndividualContext)
  const orgId = org.id
  const accountId = account.id

  const defaultFixedThresholdDollars = account.fixedThresholdAmount
    ? Number(account.fixedThresholdAmount) / 100
    : 0

  const [fixedThresholdDollars, setFixedThresholdDollars] = useState(
    defaultFixedThresholdDollars
  )

  const handleInputChange = (value: number | null) => {
    if (accountId && value !== null) {
      updateAccountThreshold({
        accountId,
        fixedThresholdAmount: value * 100,
        orgId,
      })
      setFixedThresholdDollars(value)
    }
  }

  const handleUpdateNotificationSettings = (checked: boolean) => {
    updateIndividualBlockedNotifications({
      orgId,
      individualId: individual.id,
      block: !checked,
      notificationType: Notification_Type.lowAccountBalanceNotification,
    })
  }

  const individualBlockedNotifications =
    individual?.notificationSettings?.[
      Notification_Type[Notification_Type.lowAccountBalanceNotification]
    ]?.block

  return (
    <Flex vertical gap={16}>
      <Heading>Balance notifications</Heading>
      <Flex gap={8}>
        <SwitchAndText
          label="Receive email notifications when your issuing balance falls below a
          threshold."
          onCheck={handleUpdateNotificationSettings}
          checked={!individualBlockedNotifications}
        />
      </Flex>
      <Flex gap={16} align="center">
        <Text>Fixed threshold</Text>
        <InputNumber
          prefix="$"
          formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
          style={{ width: 160 }}
          value={fixedThresholdDollars}
          onChange={handleInputChange}
        />
      </Flex>
    </Flex>
  )
}
