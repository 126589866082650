import { Heading, Pane, Text, useTheme } from 'evergreen-ui'
import isNumber from 'lodash-es/isNumber'
import { numToDollars } from 'utils'

export default function BalanceLabel({
  isImportant = false,
  label = 'Balance',
  subHeading,
  balance = 0,
  balanceContainerProps,
  link,
}: {
  isImportant?: boolean
  label: string
  subHeading?: string
  balance: number | undefined
  balanceContainerProps?: any
  link?: JSX.Element
}) {
  const theme = useTheme()

  const isNegativeBalance = isNumber(balance) && balance < 0
  const balanceColor = isNegativeBalance ? theme.colors.red500 : ''

  const balanceDisplay = numToDollars(balance)

  return (
    <>
      <Pane
        height="100%"
        display="flex"
        justifyContent="end"
        {...balanceContainerProps}
      >
        <Heading
          alignSelf="center"
          size={isImportant ? 900 : 500}
          color={balanceColor}
        >
          {balanceDisplay}
        </Heading>
      </Pane>
      <Pane display="flex" flexDirection="column" gap={4}>
        <Pane display="flex" alignItems="center" gap={16}>
          {link || <Heading size={500}>{label}</Heading>}
        </Pane>
        {subHeading && <Text color="muted">{subHeading}</Text>}
      </Pane>
    </>
  )
}
