import { GetIndividualByUserId } from 'api/databaseCalls'
import { Individual } from 'gen/perkup/v1/individual_pb'
import useIds from 'hooks/useIds'
import { useEffect, useState } from 'react'

export function useIndividualByUserId({ userId }: { userId?: string }) {
  const { orgId } = useIds()
  const [individual, setIndividual] = useState<Individual>()
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (!userId || !orgId) return
    GetIndividualByUserId({
      orgId,
      userId,
    })
      .then(individual => setIndividual(individual))
      .finally(() => setIsLoading(false))
  }, [orgId, userId])

  return { individual, isLoading }
}
