import { PlusOutlined } from '@ant-design/icons'
import { Flex } from 'antd'
import { OrgTemplateCardGrid, ORG_TEMPLATE_CARD_STYLES } from 'components'
import { DEFAULT, DEFAULT_ROUTES, OCCASION } from 'constants/routes'
import { OrgContext } from 'context'
import { Heading, Pane, Strong } from 'evergreen-ui'
import { Template } from 'gen/perkup/v1/org_template_pb'
import { Program } from 'gen/perkup/v1/program_pb'
import { useContext } from 'react'
import { useNavigate } from 'react-router'

export function SelectTemplateForm({
  program,
  templates,
  onSelectTemplate,
}: {
  program: Program
  templates: Template[]
  onSelectTemplate: (template: Template) => void
}) {
  const navigate = useNavigate()
  const org = useContext(OrgContext)
  const showStartFromScratch = !org.settings?.hideStartFromScratch

  return (
    <Flex vertical align="center" style={{ width: '100%' }} gap={16}>
      <Heading size={800}>Select a reward</Heading>
      <OrgTemplateCardGrid
        onSelectTemplate={onSelectTemplate}
        templates={templates}
      >
        {showStartFromScratch && (
          <Pane
            onClick={() => {
              navigate(
                `${DEFAULT_ROUTES.ORGANIZATION.REWARDS.NEW_REWARD}/${program.id}${OCCASION}${DEFAULT}`
              )
            }}
            {...ORG_TEMPLATE_CARD_STYLES}
            justifyContent="center"
          >
            <PlusOutlined />
            <Strong>Start from scratch</Strong>
          </Pane>
        )}
      </OrgTemplateCardGrid>
    </Flex>
  )
}
