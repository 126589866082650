import {
  Organization,
  Organization_StripeConnectAccountCapabilityStatus_Enum,
} from 'gen/perkup/v1/organization_pb'

export function getStripeCapabilitiesStatus({
  org,
}: {
  org: Organization
}): boolean {
  const stripeConnectAccountCapabilities = Object.entries(
    org?.stripeConnectAccountCapabilities
  )

  // check if all capabilities are active
  const activeConnectedAccount = stripeConnectAccountCapabilities.length
    ? stripeConnectAccountCapabilities.every(
        ([_, status]) =>
          status ===
          Organization_StripeConnectAccountCapabilityStatus_Enum.active
      )
    : false
  return activeConnectedAccount
}
