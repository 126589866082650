import { ListenToMemberById } from 'api/databaseCalls'
import { Member } from 'gen/perkup/v1/program_pb'
import useIds from 'hooks/useIds'
import { useEffect, useState } from 'react'

export function useListenToMember({
  memberId,
  programId,
}: {
  memberId?: string
  programId?: string
}) {
  const { orgId } = useIds()
  const [member, setMember] = useState<Member>()
  const [hasLoaded, setHasLoaded] = useState(false)

  // Listen to Member
  useEffect(() => {
    if (!orgId || !programId || !memberId) {
      return undefined
    }

    return ListenToMemberById({
      orgId,
      programId,
      memberId,
      cb: member => {
        setMember(member)
        setHasLoaded(true)
      },
    })
  }, [orgId, programId, memberId])

  return { member, hasLoaded }
}
