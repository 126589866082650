import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import useEmblaCarousel from 'embla-carousel-react'
import { WheelGesturesPlugin } from 'embla-carousel-wheel-gestures'
import { Heading, Pane, Size, Text } from 'evergreen-ui'
import { usePrevNextButtons } from 'hooks'
import { EmblaStyles } from 'types/embla'

const emblaStyles: EmblaStyles = {
  embla: {
    overflow: 'hidden',
  },
  viewport: {},
  container: {
    display: 'flex',
  },
  slide: {
    flex: '0 0 auto',
    minWidth: 0,
    maxWidth: '100%',
    paddingRight: 16,
  },
}

export function CustomCarousel({
  showSeeAllButton = false,
  showHeading = true,
  handleSeeMoreClick,
  header,
  handleCTAText = 'See all',
  children,
  headerIcon,
  headerSize = 600,
  headerNote,
}: {
  showSeeAllButton?: boolean
  showHeading?: boolean
  handleSeeMoreClick?: () => void
  header?: string
  handleCTAText?: string
  children?: JSX.Element[]
  headerIcon?: JSX.Element
  headerSize?: Size
  headerNote?: string
}) {
  const [emblaRef, emblaApi] = useEmblaCarousel(
    {
      align: 'start',
      dragFree: true,
      containScroll: 'trimSnaps',
    },
    [WheelGesturesPlugin()]
  )

  const {
    prevBtnDisabled,
    nextBtnDisabled,
    onPrevButtonClick,
    onNextButtonClick,
  } = usePrevNextButtons(emblaApi)

  const childrenCount = children?.length || 0

  const showArrows = childrenCount > 3

  return (
    <Pane display="flex" flexDirection="column" gap={16}>
      {showHeading && (
        <Pane
          display="flex"
          alignItems="center"
          gap={8}
          justifyContent="space-between"
        >
          <Pane display="flex" gap={16} alignItems="start">
            {headerIcon}
            <Pane display="flex" alignItems="center" gap={16}>
              <Heading size={headerSize}>{header}</Heading>
              {headerNote && (
                <Text whiteSpace="nowrap" color="muted">
                  {headerNote}
                </Text>
              )}
            </Pane>
          </Pane>
          {showSeeAllButton && (
            <Button type="default" onClick={handleSeeMoreClick}>
              {handleCTAText}
            </Button>
          )}
        </Pane>
      )}
      <Pane display="flex" alignItems="center" gap={16}>
        {showArrows && (
          <Pane>
            <Button
              icon={<LeftOutlined />}
              type="text"
              size="large"
              onClick={onPrevButtonClick}
              disabled={prevBtnDisabled}
            />
          </Pane>
        )}
        <Pane style={emblaStyles.embla} ref={emblaRef}>
          <Pane style={emblaStyles.container}>
            {children?.map(child => (
              <Pane key={child.key} style={emblaStyles.slide}>
                {child}
              </Pane>
            ))}
          </Pane>
        </Pane>

        {showArrows && (
          <Pane>
            <Button
              icon={<RightOutlined />}
              type="text"
              size="large"
              onClick={onNextButtonClick}
              disabled={nextBtnDisabled}
            />
          </Pane>
        )}
      </Pane>
    </Pane>
  )
}
