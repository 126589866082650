import { captureException } from '@sentry/react'
import { getStorage, listAll, ref } from 'firebase/storage'
import { FirebaseStorageImage } from 'types'
import { toSentry } from 'utils'
import { formatDownloadUrl } from 'utils/images'

export async function ListFirebaseStorageImages({
  orgId,
  suffix,
}: {
  orgId: string
  suffix?: string
}): Promise<FirebaseStorageImage[]> {
  try {
    const imageRoute = suffix ? `${orgId}/${suffix}/` : `${orgId}/`
    const customImagesRef = ref(getStorage(), imageRoute)
    const res = await listAll(customImagesRef)
    if (res.items.length === 0) return []
    return await Promise.all(
      res.items.map(async item => {
        const url = await formatDownloadUrl(item)
        return { name: item.name, url }
      })
    )
  } catch (error) {
    console.error(error)
    captureException(toSentry(error), {
      contexts: {
        ListFirebaseStorageImages: {
          orgId,
          suffix,
        },
      },
    })
    return []
  }
}

export async function GetFirebaseStorageImage({
  orgId,
  imageName,
  suffix,
}: {
  orgId: string
  imageName: string
  suffix?: string
}): Promise<FirebaseStorageImage | undefined> {
  try {
    const imageRoute = suffix
      ? `${orgId}/${suffix}/${imageName}`
      : `${orgId}/${imageName}`
    const customImageRef = ref(getStorage(), imageRoute)
    const url = await formatDownloadUrl(customImageRef)
    return { name: imageName, url }
  } catch (error) {
    console.error(error)
    captureException(toSentry(error), {
      contexts: {
        GetFirebaseStorageImage: {
          orgId,
          imageName,
          suffix,
        },
      },
    })
    return undefined
  }
}
