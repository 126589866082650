import { ClockCircleOutlined } from '@ant-design/icons'
import { updateProgram } from 'api/databaseCalls'
import { PerkIconTooltipButton } from 'components'
import { DEFAULT_ROUTES } from 'constants/routes'
import { serverTimestamp } from 'firebase/firestore'
import { Program, ProgramStatus } from 'gen/perkup/v1/program_pb'
import useIds from 'hooks/useIds'
import { useNavigate } from 'react-router'

export function ExpireReward({ program }: { program?: Program }) {
  const navigate = useNavigate()
  const { orgId } = useIds()
  const canExpireProgram = program?.status === ProgramStatus.active

  if (!canExpireProgram || !program) return null
  const programId = program.id

  const handleExpireProgram = () => {
    updateProgram({
      orgId,
      programId,
      program: {
        status: ProgramStatus.inactive,
        endsOn: serverTimestamp(),
      },
    }).finally(() => {
      navigate(DEFAULT_ROUTES.ORGANIZATION.REWARDS.SPOT)
    })
  }

  return (
    <PerkIconTooltipButton
      title="Set as expired"
      onClick={handleExpireProgram}
      type="text"
      icon={<ClockCircleOutlined />}
    />
  )
}
