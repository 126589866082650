// @generated by protoc-gen-es v1.6.0 with parameter "target=ts"
// @generated from file perkup/v1/account.proto (package perkup.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, protoInt64, Timestamp } from "@bufbuild/protobuf";

/**
 * Firestore /organizations/:id/accounts
 *
 * @generated from message perkup.v1.Account
 */
export class Account extends Message<Account> {
  /**
   * personal fund account will have `pf_{individualId}` prefix for the id
   *
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: google.protobuf.Timestamp created = 2;
   */
  created?: Timestamp;

  /**
   * @generated from field: int64 balance = 3;
   */
  balance = protoInt64.zero;

  /**
   * @generated from field: string name = 4;
   */
  name = "";

  /**
   * @generated from field: int64 spent = 5;
   */
  spent = protoInt64.zero;

  /**
   * @generated from field: optional int32 fixed_threshold_amount = 6;
   */
  fixedThresholdAmount?: number;

  /**
   * @generated from field: perkup.v1.AccountType.Enum type = 7;
   */
  type = AccountType_Enum.ACCOUNT_TYPE_UNSPECIFIED;

  /**
   * id of the individual who created the account
   *
   * @generated from field: optional string individual_id = 8;
   */
  individualId?: string;

  /**
   * @generated from field: bool is_hidden = 11;
   */
  isHidden = false;

  /**
   *
   * Organization funds default new account
   * 'permissions.${individualId}': full
   * 'permissions.admin': full
   * 'permissions.manager': <omit no access> 
   *
   * PersonalFunds default new account
   * 'permissions.${individualId}': full
   *
   * @generated from field: map<string, perkup.v1.Account.Permission> permissions = 9;
   */
  permissions: { [key: string]: Account_Permission } = {};

  /**
   * @generated from field: int64 program_reserved = 10;
   */
  programReserved = protoInt64.zero;

  constructor(data?: PartialMessage<Account>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "perkup.v1.Account";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "created", kind: "message", T: Timestamp },
    { no: 3, name: "balance", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "spent", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 6, name: "fixed_threshold_amount", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 7, name: "type", kind: "enum", T: proto3.getEnumType(AccountType_Enum) },
    { no: 8, name: "individual_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 11, name: "is_hidden", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 9, name: "permissions", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "enum", T: proto3.getEnumType(Account_Permission)} },
    { no: 10, name: "program_reserved", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Account {
    return new Account().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Account {
    return new Account().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Account {
    return new Account().fromJsonString(jsonString, options);
  }

  static equals(a: Account | PlainMessage<Account> | undefined, b: Account | PlainMessage<Account> | undefined): boolean {
    return proto3.util.equals(Account, a, b);
  }
}

/**
 * @generated from enum perkup.v1.Account.Permission
 */
export enum Account_Permission {
  /**
   * @generated from enum value: PERMISSION_UNSPECIFIED = 0;
   */
  PERMISSION_UNSPECIFIED = 0,

  /**
   * Full access: Can invite users, view, fund, download reports and use funds to send
   *
   * @generated from enum value: full = 1;
   */
  full = 1,

  /**
   * Can send: Can view, fund, download reports and use funds to send rewards
   *
   * @generated from enum value: send = 2;
   */
  send = 2,

  /**
   * Can view: Can view and download reports
   *
   * @generated from enum value: view = 3;
   */
  view = 3,
}
// Retrieve enum metadata with: proto3.getEnumType(Account_Permission)
proto3.util.setEnumType(Account_Permission, "perkup.v1.Account.Permission", [
  { no: 0, name: "PERMISSION_UNSPECIFIED" },
  { no: 1, name: "full" },
  { no: 2, name: "send" },
  { no: 3, name: "view" },
]);

/**
 * group notification setting
 *
 * @generated from message perkup.v1.AccountType
 */
export class AccountType extends Message<AccountType> {
  constructor(data?: PartialMessage<AccountType>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "perkup.v1.AccountType";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AccountType {
    return new AccountType().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AccountType {
    return new AccountType().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AccountType {
    return new AccountType().fromJsonString(jsonString, options);
  }

  static equals(a: AccountType | PlainMessage<AccountType> | undefined, b: AccountType | PlainMessage<AccountType> | undefined): boolean {
    return proto3.util.equals(AccountType, a, b);
  }
}

/**
 * @generated from enum perkup.v1.AccountType.Enum
 */
export enum AccountType_Enum {
  /**
   * @generated from enum value: ACCOUNT_TYPE_UNSPECIFIED = 0;
   */
  ACCOUNT_TYPE_UNSPECIFIED = 0,

  /**
   * @generated from enum value: organizationFunds = 1;
   */
  organizationFunds = 1,

  /**
   * @generated from enum value: personalFunds = 2;
   */
  personalFunds = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(AccountType_Enum)
proto3.util.setEnumType(AccountType_Enum, "perkup.v1.AccountType.Enum", [
  { no: 0, name: "ACCOUNT_TYPE_UNSPECIFIED" },
  { no: 1, name: "organizationFunds" },
  { no: 2, name: "personalFunds" },
]);

/**
 * @generated from message perkup.v1.AccountRequest
 */
export class AccountRequest extends Message<AccountRequest> {
  /**
   * @generated from field: optional string id = 1;
   */
  id?: string;

  /**
   * @generated from field: string name = 2;
   */
  name = "";

  /**
   * @generated from field: optional int32 fixed_threshold_amount = 3;
   */
  fixedThresholdAmount?: number;

  constructor(data?: PartialMessage<AccountRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "perkup.v1.AccountRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "fixed_threshold_amount", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AccountRequest {
    return new AccountRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AccountRequest {
    return new AccountRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AccountRequest {
    return new AccountRequest().fromJsonString(jsonString, options);
  }

  static equals(a: AccountRequest | PlainMessage<AccountRequest> | undefined, b: AccountRequest | PlainMessage<AccountRequest> | undefined): boolean {
    return proto3.util.equals(AccountRequest, a, b);
  }
}

/**
 * Firestore /organizations/:id/accountTransactions
 *
 * @generated from message perkup.v1.AccountTransaction
 */
export class AccountTransaction extends Message<AccountTransaction> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * source account is the one transferring the funds out from
   *
   * @generated from field: string account_id = 2;
   */
  accountId = "";

  /**
   * source_id can be account id if type are between accounts transfer types
   * or it can be Order id when transaction type is Order
   * or it can be Invoice Id when transaction type is Stripe
   *
   * @generated from field: string source_id = 3;
   */
  sourceId = "";

  /**
   * Positive value is money in
   * Negative value is money out
   *
   * @generated from field: int64 amount = 4;
   */
  amount = protoInt64.zero;

  /**
   * @generated from field: google.protobuf.Timestamp created = 5;
   */
  created?: Timestamp;

  /**
   * @generated from field: perkup.v1.AccountTransaction.TransactionType type = 6;
   */
  type = AccountTransaction_TransactionType.TRANSFER_TYPE_UNSPECIFIED;

  /**
   * the individual who initiated the transfer
   *
   * @generated from field: string individual_id = 7;
   */
  individualId = "";

  /**
   * used for between account transfer. the transfer_id is the transfer out account transaction id.
   * it will be the tie between the two way transactions between accounts
   *
   * @generated from field: optional string transfer_id = 8;
   */
  transferId?: string;

  /**
   * the memo for the transaction (optional)
   * if type is an adjustment, it will be the reason for the adjustment
   *
   * @generated from field: optional string memo = 9;
   */
  memo?: string;

  constructor(data?: PartialMessage<AccountTransaction>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "perkup.v1.AccountTransaction";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "account_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "source_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "amount", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 5, name: "created", kind: "message", T: Timestamp },
    { no: 6, name: "type", kind: "enum", T: proto3.getEnumType(AccountTransaction_TransactionType) },
    { no: 7, name: "individual_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "transfer_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 9, name: "memo", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AccountTransaction {
    return new AccountTransaction().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AccountTransaction {
    return new AccountTransaction().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AccountTransaction {
    return new AccountTransaction().fromJsonString(jsonString, options);
  }

  static equals(a: AccountTransaction | PlainMessage<AccountTransaction> | undefined, b: AccountTransaction | PlainMessage<AccountTransaction> | undefined): boolean {
    return proto3.util.equals(AccountTransaction, a, b);
  }
}

/**
 * @generated from enum perkup.v1.AccountTransaction.TransactionType
 */
export enum AccountTransaction_TransactionType {
  /**
   * @generated from enum value: TRANSFER_TYPE_UNSPECIFIED = 0;
   */
  TRANSFER_TYPE_UNSPECIFIED = 0,

  /**
   * between account transfer
   *
   * @generated from enum value: account = 1;
   */
  account = 1,

  /**
   * stripe top up or refund
   *
   * @generated from enum value: stripe = 2;
   */
  stripe = 2,

  /**
   * adjustment to add or remove balance;
   *
   * @generated from enum value: adjustment = 3;
   */
  adjustment = 3,

  /**
   * order related transaction
   *
   * @generated from enum value: order = 4;
   */
  order = 4,
}
// Retrieve enum metadata with: proto3.getEnumType(AccountTransaction_TransactionType)
proto3.util.setEnumType(AccountTransaction_TransactionType, "perkup.v1.AccountTransaction.TransactionType", [
  { no: 0, name: "TRANSFER_TYPE_UNSPECIFIED" },
  { no: 1, name: "account" },
  { no: 2, name: "stripe" },
  { no: 3, name: "adjustment" },
  { no: 4, name: "order" },
]);

