import { PartialMessage } from '@bufbuild/protobuf'
import { captureException } from '@sentry/react'
import { allApprovedCats } from 'constants/EligibleCategories'
import { DEFAULT_PROGRAM_BUDGET } from 'constants/programs'
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getFirestore,
  setDoc,
} from 'firebase/firestore'
import {
  Action,
  ActionType_Enum,
  CustomAction,
  DateSelectorType_Enum,
  GroupType_Enum,
  Rule,
} from 'gen/perkup/v1/rules_pb'
import { NotificationOccassions } from 'types/Notifications'
import {
  getAnniversaryYearTitle,
  getRandomActionGIF,
  getRandomActionMessage,
  toSentry,
} from 'utils'
import { storedId } from 'utils/firestore'
import {
  ListActionsByRuleId,
  ListCustomActionsByActionId,
} from '../reads/rules'

export async function createNewRule({
  orgId,
  year,
  ruleId,
  occasion,
  accountId,
}: {
  orgId: string
  year: number
  ruleId?: string
  occasion: NotificationOccassions
  accountId?: string
}) {
  // Depending on current program type, set approriate default rule fields.
  const isBirthday = occasion === NotificationOccassions.BIRTHDAY
  const ruleYear = isBirthday ? 0 : year

  let dateSelector: DateSelectorType_Enum | undefined
  let group: GroupType_Enum | undefined
  switch (occasion) {
    case NotificationOccassions.BIRTHDAY:
      dateSelector = DateSelectorType_Enum.birthday
      group = GroupType_Enum.birthdays
      break

    case NotificationOccassions.ANNIVERSARY:
      dateSelector = DateSelectorType_Enum.startDate
      group = GroupType_Enum.anniversaries
      break

    default:
      break
  }
  const db = getFirestore()
  const newRule = new Rule({
    dateSelector,
    group,
    year: ruleYear,
    orgId,
    accountId,
    eligibleCategories: allApprovedCats,
  })

  if (ruleId) {
    const docRef = doc(
      db,
      `organizations/${orgId}/rules/${ruleId}`
    ).withConverter(storedId(Rule))
    await setDoc(docRef, newRule)
    return docRef
  }

  const colRef = collection(db, `organizations/${orgId}/rules`).withConverter(
    storedId(Rule)
  )
  try {
    return await addDoc(colRef, newRule)
  } catch (error) {
    console.error(error)
    captureException(toSentry(error), {
      contexts: { createNewRule: { orgId } },
    })
    return undefined
  }
}

export async function createNewAction({
  orgId,
  ruleId,
  isBirthday,
  from,
  year,
  actionType,
}: {
  orgId: string
  ruleId: string
  isBirthday: boolean
  from: string
  year: number
  actionType: ActionType_Enum
}) {
  // Depending on current program type, set approriate default rule fields.
  const image = await getRandomActionGIF({ isBirthday, year, orgId })
  const title = isBirthday ? 'Happy Birthday!' : getAnniversaryYearTitle(year)
  const message = getRandomActionMessage(isBirthday, year)

  const newAction = new Action({
    amount: DEFAULT_PROGRAM_BUDGET,
    dateOffset: 0,
    image,
    from,
    title,
    message,
    ruleId,
    actionType,
    orgId,
  })

  const db = getFirestore()
  const colRef = collection(
    db,
    `organizations/${orgId}/rules/${ruleId}/actions`
  ).withConverter(storedId(Action))
  try {
    return await addDoc(colRef, newAction)
  } catch (error) {
    console.error(error)
    captureException(toSentry(error), {
      contexts: { createNewAction: { orgId, ruleId } },
    })
    return undefined
  }
}

export async function createNewCustomAction({
  orgId,
  individualId,
  currentAction,
  excluded,
  actionType,
}: {
  orgId: string
  individualId: string
  currentAction: Action
  excluded: boolean
  actionType: ActionType_Enum
}) {
  if (!orgId || !individualId || !currentAction) return null

  const {
    ruleId,
    id: actionId,
    dateOffset,
    message,
    title,
    amount,
    image,
    from,
    gift,
  } = currentAction // Extract rule and action id

  // Build new custom action based on input

  const newCustomAction = new CustomAction({
    id: individualId,
    actionId,
    amount,
    dateOffset,
    excluded,
    from,
    image,
    message,
    orgId,
    ruleId,
    gift,
    title,
    actionType,
  })

  /**
   * Add the custom action to the currect action's subcollection
   * Note: If subcollection DNE, firestore creates it.
   */
  const db = getFirestore()
  const docRef = doc(
    db,
    `organizations/${orgId}/rules/${ruleId}/actions/${actionId}/customActions/${individualId}`
  ).withConverter(storedId(CustomAction))
  try {
    return await setDoc(docRef, newCustomAction)
  } catch (error) {
    console.error(error)
    captureException(toSentry(error), {
      contexts: { createNewCustomAction: { orgId, ruleId, actionId } },
    })
    return undefined
  }
}

export async function deleteCustomAction({
  orgId,
  ruleId,
  actionId,
  customActionId,
}: {
  orgId: string
  ruleId: string
  actionId: string
  customActionId: string
}) {
  const db = getFirestore()
  const docRef = doc(
    db,
    `organizations/${orgId}/rules/${ruleId}/actions/${actionId}/customActions/${customActionId}`
  )
  return deleteDoc(docRef)
}

export async function deleteAction({
  orgId,
  ruleId,
  actionId,
}: {
  orgId: string
  ruleId: string
  actionId: string
}) {
  const customActions = await ListCustomActionsByActionId({
    orgId,
    ruleId,
    actionId,
  })

  if (customActions) {
    customActions.forEach(async customAction => {
      const { id: customActionId } = customAction

      await deleteCustomAction({ orgId, ruleId, actionId, customActionId })
    })
  }
  const db = getFirestore()
  const docRef = doc(
    db,
    `organizations/${orgId}/rules/${ruleId}/actions/${actionId}`
  )
  try {
    await deleteDoc(docRef)
  } catch (error) {
    console.error(error)
    captureException(toSentry(error), {
      contexts: { deleteAction: { orgId, ruleId, actionId } },
    })
  }
}

export async function deleteRule({
  orgId,
  ruleId,
}: {
  orgId: string
  ruleId: string
}) {
  const actions = await ListActionsByRuleId({ orgId, ruleId })

  actions.forEach(async action => {
    const { id: actionId } = action

    await deleteAction({ orgId, ruleId, actionId })
  })

  const db = getFirestore()
  const docRef = doc(db, `organizations/${orgId}/rules/${ruleId}`)
  try {
    return await deleteDoc(docRef)
  } catch (error) {
    console.error(error)
    captureException(toSentry(error), {
      contexts: { deleteRule: { orgId, ruleId } },
    })
    return undefined
  }
}

export async function updateCustomAction({
  orgId,
  ruleId,
  actionId,
  customActionId,
  newData,
}: {
  orgId: string
  ruleId: string
  actionId: string
  customActionId: string
  newData: PartialMessage<CustomAction>
}) {
  const db = getFirestore()
  const docRef = doc(
    db,
    `organizations/${orgId}/rules/${ruleId}/actions/${actionId}/customActions/${customActionId}`
  ).withConverter(storedId(CustomAction))

  try {
    return await setDoc(docRef, { ...newData, orgId }, { merge: true })
  } catch (error) {
    console.error(error)
    captureException(toSentry(error), {
      contexts: { updateCustomAction: { orgId, ruleId, actionId } },
    })
    return undefined
  }
}

export async function updateRule({
  orgId,
  ruleId,
  newData,
}: {
  orgId: string
  ruleId: string
  newData: PartialMessage<Rule>
}) {
  const db = getFirestore()
  const docRef = doc(
    db,
    `organizations/${orgId}/rules/${ruleId}`
  ).withConverter(storedId(Rule))

  try {
    return await setDoc(docRef, { ...newData, orgId }, { merge: true })
  } catch (error) {
    console.error(error)
    captureException(toSentry(error), {
      contexts: { updateRule: { orgId, ruleId } },
    })
    return undefined
  }
}
