import { DatePicker } from 'antd'
import { DownloadRewardReportButton, PageHeader } from 'components'
import { Pane } from 'evergreen-ui'
import { ProgramType } from 'gen/perkup/v1/program_pb'
import { useIndividualRole } from 'hooks'
import { useState } from 'react'
import { RangeValue } from 'types/RewardsDate'

export function RewardReports() {
  const [dates, setDates] = useState<RangeValue>(null)
  const { isAdmin } = useIndividualRole()

  return (
    <Pane display="flex" flexDirection="column" gap={16}>
      <PageHeader
        title="Rewards Report"
        description="Download a report of all rewards, gifts, birthdays, and anniversaries sent within the specified date range."
        vlogLink="https://www.loom.com/embed/5150e72a17d94235ad19d453993b2445?sid=882215fd-d4e0-482f-83d8-6416cb4be848"
      />
      {isAdmin && (
        <Pane gap={8} display="flex">
          <DatePicker.RangePicker format="YYYY/MM/DD" onChange={setDates} />
          <DownloadRewardReportButton
            dates={dates}
            programTypes={[
              ProgramType.anniversaries,
              ProgramType.birthdays,
              ProgramType.rewards,
            ]}
          />
        </Pane>
      )}
    </Pane>
  )
}
