import { captureException, captureMessage } from '@sentry/react'
import {
  addDoc,
  collection,
  doc,
  getFirestore,
  increment,
  updateDoc,
} from 'firebase/firestore'
import { ProductVariant } from 'gen/perkup/v1/product_variant_pb'
import { toSentry } from 'utils'
import { converter } from 'utils/firestore'

export function incrementProductVariantClick({ id }: { id: string }) {
  if (!id) {
    captureMessage('Missing Product Variant ID')
    return
  }

  const db = getFirestore()
  const docRef = doc(db, `productVariants/${id}`)

  updateDoc(docRef, {
    'metrics.clicks': increment(1),
  }).catch(error => {
    console.error(error)
    captureException(toSentry(error), {
      contexts: {
        incrementProductVariantClick: {
          id,
        },
      },
    })
  })
}

export function updateProductVariantClicksAmount({
  id,
  clicksAmount,
}: {
  id: string
  clicksAmount: number
}) {
  try {
    const db = getFirestore()
    const docRef = doc(db, `productVariants/${id}`)

    return updateDoc(docRef, {
      'metrics.clicks': clicksAmount,
    })
  } catch (error) {
    console.error(error)
    captureException(toSentry(error), {
      contexts: {
        incrementProductVariantClick: {
          id,
        },
      },
    })
    return undefined
  }
}

export async function CreateProductVariant(pv: ProductVariant) {
  try {
    const db = getFirestore()
    const colRef = collection(db, `productVariants`).withConverter(
      converter(ProductVariant)
    )
    const addedDocRef = await addDoc(colRef, pv)
    return addedDocRef.id
  } catch (e) {
    console.error(e)
    captureException(toSentry(e), {
      contexts: {
        CreateProductVariant: {
          ...pv,
        },
      },
    })
    return undefined
  }
}
