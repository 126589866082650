import { captureMessage } from '@sentry/react'
import { Pane, Switch, Text } from 'evergreen-ui'
import React from 'react'

export function Toggle({
  checked,
  onChange,
  label,
  hasCheckIcon,
}: {
  checked: boolean
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  label: string
  hasCheckIcon?: boolean
}) {
  if (!onChange || !label) {
    const error = 'Missing required data for Toggle component'
    console.error(error)
    captureMessage(error, 'warning')
  }
  return (
    <Pane display="flex" alignItems="center">
      <Switch
        checked={checked}
        marginBottom={0}
        marginRight={8}
        onChange={onChange}
        hasCheckIcon={hasCheckIcon}
      />
      <Text>{label}</Text>
    </Pane>
  )
}
