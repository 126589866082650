import { Individual_Role } from 'gen/perkup/v1/individual_pb'

export function buildFullName({
  firstName,
  lastName,
}: {
  firstName: string | undefined
  lastName: string | undefined
}) {
  if (!firstName || !lastName) return undefined

  return `${firstName} ${lastName}`.trim()
}

/**
 *
 * @param role The user role.
 *
 * @returns Badge color string.
 */
export function getMemberBadgeColor(
  role?: Individual_Role
): 'green' | 'blue' | 'default' | undefined {
  if (!role) return undefined

  const badgeColorMap = new Map<Individual_Role, any>([
    [Individual_Role.member, 'green'],
    [Individual_Role.admin, 'blue'],
  ])

  return badgeColorMap.get(role) || 'default'
}

export function getDomainFromEmail(email: string): string {
  const domain = email.split('@')[1]
  return domain
}

/**
 *
 * https://stackoverflow.com/questions/33076177/getting-name-initials-using-js
 */

export function getInitials(fullname: string) {
  const initials = fullname
    .match(/(^\S\S?|\s\S)?/g)
    ?.map(v => v.trim())
    .join('')
    .match(/(^\S|\S$)?/g)
    ?.join('')
    .toLocaleUpperCase()

  return initials
}
