// @generated by protoc-gen-es v1.6.0 with parameter "target=ts"
// @generated from file canvas/products.proto (package canvas, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, Timestamp } from "@bufbuild/protobuf";

/**
 * Firestore /canvasProducts/:id/
 *
 * @generated from message canvas.CanvasProduct
 */
export class CanvasProduct extends Message<CanvasProduct> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * shopify product id
   *
   * @generated from field: string product_id = 2;
   */
  productId = "";

  /**
   * @generated from field: string aurigma_product_id = 10;
   */
  aurigmaProductId = "";

  /**
   * @generated from field: optional google.protobuf.Timestamp created_at = 3;
   */
  createdAt?: Timestamp;

  /**
   * @generated from field: optional google.protobuf.Timestamp updated_at = 4;
   */
  updatedAt?: Timestamp;

  /**
   * @generated from field: canvas.CanvasProperties canvas_properties = 6;
   */
  canvasProperties?: CanvasProperties;

  /**
   * will override the blank body_html if defined
   *
   * @generated from field: optional string body_html = 14 [json_name = "body_html"];
   */
  bodyHtml?: string;

  /**
   * will override the blank title if defined
   *
   * @generated from field: optional string title = 15;
   */
  title?: string;

  /**
   * @generated from field: string aurigma_owner_id = 11;
   */
  aurigmaOwnerId = "";

  /**
   * CUSTOMER CANVAS DESIGN ID
   *
   * @generated from field: string design_id = 7;
   */
  designId = "";

  /**
   * the customer canvas project id of the project created by functions
   * when the user called `HandleCreateProduct`
   *
   * @generated from field: optional string project_id = 8;
   */
  projectId?: string;

  /**
   * The product options selected by the user when creating the product in Customer Canvas ui.
   *
   * we'll generate variants for all the the combinations of the remaining options in the product 
   * against the selected options in this array
   *
   * @generated from field: repeated canvas.CanvasProduct.Option options = 9;
   */
  options: CanvasProduct_Option[] = [];

  /**
   * IndividualId of the design creator
   *
   * @generated from field: string creator_id = 12;
   */
  creatorId = "";

  /**
   * @generated from field: optional string org_id = 13;
   */
  orgId?: string;

  constructor(data?: PartialMessage<CanvasProduct>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "canvas.CanvasProduct";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "product_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "aurigma_product_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "created_at", kind: "message", T: Timestamp, opt: true },
    { no: 4, name: "updated_at", kind: "message", T: Timestamp, opt: true },
    { no: 6, name: "canvas_properties", kind: "message", T: CanvasProperties },
    { no: 14, name: "body_html", jsonName: "body_html", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 15, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 11, name: "aurigma_owner_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "design_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "project_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 9, name: "options", kind: "message", T: CanvasProduct_Option, repeated: true },
    { no: 12, name: "creator_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 13, name: "org_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CanvasProduct {
    return new CanvasProduct().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CanvasProduct {
    return new CanvasProduct().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CanvasProduct {
    return new CanvasProduct().fromJsonString(jsonString, options);
  }

  static equals(a: CanvasProduct | PlainMessage<CanvasProduct> | undefined, b: CanvasProduct | PlainMessage<CanvasProduct> | undefined): boolean {
    return proto3.util.equals(CanvasProduct, a, b);
  }
}

/**
 * deprecated, should use CanvasDesign.Option instead
 *
 * @generated from message canvas.CanvasProduct.Option
 */
export class CanvasProduct_Option extends Message<CanvasProduct_Option> {
  /**
   * shopifyProducts/:id/options/n/name
   *
   * @generated from field: string name = 1;
   */
  name = "";

  /**
   * must an slice of shopifyProducts/:id/options/n/id/values
   *
   * @generated from field: repeated string values = 2;
   */
  values: string[] = [];

  constructor(data?: PartialMessage<CanvasProduct_Option>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "canvas.CanvasProduct.Option";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "values", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CanvasProduct_Option {
    return new CanvasProduct_Option().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CanvasProduct_Option {
    return new CanvasProduct_Option().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CanvasProduct_Option {
    return new CanvasProduct_Option().fromJsonString(jsonString, options);
  }

  static equals(a: CanvasProduct_Option | PlainMessage<CanvasProduct_Option> | undefined, b: CanvasProduct_Option | PlainMessage<CanvasProduct_Option> | undefined): boolean {
    return proto3.util.equals(CanvasProduct_Option, a, b);
  }
}

/**
 * deprecated, should use PrintMetadata instead
 *
 * @generated from message canvas.CanvasProperties
 */
export class CanvasProperties extends Message<CanvasProperties> {
  /**
   * @generated from field: repeated canvas.CanvasProperties.PrintProperties print_properties = 1;
   */
  printProperties: CanvasProperties_PrintProperties[] = [];

  /**
   * /canvasProduct/:id/
   *
   * @generated from field: string canvas_product_id = 2;
   */
  canvasProductId = "";

  /**
   * the print on demmand specific sku
   *
   * @generated from field: string print_sku = 3;
   */
  printSku = "";

  constructor(data?: PartialMessage<CanvasProperties>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "canvas.CanvasProperties";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "print_properties", kind: "message", T: CanvasProperties_PrintProperties, repeated: true },
    { no: 2, name: "canvas_product_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "print_sku", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CanvasProperties {
    return new CanvasProperties().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CanvasProperties {
    return new CanvasProperties().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CanvasProperties {
    return new CanvasProperties().fromJsonString(jsonString, options);
  }

  static equals(a: CanvasProperties | PlainMessage<CanvasProperties> | undefined, b: CanvasProperties | PlainMessage<CanvasProperties> | undefined): boolean {
    return proto3.util.equals(CanvasProperties, a, b);
  }
}

/**
 * @generated from message canvas.CanvasProperties.PrintProperties
 */
export class CanvasProperties_PrintProperties extends Message<CanvasProperties_PrintProperties> {
  /**
   * @generated from field: string print_url = 1;
   */
  printUrl = "";

  /**
   * @generated from field: optional string print_location = 2;
   */
  printLocation?: string;

  /**
   * @generated from field: optional string print_method = 3;
   */
  printMethod?: string;

  constructor(data?: PartialMessage<CanvasProperties_PrintProperties>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "canvas.CanvasProperties.PrintProperties";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "print_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "print_location", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 3, name: "print_method", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CanvasProperties_PrintProperties {
    return new CanvasProperties_PrintProperties().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CanvasProperties_PrintProperties {
    return new CanvasProperties_PrintProperties().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CanvasProperties_PrintProperties {
    return new CanvasProperties_PrintProperties().fromJsonString(jsonString, options);
  }

  static equals(a: CanvasProperties_PrintProperties | PlainMessage<CanvasProperties_PrintProperties> | undefined, b: CanvasProperties_PrintProperties | PlainMessage<CanvasProperties_PrintProperties> | undefined): boolean {
    return proto3.util.equals(CanvasProperties_PrintProperties, a, b);
  }
}

/**
 * @generated from message canvas.SyncCanvasProductsRequest
 */
export class SyncCanvasProductsRequest extends Message<SyncCanvasProductsRequest> {
  /**
   * if provided, only sync products with these ids
   *
   * otherwise sync all products
   *
   * @generated from field: repeated string canvas_products_ids = 1;
   */
  canvasProductsIds: string[] = [];

  constructor(data?: PartialMessage<SyncCanvasProductsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "canvas.SyncCanvasProductsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "canvas_products_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SyncCanvasProductsRequest {
    return new SyncCanvasProductsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SyncCanvasProductsRequest {
    return new SyncCanvasProductsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SyncCanvasProductsRequest {
    return new SyncCanvasProductsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: SyncCanvasProductsRequest | PlainMessage<SyncCanvasProductsRequest> | undefined, b: SyncCanvasProductsRequest | PlainMessage<SyncCanvasProductsRequest> | undefined): boolean {
    return proto3.util.equals(SyncCanvasProductsRequest, a, b);
  }
}

