import { Image, Tag } from 'antd'
import { AMAZON_PRICE } from 'constants/colors'
import { OrgContext, UserContext } from 'context'
import { Heading, Pane, Text } from 'evergreen-ui'
import useNumToDollarsConversion from 'hooks/useNumToDollarsConversion'
import { useContext } from 'react'
import { AmazonOffer, AmazonProduct } from 'types/Amazon'
import { getAmazonDisplayPrices } from 'utils'
import { PrimeLogo } from './PrimeLogo'

export function AmazonProductRowCard({
  product,
  offer,
  lastProductElmRef,
}: {
  product: AmazonProduct
  offer: AmazonOffer
  lastProductElmRef?: (node: HTMLDivElement) => void
}) {
  const user = useContext(UserContext)
  const org = useContext(OrgContext)

  const productDimensions = product.productVariations.dimensions
  const { offerAmount, listAmount, percentOff, offerCurrency } =
    getAmazonDisplayPrices(offer)

  const offerDisplayPrice = useNumToDollarsConversion({
    num: offerAmount,
    numCurrency: offerCurrency,
    decimal: 2,
    displayCurrency: user.displayCurrency || org?.displayCurrency,
  })

  const listDisplayPrice = useNumToDollarsConversion({
    num: listAmount,
    numCurrency: offerCurrency,
    decimal: 2,
    displayCurrency: user.displayCurrency || org?.displayCurrency,
  })

  return (
    <Pane
      display="flex"
      gap={8}
      hoverElevation={1}
      width="100%"
      ref={(node: HTMLDivElement) =>
        !!lastProductElmRef && lastProductElmRef(node)
      }
    >
      {product.includedDataTypes?.IMAGES?.length && (
        <Image
          preview={false}
          style={{
            padding: 8,
            objectFit: 'contain',
            aspectRatio: '1 / 1',
            maxWidth: 240,
          }}
          alt={product.includedDataTypes.IMAGES[0]?.altText}
          src={product.includedDataTypes.IMAGES[0]?.large?.url}
        />
      )}
      <Pane
        minWidth={228}
        display="flex"
        flexDirection="column"
        gap={8}
        flex={1}
        padding={16}
      >
        <Heading className="three-line-truncate" size={600}>
          {product.title}
        </Heading>
        <Pane display="flex" alignItems="center">
          <Pane>
            <Pane display="flex" marginRight={16} gap={8} alignItems="flex-end">
              {/* PRICE */}
              <Heading size={500} color={AMAZON_PRICE}>
                {offerDisplayPrice}
              </Heading>

              {/* LIST PRICE */}
              {listAmount > 0 && (
                <Text textDecoration="line-through" color="muted">
                  {listDisplayPrice}
                </Text>
              )}
              <Pane>
                <PrimeLogo />
              </Pane>
            </Pane>

            {/* PERCENT OFF */}
            {percentOff > 0 && (
              <Pane marginTop={4}>
                <Tag color="green">Save {percentOff}%</Tag>
              </Pane>
            )}
          </Pane>
        </Pane>
        {offer.deliveryInformation && <Text>{offer.deliveryInformation}</Text>}

        <Pane display="flex" gap={8}>
          {productDimensions.map(value => {
            const { displayString, dimensionValues } = value
            const numOptions = dimensionValues.length
            const dimensionInfo = `${numOptions}+ ${displayString}`
            return <Tag key={value.displayString}>{dimensionInfo}</Tag>
          })}
        </Pane>
      </Pane>
    </Pane>
  )
}
