import { callFunction } from 'api/functionCalls'
import { OrgContext } from 'context'
import isEmpty from 'lodash-es/isEmpty'
import isString from 'lodash-es/isString'
import { useContext, useEffect, useState } from 'react'
import Stripe from 'stripe'

export default function useDefaultPaymentMethod() {
  const { customerId } = useContext(OrgContext)

  const [isLoading, setIsLoading] = useState(false)
  const [defaultPaymentMethod, setDefaultPaymentMethod] =
    useState<Stripe.PaymentMethod>()

  useEffect(() => {
    async function getDefaultPaymentMethod() {
      if (!customerId) return undefined

      const customer: Stripe.Customer | undefined = await callFunction(
        'stripe-RetrieveCustomer',
        {
          customerId,
        }
      )

      const customerPaymentMethods: Stripe.PaymentMethod[] = await callFunction(
        'stripe-GetPaymentMethodsByCustomerId',
        {
          customerId,
        }
      )

      const defaultPayId = customer?.invoice_settings?.default_payment_method

      if (isEmpty(customerPaymentMethods) || !isString(defaultPayId)) {
        return undefined
      }

      const customerDefaultPaymentMethod = customerPaymentMethods.find(
        method => method.id === defaultPayId
      )

      return customerDefaultPaymentMethod
    }

    setIsLoading(true)
    getDefaultPaymentMethod()
      .then(setDefaultPaymentMethod)
      .finally(() => setIsLoading(false))
  }, [customerId])

  return { isLoading, defaultPaymentMethod }
}
