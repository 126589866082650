import { captureException } from '@sentry/react'
import { callFunction } from 'api/functionCalls'
import { Membership, MembershipList } from 'gen/perkup/v1/program_pb'
import { toSentry } from 'utils'

export async function ListMembershipsByIndividualId({
  orgId,
  individualId,
}: {
  orgId: string
  individualId: string
}): Promise<Membership[]> {
  try {
    const res = await callFunction('firestore-ListMembershipsByIndividualId', {
      individualId,
      orgId,
    })
    return res ? MembershipList.fromJson(res).memberships : []
  } catch (error) {
    console.error(error)
    captureException(toSentry(error), {
      contexts: {
        ListMembershipsByIndividualId: { individualId, orgId },
      },
    })
    return []
  }
}
