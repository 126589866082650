import {
  MANUAL_GIFT_TAX_RATE,
  MANUAL_PERKUP_FEE_MULTIPLIER,
} from 'constants/money'
import {
  VendorProductPrice,
  VendorProductSubCategory,
} from 'gen/perkup/v1/vendor_pb'
import difference from 'lodash-es/difference'
import every from 'lodash-es/every'
import filter from 'lodash-es/filter'
import includes from 'lodash-es/includes'
import intersection from 'lodash-es/intersection'
import map from 'lodash-es/map'
import uniq from 'lodash-es/uniq'
import { VendorProductCategory } from 'types/BrowseGifts'
import { getCountryIsoAlpha2 } from './countries'

const constCategories = [
  'Featured',
  'Gift Boxes',
  'Food',
  'Home',
  'Outdoors & Travel',
  'Wellness & Beauty',
  'Tech',
  'Sports',
  'Flowers & Plants',
  'Pets',
  'Women',
  'Men',
  'Baby & Kids',
  'Charity',
]

export function constructVendorProductCategories(
  subCategories: VendorProductSubCategory[]
) {
  const categoryNames = uniq(
    map(subCategories, subCategory => subCategory.categoryName)
  )
  const sortedCategoryNamesSlice = intersection(constCategories, categoryNames)
  const sortedCategoryNames = [
    ...sortedCategoryNamesSlice,
    ...difference(constCategories, sortedCategoryNamesSlice),
  ]

  return map(sortedCategoryNames, categoryName => {
    const nestedSubCategories = filter(
      subCategories,
      subCategory => subCategory.categoryName === categoryName
    )
    const category: VendorProductCategory = {
      name: categoryName,
      subCategories: nestedSubCategories,
    }
    return category
  })
}

export function beforeFeesTotalAmount(amount: number) {
  const rate = 1 + MANUAL_GIFT_TAX_RATE + MANUAL_PERKUP_FEE_MULTIPLIER
  return Math.round(amount / rate)
}

export function isUnavailableInCountry({
  programBudget,
  userCountryCode,
  productPrices,
}: {
  programBudget: number
  userCountryCode: string
  productPrices: VendorProductPrice[]
}) {
  const maxTotalAmount = beforeFeesTotalAmount(programBudget)
  const eligiblePrices = productPrices.filter(
    price => price.totalAmount <= maxTotalAmount
  )
  return every(
    eligiblePrices,
    price =>
      !includes(price.iso2s, getCountryIsoAlpha2(userCountryCode).toUpperCase())
  )
}
