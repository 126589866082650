// @generated by protoc-gen-connect-es v1.1.4 with parameter "target=ts"
// @generated from file perkup/v1/tinybird.proto (package perkup.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { Empty } from "../../google/protobuf/empty_pb.js";
import { TinybirdTokenResponse } from "./tinybird_pb.js";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service perkup.v1.TinybirdTokens
 */
export const TinybirdTokens = {
  typeName: "perkup.v1.TinybirdTokens",
  methods: {
    /**
     * @generated from rpc perkup.v1.TinybirdTokens.GetToken
     */
    getToken: {
      name: "GetToken",
      I: Empty,
      O: TinybirdTokenResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

