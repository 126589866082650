import { Button } from 'antd'
import { AddPersonalFundsButton } from 'components'
import { ChangeAddressButton } from 'components/Addresses'
import { PrimeLogo } from 'components/Amazon'
import { VariantDeliveryLabel } from 'components/ProductVariants'
import { SHIPPING_AND_HANDLING } from 'constants/checkout'
import { AMAZON_PRICE } from 'constants/colors'
import { USD } from 'constants/currencies'
import { MIN_PERSONAL_FUNDS_DEPOSIT } from 'constants/money'
import { FREE_DELIVERY } from 'constants/transactions'
import {
  ExchangeRateContext,
  OrgContext,
  UserContext,
  UserShippingAddressesContext,
} from 'context'
import {
  Alert,
  Heading,
  Image,
  Pane,
  RadioGroup,
  Small,
  Text,
} from 'evergreen-ui'
import { Organization_SubscriptionStatus } from 'gen/perkup/v1/organization_pb'
import { ProductVariant_DeliveryType } from 'gen/perkup/v1/product_variant_pb'
import { ShippingAddress } from 'gen/perkup/v1/root_user_pb'
import { useConvertCurrency } from 'hooks/useConvertCurrency'
import { CSSProperties, ReactNode, useContext } from 'react'
import { isMobile } from 'react-device-detect'
import { numToDollars } from 'utils'

export function CheckoutForm(props: {
  orderTotal: number
  budget: number
  deliveryInformation?: string
  fulfillmentName?: string
  imageUrl?: string
  title: string
  productPrice: number
  soldBy: string
  onPlaceOrder: ({ buttonLocation }: { buttonLocation?: string }) => void
  isLoading: boolean
  orderSummary: { label: string; amount: number; border?: string }[]
  termsElement?: JSX.Element
  returnPolicyElement?: JSX.Element
  quantityElement?: JSX.Element
  isPrime?: boolean
  inEligibleReason?: string
  isLocalCurrency?: boolean
  showExchangeRateNote?: boolean
  deliveryType?: ProductVariant_DeliveryType
  deliveryEstimateElement?: ReactNode
  shippingAddress?: ShippingAddress
  onAddressChange: (addressFormData?: ShippingAddress) => void
}) {
  const {
    orderTotal,
    orderSummary,
    budget,
    deliveryInformation,
    fulfillmentName,
    imageUrl,
    title,
    productPrice,
    soldBy,
    onPlaceOrder,
    isLoading,
    termsElement,
    returnPolicyElement,
    quantityElement,
    isPrime,
    isLocalCurrency = false,
    showExchangeRateNote = false,
    deliveryType,
    deliveryEstimateElement,
    shippingAddress,
    onAddressChange,
  } = props
  const user = useContext(UserContext)
  const org = useContext(OrgContext)
  const exchangeRate = useContext(ExchangeRateContext)
  const displayCurrency = user.displayCurrency || USD
  const shippingAddresses = useContext(UserShippingAddressesContext)

  let inEligible = true
  let { inEligibleReason } = props
  let showPersonalFundsCTA = false

  const orderTotalInUsd = useConvertCurrency({
    amount: orderTotal,
    fromCurrency: isLocalCurrency ? displayCurrency : USD,
    toCurrency: USD,
  })

  const outstandingAmountInUsd = Math.ceil(orderTotalInUsd - budget)

  const outstandingAmountDisplayPrice = numToDollars(
    useConvertCurrency({
      amount: outstandingAmountInUsd,
      fromCurrency: USD,
      toCurrency: displayCurrency,
    }),
    2,
    false,
    displayCurrency
  )

  const productTotalBeforeExchange = isLocalCurrency
    ? productPrice
    : productPrice * exchangeRate
  const productDisplayPrice = numToDollars(
    productTotalBeforeExchange,
    2,
    false,
    displayCurrency
  )

  const orderTotalBeforeExchange = isLocalCurrency
    ? orderTotal
    : orderTotal * exchangeRate
  const orderDisplayTotal = numToDollars(
    orderTotalBeforeExchange,
    2,
    false,
    displayCurrency
  )

  if (outstandingAmountInUsd > 0) {
    inEligibleReason = `Insufficient funds, you need ${outstandingAmountDisplayPrice}`
    showPersonalFundsCTA = true
  } else if (
    org.subscriptionStatus !== Organization_SubscriptionStatus.active
  ) {
    inEligibleReason = 'Checkout disabled — Organization is inactive'
  } else if (!inEligibleReason) {
    inEligible = false
  }

  const disclaimerAlertStyles: CSSProperties = {
    display: 'flex',
    gap: '8px',
    alignItems: 'center',
    marginTop: '8px',
  }

  const regularAlertStyles: CSSProperties = {
    position: isMobile ? 'relative' : 'absolute',
    right: isMobile ? 0 : 10,
    top: '7px',
  }

  const alertStyle =
    displayCurrency?.toLowerCase() !== USD
      ? disclaimerAlertStyles
      : regularAlertStyles
  return (
    <Pane>
      <Heading display="flex" justifyContent="center" size={700}>
        Checkout
      </Heading>

      {inEligible && !isLoading && (
        <Pane display="flex" justifyContent="center" marginY={16}>
          <Alert
            title={inEligibleReason}
            intent="warning"
            width={isMobile ? undefined : 512}
          >
            {showPersonalFundsCTA && (
              <Pane style={alertStyle}>
                {/** Only display disclaimer if displayCurrency is not in USD */}
                {displayCurrency?.toLowerCase() !== USD && (
                  <Text color="muted">
                    Note: You will be charged the appropriate amount in USD.
                  </Text>
                )}

                <AddPersonalFundsButton
                  defaultAmount={
                    outstandingAmountInUsd < MIN_PERSONAL_FUNDS_DEPOSIT
                      ? MIN_PERSONAL_FUNDS_DEPOSIT
                      : outstandingAmountInUsd
                  }
                />
              </Pane>
            )}
          </Alert>
        </Pane>
      )}

      <Pane display="flex" gap={32} alignItems="flex-start" flexWrap="wrap">
        <Pane flex={1}>
          <Pane display="flex" padding={16} borderBottom="muted" gap="2rem">
            <Pane display="flex">
              <Heading>1</Heading>
            </Pane>
            <Pane display="flex" flexWrap="wrap">
              <Pane display="flex" marginRight={16} marginBottom={12}>
                <Heading>Address</Heading>
              </Pane>
              <ChangeAddressButton
                shippingAddresses={shippingAddresses}
                selectedAddress={shippingAddress}
                onAddressChange={onAddressChange}
              />
            </Pane>
          </Pane>
          <Pane display="flex" flexDirection="column" padding={16}>
            <Pane display="flex" gap={32}>
              <Heading>2</Heading>
              <Heading>Review Items</Heading>
            </Pane>
            <Pane
              marginLeft={isMobile ? undefined : 40}
              display="flex"
              flex={1}
              flexDirection="column"
            >
              <Pane
                display="flex"
                padding={16}
                borderRadius={8}
                border
                marginTop={16}
                flexDirection="column"
              >
                {deliveryInformation && (
                  <Heading marginBottom={8}>
                    Estimated delivery: {deliveryInformation}
                  </Heading>
                )}

                {fulfillmentName && (
                  <Text color="muted">Item shipped from {fulfillmentName}</Text>
                )}
                <Pane display="flex" gap="0.5rem" marginTop={16}>
                  {imageUrl && (
                    <Image src={imageUrl} style={{ maxHeight: '112px' }} />
                  )}
                  <Pane display="flex" flexDirection="column" gap={8}>
                    <Heading size={500}>{title}</Heading>
                    <Pane display="flex" alignItems="center" flexWrap="wrap">
                      <Heading color={AMAZON_PRICE} marginRight={8}>
                        {productDisplayPrice}
                      </Heading>
                      {isPrime && <PrimeLogo />}
                    </Pane>
                    {quantityElement}
                    {soldBy && <Text color="muted">Sold by: {soldBy}</Text>}
                    <VariantDeliveryLabel
                      deliveryType={deliveryType}
                      useAlert={false}
                    />
                    {showExchangeRateNote && (
                      <Text color="muted">
                        Note: Differences in price are due to exchange rate
                        fluctuations
                      </Text>
                    )}
                    {deliveryInformation && (
                      <Pane>
                        <Heading marginTop={16}>
                          Choose your delivery option:
                        </Heading>
                        <RadioGroup
                          value={deliveryInformation}
                          options={[
                            {
                              label: deliveryInformation,
                              value: deliveryInformation,
                            },
                          ]}
                        />
                      </Pane>
                    )}
                  </Pane>
                </Pane>
                {deliveryEstimateElement}
              </Pane>
              {!isMobile && (
                <Pane
                  display="flex"
                  padding={16}
                  gap="1rem"
                  borderRadius={7}
                  border
                  marginTop={16}
                >
                  <Button
                    type="primary"
                    size="large"
                    onClick={() => onPlaceOrder({ buttonLocation: 'bottom' })}
                    disabled={inEligible}
                    loading={isLoading}
                  >
                    Place your order
                  </Button>
                  <Pane>
                    <Pane display="flex" gap="0.5rem">
                      <Heading color={AMAZON_PRICE}>Order total: </Heading>

                      <Heading color={AMAZON_PRICE}>
                        {orderDisplayTotal}
                      </Heading>
                    </Pane>
                    {termsElement}
                  </Pane>
                </Pane>
              )}
              {returnPolicyElement}
            </Pane>
          </Pane>
        </Pane>
        <Pane
          display="flex"
          flexDirection={isMobile ? 'column-reverse' : 'column'}
          border
          padding={16}
          borderRadius={7}
          width={isMobile ? '100%' : 300}
          marginX="auto"
          gap={16}
        >
          <Pane>
            <Button
              style={{ width: '100%' }}
              type="primary"
              size="large"
              onClick={() => onPlaceOrder({ buttonLocation: 'top' })}
              disabled={inEligible}
              loading={isLoading}
            >
              Place your order
            </Button>

            <Pane paddingTop={5} textAlign="center">
              {termsElement}
            </Pane>
          </Pane>
          {!isMobile && <hr />}
          <Pane>
            <Heading size={600} marginBottom={8}>
              Order Summary
            </Heading>
            <Pane
              display="flex"
              flexDirection="column"
              gap={4}
              borderBottom="default"
              paddingBottom={8}
            >
              {orderSummary?.map(({ label, amount, border }) => {
                const displayAmount = isLocalCurrency
                  ? amount
                  : exchangeRate * amount
                return (
                  <Pane key={label}>
                    <Pane display="flex" gap={4}>
                      <Small flex={1}>{label}</Small>
                      <Pane>
                        <Small>
                          {amount === 0 && label === SHIPPING_AND_HANDLING
                            ? FREE_DELIVERY
                            : numToDollars(
                                displayAmount,
                                2,
                                false,
                                user.displayCurrency || org?.displayCurrency
                              )}
                        </Small>
                      </Pane>
                    </Pane>
                    {border && <Pane borderBottom="muted" />}
                  </Pane>
                )
              })}
            </Pane>
            <Pane
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              marginTop={16}
            >
              <Heading color={AMAZON_PRICE}>Order total: </Heading>
              <Heading color={AMAZON_PRICE}>{orderDisplayTotal}</Heading>
            </Pane>
          </Pane>
        </Pane>
      </Pane>
    </Pane>
  )
}
