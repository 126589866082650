import { IGif } from '@giphy/js-types'
import {
  Carousel as GiphyCarousel,
  Grid as GiphyGrid,
} from '@giphy/react-components'
import { Button, Grid, Tabs } from 'antd'
import GiphyAtt from 'assets/Powered by Giphy.png'
import { giphy } from 'assets/icons'
import { CustomCarousel } from 'components/Carousels/CustomCarousel'
import { CarouselImageCard } from 'components/Images/CarouselImageCard'
import { USE_CASE_CLICKED } from 'constants/events'
import { defaultOccasions } from 'constants/newReward/occasions'
import { FEATURED, ImageSearchEngine } from 'constants/themeEditor'
import { OrgContext } from 'context'
import { Heading, Image, Pane, SearchInput } from 'evergreen-ui'
import { ProgramOccasion } from 'gen/perkup/v1/program_pb'
import useIds from 'hooks/useIds'
import { compact, sample, values } from 'lodash-es'
import isEmpty from 'lodash-es/isEmpty'
import {
  getUseCaseEmailData,
  getUseSearchCaseQuery,
  saveProgramImageQuery,
} from 'pages/NewReward/utils/programs'
import {
  getDefaultSearchQueryFromOccasion,
  getDefaultUseCaseForBrandedImage,
} from 'pages/NewReward/utils/uiUtils'
import { useCallback, useContext, useState } from 'react'
import { ProgramTheme } from 'types'
import { Basic } from 'unsplash-js/dist/methods/photos/types'
import { giphyApi, logEvent } from 'utils'
import { getTemplateImageUrl } from 'utils/templates'
import { FeaturedGrid } from './featured-grid'
import { useProgramTemplates, useRewardsUseCases } from './hooks'
import UnsplashResults from './unsplash-results'
import { UploadCustomImage } from './upload-custom-image'
import { getUseCaseLabel } from './util'

const { useBreakpoint } = Grid

export function ThemeEditor({
  onSelectTheme,
  defaultQuery,
  occasion,
  programId,
}: {
  onSelectTheme: ({
    emailData,
    advance,
    rewardUseCase,
  }: {
    emailData: ProgramTheme
    advance?: boolean
    rewardUseCase?: string
  }) => void
  defaultQuery?: string
  occasion?: ProgramOccasion
  programId?: string
}) {
  const screens = useBreakpoint()

  const { name: orgName } = useContext(OrgContext)

  const { userId, orgId } = useIds()

  const [currentSearchEngine, setCurrentSearchEngine] = useState(
    occasion ? ImageSearchEngine.ALL : ImageSearchEngine.GIPHY
  )

  const isAll = currentSearchEngine === ImageSearchEngine.ALL
  const isFeatured = currentSearchEngine === ImageSearchEngine.FEATURED
  const isGiphy = currentSearchEngine === ImageSearchEngine.GIPHY
  const isUnsplash = currentSearchEngine === ImageSearchEngine.UNSPLASH
  const isCustom = currentSearchEngine === ImageSearchEngine.CUSTOM

  const SIDE_TABS_WIDTH = 192

  const {
    currentUseCase,
    setCurrentUseCase,
    searchQuery,
    setSearchQuery,
    useCaseOnProgram,
    rewardUseCasesToSearch,
    rewardUseCasesToRender,
  } = useRewardsUseCases({
    occasion,
    defaultQuery,
    programId,
  })

  const { featured } = useProgramTemplates({
    rewardUseCasesToSearch,
  })

  const fetchGifs = useCallback(
    (offset: number) => {
      if (isEmpty(searchQuery)) {
        return giphyApi.trending({ offset, limit: 10, rating: 'pg' })
      }

      return giphyApi.search(searchQuery, {
        offset,
        limit: 10,
        sort: 'relevant',
      })
    },
    [searchQuery]
  )

  const handleGifClick = (
    gif: IGif,
    e: React.SyntheticEvent<HTMLElement, Event>
  ) => {
    e.preventDefault()
    const downsizedGif = gif.images.downsized.url
    onSelectTheme({
      emailData: { banner: downsizedGif },
      rewardUseCase: useCaseOnProgram,
      advance: true,
    })
  }

  const searchEngineTabs = values(ImageSearchEngine).map(engine => {
    if (!occasion && engine === ImageSearchEngine.ALL) return null
    return {
      label: engine,
      key: engine,
    }
  })

  const occasionData = defaultOccasions.find(
    defaultOccasion => defaultOccasion.occasion === occasion
  )

  const occasionEmailData = sample(occasionData?.emailData)

  const rewardUseCaseTabs = rewardUseCasesToRender.map(cat => ({
    label: getUseCaseLabel(cat),
    key: cat,
    disabled: isCustom,
  }))

  const determineContentSectionWidth = () => {
    if (screens.xxl) return 1280
    if (screens.xl) return 960
    if (screens.lg) return 640
    return 512
  }

  const contentSectionWidth = determineContentSectionWidth()

  return (
    <Pane id="theme-editor" display="flex" gap={32} width="100%">
      {/** NAVIGATION SECTION */}
      <Pane
        gap={32}
        display="flex"
        flexDirection="column"
        alignItems="start"
        width={SIDE_TABS_WIDTH}
      >
        {occasion && (
          <Heading marginLeft={16} size={600}>
            Use cases
          </Heading>
        )}
        <Tabs
          items={rewardUseCaseTabs}
          tabPosition="left"
          onTabClick={rewardUseCase => {
            logEvent(USE_CASE_CLICKED, {
              rewardUseCase,
              orgId,
              userId,
              orgName,
            })
            // @ts-expect-error - rewardUseCase is a string type
            setCurrentUseCase(rewardUseCase)
            const defaultOccasionQuery = getDefaultSearchQueryFromOccasion({
              occasion,
            })
            if (rewardUseCase === FEATURED) {
              setSearchQuery(defaultQuery || '')
            } else if (rewardUseCase === ImageSearchEngine.ALL) {
              setSearchQuery(defaultOccasionQuery)
              onSelectTheme({ emailData: { ...occasionEmailData } })
            } else if (occasion) {
              const useCaseQuery = getUseSearchCaseQuery({ rewardUseCase })
              const emailData = getUseCaseEmailData({ rewardUseCase })
              onSelectTheme({
                emailData: {
                  note: emailData?.note,
                  title: emailData?.title,
                },
                rewardUseCase: useCaseOnProgram,
              })
              setSearchQuery(useCaseQuery || defaultOccasionQuery)
            } else {
              setSearchQuery(rewardUseCase)
            }
          }}
        />
      </Pane>

      {/** CONTENT SECTION */}
      <Pane marginTop={32} maxWidth={contentSectionWidth}>
        {/** Header */}
        <Tabs
          items={compact(searchEngineTabs)}
          activeKey={currentSearchEngine}
          onTabClick={searchEngine => {
            const engineEnum = Object.values(ImageSearchEngine).find(
              engine => engine === searchEngine
            )
            if (!engineEnum) return
            setCurrentSearchEngine(engineEnum)
          }}
        />

        {!occasion && (
          <Heading size={600} marginTop={16} marginBottom={16}>
            {isCustom ? 'Custom Images' : currentUseCase}
          </Heading>
        )}
        <Pane
          display="flex"
          flexDirection="column"
          gap={32}
          width="100%"
          marginTop={16}
        >
          {isAll && !isEmpty(featured) && (
            <CustomCarousel
              header="Featured"
              showSeeAllButton
              key={currentUseCase}
              handleSeeMoreClick={() =>
                setCurrentSearchEngine(ImageSearchEngine.FEATURED)
              }
              handleCTAText="See more"
            >
              {[
                ...featured.flatMap(({ brandedImages }) =>
                  brandedImages.map(image => (
                    <CarouselImageCard
                      key={image}
                      imageUrl={image}
                      onClick={() => {
                        const defaultUseCase = getDefaultUseCaseForBrandedImage(
                          {
                            image,
                          }
                        )
                        const useCaseEmail = defaultUseCase?.emailData
                        const defaultTitle = useCaseEmail?.[0]?.title
                        const defaultNote = useCaseEmail?.[0]?.note
                        onSelectTheme({
                          emailData: {
                            banner: image,
                            title: defaultTitle,
                            note: defaultNote,
                          },
                          advance: true,
                          rewardUseCase: defaultUseCase?.label,
                        })
                      }}
                    />
                  ))
                ),
                ...featured.flatMap(({ programTemplates }) =>
                  programTemplates.map(template => {
                    const imageUrl = getTemplateImageUrl({
                      programImage: template.programImage,
                    })
                    return (
                      <CarouselImageCard
                        key={template.id}
                        imageUrl={imageUrl}
                        onClick={() => {
                          onSelectTheme({
                            emailData: {
                              banner: template.programImage,
                              title: template.programTitle,
                              note: template.programMessage,
                              categories: template.categories,
                            },
                            advance: true,
                            rewardUseCase: useCaseOnProgram,
                          })
                        }}
                      />
                    )
                  })
                ),
              ]}
            </CustomCarousel>
          )}

          {/** Search */}
          {(isGiphy || isUnsplash) && (
            <Pane marginBottom={16}>
              <SearchInput
                autoFocus
                placeholder={
                  isGiphy
                    ? 'Search Giphy'
                    : 'Search free high-resolution photos'
                }
                value={searchQuery}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  const queryValue = event.currentTarget.value
                  setSearchQuery(queryValue)
                  if (programId) {
                    saveProgramImageQuery({ programId, query: queryValue })
                  }
                }}
                width={320}
                style={{
                  backgroundImage: isGiphy ? `url(${GiphyAtt})` : 'none',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'right',
                  backgroundSize: '104px',
                }}
              />
            </Pane>
          )}

          {/** Results */}
          {isAll && (
            <Pane display="flex" flexDirection="column" gap={16}>
              <Pane
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Pane display="flex" alignItems="center" gap={16}>
                  <Image src={giphy} height={16} />
                  <Heading size={600}>Giphy</Heading>
                </Pane>
                <Button
                  onClick={() =>
                    setCurrentSearchEngine(ImageSearchEngine.GIPHY)
                  }
                >
                  See more
                </Button>
              </Pane>
              <GiphyCarousel
                key={searchQuery}
                gifHeight={120}
                onGifClick={handleGifClick}
                fetchGifs={fetchGifs}
                gutter={16}
                borderRadius={8}
              />
            </Pane>
          )}
          {isFeatured && (
            <FeaturedGrid
              featured={featured}
              onSelectTheme={onSelectTheme}
              useCaseOnProgram={useCaseOnProgram}
            />
          )}
          {isGiphy && (
            <GiphyGrid
              width={contentSectionWidth}
              key={searchQuery}
              onGifClick={handleGifClick}
              fetchGifs={fetchGifs}
              gutter={16}
              columns={4}
            />
          )}
          {(isUnsplash || isAll) && (
            <UnsplashResults
              query={searchQuery || 'celebrations'}
              onPhotoClick={(photo: Basic) => {
                onSelectTheme({
                  emailData: {
                    banner: photo.urls?.raw,
                  },
                  advance: true,
                  rewardUseCase: useCaseOnProgram,
                })
              }}
              useCarousel={isAll}
              handleSeeMoreClick={() =>
                setCurrentSearchEngine(ImageSearchEngine.UNSPLASH)
              }
            />
          )}

          {(isCustom || isAll) && (
            <UploadCustomImage
              useCarousel={isAll}
              onBannerClick={banner =>
                onSelectTheme({
                  emailData: { banner },
                  advance: true,
                  rewardUseCase: useCaseOnProgram,
                })
              }
              handleSeeMoreClick={() =>
                setCurrentSearchEngine(ImageSearchEngine.CUSTOM)
              }
            />
          )}
        </Pane>
      </Pane>
    </Pane>
  )
}
