import { PageHeader, withOrgSidebar } from 'components'
import { Pane } from 'evergreen-ui'
import BillingEmail from './components/BillingEmail'
import BillingHistory from './components/BillingHistory'
import BillingStatus from './components/BillingStatus'
import BillingSubscription from './components/BillingSubscription'

function Billing() {
  return (
    <Pane>
      <PageHeader
        title="Billing"
        description="Manage your plans, billing information, and invoices"
        vlogLink="https://www.loom.com/embed/9d8da7c958f14aa594ff196bf5c3ba51?sid=83c459c4-71ed-46db-a9b2-2547b393e2ca"
      />

      <Pane display="grid" marginTop={16} width={512} gap={32}>
        <BillingStatus />
        <Pane borderBottom />
        <BillingSubscription />
        <Pane borderBottom />
        <BillingEmail />
        <Pane borderBottom />
        <BillingHistory />
      </Pane>
    </Pane>
  )
}

export default withOrgSidebar(Billing)
