import { PLACID_BUCKET_URL_SUFFIX } from 'constants/images'
import { RewardUseCasesKeys } from 'constants/newReward/rewardUseCases/RewardUseCasesKeys'
import { DEFAULT_ROUTES } from 'constants/routes'
import { Rule } from 'gen/perkup/v1/rules_pb'
import { intersectionWith, isEmpty, maxBy } from 'lodash-es'
import sample from 'lodash-es/sample'
import { getPlacidTemplateIdsFromUseCases } from 'pages/NewReward/utils/program-templates'
import { ListFirebaseStorageImages } from 'services/images'
import { RuleType, RuleTypes } from 'types/rules'
import { ordinalSuffixOf } from './uiUtils'

const birthdayGIFS: string[] = [
  'https://media.giphy.com/media/YTbZzCkRQCEJa/giphy.gif',
  'https://media.giphy.com/media/g5R9dok94mrIvplmZd/giphy.gif',
  'https://media.giphy.com/media/V2JgPXdvuCKrDo9uem/giphy.gif',
]

const anniversaryGifs: string[] = [
  'https://media.giphy.com/media/l2JJyDYEX1tXFmCd2/giphy.gif',
  'https://media.giphy.com/media/l0MYt5jPR6QX5pnqM/giphy.gif',
  'https://media.giphy.com/media/IwAZ6dvvvaTtdI8SD5/giphy.gif',
]

const birthdayMessages: string[] = [
  'Wishing you the happiest of the birthdays - here’s to another year of success and positivity!',
  'Cheers to you on your special day! Happy Birthday from the entire team - we hope it’s filled with smiles, laughter and lots of fun!',
  'Happy Birthday and thank you for everything you do! We feel lucky to work alongside someone as dedicated and sincere as you!',
  'Happy Birthday to one of the most devoted, talented and positive people we know! Enjoy your special day and remember that the entire team is grateful to have you on board.',
  'Thank you for everything that you do for the team, it wouldn’t be the same without you! Happy Birthday and we hope you enjoy a relaxing and unforgettable day.',
]

// Helper function for getRandomActionMessage()
function getAnniversaryMessage(year: number): string {
  // Default message
  const ordinalYear = ordinalSuffixOf(year)
  let anniversaryMessage = `Congratulations on your ${ordinalYear} work anniversary! It’s such a pleasure having you on board and we can’t imagine the team without you!`

  // Special messages for specific years
  switch (year) {
    case -1:
      anniversaryMessage =
        'Welcome to the team! We are so excited to have you on board and can’t wait to see what you accomplish in the coming year.'
      break
    case 3:
      anniversaryMessage =
        'How has it been 3 years already? Your positive attitude and hard work are contagious and I want to thank you for being an amazing part of this team. Congratulations and thanks for being you!'
      break
    case 5:
      anniversaryMessage =
        'Wow - can’t believe it’s been 5 years since you started working with us! You’ve been an essential part of our company’s growth and we are deeply grateful for your dedication and hard work. Congratulations on hitting this major milestone!'
      break
    case 10:
      anniversaryMessage =
        'Thank you for your deep loyalty and dedication to the team. After 10 years, it’s impossible to imagine the company without you and it’s been incredible watching you grow and evolve in the past decade. Congratulations on this special milestone!'
      break
    case 20:
      anniversaryMessage =
        'Words are not enough to express our gratitude for everything you’ve accomplished these past 20 years, but I still want to send my deepest congratulations on this very special day! Thank you for hitting this massive milestone with us.'
      break
    default:
      break
  }

  return anniversaryMessage
}

export function getAnniversaryYearTitle(year: number): string {
  if (year === -1) return 'Welcome to the team!'
  const ordinalYear = ordinalSuffixOf(year)

  return `Happy ${ordinalYear} anniversary!`
}

export async function getRandomActionGIF({
  isBirthday,
  year,
  orgId,
}: {
  isBirthday: boolean
  year: number
  orgId: string
}) {
  if (year === -1) {
    const placidImages = await ListFirebaseStorageImages({
      orgId,
      suffix: PLACID_BUCKET_URL_SUFFIX,
    })

    const placidImageUrls = placidImages.map(image => image.url)

    const welcomeImageIds = getPlacidTemplateIdsFromUseCases({
      useCases: [RewardUseCasesKeys.NEW_EMPLOYEE],
    })

    const welcomePlacidImages = intersectionWith(
      placidImageUrls,
      welcomeImageIds,
      (imageUrl, id) => imageUrl.includes(id)
    )

    return isEmpty(welcomePlacidImages)
      ? sample(anniversaryGifs)
      : sample(placidImageUrls)
  }

  const arrayToUse: string[] = isBirthday ? birthdayGIFS : anniversaryGifs

  const randomGIF: string = sample(arrayToUse)!

  return randomGIF
}

export function getRandomActionMessage(
  isBirthday: boolean,
  year: number
): string {
  /**
   * if it's a birthday action, get a random message from the arrat
   * if its a anniversary action, call the helper function to return an appropriate message
   */
  const message = isBirthday
    ? sample(birthdayMessages)!
    : getAnniversaryMessage(year)

  return message
}

export const getRuleType = ({ pathName }: { pathName: string }): RuleType => {
  if (pathName.includes(DEFAULT_ROUTES.ORGANIZATION.REWARDS.BIRTHDAYS.ROOT)) {
    return RuleTypes.birthdays
  }
  if (
    pathName.includes(DEFAULT_ROUTES.ORGANIZATION.REWARDS.ANNIVERSARIES.ROOT)
  ) {
    return RuleTypes.anniversaries
  }

  return RuleTypes.newHires
}

export const getRuleYear = ({
  rules,
  pathName,
}: {
  rules: Rule[]
  pathName: string
}) => {
  const ruleType = getRuleType({ pathName })

  if (ruleType === RuleTypes.newHires) {
    return -1
  }
  if (ruleType === RuleTypes.birthdays) {
    return 0
  }
  let ruleYear = 1
  const highestYear = maxBy(rules, 'year')?.year
  if (highestYear) {
    ruleYear = highestYear + 1
  }
  return ruleYear
}
