import { CloseOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons'
import { Tooltip } from 'antd'
import { GiftCard, ProductGridCard } from 'components'
import { AsyncButton } from 'components/Buttons/AsyncButton'
import { ExchangeRateContext } from 'context'
import { Pane } from 'evergreen-ui'
import {
  ProductVariant,
  ProductVariant_SourceType,
} from 'gen/perkup/v1/product_variant_pb'
import { useDisplayCurrency } from 'hooks'
import { isFunction } from 'lodash-es'
import { useContext } from 'react'
import { isMobile } from 'react-device-detect'
import { Disablable, Selectable } from 'types'
import { numToDollars } from 'utils'
import { isCustomizable, isNearCashProductVariant } from 'utils/productVariant'

export function ProductsGrid({
  products,
  toPrefix,
  onCardClick,
  withPrices = false,
  withAmountsInUsd = false,
  withShipping = false,
  showPrepaidBadge = false,
  onCardSelectButtonClick,
  selectedCardUiProps,
  hideSelectedRibbon = false,
  showCustomizableBadge = false,
  allowCustomization = false,
}: {
  products: Disablable<Selectable<ProductVariant>>[]
  toPrefix?: string
  onCardClick?: (productVariant: ProductVariant) => void
  withPrices?: boolean
  withAmountsInUsd?: boolean
  withShipping?: boolean
  onCardSelectButtonClick?: (
    productVariant: ProductVariant
  ) => void | Promise<void>
  selectedCardUiProps?: {
    buttonText?: string
    ribbonText?: string
  }
  showPrepaidBadge?: boolean
  hideSelectedRibbon?: boolean
  showCustomizableBadge?: boolean
  allowCustomization?: boolean
}) {
  const exchange = useContext(ExchangeRateContext)
  const displayCurrency = useDisplayCurrency()

  return (
    <Pane
      display={isMobile ? 'flex' : 'grid'}
      flexDirection={isMobile ? 'column' : undefined}
      alignItems={isMobile ? 'center' : undefined}
      gridTemplateColumns={
        isMobile
          ? undefined
          : `repeat(auto-fit, minmax(280px, ${products.length > 2 ? '0.5fr' : '0.33fr'}  ))`
      }
      gap={32}
    >
      {products.map(productVariant => {
        const displayAmountInUsd = numToDollars(Number(productVariant.amount))
        const displayAmountInLocal = numToDollars(
          Number(productVariant.amount) * exchange,
          2,
          false,
          displayCurrency
        )
        const displayAmount = withAmountsInUsd
          ? displayAmountInUsd
          : displayAmountInLocal

        const isProductSelected = productVariant.isSelected

        const onClick = onCardClick
          ? () => onCardClick(productVariant)
          : undefined

        if (isNearCashProductVariant(productVariant)) {
          return (
            <GiftCard
              key={productVariant.id}
              onClick={onClick}
              productId={productVariant.productId}
              productVariantId={productVariant.id}
              productImage={productVariant.imageUrl}
              showSelectedRibbon={isProductSelected && !hideSelectedRibbon}
            />
          )
        }

        const canBeCustomized = isCustomizable(productVariant)

        const defaultCtaText =
          canBeCustomized && allowCustomization ? 'Customize' : 'Select'

        const defaultButtonIcon =
          canBeCustomized && allowCustomization ? (
            <EditOutlined />
          ) : (
            <PlusOutlined />
          )

        const extraCta =
          isFunction(onCardSelectButtonClick) &&
          !!productVariant?.productIsAvailable ? (
            <Tooltip
              title={
                productVariant.disabled ? productVariant.disabled : undefined
              }
            >
              <AsyncButton
                size="small"
                type="default"
                icon={isProductSelected ? <CloseOutlined /> : defaultButtonIcon}
                danger={isProductSelected}
                disabled={!!productVariant.disabled}
                isSkeleton={!!productVariant.isDisabledLoading}
                skeletonProps={{
                  size: 'small',
                  style: { width: 80 },
                }}
                onClick={async e => {
                  e.preventDefault()
                  e.stopPropagation()
                  await onCardSelectButtonClick(productVariant)
                }}
              >
                {isProductSelected
                  ? 'Remove'
                  : selectedCardUiProps?.buttonText || defaultCtaText}
              </AsyncButton>
            </Tooltip>
          ) : undefined

        return (
          <ProductGridCard
            key={productVariant.id}
            toPrefix={toPrefix}
            selectedRibbonText={selectedCardUiProps?.ribbonText}
            onClick={productVariant.disabled ? undefined : onClick}
            productId={productVariant.productId}
            productVariantId={productVariant.id}
            productName={productVariant.productName}
            productImages={productVariant.productImages}
            vendor={productVariant.vendor}
            showSelectedRibbon={isProductSelected && !hideSelectedRibbon}
            outOfStock={!productVariant.productIsAvailable}
            price={withPrices ? displayAmount || undefined : undefined}
            showShipping={withShipping}
            tags={productVariant.tags}
            cardCTA={extraCta}
            iso3s={productVariant?.shippingCountries}
            tagline={productVariant.productTagline}
            showPrepaidBadge={
              showPrepaidBadge &&
              productVariant.sourceType ===
                ProductVariant_SourceType.fullPrepaid
            }
            showCustomizableBadge={showCustomizableBadge && canBeCustomized}
          />
        )
      })}
    </Pane>
  )
}
