import { Button, Flex, Segmented } from 'antd'
import {
  AddPersonalFundsButton,
  AddressShortDisplay,
  Footer,
  MemberMobileBottomNav,
  MemberNavigation,
  NewAddressButton,
  PerkScrollbars,
} from 'components'
import OrderConfirmation from 'components/Amazon/OrderConfirmation'
import * as ROUTES from 'constants/routes'
import { Heading, Image, Pane, Strong, Text } from 'evergreen-ui'
import { isMobile } from 'react-device-detect'
import { Outlet, Route, Routes, useNavigate } from 'react-router'

import { ArrowLeftOutlined } from '@ant-design/icons'
import { createShippingAddress } from 'api/databaseCalls'
import {
  AMAZON_LOGO,
  DISCOVER_LOGO,
  FOOD_LOGO,
  GIFT_LOGO,
  SHOP_LOGO,
  SWAG_LOGO,
} from 'assets/contentful'
import Color from 'color'
import { ALGOLIA_PRODUCT_VARIANTS_INDEX } from 'constants/algolia'
import { NUMBER_GREEN } from 'constants/colors'
import { PRODUCT_CATALOG_CLICKED } from 'constants/events'
import { DEFAULT_ROUTES } from 'constants/routes'
import {
  ExchangeRateContext,
  IndividualContext,
  OrgContext,
  OrgUserContext,
  UserContext,
  UserShippingAddressesContext,
} from 'context'
import { ShippingAddress } from 'gen/perkup/v1/root_user_pb'
import { useDefaultOrgColors } from 'hooks'
import { useSwagStoreRoute } from 'hooks/routingHooks/useSwagStoreRoute'
import useIds from 'hooks/useIds'
import { useSpendingOptions } from 'hooks/useSpendingOptions'
import { useContext, useState } from 'react'
import { Link } from 'react-router-dom'
import { afterLastSlash, logEvent, numToDollars } from 'utils'
import { Discover } from './discover-page'
import { Food } from './food-page'
import { GiftCards } from './gift-cards-page'
import { Shopping } from './shopping-page'

function MarketplaceNavItem({
  logo,
  value,
  to,
}: {
  logo: string
  value: string
  to: string
}) {
  const { orgId, userId } = useIds()
  const orgUser = useContext(OrgUserContext)
  return (
    <Link to={to}>
      <Pane
        display="flex"
        flexDirection="column"
        alignItems="center"
        onClick={() =>
          logEvent(PRODUCT_CATALOG_CLICKED, {
            orgId,
            userId,
            route: to,
            orgUserBalance: orgUser?.balance,
            component: 'tab',
            userToken: userId,
            index: ALGOLIA_PRODUCT_VARIANTS_INDEX,
          })
        }
        width={88}
        paddingY={8}
      >
        <Image width={32} height={32} src={logo} />
        <Text>{value}</Text>
      </Pane>
    </Link>
  )
}

export function MarketplaceNavBar() {
  const { showAmazon, showShopping, showSwag, showNearCash, showFood } =
    useSpendingOptions()

  const { defaultColor } = useDefaultOrgColors()
  const primaryColor = Color(defaultColor)
  const backgroundColor = primaryColor.lightness(95).toString()

  const { swagRoute } = useSwagStoreRoute()

  const marketplaceOptions = [
    {
      label: (
        <MarketplaceNavItem
          logo={DISCOVER_LOGO}
          value="Discover"
          to={ROUTES.SHOP}
        />
      ),
      value: 'discover',
    },
    {
      label: (
        <MarketplaceNavItem
          logo={SHOP_LOGO}
          value="Products"
          to={ROUTES.SHOP_PRODUCTS}
        />
      ),
      value: 'products',
      hidden: !showShopping,
    },
    {
      label: (
        <MarketplaceNavItem logo={SWAG_LOGO} value="Swag" to={swagRoute} />
      ),
      value: 'swag',
      hidden: !showSwag,
    },

    {
      label: (
        <MarketplaceNavItem
          logo={AMAZON_LOGO}
          value="Amazon"
          to={ROUTES.AMAZON}
        />
      ),
      value: 'amazon',
      hidden: !showAmazon,
    },
    {
      label: (
        <MarketplaceNavItem
          logo={FOOD_LOGO}
          value="Food"
          to={ROUTES.SHOP_FOOD}
        />
      ),
      value: 'food',
      hidden: !showFood,
    },
    {
      label: (
        <MarketplaceNavItem
          value="Gift cards"
          logo={GIFT_LOGO}
          to={ROUTES.SHOP_GIFT_CARDS}
        />
      ),
      value: 'gift-cards',
      hidden: !showNearCash,
    },
  ]

  const marketplaceOptionsToDisplay = marketplaceOptions.filter(
    option => !option.hidden
  )

  return (
    <PerkScrollbars
      style={{
        borderRadius: 8,
        backgroundColor,
        width: '100%',
      }}
    >
      <Segmented
        style={{
          backgroundColor,
          justifyContent: 'center',
          width: '100%',
        }}
        id="custom-segment-items"
        className={
          isMobile
            ? 'mobile-custom-segment-group'
            : 'desktop-custom-segment-group'
        }
        size="large"
        options={marketplaceOptionsToDisplay}
        value={afterLastSlash(window.location.pathname)}
        defaultValue="discover"
      />
    </PerkScrollbars>
  )
}

export function MarketplaceHeader({
  shippingAddress,
  onShippingAddressChange,
}: {
  shippingAddress: ShippingAddress | undefined
  onShippingAddressChange: (address?: ShippingAddress) => void
}) {
  const navigate = useNavigate()
  const individual = useContext(IndividualContext)
  const org = useContext(OrgContext)
  const user = useContext(UserContext)
  const exchangeRate = useContext(ExchangeRateContext)
  const shippingAddresses = useContext(UserShippingAddressesContext)

  const displayBalance = numToDollars(
    Number(individual.balance) * exchangeRate,
    2,
    false,
    user.displayCurrency || org.displayCurrency
  )

  return (
    <Flex vertical gap={8}>
      {!isMobile && (
        <Button
          style={{ width: 'fit-content', marginLeft: -16 }}
          type="text"
          icon={<ArrowLeftOutlined />}
          onClick={() => navigate(DEFAULT_ROUTES.CARD.ROOT)}
        >
          Return to dashboard
        </Button>
      )}

      <Flex
        vertical={isMobile}
        justify={isMobile ? undefined : 'space-between'}
        align={isMobile ? 'center' : undefined}
        gap={8}
      >
        <Flex align="center" gap={8}>
          <Strong>Balance:</Strong>
          <Strong color={NUMBER_GREEN}>{displayBalance}</Strong>
          <AddPersonalFundsButton asIcon />
        </Flex>
        <Heading justifySelf="center" whiteSpace="nowrap" size={800}>
          {org.name} | Shop
        </Heading>

        <Pane justifySelf="end">
          {shippingAddress ? (
            <AddressShortDisplay
              shippingAddress={shippingAddress}
              onShippingAddressChange={onShippingAddressChange}
              shippingAddresses={shippingAddresses}
            />
          ) : (
            <NewAddressButton
              addAddressCTALabel="Add address"
              submitLabel="Add"
              dialogTitle="Add a new address"
              asSmallButton
              onAddressSubmit={address => onShippingAddressChange(address)}
            />
          )}
        </Pane>
      </Flex>
    </Flex>
  )
}

function MarketplaceLayout() {
  const hideHeader =
    window.location.pathname.includes(ROUTES.BROWSE) ||
    window.location.pathname.includes(ROUTES.CHECKOUT)
  const user = useContext(UserContext)
  const shippingAddresses = useContext(UserShippingAddressesContext)

  const [shippingAddress, setShippingAddress] = useState<
    ShippingAddress | undefined
  >(shippingAddresses.find(sa => sa.id === user?.defaultShippingAddressId))

  const handleShippingAddressChange = (address?: ShippingAddress) => {
    const isExistingAddress = shippingAddresses.some(
      existingAddress => existingAddress?.id === address?.id
    )
    if (!isExistingAddress && !address?.id && address) {
      createShippingAddress({
        shippingAddress: address,
        userId: user?.id,
      })
    }
    setShippingAddress(address)
  }
  return (
    <>
      <MemberNavigation />
      <Flex
        vertical
        gap={32}
        style={{
          minHeight: '100vh',
          padding: isMobile ? 8 : 32,
          paddingBottom: isMobile ? 88 : 0,
        }}
      >
        <Pane width="100%" maxWidth={1920} flexGrow={1}>
          <OrderConfirmation />
          <Flex vertical justify="center" gap={16}>
            {!hideHeader && (
              <Flex vertical gap={16}>
                <MarketplaceHeader
                  shippingAddress={shippingAddress}
                  onShippingAddressChange={handleShippingAddressChange}
                />
                <MarketplaceNavBar />
              </Flex>
            )}
            <Outlet
              context={{
                shippingAddress,
                onShippingAddressChange: handleShippingAddressChange,
              }}
            />
          </Flex>
        </Pane>
        <Footer />
      </Flex>
      {isMobile && <MemberMobileBottomNav />}
    </>
  )
}

function Marketplace() {
  return (
    <Routes>
      <Route element={<MarketplaceLayout />}>
        <Route index element={<Discover />} />
        <Route path={`${ROUTES.FOOD}/*`} element={<Food />} />
        <Route path={`${ROUTES.GIFT_CARDS}/*`} element={<GiftCards />} />
        <Route path={`${ROUTES.PRODUCTS}/*`} element={<Shopping />} />
      </Route>
    </Routes>
  )
}

export default Marketplace
