import { Pane, useTheme } from 'evergreen-ui'
import { useEffect, useState } from 'react'
import Confetti from 'react-confetti'
import { useLocation } from 'react-router'

export function ConfettiCelebration() {
  const [isShown, setIsShown] = useState(false)

  const theme = useTheme()

  const { state } = useLocation()

  useEffect(() => {
    if (state?.confetti) setIsShown(true)
  }, [state])

  if (!isShown) return null

  return (
    <Pane position="fixed" top={0} left={0}>
      <Confetti
        width={window.innerWidth}
        height={window.innerHeight}
        colors={[
          theme?.colors?.blue500,
          theme?.colors?.red500,
          theme?.colors?.orange500,
          theme?.colors?.yellow100,
          theme?.colors?.green500,
          theme?.colors?.teal100,
          theme?.colors?.purple600,
        ]}
        recycle={false}
      />
    </Pane>
  )
}
