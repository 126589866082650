import type { InputNumberProps } from 'antd'
import { Checkbox, Flex, InputNumber } from 'antd'
import { FILTER_DISPLAY_NAMES } from 'constants/algolia'
import { Strong } from 'evergreen-ui'
import { ProductVariant } from 'gen/perkup/v1/product_variant_pb'
import { capitalize, uniqBy } from 'lodash-es'
import { useState } from 'react'
import {
  RangeInput,
  UseNumericMenuProps,
  useClearRefinements,
  useNumericMenu,
  useRange,
} from 'react-instantsearch'
import { insertElementIf } from 'utils/arrays'

export function AlgoliaPriceFilterCheckbox({
  attribute,
  defaultFilter = [],
  disabled = false,
  filterLabelPrefix = 'Gifts',
}: {
  attribute: keyof ProductVariant
  defaultFilter?: UseNumericMenuProps['items']
  disabled?: boolean
  filterLabelPrefix?: string
}) {
  const { refine: clearRefinements } = useClearRefinements({
    includedAttributes: [attribute],
  })

  const isSwag = filterLabelPrefix === 'Swag' || !filterLabelPrefix // Hot fix for cam

  const budgetOptions = uniqBy(
    [
      ...defaultFilter,
      { label: `${filterLabelPrefix} under $25`, end: 25_00 },
      { label: `${filterLabelPrefix} under $50`, start: 25_00, end: 50_00 },
      { label: `${filterLabelPrefix} under $75`, start: 50_00, end: 75_00 },
      { label: `${filterLabelPrefix} under $100`, start: 75_00, end: 100_00 },
      { label: `${filterLabelPrefix} under $150`, start: 100_00, end: 150_00 },
      ...insertElementIf(!isSwag, {
        label: `${filterLabelPrefix} under $200`,
        start: 150_00,
        end: 200_00,
      }),
      ...insertElementIf(!isSwag, {
        label: `${filterLabelPrefix} under $500`,
        start: 300_00,
        end: 500_00,
      }),
    ],
    'label'
  )

  const { items, refine: refineCheckbox } = useNumericMenu({
    attribute,
    items: budgetOptions,
  })

  const activeItem = items.find(item => item.isRefined)

  const {
    start,
    canRefine,
    refine: refineRange,
  } = useRange({ attribute, min: 0 })

  const [localMin, setLocalMin] = useState<number>()
  const [localMax, setLocalMax] = useState<number>()

  const currentMinInAlgolia = Number.isFinite(start[0]) ? start[0] : undefined
  const currentMaxInAlgolia = Number.isFinite(start[1]) ? start[1] : undefined

  const handleClickCheckboxItem = (label: string, value: string) => {
    if (value === activeItem?.value) {
      setLocalMin(undefined)
      setLocalMax(undefined)
      clearRefinements()
    } else {
      const filterOption = budgetOptions.find(option => option.label === label)
      setLocalMin(filterOption?.start ? filterOption.start / 100 : undefined)
      setLocalMax(filterOption?.end ? filterOption.end / 100 : undefined)
      refineCheckbox(value)
    }
  }

  const handleChangeMin: InputNumberProps['onChange'] = value =>
    setLocalMin(value ? Number(value) : undefined)

  const handleChangeMax: InputNumberProps['onChange'] = value =>
    setLocalMax(value ? Number(value) : undefined)

  const handleMinStep = (value: number) =>
    refineRange([value * 100, currentMaxInAlgolia])

  const handleMaxStep = (value: number) =>
    refineRange([currentMinInAlgolia, value * 100])

  const handleMinBlur = () => {
    if (localMin === currentMinInAlgolia) return
    if (!localMin) refineRange([undefined, currentMaxInAlgolia])
    else refineRange([localMin * 100, currentMaxInAlgolia])
  }

  const handleMaxBlur = () => {
    if (localMax === currentMaxInAlgolia) return
    if (!localMax) refineRange([currentMinInAlgolia, undefined])
    else refineRange([currentMinInAlgolia, localMax * 100])
  }

  return (
    <Flex vertical gap={8}>
      <Strong>{capitalize(FILTER_DISPLAY_NAMES[attribute])}</Strong>
      <Flex vertical gap={4}>
        {items.map(item => (
          <Checkbox
            style={{ width: 'max-content' }}
            key={item.value}
            checked={item.isRefined}
            disabled={disabled}
            onChange={() => handleClickCheckboxItem(item.label, item.value)}
          >
            {capitalize(item.label.trim())}
          </Checkbox>
        ))}
      </Flex>
      <Flex gap={4}>
        <InputNumber
          prefix="$"
          placeholder="Min"
          disabled={disabled || !canRefine}
          value={localMin ?? null} // Clear the input if value is falsy
          min={0}
          max={currentMaxInAlgolia ? currentMaxInAlgolia / 100 : undefined}
          changeOnBlur
          onStep={handleMinStep}
          onBlur={handleMinBlur}
          onChange={handleChangeMin}
        />
        <InputNumber
          prefix="$"
          placeholder="Max"
          disabled={disabled || !canRefine}
          value={localMax ?? null} // Clear the input if value is falsy
          min={currentMinInAlgolia ? currentMinInAlgolia / 100 : undefined}
          max={undefined}
          changeOnBlur
          onStep={handleMaxStep}
          onBlur={handleMaxBlur}
          onChange={handleChangeMax}
        />
        {/* As mentioned in /gifts route in perkup public, we need this or else useRange hook doesn't work properly */}
        <div style={{ display: 'none' }}>
          <RangeInput attribute={attribute} />
        </div>
      </Flex>
    </Flex>
  )
}
