import * as ROUTES from 'constants/routes'
import { OrgContext } from 'context'
import { useContext } from 'react'

export function useShopRoute() {
  const org = useContext(OrgContext)

  let shopRoute = ROUTES.SHOP_PRODUCTS

  if (org.settings?.hideShopping) {
    shopRoute = ROUTES.SHOP
  }

  return { shopRoute }
}
