import { captureException } from '@sentry/react'
import {
  collection,
  getFirestore,
  onSnapshot,
  query,
  where,
} from 'firebase/firestore'
import { Cart } from 'gen/perkup/v1/cart_pb'
import { toSentry } from 'utils'
import { converter } from 'utils/firestore'

export function ListenToActiveCartsByIndividualId({
  individualId,
  orgId,
  cb,
}: {
  individualId: string
  orgId: string
  cb: (carts: Cart[]) => void
}) {
  const db = getFirestore()
  const colRef = collection(db, 'carts').withConverter(converter(Cart))

  const q = query(
    colRef,
    where('individualId', '==', individualId),
    where('orgId', '==', orgId),
    where('isActive', '==', true)
  )

  return onSnapshot(
    q,
    { includeMetadataChanges: true },
    query => {
      cb(query.docs.map(doc => doc.data()))
    },
    error => {
      console.error(error)
      captureException(toSentry(error), {
        contexts: {
          ListenToOrgAccounts: { orgId, individualId },
        },
      })
      cb([])
    }
  )
}
