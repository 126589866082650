import { captureException } from '@sentry/react'
import {
  collection,
  getDocs,
  getFirestore,
  query,
  where,
} from 'firebase/firestore'
import { OrderFulfillment } from 'gen/perkup/v1/vendor_pb'
import { isEmpty } from 'lodash-es'
import { storedId } from 'utils/firestore'
import { toSentry } from 'utils/sentry'
import { ListBalanceTransactionsByProgramId } from './orgUsers'

export async function GetOrderFulfillmentsByOrderId({
  orderId,
}: {
  orderId: string
}) {
  const orderFulfillments: OrderFulfillment[] = []

  try {
    const db = getFirestore()
    const colRef = collection(db, 'orderFulfillments').withConverter(
      storedId(OrderFulfillment)
    )
    const q = query(colRef, where('orderId', '==', orderId))
    await getDocs(q).then(querySnapshot =>
      querySnapshot.forEach(doc => orderFulfillments.push(doc.data()))
    )
  } catch (error) {
    console.error(error)
    captureException(toSentry(error), {
      contexts: {
        GetOrderFulfillmentsByOrderId: { orderId },
      },
    })
  }

  return orderFulfillments
}

export async function GetOrderFulfillmentsForMember({
  userId,
  programId,
  orgId,
}: {
  userId: string
  programId: string
  orgId: string
}) {
  try {
    const balanceTransactions = await ListBalanceTransactionsByProgramId({
      userId,
      programId,
      orgId,
    })
    if (!balanceTransactions || isEmpty(balanceTransactions)) return []
    const authId = balanceTransactions[0]?.authId

    if (!authId) return []

    const orderFulfillments = await GetOrderFulfillmentsByOrderId({
      orderId: authId,
    })

    return orderFulfillments
  } catch (error) {
    console.error(error)
    captureException(toSentry(error), {
      contexts: {
        GetOrderFulfillmentsForMember: { userId, programId },
      },
    })
    return []
  }
}
