import { GiftOutlined } from '@ant-design/icons'
import Skeleton from 'antd/es/skeleton'
import { ConfettiCelebration } from 'components/ConfettiCelebration'
import { EmptyState, Pane, Tab, Tablist, useTheme } from 'evergreen-ui'
import { ProgramStatus } from 'gen/perkup/v1/program_pb'
import { useMemberships } from 'hooks'
import { capitalize } from 'lodash-es'
import isEmpty from 'lodash-es/isEmpty'
import { useMemo, useState } from 'react'
import { isMobile } from 'react-device-detect'
import {
  getActiveMemberships,
  getInactiveMemberships,
  isSpentGiftList,
  sortMembershipsByCreationDate,
} from 'utils/programs'
import { MembershipSummary } from './MembershipSummary'
import { MEMBERSHIP_TABS, MEMBERSHIP_TAB_LABELS } from './constants/memberships'

export default function MembershipSummaries() {
  const theme = useTheme()
  const [currentStatusTab, setCurrentStatusTab] = useState<ProgramStatus>(
    ProgramStatus.active
  )
  const { memberships, isLoading } = useMemberships()

  const activeMemberships = useMemo(
    () => getActiveMemberships(memberships),
    [memberships]
  )
  const inactiveMemberships = useMemo(
    () => getInactiveMemberships(memberships),
    [memberships]
  )

  const membershipsFilteredByStatus =
    currentStatusTab === ProgramStatus.active
      ? activeMemberships
      : inactiveMemberships

  if (isLoading) return <Skeleton active paragraph={{ rows: 2 }} />

  if (isEmpty(memberships) || isSpentGiftList(memberships)) {
    return (
      <Pane
        display="flex"
        flexDirection="column"
        justifyContent="center"
        flex={1}
        marginTop={-32}
      >
        <EmptyState
          background="light"
          title="No rewards yet"
          description="When you receive a reward, you'll get an email and it will appear here."
          orientation={isMobile ? 'vertical' : 'horizontal'}
          icon={
            <GiftOutlined style={{ color: theme.colors.muted, fontSize: 24 }} />
          }
          iconBgColor={theme.colors.gray200}
        />
      </Pane>
    )
  }

  // Render all of the user's memberships

  return (
    <Pane display="flex" flexDirection="column">
      <Tablist marginBottom={16}>
        {MEMBERSHIP_TABS.map(tab => (
          <Tab
            key={tab}
            appearance="primary"
            id={ProgramStatus[tab]}
            onSelect={() => setCurrentStatusTab(tab)}
            isSelected={tab === currentStatusTab}
          >
            {capitalize(MEMBERSHIP_TAB_LABELS[tab])}
          </Tab>
        ))}
      </Tablist>
      {sortMembershipsByCreationDate({
        memberships: membershipsFilteredByStatus,
      }).map(membership => {
        return (
          <MembershipSummary
            key={membership?.program?.id}
            membership={membership}
          />
        )
      })}
      <ConfettiCelebration />
    </Pane>
  )
}
