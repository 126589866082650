import { Pane, Text, useTheme } from 'evergreen-ui'

export function AccountItemLabel({
  accountName,
  balanceDisplay,
}: {
  accountName: string
  balanceDisplay: string | null
}) {
  const theme = useTheme()
  return (
    <Pane
      display="flex"
      width="100%"
      minWidth="208px"
      justifyContent="space-between"
      alignItems="center"
    >
      <Text>{accountName}</Text>{' '}
      <Text color={theme.colors.green400}>{balanceDisplay}</Text>
    </Pane>
  )
}
