import {
  AppstoreOutlined,
  MinusOutlined,
  PlusOutlined,
  SkinOutlined,
} from '@ant-design/icons'
import { Button, Flex, Modal, Segmented, Skeleton, Tooltip } from 'antd'
import {
  PerkEmpty,
  PerkScrollbars,
  ProductCollectionCard,
  ProductCollectionsGrid,
} from 'components'
import { AsyncButton } from 'components/Buttons/AsyncButton'
import { SWAG_COLLECTION, SWAG_PRODUCTS } from 'constants/newReward'
import { OCCASION_COLLECTION_IDS } from 'constants/productCollections'
import { OrgContext } from 'context'
import { Heading, Text, toaster } from 'evergreen-ui'
import {
  AlgoliaBrowseCollectionProducts,
  AlgoliaBrowseProducts,
  ProductDetails,
  ProductRetails,
} from 'features'
import { CanvasDesign, Supplier } from 'gen/canvas/designs_pb'
import {
  ProductCollection,
  ProductCollection_Permission,
} from 'gen/perkup/v1/product_collections_pb'
import { ProductVariant } from 'gen/perkup/v1/product_variant_pb'
import { useAllowedCollections, useListCanvasDesigns } from 'hooks'
import useListAllProductVariantsByProductIds from 'hooks/productVariants/useListProductVariantsByProductIds'
import useIds from 'hooks/useIds'
import { keys } from 'lodash-es'
import { ReactNode, useContext, useMemo, useState } from 'react'
import { createCanvasDesign } from 'services'
import {
  DesignVariable,
  Disablable,
  ProductRetailsData,
  Selectable,
} from 'types'
import { convertMockupIdsToUrls, makePlural } from 'utils'
import {
  constructUnifiedOptionsText,
  getUniqAndFormatWithSelectable,
  getVariantOptionValuesFromCanvasDesign,
  isCustomizable,
} from 'utils/productVariant'
import { useSelectRewardContext } from '../SelectRewardContext'

function BrowseSwagProductCollections({
  currentProductCollectionId,
  onCollectionSelect,
  onCollectionRemoveClick,
  firstRowSuffix,
}: {
  currentProductCollectionId: string | undefined
  onCollectionSelect: (productCollection: ProductCollection) => void
  onCollectionRemoveClick: () => void
  firstRowSuffix?: ReactNode
}) {
  const [collectionToInspect, setCollectionToInspect] =
    useState<ProductCollection>()

  const { isLoading, allowedCollections } = useAllowedCollections({
    minPermission: ProductCollection_Permission.send,
  })

  const collectionsToRender = allowedCollections.filter(
    collection => collection.productsCount > 0
  )

  if (isLoading) {
    return (
      <Flex vertical>
        {Array.from({ length: 3 }).map((_, index) => (
          <Skeleton
            key={`${index.toString()}`}
            paragraph={{ rows: 2 }}
            style={{ margin: 'auto', padding: 32 }}
            active
          />
        ))}
      </Flex>
    )
  }

  if (collectionsToRender.length === 0) {
    return (
      <PerkEmpty
        header="No product collections found"
        description={"We couldn't find exactly what you were looking for"}
      />
    )
  }

  return (
    <>
      <Flex vertical style={{ width: '100%' }}>
        <Flex align="center" style={{ padding: '16px 0px' }}>
          <Flex flex={1}>
            <Heading size={700}>Select a collection</Heading>
          </Flex>
          <Text color="muted" minWidth="fit-content" height="fit-content">
            {makePlural('collection', collectionsToRender.length, true)}
          </Text>
          {firstRowSuffix}
        </Flex>

        <ProductCollectionsGrid>
          {collectionsToRender.map(collection => {
            const isSelected = currentProductCollectionId === collection.id
            return (
              <ProductCollectionCard
                key={collection.id}
                isSelected={isSelected}
                productCollection={collection}
                onClick={() => {
                  if (isSelected) onCollectionRemoveClick()
                  else onCollectionSelect(collection)
                }}
              >
                <ProductCollectionCard.Details />

                <ProductCollectionCard.Row>
                  <Tooltip title="View products">
                    <div>
                      <ProductCollectionCard.Row.ProductCount
                        onClick={() => setCollectionToInspect(collection)}
                      />
                    </div>
                  </Tooltip>
                  <ProductCollectionCard.Row.Cta>
                    {isSelected ? (
                      <AsyncButton
                        onClick={e => {
                          e.stopPropagation()
                          onCollectionRemoveClick()
                        }}
                        size="small"
                        icon={<MinusOutlined />}
                      >
                        Remove
                      </AsyncButton>
                    ) : (
                      <AsyncButton
                        onClick={e => {
                          e.stopPropagation()
                          onCollectionSelect(collection)
                        }}
                        size="small"
                        icon={<PlusOutlined />}
                      >
                        Select
                      </AsyncButton>
                    )}
                  </ProductCollectionCard.Row.Cta>
                </ProductCollectionCard.Row>
              </ProductCollectionCard>
            )
          })}
        </ProductCollectionsGrid>
      </Flex>

      {collectionToInspect && (
        <Modal
          open={!!collectionToInspect}
          title={collectionToInspect.name}
          onClose={() => setCollectionToInspect(undefined)}
          footer={
            <Button
              type={
                currentProductCollectionId === collectionToInspect.id
                  ? 'default'
                  : 'primary'
              }
              onClick={() => {
                if (currentProductCollectionId === collectionToInspect.id)
                  onCollectionRemoveClick()
                else onCollectionSelect(collectionToInspect)

                setCollectionToInspect(undefined)
              }}
            >
              {currentProductCollectionId === collectionToInspect.id
                ? 'Remove collection'
                : 'Select collection'}
            </Button>
          }
          width="min(80%, 800px)"
        >
          <AlgoliaBrowseCollectionProducts
            productCollectionId={collectionToInspect.id}
          />
        </Modal>
      )}
    </>
  )
}

function BrowseSwagProducts({
  selectedProductIds,
  onProductVariantClick,
  onDesignChange,
  extraElementsInFirstRow,
  bottomOffsetAmount,
  minCollectionPermission,
  minSelectPermission = minCollectionPermission,
}: {
  selectedProductIds: string[]
  onProductVariantClick: (
    productVariant: ProductVariant,
    allSelectedVariants: ProductVariant[],
    design?: CanvasDesign
  ) => void | Promise<void>
  onDesignChange?: (design: CanvasDesign) => void
  extraElementsInFirstRow?: ReactNode
  bottomOffsetAmount?: number
  minCollectionPermission: ProductCollection_Permission
  minSelectPermission?: ProductCollection_Permission
}) {
  const { orgId, individualId, userId } = useIds()
  const { selectedReward } = useSelectRewardContext()

  const [isAddingCustomizedProduct, setIsAddingCustomizedProduct] =
    useState(false)
  const [productVariantToShow, setProductVariantToShow] =
    useState<Disablable<Selectable<ProductVariant>>>()

  const { productVariants } = useListAllProductVariantsByProductIds({
    productIds: selectedProductIds,
  })

  const uniqueSelectedVariants = getUniqAndFormatWithSelectable(productVariants)

  const handleCtaClick = async (productVariant: ProductVariant) => {
    if (
      isCustomizable(productVariant) &&
      !selectedProductIds.includes(productVariant.productId)
    ) {
      // If customization is allowed, show the customization form
      setProductVariantToShow(productVariant)
      return
    }
    setProductVariantToShow(undefined)
    await onProductVariantClick(productVariant, uniqueSelectedVariants)
  }

  const selectedPvIsCustomizable =
    productVariantToShow && isCustomizable(productVariantToShow)

  const canvasDesignIds = useMemo(() => {
    return selectedReward?.gift?.canvasDesignIds || []
  }, [selectedReward?.gift?.canvasDesignIds])

  const { canvasDesigns } = useListCanvasDesigns(canvasDesignIds)

  const canvasDesignForToggledProduct = useMemo(() => {
    return canvasDesigns.find(
      design => design.shopifyProductId === productVariantToShow?.productId
    )
  }, [canvasDesigns, productVariantToShow?.productId])

  const handleProductRetailsSubmit = async (
    productRetailsData: ProductRetailsData
  ) => {
    setIsAddingCustomizedProduct(true)
    const {
      formData: {
        selectedProductVariant,
        selectedCustomerCanvasDesignId,
        selectedCustomerCanvasVariantId,
        selectedCustomerCanvasMockupIds,
        selectedCustomerCanvasOption,
        selectedVariables,
      },
    } = productRetailsData

    const mockupUrls = convertMockupIdsToUrls(
      selectedCustomerCanvasMockupIds,
      selectedCustomerCanvasDesignId,
      selectedVariables
    )
    const newDesign = await createCanvasDesign({
      orgId,
      individualId,
      userId,
      sku: selectedProductVariant?.sku,
      options: [selectedCustomerCanvasOption],
      customerCanvasVariantId: selectedCustomerCanvasVariantId,
      publicDesignId: selectedCustomerCanvasDesignId,
      mockupUrls,
      variables: selectedVariables,
      supplier: Supplier.printful,
      productId: selectedProductVariant?.productId,
    })

    if (!newDesign) {
      toaster.danger('Failed to create design')
      setIsAddingCustomizedProduct(false)

      return
    }

    if (canvasDesignForToggledProduct && onDesignChange) {
      onDesignChange(newDesign)
    } else {
      await onProductVariantClick(
        selectedProductVariant,
        uniqueSelectedVariants,
        newDesign
      )
    }

    setProductVariantToShow(undefined)

    setIsAddingCustomizedProduct(false)
  }

  const selectedProductVariant = uniqueSelectedVariants.find(
    pv => pv.productId === productVariantToShow?.productId
  )

  const defaultProductRetailsValues = useMemo(() => {
    if (!canvasDesignForToggledProduct) return undefined

    const defaultVariables: Record<string, DesignVariable> = {}
    canvasDesignForToggledProduct.variables.forEach(variable => {
      defaultVariables[variable.name] = {
        name: variable.name,
        value: variable.value,
        type: variable.type as DesignVariable['type'],
      }
    })

    return {
      selectedCustomerCanvasVariantId:
        canvasDesignForToggledProduct?.customerCanvasVariantId
          ? Number(canvasDesignForToggledProduct.customerCanvasVariantId)
          : undefined,
      selectedVariables: defaultVariables,
      selectedCustomerCanvasOption: undefined,
      selectedProductVariant,
    }
  }, [canvasDesignForToggledProduct, selectedProductVariant])

  return (
    <>
      <AlgoliaBrowseProducts>
        <AlgoliaBrowseProducts.Swag
          title="Select swag"
          onProductCardClick={pv => setProductVariantToShow(pv)}
          selectedVariants={uniqueSelectedVariants}
          onCardSelectButtonClick={handleCtaClick}
          showSelectedVariantsFirst={false}
          bottomOffsetAmount={bottomOffsetAmount}
          minPermission={minCollectionPermission}
          minSelectPermission={minSelectPermission}
          childrenNextToProductCount={extraElementsInFirstRow}
          allowCustomization
        />
      </AlgoliaBrowseProducts>
      <Modal
        width="fit-content"
        centered
        title="‎"
        open={!!productVariantToShow}
        styles={{
          content: {
            padding: '16px 0',
          },
          body: {
            padding: 0,
          },
        }}
        footer={null}
        onCancel={() => setProductVariantToShow(undefined)}
      >
        {productVariantToShow && (
          <PerkScrollbars
            style={{ padding: '0 24px' }}
            options={{ overflow: { x: 'hidden' } }}
          >
            {selectedPvIsCustomizable ? (
              <ProductRetails
                key={canvasDesignForToggledProduct?.id}
                onSubmit={handleProductRetailsSubmit}
                productVariant={productVariantToShow}
                defaultValues={defaultProductRetailsValues}
              >
                <ProductRetails.Title />
                <ProductRetails.Price inUsd />
                <ProductRetails.Variants
                  optionsToExclude={['size']}
                  defaultSelectedOptions={
                    canvasDesignForToggledProduct
                      ? getVariantOptionValuesFromCanvasDesign({
                          productVariant: productVariantToShow,
                          canvasDesign: canvasDesignForToggledProduct,
                        })
                      : undefined
                  }
                />
                <ProductRetails.Inputs />
                <section className="flex gap-2">
                  <Button
                    className="flex-1"
                    htmlType="submit"
                    type="primary"
                    loading={isAddingCustomizedProduct}
                  >
                    {selectedProductIds.includes(productVariantToShow.productId)
                      ? 'Update '
                      : 'Add '}
                    product
                  </Button>
                  {selectedProductVariant && (
                    <Button
                      className="flex-1"
                      danger
                      disabled={isAddingCustomizedProduct}
                      onClick={() => {
                        handleCtaClick(selectedProductVariant)
                      }}
                    >
                      Remove product
                    </Button>
                  )}
                </section>
                <ProductRetails.Shipping />
                <ProductRetails.Description />
              </ProductRetails>
            ) : (
              <ProductDetails
                key={productVariantToShow.id}
                withQuantityTable
                productVariant={productVariantToShow}
                showPrice
                showShipping
                showShippingCountries
                disableOutOfStock={false}
                onSubmit={handleCtaClick}
                disableVariantOptions
                submitButtonProps={{
                  type: productVariantToShow.isSelected ? 'default' : 'primary',
                  danger: productVariantToShow.isSelected,
                  children: productVariantToShow.isSelected
                    ? 'Remove swag'
                    : 'Add swag',
                }}
                alertProps={
                  keys(productVariantToShow?.options || {}).length >= 1
                    ? {
                        type: 'info',
                        message: `Recipients will select ${constructUnifiedOptionsText(productVariantToShow)}`,
                      }
                    : undefined
                }
              />
            )}
          </PerkScrollbars>
        )}
      </Modal>
    </>
  )
}

export function SwagForm() {
  const { selectedReward, swagForm } = useSelectRewardContext()
  const { id: orgId } = useContext(OrgContext)

  const isSwagCollection =
    selectedReward?.gift?.productCollectionId &&
    !OCCASION_COLLECTION_IDS.includes(selectedReward.gift.productCollectionId)

  const [selectedTab, setSelectedTab] = useState<
    typeof SWAG_PRODUCTS | typeof SWAG_COLLECTION
  >(isSwagCollection ? SWAG_COLLECTION : SWAG_PRODUCTS)

  const swagFormToggle = (
    <Segmented
      options={[
        { value: SWAG_PRODUCTS, label: SWAG_PRODUCTS, icon: <SkinOutlined /> },
        {
          value: SWAG_COLLECTION,
          label: SWAG_COLLECTION,
          icon: <AppstoreOutlined />,
        },
      ]}
      style={{ width: 'fit-content', marginLeft: 16 }}
      className="small-custom-segment"
      size="large"
      value={selectedTab}
      onChange={setSelectedTab}
    />
  )

  if (selectedTab === SWAG_PRODUCTS) {
    return (
      <Flex vertical style={{ width: '100%', marginBottom: 64 }}>
        <BrowseSwagProducts
          minCollectionPermission={ProductCollection_Permission.view}
          minSelectPermission={ProductCollection_Permission.send}
          selectedProductIds={selectedReward?.gift?.productIds ?? []}
          onProductVariantClick={swagForm.handleSelectSwagProductVariant}
          onDesignChange={design =>
            swagForm.handleDesignChange({ design, orgId })
          }
          extraElementsInFirstRow={swagFormToggle}
          bottomOffsetAmount={73}
        />
      </Flex>
    )
  }

  return (
    <Flex
      style={{
        width: '100%',
        marginBottom: 64,
      }}
    >
      <BrowseSwagProductCollections
        currentProductCollectionId={selectedReward?.gift?.productCollectionId}
        firstRowSuffix={swagFormToggle}
        onCollectionSelect={swagForm.handleSelectSwagProductCollection}
        onCollectionRemoveClick={swagForm.handleRemoveCollection}
      />
    </Flex>
  )
}
