import Select, { SelectProps } from 'antd/es/select'
import { Text } from 'evergreen-ui'
import { useOrgLists } from 'hooks'
import { formatLabelSelectOptions } from 'utils/labels'

type LabelRender = SelectProps['labelRender']

const labelRender: LabelRender = ({ label }) => {
  return <Text size={300}>{label}</Text>
}

export function LabelSelect({
  selectedLabel, // This passed in value is expected to be a string with the format: '{"key":"value"}'
  onLabelSelect,
}: {
  selectedLabel: string | undefined
  onLabelSelect: ({ value, key }: { value: string; key: string }) => void
}) {
  const { orgLists, hasLoaded } = useOrgLists()

  // Need to parse to match the schema we created down in the options prop where value = `${list.id}:${option}`
  const valueThatMatchesOurSchema = selectedLabel?.replace(/['"{}]+/g, '')

  return (
    <Select
      disabled={!hasLoaded}
      placeholder={<Text size={300}>Select by label</Text>}
      variant="borderless"
      value={valueThatMatchesOurSchema ?? null}
      dropdownStyle={{ width: 150 }}
      labelRender={labelRender}
      placement="bottomRight"
      options={formatLabelSelectOptions(orgLists)}
      onSelect={option => {
        const [key, value] = option.split(':')
        onLabelSelect({ key, value })
      }}
    />
  )
}
