import { CheckOutlined, CloseOutlined } from '@ant-design/icons'
import { Flex, Switch } from 'antd'
import { TemplateContext } from 'context'
import { Heading, Text } from 'evergreen-ui'
import { Template_CoreValues } from 'gen/perkup/v1/org_template_pb'
import { CoreValuesTags } from 'pages/NewReward/components/CoreValuesTags'
import { useContext } from 'react'

export function TemplateCoreValuesForm({
  onCoreValuesChange,
}: {
  onCoreValuesChange: (newCoreValues: Template_CoreValues) => void
}) {
  const template = useContext(TemplateContext)
  const coreValues = template?.coreValues
  return (
    <Flex vertical gap={8}>
      <Heading>Core values</Heading>
      <CoreValuesTags
        showTitle={false}
        selectedCoreValues={coreValues?.values || []}
        setSelectedCoreValues={cv => {
          const formattedCvs = new Template_CoreValues({
            ...coreValues,
            values: cv,
          })
          onCoreValuesChange(formattedCvs)
        }}
      />
      <Flex gap={8}>
        <Switch
          unCheckedChildren={<CloseOutlined />}
          checkedChildren={<CheckOutlined />}
          checked={!!coreValues?.writable}
          onChange={checked => {
            onCoreValuesChange(
              new Template_CoreValues({
                ...coreValues,
                writable: checked,
              })
            )
          }}
        />

        <Text>Let sender select core values</Text>
      </Flex>
    </Flex>
  )
}
