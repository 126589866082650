import { Flex, Tag } from 'antd'
import { findFlagUrlByIso2Code } from 'country-flags-svg'
import { Avatar, Heading, Pane, Paragraph } from 'evergreen-ui'
import { ShippingAddress } from 'gen/perkup/v1/root_user_pb'
import { parsePhoneNumber } from 'utils'

export function AddressDisplay({
  address,
  showDefaultBadge = false,
}: {
  address: ShippingAddress
  showDefaultBadge?: boolean
}) {
  const {
    name,
    line1,
    line2,
    city,
    state,
    postalCode,
    phoneNumber,
    country,
    localizationExtensions,
  } = address

  const addressLines = `${line1} ${line2 ?? ''}`.trim() // Address 2 might be undefined, so we don't want to render the string 'undefined'
  const parsedPhoneNumber = parsePhoneNumber(phoneNumber)

  const countryFlag = findFlagUrlByIso2Code(country)
  const regions = new Intl.DisplayNames(['en'], { type: 'region' })

  const countryDisplayName = regions.of(country.toUpperCase())
  const brazilCFP = localizationExtensions?.shippingCredentialBr
  const chinaResidentId = localizationExtensions?.shippingCredentialCn
  const southKoreaPccc = localizationExtensions?.shippingCredentialKr

  return (
    <Pane>
      <Flex justify="space-between" align="center">
        <Heading size={400}>{name}</Heading>
        {showDefaultBadge && (
          <Tag color="orange" style={{ marginRight: 0 }}>
            Default
          </Tag>
        )}
      </Flex>

      <Paragraph size={400}>{addressLines}</Paragraph>
      <Paragraph size={400}>
        {city}, {state} {postalCode}
      </Paragraph>
      <Pane display="flex">
        <Paragraph size={400}>{countryDisplayName}</Paragraph>
        <Avatar
          size={18}
          src={countryFlag}
          alignSelf="center"
          marginLeft={10}
        />
      </Pane>

      {parsedPhoneNumber && (
        <Paragraph size={400}>
          Phone number: {parsedPhoneNumber.formatNational()}
        </Paragraph>
      )}
      {brazilCFP && <Paragraph size={400}>CPF: {brazilCFP}</Paragraph>}
      {chinaResidentId && (
        <Paragraph size={400}>Resident ID: {chinaResidentId}</Paragraph>
      )}
      {southKoreaPccc && (
        <Paragraph size={400}>PCCC: {southKoreaPccc}</Paragraph>
      )}
    </Pane>
  )
}
