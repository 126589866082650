// Assets
import ADPrunLogo from 'assets/payroll-providers/adp-logo.png'
import BambooHRLogo from 'assets/payroll-providers/bamboohr-logo-green.png'
import GustoLogo from 'assets/payroll-providers/Gusto logo.png'
import HiBobLogo from 'assets/payroll-providers/hibob-logo.png'
import InsperityLogo from 'assets/payroll-providers/insperity-logo.png'
import QuickbooksPayrollLogo from 'assets/payroll-providers/Intuit-QuickBooks-Payroll.png'
import JustworksLogo from 'assets/payroll-providers/JW_LOGO_1080x200.png'
import NamelyLogo from 'assets/payroll-providers/namely-logo.png'
import PaychexLogo from 'assets/payroll-providers/paychex-logo.png'
import PaycomLogo from 'assets/payroll-providers/paycom-logo.png'
import PaycorLogo from 'assets/payroll-providers/paycor-logo.png'
import PaylocityLogo from 'assets/payroll-providers/paylocity-logo.png'
import RipplingLogo from 'assets/payroll-providers/Rippling_logo_Full_Black.png'
import SquareLogo from 'assets/payroll-providers/square-logo.png'
import TriNetLogo from 'assets/payroll-providers/trinet-logo.png'
import UKGLogo from 'assets/payroll-providers/ukg-logo.png'
import WorkdayLogo from 'assets/payroll-providers/Workday_Logo.png'
import ZenefitsLogo from 'assets/payroll-providers/zen_logo.png'

const integrations = [
  { name: 'gusto', logoUrl: GustoLogo },
  { name: 'rippling', logoUrl: RipplingLogo },
  { name: 'zenefits', logoUrl: ZenefitsLogo },
  { name: 'bamboo_hr', logoUrl: BambooHRLogo },
  { name: 'namely', logoUrl: NamelyLogo },
  { name: 'justworks', logoUrl: JustworksLogo },
  { name: 'adp_run', logoUrl: ADPrunLogo },
  { name: 'insperity', logoUrl: InsperityLogo },
  { name: 'paylocity', logoUrl: PaylocityLogo },
  { name: 'square', logoUrl: SquareLogo },
  { name: 'paychex', logoUrl: PaychexLogo },
  { name: 'trinet', logoUrl: TriNetLogo },
  { name: 'quickbooks', logoUrl: QuickbooksPayrollLogo },
  { name: 'paycor', logoUrl: PaycorLogo },
  { name: 'workday', logoUrl: WorkdayLogo },
  { name: 'ukg', logoUrl: UKGLogo },
  { name: 'hibob', logoUrl: HiBobLogo },
  { name: 'paycom', logoUrl: PaycomLogo },
]

export default integrations
