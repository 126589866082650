import { isEqual } from 'lodash-es'
import { useRef } from 'react'

export function useDeepCompareDeps<T>(...values: T[]) {
  const ref = useRef<T[]>(values)

  if (!isEqual(ref.current, values)) {
    ref.current = values
  }

  return ref.current
}
