import { useEffect, useState } from 'react'
import { getFolderByFullPath } from 'services'
import { Entity } from 'types'

export function useGetFolderByFullPath({ fullPath }: { fullPath: string }) {
  const [entities, setEntities] = useState<Entity[]>([])
  const [hasLoaded, setHasLoaded] = useState(false)

  useEffect(() => {
    getFolderByFullPath(fullPath)
      .then(setEntities)
      .finally(() => {
        setHasLoaded(true)
      })
  }, [fullPath])

  return {
    entities,
    hasLoaded,
  }
}
