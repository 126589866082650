import { CountrySelection, PerkUpName } from 'components'
import { OrgContext, UserContext } from 'context'
import { Image, Pane } from 'evergreen-ui'
import { isFunction } from 'lodash-es'
import { useContext } from 'react'

export function WithCountryNavbar({
  isCuratedGift,
  onCompanyLogoClick,
}: {
  isCuratedGift: boolean
  onCompanyLogoClick?: () => void
}) {
  const user = useContext(UserContext)
  const org = useContext(OrgContext)

  const { logoUrl: orgLogo, name: orgName } = org

  const handleLogoClick = () => {
    if (isFunction(onCompanyLogoClick)) {
      onCompanyLogoClick()
    }
  }

  return (
    <Pane display="flex" alignItems="center" justifyContent="space-between">
      <Pane
        cursor={onCompanyLogoClick ? 'pointer' : undefined}
        onClick={handleLogoClick}
        height={42}
      >
        {orgLogo ? (
          <Image alt={orgName} src={orgLogo} maxHeight={40} width="100%" />
        ) : (
          <PerkUpName />
        )}
      </Pane>

      <Pane className="mx-auto" />
      {isCuratedGift && <CountrySelection user={user} />}
    </Pane>
  )
}
