import { DraftOrderContext } from 'context'
import { ProductVariant } from 'gen/perkup/v1/product_variant_pb'
import { ShippingAddress } from 'gen/perkup/v1/root_user_pb'
import { useDraftOrder } from 'hooks'
import { PropsWithChildren } from 'react'
import { WithSelectedQuantity } from 'types'

export function DraftOrderContextBuilder({
  productVariants,
  shippingAddress,
  children,
}: PropsWithChildren<{
  productVariants: WithSelectedQuantity<ProductVariant>[]
  shippingAddress: ShippingAddress | undefined
}>) {
  const draftOrderContextValue = useDraftOrder({
    productVariants,
    shippingAddress,
  })

  return (
    <DraftOrderContext.Provider value={draftOrderContextValue}>
      {children}
    </DraftOrderContext.Provider>
  )
}
