import { Pane, useTheme } from 'evergreen-ui'
import { ReactNode } from 'react'

export interface IMembershipCard {
  handleOnClick?: () => void
  orgPrimaryColor?: string
  children: ReactNode
  isCardHovered: boolean
  setIsCardHovered: React.Dispatch<React.SetStateAction<boolean>>
}

export function LeftLineAtCard(primaryColor: string) {
  return (
    <Pane
      position="absolute"
      left="0px"
      top="0px"
      height="100%"
      width="5px"
      backgroundColor={primaryColor}
      style={{
        borderRadius: '5px 0px 0px 5px',
      }}
    />
  )
}

export function MembershipCard({
  handleOnClick,
  orgPrimaryColor,
  children,
  isCardHovered,
  setIsCardHovered,
}: IMembershipCard) {
  const theme = useTheme()
  const organizationColor = orgPrimaryColor || theme.colors.blue400

  return (
    <Pane
      onMouseEnter={() => setIsCardHovered(true)}
      onMouseLeave={() => setIsCardHovered(false)}
      onClick={handleOnClick}
      cursor="pointer"
      position="relative"
      paddingLeft={15}
      paddingY={8}
      marginLeft={-15}
    >
      <Pane display="block">
        {isCardHovered && LeftLineAtCard(organizationColor)}
        {children}
      </Pane>
    </Pane>
  )
}
