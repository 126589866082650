import { BE_BASE_URL } from 'constants/keys'
import { sortBy, startCase } from 'lodash-es'
import { DesignVariable, ProductDesign, ProductMockup } from 'types'

export const buildPublicImageReferenceString = ({
  fullPath,
  fileName,
}: {
  fullPath: string
  fileName: string
}) => `public:${fullPath}/${fileName}`

export const findDesignFileByVariantId = (
  designs: ProductDesign[],
  variantId: number | undefined
) => {
  if (!variantId) return undefined
  return designs.find(design => design.productVariantId === variantId)
}

export const findMockupFilesByVariantId = (
  mockups: ProductMockup[],
  variantId: number | undefined
) => {
  if (!variantId) return []
  return mockups.filter(mockup => mockup.productVariantId === variantId)
}

export const convertMockupIdsToUrls = (
  mockupsIds: string[],
  designId: string,
  variables: DesignVariable[]
) => {
  return mockupsIds.map(
    (mockupId, index) =>
      `${BE_BASE_URL}/canvasUtils/getMockupImage?designId=${designId}&mockupId=${mockupId}&surfaceIndex=${index}&designVariables=${encodeURIComponent(JSON.stringify(variables))}`
  )
}

// Beef this up later to use a proper comparator
export const sortMockupsByName = (mockups: ProductMockup[]) => {
  return sortBy(mockups, 'mockupName')
}

export const buildAssetBucketString = ({
  variable,
  optionValueTitle,
  orgId,
}: {
  variable: DesignVariable
  optionValueTitle: string | undefined
  orgId: string
}) => {
  // We have some specific rules for the icon variables
  if (variable.name.includes('Icon')) {
    // The icon variable name could be either Icon_outlined or Icon_filled
    const iconType = variable.name.split('_')[1] // Get second word (ex: Icon_outlined -> outlined)

    return `Org-Logos/${orgId}/Icons/${startCase(iconType)}/${optionValueTitle}` // Depending on the option, we want to use a specific folder (Outlined/White)
  }

  // For any other variable that is of type Image, but is not named Icon then just use the big Illustrations folder
  return `Org-Logos/${orgId}/Illustrations`
}

export const extractFileNameFromVariableValue = (variableValue: string) => {
  return variableValue.split('/').pop() || ''
}
