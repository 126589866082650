import { Flex } from 'antd'
import { CategoryForm } from 'components'
import { allCategories } from 'constants/EligibleCategories'
import { selectableCats } from 'constants/rewards'
import { Heading } from 'evergreen-ui'
import { useSelectRewardContext } from '../SelectRewardContext'

export function DollarsForm2() {
  const { perkupDollarsForm, selectedReward } = useSelectRewardContext()
  return (
    <Flex align="center" gap={16}>
      <Heading size={400}>Approved categories</Heading>
      <CategoryForm
        onChangeApprovedCategories={perkupDollarsForm.handleSelectCategories}
        selectableCats={selectableCats}
        defaultCat={
          selectedReward.approvedCategories.length > 1
            ? allCategories
            : selectedReward.approvedCategories[0]
        }
      />
    </Flex>
  )
}
