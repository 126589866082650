import {
  CheckOutlined,
  CloseOutlined,
  DownloadOutlined,
} from '@ant-design/icons'
import { Button, Modal, Switch } from 'antd'
import { PDF_DATE_FORMAT } from 'constants/insights'
import { INSIGHTS_PRINT_KEY_TO_LABEL } from 'constants/print'
import { Heading, Text } from 'evergreen-ui'
import { useState } from 'react'
import { useReactToPrint } from 'react-to-print'
import { InsightsPrintableSections } from 'types'
import { dateRangeToJoinedString } from 'utils/insights'
import { useInsightsContext } from '.'

export function InsightsDownload({
  printRef,
  printableSections,
  onPrintableSectionsChange,
}: {
  printRef: React.RefObject<HTMLDivElement>
  printableSections: InsightsPrintableSections
  onPrintableSectionsChange: (sections: InsightsPrintableSections) => void
}) {
  const { dayJsDateRange } = useInsightsContext()

  const [showPrintConfig, setShowPrintConfig] = useState(false)
  const [isLoadingPrint, setIsLoadingPrint] = useState(false)

  const printFn = useReactToPrint({
    contentRef: printRef,
    documentTitle: `PerkUp Engagment Report ${dayJsDateRange ? dateRangeToJoinedString(dayJsDateRange, PDF_DATE_FORMAT) : ''}`,
    onBeforePrint: () => {
      setIsLoadingPrint(true)
      return Promise.resolve()
    },
    onAfterPrint: () => {
      setIsLoadingPrint(false)
      return undefined
    },
  })

  return (
    <>
      <Button
        icon={<DownloadOutlined />}
        type="primary"
        onClick={() => setShowPrintConfig(true)} // Need to rush, open modal later on next ticket
      >
        Download
      </Button>

      <Modal
        title={
          <div className="flex flex-col gap-2">
            <Heading size={600}>PerkUp Engagment Report</Heading>
            <Text color="muted">
              Select the sections you want to include in the report:
            </Text>
          </div>
        }
        open={showPrintConfig}
        width={480}
        onOk={() => printFn()}
        confirmLoading={isLoadingPrint}
        okText="Download"
        onCancel={() => setShowPrintConfig(false)}
      >
        <section className="flex flex-col gap-2 my-6">
          {Object.entries(printableSections).map(
            ([sectionKey, sectionBool]) => (
              <article
                data-checked={sectionBool}
                key={sectionKey}
                className="flex justify-between items-center bg-primary data-[checked=true]:bg-opacity-[0.03] bg-opacity-0 py-2 px-4 rounded-lg transition-all"
              >
                <Text>
                  {
                    INSIGHTS_PRINT_KEY_TO_LABEL[
                      sectionKey as keyof InsightsPrintableSections
                    ]
                  }
                </Text>
                <Switch
                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}
                  size="small"
                  checked={sectionBool}
                  onChange={value =>
                    onPrintableSectionsChange({
                      ...printableSections,
                      [sectionKey]: value,
                    })
                  }
                />
              </article>
            )
          )}
        </section>
      </Modal>
    </>
  )
}
