import { ReactComponent as PurchaseOrder } from 'assets/purchase-order.svg'
import { UserTransactionsTable } from 'components'
import { Heading, Pane, Text } from 'evergreen-ui'
import { useTransactionsByUserId } from 'hooks/useTransactionsByUserId'
import isEmpty from 'lodash-es/isEmpty'
import { Outlet } from 'react-router-dom'

export default function UserTransactions() {
  const { transactions } = useTransactionsByUserId()

  if (isEmpty(transactions)) {
    return (
      <Pane marginTop={32} textAlign="center">
        <PurchaseOrder />
        <Heading size={600} marginTop={32} marginBottom={8}>
          Start spending
        </Heading>
        <Text>Your transactions will appear here.</Text>
      </Pane>
    )
  }

  return (
    <Pane>
      <Heading size={900} marginBottom={16}>
        Transactions
      </Heading>
      <UserTransactionsTable transactions={transactions} />
      <Outlet />
    </Pane>
  )
}
