import { CheckOutlined, CloseOutlined } from '@ant-design/icons'
import { Flex, Switch } from 'antd'
import { EmojiTextInput } from 'components'
import { TemplateContext } from 'context'
import { Heading, Text } from 'evergreen-ui'
import { Template_Email } from 'gen/perkup/v1/org_template_pb'
import { useContext } from 'react'

export function TemplateEmailForm({
  onTemplateEmailChange,
}: {
  onTemplateEmailChange: (newTemplateEmail: Template_Email) => void
}) {
  const template = useContext(TemplateContext)
  const templateEmail = template?.email
  return (
    <Flex vertical gap={8} style={{ width: 480 }}>
      <Heading>Reward message</Heading>
      <EmojiTextInput
        value={templateEmail?.title || ''}
        onChange={(newTitle: string) => {
          onTemplateEmailChange(
            new Template_Email({
              ...templateEmail,
              title: newTitle,
            })
          )
        }}
        placeholder="Reward title"
      />
      <EmojiTextInput
        asParagraph
        value={templateEmail?.message || ''}
        onChange={(newMessage: string) => {
          onTemplateEmailChange(
            new Template_Email({
              ...templateEmail,
              message: newMessage,
            })
          )
        }}
        placeholder="Personalized note"
      />
      <Flex gap={8}>
        <Switch
          unCheckedChildren={<CloseOutlined />}
          checkedChildren={<CheckOutlined />}
          checked={templateEmail?.writable}
          onChange={checked => {
            onTemplateEmailChange(
              new Template_Email({
                ...templateEmail,
                writable: checked,
              })
            )
          }}
        />
        <Text>Let sender edit message</Text>
      </Flex>
    </Flex>
  )
}
