import { ListNonRemovedIndividualsByIds } from 'api/databaseCalls'
import { Individual } from 'gen/perkup/v1/individual_pb'
import useIds from 'hooks/useIds'
import { isEmpty, sortBy, uniqBy } from 'lodash-es'
import { useEffect, useState } from 'react'
import { useInfiniteHits, useInstantSearch } from 'react-instantsearch'
import { useInView } from 'react-intersection-observer'
import {
  algoliaHitToIndividual as algoliaItemToIndividual,
  getIndividualDisplayName,
} from 'utils/individual'

/**
 * Hook to infinite scroll an orgs individuals, with selected functionality
 */
export function useInfiniteSelectableIndividuals({
  selectedIndividualIds = [],
  incrementAmount = 20,
}: {
  selectedIndividualIds?: string[]
  incrementAmount?: number
}) {
  const { orgId } = useIds()
  const { items, isLastPage, showMore } = useInfiniteHits()
  const { status, results } = useInstantSearch()

  // These two loading states are coming from firestore (selectedIndividuals)
  const [isLoadingInitialSelected, setIsLoadingInitialSelected] = useState(true)
  const [isLoadingMoreSelected, setIsLoadingMoreSelected] = useState(false)
  const [defaultIndividuals, setDefaultIndividuals] = useState<Individual[]>([])

  const individualHits = items.map(algoliaItemToIndividual)

  // These two loading states are coming from algolia (individuals & searchbox)
  const isLoadingInitial = items.length === 0 && status === 'loading'

  const isLoadingMore = status === 'loading' && items.length > 0

  useEffect(() => {
    if (isEmpty(selectedIndividualIds)) {
      setIsLoadingInitialSelected(false)
      setDefaultIndividuals([])
      return
    }
    ListNonRemovedIndividualsByIds({
      orgId,
      individualIds: selectedIndividualIds.slice(0, incrementAmount),
    })
      .then(setDefaultIndividuals)
      .finally(() => {
        setIsLoadingInitialSelected(false)
      })
  }, [selectedIndividualIds, orgId, incrementAmount])

  const { ref: sentinelRef } = useInView({
    onChange: inView => {
      if (inView) {
        if (defaultIndividuals.length < selectedIndividualIds.length) {
          setIsLoadingMoreSelected(true)
          ListNonRemovedIndividualsByIds({
            orgId,
            individualIds: selectedIndividualIds.slice(
              defaultIndividuals.length,
              defaultIndividuals.length + incrementAmount
            ),
          })
            .then(individuals => {
              setDefaultIndividuals(prev => [...prev, ...individuals])
            })
            .finally(() => setIsLoadingMoreSelected(false))
        } else if (!isLoadingInitial) {
          // We need to make sure this doesnt trigger before we've loaded
          showMore()
        }
      }
    },
  })

  // eslint-disable-next-line no-underscore-dangle
  const noHits = !results.__isArtificial && results.nbHits === 0

  const individuals = sortBy(
    uniqBy([...defaultIndividuals, ...individualHits], 'id'),
    individual => getIndividualDisplayName(individual)
  )

  return {
    individuals,
    isLoadingInitial: isLoadingInitial || isLoadingInitialSelected,
    isLoadingMore: isLoadingMore || isLoadingMoreSelected,
    isEmpty: noHits,
    sentinelRef,
    isLastPage,
    hits: individualHits,
  }
}
