import { AMAZON_PERKUP_FEE_MULTIPLIER, AMAZON_TAX_RATE } from 'constants/money'
import { AmazonOffer } from 'types/Amazon'

export function getAmazonDisplayPrices(offer: AmazonOffer): {
  offerAmount: number
  listAmount: number
  savingsAmount: number
  percentOff: number
  offerCurrency: string
} {
  let offerAmount = 0
  let listAmount = 0
  let savingsAmount = 0
  let percentOff = 0
  const offerCurrency = offer.price.value?.currencyCode?.toLowerCase() || 'usd'

  if (offer.price.value?.amount) {
    offerAmount = offer.price.value.amount * 100
  }

  if (offer.listPrice?.value?.amount) {
    listAmount = offer.listPrice.value.amount * 100
  }

  if (offerAmount && listAmount) {
    savingsAmount = listAmount - offerAmount
    percentOff = Math.round((1 - offerAmount / listAmount) * 100)
  }

  return {
    offerAmount,
    listAmount,
    savingsAmount,
    percentOff,
    offerCurrency,
  }
}

export function getAmazonUnitAmount({
  offer,
  useListPrice,
}: {
  offer: AmazonOffer
  useListPrice?: boolean
}): number {
  if (!offer?.price?.value?.amount) return 0

  let unitAmount = offer.price.value.amount

  if (useListPrice && offer.listPrice?.value?.amount) {
    unitAmount = offer.listPrice?.value?.amount
  }

  return Math.round(unitAmount * 100)
}

export function getAmazonOrderAmounts(data: {
  unitAmount: number
  quantity: number
  isGift?: boolean
}): {
  offerPriceInCents: number
  itemTotal: number
  estimatedTax: number
  orderTotal: number
} {
  const { unitAmount, quantity, isGift } = data

  const itemTotal = unitAmount * quantity

  const taxRate = AMAZON_TAX_RATE
  const estimatedTax = itemTotal * taxRate

  let perkUpFee = 0
  if (isGift) {
    perkUpFee = itemTotal * AMAZON_PERKUP_FEE_MULTIPLIER
  }

  const orderTotal = Math.round(itemTotal + estimatedTax + perkUpFee)

  return {
    offerPriceInCents: unitAmount,
    itemTotal,
    estimatedTax,
    orderTotal,
  }
}
