import { PlacidTags } from 'constants/placid/placid-tags'
import { UseCaseRecord } from 'pages/NewReward/types/forms'
import { CategoryTitle } from 'pages/Templates/constants'
import { RewardUseCasesKeysType } from './RewardUseCasesKeys'

export const birthdayUseCases = {
  BIRTHDAYS: {
    label: 'Happy Birthday',
    details: {
      searchQuery: 'happy birthday',
      templateCategories: [CategoryTitle.BIRTHDAYS],
      placidTags: [PlacidTags.BIRTHDAYS],
    },
    emailData: [
      {
        title: 'Happy Birthday!',
        note: 'We hope you have an amazing birthday 🥳',
      },
      {
        title: 'Happy Birthday 🎂',
        note: 'Have an outstanding birthday, you deserve it!',
      },
      {
        title: 'Celebrate!',
        note: 'Another year, another adventure! Happy Birthday!',
      },
    ],
  },
  FUNNY_BIRTHDAY: {
    label: 'Funny birthday',
    details: {
      searchQuery: 'funny birthday',
      templateCategories: [],
      placidTags: [],
    },
    emailData: [
      {
        title: 'Older But Gold!',
        note: 'Another year, another wrinkle! Just kidding, happy birthday!',
      },
      {
        title: 'Aging Like Fine Wine 🍷',
        note: 'They say age is just a number, but you make it look good!',
      },
      {
        title: 'Birthdays & Bloopers!',
        note: 'Another year to prove that older doesn’t really mean wiser! Have a fun-filled birthday!',
      },
    ],
  },
  PET_BIRTHDAY: {
    label: 'Pet birthday',
    details: {
      searchQuery: 'dog birthday',
      templateCategories: [],
      placidTags: [PlacidTags.NEW_PET],
    },
    emailData: [
      {
        title: 'Furry Celebration!',
        note: "Here's to more treats and belly rubs for your furry friend!",
      },
      {
        title: 'Paw-ty Time 🐾',
        note: 'Happy birthday to your adorable pet. Wishing them a tail-wagging celebration!',
      },
      {
        title: 'Four-Legged Fiesta!',
        note: "Treats and toys galore for your pet's special day!",
      },
    ],
  },
} satisfies Partial<Record<RewardUseCasesKeysType, UseCaseRecord>>
