import { DollarOutlined, MinusCircleOutlined } from '@ant-design/icons'
import { Flex } from 'antd'
import { PerkImage } from 'components'
import { AsyncButton } from 'components/Buttons/AsyncButton'
import { NUMBER_GREEN } from 'constants/colors'
import { Text } from 'evergreen-ui'
import { Program_Gift, Program_RewardTab } from 'gen/perkup/v1/program_pb'
import { numToDollars } from 'utils'
import { useSelectRewardContext } from '../SelectRewardContext'

function getEmptyTabName(tab: Program_RewardTab): string {
  switch (tab) {
    case Program_RewardTab.swag:
      return 'swag'
    case Program_RewardTab.catalog:
      return 'gift'

    case Program_RewardTab.perkupDollars:
      return 'amount'

    case Program_RewardTab.giftCard:
      return 'gift card'

    default:
      return ''
  }
}

function getRewardIdentifier({
  tab,
  gift,
}: {
  tab: Program_RewardTab
  gift: Program_Gift | undefined
}) {
  if (tab === Program_RewardTab.perkupDollars)
    return (
      <>
        <DollarOutlined
          style={{
            marginRight: 4,
          }}
        />{' '}
        Perkup dollars
      </>
    )

  if (!gift) return ''

  if (gift.productCollectionId || gift.productIds.length <= 1) return gift.title

  return `${gift.productIds.length} products`
}

export function CurrentSelectedReward({
  readOnly = false,
}: {
  readOnly?: boolean
}) {
  const { noRewardSelected, selectedTab, selectedReward, swagForm } =
    useSelectRewardContext()

  if (noRewardSelected) {
    return (
      <Text color="currentColor">
        No {getEmptyTabName(selectedTab.selectedRewardTab)} selected
      </Text>
    )
  }

  const isSingleGift =
    selectedReward?.gift?.productCollectionId ||
    selectedReward.gift?.productIds.length === 1

  return (
    <Flex align="center" gap={4}>
      {isSingleGift && (
        <Flex
          style={{
            marginRight: -4,
          }}
        >
          <PerkImage
            sizes="24px"
            preview
            src={selectedReward.gift?.imageUrl}
            width={24}
            style={{
              objectFit: 'cover',
              width: 24,
              height: 24,
              borderRadius: 4,
            }}
          />
        </Flex>
      )}
      <Text color="currentcolor" marginLeft={8}>
        {getRewardIdentifier({
          tab: selectedTab.selectedRewardTab,
          gift: selectedReward.gift,
        })}{' '}
      </Text>
      <Text marginLeft={12} marginRight={8} color={NUMBER_GREEN}>
        {numToDollars(selectedReward.budget)}
      </Text>
      {isSingleGift && !readOnly && (
        <AsyncButton
          danger
          type="text"
          icon={<MinusCircleOutlined />}
          style={{
            marginLeft: -8,
            height: 22,
            width: 22,
          }}
          onClick={swagForm.handleRemoveCollection}
        />
      )}
    </Flex>
  )
}
