import { GetIndividualById, GetMemberById } from 'api/databaseCalls'
import { GetOrderFulfillmentsForMember } from 'api/databaseCalls/reads/orderFullfilments'
import { Program } from 'gen/perkup/v1/program_pb'
import useIds from 'hooks/useIds'
import { compact } from 'lodash-es'
import { useEffect, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import { ProgramMemberData } from 'types'
import { getProgramEngagement } from 'utils'

const incrementAmount = 20

const formatMemberData = async ({
  memberIds,
  program,
  orgId,
}: {
  memberIds: string[]
  program: Program
  orgId: string
}) => {
  const formattedMemberData = memberIds.map(async memberId => {
    const individual = await GetIndividualById({
      individualId: memberId,
      orgId,
    })
    const member = await GetMemberById({
      orgId,
      programId: program.id,
      memberId,
    })
    if (!individual || !member) return undefined

    const programEngagement = getProgramEngagement({
      memberActions: member.actions,
      amountUsed: member.spent,
      isGift: !!program?.gift,
    })

    const orderFulfillments = !individual?.userId
      ? []
      : await GetOrderFulfillmentsForMember({
          programId: program.id,
          orgId,
          userId: individual.userId,
        })

    return {
      key: individual.id,
      individual,
      member,
      orgId,
      engagement: programEngagement,
      program,
      orderFulfillments,
    }
  })

  return Promise.all(formattedMemberData)
}

export function useInfiniteProgramMemberData({
  memberIds,
  program,
}: {
  memberIds: string[]
  program: Program
}) {
  const { orgId } = useIds()

  const [data, setData] = useState<
    Omit<
      ProgramMemberData,
      'lastMemberRef' | 'sentProgramReminder' | 'onRemoveIndividual'
    >[]
  >([])
  const [currentIndex, setCurrentIndex] = useState(0)
  const [hasLoadedInitial, setHasLoadedInitial] = useState(false)
  const [isLoadingMore, setIsLoadingMore] = useState(false)

  // Fetch first page
  useEffect(() => {
    if (memberIds.length === 0) return
    formatMemberData({
      memberIds: memberIds.slice(0, incrementAmount),
      program,
      orgId,
    })
      .then(formattedData => {
        setData(compact(formattedData))
        setCurrentIndex(incrementAmount)
      })
      .finally(() => {
        setHasLoadedInitial(true)
      })
  }, [memberIds, orgId, program])

  // Fetch more data
  const { ref: sentinelRef } = useInView({
    onChange: inView => {
      if (inView) {
        setIsLoadingMore(true)
        formatMemberData({
          memberIds: memberIds.slice(
            currentIndex,
            currentIndex + incrementAmount
          ),
          program,
          orgId,
        })
          .then(formattedData => {
            setData(prev => [...prev, ...compact(formattedData)])
            setCurrentIndex(prev => prev + incrementAmount)
          })
          .finally(() => {
            setIsLoadingMore(false)
          })
      }
    },
  })

  const isLastPage = data.length === memberIds.length

  return {
    data,
    isLoadingMore,
    hasLoadedInitial,
    lastMemberRef: sentinelRef,
    isLastPage,
  }
}
