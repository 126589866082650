import { GetOrderFulfillmentsByOrderId } from 'api/databaseCalls/reads/orderFullfilments'
import { OrgTransaction } from 'gen/perkup/v1/organization_pb'
import { OrderFulfillment } from 'gen/perkup/v1/vendor_pb'
import { useEffect, useMemo, useState } from 'react'
import { isManualTransaction } from 'utils/transactions'

export function useOrderFulfillmentsByOrgTransaction({
  transaction,
}: {
  transaction?: OrgTransaction
}) {
  const [orderFulfillments, setOrderFullfilments] = useState<
    OrderFulfillment[]
  >([])
  const [isLoadingInitial, setIsLoadingInitial] = useState(true)
  const [isLoadingMore, setIsLoadingMore] = useState(false)

  const orderFulfillmentId = useMemo(() => {
    if (!isManualTransaction({ transaction }) || !transaction) {
      return undefined
    }
    return transaction?.id
  }, [transaction])

  useEffect(() => {
    if (orderFulfillmentId) {
      setIsLoadingMore(true)
      GetOrderFulfillmentsByOrderId({ orderId: orderFulfillmentId })
        .then(setOrderFullfilments)
        .finally(() => {
          setIsLoadingInitial(false)
          setIsLoadingMore(false)
        })
    } else {
      setOrderFullfilments([])
      setIsLoadingInitial(false)
    }
  }, [orderFulfillmentId])

  return {
    orderFulfillments,
    isLoadingInitial,
    isLoadingMore,
    isLoading: isLoadingInitial || isLoadingMore,
  }
}
