import { useEffect, useState } from 'react'
import { listSageProducts } from 'services/sage'
import { SageSearchFilters } from 'types/sage'

export function useSageProducts({ filters }: { filters: SageSearchFilters }) {
  const [products, setProducts] = useState<any[]>([])
  const [hasLoaded, setHasLoaded] = useState(false)
  const [isLoadingMore, setIsLoadingMore] = useState(false)

  useEffect(() => {
    setIsLoadingMore(true)
    listSageProducts({ filters })
      .then(setProducts)
      .finally(() => {
        setHasLoaded(true)
        setIsLoadingMore(false)
      })
  }, [filters])

  return { products, hasLoaded, isLoadingMore }
}
