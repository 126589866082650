// @generated by protoc-gen-connect-es v1.1.4 with parameter "target=ts"
// @generated from file amazon/fulfill.proto (package amazon, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { PlaceOrderRequest } from "./fulfill_pb.js";
import { Empty } from "../google/protobuf/empty_pb.js";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service amazon.Order
 */
export const Order = {
  typeName: "amazon.Order",
  methods: {
    /**
     * @generated from rpc amazon.Order.PlaceOrder
     */
    placeOrder: {
      name: "PlaceOrder",
      I: PlaceOrderRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
  }
} as const;

