import { Button } from 'antd'
import { updateUserProfile } from 'api/databaseCalls'
import { ReactComponent as AvatarGirl } from 'assets/avatar-girl1.svg'
import { CONFIRM_NAME_CLICKED } from 'constants/events'
import { isProduction } from 'constants/keys'
import { CREATE_ORG } from 'constants/params'
import * as ROUTES from 'constants/routes'
import { OrgContext, UserContext } from 'context'
import { Heading, Pane, TextInput, toaster } from 'evergreen-ui'
import { validateFullName } from 'pages/NewReward/utils/uiUtils'
import { useContext } from 'react'
import { isMobile } from 'react-device-detect'
import { useForm } from 'react-hook-form'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { logEvent } from 'utils'

function UserName() {
  const user = useContext(UserContext)
  const userId = user?.id
  const { register, handleSubmit } = useForm<{
    firstName: string
    lastName: string
  }>()
  const firstNameRegister = register('firstName')
  const lastNameRegister = register('lastName')
  const navigate = useNavigate()
  const org = useContext(OrgContext)
  const orgId = org?.id

  const [searchParams] = useSearchParams()
  const createOrg = searchParams.get(CREATE_ORG) === true.toString()

  const onSubmit = ({
    firstName,
    lastName,
  }: {
    firstName: string
    lastName: string
  }) => {
    logEvent(CONFIRM_NAME_CLICKED, { orgId, userId })
    const validFullName = validateFullName({ firstName, lastName })
    if (!validFullName) return

    updateUserProfile({ userId: user.id, data: { firstName, lastName } })

    const allowCreateOrg =
      !user?.currentOrganization && (createOrg || !isProduction)

    if (allowCreateOrg) {
      toaster.success('Successfully created user profile')

      navigate(ROUTES.ORGANIZATION_NEW)
    } else {
      navigate(ROUTES.SELECT_COUNTRY)
    }
  }

  if (!user.profile) return null

  return (
    <>
      <Pane
        width={112}
        marginX="auto"
        marginBottom={16}
        marginTop={isMobile ? 90 : 0}
      >
        <AvatarGirl style={{ width: '100%', height: 'auto' }} />
      </Pane>
      <Heading size={600} marginBottom={24} marginTop={16} textAlign="center">
        What&apos;s your name?
      </Heading>
      <Pane
        is="form"
        onSubmit={handleSubmit(onSubmit)}
        display="flex"
        flexDirection="column"
        gap={16}
      >
        <TextInput
          type="text"
          name="firstName"
          defaultValue={user.profile?.firstName}
          placeholder="First name"
          ref={firstNameRegister.ref}
          onChange={firstNameRegister.onChange}
          min={2}
          height={40}
          required
          autoFocus
          borderRadius={8}
        />

        <TextInput
          type="text"
          name="lastName"
          defaultValue={user.profile?.lastName}
          placeholder="Last name"
          ref={lastNameRegister.ref}
          onChange={lastNameRegister.onChange}
          min={2}
          height={40}
          required
          borderRadius={8}
        />

        <Button htmlType="submit" type="primary" disabled={!user.id}>
          Continue
        </Button>
      </Pane>
    </>
  )
}

export default UserName
