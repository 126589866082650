// @generated by protoc-gen-es v1.6.0 with parameter "target=ts"
// @generated from file perkup/v1/stripe_data.proto (package perkup.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, protoInt64, Timestamp } from "@bufbuild/protobuf";

/**
 * Firestore /authorizations/:id
 *
 * @generated from message perkup.v1.Authorization
 */
export class Authorization extends Message<Authorization> {
  /**
   * stored in firestore
   *
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: int32 amount = 2;
   */
  amount = 0;

  /**
   * @generated from field: optional perkup.v1.StripeAmountDetail amount_details = 3 [json_name = "amount_details"];
   */
  amountDetails?: StripeAmountDetail;

  /**
   * @generated from field: bool approved = 4;
   */
  approved = false;

  /**
   * @generated from field: string authorization_method = 5 [json_name = "authorization_method"];
   */
  authorizationMethod = "";

  /**
   * @generated from field: repeated perkup.v1.Authorization.StripeBalanceTransaction balance_transactions = 6 [json_name = "balance_transactions"];
   */
  balanceTransactions: Authorization_StripeBalanceTransaction[] = [];

  /**
   * @generated from field: perkup.v1.Card card = 7;
   */
  card?: Card;

  /**
   * @generated from field: string cardholder = 8;
   */
  cardholder = "";

  /**
   * @generated from field: int64 created = 9;
   */
  created = protoInt64.zero;

  /**
   * @generated from field: string currency = 10;
   */
  currency = "";

  /**
   * @generated from field: bool livemode = 11;
   */
  livemode = false;

  /**
   * @generated from field: int32 merchant_amount = 12 [json_name = "merchant_amount"];
   */
  merchantAmount = 0;

  /**
   * @generated from field: string merchant_currency = 13 [json_name = "merchant_currency"];
   */
  merchantCurrency = "";

  /**
   * @generated from field: perkup.v1.StripeMerchantData merchant_data = 14 [json_name = "merchant_data"];
   */
  merchantData?: StripeMerchantData;

  /**
   * @generated from field: optional perkup.v1.StripeMetadata metadata = 15;
   */
  metadata?: StripeMetadata;

  /**
   * @generated from field: string object = 16;
   */
  object = "";

  /**
   * pending_request is ignored because null on all docs
   *
   * @generated from field: repeated perkup.v1.Authorization.RequestHistory request_history = 17 [json_name = "request_history"];
   */
  requestHistory: Authorization_RequestHistory[] = [];

  /**
   * @generated from field: string status = 18;
   */
  status = "";

  /**
   * @generated from field: repeated perkup.v1.Transaction transactions = 19;
   */
  transactions: Transaction[] = [];

  /**
   * @generated from field: perkup.v1.Authorization.VerificationData verification_data = 20 [json_name = "verification_data"];
   */
  verificationData?: Authorization_VerificationData;

  /**
   * Nullable
   *
   * @generated from field: optional string wallet = 21;
   */
  wallet?: string;

  constructor(data?: PartialMessage<Authorization>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "perkup.v1.Authorization";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "amount", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "amount_details", jsonName: "amount_details", kind: "message", T: StripeAmountDetail, opt: true },
    { no: 4, name: "approved", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 5, name: "authorization_method", jsonName: "authorization_method", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "balance_transactions", jsonName: "balance_transactions", kind: "message", T: Authorization_StripeBalanceTransaction, repeated: true },
    { no: 7, name: "card", kind: "message", T: Card },
    { no: 8, name: "cardholder", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "created", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 10, name: "currency", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "livemode", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 12, name: "merchant_amount", jsonName: "merchant_amount", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 13, name: "merchant_currency", jsonName: "merchant_currency", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 14, name: "merchant_data", jsonName: "merchant_data", kind: "message", T: StripeMerchantData },
    { no: 15, name: "metadata", kind: "message", T: StripeMetadata, opt: true },
    { no: 16, name: "object", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 17, name: "request_history", jsonName: "request_history", kind: "message", T: Authorization_RequestHistory, repeated: true },
    { no: 18, name: "status", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 19, name: "transactions", kind: "message", T: Transaction, repeated: true },
    { no: 20, name: "verification_data", jsonName: "verification_data", kind: "message", T: Authorization_VerificationData },
    { no: 21, name: "wallet", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Authorization {
    return new Authorization().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Authorization {
    return new Authorization().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Authorization {
    return new Authorization().fromJsonString(jsonString, options);
  }

  static equals(a: Authorization | PlainMessage<Authorization> | undefined, b: Authorization | PlainMessage<Authorization> | undefined): boolean {
    return proto3.util.equals(Authorization, a, b);
  }
}

/**
 * @generated from message perkup.v1.Authorization.VerificationData
 */
export class Authorization_VerificationData extends Message<Authorization_VerificationData> {
  /**
   * @generated from field: string expiry_check = 1 [json_name = "expiry_check"];
   */
  expiryCheck = "";

  /**
   * @generated from field: string cvc_check = 2 [json_name = "cvc_check"];
   */
  cvcCheck = "";

  /**
   * @generated from field: string address_line1_check = 3 [json_name = "address_line1_check"];
   */
  addressLine1Check = "";

  /**
   * @generated from field: string address_postal_code_check = 4 [json_name = "address_postal_code_check"];
   */
  addressPostalCodeCheck = "";

  constructor(data?: PartialMessage<Authorization_VerificationData>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "perkup.v1.Authorization.VerificationData";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "expiry_check", jsonName: "expiry_check", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "cvc_check", jsonName: "cvc_check", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "address_line1_check", jsonName: "address_line1_check", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "address_postal_code_check", jsonName: "address_postal_code_check", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Authorization_VerificationData {
    return new Authorization_VerificationData().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Authorization_VerificationData {
    return new Authorization_VerificationData().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Authorization_VerificationData {
    return new Authorization_VerificationData().fromJsonString(jsonString, options);
  }

  static equals(a: Authorization_VerificationData | PlainMessage<Authorization_VerificationData> | undefined, b: Authorization_VerificationData | PlainMessage<Authorization_VerificationData> | undefined): boolean {
    return proto3.util.equals(Authorization_VerificationData, a, b);
  }
}

/**
 * @generated from message perkup.v1.Authorization.RequestHistory
 */
export class Authorization_RequestHistory extends Message<Authorization_RequestHistory> {
  /**
   * @generated from field: int64 amount = 1;
   */
  amount = protoInt64.zero;

  /**
   * @generated from field: optional perkup.v1.StripeAmountDetail amount_details = 2 [json_name = "amount_details"];
   */
  amountDetails?: StripeAmountDetail;

  /**
   * @generated from field: bool approved = 3;
   */
  approved = false;

  /**
   * UNIX timestamp
   *
   * @generated from field: int64 created = 4;
   */
  created = protoInt64.zero;

  /**
   * @generated from field: string currency = 5;
   */
  currency = "";

  /**
   * @generated from field: int64 merchant_amount = 6 [json_name = "merchant_amount"];
   */
  merchantAmount = protoInt64.zero;

  /**
   * @generated from field: string merchant_currency = 7 [json_name = "merchant_currency"];
   */
  merchantCurrency = "";

  /**
   * @generated from field: string reason = 8;
   */
  reason = "";

  constructor(data?: PartialMessage<Authorization_RequestHistory>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "perkup.v1.Authorization.RequestHistory";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "amount", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "amount_details", jsonName: "amount_details", kind: "message", T: StripeAmountDetail, opt: true },
    { no: 3, name: "approved", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 4, name: "created", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 5, name: "currency", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "merchant_amount", jsonName: "merchant_amount", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 7, name: "merchant_currency", jsonName: "merchant_currency", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "reason", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Authorization_RequestHistory {
    return new Authorization_RequestHistory().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Authorization_RequestHistory {
    return new Authorization_RequestHistory().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Authorization_RequestHistory {
    return new Authorization_RequestHistory().fromJsonString(jsonString, options);
  }

  static equals(a: Authorization_RequestHistory | PlainMessage<Authorization_RequestHistory> | undefined, b: Authorization_RequestHistory | PlainMessage<Authorization_RequestHistory> | undefined): boolean {
    return proto3.util.equals(Authorization_RequestHistory, a, b);
  }
}

/**
 * @generated from message perkup.v1.Authorization.StripeBalanceTransaction
 */
export class Authorization_StripeBalanceTransaction extends Message<Authorization_StripeBalanceTransaction> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: int32 amount = 2;
   */
  amount = 0;

  /**
   * UNIX timestamp
   *
   * @generated from field: int64 available_on = 3 [json_name = "available_on"];
   */
  availableOn = protoInt64.zero;

  /**
   * UNIX timestamp
   *
   * @generated from field: int64 created = 4;
   */
  created = protoInt64.zero;

  /**
   * @generated from field: string currency = 5;
   */
  currency = "";

  /**
   * @generated from field: string description = 6;
   */
  description = "";

  /**
   * Nullable
   *
   * @generated from field: optional float exchange_rate = 7 [json_name = "exchange_rate"];
   */
  exchangeRate?: number;

  /**
   * @generated from field: int32 fee = 8;
   */
  fee = 0;

  /**
   * @generated from field: repeated perkup.v1.Authorization.StripeBalanceTransaction.FeeDetail fee_details = 9 [json_name = "fee_details"];
   */
  feeDetails: Authorization_StripeBalanceTransaction_FeeDetail[] = [];

  /**
   * @generated from field: int32 net = 10;
   */
  net = 0;

  /**
   * @generated from field: string object = 11;
   */
  object = "";

  /**
   * @generated from field: string reporting_category = 12 [json_name = "reporting_category"];
   */
  reportingCategory = "";

  /**
   * @generated from field: string source = 13;
   */
  source = "";

  /**
   * @generated from field: string status = 14;
   */
  status = "";

  /**
   * @generated from field: string type = 15;
   */
  type = "";

  constructor(data?: PartialMessage<Authorization_StripeBalanceTransaction>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "perkup.v1.Authorization.StripeBalanceTransaction";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "amount", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "available_on", jsonName: "available_on", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: "created", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 5, name: "currency", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "exchange_rate", jsonName: "exchange_rate", kind: "scalar", T: 2 /* ScalarType.FLOAT */, opt: true },
    { no: 8, name: "fee", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 9, name: "fee_details", jsonName: "fee_details", kind: "message", T: Authorization_StripeBalanceTransaction_FeeDetail, repeated: true },
    { no: 10, name: "net", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 11, name: "object", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 12, name: "reporting_category", jsonName: "reporting_category", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 13, name: "source", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 14, name: "status", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 15, name: "type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Authorization_StripeBalanceTransaction {
    return new Authorization_StripeBalanceTransaction().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Authorization_StripeBalanceTransaction {
    return new Authorization_StripeBalanceTransaction().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Authorization_StripeBalanceTransaction {
    return new Authorization_StripeBalanceTransaction().fromJsonString(jsonString, options);
  }

  static equals(a: Authorization_StripeBalanceTransaction | PlainMessage<Authorization_StripeBalanceTransaction> | undefined, b: Authorization_StripeBalanceTransaction | PlainMessage<Authorization_StripeBalanceTransaction> | undefined): boolean {
    return proto3.util.equals(Authorization_StripeBalanceTransaction, a, b);
  }
}

/**
 * @generated from message perkup.v1.Authorization.StripeBalanceTransaction.FeeDetail
 */
export class Authorization_StripeBalanceTransaction_FeeDetail extends Message<Authorization_StripeBalanceTransaction_FeeDetail> {
  /**
   * @generated from field: int64 amount = 1;
   */
  amount = protoInt64.zero;

  /**
   * @generated from field: string application = 2;
   */
  application = "";

  /**
   * @generated from field: string currency = 3;
   */
  currency = "";

  /**
   * @generated from field: string description = 4;
   */
  description = "";

  /**
   * @generated from field: string type = 5;
   */
  type = "";

  constructor(data?: PartialMessage<Authorization_StripeBalanceTransaction_FeeDetail>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "perkup.v1.Authorization.StripeBalanceTransaction.FeeDetail";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "amount", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "application", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "currency", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Authorization_StripeBalanceTransaction_FeeDetail {
    return new Authorization_StripeBalanceTransaction_FeeDetail().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Authorization_StripeBalanceTransaction_FeeDetail {
    return new Authorization_StripeBalanceTransaction_FeeDetail().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Authorization_StripeBalanceTransaction_FeeDetail {
    return new Authorization_StripeBalanceTransaction_FeeDetail().fromJsonString(jsonString, options);
  }

  static equals(a: Authorization_StripeBalanceTransaction_FeeDetail | PlainMessage<Authorization_StripeBalanceTransaction_FeeDetail> | undefined, b: Authorization_StripeBalanceTransaction_FeeDetail | PlainMessage<Authorization_StripeBalanceTransaction_FeeDetail> | undefined): boolean {
    return proto3.util.equals(Authorization_StripeBalanceTransaction_FeeDetail, a, b);
  }
}

/**
 * Firestore /transactions/:id
 *
 * @generated from message perkup.v1.Transaction
 */
export class Transaction extends Message<Transaction> {
  /**
   * stored in firestore
   *
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: int32 amount = 2;
   */
  amount = 0;

  /**
   * @generated from field: optional perkup.v1.StripeAmountDetail amount_details = 3 [json_name = "amount_details"];
   */
  amountDetails?: StripeAmountDetail;

  /**
   * @generated from field: string authorization = 4;
   */
  authorization = "";

  /**
   * @generated from field: string balance_transaction = 5 [json_name = "balance_transaction"];
   */
  balanceTransaction = "";

  /**
   * @generated from field: string card = 6;
   */
  card = "";

  /**
   * @generated from field: string cardholder = 7;
   */
  cardholder = "";

  /**
   * UNIX timestamp
   *
   * @generated from field: int64 created = 8;
   */
  created = protoInt64.zero;

  /**
   * @generated from field: string currency = 9;
   */
  currency = "";

  /**
   * Nullable
   *
   * @generated from field: optional string dispute = 10;
   */
  dispute?: string;

  /**
   * @generated from field: bool livemode = 11;
   */
  livemode = false;

  /**
   * @generated from field: int32 merchant_amount = 12 [json_name = "merchant_amount"];
   */
  merchantAmount = 0;

  /**
   * @generated from field: string merchant_currency = 13 [json_name = "merchant_currency"];
   */
  merchantCurrency = "";

  /**
   * @generated from field: perkup.v1.StripeMerchantData merchant_data = 14 [json_name = "merchant_data"];
   */
  merchantData?: StripeMerchantData;

  /**
   * @generated from field: optional perkup.v1.StripeMetadata metadata = 15;
   */
  metadata?: StripeMetadata;

  /**
   * @generated from field: string object = 16;
   */
  object = "";

  /**
   * @generated from field: string type = 17;
   */
  type = "";

  constructor(data?: PartialMessage<Transaction>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "perkup.v1.Transaction";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "amount", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "amount_details", jsonName: "amount_details", kind: "message", T: StripeAmountDetail, opt: true },
    { no: 4, name: "authorization", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "balance_transaction", jsonName: "balance_transaction", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "card", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "cardholder", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "created", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 9, name: "currency", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "dispute", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 11, name: "livemode", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 12, name: "merchant_amount", jsonName: "merchant_amount", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 13, name: "merchant_currency", jsonName: "merchant_currency", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 14, name: "merchant_data", jsonName: "merchant_data", kind: "message", T: StripeMerchantData },
    { no: 15, name: "metadata", kind: "message", T: StripeMetadata, opt: true },
    { no: 16, name: "object", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 17, name: "type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Transaction {
    return new Transaction().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Transaction {
    return new Transaction().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Transaction {
    return new Transaction().fromJsonString(jsonString, options);
  }

  static equals(a: Transaction | PlainMessage<Transaction> | undefined, b: Transaction | PlainMessage<Transaction> | undefined): boolean {
    return proto3.util.equals(Transaction, a, b);
  }
}

/**
 * Firestore /cards/:id
 *
 * @generated from message perkup.v1.Card
 */
export class Card extends Message<Card> {
  /**
   * stored in firestore
   *
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string brand = 2;
   */
  brand = "";

  /**
   * @generated from field: optional string cancellation_reason = 3 [json_name = "cancellation_reason"];
   */
  cancellationReason?: string;

  /**
   * @generated from field: perkup.v1.Cardholder cardholder = 4;
   */
  cardholder?: Cardholder;

  /**
   * @generated from field: int32 created = 5;
   */
  created = 0;

  /**
   * @generated from field: string currency = 6;
   */
  currency = "";

  /**
   * @generated from field: int32 exp_month = 7 [json_name = "exp_month"];
   */
  expMonth = 0;

  /**
   * @generated from field: int32 exp_year = 8 [json_name = "exp_year"];
   */
  expYear = 0;

  /**
   * @generated from field: string last4 = 9;
   */
  last4 = "";

  /**
   * @generated from field: bool livemode = 10;
   */
  livemode = false;

  /**
   * @generated from field: perkup.v1.StripeMetadata metadata = 11;
   */
  metadata?: StripeMetadata;

  /**
   * @generated from field: string object = 12;
   */
  object = "";

  /**
   * @generated from field: optional string replaced_by = 13 [json_name = "replaced_by"];
   */
  replacedBy?: string;

  /**
   * @generated from field: optional string replacement_for = 14 [json_name = "replacement_for"];
   */
  replacementFor?: string;

  /**
   * @generated from field: optional string replacement_reason = 15 [json_name = "replacement_reason"];
   */
  replacementReason?: string;

  /**
   * @generated from field: optional perkup.v1.Card.Shipping shipping = 16;
   */
  shipping?: Card_Shipping;

  /**
   * @generated from field: perkup.v1.SpendingControl spending_control = 17 [json_name = "spending_controls"];
   */
  spendingControl?: SpendingControl;

  /**
   * @generated from field: perkup.v1.Card.Status status = 18;
   */
  status = Card_Status.STATUS_UNSPECIFIED;

  /**
   * @generated from field: string type = 19;
   */
  type = "";

  constructor(data?: PartialMessage<Card>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "perkup.v1.Card";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "brand", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "cancellation_reason", jsonName: "cancellation_reason", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 4, name: "cardholder", kind: "message", T: Cardholder },
    { no: 5, name: "created", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 6, name: "currency", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "exp_month", jsonName: "exp_month", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 8, name: "exp_year", jsonName: "exp_year", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 9, name: "last4", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "livemode", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 11, name: "metadata", kind: "message", T: StripeMetadata },
    { no: 12, name: "object", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 13, name: "replaced_by", jsonName: "replaced_by", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 14, name: "replacement_for", jsonName: "replacement_for", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 15, name: "replacement_reason", jsonName: "replacement_reason", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 16, name: "shipping", kind: "message", T: Card_Shipping, opt: true },
    { no: 17, name: "spending_control", jsonName: "spending_controls", kind: "message", T: SpendingControl },
    { no: 18, name: "status", kind: "enum", T: proto3.getEnumType(Card_Status) },
    { no: 19, name: "type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Card {
    return new Card().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Card {
    return new Card().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Card {
    return new Card().fromJsonString(jsonString, options);
  }

  static equals(a: Card | PlainMessage<Card> | undefined, b: Card | PlainMessage<Card> | undefined): boolean {
    return proto3.util.equals(Card, a, b);
  }
}

/**
 * @generated from enum perkup.v1.Card.Status
 */
export enum Card_Status {
  /**
   * @generated from enum value: STATUS_UNSPECIFIED = 0;
   */
  STATUS_UNSPECIFIED = 0,

  /**
   * @generated from enum value: active = 1;
   */
  active = 1,

  /**
   * @generated from enum value: inactive = 2;
   */
  inactive = 2,

  /**
   * @generated from enum value: canceled = 3;
   */
  canceled = 3,
}
// Retrieve enum metadata with: proto3.getEnumType(Card_Status)
proto3.util.setEnumType(Card_Status, "perkup.v1.Card.Status", [
  { no: 0, name: "STATUS_UNSPECIFIED" },
  { no: 1, name: "active" },
  { no: 2, name: "inactive" },
  { no: 3, name: "canceled" },
]);

/**
 * @generated from message perkup.v1.Card.Shipping
 */
export class Card_Shipping extends Message<Card_Shipping> {
  /**
   * @generated from field: perkup.v1.StripeAddress address = 1;
   */
  address?: StripeAddress;

  /**
   * @generated from field: string carrier = 2;
   */
  carrier = "";

  /**
   * @generated from field: int32 eta = 3;
   */
  eta = 0;

  /**
   * @generated from field: string name = 4;
   */
  name = "";

  /**
   * @generated from field: optional string phone = 5;
   */
  phone?: string;

  /**
   * @generated from field: string service = 6;
   */
  service = "";

  /**
   * @generated from field: string status = 7;
   */
  status = "";

  /**
   * @generated from field: optional string tracking_number = 8 [json_name = "tracking_number"];
   */
  trackingNumber?: string;

  /**
   * @generated from field: optional string tracking_url = 9 [json_name = "tracking_url"];
   */
  trackingUrl?: string;

  /**
   * @generated from field: string type = 10;
   */
  type = "";

  constructor(data?: PartialMessage<Card_Shipping>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "perkup.v1.Card.Shipping";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "address", kind: "message", T: StripeAddress },
    { no: 2, name: "carrier", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "eta", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "phone", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 6, name: "service", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "status", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "tracking_number", jsonName: "tracking_number", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 9, name: "tracking_url", jsonName: "tracking_url", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 10, name: "type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Card_Shipping {
    return new Card_Shipping().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Card_Shipping {
    return new Card_Shipping().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Card_Shipping {
    return new Card_Shipping().fromJsonString(jsonString, options);
  }

  static equals(a: Card_Shipping | PlainMessage<Card_Shipping> | undefined, b: Card_Shipping | PlainMessage<Card_Shipping> | undefined): boolean {
    return proto3.util.equals(Card_Shipping, a, b);
  }
}

/**
 * Firestore /cardholders/:id
 *
 * @generated from message perkup.v1.Cardholder
 */
export class Cardholder extends Message<Cardholder> {
  /**
   * stored in firestore
   *
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: perkup.v1.Cardholder.Billing billing = 2;
   */
  billing?: Cardholder_Billing;

  /**
   * @generated from field: optional string company = 3;
   */
  company?: string;

  /**
   * @generated from field: google.protobuf.Timestamp created = 4;
   */
  created?: Timestamp;

  /**
   * @generated from field: string email = 5;
   */
  email = "";

  /**
   * @generated from field: optional perkup.v1.Cardholder.Individual individual = 6;
   */
  individual?: Cardholder_Individual;

  /**
   * @generated from field: bool livemode = 7;
   */
  livemode = false;

  /**
   * @generated from field: perkup.v1.StripeMetadata metadata = 8;
   */
  metadata?: StripeMetadata;

  /**
   * @generated from field: string name = 9;
   */
  name = "";

  /**
   * @generated from field: string object = 10;
   */
  object = "";

  /**
   * @generated from field: optional string phone_number = 11 [json_name = "phone_number"];
   */
  phoneNumber?: string;

  /**
   * @generated from field: perkup.v1.Cardholder.Requirement requirements = 12;
   */
  requirements?: Cardholder_Requirement;

  /**
   * @generated from field: perkup.v1.SpendingControl spending_controls = 13 [json_name = "spending_controls"];
   */
  spendingControls?: SpendingControl;

  /**
   * @generated from field: string status = 14;
   */
  status = "";

  /**
   * @generated from field: string type = 15;
   */
  type = "";

  constructor(data?: PartialMessage<Cardholder>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "perkup.v1.Cardholder";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "billing", kind: "message", T: Cardholder_Billing },
    { no: 3, name: "company", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 4, name: "created", kind: "message", T: Timestamp },
    { no: 5, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "individual", kind: "message", T: Cardholder_Individual, opt: true },
    { no: 7, name: "livemode", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 8, name: "metadata", kind: "message", T: StripeMetadata },
    { no: 9, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "object", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "phone_number", jsonName: "phone_number", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 12, name: "requirements", kind: "message", T: Cardholder_Requirement },
    { no: 13, name: "spending_controls", jsonName: "spending_controls", kind: "message", T: SpendingControl },
    { no: 14, name: "status", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 15, name: "type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Cardholder {
    return new Cardholder().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Cardholder {
    return new Cardholder().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Cardholder {
    return new Cardholder().fromJsonString(jsonString, options);
  }

  static equals(a: Cardholder | PlainMessage<Cardholder> | undefined, b: Cardholder | PlainMessage<Cardholder> | undefined): boolean {
    return proto3.util.equals(Cardholder, a, b);
  }
}

/**
 * @generated from message perkup.v1.Cardholder.Billing
 */
export class Cardholder_Billing extends Message<Cardholder_Billing> {
  /**
   * @generated from field: perkup.v1.StripeAddress address = 1;
   */
  address?: StripeAddress;

  constructor(data?: PartialMessage<Cardholder_Billing>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "perkup.v1.Cardholder.Billing";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "address", kind: "message", T: StripeAddress },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Cardholder_Billing {
    return new Cardholder_Billing().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Cardholder_Billing {
    return new Cardholder_Billing().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Cardholder_Billing {
    return new Cardholder_Billing().fromJsonString(jsonString, options);
  }

  static equals(a: Cardholder_Billing | PlainMessage<Cardholder_Billing> | undefined, b: Cardholder_Billing | PlainMessage<Cardholder_Billing> | undefined): boolean {
    return proto3.util.equals(Cardholder_Billing, a, b);
  }
}

/**
 * @generated from message perkup.v1.Cardholder.Individual
 */
export class Cardholder_Individual extends Message<Cardholder_Individual> {
  /**
   * @generated from field: perkup.v1.Cardholder.Individual.Dob dob = 1;
   */
  dob?: Cardholder_Individual_Dob;

  /**
   * @generated from field: string first_name = 2 [json_name = "first_name"];
   */
  firstName = "";

  /**
   * @generated from field: string last_name = 3 [json_name = "last_name"];
   */
  lastName = "";

  /**
   * @generated from field: perkup.v1.Cardholder.Individual.Verification verification = 4;
   */
  verification?: Cardholder_Individual_Verification;

  constructor(data?: PartialMessage<Cardholder_Individual>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "perkup.v1.Cardholder.Individual";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "dob", kind: "message", T: Cardholder_Individual_Dob },
    { no: 2, name: "first_name", jsonName: "first_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "last_name", jsonName: "last_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "verification", kind: "message", T: Cardholder_Individual_Verification },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Cardholder_Individual {
    return new Cardholder_Individual().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Cardholder_Individual {
    return new Cardholder_Individual().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Cardholder_Individual {
    return new Cardholder_Individual().fromJsonString(jsonString, options);
  }

  static equals(a: Cardholder_Individual | PlainMessage<Cardholder_Individual> | undefined, b: Cardholder_Individual | PlainMessage<Cardholder_Individual> | undefined): boolean {
    return proto3.util.equals(Cardholder_Individual, a, b);
  }
}

/**
 * @generated from message perkup.v1.Cardholder.Individual.Dob
 */
export class Cardholder_Individual_Dob extends Message<Cardholder_Individual_Dob> {
  /**
   * @generated from field: int32 day = 1;
   */
  day = 0;

  /**
   * @generated from field: int32 month = 2;
   */
  month = 0;

  /**
   * @generated from field: int32 year = 3;
   */
  year = 0;

  constructor(data?: PartialMessage<Cardholder_Individual_Dob>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "perkup.v1.Cardholder.Individual.Dob";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "day", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "month", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "year", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Cardholder_Individual_Dob {
    return new Cardholder_Individual_Dob().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Cardholder_Individual_Dob {
    return new Cardholder_Individual_Dob().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Cardholder_Individual_Dob {
    return new Cardholder_Individual_Dob().fromJsonString(jsonString, options);
  }

  static equals(a: Cardholder_Individual_Dob | PlainMessage<Cardholder_Individual_Dob> | undefined, b: Cardholder_Individual_Dob | PlainMessage<Cardholder_Individual_Dob> | undefined): boolean {
    return proto3.util.equals(Cardholder_Individual_Dob, a, b);
  }
}

/**
 * @generated from message perkup.v1.Cardholder.Individual.Verification
 */
export class Cardholder_Individual_Verification extends Message<Cardholder_Individual_Verification> {
  /**
   * @generated from field: perkup.v1.Cardholder.Individual.Verification.Document document = 1;
   */
  document?: Cardholder_Individual_Verification_Document;

  constructor(data?: PartialMessage<Cardholder_Individual_Verification>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "perkup.v1.Cardholder.Individual.Verification";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "document", kind: "message", T: Cardholder_Individual_Verification_Document },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Cardholder_Individual_Verification {
    return new Cardholder_Individual_Verification().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Cardholder_Individual_Verification {
    return new Cardholder_Individual_Verification().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Cardholder_Individual_Verification {
    return new Cardholder_Individual_Verification().fromJsonString(jsonString, options);
  }

  static equals(a: Cardholder_Individual_Verification | PlainMessage<Cardholder_Individual_Verification> | undefined, b: Cardholder_Individual_Verification | PlainMessage<Cardholder_Individual_Verification> | undefined): boolean {
    return proto3.util.equals(Cardholder_Individual_Verification, a, b);
  }
}

/**
 * @generated from message perkup.v1.Cardholder.Individual.Verification.Document
 */
export class Cardholder_Individual_Verification_Document extends Message<Cardholder_Individual_Verification_Document> {
  /**
   * @generated from field: optional string back = 1;
   */
  back?: string;

  /**
   * @generated from field: optional string front = 2;
   */
  front?: string;

  constructor(data?: PartialMessage<Cardholder_Individual_Verification_Document>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "perkup.v1.Cardholder.Individual.Verification.Document";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "back", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 2, name: "front", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Cardholder_Individual_Verification_Document {
    return new Cardholder_Individual_Verification_Document().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Cardholder_Individual_Verification_Document {
    return new Cardholder_Individual_Verification_Document().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Cardholder_Individual_Verification_Document {
    return new Cardholder_Individual_Verification_Document().fromJsonString(jsonString, options);
  }

  static equals(a: Cardholder_Individual_Verification_Document | PlainMessage<Cardholder_Individual_Verification_Document> | undefined, b: Cardholder_Individual_Verification_Document | PlainMessage<Cardholder_Individual_Verification_Document> | undefined): boolean {
    return proto3.util.equals(Cardholder_Individual_Verification_Document, a, b);
  }
}

/**
 * @generated from message perkup.v1.Cardholder.Requirement
 */
export class Cardholder_Requirement extends Message<Cardholder_Requirement> {
  /**
   * @generated from field: optional string disabled_reason = 1 [json_name = "disabled_reason"];
   */
  disabledReason?: string;

  /**
   * @generated from field: repeated string past_due = 2 [json_name = "past_due"];
   */
  pastDue: string[] = [];

  constructor(data?: PartialMessage<Cardholder_Requirement>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "perkup.v1.Cardholder.Requirement";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "disabled_reason", jsonName: "disabled_reason", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 2, name: "past_due", jsonName: "past_due", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Cardholder_Requirement {
    return new Cardholder_Requirement().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Cardholder_Requirement {
    return new Cardholder_Requirement().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Cardholder_Requirement {
    return new Cardholder_Requirement().fromJsonString(jsonString, options);
  }

  static equals(a: Cardholder_Requirement | PlainMessage<Cardholder_Requirement> | undefined, b: Cardholder_Requirement | PlainMessage<Cardholder_Requirement> | undefined): boolean {
    return proto3.util.equals(Cardholder_Requirement, a, b);
  }
}

/**
 * @generated from message perkup.v1.StripeAmountDetail
 */
export class StripeAmountDetail extends Message<StripeAmountDetail> {
  /**
   * Nullable
   *
   * @generated from field: optional bool atm_fee = 1 [json_name = "atm_fee"];
   */
  atmFee?: boolean;

  constructor(data?: PartialMessage<StripeAmountDetail>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "perkup.v1.StripeAmountDetail";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "atm_fee", jsonName: "atm_fee", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): StripeAmountDetail {
    return new StripeAmountDetail().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): StripeAmountDetail {
    return new StripeAmountDetail().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): StripeAmountDetail {
    return new StripeAmountDetail().fromJsonString(jsonString, options);
  }

  static equals(a: StripeAmountDetail | PlainMessage<StripeAmountDetail> | undefined, b: StripeAmountDetail | PlainMessage<StripeAmountDetail> | undefined): boolean {
    return proto3.util.equals(StripeAmountDetail, a, b);
  }
}

/**
 * @generated from message perkup.v1.StripeMerchantData
 */
export class StripeMerchantData extends Message<StripeMerchantData> {
  /**
   * @generated from field: string category = 1;
   */
  category = "";

  /**
   * @generated from field: string city = 2;
   */
  city = "";

  /**
   * @generated from field: string country = 3;
   */
  country = "";

  /**
   * @generated from field: string name = 4;
   */
  name = "";

  /**
   * @generated from field: string network_id = 5 [json_name = "network_id"];
   */
  networkId = "";

  /**
   * @generated from field: string postal_code = 6 [json_name = "postal_code"];
   */
  postalCode = "";

  /**
   * @generated from field: string state = 7;
   */
  state = "";

  constructor(data?: PartialMessage<StripeMerchantData>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "perkup.v1.StripeMerchantData";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "category", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "city", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "country", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "network_id", jsonName: "network_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "postal_code", jsonName: "postal_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "state", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): StripeMerchantData {
    return new StripeMerchantData().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): StripeMerchantData {
    return new StripeMerchantData().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): StripeMerchantData {
    return new StripeMerchantData().fromJsonString(jsonString, options);
  }

  static equals(a: StripeMerchantData | PlainMessage<StripeMerchantData> | undefined, b: StripeMerchantData | PlainMessage<StripeMerchantData> | undefined): boolean {
    return proto3.util.equals(StripeMerchantData, a, b);
  }
}

/**
 * @generated from message perkup.v1.StripeDiscount
 */
export class StripeDiscount extends Message<StripeDiscount> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: perkup.v1.StripeDiscount.Coupon coupon = 2;
   */
  coupon?: StripeDiscount_Coupon;

  /**
   * @generated from field: string customer = 3;
   */
  customer = "";

  /**
   * UNIX timestamp and Nullable
   *
   * @generated from field: optional int64 end = 4;
   */
  end?: bigint;

  /**
   * UNIX timestamp
   *
   * @generated from field: int64 start = 5;
   */
  start = protoInt64.zero;

  /**
   * @generated from field: string subscription = 6;
   */
  subscription = "";

  /**
   * @generated from field: string object = 7;
   */
  object = "";

  /**
   * @generated from field: string checkout_session = 8 [json_name = "checkout_session"];
   */
  checkoutSession = "";

  /**
   * Nulalble
   *
   * @generated from field: optional string invoice = 9;
   */
  invoice?: string;

  /**
   * Nullable
   *
   * @generated from field: optional string invoice_item = 10 [json_name = "invoice_item"];
   */
  invoiceItem?: string;

  /**
   * Nullable
   *
   * @generated from field: optional string promotion_code = 11 [json_name = "promotion_code"];
   */
  promotionCode?: string;

  constructor(data?: PartialMessage<StripeDiscount>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "perkup.v1.StripeDiscount";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "coupon", kind: "message", T: StripeDiscount_Coupon },
    { no: 3, name: "customer", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "end", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 5, name: "start", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 6, name: "subscription", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "object", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "checkout_session", jsonName: "checkout_session", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "invoice", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 10, name: "invoice_item", jsonName: "invoice_item", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 11, name: "promotion_code", jsonName: "promotion_code", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): StripeDiscount {
    return new StripeDiscount().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): StripeDiscount {
    return new StripeDiscount().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): StripeDiscount {
    return new StripeDiscount().fromJsonString(jsonString, options);
  }

  static equals(a: StripeDiscount | PlainMessage<StripeDiscount> | undefined, b: StripeDiscount | PlainMessage<StripeDiscount> | undefined): boolean {
    return proto3.util.equals(StripeDiscount, a, b);
  }
}

/**
 * @generated from message perkup.v1.StripeDiscount.Coupon
 */
export class StripeDiscount_Coupon extends Message<StripeDiscount_Coupon> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string object = 2;
   */
  object = "";

  /**
   * @generated from field: int64 amount_off = 3 [json_name = "amount_off"];
   */
  amountOff = protoInt64.zero;

  /**
   * UNIX timestamp
   *
   * @generated from field: int64 created = 4;
   */
  created = protoInt64.zero;

  /**
   * @generated from field: string currency = 5;
   */
  currency = "";

  /**
   * @generated from field: string duration = 6;
   */
  duration = "";

  /**
   * Nullable
   *
   * @generated from field: optional int32 duration_in_months = 7 [json_name = "duration_in_months"];
   */
  durationInMonths?: number;

  /**
   * @generated from field: bool livemode = 8;
   */
  livemode = false;

  /**
   * Nullable
   *
   * @generated from field: optional int32 max_redemptions = 9 [json_name = "max_redemptions"];
   */
  maxRedemptions?: number;

  /**
   * @generated from field: map<string, string> metadata = 10;
   */
  metadata: { [key: string]: string } = {};

  /**
   * @generated from field: string name = 11;
   */
  name = "";

  /**
   * @generated from field: float percent_off = 12 [json_name = "percent_off"];
   */
  percentOff = 0;

  /**
   * UNIX timestamp and Nullable
   *
   * @generated from field: optional int64 redeem_by = 13 [json_name = "redeem_by"];
   */
  redeemBy?: bigint;

  /**
   * @generated from field: int32 times_redeemed = 14 [json_name = "times_redeemed"];
   */
  timesRedeemed = 0;

  /**
   * @generated from field: bool valid = 15;
   */
  valid = false;

  constructor(data?: PartialMessage<StripeDiscount_Coupon>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "perkup.v1.StripeDiscount.Coupon";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "object", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "amount_off", jsonName: "amount_off", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: "created", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 5, name: "currency", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "duration", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "duration_in_months", jsonName: "duration_in_months", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 8, name: "livemode", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 9, name: "max_redemptions", jsonName: "max_redemptions", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 10, name: "metadata", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "scalar", T: 9 /* ScalarType.STRING */} },
    { no: 11, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 12, name: "percent_off", jsonName: "percent_off", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 13, name: "redeem_by", jsonName: "redeem_by", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 14, name: "times_redeemed", jsonName: "times_redeemed", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 15, name: "valid", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): StripeDiscount_Coupon {
    return new StripeDiscount_Coupon().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): StripeDiscount_Coupon {
    return new StripeDiscount_Coupon().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): StripeDiscount_Coupon {
    return new StripeDiscount_Coupon().fromJsonString(jsonString, options);
  }

  static equals(a: StripeDiscount_Coupon | PlainMessage<StripeDiscount_Coupon> | undefined, b: StripeDiscount_Coupon | PlainMessage<StripeDiscount_Coupon> | undefined): boolean {
    return proto3.util.equals(StripeDiscount_Coupon, a, b);
  }
}

/**
 * @generated from message perkup.v1.StripeAddress
 */
export class StripeAddress extends Message<StripeAddress> {
  /**
   * @generated from field: string line1 = 1;
   */
  line1 = "";

  /**
   * @generated from field: optional string line2 = 2;
   */
  line2?: string;

  /**
   * @generated from field: string city = 3;
   */
  city = "";

  /**
   * @generated from field: string state = 4;
   */
  state = "";

  /**
   * ISO 3166-1 alpha2 US,CA,...
   *
   * @generated from field: string country = 5;
   */
  country = "";

  /**
   * @generated from field: string postal_code = 6 [json_name = "postal_code"];
   */
  postalCode = "";

  constructor(data?: PartialMessage<StripeAddress>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "perkup.v1.StripeAddress";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "line1", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "line2", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 3, name: "city", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "state", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "country", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "postal_code", jsonName: "postal_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): StripeAddress {
    return new StripeAddress().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): StripeAddress {
    return new StripeAddress().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): StripeAddress {
    return new StripeAddress().fromJsonString(jsonString, options);
  }

  static equals(a: StripeAddress | PlainMessage<StripeAddress> | undefined, b: StripeAddress | PlainMessage<StripeAddress> | undefined): boolean {
    return proto3.util.equals(StripeAddress, a, b);
  }
}

/**
 * @generated from message perkup.v1.SpendingControl
 */
export class SpendingControl extends Message<SpendingControl> {
  /**
   * @generated from field: repeated string allowed_categories = 1 [json_name = "allowed_categories"];
   */
  allowedCategories: string[] = [];

  /**
   * @generated from field: repeated string blocked_categories = 2 [json_name = "blocked_categories"];
   */
  blockedCategories: string[] = [];

  /**
   * @generated from field: repeated perkup.v1.SpendingControl.SpendingLimit spending_limits = 3 [json_name = "spending_limits"];
   */
  spendingLimits: SpendingControl_SpendingLimit[] = [];

  /**
   * @generated from field: optional string spending_limits_currency = 4 [json_name = "spending_limits_currency"];
   */
  spendingLimitsCurrency?: string;

  constructor(data?: PartialMessage<SpendingControl>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "perkup.v1.SpendingControl";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "allowed_categories", jsonName: "allowed_categories", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 2, name: "blocked_categories", jsonName: "blocked_categories", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 3, name: "spending_limits", jsonName: "spending_limits", kind: "message", T: SpendingControl_SpendingLimit, repeated: true },
    { no: 4, name: "spending_limits_currency", jsonName: "spending_limits_currency", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SpendingControl {
    return new SpendingControl().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SpendingControl {
    return new SpendingControl().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SpendingControl {
    return new SpendingControl().fromJsonString(jsonString, options);
  }

  static equals(a: SpendingControl | PlainMessage<SpendingControl> | undefined, b: SpendingControl | PlainMessage<SpendingControl> | undefined): boolean {
    return proto3.util.equals(SpendingControl, a, b);
  }
}

/**
 * @generated from message perkup.v1.SpendingControl.SpendingLimit
 */
export class SpendingControl_SpendingLimit extends Message<SpendingControl_SpendingLimit> {
  /**
   * @generated from field: int32 amount = 1;
   */
  amount = 0;

  /**
   * @generated from field: repeated string categories = 2;
   */
  categories: string[] = [];

  /**
   * @generated from field: string interval = 3;
   */
  interval = "";

  constructor(data?: PartialMessage<SpendingControl_SpendingLimit>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "perkup.v1.SpendingControl.SpendingLimit";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "amount", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "categories", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 3, name: "interval", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SpendingControl_SpendingLimit {
    return new SpendingControl_SpendingLimit().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SpendingControl_SpendingLimit {
    return new SpendingControl_SpendingLimit().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SpendingControl_SpendingLimit {
    return new SpendingControl_SpendingLimit().fromJsonString(jsonString, options);
  }

  static equals(a: SpendingControl_SpendingLimit | PlainMessage<SpendingControl_SpendingLimit> | undefined, b: SpendingControl_SpendingLimit | PlainMessage<SpendingControl_SpendingLimit> | undefined): boolean {
    return proto3.util.equals(SpendingControl_SpendingLimit, a, b);
  }
}

/**
 * @generated from message perkup.v1.StripeMetadata
 */
export class StripeMetadata extends Message<StripeMetadata> {
  /**
   * @generated from field: optional string user_id = 1;
   */
  userId?: string;

  /**
   * @generated from field: optional string org_id = 2;
   */
  orgId?: string;

  constructor(data?: PartialMessage<StripeMetadata>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "perkup.v1.StripeMetadata";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 2, name: "org_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): StripeMetadata {
    return new StripeMetadata().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): StripeMetadata {
    return new StripeMetadata().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): StripeMetadata {
    return new StripeMetadata().fromJsonString(jsonString, options);
  }

  static equals(a: StripeMetadata | PlainMessage<StripeMetadata> | undefined, b: StripeMetadata | PlainMessage<StripeMetadata> | undefined): boolean {
    return proto3.util.equals(StripeMetadata, a, b);
  }
}

/**
 * Firestore /charges/:id
 *
 * @generated from message perkup.v1.Charge
 */
export class Charge extends Message<Charge> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: int64 amount = 2;
   */
  amount = protoInt64.zero;

  /**
   * @generated from field: int64 amount_captured = 3 [json_name = "amount_captured"];
   */
  amountCaptured = protoInt64.zero;

  /**
   * @generated from field: int64 amount_refunded = 4 [json_name = "amount_refunded"];
   */
  amountRefunded = protoInt64.zero;

  /**
   * @generated from field: string balance_transaction = 5 [json_name = "balance_transaction"];
   */
  balanceTransaction = "";

  /**
   * @generated from field: int64 created = 6;
   */
  created = protoInt64.zero;

  /**
   * @generated from field: string currency = 7;
   */
  currency = "";

  /**
   * @generated from field: string customer = 8;
   */
  customer = "";

  /**
   * @generated from field: string description = 9;
   */
  description = "";

  /**
   * @generated from field: string invoice = 10;
   */
  invoice = "";

  /**
   * @generated from field: string status = 11;
   */
  status = "";

  constructor(data?: PartialMessage<Charge>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "perkup.v1.Charge";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "amount", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "amount_captured", jsonName: "amount_captured", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: "amount_refunded", jsonName: "amount_refunded", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 5, name: "balance_transaction", jsonName: "balance_transaction", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "created", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 7, name: "currency", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "customer", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "invoice", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "status", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Charge {
    return new Charge().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Charge {
    return new Charge().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Charge {
    return new Charge().fromJsonString(jsonString, options);
  }

  static equals(a: Charge | PlainMessage<Charge> | undefined, b: Charge | PlainMessage<Charge> | undefined): boolean {
    return proto3.util.equals(Charge, a, b);
  }
}

