import { StripeAddressElementChangeEvent } from '@stripe/stripe-js'
import { Button, Input, message, Modal } from 'antd'
import { UserContext } from 'context'
import { Heading, Pane, PlusIcon, Text } from 'evergreen-ui'
import {
  ShippingAddress,
  ShippingAddress_LocalizationExtensions,
} from 'gen/perkup/v1/root_user_pb'
import { StripeAddress } from 'gen/perkup/v1/stripe_data_pb'
import parse from 'html-react-parser'
import { capitalize, isNaN } from 'lodash-es'
import { useContext, useState } from 'react'
import { StripeAddressData } from 'types/Stripe'
import {
  minValidFieldLength,
  stripeAddressToShippingAddress,
  verifyAddress,
} from 'utils'
import { AddressForm } from './address-form'

interface LocalizationInfo {
  label: string
  placeholder: string
  validationMessage: string
  length: number
  iso2: string
  verification: (value?: string) => boolean
  key: string
}

const localizations: Record<string, LocalizationInfo> = {
  BR: {
    label: 'CPF (Cadastro de Pessoa Física) number',
    placeholder: 'Enter your CPF number',
    validationMessage: 'Please enter a valid CPF',
    length: 11,
    iso2: 'BR',
    verification: (cpf?: string) => {
      if (!cpf) return false
      if (cpf.length !== 11) return false
      if (isNaN(Number(cpf))) return false
      return true
    },
    key: 'shippingCredentialBr',
  },
  CN: {
    label: 'Resident ID number',
    placeholder: 'Enter your Resident ID number',
    validationMessage: 'Please enter a valid Resident ID number',
    length: 18,
    iso2: 'CN',
    verification: (residentId?: string) => {
      if (!residentId) return false
      if (residentId.length !== 18) return false
      return true
    },
    key: 'shippingCredentialCn',
  },
  KR: {
    label: 'PCCC number',
    placeholder: 'Enter your PCCC number',
    validationMessage: 'Please enter a valid PCCC number',
    length: 13,
    iso2: 'KR',
    verification: (pccc?: string) => {
      if (!pccc) return false
      if (pccc.length !== 13) return false
      return true
    },
    key: 'shippingCredentialKr',
  },
}

export function NewAddressButton({
  addAddressCTALabel,
  submitLabel,
  dialogTitle,
  icon,
  asSmallButton = false,
  asPrimaryButton = false,
  bigButtonWidth = 312,
  bigButtonMinHeight = 192,
  defaultAddress,
  onAddressSubmit,
}: {
  addAddressCTALabel: string
  submitLabel: string
  dialogTitle: string
  icon?: JSX.Element
  asSmallButton?: boolean
  asPrimaryButton?: boolean
  bigButtonMinHeight?: number | string
  bigButtonWidth?: number | string
  defaultAddress?: ShippingAddress
  onAddressSubmit: (newAddress: ShippingAddress) => void
}) {
  const [messageApi, contextHolder] = message.useMessage()

  const { id: userId } = useContext(UserContext)
  const [showAddressForm, setShowAddressForm] = useState(false)
  const [currentAddress, setCurrentAddress] = useState<StripeAddressData>()
  const [addressFormCompleted, setAddressFormCompleted] = useState(false)
  const [currentLocalization, setCurrentLocalization] =
    useState<LocalizationInfo>()

  const handleModalClose = () => {
    setCurrentAddress(undefined)
    setShowAddressForm(false)
    setAddressFormCompleted(false)
    setCurrentLocalization(undefined)
  }

  const handleAddressChange = (event: StripeAddressElementChangeEvent) => {
    if (event.complete && event.value.phone) {
      setAddressFormCompleted(true)

      const eventAddress = event.value.address

      const cityStates = ['SG', 'MC', 'VA']

      const address = new StripeAddress({
        line1: eventAddress.line1,
        line2: eventAddress.line2 ?? undefined,
        city: eventAddress.city || eventAddress.state,
        state: eventAddress.state,
        country: eventAddress.country,
        postalCode: eventAddress.postal_code || '00000',
      })
      if (cityStates.includes(eventAddress.country)) {
        address.city = ''
        address.state = ''
      }

      const fullAddress: StripeAddressData = {
        name: event.value.name,
        address,
        phone: event.value.phone,
      }
      setCurrentAddress(fullAddress)
    } else setAddressFormCompleted(false)

    if (localizations[event.value.address.country]) {
      setCurrentLocalization(localizations[event.value.address.country])
    } else if (currentLocalization) {
      setCurrentLocalization(undefined)

      if (currentAddress) {
        setCurrentAddress({
          ...currentAddress,
          localizationExtensions: undefined,
        })
      }
    }
  }

  const handleConfirmAddress = () => {
    if (!addressFormCompleted) {
      messageApi.warning({
        key: 'form-completion',
        content: 'Please enter a valid address & phone number',
        type: 'warning',
      })
      const stripeModal = document.querySelector('.ant-modal-body')
      if (stripeModal) {
        stripeModal.scrollTo(0, 100000)
      }
      return
    }

    if (!currentAddress?.address?.country) {
      messageApi.warning({
        key: 'form-completion',
        content: 'Please enter a valid address & phone number',
        type: 'warning',
      })
      return
    }

    const localization = localizations[currentAddress?.address?.country]

    if (localization) {
      let localizationToVerify: string | undefined = ''
      switch (localization.iso2) {
        case 'BR': {
          localizationToVerify =
            currentAddress?.localizationExtensions?.shippingCredentialBr
          break
        }
        case 'CN': {
          localizationToVerify =
            currentAddress?.localizationExtensions?.shippingCredentialCn
          break
        }
        case 'KR': {
          localizationToVerify =
            currentAddress?.localizationExtensions?.shippingCredentialKr
          break
        }
        default:
          break
      }
      const verified = localization.verification(localizationToVerify)
      if (!verified) {
        messageApi.warning({
          key: 'form-completion',
          content: localization.validationMessage,
          type: 'warning',
        })
        const stripeModal = document.querySelector('.ant-modal-body')
        if (stripeModal) {
          stripeModal.scrollTo(0, 100000)
        }
        return
      }
    }

    if (!currentAddress || !userId) return

    const stripeToShippingAddress = new ShippingAddress({
      ...currentAddress.address,
      name: currentAddress.name,
    })

    const addressValidation = verifyAddress(stripeToShippingAddress)
    const { invalidFields } = addressValidation
    if (!addressValidation.validationSuccessful) {
      const message = 'Invalid address'
      let description = ''
      invalidFields.forEach((field, index) => {
        const fieldDisplayValue = (field: keyof ShippingAddress) => {
          if (field === 'postalCode') return 'Postal code'
          if (field === 'line1') return 'Address line 1'
          if (field === 'name') return 'First and last name'
          return capitalize(field.toString())
        }
        // If more than 1 invalid field, list them by number
        description += `${
          invalidFields.length > 1 ? `${index + 1}. ` : ''
        } ${fieldDisplayValue(
          field
        )} must be at least ${minValidFieldLength} characters long. <br />`
      })

      messageApi.warning({
        key: 'form-completion',
        content: message,
        children: <Text>{parse(description)}</Text>,
        type: 'warning',
      })

      return
    }
    const convertedAddress = stripeAddressToShippingAddress(currentAddress)
    onAddressSubmit(
      new ShippingAddress({
        ...convertedAddress,
        id: defaultAddress?.id,
      })
    )
    handleModalClose()
  }

  const defaultLocalizationValue = currentLocalization
    ? defaultAddress?.localizationExtensions?.[
        currentLocalization.key as keyof ShippingAddress_LocalizationExtensions
      ]?.valueOf()
    : undefined

  return (
    <>
      {contextHolder}

      {!asSmallButton ? (
        <Pane
          display="flex"
          alignItems="center"
          justifyContent="center"
          width={bigButtonWidth}
          minHeight={bigButtonMinHeight}
          border
          borderStyle="dashed"
          cursor="pointer"
          onClick={() => {
            setShowAddressForm(true)
          }}
          gap={8}
        >
          <PlusIcon />

          <Heading size={500}>{addAddressCTALabel}</Heading>
        </Pane>
      ) : (
        <Button
          type={asPrimaryButton ? 'primary' : 'default'}
          style={{ width: 'fit-content' }}
          onClick={() => setShowAddressForm(true)}
          icon={icon}
        >
          {addAddressCTALabel}
        </Button>
      )}

      <Modal
        okText={submitLabel}
        closeIcon={null}
        open={showAddressForm}
        afterClose={handleModalClose}
        onOk={handleConfirmAddress}
        onCancel={handleModalClose}
        width={720}
        styles={{
          body: {
            height: '60vh',
            overflow: 'auto',
          },
        }}
        zIndex={1000}
        destroyOnClose
      >
        <Pane>
          <Heading size={600} marginBottom={24}>
            {dialogTitle}
          </Heading>

          <AddressForm
            defaultAddress={defaultAddress}
            onAddressChange={handleAddressChange}
          />
          {currentLocalization && currentAddress && (
            <Pane
              paddingX={16}
              display="flex"
              flexDirection="column"
              gap={8}
              marginTop={16}
            >
              <Text>{currentLocalization?.label}</Text>
              <Input
                size="large"
                defaultValue={
                  typeof defaultLocalizationValue === 'string'
                    ? defaultLocalizationValue
                    : undefined
                }
                onChange={e => {
                  if (!currentAddress) return

                  const { value } = e.target

                  const localizationExtensions =
                    new ShippingAddress_LocalizationExtensions({
                      [currentLocalization.key]: value,
                    })
                  setCurrentAddress({
                    ...currentAddress,
                    localizationExtensions,
                  })
                }}
              />
            </Pane>
          )}
        </Pane>
      </Modal>
    </>
  )
}
