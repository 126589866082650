import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  SwapOutlined,
} from '@ant-design/icons'
import { captureException } from '@sentry/react'
import { Button, Dropdown, Flex, InputNumber, MenuProps, Modal } from 'antd'
import { SelectFullAccessAccount } from 'components/SelectFullAccessAccount'
import { Heading, Pane, Strong, Text, toaster, useTheme } from 'evergreen-ui'
import { Account } from 'gen/perkup/v1/account_pb'
import { useFullAccessAccounts } from 'hooks/accounts/use-full-access-accounts'
import useIds from 'hooks/useIds'
import { useState } from 'react'
import { transferFunds } from 'services/accounts'
import { numToDollars, toSentry } from 'utils'
import { getAccountBalances } from 'utils/accounts'

export function TransferFundsButton({
  defaultAccount,
}: {
  defaultAccount?: Account
}) {
  const defaultAccountWithFunds = !!defaultAccount && defaultAccount.balance > 0
  const [showTransferFundsModal, setShowTransferFundsModal] = useState(false)
  const [sourceAccount, setSourceAccount] = useState<Account | undefined>()
  const [destinationAccount, setDestinationAccount] = useState<
    Account | undefined
  >(!defaultAccountWithFunds ? defaultAccount : undefined)
  const [amountToTransfer, setAmountToTransfer] = useState<number>(0)
  const [isTransactionLoading, setIsTransactionLoading] = useState(false)
  const theme = useTheme()
  const { orgId } = useIds()

  const { fullAccessAccounts } = useFullAccessAccounts()

  const sourceAccountUpdated = fullAccessAccounts.find(
    account => account.id === sourceAccount?.id
  )
  const availableAmount = Number(sourceAccountUpdated?.balance) || 0

  const { unallocatedFunds: defaultExposure } = getAccountBalances({
    account: defaultAccount,
  })
  const hasNegativeExposure = !!defaultExposure && defaultExposure < 0
  const negativeExposureTransferAmount = hasNegativeExposure
    ? Number((defaultExposure * -1) / 100)
    : undefined

  const transferFundsCopy = 'Transfer funds'

  const isTransferDisabled =
    sourceAccount?.id === destinationAccount?.id ||
    !sourceAccount ||
    !destinationAccount ||
    amountToTransfer === 0 ||
    amountToTransfer > availableAmount / 100

  const handleTransferFunds = async () => {
    setIsTransactionLoading(true)
    if (!sourceAccount || !destinationAccount) {
      toaster.warning(
        'Cannot transfer funds without both a source and destination account'
      )
      return
    }
    if (sourceAccount?.id === destinationAccount?.id) {
      toaster.warning('Cannot transfer funds to the same account')
      return
    }
    try {
      await transferFunds({
        sourceAccount: sourceAccount?.id || '',
        destinationAccount: destinationAccount?.id || '',
        amount: BigInt(Math.ceil(amountToTransfer * 100)),
      })
      toaster.success('Successfully transferred funds')
      setSourceAccount(undefined)
      setDestinationAccount(undefined)
    } catch (e) {
      toaster.danger('Failed to transfer funds')
      console.error(e)
      captureException(toSentry(e), {
        contexts: {
          handleTransferFunds: {
            sourceAccount: sourceAccount?.id || '',
            destinationAccount: destinationAccount?.id || '',
            orgId,
          },
        },
      })
    }
    setShowTransferFundsModal(false)
    setIsTransactionLoading(false)
  }

  const transferCTA = () => {
    if (defaultAccountWithFunds) {
      const items: MenuProps['items'] = [
        {
          key: '1',
          label: (
            <Pane
              display="flex"
              gap={8}
              alignItems="center"
              onClick={() => {
                setSourceAccount(defaultAccount)
                setShowTransferFundsModal(true)
              }}
            >
              <ArrowLeftOutlined />
              <Text>Transfer out</Text>
            </Pane>
          ),
        },
        {
          key: '2',
          label: (
            <Pane
              display="flex"
              gap={8}
              alignItems="center"
              onClick={() => {
                setDestinationAccount(defaultAccount)
                setShowTransferFundsModal(true)
                if (negativeExposureTransferAmount) {
                  setAmountToTransfer(negativeExposureTransferAmount)
                }
              }}
            >
              <ArrowRightOutlined />
              <Text>Transfer in</Text>
            </Pane>
          ),
        },
      ]
      return (
        <Dropdown arrow menu={{ items }}>
          <Button style={{ width: 'fit-content' }} icon={<SwapOutlined />}>
            {transferFundsCopy}
          </Button>
        </Dropdown>
      )
    }
    return (
      <Button
        icon={<SwapOutlined />}
        style={{ width: 'fit-content' }}
        onClick={() => {
          setShowTransferFundsModal(true)
          if (negativeExposureTransferAmount) {
            setAmountToTransfer(negativeExposureTransferAmount)
          }
        }}
      >
        {transferFundsCopy}
      </Button>
    )
  }

  return (
    <>
      <Modal
        open={showTransferFundsModal}
        footer={
          <Flex gap={8} justify="end" style={{ marginTop: 24 }}>
            <Button key="back" onClick={() => setShowTransferFundsModal(false)}>
              Cancel
            </Button>
            <Button
              key="submit"
              type="primary"
              disabled={isTransferDisabled}
              onClick={handleTransferFunds}
              loading={isTransactionLoading}
            >
              Submit
            </Button>
          </Flex>
        }
        onCancel={() => {
          setShowTransferFundsModal(false)
        }}
        afterClose={() => {
          setSourceAccount(undefined)

          setAmountToTransfer(0)
          if (!defaultAccountWithFunds) {
            setDestinationAccount(defaultAccount)
          } else {
            setDestinationAccount(undefined)
          }
        }}
        destroyOnClose
      >
        <Pane marginBottom={32}>
          <Heading size={600} paddingBottom={24}>
            Transfer funds
          </Heading>
          <Text>Move funds from one account to another.</Text>
        </Pane>
        <Pane display="flex" justifyContent="space-between" marginBottom={16}>
          <Strong>From</Strong>
          <SelectFullAccessAccount
            setSelectedAccount={account => setSourceAccount(account)}
            selectedAccount={sourceAccount}
          />
        </Pane>
        <Pane display="flex" justifyContent="space-between" marginBottom={16}>
          <Strong>To</Strong>
          <SelectFullAccessAccount
            setSelectedAccount={account => setDestinationAccount(account)}
            selectedAccount={destinationAccount}
          />
        </Pane>
        <Pane display="grid" gridTemplateColumns="5fr 6fr" marginBottom={16}>
          <Strong>Available to transfer</Strong>
          <Strong marginLeft={8} color={theme.colors.green400}>
            {numToDollars(availableAmount)}
          </Strong>
        </Pane>
        <Pane display="flex" justifyContent="space-between" marginBottom={16}>
          <Strong>Amount</Strong>
          <Pane width={252}>
            <InputNumber
              addonBefore="$"
              onChange={value => setAmountToTransfer(value || 0)}
              min={0}
              style={{ width: '100%' }}
              placeholder="Enter an amount"
              value={amountToTransfer || undefined}
            />
          </Pane>
        </Pane>
      </Modal>
      {transferCTA()}
    </>
  )
}
