export const createSpaceConfig = (
  orgName: string,
  orgColor: string,
  orgLogo: string | undefined,
  withSidebar?: boolean
) => ({
  name: `${orgName} Space`,
  namespace: 'portal',
  metadata: {
    theme: {
      root: {
        primaryColor: orgColor,
        buttonBorderRadius: '8px',
        modalBorderRadius: '8px',
        popoverBorderRadius: '8px',
      },
      sidebar: {
        logo:
          orgLogo || 'https://asset.brandfetch.io/id2dHAotyK/idzRY-FwD4.svg', // PerkUp logo as url string
      },
    },
    sidebarConfig: {
      showDataChecklist: !!withSidebar,
      showSidebar: !!withSidebar,
    },
  },
})
