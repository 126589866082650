import { Heading } from 'evergreen-ui'
import { SelectGiftCard } from 'features'
import { useSelectRewardContext } from '../SelectRewardContext'

export function CardsForm1() {
  const { selectedReward, giftCardForm } = useSelectRewardContext()
  return (
    <SelectGiftCard
      title={<Heading size={700}>Select a gift card</Heading>}
      currentGift={selectedReward?.gift}
      onSelectGiftCard={giftCardForm.handleSelectGiftCard}
    />
  )
}
