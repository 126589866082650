import { SearchOutlined } from '@ant-design/icons'
import { CATEGORY_COLLECTION_IDS } from '@repo/constants/algolia'
import { Button, Flex, Input, Select, Tabs } from 'antd'
import { PerkTruncate } from 'components'
import { ALL_PRODUCTS, LABELS } from 'constants/algolia'
import { OCCASION_COLLECTION_IDS } from 'constants/productCollections'
import { Strong } from 'evergreen-ui'
import { useListProductCollectionsByOrgId } from 'hooks'
import { compact, startCase } from 'lodash-es'
import { useState } from 'react'
import { useClearRefinements, useMenu } from 'react-instantsearch'
import { startCaseWithAmpersand } from 'utils/labels'

function AlgoliaCollectionsFilterOrg() {
  const { refine: clearRefinements } = useClearRefinements({
    includedAttributes: ['collectionIds'],
  })

  const { productCollections, isLoading } = useListProductCollectionsByOrgId()

  const { items, refine, canToggleShowMore, toggleShowMore, isShowingMore } =
    useMenu({
      limit: 100,
      attribute: 'collectionIds',
      sortBy: ['name:asc'],
      showMore: true,
      showMoreLimit: 1000,
    })

  const [currentSearchValue, setCurrentSearchValue] = useState('')

  if (isLoading || productCollections.length === 0) return null

  const itemsToRender = items.filter(item => {
    const collectionForItem = productCollections.find(
      collection => collection.id === item.value
    )
    if (!collectionForItem) return false
    if (!collectionForItem.name.toLowerCase().includes(currentSearchValue))
      return false

    return true
  })

  const itemsWithAllTab = [
    {
      key: ALL_PRODUCTS,
      label: startCase(ALL_PRODUCTS),
      value: ALL_PRODUCTS,
      isRefined: !itemsToRender.some(item => item.isRefined),
    },
    ...itemsToRender.map(item => {
      const collectionName = productCollections.find(
        collection => collection.id === item.value
      )?.name
      return {
        key: item.value,
        label: <PerkTruncate>{collectionName || item.label}</PerkTruncate>,
        value: item.value,
        isRefined: item.isRefined,
      }
    }),
  ]

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentSearchValue(e.currentTarget.value)
  }

  return (
    <Flex vertical gap={8}>
      <Strong>Collections</Strong>
      <Input
        prefix={<SearchOutlined />}
        placeholder="Search collections"
        allowClear
        onChange={handleSearchChange}
      />
      <Tabs
        className="custom-ant-vertical-tabs"
        defaultActiveKey="All"
        tabPosition="right"
        items={itemsWithAllTab}
        onChange={activeKey =>
          activeKey === ALL_PRODUCTS ? clearRefinements() : refine(activeKey)
        }
        tabBarGutter={1}
      />

      {canToggleShowMore && (
        <Button onClick={toggleShowMore}>
          {isShowingMore ? 'Show less' : 'Show more'}
        </Button>
      )}
    </Flex>
  )
}

function AlgoliaCollectionsFilterCategories({ asTabs }: { asTabs?: boolean }) {
  const { refine: clearRefinements } = useClearRefinements({
    includedAttributes: ['collectionIds'],
  })

  const { items, refine, canToggleShowMore, toggleShowMore, isShowingMore } =
    useMenu({
      limit: 20,
      attribute: 'collectionIds',
      sortBy: ['name:asc'],
      showMore: true,
      showMoreLimit: 1000,
    })

  // We only want to display the collections that we've hard-coded and in the same order, even as the refine!
  const itemsToRender = compact(
    CATEGORY_COLLECTION_IDS.map(id => items.find(item => item.value === id))
  )

  const itemsWithAllTab = [
    {
      key: ALL_PRODUCTS,
      label: 'All',
      value: ALL_PRODUCTS,
      isRefined: !items.some(item => item.isRefined),
    },
    ...itemsToRender.map(item => ({
      key: item.value,
      label: LABELS[item.label] || startCaseWithAmpersand(item.label),
      value: item.value,
      isRefined: item.isRefined,
    })),
  ]

  if (asTabs) {
    return (
      <Flex vertical gap={8}>
        <Strong>Categories</Strong>

        <Tabs
          className="custom-ant-vertical-tabs"
          defaultActiveKey="All"
          tabPosition="right"
          items={itemsWithAllTab}
          activeKey={
            itemsWithAllTab.find(i => i.isRefined)?.key ?? ALL_PRODUCTS
          }
          onChange={activeKey =>
            activeKey === ALL_PRODUCTS ? clearRefinements() : refine(activeKey)
          }
          tabBarGutter={1}
        />

        {canToggleShowMore && (
          <Button onClick={toggleShowMore}>
            {isShowingMore ? 'Show less' : 'Show more'}
          </Button>
        )}
      </Flex>
    )
  }

  return (
    <Select
      style={{ width: '100%' }}
      defaultValue={ALL_PRODUCTS}
      variant="borderless"
      placement="bottomRight"
      onClear={clearRefinements}
      popupMatchSelectWidth={false}
      placeholder="Categories"
      onSelect={(_, { key }) =>
        key === ALL_PRODUCTS ? clearRefinements() : refine(key)
      }
      options={itemsWithAllTab}
    />
  )
}

function AlgoliaCollectionsFilterOccasions({ asPills }: { asPills?: boolean }) {
  const { refine: clearRefinements } = useClearRefinements({
    includedAttributes: ['collectionIds'],
  })

  const { items, refine, canToggleShowMore, toggleShowMore, isShowingMore } =
    useMenu({
      limit: 100,
      attribute: 'collectionIds',
      sortBy: ['name:asc'],
      showMore: true,
      showMoreLimit: 1000,
    })

  // We only want to display the collections that we've hard-coded and in the same order, even as the refine!
  const itemsToRender = compact(
    OCCASION_COLLECTION_IDS.map(id => items.find(item => item.value === id))
  )

  const itemsWithAllTab = [
    {
      key: ALL_PRODUCTS,
      label: `⭐ ${startCase(ALL_PRODUCTS)}`,
      value: ALL_PRODUCTS,
      isRefined: !itemsToRender.some(item => item.isRefined),
    },
    ...itemsToRender.map(item => ({
      key: item.value,
      label: LABELS[item.label] || startCaseWithAmpersand(item.label),
      value: item.value,
      isRefined: item.isRefined,
    })),
  ]

  if (asPills) {
    return (
      <Flex vertical gap={8}>
        <Strong>Occasions</Strong>
        <Flex gap={8} wrap>
          {itemsWithAllTab.map(item => (
            <Button
              key={item.key}
              ghost={item.isRefined}
              type={item.isRefined ? 'primary' : 'default'}
              shape="round"
              onClick={() =>
                item.key === ALL_PRODUCTS
                  ? clearRefinements()
                  : refine(item.key)
              }
            >
              {item.label}
            </Button>
          ))}
        </Flex>
      </Flex>
    )
  }

  return (
    <Flex vertical gap={8}>
      <Strong>Occasions</Strong>

      <Tabs
        className="custom-ant-vertical-tabs"
        defaultActiveKey="All"
        tabPosition="right"
        items={itemsWithAllTab}
        onChange={activeKey =>
          activeKey === ALL_PRODUCTS ? clearRefinements() : refine(activeKey)
        }
        tabBarGutter={1}
      />

      {canToggleShowMore && (
        <Button onClick={toggleShowMore}>
          {isShowingMore ? 'Show less' : 'Show more'}
        </Button>
      )}
    </Flex>
  )
}

function AlgoliaCollectionsFilter() {}

AlgoliaCollectionsFilter.Occasions = AlgoliaCollectionsFilterOccasions
AlgoliaCollectionsFilter.Org = AlgoliaCollectionsFilterOrg
AlgoliaCollectionsFilter.Categories = AlgoliaCollectionsFilterCategories

export default AlgoliaCollectionsFilter
