import { Account_Permission } from 'gen/perkup/v1/account_pb'

const accountPermissionOptions = [
  {
    value: Account_Permission.full,
    name: 'Full access',
    description:
      'Can invite users, view, fund, download reports, and use funds to send rewards',
  },
  {
    value: Account_Permission.send,
    name: 'Can send',
    description:
      'Can view, fund, download reports, and use funds to send rewards',
  },
  {
    value: Account_Permission.view,
    name: 'Can view',
    description: 'Can view and download reports',
  },
]

export const adminAccountPermissionsOptions = [...accountPermissionOptions]

export const managerAccountPermissionsOptions = [
  ...accountPermissionOptions,
  {
    value: Account_Permission.PERMISSION_UNSPECIFIED,
    name: 'No access',
    description: '',
  },
]

export const individualAccountPermissionsOptions = [
  ...accountPermissionOptions,
  {
    value: Account_Permission.PERMISSION_UNSPECIFIED,
    name: 'Remove',
    description: '',
  },
]

export enum BalanceTypes {
  OUTSTANDING = 'outstanding',
  DIFFERENCE = 'difference',
}

export enum AccountBalanceLabels {
  UNREDEEMED_REWARDS = 'Unredeemed rewards',
}
