import { ListenToCollectionById } from 'api/databaseCalls'
import { FEATURED_COLLECTION_ID } from 'constants/productCollections'
import { ProductCollection } from 'gen/perkup/v1/product_collections_pb'
import { useEffect, useState } from 'react'

export function useCollectionById({ id }: { id?: string }) {
  const [collection, setCollection] = useState<ProductCollection>()
  const [hasLoaded, setHasLoaded] = useState(false)

  // Featured collection doesnt really exist so we can't try to read it
  const isFeaturedCollection = id === FEATURED_COLLECTION_ID

  useEffect(() => {
    if (!id || isFeaturedCollection) {
      setCollection(undefined)
      setHasLoaded(true)
      return undefined
    }
    return ListenToCollectionById({
      id,
      cb: setCollection,
      setHasLoaded,
    })
  }, [id, isFeaturedCollection])

  return { collection, products: collection?.products, hasLoaded }
}
