import { Flex } from 'antd'
import { BackIconButton } from 'components'
import { MAX_PAGE_WIDTH } from 'constants/layout'
import { DEFAULT_ROUTES } from 'constants/routes'
import { SwagOrdersTable, SwagProductDetails } from 'features'
import { Helmet } from 'react-helmet-async'
import { useParams } from 'react-router'

export default function OrgSwagProductPage() {
  const { productId } = useParams()

  if (!productId) return null

  return (
    <>
      <Helmet>
        <title>Inventory | Swag</title>
      </Helmet>

      <Flex
        style={{ maxWidth: MAX_PAGE_WIDTH, margin: 'auto' }}
        vertical
        gap={64}
      >
        <Flex gap={16}>
          <BackIconButton to={DEFAULT_ROUTES.ORGANIZATION.SWAG.PRODUCTS} />
          <SwagProductDetails productId={productId} />
        </Flex>

        <SwagOrdersTable productId={productId} />
      </Flex>
    </>
  )
}
