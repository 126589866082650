import {
  HourglassOutlined,
  MinusOutlined,
  PlusOutlined,
} from '@ant-design/icons'
import { captureMessage } from '@sentry/react'
import { Alert, Button, Flex, Image, Modal, Tag, Tooltip } from 'antd'
import {
  ListNonRemovedIndividualsByIds,
  updateProgram,
} from 'api/databaseCalls'
import { callFunction } from 'api/functionCalls'
import {
  AccountBalanceForm,
  AddressDisplay,
  IndividualAvatar,
  ItemInfo,
  PerkActionFooter,
  PerkLoader,
  PriceBreakdownV2,
  ProductCollectionCard,
} from 'components'
import { BalanceText } from 'components/Typography/BalanceText'
import { PROGRAM_STEP_COMPLETED } from 'constants/events'
import { PERKUP_PRIMARY_LOGO } from 'constants/logos'
import { SURVEY } from 'constants/params'
import { RewardSteps } from 'constants/rewards'
import { CHECKOUT, DEFAULT_ROUTES } from 'constants/routes'
import {
  IndividualContext,
  OrgContext,
  ProgramContext,
  TemplateContext,
} from 'context'
import { Dayjs } from 'dayjs'
import {
  Heading,
  Pane,
  Paragraph,
  PlusIcon,
  SendMessageIcon,
  Strong,
  Text,
  TimeIcon,
  toaster,
  useTheme,
} from 'evergreen-ui'
import { ProductSummary } from 'features'
import { Individual } from 'gen/perkup/v1/individual_pb'
import { Organization_SubscriptionStatus } from 'gen/perkup/v1/organization_pb'
import { ProductVariant_SourceType } from 'gen/perkup/v1/product_variant_pb'
import { Program, ProgramStatus } from 'gen/perkup/v1/program_pb'
import {
  useAccountBalances,
  useCanCreateAccountInvoices,
  useCollectionById,
  useDefaultOrgColors,
  useIndividualRole,
  useSendableAccounts,
} from 'hooks'
import useListAllProductVariantsByProductIds from 'hooks/productVariants/useListProductVariantsByProductIds'
import useIds from 'hooks/useIds'
import parse from 'html-react-parser'
import { capitalize, cloneDeep, isEmpty } from 'lodash-es'
import { useContext, useEffect, useMemo, useState } from 'react'
import { useNavigate, useOutletContext } from 'react-router'
import { createSearchParams } from 'react-router-dom'
import { createDefaultCompanyAccount } from 'services/accounts'
import { ConvertableTo } from 'types/Gift'
import {
  copyProgramAcceptanceLink,
  getDateTimeString,
  logEvent,
  makePlural,
  numToDollars,
} from 'utils'
import { ProductsSummary } from '../../features/ProductVariants/ProductsSummary'
import { CoreValuesTags } from './components/CoreValuesTags'
import ScheduleForm from './components/ScheduleForm'
import { useFormatDraftProgram } from './hooks/useFormatDraftProgram'
import { getRewardMetadata } from './utils/program-gifts'
import {
  createProgram,
  getProgramEndsOnFromTemplateExpiry,
  getRewardQuantity,
  removeSavedProgramImageQuery,
} from './utils/programs'
import { getRewardSendDisabledAlert } from './utils/uiUtils'

function ProductCollectionSummary({
  productCollectionId,
}: {
  productCollectionId: string
}) {
  const { collection } = useCollectionById({
    id: productCollectionId,
  })

  if (!collection) return null

  return (
    <ProductCollectionCard productCollection={collection}>
      <ProductCollectionCard.Details />
    </ProductCollectionCard>
  )
}

export function Review() {
  const program = useContext(ProgramContext)
  const { orgId, userId } = useIds()
  const theme = useTheme()
  const { isManager, isAdmin } = useIndividualRole()
  const navigate = useNavigate()
  const individual = useContext(IndividualContext)
  const org = useContext(OrgContext)
  const template = useContext(TemplateContext)
  const {
    setIsSendingReward,
    isSendingReward,
  }: {
    setIsSendingReward: (isSending: boolean) => void
    isSendingReward: boolean
  } = useOutletContext() // Tells outlet not to show empty state after we convert program from a draft but before we navigate to the program page

  const { defaultColor } = useDefaultOrgColors()
  const { id: programId, predefinedAddress } = program

  const [futureSendDate, setFutureSendDate] = useState<Dayjs | undefined>()

  const [viewReceipt, setViewReceipt] = useState(false)
  const [displayIndividuals, setDisplayIndividuals] = useState<Individual[]>([])
  const [showInsufficentBalanceModal, setShowInsufficentBalanceModal] =
    useState(false)

  const incrementRecipientsToDisplayCount = 10
  const [recipientsToDisplayCount, setRecipientsToDisplayCount] = useState(
    incrementRecipientsToDisplayCount
  )

  const {
    selectedAccount,
    setSelectedAccount,
    isLoadingSave,
    hasLoadedDraftProgram,
    recipientEmails,
    recipientIndividualIds,
    handleProgramSave,
    recipientFilters,
  } = useFormatDraftProgram({ program })

  const canCreateInvoices = useCanCreateAccountInvoices()

  const gift = program?.gift
  const {
    isGift,
    isSwag,
    isSwagGift,
    isSingleProduct,
    isSwagCollection,
    isGiftCollection,
    isZeroDollarBudget,
  } = getRewardMetadata(program)

  useEffect(() => {
    if (recipientIndividualIds.length > 0) {
      ListNonRemovedIndividualsByIds({
        individualIds: recipientIndividualIds.slice(
          0,
          recipientsToDisplayCount
        ),
        orgId,
      }).then(res => {
        setDisplayIndividuals(res)
      })
    }
  }, [orgId, recipientIndividualIds, recipientsToDisplayCount])

  const { sendableAccounts } = useSendableAccounts()

  const recipientTotal = recipientEmails.length + recipientIndividualIds.length

  const quantity = getRewardQuantity({
    recipientCount: recipientTotal,
    isDirectMail: false,
  })

  const giftProductIds = useMemo(() => {
    if (gift?.productIds) return gift?.productIds
    return []
  }, [gift?.productIds])

  const { productVariants: selectedProductVariants } =
    useListAllProductVariantsByProductIds({
      productIds: giftProductIds,
    })

  const noRecipients = !quantity

  const stepCompletedInfo = {
    step: RewardSteps.REVIEW,
    orgId,
    userId,
    programId: program.id,
    ...program,
  }
  const sendEmailNow = !futureSendDate

  let disableSubmitButton = !selectedAccount

  const { unallocatedFunds } = useAccountBalances(selectedAccount?.id)

  const checkoutWithStripe = useMemo(() => {
    const nonActiveOrg =
      org.subscriptionStatus !== Organization_SubscriptionStatus.active

    const createAccountAndCheckout = isManager && !unallocatedFunds

    if (nonActiveOrg) {
      return false
    }
    if (isAdmin) {
      return false
    }
    if (createAccountAndCheckout) {
      return true
    }

    const adminThreshold = 0
    const managerThreshold = Math.min(program.budget * quantity, 100000)

    const threshold = isManager ? managerThreshold : adminThreshold

    const selAcctIsLessThanThreshold = unallocatedFunds < threshold
    if (selAcctIsLessThanThreshold) {
      return selAcctIsLessThanThreshold
    }

    return false
  }, [
    org.subscriptionStatus,
    isManager,
    unallocatedFunds,
    isAdmin,
    program.budget,
    quantity,
  ])

  let submitButtonLabel = 'Send now'
  let submitButtonIcon: JSX.Element | null = <SendMessageIcon />

  if (checkoutWithStripe) {
    submitButtonLabel = 'Continue'
    submitButtonIcon = <SendMessageIcon />
    disableSubmitButton = noRecipients || isLoadingSave || !canCreateInvoices
  } else if (futureSendDate) {
    submitButtonLabel = 'Schedule'
    submitButtonIcon = <TimeIcon />
  } else if (noRecipients) {
    submitButtonLabel = 'Create'
    submitButtonIcon = <PlusIcon />
  }
  if (!disableSubmitButton) {
    disableSubmitButton = isZeroDollarBudget
  }
  const disabledCTAAlertText = getRewardSendDisabledAlert({
    isZeroDollarBudget,
    checkoutWithStripe,
    noRecipients,
    invoiceCreationDisabled: !canCreateInvoices,
  })

  const handleProgramActivate = async () => {
    setIsSendingReward(true)
    removeSavedProgramImageQuery({ programId })

    const endsOn = program?.endsOn
      ? program.endsOn
      : getProgramEndsOnFromTemplateExpiry({
          templateExpiry: template?.expiry,
        })

    const activeProgram = new Program({
      ...program,
      status: ProgramStatus.active,
      items: undefined,
      recipientFilters,
      endsOn,
      draftData: undefined,
    })

    // Add program to org and add recipients to program
    const addedProgram = await createProgram({
      org,
      program: activeProgram,
      recipientEmails,
      recipientIds: recipientIndividualIds,
      sendDate: futureSendDate,
      senderEmail: individual?.email,
    })
    if (!addedProgram?.id) {
      toaster.warning(`Error ${programId ? 'updating' : 'creating'} reward`)
      captureMessage(
        `Error ${programId ? 'updating' : 'creating'} program`,
        'error'
      )
      setIsSendingReward(false)
    }
    if (!addedProgram) return

    if (sendEmailNow) {
      callFunction('mailjet-SendBulk', {
        orgId,
        programId: addedProgram.id,
        logo: org.logoUrl || PERKUP_PRIMARY_LOGO,
        banner: program?.email?.banner,
        title: program?.email?.title,
        programName: program?.email?.title,
        programNote: program?.note,
        fromName: program?.email?.fromName,
        peopleIds: recipientIndividualIds,
        emails: recipientEmails,
        color: defaultColor,
      })
    }

    logEvent(PROGRAM_STEP_COMPLETED, {
      ...stepCompletedInfo,
    })

    copyProgramAcceptanceLink(addedProgram.id)
    navigate(
      {
        pathname: `${DEFAULT_ROUTES.ORGANIZATION.REWARDS.ROOT}/${addedProgram.id}`,
        search: createSearchParams({
          [SURVEY]: 'true',
        }).toString(),
      },
      {
        state: { confetti: true },
      }
    )
    setIsSendingReward(false)
  }
  const handleProgramCheckout = async () => {
    setIsSendingReward(true)
    const needsAccount = !sendableAccounts.length

    if (needsAccount) {
      const newAccount = await createDefaultCompanyAccount({
        individual,
        orgId,
      })
      if (newAccount?.id) {
        const updatedProgram = cloneDeep(program)
        updatedProgram.accountId = newAccount.id
        await updateProgram({
          program: updatedProgram,
          orgId,
          programId,
        })
      }
    }

    logEvent(PROGRAM_STEP_COMPLETED, {
      ...stepCompletedInfo,
    })

    navigate(
      `${DEFAULT_ROUTES.ORGANIZATION.REWARDS.NEW_REWARD}/${programId}/${CHECKOUT}`
    )
    setIsSendingReward(false)
  }

  const renderedIndividualsCount = displayIndividuals.length

  const recipientDisplayTags = useMemo(() => {
    // Make sure we don't display more recipients than we intend to
    const emailsToDisplay = recipientEmails.slice(
      0,
      recipientsToDisplayCount - renderedIndividualsCount
    )

    const tags = [...emailsToDisplay]
    const leftover = recipientTotal - tags.length - renderedIndividualsCount
    if (leftover > 0) {
      tags.push(`+ ${leftover} recipients...`)
    }
    return tags
  }, [
    recipientEmails,
    recipientsToDisplayCount,
    renderedIndividualsCount,
    recipientTotal,
  ])

  const numberTotalCost = program.budget * quantity

  const allGiftProductsPrepaid =
    !isEmpty(selectedProductVariants) &&
    selectedProductVariants.every(
      pv => pv?.sourceType === ProductVariant_SourceType.fullPrepaid
    )

  const allProductsPrepaid = allGiftProductsPrepaid

  const priceExceedsBalance =
    !!selectedAccount &&
    numberTotalCost > Number(selectedAccount.balance) &&
    !allProductsPrepaid

  const showInsufficientBalanceWarning = priceExceedsBalance && isAdmin

  const redeemableQuantity = gift?.redeemableQuantity

  const rewardTypeDisplayString = isGift ? 'gift' : 'reward'

  if (!hasLoadedDraftProgram) return <PerkLoader />

  const convertableTo = program?.gift?.convertableTo

  const canSwapForCash = convertableTo?.includes(ConvertableTo.cash)
  const canSwapForGift = convertableTo?.includes(ConvertableTo.gift)

  const endDate = getDateTimeString(program?.endsOn)
  const expiryLabel = endDate ? `Expires ${endDate}` : ''

  const estimatedDisplayTotal =
    numToDollars(Number(program.budget) * quantity) ?? ''

  return (
    <Pane
      maxWidth={918}
      width="100%"
      display="flex"
      flexDirection="column"
      alignItems="center"
      gap={32}
      margin="auto"
      paddingBottom={64}
    >
      <Modal
        open={showInsufficentBalanceModal}
        title={`${selectedAccount?.name} has insufficient funds`}
        onCancel={() => setShowInsufficentBalanceModal(false)}
        onOk={() => {
          setShowInsufficentBalanceModal(false)
          handleProgramActivate()
        }}
        okText={submitButtonLabel}
        cancelText="Change account"
        afterClose={() => setShowInsufficentBalanceModal(false)}
        destroyOnClose
      >
        <Pane marginY={16}>
          <Text>{`Your total budget exceeds ${selectedAccount?.name}. If the balance hits $0, recipients will be declined.`}</Text>
        </Pane>
      </Modal>
      <Heading size={800}>Review</Heading>
      <Pane display="flex" flexDirection="column" gap={32}>
        <Pane display="flex" flexDirection="column" gap={32}>
          <Pane display="flex" gap={32} alignItems="center">
            <Pane height={128}>
              <Image
                preview
                style={{
                  objectFit: 'contain',
                  borderRadius: 8,
                  border: `1px solid ${theme.colors.gray100}`,
                }}
                height="100%"
                src={program.email?.banner}
              />
            </Pane>
            <Pane display="flex" flexDirection="column" maxWidth={384}>
              <Flex vertical gap={16}>
                <Pane>
                  <Heading size={600} marginBottom={8}>
                    {program?.email?.title}
                  </Heading>
                  <Flex vertical gap={4}>
                    <Text> {parse(program?.note || '')}</Text>
                    <Text fontStyle="italic">{program?.email?.fromName}</Text>
                  </Flex>
                </Pane>
                <CoreValuesTags
                  selectedCoreValues={program.coreValues}
                  disabled
                  showTitle={false}
                />
              </Flex>
            </Pane>
          </Pane>

          <Pane border="muted" />

          <Flex vertical gap={16}>
            {isSwagCollection && (
              <ProductCollectionSummary
                productCollectionId={gift?.productCollectionId!}
              />
            )}
            {isSwagGift && (
              <ProductsSummary
                productIds={gift?.productIds ?? []}
                designIds={gift?.canvasDesignIds ?? []}
              />
            )}
            {isSingleProduct && gift?.productVariantId && (
              <ProductSummary productVariantId={gift?.productVariantId} />
            )}
            {isGiftCollection && (
              <ItemInfo
                name={gift?.title}
                image={gift?.imageUrl}
                imageSize={80}
                imageFit="cover"
                amount={program.budget}
                amountSuffix="/person"
              />
            )}

            <Flex vertical gap={8}>
              {!gift && (
                <Text size={600}>
                  <BalanceText amount={program.budget} size={600} /> per person
                </Text>
              )}
              {isSwag && (
                <>
                  {redeemableQuantity && (
                    <Text>{`${redeemableQuantity} ${makePlural(
                      'item',
                      redeemableQuantity
                    )} per recipient`}</Text>
                  )}
                  {!redeemableQuantity && (
                    <BalanceText
                      amount={program.budget}
                      amountSuffix=" budget per recipient"
                    />
                  )}
                </>
              )}

              <Flex gap={8}>
                {canSwapForGift && <Tag color="green">Swappable</Tag>}
                {canSwapForCash && (
                  <Tag color="gold">Convert PerkUp dollars</Tag>
                )}
              </Flex>
              {expiryLabel && (
                <Pane display="flex" gap={8} marginTop={8}>
                  <HourglassOutlined color="blue" />
                  <Text>{expiryLabel}</Text>
                </Pane>
              )}
            </Flex>
            {predefinedAddress && (
              <>
                <AddressDisplay address={predefinedAddress} />
                <Alert
                  type="info"
                  showIcon
                  message="All items will be shipped to this address."
                  style={{ width: 'fit-content' }}
                />
              </>
            )}
          </Flex>

          <Pane border="muted" />

          <Pane display="flex" flexDirection="column" gap={16}>
            <Text>
              {`${recipientTotal}
              ${makePlural('recipient', recipientTotal)}`}
            </Text>
            <Pane
              display="grid"
              gridTemplateColumns="repeat(auto-fill, minmax(328px, 1fr))"
              gap={8}
              alignItems="center"
            >
              {displayIndividuals.map(individual => {
                return (
                  <IndividualAvatar
                    key={individual.id}
                    individual={individual}
                    hideRoleBadge
                  />
                )
              })}
              <Flex vertical gap={8}>
                {/* Only show email tags once all individuals are loaded */}
                {recipientIndividualIds.length === renderedIndividualsCount && (
                  <Flex vertical gap={4}>
                    {recipientDisplayTags.map(tag => (
                      <Text key={tag}>{tag}</Text>
                    ))}
                  </Flex>
                )}
                {recipientsToDisplayCount < recipientTotal && (
                  <Button
                    type="text"
                    style={{ width: 'fit-content' }}
                    onClick={() =>
                      setRecipientsToDisplayCount(
                        current => current + incrementRecipientsToDisplayCount
                      )
                    }
                  >
                    Show more
                  </Button>
                )}
              </Flex>
            </Pane>
          </Pane>
        </Pane>

        <Pane display="flex " flexDirection="column" gap={8}>
          <Flex vertical>
            <Tooltip title="View price breakdown">
              <Pane
                display="flex"
                gap={8}
                alignItems="center"
                width="fit-content"
                cursor="pointer"
                onClick={() => setViewReceipt(!viewReceipt)}
              >
                <Button
                  type="text"
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                  icon={viewReceipt ? <MinusOutlined /> : <PlusOutlined />}
                />

                <Strong>Total: {estimatedDisplayTotal}</Strong>
              </Pane>
            </Tooltip>
            <Paragraph color="muted">
              {`You're only charged for redeemed ${rewardTypeDisplayString}s.`}
            </Paragraph>
          </Flex>

          {viewReceipt && (
            <Pane marginY={16}>
              <PriceBreakdownV2
                receiptInfo={[
                  {
                    label: 'Total per recipient',
                    amount: numToDollars(program.budget) || '',
                  },
                  {
                    label: `x ${quantity} ${makePlural('recipient', quantity)}`,
                    amount: `x ${quantity}`,
                  },
                  {
                    label: 'Total amount',
                    amount: estimatedDisplayTotal,
                  },
                ]}
              />
            </Pane>
          )}

          <Pane
            display="flex"
            flexDirection="column"
            gap={16}
            width="fit-content"
            marginTop={8}
          >
            {showInsufficientBalanceWarning && (
              <Alert
                type="warning"
                message={`${capitalize(
                  rewardTypeDisplayString
                )} budget exceeds account balance.`}
                showIcon
              />
            )}
          </Pane>
          {disableSubmitButton && disabledCTAAlertText && (
            <Alert type="warning" message={disabledCTAAlertText} showIcon />
          )}
        </Pane>
      </Pane>
      <PerkActionFooter>
        <Pane display="flex" gap={16}>
          <Pane marginRight={32}>
            {hasLoadedDraftProgram && (
              <AccountBalanceForm
                setSelectedAccount={value => {
                  setSelectedAccount(value)
                  if (!value) return
                  handleProgramSave({
                    accountId: value.id,
                  })
                }}
                selectedAccount={selectedAccount}
                size="large"
              />
            )}
          </Pane>
          <ScheduleForm
            futureSendDate={futureSendDate}
            setFutureSendDate={value => {
              setFutureSendDate(value)
            }}
            disabled={noRecipients}
          />
          <Button
            size="large"
            type="primary"
            onClick={() => {
              if (checkoutWithStripe) {
                handleProgramCheckout()
              } else if (priceExceedsBalance) {
                setShowInsufficentBalanceModal(true)
              } else {
                handleProgramActivate()
              }
            }}
            disabled={disableSubmitButton}
            loading={isSendingReward}
            style={{
              width: 248,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: 8,
            }}
          >
            {submitButtonLabel}
            {submitButtonIcon}
          </Button>
        </Pane>
      </PerkActionFooter>
    </Pane>
  )
}
