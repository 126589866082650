import { Image, Tooltip } from 'antd'
import { Button, Pane, Text } from 'evergreen-ui'
import { isMobile } from 'react-device-detect'

export function CategoryButton({
  withName = true,
  name,
  icon,
  onClick,
  ...other
}: {
  withName?: boolean
  name?: string
  icon: string
  onClick: () => void
}) {
  if (isMobile)
    return (
      <Button
        appearance="minimal"
        height={40}
        paddingX={8}
        paddingY={8}
        onClick={onClick}
        {...other}
      >
        <span className="py-1">
          <Image src={icon} height={16} preview={false} />
        </span>
      </Button>
    )

  if (withName)
    return (
      <Button
        appearance="minimal"
        iconBefore={<Image src={icon} height={28} preview={false} />}
        height={48}
        paddingX={16}
        onClick={onClick}
        {...other}
      >
        <Text color="#084B8A">{name}</Text>
      </Button>
    )

  return (
    <Tooltip title={name}>
      <Pane onClick={onClick}>
        <Image src={icon} height={22} preview={false} {...other} />
      </Pane>
    </Tooltip>
  )
}
