import { PartialMessage } from '@bufbuild/protobuf'
import { captureException } from '@sentry/react'
import { UploadFile } from 'antd'
import { UploadChangeParam } from 'antd/es/upload'
import {
  addDoc,
  collection,
  deleteDoc,
  deleteField,
  doc,
  getFirestore,
  setDoc,
  updateDoc,
} from 'firebase/firestore'
import {
  deleteObject,
  getMetadata,
  getStorage,
  ref,
  uploadBytes,
} from 'firebase/storage'
import { Organization } from 'gen/perkup/v1/organization_pb'
import { ShippingAddress } from 'gen/perkup/v1/root_user_pb'
import { converter } from 'utils/firestore'
import { formatDownloadUrl } from 'utils/images'
import { toSentry } from 'utils/sentry'

export async function completeOnboarding({
  id,
  collectionPath,
}: {
  id: string
  collectionPath: string
}) {
  const db = getFirestore()
  const docRef = doc(db, `${collectionPath}/${id}`)
  updateDoc(docRef, {
    'onboarding.complete': true,
  }).catch(error => {
    console.error(error)
    captureException(toSentry(error), {
      contexts: {
        completeOnboarding: {
          id,
          collectionPath,
        },
      },
    })
  })
}

export function updateOrgSetting({
  orgId,
  key,
  value,
}: {
  orgId: string
  key: string
  value: boolean
}) {
  const db = getFirestore()
  const docRef = doc(db, `organizations/${orgId}`).withConverter(
    converter(Organization)
  )

  const updateOrgSettings: PartialMessage<Organization> = {
    settings: { [key]: value || deleteField() },
  }

  setDoc(docRef, updateOrgSettings, { merge: true }).catch(error => {
    console.error(error)
    captureException(toSentry(error), {
      contexts: {
        updateOrgSetting: {
          orgId,
          key,
          value,
        },
      },
    })
  })
}

export function updateOrgCoreValues({
  orgId,
  coreValues,
}: {
  orgId: string
  coreValues: string[]
}) {
  const db = getFirestore()
  const docRef = doc(db, `organizations/${orgId}`).withConverter(
    converter(Organization)
  )

  const updateOrg: PartialMessage<Organization> = {
    coreValues,
  }

  setDoc(docRef, updateOrg, { merge: true }).catch(e => {
    console.error(e)
    captureException(toSentry(e), {
      contexts: {
        updateOrgCoreValues: {
          orgId,
          coreValues,
        },
      },
    })
  })
}

export async function updateOrgStripeConnectAccountId({
  orgId,
  stripeConnectAccountId,
}: {
  orgId: string
  stripeConnectAccountId: string
}) {
  const db = getFirestore()
  const docRef = doc(db, `organizations/${orgId}`).withConverter(
    converter(Organization)
  )

  const updateOrg: PartialMessage<Organization> = {
    stripeConnectAccountId,
  }

  await setDoc(docRef, updateOrg, { merge: true }).catch(e => {
    console.error(e)
    captureException(toSentry(e), {
      contexts: {
        updateOrgStripeConnectAccountId: {
          orgId,
          stripeConnectAccountId,
        },
      },
    })
  })
}

export async function uploadOrgAsset({
  orgId,
  upload,
  field,
}: {
  orgId: string
  upload: UploadChangeParam<UploadFile<any>>
  field: 'logoUrl' | 'swagBannerUrl'
}) {
  try {
    if (!upload.file.originFileObj) return
    const logoRef = ref(getStorage(), `${orgId}/${upload.file.name}`)
    await uploadBytes(logoRef, upload.file.originFileObj)
    const url = await formatDownloadUrl(logoRef)

    const db = getFirestore()
    const docRef = doc(db, `organizations/${orgId}`).withConverter(
      converter(Organization)
    )
    setDoc(
      docRef,
      {
        [field]: url,
      },
      { merge: true }
    )
  } catch (error) {
    console.error(error)
    captureException(toSentry(error), {
      contexts: {
        uploadOrgLogo: {
          orgId,
        },
      },
    })
  }
}

export async function uploadOrgImage({
  orgId,
  upload,
  directory,
}: {
  orgId: string
  upload: UploadChangeParam<UploadFile<any>>
  directory: string
}) {
  try {
    if (!upload.file.originFileObj) return undefined
    const customImagesRef = ref(
      getStorage(),
      `${orgId}/${directory}/${upload.file.name}`
    )
    await uploadBytes(customImagesRef, upload.file.originFileObj)
    const url = await formatDownloadUrl(customImagesRef)
    const metaData = await getMetadata(customImagesRef)

    const bucketImage = {
      url,
      metaData,
    }
    return bucketImage
  } catch (error) {
    console.error(error)
    captureException(toSentry(error), {
      contexts: {
        uploadUserPfp: {
          orgId,
        },
      },
    })
    return undefined
  }
}

export async function deleteOrgImage({
  orgId,
  directory,
  imageName,
}: {
  orgId: string
  directory: string
  imageName: string
}) {
  try {
    const customImagesRef = ref(
      getStorage(),
      `${orgId}/${directory}/${imageName}`
    )
    await deleteObject(customImagesRef)
  } catch (error) {
    console.error(error)
    captureException(toSentry(error), {
      contexts: {
        uploadUserPfp: {
          orgId,
        },
      },
    })
  }
}

export async function createOrgShippingAddress({
  orgId,
  shippingAddress,
}: {
  orgId: string
  shippingAddress: ShippingAddress
}) {
  try {
    const db = getFirestore()
    const colRef = collection(
      db,
      `organizations/${orgId}/shippingAddresses`
    ).withConverter(converter(ShippingAddress))
    const docRef = await addDoc(colRef, shippingAddress)
    return docRef
  } catch (error) {
    console.error(error)
    captureException(toSentry(error), {
      contexts: {
        createOrgShippingAddress: {
          orgId,
          shippingAddress,
        },
      },
    })
  }
  return undefined
}

export async function updateOrgShippingAddress({
  orgId,
  shippingAddress,
}: {
  orgId: string
  shippingAddress: ShippingAddress
}) {
  try {
    const db = getFirestore()
    const docRef = doc(
      db,
      `organizations/${orgId}/shippingAddresses/${shippingAddress.id}`
    ).withConverter(converter(ShippingAddress))
    await setDoc(docRef, shippingAddress)
  } catch (error) {
    console.error(error)
    captureException(toSentry(error), {
      contexts: {
        updateOrgShippingAddress: {
          orgId,
          shippingAddress,
        },
      },
    })
  }
}

export async function removeOrgShippingAddress({
  orgId,
  addressId,
}: {
  orgId: string
  addressId: string
}) {
  try {
    const db = getFirestore()
    const docRef = doc(
      db,
      `organizations/${orgId}/shippingAddresses/${addressId}`
    )
    await deleteDoc(docRef)
  } catch (error) {
    console.error(error)
    captureException(toSentry(error), {
      contexts: {
        removeOrgShippingAddress: {
          orgId,
          addressId,
        },
      },
    })
  }
}
