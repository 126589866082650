import { getAnalytics, logEvent as fbLogEvent } from 'firebase/analytics'

export function logEvent(
  event: string,
  properties?: Record<string, any> & {
    orgId: string | undefined
    userId: string | undefined
  }
) {
  // Segment
  window.analytics?.track(event, properties)
  // Google Analytics via Firebase
  fbLogEvent(getAnalytics(), event, { items: [properties] })
}
