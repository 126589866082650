// @generated by protoc-gen-es v1.6.0 with parameter "target=ts"
// @generated from file shopify/catalog.proto (package shopify, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";

/**
 * ImageInput specifies the input fields for an image.
 *
 * @generated from message shopify.ImageInput
 */
export class ImageInput extends Message<ImageInput> {
  /**
   * A globally-unique identifier.
   *
   * @generated from field: optional string id = 1;
   */
  id?: string;

  /**
   * A word or phrase to share the nature or contents of an image.
   *
   * @generated from field: optional string alt_text = 2;
   */
  altText?: string;

  /**
   * The URL of the image. May be a staged upload URL.
   *
   * @generated from field: optional string src = 3;
   */
  src?: string;

  constructor(data?: PartialMessage<ImageInput>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "shopify.ImageInput";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 2, name: "alt_text", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 3, name: "src", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ImageInput {
    return new ImageInput().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ImageInput {
    return new ImageInput().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ImageInput {
    return new ImageInput().fromJsonString(jsonString, options);
  }

  static equals(a: ImageInput | PlainMessage<ImageInput> | undefined, b: ImageInput | PlainMessage<ImageInput> | undefined): boolean {
    return proto3.util.equals(ImageInput, a, b);
  }
}

/**
 * @generated from message shopify.ProductInput
 */
export class ProductInput extends Message<ProductInput> {
  /**
   * The description of the product, complete with HTML formatting.
   *
   * @generated from field: optional string description_html = 1;
   */
  descriptionHtml?: string;

  /**
   * Specifies the product to update in productUpdate or creates a new product if absent in productCreate.
   *
   * @generated from field: optional string id = 17;
   */
  id?: string;

  /**
   * The images to associate with the product.
   *
   * @generated from field: repeated shopify.ImageInput images = 18;
   */
  images: ImageInput[] = [];

  /**
   * The title of the product.
   *
   * @generated from field: optional string title = 56;
   */
  title?: string;

  constructor(data?: PartialMessage<ProductInput>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "shopify.ProductInput";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "description_html", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 17, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 18, name: "images", kind: "message", T: ImageInput, repeated: true },
    { no: 56, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ProductInput {
    return new ProductInput().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ProductInput {
    return new ProductInput().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ProductInput {
    return new ProductInput().fromJsonString(jsonString, options);
  }

  static equals(a: ProductInput | PlainMessage<ProductInput> | undefined, b: ProductInput | PlainMessage<ProductInput> | undefined): boolean {
    return proto3.util.equals(ProductInput, a, b);
  }
}

/**
 * @generated from message shopify.UpdateCatalogProductRequest
 */
export class UpdateCatalogProductRequest extends Message<UpdateCatalogProductRequest> {
  /**
   * @generated from field: shopify.ProductInput product_input = 1;
   */
  productInput?: ProductInput;

  constructor(data?: PartialMessage<UpdateCatalogProductRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "shopify.UpdateCatalogProductRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "product_input", kind: "message", T: ProductInput },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateCatalogProductRequest {
    return new UpdateCatalogProductRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateCatalogProductRequest {
    return new UpdateCatalogProductRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateCatalogProductRequest {
    return new UpdateCatalogProductRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateCatalogProductRequest | PlainMessage<UpdateCatalogProductRequest> | undefined, b: UpdateCatalogProductRequest | PlainMessage<UpdateCatalogProductRequest> | undefined): boolean {
    return proto3.util.equals(UpdateCatalogProductRequest, a, b);
  }
}

