import { useEffect, useState } from 'react'
import { getIndividualIds } from 'services/individuals'

export function useIndividualIds({
  labels,
  statuses,
}: {
  labels?: { [key: string]: string }
  statuses: string[]
}) {
  const [individualIds, setIndividualIds] = useState<string[]>([])
  const [hasLoaded, setHasLoaded] = useState(false)

  useEffect(() => {
    getIndividualIds({ labels, statuses })
      .then(({ ids }) => setIndividualIds(ids))
      .finally(() => setHasLoaded(true))
  }, [labels, statuses])

  return { individualIds, hasLoaded }
}
