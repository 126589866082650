import { ConnectError } from '@connectrpc/connect'

export function toSentry(error: any): any {
  if (error.name === 'FirebaseError' && error.cause === undefined) {
    // FirestoreError contains `.stack` holding the firestore rules stack trace.
    // Must wrap the Error to allow sentry to attach the browser stacktrace.
    const e = new Error(`${error.code}`, {
      cause: error,
    })
    e.name = error.name

    // Remove ` at toSentry() ` from stack trace
    const stackLines = e.stack?.split('\n')
    stackLines?.splice(1, 1)
    e.stack = stackLines?.join('\n')
    return e
  }
  if (error instanceof ConnectError) {
    const e = new Error(`${error.message}`, {
      cause: error,
    })
    e.name = error.name

    // Remove ` at toSentry() ` from stack trace
    const stackLines = e.stack?.split('\n')
    stackLines?.splice(1, 1)
    e.stack = stackLines?.join('\n')
    return e
  }
  return error
}
