export function insertElementIf(condition: boolean, element: any) {
  return condition ? [element] : []
}

interface KeyValuePair<TValue, TKey> {
  key: TKey
  value: TValue
}

/**
 * Filters an array of key-value pairs, removing pairs with null or undefined keys.
 *
 * @template TValue - The type of the value in the key-value pair.
 * @template TKey - The type of the key in the key-value pair. Can be null, undefined, or any type that extends `keyof any`.
 *
 * @param {KeyValuePair<TValue, TKey>[]} arr - The array of key-value pairs to filter.
 * @param {boolean} [filterValues=false] - If true, the function also filters out pairs with null or undefined values.
 *
 * @returns {KeyValuePair<TValue, NonNullable<TKey>>[]}
 * - If `filterValues` is false or not provided, returns an array of key-value pairs with non-nullable keys.
 * @returns {KeyValuePair<NonNullable<TValue>, NonNullable<TKey>>[]}
 * - If `filterValues` is true, returns an array of key-value pairs with both non-nullable keys and values.
 *
 * @example
 * // Basic usage without filtering values
 * const pairs = [
 *   { key: 'a', value: 1 },
 *   { key: null, value: 2 },
 *   { key: 'b', value: null },
 * ];
 * const result = notNullKeyValuePairs(pairs);
 * // result: [{ key: 'a', value: 1 }, { key: 'b', value: null }]
 *
 * @example
 * // Filtering both keys and values
 * const pairs = [
 *   { key: 'a', value: 1 },
 *   { key: null, value: 2 },
 *   { key: 'b', value: null },
 * ];
 * const result = notNullKeyValuePairs(pairs, true);
 * // result: [{ key: 'a', value: 1 }]
 */
export function notNullKeyValuePairs<
  TValue,
  TKey extends keyof any | null | undefined = string | null | undefined,
>(arr: KeyValuePair<TValue, TKey>[]): KeyValuePair<TValue, NonNullable<TKey>>[]

export function notNullKeyValuePairs<
  TValue,
  TKey extends keyof any | null | undefined = string | null | undefined,
>(
  arr: KeyValuePair<TValue, TKey>[],
  filterValues: true
): KeyValuePair<NonNullable<TValue>, NonNullable<TKey>>[]

export function notNullKeyValuePairs<
  TValue,
  TKey extends keyof any | null | undefined = string | null | undefined,
>(
  arr: KeyValuePair<TValue, TKey>[],
  filterValues?: boolean
): KeyValuePair<TValue, NonNullable<TKey>>[]

// implementation
export function notNullKeyValuePairs<
  TValue,
  TKey extends keyof any | null | undefined = string | null | undefined,
>(arr: KeyValuePair<TValue, TKey>[], filterValues: boolean = false) {
  return arr.filter(({ key, value }) => {
    const isKeyValid = key !== null && key !== undefined
    const isValueValid = filterValues
      ? value !== null && value !== undefined
      : true
    return isKeyValid && isValueValid
  }) as any
}

export function arrayToObject<
  TValue,
  TKey extends keyof any = string,
  TRes = TValue,
>(
  array: KeyValuePair<TValue, TKey>[],
  {
    parseFunction = (value: TValue): TRes => value as unknown as TRes,
  }: {
    parseFunction?: (value: TValue) => TRes
  } = {}
): Record<TKey, TRes> {
  return array.reduce(
    (acc, curr) => {
      acc[curr.key] = parseFunction(curr.value)
      return acc
    },
    {} as Record<TKey, TRes>
  )
}
