import { firebaseConfig, firebaseConfigTest } from 'constants/firebaseConfig'
import * as HOSTS from 'constants/hosts'
import { getAnalytics } from 'firebase/analytics'
import { initializeApp } from 'firebase/app'
import 'firebase/app-check'
import { connectAuthEmulator, getAuth } from 'firebase/auth'
import {
  connectFirestoreEmulator,
  FirestoreSettings,
  initializeFirestore,
} from 'firebase/firestore'
import { connectFunctionsEmulator, getFunctions } from 'firebase/functions'
import { connectStorageEmulator, getStorage } from 'firebase/storage'

const { hostname } = window.location
const isProdHost = hostname === HOSTS.PROD_HOSTNAME

const firestoreSettings: FirestoreSettings = {
  experimentalAutoDetectLongPolling: true,
}

// Initialize Firebase
if (isProdHost) {
  // web9
  const firebaseApp = initializeApp(firebaseConfig)
  getAnalytics(firebaseApp) // only on prod
  getAuth(firebaseApp)
  initializeFirestore(firebaseApp, firestoreSettings)
  getFunctions(firebaseApp)
  getStorage(firebaseApp)
} else if (hostname === HOSTS.LOCALHOST) {
  // web9
  const firebaseApp = initializeApp(firebaseConfig)
  getAnalytics(firebaseApp)
  const auth = getAuth(firebaseApp)
  const firestore = initializeFirestore(firebaseApp, firestoreSettings)
  const functions = getFunctions(firebaseApp)
  const storage = getStorage(firebaseApp)
  // Use Firestore Emulator Auth, Firestore and Functions if on localhost
  connectAuthEmulator(auth, 'http://localhost:9099/')
  connectFirestoreEmulator(firestore, HOSTS.LOCALHOST, 8080)
  connectFunctionsEmulator(functions, HOSTS.LOCALHOST, 5001)
  connectStorageEmulator(storage, HOSTS.LOCALHOST, 9199)
} else {
  // Use test config if none other
  // web9
  initializeApp(firebaseConfigTest)
}
