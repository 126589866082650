export const PERKUP_FEE_MULTIPLIER = 0.05
export const STRIPE_FEE_MULTIPLIER = 0.03
export const AMAZON_TAX_RATE = 0.085

export const MANUAL_GIFT_TAX_RATE = 0.1

export const AMAZON_PERKUP_FEE_MULTIPLIER = PERKUP_FEE_MULTIPLIER
export const SHOPIFY_PERKUP_FEE_MULTIPLIER = 0
export const MANUAL_PERKUP_FEE_MULTIPLIER = PERKUP_FEE_MULTIPLIER

export const MIN_PERSONAL_FUNDS_DEPOSIT = 100
export const MAX_PERSONAL_FUNDS_BALANCE = 200000
export const EMBEDDED_CHECKOUT_THRESHOLD = 10000
export const DEFAULT_PERSONAL_FUNDS_DEPOSIT = 2500

export const MIN_PROGRAM_BUDGET_AMOUNT = 2500
export const DEFAULT_PROGRAM_BUDGET_AMOUNT = 5000

export const MIN_ACCOUNT_TOPUP_AMOUNT = 10
export const MIN_TOPUP_PROCESS_FEE = 300
export const DEFAULT_TOPUP_AMOUNT = 50000
export const TOPUP_MARKUP_PERCENT = 0.15
