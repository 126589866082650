import { CaretDownOutlined } from '@ant-design/icons'
import { Badge } from 'antd'
import { OrderDetails } from 'api/tinybirdCalls/types'
import { Text } from 'evergreen-ui'
import Tracking from './Tracking'

export function TrackingFulfillmentsBreakdown({
  fulfillments,
}: {
  fulfillments: OrderDetails['fulfillments']
}) {
  const numFulfillments = fulfillments.length

  if (numFulfillments === 0) {
    return (
      <Text color="muted" minWidth="max-content">
        Fulfillment in progress
      </Text>
    )
  }

  if (numFulfillments === 1) {
    const { trackingNumber, trackingUrl } = fulfillments[0]
    return !trackingNumber || !trackingUrl ? (
      <Text minWidth="max-content">Tracking not available</Text>
    ) : (
      <Tracking>
        <Tracking.Badge status="success" />
        <Tracking.Link url={trackingUrl}>{trackingNumber}</Tracking.Link>
      </Tracking>
    )
  }

  return (
    <Text display="flex" gap={8} alignItems="center">
      <Badge
        style={{ backgroundColor: '#EAEAEA', color: 'inherit' }}
        count={numFulfillments}
      />{' '}
      Fulfillments
      <CaretDownOutlined />
    </Text>
  )
}
