import { SideSheet } from 'evergreen-ui'
import { isMobile } from 'react-device-detect'
import CardDetails from './CardDetails'

export function CardSidesheet({
  isShown,
  onCloseComplete,
}: {
  isShown: boolean
  onCloseComplete: () => void
}) {
  return (
    <SideSheet
      isShown={isShown}
      onCloseComplete={onCloseComplete}
      width={isMobile ? window.innerWidth * 0.75 : 620}
      preventBodyScrolling
    >
      <CardDetails />
    </SideSheet>
  )
}
