import { Skeleton } from 'antd'
import { CarouselImageCard } from 'components/Images/CarouselImageCard'
import {
  BRANDED_IMAGE_CLICKED,
  PROGRAM_TEMPLATE_CLICKED,
} from 'constants/events'
import { OrgContext } from 'context'
import { Pane } from 'evergreen-ui'

import { defaultOccasions, OccasionType } from 'constants/newReward/occasions'
import { RewardUseCases } from 'constants/newReward/rewardUseCases'
import { DEFAULT_ROUTES } from 'constants/routes'
import { ProgramTemplate } from 'gen/perkup/v1/contentful_pb'
import { Organization_SubscriptionStatus } from 'gen/perkup/v1/organization_pb'
import { useAllBrandedImages } from 'hooks/images'
import useIds from 'hooks/useIds'
import { compact, find, isFunction, sample } from 'lodash-es'
import { getUseCaseEmailData } from 'pages/NewReward/utils/programs'
import {
  getDefaultUseCaseForPlacidData,
  getPlacidDataForBrandedImage,
} from 'pages/NewReward/utils/uiUtils'
import { useFeaturedProgramTemplates } from 'pages/Templates/hooks/useFeaturedProgramTemplates'
import { useContext } from 'react'
import { useNavigate } from 'react-router'
import { CreateRewardLocation } from 'types'
import { logEvent, shuffleArrayWithDateSeed } from 'utils'
import { getTemplateImageUrl } from 'utils/templates'
import { CustomCarousel } from './CustomCarousel'

export interface BannerClickData {
  banner: string
  occasion: OccasionType
  title?: string
  note?: string
}

function ProgramTemplateCardSkeleton() {
  return (
    <Pane
      width={208}
      height={120}
      borderRadius={8}
      marginY={4}
      marginRight={16}
      border="muted"
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
    >
      <Skeleton.Image
        style={{
          aspectRatio: '1 / 1',
          objectFit: 'cover',
        }}
        active
      />
    </Pane>
  )
}

export function ProgramTemplateSkeleton() {
  const isLoadingArray = new Array<undefined>(5).fill(undefined)
  return (
    <Pane display="flex" flexDirection="column" gap={16}>
      <Skeleton active title={{ width: 80 }} paragraph={{ rows: 0 }} />
      <CustomCarousel>
        {isLoadingArray.map((_, i) => {
          const id = i.toString()
          return <ProgramTemplateCardSkeleton key={id} />
        })}
      </CustomCarousel>
    </Pane>
  )
}

function BrandedTemplateCarouselItems({
  brandedImages,
  handleTemplateClick,
}: {
  brandedImages: string[]
  handleTemplateClick: ({ bannerData }: { bannerData: BannerClickData }) => void
}) {
  const org = useContext(OrgContext)
  const { orgId, userId } = useIds()
  const brandedCarouselItems = compact(
    brandedImages.map(image => {
      const placidData = getPlacidDataForBrandedImage({ image })
      const defaultUseCase = getDefaultUseCaseForPlacidData(placidData)

      const templateId = placidData?.uuid

      const defaultEmailData = getUseCaseEmailData({
        rewardUseCase: defaultUseCase?.label,
      })
      const defaultTitle = defaultEmailData?.title
      const defaultNote = defaultEmailData?.note

      if (!defaultUseCase) return null

      const defaultOccasion = find(defaultOccasions, occ =>
        occ.useCases
          .map(uc => RewardUseCases[uc]?.label)
          .filter(Boolean)
          .some(uc => uc === defaultUseCase.label)
      )
      if (!defaultOccasion) return null

      const handleImageCTA = () => {
        logEvent(BRANDED_IMAGE_CLICKED, {
          templateId,
          orgName: org?.name,
          orgSubscriptionStatus: org?.subscriptionStatus
            ? Organization_SubscriptionStatus[org.subscriptionStatus]
            : null,
          useCase: defaultUseCase?.label,
          occasion: defaultOccasion.occasion,
          orgId,
          userId,
        })
        handleTemplateClick({
          bannerData: {
            occasion: defaultOccasion,
            banner: image,
            note: defaultNote,
            title: defaultTitle,
          },
        })
      }

      return (
        <CarouselImageCard
          key={image}
          imageUrl={image}
          onClick={handleImageCTA}
        />
      )
    })
  )

  return brandedCarouselItems
}

export function ProgramTemplateCarouselItems({
  programTemplates,
  onTemplateSelect,
  templatesFilter,
}: {
  programTemplates: ProgramTemplate[]
  onTemplateSelect: ({
    chosenTemplate,
  }: {
    chosenTemplate: ProgramTemplate
  }) => void
  templatesFilter?: (templates: ProgramTemplate) => boolean
}) {
  const org = useContext(OrgContext)
  const { userId, orgId } = useIds()
  let filteredTemplates = programTemplates
  if (isFunction(templatesFilter)) {
    filteredTemplates = programTemplates.filter(templatesFilter)
  }

  const templateCarouselItems = filteredTemplates.map(template => {
    const imageUrl = getTemplateImageUrl({
      programImage: template.programImage,
    })

    const handleTemplateCTA = () => {
      logEvent(PROGRAM_TEMPLATE_CLICKED, {
        name: template?.name,
        fromTemplate: true,
        orgName: org?.name,
        orgSubscriptionStatus: org?.subscriptionStatus
          ? Organization_SubscriptionStatus[org.subscriptionStatus]
          : null,
        featured: !!template?.featured,
        orgId,
        userId,
      })
      onTemplateSelect({ chosenTemplate: template })
    }

    return (
      <CarouselImageCard
        key={template.id}
        imageUrl={imageUrl}
        onClick={handleTemplateCTA}
      />
    )
  })

  return templateCarouselItems
}

export function FeaturedProgramTemplatesCarousel() {
  const org = useContext(OrgContext)

  const { allBrandedImages, hasLoaded: hasLoadedBrandedTemplates } =
    useAllBrandedImages()

  const { featuredProgramTemplates, isLoadingFeaturedProgramTemplates } =
    useFeaturedProgramTemplates()

  const isLoading =
    isLoadingFeaturedProgramTemplates || !hasLoadedBrandedTemplates

  const navigate = useNavigate()

  const handleProgramTemplateClick = ({
    bannerData,
    chosenTemplate,
  }: {
    bannerData?: BannerClickData
    chosenTemplate?: ProgramTemplate
  }) => {
    let email
    if (chosenTemplate) {
      email = {
        title: chosenTemplate.programTitle,
        banner: chosenTemplate.programImage,
        note: chosenTemplate.programMessage,
        fromName: org.name,
      }
    } else if (bannerData) {
      const defaultEmailData = sample(bannerData.occasion?.emailData)
      email = {
        title: bannerData?.title || defaultEmailData?.title || '',
        note: bannerData?.note || defaultEmailData?.note || '',
        fromName: org.name,
        banner: bannerData.banner,
      }
    }

    const createRewardState: CreateRewardLocation = {
      state: {
        email,
        startingTemplate: chosenTemplate,
        occasion: bannerData?.occasion.occasion,
      },
    }

    navigate(DEFAULT_ROUTES.ORGANIZATION.REWARDS.NEW_REWARD, createRewardState)
  }

  const templateItems = ProgramTemplateCarouselItems({
    programTemplates: featuredProgramTemplates,
    onTemplateSelect: handleProgramTemplateClick,
  })

  const brandedCarouselItems = BrandedTemplateCarouselItems({
    brandedImages: allBrandedImages,
    handleTemplateClick: handleProgramTemplateClick,
  })

  const featuredCarouselItems = shuffleArrayWithDateSeed([
    ...brandedCarouselItems,
    ...templateItems,
  ])

  return (
    <Pane width="calc(100vw - 265px)" marginBottom={16}>
      {isLoading ? (
        <ProgramTemplateSkeleton />
      ) : (
        <CustomCarousel showHeading={false}>
          {featuredCarouselItems}
        </CustomCarousel>
      )}
    </Pane>
  )
}
