import { CheckOutlined, CloseOutlined } from '@ant-design/icons'
import { Flex, Input, Switch } from 'antd'
import { TemplateContext } from 'context'
import { Heading, Text, toaster } from 'evergreen-ui'
import { Template_Expiry } from 'gen/perkup/v1/org_template_pb'
import { isNaN } from 'lodash-es'
import { useContext } from 'react'

export function TemplateExpiryForm({
  onExpiryChange,
}: {
  onExpiryChange: (newExpiry: Template_Expiry) => void
}) {
  const template = useContext(TemplateContext)
  const expiry = template?.expiry
  return (
    <Flex vertical gap={8}>
      <Heading>Expiry</Heading>
      <Input
        style={{ width: 120 }}
        placeholder="30"
        addonAfter="days"
        value={expiry?.days}
        onChange={e => {
          const numberValue = Number(e.target.value)
          const invalidNumber = isNaN(numberValue) || numberValue <= 0
          if (invalidNumber) {
            toaster.warning('Please enter a valid number')
          }
          onExpiryChange(
            new Template_Expiry({
              ...expiry,
              days: invalidNumber ? undefined : numberValue,
            })
          )
        }}
      />
      <Flex gap={8}>
        <Switch
          checked={expiry?.writable}
          onChange={checked => {
            onExpiryChange(
              new Template_Expiry({
                ...expiry,
                writable: checked,
              })
            )
          }}
          unCheckedChildren={<CloseOutlined />}
          checkedChildren={<CheckOutlined />}
        />

        <Text>Let sender set expiry</Text>
      </Flex>
    </Flex>
  )
}
