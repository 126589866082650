import { useEffect, useMemo, useRef, useState } from 'react'

/**
 * Helper function to observe any property of a client element. When the desired property changes, the hook will return the new value.
 * https://github.com/mantinedev/mantine/blob/master/packages/@mantine/hooks/src/use-resize-observer/use-resize-observer.ts
 */
export function useResizeObserver<T extends HTMLElement = any>({
  valueToObserve = 'height',
}: {
  valueToObserve:
    | 'x'
    | 'y'
    | 'width'
    | 'height'
    | 'top'
    | 'left'
    | 'bottom'
    | 'right'
}) {
  const frameID = useRef(0)
  const ref = useRef<T>(null)

  const [value, setRect] = useState(0)

  const observer = useMemo(
    () =>
      typeof window !== 'undefined'
        ? new ResizeObserver((entries: any) => {
            const entry = entries[0]

            if (entry) {
              cancelAnimationFrame(frameID.current)

              frameID.current = requestAnimationFrame(() => {
                if (ref.current) {
                  setRect(entry.contentRect[valueToObserve])
                }
              })
            }
          })
        : null,
    [valueToObserve]
  )

  useEffect(() => {
    if (ref.current) {
      observer?.observe(ref.current)
    }

    return () => {
      observer?.disconnect()

      if (frameID.current) {
        cancelAnimationFrame(frameID.current)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref.current])

  return [ref, value] as const
}
