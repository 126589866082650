import { CloudDownloadOutlined } from '@ant-design/icons'
import { captureMessage } from '@sentry/react'
import { Button } from 'antd'
import { callFunction } from 'api/functionCalls'
import close from 'assets/crm-logos/close.svg'
import eventbrite from 'assets/crm-logos/eventbrite.svg'
import hubspot from 'assets/crm-logos/hubspot.svg'
import intercom from 'assets/crm-logos/intercom.svg'
import klaviyo from 'assets/crm-logos/klaviyo.svg'
import mailchimp from 'assets/crm-logos/mailchimp.png'
import pipedrive from 'assets/crm-logos/pipedrive.svg'
import salesforce from 'assets/crm-logos/salesforce.svg'
import zendesk from 'assets/crm-logos/zendesk.svg'
import zoho from 'assets/crm-logos/zoho.png'
import OktaLogo from 'assets/Okta_Logo.png'
import {
  IntegrationCard,
  PageHeader,
  PeopleCsvUploadButton,
  withOrgSidebar,
} from 'components'
import SlackIntegration from 'components/Integrations/SlackIntegration'
import { OrgContext, OrgIntegrationsContext } from 'context'
import { Heading, Pane, Paragraph, toaster } from 'evergreen-ui'
import {
  Organization_SubscriptionStatus,
  Organization_WorkOSStatus,
} from 'gen/perkup/v1/organization_pb'
import { useFinchConnectOpen } from 'hooks/useFinchConnectOpen'
import { useContext } from 'react'
import { Link } from 'react-router-dom'
import { makePlural } from 'utils'
import ConnectedIntegration from './ConnectedIntegration'
import FinchButton from './FinchButton'
import IntegrationsList from './IntegrationsList'

export function CSVTemplateButton() {
  const headers = [
    { label: 'Employee ID (optional)', key: 'employeeId' },
    { label: 'First Name', key: 'firstName' },
    { label: 'Last Name', key: 'lastName' },
    { label: 'Email', key: 'email' },
    { label: 'Birthday (optional)', key: 'dob' },
    { label: 'Start Date (optional)', key: 'startDate' },
    { label: 'End Date (optional)', key: 'endDate' },
    { label: 'Title (optional)', key: 'title' },
    { label: 'Manager ID (optional)', key: 'managerId' },
    { label: 'Location (optional)', key: 'location' },
    { label: 'Department (optional)', key: 'department' },
  ]

  const csvHeaderString = headers.map(header => header.label).join(',')

  const csvContent = `data:text/csv;charset=utf-8,${csvHeaderString}\n`

  return (
    <Button
      type="link"
      href={encodeURI(csvContent)}
      download="perkup-people-template.csv"
      icon={<CloudDownloadOutlined />}
    >
      CSV Template
    </Button>
  )
}

const CRM_INTEGRATIONS = [
  {
    logo: salesforce,
    title: 'Salesforce',
    description: 'Customer relationship management software',
  },
  {
    logo: hubspot,
    title: 'HubSpot',
    description: 'Inbound marketing and sales software',
  },
  {
    logo: pipedrive,
    title: 'Pipedrive',
    description: 'Sales CRM and pipeline management tool',
  },
  {
    logo: zoho,
    title: 'Zoho',
    description: 'Cloud software suite and SaaS applications',
  },
  {
    logo: mailchimp,
    title: 'Mailchimp',
    description: 'Email marketing platform',
  },
  {
    logo: zendesk,
    title: 'Zendesk',
    description: 'Customer service and engagement platform',
  },
  {
    logo: intercom,
    title: 'Intercom',
    description: 'Customer messaging platform',
  },
  {
    logo: klaviyo,
    title: 'Klaviyo',
    description: 'Email marketing and SMS platform',
  },

  {
    logo: eventbrite,
    title: 'Eventbrite',
    description: 'Event management and ticketing platform',
  },
  {
    logo: close,
    title: 'Close',
    description: 'Sales engagement CRM',
  },
]

function Integrations() {
  const org = useContext(OrgContext)
  const integrations = useContext(OrgIntegrationsContext)
  const { open, contextHolder } = useFinchConnectOpen()

  const handleWorkOSConnectClick = async () => {
    await callFunction('workos-GenerateAdminPortalLink', {}).then(link => {
      if (link) {
        window.location.href = link
      } else {
        toaster.warning('Something went wrong, please contact support')
        captureMessage('WorkOS admin portal link not available')
      }
    })
  }

  const hasConnectHRIS = integrations ? integrations?.length > 0 : false

  return (
    <Pane>
      {contextHolder}
      <Pane marginBottom={24}>
        <Pane display="flex" alignItems="flex-start" gap={32} marginBottom={12}>
          <PageHeader
            title={
              hasConnectHRIS
                ? 'Active HRIS integrations'
                : 'Integrate your HRIS'
            }
            description={
              hasConnectHRIS
                ? `Manage HRIS ${makePlural('integration', integrations?.length)}`
                : 'Connect your employee directory to PerkUp.'
            }
            vlogLink="https://www.loom.com/embed/d34fa651f4ab4a21b39282ba960f25b9?sid=e74b673f-5fa4-47a8-8c18-a9dd15163161"
          />

          {hasConnectHRIS && <FinchButton isButton onClick={() => open()} />}
        </Pane>
      </Pane>
      <Pane>
        {hasConnectHRIS ? (
          <Pane display="grid" gap={16}>
            {integrations?.map(integration => (
              <ConnectedIntegration
                key={integration.id}
                integration={integration}
              />
            ))}
          </Pane>
        ) : (
          <IntegrationsList onClick={() => open()} />
        )}
        <Pane>
          <Pane marginBottom={32}>
            <Heading size={800} marginTop={48} marginBottom={8}>
              Integrate your CRM (add-on)
            </Heading>
            <Paragraph size={500}>
              Bi-directional real-time sync between your CRM and PerkUp.
            </Paragraph>
          </Pane>
          <Pane>
            <Pane display="flex" flexWrap="wrap" gap={16} alignItems="center">
              {CRM_INTEGRATIONS.map(integration => (
                <Pane
                  is={Link}
                  to="https://form.typeform.com/to/wouxREAM"
                  target="_blank"
                  key={integration.title}
                  display="flex"
                  elevation={1}
                  hoverElevation={2}
                  borderRadius={4}
                  padding={24}
                  cursor="pointer"
                  width={200}
                  height={100}
                >
                  <img
                    src={integration.logo}
                    alt={integration.title}
                    style={{
                      maxWidth: '100%',
                      maxHeight: '100%',
                      margin: 'auto',
                    }}
                  />
                </Pane>
              ))}
            </Pane>
          </Pane>
        </Pane>

        <Pane>
          <Heading size={800} marginTop={48} marginBottom={24}>
            Other integrations
          </Heading>

          <Pane display="grid" gap={16}>
            <IntegrationCard
              logo={OktaLogo}
              title="SSO & SAML Authentication"
              description="Okta, Azure, OneLogin and more"
              connectLabel={
                org.workosStatus === Organization_WorkOSStatus.activated
                  ? 'Manage'
                  : 'Set up'
              }
              onConnectClick={handleWorkOSConnectClick}
              disabled={
                org.subscriptionStatus !==
                Organization_SubscriptionStatus.active
              }
            />
            <SlackIntegration displayCard />
          </Pane>
        </Pane>
        <Pane marginTop={48}>
          <Pane display="flex" gap={32} alignItems="center" marginBottom={16}>
            <Heading size={800}>CSV Uploader</Heading>
            <CSVTemplateButton />
          </Pane>
          <PeopleCsvUploadButton />
        </Pane>
      </Pane>
    </Pane>
  )
}

export default withOrgSidebar(Integrations)
