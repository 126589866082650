import { GIFTS, SWAG } from 'constants/routes'
import NoMatch404 from 'pages/NoMatch404'
import { Route, Routes } from 'react-router'
import { OrderGiftsPage } from './order-gifts-page'
import { OrderSwagPage } from './order-swag-page'

export default function OrderProducts() {
  return (
    <Routes>
      <Route path={SWAG} element={<OrderSwagPage />} />
      <Route path={GIFTS} element={<OrderGiftsPage />} />

      <Route path="*" element={<NoMatch404 />} />
    </Routes>
  )
}
