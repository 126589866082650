import { Individual_Status } from 'gen/perkup/v1/individual_pb'

// Eligible refers to being able to receive rewards
export const eligibleIndividualStatuses = [
  Individual_Status[Individual_Status.active],
  Individual_Status[Individual_Status.invited],
  Individual_Status[Individual_Status.none],
]

export const nonRemovedIndividualStatuses = [
  Individual_Status[Individual_Status.active],
  Individual_Status[Individual_Status.invited],
  Individual_Status[Individual_Status.none],
  Individual_Status[Individual_Status.blocked],
]
