// @generated by protoc-gen-connect-es v1.1.4 with parameter "target=ts"
// @generated from file perkup/v1/firebase_auth.proto (package perkup.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { CustomTokenRequest, CustomTokenResponse } from "./firebase_auth_pb.js";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service perkup.v1.FirebaseTokens
 */
export const FirebaseTokens = {
  typeName: "perkup.v1.FirebaseTokens",
  methods: {
    /**
     * @generated from rpc perkup.v1.FirebaseTokens.CustomToken
     */
    customToken: {
      name: "CustomToken",
      I: CustomTokenRequest,
      O: CustomTokenResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

