import { Flex } from 'antd'
import { giftNotAvailableAsset } from 'assets'
import { PerkEmpty, ProgramCard, ProgramListLoadingState } from 'components'
import { Text } from 'evergreen-ui'
import { useInfinitePrograms } from 'hooks/programs/useInfinitePrograms'
import { ProgramQueryFilters } from 'types'
import { makePlural } from 'utils'

export function InfiniteProgramsList({
  numProgramsOnMount,
  numProgramsOnPagination,
  filters,
}: {
  numProgramsOnMount: number
  numProgramsOnPagination: number
  filters: ProgramQueryFilters
}) {
  const { programs, hasLoaded, sentinelRef, isLastPage } = useInfinitePrograms({
    numProgramsOnMount,
    numProgramsOnPagination,
    filters,
  })

  if (!hasLoaded) return <ProgramListLoadingState />

  if (programs.length === 0) {
    return (
      <PerkEmpty iconUrl={giftNotAvailableAsset} header="No rewards in here" />
    )
  }

  return (
    <Flex vertical gap={8}>
      {programs.map(program => (
        <ProgramCard key={program.id} program={program} />
      ))}
      {isLastPage ? (
        <Text alignSelf="end" color="muted">
          {programs.length} of {programs.length}{' '}
          {makePlural('reward', programs.length)}
        </Text>
      ) : (
        <div ref={sentinelRef} aria-hidden="true" />
      )}
    </Flex>
  )
}
