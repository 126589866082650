import { captureException } from '@sentry/react'
import Input from 'antd/es/input'
import { toaster } from 'evergreen-ui'
import { setDoc } from 'firebase/firestore'
import capitalize from 'lodash-es/capitalize'
import { useEffect, useState } from 'react'
import { toSentry } from 'utils/sentry'

export function EditableTextInput({
  docRef,
  fieldKey,
  columnTitle,
  currentValue,
  isTextArea,
  extraStyles,
  handleCreateCustomAction,
}: {
  docRef: any
  fieldKey: string
  columnTitle: string
  currentValue: string
  isTextArea?: boolean
  extraStyles?: Object
  handleCreateCustomAction?: Function
}) {
  const [isEditing, setIsEditing] = useState<boolean>(false)
  const [newValue, setNewValue] = useState<string>(currentValue)

  // Set useState hook default to currentValue prop.
  useEffect(() => {
    setNewValue(currentValue)
  }, [currentValue])

  // On focus change, or on press enter, try to submit to firestore.
  function handleSubmit() {
    if (newValue.length === 0) return

    if (newValue === currentValue) return

    if (isEditing) {
      setIsEditing(false)

      if (handleCreateCustomAction) handleCreateCustomAction()

      setDoc(docRef, { [fieldKey]: newValue }, { merge: true })
        .then(() => {
          toaster.success(`${capitalize(columnTitle)} updated`, {
            id: fieldKey,
          })
        })
        .catch((error: any) => {
          console.error(error)
          toaster.warning('Something went wrong, try again or contact support.')
          captureException(toSentry(error), {
            contexts: {
              EditableTextInput: {
                docRef,
                fieldKey,
                columnTitle,
                currentValue,
                isTextArea,
                extraStyles,
                handleCreateCustomAction,
              },
            },
          })
        })
    }
  }

  const InputComponent = isTextArea ? Input.TextArea : Input

  return (
    <InputComponent
      className="editable-input"
      style={extraStyles}
      placeholder={columnTitle}
      bordered={isEditing}
      status={newValue ? undefined : 'error'}
      value={newValue}
      onBlur={() => handleSubmit()}
      onPressEnter={() => handleSubmit()}
      onClick={() => setIsEditing(true)}
      onChange={(e: any) => {
        setNewValue(e.target.value)
      }}
      maxLength={512}
      autoSize
    />
  )
}
