// @generated by protoc-gen-es v1.6.0 with parameter "target=ts"
// @generated from file perkup/v1/org_template.proto (package perkup.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, Timestamp } from "@bufbuild/protobuf";
import { ProgramOccasion } from "./program_pb.js";

/**
 * Firestore /organization/:id/templates/:id
 *
 * @generated from message perkup.v1.Template
 */
export class Template extends Message<Template> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string name = 2;
   */
  name = "";

  /**
   * @generated from field: perkup.v1.Template.CoreValues core_values = 3;
   */
  coreValues?: Template_CoreValues;

  /**
   * @generated from field: perkup.v1.Template.Budget budget = 4;
   */
  budget?: Template_Budget;

  /**
   * @generated from field: perkup.v1.Template.Expiry expiry = 5;
   */
  expiry?: Template_Expiry;

  /**
   * @generated from field: perkup.v1.ProgramOccasion occasion = 6;
   */
  occasion = ProgramOccasion.UNKNOWN_ProgramOccasion;

  /**
   * @generated from field: perkup.v1.Template.Email email = 7;
   */
  email?: Template_Email;

  /**
   * @generated from field: perkup.v1.Template.Image image = 8;
   */
  image?: Template_Image;

  /**
   * @generated from field: perkup.v1.Template.Access access = 9;
   */
  access = Template_Access.ACCESS_UNSPECIFIED;

  /**
   * @generated from field: optional string internal_memo = 10;
   */
  internalMemo?: string;

  /**
   * @generated from field: perkup.v1.Template.EligibleRewards eligible_rewards = 11;
   */
  eligibleRewards?: Template_EligibleRewards;

  /**
   * @generated from field: repeated string approved_categories = 12;
   */
  approvedCategories: string[] = [];

  /**
   * @generated from field: bool is_deleted = 13;
   */
  isDeleted = false;

  /**
   * @generated from field: google.protobuf.Timestamp created = 14;
   */
  created?: Timestamp;

  /**
   * Individual id of creator
   *
   * @generated from field: string owner_id = 15;
   */
  ownerId = "";

  constructor(data?: PartialMessage<Template>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "perkup.v1.Template";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "core_values", kind: "message", T: Template_CoreValues },
    { no: 4, name: "budget", kind: "message", T: Template_Budget },
    { no: 5, name: "expiry", kind: "message", T: Template_Expiry },
    { no: 6, name: "occasion", kind: "enum", T: proto3.getEnumType(ProgramOccasion) },
    { no: 7, name: "email", kind: "message", T: Template_Email },
    { no: 8, name: "image", kind: "message", T: Template_Image },
    { no: 9, name: "access", kind: "enum", T: proto3.getEnumType(Template_Access) },
    { no: 10, name: "internal_memo", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 11, name: "eligible_rewards", kind: "message", T: Template_EligibleRewards },
    { no: 12, name: "approved_categories", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 13, name: "is_deleted", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 14, name: "created", kind: "message", T: Timestamp },
    { no: 15, name: "owner_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Template {
    return new Template().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Template {
    return new Template().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Template {
    return new Template().fromJsonString(jsonString, options);
  }

  static equals(a: Template | PlainMessage<Template> | undefined, b: Template | PlainMessage<Template> | undefined): boolean {
    return proto3.util.equals(Template, a, b);
  }
}

/**
 * @generated from enum perkup.v1.Template.Access
 */
export enum Template_Access {
  /**
   * @generated from enum value: ACCESS_UNSPECIFIED = 0;
   */
  ACCESS_UNSPECIFIED = 0,

  /**
   * @generated from enum value: admin = 1;
   */
  admin = 1,

  /**
   * @generated from enum value: manager = 2;
   */
  manager = 2,

  /**
   * @generated from enum value: all = 3;
   */
  all = 3,
}
// Retrieve enum metadata with: proto3.getEnumType(Template_Access)
proto3.util.setEnumType(Template_Access, "perkup.v1.Template.Access", [
  { no: 0, name: "ACCESS_UNSPECIFIED" },
  { no: 1, name: "admin" },
  { no: 2, name: "manager" },
  { no: 3, name: "all" },
]);

/**
 * @generated from message perkup.v1.Template.CoreValues
 */
export class Template_CoreValues extends Message<Template_CoreValues> {
  /**
   * @generated from field: repeated string values = 1;
   */
  values: string[] = [];

  /**
   * @generated from field: bool writable = 2;
   */
  writable = false;

  constructor(data?: PartialMessage<Template_CoreValues>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "perkup.v1.Template.CoreValues";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "values", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 2, name: "writable", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Template_CoreValues {
    return new Template_CoreValues().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Template_CoreValues {
    return new Template_CoreValues().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Template_CoreValues {
    return new Template_CoreValues().fromJsonString(jsonString, options);
  }

  static equals(a: Template_CoreValues | PlainMessage<Template_CoreValues> | undefined, b: Template_CoreValues | PlainMessage<Template_CoreValues> | undefined): boolean {
    return proto3.util.equals(Template_CoreValues, a, b);
  }
}

/**
 * Maybe break budget up into two different messages? VariableAmount and fixedAmounts?
 *
 * @generated from message perkup.v1.Template.Budget
 */
export class Template_Budget extends Message<Template_Budget> {
  /**
   * @generated from field: repeated int32 amounts = 1;
   */
  amounts: number[] = [];

  /**
   * @generated from field: bool writable = 2;
   */
  writable = false;

  /**
   * @generated from field: optional int32 max_amount = 3;
   */
  maxAmount?: number;

  constructor(data?: PartialMessage<Template_Budget>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "perkup.v1.Template.Budget";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "amounts", kind: "scalar", T: 5 /* ScalarType.INT32 */, repeated: true },
    { no: 2, name: "writable", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 3, name: "max_amount", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Template_Budget {
    return new Template_Budget().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Template_Budget {
    return new Template_Budget().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Template_Budget {
    return new Template_Budget().fromJsonString(jsonString, options);
  }

  static equals(a: Template_Budget | PlainMessage<Template_Budget> | undefined, b: Template_Budget | PlainMessage<Template_Budget> | undefined): boolean {
    return proto3.util.equals(Template_Budget, a, b);
  }
}

/**
 * @generated from message perkup.v1.Template.Expiry
 */
export class Template_Expiry extends Message<Template_Expiry> {
  /**
   * @generated from field: optional int32 days = 1;
   */
  days?: number;

  /**
   * @generated from field: bool writable = 2;
   */
  writable = false;

  constructor(data?: PartialMessage<Template_Expiry>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "perkup.v1.Template.Expiry";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "days", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 2, name: "writable", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Template_Expiry {
    return new Template_Expiry().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Template_Expiry {
    return new Template_Expiry().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Template_Expiry {
    return new Template_Expiry().fromJsonString(jsonString, options);
  }

  static equals(a: Template_Expiry | PlainMessage<Template_Expiry> | undefined, b: Template_Expiry | PlainMessage<Template_Expiry> | undefined): boolean {
    return proto3.util.equals(Template_Expiry, a, b);
  }
}

/**
 * @generated from message perkup.v1.Template.Email
 */
export class Template_Email extends Message<Template_Email> {
  /**
   * @generated from field: string title = 1;
   */
  title = "";

  /**
   * @generated from field: string message = 2;
   */
  message = "";

  /**
   * @generated from field: bool writable = 3;
   */
  writable = false;

  constructor(data?: PartialMessage<Template_Email>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "perkup.v1.Template.Email";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "writable", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Template_Email {
    return new Template_Email().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Template_Email {
    return new Template_Email().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Template_Email {
    return new Template_Email().fromJsonString(jsonString, options);
  }

  static equals(a: Template_Email | PlainMessage<Template_Email> | undefined, b: Template_Email | PlainMessage<Template_Email> | undefined): boolean {
    return proto3.util.equals(Template_Email, a, b);
  }
}

/**
 * @generated from message perkup.v1.Template.Image
 */
export class Template_Image extends Message<Template_Image> {
  /**
   * @generated from field: optional string url = 1;
   */
  url?: string;

  /**
   * @generated from field: bool writable = 2;
   */
  writable = false;

  constructor(data?: PartialMessage<Template_Image>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "perkup.v1.Template.Image";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "url", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 2, name: "writable", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Template_Image {
    return new Template_Image().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Template_Image {
    return new Template_Image().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Template_Image {
    return new Template_Image().fromJsonString(jsonString, options);
  }

  static equals(a: Template_Image | PlainMessage<Template_Image> | undefined, b: Template_Image | PlainMessage<Template_Image> | undefined): boolean {
    return proto3.util.equals(Template_Image, a, b);
  }
}

/**
 * @generated from message perkup.v1.Template.EligibleRewards
 */
export class Template_EligibleRewards extends Message<Template_EligibleRewards> {
  /**
   * PerkUp dollars
   *
   * @generated from field: bool cash = 1;
   */
  cash = false;

  /**
   * Physical gifts
   *
   * @generated from field: bool gift = 2;
   */
  gift = false;

  /**
   * Swag gifts
   *
   * @generated from field: bool swag = 3;
   */
  swag = false;

  /**
   * Gift cards
   *
   * @generated from field: bool gift_card = 4;
   */
  giftCard = false;

  constructor(data?: PartialMessage<Template_EligibleRewards>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "perkup.v1.Template.EligibleRewards";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "cash", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "gift", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 3, name: "swag", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 4, name: "gift_card", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Template_EligibleRewards {
    return new Template_EligibleRewards().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Template_EligibleRewards {
    return new Template_EligibleRewards().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Template_EligibleRewards {
    return new Template_EligibleRewards().fromJsonString(jsonString, options);
  }

  static equals(a: Template_EligibleRewards | PlainMessage<Template_EligibleRewards> | undefined, b: Template_EligibleRewards | PlainMessage<Template_EligibleRewards> | undefined): boolean {
    return proto3.util.equals(Template_EligibleRewards, a, b);
  }
}

