import { DeleteTwoTone } from '@ant-design/icons'
import { Flex, Popconfirm, Table } from 'antd'
import type { ColumnsType } from 'antd/es/table'
import { archiveOrgList } from 'api/databaseCalls'
import { PageHeader } from 'components'
import { tablePaginationSettings } from 'constants/antdesign'
import { Code, Pane, Strong } from 'evergreen-ui'
import { OrgList } from 'gen/perkup/v1/organization_pb'
import useIds from 'hooks/useIds'
import { startCase } from 'lodash-es'
import { CreateLabel } from './component/CreateLabel'

interface DataType {
  key: string
  label: string
  orgId: string
  listId: string
}

const columns: ColumnsType<DataType> = [
  {
    title: 'Display Name',
    key: 'label',
    dataIndex: 'label',
    width: '30%',
    render: value => <Strong>{startCase(value)}</Strong>,
  },
  {
    title: 'Key',
    key: 'listId',
    dataIndex: 'listId',
    width: '30%',
    render: (_, { listId }) => <Code>{listId}</Code>,
  },
  {
    title: '',
    key: 'actions',
    dataIndex: '',
    render: (_, { orgId, listId }) => (
      <Pane
        display="flex"
        justifyContent="center"
        alignItems="center"
        gap="8px"
      >
        <Popconfirm
          title="Are you sure you want to delete?"
          onConfirm={() => archiveOrgList({ orgId, listId })}
          okText="Delete"
        >
          <DeleteTwoTone twoToneColor="red" />
        </Popconfirm>
      </Pane>
    ),
    width: '5%',
  },
]

export function Labels({ labels }: { labels: OrgList[] }) {
  const { orgId } = useIds()

  const data = labels.map(label => {
    return {
      key: label.id,
      label: label.name,
      listId: label.id,
      orgId,
    }
  })

  return (
    <Flex vertical gap={32}>
      <PageHeader
        title="Custom Labels"
        description="Create custom labels to organize your people directory and send bulk rewards."
        buttonsToRenderOnLeft={<CreateLabel />}
        vlogLink="https://www.loom.com/embed/98c47a933cd84b9b86b602756fc17d00?sid=8bf41cd5-051c-4eb2-881c-936f0a0775b8"
      />

      <Table
        columns={columns}
        dataSource={data}
        pagination={tablePaginationSettings}
        style={{ maxWidth: 768 }}
      />
    </Flex>
  )
}
