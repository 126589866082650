export const IN_STOCK = 'In stock'

export const OUT_OF_STOCK = 'Out of stock'

export const DENOMINATION = 'denomination'

export const PRODUCT_NAME = 'productName'

export const PRODUCTS = 'products'

export const PRODUCT = 'product'

export const COLOR_OPTION = 'color'

export const SIZE_OPTION = 'size'

export const SORTED_SIZES = [
  'XS',
  'S',
  'M',
  'L',
  'XL',
  '2XL',
  '3XL',
  '4XL',
  '5XL',
]

export const NEARCASH = 'nearcash'

export const IN_STOCK_THRESHOLD = 9000
