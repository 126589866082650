import * as EVENTS from 'constants/events'
import * as ROUTES from 'constants/routes'
import { IndividualContext, OrgContext, UserContext } from 'context'
import {
  Avatar,
  Heading,
  Menu,
  Pane,
  Popover,
  Position,
  Text,
} from 'evergreen-ui'
import { getAuth, signOut } from 'firebase/auth'
import { Individual_Role } from 'gen/perkup/v1/individual_pb'
import capitalize from 'lodash-es/capitalize'
import { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { logEvent } from 'utils'

export function UserDropdown() {
  const individual = useContext(IndividualContext)
  const navigate = useNavigate()
  const role = individual?.role
  const org = useContext(OrgContext)
  const orgId = org?.id
  const user = useContext(UserContext)
  const userId = user?.id

  const { firstName, lastName, email, profilePicture } = individual || {}

  const name = firstName ? `${firstName} ${lastName}` : email

  const textStyles = { color: '#1070CA', fontWeight: 500 }

  const popoverContent = ({ close }: { close: () => void }) => (
    <Menu>
      <Menu.Group>
        <Pane paddingX={16} paddingY={4}>
          <Pane display="flex" gap={8} alignItems="center">
            <Heading size={500}>{name}</Heading>
          </Pane>
          {role && <Text>{capitalize(Individual_Role[role])}</Text>}
        </Pane>
      </Menu.Group>
      <Menu.Divider />
      <Menu.Group>
        <Menu.Item
          onClick={() => {
            navigate(`${ROUTES.ACCOUNT}/${ROUTES.AccountRoutes.PROFILE}`)
            close()
          }}
        >
          <Text {...textStyles}>Account</Text>
        </Menu.Item>

        <Menu.Item
          onClick={() => {
            navigate(ROUTES.TRANSACTIONS)
            close()
          }}
        >
          <Text {...textStyles}>Transactions</Text>
        </Menu.Item>

        <Menu.Divider />

        <Menu.Item
          onClick={async () => {
            logEvent(EVENTS.SIGNED_OUT, { orgId, userId })
            close()
            await signOut(getAuth())
            navigate(ROUTES.SIGN_IN)
            window.location.reload()
          }}
        >
          <Text {...textStyles}>Sign out</Text>
        </Menu.Item>
      </Menu.Group>
    </Menu>
  )
  const avatarBorderSize = 2

  const avatarSize = 40
  return (
    <Popover position={Position.BOTTOM_RIGHT} content={popoverContent}>
      <Pane borderRadius="100%" height={avatarSize + avatarBorderSize * 2}>
        <Avatar
          position="relative"
          name={name}
          src={profilePicture}
          size={avatarSize}
        />
      </Pane>
    </Popover>
  )
}
