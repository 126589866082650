import { CheckOutlined, SearchOutlined } from '@ant-design/icons'
import { Button, Image, Input, InputNumber, Select, Switch } from 'antd'
import { PerkEmpty, PerkLoader, PerkScrollbars } from 'components'
import { BULK_PRODUCT_CLICKED } from 'constants/events'
import { IndividualContext, OrgContext, UserContext } from 'context'
import { Heading, Strong, Text } from 'evergreen-ui'
import { useSageList, useSageProducts } from 'hooks'
import { isArray } from 'lodash-es'
import { useContext, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { Link, useSearchParams } from 'react-router-dom'
import { SageSearchFilters } from 'types/sage'
import { logEvent, makePlural } from 'utils'

export function OrgSwagSearchProducts() {
  const { id: orgId } = useContext(OrgContext)
  const { id: individualId, email } = useContext(IndividualContext)
  const { id: userId } = useContext(UserContext)
  const [searchParams, setSearchParams] = useSearchParams()

  const [productDisplayCount, setProductDisplayCount] = useState(23)

  // Parse the query string into filters
  const DEFAULT_FILTERS: SageSearchFilters = {
    sort: searchParams.get('sort') || 'POPULARITY',
    quickSearch: searchParams.get('quickSearch') || 'tumblers',
    categories: searchParams.get('categories')
      ? JSON.parse(searchParams.get('categories') || '[]')
      : undefined,
    colors: searchParams.get('colors') || undefined,
    priceLow: searchParams.get('priceLow')
      ? Number(searchParams.get('priceLow'))
      : undefined,
    priceHigh: searchParams.get('priceHigh')
      ? Number(searchParams.get('priceHigh'))
      : undefined,
    qty: searchParams.get('qty') ? Number(searchParams.get('qty')) : undefined,
    esg: searchParams.get('esg')
      ? JSON.parse(searchParams.get('esg') || '[]')
      : undefined,
    recyclable: searchParams.get('recyclable') === 'true' || undefined,
    envFriendly: searchParams.get('envFriendly') === 'true' || undefined,
    newProduct: searchParams.get('newProduct') === 'true' || undefined,
    fresh: searchParams.get('fresh') === 'true' || undefined,
  }

  const [localFilters, setLocalFilters] =
    useState<SageSearchFilters>(DEFAULT_FILTERS)

  const [filtersInUse, setFiltersInUse] =
    useState<SageSearchFilters>(DEFAULT_FILTERS)

  const {
    products,
    hasLoaded: hasLoadedProducts,
    isLoadingMore,
  } = useSageProducts({
    filters: filtersInUse,
  })

  const { items: categories, hasLoaded: hasLoadedCategories } = useSageList({
    listType: 'categories',
  })

  const { items: esgFlags, hasLoaded: hasLoadedESGFlags } = useSageList({
    listType: 'esg',
  })

  const updateSageQuery = (filters: SageSearchFilters) => {
    setFiltersInUse(filters)
  }

  const updateSearchParams = (filters: SageSearchFilters) => {
    const newSearchParams = new URLSearchParams()
    Object.entries(filters).forEach(([key, value]) => {
      if (value) {
        if (isArray(value)) {
          newSearchParams.append(key, JSON.stringify(value))
        } else {
          newSearchParams.append(key, value)
        }
      }
    })
    setSearchParams(newSearchParams, { replace: true })
  }

  return (
    <>
      <Helmet>
        <title>Bulk swag catalog | PerkUp</title>
      </Helmet>
      <main
        className="flex flex-col -mt-8"
        style={{ width: '100%', padding: '32px' }}
      >
        <section className="sticky top-[60px] w-full bg-background z-10 flex gap-4 py-4 pt-8">
          <Heading size={800} width={320}>
            Bulk swag catalog
          </Heading>
          <div className="flex gap-8 w-full">
            <Input.Search
              className="max-w-lg"
              prefix={<SearchOutlined />}
              placeholder="Search for t-shirts, mugs, tumblers, etc."
              value={localFilters.quickSearch}
              onChange={e => {
                setLocalFilters(prev => ({
                  ...prev,
                  quickSearch: e.target.value,
                }))
              }}
              onSearch={() => {
                updateSearchParams(localFilters)
                updateSageQuery(localFilters)
              }}
              size="large"
              enterButton="Search"
              loading={isLoadingMore}
              autoFocus
              allowClear
            />
          </div>
        </section>

        {/** LEFT NAV AND PRODUCTS GRID */}
        <section className="flex gap-4 w-full">
          <PerkScrollbars
            style={{
              position: 'sticky',
              top: 148,
              alignSelf: 'flex-start',
              height: `calc(100vh - 148px)`, // The height of the side nav really needs to adapt dynamically
              maxWidth: 'fit-content',
              paddingBottom: 16,
              transition: 'all 0.2s ease-in-out',
              width: 320,
            }}
          >
            <nav className="flex flex-col gap-4">
              <div>
                <Text>Categories</Text>
                <Select
                  disabled={!hasLoadedCategories}
                  mode="multiple"
                  className="w-full"
                  placeholder="Select categories"
                  value={localFilters.categories}
                  onChange={values =>
                    setLocalFilters(prev => ({ ...prev, categories: values }))
                  }
                  options={categories
                    .filter(
                      (cat, index, self) =>
                        index === self.findIndex(c => c.id === cat.id)
                    )
                    .map(cat => ({
                      label: cat.name,
                      value: cat.id,
                    }))}
                  showSearch
                />
              </div>

              <div>
                <Text>Colors</Text>
                <Input
                  placeholder="Enter colors (comma separated)"
                  value={localFilters.colors}
                  onChange={e =>
                    setLocalFilters(prev => ({
                      ...prev,
                      colors: e.target.value,
                    }))
                  }
                />
              </div>

              <div>
                <Text>Price Range</Text>
                <div className="flex gap-2 items-center">
                  <InputNumber
                    placeholder="Min"
                    prefix="$"
                    value={localFilters.priceLow}
                    onChange={val =>
                      setLocalFilters(prev => ({
                        ...prev,
                        priceLow: val || undefined,
                      }))
                    }
                  />
                  <Text>to</Text>
                  <InputNumber
                    placeholder="Max"
                    prefix="$"
                    value={localFilters.priceHigh}
                    onChange={val =>
                      setLocalFilters(prev => ({
                        ...prev,
                        priceHigh: val || undefined,
                      }))
                    }
                  />
                </div>
              </div>

              <div>
                <Text>Quantity</Text>
                <InputNumber
                  className="w-full"
                  min={1}
                  value={localFilters.qty}
                  onChange={val =>
                    setLocalFilters(prev => ({
                      ...prev,
                      qty: val || undefined,
                    }))
                  }
                />
              </div>

              <div>
                <Text>ESG/Diversity Flags</Text>
                <Select
                  disabled={!hasLoadedESGFlags}
                  mode="multiple"
                  className="w-full"
                  placeholder="Select ESG/Diversity"
                  value={localFilters.esg}
                  onChange={values =>
                    setLocalFilters(prev => ({ ...prev, esg: values }))
                  }
                  options={esgFlags.map(flag => ({
                    label: flag.name,
                    value: flag.id,
                  }))}
                />
              </div>

              <div className="flex flex-col gap-2">
                <div className="flex justify-between">
                  <Text>Recyclable Products</Text>
                  <Switch
                    checkedChildren={<CheckOutlined />}
                    checked={localFilters.recyclable}
                    onChange={checked =>
                      setLocalFilters(prev => ({
                        ...prev,
                        recyclable: checked,
                      }))
                    }
                  />
                </div>

                <div className="flex justify-between">
                  <Text>Environmentally Friendly</Text>
                  <Switch
                    checkedChildren={<CheckOutlined />}
                    checked={localFilters.envFriendly}
                    onChange={checked =>
                      setLocalFilters(prev => ({
                        ...prev,
                        envFriendly: checked,
                      }))
                    }
                  />
                </div>

                <div className="flex justify-between">
                  <Text>New Products Only</Text>
                  <Switch
                    checkedChildren={<CheckOutlined />}
                    checked={localFilters.newProduct}
                    onChange={checked =>
                      setLocalFilters(prev => ({
                        ...prev,
                        newProduct: checked,
                      }))
                    }
                  />
                </div>

                <div className="flex justify-between">
                  <Text>Fresh Ideas</Text>
                  <Switch
                    checkedChildren={<CheckOutlined />}
                    checked={localFilters.fresh}
                    onChange={checked =>
                      setLocalFilters(prev => ({ ...prev, fresh: checked }))
                    }
                  />
                </div>
              </div>

              <div className="flex flex-col">
                <Text>Sort by</Text>
                <Select
                  value={localFilters.sort}
                  onChange={value => {
                    setLocalFilters(prev => ({ ...prev, sort: value }))
                  }}
                  options={[
                    { label: 'Best Match', value: 'BESTMATCH' },
                    { label: 'Price: Low to High', value: 'PRICE' },
                    { label: 'Price: High to Low', value: 'PRICEHIGHLOW' },
                    { label: 'Most Popular', value: 'POPULARITY' },
                    { label: 'Preference Groups', value: 'PREFGROUP' },
                  ]}
                />
              </div>

              <Button
                type="primary"
                onClick={() => {
                  updateSearchParams(localFilters)
                  updateSageQuery(localFilters)
                }}
                loading={isLoadingMore}
              >
                Apply
              </Button>

              <div>
                <Text size={300} color="muted">
                  Trending Searches:
                </Text>
                <div className="flex flex-wrap mt-2 gap-2">
                  {[
                    'Eco-friendly water bottles',
                    'Recycled t-shirts',
                    'Bamboo products',
                    'Sustainable bags',
                  ].map(term => (
                    <Button
                      key={term}
                      size="small"
                      onClick={() => {
                        const newFilters = {
                          ...localFilters,
                          quickSearch: term,
                        }
                        setLocalFilters(newFilters)
                        updateSearchParams(newFilters)
                        updateSageQuery(newFilters)
                      }}
                    >
                      {term}
                    </Button>
                  ))}
                </div>
              </div>
            </nav>
          </PerkScrollbars>

          <div className="w-full">
            <div className="flex flex-wrap gap-4">
              {products.length === 0 && hasLoadedProducts && (
                <div className="flex items-center justify-center w-full my-32">
                  <PerkEmpty
                    iconNode={
                      <SearchOutlined
                        className="text-muted-foreground"
                        style={{ fontSize: 32 }}
                      />
                    }
                    header="Nothing found"
                    description="No products found. Try adjusting your search filters."
                  />
                </div>
              )}

              {!hasLoadedProducts ? (
                <div className="flex items-center justify-center w-full my-32">
                  <PerkLoader />
                </div>
              ) : (
                products.map((result, index) => {
                  if (index > productDisplayCount) return null

                  const colorsCount = result.colors.split(',').length
                  return (
                    <Link
                      key={result.prodEId}
                      to={`${result.prodEId}`}
                      onClick={() => {
                        logEvent(BULK_PRODUCT_CLICKED, {
                          orgId,
                          individualId,
                          userId,
                          email,
                          productId: result.prodEId,
                          productName: result.name,
                        })
                      }}
                    >
                      <article
                        data-loading={isLoadingMore}
                        className="relative flex flex-col justify-between w-80 border gap-4 h-full rounded-lg p-6 transition-shadow data-[loading=false]:cursor-pointer data-[loading=false]:hover:shadow-md"
                      >
                        <section className="my-auto">
                          <Image
                            src={result.thumbPic}
                            alt={result.name}
                            width="100%"
                            preview={false}
                            style={{
                              maxHeight: 256,
                              objectFit: 'contain',
                              borderRadius: 4,
                            }}
                          />
                        </section>
                        <section className="flex flex-col gap-2">
                          <Strong size={500}>{result.name}</Strong>
                          <div className="flex flex-col gap-1">
                            {result.prc && (
                              <Text size={500}>
                                ${result.prc.toString().replace(' - ', ' - $')}
                              </Text>
                            )}
                            {colorsCount > 0 && (
                              <Text color="muted">
                                {colorsCount} {makePlural('color', colorsCount)}
                              </Text>
                            )}
                          </div>
                        </section>
                        <div
                          data-loading={isLoadingMore}
                          className="absolute left-0 top-0 rounded-lg transition-opacity opacity-0 w-full h-full bg-gray-400 data-[loading=true]:animate-pulse data-[loading=true]:opacity-80"
                        />
                      </article>
                    </Link>
                  )
                })
              )}
            </div>

            {hasLoadedProducts && products.length > productDisplayCount && (
              <div className="mt-8">
                <Button
                  onClick={() =>
                    setProductDisplayCount(productDisplayCount + 23 + 1)
                  }
                  type="primary"
                >
                  Show more
                </Button>
              </div>
            )}
          </div>
        </section>
      </main>
    </>
  )
}
