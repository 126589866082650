import { captureMessage } from '@sentry/react'
import { StripeAddressElementChangeEvent } from '@stripe/stripe-js'
import { setUserBillingAddress } from 'api/databaseCalls'
import { callFunction } from 'api/functionCalls'
import { AddressDisplay, AddressForm } from 'components'
import { UserContext } from 'context'
import {
  Alert,
  Button,
  Dialog,
  Heading,
  Pane,
  Paragraph,
  toaster,
} from 'evergreen-ui'
import { RootUser_Address, ShippingAddress } from 'gen/perkup/v1/root_user_pb'
import { useContext, useState } from 'react'
import Stripe from 'stripe'

export default function BillingAddressSection() {
  const user = useContext(UserContext)
  const { address, cardholderId } = user

  const [formAddress, setFormAddress] = useState<Stripe.Address>()
  const [isShown, setIsShown] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const handleAddressChange = (event: StripeAddressElementChangeEvent) => {
    if (event.complete) setFormAddress(event.value.address)
    else setFormAddress(undefined)
  }

  const handleUpdateBillingAddress = async () => {
    if (!formAddress) return

    setIsLoading(true)
    const response = await callFunction('stripe-UpdateCardholderAddress', {
      cardholderId,
      newAddress: address,
    })
    if (response?.type === 'StripeInvalidRequestError') {
      toaster.danger('Invalid address', {
        description: 'If the issue persists, please contact support',
      })
      captureMessage('Error updating billing address - invalid address', 'info')
    } else if (!response?.id) {
      toaster.danger('Error updating billing address, please contact support')
      captureMessage('Unknown error updating billing address', 'error')
    } else {
      toaster.success('Successfully updated billing address')
      setUserBillingAddress({
        address: new RootUser_Address({
          line1: formAddress.line1 ?? undefined,
          line2: formAddress.line2 ?? undefined,
          city: formAddress.city ?? undefined,
          state: formAddress.state ?? undefined,
          country: formAddress.country ?? undefined,
          postalCode: formAddress.postal_code ?? undefined,
        }),
        userId: user.id,
      })
    }
    setIsLoading(false)
    setIsShown(false)
    setFormAddress(undefined)
  }

  // Don't display billing address if one isn't set
  if (!address) return null

  return (
    <>
      <Pane>
        <Heading size={600} marginBottom={24}>
          Billing address
        </Heading>

        <AddressDisplay address={new ShippingAddress(address)} />

        <Button onClick={() => setIsShown(true)}>Edit</Button>
      </Pane>

      <Dialog
        hasHeader={false}
        isShown={isShown}
        confirmLabel="Save"
        isConfirmDisabled={!formAddress}
        onConfirm={handleUpdateBillingAddress}
        isConfirmLoading={isLoading}
        onCloseComplete={() => {
          setIsShown(false)
          setFormAddress(undefined)
        }}
        contentContainerProps={{
          padding: 24,
          height: '60vh',
        }}
      >
        <Pane>
          <Heading size={600} marginBottom={24}>
            Billing address
          </Heading>

          <Alert
            appearance="card"
            intent="info"
            marginY={16}
            title="Remember to update your billing addresses"
          >
            <Pane marginTop={4}>
              <Paragraph size={400}>
                If you change your billing address, you must update your billing
                details wherever you use your perk card. Failing to do so may
                result in subscription downgrades or cancellations.
              </Paragraph>
            </Pane>
          </Alert>

          <AddressForm
            mode="billing"
            allowedCountries={['US', 'CA']}
            hidePhoneNumber
            defaultAddress={new ShippingAddress(address)}
            onAddressChange={handleAddressChange}
          />
        </Pane>
      </Dialog>
    </>
  )
}
