import { GetExchangeRate } from 'api/databaseCalls/reads/exchange'
import { USD } from 'constants/currencies'
import isNaN from 'lodash-es/isNaN'
import { useEffect, useState } from 'react'
import { numToDollars } from 'utils'

export default function useNumToDollarsConversion({
  num,
  numCurrency = USD,
  decimal = 2,
  displayCurrency = USD,
}: {
  num: number
  numCurrency: string // @todo(thomas) change to just accept currencies
  decimal: number
  displayCurrency: string | undefined
}): string | null {
  const [exchangeRate, setExchangeRate] = useState<number>(1)

  useEffect(() => {
    GetExchangeRate({
      fromCurrency: numCurrency,
      toCurrency: displayCurrency,
    }).then(res => {
      if (res && res !== exchangeRate) {
        setExchangeRate(res)
      }
    })
  }, [displayCurrency, exchangeRate, numCurrency])

  if (isNaN(num)) return null
  if (!num && num !== 0) return null

  const amount = num * exchangeRate

  return numToDollars(amount, decimal, false, displayCurrency)
}
