import { Skeleton } from 'antd'
import { GetTransactionById } from 'api/databaseCalls'
import { AddressDisplay } from 'components/Addresses'
import { DigitalOrderInfo } from 'components/Transactions/DigitalOrderInfo'
import { NUMBER_GREEN } from 'constants/colors'
import { TRANSACTIONS } from 'constants/routes'
import { OrgContext } from 'context'
import { Heading, Image, Link, Pane, Paragraph, Strong } from 'evergreen-ui'
import { OrgTransaction_DigitalOrderInfo } from 'gen/perkup/v1/organization_pb'
import { ShippingAddress } from 'gen/perkup/v1/root_user_pb'
import { useContext, useEffect, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { useLocation } from 'react-router'
import { createOrderLocation } from 'utils/transactions'

export function OrderConfirmationDetails({
  imageUrl = '',
  shippingAddress,
  deliveryInformation = '',
  digitalOrderInfo,
}: {
  imageUrl?: string
  shippingAddress?: ShippingAddress
  deliveryInformation?: string
  digitalOrderInfo?: OrgTransaction_DigitalOrderInfo
}) {
  const isDigitalItem = !!digitalOrderInfo

  return (
    <Pane
      padding={32}
      marginBottom={32}
      border
      borderRadius={4}
      overflow="hidden"
      display="flex"
      flexDirection={isMobile ? 'column' : undefined}
      justifyContent="space-between"
      alignItems="center"
      gap={16}
    >
      <Pane>
        <Heading size={600} fontWeight="bolder" marginBottom={16}>
          Order placed, thanks!
        </Heading>
        <Paragraph lineHeight={1} marginBottom={16}>
          A confirmation will be sent to your email.
        </Paragraph>

        {!isDigitalItem && shippingAddress && (
          <AddressDisplay address={shippingAddress} />
        )}
        <Pane display="flex" flexDirection="column" gap={8} marginBottom={16}>
          {digitalOrderInfo && <DigitalOrderInfo info={digitalOrderInfo} />}
        </Pane>
        {deliveryInformation && (
          <Paragraph marginBottom={16}>
            Estimated delivery:{' '}
            <Strong color={NUMBER_GREEN} fontWeight="bolder">
              {deliveryInformation}
            </Strong>
          </Paragraph>
        )}
        <Link href={TRANSACTIONS}>Review your recent transactions</Link>
      </Pane>
      {imageUrl && (
        <Image
          src={imageUrl}
          maxWidth={isMobile ? '90%' : 300}
          maxHeight={150}
          width="auto"
          height="auto"
          float="right"
          marginLeft={4}
          borderRadius={isMobile ? 8 : undefined}
          alignSelf={isMobile ? 'center' : undefined}
        />
      )}
    </Pane>
  )
}

export default function OrderConfirmation() {
  const org = useContext(OrgContext)
  const orgId = org.id
  const location = useLocation()
  const orderInfo = createOrderLocation({ location })

  const [digitalOrderInfo, setDigitalOrderInfo] =
    useState<OrgTransaction_DigitalOrderInfo>()

  const [isLoadingOrderInfo, setIsLoadingOrderInfo] = useState(false)

  useEffect(() => {
    if (!orderInfo?.transactionConfirmed) return
    if (orderInfo?.orderId) {
      setIsLoadingOrderInfo(true)
      GetTransactionById({ orgId, transactionId: orderInfo.orderId })
        .then(res => {
          if (res) {
            setDigitalOrderInfo(res.digitalOrderInfo)
          }
        })
        .finally(() => setIsLoadingOrderInfo(false))
    }
  }, [orderInfo?.orderId, orderInfo?.transactionConfirmed, orgId])

  const showOrder = orderInfo?.transactionConfirmed && !isLoadingOrderInfo

  const orderIsLoading = orderInfo?.transactionConfirmed && isLoadingOrderInfo

  return (
    <>
      {showOrder && (
        <OrderConfirmationDetails
          imageUrl={orderInfo?.imageUrl}
          digitalOrderInfo={digitalOrderInfo}
          deliveryInformation={orderInfo?.deliveryInformation}
          shippingAddress={orderInfo?.shippingAddress}
        />
      )}
      {orderIsLoading && <Skeleton />}
    </>
  )
}
